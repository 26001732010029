import { useFieldArray, useFormContext } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import CheckboxType from "./CheckboxType";
import CurrencyType from "./CurrencyType";
import LookupType from "./LookupType";
import DateType from "./DateType";
import Notes from "./Notes";
import TextType from "./TextType";
import { Card, IconButton } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";
import { useState } from "react";
import { useEffect } from "react";
import Activities from "./Activities";
import RecordAction from "./RecordAction";
import TouchedRecords from "./TouchedRecords";

const PipelineFilter = (props) => {

    const { control } = useFormContext();

    const { fields, append, remove, replace } = useFieldArray({
        name: `filters`,
        control,
    });

    useEffect(() => {
        props.clearFilter && removeAllFilters();
    }, [props.clearFilter]);

    useEffect(() => {
        if (props.selectedOption) {
            append(props.selectedOption);
        }
    }, [props.selectedOption]);

    const renderFields = (field, index) => {
        switch (field?.type) {
            case "touchedRecords":
                return <TouchedRecords name={`filters.${index}.filters`} field={field} touched={true} />
            case "untouchedRecords":
                return <TouchedRecords name={`filters.${index}.filters`} field={field} touched={false} />
            case "recordAction":
                return <RecordAction name={`filters.${index}.filters`} field={field} module="recordAction" />
            case "relatedRecordAction":
                return <RecordAction name={`filters.${index}.filters`} field={field} module="relatedRecordAction" />
            case "activities":
                return <Activities name={`filters.${index}.filters`} field={field} />
            case "notes":
                return <Notes name={`filters.${index}.filters`} field={field} />
            case "text":
                return <TextType name={`filters.${index}.filters`} field={field} />
            case "checkbox":
                return <CheckboxType name={`filters.${index}.filters`} field={field} />
            case "currency":
            case "number":
                return <CurrencyType name={`filters.${index}.filters`} field={field} />
            case "lookup":
            case "picklist":
                return <LookupType name={`filters.${index}.filters`} field={field} />
            case "datePicker":
                return <DateType name={`filters.${index}.filters`} field={field} />
            default:
                return <p> Not found </p>
        }
    }

    const removeAllFilters = () => {
        replace([]);
        props.resetSelectionOnAllFilterData();
    };

    const removeFilter = (index, filter) => {
        remove(index);
        props.unselectOption(filter);
    };

    return (
        <>
            {fields.map((filter, index) => {
                return <Field key={filter.id} filter={filter} index={index} removeFilter={removeFilter} renderFields={renderFields} />
            })}
        </>
    )
};

export const Field = (props) => {

    const [isHovering, setIsHovering] = useState(false);

    return (
        <Card onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
            sx={{
                p: 1.5, pt: 0.5, boxShadow: "none", borderBottom: `1px solid ${variables.lightBorderColor}`,
                "&:hover": {
                    bgcolor: `${variables.linkBackgroundColor}`
                }
            }}>
            {/* {isHovering &&  */}
            <IconButton sx={{ float: "right", mr: -0.5, mt: 0.5, p: 0.5 }} onClick={() => props.removeFilter(props.index, props.filter)}>
                <CloseIcon style={{ fontSize: "17px" }} />
            </IconButton>
            {/* } */}
            {props.renderFields(props.filter, props.index)}
        </Card>
    )
}

export default PipelineFilter;