import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Tooltip,
  Grid,
} from "@material-ui/core";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatColorText,
  InsertPhoto,
  Link,
  Gif,
  Movie,
  Archive,
  Gamepad,
} from "@material-ui/icons";

const MessageShortcuts = () => {
  return (
    <div style={{ padding: "0 18px", maxWidth: "700px" }}>
      <Typography
        variant="h6"
        style={{ width: "100%", flex: "0 0 auto", margin: "0px 0 20px" }}
      >
        Message shortcut
      </Typography>
      <Box
        style={{
          border: "1px solid rgba(168,198,223,.4)",
          borderRadius: "2px",
          background: "#ffffff",
          backgroundColor: "#fff",
          boxShadow: "0 3px 4px 0 rgba(117,134,156,.1)",
          animation: "fade-in-up",
          animationDuration: ".4s",
          animationFillMode: "backwards",
          padding: "28px 34px",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          style={{
            fontSize: "18px",
            lineHeight: "28px",
            fontWeight: 700,
            color: "#1c293b",
            marginBottom: "10px!important",
          }}
        >
          Add shortcut
        </Typography>
        <Typography
          variant="body1"
          paragraph
          style={{
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            color: "#75869c",
          }}
        >
          Create a new shortcut. Give it a !bang, a group tag, and write a
          message for this shortcut. Then, use it in conversations to quickly
          answer to visitors using the shortcut !bang.
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1">Shortcuts</Typography>
            <TextField
              placeholder="! bang"
              variant="outlined"
              style={{
                marginTop: "-30px",
                marginLeft: "99px",
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" sx={{ marginTop: "20px" }}>
              In tag
            </Typography>
            <TextField
              placeholder="-"
              variant="outlined"
              style={{
                marginTop: "-30px",
                marginLeft: "99px",
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ marginTop: "20px" }}>
              Message
            </Typography>
            <TextField
              placeholder="Enter a message for this shortcut !bang..."
              variant="outlined"
              style={{ width: "70%", marginLeft: "99px", marginTop: "-30px" }}
            />
          </Grid>
        </Grid>

        <Box
          style={{
            display: "flex",
            marginLeft: "140px",
            marginBottom: "20px!important",
          }}
        >
          {/* Format Controls */}
          <Box
            style={{
              display: "flex",
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "4px",
              opacity: 1,
              marginRight: "10px",
            }}
          >
            <IconButton>
              <Tooltip title="Bold">
                <Box style={{ width: "24px", height: "24px" }}>
                  <FormatBold fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Italic">
                <Box style={{ width: "24px", height: "24px" }}>
                  <FormatItalic fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Underline">
                <Box style={{ width: "24px", height: "24px" }}>
                  <FormatUnderlined fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Colored text">
                <Box style={{ width: "24px", height: "24px" }}>
                  <FormatColorText fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
          </Box>

          {/* Insert Controls */}
          <Box
            style={{
              display: "flex",
              borderStyle: "solid",
              borderWidth: "1px",
              borderRadius: "4px",
              opacity: 1,
            }}
          >
            <IconButton>
              <Tooltip title="Insert a link">
                <Box style={{ width: "24px", height: "24px" }}>
                  <Link fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Insert an image">
                <Box style={{ width: "24px", height: "24px" }}>
                  <InsertPhoto fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Insert a GIF">
                <Box style={{ width: "24px", height: "24px" }}>
                  <Gif fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Insert a video">
                <Box style={{ width: "24px", height: "24px" }}>
                  <Movie fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Insert a file">
                <Box style={{ width: "24px", height: "24px" }}>
                  <Archive fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
            <IconButton>
              <Tooltip title="Insert a replacement tag">
                <Box style={{ width: "24px", height: "24px" }}>
                  <Gamepad fontSize="small" />
                </Box>
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
        <Button variant="contained" color="primary" style={{ marginTop: 16 }}>
          Save Shortcut
        </Button>
      </Box>
    </div>
  );
};

export default MessageShortcuts;
