import React from 'react';
import { Form, FormBuilder } from "@formio/react";
import { useState } from "react";

import ReactJson from "react-json-view";

// import "../../../../../../node_modules/formiojs/dist/formio.full.css";
// import "../../../../../../node_modules/formiojs/dist/formio.embed.css";
// import "../../../../../../node_modules/formiojs/dist/formio.form.css";

import { json_formData } from '../../../../../Atoms/LoginAtoms';
import { NavLink } from 'react-router-dom/dist';


const CustomizeFields = (props) => {
    const [jsonFormData, setJsonFormData] = useState(json_formData);

    const [jsonSchema, setSchema] = useState({components :[]});
  
  
  
    const onFormChange = (schema) => {
      setJsonFormData({ ...schema, components: [...schema.components] });
    };
  
  

  return (
          <div  style={{width : "100%"}}>
            <div style={{padding:10}}>
            <NavLink to='/contacts/Fields'>Back</NavLink>
            </div>
          <FormBuilder form={jsonFormData} onChange={onFormChange} />

<ReactJson
  src={jsonFormData}
  name={null}
  collapsed={true}
></ReactJson>


<Form form={jsonFormData} />
          </div>
  )
}

export default CustomizeFields