import React from 'react';
import styled from 'styled-components';
import History from '../../components/trackey/History/History';
import CrmTaskList from './CrmTaskList';

const tabContents = {
  home: <CrmTaskList />,
  about: 'Content for About Tab',
  contact: 'Content for Contact Tab',
};

const TabContentContainer = styled.div`
  flex-grow: 1;
  padding: 20px;
  border-left: 1px solid #ddd;
  background-color: #f0f0f0;
`;

const TabContent = ({ activeTab }) => {
  return <TabContentContainer>
    <CrmTaskList activeTab={activeTab}/>
    </TabContentContainer>;
};

export default TabContent;
