import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import ReorderableList from "../../pipelines/components/ReorderableList";
import variables from "../../../assets/styles/_colors.scss";

export default function QuickCreatePreference(props) {

    const items = [
        {
            _id: '1',
            label: 'Pipeline Name',
            disabled: true,
            selected: true
        },
        {
            _id: '2',
            label: 'SubPipeline & Stage',
            disabled: true,
            selected: true
        },
        {
            _id: '3',
            label: 'Deal Owner',
            disabled: false,
            selected: true
        },
        {
            _id: '4',
            label: 'Custom Field 1',
            disabled: false,
            selected: false
        },
        {
            _id: '5',
            label: 'Custom Field 2',
            disabled: false,
            selected: false
        },
        {
            _id: '6',
            label: 'Custom Field 3',
            disabled: false,
            selected: false
        },
        {
            _id: '7',
            label: 'Custom Field 4',
            disabled: false,
            selected: false
        },
        {
            _id: '8',
            label: 'Custom Field 5',
            disabled: false,
            selected: false
        }
    ]
    const handleCancel = () => {
        props.close(null);
    };

    const handleOk = (data) => {
        props.close(data);
    };

    return (
        <Dialog sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '100%' } }} open={true} >
            <DialogTitle sx={{ pb: 0 }} alignItems="center">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography component="h2" sx={{ fontSize: "1.25rem" }}>Quick Create Fields</Typography>
                    <Typography sx={{ bgcolor: `${variables.listBackgroundColor}`, fontSize: 11, p: 1, borderRadius: 20 }}>{props.module?.label} </Typography>
                </Stack>

            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 0, pt: 0 }}>
                <Typography sx={{ pl: 2, pt: 1 }}>
                    Choose the fields that needs to be shown in the Quick Create popup when you try to create a new pipeline record from a lookup field
                </Typography>
                <ReorderableList items={items} handleCancel={handleCancel} handleOk={handleOk} module="modifyProducts" />
            </DialogContent>
        </Dialog>
    )
}