import { Box, Button, CircularProgress, Drawer, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import variables from "../../../../assets/styles/_colors.scss";
import { CloseButton } from "../../../../components/CloseButton";
import { CustomInputField } from "../../../pipelines/components/CustomInputField";
import { CustomSwitch } from "../../../pipelines/components/CustomSwitch";
import RoleTree from "./RoleTree";
import { createRole, editRole } from "../../../../webservices/UserControlService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CustomSnackbar from "../../../../components/CustomSnackbar";

import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
    roleName: Yup.string().trim().required("Role Name cannot be empty")
        .min(2, "Role Name must be atleast 2 chars long")
        .max(200, "Role Name cannot be more that 200 chars long"),
    reportsToRole: Yup.string().trim().required("Reports To cannot be empty"),
    peerDataVisibility: Yup.boolean(),
    description: Yup.string().trim().optional(),
    createdBy: Yup.string()
});

const UpsertRole = (props) => {

    const queryClient = useQueryClient();

    const [roleTree, showRoleTree] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(true);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const getRoleNameById = (roleId) => {
        return props.roles?.find(x => x._id == roleId)?.roleName;
    }

    const { mutateAsync, isPending } = useMutation({
        mutationKey: props.edit ? "editRole" : "createRole",
        mutationFn: (data) => props.edit ? editRole(props.item?._id, data) : createRole(data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllRoles'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    const methods = useForm(
        {
            defaultValues: props.edit ? {
                "roleName": props.item.roleName,
                "reportsTo": props.item.reportsTo,
                "reportsToRole": props.item.reportsTo ? getRoleNameById(props.item?.reportsTo) : "test",
                "peerDataVisibility": props.item.peerDataVisibility,
                "description": props.item.description
            } : {
                "roleName": "",
                "reportsTo": props.item?._id,
                "reportsToRole": props.item?.roleName,
                "peerDataVisibility": true,
                "description": ""
            },
            shouldUnregister: false,
            resolver: yupResolver(schema),
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, setValue, trigger, clearErrors } = methods;

    const onSubmit = async (data) => {
        const isFormValid = await trigger();
        if (isFormValid) {
            // alert(JSON.stringify(data));
            if (props.edit) {
                data.peerDataVisibility = (props.edit && props.item?.reportsTo === null) ? true : data.peerDataVisibility;
                data._id = props.item._id;
                data.modifiedBy = "User 1";
            } else {
                data.createdBy = "User 1";
            }
            mutateAsync(data);
        }
    }

    const handleRoleSelect = (item) => {
        if (item) {
            clearErrors("reportsToRole");
            setValue("reportsToRole", item.roleName);
            setValue("reportsTo", item?._id);
        }
        showRoleTree(false);
    }

    function handleDrawerClose(data) {
        setOpenDrawer(false);
        props.handleDrawerClose(data);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <>
            <Drawer anchor="right" open={openDrawer}
                PaperProps={{
                    sx: {
                        minWidth: "35vw", maxWidth: "35vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                    },
                }}>

                <Box sx={{ height: "100vh" }}>

                    <Typography variant="h5" sx={{ pl: 2 }}>{props.edit ? 'Edit ' : 'Create '} Role
                        <CloseButton handleDialogClose={() => handleDrawerClose()} />
                    </Typography>

                    <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 2.5, height: "86vh" }}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} id="roleForm" style={{ height: "100%" }}>
                                <Stack direction="column" spacing={2}>
                                    <CustomInputField name={`roleName`} size="medium" label="Role Name*" />
                                    {
                                        !(props.edit && props.item?.reportsTo === null) &&
                                        <>
                                            <Typography component="div" onClick={(e) => { e.preventDefault(); e.stopPropagation(); showRoleTree(true) }} sx={{ cursor: 'pointer' }}>
                                                <CustomInputField name={`reportsToRole`} size="medium" label="Reports To*" placeholder="Select" readOnly={true} disabled={props.disableReportsTo} showCursor={true} />
                                            </Typography>
                                            <Stack direction="row" spacing={1} width="100%" alignItems="center" >
                                                <Typography>Peer Data Visibility</Typography>
                                                <Typography sx={{ bgcolor: `${variables.linkBackgroundColor}`, border: `1px solid ${variables.lightBorderColor}`, pl: 1 }}>
                                                    <CustomSwitch name={`peerDataVisibility`} label={`Let user's in this role see each other's data`} defaultChecked={true} />
                                                </Typography>
                                            </Stack>
                                        </>
                                    }
                                    <CustomInputField name={`description`} size="medium" label="Description" rows={5} subType="multi" placeholder="A few words about this role" />
                                </Stack>
                            </form>
                        </FormProvider>
                    </Box>

                    <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button disabled={isPending} variant="outlined" onClick={() => handleDrawerClose()}> Cancel </Button>
                        <Button
                            disabled={isPending}
                            type="submit"
                            form={"roleForm"}
                            variant="contained"
                            color="primary"
                        > Save
                            {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                        </Button>
                    </Stack>
                </Box>
                {roleTree && <RoleTree nodeToDisable={props.item} handleClose={(item) => handleRoleSelect(item)} />}
            </Drawer >

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />
        </>
    );
};

export default UpsertRole;