import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { createCallLog, createEvent, createTask } from "../../../utils/createpipelineformdata";
import { useEffect } from "react";
import { CustomInputField } from "../../pipelines/components/CustomInputField";
import { CustomCurrencyField } from "../../pipelines/components/CustomCurrencyField";
import { CustomCheckbox } from "../../pipelines/components/CustomCheckbox";
import { CustomDatePicker } from "../../pipelines/components/CustomDatePicker";
import { CustomDateTimePicker } from "../../pipelines/components/CustomDateTimePicker";
import { CustomSelect } from "../../pipelines/components/CustomSelect";
import { CustomNumberField } from "../../pipelines/components/CustomNumberField";
import { CloseButton } from "../../../components/CloseButton";
import CustomizeFields from "../../pipelines/create/CustomizeFields";
import variables from "../../../assets/styles/_colors.scss";
import { CustomTaskRepeatField } from "./CustomTaskRepeatField";
import { capitalizeFirstLetter } from "../../../utils/PipelineConstants";

const UpsertTask = (props) => {

    const [data, setData] = useState(null);
    const [showFieldsForm, setShowFieldsForm] = useState(false);

    const [open, setOpen] = useState(true);

    function handleDrawerClose() {
        setOpen(false);
        props.handleDrawerClose();
    }

    const methods = useForm(
        {
            defaultValues: props.module === "task" && createTask || props.module === "event" && createEvent || props.module === "call" && createCallLog,
            shouldUnregister: false,
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, getValues, setValue, watch, trigger } = methods;

    const currentFields = watch(`fields`);

    useEffect(() => {
        props.edit === true && setValue(props.module, {
            name: "Task 1"
        })
    }, [props.edit]);

    useEffect(() => {
        setData(getValues("fields"));
    }, [currentFields]);

    const renderFields = (field, prefix) => {
        switch (field.type) {
            case "repeat":
                return <CustomTaskRepeatField module={props.module} prefix={prefix} compareWith={field.compareWith} name={`${prefix}.${field.key}`} label={field.label} defaultChecked={field.checkbox.defaultChecked} />
            case "text":
            case "url":
            case "phone":
            case "email":
                return <CustomInputField name={`${prefix}.${field.key}`} rows={field.rows} value={field.value} label={field.label} rules={field.rules} subType={field.subType}
                    placeholder={field.placeholder} />;
            case "currency":
                return <CustomCurrencyField name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} />;
            case "checkbox":
                return <CustomCheckbox name={`${prefix}.${field.key}`} label={field.label} defaultChecked={field.checkbox.defaultChecked} />;
            case "date": {
                return field.subType === "datePicker" ?
                    <CustomDatePicker name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} width="100%" /> :
                    <CustomDateTimePicker name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} width="100%" />
            }
            case "user":
                return <CustomSelect name={`${prefix}.${field.key}`} inputLabel={field.label} value={null} rules={field.rules}
                    options={[{ label: 'Mike Ross', value: "1" }, { label: 'Harvey Specter', value: "2" }]} placeholder={field.placeholder}>
                </CustomSelect>
            case "picklist":
                return <CustomSelect name={`${prefix}.${field.key}`} inputLabel={field.label} defaultValue={field.picklist.defaultValue?.value} rules={field.rules}
                    options={field.picklist.options} />;
            case "lookup":
                return <CustomSelect name={`${prefix}.${field.key}`} rules={field.rules} inputLabel={field.label}
                    options={field.options ? field.options : [{ label: "ABC Corp", value: "abc" }, { label: "XYZ Corp", value: "xyz" }]}>
                </CustomSelect>;
            case "numerical":
                return <CustomNumberField name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} subType={field.subType} />;
            default:
                return null;

        }
    }

    const handleCustomizeFieldsClose = (formData) => {
        setShowFieldsForm(false);
        if (formData !== null) {
            setValue("fields", formData.fields);
        }
    }

    const onSubmit = async (data) => {
        const isStepValid = await trigger("fields");
        if (isStepValid) {
            // alert(JSON.stringify(data[props.module]));
            // handleDrawerClose();
        }
    }

    return (
        <><Drawer anchor="right" open={open}
            PaperProps={{
                sx: {
                    minWidth: "35vw", maxWidth: "35vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                },
            }}>
            <Box sx={{ height: "100vh" }}>
                <Typography variant="h5" sx={{ pl: 2 }}>{props.edit ? 'Edit ' : 'Create '} {capitalizeFirstLetter(props.module)}
                    <CloseButton handleDialogClose={handleDrawerClose} />
                </Typography>

                <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 2.5, height: "86vh" }}>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)} id="taskForm" style={{ height: "100%" }}>
                            {data && Object.keys(data).filter(l => l !== "unUsedFields").map((l, i) => {
                                return <div key={`fields-wrapper-${i}`}>
                                    {data[l]?.fields?.length > 0 && <Typography component="div" sx={{ pb: 2, fontWeight: 600 }}> {data[l].name} </Typography>}
                                    <Stack direction="column" spacing={1}>
                                        {data[l].fields.map((field, index) => {
                                            return <Box key={`field-${index}`} sx={{ pb: 1.5 }}>{renderFields(field, props.module, false)}</Box>
                                        })}
                                    </Stack>
                                </div>
                            })}
                        </form>
                    </FormProvider>
                </Box>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 16px 12px 20px", background: `${variables.linkBackgroundColor}` }}>
                    <Stack direction="row" justifyContent="left" sx={{ width: "50%" }}>
                        {props.module !== "call" &&
                            <Typography
                                onClick={() => setShowFieldsForm(true)} sx={{ color: "#3d5af1", cursor: "pointer", textDecoration: "underline" }}>
                                Customize Fields
                            </Typography>
                        }
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="right" sx={{ width: "50%" }}>
                        <Button variant="outlined" onClick={handleDrawerClose}> Cancel </Button>
                        <Button
                            // disabled={isSubmitting}
                            form="taskForm"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >Save</Button>
                        {/* {isSubmitting && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )} */}
                    </Stack>
                </Stack>

                {showFieldsForm && <CustomizeFields title="Edit Task Fields" open={true} handleDrawerClose={(formData) => handleCustomizeFieldsClose(formData)}
                    defaultValues={{ "fields": getValues("fields") }} />}
            </Box>
        </Drawer >
        </>
    );
};

export default UpsertTask;