import React from 'react'

const InstagramHome = () => {
  return (
    <div>
      Instagram
    </div>
  )
}

export default InstagramHome
