import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel, IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const CustomCheckbox = (props) => {
    const { control, getValues, setValue } = useFormContext();
    const [tooltipText, setTooltipText] = React.useState(props.tooltipText);

    return (
        <FormControlLabel
            sx={{ width: props.width ? props.width : '100%' }}
            control={
                <Controller
                    name={props.name}
                    control={control}
                    defaultValue={props.defaultValue ? props.defaultValue : false}
                    render={({ field }) => (
                        <>
                            {props.disableCheckbox ?
                                <Checkbox
                                    disabled
                                />
                                :
                                <Checkbox
                                    {...field}
                                    color="primary"
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                />}
                        </>
                    )}
                />
            }
            label={
                <>
                    {props.label}
                    {tooltipText && <Tooltip title={tooltipText}>
                        <span>
                            <IconButton aria-label="edit" color="primary" size="small">
                                <InfoOutlinedIcon fontSize="inherit"></InfoOutlinedIcon>
                            </IconButton>
                        </span>
                    </Tooltip>}
                </>
            }
        />
    );
};
