import React from 'react';

import { Pagination, Navigation , Scrollbar} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import './ImageSliderDialog.css'
import 'swiper/swiper.min.css';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height : 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function NotesSliderDialog({ isOpen, onClose, File }) {
 

  return (
   
       <Modal
        keepMounted
        open={isOpen}
        onClose={onClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
      <Box sx={style}>
      <Swiper
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Navigation, Pagination, Scrollbar]}
        className="mySwiper"
      >  
    {File.map((fileData, index) => (
        <div key={index}>
        <SwiperSlide>
        <div>
      <h1>Description</h1>
            
          <pre>{fileData.content}</pre>
       
     
    </div>

        </SwiperSlide>
        </div>
        ))}
      </Swiper>
      </Box>
    </Modal>
  );
}


