import React from "react";
import RenewalStyle from "../Styles/RenewalConfirm.module.css";
const RenewalConfirm = ({ confirmationText, domainName, isDelete }) => {
  return (
    <div className={RenewalStyle.yellow_banner}>
      {isDelete ? (
        <div>
          <span>{`Are you sure you want to`}</span>{" "}
          <span style={{ color: "rgba(223, 0, 0, 1)" }}>
            {"Delete"} {""}
          </span>
          <span>{`gsuite account for `} {" "} </span>
        </div>
      ) : (
        <span>{confirmationText}</span>
      )}{" "}
      <div>
        <span className={RenewalStyle.blue_banner}> {" "}{domainName}</span>
      </div>
    </div>
  );
};

export default RenewalConfirm;
