import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Grid, Stack } from "@mui/material";
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import PipelineActions from './PipelineActions';
import SubPipelineTabs from './SubPipelineTabs';
import { useRecoilState, useRecoilValue } from 'recoil';
import { pipeline_atoms } from '../../../../../Atoms/CrmAtoms';
import CreatePipelineWrapper from '../create/CreatePipelineWrapper';
import CreatePipeline from '../create/CreatePipeline';
import PipelinePopover from './PipelinePopover';
import CreateSubPipeline from '../create/CreateSubpipeline';
import ClonePipelineModal from '../create/ClonePipelineModal';
import ClonePipeline from '../create/ClonePipeline';
import CloneTemplateBoard from '../create/CloneTemplateBoard';

const drawerWidth = '15vw';

const PipelineContainer = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            // marginRight: `${drawerWidth}px`,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: 4,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    maxHeight: "50px !important",
    minHeight: "50px !important"
}));

export default function PipelineLeftPanel(props) {
    const [pipelineAtom, setPipelineAtom] = useRecoilState(pipeline_atoms);

    const [selectedPipeline, setSelectedPipeline] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const theme = useTheme();
    const [open, setOpen] = useState(pipelineAtom.isDrawerOpen);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const [pipelineView, setPipelineView] = useState(pipelineAtom.isBoardView);

    const [openCreatePipelineWrapper, setOpenCreatePipelineWrapper] = useState(false);
    const [openCreatePipelineStepper, setOpenCreatePipelineStepper] = useState(false);
    const [openCreateSubPipelineModal, setOpenCreateSubPipelineModal] = useState(false);
    const [openClonePipelineModal, setOpenClonePipelineModal] = useState(false);

    const [showCloneTemplate, setShowCloneTemplate] = useState(false);
    const [removeCloneSelection, setRemoveCloneSelection] = useState(true);

    useEffect(() => {

        const pipelineIdParam = searchParams.get('pipeline');
        const index = pipelineIdParam ? props.pipelineList.findIndex((el) => el.id === pipelineIdParam) : 0;
        const selectedPipelineByParam = props.pipelineList[index];
        const viewTypeParam = searchParams.get('view');

        if (viewTypeParam && viewTypeParam === 'list') {
            setPipelineView(false);
        } else {
            searchParams.set('view', 'board'); // set default view as board
            setPipelineView(true);
        }

        if (props.pipelineList && props.pipelineList.length > 0) {
            if (pipelineIdParam) {
                setSelectedIndex(index);
                setSelectedPipeline(selectedPipelineByParam);
            } else {
                setSelectedIndex(0);
                setSelectedPipeline(props.pipelineList[0]);
                searchParams.set('pipeline', props.pipelineList[0]?.id);
                props.pipelineList[0]?.subPipelines[0] && searchParams.set('subpipeline', props.pipelineList[0]?.subPipelines[0]?.id);
            }
            setSearchParams(searchParams);
        }

    }, [searchParams]);

    useEffect(() => {

    }, [props.showCloneTemplate]);

    useEffect(() => {

    }, [open]);

    useEffect(() => {

    }, [isFilterOpen]);

    const sendViewType = (isPipelineView) => {
        setPipelineView(isPipelineView);
        searchParams.set('view', isPipelineView ? 'board' : 'list');
        setSearchParams(searchParams);
        // set view type in recoil to remember selection
        const a = Object.assign({}, pipelineAtom);
        a.isBoardView = isPipelineView;
        setPipelineAtom(a);
    }

    const handleListItemClick = (event, index, pipeline) => {
        setSelectedIndex(index);
        setSelectedPipeline(pipeline);
        setShowCloneTemplate(false);
        setRemoveCloneSelection(true);
        if (pipeline) {
            searchParams.set('pipeline', pipeline.id);
            searchParams.delete('subpipeline');
            pipeline.subPipelines[0] && searchParams.set('subpipeline', pipeline.subPipelines[0]?.id);
            setSearchParams(searchParams);
        }
    };

    const setIsDrawerOpen = (isOpen) => {
        const a = Object.assign({}, pipelineAtom);
        a.isDrawerOpen = isOpen;
        setPipelineAtom(a);
        setOpen(isOpen);
    }

    const handleCreatePipelineWrapperOpen = () => {
        setOpenCreatePipelineWrapper(true);
    }

    const handleCreatePipelineWrapperClose = (type) => {
        if (type) {
            type === "newpipeline" && setOpenCreatePipelineStepper(true);
            type === "newsubpipeline" && setOpenCreateSubPipelineModal(true);
            type === "clonepipeline" && setOpenClonePipelineModal(true);
        }
        setOpenCreatePipelineWrapper(false);
    }

    const handleClonePipelineClose = (clone) => {
        setOpenClonePipelineModal(false);
    }

    const setSelectedClonePipeline = (pipeline) => {
        setSelectedIndex(null);
        setSelectedPipeline(pipeline);
        setShowCloneTemplate(true);
    }

    return (<>
        <Box sx={{ display: 'flex', height: "calc(100vh - 48px)", overflow: 'hidden' }}>
            <CssBaseline />
            {!open &&
                <Button onClick={() => setIsDrawerOpen(true)}
                    size="small"
                    variant="contained"
                    sx={{
                        writingMode: 'vertical-lr',
                        textTransform: 'none', fontSize: 16,
                        minWidth: "50px",
                        bgcolor: "#3d5af1",
                        borderRadius: 0,
                        color: "#fff",
                        "&:hover": {
                            bgcolor: "#3d5af1"
                        }
                    }}>
                    {selectedPipeline?.name}
                </Button>}
            <Drawer
                sx={{
                    height: "calc(100vh - 48px)",
                    width: open ? `${drawerWidth}` : 0,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: open ? `${drawerWidth}` : `-${drawerWidth}`,
                        boxSizing: 'border-box',
                    }
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader sx={{ justifyContent: 'normal', overflowY: 'auto' }}>
                    <Stack direction="row" width="100%">
                        <Stack direction="row" align="left" width="77%">
                            <Typography component="div" sx={{ p: 1, pr: 0.5, fontSize: '1rem', fontWeight: 600 }}>
                                Team Pipelines
                            </Typography>
                            <IconButton aria-label="add" onClick={handleCreatePipelineWrapperOpen}>
                                <AddIcon />
                            </IconButton>
                        </Stack>
                        <Stack direction="row" align="right" width="23%">
                            <IconButton onClick={() => setIsDrawerOpen(false)} sx={{ mr: 1, ml: 1 }}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </Stack>
                    </Stack>
                </DrawerHeader>
                <List sx={{ pt: 0, pb: 0 }}>
                    {props.pipelineList.map((pipeline, index) => (

                        <ListItemButton
                            sx={{ pr: 0, pl: 1 }}
                            key={pipeline.id}
                            selected={index === selectedIndex}
                            onClick={(event) => { handleListItemClick(event, index, pipeline) }}>
                            <ListItemIcon sx={{ minWidth: 28 }}>
                                <StackedBarChartIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={pipeline.name}
                            />
                            <ListItemIcon sx={{ display: 'contents' }}>
                                <PipelinePopover />
                            </ListItemIcon>
                        </ListItemButton>
                    ))}
                </List>
                <Divider />
                <ClonePipeline removeSelection={removeCloneSelection} showTemplate={false} handleClonePipelineSelection={(data) => setSelectedClonePipeline(data)} />
            </Drawer>
            {!showCloneTemplate ?
                (
                    <>
                        <Grid container>
                            <PipelineContainer open={open} style={{ 'padding': '0px', overflowX: 'auto' }}>
                                <Grid item container direction="row" sx={{ width: '100%', p: 0, height: "calc(100vh - 48px)", ml: isFilterOpen ? (open ? '7vw' : '19vw') : 'auto' }}>
                                    <Grid item xs={12} sx={{ width: '100%', height: '50px !important', right: 0 }}>
                                        <DrawerHeader>
                                            <PipelineActions drawerOpen={open} isFilterOpen={(val) => setIsFilterOpen(val)} pipelineView={pipelineView} sendViewType={sendViewType}></PipelineActions>
                                        </DrawerHeader>
                                    </Grid>
                                    <Grid item xs={12} sx={{ width: '100%', height: "calc(100vh - 115px)" }}>
                                        <SubPipelineTabs drawerOpen={open} pipelineView={pipelineView} currentPipeline={selectedPipeline}></SubPipelineTabs>
                                    </Grid>
                                </Grid>
                            </PipelineContainer>
                        </Grid>
                    </>
                )
                : (
                    <CloneTemplateBoard selectedPipeline={selectedPipeline} />
                )}
        </Box>
        {openCreatePipelineWrapper && <CreatePipelineWrapper handleDialogClose={(type) => handleCreatePipelineWrapperClose(type)} />}
        {openCreatePipelineStepper && <CreatePipeline isNewPipeline={true} handleDrawerClose={() => setOpenCreatePipelineStepper(false)} />}
        {openCreateSubPipelineModal && <CreateSubPipeline handleDrawerClose={() => setOpenCreateSubPipelineModal(false)} ></CreateSubPipeline>}
        {openClonePipelineModal && <ClonePipelineModal handleClose={((clone) => handleClonePipelineClose(clone))} />}
    </>
    );
}