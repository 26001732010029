import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Autocomplete, IconButton, Stack, TextField, Typography } from "@mui/material";
import { AddCircleOutline } from "@material-ui/icons";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import { useFieldArray, useFormContext } from "react-hook-form";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import variables from "../../../assets/styles/_colors.scss";
import { useEffect } from "react";

const defaultValue = {
    label: null,
    value: null
};

export default function ReorderableAutocompleteList(props) {
    const {
        control,
        register,
        getValues,
        setValue,
        clearErrors,
        formState: { errors }
    } = useFormContext();

    const [options, setOptions] = useState([]);

    useEffect(() => {
        props.items && setOptions(props.items);
    }, [props.items]);

    const { fields, append, remove, move, insert } = useFieldArray({
        name: props.id,
        control,
    });

    useEffect(() => {
        setValue(props.id, props.defaultValues ? props.defaultValues : [defaultValue, defaultValue]);
    }, [props.defaultValues]);

    const handleChange = index => (event, option, val) => {
        const d = getValues(props.id);
        // set current selected option visibility
        const data = options.map(el => el.value === option?.value ? { ...el, selected: true } : el);
        // set previously selected option visibility
        const dataa = data.map(el => el.value === d.at(index)?.value ? { ...el, selected: false } : el);
        setOptions(dataa);
        setValue(`${props.id}.${index}`, option ? { label: option.label, value: option.value } : defaultValue);
        clearErrors(`${props.id}.${index}.label`);
    }

    function reorderItems(result) {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        move(startIndex, endIndex);
    }

    const addNewRow = (index) => {
        index === -1 ? append(defaultValue) : insert(index + 1, defaultValue);
    }

    const removeSelectedRow = (index) => {
        const d = getValues(props.id);
        const data = options.map(el => el.value === d[index]?.value ? { ...el, selected: false } : el)
        setOptions(data);
        remove(index);
    }

    return (
        <>
            <DragDropContext onDragEnd={reorderItems}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {fields.map((item, i) => {
                                return (
                                    <Draggable key={`${item.label}_${i}`} draggableId={`${item.label}_${i}`} index={i}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                variant={snapshot.isDragging ? "elevation" : "outlined"}
                                                elevation={4}
                                            >
                                                <Stack alignItems="center" direction="row" sx={{ width: "100%", pb: 1 }}>
                                                    <Typography component="div" sx={{ cursor: "move", pr: 2, width: "6%" }} {...provided.dragHandleProps} >
                                                        <DragIndicatorIcon />
                                                    </Typography>
                                                    <Autocomplete
                                                        freeSolo
                                                        disablePortal
                                                        size="small"
                                                        id={`select_${item.value}`}
                                                        options={options?.filter(item => !item.selected)}
                                                        sx={{ width: '85%' }}
                                                        onChange={handleChange(i)}
                                                        defaultValue={item.label}
                                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                                        renderInput={(params, ref, value) => (
                                                            <TextField
                                                                error={Boolean(errors[props.id]?.[i])}
                                                                helperText={errors[props.id]?.[i]?.label?.message}
                                                                id={`stages_${item.value}`}
                                                                name="stages-text"
                                                                {...register(`${props.id}.${i}.label`)}
                                                                inputRef={ref}
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                    <IconButton onClick={() => addNewRow(i)} sx={{ color: `${variables.addIconColor}`, width: "7%" }}>
                                                        <AddCircleOutline></AddCircleOutline>
                                                    </IconButton>
                                                    {fields.length > 1 &&
                                                        <IconButton onClick={() => removeSelectedRow(i)} sx={{ color: `${variables.removeIconColor}`, width: "7%" }}>
                                                            <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
                                                        </IconButton>
                                                    }
                                                </Stack>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Typography component="span" onClick={() => addNewRow(-1)} sx={{
                color: `${variables.linkColor}`, cursor: "pointer",
                backgroundColor: `${variables.linkBackgroundColor}`
            }} >
                <AddIcon /> Stage
            </Typography>
        </>
    );
}
