// importing atom from recoil
import { atom } from "recoil";
//for persisting recoil state
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

var schedule1 = atom({
    key: "schedule1",
    default: [],
  });

  var scheduleID = atom({
    key: "scheduleID",
    default: '',
    effects_UNSTABLE: [persistAtom],
  });

  var emailAccount = atom({
    key: "emailAccount",
    default: '',
    effects_UNSTABLE: [persistAtom],
  });

  var preloadStatus = atom({
    key: "preloadStatus",
    default: false,
  });

  var searchbar = atom({
    key: "searchbar",
    default: {search:"",status:"all",owner:"all"}
  })

  var owners = atom({
    key: "owners",
    default: []
  })

  var serachTrigger = atom({
    key: "serachTrigger",
    default: false
  })

  var addTemplatePopup = atom({
    key: "addTemplatePopup",
    default: false
  })

  var templateWarningPopup = atom({
    key: "templateWarningPopup",
    default: false
  })

  var templateTitle = atom({
    key: "templateTitle",
    default: false
  })

  export {
    schedule1,
    scheduleID,
    emailAccount,
    preloadStatus,
    searchbar,
    owners,
    serachTrigger,
    addTemplatePopup,
    templateWarningPopup,
    templateTitle
  };