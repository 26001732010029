import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme }) => ({
  height: "48px",
  background: "#000AFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight:"600",
  fontSize:"16px",
  color:"white"
}));
const Header = ({ handleClickClose }) => {
  return (
    <StyledBox>
      <div style={{marginLeft:"30px"}}>
      Create Bussiness Unit
      </div>
      <IconButton
        component="span"
        onClick={handleClickClose}
        style={{ padding: 0, marginRight: 20 }}
      >
        <CloseIcon style={{ height: "16px", width: "16px", color: "white" }} />
      </IconButton>
    </StyledBox>
  );
};

export default Header;
