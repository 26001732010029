import { Box, Stack, Typography } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { get, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { CustomAutoComplete } from "../components/CustomAutocomplete";
import variables from "../../../assets/styles/_colors.scss";

const options = [
    {
        label: "Is",
        value: "="
    },
    {
        label: "Isn't",
        value: "!="
    },
    {
        label: "Is Empty",
        value: "is_empty"
    },
    {
        label: "Is Not Empty",
        value: "not_empty"
    }
];

const LookupType = (props) => {

    const { watch, formState: { errors }, setValue, getValues } = useFormContext();
    const [showField, setShowField] = useState(true);
    const fieldErrors = get(errors, props.name);

    const selectedOption = watch(`${props.name}.comparator`);

    useEffect(() => {
        setShowField((selectedOption !== "is_empty" && selectedOption !== "not_empty") ? true : false);
        setValue(`${props.name}.value`, []);
    }, [selectedOption]);


    useEffect(() => {
        setValue(`${props.name}.value`, []);
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>

            <Stack direction="row" sx={{ width: '50%', pt: 1 }}>
                <CustomSelect size="small" options={options} name={`${props.name}.comparator`} defaultValue={options[0].value} />
            </Stack>

            <Stack direction="row" sx={{ width: '100%', pt: 1 }}>
                {showField &&
                    <CustomAutoComplete name={`${props.name}.value`} rules={{ minLength: { value: 1 } }} options={props.field?.lookup?.options} size="small" placeholder="Search" />
                }
            </Stack>

            {fieldErrors &&
                <Box sx={{ color: variables.removeIconColor, pt: 1 }}>
                    {JSON.stringify(fieldErrors)}
                    {fieldErrors["value"] && <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid {props.field?.label} </Typography>}
                </Box>
            }
        </>
    );
};

export default LookupType;