
import "./History.css";
import React, { useEffect , useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';
import { useRecoilState } from "recoil";
import { selectedEmp} from "../../../Atoms/LoginAtoms";


export default function  HistoryMap(props) {

  const [SelectedEmp, setSelectedEmp] = useRecoilState(selectedEmp);


  const mapRef = useRef(null);
  const polylineRef = useRef(null);
  const decoratorRef = useRef(null);
  const tooltipRef = useRef(null);
  const startMarkerRef = useRef(null); 
  const endMarkerRef = useRef(null);   

  useEffect(() => {
    const osmUrl = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png';
    const osmAttrib =
      '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';




    if (!mapRef.current) {
      const map = L.map('map').setView([SelectedEmp.current_location_latitude, SelectedEmp.current_location_longitude], 10);
      L.tileLayer(osmUrl, {
        maxZoom: 18,
        attribution: osmAttrib
      }).addTo(map);
      mapRef.current = map;
    }

    if (props.ondutySocket && props.ondutySocket.length > 0) {
      const latlngs = props.ondutySocket.map(k => [
        parseFloat(k.latitude),
        parseFloat(k.longitude),
        `${k.date} - ${k.time} , ${k.location_name}`,
      ]).reverse();

      const latlngs1 = [props.selectedOndtyEmp.off_duty_latitude , props.selectedOndtyEmp.off_duty_longitude];

    
  const customIcon = L.icon({
    iconUrl: 'https://img.icons8.com/ultraviolet/512/place-marker--v1.png',
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
  });

  const customIcon1 = L.icon({
    iconUrl: 'https://img.icons8.com/office/512/place-marker--v1.png',
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
  });
           if (startMarkerRef.current) {
            mapRef.current.removeLayer(startMarkerRef.current);
          }
          if (endMarkerRef.current) {
            mapRef.current.removeLayer(endMarkerRef.current);
          }
    
          const startMarker = L.marker(latlngs[0], { icon: customIcon }).addTo(mapRef.current);
          if(props.selectedOndtyEmp.off_duty_latitude){
            const endMarker = L.marker(latlngs1
              , { icon: customIcon1 }).addTo(mapRef.current);
              endMarkerRef.current = endMarker;
          }    
          startMarkerRef.current = startMarker; // Save the start marker instance in the ref

      const polyline = L.polyline(latlngs).addTo(mapRef.current);
      polylineRef.current = polyline;

      const decorator = L.polylineDecorator(polyline, {
        patterns: [
          {
            offset: '1%',
            repeat: '5%',
            symbol: L.Symbol.arrowHead({
              pixelSize: 10,
              polygon: false,
              pathOptions: {
                stroke: true,
                color: 'blue'
              }
            })
          }
        ]
      }).addTo(mapRef.current);
      decoratorRef.current = decorator;

      polyline.on('mouseover', e => {
        const point = findClosestPoint(e.latlng);
        const text = point[2];
        const tooltip = L.tooltip({
          permanent: true,
          direction: 'top',
          opacity: 0.7
        }).setContent(text);

        tooltipRef.current = tooltip;
        tooltip.setLatLng(e.latlng).openOn(mapRef.current);
      });

      polyline.on('mouseout', () => {
        if (tooltipRef.current) {
          mapRef.current.closePopup(tooltipRef.current);
          tooltipRef.current = null;
        }
      });

      function findClosestPoint(latlng) {
        let closestPoint = null;
        let distance = 0;
        latlngs.forEach(point => {
          const latLngObj = L.latLng([point[0], point[1]]);
          if (closestPoint === null || distance > latLngObj.distanceTo(latlng)) {
            distance = latLngObj.distanceTo(latlng);
            closestPoint = point;
          }
        });
        return closestPoint;
      }
    }

    return () => {
      if (polylineRef.current) {
        polylineRef.current.remove();
      }
      if (decoratorRef.current) {
        decoratorRef.current.remove();
      }
      if (startMarkerRef.current) {
        mapRef.current.removeLayer(startMarkerRef.current);
      }
      if (endMarkerRef.current) {
        mapRef.current.removeLayer(endMarkerRef.current);
      }
      if (tooltipRef.current) {
        mapRef.current.closePopup(tooltipRef.current);
        tooltipRef.current = null;
      }
    };
  }, [props.ondutySocket]);


    
  return (

<>
<div id="map" style={{ height: '400px', width: '100%' }}></div>
<span id="testspan"></span></>

  );
}










