import { Button, Grid, Stack, Typography } from "@mui/material"
import React, { useEffect } from "react"
import ViewCloneTemplateFields from "../view/ViewCloneTemplateFields";
import { useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import variables from '../../../assets/styles/_colors.scss';
import { withStyles } from "@mui/styles";

const ViewFieldsTypography = withStyles({
    root: {
        color: `${variables.linkColor}`,
        bottom: '1.5rem',
        right: '1.5rem',
        margin: '0 1.5rem 2rem 0',
        cursor: 'pointer',
        position: 'fixed',
        "&:hover": {
            textDecoration: 'underline'
        }
    }
})(Typography);

export default function CloneTemplateBoard(props) {

    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {

    }, [props.selectedPipeline]);

    let handleClose = (pipeline) => {
    }

    return (
        <Grid container direction="column" sx={{ height: '100%', p: 2, pl:0.5 }}>
            <Grid item xs={6} sx={{ pl: 1 }}>
                <Grid container direction="row" spacing={2} justifyContent="center">
                    {props.selectedPipeline?.stages?.map((stage, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item sx={{ minWidth: `calc(100% / ${props.selectedPipeline.stages.length})`, pl: 1 }} >
                                    <Grid container direction="column" sx={[{
                                        fontWeight: 600, height: "55px", marginLeft: "1%",
                                        borderRadius: 1, border: `1px solid ${variables.borderColor}`
                                    }, stage.openStage ? { borderTop: `3px solid ${variables.openStageColor}` } : { borderTop: `3px solid ${variables.closeStageSuccessColor}` }]}>
                                        <Grid item xs={2} sx={{ width: "100%", pl: 1 }}>
                                            {stage.name}
                                        </Grid>
                                        <Grid item sx={{ fontWeight: 400, fontSize: 12, marginTop: index === 0 ? 0 : "7px", pl: 1 }}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                {index === 0 ? <span>$1256</span> : <span style={{ width: "40%", height: "10px", borderRadius: 5, backgroundColor: `${variables.borderColor}` }}></span>}
                                                <span style={{
                                                    borderRadius: "50%",
                                                    height: "6px", width: "6px", backgroundColor: `${variables.borderColor}`
                                                }}></span>
                                                {index === 0 ? <span>3 Projects</span> : <span style={{ width: "25%", height: "10px", borderRadius: 5, backgroundColor: `${variables.borderColor}` }}></span>}
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="column"
                                        sx={{ minWidth: `calc(100% / ${props.selectedPipeline?.stages?.length})`, fontWeight: 600, height: "65px", marginLeft: "1%", border: `1px solid ${variables.borderColor}`, borderRadius: 1, marginTop: "8px", pt: 1 }}>
                                        {index === 0 && <>
                                            <Grid item xs={4} sx={{ width: "100%", height: "33%", pl: 1 }}>
                                                {stage?.tasks?.[0]?.name}
                                            </Grid>
                                            <Grid item xs={4} sx={{ fontWeight: 400, fontSize: 12, height: "33%", pl: 1 }}>
                                                Mike Ross
                                            </Grid>
                                            <Grid xs={4} item sx={{ fontWeight: 400, fontSize: 12, height: "33%", pl: 1 }}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <span>$967</span>
                                                    <span style={{
                                                        borderRadius: "50%",
                                                        height: "6px", width: "6px", backgroundColor: `${variables.borderColor}`
                                                    }}></span>
                                                    <span>Jan 20</span>
                                                </Stack>
                                            </Grid>
                                        </>}
                                        {index !== 0 && <>
                                            <Grid item xs={4} sx={{ width: "100%", pl: 1 }}>
                                                <p style={{ width: "60%", height: "10px", borderRadius: 5, backgroundColor: `${variables.borderColor}` }}></p>
                                            </Grid>
                                            <Grid item xs={4} sx={{ fontWeight: 400, fontSize: 12, pl: 1 }}>
                                                <p style={{ width: "50%", height: "10px", borderRadius: 5, backgroundColor: `${variables.borderColor}` }}></p>
                                            </Grid>
                                            <Grid item xs={4} sx={{ fontWeight: 400, fontSize: 12, pl: 1 }}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <span style={{ width: "20%", height: "10px", borderRadius: 5, backgroundColor: `${variables.borderColor}` }}></span>
                                                    <span style={{
                                                        display: "inline-block",
                                                        borderRadius: "50%",
                                                        height: "6px", width: "6px", backgroundColor: `${variables.borderColor}`
                                                    }}></span>
                                                    <span style={{ width: "20%", height: "10px", borderRadius: 5, backgroundColor: `${variables.borderColor}` }}></span>
                                                </Stack>
                                            </Grid>
                                        </>}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )
                    })}
                </Grid >
            </Grid>
            <Grid item xs={6} align="center" sx={{ pl: 1 }}>
                {props.selectedPipeline &&
                    <>
                        <Typography variant="h6" component="div" sx={{ pb: 2 }}>{props.selectedPipeline?.name}</Typography>
                        <Typography sx={{ pb: 2 }}>{props.selectedPipeline?.description}</Typography>
                        <Button variant="contained" size="medium" color="primary" onClick={() => handleClose(props.selectedPipeline)}>Use this template</Button>
                        <ViewFieldsTypography onClick={() => setShowDetails(true)}>
                            <VisibilityIcon sx={{ mr: 1 }} />View Deal fields
                        </ViewFieldsTypography>
                    </>}
                {showDetails && <ViewCloneTemplateFields open={showDetails} close={() => setShowDetails(false)} />}
            </Grid>
        </Grid>


    )
}