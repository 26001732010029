import axios from 'axios';
import { crm_api_url } from '../../../globalSettings';

export const getAllPipelines = async () => {

    const url = `${crm_api_url}/stages`;
    return axios.get(url).then(({ data }) => data);

};

export const createStageRule = async (data) => {

    const url = `${crm_api_url}/stages/rules`;
    return axios.post(url, data).then(({ data }) => data);

}

export const editStageRule = async (data) => {

    const url = `${crm_api_url}/stages/rules`;
    return axios.put(url, data).then(({ data }) => data);

}

export const deleteStageRule = async (transitionRuleId) => {

    const url = `${crm_api_url}/stages/rules/${transitionRuleId}`;
    return axios.delete(url).then(({ data }) => data);

}

export const getTransitionRules = async (teamPipelineId, subPipelineId, start, limit, sortByAsc) => {
    const url = `${crm_api_url}/stages/rules`;
    return axios.get(url, { params: { pipelineId: teamPipelineId, subPipelineId: subPipelineId, limit: limit, offset: start, sort: sortByAsc ? 1 : -1 } })
        .then(({ data }) => data);
};

export const getClosureRestrictions = async (teamPipelineId) => {
    const url = `${crm_api_url}/stages/closurerestrictions/${teamPipelineId}`;
    return axios.get(url).then(({ data }) => data);
};

export const updateClosureRestriction = async (pipelineId, subPipelineId, data) => {

    const url = `${crm_api_url}/stages/closurerestrictions/${pipelineId}/${subPipelineId}`;
    return axios.put(url, data).then(({ data }) => data);

}

export const removeClosureRestriction = async (pipelineId, subPipelineId) => {

    const url = `${crm_api_url}/stages/closurerestrictions/${pipelineId}/${subPipelineId}`;
    return axios.delete(url).then(({ data }) => data);

}