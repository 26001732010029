import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReactSwitch from "react-switch";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FaAngleRight } from "react-icons/fa6";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";

const ChatAvailability = () => {
  const [isOnline, setIsOnline] = useState(true);

  const [isHelpDialogOpen, setHelpDialogOpen] = useState(false);
  const [isDropdownIcon, setIsDropdownIcon] = useState(false);
  const [availability, setAvailability] = useState({
    forceoffline: false,
    available: false,
    availabilityschedule: false,
  });

  const handleAvailability = (name) => (val) => {
    setAvailability((prev) => ({ ...prev, [name]: val }));
  };
  const handleHelpButtonClick = () => {
    setHelpDialogOpen((prev) => !prev);
    setIsDropdownIcon((prev) => !prev);
  };

  const [selectedDay, setSelectedDay] = useState([]);

  const handleDayClick = (day) => {
    setSelectedDay(day === selectedDay ? null : day);
  };

  const [timezoneValue, setTimezoneValue] = useState("");
  const handleAddInterval = () => {
    console.log("Adding Interval");
  };

  return (
    <div>
      <Typography
        sx={{
          flex: 1,
          marginBottom: 0,
          paddingRight: "12px",
          color: "#000",
          fontWeight: 700,
          fontSize: "24px",
          fontFamily: "Roboto",
        }}
      >
        Availability Settings
      </Typography>

      <Box
        sx={{
          border: "1px solid #e3e8ef",
          backgroundColor: "#fff",
          padding: "16px",
          marginTop: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            fontSize: "14.5px",
            lineHeight: "22px",
            fontFamily: "Roboto",
            fontWeight: 400,
            color: "#75869c",
            marginBottom: "10px !important",
          }}
        >
          Set yourself available on schedule, by configuring days and times (in
          your timezone). Visitors will see you as away off scheduled hours, but
          they can still send you messages.
        </Typography>
        <Typography
          sx={{
            fontSize: "14.5px",
            lineHeight: "22px",
            fontFamily: "Roboto",
            fontWeight: 800,
            color: "#75869c",
            marginBottom: "30px !important",
          }}
        >
          If you are a member of a website with multiple operators, the chatbox
          will be seen as online if at least one operator is available, and away
          if all operators are unavailable.
        </Typography>
      </Box>

      <Box
        sx={{
          height: "42px",
          paddingRight: "40px",
          paddingLeft: "40px",
          backgroundColor: "#f5fcee",
        }}
      >
        <Typography
          sx={{
            marginTop: "2px",
            marginRight: "8px",
            flex: "0 0 auto",
            color: "#000",
            display: "flex",
            alignItems: "center",
            padding: "5px",
            fontSize: "14.5px",
            lineHeight: "22px",
            fontFamily: "Roboto",
          }}
        >
          You are Currently seen as:
          <span
            style={{
              display: "inline-block",
              width: "8px",
              height: "8px",
              borderRadius: "100%",
              backgroundColor: isOnline ? "#40b630" : "#ff0000",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          ></span>
          <Typography sx={{ color: isOnline ? "#40b630" : "#ff0000" }}>
            {isOnline ? "Online" : "Offline"}
          </Typography>
        </Typography>
      </Box>

      <Box
        sx={{
          border: "1px solid #e3e8ef",
          backgroundColor: "#fff",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "555px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: availability.forceoffline ? "black" : "#75869c",
                fontFamily: "Roboto",
              }}
            >
              Force offline (invisible mode)
            </Typography>
            <ReactSwitch
              checked={availability.forceoffline}
              onChange={handleAvailability("forceoffline")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "521px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: availability.available ? "black" : "#75869c",
                fontFamily: "Roboto",
              }}
            >
              Set me available when using the app
            </Typography>
            <ReactSwitch
              checked={availability.available}
              onChange={handleAvailability("available")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "570px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: availability.availabilityschedule ? "black" : "#75869c",
                fontFamily: "Roboto",
              }}
            >
              Enable availability schedule
            </Typography>
            <ReactSwitch
              checked={availability.availabilityschedule}
              onChange={handleAvailability("availabilityschedule")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "425px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: "black",
                fontFamily: "Roboto",
              }}
            >
              Days
            </Typography>

            <Box
              display="flex"
              alignItems="center"
              sx={{
                borderRadius: "5px",
                overflow: "hidden",
                backgroundColor: "white",
                padding: "5px",
                textTransform: "capitalize",
              }}
            >
              {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
                <Button
                  key={day}
                  onClick={() => handleDayClick(day)}
                  sx={{
                    backgroundColor:
                      day === selectedDay ? "#ffffff" : "#0093ee",
                    color: day === selectedDay ? "#1c293b" : "#fff",
                    borderRadius: "0px !important",
                    fontSize: "13.5px",
                    minWidth: "40px",
                  }}
                >
                  {day}
                </Button>
              ))}
            </Box>
          </div>
          <Divider sx={{ borderColor: "black" }} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "480px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: availability.availabilityschedule ? "black" : "#75869c",
                fontFamily: "Roboto",
              }}
            >
              Timezone
            </Typography>
            <TextField
              sx={{
                borderColor: "#a8c6df",
                color: " #2c405a",
                height: "40px",
                borderRadius: "3px",
                position: "relative",
                zIndex: 21,
                alignItems: "center",
                backgroundColor: "#fff",
                width: "244px",
              }}
              value={timezoneValue}
              onChange={(e) => setTimezoneValue(e.target.value)}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "620px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: availability.availabilityschedule ? "black" : "#75869c",
                fontFamily: "Roboto",
              }}
            >
              Hours
            </Typography>
            <Button
              sx={{
                fontSize: "13px",
                lineHeight: "22px",
                fontWeight: 400,
                backgroundColor: "#0093ee",
                color: "#fff",
                fontFamily: "Roboto",
                textTransform: "capitalize",
                position: "relative",
                zIndex: 20,
                display: "inline-block",
                outline: 0,
                border: "1px solid rgba(0,0,0,.1)",
                borderRadius: "3px",
              }}
              variant="contained"
              color="primary"
              onClick={handleAddInterval}
            >
              Add an Interval
            </Button>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          textTransform: "capitalize",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid #e3e8ef",
          backgroundColor: "#fafbfc ",
          color: "#75869c",
          lineHeight: "18px",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          onClick={handleHelpButtonClick}
          sx={{
            textTransform: "capitalize",
            paddingTop: "15px",
            marginLeft: "729px",
          }}
        >
          Help{" "}
          {isDropdownIcon ? (
            <KeyboardArrowDownTwoToneIcon />
          ) : (
            <FaAngleRight
              sx={{
                cursor: "inherit",
                fontSize: "16px",
                height: "16px",
                width: "16px",
                position: "absolute",
                right: "22px",
                fill: "#0093ee",
                transform: "rotate(0)",
              }}
            />
          )}
        </Button>

        <Divider />
      </Box>

      {isHelpDialogOpen && (
        <Box
          sx={{
            padding: "6px 30px 26px",
            borderTop: "1px solid #e3e8ef",
            backgroundColor: "#fff",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              margin: "0.85em 0 0.45em",
              color: " #1c293b",
              fontFamily: "Roboto",
              fontWeight: "800!important",
            }}
          >
            What is ‘invisible mode’?{" "}
          </Typography>
          <Typography
            sx={{
              color: "#2c405a",
              position: "relative",
              overflow: "hidden",
              fontFamily: "Roboto",
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "17px",
              marginBottom: "20px",
            }}
          >
            Invisible mode lets you
            <span style={{ fontWeight: 800, padding: "3px" }}>
              force an offline availability
            </span>
            indefinitely, until you disable it. It is useful if you go on an
            extended holiday and you do not want to change your schedule
            settings.
          </Typography>
          <Typography
            sx={{
              color: "#2c405a",
              position: "relative",
              overflow: "hidden",
              fontFamily: "Roboto",
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "17px",
              marginBottom: "20px",
            }}
          >
            You can quickly toggle invisible mode from the sidebar, by clicking
            on your website icon and selecting the invisible mode option. Iaaxin
            will show a reminder that you are offline so that you do not forget.
          </Typography>
          <Divider sx={{ borderColor: "black" }} />

          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              margin: "0.85em 0 0.45em",
              color: " #1c293b",
              fontFamily: "Roboto",
              fontWeight: "800!important",
            }}
          >
            What is ‘set me available when using the app’?
          </Typography>
          <Typography
            sx={{
              color: "#2c405a",
              position: "relative",
              overflow: "hidden",
              fontFamily: "Roboto",
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "17px",
              marginBottom: "20px",
            }}
          >
            This option lets you
            <span style={{ fontWeight: 800, padding: "3px" }}>
              {" "}
              appear online when you are actively using a Iaaxin app
            </span>
            , even if you are off-schedule.
          </Typography>
          <Divider sx={{ borderColor: "black" }} />

          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              margin: "0.85em 0 0.45em",
              color: " #1c293b",
              fontFamily: "Roboto",
              fontWeight: "800!important",
            }}
          >
            How does the schedule work?
          </Typography>
          <Typography
            sx={{
              color: "#2c405a",
              position: "relative",
              overflow: "hidden",
              fontFamily: "Roboto",
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "17px",
              marginBottom: "20px",
            }}
          >
            The schedule lets you define
            <span style={{ fontWeight: 800, padding: "3px" }}>
              {" "}
              automatic online and offline days and times
            </span>
            . It is useful if you have fixed working hours and not actively
            using Iaaxin apps all day long. For instance, if you want to appear
            online and reply from your phone. The configuration is saved and
            applied relative to your
            <span style={{ fontWeight: 800, padding: "3px" }}>
              {" "}
              local timezone.
            </span>
          </Typography>
          <Divider sx={{ borderColor: "black" }} />

          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              margin: "0.85em 0 0.45em",
              color: " #1c293b",
              fontFamily: "Roboto",
              fontWeight: "800!important",
            }}
          >
            When my websites will appear as online?
          </Typography>
          <Typography
            sx={{
              color: "#2c405a",
              position: "relative",
              overflow: "hidden",
              fontFamily: "Roboto",
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "17px",
              marginBottom: "20px",
            }}
          >
            The websites you are a member of will appear as online if
            <span style={{ fontWeight: 800, padding: "3px" }}> at least</span>
            one member operator is online. In other words, if you are online as
            per your availability settings, the websites you are member of will
            appear online. If you do not want to appear online temporarily, you
            can enable invisible mode.
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default ChatAvailability;
