import { Typography } from "@mui/material";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const DashBarChart = () => {
  const [chartData] = useState({
    series: [
      {
        data: [400, 430, 448, 470, 540, 580, 690],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      annotations: {
        xaxis: [
          {
            x: 500,
            borderColor: "#00E396",
            label: {
              borderColor: "#00E396",
              style: {
                color: "#fff",
                background: "#00E396",
              },
              text: "X annotation",
            },
          },
        ],
        yaxis: [
          {
            y: "July",
            y2: "September",
            label: {
              text: "Y annotation",
            },
          },
        ],
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: [
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
      //   grid: {
      //     xaxis: {
      //       lines: {
      //         show: true,
      //       },
      //     },
      //   },
      //   yaxis: {
      //     reversed: true,
      //     axisTicks: {
      //       show: true,
      //     },
      //   },
    },
  });

  return (
    <div
      id="chart"
      style={{
        width: "95%",
        backgroundColor: "white",
        borderRadius: "10px",
        marginTop: "20px",
        padding:10
      }}
    >
        <Typography> Leads Generate By Month</Typography>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default DashBarChart;
