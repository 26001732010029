import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material";
import variables from "../../../../assets/styles/_colors.scss";
import { useState } from "react";
import { flexRender } from "@tanstack/react-table";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import * as React from 'react';
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { useEffect } from "react";
import { stringAvatar } from "../../../../utils/PipelineConstants";
import UserTablePopover from "./UserTablePopover";
import EditUser from "./EditUser";

const StyledLink = styled(Link)`
    display: block;
    color: #212129;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export default function UserTableRow(props) {

    const rowEl = props.rowEl;
    const [isHovering, setIsHovering] = useState(false);
    const [editUser, setEditUser] = useState(false);

    const handleEditUserClose = (data) => {
        setEditUser(false);
        resetHover(data);
    }

    const resetHover = (data) => {
        setIsHovering(false)
        data && props.showSnackbar(data);
    }

    return (
        <tr key={rowEl?.original?.id} id={rowEl?.original?.id} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
            style={{ position: "relative", backgroundColor: isHovering ? `${variables.tableBackgroundColor}` : '#fff', height: "47px" }}
        >
            {rowEl.getVisibleCells().map((cellEl) => {
                return (
                    <td key={cellEl.id}>
                        {(cellEl.column.columnDef.accessorKey === "firstName")
                            ? <>
                                <Box onClick={() => props.handleUserViewClick(rowEl?.original)}
                                    style={{ fontWeight: 'bold', color: isHovering ? `${variables.linkColor}` : "#000", cursor: "pointer" }} >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Avatar {...stringAvatar(`${rowEl?.original?.firstName} ${rowEl?.original?.lastName}`, 30, 30, "1rem")} />
                                        <Typography sx={{ fontWeight: "bold" }}>{rowEl?.original?.firstName} {rowEl?.original?.lastName}</Typography>
                                    </Stack>
                                </Box>
                            </>
                            : (cellEl.column.columnDef.accessorKey === "email")
                                ? <>
                                    <Typography component="a"
                                        sx={{ color: isHovering ? `${variables.linkColor}` : `${variables.pipelineBoardText}` }}
                                        target="_blank" rel="noreferrer" href={`mailto:${rowEl?.original?.email}`}>
                                        {flexRender(
                                            cellEl.column.columnDef.cell,
                                            cellEl.getContext()
                                        )}
                                    </Typography>
                                </>
                                : <>
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                </>
                        }
                    </td>
                );
            })}
            {props.selectedTab !== "deleted" &&
                <Stack direction="row" spacing={1} alignItems="center" sx={{
                    visibility: isHovering ? 'inherit' : 'hidden',
                    height: "47px",
                    zIndex: 5,
                    pr: 0.5,
                    bgcolor: `${variables.lightGreyBackgroundColor}`,
                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    position: 'sticky', right: 0.5,
                    pl: 0.5
                }}>
                    <IconButton onClick={() => setEditUser(true)} sx={{ height: "35px", width: "35px" }} >
                        <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                    </IconButton>

                    <UserTablePopover selectedTab={props.selectedTab} item={[rowEl]} handleClose={(data) => resetHover(data)} />

                </Stack>
            }

            {editUser && <EditUser item={rowEl?.original} handleDrawerClose={(data) => { handleEditUserClose(data) }} />}

        </tr >
    )
}
