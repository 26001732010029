import React from 'react'
import ChatApp from './ChatApp';

const ChatIndex = () => {
  return (
    <div>
    <ChatApp />
  </div>
  )
}

export default ChatIndex