import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

var pipeline_atoms = atom({
    key: "pipeline_atoms",
    default: {
        isBoardView: true,
        isDrawerOpen: true,
        selectedBoardData: 0,
        selectedTableData: 0,
        resetBulkSelection: false,
        displayedColumns: {
            pipelineBoard: [],
            pipelineTable: []
        }
    },
    effects_UNSTABLE: [persistAtom],
});

var activities_atom = atom({
    key: "activities_atom",
    default: {
        displayedColumns: {
            task: [],
            event: [],
            call: []
        }
    },
    effects_UNSTABLE: [persistAtom],
});

var contacts_atom = atom({
    key: "contacts_atom",
    default: {
        displayedColumns: {
            contactsTable: []
        }
    },
    effects_UNSTABLE: [persistAtom],
});

var companies_atom = atom({
    key: "companies_atom",
    default: {
        displayedColumns: {
            companiesTable: []
        }
    },
    effects_UNSTABLE: [persistAtom],
});

var products_atom = atom({
    key: "products_atom",
    default: {
        displayedColumns: {
            productsTable: []
        }
    },
    effects_UNSTABLE: [persistAtom],
});

var settings_atom = atom({
    key: "settings_atom",
    default: {
        freezeActions: false
    },
    effects_UNSTABLE: [persistAtom],
});

export {
    pipeline_atoms,
    activities_atom,
    contacts_atom,
    companies_atom,
    products_atom,
    settings_atom
}
