import React from 'react'
import Truncate from 'react-truncate';
import moment from 'moment';
import noTask from "../../../images/CRM/plan_7404361.png";


const WorkTime = (crmDashDetails) => {


  return (

    <div style={{paddingTop : "20px" , display:"flex" , justifyContent :"center", width : "100%" }}>
         <div className="task-table-container scroll">
         {
             crmDashDetails.crmDashDetails.alloverdueTask.length > 0 ?   
            
      <table className="task-table">
        <thead>
          <tr>
            <th>Over Due Work Items</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
        {crmDashDetails.crmDashDetails.alloverdueTask.map((dueTask) => (
              <React.Fragment 
               key={dueTask._id}
              >
                <tr>
                  <td style={{ width: "70%" ,  fontSize: "12px" }}>
                  <Truncate lines={1} ellipsis={<span>... </span>}>
{dueTask.title}
            </Truncate>
                  </td>
                  <td style={{ width: "30%", fontSize: "12px", color:"rgb(237, 81, 86)" }}>
                    late by {moment.utc(dueTask.due_date).local().startOf('days').fromNow()}
                  </td>
                </tr>
              </React.Fragment>
          )) }
        </tbody>
      </table>

  :  <table className="task-table">
  <thead>
    <tr>
    {/* <th>ID</th> */}
      <th>Over Due Work Items</th>
    </tr>
  </thead>
  <tbody>
   
        <React.Fragment 
        // key={dueTask._id}
        >
          <tr>
          <div className="task-table-container1">
<img src={noTask} alt="loading" />
<p style={{ margin: "20px", fontSize: "14px" }}>There is no Over Due Task</p>

<style>
{" "}
{`
.task-table-container1 {
display: flex;
justify-content: center;
align-items:center;
height:100%;
width:100%;
flex-direction : column;
padding: 20px;
font-family: "Poppins",sans-serif;
}
`}
</style>
</div>
          </tr>
        </React.Fragment>
   
  </tbody>
</table>
  }  
      <style>
        {`
    .task-table-container {
display: flex;
justify-content: flex-start;
align-items:center;
flex-direction : column;
font-family: "Poppins",sans-serif;
overflow: auto;
border-radius:10px;
width:95%;

}



.task-table {
border-collapse: collapse;
margin: 0 auto;
width:100%
}
.button{
color: rgb(212, 211, 211) ;
border: 1px solid #3367d6;
background-color: #3367d6 ;
padding: 5px 10px ;
border-radius: 8px ;
margin-bottom:10px;
}

.button:hover{
color: rgb(212, 211, 211) ;
border: 1px solid #3367d6;
background-color: #3367d6 ;
padding: 5px 10px ;
border-radius: 8px ;
margin-bottom:10px;
}

.task-table th,
.task-table td {
padding: 12px;
text-align: left;
}

.task-table th {
background-color: #fff;
font-weight: 500;
font-size : 12px
}

.task-table tr {
background: #ffffff;
margin-left: 0;
box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
color: #1c1c22;
transition: background-color 0.2s ease;
cursor:pointer;
}

.task-table tr:hover {
background-color: #f5f5f5;
}

.task-row.active {
background-color: #e1f5fe;
cursor: pointer;
}

.add-subtask-button {
background-color: #007bff;
color: white;
border: none;
padding: 4px 8px;
cursor: pointer;
border-radius: 4px;
font-size: 14px;
transition: background-color 0.2s ease;
margin:10px 0
}

.add-subtask-button:hover {
background-color: #0056b3;
}

.sub-tasks-row td {
padding: 0 12px;
text-align: left;
border: none;
}


.task-line {
display: inline-block;
width: 20px;
position: relative;
padding-left:10px;
}

.branch-line {
position: absolute;
top: 8px;
left: -10px;
height: calc(100% - 8px);
width: 1px;
background-color: #ccc;
}

.branch-line.expanded {
background-color: #007bff;
}
.delete-icon {
position: absolute;
top: -8px;
right: -8px;
cursor: pointer;
background-color: #00000024;
color: white;
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}

/* Add this to your CSS */
.calendar-icon {
position: relative;
display: inline-block;
font-size: 12px
}

.calendar-icon input {
padding-right: 30px; /* Create space for the icon */
}

.calendar-icon .icon {
position: absolute;
top: 50%;
right: 10px;
transform: translateY(-50%);
cursor: pointer;
}
/* Add this to your CSS */
.calendar-icon {
position: relative;
display: inline-block;
}

.calendar-icon .icon {
cursor: pointer;
}
/* Add this to your CSS */
.calendar-dropdown {
position: relative;
display: inline-block;
}

.calendar-dropdown .icon {
cursor: pointer;
}

.calendar-dropdown .dropdown-content {
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}

/* Add this to your CSS */
.calendar-dropdown {
position: relative;
display: inline-block;
}

.calendar-icon {
cursor: pointer;
}

.calendar-dropdown-content {
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}
`}
      </style>
    </div>
    </div>
  )
}

export default WorkTime