import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import events from "./events_data";
import { useEffect } from "react";
import { createRef } from "preact";
import ActivitiesPopover from "./ActivitiesPopover";
import { useState } from "react";
import EventInfo from "./create/EventInfo";
import "./activitiescalendar.css";

export default function ActivitiesMainCalendar(props) {

    const calendarComponentRef = createRef();
    const [hoveredDate, setHoveredDate] = useState(null);
    const [anchor, setAnchor] = useState(null);
    const [event, setEvent] = useState(null);

    useEffect(() => {
        if (props.selectedDate) {
            queueMicrotask(() => {
                const calendarApi = calendarComponentRef?.current?.getApi();
                calendarApi?.changeView("timeGridDay");
                calendarApi?.gotoDate(props.selectedDate);
            });
        }
    }, [props.selectedDate]);

    const handleDateChange = (param) => {
        props.handleDateSelection(param.view.currentStart);
    }

    const handleCellClick = (param) => {
        setHoveredDate(param.start);
    }

    const dayCellContent = (arg, arg2) => {
        return <CellContent hoveredDate={hoveredDate} arg={arg} arg2={arg2} />
    };

    const renderEventContent = (eventInfo) => (
        <Stack direction="row" alignItems="center" spacing={0.5} sx={{ maxHeight: 10 }}>
            <Typography sx={{ fontSize: 12 }}>{eventInfo.timeText}</Typography>
            <Typography sx={{ fontSize: 12, fontStyle: 'italic', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{eventInfo.event.title}</Typography>
        </Stack>
    );

    const handleEventClick = (info) => {
        setEvent(info);
        info.jsEvent.preventDefault();
        setAnchor(info.jsEvent?.target);
    };

    const handleDateClick = (param) => {
    }
    // const handleEventMouseEnter = (info) => {
    //     info.jsEvent.preventDefault();
    //     setEvent(info);
    //     setAnchor(info.jsEvent?.target);
    // };

    // const handleEventMouseLeave = (info) => {
    //     info.jsEvent.preventDefault();
    //     setEvent(null);
    //     setAnchor(null);
    // };

    return (
        <>
            <FullCalendar
                ref={calendarComponentRef}
                height='calc(100vh - 112px)'
                width='78vw'
                initialDate={props.selectedDate}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: "today prev,next",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                buttonText={{
                    today: 'Today',
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    list: 'List'
                }}
                initialView="dayGridMonth"
                editable={false}
                selectable={true}
                dayMaxEvents={true}
                events={events}
                dayHeaderContent={(args) => {
                    return args.view.type === "dayGridMonth" ? moment(args.date).format('ddd') : moment(args.date).format('ddd D')
                }}
                datesSet={handleDateChange}
                // initialEvents={INITIAL_EVENTS}
                select={handleCellClick}
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                // eventsSet={handleEvents}
                // eventMouseEnter={handleEventMouseEnter}
                // eventMouseLeave={handleEventMouseLeave}
                // dateClick={handleDateClick}
                dayCellContent={dayCellContent}
                moreLinkClick={"day"}
            />

            {anchor && <EventInfo event={event} anchor={anchor} reset={() => { setAnchor(null); setEvent(null); }} />}

        </>
    )
}

const CellContent = (props) => {
    const date1 = moment(props.hoveredDate).format("DD/MM/YY");
    const date2 = moment(props.arg?.date).format("DD/MM/YY");

    return (
        <Stack direction="row" sx={{ width: props.arg.view.type !== "dayGridMonth" ? '90vw' : '10vw' }}>
            <Stack direction="row" sx={{ width: '90%' }} justifyContent="left">
                <Typography sx={{ color: '#212129' }}>{props.arg?.dayNumberText}</Typography>
            </Stack>
            <Stack direction="row" sx={{ width: '10%' }} justifyContent="right">
                <ActivitiesPopover />
            </Stack>
        </Stack>
    );
}