import { useState } from "react";
import { IconButton, Popover, Tooltip } from "@mui/material";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";

const EmojiPicker = ({ onSelectEmoji }) => {
  const emojis = [
    "😀",
    "🙂",
    "😊",
    "😅",
    "😎",
    "😂",
    "❤",
    "😟",
    "😠",
    "😫",
    "👍",
    "👎",
    "🙏",
    "🤚",
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmojiClick = (emoji) => {
    onSelectEmoji(emoji);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "emoji-picker" : undefined;

  return (
    <>
      {" "}
      <Tooltip title="Insert an emoji" arrow>
        <IconButton
          sx={{
            color: "grey",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
          }}
          onClick={handleClick}
        >
          <EmojiEmotionsOutlinedIcon sx={{ fontSize: "18px" }} />
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "10px" }}>
          {emojis.map((emoji, index) => (
            <span
              key={index}
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={() => handleEmojiClick(emoji)}
            >
              {emoji}
            </span>
          ))}
        </div>
      </Popover>
    </>
  );
};

export default EmojiPicker;
