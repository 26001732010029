import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  TextField,
  Paper,
  Typography,
  Stack,
  Avatar,
  Tooltip,
  IconButton,
} from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmojiPicker from "./components/EmojiPicker";
import { BsFiletypeJson } from "react-icons/bs";
import axios from "axios";
import {
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileArchive,
  FaFileAlt,
} from "react-icons/fa";
import useWindowFocus from "use-window-focus";
import io from "socket.io-client";
import { EditorContent } from "@tiptap/react";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import * as Icons from "./components/Icons";
import classNames from "classnames";
import Underline from "@tiptap/extension-underline";
import "./components/Editor.css";
//import Editor from "./components/ChatEditor/Editor";

const socket = io(`${process.env.REACT_APP_CHATAPP_BASEURL}`);

const Messages = () => {
  const { websiteId } = useParams();
  const [params] = useSearchParams();
  const senderId = params.get("sender");
  const ConversationId = params.get("conversation");

  console.log("ConversationId", ConversationId);
  console.log("senderId", senderId);

  useEffect(() => {
    const fetchInitialMessages = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/chatmsg/${senderId}/${websiteId}/${ConversationId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Initial Messages:", data);
          setMessages(data);
        } else {
          console.error("Failed to fetch initial messages");
        }
      } catch (error) {
        console.error("Error occurred while fetching initial messages:", error);
      }
    };

    fetchInitialMessages();
  }, [senderId, websiteId, ConversationId]);

  const [messages, setMessages] = useState([]);
  const [replyMessage, setReplyMessage] = useState("");
  const [socketid, setSocketid] = useState("");
  const messageContainerRef = useRef(null);
  const isWindowFocused = useWindowFocus();
  const fileInputRef = useRef(null);
  const [openEditor, setOpenEditor] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline.configure({
        HTMLAttributes: {
          class: "my-custom-class",
        },
      }),
    ],
  });

  const handleOpenEditor = () => {
    setOpenEditor(!openEditor);
  };

  const handleChange = (e) => {
    setReplyMessage(e.target.value);
  };

  useEffect(() => {
    socket.emit("createRoom", `${senderId}.${websiteId}.${ConversationId}`);
    console.log("Room created:", `${senderId}.${websiteId}.${ConversationId}`);
  }, [socket, websiteId, senderId, ConversationId]);

  useEffect(() => {
    if (isWindowFocused) {
      console.log(
        `Window is focused. Marking messages as read...${ConversationId},${senderId},${websiteId}`
      );

      socket.emit("markMessagesAsRead", {
        conversation_Id: ConversationId,
        receiver: websiteId,
        sender: senderId,
      });
    }
  }, [isWindowFocused]);

  useEffect(() => {
    socket.on("messageFromUser", (receivedMessage) => {
      console.log("messge from usewr inside ");
      console.log("Received message from:", receivedMessage);
      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
    });
  }, []);

  const replyToUser = async () => {
    const timestamp = new Date().toISOString();
    let messageContent;

    if (openEditor) {
      const htmlContent = editor.getHTML();
      messageContent = htmlContent;
      editor.commands.setContent("");
    } else {
      messageContent = replyMessage;
    }
    const data = {
      room: `${senderId}.${websiteId}.${ConversationId}`,
      sender: websiteId,
      receiver: senderId,
      conversation_Id: ConversationId,
      message: messageContent,
      timestamps: timestamp,
      meta: { messagetype: "text" },
    };

    socket.emit("replyToUser", data);
    console.log("Reply to User", data);
    setMessages((prevMessages) => [...prevMessages, data]);
    setReplyMessage("");
  };

  useEffect(() => {
    socket.on("disconnect", () => {
      console.log("admin Socket disconnected");
    });
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  const handleAttachFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    sendFile(file);
  };

  const sendFile = async (file) => {
    const formData = new FormData();
    formData.append("message", file);
    formData.append("sender", senderId);
    formData.append("receiver", websiteId);
    formData.append("conversation_Id", ConversationId);

    const response = await axios
      .post(
        `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/documents`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(({ data }) => {
        console.log("data--------------------", data);
        const fileMessage = {
          room: `${senderId}.${websiteId}.${ConversationId}`,
          message: "",
          meta: {
            mimeType: data.mimeType,
            orginalFilename: data.orginalFilename,
            fileName: data.fileName,
            messagetype: "attachment",
          },

          sender: websiteId,
          receiver: senderId,
          conversation_Id: ConversationId,
          timestamps: new Date().toISOString(),
        };
        console.log("Sending file message to server:", fileMessage);

        setMessages((prevMessages) => [...prevMessages, fileMessage]);
        socket.emit("replyToUser", fileMessage);
        setReplyMessage("");
        console.log("File message sent to server via socket.");
      });
  };

  useEffect(() => {
    const messageContainer = messageContainerRef.current;
    if (messageContainer) {
      messageContainer.scrollTop = messageContainer.scrollHeight;
    }
  }, [messages]);

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     event.preventDefault();
  //     replyToUser();
  //   }
  // };

  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  const toggleStrike = useCallback(() => {
    editor.chain().focus().toggleStrike().run();
  }, [editor]);

  const toggleCode = useCallback(() => {
    editor.chain().focus().toggleCode().run();
  }, [editor]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 50px)",
        maxWidth: "auto",
        width: "700px",
      }}
    >
      {/* Resolved text */}
      <Box sx={{ backgroundColor: "#D9D9D966", paddingTop: "4px" }}>
        <Stack
          spacing={2}
          padding={2}
          width="100%"
          alignItems="flex-start"
          height="100%"
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              fontSize: "14px",
              fontFamily: "Inter !important",
              fontWeight: 400,
            }}
          >
            This Conversation has been Resolved
          </Typography>
        </Stack>
      </Box>

      <Box
        id="message-container"
        ref={messageContainerRef}
        sx={{
          flex: 1,
          overflowY: "auto",
          backgroundColor: "#EFF3F6",
          paddingTop: "19px",
          paddingLeft: "11px",
          paddingRight: "10px",
          position: "relative",
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection:
                message.sender === websiteId ? "row-reverse" : "row",
              alignItems: "flex-end",
              position: "relative",
            }}
          >
            {index === messages.length - 1 &&
              (index === 0 ||
                messages[index - 1].sender !== message.sender) && (
                <Avatar
                  sx={{
                    marginBottom: "8px",
                    width: "30px",
                    height: "30px",
                    alignSelf: "flex-end",
                    marginLeft: "5px",
                  }}
                />
              )}
            <Tooltip
              // title={moment(message.createdAt).format("LT")}
              title={new Date(message.timestamps).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
              arrow={false}
              placement="top-start"
            >
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "8px",
                  maxWidth: "280px",
                  minWidth: "115px",
                  minHeight: "20px",
                  borderRadius: "10px !important",
                  backgroundColor:
                    message.sender === websiteId
                      ? "#ADD8E6"
                      : "rgb(242,243,242)",
                  color: message.sender === websiteId ? "#000" : "#000",
                  position: "relative",
                  boxShadow: "0 1px 2px rgba(0,0,0,.1)",
                  fontSize: "14px",
                  lineHeight: "18px",
                  wordWrap: "break-word",
                  marginLeft: "5px",
                  marginRight: "5px",
                  marginBottom: "7px",
                }}
              >
                {message.meta && message.meta.messagetype === "attachment" ? (
                  <AttachmentMessage
                    message={message}
                    sx={{
                      display: "flex",
                      flexDirection:
                        message.sender === websiteId ? "row-reverse" : "row",
                      alignItems: "flex-end",
                      position: "relative",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      fontSize: "0.8rem",
                      textAlign: "center",
                      paddingLeft: "3px",
                    }}
                    dangerouslySetInnerHTML={{ __html: message.message }}
                  />
                )}
              </Paper>
            </Tooltip>
          </Box>
        ))}
      </Box>

      {openEditor ? (
        <>
          {/* <Editor replyToUser={replyToUser} /> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              bottom: 0,
              backgroundColor: "#fff",
              padding: "5px 10px",
              width: "700px",
              height: "80px",
            }}
          >
            <div style={{ display: "flex", gap: "15px" }}>
              <Tooltip title="Normal Text" arrow>
                <IconButton
                  sx={{ color: "grey", fontSize: "17px",height:"40px",marginTop:"35px" }}
                  onClick={handleOpenEditor}
                >
                  Aa
                </IconButton>
              </Tooltip>
             
              <EditorContent
                editor={editor}
                placeholder="Type your message..."
                autoFocus
                // onKeyDown={(event) => {
                //   if (event.key === "Enter" && !event.shiftKey) {
                //     event.preventDefault(); // Prevent the default behavior of the Enter key
                //     replyToUser(); // Call the function to send the message
                //   }
                // }}
              />
            </div>
          </div>
          <div className="menu">
            <button
              className={classNames("menu-button", {
                "is-active": editor.isActive("bold"),
              })}
              onClick={toggleBold}
            >
              <Icons.Bold />
            </button>
            <button
              className={classNames("menu-button", {
                "is-active": editor.isActive("underline"),
              })}
              onClick={toggleUnderline}
            >
              <Icons.Underline />
            </button>
            <button
              className={classNames("menu-button", {
                "is-active": editor.isActive("italic"),
              })}
              onClick={toggleItalic}
            >
              <Icons.Italic />
            </button>
            <button
              className={classNames("menu-button", {
                "is-active": editor.isActive("strike"),
              })}
              onClick={toggleStrike}
            >
              <Icons.Strikethrough />
            </button>
            <button
              className={classNames("menu-button", {
                "is-active": editor.isActive("code"),
              })}
              onClick={toggleCode}
            >
              <Icons.Code />
            </button>
            <Tooltip title="Attachment" arrow>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />

                <IconButton
                  sx={{
                    color: "grey",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                  onClick={handleAttachFile}
                >
                  <AttachFileIcon sx={{ fontSize: "18px" }} />
                </IconButton>
              </Tooltip>
            <button className="menu-button" onClick={replyToUser} style={{marginLeft:"auto",color:"primary",cursor:"pointer"}}>
              <SendIcon />
            </button>
          </div>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            bottom: 0,
            backgroundColor: "#fff",
            padding: "10px 15px",
            width: "700px",
            height: "55px",
          }}
        >
          <TextField
            id="chatInput"
            type="text"
            placeholder="Type your message..."
            fullWidth
            variant="outlined"
            value={replyMessage}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Activate editor" arrow>
                    <IconButton
                      sx={{ color: "grey", fontSize: "17px" }}
                      onClick={handleOpenEditor}
                    >
                      Aa
                    </IconButton>
                  </Tooltip>

                  <EmojiPicker
                    onSelectEmoji={(emoji) =>
                      setReplyMessage((preMsg) => preMsg + emoji)
                    }
                  />

                  <Tooltip title="Attachment" arrow>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />

                    <IconButton
                      sx={{
                        color: "grey",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                      onClick={handleAttachFile}
                    >
                      <AttachFileIcon sx={{ fontSize: "18px" }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={async () => {
                      if (replyMessage.trim()) {
                        await replyToUser(socketid);
                      }
                    }}
                    disabled={!replyMessage.trim()}
                  >
                    <SendIcon
                      sx={{ cursor: "pointer" }}
                      variant="contained"
                      color="primary"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "0",
                padding: "0px",
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover fieldset": {
                  borderColor: "transparent",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "transparent",
                },
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Messages;

const getFileIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "pdf":
      return <FaFilePdf style={{ width: "20px", height: "20px" }} />;
    case "doc":
    case "docx":
      return <FaFileWord style={{ width: "20px", height: "20px" }} />;
    case "xls":
    case "xlsx":
      return <FaFileExcel style={{ width: "20px", height: "20px" }} />;
    case "ppt":
    case "pptx":
      return <FaFilePowerpoint style={{ width: "20px", height: "20px" }} />;
    case "zip":
    case "rar":
      return <FaFileArchive style={{ width: "20px", height: "20px" }} />;
    case "json":
      return <BsFiletypeJson style={{ width: "20px", height: "20px" }} />;
    default:
      return <FaFileAlt style={{ width: "20px", height: "20px" }} />;
  }
};

const AttachmentMessage = ({ message }) => {
  const fileContainerRef = useRef(null);

  const scrollToBottom = () => {
    if (fileContainerRef.current) {
      fileContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };
  if (!message || !message.meta) {
    return null;
  }
  console.log("message", message.meta.fileName);
  const fileIcon = getFileIcon(message.meta.orginalFilename);

  return (
    <Box
      sx={{
        backgroundColor: "none",
        textDecoration: "none !important",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      {message.meta.mimeType.includes("image") ? (
        <a
          href={`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/file/${message.meta.fileName}`}
        >
          <img
            src={`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/file/${message.meta.fileName}`}
            alt="images"
            style={{ width: "200px", height: "200px" }}
          />
        </a>
      ) : (
        <a
          href={`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/file/${message.meta.fileName}`}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            textDecoration: "none",
            marginRight: "10px",
            color: "#000",
          }}
        >
          {fileIcon}
          {message.meta.orginalFilename}
        </a>
      )}
    </Box>
  );
};

// import React, { useState, useEffect, useRef } from "react";
// import { useParams, useSearchParams } from "react-router-dom";
// import {
//   Box,
//   TextField,
//   Paper,
//   Typography,
//   Stack,
//   Avatar,
//   Tooltip,
//   IconButton,
//   Button,
// } from "@mui/material";
// import InputAdornment from "@mui/material/InputAdornment";
// import SendIcon from "@mui/icons-material/Send";
// import AttachFileIcon from "@mui/icons-material/AttachFile";
// import EmojiPicker from "./components/EmojiPicker";
// import { BsFiletypeJson } from "react-icons/bs";
// import axios from "axios";
// import {
//   FaFilePdf,
//   FaFileWord,
//   FaFileExcel,
//   FaFilePowerpoint,
//   FaFileArchive,
//   FaFileAlt,
// } from "react-icons/fa";
// import useWindowFocus from "use-window-focus";
// import io from "socket.io-client";
// import { SimpleEditor } from "./components/ChatEditor/Editor";
// import ChatEditor from "./components/ChatEditor";

// const socket = io(`${process.env.REACT_APP_CHATAPP_BASEURL}`);

// const Messages = () => {
//   const { websiteId } = useParams();
//   const [params] = useSearchParams();
//   const senderId = params.get("sender");
//   const ConversationId = params.get("conversation");

//   console.log("ConversationId", ConversationId);
//   console.log("senderId", senderId);

//   useEffect(() => {
//     const fetchInitialMessages = async () => {
//       try {
//         const response = await fetch(
//           `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/chatmsg/${senderId}/${websiteId}/${ConversationId}`
//         );
//         if (response.ok) {
//           const data = await response.json();
//           console.log("Initial Messages:", data);
//           setMessages(data);
//         } else {
//           console.error("Failed to fetch initial messages");
//         }
//       } catch (error) {
//         console.error("Error occurred while fetching initial messages:", error);
//       }
//     };

//     fetchInitialMessages();
//   }, [senderId, websiteId, ConversationId]);

//   const [messages, setMessages] = useState([]);
//   const [replyMessage, setReplyMessage] = useState("");
//   const [socketid, setSocketid] = useState("");
//   const messageContainerRef = useRef(null);
//   const isWindowFocused = useWindowFocus();
//   const fileInputRef = useRef(null);
//   const [openEditor, setOpenEditor] = useState(false);

//   const handleOpenEditor = () => {
//     setOpenEditor(!openEditor);
//   };
//   const handleChange = (e) => {
//     setReplyMessage(e.target.value);
//   };

//   useEffect(() => {
//     socket.emit("createRoom", `${senderId}.${websiteId}.${ConversationId}`);
//     console.log("Room created:", `${senderId}.${websiteId}.${ConversationId}`);
//   }, [socket, websiteId, senderId, ConversationId]);

//   useEffect(() => {
//     if (isWindowFocused) {
//       console.log(
//         `Window is focused. Marking messages as read...${ConversationId},${senderId},${websiteId}`
//       );

//       socket.emit("markMessagesAsRead", {
//         conversation_Id: ConversationId,
//         receiver: websiteId,
//         sender: senderId,
//       });
//     }
//   }, [isWindowFocused]);

//   useEffect(() => {
//     socket.on("messageFromUser", (receivedMessage) => {
//       console.log("messge from usewr inside ");
//       console.log("Received message from:", receivedMessage);
//       setMessages((prevMessages) => [...prevMessages, receivedMessage]);
//     });
//   }, []);

//   const replyToUser = async () => {
//     const timestamp = new Date().toISOString();
//     const data = {
//       room: `${senderId}.${websiteId}.${ConversationId}`,
//       sender: websiteId,
//       receiver: senderId,
//       conversation_Id: ConversationId,
//       message: replyMessage,
//       timestamps: timestamp,
//       meta: { messagetype: "text" },

//       // read: isWindowFocused,
//     };
//     socket.emit("replyToUser", data);
//     console.log("Reply to User", data);
//     setMessages((prevMessages) => [...prevMessages, data]);
//     setReplyMessage("");
//   };

//   useEffect(() => {
//     socket.on("disconnect", () => {
//       console.log("admin Socket disconnected");
//     });
//     return () => {
//       socket.off("connect");
//       socket.off("disconnect");
//     };
//   }, []);

//   const handleAttachFile = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     sendFile(file);
//   };

//   const sendFile = async (file) => {
//     const formData = new FormData();
//     formData.append("message", file);
//     formData.append("sender", senderId);
//     formData.append("receiver", websiteId);
//     formData.append("conversation_Id", ConversationId);

//     const response = await axios
//       .post(
//         `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/documents`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       )
//       .then(({ data }) => {
//         console.log("data--------------------", data);
//         const fileMessage = {
//           room: `${senderId}.${websiteId}.${ConversationId}`,
//           message: "",
//           meta: {
//             mimeType: data.mimeType,
//             orginalFilename: data.orginalFilename,
//             fileName: data.fileName,
//             messagetype: "attachment",
//           },

//           sender: websiteId,
//           receiver: senderId,
//           conversation_Id: ConversationId,
//           timestamps: new Date().toISOString(),
//         };
//         console.log("Sending file message to server:", fileMessage);

//         setMessages((prevMessages) => [...prevMessages, fileMessage]);
//         socket.emit("replyToUser", fileMessage);
//         setReplyMessage("");
//         console.log("File message sent to server via socket.");
//       });
//   };

//   useEffect(() => {
//     const messageContainer = messageContainerRef.current;
//     if (messageContainer) {
//       messageContainer.scrollTop = messageContainer.scrollHeight;
//     }
//   }, [messages]);

//   const handleKeyPress = (event) => {
//     if (event.key === "Enter" && !event.shiftKey) {
//       event.preventDefault();
//       replyToUser();
//     }
//   };

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         flexDirection: "column",
//         height: "calc(100vh - 50px)",
//         maxWidth: "auto",
//         width: "700px",
//       }}
//     >
//       {/* Resolved text */}
//       <Box sx={{ backgroundColor: "#D9D9D966", paddingTop: "4px" }}>
//         <Stack
//           spacing={2}
//           padding={2}
//           width="100%"
//           alignItems="flex-start"
//           height="100%"
//         >
//           <Typography
//             sx={{
//               whiteSpace: "nowrap",
//               fontSize: "14px",
//               fontFamily: "Inter !important",
//               fontWeight: 400,
//             }}
//           >
//             This Conversation has been Resolved
//           </Typography>
//         </Stack>
//       </Box>

//       <Box
//         id="message-container"
//         ref={messageContainerRef}
//         sx={{
//           flex: 1,
//           overflowY: "auto",
//           backgroundColor: "#EFF3F6",
//           paddingTop: "19px",
//           paddingLeft: "11px",
//           paddingRight: "10px",
//           position: "relative",
//         }}
//       >
//         {messages.map((message, index) => (
//           <Box
//             key={index}
//             sx={{
//               display: "flex",
//               flexDirection:
//                 message.sender === websiteId ? "row-reverse" : "row",
//               alignItems: "flex-end",
//               position: "relative",
//             }}
//           >
//             {index === messages.length - 1 &&
//               (index === 0 ||
//                 messages[index - 1].sender !== message.sender) && (
//                 <Avatar
//                   sx={{
//                     marginBottom: "8px",
//                     width: "30px",
//                     height: "30px",
//                     alignSelf: "flex-end",
//                     marginLeft: "5px",
//                   }}
//                 />
//               )}
//             <Tooltip
//               // title={moment(message.createdAt).format("LT")}
//               title={new Date(message.timestamps).toLocaleTimeString([], {
//                 hour: "2-digit",
//                 minute: "2-digit",
//               })}
//               arrow={false}
//               placement="top-start"
//             >
//               <Paper
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   padding: "8px",
//                   maxWidth: "280px",
//                   minWidth: "115px",
//                   minHeight: "20px",
//                   borderRadius: "10px !important",
//                   backgroundColor:
//                     message.sender === websiteId
//                       ? "#ADD8E6"
//                       : "rgb(242,243,242)",
//                   color: message.sender === websiteId ? "#000" : "#000",
//                   position: "relative",
//                   boxShadow: "0 1px 2px rgba(0,0,0,.1)",
//                   fontSize: "14px",
//                   lineHeight: "18px",
//                   wordWrap: "break-word",
//                   marginLeft: "5px",
//                   marginRight: "5px",
//                   marginBottom: "7px",
//                 }}
//               >
//                 {message.meta && message.meta.messagetype === "attachment" ? (
//                   <AttachmentMessage
//                     message={message}
//                     sx={{
//                       display: "flex",
//                       flexDirection:
//                         message.sender === websiteId ? "row-reverse" : "row",
//                       alignItems: "flex-end",
//                       position: "relative",
//                     }}
//                   />
//                 ) : (
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       fontSize: "0.8rem",
//                       textAlign: "center",
//                       paddingTop: "3px",
//                       paddingLeft: "3px",
//                     }}
//                   >
//                     {message.message}
//                   </Typography>
//                 )}
//               </Paper>
//             </Tooltip>
//           </Box>
//         ))}
//       </Box>

//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           overflow: "hidden",
//           bottom: 0,
//           backgroundColor: "#fff",
//           padding: "10px 15px",
//           width: "715 px",
//           height: "59px",
//         }}
//       >
//         <TextField
//           id="chatInput"
//           type="text"
//           placeholder="Type your message..."
//           fullWidth
//           variant="outlined"
//           value={replyMessage}
//           onChange={handleChange}
//           onKeyDown={handleKeyPress}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <Tooltip title="Activate editor" arrow>
//                   <IconButton
//                     sx={{ color: "grey", fontSize: "17px" }}
//                     onClick={handleOpenEditor}
//                   >
//                     Aa
//                   </IconButton>
//                 </Tooltip>

//                 <EmojiPicker
//                   onSelectEmoji={(emoji) =>
//                     setReplyMessage((preMsg) => preMsg + emoji)
//                   }
//                 />

//                 <Tooltip title="Attachment" arrow>
//                   <input
//                     type="file"
//                     ref={fileInputRef}
//                     style={{ display: "none" }}
//                     onChange={handleFileChange}
//                   />

//                   <IconButton
//                     sx={{
//                       color: "grey",
//                       display: "flex",
//                       alignItems: "flex-start",
//                       justifyContent: "flex-start",
//                     }}
//                     onClick={handleAttachFile}
//                   >
//                     <AttachFileIcon sx={{ fontSize: "18px" }} />
//                   </IconButton>
//                 </Tooltip>
//               </InputAdornment>
//             ),
//             endAdornment: (
//               <InputAdornment position="end">
//                 <IconButton
//                   onClick={async () => {
//                     if (replyMessage.trim()) {
//                       await replyToUser(socketid);
//                     }
//                   }}
//                   disabled={!replyMessage.trim()}
//                 >
//                   <SendIcon
//                     sx={{ cursor: "pointer" }}
//                     variant="contained"
//                     color="primary"
//                   />
//                 </IconButton>
//               </InputAdornment>
//             ),
//           }}
//           sx={{
//             "& .MuiOutlinedInput-root": {
//               borderRadius: "0",
//               padding: "0px",
//               "& fieldset": {
//                 borderColor: "transparent",
//               },
//               "&:hover fieldset": {
//                 borderColor: "transparent",
//               },
//               "&.Mui-focused fieldset": {
//                 borderColor: "transparent",
//               },
//             },
//           }}
//         />
//     </Box>
//     <Box sx={{height:"60px"}}>{openEditor && (<Box></Box>)}</Box>
//     </Box>
//   );
// };

// export default Messages;

// const getFileIcon = (fileName) => {
//   const extension = fileName.split(".").pop().toLowerCase();
//   switch (extension) {
//     case "pdf":
//       return <FaFilePdf style={{ width: "20px", height: "20px" }} />;
//     case "doc":
//     case "docx":
//       return <FaFileWord style={{ width: "20px", height: "20px" }} />;
//     case "xls":
//     case "xlsx":
//       return <FaFileExcel style={{ width: "20px", height: "20px" }} />;
//     case "ppt":
//     case "pptx":
//       return <FaFilePowerpoint style={{ width: "20px", height: "20px" }} />;
//     case "zip":
//     case "rar":
//       return <FaFileArchive style={{ width: "20px", height: "20px" }} />;
//     case "json":
//       return <BsFiletypeJson style={{ width: "20px", height: "20px" }} />;
//     default:
//       return <FaFileAlt style={{ width: "20px", height: "20px" }} />;
//   }
// };

// const AttachmentMessage = ({ message }) => {
//   const fileContainerRef = useRef(null);

//   const scrollToBottom = () => {
//     if (fileContainerRef.current) {
//       fileContainerRef.current.scrollIntoView({
//         behavior: "smooth",
//         block: "end",
//       });
//     }
//   };
//   if (!message || !message.meta) {
//     return null;
//   }
//   console.log("message", message.meta.fileName);
//   const fileIcon = getFileIcon(message.meta.orginalFilename);

//   return (
//     <Box
//       sx={{
//         backgroundColor: "none",
//         textDecoration: "none !important",
//         display: "flex",
//         justifyContent: "flex-end",
//       }}
//     >
//       {message.meta.mimeType.includes("image") ? (
//         <a
//           href={`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/file/${message.meta.fileName}`}
//         >
//           <img
//             src={`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/file/${message.meta.fileName}`}
//             alt="images"
//             style={{ width: "200px", height: "200px" }}
//           />
//         </a>
//       ) : (
//         <a
//           href={`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/msg/file/${message.meta.fileName}`}
//           style={{
//             display: "flex",
//             justifyContent: "space-evenly",
//             textDecoration: "none",
//             marginRight: "10px",
//             color: "#000",
//           }}
//         >
//           {fileIcon}
//           {message.meta.orginalFilename}
//         </a>
//       )}
//     </Box>
//   );
// };
