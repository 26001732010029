import React from 'react'
import { getCoreRowModel, useReactTable, } from '@tanstack/react-table'
import { useInfiniteQuery, useQuery, useQueryClient, } from '@tanstack/react-query'
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import variables from "../../../../assets/styles/_colors.scss";
import { useReducer } from 'react';
import { CrmTableHeader } from '../../../../components/table/CrmTableHeader';
import { getTransitionRules } from '../../../../webservices/StageService';
import { CrmTableFooter } from '../../../../components/table/CrmTableFooter';
import "./transitionrules.css";
import TransitionRulesTableRow from './TransitionRulesTableRow';

const fetchSize = 10;

const TransitionRulesTable = (props) => {

    const queryClient = useQueryClient();
    const rerender = useReducer(() => ({}), {})[1];

    const tableContainerRef = useRef(null);
    const [columnVisibility, setColumnVisibility] = useState({});

    const columns = useMemo(
        () => [
            {
                "id": "1",
                "accessorKey": "fromStage.stageName",
                "header": "From Stage",
                enableSorting: false
            },
            {
                "id": "2",
                "accessorKey": "toStage.stageName",
                "header": "To Stage",
                enableSorting: false
            },
            {
                "id": "3",
                "accessorKey": "informationToBeFilled",
                "header": "Information To Be Filled",
                enableSorting: false
            }],
        []
    );

    const { data, fetchNextPage, isFetching } =
        useInfiniteQuery({
            queryKey: [`getTransitionRules`, props.teamPipelineId, props.subPipelineId, fetchSize, props.sortByAsc],
            queryFn: async ({ pageParam = 0 }) => {
                const start = pageParam * fetchSize
                return getTransitionRules(props.teamPipelineId, props.subPipelineId, start, fetchSize, props.sortByAsc);
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            placeholderData: {},
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages?.flatMap(page => page.rows) ?? [],
        [data]
    )
    const meta = data?.pages?.[0]?.meta ?? 0
    const totalFetched = flatData.length;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement
                if (
                    scrollHeight - scrollTop - clientHeight < 100 &&
                    !isFetching &&
                    totalFetched < meta.totalRowCount
                ) {
                    fetchNextPage()
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, meta.totalRowCount]
    )

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached])

    const table = useReactTable({
        data: flatData,
        columns: columns,
        state: {
            columnVisibility
        },
        getCoreRowModel: getCoreRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        // debugTable: true,
    })

    return (
        <Box sx={{
            overflow: 'hidden', width: "100%", color: "#000",
            bgcolor: `${variables.tabsBackgroundColor}`
        }}>
            <Box
                sx={{ overflow: 'auto', height: "55vh", width: "100%", color: "#000" }}
                onScroll={e => fetchMoreOnBottomReached(e.target)}
                ref={tableContainerRef}
            >
                <table id="stageTransitionRuleTable">
                    <thead>
                        <CrmTableHeader table={table} />
                    </thead>
                    <tbody>
                        {
                            !isFetching && table.getRowModel().rows?.length > 0 && table.getRowModel().rows.map((rowEl) => {
                                return (
                                    <TransitionRulesTableRow
                                        key={rowEl.original?.id}
                                        rowEl={rowEl}
                                        teamPipelineId={props.teamPipelineId}
                                        subPipelineId={props.subPipelineId}
                                        showSnackbar={(data) => props.showSnackbar(data)} />
                                )
                            })}
                    </tbody>
                </table>
                {
                    isFetching &&
                    <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="50px">
                        <Typography>Loading ...</Typography>
                    </Stack>
                }
                {
                    !isFetching && table.getRowModel().rows?.length === 0 &&
                    <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="50px">
                        <Typography>No data found</Typography>
                    </Stack>
                }
            </Box>
            <Stack direction="row" justifyContent="center" sx={{
                maxHeight: 70, position: "absolute", bottom: 2, width: '80vw',
                padding: "12px 20px", background: `${variables.lightGreyBackgroundColor}`
            }}>

                <CrmTableFooter table={table} meta={meta} data={flatData} />

            </Stack>
        </Box >
    )
}

export default TransitionRulesTable;