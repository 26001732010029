import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Grid, Box, Modal } from "@mui/material";
import { Country } from "country-state-city";
import Header from "./Components/Header";
import CustomCell from "./Components/CustomCell";
import Footer from "../../../Component/Footer";
import { listTimeZones } from "timezone-support";
import { useRecoilValue } from "recoil";
import { login_atoms } from "../../../../../Atoms/LoginAtoms";

import CustomSnackBar from "../../../../SnackBar/CustomSnackBar";
import { FetchWorkSpaceApi } from "../../../CustomHooks/GetCompanyApi";
import useWorkSpaceStore from "../../../../../GlobalState/WorkSpaceState";
import axios from "axios";
import { api_url1 } from "../../../../../globalSettings";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90%",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};

const CreateModule = ({ open, handleClickClose }) => {
  const setWorkSpaces = useWorkSpaceStore((state) => state.setWorkSpace);
  const validationSchema = Yup.object().shape({
    workspace_name: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter Company name"),

    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter Email"),

    phone_number: Yup.string().required("Please Enter The phonenumber"),

    address: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(100, "Maximum 100 characters")
      .required("Please enter Address"),
  });
  const loginAtom1 = useRecoilValue(login_atoms);
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const formik = useFormik({
    initialValues: {
      workspace_name: "",
      email: "",
      phone_number: "",
      gst_no: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
      timezone: "",
      contact_person: "",
      address: "",
      weekstart: "",
      company_logo: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      const params = { ...values };

      const formdata = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        formdata.append(key, value);
      });
      formdata.append("user_id", loginAtom1);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      try {
        const CreateWorkSpace = axios.post(
          `${api_url1}/workspace/add_workspace`,
          formdata
        );

        handleClickClose();
        resetForm();
        setSnackbaropen({
          open: true,
          message: "Company Created!!",
          severity: "success",
        });
        const workSpaceArray = await FetchWorkSpaceApi();
        setWorkSpaces(workSpaceArray);
      } catch (err) {
        console.log(err);
        console.log("errmsg", err.response.data.error.message);
        setSnackbaropen({
          open: true,
          message: err.response.data.error.message,
          severity: "error",
          actionColor: "#edf7ed",
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const weekArray = [
    { id: "Mondayy", key: "Mon" },
    { id: "Tuesdayy", key: "Tue" },
    { id: "Wednesdayy", key: "Wed" },
    { id: "Thursdayy", key: "Thu" },
    { id: "Fridayy", key: "Fri" },
    { id: "Saturdayy", key: "Sat" },
    { id: "Sundayy", key: "Sun" },
  ];
  const timeZoneArray = listTimeZones();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("company_logo", file.name);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          p: "0.5rem",
          flexWrap: "wrap",
          padding: "0px",
        }}
      >
        <Modal
          open={open}
          onClose={handleClickClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="scroll">
            <Header handleClickClose={handleClickClose} />
            <form onSubmit={formik.handleSubmit}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  maxWidth: "100%",
                }}
              >
                <Grid container spacing={4} sx={{ padding: "20px" }}>
                  <CustomCell
                    fieldVal={formik.values.workspace_name}
                    handleChange={formik.handleChange}
                    err={
                      formik.touched.workspace_name &&
                      !!formik.errors.workspace_name
                    }
                    helper={
                      formik.touched.workspace_name &&
                      formik.errors.workspace_name
                    }
                    fieldName={"workspace_name"}
                    cellName={"Workspace Name"}
                    isRequired={true}
                    isMultiline={false}
                    isDownload={false}
                  />
                  <CustomCell
                    fieldVal={formik.values.email}
                    handleChange={formik.handleChange}
                    err={formik.touched.email && !!formik.errors.email}
                    helper={formik.touched.email && formik.errors.email}
                    fieldName={"email"}
                    cellName={"Email"}
                    isRequired={true}
                    isMultiline={false}
                    isDownload={false}
                  />
                  <CustomCell
                    fieldVal={formik.values.phone_number}
                    handleChange={formik.handleChange}
                    err={
                      formik.touched.phone_number &&
                      !!formik.errors.phone_number
                    }
                    helper={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                    fieldName={"phone_number"}
                    cellName={"Phone Number"}
                    isRequired={true}
                    isMultiline={false}
                    isDownload={false}
                  />
                  <CustomCell
                    fieldVal={formik.values.gst_no}
                    handleChange={formik.handleChange}
                    err={formik.touched.gst_no && !!formik.errors.gst_no}
                    helper={formik.touched.gst_no && formik.errors.gst_no}
                    fieldName={"gst_no"}
                    cellName={"Gst No"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                  />
                  <CustomCell
                    fieldVal={formik.values.country}
                    handleChange={formik.handleChange}
                    err={formik.touched.country && !!formik.errors.country}
                    helper={formik.touched.country && formik.errors.country}
                    fieldName={"country"}
                    cellName={"Country"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                    isSelect={true}
                    array={Country.getAllCountries()}
                    valueParam={"name"}
                    keyParam={"phonecode"}
                  />

                  <CustomCell
                    fieldVal={formik.values.state}
                    handleChange={formik.handleChange}
                    err={formik.touched.state && !!formik.errors.state}
                    helper={formik.touched.state && formik.errors.state}
                    fieldName={"state"}
                    cellName={"State"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                  />
                  <CustomCell
                    fieldVal={formik.values.city}
                    handleChange={formik.handleChange}
                    err={formik.touched.city && !!formik.errors.city}
                    helper={formik.touched.city && formik.errors.city}
                    fieldName={"city"}
                    cellName={"City"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                  />

                  <CustomCell
                    fieldVal={formik.values.zip_code}
                    handleChange={formik.handleChange}
                    err={formik.touched.zip_code && !!formik.errors.zip_code}
                    helper={formik.touched.zip_code && formik.errors.zip_code}
                    fieldName={"zip_code"}
                    cellName={"Zip Code"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                  />

                  <CustomCell
                    fieldVal={formik.values.timezone}
                    handleChange={formik.handleChange}
                    err={formik.touched.timezone && !!formik.errors.timezone}
                    helper={formik.touched.timezone && formik.errors.timezone}
                    fieldName={"timezone"}
                    cellName={"TimeZone"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                    isSelect={true}
                    array={timeZoneArray}
                  />
                  <CustomCell
                    fieldVal={formik.values.contact_person}
                    handleChange={formik.handleChange}
                    err={
                      formik.touched.contact_person &&
                      !!formik.errors.contact_person
                    }
                    helper={
                      formik.touched.contact_person &&
                      formik.errors.contact_person
                    }
                    fieldName={"contact_person"}
                    cellName={"Contact Person"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                  />
                  <CustomCell
                    fieldVal={formik.values.address}
                    handleChange={formik.handleChange}
                    err={formik.touched.address && !!formik.errors.address}
                    helper={formik.touched.address && formik.errors.address}
                    fieldName={"address"}
                    cellName={"Address"}
                    isRequired={true}
                    isMultiline={true}
                    isDownload={false}
                  />
                  <CustomCell
                    fieldVal={formik.values.weekstart}
                    handleChange={formik.handleChange}
                    err={formik.touched.weekstart && !!formik.errors.weekstart}
                    helper={formik.touched.weekstart && formik.errors.weekstart}
                    fieldName={"weekstart"}
                    cellName={"Week Start"}
                    isRequired={false}
                    isMultiline={false}
                    isDownload={false}
                    isSelect={true}
                    array={weekArray}
                    valueParam={"key"}
                    keyParam={"id"}
                  />
                  <CustomCell
                    fieldVal={formik.values.company_logo}
                    handleChange={handleFileChange}
                    err={
                      formik.touched.company_logo &&
                      !!formik.errors.company_logo
                    }
                    helper={
                      formik.touched.company_logo && formik.errors.company_logo
                    }
                    fieldName={"company_logo"}
                    cellName={"Logo"}
                    isRequired={false}
                    isMultiline={true}
                    isDownload={true}
                  />
                </Grid>
                <Footer
                  handleClose={handleClickClose}
                  handleSubmit={formik.handleSubmit}
                  loading={formik.isSubmitting}
                  SuccessTitle={"Submit"}
                  background={"#F5F5F8"}
                />
              </div>
            </form>
          </Box>
        </Modal>
      </Box>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="right"
      />
    </>
  );
};

export default CreateModule;
