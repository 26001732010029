import { Autocomplete, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, TextField } from "@mui/material";
import { CloseButton } from "../../../../components/CloseButton";
import { useState } from "react";

const tags = [
    {
        label: "new",
        value: "new"
    },
    {
        label: "old",
        value: "old"
    },
    {
        label: "important",
        value: "important"
    },
    {
        label: "hot",
        value: "hot"
    }
]
const UpdateTags = (props) => {

    const [open, setOpen] = useState(true);
    const [replaceExistingOptions, setReplaceExistingOptions] = useState(false);

    function handleDialogClose(type) {
        setOpen(false);
        props.handleDialogClose(type);
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="update-tags-dialog"
                PaperProps={{
                    sx: { minWidth: '40%', maxWidth: '40%', overflow: 'hidden' }
                }}>

                <DialogTitle id="update-tags-dialog-title">
                    {props.add ? "Add Tags" : "Remove Tags"}
                    <CloseButton handleDialogClose={handleDialogClose} />
                </DialogTitle>

                <DialogContent sx={{ pb: 8 }}>
                    <Autocomplete
                        sx={{ pt: 1 }}
                        multiple
                        id="tags-filled"
                        options={tags}
                        freeSolo
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option.label ? option.label : option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tags"
                                placeholder="Enter Tag Name"
                            />
                        )}
                    />
                    {props.add &&
                        <FormControlLabel
                            sx={{ pt: 1 }}
                            control={<Checkbox checked={replaceExistingOptions} onChange={() => setReplaceExistingOptions(!replaceExistingOptions)} />}
                            label="Overwrite any existing Tags in the selected records with the above Tag(s)."
                        />
                    }
                </DialogContent>

                <DialogActions>
                    <Stack direction="row" spacing={2} justifyContent="right" sx={{ maxHeight: 70, position: "absolute", bottom: 0, right: 0, padding: "20px 24px", background: "#fff" }}>
                        <Button variant="outlined" onClick={handleDialogClose}> Cancel  </Button>
                        <Button variant="contained" color="primary" onClick={handleDialogClose}> Save</Button>
                    </Stack>
                </DialogActions>

            </Dialog>
        </>
    )

}

export default UpdateTags;