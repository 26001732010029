import { useState } from "react";
import { Table, TableHead, TableBody, TableCell, TableRow, Dialog, Paper, Typography, TextField, DialogActions, Stack, Box, Grid, InputLabel, FormControl, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RedAsterisk from "../../../../components/RedAsterisk";
import Button from '../../../../components/Button'

const schema = yup
  .object({
    expense_name: yup
      .string()
      .min(3, "Please Enter Minimum 3 characters")
      .max(50, "Please Enter Maximum 50 characters")
      .required("Please Enter Expense Name"),
  })
  .required();

function Expense() {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    // const [expenses, setExpenses] = useState([]);

    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm({
      resolver: yupResolver(schema),
    });
  
    const handleAddExpenseClick = () => {
      setIsDialogOpen(true);
    };
  
    const handleDialogClose = () => {
      setIsDialogOpen(false);
    };
  const onSubmit = (data) => {
    console.log("Expense:", data);
    reset();
  };

    return (
        <div style={{
            height: "65vh"
        }}>
          <div style={{
            // overflowY: "auto",
            // border: "1px solid red",
            // height: "61.8vh"
          }}>
            <div>
          <div style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end"
          }}>
            <Button style={{
              background: "rgba(0, 10, 255, 1)",
              color: "white",
              marginTop: "2%",
              marginRight: "2%"
            }} onClick={handleAddExpenseClick}>
              Add Expense
            </Button>
          </div>
          <div style={{
            // border: "1px solid red",
            width: "100%",
            // overflow: "auto"
          }}>
            <Paper style={{
              width: "100%",
              marginTop: "2%",
              // border: "1px solid blue",
              overflowX: 'auto'
            }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{
                        fontWeight: "bold"
                    }}>
                      Expense Name
                    </TableCell>
                    <TableCell style={{
                        fontWeight: "bold"
                    }}>
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* {expenses.map((expense, index) => (
                    <TableRow key={index} style={{
                     
                    }}>
                      <TableCell>{expense.name}</TableCell>
                      <TableCell style={{
                        wordWrap: "break-word" // Add this line for text wrapping
                      }} >{expense.description}</TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </Paper>
            <Dialog
              open={isDialogOpen}
              onClose={handleDialogClose}
              maxWidth="xs"
              fullWidth
            >
              <Box
              style={{
                background: "rgba(0, 10, 255, 1)",
                padding: "10px 10px 10px 25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                }}
              >
                Expense
              </Typography>
              <CloseIcon
                onClick={handleDialogClose}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20px",
                }}
              />
            </Box>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputLabel sx={{color:"black"}}>
                    Expense Name <RedAsterisk />
                    </InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        {...register("expense_name")}
                        error={!!errors.expense_name}
                        helperText={errors.expense_name?.message}
                        variant="outlined"
                        placeholder="Expense Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{color:"black"}}>Description</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        {...register("description")}
                        placeholder="Description"
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    style={{
                      background: "rgba(0, 0, 0, 0)",
                      borderRadius: "5px",
                      color: "Black",
                      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                      marginRight: "20px",
                    }}
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      background: "rgba(0, 10, 255, 1)",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Add
                  </Button>
                </Box>
              </form>
            </DialogContent>
            </Dialog>
          </div>
        </div>
    
          </div>
          
          
        </div>
      );
    }

export default Expense

