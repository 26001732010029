import React, { useRef, useState } from "react";
import {
  Avatar,
  Stack,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Modal,
  Button,
  TextField,
  Divider,
  styled,
  Snackbar,
  Alert,
} from "@mui/material";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FlagIcon } from "react-flag-kit";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    width: "300px",
    display: "flex",
    justifyContent: "center",
  },
});

const HeaderComponent = () => {
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [hovered, setHovered] = useState(false);
  const [emailHovered, setEmailHovered] = useState(false);
  const [editNameHovered, setEditNameHovered] = useState(false);
  const [copyNameHovered, setCopyNameHovered] = useState(false);
  const [editEmailHovered, setEditEmailHovered] = useState(false);
  const [newName, setNewName] = useState("");
  const [nameModalOpen, setNameModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
const navigate = useNavigate();
const nameRef = useRef(null);

  const handleEditName = () => {
    setNameModalOpen(true);
  };

  const handleCloseNameModal = () => {
    setNameModalOpen(false);
    setNewName("");
  };

  const handleEditEmail = () => {
    setEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
    setNewEmail("");
  };

  const handleContactInfo = () => {
    navigate("/chat/contacts/1");
  };

  const handleCopyName = () => {
    if (nameRef.current) {
      navigator.clipboard.writeText(nameRef.current.textContent);
      setAlertOpen(true);
      setTimeout(() => {
        setAlertOpen(false);
      }, 3000);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ paddingLeft: "25px",paddingTop:"10px" }}>
        <Box sx={{ position: "relative" }}>
          <Avatar
            sx={{
              width: 60,
              height: 60,
              borderRadius: "100px",
              backgroundPosition: "center center",
              backgroundSize: " 120%",
              backgroundColor: "#FF6AC2",
            }}
          >
            <PersonIcon style={{ width: 35, height: 35 }} />
          </Avatar>
          <Box
            sx={{
              position: "absolute",
              bottom: "8px",
              left: "48px",
            }}
          >
            <FlagIcon
              code={selectedCountry}
              style={{
                width: "25px",
                height: "25px",
                borderColor: "#fff",
                borderStyle: "solid",
                borderWidth: "3px",
                borderRadius: "50px",
              }}
            />
          </Box>
        </Box>

        <Stack sx={{ marginLeft: "20px", width: "200px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => {
              setHovered(false);
              setEditNameHovered(false);
              setCopyNameHovered(false);
            }}
          >
            <Typography
            ref={nameRef}
              sx={{
                fontFamily: "Inter",
                fontWeight: 600,
                marginRight: "auto",
                fontSize:"16px",
              }}
            >
              name
            </Typography>
            {hovered && (
              <>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    sx={{ cursor: "pointer" }}
                    onMouseEnter={() => setEditNameHovered(true)}
                    onMouseLeave={() => setEditNameHovered(false)}
                    onClick={handleEditName}
                  >
                    <EditSharpIcon sx={{ width: "12px", height: "12px" }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Copy" arrow>
                  <IconButton
                    sx={{ cursor: "pointer" }}
                    onClick={handleCopyName}
                    onMouseEnter={() => setCopyNameHovered(true)}
                    onMouseLeave={() => setCopyNameHovered(false)}
                  >
                    <ContentCopyIcon sx={{ width: "12px", height: "12px" }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Box>
          <Stack direction="row" alignItems="center">
            <Box
              sx={{ display: "flex", alignItems: "center", lineHeight: "18px" }}
              onMouseEnter={() => setEmailHovered(true)}
              onMouseLeave={() => {
                setEmailHovered(false);
                setEditEmailHovered(false);
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Inter",
                  marginRight: "10px",
                }}
              >
                Set Email
              </Typography>
              {emailHovered && (
                <>
                  <Tooltip title="Edit">
                    <IconButton
                      sx={{ cursor: "pointer" }}
                      onMouseEnter={() => setEditEmailHovered(true)}
                      onMouseLeave={() => setEditEmailHovered(false)}
                      onClick={handleEditEmail}
                    >
                      <EditSharpIcon
                        sx={{ width: "12px", height: "12px", right: 0 }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body2">city</Typography>
          </Stack>
        </Stack>

        <Modal open={nameModalOpen} onClose={handleCloseNameModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid grey",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography>Set a new nickname</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              fullWidth
              placeholder="Enter a new nickname...(eg. John Doe)"
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <Box>
              <>
                <Button
                  onClick={handleCloseNameModal}
                  sx={{
                    marginLeft: "140px",
                    color: "black",
                    border: "1px solid grey",
                    backgroundColor: "white",
                    textTransform: "capitalize",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </Button>
              </>
              <>
                <Button
                  onClick={handleCloseNameModal}
                  sx={{
                    marginLeft: "10px",
                    color: "white",
                    border: "1px solid grey",
                    backgroundColor: "#0093ee",
                    textTransform: "capitalize",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: "#0093ee",
                    },
                  }}
                >
                  Set Nickname
                </Button>
              </>
            </Box>
          </Box>
        </Modal>

        <Modal open={emailModalOpen} onClose={handleCloseEmailModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid grey",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography>Set a new email address</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              fullWidth
              placeholder="Enter a new email address..."
              type="text"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <Box>
              <>
                <Button
                  onClick={handleCloseEmailModal}
                  sx={{
                    marginLeft: "140px",
                    color: "black",
                    border: "1px solid grey",
                    backgroundColor: "white",
                    textTransform: "capitalize",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </Button>
              </>
              <>
                <Button
                  onClick={handleCloseEmailModal}
                  sx={{
                    marginLeft: "10px",
                    color: "white",
                    border: "1px solid grey",
                    backgroundColor: "#0093ee",
                    textTransform: "capitalize",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: "#0093ee",
                    },
                  }}
                >
                  Set Email
                </Button>
              </>
            </Box>
          </Box>
        </Modal>
        <Divider />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          display: "flex",
          width: "300px",
          marginTop: "10px",
          marginBottom: "10px",
          backgroundColor: "#0093ee",
        }}
      >
        <Button onClick={handleContactInfo}>
          <PersonIcon
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              color: "#fff",
            }}
          />
          <Typography
            sx={{
              marginLeft: "30px",
              textTransform: "capitalize",
              fontSize: 14,
              fontFamily: "Inter",
              color: "#fff",
            }}
          >
            View Santhiya Profile
          </Typography>
        </Button>
      </Stack>
      <Divider sx={{ borderBottom: "1px solid grey" }} />
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {" "}
        Name Copied
        </Alert>
      </Snackbar>
    </>
  );
};

export default HeaderComponent;
