export const pipelineFilters = [
    {
        label: "Touched Records",
        type: "touchedRecords"
    },
    {
        label: "Untouched Records",
        type: "untouchedRecords"
    },
    {
        label: "Record Action",
        type: "recordAction"
    },
    {
        label: "Related Record Action",
        type: "relatedRecordAction"
    },
    {
        label: "Activities",
        type: "activities"
    },
    {
        label: "Notes",
        type: "notes"
    },
    {
        label: "Sub-Pipeline",
        value: "sub_pipeline",
        type: 'lookup',
        lookup: {
            defaultValue: 'contacts',
            relatedListName: 'Pipelines',
            options: [
                {
                    label: "Sub-Pipeline 1",
                    value: "1"
                },
                {
                    label: "Sub-Pipeline 2",
                    value: "2"
                },
                {
                    label: "Sub-Pipeline 3",
                    value: "3"
                }
            ]
        },
    },
    {
        label: "Stage",
        value: "stage",
        type: 'lookup',
        lookup: {
            options: [
                {
                    label: "Stage 1",
                    value: "1"
                },
                {
                    label: "Stage 2",
                    value: "2"
                }
            ]
        },
    },
    {
        label: "Email",
        value: "email",
        type: "text"
    },
    {
        label: "isProductActive",
        value: "is_product_active",
        type: "checkbox"
    },
    {
        label: "Amount",
        value: "amount",
        type: "currency"
    },
    {
        label: "Number",
        value: "number",
        type: "number"
    },
    {
        label: "Date/Time Picker",
        value: "datePicker",
        type: "datePicker"
    }
];

export const taskFilters = [
    {
        label: "Touched Records",
        type: "touchedRecords"
    },
    {
        label: "Untouched Records",
        type: "untouchedRecords"
    },
    {
        label: "Record Action",
        type: "recordAction"
    },
    {
        label: "Related Record Action",
        type: "relatedRecordAction"
    },
    {
        label: "Closed Time",
        type: "datePicker"
    },
    {
        label: "Created By",
        type: "text"
    },
    {
        label: "Created Time",
        type: "datePicker"
    },
    {
        label: "Description",
        type: "text"
    },
    {
        label: "Due Date",
        type: "datePicker"
    },
];

export const eventFilters = [
    {
        label: "Touched Records",
        type: "touchedRecords"
    },
    {
        label: "Untouched Records",
        type: "untouchedRecords"
    },
    {
        label: "Record Action",
        type: "recordAction"
    },
    {
        label: "Related Record Action",
        type: "relatedRecordAction"
    }
];

export const callFilters = [
    {
        label: "Touched Records",
        type: "touchedRecords"
    },
    {
        label: "Untouched Records",
        type: "untouchedRecords"
    },
    {
        label: "Record Action",
        type: "recordAction"
    },
    {
        label: "Related Record Action",
        type: "relatedRecordAction"
    }
];