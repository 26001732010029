import React from "react";
import refresh from "../../../images/DomainDetails/Refresh.png";
import Style from "../Style/ErrorInfo.module.css";
const ErrorInfoo = ({error,reason,status}) => {
  return (
    <div className={Style.container}>
    <div className={Style.inner_container}>
      <div className={Style.img_margin}>
        <img src={refresh} alt="action pending" />
      </div>
      <div>
        <div>
          <p className={Style.text_style}>
        {error}
          </p>
        </div>
        <div>
          <p className={Style.text_style}>{reason}</p>
        </div>
      </div>
    </div>
  </div>
  );
};

export default ErrorInfoo;
