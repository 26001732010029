import { Box, Stack, Typography } from "@mui/material"
import { capitalizeFirstLetter } from "../../../../utils/PipelineConstants";
import Tags from "../../../../components/Tags";

export const ActivityInfo = (props) => {

    const fields = {
        "description": "Test description",
        "basicInfo": [{
            label: "Due Date",
            value: "Feb 09, 2024"
        },
        {
            label: "Priority",
            value: "Normal"
        },
        {
            label: "Status",
            value: "In Progress"
        },
        {
            label: "Reminder",
            value: null
        },
        {
            label: "Description",
            value: null
        },
        {
            label: "Related To",
            value: "Test Deal 2"
        },
        {
            label: "Last Modified",
            value: "akshatkanu k on Feb 16, 11:38 AM"
        }],
        "otherInfo": [{
            label: "Additional Field",
            value: "Holaaaaa"
        }]
    };

    return (
        <Box sx={{ pl: 2, pt: 2 }}>
            {fields["basicInfo"]?.length > 0 &&
                <>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1 }}>{capitalizeFirstLetter(props.module)} Details</Typography>
                    {fields["basicInfo"].map((field, index) => {
                        return <Stack direction="row" sx={{ pb: 1 }} key={index}>
                            <Typography width="200px">{field.label}</Typography>
                            <Typography width="60%">{field.value ? field.value : '--'}</Typography>
                        </Stack>
                    })}
                </>
            }
            <Tags />

            {
                (props.module === "task" || props.module === "pipeline") && <>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 2 }}>Other Info</Typography>
                    {fields["otherInfo"].map((field, index) => {
                        return <Stack direction="row" sx={{ pb: 1 }} key={index}>
                            <Typography width="200px">{field.label}</Typography>
                            <Typography width="60%">{field.value ? field.value : '--'}</Typography>
                        </Stack>
                    })}
                </>
            }
            {
                props.module === "call" && <>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 2 }}>Description</Typography>
                    <Typography width="100%">{fields.description ? fields.description : '--'}</Typography>
                </>
            }
        </Box>
    )
}