import {atom} from 'recoil'

export const selectedcompany = atom({
    key : 'selectedcompany',
    default : ''
})

export const selectedbranchid = atom({
    key : 'selectedbranchid',
    default : ''
})
