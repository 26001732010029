import { Box, Button, Checkbox, } from '@mui/material';
import React, { useState } from 'react'
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AddUser from './AddContact/AddUser';

const Header = () => {
    const [open, setOpen] = useState(false);
   
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
   
      <Box
        sx={{
          display: "flex",
          height: "58px",
          borderBottom: "1px solid #DADFE2",
        }}
      >
        <Box
          sx={{
            display:"flex",
            alignItems:"center",
            alignContent: "center",
            paddingLeft: "10px",
          }}
        >
          <Checkbox />
        <RefreshOutlinedIcon  sx={{marginLeft:"15px",cursor: "pointer",}}/>
        <MoreVertOutlinedIcon  sx={{marginLeft:"15px",cursor: "pointer",}}/>
        </Box>
  
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            padding: "8px 20px 10px 0px ",
            alignItems: "center",
          }}
        >
          <FilterAltOutlinedIcon
            onClick={handleClickOpen}
            sx={{
              border: "1px solid var(--light-border-color)",
              height: "25px",
              marginTop: "2px",
              borderRadius: "2px",
              color: "var(--light-filter-icon-color)",
              backgroundColor: "var(  --onhover-filter-icon-bgc)",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "var(--dark-filter-icon-color)",
                border: "1px solid #00000040",
              },
            }}
          />
          <Box sx={{ marginLeft: "20px" }}>
         <AddUser/>
          </Box>
        </Box>
      </Box>

    );
  };

export default Header
