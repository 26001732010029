import { Box, Button, MenuItem, Select, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import variables from "../../../assets/styles/_colors.scss";
import { useState } from "react";
import { useEffect } from "react";
import ActivitiesTable from "./ActivitiesTablePipelineDetailedView";
import UpsertTask from "../../activities/create/UpsertTask";

const tasks = [
    {
        label: "All Tasks",
        value: "all"
    },
    {
        label: "Open Tasks",
        value: "open"
    },
    {
        label: "Closed Tasks",
        value: "closed"
    }
];

const events = [
    {
        label: "All Events",
        value: "all"
    },
    {
        label: "Open Events",
        value: "open"
    },
    {
        label: "Completed Events",
        value: "completed"
    }
];

const calls = [
    {
        label: "All Calls",
        value: "all"
    },
    {
        label: "Open Calls",
        value: "open"
    },
    {
        label: "Completed Calls",
        value: "completed"
    }
];

const ActivitesTab = (props) => {

    const [selectedTab, setSelectedTab] = useState("tasks");
    const [filterOptions, setFilterOptions] = useState(tasks);
    const [selectedModule, setSelectedModule] = useState(null);

    useEffect(() => {
        setFilterOptions(selectedTab === "tasks" && tasks || selectedTab === "events" && events || selectedTab === "calls" && calls);
    }, [selectedTab]);

    return (
        <>
            <Stack direction="row" alignItems="center" width="100%" sx={{ p: 1, pl: 0, pr: 2 }}>
                <Stack direction="row" justifyContent="center" width="auto" sx={{ ml: 2, borderRadius: 24, border: `1px solid ${variables.borderColor}`, cursor: "pointer" }}>
                    <Box onClick={() => setSelectedTab("tasks")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "tasks" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Tasks </Typography>
                        <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === "tasks" ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>1</Typography>
                    </Box>
                    <Box onClick={() => setSelectedTab("events")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "events" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Events</Typography>
                        <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === "events" ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>1</Typography>
                    </Box>
                    <Box onClick={() => setSelectedTab("calls")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "calls" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Calls </Typography>
                    </Box>
                </Stack>
                <Stack direction="row" justifyContent="right" width="75%" spacing={2}>
                    <Button variant="outlined" onClick={() => setSelectedModule("task")}><AddIcon /> Task </Button>
                    <Button variant="outlined" onClick={() => setSelectedModule("event")}><AddIcon /> Event </Button>
                    <Button variant="outlined" onClick={() => setSelectedModule("call")}><AddIcon /> Call </Button>
                </Stack>
            </Stack>
            <Select defaultValue={filterOptions[0]?.value} size="small"
                sx={
                    [{
                        ml: 1,
                        fontWeight: 'bold',
                    }, {
                        '.MuiOutlinedInput-notchedOutline': {
                            border: 'none'
                        }
                    }]
                }>
                {filterOptions?.map(option => {
                    return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                })}
            </Select>
            <ActivitiesTable module={selectedTab} view={props.view} />
            {selectedModule && <UpsertTask module={selectedModule} handleDrawerClose={() => setSelectedModule(null)} />}
        </>
    )
}

export default ActivitesTab;