// styles.js

import { textTransform } from "@xstyled/styled-components";

export const customTextStyle = {
  color: "#434c5e",
  fontFamily: "OpenSans",
  fontSize: "12px",
  textAlign: "left",
  textTransform: "capitalize",
};

export const customInlineBlockStyle = {
  color: "#596275",
  display: "inline-block",
  fontFamily: "OpenSans",
  fontSize: "12px",
  fontWeight: 600,
  marginBottom: "6px",
};

export const customContact = {
  fontFamily: "OpenSans",
  fontSize: "11px",
  fontWeight: 600,
  textTransform: "capitalize",
};

export const contactTitle = {
  color: "#0B1433", // Added quotes around the color value
  fontFamily: "Inter",
  fontSize: "15px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

export const customContactData = {
  color: "#596275",
  fontFamily: "Inter",
  fontSize: "13px",
  lineHeight: "18px",
  opacity: 0.8,
  textTransform: "uppercase",
  fontWeight: 400,
};

export const customNameSereverTextStyle = {
  color: "#434c5e",
  fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: 500,
  letterSpacing: "0.5px",
  textTransform: "uppercase",
  fontStyle: "normal", // Added the missing property
  lineHeight: "normal",
};

export const flexContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
};

export const customNameServer = {
  color: "#0B1433",
  fontFamily: "Inter",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 500,
  opacity: 0.6,
  lineHeight: "normal",
  paddingBottom: "6px",
};

export const customRenewButton = {
  border: "none",
  borderRadius: "2px",
  color: "#FFF",
  fontFamily: "Inter",
  background: "#14B662",
  cursor: "pointer",
  display: "inline-block",
  width: "60.177px",
  fontSize: "11px",
  fontWeight: 600,
  padding: "8px 12px",
  textDecoration: "none",
  height: "32px",
  textTransform: "capitalize",
};

export const customOutlineButtonStyle = {
  background: "#0000",
  borderRadius: "4px",
  border: "2px solid rgba(0, 10, 255, 1)",
  color: "rgba(0, 10, 255, 1)",
  cursor: "pointer",
  display: "inline-block",
  fontFamily: "Inter",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  outline: "none",
  padding: "7px 14px",
  transition: "0.2s",
  textTransform: "capitalize",
};

export const customDisabledButtonStyle = {
  background: "#0000",
  borderRadius: "4px",
  border: "1px solid #a0a0b",
  color: "#a0a0b",
  cursor: "pointer",
  display: "inline-block",
  fontFamily: "Inter",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  outline: "none",
  padding: "7px 14px",
  transition: "0.2s",
};

export const contactDetailsNumber = {
  color: "#596275",
  fontFamily: "Inter",
  fontSize: "13px",
  lineHeight: "18px",
  opacity: 0.8,
  textTransform: "uppercase",
  fontWeight: 400,
};

export const proceedStyle = {
  background: "#28bd8b",
  border: "none",
  borderRadius: "2px",
  color: "#fff",
  cursor: "pointer",
  display: "inline-block",
  fontFamily: "OpenSans",
  fontSize: "12px",
  fontWeight: 600,
  outline: "none",

  position: "relative",
  textTransform: "uppercase",
};
