import { action_contants } from "../actions/action-types.js";

const initialState = {
  isToShowToastMsg: false,
  toastPrefixIconName: "",
  toastMessage: "",
  isToShowLoader: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case action_contants.SHOW_TOAST_MESSAGE:
      return {
        ...state,
        isToShowToastMsg: action.payload.isToShow,
        toastPrefixIconName: action.payload.iconName,
        toastMessage: action.payload.toastMessage,
      };

    case action_contants.SHOW_LOADER:
      return {
        ...state,
        isToShowLoader: action.payload,
      };
    case action_contants.LOG_ERROR:
      return {
        ...state,
        errorObject: action.payload,
      };

    default:
      break;
  }
  return state;
};

export default commonReducer;
