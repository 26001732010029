import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

const open_snackbar = atom({
  key: "open_snackbar",
  default: {open: false,
       message: "",
       severity: ""},
  effects_UNSTABLE: [persistAtom],
});

const clientidd = atom({
  key: "clientidd",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

const useridd = atom({
  key: "useridd",
  default: -1,
  effects_UNSTABLE: [persistAtom],
});

const invoiceidd = atom({
  key: "invoiceidd",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

const amount = atom({
  key: "amount",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export { clientidd, useridd, invoiceidd, amount , open_snackbar  };
