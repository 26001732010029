import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { Button } from '@mui/material';

const loadMore = true;

const timelineData = {
    "today": {
        "label": "TODAY",
        "history": [
            {
                "time": "9.30 AM",
                "change": "Stage Updated",
                "changedBy": "User 2"
            },
            {
                "time": "07.00 AM",
                "change": "Stage Updated",
                "changedBy": "Test User 1"
            },
            {
                "time": "8.30 PM",
                "change": "Stage Updated",
                "changedBy": "Test User 2"
            },
            {
                "time": "07.00 PM",
                "change": "Stage Updated",
                "changedBy": "User 1"
            }
        ]
    },
    "lastweek": {
        "label": "Feb 22, 2024",
        "history": [
            {
                "time": "8.30 PM",
                "change": "Stage Updated",
                "changedBy": "Test User 2"
            },
            {
                "time": "07.00 PM",
                "change": "Stage Updated",
                "changedBy": "User 1",
            }
        ]
    }
}

export default function PipelineTimeline() {
    return (
        <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                    maxWidth: 100
                },
            }}
        >
            {Object.keys(timelineData).map((key, index) => {
                return <>
                    <TimelineItem>
                        <TimelineOppositeContent color="textSecondary">
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color="success" />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>{timelineData[key].label}</TimelineContent>
                    </TimelineItem >
                    {timelineData[key].history.map((item, index) => {
                        return <TimelineItem>
                            <TimelineOppositeContent color="textSecondary">
                                {item.time}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>{item.change} by  {item.changedBy}</TimelineContent>
                        </TimelineItem>
                    })}
                </>
            })}

            {loadMore && <TimelineItem>
                <TimelineOppositeContent color="textSecondary">
                </TimelineOppositeContent>
                <Button variant="contained" sx={{ width: "110px", height: "35px", ml: "-4%" }}>Load More</Button>
                <TimelineContent></TimelineContent>
            </TimelineItem>}
        </Timeline >
    );
}