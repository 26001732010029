import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { getAllPipelines } from "../../../../webservices/PipelineService";
import { useState } from "react";
import { CloseButton } from "../../../../components/CloseButton";
import variables from "../../../../assets/styles/_colors.scss";

const dummyUsers = [{
    name: 'User 1',
    id: '1',
},
{
    name: 'User 2',
    id: '2',
},
{
    name: 'User 3',
    id: '3',
}]
export default function ChangeOwner(props) {

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [assign, setAssign] = useState(false);

    const [open, setOpen] = useState(true);

    function handleDialogClose(type) {
        setOpen(false);
        props.handleDialogClose(type);
    }

    useEffect(() => {
        getAllPipelines().then(response => {
            setUsers(dummyUsers);
        });
    }, []);

    useEffect(() => {
    }, [users]);

    return (
        <Dialog
            open={open}
            aria-labelledby="change-owner-dialog"
            PaperProps={{
                sx: { minWidth: '42%', maxWidth: '42%', overflow: 'hidden' }
            }}>

            <DialogTitle id="change-owner-dialog-title">
                Change Owner
                <CloseButton handleDialogClose={handleDialogClose} />
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel shrink id="change-owner-input-label">Change Owner</InputLabel>
                    <Select
                        displayEmpty
                        value={selectedUser}
                        onChange={(event) => setSelectedUser(event.target.value)}
                    >
                        <MenuItem value="" disabled>Choose Owner</MenuItem>
                        {users?.map(option => {
                            return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                {selectedUser !== "" && props.module === "pipeline" &&
                    <>
                        <FormControlLabel
                            sx={{ pt: 1 }}
                            control={<Checkbox checked={assign} onChange={() => setAssign(!assign)} />}
                            label="Assign all the open tasks, open events and open calls of selected records to this user"
                        />
                        <Typography sx={{ p: 1, backgroundColor: `${variables.lightGreyBackgroundColor}` }}>
                            <b>Note:</b> This will not change the hosts for online meetings in events
                        </Typography>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction="row" justifyContent="right" sx={{ pb: 2, pr: 2 }}>
                    <Button variant="outlined" onClick={handleDialogClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleDialogClose} disabled={selectedUser === ""}>Save</Button>
                </Stack>
            </DialogActions>

        </Dialog>
    );

};