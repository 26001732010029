import React from "react";
import HeatMap from "react-heatmap-grid";
import Styles from "../../../../Style/TopBar/Chart.module.css";
export const graphData = [
  [100, 100, 40, 40],
  [58, 97, 34, 79],
  [33, 47, 6, 58],
  [28, 74, 8, 19],
];

export default function Heatmap({}) {
  const xLabels = ["Mike", "Ricky", "Jack"];
  const xLabelsVisibility = new Array(3).fill(true);

  const yLabels = ["Jan", "Feb", "March", "Apr"];
  const yLabelsVisibility = new Array(4).fill(true);

  return (
    <div className={Styles.heatWrapper}>
      <div
    
        style={{ fontSize: "13px", width: "80%" }}
      >
        <div className="heatmap-grip-background">
          <HeatMap
            xLabels={xLabels}
            yLabels={yLabels}
            xLabelsLocation={"bottom"}
            xLabelsVisibility={xLabelsVisibility}
            yLabelsVisibility={yLabelsVisibility}
            xLabelWidth={45}
            yLabelWidth={50}
            data={graphData}
            squares={false}
            height={35}
            cellStyle={(background, value, min, max, data, x, y) => ({
              background:
                value >= 70 ? "#90DEFD" : value >= 40 ? "#B4FFFF" : "#CBF3FE",
              width: "75x",
              height: "30px",
              fontSize: "11.5px",
              color: "#444",
            })}
            cellRender={(value, min, max) => <div>{value}</div>}
          />
        </div>
      </div>
    </div>
  );
}
