import React , {useState , useEffect} from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import axios from "axios";

const summary = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "auto",
};

const topPart = {
  display: "flex",
  flexDirection: "column",
  borderBottom: "1px solid #00000036",
  padding: "10px 0px",
  margin: "10px",
};

const Typo = {
  fontSize: "12px",
  padding: "2px",
  fontWeight:"700"
};

const Typo1 = {
  fontSize: "12px",
  padding: "0 0 10px 20px",
  width : "90px"
 // marginLeft: "10px",
  //borderLeft: "3px solid blue",
};


const span = {
  display: "inline-block",
  width: "90px",
};

const span1 = {
  display: "inline-block",
  width: "49%",
  borderBottom:"1px solid black",
  textAlign:"center"
// position:"relative",
// left:"20px"
};

// const span1={
//     width:"2px",
//     height:"40px",
//     backgroundColor:"blue",
//     marginLeft:"10px",
//     borderRadius : "5px"
// }

const span2={
  display: "inline-block",
  width: "49%",
}

const span3={
    width:"2px",
    height:"40px",
    backgroundColor:"red",
    marginLeft:"10px",
    borderRadius : "5px"
}

const span4={
    width:"3px",
    height:"40px",
    backgroundColor:"grey",
    marginLeft:"10px",
    borderRadius : "5px"
}

export default function InfoTask(props) {

    return (
      <div className="summary" style={summary}>
        <div className="topPart" style={topPart}>
          <Typography style={Typo}>
            <span style={span}>Completed Task</span> :  {props.summaryDetails.task.completed_task_count}
          </Typography>
          <Typography style={Typo}>
            <span style={span}>Running Task </span> : <span style={span}>{props.summaryDetails.task.running_task}</span>
          </Typography>
          <Typography style={Typo}>
            <span style={span}>Upcoming Task </span> :  <span style={span}>{props.summaryDetails.task.upcoming_task}</span>
          </Typography>
          {/* <Typography style={Typo}>
            <span style={span}>Alert </span> :  --
          </Typography>
          <Typography style={Typo}>
            <span style={span}>Expense </span> :  <span style={span}>{props.summaryDetails.summary.expence_amount}</span>
          </Typography> */}
        </div>
        <div className="bottomPart">
        <Typography style={Typo}>
            <span style={span1}>Task Name </span>   <span style={span1}>Status</span>
          </Typography>
          {props.summaryDetails.task.task_details.length > 0 ? props.summaryDetails.task.task_details.map((item) =>  <Typography style={Typo}>
            <span style={span2}>{item.task_name} </span> :  <span style={span}>{item.task_status}</span>
          </Typography> ) : <Typography style={{fontWeight : 700 , marginTop :20 , textAlign : "center"}}>No Task Found</Typography>}
          {/* <Grid container spacing={1}>
            <Grid item xs={6} display={"flex"} flexDirection={"row"} justifyContent={"start"} alignItems={"center"}>
              <span style={span1}></span>
              <Typography style={Typo1}>
                Travel
                <br />
                Distance
              </Typography>
              <span style={{color : "blue" , fontSize : "12px" , fontWeight : "700"}}>{props.summaryDetails.summary.travel_distance}km</span>
            </Grid>
            <Grid item xs={6} display={"flex"} flexDirection={"row"} justifyContent={"start"} alignItems={"center"}>
            <span style={span2}></span>
              <Typography style={Typo1}>
                Travel
                <br />
                Duration
              </Typography>
              <span style={{color : "pink" , fontSize : "12px" , fontWeight : "700"}}>{props.summaryDetails.summary.travel_duration} mins </span>
            </Grid>
           
          </Grid> */}
        </div>
        <br />
      </div>
    );
}
