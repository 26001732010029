import React, { useState } from "react";
import { Drawer,  Button, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./AddContactDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@mui/material/Grid";

import { api_url } from "../../globalSettings";
import axios from "axios";
import { login_EmpDetails, login_companyid, profile_trigger, selectedEmp } from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";

import "react-country-dropdown/dist/index.css";

import styled from "styled-components";
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";



const CssTextField = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#000",
  },

  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#00000033",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#00000033",
      padding: "9.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009DFE",
    },
  },
});



const AdddProjectdrawer = styled.div`
  width: 30vw;

  height: 100%;
`;

const AddProjectDrawer = (props) => {
  const [company_idd] = useRecoilState(login_companyid);
  const [errors, setErrors] = useState({});
  const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
  const [companyform, setCompanyForm] = useState({
    name: "",
    notes: "",
    estimation_hours: "",
  });
  const [loginEmpDetails] = useRecoilState(login_EmpDetails)



   //===================================== set valid time  ===========================

   const [validFrom, setValidFrom] = React.useState('');
   const [validTo, setValidTo] = React.useState('');
 
   function valid_from(event) {
     setValidFrom(event.target.value);
   }
 
   function valid_to(event) {
     setValidTo(event.target.value);
   }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyForm((state) => ({ ...state, [name]: value }));

    // Real-time validation
    const newErrors = { ...errors };

    switch (name) {
      case "name":
        newErrors.name = validator.isEmpty(value)
          ? "Please enter your Project Name."
          : "";
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

   

    if (validate()) {

        const params = JSON.stringify({
          name:companyform.name,
          employee_id:loginEmpDetails.employeeDetails._id,
          company_id:company_idd,
          start_date:validFrom,
          end_date:validTo,
          notes:companyform.notes,
          estimation_hours:companyform.estimation_hours,
          // estimation_budget:"1cr"
        });
    
        axios
          .post(`${api_url}/todo/add_project`, params, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then(function (response) {
            setProfileTrigger(!profileTrigger);
            props.toggleDrawer();
           
            alert("Project Added Successfully")
          })
    
          .catch(function (error) {
            if (error.response) {
             
            } else if (error.request) {
             
            } else {
             
            }
          });
  

    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (validator.isEmpty(companyform.name)) {
      isValid = false;
      newErrors.name = "Please enter your Project name.";
    }

  setErrors(newErrors);
    return isValid;
  };



  
  return (
    <div>
      {" "}
      <React.Fragment>
        <Drawer anchor="right" open={props.open} onClose={props.toggleDrawer}>
          <AdddProjectdrawer>
            <div className="addcompanytitle">
              ADD PROJECT
              <IconButton
                component="span"
                onClick={props.toggleDrawer}
                style={{ padding: 0, marginRight: 20 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="information">
              <Grid container spacing={4} padding={5}>
                <Grid item xs={12} alignItems="center" justifyContent=" center">
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    label={"Project Name"}
                    name="name"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                                          {errors.name ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.name}
            </div>
          ) : (
            ""
          )}
                </Grid>
                {/* <div className="informationtitle">Additional information</div> */}
                <Grid item xs={12} sm={6} md={6}>
                  <CssTextField
                    style={{
                      height: "50px",
                      textTransform: "capitalize",
                    }}
                    id="date"
                    type="date"
                    value={validFrom}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={valid_from}
                    size="small"
                    fullWidth
                    label="Valid From*"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <CssTextField
                    style={{
                      height: "50px",
                      textTransform: "capitalize",
                    }}
                    id="date"
                    type="date"
                    value={validTo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={valid_to}
                    size="small"
                    fullWidth
                    label="Valid To*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    type="number"
                    label={"Estimation (hours)"}
                    name="estimation_hours"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} alignItems="center" justifyContent=" center">
                  <TextField
                    size="small"
                    id="outlined-multiline-static"
                    variant="outlined"
                    multiline
                    rows={3}
                    label={"Project Notes"}
                    name="notes"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                
              </Grid>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "30px",
              }}
            >
              <Button
                className="tablebutton"
                onClick={handleSubmit}
              >
                Add Project
              </Button>
            </div>
          </AdddProjectdrawer>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default AddProjectDrawer;
