import { Box, Grid, List, ListItemButton, Stack, Typography } from "@mui/material";
import variables from "../../assets/styles/_colors.scss";
import { makeStyles } from '@mui/styles';
import { Link, useSearchParams } from "react-router-dom";
import { styled } from "@mui/system";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
    listItemSelected: {
        backgroundColor: `${variables.listBackgroundColor} !important`,
        color: `${variables.linkColor} !important`
    },
}));

const StyledLink = styled(Link)`
    display: block;
    color: #212129;
`;

const items = [
    {
        id: "usercontrols",
        name: "Users and Controls",
        path: "/crm/settings/usercontrols/users",
        defaultTab: "users"
    },
    {
        id: "fields",
        name: "Fields",
        path: "/crm/settings/fields/modulefields",
        defaultTab: "fields"
    },
    {
        id: "stages",
        name: "Stages",
        path: "/crm/settings/stages/subpipelines",
        defaultTab: "subpipelines"
    }
];

export default function CrmSettings(props) {

    const classes = useStyles();

    return (
        <Grid item xs={2}>
            <Grid container direction="column" sx={{ borderRight: `1px solid ${variables.borderColor}` }}>
                <Grid item sx={{ height: 46, pt: 1, alignItems: "center" }}>
                    <Stack direction="row" alignItems="center">
                        <Typography component="div" variant="h6" sx={{ pl: 2 }}>Settings</Typography>
                    </Stack>
                </Grid>
                <Grid item sx={{ overflowX: 'hidden', overflowY: 'auto', height: "calc(100vh - 110px)" }} >
                    <Box>
                        <List sx={{ pt: 0 }}>
                            {items?.map((item, index) => {
                                return <StyledLink key={index} to={item.path}>
                                    <ListItemButton sx={{ p: 0, "&:hover #name": { color: `${variables.linkColor}` } }} selected={props.selectedMenu == item.id}
                                        classes={{ selected: classes.listItemSelected }}
                                    >
                                        <Stack direction="row" alignItems="center" sx={{ width: '100%', p: 2, pr: 0 }}>
                                            <Typography id="name">{item.name}</Typography>
                                        </Stack>
                                    </ListItemButton>
                                </StyledLink>
                            })}
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}