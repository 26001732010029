import { useState } from "react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getProductColumns } from "../../webservices/PipelineService";
import { products_atom } from "../../../../Atoms/CrmAtoms";
import ProductsTable from "./view/table/ProductsTable";
import ProductActions from "./ProductActions";

export default function CrmProducts() {

    const [productsAtom, setProductsAtom] = useRecoilState(products_atom);

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [productCols, setProductCols] = useState([]);

    useEffect(() => {

        if (productsAtom.displayedColumns.productsTable.length === 0) {
            getProductColumns().then((response) => {
                persistData(response.data);
            });
        } else {
            setProductCols(productsAtom.displayedColumns.productsTable);
        }

    }, []);

    const persistData = (cols) => {
        if (cols?.length > 0) {
            const obj = {
                ...productsAtom,
                "displayedColumns": {
                    ...productsAtom["displayedColumns"],
                    "productsTable": cols
                }
            }
            setProductsAtom(obj);
            setProductCols(cols);
        }
    }

    return (
        <Box sx={{ ml: isFilterOpen ? '22vw' : 0 }}>
            <ProductActions isFilterOpen={(val) => setIsFilterOpen(val)} />
            {productCols.length > 0 && <ProductsTable columns={productCols} drawerOpen={isFilterOpen} />}
        </Box>
    );
}
