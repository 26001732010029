import EditNoteIcon from '@mui/icons-material/EditNote';
import { Box, Drawer, Fab, Typography } from "@mui/material";
import { useState } from 'react';
import ReorderableList from '../components/ReorderableList';
import { useRecoilState } from 'recoil';
import { CloseButton } from '../../../components/CloseButton';
import variables from "../../../assets/styles/_colors.scss";
import { activities_atom, companies_atom, contacts_atom, pipeline_atoms, products_atom } from '../../../../../Atoms/CrmAtoms';

export default function ModifyPipelineColumns(props) {

    const [editColumns, setEditColumns] = useState(false);
    const [pipelineAtom, setPipelineAtom] = useRecoilState(pipeline_atoms);
    const [activitiesAtom, setActivitiesAtom] = useRecoilState(activities_atom);
    const [contactsAtom, setContactsAtom] = useRecoilState(contacts_atom);
    const [companiesAtom, setCompaniesAtom] = useRecoilState(companies_atom);
    const [productsAtom, setProductsAtom] = useRecoilState(products_atom);
    const [columns, setColumns] = useState([]);

    const persistData = (cols, type, atom) => {
        if (cols?.length > 0) {
            const obj = {
                ...atom,
                "displayedColumns": {
                    ...atom["displayedColumns"],
                    [type]: cols
                }
            }
            switch (type) {
                case "productsTable":
                    setProductsAtom(obj);
                    return;
                case "companiesTable":
                    setCompaniesAtom(obj);
                    return;
                case "contactsTable":
                    setContactsAtom(obj);
                    return;
                case "pipelineBoard":
                case "pipelineTable":
                    setPipelineAtom(obj);
                    return;
                case "task":
                case "event":
                case "call":
                    setActivitiesAtom(obj);
                    return;
            }
        }
    }

    const handleOk = (data) => {
        switch (props.module) {
            case "productsTable":
                persistData(data, "productsTable", companiesAtom);
                break;
            case "companiesTable":
                persistData(data, "companiesTable", companiesAtom);
                break;
            case "contactsTable":
                persistData(data, "contactsTable", contactsAtom);
                break;
            case "pipelineBoard":
                persistData(data, "pipelineBoard", pipelineAtom);
                break;
            case "pipelineTable":
                persistData(data, "pipelineTable", pipelineAtom);
                break;
            case "task":
                persistData(data, "task", activitiesAtom);
                break;
            case "event":
                persistData(data, "event", activitiesAtom);
                break;
            case "call":
                persistData(data, "call", activitiesAtom);
                break;
            default:
                return false;
        }
        setColumns(data);
        setEditColumns(false);
    }

    const handleClose = (data) => {
        setEditColumns(false);
    }

    const openDrawer = () => {
        setEditColumns(true);
        switch (props.module) {
            case "productsTable":
                setColumns(companiesAtom.displayedColumns?.productsTable);
                break;
            case "companiesTable":
                setColumns(companiesAtom.displayedColumns?.companiesTable);
                break;
            case "contactsTable":
                setColumns(contactsAtom.displayedColumns?.contactsTable);
                return true;
            case "pipelineBoard":
                setColumns(pipelineAtom.displayedColumns?.pipelineBoard);
                return true;
            case "pipelineTable":
                setColumns(pipelineAtom.displayedColumns?.pipelineTable);
                return true;
            case "task":
                setColumns(activitiesAtom.displayedColumns?.task);
                return true;
            case "event":
                setColumns(activitiesAtom.displayedColumns?.event);
                return true;
            case "call":
                setColumns(activitiesAtom.displayedColumns?.call);
                return true;
            default:
                return false;
        }
    };

    return (
        <>
            <Box sx={{
                margin: 0, top: (props.module === "pipelineBoard" || props.module === "pipelineTable"
                    || props.module === "contactsTable" || props.module === "companiesTable" || props.module === "productsTable") ? 118 : 166,
                right: 10, bottom: 'auto', left: 'auto', position: 'fixed', zIndex: 5
            }}>
                <Fab size="small" color="primary" sx={{ pl: 0.75 }} onClick={() => openDrawer()}>
                    <EditNoteIcon />
                </Fab>
            </Box>

            <Drawer anchor="right" open={editColumns}
                PaperProps={{
                    sx: { minWidth: "25vw", maxWidth: "25vw", pt: 2, pb: 2, borderTop: `2px solid ${variables.borderColor}` },
                }}>
                <Typography variant="h5" sx={{ pl: 2 }}>{props.module === "pipelineBoard" ? "Displayed Fields" : "Displayed Columns"}
                    <CloseButton handleDialogClose={handleClose} />
                </Typography>
                <ReorderableList items={columns} handleCancel={handleClose} handleOk={(data) => handleOk(data)} module={props.module} />
            </Drawer>
        </>
    )
}