import { Box, Button, CircularProgress, Drawer, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { CloseButton } from "../../../../components/CloseButton";
import variables from "../../../../assets/styles/_colors.scss";
import CustomMultiSelect from "./CustomMultiSelect";
import { FormProvider, useForm } from "react-hook-form";
import { EditProfileSwitch } from "./EditProfileSwitch";
import { CustomInputField } from "../../../pipelines/components/CustomInputField";
import { editProfile } from "../../../../webservices/UserControlService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CustomSnackbar from "../../../../components/CustomSnackbar";

import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
    profileName: Yup.string().label("Profile Name").trim()
        .required("Profile Name cannot be empty")
        .min(2, "Profile Name must be atleast 2 chars long")
        .max(200, "Profile Name cannot be more that 200 chars long"),
    profileDescription: Yup.string().label("Description").trim(),
});

const EditProfile = (props) => {

    const queryClient = useQueryClient();

    const [openDrawer, setOpenDrawer] = useState(true);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const methods = useForm(
        {
            defaultValues: {
                "profileName": props.item?.profileName,
                "description":  props.item?.profileDescription
            },
            shouldUnregister: false,
            resolver: yupResolver(schema),
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, getValues, trigger } = methods;

    const onSubmit = async (data) => {
        const isStepValid = await trigger();
        if (isStepValid) {
            const editedObj = {
                "_id": props.item._id,
                "profileName": props.item.systemDefined ? props.item.profileName : data.profileName,
                "cloneFrom": props.item.cloneFrom,
                "profileDescription": props.item.systemDefined ? props.item.profileDescription : data.profileDescription,
                "permissions": [
                    {
                        "category": "Pipeline Permissions",
                        "attributes": [
                            {
                                "name": "Pipeline Records",
                                "id": "pipelineRecords",
                                "disableSwitch": false,
                                "switchOn": data.pipelineRecords.switchOn,
                                "selected": data.pipelineRecords.switchOn ? data.pipelineRecords.selected : [],
                                "options": [
                                    {
                                        "label": "View",
                                        "value": "view",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Create",
                                        "value": "create",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Edit",
                                        "value": "edit",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Delete",
                                        "value": "delete",
                                        "disabled": false
                                    }
                                ]
                            },
                            {
                                "name": "Team Pipelines",
                                "id": "teamPipeline",
                                "disableSwitch": false,
                                "selected": data.teamPipeline.selected,
                                "switchOn": true,
                                "hideSwitch": true,
                                "options": [
                                    {
                                        "label": "Pipeline 1",
                                        "value": "1"
                                    },
                                    {
                                        "label": "Pipeline 2",
                                        "value": "2"
                                    },
                                    {
                                        "label": "Pipeline 3",
                                        "value": "3"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "category": "Basic Permissions",
                        "attributes": [
                            {
                                "name": "Contacts and Companies",
                                "id": "basicPermissions",
                                "disableSwitch": true,
                                "switchOn": data.basicPermissions.switchOn,
                                "selected": data.basicPermissions.switchOn ? data.basicPermissions.selected : [],
                                "options": [
                                    {
                                        "label": "View",
                                        "value": "view",
                                        "disabled": true
                                    },
                                    {
                                        "label": "Create",
                                        "value": "create",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Edit",
                                        "value": "edit",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Delete",
                                        "value": "delete",
                                        "disabled": false
                                    }
                                ]
                            },
                            {
                                "name": "Products",
                                "id": "products",
                                "disableSwitch": false,
                                "switchOn": data.products.switchOn,
                                "selected": data.products.switchOn ? data.products.selected : [],
                                "options": [
                                    {
                                        "label": "View",
                                        "value": "view",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Create",
                                        "value": "create",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Edit",
                                        "value": "edit",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Delete",
                                        "value": "delete",
                                        "disabled": false
                                    }
                                ]
                            },
                            {
                                "name": "Activities",
                                "id": "activities",
                                "disableSwitch": false,
                                "switchOn": data.activities.switchOn,
                                "selected": data.activities.switchOn ? data.activities.selected : [],
                                "options": [
                                    {
                                        "label": "View",
                                        "value": "view",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Create",
                                        "value": "create",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Edit",
                                        "value": "edit",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Delete",
                                        "value": "delete",
                                        "disabled": false
                                    }
                                ]
                            },
                            {
                                "name": "Notes",
                                "id": "notes",
                                "disableSwitch": true,
                                "switchOn": data.notes.switchOn,
                                "selected": data.notes.switchOn ? data.notes.selected : [],
                                "options": [
                                    {
                                        "label": "View",
                                        "value": "view",
                                        "disabled": true
                                    },
                                    {
                                        "label": "Create",
                                        "value": "create",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Edit",
                                        "value": "edit",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Delete",
                                        "value": "delete",
                                        "disabled": false
                                    }
                                ]
                            },
                            {
                                "name": "Files",
                                "id": "files",
                                "disableSwitch": true,
                                "switchOn": data.files.switchOn,
                                "selected": data.files.switchOn ? data.files.selected : [],
                                "options": [
                                    {
                                        "label": "View",
                                        "value": "view",
                                        "disabled": true
                                    },
                                    {
                                        "label": "Create",
                                        "value": "create",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Edit",
                                        "value": "edit",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Delete",
                                        "value": "delete",
                                        "disabled": false
                                    }
                                ]
                            },
                            {
                                "name": "Dashboards",
                                "id": "dashboards",
                                "disableSwitch": false,
                                "switchOn": data.dashboards.switchOn,
                                "selected": data.dashboards.switchOn ? data.dashboards.selected : [],
                                "options": [
                                    {
                                        "label": "View",
                                        "value": "view",
                                        "disabled": false
                                    },
                                    {
                                        "label": "Manage",
                                        "value": "manage",
                                        "disabled": false
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "category": "Advanced Features",
                        "attributes": [
                            {
                                "name": "Workflows",
                                "id": "workflows",
                                "disableSwitch": props.item.systemDefined,
                                "switchOn": props.item.systemDefined ? true : data.workflows.switchOn
                            },
                            {
                                "name": "Forms",
                                "id": "forms",
                                "disableSwitch": props.item.systemDefined,
                                "switchOn": props.item.systemDefined ? true : data.forms.switchOn,
                                "selected": data.forms.switchOn ? data.forms.selected : [],
                                "options": [
                                    {
                                        "label": "Manage Forms",
                                        "value": "manageForms",
                                        "disabled": props.item.systemDefined
                                    },
                                    {
                                        "label": "Approve Records",
                                        "value": "approveRecords",
                                        "disabled": props.item.systemDefined
                                    }
                                ]
                            },
                            {
                                "name": "User Management",
                                "id": "userManagement",
                                "disableSwitch": props.item.systemDefined,
                                "switchOn": props.item.systemDefined ? true : data.userManagement.switchOn
                            },
                            {
                                "name": "Bulk Actions",
                                "id": "bulkActions",
                                "disableSwitch": true,
                                "disableSwitch": props.item.systemDefined,
                                "switchOn": props.item.systemDefined ? true : data.bulkActions.switchOn,
                                "selected": data.bulkActions.switchOn ? data.bulkActions.selected : [],
                                "options": [
                                    {
                                        "label": "Update",
                                        "value": "update",
                                        "disabled": props.item.systemDefined
                                    },
                                    {
                                        "label": "Delete",
                                        "value": "delete",
                                        "disabled": props.item.systemDefined
                                    },
                                    {
                                        "label": "Change Owner",
                                        "value": "changeOwner",
                                        "disabled": props.item.systemDefined
                                    }
                                ]
                            },
                            {
                                "name": "Miscellaneous",
                                "id": "miscellaneous",
                                "disableSwitch": props.item.systemDefined,
                                "switchOn": props.item.systemDefined ? true : data.miscellaneous.switchOn,
                                "selected": data.miscellaneous.switchOn ? data.miscellaneous.selected : [],
                                "options": [
                                    {
                                        "label": "Manage Custom Views",
                                        "value": "manageViews",
                                        "disabled": props.item.systemDefined
                                    },
                                    {
                                        "label": "Tags",
                                        "value": "tags",
                                        "disabled": props.item.systemDefined
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "createdBy": props.item.createdBy,
                "modifiedBy": "User 2",
                "systemDefined": props.item.systemDefined
            }
            // alert(JSON.stringify(data));
            mutateAsync(editedObj);
        }
    }

    const { mutateAsync, isPending } = useMutation({
        mutationKey: "editProfile",
        mutationFn: (data) => editProfile(props.item?._id, data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllProfiles'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    function handleDrawerClose(data) {
        setOpenDrawer(false);
        props.handleDrawerClose(data);
    }

    return (
        <>
            <Drawer anchor="right" open={openDrawer}
                PaperProps={{
                    sx: {
                        minWidth: "70vw", maxWidth: "70vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                    }
                }}>
                <Box sx={{ height: "100vh" }}>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)} id="editProfileForm" style={{ height: "100%" }}>
                            <CloseButton disabled={isPending} handleDialogClose={() => handleDrawerClose()} />
                            {
                                props.item && props.item.systemDefined
                                    ?
                                    <Typography variant="h5" sx={{ pl: 2 }}>{props.item?.profileName} </Typography>
                                    :
                                    <Stack direction="row" width="40%" sx={{ pl: 2.5, pt: 1 }}>
                                        <CustomInputField name={'profileName'} defaultValue={props.item?.profileName} label={'Profile Name*'} boldText={true} />
                                    </Stack>
                            }

                            <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 2.5, height: "86vh", pb: 6 }}>
                                {
                                    props.item && props.item.systemDefined
                                        ? <Typography> {props.item?.profileDescription} </Typography>
                                        : <Stack direction="row" width="60%">
                                            <CustomInputField name={'profileDescription'} rows={3} subType={'multi'} defaultValue={props.item?.profileDescription} label={'Profile Description'} placeholder="A few words about this profile" />
                                        </Stack>
                                }

                                <Box>
                                    {props.item?.permissions.map(permission => {
                                        return <Box sx={{ pt: 2 }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>{permission.category} </Typography>
                                            {
                                                permission.attributes.map((attribute, index) => {
                                                    return <Stack direction="row" alignItems="center" sx={{ pt: 1 }} width="100%" key={index}>
                                                        <Typography width="30%">{attribute.name}</Typography>
                                                        <Typography component="span" sx={{ visibility: !attribute.hideSwitch ? 'inherit' : 'hidden' }}>
                                                            <EditProfileSwitch name={`${attribute.id}.switchOn`} attributeId={attribute.id} options={attribute.options} disabled={attribute.disableSwitch} defaultChecked={attribute.switchOn} />
                                                        </Typography>
                                                        {getValues(`${attribute.id}.switchOn`) && attribute.options?.length > 0 &&
                                                            <CustomMultiSelect size="small" attributeId={attribute.id} name={`${attribute.id}.selected`} options={attribute.options} defaultValue={attribute.selected} />}
                                                    </Stack>
                                                })
                                            }
                                        </Box>
                                    })}
                                </Box>
                            </Box>
                        </form>
                    </FormProvider>

                    <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button disabled={isPending} variant="outlined" onClick={() => handleDrawerClose()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={isPending}
                            type="submit"
                            form={"editProfileForm"}
                            variant="contained"
                            color="primary"
                        > Save
                            {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                        </Button>
                    </Stack>
                </Box>
            </Drawer >

            <CustomSnackbar
                snackbarInfo={snackbarInfo}
                handleSnackbarClose={handleSnackbarClose}
            />
        </>
    )
}

export default EditProfile;