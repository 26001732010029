import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Style from "../Style/FundTextField.module.css";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
  customInputRoot: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
  },
}));

const FundTextField = ({ name, value, error, helperText, onChangeFuncion }) => {
  const classes = useStyles();
  return (
    <>
      <div className={Style.fund_text}>WHAT AMOUNT WOULD YOU LIKE TO ADD </div>
      <div className={Style.fund_align}>
        <TextField
          name={name}
          type="number"
          value={value}
          error={error}
          helperText={helperText}
          onChange={onChangeFuncion}
          style={{ width: "70%" }}
          fullWidth
          className={classes.customInputRoot}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">INR</InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};

export default FundTextField;
