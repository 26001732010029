import React from 'react'

const Analytics = () => {
  return (
    <div>
      Analytics
    </div>
  )
}

export default Analytics
