import * as React from 'react';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { Button, Divider, ListItemButton, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import variables from "../../../../assets/styles/_colors.scss";
import UpdateTags from './UpdateTags';
import Delete from './Delete';
import ChangePipelineAndStage from './ChangePipelineAndStage';
import ChangeOwner from './ChangeOwner';
import UpdateField from './UpdateField';

const useStyles = makeStyles((theme) => ({
    pipelineBulkActions: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

export default function TableBulkActionsPopover(props) {
    const classes = useStyles();
    const [addTags, setAddTags] = useState(false);
    const [removeTags, setRemoveTags] = useState(false);
    const [updateField, setUpdateField] = useState(false);
    const [updatePipelineAndStage, setUpdatePipelineAndStage] = useState(false);
    const [showChangeOwner, setShowChangeOwner] = useState(false);
    const [deleteRecords, setDeleteRecords] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const bulkActions = [
        {
            name: "Add Tags",
            key: 'addTags'
        },
        {
            name: "Remove Tags",
            key: "removeTags"
        },
        {
            name: "Update Field",
            key: "updateField"
        },
        props.module === "pipeline" ? {
            name: "Change Stage/Pipeline",
            key: "changePipelineAndStage"
        } : null,
        props.module === "contacts" ? {
            name: "Change owner",
            key: "changeOwner"
        } : null,
        {
            name: "Delete",
            key: "deleteRecords"
        }
    ]

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "addTags":
                    setAddTags(true);
                    return true;
                case "removeTags":
                    setRemoveTags(true);
                    return true;
                case "updateField":
                    setUpdateField(true);
                    return true;
                case "changePipelineAndStage":
                    setUpdatePipelineAndStage(true);
                    return true;
                case "changeOwner":
                    setShowChangeOwner(true);
                    return true;
                case "deleteRecords":
                    setDeleteRecords(true);
                    return true;
                default:
                    return null;
            }
        }

    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'bulk-actions-popover' : undefined;

    return (
        <div style={{ 'display': 'inline-block' }}>
            <Button aria-label="bulk-actions-popover" variant="outlined" onClick={handleClick} sx={{ textTransform: 'none', height: 40, borderRadius: 20, width: 80 }}>
                <Typography>More</Typography>
                <ArrowDropDownIcon sx={{ ml: 0.5, mr: -1 }} />
            </Button>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{ paper: classes.pipelineBulkActions }}
            >
                {bulkActions.map((item, index) => (
                    item && <div key={`pipeline-bulk-actions-item-${index}`}>
                        <ListItemButton
                            key={`${item.name}-${index}`}
                            sx={{ p: "8px 12px" }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </div>
                ))}
            </Popover>
            {addTags && <UpdateTags add={true} handleDialogClose={() => setAddTags(false)} />}
            {removeTags && <UpdateTags add={false} handleDialogClose={() => setAddTags(false)} />}
            {updateField && <UpdateField handleDialogClose={() => setUpdateField(false)} />}
            {updatePipelineAndStage && <ChangePipelineAndStage handleDialogClose={() => setUpdatePipelineAndStage(false)} />}
            {showChangeOwner && <ChangeOwner handleDialogClose={() => setShowChangeOwner(false)} />}
            {deleteRecords && <Delete handleDialogClose={() => setDeleteRecords(false)} />}
        </div>
    );
}