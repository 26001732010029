import React from 'react';
import { ItemContainer } from './PipelineBoardStyles';
import { Box, Checkbox, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { pipeline_atoms } from '../../../../../../Atoms/CrmAtoms';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ComposeEmail from './ComposeEmail';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UpsertDeal from '../../create/UpsertDeal';
import variables from "../../../../assets/styles/_colors.scss";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteDeal from '../../delete/DeleteDeal';
import DealQuickPreview from '../DealQuickPreview';
import { Link } from 'react-router-dom';
import styled from "styled-components";
import ActivitiesPopover from '../../../activities/ActivitiesPopover';

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

// const StyledLink = styled(Link)`
//     display: block;
//     color: #212129;
//     padding-top: 8px;
//     padding-bottom: 8px;
// `;

function TaskItem(props) {

  const { task, $isDragging, provided, style, index, reff, selectedItems } = props;
  const columns = useRecoilValue(pipeline_atoms).displayedColumns.pipelineBoard;
  const inSelectionMode = useRecoilValue(pipeline_atoms).selectedBoardData;
  const [isHovering, setIsHovering] = useState(false);
  const [editDeal, setEditDeal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [deleteDeal, setDeleteDeal] = useState(false);
  const [showQuickView, setShowQuickView] = useState(false);

  useEffect(() => {
    setChecked(selectedItems.indexOf(index) > -1 ? true : false);
  }, [selectedItems]);

  const handleQuickView = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowQuickView(true);
  }

  const handleEdit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditDeal(true);
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDeleteDeal(true);
  }

  return (
    <>
      <ItemContainer
        key={task._id}
        href={task.stage.url}
        $isDragging={$isDragging}
        ref={(el) => { provided.innerRef(el); }}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getStyle(provided, style)}
        data-is-dragging={$isDragging}
        data-testid={task._id}
        data-index={index}
        aria-label={`${task.stage.name} task ${task.content}`}
        onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
      >
        <div ref={reff} style={{ width: '100%' }}>
          {/* <StyledLink to={`/crm/pipeline/table/1/1/1`}
            onClick={(e) => {
              if (e.target !== e.currentTarget) {
                if (["A", "BUTTON", "SELECT", ""].includes(e.target.nodeName)) {
                e.preventDefault();
                }
              }
            }}
            state={{
              module: props.module,
              item: task
            }}
            style={{ color: isHovering ? `${variables.linkColor}` : "#000" }} > */}
          <Stack direction="row">
            <Stack direction="row" spacing={1} justifyContent="left" sx={{ width: "50%" }}>
              <Typography variant="div" id="dealName" sx={{ pb: 0.5, color: `${variables.pipelineBoardText}`, fontWeight: 600 }}>
                <Link to={`/crm/pipeline/table/1/1/1`} state={{
                  module: props.module,
                  item: task
                }}
                  style={{ color: isHovering ? `${variables.linkColor}` : "#000" }} >
                  {task["name"]}
                </Link>
              </Typography>
              <Tooltip title="Quick Preview" placement="right">
                <IconButton sx={{ p: 0, visibility: isHovering ? 'inherit' : 'hidden' }} onClick={(e) => { handleQuickView(e) }}>
                  <VisibilityOutlinedIcon fontSize="small" color={variables.greyTextColor} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction="row" spacing={1} justifyContent="right" sx={{ width: "50%" }}>
              <Tooltip title="Delete" placement="bottom">
                <IconButton sx={{ p: 0, visibility: isHovering ? (inSelectionMode ? 'hidden' : 'inherit') : 'hidden' }} onClick={(e) => { handleDelete(e) }}>
                  <DeleteOutlineIcon fontSize='small' color="error" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" placement="bottom">
                <IconButton sx={{ p: 0, visibility: isHovering ? (inSelectionMode ? 'hidden' : 'inherit') : 'hidden' }} onClick={(e) => { handleEdit(e) }}>
                  <EditOutlinedIcon fontSize='small' color={variables.greyTextColor} />
                </IconButton>
              </Tooltip>
              {showQuickView && <DealQuickPreview item={task} handleDrawerClose={() => setShowQuickView(false)} />}
              {deleteDeal && <DeleteDeal item={task} handleClose={() => { setDeleteDeal(false); setIsHovering(false); }} />}
              {editDeal && <UpsertDeal edit={true} handleDrawerClose={() => { setEditDeal(false); setIsHovering(false); }} />}
              <Checkbox
                size="medium"
                sx={{
                  p: 0,
                  visibility: checked ? 'inherit' : (isHovering ? 'inherit' : 'hidden')
                }}
                color="primary"
                checked={checked}
                onChange={(e) => { setChecked(e.target.checked); props.handleCheckboxSelection(index, e.target.checked) }}
              />
            </Stack>
          </Stack>
          {columns?.map((column, index) => {
            return column.selected &&
              <Box component="div" key={`${column.label}-${index}`} sx={{
                fontSize: '0.9rem',
                color: `${variables.pipelineBoardText}`,
                paddingBottom: 0.5,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              }}>
                {column["label"] !== "amount" ?
                  <Typography variant="span"> {task[column?.["label"]]} </Typography> :
                  <>
                    <Typography variant="span" sx={{ pr: 1 }}> {task.amount ? '$' + task.amount : "$0.00"}</Typography>
                    <Typography variant="span" sx={{
                      borderRadius: "50%", display: "inline-block",
                      height: "6px", width: "6px", backgroundColor: "lightgrey"
                    }} />
                    <Typography variant="span" sx={{ pl: 1 }}>{task["closingDate"]}</Typography>
                  </>
                }
              </Box>
          })}

          <Stack direction="row" spacing={1} sx={{
            position: 'absolute',
            right: 1,
            bottom: '6px',
            padding: 0.5,
            borderRadius: 2,
            backgroundColor: `${variables.whiteColor}`,
            visibility: isHovering ? 'inherit' : 'hidden'
          }}>
            <ComposeEmail handleClose={() => setIsHovering(false)}></ComposeEmail>
            <ActivitiesPopover resetHover={() => setIsHovering(false)} />
          </Stack>
          {/* </StyledLink> */}
        </div>
      </ItemContainer >
    </>
  );
}

export default React.memo(TaskItem);
