import React from "react";
import CautionImage from "../../../images/DomainDetails/warning.png";
import { LoadingButton } from "@mui/lab";
import { customRenewButton } from "../Style/DomainStyles";
import { marginBottom } from "@xstyled/styled-components";

const RenewalError = ({
  height,

  overlap,
  type,

  loadButton,
  handleClick,
}) => {
  return (
    <div
      style={{
        height: `${height}`,
        background: "black",
        marginBottom: `${overlap}`,
        zIndex: 1,
        opacity: "0.8",
      }}
    >
      <div>
        <div style={{ display: "flex",alignItems:'center' }}>
          <img
            src={CautionImage}
            alt="Caution"
            style={{ height: "50px", width: "50px" }}
          />
          <div style={{
            color: "white",
            fontWeight: 500,
            fontSize: "13px",
            fontFamily: "Inter"
          }}>
            <div>Your {type} has been Expired</div>
            <div>
              If you dont restore your {type} you will lost your ownership
            </div>
          </div>
        </div>
      </div>
      <div style={{margin:"10px"}}>
        <LoadingButton
          variant="contained"
          color="success"
          style={customRenewButton}
          onClick={() => {
            handleClick();
          }}
          loading={loadButton}
        >
          Renew
        </LoadingButton>
      </div>
    </div>
  );
};

export default RenewalError;
