import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AvatarStatus from "./Components/Avatar";
import Styles from "./Contact.module.scss";
import Header from "./Header";
import { useQuery } from "@tanstack/react-query";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day} / ${month} / ${year}`;
};

const fetcher = () => {
  return axios
    .get( "https://660d45d36ddfa2943b33ecdd.mockapi.io/employee")
    .then(({ data }) => data);
};
const Body = () => {
  const [selected, setSelected] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const { data: contactdata, isLoading } = useQuery({
    queryKey: ["contactdata"],
    queryFn: fetcher,
  });

  const handleRoute = (id) => {
    navigate(`/chat/contacts/${id}`);
  };
  const handleCheckboxChange = (id) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }));
  };
  if (isLoading) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "60%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "block", width: "100%" }}>
      <Header />
      <Box sx={{ height: "100%" }}>
        <TableContainer sx={{ maxHeight: "92%" }}>
          <Table
            size="small"
            sx={{ minWidth: 300, overflowY: "auto" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead
              sx={{ borderBottom: "1px solid #E2E3E6", height: "54px" }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Department
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Date Of Joining
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Mobile No
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: "pointer" }}>
              {contactdata.map((value) => (
                <TableRow
                  className={`${Styles.ChoverCheckbox} ${
                    location.pathname === `/chat/contacts/${value.id}`
                      ? Styles.CActiveselect
                      : ""
                  }`}
                  key={value.id}
                  sx={{
                    "&:hover": { backgroundColor: "var(--light-bg-img-color)" },
                  }}
                >
                  <TableCell sx={{ paddingLeft: "10px", paddingRight: "0" }}>
                    <Checkbox
                      className={
                        selected[value.id]
                          ? `${Styles.ChoverCheckbox}`
                          : Styles.CCheckbox
                      }
                      checked={selected[value.id] || false}
                      onChange={() => handleCheckboxChange(value.id)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingLeft: "8px",
                      display: "flex",
                      paddingTop: "8px",
                    }}
                    onClick={() => handleRoute(value.id)}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AvatarStatus
                        src={value.avatar}
                        alt="User Avatar"
                        status={value.status}
                      />
                      <span style={{ marginLeft: "10px" }}>{value.name}</span>
                    </div>
                  </TableCell>
                  <TableCell onClick={() => handleRoute(value.id)}>
                    {value.email}
                  </TableCell>
                  <TableCell onClick={() => handleRoute(value.id)}>
                    {value.type}
                  </TableCell>
                  <TableCell onClick={() => handleRoute(value.id)}>
                    {formatDate(value.joiningdate)}
                  </TableCell>
                  <TableCell onClick={() => handleRoute(value.id)}>
                    {value.phone}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Body;
