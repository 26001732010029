import React from 'react'

const SmsHome = () => {
  return (
    <div>
      SMS
    </div>
  )
}

export default SmsHome
