import React from "react";
import SettingsSideBar from "./SettingsSideBar";
import { FormControl, InputBase, NativeSelect } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import EditCompany from "./EditCompany";
import { useLocation } from "react-router-dom";
import MainWebsiteSettings from "../../chats/websitesettings/MainWebsiteSettings";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #000",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 20,
      color: "#000",
      borderColor: "#000",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const MySettings = () => {
  const [value, setValue] = React.useState("1");

  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div style={{ display: "flex" }}>
      <SettingsSideBar />

      <div style={{ width: "100%" }}>
        {pathname === "/mySettings" ? (
          <>
            <div
              className="nav-bar-notification"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FormControl
                sx={{ m: 1 }}
                variant="standard"
                style={{ width: "100px" }}
                size="small"
              >
                <NativeSelect
                  id="demo-customized-select-native"
                  //defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
                  // onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  {/* {console.log(Getlist[0].label)} */}
                  {/* {Getlist.map((item) => {
                return ( */}
                  {/* <option value={item.id}>{item.label}</option> */}
                  {/* {console.log(item)} */}

                  <option value={20}>Twenty</option>
                  <option value={30}>Thirty</option>
                  {/* );
              })} */}
                </NativeSelect>
              </FormControl>
            </div>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Shift"
                      value="1"
                    />
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Department"
                      value="2"
                    />
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Leave"
                      value="3"
                    />
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Holidays"
                      value="4"
                    />
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Designation"
                      value="5"
                    />
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Task Category"
                      value="6"
                    />
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Expense"
                      value="7"
                    />
                    <Tab
                      style={{ textTransform: "none" }}
                      label="Info"
                      value="8"
                    />
                  </TabList>
                </Box>
                <TabPanel value="1">{/* <Table /> */}</TabPanel>
                <TabPanel value="2">{/* <Table /> */}</TabPanel>
                <TabPanel value="3">{/* <Table /> */}</TabPanel>
                <TabPanel value="4">{/* <Table /> */}</TabPanel>
                <TabPanel value="5">{/* <Table /> */}</TabPanel>
                <TabPanel value="6">{/* <Table /> */}</TabPanel>
                <TabPanel value="7">{/* <Table /> */}</TabPanel>
                <TabPanel value="8">
                  <EditCompany />
                </TabPanel>
              </TabContext>
            </Box>
          </>
        ) : pathname === "/mySettings/chat" ? (
          <MainWebsiteSettings />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MySettings;
