import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CloseButton } from '../../../components/CloseButton';
import { deleteSubPipeline } from '../../../webservices/PipelineService';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function DeleteSubPipeline(props) {

    const queryClient = useQueryClient();
    const [open, setOpen] = React.useState(true);

    const { mutateAsync: handleDelete } = useMutation({
        mutationKey: "updateSubPipelineName",
        mutationFn: () => deleteSubPipeline(props.teamPipelineId, props.subPipeline._id),
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
            props.handleClose(response);
        }
    });

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    }

    return (
        <div>
            <Modal open={open} >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px"
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Delete Sub-Pipeline
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ mt: 2 }}>
                        Are you sure you want to remove the sub-pipeline <em>{props.subPipeline ? props.subPipeline.subPipelineName : props?.item?.label}?</em>
                    </Typography>

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="error" onClick={handleDelete}>Delete Sub-Pipeline</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}