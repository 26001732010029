import * as React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Grid, IconButton, MenuItem, Select, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import variables from '../../../assets/styles/_colors.scss';
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { withStyles } from "@material-ui/core/styles";
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { stringAvatar } from '../../../utils/PipelineConstants';
import { Notes } from '../../../components/notes/Notes';
import ActivitesTab from '../../pipelines/view/ActivitiesPipelineDetailedView';
import Emails from '../../pipelines/view/Emails';
import FileUpload from '../../../components/files/FilesUpload';
import DeleteCompany from '../DeleteCompany';
import UpsertCompany from '../create/UpsertCompany';
import PipelineTimeline from '../../pipelines/view/PipelineTimeline';
import { CompanyInfo } from './CompanyInfo';
import PipelinesTab from '../../contacts/view/PipelineTableContactDetailedView';
import ContactsTab from './ContactsTableCompanyDetailedView';

const StyledIcon = withStyles({
    root: {
        backgroundColor: `${variables.listBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.listBackgroundColor} !important`
        }
    }
})(IconButton);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 0,
    flexGrow: 1,
    minHeight: '100vh',
    minWidth: '100vw',
    height: '100vh',
    width: '100vw'
};

const useStyles = makeStyles((theme) => ({
    listItemSelected: {
        backgroundColor: `${variables.listBackgroundColor} !important`,
    },
}));

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important',
        fontWeight: 'bold !important',
    }
})(Tab);

const StyledTableSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

const StyledLink = styled(Link)`
     color: #212129;
`;

const otherInfo = [
    {
        label: "Custom Field 1",
        value: "Holaaaaa"
    }];

export default function CompaniesDetailedView(props) {

    const locationData = useLocation();
    const classes = useStyles();
    const [selectedItem, setSelectedItem] = useState(null);
    const [editCompany, setEditCompany] = useState(false);
    const [deleteCompany, setDeleteCompany] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedTab, setSelectedTab] = useState("0");

    const notesFilter = [
        {
            label: "All Notes",
            value: "all"
        },
        {
            label: "This Company's Notes",
            value: "pipeline"
        }
    ];

    const timelineOptions = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Calls",
            value: "calls"
        },
        {
            label: "Notes",
            value: "notes"
        },
        {
            label: "Tasks",
            value: "tasks"
        },
        {
            label: "Events",
            value: "events"
        },
        {
            label: "Files",
            value: "files"
        }
    ]
    useEffect(() => {
        if (locationData?.state) {
            const item = locationData?.state?.item;

            setSelectedItem(item);
            setSelectedItemId(item?.id);
        }
    }, [locationData?.state])

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
    }

    return (
        <div>
            <Box sx={style}>
                <Grid container direction="row" sx={{ mt: 6, ml: 7.25, width: 'auto' }}>
                    <Grid item xs={12} sx={{ backgroundColor: `${variables.lightGreyBackgroundColor}`, borderBottom: `1px solid ${variables.borderColor}` }}>
                        <Grid container direction="row" sx={{ p: 2, pb: 0, pl: 1 }}>
                            <Grid item xs={0.25}>
                                <IconButton>
                                    <StyledLink to={-1}><KeyboardBackspaceIcon /></StyledLink>
                                </IconButton>
                            </Grid>
                            <Grid item xs={5} sx={{ pl: 2, pb: 1.5 }}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Stack direction="column">
                                        {selectedItem && selectedItem.companyName && <Avatar {...stringAvatar(`${selectedItem?.companyName}`)} />}
                                    </Stack>
                                    <Stack direction="column">
                                        <Stack direction="row" sx={{ cursor: 'pointer', color: `${variables.linkColor}`, fontWeight: 'bold' }}>
                                            {selectedItem?.companyName}
                                        </Stack>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography sx={{ color: `${variables.greyTextColor}`}}>
                                                <PersonIcon size="small" sx={{ width: 16, height: 16, mt: -0.25 }} />
                                                <Typography component="span" sx={{ pl: 0.5, fontSize: "12px" }}>{selectedItem?.companyOwner}</Typography>
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6.75} sx={{}}>
                                <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                                    <StyledIcon onClick={() => setEditCompany(true)}>
                                        <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                                    </StyledIcon>
                                    <StyledIcon onClick={() => setDeleteCompany(true)}>
                                        <DeleteOutlineIcon fontSize='medium' color="error" />
                                    </StyledIcon>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" sx={{ p: 0, pl: 1 }}>
                        <Grid item xs={3}>
                            <Box sx={{ bgcolor: "#fff", height: '83vh', overflowX: "hidden", overflowY: "auto", borderRight: `1px solid ${variables.borderColor}` }}>
                                {<CompanyInfo item={selectedItem} showStats={true} />}
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Tabs value={selectedTab} onChange={handleTabChange}>
                                <StyledTab label="Timeline" value="0" />
                                <StyledTab label="Notes" value="1" />
                                <StyledTab label="Activities" value="2" />
                                <StyledTab label="Emails" value="3" />
                                <StyledTab label="Pipelines" value="4" />
                                <StyledTab label="Contacts" value="5" />
                                <StyledTab label="Files" value="6" />
                            </Tabs>

                            <Box sx={{ overflow: 'hidden', p: 1, bgcolor: `${variables.tabsBackgroundColor}` }}>
                                {
                                    selectedTab === "0" &&
                                    <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <Stack direction="row" width="100%" alignItems="center" sx={{ pl: 2, pr: 1, pt: 1 }}>
                                            <Stack direction="row" justifyContent="left" width="50%">
                                                <Typography sx={{ fontWeight: "bold" }}>History</Typography>
                                            </Stack>
                                            <Stack direction="row" justifyContent="right" width="50%" alignItems="center">
                                                <Typography sx={{ pr: 2 }}>Filter: </Typography>
                                                <Select
                                                    defaultValue={timelineOptions[0]?.value}
                                                    size="small"
                                                >
                                                    {timelineOptions?.map(option => {
                                                        return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                    })}
                                                </Select>
                                            </Stack>
                                        </Stack>
                                        <PipelineTimeline />
                                    </Box>
                                }
                                {
                                    selectedTab === "1" &&
                                    <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <Select
                                            defaultValue={notesFilter[0]?.value}
                                            sx={
                                                [{ mt: 1, fontWeight: 'bold' }, {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none'
                                                    }
                                                }]
                                            }
                                            size="small"
                                        >
                                            {notesFilter?.map(option => {
                                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            })}
                                        </Select>
                                        <Notes />
                                    </Box>
                                }

                                {selectedTab === "2" &&
                                    <Box sx={{ overflow: "hidden", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <ActivitesTab view="companies" />
                                    </Box>
                                }

                                {selectedTab === "3" &&
                                    <Box sx={{ overflow: "hidden", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <Emails />
                                    </Box>
                                }

                                {selectedTab === "4" &&
                                    <Box sx={{ overflow: "hidden", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <PipelinesTab />
                                    </Box>
                                }

                                {selectedTab === "5" &&
                                    <Box sx={{ overflow: "hidden", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <ContactsTab />
                                    </Box>
                                }

                                {selectedTab === "6" &&
                                    <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <FileUpload />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {deleteCompany &&
                <DeleteCompany item={selectedItem} module={props.module} handleClose={() => { setDeleteCompany(false) }} />}

            {editCompany &&
                <UpsertCompany edit={true} module={props.module} handleDrawerClose={() => { setEditCompany(false) }} />}
        </div >
    );
}
