import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { CloseButton } from "../../../../components/CloseButton";
import { useState } from "react";

const fields = [
    {
        label: "Mobile",
        value: "mobile"
    },
    {
        label: "Company Name",
        value: "companyName"
    },
    {
        label: "Location",
        value: "location"
    },
    {
        label: "Contact Name",
        value: "contactName"
    }
];

const UpdateField = (props) => {

    const [open, setOpen] = useState(true);
    const [selectedField, setSelectedField] = useState("");

    function handleDialogClose(type) {
        setOpen(false);
        props.handleDialogClose(type);
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="update-field-dialog"
                PaperProps={{
                    sx: { minWidth: '40%', maxWidth: '40%', overflow: 'hidden' }
                }}>

                <DialogTitle id="update-field-dialog-title">
                    Update Field
                    <CloseButton handleDialogClose={handleDialogClose} />
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 1 }}>
                        <InputLabel shrink id="select-field-input-label">Select a field</InputLabel>
                        <Select
                            displayEmpty
                            value={selectedField}
                            onChange={(event) => setSelectedField(event.target.value)}
                        >
                            <MenuItem value="" disabled>Select</MenuItem>
                            {fields?.map(option => {
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </DialogContent>

                <DialogActions>
                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1.5, pr: 1.5 }}>
                        <Button variant="outlined" onClick={handleDialogClose}>Cancel</Button>
                        <Button variant="contained" color="error" onClick={handleDialogClose} disabled={!selectedField}>Delete</Button>
                    </Stack>
                </DialogActions>

            </Dialog>
        </>
    )

}

export default UpdateField;