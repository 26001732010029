import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import VisitorsList from "./VisitorList";
import Unread from "./Unread";
import Unresolved from "./Unresolved";
import { Popover, MenuItem } from "@mui/material";

const ChatTabPanel = () => {
  const [value, setValue] = React.useState("1");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "300px",borderRight: "1px solid #ddd", typography: "body1",height:"calc(100vh - 50px)" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", width: "300px",borderRight: "1px solid #ddd",paddingTop:"10px" }}>
          <TabList
            onChange={handleChange}
            sx={{ display: "flex", gap: "15px", p:0 }}
          >
            <Tab
              label="All"
              value="1"
              sx={{
                padding: "0px 11px 9px 11px",
                minWidth: 0,
                fontSize: "12px",
                fontFamily: "Inter",
              }}
            />
            <Tab
              label="Unread"
              value="2"
              sx={{
                padding: "0px 11px 9px 11px",
                minWidth: 0,
                fontSize: "12px",
                fontFamily: "Inter",
              }}
            />
            <Tab
              label="Unresolved"
              value="3"
              sx={{
                padding: "0px 11px 9px 11px",
                minWidth: 0,
                fontSize: "12px",
                fontFamily: "Inter",
              }}
            />
            <Tab
              label="Filters"
              value="4"
              onClick={(event) => setAnchorEl(event.currentTarget)}
              sx={{
                padding: "0px 11px 9px 11px",
                minWidth: 0,
                fontSize: "12px",
                fontFamily: "Inter",
              }}
            />
          </TabList>

          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              position: "fixed",
              zIndex: "1500",
              right: "230px",
              bottom: "0",
              top: "47px",
            }}
          >
            <MenuItem
              onClick={() => {
                handleChange(null, "resolved");
                handleClose();
              }}
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "#0079c4",
                  color: "white",
                },
              }}
            >
              Resolved
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange(null, "mostResolved");
                handleClose();
              }}
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "#0079c4",
                  color: "white",
                },
              }}
            >
              Assigned To me
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleChange(null, "resolved");
                handleClose();
              }}
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: "#0079c4",
                  color: "white",
                },
              }}
            >
              Unassigned
            </MenuItem>
          </Popover>
        </Box>
        <TabPanel value="1" sx={{padding:"0px !important"}}>
          <VisitorsList />
        </TabPanel>
        <TabPanel value="2" sx={{padding:"0px !important"}}>
          <Unread />
        </TabPanel>
        <TabPanel value="3" sx={{padding:"0px !important"}}>
          <Unresolved />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default ChatTabPanel;
