import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { api_url } from "../../globalSettings";
import {
 
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,

  Drawer,

  Avatar,
  Typography,
} from "@mui/material";

import { Box } from "@mui/system";

//importing useRecoilState
import { useRecoilState } from "recoil";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import DialogTitle from "@mui/material/DialogTitle";

import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
//import recoil state
// import {
//   login_atoms,
//   login_token,
//   login_companyid,
// } from "../../../Atoms/LoginAtoms";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import {

  login_token,
  login_companyid,
} from "../../Atoms/LoginAtoms";
import { styled } from "@mui/system";
import  { createFilter } from "react-search-input";

// import AddCompany from "./AddCompany";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(5),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 0,
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 0,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const time = [
  { id: 1, day: "TODAY" },
  { id: 2, day: "YESTERDAY" },
  { id: 3, day: "THIS WEEK" },
  { id: 4, day: "LAST WEEK" },
  { id: 5, day: "THIS MONTH" },
  { id: 6, day: "LAST MONTH" },
  // { id: 7, day: "Sunday" },
];

const status = [
  { id: 1, status: "ALL" },
  { id: 2, status: "PENDING" },
  { id: 3, status: "APPROVED" },
  { id: 4, status: "REJECTEDS" },
  // { id: 7, day: "Sunday" },
];

const KEYS_TO_FILTERS = ["first_name", "last_name"];

function LeaveAproval() {
  //================================  EMPLOYEE LIST =======================

  const [employeList, setGetEmployeelist] = useState([]);

  //const [taskempId , setTaskempId] = useState('')

  //=========================== Drawer1 ========================

  const [openDrawer, setopenDrawer] = useState(true);
  const [selectAll, setSelectAll] = useState(false);

  const toggleDrawer = (event) => {
  
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  const filteredItems = employeList.filter(
    createFilter(searchTerm, KEYS_TO_FILTERS)
  );



  const [Getlist, setGetlist] = useState([]);
  const [GetDepartmentlist, setGetDepartmentlist] = useState([]);



   const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);
 
  // ======================================= Api company list =================================================
  const [branchid, setBranchid] = useState("");


  const handleChange2 = (event) => {
    setBranchid(event.target.value);
  };

  const [dateId, setDateId] = useState("");


  const [statusId, setStatusId] = useState("");

  const handleChange4 = (event) => {
    setStatusId(event.target.value);
  };

  function getBranchList() {
    const params = {
      company_id: company_idd,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
        setBranchid(responce.data.data[0]._id);
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    getBranchList();
    setDateId(time[0].id);
    setStatusId(status[0].id);
  }, []);

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClickClose = () => {
    setOpen1(false);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen2(true);
  };

  const handleClickClose1 = () => {
    setOpen2(false);
    getLeaveList();
  };


  const [SelectedEmploye, setSelectedEmploye] = useState([]);


  function leaveAproval() {
    const params = JSON.stringify({
      token: loginToken1,
      branch_id: branchid,
      employee_id: SelectedEmploye.employee_id,
      employee_leave_id: SelectedEmploye._id,
      leave_status: "approved",
    });

    axios
      .patch(`${api_url}/LeaveApprovalStatus`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        // setSnackbaropen({
        //   open: true,
        //   message: "Shift Added successfully",
        //   severity: "success",
        //   actionColor: "#edf7ed",
        // });
        // getShiftList();
        handleClickClose1();
      
      })

      .catch(function (error) {
        if (error.response) {
         
        } else if (error.request) {
       
        } else {
       
        }
    
      });
  }

  function leaveCancel() {
    const params = JSON.stringify({
      token: loginToken1,
      branch_id: branchid,
      employee_id: SelectedEmploye.employee_id,
      employee_leave_id: SelectedEmploye._id,
      leave_status: "rejected",
    });


    axios
      .patch(`${api_url}/LeaveApprovalStatus`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
    
        // setSnackbaropen({
        //   open: true,
        //   message: "Shift Added successfully",
        //   severity: "success",
        //   actionColor: "#edf7ed",
        // });
        // getShiftList();
        getLeaveList();
        //setOpen2(false);
        handleClickClose();
      })

      .catch(function (error) {
        if (error.response) {
      
        } else if (error.request) {
      
        } else {
        
        }
    
      });
  }

  // ======================================= Api Department list =================================================


  const [Status, setStatus] = useState("");

  useEffect(() => {
    if (statusId == 1) {
      setStatus("all");
    }
    if (statusId == 2) {
      setStatus("pending");
    }
    if (statusId == 3) {
      setStatus("approved");
    }
    if (statusId == 4) {
      setStatus("rejected");
    }
  }, [statusId]);

  function getLeaveList() {
 
    const params = {
      token: loginToken1,
      branch_id: branchid,
      status: Status,
    };


    axios
      .get(`${api_url}/get_All_EmpLeaveList`, { params: { ...params } })
      .then((responce) => {
        setGetDepartmentlist(responce.data.data);

        setopenDrawer(false);
      })
      .catch((error) => {
        if (error.response) {
        
        } else if (error.request) {
    
        } else {
      
        }
     
      });
  }
  function getEmployeeList() {
    const params = {
      branch_id: branchid,
      token: loginToken1,
    };


    axios
      .get(`${api_url}/getEmployeeList`, { params: { ...params } })
      .then((responce) => {
        setGetEmployeelist(responce.data.data);
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    getEmployeeList();
  }, [branchid]);

  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  useEffect(() => {
    if (dateId == 1) {
      var start = new Date();
      start.setHours(0, 0, 0, 0);
      setStart(start.toISOString());
      var end = new Date();
      end.setHours(23, 59, 59, 999);

      setEnd(end.toISOString());

    } else if (dateId == 2) {
     

      const start = new Date();
      start.setHours(0, 0, 0, 0);

      setStart(start.toISOString());

      const end = new Date();
      end.setDate(end.getDate() - 1);
      end.setHours(23, 59, 59, 999);
      setEnd(end.toISOString());

     
    } else if (dateId == 3) {
     
      var curr = new Date(); 
      var first = curr.getDate() - curr.getDay(); 
      var last = first + 6; 

      var firstDay = new Date(curr.setDate(first)).toISOString();
      var lastDay = new Date(curr.setDate(last)).toISOString();


      setStart(firstDay);
      setEnd(lastDay);
    } else if (dateId == 4) {
    
      var beforeOneWeek = new Date(
        new Date().getTime() - 60 * 60 * 24 * 7 * 1000
      );
      var beforeOneWeek2 = new Date(beforeOneWeek);
      var day0 = beforeOneWeek.getDay();
      var diffToMonday = beforeOneWeek.getDate() - day0 + (day0 === 0 ? -6 : 1);
      var lastMonday = new Date(
        beforeOneWeek.setDate(diffToMonday)
      ).toISOString();
      var lastSunday = new Date(
        beforeOneWeek2.setDate(diffToMonday + 6)
      ).toISOString();

      setStart(lastMonday);
      setEnd(lastSunday);

      // var curr = new Date; // get current date
      // var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      // var last = first + 6; // last day is the first day + 6

      // var lastMonday = new Date(curr.setDate(first)).toLocaleDateString();
      // var lastSunday = new Date(curr.setDate(last)).toLocaleDateString();
    } else if (dateId == 5) {
      // setToday(false);
      // setYesterday(false);
      // setThisWeek(false);
      // setLastWeek(false);
      // setLastMonth(false);
      // setThisMonth(true);
      var date = new Date();
      var firstDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        1
      ).toISOString();
      var lastDay = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      ).toISOString();


      setStart(firstDay);
      setEnd(lastDay);
    } else if (dateId == 6) {
     
      var date = new Date();
      var firstDay = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        1
      ).toISOString();
      var lastDay = new Date(
        date.getFullYear(),
        date.getMonth(),
        0
      ).toISOString();


      setStart(firstDay);
      setEnd(lastDay);
    }
  }, [dateId]);

  return (
    <div className="companylist">
      {/* ======================================================== Company Table ======================================================= */}

      <div className="company_table">
        <Box>
    
          <div className="reporttop" style={{ marginBottom: "20px" }}>
            <Typography>EMPLOYEE LEAVE LIST</Typography>
            <Typography onClick={toggleDrawer} style={{ cursor: "pointer" }}>
              <FilterAltOutlinedIcon />
            </Typography>
          </div>

          <TableContainer
            component={Paper}
            className="tblcontainer"
            style={{ height: "auto" }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table_head ">Employe Name</TableCell>
                  <TableCell className="table_head">Department</TableCell>
                  <TableCell className="table_head">Leave Type</TableCell>
                  <TableCell className="table_head">From</TableCell>
                  <TableCell className="table_head">To</TableCell>
                  <TableCell className="table_head">No Of Days</TableCell>
                  <TableCell className="table_head">Reason</TableCell>
                  <TableCell className="table_head">Status</TableCell>
                </TableRow>
              </TableHead>

              {GetDepartmentlist.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.branchid1}
                    onClick={() => {
                      setSelectedEmploye(item);
                    }}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                  >
                    <TableCell className="company_name" title={item.employee}>
                      <div>
                        <Avatar style={{ marginRight: 15 }} />
                      </div>
                      {item.employee}
                    </TableCell>
                    <TableCell
                      title={item.department}
                      className="companypersonalinfo"
                    >
                      {item.department}
                    </TableCell>
                    <TableCell
                      title={item.leave_type}
                      className="companypersonalinfo"
                    >
                      {item.leave_type}
                    </TableCell>
                    <TableCell
                      title={item.from}
                      className="companypersonalinfo"
                    >
                      {item.from}
                    </TableCell>
                    <TableCell title={item.to} className="companypersonalinfo">
                      {item.to}
                    </TableCell>
                    <TableCell
                      title={item.no_of_days}
                      className="companypersonalinfo"
                    >
                      {item.no_of_days}
                    </TableCell>
                    <TableCell
                      title={item.reason_for_leave}
                      className="companypersonalinfo"
                    >
                      {item.reason_for_leave}
                    </TableCell>

                    <TableCell
                      title={item.leave_status}
                      className="companypersonalinfo"
                    >
                      {item.leave_status}
                    </TableCell>

                    {item.leave_status == "pending" ? (
                      <div class="overlay" style={{ width: "20%" }}>
                        <div class="text">
                          <Button
                            onClick={handleClickOpen}
                            style={{ fontWeight: 600 }}
                          >
                            Cancel
                          </Button>
                          <Dialog
                            open={open1}
                            //   TransitionComponent={Transition}
                            BackdropProps={{
                              style: { backgroundColor: "#75757542" },
                            }}
                            PaperProps={{
                              style: {
                                boxShadow: "none",
                              },
                            }}
                            keepMounted
                            onClose={handleClickClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogTitle>
                              {
                                "Are you sure you want to Cancel Employee Leave?"
                              }
                            </DialogTitle>

                            <DialogActions>
                              <Button onClick={handleClickClose}>
                                Disagree
                              </Button>
                              <Button onClick={leaveCancel}> Agree</Button>
                            </DialogActions>
                          </Dialog>
                          <Button
                            onClick={handleClickOpen1}
                            style={{ fontWeight: 600 }}
                          >
                            Approved
                          </Button>
                          <Dialog
                            open={open2}
                            //   TransitionComponent={Transition}
                            BackdropProps={{
                              style: { backgroundColor: "#75757542" },
                            }}
                            PaperProps={{
                              style: {
                                boxShadow: "none",
                              },
                            }}
                            keepMounted
                            onClose={handleClickClose1}
                            aria-describedby="alert-dialog-slide-description"
                          >
                            <DialogTitle>
                              {
                                "Are you sure you want to Approve Employee Leave?"
                              }
                            </DialogTitle>

                            <DialogActions>
                              <Button onClick={handleClickClose1}>
                                Disagree
                              </Button>
                              <Button onClick={leaveAproval}> Agree</Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      </div>
                    ) : (
                      " "
                    )}

                
                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      </div>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer}
       
      >
        <div id="drawer" style={{ width: "250px " }}>
       
          <InputLabel
            htmlFor="demo-customized-select-native"
            sx={{ ml: 1, mt: 1, fontSize: 12 }}
          >
            Select Your Branch
          </InputLabel>
          <FormControl
            sx={{ m: 1 }}
            variant="standard"
            style={{ minWidth: "95%" }}
            size="small"
          >
            <NativeSelect
              id="demo-customized-select-native"
              defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
              onChange={handleChange2}
              input={<BootstrapInput />}
            >
           
              {Getlist.map((item) => {
                return (
                  <>
                    <option value={item.id}>{item.label}</option>
              
                  </>
                 
                );
              })}
            </NativeSelect>
          </FormControl>
     
          <InputLabel
            htmlFor="demo-customized-select-native"
            sx={{ ml: 1, mt: 1, fontSize: 12 }}
          >
            Status Selection :
          </InputLabel>
          <FormControl
            sx={{ m: 1 }}
            variant="standard"
            style={{ minWidth: "95%" }}
            size="small"
          >
            <NativeSelect
              id="demo-customized-select-native"
              defaultValue={status[0].id}
              onChange={handleChange4}
              input={<BootstrapInput />}
            >
              {status.map((item) => {
                return (
                  <>
                    <option value={item.id}>{item.status}</option>
                  </>
                );
              })}
            </NativeSelect>
          </FormControl>
     
          <div className="buttons">
            <Button className="button1" onClick={() => getLeaveList()}>
              apply
            </Button>
          </div>
       
        </div>
      </Drawer>
    </div>
  );
}

export default LeaveAproval;
