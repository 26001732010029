import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import variables from "../../../../assets/styles/_colors.scss";
import { useState } from "react";
import { flexRender } from "@tanstack/react-table";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ActivityDetailedView from "../../../activities/view/table/ActivityDetailedView";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import CompanyQuickPreview from "../CompanyQuickPreview";
import DeleteCompany from "../../DeleteCompany";
import UpsertCompany from "../../create/UpsertCompany";

const StyledLink = styled(Link)`
    display: block;
    color: #212129;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export default function CompanyTableRow(props) {

    const rowEl = props.rowEl;
    const [isHovering, setIsHovering] = useState(false);
    const [editCompany, setEditCompany] = useState(false);
    const [deleteCompany, setDeleteCompany] = useState(false);
    const [showQuickView, setShowQuickView] = useState(false);
    const [showDetailedView, setShowDetailedView] = useState(false);

    return (
        <tr key={rowEl?.original?.id} id={rowEl?.original?.id} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
            style={{ position: "relative", backgroundColor: isHovering ? `${variables.tableBackgroundColor}` : '#fff' }}
        >
            {rowEl.getVisibleCells().map((cellEl) => {
                return (
                    <td key={cellEl.id}>
                        {(cellEl.column.columnDef.accessorKey === "companyName")
                            ? <>
                                <StyledLink to={`/crm/companies/${rowEl?.original?.id}`} state={{
                                    item: rowEl?.original
                                }}
                                    style={{ color: isHovering ? `${variables.linkColor}` : "#000" }} >
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                    <Typography component="span" sx={{ visibility: isHovering ? 'inherit' : 'hidden', pl: 1.5 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowQuickView(true) }}>
                                        <Tooltip title="Quick Preview" placement="right">
                                            <IconButton sx={{ p: 0 }}>
                                                <VisibilityOutlinedIcon fontSize="small" color={variables.greyTextColor} />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </StyledLink>
                            </>

                            : ((cellEl.column.columnDef.accessorKey === "website"))
                                ?  rowEl.original.website && <>
                                    <Typography component="a"
                                        sx={{ color: isHovering ? `${variables.linkColor}` : `${variables.pipelineBoardText}` }}
                                        target="_blank" rel="noreferrer" href={rowEl?.original?.website}>
                                        {flexRender(
                                            cellEl.column.columnDef.cell,
                                            cellEl.getContext()
                                        )}
                                    </Typography>
                                </>
                                :
                                <>
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                </>
                        }
                    </td>
                );
            })}
            <Stack direction="row" spacing={1} alignItems="center" sx={{
                visibility: isHovering ? 'inherit' : 'hidden',
                height: "47px",
                zIndex: 5,
                width: "85px",
                bgcolor: `${variables.lightGreyBackgroundColor}`,
                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                position: 'sticky', right: 0.5,
                pl: 0.5
            }}>
                <IconButton onClick={() => setEditCompany(true)} sx={{ height: "35px", width: "35px" }}>
                    <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                </IconButton>

                <IconButton onClick={() => setDeleteCompany(true)} sx={{ height: "35px", width: "35px" }}>
                    <DeleteOutlineIcon fontSize='medium' color="error" />
                </IconButton>
            </Stack>

            {showQuickView && <CompanyQuickPreview module={props.module} handleDrawerClose={() => { setShowQuickView(false); setIsHovering(false); }} item={rowEl?.original} />}

            {
                deleteCompany &&
                <DeleteCompany item={rowEl?.original} handleClose={() => { setDeleteCompany(false); setIsHovering(false); }} />
            }

            {
                editCompany &&
                <UpsertCompany edit={true} editData={rowEl.original} module={props.module} handleDrawerClose={() => { setEditCompany(false); setIsHovering(false); }} />
            }

            {
                showDetailedView &&
                <ActivityDetailedView module={props.module} item={rowEl?.original} handleClose={(val) => setShowDetailedView(false)} />
            }

        </tr >
    )
}
