import { Tab, Tabs, Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { useRef, useState } from "react";
import WebsiteSettings from "./WebSiteSettings/WebsiteSettings";
import MessageShortcuts from "./MessageShortcuts/MessageShortcuts";
import Availability from "./Availability/Availability";
import  Notifications from "./Notifications/Notifications";
const theme = createTheme();
  

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        height: "90%",
        overflowY: "hidden"
      }}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "100%",overflowY: "auto",}}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  tabsContainer: {
    overflowX: 'auto',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  tabLabel: {
    minHeight: 20,
    padding: "16px 16px"
  },
  smallTabs: {
    minHeight: 25,
  },
}));
function LiveChat({handleClose}) {
  const classes = useStyles();
  // const tabsRef = useRef(null);
  const [value, setValue] = useState(0);
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <ThemeProvider theme={theme}>
    <div style={{
      // border: "1px solid red",
      height: "100%"
    }}>
      <div style={{
        // border:"1px solid black",
        height: "10%",
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        position: "relative"
      }}>
         <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            className={`${classes.tabsContainer} ${classes.smallTabs}`}
            style={{
              // marginLeft: "-1%",
            }}
            sx={{
              ".MuiTab-root .MuiButtonBase-root": {},
              ".MuiTabs-indicator": {
                height: "5px",
                backgroundColor: "rgba(0, 10, 255, 1)",
                borderRadius: "10px",
              },
            }}
          >
            <Tab label="Website Settings" className={classes.tabLabel} />
            <Tab label="Message Shortcuts" className={classes.tabLabel} />
            <Tab label="Availability" className={classes.tabLabel} />
            <Tab label="Notifications" className={classes.tabLabel} />
            
          </Tabs>
          <CloseIcon
            onClick={handleClose}
            style={{
              width: "16px",
              height: "16px",
              marginLeft: "16px",
              marginRight: "10px",
              cursor: "pointer",
              position: "absolute",
              // top: "1",
              right: "5",
              alignSelf: "center"
            }}
          />
        </div>
        <CustomTabPanel value={value} index={0}>
          <WebsiteSettings />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <MessageShortcuts />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Availability />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Notifications />
        </CustomTabPanel>

      </div>
      
    
    </ThemeProvider>
  )
}

export default LiveChat