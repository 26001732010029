

import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,

  OverlayView
} from "@react-google-maps/api";
import "./MapComponentNew.css";
import InfoWindows from "./InfoWindows";
import { api_url } from "../../globalSettings";
import {

  login_token,

  selectedEmp,
  selectedEmpPosition,
  ZoomLevel
} from "../../Atoms/LoginAtoms";
import axios from "axios";
import { useRecoilState } from "recoil";

import { Button } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import Addpoi from "./Addpoi";
import marker1 from '../../images/location.png'
import marker2 from '../../images/pin-map.png'
import {

  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

const markers = [
  {
    id: 1,
    name: "Chicago, Illinois",
    position: { lat: 13.0827, lng: 80.2707 },
  },
  {
    id: 2,
    name: "Denver, Colorado",
    position: { lat: 10.7905, lng: 78.7047 },
  },
  {
    id: 3,
    name: "Los Angeles, California",
    position: { lat: 8.0883, lng: 77.5385 },
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function MapComponentNew(props) {
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [SelectedEmp, setSelectedEmp] = useRecoilState(selectedEmp);
  const [selectedElement, setSelectedElement] = useState(null);
  const [SelectedEmpPosition ,setSelectedEmpPosition ] =  useRecoilState(selectedEmpPosition);
  const [zoomLevel, setZoomLevel] =useRecoilState(ZoomLevel);

  const [activeMarker, setActiveMarker] = useState(null);
  
  const { totemplist, empListonduty, empListoffduty, emponlvelist , empnodatalist} = props;

  const [SocketData, setSocketData] = useState([]);
  

  function getSocketDetails() {
    
    const params = {
      token: loginToken1,
      emp_attendance_id: SelectedEmp._id,
      employee_id: SelectedEmp.employee_id._id,
    };
   

    axios
      .get(`${api_url}/employee/get_employee_socket_details`, {
        params: { ...params },
      })

      .then((response) => {
        
        setSocketData(response.data.data);
       
      })
      .catch(function (error) {
        if (error.response) {
        
        } else if (error.request) {

        } else {
         
        }
       
      });
    
  }

  

  useEffect(() => {
    if (Object.keys(SelectedEmp).length >= 1) {
       const interval = setInterval(() => {
        getSocketDetails();
       }, 10000);
      return () => clearInterval(interval);
    }
  }, [SelectedEmp]);

  let iconMarker = 
  new window.google.maps.MarkerImage(
    marker2 ,
    null ,
    null ,
    null ,
    new window.google.maps.Size(40, 40)
  );

  let iconMarker1 = new window.google.maps.MarkerImage(
    marker1,
    null ,
    null ,
    null,
    new window.google.maps.Size(40, 40)
  );

  const [map, setMap] = useState(null);



  const handleActiveMarker = (marker) => {
    setActiveMarker(marker);

    if (marker === activeMarker) {
      return;
    }

  };

  const handleOnLoad = (map) => {
    setMarkerRef(map);
    setMap(map)
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };


  const iconsetngs = {
    path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
  };



  const [anchore1, setAnchore1] = useState(null);

  const open = Boolean(anchore1);
  const [open1, setOpen1] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen1 = (scrollType) => () => {
    setOpen1(true);
    setScroll(scrollType);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const [markerRef, setMarkerRef] = useState(null);


  const [isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    if (map && SelectedEmpPosition) {

      const targetZoom = 20;
      const duration = 1000; 
      const interval = 10; 

      const initialZoom = map.getZoom();
      const zoomStep = (targetZoom - initialZoom) / (duration / interval);

      const animateZoom = () => {
        setZoomLevel((prevZoom) => prevZoom + zoomStep);
        if (zoomLevel < targetZoom) {
          requestAnimationFrame(animateZoom);
        }
      };

      animateZoom();
    }
  }, [map, SelectedEmpPosition]);

  
  
  const renderOverlayView = (marker) => {
    return (
      marker.current_location_latitude == "" ? "" : <OverlayView
        key={marker._id}
        position={{
          lat: parseFloat(marker.current_location_latitude),
          lng: parseFloat(marker.current_location_longitude),
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <div
          style={{
            position: 'relative',
            width: '100px',
            height: '40px',
            textAlign: 'center',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '-65px',
              left: '-40px',
              color: 'white',
              backgroundColor: marker.duty_status === 'off_duty' ? '#eb5569' : '#62c89b',
              borderRadius: '5px',
              padding: '5px',
            }}
          >
            {marker.employee_id.first_name} {marker.employee_id.last_name}
          </div>
        </div>
      </OverlayView>
    );
  };
  
  


  return (
    <>
    <GoogleMap
      onLoad={handleOnLoad}
      center={SelectedEmpPosition ? SelectedEmpPosition : { lat: 0, lng: 0 }}
      zoom={zoomLevel}      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      {(
        totemplist.all_employee_details ||
        empListonduty.on_duty_employee_details ||
        empListoffduty.off_duty_employee_details ||
        emponlvelist.leave_employee_details ||
        empnodatalist.noData_employee_details
      ).map((k, id) => (
       <React.Fragment key={id}>
        {k.employes.map((k) => ( <>
           <Marker
ref={markerRef}
  key={id}
  animation={window.google.maps.Animation.DROP}
  icon={k.duty_status === "off_duty" ? iconMarker1 : iconMarker }
  //onLoad={handleMarkerLoad}
  position={{
    lat: parseFloat(k.current_location_latitude),
    lng: parseFloat(k.current_location_longitude),
    // lat: 10.7905,
    // lng: 78.7047,
  }}
  onClick={(props, marker) => {
    setIsOpen(true);
    setSelectedElement(k);

    setActiveMarker(marker);
    handleActiveMarker(k._id);
  }}
>


  {activeMarker === k._id  ? (
    <InfoWindow  anchor={markerRef.current} onCloseClick={() => setIsOpen(false)}>
      <>
        <div className="infotitle">
          {k.employee_id.first_name} {k.employee_id.last_name}
        </div>
        <InfoWindows selectedElement={selectedElement} />
        <div className="infofooter">
          <Button className="infobtn">Add Geofence</Button>
          <Button
            className="infobtn"
            onClick={handleClickOpen1("paper")}
          >
            Add POI
          </Button>
          <Dialog
            open={open1}
            onClose={handleClose1}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle
              id="scroll-dialog-title"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              ADD POI{" "}
              <Button onClick={handleClose1}>
                <CloseIcon />
              </Button>
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <Addpoi handleClose={handleClose1} SelectedEmp={k} />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Button className="infobtn">Navigate</Button>
        </div>
      </>
    </InfoWindow>
  ) : (
    ""
  )}
</Marker>
{renderOverlayView(k)}
       </> ))}
     </React.Fragment>
      ))}


    </GoogleMap>
    </>
  );
}

export default MapComponentNew;



{/*  */}