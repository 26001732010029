import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import KpiImg from "../../Images/TopBar/ComboChart.png";
import BarChartImg from "../../Images/TopBar/BarChart.png";
import speedImg from "../../Images/TopBar/Speed.png";
import Style from "../../Style/TopBar/TopBar.module.css";
import { Divider } from "@mui/material";
import CustomDrawer from "./Component/CustomDrawer";

const buttonStyle = {
  height: "32px",
  color: "#FFFFFF",
  fontWeight: 600,
  fontSize: "14px",
  textTransform: "capitalize",
  background: "#1A23FF",
  borderRadius: "18px",
};
export default function ComponentDropDown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [seletced, setSelected] = React.useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = [
    {
      img: KpiImg,
      label: "KPI",
      id: "#kpi",
    },
    {
      img: BarChartImg,
      label: "BarChart",
      id: "#barChart",
    },
    {
      img: speedImg,
      label: "TargetMeter",
      id: "#targetMeter",
    },
  ];
  const [openDrawer, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  function handleChange(data) {
    setSelected(data);
    handleOpen();
    handleClose();
  }
  return (
    <div style={{ paddingRight: "10px" }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={buttonStyle}
        sx={{
          "& .MuiButton-startIcon": {
            margin: "0 !important",
          },
        }}
        startIcon={<AddIcon />}
      >
        Component
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          style: {
            padding: 0,
            border: "1px solid #D1DAFF",
            borderRadius: "4px !important",
            color: "#000720",
          },
        }}
      >
        {options.map((data, index) => (
          <div key={data.id}>
            <MenuItem
              onClick={() => handleChange(data.label)}
              sx={{
                padding: 0,
                height: "38px",
                width: "131px",
                padding: "12px",
              }}
              key={data.id}
              value={data.label}
              onChange={handleChange}
            >
              <div className={Style.flex}>
                <img src={data.img} style={{ marginRight: "5px" }} />
                <span>{data.label}</span>
              </div>
            </MenuItem>
            <Divider style={{ margin: 0, borderColor: "#00000042" }} />
          </div>
        ))}
      </Menu>
      {openDrawer && (
        <CustomDrawer
          open={openDrawer}
          onClose={handleDrawerClose}
          title={"Choose The KPI Style"}
          selected={seletced}
        />
      )}
    </div>
  );
}
