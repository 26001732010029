export const EMPTY_STR = "";

export function capitalizeFirstLetter(string) {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name, width, height, fontSize) {
    const firstInitial = `${name.split(' ')[0][0]}` === "undefined" ? "" : `${name.split(' ')[0][0]}`;
    const secondInitial = `${name.split(' ')[1][0]}` === "undefined" ? "" : `${name.split(' ')[1][0]}`;
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: width ? width : 40,
            height: height ? height : 40,
            fontSize: fontSize ? fontSize : "1.25rem"
        },
        children: `${firstInitial}${secondInitial}`,
    };
}