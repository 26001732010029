import * as React from "react";
import DomainIcon from "../../../images/DomainDetails/Website.png";
import HostingIcon from "../../../images/DomainDetails/Upload to Cloud.png";
import GsuiteIcon from "../../../images/DomainDetails/Google.png";
import tableStyle from "../Style/DomainTable.module.css";
import { useRecoilState } from "recoil";
import { selecteddomainn } from "../../../webservicesatoms/webserviceatom";
import Button from "@mui/material/Button";
import "../Style/DomainTable.css";
import { formatDateWithOrdinal } from "../Domain/Api/DomainApi";
import { NavLink } from "react-router-dom";
const Buttonstyles = {
  width: "72px",
  height: "28px",
  borderRadius: "4px",
  border: "1px solid #000AFF",
  padding: "6px 20px 6px 20px",
  gap: "10px",
  color: "#000AFF",
  textTransform: "capitalize",
};
export default function DomainTable({ domaindata, hasDataAfterWebservices }) {
  const [Selecteddomain, setSelecteddomain] = useRecoilState(selecteddomainn);
  function isActiveLink(link,id) {
    const urlParts = window.location.pathname.split("/");

    return urlParts[2] === link && urlParts[3] === id;
  }
  function mapProductName(productName) {
    switch (productName.toLowerCase()) {
      case "gsuite":
        return "Google Workspace";
      case "plesk":
        return "Web Hosting";
      case "domain":
        return "Domain Registration";
      default:
        return productName;
    }
  }
  function mapProductImage(productName) {
    switch (productName.toLowerCase()) {
      case "gsuite":
        return GsuiteIcon;
      case "plesk":
        return HostingIcon;
      case "domain":
        return DomainIcon;
      default:
        return productName;
    }
  }
  return (
    <>
      <div className={tableStyle.tableHead}>
        <div className={tableStyle.tableAlignLeft}>
          <table className={tableStyle.tableWidth}>
            <tr>
              <td className={`${tableStyle.cellWidth}`}>Webservices</td>
              <td
                className={`${tableStyle.cellWidth} ${
                  hasDataAfterWebservices ? tableStyle.table_data_view : ""
                }`}
              >
                Purchase Date
              </td>
              <td
                className={`${tableStyle.cellWidth} ${
                  hasDataAfterWebservices ? tableStyle.table_data_view : ""
                }`}
              >
                Expiry Date
              </td>
              <td
                className={`${tableStyle.cellWidth} ${
                  hasDataAfterWebservices ? tableStyle.table_data_view : ""
                }`}
              >
                Action
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className={tableStyle.cellBorder}></div>
      {domaindata.map((data) => (
        <NavLink
          key={data._id}
          to={`${data.domainName}/${data._id}`}
          className={tableStyle.tableData_Outline}
          onClick={() => setSelecteddomain(data)}
          style={{
            display: "block",
          }}
        >
          <div
            className={`${tableStyle.tableData_Outline} ${tableStyle.tableCell}`}
          >
            <div
              style={{
                backgroundColor: isActiveLink(data.domainName,data._id)
                  ? "#EAF2FF"
                  : "inherit",
              }}
            >
              <div className={tableStyle.tableAlignLeft}>
                <table className={tableStyle.tableWidth}>
                  <tr
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <td className={tableStyle.cellWidth}>
                      <div className={tableStyle.webservice}>
                        <div className={tableStyle.imgWrapper}>
                          <img src={mapProductImage(data.product)} alt="dns" />
                        </div>
                        <div>
                          <div className={tableStyle.domainName}>
                            {data.domainName}
                          </div>
                          <div className={tableStyle.productName}>
                            {data.product === "gsuite" ? data.productName : mapProductName(data.product)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td
                      className={`${tableStyle.cellWidth} ${
                        tableStyle.dateStyle
                      } ${
                        hasDataAfterWebservices
                          ? tableStyle.table_data_view
                          : ""
                      }`}
                    >
                      {formatDateWithOrdinal(data?.purchaseDate)}
                    </td>
                    <td
                      className={`${tableStyle.cellWidth} ${
                        tableStyle.dateStyle
                      } ${
                        hasDataAfterWebservices
                          ? tableStyle.table_data_view
                          : ""
                      }`}
                    >
                      {formatDateWithOrdinal(data?.expiryDate)}
                    </td>
                    <td
                      className={`${tableStyle.cellWidth} ${
                        hasDataAfterWebservices
                          ? tableStyle.table_data_view
                          : ""
                      }`}
                    >
                      <Button variant="outlined" style={Buttonstyles}>
                        More
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </NavLink>
      ))}
    </>
  );
}
