import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import ClonePipeline from './ClonePipeline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import variables from '../../../assets/styles/_colors.scss';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 0,
    flexGrow: 1,
    minHeight: '100vh',
    minWidth: '100vw',
    height: '100vh',
    width: '100vw'
};

export default function ClonePipelineModal(props) {

    const [open, setOpen] = useState(true);

    const handleClose = (val) => {
        setOpen(false);
        props.handleClose(val);
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container direction="row" alignItems="center" sx={{ height: 50, borderBottom: `1px solid ${variables.borderColor}` }}>
                        <Grid item>
                            <IconButton
                                aria-label="close"
                                onClick={() => handleClose(null)}
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography component="div" variant="h6">Pipeline Templates</Typography>
                        </Grid>
                    </Grid>
                    <ClonePipeline showTemplate={true}></ClonePipeline>
                </Box>
            </Modal>
        </div>
    );
}