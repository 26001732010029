import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import {
  Button,
  Typography,
  styled,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import useDeleteSegment from "../components/useDeleteSegment";
import { usePostSegments } from "../components/usePostSegments"; 
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    width: "300px",
    display: "flex",
    justifyContent: "flex-start",
  },
});

const SegmentConvo = ({ visitorId }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);

  const handleDeleteSegment = useDeleteSegment()
  const { sendVisitorData } = usePostSegments()

  const handleClick = () => {
    setOpen(!open);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = async (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      try {
        await sendVisitorData(visitorId, inputValue.trim());
        setChips([...chips, inputValue.trim()]);
        setInputValue("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const handleChipDelete = (chipToDelete) => async () => {
    try {
      const deleteResponse = await handleDeleteSegment(chipToDelete);
      if (deleteResponse.ok) {
        setChips((chips) => chips.filter((chip) => chip !== chipToDelete));
      } else {
        console.error("Error deleting segment:", deleteResponse.responseData);
      }
    } catch (error) {
      console.error("Error deleting segment:", error);
    }
  };


  return (
    <List sx={{ p: 0 }}>
      <StyledButton
        onClick={handleClick}
        sx={{
          padding: "11px 40px 10px 12px ",
          backgroundColor: "#fafbfc",
          borderBottom: "1px solid #e3e8ef",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "Inter",
            color: "#75869c",
            textAlign: "left",
          }}
        >
          SEGMENTS FOR CONVERSATION
        </Typography>
        {open ? (<ArrowDropDownIcon sx={{color: "#75869c",marginLeft:"auto"}}/>) : (<ArrowLeftIcon sx={{color: "#75869c",marginLeft:"auto"}}/>)}
      </StyledButton>

      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListItem
         
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Stack direction="row" spacing={1} sx={{ marginTop: "1rem" }}>
              {chips.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={handleChipDelete(chip)}                 
                   color="primary"
                />
              ))}
            </Stack>
            <TextField
              label="Segment conversation"
              variant="outlined"
              size="small"
              value={inputValue}
              onChange={handleInputChange}
              onKeyPress={handleInputKeyPress}
              sx={{
                display: "flex",
                color: "#1c293b",
                // paddingLeft: "34px",
                flex: 1,
                width: "100%",
                border: "1px solid #e3e8ef",
                borderRadius: "2px",
                fontSize: "13px",
                height: "30px",
              }}
            />
          </ListItem>
        </Collapse>
      )}
      <Divider sx={{ borderBottom: "1px solid grey" }} />
    </List>
  );
};

export default SegmentConvo;
