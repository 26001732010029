import React from "react";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Button, Typography, styled, Divider, Box } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    width: "300px",
    display: "flex",
    justifyContent: "flex-start",
  },
});

const PrivateNote = () => {
  const [open, setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [isDirty, setIsDirty] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleTextareaChange = (event) => {
    setText(event.target.value);
    setIsDirty(true);
  };

  const handleCancelChanges = () => {
    setText("");
    setIsDirty(false);
  };

  const handleSaveChanges = () => {
    console.log("Changes saved:", text);
    setIsDirty(false);
  };

  return (
    <List sx={{ p: 0 }}>
      <StyledButton
        onClick={handleClick}
        sx={{
          padding: "11px 40px 10px 12px ",
          backgroundColor: "#fafbfc",
          borderBottom: "1px solid #e3e8ef",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "Inter",
            color: "#75869c",
            textAlign: "left",
          }}
        >
          PRIVATE NOTEPAD
        </Typography>
        {open ? (
          <ArrowDropDownIcon sx={{ color: "#75869c", marginLeft: "auto" }} />
        ) : (
          <ArrowLeftIcon sx={{ color: "#75869c", marginLeft: "auto" }} />
        )}
      </StyledButton>

      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
            {isDirty && (
            <Box style={{ backgroundColor: "#FFFBEB",padding:"6px 0px 6px 25px" }}>
              <Button onClick={handleCancelChanges} variant="text" color="error" size="small" sx={{textTransform: "capitalize"}}>
                Cancel Changes
              </Button>
              <Button onClick={handleSaveChanges} variant="text"  size="small" sx={{textTransform: "capitalize",marginLeft:"7px"}} >
                Save Changes
              </Button>
            </Box>
          )}
          <textarea
            id="w3review"
            name="w3review"
            rows="4"
            value={text}
            onChange={handleTextareaChange}
            style={{
              backgroundColor: "#FFF8DB",
              padding: "10px",
              border: "none",
              width: "100%",
              outline: "none",
            }}
            placeholder="Write your private notes..."
          ></textarea>
        
        </Collapse>
      )}
      <Divider sx={{ borderBottom: "1px solid grey" }} />
    </List>
  );
};

export default PrivateNote;
