import { action_contants } from "./action-types";

export const setCurrentPage = (params) => {
  return (dispatch) => {
    dispatch({
      type: action_contants.SET_CURRENT_PAGE,
      payload: params,
    });
  };
};
export const setCurrentSetting = (params) => {
  return (dispatch) => {
    dispatch({
      type: action_contants.SET_CURRENT_SETTING,
      payload: params,
    });
  };
};
