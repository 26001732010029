import { Stack, Typography } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { flexRender } from "@tanstack/react-table";

export const PipelineTableHeader = (props) => {

    return (
        <>
            {props.table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} style={{backgroundColor: 'inherit'}}>
                    {headerGroup.headers.map(header => {
                        return (
                            <th
                                key={header.id}
                                colSpan={header.colSpan}
                            >
                                {header.isPlaceholder ? null : (
                                    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                                        <Stack direction="row" justifyContent="left" sx={{ width: '50%' }}>
                                            {header.column.getCanSort() && <Typography  {...{
                                                className: header.column.getCanSort()
                                                    ? 'cursor-pointer select-none'
                                                    : '',
                                                onClick: header.column.getToggleSortingHandler(),
                                            }} sx={{ cursor: 'pointer', fontWeight: 'bold' }}>
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                            </Typography>}

                                            {!header.column.getCanSort() &&
                                                flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )
                                            }
                                            {{
                                                asc: <ArrowUpwardIcon fontSize="small" sx={{ ml: 1 }} />,
                                                desc: <ArrowDownwardIcon fontSize="small" sx={{ ml: 1 }} />,
                                            }[header.column.getIsSorted()] ?? null}
                                        </Stack>
                                    </Stack>
                                )}
                            </th>
                        )
                    })}
                </tr>
            ))}
        </>
    )
}