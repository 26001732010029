import * as React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import ReorderableList from '../components/ReorderableList';

export default function ModifyProductsTable(props) {

    const handleCancel = () => {
        props.close(null);
    };

    const handleOk = (data) => {
        props.close(data);
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '100%' } }}
            maxWidth="xs"
            open={true}
            id="modify-products-table"
            keepMounted
        >
            <DialogTitle sx={{ pb: 0 }}>Modify Product Fields</DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 0, pt: 0 }}>
                <ReorderableList items={props.items} handleCancel={handleCancel} handleOk={handleOk} module="modifyProducts" />
            </DialogContent>
        </Dialog>
    );
}
