import * as React from "react";
import {
    Controller,
    get,
    useFormContext,
} from "react-hook-form";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";

export const CustomSelect = ({ ...props }) => {
    const { control, formState } = useFormContext();
    const error = get(formState.errors, props.name);
    const [disableSelect, setDisableSelect] = React.useState(props.disabled ? true : false);

    return (
        <FormControl fullWidth error={error ? true : false}>
            <InputLabel shrink id="custom-fields-input-label">{props.inputLabel}</InputLabel>
            <Controller
                name={props.name}
                control={control}
                rules={props.rules}
                defaultValue={props.defaultValue ? props.defaultValue : ""}
                render={({ field: { ref, ...field } }) => (
                    <>
                        <Select
                            {...field}
                            placeholder={props.placeholder}
                            displayEmpty
                            disabled={disableSelect}
                            label={props.label}
                            size={props.size ? props.size : "medium"}
                            sx={[
                                props?.rules?.required && { borderLeft: '3px solid red !important' }]
                            }
                        >
                            {props.addDefaultMenu && <MenuItem key="-1" value="-1">None</MenuItem>}
                            {props.options?.map((item, index) => {
                                return item?.label?.toString()?.trim() !== "" && <MenuItem key={index} value={item.value ? item.value : item.label}>{item.label} </MenuItem>;
                            })}
                        </Select>
                        {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </>
                )}
            />
        </FormControl>
    );
};
