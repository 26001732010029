import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const CustomDropDown = ({
  value,
  handleChange,
  items,
  handleFocusOpen,
  isFocused,
}) => {
  const useStyles = makeStyles(() => ({
    formControl: {
      "& .MuiInputBase-root": {
        border: "none",
        minWidth: "120px",
        justifyContent: "center",
        color: isFocused ? "black" : "#ddd",
      },
      "& .MuiSelect-select": {
        padding: "8px !important",
        border: "none",
      },
      "& .MuiInput-underline:before, & .MuiInput-underline:after": {
        border: "none !important",
      },
      "& .MuiOutlinedInput-root": {
        "&  .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
    },
    select: {
      width: "auto",
      fontSize: "1rem",
      border: "none",
      fontWeight: 400,
      "&:focus": {
        backgroundColor: "transparent",
        border: "none",
      },
    },
    selectIcon: {
      position: "relative",
      color: isFocused ? "black" : "#ddd",
      fontSize: "1rem",
      fontWeight: 400,
    },
    paper: {
      borderRadius: 12,
      marginTop: 8,
      width: isFocused ? "40vw" : "33vw",
      marginLeft: isFocused ? "16%" : "12%",
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,

      "& li": {
        fontSize: "1rem",
        fontWeight: 400,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
  }));
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl className={classes.formControl} onFocus={handleFocusOpen}>
      <Select
        value={value}
        onChange={handleChange}
        disableUnderline
        IconComponent={ExpandMoreRoundedIcon}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon,
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.routeId} value={item.route}>
            {item.routeLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropDown;
