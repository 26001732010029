import { Alert, Button, Grid, Snackbar, TextField } from "@mui/material";
import React from "react";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { login_companyid, profile_trigger, selected_contact } from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import { api_url } from "../../globalSettings";


  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiNativeSelect-icon": {
      color: "#000",
      paddingRight: "5px",
      position: "realative",
      right: "5px",
    },
    "& .MuiInputBase-input": {
      // borderRadius: 20,
      position: "relative",
      color: "#000",
      fontWeight: "500",
      display: "flex",
      alignitem: "center",
      backgroundColor: "none",
      border: "1px solid #ced4da",
      fontSize: 14,
      padding: "5px 26px 5px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        // borderRadius: 20,
        color: "#000",
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }));

const ContactDetailsFollowupTab = () => {
    const [selectedContact] = useRecoilState(selected_contact);
    const [getFollowUplist, setGetFollowUplist] = React.useState("");
    const [company_idd] = useRecoilState(login_companyid);
    const [description , setDescription] = React.useState('');
    const [remainder , setRemainder] = React.useState('');
    const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
    const [snackbaropen, setSnackbaropen] = React.useState({
        open: false,
        message: "",
        severity: "",
      });
    const[date , setDate] = React.useState('')

   

      // =========================  TIME API =============================
  const [followupType, setFollowupType] = React.useState("");

  const handleChange2 = (event) => {
    setFollowupType(event.target.value);
  };


 React.useEffect(() => {
    axios
    .get(`${api_url}/crm_setting/getGeneralSetting/${company_idd}`) //63eb2395bcdeb8a6aa878559 ${company_idd}
    .then((responce) => {
      setGetFollowUplist(
        responce.data.data[0].follow_ups.add_new_followup_type.map(
          (item, key) => ({
        label:item.name,
        id:item._id,
        color:item.color
          })
        )
      );
   
      setFollowupType(responce.data.data[0].follow_ups.add_new_followup_type[0]._id)
     
    })
    .catch(function (error) {
      if (error.response) {
       
      } else if (error.request) {
     
      } else {
       
      }
    });
 } ,[])



  const addNotes = () => {

   

    let c = getFollowUplist.find((obj) => obj.id == followupType)


    const params = JSON.stringify({
        customer_id:selectedContact._id,
        followupData:[{
        follow_up_type:{
                name:c.label,
                color:c.color,
                id:c.id
            },
            CreatedDate:date,
            UpdatedDate:"",
            description: description,
            remainders:remainder
        }]
    
    });

    axios
      .post(`${api_url}/crm_customer/addFollowUp`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        setSnackbaropen({
          open: true,
          message: response.data.message,
          severity: "success",
          actionColor: "#edf7ed",
        });
        //setTriggerdcontact(triggered)
        setProfileTrigger(!profileTrigger)
        setDate('');
        setDescription('');
      })

      .catch(function (error) {
        if (error.response) {
      
        } else if (error.request) {
      
        } else {
        }
      });
  }

  return (
    <div style={{ width: "100%", fontSize: "12px" , display:"flex" , alignItems : "center" , flexDirection : "column" }}>
        <Snackbar
    open={snackbaropen.open}
    onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
    autoHideDuration={6000}
  >
    <Alert
      severity={snackbaropen.severity}
      style={{ backgroundColor: `${snackbaropen.actionColor}` }}
    >
      {snackbaropen.message}
    </Alert>
  </Snackbar>
      <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              Type{" "}
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
            <FormControl
                sx={{ m: 1 }}
                variant="standard"
                style={{ width: "100%", marginLeft: "0" }}
                size="small"
              >
               
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={getFollowUplist ? getFollowUplist[0].id : ""}
                  onChange={handleChange2}
                  input={<BootstrapInput />}
                >
                  {getFollowUplist ? getFollowUplist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>
                      </>
                    );
                  }) : ""}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              Date{" "}
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
              <TextField
                InputProps={{
                  style: {
                    borderRadius: "0px",
                  },
                }}
                value={date}
                onChange={(e) => {setDate(e.target.value)}}
                size="small"
                fullWidth
                type="date"
              />
            </Grid>
          </Grid>
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              Remainder{" "}
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
            <TextField
        variant="outlined"
        type="number"
        InputProps={{
          style: {
            borderRadius: "0px",
          },
        }}
        value={remainder}
        onChange={(e)=>{setRemainder(e.target.value)}}
        size="small"
        fullWidth
        placeholder="In Hours"
      />
            </Grid>
          </Grid>
        </Grid>{" "}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              Description
            </Grid>
            <Grid item xs={12} sm={8} md={9}>
            <TextField
        variant="outlined"
        type="text"
        rows={3}
        InputProps={{
          style: {
            borderRadius: "0px",
          },
        }}
        value={description}
        onChange={(e)=>{setDescription(e.target.value)}}
        multiline
        fullWidth
        placeholder="Description"
      />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Button
        style={{
          width: "100px",
          marginTop: "20px",

        }}
        variant="contained" 
        disabled={description.length > 0 && remainder.length > 0 && date.length > 0 ? false : true}

        onClick={addNotes}
      >
        Add{" "}
      </Button>{" "}
    </div>
  );
};

export default ContactDetailsFollowupTab;
