import React from "react";
import RenewalStyle from "../Styles/RenewalConfirm.module.css";
import { LoadingButton } from "@mui/lab";

const RenewalButton = ({
  loading,
  clickFunc,
  title,
  disabled,
  deleteStyle,
}) => {
  const proceedStyle = {
    background: disabled ? "#e0e0e2" : "#0209fe",
    border: "none",
    borderRadius: "2px",
    color: disabled ? "#a2a2a6" : "#fff",
    cursor: disabled ? "not-allowed" : "pointer",
    display: "inline-block",
    fontFamily: "OpenSans",
    fontSize: "12px",
    fontWeight: 600,
    outline: "none",
    position: "relative",
    textTransform: "uppercase",
  };
  return (
    <div className={RenewalStyle.button_wrapper}>
      <LoadingButton
        loading={loading}
        variant="contained"
        color="success"
        style={deleteStyle || proceedStyle}
        onClick={clickFunc}
        disabled={disabled}
      >
        {title}
      </LoadingButton>
    </div>
  );
};

export default RenewalButton;
