import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, MenuItem, Select, Stack } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useState } from 'react';
import { replaceStage } from '../../../../webservices/PipelineService';

export default function ReplaceStage(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(true);
    const [stageName, setStageName] = React.useState("");
    const [filteredStages, setFilteredStages] = useState([]);

    const data = queryClient.getQueryData(["getAllStages"]);

    useEffect(() => {
        const type = props.stage.isOpenStage ? "openStages" : "closedStages";
        data && setFilteredStages(data.stages[type].filter(ar => !props.stages.find(rm => (rm._id === ar.value))));
    }, [data]);

    const { mutateAsync } = useMutation({
        mutationKey: "replaceStage",
        mutationFn: () => replaceStage(props.stage?._id, {
            pipelineId: props.teamPipelineId,
            subPipelineId: props.subPipelineId,
            stageToBeReplaced: stageName,
            isOpenStage: props.stage?.isOpenStage,
            createdBy: "User 1"
        }),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleReplace = () => {
        mutateAsync();
    }

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    }

    const onSelectChange = (val) => {
        setStageName(val);
    }

    return (
        <div>
            <Modal open={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Replace {props.stage.stageName}
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>

                    <Box sx={{ pt: 2 }}>
                        <Typography>
                            {`Replace the stage "${props.stage.stageName}" in this sub-pipeline with a different stage from another sub-pipeline.`}
                        </Typography>
                        <FormControl sx={{ pt: 1, pb: 2 }} size="small" fullWidth>
                            <Select
                                value={stageName}
                                onChange={(event) => onSelectChange(event.target.value)}
                                displayEmpty
                            >
                                <MenuItem value={''} disabled>Choose a stage</MenuItem>
                                {
                                    filteredStages.length > 0 && filteredStages.map(stage => {
                                        return <MenuItem value={stage.value}>{stage.label}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="primary" disabled={stageName === props.stage?.name}
                            onClick={handleReplace}>Save</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}