import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Styles from "../ContactInfo.module.scss";
import {
  Button,
  CircularProgress,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import axios from "axios";
import Overview from "./overview/Overview";
import Info from "../../../../images/Vector.png";
import LogoutIcon from "@mui/icons-material/Logout";

const ContactContent = () => {
  const navigate = useNavigate();
  const [contact, setContact] = useState(null);
  const { _id } = useParams();
  const [activeTab, setActiveTab] = useState("Overview");
  const [openDelete, setOpenDelete] = useState(false);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const response = await axios.get(
          `https://660d45d36ddfa2943b33ecdd.mockapi.io/employee/${_id}`
        );
        setContact(response.data);
      } catch (error) {
        console.error("Error fetching employee details:", error);
      }
    };

    fetchContactDetails();
  }, [_id]);

  const handleClose = () => {
    navigate("/chat/contacts");
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleBacktoChat = () => {
    navigate("/chat/inbox");
  }

  return (
    <>
      <div className={Styles.Ccontentmain}>
        <div className={Styles.Ccontentwrapper}>
          {contact ? (
            <>
              <div className={Styles.Cimage}>
                <PersonOutlineOutlinedIcon
                  sx={{
                    color: "var( --light-text-color)",
                    fontSize: "70px",
                    margin: "5px 0 0 10px",
                  }}
                />
              </div>
              <div className={Styles.CDetailwrapper}>
                <Typography className={Styles.CName}>{contact.name}</Typography>
                <Typography className={Styles.CMail}>
                  {contact.email}
                </Typography>
                <div className={Styles.CIconwrapper}>
                  <Typography
                    className={Styles.Cediticon}
                    component={NavLink}
                    to="edit"
                  >
                    Edit
                  </Typography>
                  <Typography
                    className={Styles.CDeleteIcon}
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Typography>
                </div>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <LogoutIcon sx={{marginRight:"15px"}} onClick={handleBacktoChat}/>
                <CloseIcon onClick={handleClose} />
              </div>
            </>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "15%",
                left: "70%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <Tabs
        sx={{ borderBottom: "1px solid var(--light-border-color)" }}
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
      >
        <Tab label="Overview" value="Overview" />
        <Tab label="Steps" value="steps" />
        <Tab label="Prospects" value="prospects" />
      </Tabs>

      {activeTab === "Overview" && <Overview contact={contact} />}
      <Modal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        style={{
          top: "30%",
          left: "15%",
          width: "70%",
          height: "60%",
          outline: "none",
        }}
      >
        <Paper elevation={1} sx={{ outline: "none" }}>
          <div style={{ padding: "20px" }}>
            <div className={Styles.CDeletePop}>
              <img
                src={Info}
                alt="Delete"
                style={{ width: "60px", paddingRight: "20px" }}
              />
              <p style={{ margin: 0 }}>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
            </div>
            <div className={Styles.CDeletepopButton}>
              <Button variant="contained" className={Styles.CDeleteButton}>
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={{ marginLeft: "20px" }}
                onClick={() => setOpenDelete(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Paper>
      </Modal>
    </>
  );
};

export default ContactContent;
