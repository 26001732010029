import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Drawer, FormControl, Typography, Radio, FormControlLabel, List, ListItemText, ListItem, Button, Checkbox, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import variables from '../../../assets/styles/_colors.scss';
import { CloseButton } from '../../../components/CloseButton';
import { withStyles } from '@mui/styles';

const StyledAccordionSummary = withStyles({
    root: {
        minHeight: "50px !important",
        maxHeight: "50px !important",
        "& .MuiAccordionSummary-content": {
            alignItems: "center"
        },
        '&.Mui-expanded': {
            background: `${variables.listBackgroundColor}`, //"#e2f3fc", 
            minHeight: "50px !important",
            maxHeight: "50px !important",
            borderTop: `1px solid ${variables.borderColor}`
        }
    },
    expandIcon: {
        order: -1
    }
})(AccordionSummary);

export default function PicklistPredefinedOptions(props) {

    const predefinedOptions = [
        {
            "summary": "Days of the Week",
            "value": "days",
            "options": [{
                label: "Sunday", value: "Sunday"
            },
            {
                label: "Monday", value: "Monday"
            },
            {
                label: "Tuesday", value: "Tuesday"
            },
            {
                label: "Wednesday", value: "Wednesday"
            },
            {
                label: "Thursday", value: "Thursday"
            },
            {
                label: "Friday", value: "Friday"
            },
            {
                label: "Saturday", value: "Saturday"
            }
            ]
        },
        {
            "summary": "Month of the Year",
            "value": "months",
            "options": [{
                label: "January", value: "January"
            },
            {
                label: "February", value: "February"
            },
            {
                label: "March", value: "March"
            },
            {
                label: "April", value: "April"
            },
            {
                label: "May", value: "May"
            }]
        },
        {
            "summary": "Month of the Year",
            "value": "monsfsths",
            "options": [{
                label: "January", value: "January"
            },
            {
                label: "February", value: "February"
            },
            {
                label: "March", value: "March"
            },
            {
                label: "April", value: "April"
            },
            {
                label: "May", value: "May"
            }]
        },
        {
            "summary": "Days of the Week",
            "value": "daydfds",
            "options": [{
                label: "Sunday", value: "Sunday"
            },
            {
                label: "Monday", value: "Monday"
            },
            {
                label: "Tuesday", value: "Tuesday"
            },
            {
                label: "Wednesday", value: "Wednesday"
            },
            {
                label: "Thursday", value: "Thursday"
            },
            {
                label: "Friday", value: "Friday"
            },
            {
                label: "Saturday", value: "Saturday"
            }
            ]
        },
        {
            "summary": "Month of the Year",
            "value": "montsdfdhs",
            "options": [{
                label: "January", value: "January"
            },
            {
                label: "February", value: "February"
            },
            {
                label: "March", value: "March"
            },
            {
                label: "April", value: "April"
            },
            {
                label: "May", value: "May"
            }]
        },
        {
            "summary": "Days of the Week",
            "value": "dayssd",
            "options": [{
                label: "Sunday", value: "Sunday"
            },
            {
                label: "Monday", value: "Monday"
            },
            {
                label: "Tuesday", value: "Tuesday"
            },
            {
                label: "Wednesday", value: "Wednesday"
            },
            {
                label: "Thursday", value: "Thursday"
            },
            {
                label: "Friday", value: "Friday"
            },
            {
                label: "Saturday", value: "Saturday"
            }
            ]
        },
        {
            "summary": "Month of the Year",
            "value": "montshs",
            "options": [{
                label: "January", value: "January"
            },
            {
                label: "February", value: "February"
            },
            {
                label: "March", value: "March"
            },
            {
                label: "April", value: "April"
            },
            {
                label: "May", value: "May"
            }]
        },
        {
            "summary": "Days of the Week",
            "value": "dadfys",
            "options": [{
                label: "Sunday", value: "Sunday"
            },
            {
                label: "Monday", value: "Monday"
            },
            {
                label: "Tuesday", value: "Tuesday"
            },
            {
                label: "Wednesday", value: "Wednesday"
            },
            {
                label: "Thursday", value: "Thursday"
            },
            {
                label: "Friday", value: "Friday"
            },
            {
                label: "Saturday", value: "Saturday"
            }
            ]
        },
        {
            "summary": "Month of the Year",
            "value": "fdmonths",
            "options": [{
                label: "January", value: "January"
            },
            {
                label: "February", value: "February"
            },
            {
                label: "March", value: "March"
            },
            {
                label: "April", value: "April"
            },
            {
                label: "May", value: "May"
            }]
        }
    ]

    const [open,] = useState(true);
    const [selectedPredefinedOption, setSelectedPredefinedOption] = useState("");
    const [replaceExistingOptions, setReplaceExistingOptions] = useState(false);

    function handleDrawerClose() {
        props.handleDrawerClose();
    }

    const handleRadioClick = (event, item) => {
        event.stopPropagation();
        setSelectedPredefinedOption(item.value);
    }

    const [expanded, setExpanded] = React.useState(null);

    const handleAccordionChange = (panel) => (event, val) => {
        setExpanded(val ? panel : false);
        setSelectedPredefinedOption(panel);
    };

    const saveOptions = () => {
        const data = predefinedOptions.filter(d => d.value === selectedPredefinedOption);
        props.savePredefinedOptions({
            options: data?.length > 0 ? data[0].options : [],
            replaceExistingOptions: replaceExistingOptions
        });
        handleDrawerClose();
    }

    return (
        <div>
            <React.Fragment>
                <Drawer anchor="right" open={open}
                    PaperProps={{
                        sx: { minWidth: "35vw", maxWidth: "35vw", pt: 2, pb: 2, borderTop: `2px solid ${variables.borderColor}` },
                    }}>
                    <Typography variant="h5" sx={{ pl: 2 }}>Predefined Options
                        <CloseButton handleDialogClose={handleDrawerClose} />
                    </Typography>
                    <Box sx={{
                        overflowX: "hidden", overflowY: "auto", height: "83vh", pr: 2, pl: 2, pt: 1
                    }}>
                        {predefinedOptions.map((item, index) => {
                            return <Accordion expanded={expanded === item.value} onChange={handleAccordionChange(item.value)}
                                sx={{ m: "0 !important", border: "none", boxShadow: "none !important" }}>
                                <StyledAccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id={index}
                                >
                                    <FormControl>
                                        <FormControlLabel sx={{ mr: 0.5 }}
                                            control={<Radio checked={selectedPredefinedOption === item.value}
                                                onClick={(event) => handleRadioClick(event, item)} />}
                                        />
                                    </FormControl>
                                    <Typography>{item.summary}</Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails sx={{ p: 0, maxHeight: 200, overflowX: "hidden", overflowY: 'scroll' }}>
                                    <List sx={{ pt: 0, pb: 0, width: '100%', bgcolor: `${variables.linkBackgroundColor}` }}>
                                        {item.options.map((option, i) => (
                                            <ListItem key={`${option.value}.${i}`} sx={{
                                                pl: 6,
                                                // borderBottom: `1px solid ${variables.borderColor}`
                                            }}>
                                                <ListItemText primary={option.label} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        })
                        }
                    </Box>

                    <Stack direction="row" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Stack direction="row" justifyContent="left" sx={{ width: "50%" }}>
                            <FormControlLabel control={<Checkbox checked={replaceExistingOptions} onChange={() => setReplaceExistingOptions(!replaceExistingOptions)} />}
                                label="Replace existing options" />
                        </Stack>
                        <Stack direction="row" spacing={2} justifyContent="right" sx={{ width: "50%" }}>
                            <Button variant="outlined" onClick={handleDrawerClose}> Cancel </Button>
                            <Button onClick={() => saveOptions()} variant="contained" disabled={selectedPredefinedOption === ""}> Add Options </Button>
                        </Stack>
                    </Stack>
                </Drawer>
            </React.Fragment>
        </div >
    );
}
