import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const MuiTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    height: "32px",
    borderRadius: "5px",
    padding: "3px",
    "&:focus": {
      border: "1px solid #d2cece",
    },
    "&:hover": {
      "& + fieldset": {
        border: "1px solid #d2cece",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #d2cece",
  },
}));

const CustomTextSelect = ({
  value,
  name,
  handlechange,
  keyProp,
  valueProp,
  array,
}) => {
  return (
    <MuiTextField
      select
      value={value}
      name={name}
      onChange={handlechange}
      variant="outlined"
      fullWidth
      SelectProps={{
        MenuProps: {
          style: {
            maxHeight: 200,
          },
        },
      }}
    >
      {array.map((item) => (
        <MenuItem key={item[keyProp]} value={item[valueProp]}>
          {item[valueProp]}
        </MenuItem>
      ))}
    </MuiTextField>
  );
};

export default CustomTextSelect;
