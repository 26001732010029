import { Avatar, Drawer, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";

import { useRecoilState } from "recoil";
import { Button, Typography } from "@mui/material";
import "../dashboard/Style/UserDrawer.css";
import axios from "axios";
import { apiresponse, login_atoms } from "../../Atoms/LoginAtoms";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";

import { api_url } from "../../globalSettings";
import { login_token } from "../../Atoms/LoginAtoms";
import { Box } from "@mui/system";
import { Stack, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "white",
  boxShadow: 24,
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function UserDrawer(props) {
  const [companyform, setCompanyForm] = useState({
    no_of_days: "",
  });
  // ==================== Assign Leave ================

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
    props.toggleDrawer(true);
  };
  const handleClose1 = () => setOpen1(false);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);

  const [apiresponse1, setApiresponse1] = useRecoilState(apiresponse);

  const url = `http://server.nowdigitaleasy.com:3505/getUserDetails`;
  const params = {
    user_id: loginAtom1,
  };

  useEffect(() => {
    axios
      .get(`${api_url}/getUserDetails`, { params: { ...params } })
      .then((response) => {
        setApiresponse1(response.data.data);
      });
  }, [url]);

  function clearToken() {
    const params = JSON.stringify({
      token: loginToken1,
      branch_id: props.selectedEmp.branch_id._id,
      employee_id: props.selectedEmp._id,
    });

    axios
      .patch(`${api_url}/clear_emp_token`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        alert("token Cleared");
        // setSnackbaropen({
        //   open: true,
        //   message: "Shift Added successfully",
        //   severity: "success",
        //   actionColor: "#edf7ed",
        // });
        // getShiftList();
        //setOpen2(false);
      })

      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  }

  // =========================  LEAVE LIST APII ==========================
  const [leaveList, setLeaveList] = useState([]);

  const [leaveTypeid, setLeaveTypeid] = useState("");
  const [leaveDays, setLeaveDays] = useState("");

  function LeaveTypeList(event, newValue) {
    if (newValue != null) {
      setLeaveTypeid(newValue.id);
      setLeaveDays(newValue.days);
    }
  }

  function updateLeave() {
    const params = JSON.stringify({
      token: loginToken1,
      leave_type_id: leaveTypeid,
      no_of_days: companyform.no_of_days,
    });

    axios
      .patch(`${api_url}/updateLeaveType`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        alert("leave added");
        // setSnackbaropen({
        //   open: true,
        //   message: "Shift Added successfully",
        //   severity: "success",
        //   actionColor: "#edf7ed",
        // });
        // getShiftList();
        //setOpen2(false);
      })

      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  }

  function leaveTyeList() {
    const params = {
      token: loginToken1,
      employee_id: props.selectedEmp._id,
      branch_id: props.selectedEmp.branch_id._id,
    };

    axios
      .get(`${api_url}/getLeaveTypeDetails`, {
        params: { ...params },
      })

      .then((response) => {
        setLeaveList(
          response.data.data.map((item, key) => ({
            label: item.leave_type,
            id: item._id,
            days: item.no_of_days,
          }))
        );
      })
      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  }

  React.useEffect(() => {
    if (Object.keys(props.selectedEmp).length >= 1) {
      leaveTyeList();
    }
  }, [Object.keys(props.selectedEmp).length >= 1]);

  //============================================== Changing Form Values =============================================
  const formonchange = (attribute, e) => {
    setCompanyForm((state) => ({ ...state, [attribute]: e.target.value }));
  };

  return (
    <div>
      <React.Fragment>
        <Drawer anchor="right" open={props.open} onClose={props.toggleDrawer}>
          <div id="drawer">
            <div className="drawer-close-btn" onClick={props.toggleDrawer}>
              <IconButton component="span" style={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </div>

            <div className="userdraweravatar">
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  style={{ width: 90, height: 90, backgroundColor: "#ced2df" }}
                  variant="rounded"
                ></Avatar>
              </StyledBadge>
              <div className="userdrawerinfo">
                <Typography variant="subtitle2" gutterBottom>
                  {props.selectedEmp.first_name}&nbsp;
                  {props.selectedEmp.last_name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {props.selectedEmp.moile_no}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {" "}
                  {props.selectedEmp.email_id}
                </Typography>
                {/* <div className="drawer-signout" onClick={signOutOnClick}>
                    Sign Out
                  </div> */}
              </div>
            </div>

            <div className="userdraweraccount">
              <div className="drawer-my-account" onClick={handleOpen1}>
                <Typography variant="subtitle2" gutterBottom>
                  Leave Assign
                </Typography>
              </div>

              <div className="drawer-signout" onClick={() => clearToken()}>
                <Typography variant="subtitle2" gutterBottom>
                  Clear Token
                </Typography>
              </div>
            </div>
          </div>
        </Drawer>
        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            backgroundColor: "#ffffff9e",
          }}
        >
          <Box sx={style}>
            <div className="addcompanytitle">
              LEAVE ASSIGN
              <IconButton
                component="span"
                onClick={handleClose1}
                style={{ padding: 0, marginRight: 20 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <Stack
              spacing={4}
              padding={5}
              width="100%"
              alignItems="center"
              height="100%"
            >
              <Grid container spacing={4}>
                <Grid item xs={6} alignItems="center" justifyContent=" center">
                  <Autocomplete
                    style={{
                      textTransform: "capitalize",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "'Montserrat', sans-serif",
                      width: "100%",
                      height: "50px",
                    }}
                    id="outlined-multiline-static"
                    size="small"
                    fullWidth
                    options={leaveList}
                    onChange={LeaveTypeList}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        // helperText="select your branch"
                        label="Select Leave Type*"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} alignItems="center" justifyContent=" center">
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="standard"
                    label={"No Of Days"}
                    onChange={(e) => formonchange("no_of_days", e)}
                    defaultValue={leaveDays}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              {/* <Autocomplete
                      style={{
                        textTransform: "capitalize",
                        backgroundColor: "#FFFFFF",
                        fontFamily: "'Montserrat', sans-serif",
                        width: "100%",
                        height: "50px",
                      }}
                      id="outlined-multiline-static"
                      size="small"
                      fullWidth
                      options={GetShiftlist}
                      onChange={shiftList}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          // helperText="select your branch"
                          label="Select Department*"
                        />
                      )}
                    /> */}

              <div className="addcompanybtn">
                <Stack direction={"row"} spacing={2}>
                  <Button
                    variant="outlined"
                    className="tablebtncancel"
                    style={{ color: "#000" }}
                    onClick={handleClose1}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="tablebutton"
                    onClick={() => {
                      updateLeave();
                      // leaveTyeList();
                      // assignShift();
                      // item.branch_id._id,
                      // item.department_id._id,
                      // item._id
                    }}
                  >
                    ASSIGN LEAVE
                  </Button>
                </Stack>
              </div>
            </Stack>
          </Box>
        </Modal>
      </React.Fragment>
    </div>
  );
}
