import React, { useState, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  IconButton,
  Box,
  Stack,
  TextField,
  Typography,
  TableBody,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import "../../styles/website.css";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { columnDef } from "../websites/API/Columns.js";
import { fetchData } from "../websites/API/useGetApi.js";
import deleteWebsite from "../websites/API/useDeleteApi.js";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { login_companyid } from "../../../Atoms/LoginAtoms";
import SettingsIcon from "@mui/icons-material/Settings";
import { IoPeople } from "react-icons/io5";
import axios from "axios";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
// import WebsiteSettingsMenu from "../LiveChatSettings/WebsiteSettings/WebsiteSettingsMenu";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AttachmentMessage from "./components/AttachmentMessage.js";
import MuiAlert from "@mui/material/Alert";
import Alert from "@mui/material/Alert";
import Badge from "@mui/material/Badge";

const addwebstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    marginTop: "20px",
  },
}));

const WebsiteTable = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useRecoilState(login_companyid);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [deleteconfirm, setDeleteconfirm] = useState(false);
  const [deleteWeb, setDeleteWeb] = useState(null);

  const { data } = useQuery({
    queryKey: ["tableData", companyId],
    queryFn: () => fetchData(companyId),
  });
  const finalColumnDef = React.useMemo(() => columnDef, []);
  const [editRowId, setEditRowId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [hoveredRows, setHoveredRows] = useState([]);
  const fileInputRef = useRef(null);

  const handleDelete = async (row) => {
    setDeleteWeb(row);
    setDeleteconfirm(true);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("website_icon", file);
  };

  useEffect(() => {
    if (alertOpen) {
      setAlertVisible(true);
      const timer = setTimeout(() => {
        setAlertOpen(false);
        setAlertVisible(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [alertOpen]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertVisible(false);
  };

  const validationSchema = Yup.object().shape({
    website_name: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter website name"),
    website_url: Yup.string()
      .min(3, "Minimum 3 characters")
      .required("Please enter website url"),
    website_icon: Yup.mixed().required("Please upload website icon"),
  });

  const formik = useFormik({
    initialValues: {
      website_icon: "",
      website_name: "",
      website_url: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting, setFieldError }) => {
      try {
        setLoading(true);
        const file = fileInputRef.current.files[0];
        const formDataToSend = new FormData();
        formDataToSend.append("company_id", companyId);
        formDataToSend.append("website_name", values.website_name);
        formDataToSend.append("website_url", values.website_url);
        formDataToSend.append("website_icon", file);
        const response = await axios.post(
          `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/website`,
          formDataToSend
        );
        console.log(response.data);
        formik.resetForm();
        setAlertMessage("Website added successfully");
        setAlertSeverity("success");
        setAlertOpen(true);
        setOpen(false);
      } catch (error) {
        console.error("Add website error:", error);
        let errorMessage = "Error adding website";
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          errorMessage = error.response.data.error;
        }
        setAlertMessage(errorMessage);
        setAlertSeverity("error");
        setAlertOpen(true);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };

  //Modal
  const [isIntegrationModalOpen, setIsIntegrationModalOpen] = useState(false);

  const toggleIntegrationModal = () => {
    setIsIntegrationModalOpen(!isIntegrationModalOpen);
  };

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const tableInstance = useReactTable({
    columns: finalColumnDef,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const updateWebsite = async (data) => {
    console.log("Update Payload:", data);
    const { _id, __v, ...restData } = data;
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/website/update/${_id}`,
        restData
      );

      if (response.status === 200) {
        const responseData = response.data;
        setAlertMessage("Website added successfully");
        setAlertSeverity("success");
        setAlertOpen(true);
        console.log("Update Response:", responseData);
        return responseData;
      } else {
        console.error("Update failed:", response.data);
        throw new Error(
          `Error during update: ${JSON.stringify(response.data)}`
        );
      }
    } catch (error) {
      let errorMessage;
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error;
      }
      setAlertMessage(errorMessage);
      setAlertSeverity("error");
      setAlertOpen(true);
    }
  };

  const handleSave = async () => {
    try {
      if (editRowId && editedData) {
        const response = await updateWebsite({
          _id: editRowId,
          ...editedData,
        });

        setTableData((prevTableData) =>
          prevTableData.map((item) =>
            item._id === editRowId ? { ...item, ...response } : item
          )
        );

        queryClient.invalidateQueries("tableData");

        setEditRowId(null);
        setEditedData({});
        console.log("Update successful");
      }
    } catch (error) {}
  };

  const handleEdit = (row) => {
    console.log("Website Icon:", row.original.website_icon);

    setEditRowId(row.original._id);
    setEditedData({ ...row.original });
    console.log("Edited Data:", editedData);
  };

  const handleCancel = () => {
    setEditRowId(null);
    setEditedData({});
  };

  const confirmDelete = async () => {
    try {
      const deleteResponse = await deleteWebsite(deleteWeb.original._id);
      if (deleteResponse.ok) {
        setTableData((prevTableData) =>
          prevTableData.filter((item) => item._id !== deleteWeb.original._id)
        );
        setAlertMessage("Website deleted successfully");
        setAlertSeverity("success");
        setAlertOpen(true);
        queryClient.invalidateQueries("websitelist");
        console.log("Delete successful");
      } else {
        console.error("Delete failed:", deleteResponse.responseData);
      }
    } catch (error) {
      console.error("Delete website error:", error);
    } finally {
      setDeleteconfirm(false);
      setDeleteWeb(null);
    }
  };

  const cancelDelete = () => {
    setDeleteconfirm(false);
    setDeleteWeb(null);
  };

  const [selectedWebsiteId, setSelectedWebsiteId] = useState(null);
  const [copied, setCopied] = useState(false);

  const generateScript = (websiteId) => `
    <script type="text/javascript"> window.NDE_LIVECHAT_WEBSITE_ID = "${websiteId}";
    (function () {
      var s = document.createElement("script");
      s.src = "/main.ce1f6f6c";
      s.async = true;
      document.getElementsByTagName("head")[0].appendChild(s);
    })();
    </script>
  `;

  const handleCopyClick = () => {
    const script = generateScript(selectedWebsiteId);

    navigator.clipboard
      .writeText(script)
      .then(() => setCopied(true))
      .catch((err) => console.error("Unable to copy to clipboard", err));
  };

  //employee modal box
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedEmployeesMap, setSelectedEmployeesMap] = useState({});


  const toggleEmployeeModal = (event, rowElement) => {
    console.log("Toggling employee modal...");
    setIsEmployeeModalOpen(!isEmployeeModalOpen);
    if (!isEmployeeModalOpen) {
      setSelectedEmployees([]);
    }
    if (rowElement) {
      console.log("Setting selected website id:", rowElement.original._id);
      setSelectedWebsiteId(rowElement.original._id);
    }
  };

  useEffect(() => {
    const fetchEmployeeList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/employee/employeeList/${companyId}`
        );
        console.log("Employee list",response.data)
        setEmployeeList(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Fetch employee list error:", error);
        setLoading(false);
      }
    };

    if (companyId) {
      fetchEmployeeList();
    }
  }, [companyId]);

  const handleEmployeeToggle = (employeeId) => {
    console.log("Toggling employee:", employeeId);
    setSelectedEmployees((prevSelected) => {
      if (prevSelected.includes(employeeId)) {
        console.log("Deselecting employee:", employeeId);
        return prevSelected.filter((id) => id !== employeeId);
      } else {
        console.log("Selecting employee:", employeeId);
        return [...prevSelected, employeeId];
      }
    });
  };
  const handleEmployeeSubmit = async () => {
    console.log("Submitting selected employees...");
    if (selectedEmployees.length === 0 || !selectedWebsiteId || !companyId) {
      console.error(
        "Please select at least one employee, a website, and a company."
      );
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/employee/assign-employee/${selectedWebsiteId}/${companyId}`,
        { employee_ids: selectedEmployees }
      );
      console.log("Assigned Employees:", response.data);
      toggleEmployeeModal();
    } catch (error) {
      console.error("Error assigning employees:", error);
    }
  };

  const handleCancelEmployee = () => {
    console.log("Cancelling employee assignment...");
    toggleEmployeeModal();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const [clickedRow, setClickedRow] = useState(null);

  const handleMenuOpen = (event, rowElement) => {
    setAnchorEl(event.currentTarget);
    setClickedRow(rowElement);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setClickedRow(null);
  };
  const handleCloseSnackbar = () => {
    setCopied(false);
  };

  //settings menu
  const [showSettings, setShowSettings] = useState(false);
  const handleSettingsIconClick = () => {
    setShowSettings(!showSettings);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <div className="websitelist">
      <div className="website_table">
        <Box>
          <div
            className="website_table-filter"
            style={{ justifyContent: "flex-end" }}
          >
            <div>
              <Button
                sx={{
                  color: "rgb(212, 211, 211) !important",
                  border: "1px solid #3367d6 !important",
                  backgroundColor: "#3367d6 !important",
                  padding: "10px 20px !important",
                  borderRadius: "8px !important",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
                onClick={handleOpen}
              >
                ADD WEBSITE
              </Button>

              {/* modal box */}
              <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={addwebstyle}>
                  <div>
                    <Typography
                      variant="h6"
                      sx={{
                        paddingTop: "10px",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        fontSize: 16,
                        fontWeight: 600,
                        color: "#fff",
                        fontFamily: "Inter",
                        textTransform: "capitalize",
                        backgroundColor: "royalblue",
                        height: "50px",
                      }}
                    >
                      ADD WEBSITE
                    </Typography>
                  </div>
                  <Stack
                    spacing={2}
                    direction="row"
                    width="100%"
                    alignItems="center"
                    height="100%"
                    justifyContent="center"
                  >
                    <Grid container direction="column">
                      <Grid item xs={12} sm={6} md={4}>
                        <Stack
                          spacing={2}
                          padding={2}
                          direction="row"
                          width="100%"
                          height="100%"
                        >
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "115px",
                              fontSize: "15px",
                              fontFamily: "Inter",
                            }}
                          >
                            Website name<span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            id="website_name"
                            placeholder="Acme Inc."
                            variant="outlined"
                            sx={{
                              width: "450px",
                              marginLeft: "45px !important",
                              "& .MuiInputBase-input": {
                                padding: "13.5px 14px",
                              },
                            }}
                            name="website_name"
                            value={formik.values.website_name}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.website_name &&
                              Boolean(formik.errors.website_name)
                            }
                            helperText={
                              formik.touched.website_name &&
                              formik.errors.website_name
                            }
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Stack
                          spacing={2}
                          padding={2}
                          direction="row"
                          width="100%"
                          height="100%"
                        >
                          <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginRight: "115px",
                              fontSize: "15px",
                              fontFamily: "Inter",
                            }}
                          >
                            Website domain
                            <span style={{ color: "red" }}>*</span>
                          </Typography>
                          <TextField
                            id="website_url"
                            placeholder="acme.com"
                            variant="outlined"
                            sx={{
                              width: "450px",
                              marginLeft: "33px !important",
                              "& .MuiInputBase-input": {
                                padding: "13.5px 14px",
                              },
                            }}
                            name="website_url"
                            value={formik.values.website_url}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.website_url &&
                              Boolean(formik.errors.website_url)
                            }
                            helperText={
                              formik.touched.website_url &&
                              formik.errors.website_url
                            }
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Stack
                          spacing={2}
                          padding={2}
                          direction="row"
                          alignItems="center"
                        >
                          <Typography
                            variant="body1"
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            Website Icon<span style={{ color: "red" }}>*</span>
                          </Typography>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "60px !important",
                            }}
                          >
                            {formik.values.website_icon && (
                              <img
                                src={URL.createObjectURL(
                                  formik.values.website_icon
                                )}
                                alt="Website Icon"
                                style={{
                                  maxWidth: "80px",
                                  maxHeight: "100px",
                                }}
                              />
                            )}
                            <label
                              htmlFor="website_icon"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                marginLeft: "60px !important",
                                flex: 1,
                                color: "#75869c",
                                paddingTop: "19px",
                                paddingBottom: "19px",
                                paddingRight: "10px",
                                paddingLeft: "5px",
                              }}
                            >
                              <input
                                style={{ display: "none" }}
                                type="file"
                                id="website_icon"
                                name="website_icon"
                                accept="image/png, image/jpeg"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                              />
                              <CloudUploadIcon
                                sx={{
                                  width: "25px",
                                  height: "25px",
                                  marginLeft: "60px !important",
                                }}
                              />
                            </label>
                          </div>
                          {formik.touched.website_icon &&
                            formik.errors.website_icon && (
                              <Typography variant="body2" color="error">
                                {formik.errors.website_icon}
                              </Typography>
                            )}
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          justifyContent="flex-end"
                          marginRight="25px"
                          marginBottom="18px"
                        >
                          <Button
                            variant="outlined"
                            className="tablebtncancel"
                            style={{ color: "#000" }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffffff !important",
                              color: "#3367d6 !important",
                              border: "1px solid #3367d6 !important",
                            }}
                            onClick={formik.handleSubmit}
                          >
                            {loading ? (
                              <CircularProgress size={24} />
                            ) : (
                              "Add Website"
                            )}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Modal>

              {alertVisible && (
                <Alert
                  variant="filled"
                  severity={alertSeverity}
                  onClose={handleCloseAlert}
                  sx={{ position: "fixed", bottom: 0, left: 10, right: 0 }}
                >
                  {alertMessage}
                </Alert>
              )}
            </div>
          </div>

          {/* integration code  */}
          <Modal
            open={isIntegrationModalOpen}
            onClose={() => setIsIntegrationModalOpen(false)}
          >
            <Box
              sx={{
                width: 500,
                height: 490,
                backgroundColor: "whitesmoke",
                position: "absolute",
                top: "10%",
                left: "35%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  color: "white",
                  paddingLeft: "25px",
                  paddingTop: "20px",
                  backgroundColor: "#0d6efd",
                  height: "60px",
                }}
              >
                HTML
              </Typography>

              <div>
                <Typography
                  sx={{
                    fontSize: "13px",
                    height: "40px",
                    width: "350px",
                    marginTop: "20px",
                    marginLeft: "20px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: "black",
                    borderRadius: "8px",
                    lineHeight: "35px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "2px",
                      backgroundColor: "#eff3f6",
                      borderRadius: "8px",
                      paddingLeft: "10px",
                      borderLeft: "5px solid #0093ee",
                    }}
                  >
                    1. Copy the following code
                  </Box>
                  <Box
                    sx={{
                      marginTop: "15px",
                      backgroundColor: "#2c405a",
                      color: "white",
                      fontSize: "11px",
                      lineHeight: "18px",
                      border: "1px solid #000000",
                      borderRadius: "4px",
                      fontWeight: "100px",
                      padding: "10px",
                      width: "354px",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        color: "white",
                        marginLeft: "30px",
                        marginRight: "-10px",
                        marginTop: "-5px",
                      }}
                      onClick={handleCopyClick}
                    >
                      <ContentCopyIcon />
                    </IconButton>
                    <div style={{ maxWidth: "341px", fontWeight: "normal" }}>
                      {generateScript(selectedWebsiteId)}{" "}
                    </div>
                    <Snackbar
                      open={copied}
                      autoHideDuration={6000}
                      onClose={handleCloseSnackbar}
                    >
                      <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={handleCloseSnackbar}
                        severity="success"
                      >
                        Script copied to clipboard!
                      </MuiAlert>
                    </Snackbar>
                  </Box>

                  {/*  2.Add the code in your page  */}
                  <Box
                    sx={{
                      padding: "2px",
                      backgroundColor: "#eff3f6",
                      borderRadius: "8px",
                      paddingLeft: "10px",
                      borderLeft: "5px solid #0093ee",
                      marginTop: "8px",
                    }}
                  >
                    2. Add the code in your page
                  </Box>

                  <Box
                    sx={{
                      padding: "5px",
                      borderRadius: "8px",
                      paddingLeft: "10px",
                      marginTop: "3px",
                      fontFamily: "poppins",
                      fontSize: "11px",
                      lineHeight: "15px",
                    }}
                  >
                    <p
                      style={{
                        display: "inline",
                        fontWeight: "bolder",
                        marginRight: "5px",
                      }}
                    >
                      Paste the Crisp chatbox code above in your page.
                    </p>
                    <p style={{ display: "inline", fontWeight: "lighter" }}>
                      You can add it in the HTML head section.
                    </p>
                    <p style={{ marginTop: "15px", fontWeight: "lighter" }}>
                      If the chatbox is not showing, try to reset your cache.
                      You can also contact our support team.
                    </p>
                  </Box>
                </Typography>
              </div>
            </Box>
          </Modal>
          {/* Employee modal */}
          <Modal open={isEmployeeModalOpen} onClose={toggleEmployeeModal}>
            <Box
              sx={{
                width: 390,
                height: 309,
                backgroundColor: "white",
                position: "absolute",
                top: "28%",
                left: "38%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  fontFamily: "Inter",
                  fontWeight: 500,
                  color: "white",
                  backgroundColor: "#1c65d4",
                  height: "60px",
                  paddingTop: "19px",
                  paddingLeft: "19px",
                }}
              >
                Employee List
              </Typography>

              <div style={{ backgroundColor: "white" }}>
                {employeeList.map((employee) => (
                  <div
                    key={employee._id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "15px",
                      marginTop: "15px",
                      backgroundColor: "white",
                    }}
                  >
                    <input
                      type="checkbox"
                      id={employee._id}
                      checked={selectedEmployees.includes(employee._id)}
                      onChange={() => handleEmployeeToggle(employee._id)}
                      style={{
                        marginRight: "20px",
                        width: "17px",
                        height: "17px",
                      }}
                    />
                    <label
                      htmlFor={employee._id}
                      style={{ fontFamily: "Inter", fontSize: "13px" }}
                    >
                      {employee.first_name}
                    </label>
                  </div>
                ))}

                {/* Submit button */}
                <Button
                  variant="contained"
                  onClick={handleEmployeeSubmit}
                  sx={{
                    position: "absolute",
                    bottom: "15px",
                    right: "10px",
                    textTransform: "none",
                    marginRight: "10px",
                    width: "100px",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    marginTop: "15px",
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleCancelEmployee}
                  sx={{
                    position: "absolute",
                    bottom: "15px",
                    right: "120px",
                    textTransform: "none",
                    marginRight: "10px",
                    width: "100px",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    marginTop: "15px",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </Modal>

          {/* TABLE  */}
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <th
                    colSpan={4}
                    style={{
                      color: "#1c293b",
                      fontSize: "15px",
                      fontWeight: 700,
                      paddingTop: "16px",
                      paddingLeft: "18px",
                      fontFamily: "Inter",
                    }}
                  >
                    Website Memberships
                  </th>
                </TableRow>
                <TableRow>
                  <th
                    colSpan={4}
                    style={{
                      color: "#75869c",
                      marginLeft: "5px !important",
                      fontWeight: 300,
                      fontSize: "14px",
                      lineHeight: "22px",
                      fontFamily: "Inter",
                      paddingTop: "10px",
                      paddingRight: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "18px",
                    }}
                  >
                    <span
                      style={{
                        color: "#75869c",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "22px",
                        fontFamily: "Inter",
                      }}
                    >
                      Those are the websites you are member of
                    </span>{" "}
                    select a website to access its settings.{" "}
                  </th>
                </TableRow>
              </TableHead>

              {tableInstance.getRowModel().rows.map((rowElement) => {
                return (
                  <TableBody
                    sx={{
                      cursor: "pointer",
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "#FAFAFA",
                      },
                    }}
                  >
                    <TableRow
                      key={rowElement.id}
                      onMouseEnter={() =>
                        setHoveredRows((prevRows) => [
                          ...prevRows,
                          rowElement.id,
                        ])
                      }
                      onMouseLeave={() =>
                        setHoveredRows((prevRows) =>
                          prevRows.filter((id) => id !== rowElement.id)
                        )
                      }
                    >
                      <TableCell
                        key="website_icon"
                        onClick={() => {
                          console.log(
                            "Website Icon:",
                            rowElement.original.website_icon
                          );
                        }}
                      >
                        <AttachmentMessage
                          websiteId={rowElement.original._id}
                          companyId={companyId}
                          website_icon={rowElement.original.website_icon}
                        />
                      </TableCell>

                      <TableCell
                        key="website_name"
                        onClick={(e) => e.stopPropagation()}
                        style={{ paddingLeft: "57px" }}
                      >
                        {editRowId === rowElement.original._id ? (
                          <TextField
                            name="website_name"
                            value={editedData.website_name}
                            onChange={(e) => {
                              setEditedData({
                                ...editedData,
                                website_name: e.target.value,
                              });
                            }}
                          />
                        ) : (
                          <Link
                            style={{
                              color: "black",
                              fontFamily: "Inter",
                              fontSize: "15px",
                            }}
                            to={`/chat/${rowElement.original._id}`}
                          >
                            {rowElement.original.website_name}
                          </Link>
                        )}
                      </TableCell>

                      <TableCell
                        key="website_url"
                        style={{ paddingLeft: "88px" }}
                      >
                        {editRowId === rowElement.original._id ? (
                          <TextField
                            name="website_url"
                            value={editedData.website_url}
                            onChange={(e) => {
                              setEditedData({
                                ...editedData,
                                website_url: e.target.value,
                              });
                            }}
                          />
                        ) : (
                          <Link
                            style={{
                              color: "black",
                              fontFamily: "Inter",
                              fontSize: "15px",
                            }}
                            to={`/chat/${rowElement.original._id}`}
                          >
                            {editRowId === rowElement.original._id
                              ? editedData.website_url
                              : rowElement.original.website_url}
                          </Link>
                        )}
                      </TableCell>

                      <TableCell>
                        {editRowId === rowElement.original._id &&
                        Object.keys(editedData).length > 0 ? (
                          <Stack direction={"row"} spacing={2}>
                            <Button
                              variant="contained"
                              className="tablebutton"
                              onClick={handleSave}
                              sx={{
                                textTransform: "none",
                              }}
                            >
                              Update
                            </Button>

                            <Button
                              svariant="outlined"
                              className="tablebtncancel"
                              style={{ color: "#000" }}
                              onClick={handleCancel}
                              sx={{
                                textTransform: "none",
                              }}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        ) : (
                          <>
                            <Button
                              onClick={(e) =>
                                toggleEmployeeModal(e, rowElement)
                              }
                            >
                              <div style={{ position: "relative" }}>
                                <IoPeople style={{ fontSize: "20px" }} />
                                {selectedEmployees.length > 0 && (
                                  <Badge
                                    style={{
                                      position: "absolute",
                                      top: "-8px",
                                      right: "-8px",
                                      backgroundColor: "red",
                                      color: "white",
                                      borderRadius: "50%",
                                      width: "20px",
                                      height: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {selectedEmployees.length}
                                  </Badge>
                                )}
                              </div>
                            </Button>

                            <Button
                              style={{ color: "#0d6efd" }}
                              onClick={handleSettingsIconClick}
                            >
                              <SettingsIcon
                                style={{
                                  fontSize: "20px",
                                }}
                              />
                            </Button>

                            <IconButton
                              onClick={(e) => handleMenuOpen(e, rowElement)}
                              style={{ color: "#0d6efd" }}
                            >
                              <HiOutlineDotsVertical
                                style={{ fontSize: "20px" }}
                              />
                            </IconButton>

                            <Menu
                              anchorEl={anchorEl}
                              open={
                                Boolean(anchorEl) && clickedRow === rowElement
                              }
                              onClose={handleMenuClose}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              style={{ fontFamily: "Inter", fontSize: "15px" }}
                            >
                              <MenuItem
                                onClick={() => {
                                  toggleIntegrationModal();
                                  setSelectedWebsiteId(rowElement.original._id);
                                }}
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "15px",
                                  "&:hover": {
                                    backgroundColor: "#0079c4",
                                    color: "white",
                                  },
                                }}
                              >
                                Integration
                              </MenuItem>

                              <MenuItem
                                onClick={() => handleEdit(rowElement)}
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "15px",
                                  "&:hover": {
                                    backgroundColor: "#0079c4",
                                    color: "white",
                                  },
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleDelete(rowElement)}
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "15px",
                                  "&:hover": {
                                    backgroundColor: "#0079c4",
                                    color: "white",
                                  },
                                }}
                              >
                                Delete
                              </MenuItem>
                            </Menu>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                );
              })}

              {/* {showSettings && <WebsiteSettingsMenu />} */}
            </Table>
          </TableContainer>
        </Box>

        <Modal open={deleteconfirm} onClose={cancelDelete}>
          <Box
            sx={{
              width: 400,
              backgroundColor: "white",
              position: "absolute",
              top: "35%",
              left: "40%",
              padding: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "15px",
                height: "40px",
                width: "350px",
                marginTop: "20px",
                marginLeft: "10px",
                marginRight: "10px",
                fontFamily: "Inter",
                color: "black",
                borderRadius: "8px",
                lineHeight: "35px",
              }}
            >
              Are you sure you want to delete the website?
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent="flex-end"
                marginRight="25px"
                marginBottom="18px"
              >
                <Button
                  onClick={confirmDelete}
                  variant="contained"
                  sx={{
                    backgroundColor: "#ffffff !important",
                    color: "#3367d6 !important",
                    border: "1px solid #3367d6 !important",
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={cancelDelete}
                  variant="outlined"
                  className="tablebtncancel"
                  style={{ color: "#000" }}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default WebsiteTable;
