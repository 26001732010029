import React, { useState } from "react";
import { Modal, Box, IconButton, Grid, Stack, Button } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { PriceFormat } from "../../../CustomHook/DateFormat";
import "./RenewModal.css";
import RenewalConfirm from "../../../Component/Renewal/Componets/RenewalConfirm";
import RenewalButton from "../../../Component/Renewal/Componets/RenewalButton";
import RenewalStyle from "../../../Gsuit/Style/Renewal.module.css";
import Gmail from "../../../../../images/DomainDetails/googleRenewal.png";
import RenewalDropDown from "../../../Component/Renewal/Componets/RenewalDropDown";
import PriceTable from "../../../Component/Renewal/Componets/PriceTable";
import RenewalPayment from "../../../Component/Renewal/Componets/ProcessRenewal";
import IsWallet from "../../../CustomHook/IsWallet";
import { displayRazorpay } from "../../../CustomHook/RazorPay/RazorPay";
import { api_url1 } from "../../../../../globalSettings";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius:"8px"
};

const RenewModal = ({
  open,
  handleClose,
  Selecteddomain,
  renewalDomainPrice,
  id,
  setSnackbarInfo,
}) => {
  const yearOptions = [
    { year: 1 },
    { year: 2 },
    { year: 3 },
    { year: 4 },
    { year: 5 },
    { year: 6 },
    { year: 7 },
    { year: 8 },
    { year: 9 },
    { year: 10 },
  ];

  const [processModal, setProcessModal] = useState(false);
  function handleCloseModal() {
    setProcessModal(false);
  }

  const [selectedYear, setSelectedYear] = useState(1);
  const [scroll, setScroll] = React.useState("paper");

  const handleYearChange = (event) => {
    const selected = parseInt(event.target.value, 10);
    setSelectedYear(selected);
  };
  const selectedData = renewalDomainPrice?.mappedData?.find(
    (data) => data.year === selectedYear
  );
  const [loading, setLoading] = useState(false);

  function domainRenewal() {
    setLoading(true);
    let obj = {
      id: id,
      period: selectedData.year,
      isWallet: IsWallet(renewalDomainPrice?.walletBalance),
    };
    axios
      .post(`${api_url1}/payment/razorpay/domain-renewal`, obj)
      .then((result) => {
        if (!result.data.isRazorpay) {
          setSnackbarInfo({
            open: true,
            severity: "success",
            message:
              "Payment Successfully Made Via Wallet,It Will Take Few Minutes To Update",
          });
        } else {
          const handlerFunction = function (response) {
            setSnackbarInfo({
              open: true,
              severity: "success",
              message: "Payment Successful,It Will Take Few Minutes To Update!",
            });
          };
          let options = {
            ...result.data.options,
            handler: handlerFunction,
          };
          displayRazorpay(options, setSnackbarInfo);
        }

        handleCloseModal();
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="addcompanytitle">
            Domain Renewal
            <IconButton
              component="span"
              onClick={handleClose}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <Stack
            spacing={1}
            sx={{ padding: "20px 16px 8px 16px" }}
            width="100%"
            alignItems="center"
            height="100%"
          >
            <RenewalConfirm
              confirmationText={
                "Are You Sure You Want To Confirm The Renewal For"
              }
              domainName={Selecteddomain}
              isDelete={false}
            />
            <div
              className={RenewalStyle.middleWrapper}
              style={{ justifyContent: "space-between" }}
            >
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.img_container}>
                  <img src={Gmail} />
                </div>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>
                    {Selecteddomain}
                  </div>
                  <div className={RenewalStyle.link}>Domain Registration</div>
                </div>
              </div>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>Duration</div>
                  <RenewalDropDown
                    value={selectedYear}
                    handleChange={handleYearChange}
                    valueParam={"year"}
                    keyParam={"year"}
                    items={yearOptions}
                    displayParam={"year"}
                  />
                </div>
              </div>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>Renewal Cost</div>
                  <div className={RenewalStyle.accFontSize}>{`${PriceFormat(
                    selectedData?.price
                  )}`}</div>
                </div>
              </div>
            </div>
            <div className={RenewalStyle.price_wrapper}>
              <PriceTable
                currencyCode={renewalDomainPrice?.countryCode}
                priceData={selectedData?.price}
                gst={selectedData?.Tax}
                tax={selectedData?.total}
              />
            </div>
          </Stack>
          <RenewalButton
            loading={false}
            title={"Proceed"}
            disabled={false}
            clickFunc={() => {
              handleClose();
              setProcessModal(true);
            }}
          />
        </Box>
      </Modal>

      <RenewalPayment
        handleCloseModal={handleCloseModal}
        open={processModal}
        fullAmount={selectedData?.total}
        currencyCode={renewalDomainPrice?.countryCode}
        walletBalance={renewalDomainPrice?.walletBalance}
        loading={loading}
        proceedFunc={domainRenewal}
        id={id}
      />
    </>
  );
};

export default RenewModal;
