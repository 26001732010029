import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import Styles from '../ContactInfo.module.scss';
import { Typography } from '@mui/material';

const EditContent = () => {

    const navigate = useNavigate();
    const { id } = useParams();

    const handleClose = () => {
        navigate(`/chat/contacts/${id}`);
      };
  return (
    <div>
      <div className={Styles.CEditHeader}>
      <WestOutlinedIcon onClick={handleClose} sx={{cursor:"pointer"}}/>
      <Typography sx={{paddingLeft:"16px"}}>EditContent</Typography>
      </div>
    </div>
  )
}

export default EditContent
