import React from "react";
import { formatDateWithOrdinal } from "../Gsuit/HelperFunctions/helperFunction";
import { LoadingButton } from "@mui/lab";
import { customRenewButton } from "../Style/DomainStyles";
import TopStyle from "../Style/TopBar.module.css";
import { cursor } from "@xstyled/styled-components";

const TopBar = ({
  imgSrc,
  domainname,
  orderid,
  purchasedDate,
  expiryDate,
  handleClick,
  loadButton,
  planName,
  isDisable = true,
  isLoading=true,
}) => {
  return (
    <>
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            marginBottom: "10px",
            borderBottom: "1px solid #0b143347",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={imgSrc}
              alt="SettingsIcon"
              style={{
                height: "32px",
                width: "32px",
                marginRight: "4px",
              }}
            />
            <div>
              <p className={TopStyle.domainName_style}>{domainname}</p>

              <div style={{ display: "flex" }}>
                {orderid && (
                  <p className={TopStyle.orderId_style}>Order ID : {orderid}</p>
                )}
                {planName && (
                  <p className={TopStyle.orderId_style}>{planName}</p>
                )}
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "5px" }}>
              <p className={TopStyle.date_style}>
                {formatDateWithOrdinal(purchasedDate)}
              </p>
              <p className={TopStyle.date_style}>
                {formatDateWithOrdinal(expiryDate)}
              </p>
            </div>
            <div style={{ cursor: isDisable ? "not-allowed" : "" }}>
              <LoadingButton
                variant="contained"
                color="success"
                style={customRenewButton}
                onClick={() => {
                  handleClick();
                }}
                loading={loadButton}
                disabled={isLoading || isDisable}
                sx={{ background:  isLoading || isDisable ? "#697971 !important" : "" }}
              >
                Renew
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
