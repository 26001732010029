import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

class VisitorsGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: 2,
      visitors: [
        {
          senderId: "65c9da447089224cf0973a5a",
          name: "santhiya",
          visitior_loca: {
            latitude: "36.7323",
            longitude: "3.0875"
          }
        }
      ]
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  render() {
    const { zoom, visitors } = this.state;

    const mapStyles = {
      width: "100%",
      height: "calc(100vh - 50px)",
    };

 

    return (
      <Map
        google={this.props.google}
        zoom={zoom}
        initialCenter={{ lat: 0, lng: 0 }}
        style={mapStyles}
        onClick={() => {}}
        onZoomChanged={(mapProps, map) => {
          this.setState({ zoom: map.getZoom() });
        }}
      >
        {visitors.map(visitor => (
          <Marker
            key={visitor.senderId}
            position={{ lat: parseFloat(visitor.visitior_loca.latitude), lng: parseFloat(visitor.visitior_loca.longitude) }}
            name={visitor.name }
            label={visitor.name}
            style={labelStyle}
          />
        ))}

        {/* Zoom controls */}
        <div style={zoomControlStyle}>
          <button onClick={this.handleZoomIn} style={buttonStyle}>
            +
          </button>
          <button onClick={this.handleZoomOut} style={buttonStyle}>
            -
          </button>
        </div>
      </Map>
    );
  }
}
const labelStyle = {
  color: "white",
  fontWeight: "bold",
  fontSize: "18px",
  textAlign: "center",
  lineHeight: "30px",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: "5px",
  position: "absolute",
  top: "-40px",
  left: "-50%", 
  height: "30px",
};


const zoomControlStyle = {
  position: "absolute",
  top: "424px",
  left: "938px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "3px",
  padding: "5px",
  fontSize: "30px",
  boxShadow: "0 1px 4px rgba(0, 0, 0, 0.3)",
};

const buttonStyle = {
  background: "none",
  display: "block",
  border: "0px",
  margin: "0px",
  padding: "0px",
  textTransform: "none",
  appearance: "none",
  position: "relative",
  cursor: "pointer",
  userSelect: "none",
  overflow: "hidden",
  width: "40px",
  height: "40px",
  top: "0px",
  left: "0px",
};

export default GoogleApiWrapper({ apiKey: "YOUR_API_KEY_HERE" })(VisitorsGraph);
