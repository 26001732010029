import { Box, Stack, Typography } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { CustomCurrencyField } from "../components/CustomCurrencyField";
import { get, useFormContext, useWatch } from "react-hook-form";
import variables from "../../../assets/styles/_colors.scss";
import { useEffect } from "react";

const options = [
    {
        label: "=",
        value: "="
    },
    {
        label: "!=",
        value: "!="
    },
    {
        label: "<",
        value: "<"
    },
    {
        label: "<=",
        value: "<="
    },
    {
        label: ">",
        value: ">"
    },
    {
        label: ">=",
        value: ">="
    },
    {
        label: "Between",
        value: "between"
    },
    {
        label: "Not Between",
        value: "not_between"
    },
    {
        label: "Is Empty",
        value: "empty"
    },
    {
        label: "Is Not Empty",
        value: "not_empty"
    }
];

const CurrencyType = (props) => {

    const { control, formState: { errors }, resetField, setValue } = useFormContext();
    const fieldErrors = get(errors, props.name);

    const rules = {
        required: {
            value: true
        }
    };

    const selectedOption = useWatch({ control, name: `${props.name}.comparator` })

    useEffect(() => {
        if (selectedOption === "between" || selectedOption === "not_between") {
            resetField(`${props.name}.value`);
        } else if (selectedOption !== "empty" || selectedOption !== "not_empty") {
            resetField(`${props.name}.from`);
            resetField(`${props.name}.to`);
        } else {
            resetField(`${props.name}.value`);
            resetField(`${props.name}.from`);
            resetField(`${props.name}.to`);
        }
    }, [selectedOption]);

    useEffect(() => {
    }, [fieldErrors]);

    useEffect(() => {
        setValue(`${props.name}.comparator`, options[0].value);
        setValue(`${props.name}.value`, "");
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>
            <Stack direction="row" sx={{ width: '100%', pt: 1, flexWrap: 'wrap' }}>
                <Stack direction="row" sx={{ width: '50%' }}>
                    <CustomSelect size="small" options={options} name={`${props.name}.comparator`} defaultValue={options[0].value} />
                </Stack>
                {selectedOption !== "empty" && selectedOption !== "not_empty" &&
                    <>
                        {(selectedOption !== "between" && selectedOption !== "not_between") && <Stack direction="row" sx={{ width: '50%', pl: 1 }}>
                            <CustomCurrencyField size="small" rules={rules} name={`${props.name}.value`} />
                        </Stack>}
                        {(selectedOption === "between" || selectedOption === "not_between") &&
                            <Stack direction="row" sx={{ width: '100%', pt: 1 }} spacing={1}>
                                <CustomCurrencyField size="small" rules={rules} name={`${props.name}.from`} />
                                <CustomCurrencyField size="small" rules={rules} name={`${props.name}.to`} />
                            </Stack>
                        }
                    </>
                }
            </Stack>
            {fieldErrors &&
                <Box sx={{ color: variables.removeIconColor, pt: 1 }}>
                    {fieldErrors["value"] && <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid {props.field?.label} </Typography>}

                    {fieldErrors?.["from"] && fieldErrors?.["to"] ?
                        <Typography sx={{ fontSize: "0.85rem" }}> Please provide valid {props.field?.label} values </Typography>
                        : fieldErrors?.["from"] ?
                            <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid starting {props.field?.label} </Typography>
                            : fieldErrors?.["to"] ? <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid ending {props.field?.label} </Typography> : <></>
                    }
                </Box>
            }
        </>
    )
};

export default CurrencyType;