import { Typography } from "@mui/material";
import { useEffect } from "react";
import CommonDateFields from "./CommonDateFields";

const options = [
    {
        label: "In the Last",
        value: "last"
    },
    {
        label: "Due In",
        value: "due_in"
    },
    {
        label: "On",
        value: "on"
    },
    {
        label: "Before",
        value: "before"
    },
    {
        label: "After",
        value: "after"
    },
    {
        label: "Between",
        value: "between"
    },
    {
        label: "Today",
        value: "today"
    },
    {
        label: "Yesterday",
        value: "yesterday"
    },
    {
        label: "This Week",
        value: "week"
    },
    {
        label: "This Month",
        value: "month"
    },
    {
        label: "This Year",
        value: "year"
    },
    {
        label: "Last Week",
        value: "last_week"
    },
    {
        label: "Last Month",
        value: "last_month"
    },
    {
        label: "Is Empty",
        value: "empty"
    },
    {
        label: "Is Not Empty",
        value: "not_empty"
    }
];

const DateType = (props) => {

    const singleField = ["today", "yesterday", "week", "month", "year", "last_week", "last_month", "empty", "not_empty"];
    const showDateField = ["on", "before", "after"];
    const showTwoDateFields = ["between"];
    const customFields = ["last", "due_in"];

    useEffect(() => {
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>

            <CommonDateFields
                name={props.name}
                field={props.field}
                options={options}
                singleField={singleField}
                showDateField={showDateField}
                showTwoDateFields={showTwoDateFields}
                customFields={customFields} />
        </>
    )
};

export default DateType;