import React from 'react'

const MessengerHome = () => {
  return (
    <div>
      Messenger
    </div>
  )
}

export default MessengerHome
