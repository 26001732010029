import React from "react";
import Grid from "@mui/material/Grid";
import Panel from "./Panel/Panel";
import LoginPage from "./LogIn/LoginPage";
import VerifyMail from "./VerifyMail";
import ResetPassword from "./ResetPassword";
import { useLocation, useNavigate } from "react-router-dom";
import Hidden from "@mui/material/Hidden";

export default function Login() {
  const { pathname } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const token = params.get("token");
  const id = params.get("id");
  return (
    <>
      <div style={{ height: "100vh", width: "100vw" }}>
        <Grid container md={12} xs={12}>
          <Hidden smDown>
            <Grid item md={9} xs={12} sm={8}>
              <Panel />
            </Grid>
          </Hidden>
          <Grid item md={3} xs={12} sm={4}>
            {pathname === "/login" ? (
              <LoginPage />
            ) : pathname === "/login/verifymail" ? (
              <VerifyMail />
            ) : pathname === `/login/resetpassword` ? (
              <ResetPassword token={token} id={id} />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
