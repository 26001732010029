import { Autocomplete, Box, Button, Drawer, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import variables from "../../../assets/styles/_colors.scss";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import PipelineFilter from "./PipelineFilter";
import { callFilters, eventFilters, pipelineFilters, taskFilters } from "./filters";

const drawerWidth = '22vw';

const PipelineFilterWrapper = (props) => {

    const [open, setOpen] = useState(props.open);
    const [selectedFilterOption, setSelectedFilterOption] = useState(null);
    const [option, setOption] = useState(null);
    const [filters, setFilters] = useState([]);
    const [clearFilter, setClearFilter] = useState(false);
    const [disableButton, setDisableButton] = useState(true);

    const methods = useForm(
        {
            defaultValues: {
                data: []
            },
            shouldUnregister: false,
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    useEffect(() => {

    }, [selectedFilterOption]);

    useEffect(() => {
        setFilters(
            props.module === "pipeline" && pipelineFilters
            || props.module === "task" && taskFilters
            || props.module === "event" && eventFilters
            || props.module === "call" && callFilters);
    }, [props.module]);

    useEffect(() => {
        const selectedFilters = filters.filter(filter => filter.selected === true);
        setDisableButton(selectedFilters?.length > 0 ? false : true);
    }, [filters]);

    const getTitle = () => {
        switch (props.module) {
            case "pipeline":
                return "Filter Pipeline";
            case "task":
                return "Filter Tasks";
            case "event":
                return "Filter Events";
            case "call":
                return "Filter Calls";
            default:
                return "";
        }
    };

    const { handleSubmit, trigger } = methods;

    const onSubmit = async (data) => {

        const isStepValid = await trigger("filters");
        if (isStepValid) {
            // alert(JSON.stringify(data.filters));
        }
    }

    const updateSelectionOnFiltersData = (data, select) => {
        const currentIndex = filters.findIndex((d) => d.label === data.label);
        const updatedData = { ...filters[currentIndex], selected: select };
        setFilters([
            ...filters.slice(0, currentIndex),
            updatedData,
            ...filters.slice(currentIndex + 1)
        ]);
    }

    const handleFilterChange = (data) => {
        if (data) {
            updateSelectionOnFiltersData(data, true);
            setOption(data);
            setSelectedFilterOption(null);
        }
    }

    const handleFilterClear = () => {
        setClearFilter(true);
    }

    const resetSelectionOnAllFilterData = () => {
        const newVal = filters.map(filter => {
            return { ...filter, selected: false };
        });
        setFilters(newVal);
        setClearFilter(false);
    }

    const handleFilterClose = () => {
        setOpen(false);
        props.handleFilterClose();
    }

    return (
        <Drawer
            sx={{
                height: "93vh",
                width: open ? `${drawerWidth}` : 0,
                top: 48,
                left: 60,
                position: 'absolute',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: open ? `${drawerWidth}` : `-${drawerWidth}`,
                    boxSizing: 'border-box',
                }
            }}
            variant="persistent"
            anchor="left"
            open={open}
            PaperProps={{ sx: { height: "93vh !important" } }}
        >
            <Stack direction="column" spacing={2} sx={{ p: "8px 16px 16px 16px", borderBottom: `1px solid ${variables.borderColor}` }}>

                <Stack direction="row" alignItems="center" >
                    <IconButton component="span" onClick={(event) => { event.preventDefault(); event.stopPropagation(); handleFilterClose() }} sx={{ ml: -1, pl: 1.25 }}>
                        <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                    <Typography sx={{ p: "8px 4px", fontWeight: 'bold' }}>{getTitle()}</Typography>
                </Stack>

                <Autocomplete
                    sx={{ mt: 0.5 }}
                    id="filter"
                    size="small"
                    options={filters.filter(item => !item.selected)}
                    value={selectedFilterOption}
                    onChange={(e, data) => { handleFilterChange(data); }}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    fullWidth
                    blurOnSelect={true}
                    renderInput={(params) => <TextField {...params} placeholder="Choose a Property"
                        sx={{
                            ".MuiOutlinedInput-root": {
                                border: `1px solid ${variables.borderColor}`, borderRadius: "4px"
                            }
                        }}
                    />}
                />
            </Stack>
            <Box sx={{ overflowY: 'auto', height: '70vh !important' }}>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)} id="pipelineFilter" style={{ height: "100%" }}>
                        <PipelineFilter selectedOption={option} unselectOption={(data) => updateSelectionOnFiltersData(data, false)}
                            resetSelectionOnAllFilterData={resetSelectionOnAllFilterData} clearFilter={clearFilter} />
                    </form>
                </FormProvider>
            </Box>
            <Stack direction="row" spacing={2} justifyContent="left" sx={{
                maxHeight: 70, position: "absolute", bottom: 0,
                width: "100%", padding: "10px 20px", background: `${variables.lightGreyBackgroundColor}`
            }}>
                <Button variant="contained" form="pipelineFilter" type="submit" disabled={disableButton}>Apply Filter</Button>
                <Button variant="outlined" onClick={handleFilterClear}>Clear</Button>
            </Stack>

        </Drawer>
    )
}

export default PipelineFilterWrapper;