import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Button, CircularProgress, Drawer, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import CustomFieldOptions from './CustomFieldOptions';
import { useFormContext } from 'react-hook-form';
import { CustomSelect } from '../components/CustomSelect';
import variables from "../../../assets/styles/_colors.scss";
import { CloseButton } from '../../../components/CloseButton';
import { CustomInputField } from '../components/CustomInputField';

export default function CustomFields(props) {

    const options = {
        type: [{
            label: "Picklist",
            value: "picklist"
        }, {
            label: "Text",
            value: 'text'
        }, {
            label: "Numerical",
            value: 'numerical'
        }, {
            label: "Lookup",
            value: 'lookup'
        }, {
            label: "User",
            value: 'user'
        }, {
            label: "Date",
            value: 'date'
        }, {
            label: "Currency",
            value: 'currency'
        }, {
            label: "Email",
            value: 'email'
        }, {
            label: "Phone",
            value: 'phone'
        }, {
            label: "URL",
            value: 'url'
        }, {
            label: "Checkbox",
            value: 'checkbox'
        }]
    }

    const {
        getValues,
        setValue,
        trigger,
        watch,
        clearErrors,
        unregister,
        formState: { errors }
    } = useFormContext();

    useEffect(() => {
        setValue("creatingCustomField", true);
    }, []);

    useEffect(() => {
        clearErrors("customField.label")
        clearErrors("customField.type")
        if (props.addMode) {
            setValue("customField.label", "");
            setValue("customField.type", "");
        } else {
            const d = props.data;
            setEditData(d);
            setValue("customField", d);
        }
    }, [props.data]);

    const [open, setOpen] = useState(true);
    const [editData, setEditData] = useState(null);

    function handleDrawerClose(data = null, reopenModal) {
        props.handleDrawerClose(data, props.addMode, props.listName, reopenModal);
    }

    const selectedValue = watch("customField.type");

    const setRulesAndMessage = (obj) => {
        let customField = obj;
        customField.key = obj.label.toLowerCase().replace(/\s+(\w)?/gi, function (match, letter) {
            return letter.toUpperCase();
        });
        if (customField?.rules?.required?.value === true) {
            customField.rules.required.message = `${customField.label} cannot be empty`;
        }
        if (customField.type === "url") {
            customField.rules.pattern = {
                value: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                message: `Please enter a valid ${customField.label}`
            }
        }
        if (customField.type === "email") {
            customField.rules.pattern = {
                value: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                message: `Please enter a valid ${customField.label}`
            }
        }
        if (customField.type === "phone") {
            customField.rules.maxLength = {
                value: customField.subType,
                message: `${customField.label} shouldn't exceed ${customField.subType} characters`
            }
        }
        if (customField.type === "numerical") {
            customField.rules.maxLength = {
                value: 9,
                message: "Number is out of range"
            }
        }


        return customField;
    }
    const saveCustomField = async (event, reopenModal) => {
        event.preventDefault();
        const isStepValid = await trigger("customField");
        if (isStepValid) {
            const updatedObj = setRulesAndMessage(getValues("customField"));
            // alert(JSON.stringify(updatedObj));
            handleDrawerClose(updatedObj, reopenModal);
        }
        event.stopPropagation();
    }

    useEffect(() => {
        return () => {
            unregister("customField");
        }
    }, [])

    return (
        <div>
            <React.Fragment>
                <Drawer anchor="right" open={open}
                    sx={{ minWidth: "35vw", maxWidth: "35vw" }}
                    PaperProps={{
                        sx: {
                            minWidth: "35vw", maxWidth: "35vw", pt: "20px", pb: 2,
                            borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                        },
                    }}>

                    <Typography variant="h5" sx={{ pl: 2 }}>{props.addMode ? "Create Custom Field" : "Edit Field"}
                        <CloseButton handleDialogClose={handleDrawerClose} />
                    </Typography>

                    <Box sx={{ overflowX: "hidden", overflowY: "auto", maxHeight: "84vh", pr: 2, pl: 2 }}>
                        <Stack direction="column" spacing={2} sx={{ width: '100%', pt: 2 }}>
                            <CustomInputField name="customField.label" disabled={editData && editData.systemDefined} label="Field Label" />
                            <CustomSelect disabled={!props.addMode} inputLabel="Field Type" name={"customField.type"}
                                label="Field Type" options={options.type}>
                            </CustomSelect>
                        </Stack>

                        {selectedValue &&
                            <CustomFieldOptions addMode={props.addMode} selectedFieldType={selectedValue} selectedField={editData} />
                        }
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="right" sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button variant="outlined" onClick={() => handleDrawerClose()}> Cancel </Button>
                        {props.addMode && <Button variant="outlined" onClick={(event) => saveCustomField(event, true)}> Save & New </Button>}
                        <Button
                            // disabled={isSubmitting}
                            onClick={(event) => saveCustomField(event)}
                            variant="contained"
                            color="primary">
                            Save
                        </Button>
                        {/* {isSubmitting && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )} */}
                    </Stack>
                </Drawer>
            </React.Fragment>
        </div >
    );
}
