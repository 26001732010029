import React , {useState} from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styled from "styled-components";
import { Tooltip } from "@mui/material";
import AddProjectDrawer from "./AddProjectDrawer";

const Button = styled.div`
  background-color: white;
  color: #0098e5;
  cursor: pointer;
  padding: 2px;
  margin-left: 10px;
`;

export default function AddProject() {

     
  //   ================================ DRAWER =====================================

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
   
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };

  return (
    <div>
      <Tooltip title="Add Project">
        <Button onClick={toggleDrawer}>
          <AddCircleIcon />
        </Button>
      </Tooltip>
      <AddProjectDrawer
                open={openDrawer}
                toggleDrawer={toggleDrawer}
              />
    </div>
  );
}
