import React from "react";
import ChatTabPanel from "../MainChat/ChatTabPanel";
import { Routes, Route } from "react-router-dom";
import Messages from "./ChatMessages";
import Info from "./VisitorInformation";
import selectConvo from "../images/selectConvo.jpg";
import { Box, Typography } from "@mui/material";
const ChatMain = () => {
  return (
    <div style={{ display: "flex" }}>
      <div>
        <ChatTabPanel />
      </div>
      <Routes>
        <Route path="/chat" element={<ChatArea />} />
        <Route path="*" element={<DefaultChat />} />
      </Routes>
    </div>
  );
};

export default ChatMain;

const ChatArea = () => {
  console.log("santhiyaa--------------------------------------------");

  return (
    <div style={{ display: "flex" }}>
      <Messages />
      <Info />
    </div>
  );
};

const DefaultChat = () => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "1px",
        width: "65vw",
        display: " flex",
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <img
          src={selectConvo}
          alt="nomsg  404"
          style={{
            width: "550px",
            height: "400px",
          }}
        />

        <Typography
          style={{
            fontSize: "14px",
            fontFamily: "Inter",
            color: "#1c293b",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          select a conversation.
        </Typography>
        <Typography
          style={{
            fontSize: "12px",
            fontFamily: "Inter",
            color: "#75869c",
            textAlign: "center",
          }}
        >
          choose conversation to start a Message.
        </Typography>
      </Box>
    </Box>
  );
};
