import React, { useState } from 'react';

function CustomsShowMore({ text, more = 'Show more', less = 'Show less' }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleShowMore = (event) => {
    event.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      {isExpanded && <p>{text}</p>}
      <a
        href="#"
        onClick={toggleShowMore}
        className="showmoreless"
        style={{ textDecoration: 'none', color: '#2485e8' }}
      >
        {isExpanded ? less : more}
      </a>
    </div>
  );
}

export default CustomsShowMore;
