import React from "react";
import VisitorHead from "./VisitorHead";
import Circleview from '../../images/circleview.png';

const VCircleview = () => {
  return (
    <div style={{ width: "100%" }}>
      <VisitorHead />
      <div style={{display:"flex",justifyContent:"center"}}>
        <img src={Circleview} alt="Circleview"/>
      </div>
    </div>
  );
};

export default VCircleview;
