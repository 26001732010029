import { Box, Chip, IconButton, Stack, Typography } from "@mui/material";
import variables from "../../../../assets/styles/_colors.scss";
import { useState } from "react";
import { flexRender } from "@tanstack/react-table";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import * as React from 'react';
import { useEffect } from "react";
import UpsertTransitionRule from "./UpsertTransitionRule";
import DeleteTransitionRule from "./DeleteTransitionRule";

export default function TransitionRulesTableRow(props) {

    const rowEl = props.rowEl;
    const [isHovering, setIsHovering] = useState(false);
    const [editRule, setEditRule] = useState(false);
    const [deleteRule, setDeleteRule] = useState(false);
    const [infoToBeFilled, setInfoToBeFilled] = useState([]);

    useEffect(() => {
        let info = [];
        props.rowEl?.original?.hasChecklist && info.push("Checklist");
        props.rowEl?.original?.mandateNotes && info.push("Notes");
        props.rowEl?.original?.mandateFiles && info.push("Files");
        props.rowEl?.original?.mandateFields && rowEl?.original?.mandatoryFields.map(field => {
            info.push(field.label);
        })
        setInfoToBeFilled(info);
    }, [props.rowEl]);

    const handleEditRuleClose = (data) => {
        setEditRule(false);
        resetHover(data);
    }

    const handleDeleteRuleClose = (data) => {
        setDeleteRule(false);
        resetHover(data);
    }

    const resetHover = (data) => {
        setIsHovering(false)
        data && props.showSnackbar(data);
    }

    return (
        <tr key={rowEl?.original?.id} id={rowEl?.original?.id} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
            style={{ position: "relative", backgroundColor: isHovering ? `${variables.tableBackgroundColor}` : '#fff', height: "47px" }}
        >
            {rowEl.getVisibleCells().map((cellEl) => {
                return (
                    <td key={cellEl.id}>
                        {(cellEl.column.columnDef.accessorKey === "fromStage.stageName")
                            ? <>
                                <Box onClick={() => props.handleUserViewClick(rowEl?.original)}
                                    style={{ fontWeight: 'bold', color: isHovering ? `${variables.linkColor}` : "#000", cursor: "pointer" }} >
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography sx={{ fontWeight: "bold" }}>{rowEl?.original?.fromStage ? rowEl?.original?.fromStage?.stageName : "Any Stage"}</Typography>
                                    </Stack>
                                </Box>
                            </>
                            : (cellEl.column.columnDef.accessorKey === "informationToBeFilled")
                                ?
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    flexWrap: 'wrap',
                                    m: 0,
                                    p: 0
                                }}>
                                    {
                                        infoToBeFilled.map((data, index) => {
                                            return (
                                                <Chip sx={{ m: 0.5, ml: 0 }} label={data} key={index} />
                                            );
                                        })}
                                </Box>
                                : <>
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                </>
                        }
                    </td>
                );
            })}
            {
                <Stack direction="row" spacing={1} alignItems="center" sx={{
                    visibility: isHovering ? 'inherit' : 'hidden',
                    height: "47px",
                    zIndex: 5,
                    pr: 0.5,
                    bgcolor: `${variables.lightGreyBackgroundColor}`,
                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    position: 'sticky', right: 0.5,
                    pl: 0.5
                }}>
                    <IconButton onClick={() => setEditRule(true)} sx={{ height: "35px", width: "35px" }} >
                        <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                    </IconButton>

                    <IconButton onClick={() => setDeleteRule(true)} sx={{ height: "35px", width: "35px" }} >
                        <DeleteOutlineIcon fontSize='medium' color={variables.greyTextColor} />
                    </IconButton>
                </Stack>
            }

            {editRule && <UpsertTransitionRule
                edit={true}
                item={rowEl?.original}
                teamPipelineId={props.teamPipelineId}
                subPipelineId={props.subPipelineId}
                handleDrawerClose={(data) => handleEditRuleClose(data)} />}

            {deleteRule && <DeleteTransitionRule item={rowEl?.original} handleClose={(data) => handleDeleteRuleClose(data)} />}
        </tr >
    )
}
