import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";

const MuiTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-input.MuiOutlinedInput-input": {
      height: "32px",
      borderRadius: "5px",
      padding: "3px",
      "&:focus": {
        border: "1px solid #d2cece", 
      },
      "&:hover": {
        "& + fieldset": {
            border: "1px solid #d2cece"
        },
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #d2cece",
    },
  }));
  

const CustomTextField = ({ value, name, handlechange }) => {
  return (
    <div>
      <MuiTextField value={value} name={name} onChange={handlechange} />
    </div>
  );
};

export default CustomTextField;
