import { Stack, Typography } from "@mui/material"
import variables from "../../../../assets/styles/_colors.scss";
import { makeStyles } from '@mui/styles';
import { withStyles } from "@mui/styles";
const useStyles = makeStyles(() => ({
    boldText: {
        color: `${variables.blackTextColor}`,
        fontWeight: 'bold !important'
    }
}));

const StyledTableSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

export const PipelineTableFooter = (props) => {

    const classes = useStyles();

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="left" spacing={1} sx={{ width: '50%' }}>
                <Typography sx={{ color: `${variables.greyTextColor}` }}> Total Deals </Typography>
                <StyledTableSeparator />
                <Typography component="span" className={classes.boldText}>{props.meta?.totalDeals}</Typography>
                <Typography sx={{ color: `${variables.greyTextColor}`, pl: 2 }}> Open Deals</Typography>
                <StyledTableSeparator />
                <Typography component="span" className={classes.boldText}>{props.meta?.openDeals}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="right" spacing={3} sx={{ width: '50%' }}>
                {props.table?.getSelectedRowModel()?.flatRows?.length > 0 &&
                    <Typography sx={{ color: `${variables.greyTextColor}` }}> Selected
                        <Typography component="span" className={classes.boldText}> {props.table?.getSelectedRowModel()?.flatRows?.length} </Typography>
                        of
                        <Typography component="span" className={classes.boldText}> {props.meta?.totalRowCount} </Typography>
                        rows
                    </Typography>
                }
                <Typography sx={{ color: `${variables.greyTextColor}` }}> Fetched
                    <Typography component="span" className={classes.boldText}> {props.data?.length} </Typography>
                    of
                    <Typography component="span" className={classes.boldText}> {props.meta?.totalRowCount} </Typography>
                    rows
                </Typography>
            </Stack>
        </>
    )
}
