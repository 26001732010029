import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ListItemIcon, Stack, Button, IconButton, ListItemText, Box, Typography } from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import variables from "../../assets/styles/_colors.scss";
import { CrmViews } from '../../components/CrmViews';
import FilterListIcon from '@mui/icons-material/FilterList';
import PipelineFilterWrapper from '../pipelines/filter/PipelineFilterWrapper';
import TableBulkActionsPopover from '../pipelines/view/bulkactions/TableBulkActionsPopover';
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import ChangeOwner from '../pipelines/view/bulkactions/ChangeOwner';
import { companies_atom } from '../../../../Atoms/CrmAtoms';
import UpsertCompany from './create/UpsertCompany';

const CompanyActions = (props) => {
    const [showCompany, setShowCompany] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [bulkActionsCount, setBulkActionsCount] = useState(0);
    const [companiesAtom, setCompaniesAtom] = useRecoilState(companies_atom);
    const [showChangeOwner, setShowChangeOwner] = useState(false);

    useEffect(() => {
        if (companiesAtom.selectedTableData > 0) {
            setBulkActionsCount(companiesAtom.selectedTableData);
            setShowBulkActions(true);
        } else {
            setBulkActionsCount(0);
            setShowBulkActions(false);
        }
    }, [companiesAtom.selectedTableData]);

    const handleFilterClick = () => {
        setShowFilter(!showFilter);
        props.isFilterOpen(!showFilter);
    }

    const handleChangeOwner = () => {
        setShowChangeOwner(true);
    }

    const resetBulkSelection = () => {
        setShowBulkActions(false);
        let a = Object.assign({}, companiesAtom);
        a.resetBulkSelection = true;
        setCompaniesAtom(a);
    };

    return (
        <>
            {showFilter &&
                <PipelineFilterWrapper module={"task"} open={showFilter} handleFilterClose={() => handleFilterClick()} />
            }
            {!showBulkActions ?
                <Box sx={{ width: '100%', margin: '10px 0 8px 0px' }}>
                    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                        <Stack direction="row" spacing={2} justifyContent="left" sx={{ width: '50%', color: "#000", mt: -0.5, pl: 1.75 }}>
                            {!showFilter &&
                                <IconButton color="primary" sx={{ background: `${variables.listBackgroundColor}` }}
                                    onClick={() => { handleFilterClick() }}>
                                    <FilterListIcon />
                                </IconButton>
                            }
                            <CrmViews module={"companies"} />
                        </Stack>
                        <Stack direction="row" justifyContent="right" spacing={2}
                            sx={{ right: 14, position: "absolute", width: '50%' }}
                        >
                            <FormControl sx={{
                                minWidth: 25,
                                maxWidth: 80,
                                ".MuiInputBase-root.MuiOutlinedInput-root": {
                                    maxHeight: 37
                                }
                            }} size="small">
                                <InputLabel>View Type</InputLabel>
                                <Select
                                    labelId="pipeline-view-type-label"
                                    id="pipeline-view-type"
                                    value={'list'}
                                    label="View Type"
                                    // onChange={handleViewTypeChange}
                                    renderValue={(value) => {
                                        return (
                                            <Box sx={{ display: "flex" }}>
                                                <ListItemIcon>
                                                    <ListAltOutlinedIcon />
                                                </ListItemIcon>
                                            </Box>
                                        );
                                    }}
                                >
                                    <MenuItem value="list">
                                        <ListItemIcon>
                                            <ListAltOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText>List View</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Button variant="contained" sx={{ height: 36, borderRadius: 20, width: 100 }} onClick={() => setShowCompany(true)}>
                                <AddIcon /> Company
                            </Button>
                            <IconButton color="primary" sx={{ background: `${variables.listBackgroundColor}` }}>
                                <MoreVertIcon />
                            </IconButton>
                        </Stack>
                        {showCompany && <UpsertCompany handleDrawerClose={() => setShowCompany(false)} />}
                    </Stack>
                </Box>
                : <Box sx={{ width: '100%', pl: 1.5, pt: 0.75, pb: 0.8 }}>
                    <Stack justifyContent="left" direction="row" spacing={2}>
                        <Button variant="contained" onClick={handleChangeOwner} sx={{ textTransform: 'none', height: 40, borderRadius: 20, width: 135 }}>
                            <Typography>Change Owner</Typography>
                        </Button>
                        <TableBulkActionsPopover module="companies" />
                        <Box sx={{
                            background: `${variables.tabsBackgroundColor}`, borderRadius: 5, width: 'auto', pl: 2.5, pt: 0.5
                        }}>
                            <Typography component="span" sx={{ color: `${variables.blackTextColor}` }}>
                                {bulkActionsCount} Selected
                            </Typography>
                            <IconButton sx={{ ml: 2, mt: '-1px' }} onClick={resetBulkSelection}>
                                <CloseIcon style={{ fontSize: "17px" }} />
                            </IconButton>
                        </Box>
                    </Stack>
                    {showChangeOwner &&
                        <ChangeOwner handleDialogClose={() => setShowChangeOwner(false)} />
                    }
                </Box>
            }
        </>
    );
}
export default CompanyActions;