import React, { useState, useRef, useEffect } from "react";
import "../../styles/myAccount.css";
import { Divider, Paper } from "@mui/material";
import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useRecoilState } from "recoil";
import "../dashboard/Style/UserDrawer.css";
import { loadingAll, login_atoms } from "../../Atoms/LoginAtoms";
import { api_url1 } from "../../globalSettings";

import BillingDetails from "./BillingDetails";
import { useLocation } from "react-router-dom";
import MyAccountSideBar from "./MyAccountSideBar";
import styled from "styled-components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import tick from "../../images/tick.png";
import wrong from "../../images/wrong.png";
import { IconButton } from "@mui/material";
import { Country } from "country-state-city";
import CustomTextField from "./Components/CustomTextField";
import CustomTextSelect from "./Components/CustomTextSelect";
import { isSideBarActive } from "../../Atoms/SideBarAtom";
import { apiresponse } from "../../Atoms/LoginAtoms";
import CustomSnackbar from "../webservices/Component/CustomSnackbar";
import ProfileUpload from "../dashboard/Component/ProfileUpload";
const SidebarTab = styled.div`
  width: 60%;
  padding: 5px 0px;
  cursor: pointer;
  color: black;
  border-right: 2px solid white;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  ${(props) => props.active && "background-color: #f0f0f0;"}
`;

const SettingsIcon = styled(EditOutlinedIcon)`
  visibility: hidden;
  color: #00000066;
  font-size: 18px !important;
`;

const HoverableSidebarTab = styled(SidebarTab)`
  &:hover {
    ${SettingsIcon} {
      visibility: visible;
    }
  },

`;

const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "95%",
  },
});

const divStyle = {
  display: "flex",
};

export default function Profile() {
  const [isLoading, setIsLoading] = useRecoilState(loadingAll);
  const [isOpen] = useRecoilState(isSideBarActive);
  const uploadref = useRef(null);
  const [companyform, setCompanyForm] = useState({ logo: "" });
  const [apiresponse1, setApiresponse1] = useRecoilState(apiresponse);
  const onlogochange = (e) => {
    setCompanyForm((state) => ({ ...state, logo: e.target.files[0] }));
  };

  const { pathname } = useLocation();

  const classes = useStyles();
  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);

  const [getClientdetails, setGetClientdetails] = useState([]);
  const [changedValues, setChangedValues] = useState({});
  const [value, setValue] = useState("");
  const [edit, setEdit] = useState(false);
  const [edit1, setEdit1] = useState(false);
  const [edit2, setEdit2] = useState(false);
  const [edit3, setEdit3] = useState(false);
  const [edit4, setEdit4] = useState(false);
  const [edit5, setEdit5] = useState(false);
  const [edit6, setEdit6] = useState(false);
  const [edit7, setEdit7] = useState(false);
  const [edit8, setEdit8] = useState(false);
  const [edit9, setEdit9] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleSnackbarClose = (event, reason) => {
    setSnackbarInfo((prevInfo) => ({
      ...prevInfo,
      open: false,
    }));
  };

  function handlechange(e) {
    const { value, name } = e.target;

    if (getClientdetails[name] !== value) {
      setChangedValues({
        ...changedValues,
        [name]: value,
      });
    }

    setGetClientdetails({ ...getClientdetails, [name]: value });
  }
  function handlechangePhone(e) {
    alert("nimer");
    let name = "phone_number";

    if (getClientdetails[name] !== value) {
      setChangedValues({
        ...changedValues,
        [name]: value,
      });
    }

    setGetClientdetails({ ...getClientdetails, [name]: e });
  }

  const getClientDetails = async () => {
    setIsLoading(true);


    await axios
      .get(`${api_url1}/client/${loginAtom1}`)
      .then((responce) => {
    
        setGetClientdetails(responce.data.data);
        setApiresponse1(responce.data.data);
        setIsLoading(false);
      })
      .catch((errorr) => {
        setIsLoading(false);
        if (errorr.response) {
        } else if (errorr.request) {
        } else {
        }
      });
  };

  // ================================================================================================================================== //

  const updateClient = () => {
    setIsLoading(true);

    axios
      .put(`${api_url1}/updateClient/${loginAtom1}`, changedValues)
      .then(function (response) {
        setSnackbarInfo({
          open: true,
          severity: "success",
          message: response.data.message,
        });
        setIsLoading(false);
        setEdit(false);
        setEdit1(false);
        setEdit2(false);
        setEdit3(false);
        setEdit4(false);
        setEdit5(false);
        setEdit6(false);
        setEdit7(false);
        setEdit8(false);
        setEdit9(false);
        getClientDetails();
      })

      .catch(function (errorr) {
        setIsLoading(false);
        if (errorr.response) {
          setSnackbarInfo({
            open: true,
            severity: "error",

            message: errorr.response.data.error.message,
          });
        } else if (errorr.request) {
        } else {
        }
      });
  };

  useEffect(() => {
    getClientDetails();
  }, []);

  function updateProfile() {
    setIsLoading(true);
    let formdata = new FormData();

    formdata.append("user_id", loginAtom1);
    formdata.append("profile_picture", companyform.logo);

    axios
      .patch(
        `https://server.nowdigitaleasy.com:3505/update_user_profile`,
        formdata,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        setIsLoading(false);
        setSnackbarInfo({
          open: true,
          severity: "success",
          message: result.data.message,
        });
        getClientDetails();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  const updateProfileFun = () => {
    uploadref.current.click();
  };

  React.useEffect(() => {
    if (companyform.logo) {
      updateProfile();
    }
  }, [companyform.logo]);


  return (
    <div className="contacts">
      {isOpen && <MyAccountSideBar />}

      {pathname === "/myaccount/profile" ||
      pathname === "/myaccount/profile" ? (
        <Paper
          elevation="2"
          style={{
            paddingBottom: "25px",
            width: "100%",
            backgroundColor: "#ffff",
            height: "calc(100vh - 48px)",
            overflow: "auto",
          }}
          className={classes.root}
        >
          <div className="my-account-left-photo-wrapper">
            <div className="my-account-user-photo">
              <div>
                <div className="userdraweravatar">
                  <ProfileUpload
                    onlogochange={onlogochange}
                    uploadref={uploadref}
                    updateProfileFun={updateProfileFun}
                    path={apiresponse1.profile_pic_path}
                    srcUrl={`https://server.nowdigitaleasy.com${apiresponse1.profile_pic_path}/${apiresponse1.profile_pic_name}`}
                  />
                </div>
              </div>

              <div className="my-account-user-info">
                <div className="my-account-user-name">
                  <h6 style={{ marginBottom: 0 }}>
                    {getClientdetails.first_name}&nbsp;
                    {getClientdetails.last_name}&nbsp;
                    {getClientdetails.user_id}
                  </h6>
                </div>

                <div className="my-account-user-email">
                  <span>{getClientdetails.email}</span>
                </div>

                <div className="my-account-user-role">
                  <span>{getClientdetails?.profile?.name}</span>
                </div>
              </div>
            </div>
          </div>
          <Divider style={{ background: "#5450537a" }} />
          <div
            className="my-account-left-info-wrapper"
            style={{ backgroundColor: "#fff", margin: 17, borderRadius: 15 }}
          >
            <h6>User Information</h6>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">First Name :</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab style={{ padding: "5px 0px" }}>
                      {edit === false ? (
                        <>
                          {" "}
                          {getClientdetails.first_name}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.first_name}
                            name={"first_name"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">Last Name :</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit1 === false ? (
                        <>
                          {" "}
                          {getClientdetails.last_name}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit1(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.last_name}
                            name={"last_name"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit1(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">Phone Number :</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit2 === false ? (
                        <>
                          {" "}
                          {getClientdetails.phone_number}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit2(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.phone_number}
                            name={"phone_number"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit2(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">Email :</div>
                  <div className="my-account-left-info-value">
                    {/* <StyledEdiText
                      value={getClientdetails.email}
                      //onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      onSave={handleSave4}
                      editing={editing}
                      validationMessage="Please type at least 5 characters."
                      validation={(val) => val.length >= 3}
                      showButtonsOnHover
                    /> */}
                    <HoverableSidebarTab>
                      {edit3 === false ? (
                        <>
                          {" "}
                          {getClientdetails.email}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit3(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.email}
                            name={"email"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit3(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">GST Number</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit4 === false ? (
                        <>
                          {" "}
                          {getClientdetails.gstin}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit4(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.gstin}
                            name={"gstin"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit4(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">User Role</div>
                  <div className="my-account-left-info-value">
                    {getClientdetails?.profile?.name}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div
            className="my-account-left-info-wrapper"
            style={{ backgroundColor: "#fff", margin: 20, borderRadius: 15 }}
          >
            <h6>Address</h6>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">Address :</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit5 === false ? (
                        <>
                          {" "}
                          {getClientdetails.address}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit5(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.address}
                            name={"address"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit5(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">City :</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit6 === false ? (
                        <>
                          {" "}
                          {getClientdetails.city}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit6(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.city}
                            name={"city"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit6(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">State :</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit7 === false ? (
                        <>
                          {" "}
                          {getClientdetails.state}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit7(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.state}
                            name={"state"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit7(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">Country :</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit8 === false ? (
                        <>
                          {" "}
                          {getClientdetails.country}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit8(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextSelect
                            value={getClientdetails.country}
                            name={"country"}
                            array={Country.getAllCountries()}
                            valueProp={"name"}
                            keyProp={"phonecode"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit8(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">Postal Code</div>
                  <div className="my-account-left-info-value">
                    <HoverableSidebarTab>
                      {edit9 === false ? (
                        <>
                          {" "}
                          {getClientdetails.pincode}{" "}
                          <SettingsIcon
                            onClick={() => {
                              setEdit9(true);
                            }}
                          />{" "}
                        </>
                      ) : (
                        <>
                          <CustomTextField
                            value={getClientdetails.pincode}
                            name={"pincode"}
                            handlechange={handlechange}
                          />

                          <div style={divStyle}>
                            {" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={() => {
                                setEdit9(false);
                              }}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={wrong}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                            <IconButton
                              style={{ padding: 0 }}
                              color="success"
                              onClick={updateClient}
                              aria-label="add to shopping cart"
                            >
                              <img
                                src={tick}
                                alt="Yes"
                                width={18}
                                height={18}
                              />
                            </IconButton>{" "}
                          </div>
                        </>
                      )}
                    </HoverableSidebarTab>
                  </div>
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="my-account-left-info">
                  <div className="my-account-left-info-key">Status</div>
                  <div className="my-account-left-info-value">--</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      ) : pathname === "/myaccount/billing" ? (
        <BillingDetails />
      ) : (
        // : pathname === "/contacts/report" ? (
        //   <Report />
        // )
        ""
      )}
      <CustomSnackbar
        snackbarInfo={snackbarInfo}
        handleSnackbarClose={handleSnackbarClose}
      />
    </div>
  );
}
