import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRecoilState } from "recoil";
import TrackeyBox from "../common/TrackeyBox";

export default function TrackeyBoxList(props) {
  const [aboutemp, setaboutemp] = useState({});
  const { userInfo } = useSelector((state) => state.login);
  const { companyInfo } = useSelector((state) => state.manageCompany);

  
  // API call
  function aboutemployeedetails() {
    const formdata2 = new FormData();

    formdata2.append("company_id", companyInfo.company_id);
    formdata2.append("user_id", userInfo.user_id);
    formdata2.append(
      "token",
      JSON.parse(localStorage.getItem("auth")).data.token
    );
    formdata2.append("action", "tracking_top_count");
    formdata2.append("cmd", "1");

    axios
      .post(
        "https://server.nowdigitaleasy.com/web_tracking_employee.php",
        formdata2
      )
      .then((data) => {
        setaboutemp(data.data.data[0]);
      })
      .catch((err) => {

      });
  }

  useEffect(() => {
    if (companyInfo?.company_id) {
      aboutemployeedetails();
    }
  }, []);

  function total() {
    return (
      Number(props.empList.on_duty_employee_count) +
      Number(props.empList.off_duty_employee_count)
    );
    
  }


  const list = [
    {
      id: "onDuty",
      displayCount: props.empListonduty,
      displayName: "On Duty",
      backgroundColor: "#008000",
      onclick: "ON_DUTY",
    },
    {
      id: "offDuty",
      displayCount: props.empListoffduty,
      displayName: "Off Duty",
      backgroundColor: "#F34545", //#F34545
      onclick: "OFF_DUTY",
    },
    {
      id: "onLeave",
      displayCount: props.emponlvelist,
      displayName: "On Leave",
      backgroundColor: "#2D8BF6",//#2D8BF6
      onclick: "ON_LEAVE",
    },
    // {
    //   id: "incentive",
    //   displayCount: aboutemp.not_reachable,
    //   displayName: "Not Reachable",
    //   backgroundColor: "#F6B301",
    // },
    {
      id: "noData",
      displayCount: props.empnodatalist,
      displayName: "No Data",
      backgroundColor: "#E120D4",
      onclick: "NO_DATA",
    },
    {
      id: "total",
      displayCount: props.totemplist,
      displayName: "total",
      backgroundColor: "#808000",
      onclick: "ALL",
    },
  ];

  return (
    <div className="trackey-list-box-wrapper">
      {list.map((item, index) => {
        return <TrackeyBox key={`trackeyBox${index}`} {...item} />;
      })}
    </div>
  );
}
