import React, { useState } from "react";
import {
  GoogleMap,
 
  Marker,
 
} from "@react-google-maps/api";

import {

  isLoading
} from "../../Atoms/LoginAtoms";
import axios from "axios";
import { useRecoilState } from "recoil";
import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import { useLoadScript } from "@react-google-maps/api";
import Skeleton from '@mui/material/Skeleton';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { socket } from "../../config";
import MyLocationIcon from '@mui/icons-material/MyLocation';

const markers = [
  {
    
    position: { lat: 10.9673389, lng: 78.0615109 }, //10.9673389 //78.0615109
  },
  {
    
    position: { lat: 12.9673389, lng: 78.0615109 },
  },
  {
   
    position: { lat: 13.9673389, lng: 78.0615109 },
  },
];

export default function Follow(props) {

 
  
  const [lastData,setlastdata] = useState([])
  const [isLoading1, setisLoading1] = useRecoilState(isLoading);


  React.useEffect(()=>{

       if(props.livedata.employee_id === props.employeeid){
      
        setlastdata(props.livedata)
     
       }
       
    
  },[props])



 




 
 
  const [activeMarker, setActiveMarker] = useState(null);


 

  let iconMarker = new window.google.maps.MarkerImage(
    "https://img.icons8.com/fluency/512/maps.png",
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(40, 40)
  );

 


 
  const iconsetngs = {
    path: window.google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
  };











  return (
    <div>
      
      {isLoading1 ? <Skeleton variant="rectangular" width={600} height={500} /> : props.error ? "no data" :
      
      <GoogleMap
        //onLoad={path.length > 5 ? handleOnLoad : ''}
        center={{
          lat: parseFloat(lastData.latitude),
          lng: parseFloat(lastData.longitude),
        }}
        zoom={8}
        onClick={() => setActiveMarker(null)}
        //center={path[0]}
        mapContainerStyle={{ width: "37vw", height: "60vh" }}
      >

        <Marker
          animation={window.google.maps.Animation.DROP}
          icon={iconMarker}
          position={{
            lat: parseFloat(lastData.latitude),
            lng: parseFloat(lastData.longitude),
          }}
        ></Marker>
      
      </GoogleMap> }
    </div>
  );
}

