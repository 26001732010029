import React, { useState } from "react";
import { Modal, Box, IconButton, Grid, Stack, Typography } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import "../../Style/AddAccount.css";
import { useQuery } from "@tanstack/react-query";
import { api_url1 } from "../../../../../globalSettings.js";
import axios from "axios";
import { PriceFormat } from "../../../CustomHook/DateFormat.js";
import RenewalStyle from "../../Style/Renewal.module.css";

import {
  gstCalculation,
  formatDateWithOrdinal,
  getOfferPrices,
  calculateAmountNew,
} from "../../HelperFunctions/helperFunction.js";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Style from "../../Style/AddAcc.module.css";
import PriceTable from "../../../Component/Renewal/Componets/PriceTable.js";
import RenewalButton from "../../../Component/Renewal/Componets/RenewalButton.js";
import OfferMarkUp from "../../../Component/Renewal/Componets/OfferMarkUp.js";
import RenewalPayment from "../../../Component/Renewal/Componets/ProcessRenewal.js";
import IsWallet from "../../../CustomHook/IsWallet.js";
import { displayRazorpay } from "../../../CustomHook/RazorPay/RazorPay.js";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 0,
    background: "#404040",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};

const AddAccount = ({
  open,
  handleClose,
  setSnackbarInfo,
  filterGsuite,
  isToolTip,
  isToolTipOpen,
}) => {
  const [data, setData] = useState([]);
  const fetchData = async (api_url1) => {
    if (filterGsuite?.productId) {
      let obj = {
        id: filterGsuite._id,
      };
      const queryParams = new URLSearchParams(obj).toString();

      return axios
        .get(`${api_url1}/product/gsuite/add_liscense?${queryParams}`)
        .then((result) => {

          setData(result.data);
          setAccountValue(1);
          findPrice(result.data.gsuites, result.data, 1, result.data.planName);
          isToolTip(result.data.planName, result.data);
          return result.data;
        })
        .catch((err) => {
          return err;
        });
    }
  };
  useQuery({
    queryKey: ["gsuitedata", filterGsuite],
    queryFn: () => fetchData(api_url1),
    enabled: filterGsuite?.productId ? true : false,
  });
  const [scroll, setScroll] = React.useState("paper");
  const [accountValue, setAccountValue] = useState(1);
  const [priceData, setPriceData] = useState(0);
  const [taxData, settaxData] = useState(0);
  const [toolTipPrice, setToolPrice] = useState(0);
  const handleInputChange = (event) => {
    const inputValue = event.target.value ? parseInt(event.target.value) : " ";
    isToolTip(data?.planName, data);
    findPrice(data?.gsuites, data, inputValue || 0, data.planName);
    setAccountValue(inputValue);
  };
  const isInvalidInput =
    accountValue > data?.maxuser || isNaN(accountValue) || accountValue === 0
      ? true
      : false;

  function findPrice(gsuites, data, quantity, period) {
    setToolPrice(
      getOfferPrices(gsuites, quantity, data?.gsuite_userData, "new-liscence")
    );
    const caluclatedPrice = calculateAmountNew(
      gsuites,
      period,
      "new-liscence",
      quantity,
      data?.gsuite_userData
    );
    setPriceData(caluclatedPrice);
    settaxData(gstCalculation(caluclatedPrice, data?.userData));
  }
  const [processModal, setProcessModal] = useState(false);
  function handleCloseModal() {
    setProcessModal(false);
  }
  const offerUser = Math.abs(
    data?.gsuites?.offerForUsers - data?.maximumNumberOfSeats
  );
  const normalUser = Math.abs(accountValue - offerUser);
  const isExceed = accountValue <= offerUser;

  const [loading, setLoading] = useState(false);
  function AddAcc() {
    setLoading(true);
    let obj = {
      id: filterGsuite._id,

      quantity: accountValue,
      isWallet: IsWallet(data?.walletBalance),
    };
    axios
      .post(`${api_url1}/payment/gsuite/add-user`, obj)
      .then((result) => {
        if (!result.data.isRazorpay) {
          setSnackbarInfo({
            open: true,
            severity: "success",
            message:
              "Payment Successfully Made Via Wallet,It Will Take Few Minutes To Update",
          });
        } else {
          const handlerFunction = function (response) {
            setSnackbarInfo({
              open: true,
              severity: "success",
              message: "Payment Successful,It Will Take Few Minutes To Update!",
            });
          };
          let options = {
            ...result.data.options,
            handler: handlerFunction,
          };
          displayRazorpay(options, setSnackbarInfo);
        }
        handleClose();
        handleCloseModal();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="_addcompanytitle_">
            Add Accounts
            <IconButton
              component="span"
              onClick={handleClose}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <Stack
            spacing={2}
            width="100%"
            alignItems="center"
            height="100%"
            sx={{ padding: "20px 16px 8px 16px" }}
          >
            <div
              className={Style.buyTitle}
            >{`Buy Google Workspace Accounts for ${filterGsuite.domainName} `}</div>
            <div className={RenewalStyle.middleWrapper}>
              <div style={{ width: "30%" }}>
                <div className={Style.boxTitle}>No Of Accounts</div>
                <div class="be-input-acc">
                  <input
                    type="number"
                    min="1"
                    max="299"
                    class="input-be-acc"
                    value={accountValue}
                    onChange={handleInputChange}
                  />
                </div>
                {isToolTipOpen && !isInvalidInput && (
                  <>
                    {isExceed ? (
                      <BootstrapTooltip
                        title={
                          <div style={{ borderRadius: 0 }}>
                            <p
                              style={{ marginBottom: "0.3rem" }}
                            >{`${data?.gsuites?.name} ${data?.gsuites?.offerPrice} acc/${data?.priceparam}`}</p>
                            <p style={{ marginBottom: "0.3rem" }}>
                              <s>{`${data?.gsuites?.name} ${data?.gsuites?.defaultPrice} acc/${data?.priceparam}`}</s>{" "}
                            </p>
                          </div>
                        }
                        arrow
                      >
                        <span className={RenewalStyle.link}>
                          View cost per account
                        </span>
                      </BootstrapTooltip>
                    ) : (
                      <BootstrapTooltip
                        title={
                          <div>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "10px",
                                }}
                              >
                                <div>{`${offerUser}`} Accounts</div>
                                <div>40% </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "10px",
                                }}
                              >
                                <div>{`${data?.gsuites?.name} ${toolTipPrice?.singleOfferprice} acc/${data?.priceparam}`}</div>
                                <div>
                                  <s>{`${data?.gsuites?.name} ${data?.gsuites?.defaultPrice} acc/${data?.priceparam}`}</s>
                                </div>
                              </div>
                            </div>
                            <div style={{ display: "flex" }}>
                              <div style={{ padding: "10px" }}>
                                {normalUser} accounts
                              </div>
                              <div style={{ padding: "10px" }}>
                                {`${data?.gsuites?.name} ${data?.gsuites?.defaultPrice} acc/${data?.priceparam}`}
                              </div>
                            </div>
                          </div>
                        }
                        arrow
                      >
                        <Typography
                          style={{
                            color: "rgb(109, 118, 135)",
                            cursor: "pointer",
                            font: "400 12px OpenSans, sans-serif",
                            textDecoration: "underline",
                            display: "inline",
                          }}
                        >
                          View cost per account
                        </Typography>
                      </BootstrapTooltip>
                    )}
                  </>
                )}
                {isNaN(accountValue) || accountValue === 0 ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    Buy at least 1 account
                  </div>
                ) : (
                  isInvalidInput && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      Max accounts allowed: {data?.maxuser}
                    </div>
                  )
                )}
              </div>
              <div class="be-divider lfloat"></div>
              <div className={Style.middleWrap}>
                <div>
                  <div className={Style.boxTitle}>Your Cost Per Acc</div>
                  <div class="notranslate c59v8ul">
                    <span class="be-cost-dollar">
                      {data?.userData?.currencyCode}
                    </span>{" "}
                    {` ${PriceFormat(data?.singlePrice)}`}
                    <span class="be-permonth">/{data?.priceparam}</span>
                  </div>
                  <div class="notranslate dvqcc32">
                    {data?.userData?.currencyCode}&nbsp;
                    {`${data?.singlePrice + 120}/${data?.priceparam}`}
                  </div>
                </div>
              </div>
              <div class="be-divider lfloat"></div>
              <div style={{ width: "30%" }}>
                <div style={{ marginLeft: "15px" }}>
                  <div className={Style.boxTitle}>Duration</div>
                  <div className={Style.boxTitlePrice}>{data?.planName}</div>
                  <div class="be-valid">
                    {`(Upto ${formatDateWithOrdinal(
                      data?.expiryDate
                    )} when your Hosting expires)`}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={RenewalStyle.price_wrapper}
              style={{ justifyContent: "space-between", alignItems: "end" }}
            >
              <OfferMarkUp />
              <PriceTable
                currencyCode={data?.userData?.currencyCode}
                priceData={priceData}
                gst={taxData?.gst?.Amt || taxData?.gst}
                tax={taxData?.fullAmount}
              />
            </div>
          </Stack>
          <RenewalButton
            loading={false}
            title={"Proceed"}
            disabled={isInvalidInput}
            clickFunc={() => {
              handleClose();
              setProcessModal(true);
            }}
          />
        </Box>
      </Modal>
      {processModal && (
        <RenewalPayment
          handleCloseModal={handleCloseModal}
          open={processModal}
          fullAmount={taxData?.fullAmount}
          currencyCode={data.userData.currencyCode}
          walletBalance={data?.walletBalance}
          loading={loading}
          proceedFunc={AddAcc}
        />
      )}
    </>
  );
};

export default AddAccount;
