import { Box, Button, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from "react";
import CreateProfile from "./CreateProfile";
import { getAllProfiles } from "../../../../webservices/UserControlService";
import TableHeader from "../../../../components/nativetable/TableHeader";
import TableRow from "../../../../components/nativetable/TableRow";
import { useQuery } from "@tanstack/react-query";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import { useEffect } from "react";

const columns = [
    {
        "id": "1",
        "accessor": "profileName",
        "label": "Profile Name",
        "width": "20%",
        sortable: false
    },
    {
        "id": "2",
        "accessor": "profileDescription",
        "label": "Profile Description",
        "width": "65%",
        sortable: false
    },
    {
        "id": "3",
        "accessor": "modifiedBy",
        "label": "Modified By",
        "width": "15%",
        sortable: false
    }
];

export default function Profiles(props) {

    const [createProfile, showCreateProfile] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data,
        error,
        isError,
        isLoading,
        isFetching,
    } = useQuery({
        queryKey: ['getAllProfiles'],
        queryFn: () => getAllProfiles(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        isError && setSnackbarInfo({
            open: true,
            severity: 'error',
            message: error?.response?.data?.error?.message
        });
    }, [isError]);

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    const handleCreateProfileClose = (data) => {
        showCreateProfile(false);
        showSnackbar(data);
    }

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        })
    }

    return (
        <>
            <Stack direction="row" width="100%" sx={{ p: 2 }}>
                <Stack direction="column" width="80%">
                    <Typography sx={{ fontWeight: 'bold', fontSize: 16, pb: 1.5 }}>
                        Profiles
                    </Typography>
                    <Typography>
                        Profiles help you define a set of permissions for each user as well as the actions they can perform in this app. When you invite users, you assign a profile to each of them.
                    </Typography>
                </Stack>
                <Stack direction="row" width="20%" height="36px" justifyContent="right">
                    <Button variant="contained" sx={{ borderRadius: 10 }} onClick={() => showCreateProfile(true)}>
                        <AddIcon /> New Profile
                    </Button>
                </Stack>
            </Stack>

            <Box sx={{ height: '70vh', width: '100%', overflow: 'auto', pl: 2, pb: 1 }}>
                <>
                    <table id="profilesTable" width="100%" style={{ tableLayout: "auto !important" }}>
                        <TableHeader {...{ columns }} />
                        {
                            !isFetching && data?.rows?.length > 0 &&
                            <tbody>
                                {
                                    data.rows.map((row) => {
                                        return (
                                            <TableRow data={row} columns={columns} profiles={data.rows} showSnackbar={(data) => showSnackbar(data)} />
                                        );
                                    })
                                }
                            </tbody>
                        }
                    </table>
                    {
                        isFetching &&
                        <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="50px">
                            <Typography>Loading ...</Typography>
                        </Stack>
                    }
                    {
                        !isFetching && data?.rows?.length === 0 &&
                        <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="50px">
                            <Typography>No data found</Typography>
                        </Stack>
                    }
                </>
            </Box>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

            {createProfile && <CreateProfile profiles={data.rows} handleDrawerClose={(data) => handleCreateProfileClose(data)} />}

        </>
    )
}
