import { Autocomplete, Box, Button, Chip, IconButton, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import variables from "../../../../assets/styles/_colors.scss";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveClosureRestriction from "./RemoveClosureRestriction";
import { updateClosureRestriction } from "../../../../webservices/StageService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function RestrictClosureTableRow(props) {

    const queryClient = useQueryClient();

    const [isHovering, setIsHovering] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editStages, setEditStages] = useState([]);
    const [removeClosureRestriction, setRemoveClosureRestriction] = useState(false);

    console.log(props.row);

    useEffect(() => {

    }, []);

    useEffect(() => {
        props.row && setEditStages(props.row?.restrictClosure);
    }, [props.row]);

    const handleRemoveRestrictClosure = (data) => {
        setRemoveClosureRestriction(false);
        resetHover(data);
    }

    const resetHover = (data) => {
        setIsHovering(false)
        data && props.showSnackbar(data);
    }

    const { mutateAsync } = useMutation({
        mutationKey: ["updateClosureRestriction"],
        mutationFn: () => updateClosureRestriction(props.pipelineId, props.row?._id, { stages: editStages }),
        onSuccess: (response) => {
            resetHover(response);
            queryClient.invalidateQueries({ queryKey: ['getClosureRestrictions'] });
        },
        onError: (errorResponse) => {
            resetHover(errorResponse.response.data.error);
        }
    });

    const handleEdit = () => {
        setEditMode(false);
        mutateAsync();
    }

    return (
        <>
            <Stack direction="row" width="100%" alignItems="center" height={48}
                sx={{ borderBottom: `1px solid ${variables.borderColor}`, backgroundColor: editMode ? `${variables.listBackgroundColor}` : isHovering ? `whitesmoke` : "#FFF" }}
                onMouseEnter={() => setIsHovering(editMode ? false : true)} onMouseLeave={() => setIsHovering(false)}>

                <Stack direction="column" sx={{ fontWeight: 'bold', pl: 2 }} width="25%">
                    <Typography sx={{ fontWeight: 'bold' }}>{props.row.subPipelineName}</Typography>
                </Stack>

                <Stack direction="column" width="65%">
                    {editMode ?
                        <>
                            <Autocomplete
                                multiple
                                size="small"
                                options={props.row?.openStages}
                                value={editStages}
                                onChange={(e, val) => setEditStages(val)}
                                getOptionLabel={(option) => option.stageName}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                sx={{
                                    bgcolor: "#FFF"
                                }}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" sx={{ height: "26px !important", bgcolor: `${variables.listBackgroundColor}`, fontWeight: 'bold' }} label={option.stageName} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            "&.MuiOutlinedInput-root": {
                                                padding: "4px !important"
                                            }
                                        }}
                                        placeholder="Choose a stage"
                                    />
                                )}
                            />
                        </> :
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            m: 0,
                            p: 0,
                            pl: 2
                        }}>
                            {
                                props.row?.restrictClosure.map((data, index) => {
                                    return (
                                        <Chip sx={{ m: 0.5, ml: 0 }} label={data.stageName} key={index} />
                                    );
                                })
                            }
                            {
                                props.row?.restrictClosure?.length === 0 &&
                                <Stack direction="row" alignItems="center" width="77%">
                                    <Button sx={{ textTransform: 'none' }} onClick={() => { setEditMode(true) }}>
                                        <AddIcon fontSize="small" />
                                        <Typography component="div">
                                            Stage
                                        </Typography>
                                    </Button>
                                </Stack>
                            }
                        </Box>
                    }
                </Stack>
                {
                    editMode ?
                        <Stack direction="row" spacing={1} alignItems="center"
                            sx={{
                                height: "47px",
                                zIndex: 5,
                                width: "85px",
                                bgcolor: `${variables.listBackgroundColor}`,
                                position: 'sticky',
                                pl: 1.25
                            }}>
                            <IconButton sx={{ height: 28, width: 28, bgcolor: `${variables.lightGreyBackgroundColor} !important`, color: `${variables.pipelineBoardText}`, border: `1px solid ${variables.borderColor}` }}
                                size="small" onClick={() => setEditMode(false)}>
                                <CloseOutlinedIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton sx={{ height: 28, width: 28, bgcolor: `${variables.linkColor} !important`, color: "#fff", "&.Mui-disabled": { color: '#FFF', opacity: 0.3 } }} size="small"
                                onClick={() => handleEdit()} disabled={!editStages || editStages?.length === 0}>
                                <CheckOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Stack>
                        :
                        props.row?.restrictClosure?.length !== 0 &&
                        <Stack direction="row" spacing={1} alignItems="center"
                            sx={{
                                visibility: isHovering ? 'inherit' : 'hidden',
                                height: "47px",
                                zIndex: 5,
                                width: "85px",
                                bgcolor: `${variables.lightGreyBackgroundColor}`,
                                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                                position: 'sticky', right: 0.5,
                                pl: 0.5
                            }}>
                            <IconButton sx={{ height: "35px", width: "35px" }} onClick={() => { setEditMode(true) }} >
                                <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                            </IconButton>
                            <IconButton sx={{ height: "35px", width: "35px" }} onClick={() => { setRemoveClosureRestriction(true) }}>
                                <DeleteOutlineIcon fontSize='medium' color={variables.greyTextColor} />
                            </IconButton>
                        </Stack>
                }
            </Stack>

            {removeClosureRestriction &&
                <RemoveClosureRestriction item={props.row} pipelineId={props.pipelineId} handleClose={(data) => handleRemoveRestrictClosure(data)} />}
        </>
    )
}
