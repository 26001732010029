import React, { useRef, useState } from "react";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import {
  Button,
  Typography,
  styled,
  Divider,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Tooltip,
  Snackbar,
  Alert,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CancelIcon from "@mui/icons-material/Cancel";

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    width: "300px",
    display: "flex",
    justifyContent: "flex-start",
  },
});

const suggestions = ["Ip", "Name", "Region", "Location",];

const VisitorData = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([{ key: "", value: "" }]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const valueRef = useRef(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleInputChange = (index, key, value) => {
    const newData = [...data];
    newData[index] = { key, value };
    setData(newData);
  };

  const handleEnterPress = (event, index) => {
    if (event.key === "Enter") {
      const newData = [...data];
      newData.push({ key: "", value: "" });
      setData(newData);
    }
  };

  const handleRemoveRow = (index) => {
    const removedKey = data[index].key;
    const newData = data.filter((_, i) => i !== index);
    setData(newData);
    if (removedKey && !suggestions.includes(removedKey)) {
      suggestions.push(removedKey);
      setShowSuggestions(true); 
    }
  };

  const handleFocusInput = () => {
    setShowSuggestions(true);
  };

  const handleSelectSuggestion = (suggestion) => {
    const newData = [...data];
    newData[newData.length - 1].key = suggestion;
    setData(newData);
    setShowSuggestions(false);
    const suggestionIndex = suggestions.indexOf(suggestion);
    if (suggestionIndex !== -1) {
      suggestions.splice(suggestionIndex, 1);
    }
  };

  const handleCopyValue = (value) => {
    navigator.clipboard.writeText(value);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };
  
  return (
    <List sx={{ p: 0 }}>
      <StyledButton
        onClick={handleClick}
        sx={{
          padding: "11px 40px 10px 12px ",
          backgroundColor: "#fafbfc",
          borderBottom: "1px solid #e3e8ef",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "Inter",
            color: "#75869c",
            textAlign: "left",
          }}
        >
          VISITOR DATA
        </Typography>
        {open ? (
          <ArrowDropDownIcon sx={{ color: "#75869c", marginLeft: "auto" }} />
        ) : (
          <ArrowLeftIcon sx={{ color: "#75869c", marginLeft: "auto" }} />
        )}
      </StyledButton>

      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ padding: "10px " }}>
            <TableContainer sx={{ border: "1px solid #e3e8ef" }}>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{ borderRight: "1px solid #e3e8ef", padding: "10px" }}
                    >
                      {index === data.length - 1 ? (
                        <Box sx={{ alignItems: "center", display: "flex" }}>
                          <AddCircleIcon
                            sx={{
                              color: "#75869c",
                              fontSize: "14px",
                              marginRight: "3px",
                              cursor: "pointer",
                            }}
                          />
                          <input
                            placeholder="Data Key"
                            style={{
                              outline: "none",
                              border: "none",
                              width: "70px",
                              background: "#FAFAFC",
                              position: "relative",
                            }}
                            value={row.key}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                e.target.value,
                                row.value
                              )
                            }
                            onKeyPress={(e) => handleEnterPress(e, index)}
                            onFocus={handleFocusInput}
                          />
                        </Box>
                      ) : (
                        row.key
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "140px",
                        padding: "10px 0px 10px 10px",
                        backgroundColor: "#fff",
                        position: "relative",
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                      onClick={() => setEditIndex(index)}
                      onBlur={() => setEditIndex(null)}
                    >
                      {editIndex === index || index === data.length - 1 ? (
                        <input
                          placeholder="Data value..."
                          style={{
                            outline: "none",
                            border: "none",
                            width: "87px",
                          }}
                          value={row.value}
                          onChange={(e) =>
                            handleInputChange(index, row.key, e.target.value)
                          }
                          onKeyPress={(e) => handleEnterPress(e, index)}
                          autoFocus
                        />
                      ) : (
                        <>
                          {row.value.length > 13 ? (
                            <span title={row.value}>{row.value.slice(0, 13) + "..."}</span>
                          ) : (
                            row.value
                          )}
                          {hoveredIndex === index && (
                            <Box
                              sx={{
                                position: "absolute",
                                right: "2px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <Tooltip title="Copy" arrow>
                              <ContentCopyIcon
                              onClick={() => handleCopyValue(row.value)}
                                sx={{
                                  cursor: "pointer",
                                  marginRight: "3px",
                                  fontSize: "16px",
                                  color: "#75869c",
                                }}
                              /></Tooltip>
                               <Tooltip title="Remove" arrow>
                              <CancelIcon
                                sx={{
                                  cursor: "pointer",
                                  fontSize: "16px",
                                  color: "#75869c",
                                }}
                                onClick={() => handleRemoveRow(index)}
                                />
                                </Tooltip>
                            </Box>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </Box>
          {showSuggestions && (
            <ul
              style={{
                position: "relative",
                width: "100%",
                background: "#fff",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                listStyleType: "none",
                padding: "0",
                margin: "0",
              }}
            >
              {suggestions.map((item, i) => (
                <li
                  key={i}
                  style={{
                    padding: "8px 16px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelectSuggestion(item)}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </Collapse>
      )}
      <Divider sx={{ borderBottom: "1px solid grey" }} />
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {" "}
        Name Copied
        </Alert>
      </Snackbar>
    </List>
  );
};

export default VisitorData;
