import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    color: 'rgba(0,0,0,0.1)',
    '&$checked': {
      color: 'grey', // Change the background color when checked
      
    },
  },
  checked: {},
});

function MyCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
      style={{ /* Add additional styles here */ }}
      {...props} // Spread other props
    />
  );
}

export default MyCheckbox;
