import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ContactSideBar from "./ContactSideBar";
import { Box, Button } from "@mui/material";

import PropTypes from "prop-types";

import Contacts3 from "./Contacts3";
import Report from "./Report";
import { login_companyid } from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import Typography from "./Component/Typography";
import gif from "../../images/13038.jpg";
import { Link } from "react-router-dom";
import CrmTask from "./CrmTask";
import ContactDetails from "./ContactDetails";
import DashBoard from "./CRMDashBoard/DashBoard";
import Form from "./Form/Form";
import Pipeline from "../../apps/crm/pages/pipelines/view/Pipeline";
import Index from "../../apps/crm/pages/Fields/Components/Index";
import CustomizeFields from "../../apps/crm/pages/Fields/Components/CustomizeFields";
import Activities from "../../apps/crm/pages/activities/Activities";
import CrmContacts from "../../apps/crm/pages/contacts/CrmContacts";
import CrmCompanies from "../../apps/crm/pages/companies/CrmCompanies";
import CrmProducts from "../../apps/crm/pages/products/CrmProducts";
import UserControls from "../../apps/crm/pages/settings/usercontrols/UserControls";



const style = {
  width: "100%",
  overflow: "auto"
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



export default function Dashboard() {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);

  const { pathname } = useLocation();

  


  //   ================================CLOSE DRAWER =====================================

  return (
    <>
      <ContactSideBar />
      <Box style={style}>
        {pathname === "/contacts" || pathname === "/contacts/dashboard" ? (
          company_idd ? (
           
            <>
              <DashBoard />
            </>
          ) : (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img src={gif} width={500} height={400} alt="img"></img>
                <Typography
                  variant="subtitle1"
                  fontWeight={700}
                  color="#0098e5"
                >
                  You Need To Creat Your Company First
                </Typography>
                <Button
                  className="tablebutton"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {" "}
                  <Link
                    to="/settings/managecompany"
                    style={{
                      color: isHover ? "#3367d6" : "white",
                      textDecoration: "none",
                    }}
                  >
                    Add Company
                  </Link>
                </Button>
              </div>
            </Box>
          )
        ) : pathname === "/contacts/contact" ? (
          <Contacts3 />
        ) : pathname === "/contacts/crmtask" ? (
          <CrmTask />
        ) : pathname === "/contacts/report" ? (
          <Report />
        ): pathname === "/contacts/contactDetails" ? (
          <ContactDetails />
        ) : pathname === "/contacts/forms" ? (
          <Form />
        ) : pathname === "/crm/pipeline" ? (
          <Pipeline />
        ) : pathname === "/crm/activities" ? (
          <Activities />
        ) : pathname === "/crm/contacts" ? (
          <CrmContacts />
        ) : pathname === "/crm/companies" ? (
          <CrmCompanies />
        ) : pathname === "/crm/products" ? (
          <CrmProducts />
        ) : pathname === "/crm/settings/usercontrols/users" ? (
          <UserControls />
        ) : pathname === "/contacts/Fields" ? (
          <Index />
        ) : pathname === "/contacts/customizeFields" ? (
          <CustomizeFields />
        ) : (
          ""
        )}
      </Box>
      <style></style>
    </>
  );
}
