import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, MenuItem, Popover } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { TbTargetArrow } from "react-icons/tb";
import { AiOutlineMenuFold } from "react-icons/ai";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { Link } from "react-router-dom";

const boxStyle = {
  border: "1px solid var(--light-border-color)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "25px",
  marginTop: "2px",
  width: "25px",
  color: "var(--light-filter-icon-color)",
  backgroundColor: "var(--onhover-filter-icon-bgc)",
  cursor: "pointer",
  marginRight: "10px",
  "&:hover": {
    backgroundColor: "var(--dark-filter-icon-color)",
    border: "1px solid #00000040",
  },
};

const VisitorHead = ({searchQuery,handleSearchChange}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickOpen = (event) => {
    setAnchorEl(event.currentTarget); 
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div
      style={{
        padding: "10px 20px 8px 20px",
        borderBottom: "1px solid var(--light-border-color)",
      }}
    >
      <div
        style={{
          display: "flex",
          color: "var(--light-text-color)",
          height: "40px",
          alignItems: "center",
        }}
      >
        <SearchIcon sx={{ marginRight: "5px" }} />
        <input
          style={{
            width: "30%",
            paddingLeft: "10px",
            border: "none",
            height: "25px",
            outline: "none",
          }}
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search (minimum of 3 characters)"
        ></input>

        <Box
          sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}
        >
          <Box
            sx={{ borderRadius: "2px" }}
            style={boxStyle}
          >
            <FilterAltOutlinedIcon onClick={handleClickOpen} />
          </Box>
          <span
            style={{
              marginTop:"2px",
              color: "var(--light-text-color)",
              marginRight: "10px",
            }}
          >
            |
          </span>
          <Box
            sx={boxStyle}
            style={{ borderRadius: "2px" }}
            component={Link}
            to="/chat/visitors/cricle-view"
          >
            <TbTargetArrow />
          </Box>
          <Box
            sx={boxStyle}
            style={{ borderRadius: "2px" }}
            component={Link}
            to="/chat/visitors/tab-view"
          >
            <ListOutlinedIcon />
          </Box>
          <Box
            sx={boxStyle}
            style={{ borderRadius: "50%" }}
            component={Link}
            to="/chat/visitors/"
          >
            <AiOutlineMenuFold />
          </Box>
        </Box>
      </div>
      <Popover
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ width: "200px", height: "280px" }}>
          <MenuItem
            onClick={handleClose}
            disableRipple
            sx={{ padding: "15px", borderBottom: "1px solid var(--light-border-color)" }}
          >
            All Contacts
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            disableRipple
            sx={{ padding: "15px", borderBottom: "1px solid var(--light-border-color)" }}
          >
            Returning Contacts
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            disableRipple
            sx={{ padding: "15px", borderBottom: "1px solid var(--light-border-color)" }}
          >
            Today's Contacts
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            disableRipple
            sx={{ padding: "15px", borderBottom: "1px solid var(--light-border-color)" }}
          >
            Cold Contacts
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            disableRipple
            sx={{ padding: "15px", borderBottom: "1px solid var(--light-border-color)" }}
          >
            Today New Contacts
          </MenuItem>
        </Box>
      </Popover>
    </div>
  );
};

export default VisitorHead;
 // const [searchQuery, setSearchQuery] = useState("");

  // useEffect(() => {
  //   let filteredData;
  //   if (searchQuery.trim() === "") {
  //     filteredData = visitordata;
  //   } else {
  //     filteredData = visitordata.filter((item) =>
  //       Object.values(item).some(
  //         (val) =>
  //           typeof val === "string" &&
  //           val.toLowerCase().includes(searchQuery.toLowerCase())
  //       )
  //     );
  //   }
  //   setFilteredVisitorData(filteredData); 
  // }, [searchQuery, visitordata, setFilteredVisitorData]);

  // const handleSearch = (e) => {
  //   setSearchQuery(e.target.value);
  // };
