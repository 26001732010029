

function Info() {
  return (
    <div style={{
      // border: "1px solid red",
      height: "68.5vh"
      
    }}>Info</div>
  )
}

export default Info