// import React from "react";
// import Avatar from "@mui/material/Avatar";

// const UserAvatar = ({ username, size }) => {
//   const stringToColor = (str) => {
//     let hash = 0;
//     for (let i = 0; i < str.length; i++) {
//       hash = str.charCodeAt(i) + ((hash << 5) - hash);
//     }
//     const c = (hash & 0x00ffffff).toString(16).toUpperCase();
//     return "#" + "00000".substring(0, 6 - c.length) + c;
//   };

//   const backgroundColor = stringToColor(username);

//   return (
//     <Avatar
//       sx={{
//         bgcolor: backgroundColor,
//         width: size,
//         height: size,
//       }}
//     >
//       {username.charAt(0)}
//     </Avatar>
//   );
// };

// export default UserAvatar;


import React from "react";
import Avatar from "@mui/material/Avatar";

const UserAvatar = ({ avatar, size }) => {
    const stringToColor = (str) => {

        const backgrounds = [
            "#8EAC50",
           "#D3D04F",
            "#FF9B9B",
            "#9376E0",
            "#A367B1",
            "#B0DAFF",
            "#FF6AC2",
            "#0C2D57",
            "#CCD6A6",
            "#940B92",
        ];

        if (!str || typeof str !== "string") {
          return backgrounds;
        }
      
        const hash = Array.from(str).reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const index = hash % backgrounds.length;
      
        return backgrounds[index];
      };
      
      const backgroundColor = stringToColor(avatar || "");
  return (
    <Avatar
      src={avatar}
      sx={{
        bgcolor: backgroundColor,
        width: "35px",
        height: "35px",
        marginLeft: "10px",
        marginRight: "15px",
        backgroundImage: stringToColor(avatar)
      }}
    />
  );
};

export default UserAvatar;
