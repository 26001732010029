import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import variables from "../../assets/styles/_colors.scss";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment/moment";
import { DeleteNote } from "../notes/DeleteNote";
import { withStyles } from "@mui/styles";

const StyledTableSeparator = withStyles({
  root: {
    borderRadius: "50%",
    display: "inline-block",
    height: "5px",
    width: "5px",
    backgroundColor: `${variables.borderColor}`,
    marginLeft: 8,
    marginRight: 8,
  },
})(Typography);

const FileUpload = () => {
  const [files, setFiles] = useState([]);

  const handleFileUpload = (file) => {
    const uploadedFile = file[0];
    setFiles([...files, uploadedFile]);
  };

  const handleFileDelete = (item, index) => {
    const oldFiles = [...files];
    const removedFile = oldFiles.splice(index, 1);
    setFiles(oldFiles);
  };

  return (
    <>
      <Box sx={{ pl: 2, pb: 2, pr: 2 }}>
        <Typography component="div" sx={{ fontWeight: "bold", pt: 2, pb: 1 }}>
          Files
        </Typography>
        <Typography component="div" sx={{ width: "100%" }}>
          <FileUploader
            id="fileUpload"
            classes="fileUpload"
            multiple={true}
            hoverTitle="Drop here"
            label="Browser for a file to upload or Drag a file here"
            handleChange={handleFileUpload}
            name="file"
          />
        </Typography>
        {files.length > 0 ? (
          files.map((file, index) => {
            return (
              <>
                <File
                  file={file}
                  index={index}
                  deleteFile={(item, index) => handleFileDelete(item, index)}
                />
              </>
            );
          })
        ) : (
          <Typography sx={{ pt: 7, textAlign: "center", pb: 2 }}>
            This record doesn't have any files.
          </Typography>
        )}
      </Box>
    </>
  );
};
export default FileUpload;

const File = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false);

  return (
    <>
      <Stack
        direction="column"
        sx={{
          p: 2,
          borderBottom: `1px solid ${variables.borderColor}`,
          "&:hover": {
            cursor: "pointer",
            backgroundColor: `${variables.lightGreyBackgroundColor}`,
            "&:hover #name": { color: `${variables.linkColor}` },
          },
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Stack direction="row" width="100%">
          <Stack direction="row" justifyContent="left" width="80%">
            <Typography id="name"> {props.file?.name} </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="right"
            width="20%"
            sx={{ visibility: isHovering ? "inherit" : "hidden" }}
          >
            <IconButton
              sx={{ height: "30px", width: "30px", bgcolor: "#fff !important" }}
            >
              <DeleteOutlineIcon
                fontSize="medium"
                color="error"
                onClick={() => setDeleteFile(true)}
              />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{ fontSize: 13, color: `${variables.greyTextColor}` }}
          >
            {" "}
            {props.file?.size / 1000} KB
          </Typography>
          <StyledTableSeparator />
          <Typography
            sx={{ fontSize: 13, color: `${variables.greyTextColor}` }}
          >
            {" "}
            {`${moment().calendar()} by Test user`}{" "}
          </Typography>
        </Stack>
      </Stack>
      {deleteFile && (
        <DeleteNote
          module="file"
          handleOk={() => {
            setDeleteFile(false);
            props.deleteFile(props.item, props.index);
          }}
          handleCancel={() => setDeleteFile(false)}
        />
      )}
    </>
  );
};
