import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useEffect } from "react";
import { getAllPipelines } from "../../../../webservices/PipelineService";
import { useState } from "react";
import { CloseButton } from "../../../../components/CloseButton";

export default function ChangePipelineAndStage(props) {

    const [teamPipelines, setTeamPipelines] = useState([]);
    const [subPipelines, setSubPipelines] = useState([]);
    const [stages, setStages] = useState([]);
    const [selectedTeamPipeline, setSelectedTeamPipeline] = useState("");
    const [selectedSubPipeline, setSelectedSubPipeline] = useState("");
    const [selectedStage, setSelectedStage] = useState("");

    const [open, setOpen] = useState(true);

    function handleDialogClose(type) {
        setOpen(false);
        props.handleDialogClose(type);
    }

    useEffect(() => {
        getAllPipelines().then(response => {
            setTeamPipelines(response.data.pipelines);
        });
    }, []);

    useEffect(() => {
    }, [teamPipelines]);

    useEffect(() => {
        if (selectedTeamPipeline !== "") {
            const filtered = teamPipelines.filter(o => o.id === selectedTeamPipeline);
            setSubPipelines(filtered[0].subPipelines);
        }

    }, [selectedTeamPipeline]);


    useEffect(() => {
        if (selectedSubPipeline !== "") {
            const filtered = subPipelines.filter(o => o.id === selectedSubPipeline);
            setStages(filtered[0].stages);
        }

    }, [selectedSubPipeline]);


    return (
        <Dialog
            open={open}
            aria-labelledby="delete-dialog"
            PaperProps={{
                sx: { minWidth: '40%', maxWidth: '40%', overflow: 'hidden' }
            }}>

            <DialogTitle id="delete-dialog-title">
                Change Stage/Pipeline
                <CloseButton handleDialogClose={handleDialogClose} />
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth sx={{ mt: 1 }}>
                    <InputLabel shrink id="team-pipeline-input-label">Team Pipeline</InputLabel>
                    <Select
                        displayEmpty
                        value={selectedTeamPipeline}
                        onChange={(event) => setSelectedTeamPipeline(event.target.value)}
                    >
                        <MenuItem value="" disabled>Choose Team Pipeline</MenuItem>
                        {teamPipelines?.map(option => {
                            return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }} disabled={selectedTeamPipeline !== "" ? false : true}>
                    <InputLabel shrink id="sub-pipeline-input-label">Sub-Pipeline</InputLabel>
                    <Select
                        displayEmpty
                        value={selectedSubPipeline}
                        onChange={(event) => setSelectedSubPipeline(event.target.value)}
                    >
                        <MenuItem value="" disabled>Choose Sub-Pipeline</MenuItem>
                        {subPipelines?.map(option => {
                            return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }} disabled={selectedSubPipeline !== "" ? false : true}>
                    <InputLabel shrink id="stage-input-label">Stage</InputLabel>
                    <Select
                        displayEmpty
                        value={selectedStage}
                        onChange={(event) => setSelectedStage(event.target.value)}
                    >
                        <MenuItem value="" disabled>Choose Stage</MenuItem>
                        {stages?.map(option => {
                            return <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Stack spacing={2} direction="row" justifyContent="right" sx={{ pb: 2, pr: 2 }}>
                    <Button variant="outlined" onClick={handleDialogClose}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handleDialogClose} disabled={selectedStage === ""}>Save</Button>
                </Stack>
            </DialogActions>

        </Dialog>
    )

};