import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import appStore from "./redux/store";
import Common from "./containers/Common";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Interceptor from "./components/Interceptor/Interceptor";
import { RecoilRoot } from "recoil";
import CssBaseline from "@mui/material/CssBaseline";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.Fragment>
    <CssBaseline />
    <Provider store={appStore}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <Interceptor />
          </RecoilRoot>
        </QueryClientProvider>
        <Common />
      </ThemeProvider>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

reportWebVitals();
