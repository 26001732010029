import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,

  Modal,
} from "@mui/material";

import { useRecoilState } from "recoil";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { api_url1 } from "../../../../../globalSettings";
import { triger_records } from "../../../../../Atoms/LoginAtoms";
import axios from "axios";

import DnsARecords from "./Components/DnsARecords";
import AAAARecord from "./Components/AAAARecord";
import CnameRecord from "./Components/CnameRecord";
import NsRecord from "./Components/NsRecord";
import TxtRecord from "./Components/TxtRecord";
import MxRecord from "./Components/MxRecord";
import SrvRecord from "./Components/SrvRecord";
import SoaRecord from "./Components/SoaRecord";
import DnsAllRecords from "./Components/DnsAllRecords";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};



const DnsRecords = ({
  openDnsRecords,
  closeDnsRecords,
  Selecteddomain,

}) => {

  const [trigerRecords, setTrigerRecords] = useRecoilState(triger_records);

 

  const [allRecords, setAllRecords] = useState([]);
  const [aRecord, setArecord] = useState([]);
  const [aaaaRecord, setAaaarecord] = useState([]);
  const [mxRecord, setMxrecord] = useState([]);
  const [cnameRecord, setCnamerecord] = useState([]);
  const [nsRecord, setNsrecord] = useState([]);
  const [txtRecord, setTxtrecord] = useState([]);
  const [srvRecord, setSrvrecord] = useState([]);
  const [soaRecord, setSoarecord] = useState([]);




  useEffect(() => {
    axios
      .get(`${api_url1}/plesk/dnsRec/${Selecteddomain.domainName}`)
      .then((result) => {

        const recordData = result.data;
        setAllRecords(recordData);
        setArecord(recordData.filter((item) => item.type === "A"));
        setAaaarecord(recordData.filter((item) => item.type === "AAAA"));
        setMxrecord(recordData.filter((item) => item.type === "MX"));
        setCnamerecord(recordData.filter((item) => item.type === "CNAME"));
        setNsrecord(recordData.filter((item) => item.type === "NS"));
        setTxtrecord(recordData.filter((item) => item.type === "TXT"));
        setSrvrecord(recordData.filter((item) => item.type === "SRV"));
        setSoarecord(recordData.filter((item) => item.type === "SOA"));
      })
      .catch((err) => {
      });
  }, [trigerRecords,Selecteddomain.domainName]);


  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabData = [
    {
      label: "ALLRECORDS",
      content: (
        <DnsAllRecords
          aRecord={aRecord}
          aaaaRecord={aaaaRecord}
          mxRecord={mxRecord}
          cnameRecord={cnameRecord}
          nsRecord={nsRecord}
          txtRecord={txtRecord}
          srvRecord={srvRecord}
          soaRecord={soaRecord}
      

        />
      ),
    },
    { label: "A", content: <DnsARecords aRecord={aRecord} Selecteddomain={Selecteddomain}/> },
    { label: "AAAA", content: <AAAARecord aaaaRecord={aaaaRecord} Selecteddomain={Selecteddomain}/> },
    { label: "MX", content: <MxRecord mxRecord={mxRecord} Selecteddomain={Selecteddomain}/> },
    { label: "CNAME", content: <CnameRecord cnameRecord={cnameRecord} Selecteddomain={Selecteddomain}/> },
    { label: "NS", content: <NsRecord nsRecord={nsRecord} Selecteddomain={Selecteddomain}/> },
    { label: "TXT", content: <TxtRecord txtRecord={txtRecord} Selecteddomain={Selecteddomain}/> },
    { label: "SRV", content: <SrvRecord srvRecord={srvRecord} Selecteddomain={Selecteddomain}/> },
    { label: "SOA", content: <SoaRecord soaRecord={soaRecord} Selecteddomain={Selecteddomain}/> },
  ];

  return (
    <>

      <Modal
        open={openDnsRecords}
        onClose={closeDnsRecords}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style} >
          <div className="alignHeader" style={{ padding: 20 }}>
            <div>
              <Typography className="contact-title">
                {" "}
                MANAGE DNS RECORDS
              </Typography>
              <Typography className="contact-title-domainName">
                For:{Selecteddomain.domainName}
              </Typography>
            </div>
            <div>
              <IconButton component="span" onClick={closeDnsRecords}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {/* <div style={style1}>
              MANAGE DNS RECORDS
                <IconButton
                  component="span"
                  onClick={closeDnsRecords}
                  style={{ padding: 0, marginRight: 20 }}
                >
                  <CloseIcon />
                </IconButton>
              </div> */}
          <ul className="tabs">
            {tabData.map((tab, index) => (
              <li
                key={index}
                className={index === activeTab ? "active" : ""}
                onClick={() => handleTabClick(index)}
              >
                {tab.label}
              </li>
            ))}
          </ul>
          <div className="tab-content">{tabData[activeTab].content}</div>
        </Box>
      </Modal>
   
      <style>
        {`.tabs {
            list-style: none;
            padding: 0;
            display: flex;
            margin:0
          }
          .tabs li {
            cursor: pointer;
            margin: 10px 15px;
            font-weight: bold;
            color:#468def;
            margin-right: 10px;
            font-size:12px
          }
          
          .tabs li.active {
            background-color: #ffffff;
            color:#000;
            font-weight: bold;
            border-bottom: 3px solid #5a7cc3;
          }
          
          .tab-content {
            margin-top: 10px;
            display : flex;
            justify-content : center
          }`}
      </style>
    </>
  );
};
export default DnsRecords;
