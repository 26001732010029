import { Box, Stack, Typography } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { CustomCurrencyField } from "../components/CustomCurrencyField";
import { get, useFormContext, useWatch } from "react-hook-form";
import { CustomDatePicker } from "../components/CustomDatePicker";
import variables from "../../../assets/styles/_colors.scss";
import { useEffect } from "react";

const calendarDropdown = [
    {
        label: "Days",
        value: "days"
    },
    {
        label: "Months",
        value: "months"
    },
    {
        label: "Years",
        value: "years"
    }
];

const CommonDateFields = (props) => {

    const { control, formState: { errors }, resetField, setValue, getValues } = useFormContext();
    const fieldErrors = get(errors, props.name);

    const rules = {
        required: {
            value: true
        }
    };

    const selectedOption = useWatch({ control, name: `${props.name}.comparator` });

    useEffect(() => {
        if (!props.showTwoDateFields?.includes(selectedOption)) {
            resetField(`${props.name}.from`);
            resetField(`${props.name}.to`);
        }
        if (!props.showDateField?.includes(selectedOption)) {
            resetField(`${props.name}.date`);
        }
        if (!props.customFields?.includes(selectedOption)) {
            resetField(`${props.name}.number`);
            resetField(`${props.name}.dropdown`);
        }
        if (props.singleField?.includes(selectedOption)) {
            resetField(`${props.name}.from`);
            resetField(`${props.name}.to`);
            resetField(`${props.name}.date`);
            resetField(`${props.name}.number`);
            resetField(`${props.name}.dropdown`);
        }
        if (props.customFields?.includes(selectedOption)) {
            const numberVal = getValues(`${props.name}.number`);
            const dropdownVal = getValues(`${props.name}.dropdown`);
            setValue(`${props.name}.number`, numberVal ? numberVal : 2);
            setValue(`${props.name}.dropdown`, dropdownVal ? dropdownVal : calendarDropdown[0].value);
        }

    }, [selectedOption]);

    useEffect(() => {
        setValue(`${props.name}.comparator`, props.options[0].value);
        setValue(`${props.name}.number`, 2);
        setValue(`${props.name}.dropdown`, calendarDropdown[0].value);
    }, []);

    useEffect(() => {
    }, [fieldErrors]);

    return (
        <>
            <Stack sx={{ width: '100%', pt: 1, flexWrap: 'wrap' }} spacing={1}>
                <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
                    <Stack direction="row" sx={{ width: '50%' }}>
                        <CustomSelect size="small" options={props.options} name={`${props.name}.comparator`} defaultValue={props.options[0].value} />
                    </Stack>
                    {selectedOption &&
                        props.showDateField?.includes(selectedOption) &&
                        <Stack direction="row" sx={{ width: '50%' }}>
                            <CustomDatePicker rules={rules} name={`${props.name}.date`} width="100%" />
                        </Stack>
                    }
                </Stack>
                {selectedOption &&
                    props.showTwoDateFields?.includes(selectedOption) &&
                    <Stack direction="row" spacing={1}>
                        <Stack sx={{ width: '50%' }}>
                            <CustomDatePicker rules={rules} name={`${props.name}.from`} width="100%" />
                        </Stack>
                        <Stack sx={{ width: '50%' }}>
                            <CustomDatePicker rules={rules} name={`${props.name}.to`} width="100%" />
                        </Stack>
                    </Stack>
                }
                {selectedOption &&
                    props.customFields?.includes(selectedOption) &&
                    <Stack direction="row" sx={{ width: '100%' }} spacing={1}>
                        <CustomCurrencyField rules={rules} size="small" name={`${props.name}.number`} />
                        <CustomSelect size="small" options={calendarDropdown} name={`${props.name}.dropdown`} defaultValue={calendarDropdown[0].value} />
                    </Stack>
                }
            </Stack>

            {fieldErrors &&
                <Box sx={{ color: variables.removeIconColor, pt: 1 }}>

                    {fieldErrors["number"]
                        && <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid number </Typography>}
                    {(fieldErrors["from"] || fieldErrors["to"])
                        && <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid date range </Typography>}
                    {fieldErrors["date"]
                        && <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid date </Typography>}

                </Box>
            }
        </>
    )
};

export default CommonDateFields;