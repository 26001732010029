import React from 'react';
import { Avatar } from "@mui/material";

function AvatarStatus({ src, alt, status, shape }) {
  const renderStatusIndicator = () => {
    if (!status) { // Checking if status is false
      return (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: 'green',
            border: '2px solid white',
          }}
        />
      );
    }
    return null;
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <Avatar src={src} alt={alt} sx={{ borderRadius: shape === "square" ? '8px' : '50%' }} />
      {renderStatusIndicator()}
    </div>
  );
}

export default AvatarStatus;