import React from "react";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Button, Typography, styled, Divider, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    width: "300px",
    display: "flex",
    justifyContent: "flex-start",
  },
});

const QuickJumpInfo = () => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  const [sharedimagefilesopen, setSharedimagefilesopen] = React.useState(true);
  const handleSharedImageFilesClick = () => {
    setSharedimagefilesopen(!sharedimagefilesopen);
  };

  const [otherConversationsopen, setOtherConversationsopen] =
    React.useState(true);

  const handleOtherConversationsClick = () => {
    setOtherConversationsopen(!otherConversationsopen);
  };

  return (
    <List sx={{ p: 0 }}>
      <StyledButton
        onClick={handleClick}
        sx={{
          padding: "11px 40px 10px 12px ",
          backgroundColor: "#fafbfc",
          borderBottom: "1px solid #e3e8ef",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "Inter",
            color: "#75869c",
            textAlign: "left",
            textTransform: "capitalize",
          }}
        >
          QUICK JUMP
        </Typography>
        {open ? (<ArrowDropDownIcon sx={{color: "#75869c",marginLeft:"auto"}}/>) : (<ArrowLeftIcon sx={{color: "#75869c",marginLeft:"auto"}}/>)}
        
      </StyledButton>

      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>
            <StyledButton
              onClick={handleSharedImageFilesClick}
              sx={{
                padding: "11px 40px 10px 12px ",
                backgroundColor: "#fafbfc",
                borderBottom: "1px solid #e3e8ef",
              }}
            >
             {sharedimagefilesopen ? (
                <KeyboardArrowUpIcon sx={{color: "#75869c",}}/>
              ) : (
                <KeyboardArrowDownIcon sx={{color: "#75869c",}}/>
              )}
              <Typography
                sx={{
                  fontSize: 12,
                  fontFamily: "Inter",
                  color: "#75869c",
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
              >
                Shared Images Files
              </Typography>
            </StyledButton>

            {sharedimagefilesopen && (
              <>
                <Box
                  onClick={handleSharedImageFilesClick}
                  sx={{
                    backgroundColor: "#F8F8F8",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    color: "#75869c",
                    cursor: "pointer",
                    borderBottom: "1px solid #e3e8ef",
                  }}
                >
                  <ArrowBackIosIcon sx={{ fontSize: "14px" }} />
                  <Typography>Back</Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px",
                  }}
                >
                  No images were shared.
                </Typography>
              </>
            )}
          </List>

          <List sx={{ p: 0 }}>
            <StyledButton
              onClick={handleOtherConversationsClick}
              sx={{
                padding: "11px 40px 10px 12px ",
                backgroundColor: "#fafbfc",
                borderBottom: "1px solid #e3e8ef",
              }}
            >
              {" "}
              {otherConversationsopen ? (
                <KeyboardArrowUpIcon sx={{color: "#75869c",}}/>
              ) : (
                <KeyboardArrowDownIcon sx={{color: "#75869c",}}/>
              )}
              <Typography
                sx={{
                  fontSize: 12,
                  fontFamily: "Inter",
                  color: "#75869c",
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
              >
                Other conversations
              </Typography>
            </StyledButton>
            {otherConversationsopen && (
              <>
                <Box
                  onClick={handleOtherConversationsClick}
                  sx={{
                    backgroundColor: "#F8F8F8",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    color: "#75869c",
                    cursor: "pointer",
                    borderBottom: "1px solid #e3e8ef",
                  }}
                >
                  <ArrowBackIosIcon sx={{ fontSize: "14px" }}/>
                  <Typography>Back</Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    padding: "10px",
                  }}
                >
                  No images were shared.
                </Typography>
              </>
            )}
          </List>
        </Collapse>
      )}
      <Divider sx={{ borderBottom: "1px solid grey" }} />
    </List>
  );
};

export default QuickJumpInfo;
