import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { deleteProfile } from '../../../../webservices/UserControlService';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function DeleteProfile(props) {

    const [open, setOpen] = React.useState(true);
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
        mutationKey: "deleteProfile",
        mutationFn: (item) => deleteProfile(item._id, item.profileName),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllProfiles'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = (data) => {
        setOpen(false);
        props.handleClose(data);
    }

    const handleDelete = () => {
        mutateAsync(props.item);
    }

    return (
        <Modal open={open}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                p: "16px 24px",
                width: '33%'
            }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>Delete Profile
                    <CloseButton handleDialogClose={() => handleClose()} />
                </Typography>

                <Typography component="div" variant="body" sx={{ pt: 2, pb: 2 }}>
                    Are you sure you want to delete the profile <b>{props.item?.profileName}</b>?
                </Typography>

                <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                    <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleDelete}>Delete Profile</Button>
                </Stack>
            </Box>
        </Modal>
    );
}