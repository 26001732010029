import {  Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { loadingAll, triger_records } from "../../../../../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import edit from "../../../../img/edit-text.png";
import deleteicon from "../../../../img/delete.png";
import axios from "axios";
import { api_url1 } from "../../../../../../globalSettings";
import { open_snackbar } from "../../../../../login/loginatom";


const style = {
  width: "100%",
  padding: 20,
  paddingTop: 0,
};

const gridLastCellStyle = {
  border: "1px solid #e9e9eb",
  textAlign: "center",
  display: "flex",
};

const ttlOptions = [
  {
    label: "4 Hours",
    value: 14400,
  },
  {
    label: "6 Hours",
    value: 21600,
  },
  {
    label: "12 Hours",
    value: 43200,
  },
  {
    label: "1 day",
    value: 86400,
  },
  {
    label: "4 days",
    value: 345600,
  },
  {
    label: "1 week",
    value: 604800,
  },
];
const CnameRecord = ({ cnameRecord, Selecteddomain }) => {
  const [trigerRecords, setTrigerRecords] = useRecoilState(triger_records);
  const [isLoading, setIsLoading] = useRecoilState(loadingAll);
  const [snackbaropen, setSnackbaropen] = useRecoilState(open_snackbar);
  const [isThirdGridOpen, setThirdGridOpen] = useState(false);

  const toggleThirdGrid = (indexId) => {
    setThirdGridOpen(!isThirdGridOpen);
    setIndexId(indexId);
  };
  const [isDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isEditable, setEditable] = useState(false);
  const [indexId, setIndexId] = useState("");

  const toggleDelete = (indexId) => {
    setDeleteId(indexId);
    setDelete(!isDelete);
  };

  const toggleEditable = (indexId) => {
    // Toggle the state when the toggle button is clicked
    setIndexId(indexId);
    setEditable(!isEditable);
    setThirdGridOpen(!isThirdGridOpen);
  };

  const gridCellStyle = {
    border: "1px solid #e9e9eb",
    textAlign: "center",
    borderBottom: `${isThirdGridOpen ? "none" : "1px solid #e9e9eb"}`,
    alignItems: "center",
    display: "flex",
  };

  const [companyform, setCompanyForm] = useState({
    host:`${Selecteddomain?.domainName || ""}`,
    value: "",
  });

  const [ttl, setTtl] = useState("4 Hours");


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCompanyForm((state) => ({ ...state, [name]: value }));
  };

  const handleSelectChange = (e) => {
    setTtl(e.target.value);
  };

  useEffect(() => {
    setYourState({ cnameRecord });
  }, [cnameRecord]);

  const addRecord = () => {
    setIsLoading(true);
    const params = {
      type: "CNAME",
      host: companyform.host,
      value: companyform.value,
      opt: "",
      ttl: ttl,
    };

    axios
      .post(`${api_url1}/plesk/dns/${Selecteddomain.domainName}`, params)
      .then(function (response) {
        setIsLoading(false);
        setCompanyForm({ host: "", value: "" });
        setSnackbaropen({
          open: true,
          severity: "success",
          message: "A Record Added Successfully",
        });
        setTrigerRecords(!trigerRecords);
      })

      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
          setSnackbaropen({
            open: true,
            severity: "error",
            actionColor: "#edf7ed",
            message: error.response.data.message,
          });
         
        } else if (error.request) {
       
        } else {
       
        }
      });
  };

  const deleteRecord = () => {
    setIsLoading(true);

    axios
      .delete(`${api_url1}/plesk/dns/${deleteId}`)
      .then((result) => {
        setSnackbaropen({
          open: true,
          severity: "success",
          message: "Record Deleted Successfully",
        });
        setIsLoading(false);
        setTrigerRecords(!trigerRecords);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
       
        } else if (error.request) {
        
        } else {
        
        }
      });
  };

  // ====================================== UPDATE RECORDS CALL ======================================

  const [yourState, setYourState] = useState({ cnameRecord });

  const [changedRecords, setChangedRecords] = useState([]);


  const handleUpdateChange = (index, fieldName, value) => {

    const updatedRecord = yourState.cnameRecord.map((record, i) =>
      i === index ? { ...record, [fieldName]: value } : record
    );

    setYourState({
      ...yourState,
      cnameRecord: updatedRecord,
    });

    setChangedRecords(updatedRecord[index]);
  };

  const handleUpdateTtl = (index, fieldName, value) => {

    const numericValue = parseFloat(value);
    const updatedRecord = yourState.cnameRecord.map((record, i) =>
      i === index ? { ...record, [fieldName]: numericValue } : record
    );

    setYourState({
      ...yourState,
      cnameRecord: updatedRecord,
    });

    setChangedRecords(updatedRecord[index]);
  };

  const updateRecords = () => {
    setIsLoading(true);
   
    axios
      .put(`${api_url1}/plesk/dns`, changedRecords)
      .then(function (response) {
        setSnackbaropen({
          open: true,
          severity: "success",
          message: "AAAA Record Updated Successfully",
        });
        setIsLoading(false);
        setEditable(!isEditable);
        setTrigerRecords(!trigerRecords);
      })

      .catch(function (error) {
        setIsLoading(false);
        if (error.response) {
         
        } else if (error.request) {
        
        } else {
       
        }
      
      });
  };
  

  return (
    <div style={style}>
     
      <Typography className="contact-title" style={{ marginBottom: 10 }}>
        {" "}
        ADD CNAME RECORD
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={6} style={gridCellStyle}>
        <div className="cd-align">
            <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
            <input
              className="cd-input"
              value={companyform.host}
              name="host"
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item xs={6} style={gridCellStyle} display={"flex"}>
          <div
            style={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p style={{ fontSize: "small" }}>CNAME</p>
          </div>
          <span
            style={{ width: 1, height: 20, backgroundColor: "#0000005c" }}
          ></span>
           <div className="cd-align">
            <label className="cd-title">
              SUB-DOMAIN/FULLYQUALIFIED DOMAIN*
            </label>
            <input
              className="cd-input"
              value={companyform.value}
              name="value"
              onChange={handleChange}
            />
          </div>
        </Grid>
        <Grid item xs={12} style={gridLastCellStyle}>
        <div className="cd-align">
            <label className="cd-title">TTL(Time To Live)</label>
            <select
              style={{ width: 200 }}
              className="cd-input"
              value={ttl}
              onChange={handleSelectChange}
            >
              <option value="" disabled selected>
                Choose ttl
              </option>
              {ttlOptions.map((options) => {
                return <option value={options.value}>{options.label}</option>;
              })}
            </select>
          </div>
        </Grid>
      </Grid>
      <LoadingButton
        loading={isLoading}
        className="loadingButton"
        style={{
          backgroundColor: `${
            companyform.host && companyform.value ? "#468def" : "#e0e0e2"
          } `,
        }}
        onClick={addRecord}
        disabled={companyform.host && companyform.value ? false : true}
      >
        SAVE RECORD(S)
      </LoadingButton>

      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="subtitle2"
          style={{ marginBottom: "10px", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          CURRENT RECORDS ({cnameRecord.length})
        </Typography>

        {yourState.cnameRecord.length > 0 ? (
          yourState.cnameRecord.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ width: "100%", display: "flex", margin: "10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    borderLeft: "2px solid #28bd8b",
                    width: "80%",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={gridCellStyle}>
                      {isEditable && indexId === item.id ? (
                          <div className="cd-align">
                          <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                          <input
                            className="cd-input"
                            value={item.host}
                            onChange={(e) =>
                              handleUpdateChange(index, "host", e.target.value)
                            }
                          />
                        </div>
                      ) : (
                        <p style={{ fontSize: "14px", padding: "10px" }}>
                          {item.host}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} style={gridCellStyle} display={"flex"}>
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "small" }}>CNAME</p>
                      </div>
                      <span
                        style={{
                          width: 1,
                          height: 20,
                          backgroundColor: "#0000005c",
                        }}
                      ></span>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        {isEditable && indexId === item.id ? (
                           <div className="cd-align">
                            <label className="cd-title">
                              SUB-DOMAIN/FULLYQUALIFIED DOMAIN
                            </label>
                            <input
                              className="cd-input"
                              value={item.value}
                              onChange={(e) =>
                                handleUpdateChange(
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                            />{" "}
                          </div>
                        ) : (
                          <p style={{ fontSize: "14px", padding: "10px" }}>
                            {item.value}
                          </p>
                        )}
                      </div>

                      <div onClick={() => toggleThirdGrid(item.id)}>
                        {isThirdGridOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </Grid>

                    {isThirdGridOpen && indexId === item.id && (
                      <Grid item xs={12} style={gridLastCellStyle}>
                        <div className="cd-align">
                          <label className="cd-title">TTL(Time To Live)</label>
                          <select
                            style={{ width: 200 }}
                            className="cd-input"
                            value={item.ttl}
                            onChange={(e) =>
                              handleUpdateTtl(index, "ttl", e.target.value)
                            }
                          >
                            <option value="" disabled selected>
                              Choose ttl
                            </option>
                            {ttlOptions.map((options) => {
                              return (
                                <option
                                  key={options.value}
                                  value={options.value}
                                >
                                  {options.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isEditable && indexId === item.id ? (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ backgroundColor: " #468def" }}
                      onClick={updateRecords}
                    >
                      SAVE
                    </LoadingButton>

                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ color: " #468def" }}
                      onClick={toggleEditable}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    {isDelete && deleteId === item.id ? (
                      <div>
                        <p style={{ fontSize: "10px", padding: " 0 10px" }}>
                          Are you Sure?
                        </p>
                        <div>
                          <LoadingButton
                            loading={isLoading}
                            className="deleteButton"
                            onClick={deleteRecord}
                            style={{ backgroundColor: "#e76060" }}
                          >
                            Delete
                          </LoadingButton>

                          <LoadingButton
                            className="deleteButton"
                            style={{ color: " #468def" }}
                            onClick={toggleDelete}
                          >
                            Cancel
                          </LoadingButton>
                        </div>
                      </div>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => toggleEditable(item.id)}
                          style={{ margin: 10 }}
                        >
                          <img src={edit} alt="edit" />
                        </IconButton>
                        <IconButton
                          onClick={() => toggleDelete(item.id)}
                          style={{ margin: 10 }}
                        >
                          <img src={deleteicon} alt="delete" />
                        </IconButton>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p style={{ fontSize: 12 }}> You have not added any Cname records</p>
        )}
      </div>
      <style>
        {`.loadingButton{
                                      color: rgb(255, 255, 255);
                                      background-color: #468def;
                                      margin-top: 20px;
                                      cursor: pointer;
                                      padding:15px 25px;
                                      font-size:12px;
                                      width:max-content;
                                      border-radius:0px
                                  }
                                  .loadingButton1{
                                    color:#fff;
                                    font-size:12px;
                                    width:max-content;
                                    border-radius:0px;
                                    margin:10px
                                 }
                                 .deleteButton{
                                  color:#fff;
                                  font-size:10px;
                                  width:max-content;
                                  border-radius:0px;
                                  margin:5px
                                }
                                  `}
      </style>
    </div>
  );
};

export default CnameRecord;
