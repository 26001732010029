import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const useGetUnreadList = (websiteId) => {

    return useQuery({
      queryKey: ["Unread", websiteId],
      queryFn: async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/allvisitor/unread/${websiteId}`);
          console.log("Unread list", response.data);

          return response.data;
        } catch (error) {
          console.error("API Error:", error);
          throw error;
        }
      },
    });
  };

export default useGetUnreadList;
