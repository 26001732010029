import axios from "axios";
import { api_url1 } from "../../../../globalSettings";
import moment from "moment";

export function handleTheftProtection(
  domainDetails,
  settheftprotectionupdatedialog,
  setapipreloader,
  apipreloader,
  setSnackbarInfo
) {
  if (domainDetails?.theftprotection === true) {
    axios
      .post(`${api_url1}/resellers/disableTheftProtection`, {
        order_id: domainDetails?.orderid,
      })
      .then((result) => {
        settheftprotectionupdatedialog(false);
        setapipreloader(!apipreloader);
      })
      .catch((err) => {
        setapipreloader(!apipreloader);
        setSnackbarInfo({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  } else {
    axios
      .post(`${api_url1}/resellers/enableTheftProtection`, {
        order_id: domainDetails?.orderid,
      })
      .then((result) => {
        settheftprotectionupdatedialog(false);
        setapipreloader(!apipreloader);
      })
      .catch((err) => {
        setSnackbarInfo({
          open: true,
          message: err.response.data.message,
          severity: "error",
        });
      });
  }
}

export function fetchDomainRenewalPrice(
  Selecteddomain,
  setLoadButton,
  setRenewalPrice,
  setOpen
) {
  setLoadButton(true);
  axios
    .get(`${api_url1}/product/domain/renewal_price/${Selecteddomain.productId}`)
    .then((result) => {
      setRenewalPrice(result.data);
      setOpen(true);
    })
    .catch((err) => {})
    .finally(() => {
      setLoadButton(false);
    });
}

export const formatDateWithOrdinal = (date) => {
  const dayOfMonth = moment(date).format("Do");
  const formattedDate = moment(date).format(`[${dayOfMonth}] MMM YYYY`);
  return formattedDate;
};
