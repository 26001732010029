import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AssociatedProducts from './AssociatedProducts';
import CustomFields from './CustomFields';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from "@mui/styles";
import variables from "../../../assets/styles/_colors.scss";

// function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list.fields);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

// function to move fields between lists
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source.fields);
    const destClone = Array.from(destination.fields);
    if (!sourceClone[droppableSource.index].alwaysMandatory) {
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        destClone.splice(droppableDestination.index, 0, removed);
    }

    const result = {};

    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const Item = styled.div`
    display: flex;
    user-select: none;
    padding: 0.5rem;
    margin: 0 0 0.5rem 0;
    align-items: flex-start;
    align-content: flex-start;
    line-height: 1.5;
    border-radius: 3px;
    background: ${variables.whiteColor};
    border: ${props => (props.$isDragging ? `2px dashed ${variables.linkColor}` : `1px solid ${variables.borderColor}`)};
    border-left:  ${props => (props.$isMandatory && '3px solid red')};
`;

const Container = styled.div`
    border: ${props => (props.$isDraggingOver ? `2px dashed ${variables.linkColor}` : `1px solid ${variables.borderColor}`)};
    background: ${variables.whiteColor};
    padding: 0.5rem 0.5rem 0;
    border-radius: 3px;
    flex: 0 0 150px;
`;

const UnusedFields = styled(Container)`
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 0;
    width: 350px;
    border: 0;
    padding: 0 16px 0 8px;
`;

const FormFields = styled(Container)`
    background: ${variables.containerBackgroundColor};
`;

const Placeholder = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 0 0.5rem 0.5rem;
    border: 1px solid transparent;
    line-height: 1.5;
    color: ${variables.containerDisabledText}
`;

const StyledTypography = withStyles({
    root: {
        padding: "1rem 0",
        fontWeight: "600 !important"
    }
})(Typography);

export default function FieldsForm(props) {

    const UNUSED_FIELDS = "unUsedFields";
    const FIELDS = "fields";

    const {
        getValues,
        setValue,
    } = useFormContext();

    const [list, setList] = useState(getValues(FIELDS));
    const [showAddCustomField, setShowAddCustomField] = useState(false);
    const [addMode, setAddMode] = useState(true);
    const [editData, setEditData] = useState(null);
    const [editListName, setEditListName] = useState(null);

    useEffect(() => {
        setValue('fields', list);
    }, [list])

    const removeField = (key, index, item) => {
        const droppableSource = {
            index: index,
            droppableId: key
        }
        // In this case, field will always be moved to index 0 of unused fields section and hence hardcoded
        const droppableDestination = {
            index: 0,
            droppableId: UNUSED_FIELDS
        }
        const updatedData = move(list[key], list[UNUSED_FIELDS], droppableSource, droppableDestination);
        setList(current => {
            return {
                ...current,
                [key]: {
                    ...current[key],
                    fields: updatedData[key]
                },
                [UNUSED_FIELDS]: {
                    ...current[UNUSED_FIELDS],
                    fields: updatedData[UNUSED_FIELDS]
                }
            }
        });
    }

    const onDragEnd = result => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        switch (source.droppableId) {
            case destination.droppableId:
                setList(current => {
                    return {
                        ...current,
                        [destination.droppableId]: {
                            ...current[destination.droppableId],
                            fields: reorder(
                                list[source.droppableId],
                                source.index,
                                destination.index
                            )
                        }
                    }
                });
                break;
            default:
                const updatedData = move(
                    list[source.droppableId],
                    list[destination.droppableId],
                    source,
                    destination
                );
                setList(current => {
                    return {
                        ...current,
                        [source.droppableId]: {
                            ...current[source.droppableId],
                            fields: updatedData[source.droppableId]
                        },
                        [destination.droppableId]: {
                            ...current[destination.droppableId],
                            fields: updatedData[destination.droppableId]
                        }
                    }
                });
                break;
        }
    };

    const handleCreateCustomField = (val, data, addMode, listName, reopenModal) => {
        setShowAddCustomField(val);
        setAddMode(addMode);
        if (data) {
            const key = `fields.${listName}.fields`;
            const fields = getValues(key);
            let updatedFields = null;
            if (addMode) {
                const field = {
                    ...data,
                    id: Math.random().toString(),
                    customField: true
                }
                updatedFields = fields.concat([field]);
            } else {
                const editObj = fields.map((item) => (item.id === data.id ? { ...data } : item))
                updatedFields = editObj;
            }
            setValue(key, updatedFields);
            setList(getValues(FIELDS));
            setValue("creatingCustomField", false);
        }
        reopenModal &&
            handleCreateCustomField(true, null, true, "additional", false);
    }

    const editField = (listName, data) => {
        setEditData(data);
        setAddMode(false);
        setEditListName(listName);
        setShowAddCustomField(true);
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable key={UNUSED_FIELDS} droppableId={UNUSED_FIELDS} isDropDisabled={true}>
                    {(provided, snapshot) => (
                        <UnusedFields
                            ref={provided.innerRef}
                            $isDraggingOver={snapshot.isDraggingOver}>

                            <Button variant="contained" sx={{ lineHeight: 0 }} onClick={() => {
                                setEditData(null); handleCreateCustomField(true, null, true, "additional", false); setEditListName("additional");
                            }}>
                                <AddIcon /> Custom Field
                            </Button>
                            {showAddCustomField && <CustomFields listName={editListName} addMode={addMode} data={editData}
                                handleDrawerClose={(formData, addMode, listName, reopenModal) => handleCreateCustomField(false, formData, addMode, listName, reopenModal)} />}

                            <StyledTypography> Unused Fields </StyledTypography>
                            <Box sx={{ overflowX: "hidden", overflowY: "auto", maxHeight: "71vh" }}>
                                {list[UNUSED_FIELDS].fields.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <React.Fragment>
                                                <Item
                                                    key={`${item.id}.${index}`}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    $isDragging={snapshot.isDragging}
                                                    style={
                                                        provided.draggableProps.style
                                                    }>

                                                    <Typography component="div" sx={{ cursor: "move", pr: 2 }} {...provided.dragHandleProps} >
                                                        <DragIndicatorIcon />
                                                    </Typography>
                                                    <Typography>{item.label}</Typography>
                                                    {item.customField && <span style={{
                                                        borderRadius: "50%",
                                                        height: "8px", width: "8px", display: "inline-block", backgroundColor: `${variables.addIconColor}`
                                                    }}></span>}
                                                </Item>
                                            </React.Fragment>
                                        )}
                                    </Draggable>
                                ))}
                                {list[UNUSED_FIELDS]?.fields?.length < 1 && <div>No unused fields here</div>}
                                {provided.placeholder}
                            </Box>
                        </UnusedFields>
                    )}
                </Droppable>

                <Box sx={{ minWidth: "52vw", maxWidth: "52vw", maxHeight: props.edit ? "84vh" : "73vh", overflowX: "hidden", overflowY: "auto", pr: 0.5, pl: 2.5 }}>
                    {Object.keys(list).filter(l => l !== UNUSED_FIELDS).map((l, i) => {
                        return (
                            <Typography component="div" key={i}>
                                <StyledTypography>{list[l].name}</StyledTypography>
                                <Droppable key={l} droppableId={l}>
                                    {(provided, snapshot) => (
                                        <>
                                            <FormFields
                                                ref={provided.innerRef}
                                                $isDraggingOver={
                                                    snapshot.isDraggingOver
                                                }>
                                                {list[l].fields?.length > 0
                                                    ? list[l].fields.map(
                                                        (item, index) => (
                                                            <Draggable
                                                                key={item.id}
                                                                draggableId={item.id}
                                                                index={index}>
                                                                {(
                                                                    provided,
                                                                    snapshot
                                                                ) => (
                                                                    <Item
                                                                        key={`${item.id}.${index}`}
                                                                        $isMandatory={item?.rules?.required?.value}
                                                                        ref={
                                                                            provided.innerRef
                                                                        }
                                                                        {...provided.dragHandleProps}
                                                                        {...provided.draggableProps}
                                                                        $isDragging={
                                                                            snapshot.isDragging
                                                                        }
                                                                        style={
                                                                            provided
                                                                                .draggableProps
                                                                                .style
                                                                        }>
                                                                        <Typography component="div" sx={{ cursor: "move", pr: 1 }} {...provided.dragHandleProps} >
                                                                            {!item.freeze && <DragIndicatorIcon />}
                                                                        </Typography>
                                                                        <Typography>{item.label}</Typography>
                                                                        {item.unique && <Typography sx={{ fontSize: "smaller", pl: 1, lineHeight: 1.9, color: `${variables.greyTextColor}` }}>(Unique)</Typography>}
                                                                        {item.customField && <span style={{
                                                                            borderRadius: "50%",
                                                                            height: "8px", width: "8px", display: "inline-block", backgroundColor: `${variables.addIconColor}`
                                                                        }}></span>}
                                                                        <span style={{ marginLeft: "auto" }}>
                                                                            <Tooltip title={(item.editable || item.customField) ? "Edit" : "Non editable"}>
                                                                                <span>
                                                                                    <IconButton aria-label="edit" color="primary"
                                                                                        disabled={!(item.editable || item.customField)} size="small"
                                                                                        onClick={() => editField(l, item)}>
                                                                                        <EditOutlinedIcon fontSize="inherit"></EditOutlinedIcon>
                                                                                    </IconButton>
                                                                                </span>
                                                                            </Tooltip>
                                                                            <Tooltip title={item?.rules?.required?.value ? "Not removable when mandatory" : "Remove"}>
                                                                                <span>
                                                                                    <IconButton aria-label="remove" disabled={item?.rules?.required?.value} size="small"
                                                                                        onClick={() => removeField(l, index, item)}>
                                                                                        <RemoveCircleOutlineIcon fontSize="inherit"></RemoveCircleOutlineIcon>
                                                                                    </IconButton>
                                                                                </span>
                                                                            </Tooltip>
                                                                        </span>
                                                                    </Item>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    )
                                                    : (
                                                        <Placeholder>
                                                            Drag and Drop fields here
                                                        </Placeholder>
                                                    )}
                                            </FormFields>
                                            {provided.placeholder}
                                        </>
                                    )}
                                </Droppable >
                            </Typography>
                        );
                    })}
                    <AssociatedProducts></AssociatedProducts>
                </Box>
            </DragDropContext >
        </>
    );
}
