import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import variables from "../../../../assets/styles/_colors.scss";
import RestrictClosureTableRow from "./RestrictClosureTableRow";
import { getClosureRestrictions } from "../../../../webservices/StageService";
import { useQuery } from "@tanstack/react-query";

const columns = [
    {
        "id": "1",
        "accessor": "subPipeline",
        "label": "Sub-Pipeline",
        "width": "25%",
        sortable: false
    },
    {
        "id": "2",
        "accessor": "stages",
        "label": "Stages",
        "width": "75%",
        sortable: false
    }
];

export default function RestrictClosureTable(props) {

    useEffect(() => {
    }, []);

    const {
        data,
        isFetched
    } = useQuery({
        queryKey: ['getClosureRestrictions', props.pipelineId],
        queryFn: () => getClosureRestrictions(props.pipelineId),
        refetchOnWindowFocus: false
    });

    return (
        <Box sx={{ borderBottom: 0 }}>

            <Stack direction="row" width="100%"
                sx={{ bgcolor: `${variables.lightGreyBackgroundColor}`, borderBottom: `1px solid ${variables.borderColor}` }}>
                {
                    columns.map(column => {
                        return <Stack justifyContent="center" direction="column" height={40} width={column.width} sx={{ pl: 2, borderRight: `1px solid ${variables.borderColor}` }}>
                            {column.label}
                        </Stack>
                    })
                }
            </Stack>

            <Box sx={{ overflow: 'auto', height: "55vh", width: "100%", color: "#000" }}>
                {
                    data?.rows?.subPipelines?.map((row, index) => {
                        return <RestrictClosureTableRow pipelineId={props.pipelineId} row={row} key={index} showSnackbar={(data) => props.showSnackbar(data)} />
                    })
                }
            </Box >
        </Box>
    )
}
