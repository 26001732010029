import React, { useEffect, useState } from "react";
import "../../styles/myAccount.css";
import CloseIcon from "@material-ui/icons/Close";
import { Avatar, Divider,  Paper } from "@mui/material";

import { useRecoilState } from "recoil";
import { selectedcompany } from "./managecompanyatom";
import { api_url } from "../../globalSettings";
import axios from "axios";
import { login_token } from "../../Atoms/LoginAtoms";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { Button, Typography } from "@mui/material";
import ManageCompanyNew from "./ManageBranch/ManageBranchNew";
import DepartmentTableNew from "./Department/DepartmentTableNew";
import DesignationTableNew from "./Designation/DesignationTableNew";
import TaskTablenew from "./TaskCategory/TaskTableNew";
import ExpenseTableNew from "./ExpenseCategory/ExpenseTableNew";
import Shift from "./Shift/Shift";
import Leave from "./Leave/Leave";
import Holidays from "./Holidays/Holidays";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Grid from "@mui/material/Grid";
import{TextField
} from "@mui/material";
import { Alert, Snackbar } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function SelectedCompany() {
 


  const [Getlist, setGetlist] = useState([]);
  const [Selectedcompany, setSelectedcompany] = useRecoilState(selectedcompany);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);



  const closeBtnOnClick = () => {
    setSelectedcompany("");
  };

  const [durationInSeconds, setDurationInSeconds] = useState(0);

  const handleDurationChange = (event) => {
    const { value } = event.target;
    const parsedValue = parseInt(value, 10);
    setDurationInSeconds(parsedValue);
  };

  const convertSecondsToTimeFormat = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  };


  const [distanceInMeter, setDistanceInMeter] = useState("");

  const handleDistanceChange = (event) => {
    const { value } = event.target;
   
     setDistanceInMeter(value);
  };


 

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };


  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });


  function getBranchList() {

    const params = {
      company_id: Selectedcompany._id,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
     
      })
      .catch((err) => {

      });
  }

  useEffect(() => {
    getBranchList();
  },[])


   //  ========================    SET SOCKET   ===========================

   function setSocket() {
    const params = JSON.stringify({
      token: loginToken1,
      company_id:Selectedcompany._id,
      socket_interval_time:convertSecondsToTimeFormat(durationInSeconds)
  
    });
   

    axios
      .patch(`${api_url}/set_socket_interval_time`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
     
        setOpen(false);
        setSnackbaropen({
          open: true,
          message:response.data.message,
          severity: "success",
          actionColor: "#edf7ed",
        });
      })

      .catch(function (error) {
        if (error.response) {
          setSnackbaropen({
            open: true,
            message: error.response.data.message,
            severity: "error",
            actionColor: "#edf7ed",
          });
         
        } else if (error.request) {
        
        } else {
       
        }
       
  
      });
  }

  //  ========================    SET GEORANGE   ===========================

  function setGeorange() {
    const params = JSON.stringify({
      token: loginToken1,
      company_id:Selectedcompany._id,
      geofence_range:distanceInMeter
    });
  

    axios
      .patch(`${api_url}/set_geofence_range`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
    
        setOpen1(false);
        setSnackbaropen({
          open: true,
          message:response.data.message,
          severity: "success",
          actionColor: "#edf7ed",
        });
      })

      .catch(function (error) {
        if (error.response) {
          setSnackbaropen({
            open: true,
            message: error.response.data.message,
            severity: "error",
            actionColor: "#edf7ed",
          });
         
        } else if (error.request) {
        
        } else {
       
        }
     
      });
  }

 
  return (
    <div id="myAccount">
      <Snackbar
        open={snackbaropen.open}
        onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
        autoHideDuration={1000}
      >
        <Alert
          severity={snackbaropen.severity}
          style={{ backgroundColor: `${snackbaropen.actionColor}` }}
        >
          {snackbaropen.message}
        </Alert>
      </Snackbar>
      <div className="my-account-left" style={{ postion: "fixed" }}>
        <Paper elevation="3" style={{ height: "calc(100vh - 85px)" }}>
          <div className="my-account-close-btn" onClick={closeBtnOnClick}>
            <CloseIcon />
          </div>
          <div className="my-account-left-photo-wrapper">
            {/* <div className="my-account-close-btn" onClick={closeBtnOnClick}>
              <CloseIcon />
            </div> */}
            {/* <div className="my-account-user-photo">
              <div>
                <Avatar
                  className={classes.large}
                  alt={companyInfo.company_name}
                />
              </div>
            </div> */}

            <div className="userdraweravatar" style={{ padding: 5 }}>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                src={`https://server.nowdigitaleasy.com:3505/uploads/${Selectedcompany.logo_name}`}
                  style={{ width: 60, height: 60, backgroundColor: "#ced2df" }}
                  variant="rounded"
                ></Avatar>
              </StyledBadge>
              <div className="userdrawerinfo">
                <Typography
                  variant="body2"
                  style={{ fontWeight: 500 }}
                  gutterBottom
                >
                  {Selectedcompany.company_name.toUpperCase()}
                </Typography>
                <p style={{ margin: 0 }} gutterBottom>
                  {Selectedcompany.email}
                </p>
                <Typography variant="body1" gutterBottom>
                  {" "}
                  {/* User ID: {loginAtom1} */}
                </Typography>
                {/* <div className="drawer-signout" onClick={signOutOnClick}>
                  Sign Out
                </div> */}
              </div>
            </div>

            {/* <div className="my-account-user-name">
              <h6 style={{ marginBottom: 0 }}>
                {Selectedcompany.company_name} &nbsp; {companyInfo.company_id}
              </h6>
            </div> */}
            <div className="my-account-user-email">
              <Button className="sidebutton" onClick={handleClickOpen}>
                Set Socket
              </Button>
              <Dialog
              Width={"lg"}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{backgroundColor : "#002cff" , color:"white"}}>{"SET SOCKET"}</DialogTitle>
        <DialogContent style={{padding:"40px"}}>
          <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12} 
                        //sm={6} md={6}
                        display={"flex"}
                        justifyContent=" center"
                      >
                        <div style={{width : "100%"}}>
      <label>Enter Time Duration (seconds):</label>
      <TextField
       size="small"
        type="number"
        fullWidth
        value={durationInSeconds}
        onChange={handleDurationChange}
      />
<p>Entered Duration in Time Format: {convertSecondsToTimeFormat(durationInSeconds)}</p>    </div>
                         {/* {errors.shift_name ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.shift_name}
                          </div>
                        ) : (
                          ""
                        )} */}
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={6} display={"flex"}  
                        justifyContent=" center">
                          <div style={{width : "100%"}}>
                          <label>Select Branch</label>
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={Getlist}
                           onChange={handleChange1}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}

                              // {...register("Getlist", {
                              //   required: "required",
                              // })}
                              // error={errors.sub_category}
                              // helperText={errors.sub_category?.message}
                              label="Select Branch*"
                            />
                          )}
                        />
                        </div>
                      </Grid> */}
                      </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{backgroundColor :"#002cff" , color:"white"}}>CANCEL</Button>
          <Button onClick={setSocket} style={{backgroundColor :"#002cff" , color:"white"}}>SET SOCKET</Button>
        </DialogActions>
      </Dialog>
              <Button className="sidebutton" 
              onClick={handleClickOpen1}
              >
                Geo Range
              </Button>
              <Dialog
              Width={"lg"}
        open={open1}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose1}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{backgroundColor : "#002cff" , color:"white"}}>{"SET GEORANGE"}</DialogTitle>
        <DialogContent style={{padding:"40px"}}>
          <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12} 
                        //sm={6} md={6}
                        display={"flex"}
                        justifyContent=" center"
                      >
                      <div style={{width : "100%"}}>
      <label>Enter radius Distance (meter):</label>
      <TextField
       size="small"
        type="number"
        fullWidth
        value={distanceInMeter}
        onChange={handleDistanceChange}
      />
{/* <p>Entered Duration in Time Format: {convertSecondsToTimeFormat(durationInSeconds)}</p>   */}
  </div>
                         {/* {errors.shift_name ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.shift_name}
                          </div>
                        ) : (
                          ""
                        )} */}
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={6} display={"flex"}  
                        justifyContent=" center">
                          <div style={{width : "100%"}}>
                          <label>Select Branch</label>
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={Getlist}
                           onChange={handleChange2}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}

                              // {...register("Getlist", {
                              //   required: "required",
                              // })}
                              // error={errors.sub_category}
                              // helperText={errors.sub_category?.message}
                              label="Select Branch*"
                            />
                          )}
                        />
                        </div>
                      </Grid> */}
                      </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1} style={{backgroundColor :"#002cff" , color:"white"}}>CANCEL</Button>
          <Button onClick={setGeorange} style={{backgroundColor :"#002cff" , color:"white"}}>SET GERORANGE</Button>
        </DialogActions>
      </Dialog>
            </div>
          </div>
          <Divider />
          {/* <div
            className="my-account-left-info-wrapper"
            style={{ height: "calc(100vh - 240px)", overflow: "auto" }}
          >
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">COMPANY NAME </div>
              <div className="my-account-left-info-value">
                 {Selectedcompany.company_name.toUpperCase()}
              </div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">CONTACT PERSON</div>
              <div className="my-account-left-info-value">
               {" "}
                {lengthCheck(Selectedcompany.contact_person.toUpperCase())}
              </div>
            </div>

            <div className="my-account-left-info">
              <div className="my-account-left-info-key">PHONE NUMBER</div>
              <div className="my-account-left-info-value">
                 {lengthCheck(Selectedcompany.phone_number.toUpperCase())}
              </div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">EMAIL</div>
              <div className="my-account-left-info-value">
                 {Selectedcompany.email}
              </div>
            </div>
            
          </div> */}

          {/* <div className="my-account-left-info">
              <div className="my-account-left-info-key">GST NO :</div>
              <div className="my-account-left-info-value">
                &nbsp; {Selectedcompany.gst_no}
              </div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">CITY</div>
              <div className="my-account-left-info-value">{Getlist.city}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">STATE</div>
              <div className="my-account-left-info-value">
                {lengthCheck(companyInfo.state)}
              </div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">COUNTRY</div>
              <div className="my-account-left-info-value">
                {lengthCheck(companyInfo.country)}
              </div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">ZIPCODE</div>
              <div className="my-account-left-info-value">
                {lengthCheck(companyInfo.zip_code)}
              </div>
            </div> */}
        </Paper>
      </div>
      <div className="my-account-right" style={{ margin: 0 }}>
        <div style={{ flexBasis: 300, margin: "5px 10px" }}>
          <ManageCompanyNew />
        </div>
        <div style={{ flexBasis: 300, margin: "5px 10px" }}>
          <Shift />
        </div>
        <div style={{ flexBasis: 300, margin: "5px 10px" }}>
          <Leave />
        </div>
        <div style={{ flexBasis: 300, margin: "5px 10px" }}>
          <Holidays />
        </div>
        <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          <DepartmentTableNew />
        </div>
        <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          {/* <DesignationTable /> */}
          <DesignationTableNew />
        </div>
        <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          {/* <TaskTable /> */}
          <TaskTablenew />
        </div>
        <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          {/* <ExpenseTable /> */}
          <ExpenseTableNew />
        </div>
      </div>
    </div>
  );
}
