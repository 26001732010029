import React from 'react'
import "./users.css";
import { getCoreRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table'
import { useInfiniteQuery, useQueryClient, } from '@tanstack/react-query'
import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import variables from "../../../../assets/styles/_colors.scss";
import { CrmTableHeader } from '../../../../components/table/CrmTableHeader'
import UserTableRow from './UserTableRow'
import IndeterminateCheckbox from '../../../../components/IndeterminateCheckbox';
import { CrmTableFooter } from '../../../../components/table/CrmTableFooter';
import { useReducer } from 'react';
import { getUsersTableData } from '../../../../webservices/UserControlService';

const fetchSize = 10;

const UserTable = (props) => {

    const queryClient = useQueryClient();
    const rerender = useReducer(() => ({}), {})[1];
    const tableContainerRef = useRef(null);
    const [sorting, setSorting] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = useState({});
    const [currentTab, setCurrentTab] = useState("active");

    useEffect(() => {
        props.rowSelection(table.getSelectedRowModel()?.flatRows);
    }, [rowSelection]);

    useEffect(() => {
        setCurrentTab(props.selectedTab);
        setColumnVisibility({
            "select": props.selectedTab === "deleted" ? false : true,
            "firstName": true,
            "email": true,
            "role": true,
            "profile": true
        });
        rerender();
        queryClient.invalidateQueries(['getAllUsers']);
    }, [props.selectedTab]);

    useEffect(() => {
        props.resetSelection && resetBulkSelection();
    }, [props.resetSelection]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "select",
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler()
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                    />
                ),
                size: 72,
                enableSorting: false,
                enableHiding: true
            },
            {
                "id": "1",
                "accessorKey": "firstName",
                "header": "Full Name",
            },
            {
                "id": "2",
                "accessorKey": "email",
                "header": "Email",
                "selected": true
            },
            {
                "id": "3",
                "accessorKey": "role.roleName",
                "header": "Role",
                "selected": true
            },
            {
                "id": "4",
                "accessorKey": "profile.profileName",
                "header": "Profile",
                "selected": true
            }],
        []
    )

    const resetBulkSelection = () => {
        table.resetRowSelection();
    };

    const { data, fetchNextPage, isFetching } =
        useInfiniteQuery({
            queryKey: [`getAllUsers`, currentTab],
            queryFn: async ({ pageParam = 0 }) => {
                const start = pageParam * fetchSize
                return getUsersTableData(currentTab, start, fetchSize, sorting);
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            placeholderData: {},
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages?.flatMap(page => page.rows) ?? [],
        [data]
    )
    const meta = data?.pages?.[0]?.meta ?? 0
    const totalFetched = flatData.length;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement
                if (
                    scrollHeight - scrollTop - clientHeight < 100 &&
                    !isFetching &&
                    totalFetched < meta.totalRowCount
                ) {
                    fetchNextPage()
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, meta.totalRowCount]
    )

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached])

    const table = useReactTable({
        data: flatData,
        columns: columns,
        state: {
            sorting,
            rowSelection,
            columnVisibility
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onRowSelectionChange: setRowSelection,
        onColumnVisibilityChange: setColumnVisibility,
        // debugTable: true,
    })

    return (
        <Box sx={{
            overflow: 'hidden', width: "100%", color: "#000",
            bgcolor: `${variables.tabsBackgroundColor}`
        }}>
            <Box
                sx={{ overflow: 'auto', height: "71.5vh", width: "100%", color: "#000" }}
                onScroll={e => fetchMoreOnBottomReached(e.target)}
                ref={tableContainerRef}
            >
                <table id="userTable">
                    <thead>
                        <CrmTableHeader table={table} />
                    </thead>
                    <tbody>
                        {
                            !isFetching && table.getRowModel().rows?.length > 0 && table.getRowModel().rows.map((rowEl) => {
                                return (
                                    <UserTableRow
                                        key={rowEl.original?.id}
                                        rowEl={rowEl}
                                        selectedTab={props.selectedTab}
                                        handleUserViewClick={(userId) => { props.handleUserViewClick(userId) }}
                                        showSnackbar={(data) => props.showSnackbar(data)} />
                                )
                            })}
                    </tbody>
                </table>
                {
                    isFetching &&
                    <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="50px">
                        <Typography>Loading ...</Typography>
                    </Stack>
                }
                {
                    !isFetching && table.getRowModel().rows?.length === 0 &&
                    <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="50px">
                        <Typography>No data found</Typography>
                    </Stack>
                }
            </Box>
            <Stack direction="row" justifyContent="center" sx={{
                maxHeight: 70, position: "absolute", bottom: 2, width: '80vw',
                padding: "12px 20px", background: `${variables.lightGreyBackgroundColor}`
            }}>

                <CrmTableFooter table={table} meta={meta} data={flatData} />

            </Stack>
        </Box >
    )
}

export default UserTable;