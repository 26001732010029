import axios from "axios";

const deleteWebsite = async (websiteId) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/website/delete/${websiteId}`
    );

    if (response.status === 200) {
      const responseData = response.data;
      console.log("Delete Response:", responseData);
      return { ok: true, responseData };
    } else {
      console.error("Delete failed:", response.data);
      throw new Error(`Error during delete: ${JSON.stringify(response.data)}`);
    }
  } catch (error) {
    console.error("Error during delete:", error);
    throw new Error(`Error during delete: ${error.message}`);
  }
};

export default deleteWebsite;
