import { Suspense } from 'react';
import BounceLoader from '../components/webservices/Component/BounceLoader';


// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
    (
        <Suspense fallback={<BounceLoader />}>
            <Component {...props} />
        </Suspense>
    );

export default Loadable;