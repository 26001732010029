import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Style from "../Style/LogInPage.module.css";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
const useStyles = makeStyles({
  customInputRoot: {
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
      borderRadius: "2px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px white inset",
    },
  },
});
const CustomButtonContainer = ({
  title,
  name,
  value,
  error,
  helperText,
  onChangeFuncion,
  isPassword,
  handleVisiblility,
  handleMouseDownPassword,
  showPassword,
  isMargin,
  fieldType,
}) => {
  const classes = useStyles();
  console.log("====================================");
  console.log("loger", fieldType);
  console.log("====================================");
  return (
    <div style={{ marginTop: isMargin ? "8%" : "" }}>
      <p className={Style.fieldTitle}>{title}</p>
      <TextField
        name={name}
        value={value}
        error={error}
        type={fieldType ? fieldType : showPassword ? "text" : "password"}
        helperText={helperText}
        onChange={onChangeFuncion}
        fullWidth
        className={classes.customInputRoot}
        InputProps={{
          classes: { input: classes.input },
          endAdornment: isPassword ? (
            <InputAdornment position="end" className={classes.inputAdornment}>
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleVisiblility}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </div>
  );
};

export default CustomButtonContainer;
