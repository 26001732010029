import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useNameConversationList from "../components/useNameConversationList";
import _ from "lodash";
import { Box, Typography } from "@mui/material";
import UserAvatar from "./components/UserAvatar";
import { formatTimestamp } from "../components/helper";
import { useQueryClient } from "@tanstack/react-query";
import noMsg from "../../../images/visitor.jpg";

const VisitorList = () => {
  const queryClient = useQueryClient();
  const { websiteId } = useParams();
  const { data, isLoading, isError } = useNameConversationList(websiteId);
  const [openConversationId, setOpenConversationId] = useState(null);
  const [senderWiseConversation, setSenderWiseConversation] = useState([]);

  useEffect(() => {
    if (data) {
      const visitorsOnly = data.filter(i=> i.role === "visitor")

      const grouped = _.groupBy(visitorsOnly, "sender");
      const entries = Object.entries(grouped);
      const senderWiseConv = entries.map((entry) => ({
        id: entry[0],
        name: entry[1][0].name,
        conversation: entry[1],
      }));
      setSenderWiseConversation(senderWiseConv);
    }
  }, [data]);
  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError || (data && data.length === 0)) {
    return (
      <Box
        sx={{
          marginRight: "0px",
          width: "299px",
          height:"calc(100vh - 100px)",
          paddingLeft: "166px",
          backgroundColor: "white",
          border: "1px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={noMsg}
          alt="nomsg  404"
          style={{
            height: "181px",
            position: "absolute",
            left: "50%",
            top: "40%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Box style={{ marginTop: "209px", marginLeft: "-176px" }}>
          <Typography
            style={{
              fontSize: "12px",
              fontFamily: "Inter",
              color: "#1c293b",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            No message.
          </Typography>
        </Box>
      </Box>
    );
  }
  queryClient.invalidateQueries(["NameConversation"]);

  const handleNameClick = (id) => {
    setOpenConversationId((prevId) => (prevId === id ? null : id));
  };

  return (
    
    <div style={{ height: "calc(100vh - 100px)" }}>
      {senderWiseConversation.map((senderConv) => (
        <Box key={senderConv.id}>
          <Box
            onClick={() => handleNameClick(senderConv.id)}
            sx={{
              cursor:"pointer",
              display: "flex",
              alignItems: "center",
              position: "relative",
              borderTop: "1px solid #C0C0C0",
              p: 0,
              height: "85px",
              backgroundColor: "#fff",
              boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#e0e0e0",
              },
            }}
          >
            <UserAvatar>
              <div
                style={{
                  width: "9px",
                  height: "9px",
                  borderRadius: "50%",
                  position: "absolute",
                  top: "29px",
                  left: "33px",
                  zIndex: 3,
                }}
              />
            </UserAvatar>
            {senderConv.name}
          </Box>
          {openConversationId === senderConv.id && (
            <Box>
              {_.uniqBy(senderConv.conversation , "conversation_Id").map(
                (conversation) => (
                  <Link
                    to={`/chat/inbox/${websiteId}/chat?conversation=${conversation.conversation_Id}&sender=${conversation.sender}`}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        borderTop: "1px solid #C0C0C0",
                        p: 0,
                        height: "55px",
                        backgroundColor: "#e8f4f8",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                        },
                      }}
                      key={conversation.conversation_Id}
                    >
                      <Typography
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          fontSize: "16px",
                          paddingLeft: "25px",
                          fontWeight: 400,
                          color: "black",
                          fontFamily: "Roboto",
                          maxWidth: "25ch",
                        }}
                      >
                        {conversation.message}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "rgb(102, 102, 102)",
                          display: "flex",
                          whiteSpace: "break-spaces",
                          fontFamily: "Roboto",
                          position: "absolute",
                          right: 0,
                        }}
                      >
                        {formatTimestamp(conversation.timestamps)}
                      </Typography>
                    </Box>
                  </Link>
                )
              )}
            </Box>
          )}
        </Box>
      ))}
    </div>
  );
};

export default VisitorList;
