import React from "react";
import { Route, Routes } from "react-router-dom";
import ContactContent from "./Panel/ContactContent";
import EditContent from "./Panel/EditContent";
import Asset from "./Panel/overview/Asset";
import Benefit from "./Panel/overview/Benefit";
import ExitDetails from "./Panel/overview/ExitDetails";
import TExpense from "./Panel/overview/TExpense";
import TRequest from "./Panel/overview/TRequest";

const PanelContent = () => {
  return (
    <Routes>
      <Route path="/edit/*" element={<EditContent />} />
      <Route path="/asset/*" element={<Asset />} />
      <Route path="/benefit/*" element={<Benefit />} />
      <Route path="/exit-details/*" element={<ExitDetails />} />
      <Route path="/travel-expense/*" element={<TExpense />} />
      <Route path="/travel-request/*" element={<TRequest />} />
      <Route path="*" element={<ContactContent />} />
    </Routes>
  );
};

export default PanelContent;
