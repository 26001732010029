import { combineReducers } from "redux";
import login from "./login-reducer";
import common from "./common-reducer";
import manageCompany from "./manage-company-reducer";
import manageEmployee from "./manage-employee-reducer";
import home from "./home-reducer";

const rootReducer = combineReducers({
  login,
  common,
  home,
  manageCompany,
  manageEmployee,
});

export default rootReducer;
