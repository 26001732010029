import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Checkbox, CircularProgress } from "@mui/material";
import AvatarStatus from "../../components/Avatar";
import Styles from "../../MainMenu/ChatLayout.module.scss";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import VisitorHead from "./VisitorHead";
import { useQuery } from "@tanstack/react-query";

const fetcher = () => {
  return axios
    .get("https://662630ce052332d55321f8bc.mockapi.io/chatnow")
    .then(({ data }) => data);
};
const VisitorBody = () => {
  const [selected, setSelected] = React.useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");

  const { data: visitordata, isLoading } = useQuery({
    queryKey: ["visitordata"],
    queryFn: fetcher,
  });

  const handleRoute = (id) => {
    navigate(`/chat/visitors/${id}`);
  };

  const handleCheckboxChange = (id) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }));
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "60%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const filterData = (visitordata) => {
    if (!searchQuery) return visitordata;

    return visitordata.filter((visitor) => {
      for (const key in visitor) {
        if (Object.hasOwnProperty.call(visitor, key)) {
          const value = visitor[key];
          if (
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    });
  };
  const filteredData = filterData(visitordata);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box sx={{ display: "block", width: "100%" }}>
      <VisitorHead
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
      />
      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <TableContainer sx={{ maxHeight: "92%" }}>
          <Table
            size="small"
            sx={{ minWidth: 300, overflowY: "auto" }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead
              sx={{ borderBottom: "1px solid #E2E3E6", height: "54px" }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                ></TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Visitors
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Location
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Creation Time
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                >
                  Last Conversation
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    backgroundColor: "var(--light-bg-img-color)",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: "pointer" }}>
              {filteredData &&
                filteredData.map((value) => (
                  <TableRow
                    className={`${Styles.ChatCheckbox} ${
                      location.pathname === `${value.id}`
                        ? Styles.ChatActiveselect
                        : ""
                    }`}
                    key={value.id}
                    sx={{
                      "&:hover": {
                        backgroundColor: "var(--light-bg-img-color)",
                      },
                    }}
                  >
                    <TableCell
                      sx={{ paddingLeft: "10px", paddingRight: "0px" }}
                    >
                      <Checkbox
                        className={
                          selected[value.id]
                            ? `${Styles.ChatCheckbox}`
                            : Styles.CNCheckbox
                        }
                        checked={selected[value.id] || false}
                        onChange={() => handleCheckboxChange(value.id)}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        paddingLeft: "8px",
                        display: "flex",
                        paddingTop: "10px",
                      }}
                      onClick={() => handleRoute(value.id)}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <AvatarStatus
                          src={value.avatar}
                          alt="User Avatar"
                          status={value.status}
                        />
                        <span style={{ marginLeft: "10px" }}>
                          {value.Visitors}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell onClick={() => handleRoute(value.id)}>
                      <div>{value.location}</div>
                      <div
                        style={{
                          color: "var(--light-text-color)",
                          paddingTop: "3px",
                        }}
                      >
                        <span>Chrome</span>
                        <span> . </span>
                        <span>Windows</span>
                        <span> . </span>
                        <span>Desktop</span>
                      </div>
                    </TableCell>
                    <TableCell onClick={() => handleRoute(value.id)}>
                      {value.creationtime}
                    </TableCell>

                    <TableCell onClick={() => handleRoute(value.id)}>
                      {value.conversation}
                    </TableCell>
                    <TableCell onClick={() => handleRoute(value.id)}>
                      <PlayCircleOutlinedIcon
                        sx={{ color: "var(--button-color)" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default VisitorBody;
