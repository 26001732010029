import { Stack, Typography } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

const options = [
    {
        label: "Is",
        value: "="
    }
];

const booleanOptions = [
    {
        label: "True",
        value: true
    },
    {
        label: "False",
        value: false
    }
];

const CheckboxType = (props) => {

    const { setValue } = useFormContext();

    useEffect(() => {
        setValue(`${props.name}.comparator`, options[0].value);
        setValue(`${props.name}.value`, booleanOptions[0].value);
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>
            <Stack direction="row" sx={{ width: '100%', pt: 1 }} spacing={1}>
                <Stack direction="row" sx={{ width: '50%' }}>
                    <CustomSelect size="small" options={options} name={`${props.name}.comparator`} defaultValue={options[0].value} />
                </Stack>
                <Stack direction="row" sx={{ width: '50%' }}>
                    <CustomSelect size="small" options={booleanOptions} name={`${props.name}.value`} defaultValue={booleanOptions[0].value} />
                </Stack>
            </Stack>
        </>
    )
};

export default CheckboxType;