
import React, { useEffect, useState, useRef } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import TaskItem from './PipelineBoardColumnListItem';
import { getGivenStageData } from '../../../../webservices/PipelineService';
import { DropZone, ListWrapper, ScrollContainer } from './PipelineBoardStyles';
import { useIntersection } from "@mantine/hooks";
import { useInfiniteQuery } from '@tanstack/react-query'
import { Stack, Typography } from '@mui/material';
import variables from "../../../../assets/styles/_colors.scss";

const TaskList = React.memo(function TaskList(props) {

  const limit = 10;
  const title = props.title;
  const [total, setTotal] = useState(0);

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ["tasks", limit, title],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getGivenStageData({ limit, pageParam, title });
      setTotal(response.total);
      return response.data;
    },
    getNextPageParam: (_, pages) => pages.length + 1,
    refetchOnWindowFocus: false
  });

  const lastPostRef = useRef < HTMLElement > ([]);
  const { ref, entry } = useIntersection({
    root: lastPostRef.current,
    threshold: 1,
  });

  useEffect(() => {
    if (entry?.isIntersecting) fetchNextPage();
    // eslint-disable-next-line
  }, [entry]);

  const tasks = data?.pages.flatMap((page) => page);
  props.getTasksLength(tasks?.length);

  return <>{tasks && tasks.length > 0 && tasks.map((task, index) => {
    const itemProps = tasks.length < total ? { reff: ref } : {};
    return task && <Draggable key={task._id} draggableId={task._id} index={index} isDragDisabled={(props.disableBoard || props.selectedItems.length > 0) ? true : false}>
      {(dragProvided, dragSnapshot) => (
        <TaskItem
          key={task._id}
          task={task}
          index={index}
          $isDragging={dragSnapshot.isDragging}
          provided={dragProvided}
          stage={props.stage}
          selectedItems={props.selectedItems}
          handleCheckboxSelection={props.handleCheckboxSelection}
          {...itemProps}
        />
      )}
    </Draggable>
  })}
    {
      (!tasks || (tasks?.length === 0)) &&
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ minHeight: "calc(100vh - 285px)" }}
      >
        <Typography sx={{ color: `${variables.emptyStage}`, verticalAlign: 'middle' }} > This stage is empty </Typography>
      </Stack>
    }
  </>
});

export default function ColumnContent(props) {
  const {
    listId = 'LIST',
    listType,
    title,
    selectedItems,
    disableBoard,
    stage
  } = props;

  useEffect(() => {

  }, [props.handleCheckboxSelection]);

  return (
    <Droppable
      droppableId={listId}
      type={listType}
    >
      {(dropProvided, dropSnapshot) => (
        <ListWrapper
          $isDraggingOver={dropSnapshot.isDraggingOver}
          $isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          <ScrollContainer>
            <DropZone ref={dropProvided.innerRef}>
              <TaskList
                title={title}
                stage={stage}
                getTasksLength={props.getTasksLength}
                selectedItems={selectedItems}
                disableBoard={disableBoard}
                handleCheckboxSelection={props.handleCheckboxSelection} />
              {dropProvided.placeholder}
            </DropZone>
          </ScrollContainer>
        </ListWrapper>
      )}
    </Droppable>
  );
}
