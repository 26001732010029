import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import variables from "../../../../assets/styles/_colors.scss";
import { useState } from "react";
import { flexRender } from "@tanstack/react-table";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UpsertTask from "../../create/UpsertTask";
import DeleteActivity from "../../DeleteActivity";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import * as React from 'react';
import Popover from '@mui/material/Popover';
import { Avatar, Divider, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActivityQuickPreview from "./ActivityQuickPreview";
import ActivityDetailedView from "./ActivityDetailedView";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import { useEffect } from "react";
import ContactQuickPreview from "../../../contacts/view/ContactQuickPreview";
import CompanyQuickPreview from "../../../companies/view/CompanyQuickPreview";
import DealQuickPreview from "../../../pipelines/view/DealQuickPreview";

const StyledLink = styled(Link)`
    display: block;
    color: #212129;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export default function ActivityTableRow(props) {

    const rowEl = props.rowEl;
    const [isHovering, setIsHovering] = useState(false);
    const [editActivity, setEditActivity] = useState(false);
    const [deleteActivity, setDeleteActivity] = useState(false);
    const [showQuickView, setShowQuickView] = useState(false);
    const [showRelatedToQuickView, setShowRelatedToQuickView] = useState(null);
    const [showDetailedView, setShowDetailedView] = useState(false);
    const [relatedToLink, setRelatedToLink] = useState(null);

    useEffect(() => {
        if (props.rowEl) {
            setRelatedToLink(rowEl.original.relatedToModule === "contact" ? "/crm/contacts/1" :
                rowEl.original.relatedToModule === "company" ? "/crm/companies/1" : "/crm/pipeline/table/1/1/1");
        }
    }, [props.rowEl]);

    const handleRelatedToQuickView = () => {
        setShowRelatedToQuickView(rowEl.original.relatedToModule);
    };

    return (
        <tr key={rowEl?.original?.id} id={rowEl?.original?.id} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
            style={{ position: "relative", backgroundColor: isHovering ? `${variables.tableBackgroundColor}` : '#fff' }}
        >
            {rowEl.getVisibleCells().map((cellEl) => {
                return (
                    <td key={cellEl.id}>
                        {((props.module === "task" && cellEl.column.columnDef.accessorKey === "taskName") ||
                            (props.module === "event" && cellEl.column.columnDef.accessorKey === "title") ||
                            (props.module === "call" && cellEl.column.columnDef.accessorKey === "toFrom"))
                            ? <>
                                <StyledLink to={`/crm/activities/${props.module}/${rowEl?.original?.id}`} state={{
                                    module: props.module,
                                    item: rowEl?.original
                                }}
                                    style={{ color: isHovering ? `${variables.linkColor}` : "#000" }} >
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                    <Typography component="span" sx={{ visibility: isHovering ? 'inherit' : 'hidden', pl: 1.5 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowQuickView(true) }}>
                                        <Tooltip title="Quick Preview" placement="right">
                                            <IconButton sx={{ p: 0 }}>
                                                <VisibilityOutlinedIcon fontSize="small" color={variables.greyTextColor} />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </StyledLink>
                            </>
                            : (cellEl.column.columnDef.accessorKey === "relatedTo")
                                ? rowEl.original.relatedTo && <>
                                    <StyledLink to={relatedToLink} state={{
                                        module: props.module,
                                        item: null
                                    }}
                                        style={{ color: isHovering ? `${variables.linkColor}` : "#000" }} >
                                        {"hola" && flexRender(
                                            cellEl.column.columnDef.cell,
                                            cellEl.getContext()
                                        )}
                                        {rowEl.original.relatedTo && <Typography component="span" sx={{ visibility: isHovering ? 'inherit' : 'hidden', pl: 1.5 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleRelatedToQuickView(); }}>
                                            <Tooltip title="Quick Preview" placement="right">
                                                <IconButton sx={{ p: 0 }}>
                                                    <VisibilityOutlinedIcon fontSize="small" color={variables.greyTextColor} />
                                                </IconButton>
                                            </Tooltip>
                                        </Typography>}
                                    </StyledLink>
                                </>
                                : <>
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                </>
                        }
                    </td>
                );
            })}
            <Stack direction="row" spacing={1} alignItems="center" sx={{
                visibility: isHovering ? 'inherit' : 'hidden',
                height: "47px",
                zIndex: 5,
                width: "85px",
                bgcolor: `${variables.lightGreyBackgroundColor}`,
                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                position: 'sticky', right: 0.5,
                pl: 0.5
            }}>
                <IconButton onClick={() => setEditActivity(true)} sx={{ height: "35px", width: "35px" }}>
                    <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                </IconButton>

                {props.module === "event" ?
                    <IconButton onClick={() => setDeleteActivity(true)} sx={{ height: "35px", width: "35px" }}>
                        <DeleteOutlineIcon fontSize='medium' color="error" />
                    </IconButton>
                    :
                    <TablePopover item={rowEl?.original} module={props.module} name={rowEl?.original?.taskName}
                        handleClose={() => { setIsHovering(false); }} />
                }
            </Stack>

            {
                showQuickView &&
                <ActivityQuickPreview module={props.module} handleDrawerClose={() => { setShowQuickView(false); setIsHovering(false); }} item={rowEl?.original} />
            }

            {
                deleteActivity &&
                <DeleteActivity item={rowEl?.original} module={props.module} handleClose={() => { setDeleteActivity(false); setIsHovering(false); }} />
            }

            {
                editActivity &&
                <UpsertTask edit={true} module={props.module} handleDrawerClose={() => { setEditActivity(false); setIsHovering(false); }} />
            }

            {
                showDetailedView &&
                <ActivityDetailedView module={props.module} item={rowEl?.original} handleClose={(val) => { setShowDetailedView(false); setIsHovering(false); }} />
            }

            {
                showRelatedToQuickView && showRelatedToQuickView === "contact" &&
                <ContactQuickPreview module={"contact"} handleDrawerClose={() => { setShowRelatedToQuickView(null); setIsHovering(false); }} item={null} />
            }

            {
                showRelatedToQuickView && showRelatedToQuickView === "company" &&
                <CompanyQuickPreview module={"company"} handleDrawerClose={() => { setShowRelatedToQuickView(null); setIsHovering(false); }} item={null} />
            }

            {
                showRelatedToQuickView && showRelatedToQuickView === "pipeline" &&
                <DealQuickPreview module={"deal"} handleDrawerClose={() => { setShowRelatedToQuickView(null); setIsHovering(false); }} item={null} />
            }

        </tr >
    )
}


const useStyles = makeStyles((theme) => ({
    tableRow: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

function TablePopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editActivity, setEditActivity] = useState(false);
    const [deleteActivity, setDeleteActivity] = useState(false);

    const rowActions = [
        {
            name: "Mark as completed",
            icon: <CheckCircleOutlineIcon />,
            key: 'markAsCompleted'
        },
        props.module === "call" && {
            name: "Reschedule Call",
            icon: <AccessTimeIcon />,
            key: 'rescheduleCall'
        },
        props.module === "call" && {
            name: "Cancel Call",
            icon: <PhoneDisabledIcon />,
            key: 'cancelCall'
        },
        {
            name: "Delete",
            icon: <DeleteOutlineIcon />,
            key: "delete"
        }
    ];

    const handleClose = () => {
        setAnchorEl(null);
        props.handleClose();
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "markAsCompleted":
                    return true;
                case "rescheduleCall":
                    setEditActivity(true);
                    return true;
                case "delete":
                    setDeleteActivity(true);
                    return true;
                default:
                    return <></>
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'sub-pipeline-popover' : undefined;

    return (
        <span style={{ 'display': 'inline-block' }}>
            <IconButton aria-label="pipeline-popover" onClick={handleClick} sx={{ height: "35px" }} >
                <MoreVertIcon />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.pipeline }}
            >
                {rowActions.map((item, index) => (
                    item &&
                    <span key={`pipeline-popover-item-${index}`}>
                        <ListItemButton
                            key={`${item.name}-${index}`}
                            sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemAvatar sx={{ minWidth: 46 }}>
                                <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                                    {item.icon}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </span>
                ))}
            </Popover>

            {editActivity &&
                <UpsertTask edit={true} module={props.module} handleDrawerClose={() => { setEditActivity(false) }} />}

            {deleteActivity &&
                <DeleteActivity item={props.item} module={props.module} handleClose={() => { setDeleteActivity(false); }} />}
        </span>
    );
}