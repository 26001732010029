import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Button, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./AddContactDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@mui/material/Grid";
import PhoneInput from "react-phone-input-2";
import NativeSelect from "@mui/material/NativeSelect";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { api_url } from "../../globalSettings";
import axios from "axios";
import { login_companyid} from "../../Atoms/LoginAtoms";
import InputBase from "@mui/material/InputBase";
import { useRecoilState } from "recoil";
import InputLabel from "@mui/material/InputLabel";
import { ReactCountryDropdown } from "react-country-dropdown";
import "react-country-dropdown/dist/index.css";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    color: "#000",
    fontWeight: "500",
    height: "28px",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function AddContactDrawer(props) {
  const [selectedValue, setSelectedValue] = React.useState({
    id: "",
    option: "",
  });

  const handleChangeRatio = (value) => (event) => {
    setSelectedValue({ id: value, option: event.target.value });
  };

  // ============================  select Labels  ==============================
  const [personName, setPersonName] = React.useState([]);

  const handleChange9 = (event) => {
    const {
      target: { value },
    } = event;
    setlabel1(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);
  const [contactData, setContactData] = React.useState([]);
  const [contactType1, setContactType1] = React.useState([]);
  const [status1, setstatus1] = React.useState([]);
  const [followup1, setfollowup1] = React.useState([]);
  const [label1, setlabel1] = React.useState([]);
  const [source, setSource] = React.useState("");
  const [country, setCountry] = useState("India");
  const [phone, setPhone] = useState();
  const [checked, setChecked] = useState([]);
  const [checkedValue, setCheckedValue] = useState({ id: "", option: "" });
  //================================== CONTACT LIST APIII ================================

  const [contactList, setContactList] = React.useState([]);
  const [contactType, setContactType] = React.useState("");
  const [statusList, setStatusList] = React.useState("");
  const [StatusesType, setStatusesType] = React.useState("");
  const [enable, setEnable] = React.useState(false);


  // ================= Get StatusList ===========================

  function getstatuslist() {
    axios
      .get(`${api_url}/crm_setting/getStatus/${company_idd}`) //63eb2395bcdeb8a6aa878559 ${company_idd}
      .then((responce) => {
        setStatusList(
          responce.data.data.map((item, key) => ({
            label: item.status_name,
            _id: item._id,
          }))
        );
        setstatus1(responce.data.data[0]._id);
      })
      .catch((err) => {
      });
  }

  // ================= Get Contact List ===========================

  function getcontactlist() {
    axios
      .get(`${api_url}/crm_setting/getContactTypes/${company_idd}`) //63eb2395bcdeb8a6aa878559 ${company_idd}
      .then((responce) => {
        setContactList(
          responce.data.data[0].contact_type.map((item, key) => ({
            label: item.name,
            _id: item._id,
          }))
        );
        setContactType1(responce.data.data[0].contact_type[0]._id);
      })
      .catch((err) => {
      });
  }

  // ============================ GET FOLLOW UP TYPE =========================
  const [getFollowUplist, setGetFollowUplist] = React.useState("");
  const [getFollowUpTyle, setGetFollowUpType] = React.useState("");

  function getFollowUpType() {
    axios
      .get(`${api_url}/crm_setting/getGeneralSetting/${company_idd}`) //63eb2395bcdeb8a6aa878559 ${company_idd}
      .then((responce) => {
        setGetFollowUplist(
          responce.data.data[0].follow_ups.add_new_followup_type.map(
            (item, key) => ({
              label: item.name,
              color: item.color,
              _id: item._id,
            })
          )
        );
        setfollowup1(
          responce.data.data[0].follow_ups.add_new_followup_type[0]._id
        );

      })
      .catch((err) => {
      });
  }

  // ============================ GET LABEL TYPE =========================

  const [getlabellist, setGetlabellist] = React.useState("");
  const [getlabelType, setGetlabelType] = React.useState([]);


  function getlabeltype() {
    axios
      .get(`${api_url}/crm_setting/getGeneralSetting/${company_idd}`) //63eb2395bcdeb8a6aa878559 ${company_idd}
      .then((responce) => {

        setGetlabellist(
          responce.data.data[0].labels.map((item, key) => ({
            label: item.name,
            _id: item._id,
          }))
        );

      })
      .catch(function (error) {
        if (error.response) {
          
        } else if (error.request) {
        
        } else {
        
        }
      });
  }

  // ============================ GET Source TYPE =========================

  const [getSourcellist, setGetSourcelist] = React.useState([]);

  function getSourcetype() {
    axios
      .get(`${api_url}/crm_setting/getSourceByCompany/${company_idd}`) //63eb2395bcdeb8a6aa878559 ${company_idd}
      .then((responce) => {
       

        setGetSourcelist(
          responce.data.data.map((item, key) => ({
            label: item.source_name,
            _id: item._id,
          }))
        );

        setSource(responce.data.data[0]._id);
      })
      .catch(function (error) {
        if (error.response) {
       
       
        } else if (error.request) {
         
        } else {
        
        }
      });
  }

  // ====================== GET GROUP LIST =========================

  const [groupList, setGroupList] = React.useState();


  function getgroutlist() {
    axios
      .get(`${api_url}/crm_setting/getFields/${company_idd}`) //63eb2395bcdeb8a6aa878559 ${company_idd}
      .then((responce) => {
        setGroupList(responce.data.data);
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    getcontactlist();
    getFollowUpType();
    getstatuslist();
    getlabeltype();
    getSourcetype();
    getgroutlist();
  }, [company_idd]);



  const handleChangeSelect = (event) => {
    setContactType(event.target.value);
  };

 

  //  ====================================  CREATE CONATCT  ================================

  function handleChange(e) {
    const { value, name } = e.target;
    setContactData({ ...contactData, [name]: value });
  }

  function groupChange(e) {
    const { value, name } = e.target;

    for (let i = 0; i < groupList.length; i++) {
      const fieldsArray = groupList[i].Fields;
      for (let j = 0; j < fieldsArray.length; j++) {
        if (fieldsArray[j]._id === name) {
          fieldsArray[j].value = value;
          fieldsArray[j].id = name;
          break; // No need to continue once we've found and updated the object
        }
      }
    }

  }

  const checkboxChange = (value) => (e) => {
    setCheckedValue({ id: value.id, option: value.optionId });

    if (e.target.checked) {
      setChecked((oldArray) => [...oldArray, value.optionId]);
    } else {
      setChecked(checked.filter((item) => item !== value.optionId));
    }
  };

  const contactTypeChange = (event) => {
   

    setContactType1(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setstatus1(event.target.value);
  };

  const handleChangeFollowup = (event) => {
    setfollowup1(event.target.value);
  };

 
  const handleChangeSource = (event) => {
    setSource(event.target.value);
  };

  const countryChange = (country) => {
  };

  const createContact = (e) => {

    for (let i = 0; i < groupList.length; i++) {
      const fieldsArray = groupList[i].Fields;
      for (let j = 0; j < fieldsArray.length; j++) {
        if (fieldsArray[j]._id === selectedValue.id) {
          fieldsArray[j].value = selectedValue.option;
          fieldsArray[j].id = selectedValue.option;
          break; // No need to continue once we've found and updated the object
        }
      }
    }

    for (let i = 0; i < groupList.length; i++) {
      const fieldsArray = groupList[i].Fields;
      for (let j = 0; j < fieldsArray.length; j++) {
        if (fieldsArray[j]._id === checkedValue.id) {
          fieldsArray[j].value = checked.toString();
          fieldsArray[j].id = checkedValue.optionId;
          break; // No need to continue once we've found and updated the object
        }
      }
    }


    let a = contactList.find((obj) => obj._id === contactType1);
    let b = statusList.find((obj) => obj._id === status1);
    let c = getFollowUplist.find((obj) => obj._id === followup1);
    let d = getlabellist.filter(obj =>  label1.includes(obj.label));
   

    let labellistArray = d.map((item) => ({
      name: item.label,
      color: item.color, 
      id: item._id 
    }))

    let groupListArray = groupList.map((item) => ({
      name: item.group,
      id: item.groupId,
      field_name: item.Fields,
    }));

    let ff = {
      company_id: company_idd,
      employee_id: "", //employee_id.employee_id._id
      first_name: contactData.first_name,
      last_name: contactData.last_name,
      email: contactData.email,
      phone: phone,
      address: contactData.address,
      country: country,
      follow_Up: enable,
      contact_type: { name: a.label, color: "", id: a._id },
      status: { name: b.label, color: "", id: b._id },
   
      labels:labellistArray,
      follow_up: [{ follow_up_type: { name: c.label, color: "", id: c._id } }],
      group: groupListArray,
    };

   

    axios
      .post(`${api_url}/crm_customer/addCustomer`, {
        company_id: company_idd,
        employee_id: "649ebbd22a57ea9dd87fe5a2", // employee_id.employee_id._id
        first_name: contactData.first_name,
        last_name: contactData.last_name,
        email: contactData.email,
        phone: phone,
        address: contactData.address,
        country: country,
        source_id: source,
        contact_type: { name: a.label, color: "", id: a._id },
        status: { name: b.label, color: "", id: b._id },
        labels:labellistArray,
        follow_up: [
          { follow_up_type: { name: c.label, color: "", id: c._id } },
        ],
        group: groupListArray,
      })
      .then((result) => {
        if (result.data.status === "success") {
          alert("success");
        }
      })
      .catch((err) => {
      });
  };

  return (
    <div>
      {" "}
      <React.Fragment>
        <Drawer anchor="right" open={props.open} onClose={props.toggleDrawer}>
          <div className="adddcontactdrawer">
            <div className="addcompanytitle">
              ADD CONTACT
              <IconButton
                component="span"
                onClick={props.toggleDrawer}
                style={{ padding: 0, marginRight: 20 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="information">
              <div className="informationtitle">INFORMATION</div>
              <Grid container spacing={3} padding={5}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  alignItems="center"
                  justifyContent=" center"
                >
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    First Name* :
                  </InputLabel>
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    placeholder={"First Name"}
                    name="first_name"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    Last Name* :
                  </InputLabel>
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    placeholder={"Last Name"}
                    name="last_name"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    Phone* :
                  </InputLabel>
                  <PhoneInput
                    style={{ width: "100%" }}
                    enableAreaCodes={true}
                    specialLabel={false}
                    placeholder="Enter phone number"
                    value={phone}
                    country={"in"}
                    name="phone_number"
                    onChange={setPhone}
                    //onBlur={handleBlur}
                    // inputStyle={isValidPhone ? {} : { border: '2px solid red' }}
                  />
                  {/* <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    defaultCountry="IN"
                    style={{ color: "#f0eeee" }}
                    input={<BootstrapInput />}
                    onChange={setPhone}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    E-mail* :
                  </InputLabel>
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    placeholder={"E-mail"}
                    name="email"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    Address* :
                  </InputLabel>
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    placeholder={"Address"}
                    name="address"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    Contact Type :
                  </InputLabel>
                  <FormControl
                    // sx={{ m: 1 }}
                    variant="standard"
                    style={{ width: "100%" }}
                    size="small"
                  >
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={contactType1}
                      onChange={contactTypeChange}
                      input={<BootstrapInput />}
                    >
                      {contactList.length >= 1
                        ? contactList.map((item) => {
                            return (
                              <>
                                <option value={item._id}>{item.label}</option>
                              </>
                            );
                          })
                        : ""}
                    </NativeSelect>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    Status :
                  </InputLabel>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    size="small"
                  >
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={status1}
                      onChange={handleChangeStatus}
                      input={<BootstrapInput />}
                    >
                      {statusList.length >= 1
                        ? statusList.map((item) => {
                            return (
                              <>
                                <option value={item._id}>{item.label}</option>
                              </>
                            );
                          })
                        : ""}
                    </NativeSelect>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    Country :
                  </InputLabel>
                  <ReactCountryDropdown
                    style={{ zIndex: 9 }}
                    onSelect={countryChange}
                    countryCode="IN"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    FollowUp Type :
                  </InputLabel>
                  <FormControl
                    variant="standard"
                    style={{ width: "100%" }}
                    size="small"
                  >
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={followup1}
                      onChange={handleChangeFollowup}
                      input={<BootstrapInput />}
                    >
                      {getFollowUplist.length >= 1
                        ? getFollowUplist.map((item) => {
                            return (
                              <>
                                <option value={item._id}>{item.label}</option>
                              </>
                            );
                          })
                        : ""}
                    </NativeSelect>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  
                  <InputLabel
                    style={{ fontSize: 12 }}
                    htmlFor="demo-customized-select-native"
                  >
                    Labels :
                  </InputLabel>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      size="small"
                      placeholder="select Your Labels"
                      value={label1}
                      onChange={handleChange9}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      <MenuItem disabled value="">
                        <em>Select Your Labels</em>
                      </MenuItem>
                      {getlabellist.length >= 1
                        ? getlabellist.map((item) => {
                            return (
                              <MenuItem key={item._id} value={item.label}>
                                <Checkbox
                                  checked={label1.indexOf(item.label) > -1}
                                />
                                <ListItemText primary={item.label} />
                              </MenuItem>
                            );
                          })
                        : ""}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <InputLabel
                    style={{ fontSize: 12 }}
                    id="demo-multiple-checkbox-label"
                  >
                    Source :
                  </InputLabel>

                  <FormControl
                    // sx={{ m: 1 }}
                    variant="standard"
                    style={{ width: "100%" }}
                    size="small"
                  >
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={source}
                      onChange={handleChangeSource}
                      input={<BootstrapInput />}
                    >
                      {getSourcellist.length >= 1
                        ? getSourcellist.map((item) => {
                            return (
                              <>
                                <option value={item._id}>{item.label}</option>
                              </>
                            );
                          })
                        : ""}
                    </NativeSelect>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Checkbox onChange={(e) => setEnable(e.target.checked)} />
                  FollowUps
                </Grid>
              </Grid>
            </div>
            {groupList
              ? groupList.map((item) => (
                  <div className="information">
                
                    <div
                      className="informationtitle"
                      style={{ color: item.color }}
                    >
                      {item.group}
                    </div>
                    <Grid container spacing={1} padding={5}>
                      {item.Fields.map((item) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          display={"flex"}
                          alignItems="flex-start"
                          justifyContent="center"
                        >
                          <Grid container spacing={1} padding={1}>
                            <Grid
                              item
                              xs={4}
                              display={"flex"}
                              alignItems="flex-start"
                              justifyContent="center"
                            >
                              {item.name}:
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              display={"flex"}
                              alignItems="flex-start"
                              justifyContent="flex-start"
                            >
                              {item.type == "Text" ? (
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  size="small"
                                  id="standard-basic"
                                  variant="outlined"
                                  placeholder={item.name}
                                  name={item._id}
                                  onChange={groupChange}
                                  fullWidth
                                  required
                                />
                              ) : item.type == "Textarea" ? (
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  size="small"
                                  id="standard-basic"
                                  variant="outlined"
                                  placeholder={item.name}
                                  multiline
                                  rows={3}
                                  name={item._id}
                                  onChange={groupChange}
                                  fullWidth
                                  required
                                />
                              ) : item.type == "CheckBox" ? (
                                item.options.map((item1) => (
                                  <>
                                    <Checkbox
                                      style={{ marginLeft: 5 }}
                                      checked={checked.includes(item1._id)}
                                      onChange={checkboxChange({
                                        optionId: item1._id,
                                        id: item._id,
                                        name: item1.name,
                                      })}
                                    />
                                    {item1.name}
                                  </>
                                ))
                              ) : item.type == "Radio" ? (
                                <>
                                  <FormControl>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name="controlled-radio-buttons-group"
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                      value={selectedValue.option}
                                      onChange={handleChangeRatio(item._id)}
                                    >
                                      {item.options.map((item) => (
                                        <>
                                          <FormControlLabel
                                            value={item._id}
                                            control={<Radio />}
                                            label={item.name}
                                          />
                                        </>
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              ) : item.type == "Select" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                  }}
                                >
                                 
                                  <FormControl
                                    // sx={{ m: 1 }}
                                    variant="standard"
                                    style={{ width: "100%" }}
                                    size="small"
                                  >
                                    <NativeSelect
                                      id="demo-customized-select-native"
                                      defaultValue={
                                        item.options.length >= 1
                                          ? item.options[0]
                                          : ""
                                      }
                                      onChange={handleChangeSelect}
                                      input={<BootstrapInput />}
                                    >
                                      {item.options.length >= 1
                                        ? item.options.map((item) => {
                                            return (
                                              <>
                                                <option value={item}>
                                                  {item.name}
                                                </option>
                                              </>
                                            );
                                          })
                                        : ""}
                                    </NativeSelect>
                                  </FormControl>
                                </div>
                              ) : item.type == "Phone" ? (
                                <TextField
                                  InputLabelProps={{ shrink: true }}
                                  size="small"
                                  type="number"
                                  id="standard-basic"
                                  variant="outlined"
                                  placeholder={item.name}
                                  name={item._id}
                                  onChange={groupChange}
                                  fullWidth
                                  required
                                />
                              ) : (
                                ""
                              )}{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))
              : ""}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "30px",
              }}
            >
              <Button className="tablebutton" onClick={createContact}>
                Add Contact
              </Button>
            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
