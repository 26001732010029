import React from "react";
import errorStyle from "../Style/Error.module.css";
import { customOutlineButtonStyle } from "../Style/DomainStyles";
import { Button, Paper } from "@mui/material";
import nodata from "../../../images/error/No data.png";
const NoData = ({ imgSrc, handleClick, line1, line2, tilte }) => {
  return (
    <div>
      <Paper>
        <div className={errorStyle.top_margin}>
          <div className={errorStyle.top_container}>
            <div className={errorStyle.top_container_flex}>
              <img src={imgSrc} alt="web hosting" />
              <p className={errorStyle.typo_title}>{tilte}</p>
            </div>
          </div>
          <div className={errorStyle.botttom_container}>
            <div>
              <img
                src={nodata}
                alt="gsuite not found"
                className={errorStyle.img_nodata}
              />
            </div>
            <div className={errorStyle.bottom_text}>
              <div>{line1}</div>
              <div>{line2}</div>
            </div>
            <div style={{ paddingBottom: "34px" }}>
              <Button
                color="primary"
                variant="outlined"
                style={customOutlineButtonStyle}
                onClick={() => {
                  handleClick();
                }}
              >
                Buy Now
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default NoData;
