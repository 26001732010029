import { Avatar, Box, Stack, Typography } from "@mui/material"
import Tags from "../../../components/Tags";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import RingVolumeOutlinedIcon from '@mui/icons-material/RingVolumeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { stringAvatar } from "../../../utils/PipelineConstants";
import variables from "../../../assets/styles/_colors.scss";
import moment from "moment/moment";
import { withStyles } from "@material-ui/core/styles";

const metaData = {
    "stats": [{
        label: "Open Activity",
        value: "1"
    },
    {
        label: "Open Pipeline Records",
        value: "2"
    },
    {
        label: "Won Pipeline Record",
        value: "1"
    }],
    "otherInfo": [{
        label: "Additional Field",
        value: "Holaaaaa"
    }]
}


const StyledDotSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

export const ContactInfo = (props) => {

    const getAddress = () => {
        const data = props.item;
        let obj = "";
        if (data) {
            obj += data.mailingStreet ? data.mailingStreet : "";
            obj += data.mailingCity ? obj === "" ? `${data.mailingCity}` : `, ${data.mailingCity}` : "";
            obj += data.mailingState ? obj === "" ? `${data.mailingState}` : `, ${data.mailingState}` : "";
            obj += data.mailingCountry ? obj === "" ? `${data.mailingCountry}` : `, ${data.mailingCountry}` : "";
            obj += data.mailingZip ? obj === "" ? `${data.mailingZip}` : `, ${data.mailingZip}` : "";
        }

        return obj === "" ? "--" : obj;
    }

    return (
        <Box sx={{ pl: 2, pt: 2 }}>
            {props.showStats &&
                <Box sx={{ pb: 1 }}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1 }}>Stats</Typography>
                    {metaData["stats"]?.map((field, index) => {
                        return <Stack direction="row" sx={{ pb: 1 }} key={index} alignItems="center" spacing={1}>
                            <Typography >{field.label}</Typography>
                            <StyledDotSeparator />
                            <Typography>{field.value}</Typography>
                        </Stack>
                    })}
                </Box>
            }
            {
                <Box sx={{}}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1 }}>Basic Info</Typography>
                    <Stack direction="column">
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <MailOutlineIcon />
                            <Typography width="60%">{props.item?.email ? props.item?.email : '--'}</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <PhoneAndroidIcon />
                            <Typography width="60%">{props.item?.mobile ? props.item?.mobile : '--'}</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <CallOutlinedIcon />
                            <Typography width="60%">{props.item?.phone ? props.item?.phone : '--'}</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <RingVolumeOutlinedIcon />
                            <Typography width="60%">{props.item?.homePhone ? props.item?.homePhone : '--'}</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <LocationOnOutlinedIcon />
                            <Typography width="60%">{getAddress()}</Typography>
                        </Stack>
                    </Stack>
                </Box>
            }

            <Tags />

            {
                <Box sx={{ pb: 1 }}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 2 }}>Description</Typography>
                    <Typography width="100%">{props.item?.description ? props.item.description : '--'}</Typography>
                </Box>
            }
            {
                <Box sx={{ pb: 1 }}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 1 }}>Other Info</Typography>
                    {metaData["otherInfo"]?.map((field, index) => {
                        return <Stack direction="row" sx={{ pb: 1 }} key={index}>
                            <Typography width="200px">{field.label}</Typography>
                            <Typography width="60%">{field.value ? field.value : '--'}</Typography>
                        </Stack>
                    })}
                </Box>
            }
            {
                <Stack direction="row" alignItems="center" sx={{ pb: 1 }}>
                    <Avatar sx={{ width: 25, height: 25, fontSize: 10 }} {...stringAvatar("Harvey Specter")} />
                    <Typography sx={{ color: `${variables.greyTextColor}`, fontSize: 14, pl: 1.5 }}> Last Modified on </Typography>
                    <Typography sx={{ fontSize: 14, pl: 0.5 }}> {moment().calendar()} </Typography>
                </Stack>
            }
        </Box>
    )
}