import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Avatar, IconButton, Stack } from '@mui/material';
import variables from "../../../../assets/styles/_colors.scss";
import { stringAvatar } from '../../../../utils/PipelineConstants';
import { useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { useQuery } from '@tanstack/react-query';
import { getRoleUsers } from '../../../../webservices/UserControlService';

const StyledIcon = withStyles({
    root: {
        backgroundColor: `${variables.listBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.listBackgroundColor} !important`
        }
    }
})(IconButton);

export default function ViewRoleUsers(props) {

    const [open, setOpen] = useState(true);

    const {
        data,
    } = useQuery({
        queryKey: ['getRoleUsers'],
        queryFn: () => getRoleUsers(props.item?._id),
        refetchOnWindowFocus: false
    });

    const handleClose = (action) => {
        setOpen(false);
        props.handleClose(action);
    }

    const stopNavigation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <div>
            <Modal open={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 0 8px 0",
                    width: '40%'
                }}>
                    <Stack direction="row" alignItems="center" sx={{ pl: 2, pr: 2 }}>
                        <Stack direction="column" width="70%">
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>Users in {props.item?.roleName} role
                            </Typography>
                        </Stack>
                        <Stack direction="column" width="30%">
                            <Stack direction="row" justifyContent="end" spacing={1}>
                                <StyledIcon onClick={() => handleClose("edit")}>
                                    <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                                </StyledIcon>
                                {props.item?.reportsTo &&
                                    <StyledIcon onClick={() => handleClose("delete")}>
                                        <DeleteOutlineIcon fontSize='medium' color="error" />
                                    </StyledIcon>
                                }
                                <StyledIcon onClick={(e) => { stopNavigation(e); handleClose() }}>
                                    <CloseIcon fontSize='medium' color={variables.greyTextColor} />
                                </StyledIcon>
                            </Stack>
                        </Stack>
                    </Stack>

                    {props.item?.description &&
                        <Typography component="div" variant="body" sx={{ p: 2 }}>
                            {props.item?.description}
                        </Typography>
                    }

                    <Stack direction="row" sx={{ pl: 2 }}>
                        <Stack direction="column" width="30%">
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ pb: 2, pt: 1 }}>
                                <Typography component="div" variant="body" sx={{ mb: 2 }}>
                                    No. of Users:
                                </Typography>
                                <Typography component="span" sx={{ borderRadius: 4, bgcolor: `${variables.listBackgroundColor}`, pl: 1, pr: 1 }}>
                                    {data?.users?.length}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction="column" width="80%">
                            <Stack direction="row" spacing={2} alignItems="center" sx={{ pb: 2, pt: 1 }}>
                                <Typography component="div" variant="body" sx={{ mb: 2 }}>
                                    Peer Data Visibility:
                                </Typography>
                                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                    {props.item?.peerDataVisibility ? 'Yes' : 'No'}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack direction="row" sx={{ pb: 1, borderBottom: `1px solid ${variables.lightBorderColor}`, pl: 2 }}>
                        <Typography sx={{ fontWeight: 'bold' }}>Users</Typography>
                    </Stack>

                    {data?.users?.length > 0 ?
                        <Box sx={{ pb: 2, overflowY: 'auto', maxHeight: '60vh', pl: 2 }}>
                            {data?.users?.map(user => {
                                return <Stack direction="row" spacing={2} sx={{ pt: 1, pb: 1, borderBottom: `1px solid ${variables.lightBorderColor}` }}>
                                    <Stack direction="column">
                                        <Avatar {...stringAvatar(`${user?.firstName} ${user?.lastName}`)} />
                                    </Stack>
                                    <Stack direction="column">
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {user.firstName} {user.lastName}
                                        </Typography>
                                        <Typography sx={{ fontSize: 12, color: `${variables.greyTextColor}` }}>
                                            {user?.profile.profileName}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            })}
                        </Box>
                        :
                        <Stack direction="row" justifyContent="center" alignItems="center" height="150px">
                            <Typography color={`${variables.greyTextColor}`}>No users found</Typography>
                        </Stack>
                    }
                </Box>
            </Modal>
        </div>
    );
}