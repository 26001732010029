import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import "./MapComponentNew.css";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import BatteryGauge from "react-battery-gauge";
import SignalCellular0BarIcon from "@mui/icons-material/SignalCellular0Bar";
import SignalCellular1BarIcon from "@mui/icons-material/SignalCellular1Bar";
import SignalCellular2BarIcon from "@mui/icons-material/SignalCellular2Bar";
import SignalCellular3BarIcon from "@mui/icons-material/SignalCellular3Bar";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import axios from "axios";
import { useRecoilState } from "recoil";
import { api_url } from "../../globalSettings";
import {

  login_token,

} from "../../Atoms/LoginAtoms";
import Summary from "./Summary";
import InfoTask from "./InfoTask";

const typo = {
  textAlign :"center" ,fontSize : 12 , fontWeight: 700  , marginTop : 5
}

function TabPanel(props) {


  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InfoWindow(props) {
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
 
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [summaryDetails , getSummaryDetail] = useState([]);

  function getSummaryDetails() {

    const params = {
      token: loginToken1,
      emp_attendance_id: props.selectedElement._id,

    };

    axios
      .get(`${api_url}/employee/get_live_summary`, {
        params: { ...params },
      })

      .then((response) => {
      
        getSummaryDetail(response.data.data)

      })
      .catch(function (error) {  
        if (error.response) {

        } else if (error.request) {

        } else {

        }
   
      });
 
  }

  function getOnOffDutyDetails() {

    const params = {
      token: loginToken1,
      emp_attendance_id: props.selectedElement._id,
      employee_id: props.selectedElement.employee_id._id,
    };

    axios
      .get(`${api_url}/employee/get_employee_on_off_duty_details`, {
        params: { ...params },
      })

      .then((response) => {
      
      })
      .catch(function (error) {  
        if (error.response) {
  
        } else if (error.request) {
       
        } else {
     
        }
    
      });
  }

  

  useEffect(() => {
    getOnOffDutyDetails();
    getSummaryDetails();
  }, []);




  return (
    <Box sx={{ width: "306px", height: "350px" , overflow : "hidden", overflowY:"scroll" }} className="scroll" >
      <Box sx={{ borderBottom: 0, borderColor: "divider" }} >
        <Tabs
          style={{ minHeight: 30 }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab className="infoTab" label="Live" {...a11yProps(0)} />
          <Tab className="infoTab" label="Summary" {...a11yProps(1)} />
          <Tab className="infoTab" label="Task" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel style={{ padding: 0 }} value={value} index={0}>
        <div className="infotabpanels">
        <div className="infotabpanel">
          <div className="onduty">
            {props.selectedElement.duty_status === "on_duty"
              ? "Onduty"
              : "OffDuty"}
          </div>
          <div className="avatar">
            <Avatar src="" style={{ width: 50, height: 50, marginTop: 25 }} />
            <div className="mbl_no">
              <StayCurrentPortraitIcon style={{ fontSize: 15 }} />
              <Typography style={{ fontSize: 12 }}>
                {props.selectedElement.employee_id.moile_no}
              </Typography>
            </div>
          </div>
          <div className="tower">
            {props.selectedElement.data_network_type == "wifi" ? (
              <WifiIcon fontSize="small" color="success" />
            ) : (
              <WifiOffIcon fontSize="small" />
            )}
        
            <Tooltip title={props.selectedElement.signal_strength}>
              <div>
                {props.selectedElement.signal_strength >= "80" ? (
                  <SignalCellular4BarIcon fontSize="small" color="success" />
                ) : props.selectedElement.signal_strength >= "60" ? (
                  <SignalCellular3BarIcon fontSize="small" color="success" />
                ) : props.selectedElement.signal_strength >= "40" ? (
                  <SignalCellular2BarIcon fontSize="small" color="success" />
                ) : props.selectedElement.signal_strength >= "20" ? (
                  <SignalCellular1BarIcon fontSize="small" color="success" />
                ) : (
                  <SignalCellular0BarIcon fontSize="small" />
                )}
         
              </div>
            </Tooltip>
            <Tooltip
              title={`${props.selectedElement.mobile_battery_strength}%`}
            >
              <div>
                <BatteryGauge
                  size={18}
                  orientation="vertical"
                  value={props.selectedElement.mobile_battery_strength}
                />
              </div>
            </Tooltip>
          </div>
          <div className="lastUpdate">
          </div>
          </div>
          {Object.keys(summaryDetails).length > 0  ? <> <Typography style={typo}>Last Update : {new Date(summaryDetails.live.last_updated).toLocaleDateString()} {new Date(summaryDetails.live.last_updated).toLocaleTimeString()}</Typography></>: ""} 
          <hr/>
          {Object.keys(summaryDetails).length > 0  ? <> <Typography style={typo}>{summaryDetails.live.current_location_name} {`(lat : ${summaryDetails.live.current_location_latitude}, lan : ${summaryDetails.live.current_location_longitude}  )`} </Typography></>: ""}
        </div>
      </TabPanel>
      <TabPanel style={{ padding: 0 }} className="infotabpanel1" value={value} index={1}>
        {Object.keys(summaryDetails).length >= 1 ? <Summary  summaryDetails={summaryDetails}/>  : ""  }
      </TabPanel>
      <TabPanel style={{ padding: 0 }} className="infotabpanel1" value={value} index={2}>
      {Object.keys(summaryDetails).length >= 1 ? <InfoTask summaryDetails={summaryDetails} /> : ""}
      </TabPanel>
    </Box>
  );
}
