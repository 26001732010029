import axios from "axios";

export const fetchData = async (companyId) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/website/apiv3/company/${companyId}`
    );
    console.log("respo",response)

    if (response.status !== 200) {
      throw new Error("Network response was not ok");
    }

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Error fetching data");
  }
};
