import { Box, Drawer, IconButton, MenuItem, Select, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import variables from "../../..//assets/styles/_colors.scss";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@mui/styles";
import PersonIcon from '@mui/icons-material/Person';
import { Notes } from "../../../components/notes/Notes";
import DeleteDeal from "../delete/DeleteDeal";
import UpsertDeal from "../create/UpsertDeal";
import { ActivityInfo } from "../../activities/view/table/ActivityInfo";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BusinessIcon from '@mui/icons-material/Business';

const StyledIcon = withStyles({
    root: {
        height: "32px",
        width: "32px",
        backgroundColor: `${variables.iconButtonBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.iconButtonBackgroundHoverColor} !important`
        }
    }
})(IconButton);

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important'
    }
})(Tab);

const stages = [{
    label: "Stage 1",
    value: "1"
},
{
    label: "Stage 2",
    value: "2"
},
{
    label: "Stage 3",
    value: "3"
}]
const DealQuickPreview = (props) => {

    const [open, setOpen] = useState(true);
    const [editDeal, setEditActivity] = useState(false);
    const [deleteDeal, setDeleteActivity] = useState(false);
    const [selectedTab, setSelectedTab] = useState("0");

    function handleDrawerClose() {
        setOpen(false);
        props.handleDrawerClose();
    }

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
    }

    return (
        <><Drawer anchor="right" open={open}
            PaperProps={{
                sx: {
                    minWidth: "35vw", maxWidth: "35vw", borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                }
            }}>
            <Box sx={{ height: "100vh", overflowX: "hidden", overflowY: "auto" }}>
                <Box sx={{ bgcolor: `${variables.lightGreyBackgroundColor}`, pt: "20px", pb: 2, pl: 2 }}>
                    <Stack direction="row" width="100%" alignItems="top" sx={{ pr: 1.25 }}>
                        <Stack direction="column" width="60%" justifyContent="left">
                            <Typography variant="h6" sx={{ color: `${variables.linkColor}`, fontWeight: 600, fontSize: "15px" }}>
                                {props.item?.dealName || props.item?.name}
                            </Typography>
                            <Typography sx={{ pt: 0.5 }}>
                                <PersonIcon size="small" sx={{ width: 16, height: 16 }} />
                                <Typography component="span" sx={{ fontSize: "13px", pl: 1 }}>
                                    {props.item?.dealOwner}
                                </Typography>
                            </Typography>
                        </Stack>
                        <Stack direction="row" width="40%" justifyContent="right" spacing={1}>
                            <StyledIcon onClick={() => setEditActivity(true)}>
                                <EditOutlinedIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                            <StyledIcon onClick={() => setDeleteActivity(true)}>
                                <DeleteOutlineIcon fontSize='medium' color="error" />
                            </StyledIcon>
                            <StyledIcon onClick={() => handleDrawerClose()}>
                                <CloseIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pb: 1, pt: 2 }} >
                        <Typography width="200px">Closing Date</Typography>
                        <Typography width="99%">{props.item?.closingDate ? props.item?.closingDate : '--'}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pb: 1 }} >
                        <Typography width="200px">Team Pipeline</Typography>
                        <Typography width="99%">{props.item?.pipeline ? props.item?.pipeline : '--'}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pb: 1 }} >
                        <Typography width="200px">Sub-Pipeline</Typography>
                        <Typography width="99%">{props.item?.subPipeline ? props.item?.subPipeline : '--'}</Typography>
                    </Stack>
                    <Stack direction="row" sx={{ pt: 1 }}>
                        <Select
                            defaultValue={stages[1]?.value}
                            size="small"
                        >
                            {stages?.map(option => {
                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            })}
                        </Select>
                    </Stack>
                </Box>

                <Box sx={{ pl: 1, pb: 2 }}>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        <StyledTab label="Information" value="0" />
                        <StyledTab label="Notes" value="1" />
                    </Tabs>
                    {
                        <>
                            {selectedTab === "0" &&
                                <>
                                    <Stack direction="column" sx={{ pt: 1, pl: 2 }}>
                                        <Typography component="div" sx={{ fontWeight: "bold", pb: 0.5, pt: 1 }}>Related To</Typography>
                                        <Stack direction="row" sx={{ pt: 1 }}>
                                            <AccountBoxIcon />
                                            <Typography sx={{ pl: 2 }}>Contact Name</Typography>
                                        </Stack>
                                        <Stack direction="row" sx={{ pt: 1 }}>
                                            <BusinessIcon />
                                            <Typography sx={{ pl: 2 }}>Company Name</Typography>
                                        </Stack>
                                    </Stack>
                                    <ActivityInfo module={"pipeline"} />
                                </>
                            }

                            {selectedTab === "1" &&
                                <Notes />
                            }
                        </>
                    }
                </Box>

                {deleteDeal &&
                    <DeleteDeal item={props.item} module={props.module} handleClose={() => { setDeleteActivity(false) }} />}

                {editDeal &&
                    <UpsertDeal edit={true} module={props.module} handleDrawerClose={() => { setEditActivity(false) }} />}
            </Box>
        </Drawer >
        </>
    );
};

export default DealQuickPreview;