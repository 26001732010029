import * as React from "react";
import { TextField } from "@mui/material";
import {
    Controller,
    get,
    useFormContext
} from "react-hook-form";
import variables from "../../../assets/styles/_colors.scss";

export const CustomNumberField = ({ ...props }) => {

    const { control, formState } = useFormContext();
    const [num, setNum] = React.useState();
    // const [error, setError] = React.useState(null);
    const err = get(formState.errors, props.name);

    const getRegex = (type) => {
        if (type == "number" || type == "long") return /^[0-9\b]+$/;
        if (type == "decimal") return /^\d+(\.\d{1,2})?$/;
        if (type == "percent") return /^\d{1,2}\.\d{1,2}$/;
    }

    // const handleChange = (e) => {
    //     setError(null);
    //     const regex = getRegex(props.subType);
    //     if (e.target.value === "" || regex.test(e.target.value)) {
    //         setNum(e.target.value);
    //     } else {
    //         setError("Invalid  value")
    //     }
    // };

    return (
        <Controller
            control={control}
            name={props.name}
            rules={props.rules}
            render={({ field }) => (
                <TextField
                    id={props.name}
                    label={props.label}
                    variant="outlined"
                    fullWidth
                    type="number"
                    size={props.size ? props.size : "medium"}
                    placeholder={props.placeholder}
                    onChange={event => {
                        // handleChange(event);
                        field.onChange(event);
                    }}
                    {...field}
                    error={Boolean(err)}
                    helperText={err?.message}
                    sx={{
                        ".MuiOutlinedInput-root": {
                            borderLeft: props?.rules?.required ? '3px solid red !important' : `1px solid ${variables.borderColor}`
                        }
                    }}
                />
            )}
        />
    );
};
