import React, { useState } from "react";
import Styles from "../Style/Domaintab.module.css";
import AddIcon from "@mui/icons-material/Add";
import { api_url1 } from "../../../globalSettings";
import axios from "axios";
import { useRecoilState } from "recoil";
import { login_atoms } from "../../../Atoms/LoginAtoms";
import { Skeleton, Stack } from "@mui/material";
import moment from "moment";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import DomainTable from "../Component/DomainTable";
import { styled } from "@mui/system";

const CustomButton = styled(Button)(({ theme }) => ({
  "& .MuiButton-startIcon": {
    marginRight: "0px",
  },
}));
const fetchData = ({ queryKey }) => {
  const { clientid, filterValue } = queryKey[1];
  let obj = {
    id: clientid,
    filterBy: filterValue === "All" ? "" : filterValue,
  };
  const queryParams = new URLSearchParams(obj).toString();
  return axios
    .get(`${api_url1}/resellers/resellerdata?${queryParams}`)
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      return err;
    });
};
function DomainList() {
  const [clientid, setClientid] = useRecoilState(login_atoms);

  const [filterValue, setFilterValue] = useState("All");

  const handleTabClick = (tab) => {
    setFilterValue(tab);
  };

  const { pathname } = useLocation();
  const hasDataAfterWebservices =
    pathname.startsWith("/webservices/") &&
    pathname.length > "/webservices/".length;

  /*======================================== GET DOMAIN DATA ============================================*/

  const {
    data: domainData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["resellerData", { clientid, filterValue }],
    queryFn: fetchData,
    select: (data) => {
      const formattedData = data.map((item) => ({
        ...item,
        purchasedDate: moment(item.purchaseDate).format("DD/MM/YY"),
        renewalDate: moment(item.expiryDate).format("DD/MM/YY"),
      }));
      return formattedData;
    },
  });
  const handleCreateClick = () => {
    window.open("https://www.nowdigitaleasy.com/domain", "_blank");
  };

  /*=====================================================================================================*/

  return (
    <div>
      <div className={Styles.outside_wrapper}>
        <div
          style={{
            display: "flex",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "13px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              marginRight: "16px",
              color: filterValue === "All" ? "#000AFF" : "inherit",
              borderBottom:
                filterValue === "All" ? "2px solid #000AFF" : "none",
              height: "23px",
            }}
            onClick={() => handleTabClick("All")}
          >
            All
          </div>
          <div
            style={{
              cursor: "pointer",
              marginRight: "16px",
              color: filterValue === "next30days" ? "#000AFF" : "inherit",
              borderBottom:
                filterValue === "next30days" ? "2px solid #000AFF" : "none",
              height: "23px",
            }}
            onClick={() => handleTabClick("next30days")}
          >
            Expired In 30 days
          </div>
          <div
            style={{
              cursor: "pointer",
              color: filterValue === "expired" ? "#000AFF" : "inherit",
              borderBottom:
                filterValue === "expired" ? "2px solid #000AFF" : "none",
              height: "23px",
            }}
            onClick={() => handleTabClick("expired")}
          >
            Expired
          </div>
          <div></div>
        </div>

        <div className={Styles.button_margin}>
          {hasDataAfterWebservices ? (
            ""
          ) : (
            <CustomButton
              variant="contained"
              startIcon={<AddIcon style={{ marginRight: 0 }} />}
              sx={{
                height: "30px",
                width: "90px",
                borderRadius: "4px",
                fontFamily: "Inter",
                fontSize: "13px",
                background: "blue",
              }}
              onClick={handleCreateClick}
            >
              Create
            </CustomButton>
          )}
        </div>
      </div>
      {isLoading ? (
        <>
          <div style={{ flex: "2 2 30%" }}>
            <CustomSkeleton hasDataAfterWebservices={hasDataAfterWebservices} />
            <CustomSkeleton hasDataAfterWebservices={hasDataAfterWebservices} />
            <CustomSkeleton hasDataAfterWebservices={hasDataAfterWebservices} />
            <CustomSkeleton hasDataAfterWebservices={hasDataAfterWebservices} />
            <CustomSkeleton hasDataAfterWebservices={hasDataAfterWebservices} />
            <CustomSkeleton hasDataAfterWebservices={hasDataAfterWebservices} />
          </div>
        </>
      ) : (
        <DomainTable
          domaindata={domainData ?? []}
          hasDataAfterWebservices={hasDataAfterWebservices}
        />
      )}
    </div>
  );
}

function CustomSkeleton({ hasDataAfterWebservices }) {
  return (
    <>
      {hasDataAfterWebservices ? (
        <Stack
          spacing={1}
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "10px" }}
        >
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ marginTop: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ width: "78%", height: "30px", marginTop: "10px" }}
          />
        </Stack>
      ) : (
        <Stack
          spacing={1}
          direction={{ xs: "column", sm: "row" }}
          justifyContent="center"
          alignItems="center"
          sx={{ marginLeft: "10px", marginTop: "20px" }}
        >
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            sx={{ marginTop: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ width: "23%", height: "30px", marginTop: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ width: "23%", height: "30px", marginTop: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ width: "23%", height: "30px", marginTop: "10px" }}
          />
          <Skeleton
            variant="rectangular"
            sx={{ width: "23%", height: "30px", marginTop: "10px" }}
          />
        </Stack>
      )}
    </>
  );
}
export default DomainList;
