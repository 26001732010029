import { Box, Button, Divider, Drawer, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from "../../../../assets/styles/pipelines.module.css";
import { CustomAutocompleteFreesolo } from "../../components/CustomAutocompleteFreesolo";
import { useEffect } from "react";
import { getPipelineTemplates } from "../../../../webservices/PipelineService";
import variables from "../../../../assets/styles/_colors.scss";

export default function ComposeEmail(props) {

    const [user,] = useState(JSON.parse(localStorage.getItem("recoil-persist")));
    const [composeEmail, setComposeEmail] = useState(false);
    const [showCC, setShowCC] = useState(false);
    const [showBCC, setShowBCC] = useState(false);
    const [showPlainTextEditor, setShowPlainTextEditor] = useState(true);
    let editorReference = null;

    useEffect(() => {
        setComposeEmail(props.showDrawer);
    }, [props.showDrawer]);

    useEffect(() => {
        setShowCC(false);
        setShowBCC(false);
        setShowPlainTextEditor(false);
    }, [composeEmail])

    const setEditorReference = (ref) => {
        editorReference = ref;
        ref?.focus();
    }

    return (
        <>
            {!props.showDrawer &&
                <Tooltip title="Compose Email" placement="bottom">
                    <IconButton sx={{ p: 0 }} onClick={() => setComposeEmail(true)}>
                        <MailOutlineIcon fontSize='small' color={variables.greyTextColor} />
                    </IconButton>
                </Tooltip>
            }
            {composeEmail &&
                <Drawer anchor="right" open={composeEmail}
                    PaperProps={{
                        sx: { minWidth: "75vw", maxWidth: "75vw", borderTop: `1px solid ${variables.borderColor}`, overflow: 'hidden', p: 0 },
                    }}>

                    <Box sx={{ height: 'calc(100vh - 78px)' }}>
                        <Typography component="div" sx={{ pt: 1, pr: 1, pb: 1, pl: 3, height: '6%', borderBottom: `1px solid ${variables.lightBorderColor}` }}>
                            <Typography component="span">
                                {user?.apiresponse?.email}
                            </Typography>
                            <Typography component="span" sx={{ cursor: 'pointer', color: `${variables.linkColor}`, float: "right" }}>
                                Choose Template
                            </Typography>
                        </Typography>

                        <Stack direction="row" sx={{ pb: 1, height: '6%', borderBottom: `1px solid ${variables.lightBorderColor}` }}>
                            <Typography variant="div" align="right" sx={{ width: '10%', mr: 2, pt: 1 }}>
                                To*
                            </Typography>
                            <Typography sx={{ width: '83%', pt: 0.2 }}>
                                <CustomAutocompleteFreesolo id="compose-email-to" options={[]} />
                            </Typography>
                            <Typography component="span" sx={{ color: "#515159", fontStyle: "italic", cursor: "pointer", width: '7%', pt: 1 }}>
                                {!showCC &&
                                    <Typography component="span" sx={{ paddingRight: 2.5 }} onClick={() => setShowCC(true)}>
                                        CC
                                    </Typography>}
                                {!showBCC &&
                                    <Typography component="span" onClick={() => setShowBCC(true)}>
                                        BCC
                                    </Typography>}
                            </Typography>
                        </Stack>

                        {showCC && <>
                            <Stack direction="row" sx={{ pb: 1, height: '6%' }}>
                                <Typography variant="div" align="right" sx={{ width: '10%', mr: 2, pt: 1 }}>
                                    CC
                                </Typography>
                                <Typography sx={{ width: '83%', pt: 0.2 }}>
                                    <CustomAutocompleteFreesolo id="compose-email-to" options={[]} />
                                </Typography>
                            </Stack>
                            <Divider />
                        </>
                        }

                        {showBCC && <>
                            <Stack direction="row" sx={{ pb: 1, height: '6%' }}>
                                <Typography variant="div" align="right" sx={{ width: '10%', mr: 2, pt: 1 }}>
                                    BCC
                                </Typography>
                                <Typography sx={{ width: '90%', pt: 0.2 }}>
                                    <CustomAutocompleteFreesolo id="compose-email-to" options={[]} />
                                </Typography>
                            </Stack>
                            <Divider />
                        </>
                        }

                        {!showPlainTextEditor ?
                            <Box style={{ height: '75%' }}>
                                <Typography component="span" sx={{
                                    float: 'right', marginLeft: '-10%', marginTop: '1%', marginRight: '1%', cursor: 'pointer'
                                }} onClick={() => setShowPlainTextEditor(true)}>
                                    Plain Text Editor</Typography>

                                <Editor
                                    editorRef={setEditorReference}
                                    wrapperClassName={styles.editorWrapper}
                                    editorClassName={styles.editor}
                                    toolbarClassName={styles.toolbar}
                                    toolbar={{
                                        options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'],
                                        inline: {
                                            inDropdown: false,
                                            options: ['bold', 'italic', 'underline', 'strikethrough']
                                        },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        image: {
                                            urlEnabled: true,
                                            uploadEnabled: true,
                                            uploadCallback: () => getPipelineTemplates(),
                                            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
                                        }
                                    }} />
                            </Box>
                            :
                            <>
                                <Typography component="div" align="right" sx={{ p: 1.5, width: '100%', borderBottom: `1px solid ${variables.lightBorderColor}`, cursor: 'pointer', backgroundColor: `${variables.lightGreyBackgroundColor}` }}
                                    onClick={() => setShowPlainTextEditor(false)} >Rich Text Editor</Typography>
                                <Divider />
                                <TextField
                                    autoFocus
                                    id="compose-email-plain-editor"
                                    multiline
                                    fullWidth
                                    variant="standard"
                                    rows={25}
                                    sx={{ border: 'none', pl: 2, pt: 1.5 }}
                                    InputProps={{ disableUnderline: true }}
                                />
                            </>
                        }
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="right" sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button variant="outlined" onClick={() => { setComposeEmail(false); props.handleClose(); }}>Cancel</Button>
                        <Button variant="contained" onClick={() => { setComposeEmail(false); props.handleClose(); }}>Send</Button>
                    </Stack>
                </Drawer>}
        </>
    )
}