import React , {useState} from 'react'
import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { styled } from "@mui/material/styles";
import { empExpDetails} from '../../Atoms/LoginAtoms';
import { useRecoilState } from "recoil";
import Modal from 'react-modal';
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import Tooltip from "@mui/material/Tooltip";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0000003d",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  
  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 9999
    },
    content: {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: '400px',
      maxHeight: '80%',
      overflow: 'hidden'
    }
  };
  


export default function EmpexpenseReport() {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState('');
  const [expDetails , setExpDetails] = useRecoilState(empExpDetails)


  const openDialog = (image_name) => {
    setSelectedImages(image_name)
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

    return (
    <div><div className="reporttop">
    <Typography>Employee Expense Details <span style={{color:"yellow"}}>[{expDetails[0].employee_id.first_name}]</span> </Typography>
    <Typography 

    style={{cursor : "pointer"}}><FilterAltOutlinedIcon /></Typography>       
</div>
<div className="reporttable">
    <TableContainer sx={{ maxHeight : 500 }} component={Paper}>
      <Table sx={{ minWidth: 700 , maxHeight : 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Expense Category</StyledTableCell>
            <StyledTableCell align="center">Expense Date</StyledTableCell>
            <StyledTableCell align="center">Expense Amount</StyledTableCell>
            <StyledTableCell align="center">Image</StyledTableCell>
            <StyledTableCell align="center">Description</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {expDetails.map((row) => (
            <StyledTableRow
            //onClick={() => attendanceReport()} 
            style={{cursor : "pointer"}}>
              <StyledTableCell align="center">{row.expense_category ? row.expense_category : "--"}</StyledTableCell>
              <StyledTableCell align="center">{row.expense_date ? row.expense_date : "--"}</StyledTableCell>
              <StyledTableCell align="center">{row.expense ? row.expense : "--"}</StyledTableCell>
              <StyledTableCell align="center" onClick={() =>openDialog(row.image_name)} >{row.image_name.length > 0 ? <WallpaperOutlinedIcon /> : "--"}</StyledTableCell>
 
          <Modal
    isOpen={isDialogOpen}
    onRequestClose={closeDialog}
    style={modalStyles}
    contentLabel="Image Slider Dialog"
  >
 
    
      <img src={`https://server.nowdigitaleasy.com:3505/uploads/expense/${selectedImages}`} width="100%" alt={`Image `} />
     

  </Modal>
  <Tooltip title={row.description}><StyledTableCell align="center">{row.description.length ? <NoteAltOutlinedIcon /> 
              : "--" }</StyledTableCell></Tooltip>
            </StyledTableRow> 
          ))}
          
        </TableBody>
      </Table>
    </TableContainer>
    </div></div>
  )
}
