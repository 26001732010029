import { Grid, Typography } from "@mui/material";
import React from "react";
import { loadingAll } from "../../../../../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import { LoadingButton } from "@mui/lab";

const style = {
  width: "100%",
  padding: 20,
  paddingTop : 0
};

const gridCellStyle = {
  border: "1px solid #e9e9eb",
  textAlign: "center",
  borderBottom: "none",
  alignItems: "center",
};

const gridLastCellStyle = {
  border: "1px solid #e9e9eb",
  textAlign: "center",
  alignItems: "center",
};

const SrvRecord = () => {
    const [isLoading] = useRecoilState(loadingAll);

    return (
      <div style={style}>
         {/* <Typography className="contact-title" style={{ marginBottom: 10 }}>
          {" "}
          ADD SRV RECORD
        </Typography>
        <Grid container spacing={0}>
          <Grid item xs={6} style={gridCellStyle}>
            <div style={{ display: "flex" , width :"100%" }}>
              <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
              <input className="cd-input" value={""} />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle} display={"flex"}>
            <div
              style={{
                width: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ fontSize: "small" }}>SRV</p>
            </div>
            <span
              style={{ width: 1, height: 20, backgroundColor: "#0000005c" }}
            ></span>
            <div style={{ display: "flex" , width:"100%" }}>
              <label className="cd-title">IPV4-ADDRESS*</label>
              <input className="cd-input" value={""} />
            </div>
          </Grid>
          <Grid item xs={12} style={gridLastCellStyle} display={"flex"} width={"100%"}>

          <div
              style={{
                width: "25%",
                display: "flex",
              }}
            >
             <label className="cd-title">PRIORITY*</label>
              <input className="cd-input" value={""} />
            </div>
            <span
              style={{ width: 1, height: 20, backgroundColor: "#0000005c" }}
            ></span>
            <div style={{ display: "flex" , width:"25%" }}>
            <label className="cd-title">TTL(Time To Live)</label>
              <select style={{ width: 200 }} className="cd-input" value={""}>
                <option>4 hours</option>
                <option>6 hours</option>
                <option>1 day</option>
              </select>
            </div>
            <span
              style={{ width: 1, height: 20, backgroundColor: "#0000005c" }}
            ></span>
            <div
              style={{
                width: "25%",
                display: "flex",
              }}
            >
             <label className="cd-title">PORT*</label>
              <input className="cd-input" value={""} />
            </div>
            <span
              style={{ width: 1, height: 20, backgroundColor: "#0000005c" }}
            ></span>
            <div
              style={{
                width: "25%",
                display: "flex",
              }}
            >
             <label className="cd-title">WEIGHT*</label>
              <input className="cd-input" value={""} />
            </div>
          </Grid>
        </Grid>
        <LoadingButton
          loading={isLoading}
          className="loadingButton"
          style={{ backgroundColor: " #468def" }}
          // onClick={props.handleTheftProtection}
        >
          SAVE RECORD(S)
        </LoadingButton> */}
  
        <Typography
          variant="subtitle2"
          style={{ margin: "30px 0 10px 0", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          CURRENT RECORDS (0)
        </Typography>
        <p style={{ fontSize: 12 }}> You have not added any SRV records</p>
        <style>
          {`.loadingButton{
                                        color: rgb(255, 255, 255);
                                        background-color: #468def;
                                        margin-top: 20px;
                                        cursor: pointer;
                                        padding:15px 25px;
                                        font-size:12px;
                                        width:max-content;
                                        border-radius:0px
                                    }
                                    .loadingButton1{
                                      color:#fff;
                                      font-size:12px;
                                      width:max-content;
                                      border-radius:0px;
                                      margin:10px
                                   }
                                    `}
        </style>
      </div>
    );
  };

export default SrvRecord