import { useState } from "react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getContactColumns } from "../../webservices/PipelineService";
import { contacts_atom } from "../../../../Atoms/CrmAtoms";
import ContactActions from "./ContactActions";
import ContactsTable from "./view/table/ContactsTable";

export default function CrmContacts() {

    const [contactsAtom, setContactsAtom] = useRecoilState(contacts_atom);

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [contactCols, setContactCols] = useState([]);

    useEffect(() => {

        if (contactsAtom.displayedColumns.contactsTable.length === 0) {
            getContactColumns().then((response) => {
                persistData(response.data);
            });
        } else {
            setContactCols(contactsAtom.displayedColumns.contactsTable);
        }

    }, []);

    const persistData = (cols) => {
        if (cols?.length > 0) {
            const obj = {
                ...contactsAtom,
                "displayedColumns": {
                    ...contactsAtom["displayedColumns"],
                    "contactsTable": cols
                }
            }
            setContactsAtom(obj);
            setContactCols(cols);
        }
    }

    return (
        <Box sx={{ ml: isFilterOpen ? '22vw' : 0 }}>
            <ContactActions isFilterOpen={(val) => setIsFilterOpen(val)} />
            {contactCols.length > 0 && <ContactsTable columns={contactCols} drawerOpen={isFilterOpen} />}
        </Box>
    );
}
