import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ContactDetail from './ContactInfo/ContactDetail';
import Body from './Body';

const ContactRoute = () => {
  return (
<Routes>
<Route path="/:id/*" element={<ContactDetail />}/>
        <Route path="*" element={<Body />} />
</Routes>
  )
}

export default ContactRoute
