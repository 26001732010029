import React from 'react'
import VTabbody from './VTabbody'
import VTabheader from './VTabheader'

const VisitorTab = () => {
  return (
    <div style={{width:"100%"}}>
        <VTabheader/>
      <VTabbody/>
    </div>
  )
}

export default VisitorTab
