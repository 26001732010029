import { useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Dialog,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RedAsterisk from "../../../../components/RedAsterisk";
import Button from "../../../../components/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    department_name: yup
      .string()
      .min(3, "Please Enter Minimum 3 characters")
      .max(50, "Please Enter Maximum 50 characters")
      .required("Please Enter Department Name"),
  })
  .required();

function Department() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  // const [departments, setDepartments] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("Department:", data);
    reset();
  };

  const handleAddDepartmentClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div
      style={{
        height: "65vh",
        // border: "1px solid red",
      }}
    >
      <div
        style={
          {
            //    overflowY: "auto",
          }
        }
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2%",
            }}
          >
            <Button
              style={{
                background: "rgba(0, 10, 255, 1)",
                color: "white",
                marginRight: "2%",
              }}
              onClick={handleAddDepartmentClick}
            >
              Add Department
            </Button>
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Department Name
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bolder",
                    }}
                  >
                    Description
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {departments.map((department, index) => (
                   <TableRow key={index}>
                     <TableCell>{department.name}</TableCell>
                     <TableCell>{department.description}</TableCell>
                   </TableRow>
                 ))} */}
              </TableBody>
            </Table>
          </div>
          <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            maxWidth="xs"
            fullWidth
          >
            <Box
              style={{
                background: "rgba(0, 10, 255, 1)",
                padding: "10px 10px 10px 25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                }}
              >
                Department
              </Typography>
              <CloseIcon
                onClick={handleDialogClose}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20px",
                }}
              />
            </Box>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputLabel sx={{color:"black"}}>
                  Department Name <RedAsterisk />{" "}
                </InputLabel>
                <FormControl fullWidth margin="normal">
                  <TextField
                    {...register("department_name")}
                    error={!!errors.department_name}
                    helperText={errors.department_name?.message}
                    variant="outlined"
                    placeholder="Department Name"
                  />
                </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "10px",
                }}
              >
                <Button
                  style={{
                    background: "rgba(0, 0, 0, 0)",
                    borderRadius: "5px",
                    color: "Black",
                    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                    marginRight: "20px",
                  }}
                  onClick={handleDialogClose}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    background: "rgba(0, 10, 255, 1)",
                    borderRadius: "5px",
                    color: "white",
                  }}
                  type="submit"
                >
                  Add 
                </Button>
              </Box>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default Department;


//         <div>
//           <Typography>Description</Typography>
//           <TextField 
//             placeholder="Description" 
//             fullWidth 
//             multiline 
//             rows={3} 
//             name="description"
//             value={values.description}
//             onChange={handleChange}
//             onBlur={handleBlur}
//           />
//           </div>

