import CloseIcon from "@mui/icons-material/Close";
import Button from '../../../../components/Button'
import {
  
  Table,
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  Typography,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  Box,
  TableBody,
  
} from "@mui/material";
import { useState } from "react";
import RedAsterisk from "../../../../components/RedAsterisk";
import MyCheckbox from "../../../../components/CheckBox";

function Shift() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [newShift, setNewShift] = useState({
    name: "",
    validFrom: "",
    validTo: "",
    workingDays: [],
  });

  const handleAddShiftClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // Handle checkbox changes separately
      setNewShift((prevShift) => ({
        ...prevShift,
        workingDays: checked
          ? [...prevShift.workingDays, value]
          : prevShift.workingDays.filter((day) => day !== value),
      }));
    } else {
      // Handle other input changes
      setNewShift((prevShift) => ({ ...prevShift, [name]: value }));
    }
  };

  const handleAddShift = () => {
    setShifts((prevShifts) => [...prevShifts, newShift]);
    setIsDialogOpen(false);
    setNewShift({
      name: "",
      validFrom: "",
      validTo: "",
      workingDays: [],
    });
  };

  const CheckboxStyle = {
    padding: "2px",
    marginLeft:"5px",
    borderColor:"#D9D9D9"
  };

  return (
    <div style={{
      // border: "1px solid red",
      height: "65vh"
    }}>
      <div >
        <div style={{
      marginTop: "15px"
    }}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "flex-end",
        }}
      >
        <Button
          style={{  
            marginTop: "1%",
           width: '97px',
           marginRight: "10px"
            
          }}
          onClick={handleAddShiftClick}
        >
          Add Shift
        </Button>
      </div>
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Shift Name</TableCell>
              <TableCell>Valid From</TableCell>
              <TableCell>Valid To</TableCell>
              <TableCell>Working Day</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shifts.map((shift, index) => (
              <TableRow key={index}>
                <TableCell>{shift.name}</TableCell>
                <TableCell>{shift.validFrom}</TableCell>
                <TableCell>{shift.validTo}</TableCell>
                <TableCell>{shift.workingDays.join(", ")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="md"
          
        >
         <Box
                style={{
                  background: "rgba(0, 10, 255, 1)",
                  padding: "10px 10px 10px 25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                  }}
                >
                 Shift
                </Typography>
                <CloseIcon
                  onClick={handleDialogClose}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Box>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography style={{
                  padding: "5px 0px 5px"
                  
                }}>Shift Name<RedAsterisk /></Typography>
                <TextField
                fullWidth
                  size="small"
                  style={{
                    // marginBottom: "10%",
                  }}
                  name="name"
                  value={newShift.name}
                  onChange={handleInputChange}
                />
                <Typography style={{
                  padding: "20px 0px 5px"
                }}>Early Start Time<RedAsterisk /></Typography>
                <TextField
               
                  style={{
                    width: "47%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
                <TextField
                  style={{
                    width: "47%",
                    marginLeft: "5%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
                <Typography style={{
                   padding: "20px 0px 5px"
                }}>Late End Time<RedAsterisk /></Typography>
                <TextField
                  style={{
                    width: "47%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
                <TextField
                  style={{
                    width: "47%",
                    marginLeft: "5%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography style={{
                  padding: "5px 0px 5px"
                }}>Valid From<RedAsterisk /></Typography>
                <TextField
                 fullWidth
                  size="small"
                  name="validFrom"
                  value={newShift.validFrom}
                  onChange={handleInputChange}
                />
                <Typography style={{
                   padding: "20px 0px 5px"
                }}>Late Start Time<RedAsterisk /></Typography>
                <TextField
                  style={{
                    width: "47%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
                <TextField
                  style={{
                    width: "47%",
                    marginLeft: "5%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
                <Typography style={{
                   padding: "20px 0px 5px"
                }}>Working Day<RedAsterisk /></Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    border: "1px solid #ddd",
                    alignItems: "center", 
                    padding: "0px 1px", 
                    flexWrap: "wrap", 
                    borderRadius: "8px",
                    fontStyle: "Roboto"
                  }}
                >
                
                  <MyCheckbox 
                  style={CheckboxStyle}
                  size="small"
                  name="workingDays"
                  value="Mon"
                  checked={newShift.workingDays.includes("Mon")}
                    onChange={handleInputChange} />
                  <Typography style={{
                    fontStyle: "Roboto"
                  }}
                  sx={{
                    "&.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root": {
                      color: "rgba(0,0,0,0.1)"
                    }
                  }}>
                    Mon
                  </Typography>
                  <MyCheckbox 
                  style={CheckboxStyle}
                  size="small"
                  name="workingDays"
                  value="Tue"
                  checked={newShift.workingDays.includes("Tue")}
                    onChange={handleInputChange} />
                 <Typography style={{
                    fontStyle: "Roboto"
                  }}>
                    Tue
                  </Typography>
                  <MyCheckbox 
                  style={CheckboxStyle}
                  size="small"
                  name="workingDays"
                  value="Wed"
                  checked={newShift.workingDays.includes("Wed")}
                    onChange={handleInputChange} />
                 <Typography style={{
                    fontStyle: "Roboto"
                  }}>
                    Wed
                  </Typography>
                  <MyCheckbox 
                  style={CheckboxStyle}
                  size="small"
                  name="workingDays"
                  value="Thur"
                  checked={newShift.workingDays.includes("Thur")}
                    onChange={handleInputChange} />
                 <Typography style={{
                    fontStyle: "Roboto"
                  }}>
                    Thur
                  </Typography>
                  <MyCheckbox 
                  style={CheckboxStyle}
                  size="small"
                  name="workingDays"
                  value="Fri"
                  checked={newShift.workingDays.includes("Fri")}
                    onChange={handleInputChange} />
                  <Typography style={{
                    fontStyle: "Roboto"
                  }}>
                    Fri
                  </Typography>
                  <MyCheckbox 
                  style={CheckboxStyle}
                  size="small"
                  name="workingDays"
                  value="Sat"
                  checked={newShift.workingDays.includes("Sat")}
                    onChange={handleInputChange} />
                  <Typography style={{
                    fontStyle: "Roboto"
                  }}>
                    Sat
                  </Typography>
                  <MyCheckbox 
                  style={CheckboxStyle}
                  size="small"
                  name="workingDays"
                  value="Sun"
                  checked={newShift.workingDays.includes("Sun")}
                    onChange={handleInputChange} />
                  <Typography style={{
                    fontStyle: "Roboto"
                  }}>
                    Sun
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography style={{
                  padding: "5px 0px 5px"
                }}>Valid To<RedAsterisk /></Typography>
                <TextField
                 fullWidth
                  size="small"
                  name="validTo"
                  value={newShift.validTo}
                  onChange={handleInputChange}
                />
                <Typography style={{
                   padding: "20px 0px 5px"
                }}>Early End Time<RedAsterisk /></Typography>
                <TextField
                  style={{
                    width: "47%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
                <TextField
                  style={{
                    width: "47%",
                    marginLeft: "5%",
                  }}
                  placeholder="HH-MM"
                  size="small"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{padding:"0 25px 15px 0"}}>
            <Button
              style={{
                background: "rgba(0, 0, 0, 0)",
                color: "black",
                borderRadius: "5px",
                padding: "8px 16px",
                boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                marginRight:"10px",
              }}
              onClick={handleDialogClose}
            >
              cancel
            </Button>
            <Button
              style={{
                background: "rgba(0, 10, 255, 1)",
                color: "white",
                borderRadius: "5px",
                padding: "8px 16px",
              }}
              onClick={handleAddShift}
            >
              Add shift
            </Button>
            
          </DialogActions>
        </Dialog>
      </div>
    </div>

      </div>
     
    </div>
  );
}

export default Shift


