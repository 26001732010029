import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CustomInputField } from "./CustomInputField";
import { AddCircleOutline } from "@material-ui/icons";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import variables from "../../../assets/styles/_colors.scss";
import { CustomSelect } from "./CustomSelect";
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';

export default function ReorderableStringList(props) {

    const {
        control,
        register,
        watch,
        setValue,
        clearErrors,
        getValues,
        formState: { errors }
    } = useFormContext();

    const defaultValue = null;

    const { fields, append, remove, move, insert } = useFieldArray({
        name: `${props.id}.options`,
        control
    });

    const options = watch(`${props.id}.options`);

    useEffect(() => {
        const formOptions = getValues(`${props.id}.options`)
        setValue(`${props.id}.options`, formOptions.length > 0 ? formOptions : [""]);
    }, []);

    function reorderItems(result) {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        move(startIndex, endIndex);
    }

    const addNewRow = (index, val = 0) => {
        index === -1 ? append(defaultValue) : insert(index + 1, val ? val : defaultValue);
    }

    const removeSelectedRow = (index) => {
        remove(index);
    }

    return (
        <>
            <DragDropContext onDragEnd={reorderItems}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {fields.map((item, i) => {
                                return (
                                    <Draggable key={item.id} draggableId={item.id} index={i}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                variant={snapshot.isDragging ? "elevation" : "outlined"}
                                                elevation={4}
                                            >
                                                <Stack alignItems="start" direction="row" sx={{ width: "100%", pb: 1 }}>
                                                    <Typography component="div" sx={{ cursor: "move", pt: 1, pr: 2, pl: 0, width: "6%" }} {...provided.dragHandleProps} >
                                                        <DragIndicatorIcon />
                                                    </Typography>
                                                    <CustomInputField size="small" name={`${props.id}.options.${i}.0`} placeholder={`To-do item ${i + 1}`} />
                                                    <IconButton onClick={(event) => { event.preventDefault(); addNewRow(i) }}
                                                        sx={{ color: `${variables.addIconColor}`, width: "7%" }}>
                                                        <AddCircleOutline />
                                                    </IconButton>
                                                    {fields.length > 1 &&
                                                        <IconButton onClick={(event) => { event.preventDefault(); removeSelectedRow(i) }}
                                                            sx={{ color: `${variables.removeIconColor}`, width: "7%" }}>
                                                            <RemoveCircleOutlineIcon />
                                                        </IconButton>
                                                    }
                                                </Stack>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Button variant="text" fullWidth onClick={(event) => { event.preventDefault(); addNewRow(-1) }}><AddIcon /> Add Item</Button>

            {!props.hideDefaultValue && <>
                <Typography sx={{ fontWeight: 600, pb: 2 }}>Set Default value</Typography>
                <CustomSelect size="small" name="customField.picklist.defaultValue" addDefaultMenu={true} defaultValue="-1" options={options ? options : []} />
            </>
            }
        </>
    );
}
