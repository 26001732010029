import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeClosureRestriction } from '../../../../webservices/StageService';

export default function RemoveClosureRestriction(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(true);

    const { mutateAsync } = useMutation({
        mutationKey: "removeClosureRestriction",
        mutationFn: () => removeClosureRestriction(props.pipelineId, props.item._id),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getClosureRestrictions'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = (data) => {
        setOpen(false);
        props.handleClose(data);
    }

    const handleRemove = () => {
        mutateAsync();
    }

    return (
        <div>
            <Modal open={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Remove Closure Restrictions
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ pt: 2 }}>
                        Are you sure you want to remove pipeline closure restrictions for the sub-pipeline {props.item.subPipelineName}?
                    </Typography>

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="error" onClick={handleRemove}>Yes, Remove</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}