import { Box, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import CustomSnackbar from '../../../../components/CustomSnackbar';
import { useQuery } from "@tanstack/react-query";
import { useEffect } from 'react';
import { useState } from 'react';
import { getAllPipelines } from '../../../../webservices/StageService';
import RestrictClosureTable from './RestrictClosureTable';

export default function RestrictClosure(props) {

    const [selectedTeamPipeline, setSelectedTeamPipeline] = useState("");

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data
    } = useQuery({
        queryKey: ['getAllPipelines'],
        queryFn: () => getAllPipelines(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        setSelectedTeamPipeline(data?.pipelines[0]?._id);
    }, [data]);

    const handleTeamPipelineChange = (event) => {
        const selectedPipeline = event.target.value;
        setSelectedTeamPipeline(selectedPipeline);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        });
    }

    return (
        <Box sx={{ pb: 2 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, pt: 0.5 }}>
                Restrict Closure
            </Typography>
            <Typography sx={{ pb: 2, pt: 0.5 }}>
                This feature lets you specify the stages in a sub-pipeline from which pipeline records cannot be closed directly.
            </Typography>

            <Stack direction="row" width="100%" sx={{ pb: 2 }}>
                <Stack direction="row" spacing={1} width="50%" justifyContent="flex-start" alignItems="center">
                    <Typography>Team Pipeline</Typography>
                    <FormControl size="small" sx={{ background: "#fff" }}>
                        <Select
                            value={selectedTeamPipeline}
                            onChange={handleTeamPipelineChange}
                        >
                            {
                                data?.pipelines.map((pipeline, i) => {
                                    return <MenuItem key={i} value={pipeline._id}>{pipeline.pipelineName}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>

            <RestrictClosureTable pipelineId={selectedTeamPipeline} showSnackbar={(data) => showSnackbar(data)} />

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

        </Box>
    )
}