import {  Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { loadingAll, triger_records } from "../../../../../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import deleteicon from "../../../../img/delete.png";
import axios from "axios";
import { api_url1 } from "../../../../../../globalSettings";
import { open_snackbar } from "../../../../../login/loginatom";
 
const style = {
  width: "100%",
  padding: 20,
  paddingTop : 0
};

const gridLastCellStyle = {
  border: "1px solid #e9e9eb",
  textAlign: "center",
  display: "flex",
};


const DnsAllRecords = ({
  aRecord,
  aaaaRecord,
  mxRecord,
  cnameRecord,
  nsRecord,
  txtRecord,
  srvRecord,
  soaRecord,
}) => {
  const [trigerRecords, setTrigerRecords] = useRecoilState(triger_records);
  const [isLoading, setIsLoading] = useRecoilState(loadingAll);
  const [snackbaropen, setSnackbaropen] = useRecoilState(open_snackbar);

  const [isThirdGridOpen, setThirdGridOpen] = useState(false);

  const toggleThirdGrid = (indexId) => {
    setThirdGridOpen(!isThirdGridOpen);
    setIndexId(indexId);
  };
  const [isDelete, setDelete] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isEditable, setEditable] = useState(false);
  const [indexId, setIndexId] = useState("");

  const toggleDelete = (indexId) => {
    setDeleteId(indexId);
    setDelete(!isDelete);
  };

  const toggleEditable = (indexId) => {
    setIndexId(indexId);
    setEditable(!isEditable);
    setThirdGridOpen(!isThirdGridOpen);
  };

  const gridCellStyle = {
    border: "1px solid #e9e9eb",
    textAlign: "center",
    borderBottom: `${isThirdGridOpen ? "none" : "1px solid #e9e9eb"}`,
    alignItems: "center",
    display: "flex",
  };

  const deleteRecord = () => {
    setIsLoading(true);



    axios
      .delete(`${api_url1}/plesk/dns/${deleteId}`)
      .then((result) => {
        setSnackbaropen({
          open: true,
          severity: "success",
          message: "Record Deleted Successfully",
        });
        setIsLoading(false);
        setTrigerRecords(!trigerRecords);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
       
        } else if (error.request) {
        
        } else {
        
        }
      });
  };

  return (
    <div style={style}>

      <div>
        <Typography
          variant="subtitle2"
          style={{ marginBottom: "10px", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          A ({aRecord.length})
        </Typography>

        {aRecord.length > 0 ? (
          aRecord.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ width: "100%", display: "flex", margin: "10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    borderLeft: "2px solid #28bd8b",
                    width: "80%",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={gridCellStyle}>
                      {isEditable && indexId === item.id ? (
                          <div className="cd-align">
                          <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                          <input className="cd-input" value={item.host} />
                        </div>
                      ) : (
                        <p style={{ fontSize: "14px", padding: "10px" }}>
                          {item.host}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} style={gridCellStyle} display={"flex"}>
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "small" }}>A</p>
                      </div>
                      <span
                        style={{
                          width: 1,
                          height: 20,
                          backgroundColor: "#0000005c",
                        }}
                      ></span>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        {isEditable && indexId === item.id ? (
                            <div className="cd-align">
                            <label className="cd-title">IPV4-ADDRESS*</label>
                            <input
                              className="cd-input"
                              value={item.value}
                            />{" "}
                          </div>
                        ) : (
                          <p style={{ fontSize: "14px", padding: "10px" }}>
                            {item.value}
                          </p>
                        )}
                      </div>

                      <div onClick={() => toggleThirdGrid(item.id)}>
                        {isThirdGridOpen && indexId === item.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </Grid>

                    {isThirdGridOpen && indexId === item.id && (
                      <Grid item xs={12} style={gridLastCellStyle}>
                           <div className="cd-align">
                          <label className="cd-title">TTL(Time To Live)</label>
                          <select
                            style={{ width: 200 }}
                            className="cd-input"
                            value={""}
                          >
                            <option>4 hours</option>
                            <option>6 hours</option>
                            <option>1 day</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isEditable && indexId === item.id ? (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ backgroundColor: " #468def" }}
                      // onClick={props.handleTheftProtection}
                    >
                      SAVE
                    </LoadingButton>

                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ color: " #468def" }}
                      onClick={toggleEditable}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    {isDelete && deleteId === item.id ? (
                      <div>
                        <p style={{ fontSize: "10px", padding: " 0 10px" }}>
                          Are you Sure?
                        </p>
                        <div>
                          <LoadingButton
                            loading={isLoading}
                            className="deleteButton"
                            onClick={deleteRecord}
                            style={{ backgroundColor: "#e76060" }}
                          >
                            Delete
                          </LoadingButton>

                          <LoadingButton
                            className="deleteButton"
                            style={{ color: " #468def" }}
                            onClick={toggleDelete}
                          >
                            Cancel
                          </LoadingButton>
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* <IconButton
                          onClick={() => toggleEditable(item.id)}
                          style={{ margin: 10 }}
                        >
                          <img src={edit} alt="edit" />
                        </IconButton> */}
                        <IconButton
                          onClick={() => toggleDelete(item.id)}
                          style={{ margin: 10 }}
                        >
                          <img src={deleteicon} alt="delete" />
                        </IconButton>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p style={{ fontSize: 12 }}> You have not added any A records</p>
        )}
      </div>

      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="subtitle2"
          style={{ marginBottom: "10px", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          AAAA ({aaaaRecord.length})
        </Typography>

        {aaaaRecord.length > 0 ? (
          aaaaRecord.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ width: "100%", display: "flex", margin: "10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    borderLeft: "2px solid #28bd8b",
                    width: "80%",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={gridCellStyle}>
                      {isEditable && indexId === item.id ? (
                        <div className="cd-align">
                          <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                          <input className="cd-input" value={item.host} />
                        </div>
                      ) : (
                        <p style={{ fontSize: "14px", padding: "10px" }}>
                          {item.host}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} style={gridCellStyle} display={"flex"}>
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "small" }}>AAAA</p>
                      </div>
                      <span
                        style={{
                          width: 1,
                          height: 20,
                          backgroundColor: "#0000005c",
                        }}
                      ></span>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        {isEditable && indexId === item.id ? (
                          <div className="cd-align">
                            <label className="cd-title">IPV4-ADDRESS*</label>
                            <input
                              className="cd-input"
                              value={item.value}
                            />{" "}
                          </div>
                        ) : (
                          <p style={{ fontSize: "14px", padding: "10px" }}>
                            {item.value}
                          </p>
                        )}
                      </div>

                      <div onClick={() => toggleThirdGrid(item.id)}>
                        {isThirdGridOpen && indexId === item.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </Grid>

                    {isThirdGridOpen && indexId === item.id && (
                      <Grid item xs={12} style={gridLastCellStyle}>
                        <div className="cd-align">
                          <label className="cd-title">TTL(Time To Live)</label>
                          <select
                            style={{ width: 200 }}
                            className="cd-input"
                            value={""}
                          >
                            <option>4 hours</option>
                            <option>6 hours</option>
                            <option>1 day</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isEditable && indexId === item.id ? (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ backgroundColor: " #468def" }}
                      // onClick={props.handleTheftProtection}
                    >
                      SAVE
                    </LoadingButton>

                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ color: " #468def" }}
                      onClick={toggleEditable}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                ) : (
                  <div
                  style={{
                    width: "20%",
                    display: "flex",
                    height: "auto",
                    alignItems: "flex-start",
                  }}
                >
                  {isDelete && deleteId === item.id ? (
                    <div>
                      <p style={{ fontSize: "10px", padding: " 0 10px" }}>
                        Are you Sure?
                      </p>
                      <div>
                        <LoadingButton
                          loading={isLoading}
                          className="deleteButton"
                          onClick={deleteRecord}
                          style={{ backgroundColor: "#e76060" }}
                        >
                          Delete
                        </LoadingButton>

                        <LoadingButton
                          className="deleteButton"
                          style={{ color: " #468def" }}
                          onClick={toggleDelete}
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <IconButton
                        onClick={() => toggleEditable(item.id)}
                        style={{ margin: 10 }}
                      >
                        <img src={edit} alt="edit" />
                      </IconButton> */}
                      <IconButton
                        onClick={() => toggleDelete(item.id)}
                        style={{ margin: 10 }}
                      >
                        <img src={deleteicon} alt="delete" />
                      </IconButton>
                    </>
                  )}
                </div>
                )}
              </div>
            );
          })
        ) : (
          <p style={{ fontSize: 12 }}> You have not added any AAAA records</p>
        )}
      </div>

      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="subtitle2"
          style={{ marginBottom: "10px", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          MX ({mxRecord.length})
        </Typography>

        {mxRecord.length > 0 ? (
          mxRecord.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ width: "100%", display: "flex", margin: "10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    borderLeft: "2px solid #28bd8b",
                    width: "80%",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={gridCellStyle}>
                      {isEditable && indexId === item.id ? (
                          <div className="cd-align">
                          <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                          <input className="cd-input" value={item.host} />
                        </div>
                      ) : (
                        <p style={{ fontSize: "14px", padding: "10px" }}>
                          {item.host}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} style={gridCellStyle} display={"flex"}>
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "small" }}>MX</p>
                      </div>
                      <span
                        style={{
                          width: 1,
                          height: 20,
                          backgroundColor: "#0000005c",
                        }}
                      ></span>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        {isEditable && indexId === item.id ? (
                          <div className="cd-align">
                            <label className="cd-title">IPV4-ADDRESS*</label>
                            <input
                              className="cd-input"
                              value={item.value}
                            />{" "}
                          </div>
                        ) : (
                          <p style={{ fontSize: "14px", padding: "10px" }}>
                            {item.value}
                          </p>
                        )}
                      </div>

                      <div onClick={() => toggleThirdGrid(item.id)}>
                        {isThirdGridOpen  && indexId === item.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </Grid>

                    {isThirdGridOpen && indexId === item.id && (
                      <Grid item xs={12} style={gridLastCellStyle}>
                      <div className="cd-align">
                          <label className="cd-title">TTL(Time To Live)</label>
                          <select
                            style={{ width: 200 }}
                            className="cd-input"
                            value={""}
                          >
                            <option>4 hours</option>
                            <option>6 hours</option>
                            <option>1 day</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isEditable && indexId === item.id ? (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ backgroundColor: " #468def" }}
                      // onClick={props.handleTheftProtection}
                    >
                      SAVE
                    </LoadingButton>

                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ color: " #468def" }}
                      onClick={toggleEditable}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    {isDelete && deleteId === item.id ? (
                      <div>
                        <p style={{ fontSize: "10px", padding: " 0 10px" }}>
                          Are you Sure?
                        </p>
                        <div>
                          <LoadingButton
                            loading={isLoading}
                            className="deleteButton"
                            onClick={deleteRecord}
                            style={{ backgroundColor: "#e76060" }}
                          >
                            Delete
                          </LoadingButton>

                          <LoadingButton
                            className="deleteButton"
                            style={{ color: " #468def" }}
                            onClick={toggleDelete}
                          >
                            Cancel
                          </LoadingButton>
                        </div>
                      </div>
                    ) : (
                      <>
                       
                        <IconButton
                          onClick={() => toggleDelete(item.id)}
                          style={{ margin: 10 }}
                        >
                          <img src={deleteicon} alt="delete" />
                        </IconButton>
                      </>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <p style={{ fontSize: 12 }}> You have not added any MX records</p>
        )}
      </div>

      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="subtitle2"
          style={{ marginBottom: "10px", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          CNAME ({cnameRecord.length})
        </Typography>

        {cnameRecord.length > 0 ? (
          cnameRecord.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ width: "100%", display: "flex", margin: "10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    borderLeft: "2px solid #28bd8b",
                    width: "80%",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={gridCellStyle}>
                      {isEditable && indexId === item.id ? (
                           <div className="cd-align">
                          <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                          <input className="cd-input" value={item.host} />
                        </div>
                      ) : (
                        <p style={{ fontSize: "14px", padding: "10px" }}>
                          {item.host}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} style={gridCellStyle} display={"flex"}>
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "small" }}>CNAME</p>
                      </div>
                      <span
                        style={{
                          width: 1,
                          height: 20,
                          backgroundColor: "#0000005c",
                        }}
                      ></span>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        {isEditable && indexId === item.id ? (
                            <div className="cd-align">
                            <label className="cd-title">IPV4-ADDRESS*</label>
                            <input
                              className="cd-input"
                              value={item.value}
                            />{" "}
                          </div>
                        ) : (
                          <p style={{ fontSize: "14px", padding: "10px" }}>
                            {item.value}
                          </p>
                        )}
                      </div>

                      <div onClick={() => toggleThirdGrid(item.id)}>
                        {isThirdGridOpen  && indexId === item.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </Grid>

                    {isThirdGridOpen && indexId === item.id && (
                      <Grid item xs={12} style={gridLastCellStyle}>
                            <div className="cd-align">
                          <label className="cd-title">TTL(Time To Live)</label>
                          <select
                            style={{ width: 200 }}
                            className="cd-input"
                            value={""}
                          >
                            <option>4 hours</option>
                            <option>6 hours</option>
                            <option>1 day</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isEditable && indexId === item.id ? (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ backgroundColor: " #468def" }}
                      // onClick={props.handleTheftProtection}
                    >
                      SAVE
                    </LoadingButton>

                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ color: " #468def" }}
                      onClick={toggleEditable}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                ) : (
                  <div
                  style={{
                    width: "20%",
                    display: "flex",
                    height: "auto",
                    alignItems: "flex-start",
                  }}
                >
                  {isDelete && deleteId === item.id ? (
                    <div>
                      <p style={{ fontSize: "10px", padding: " 0 10px" }}>
                        Are you Sure?
                      </p>
                      <div>
                        <LoadingButton
                          loading={isLoading}
                          className="deleteButton"
                          onClick={deleteRecord}
                          style={{ backgroundColor: "#e76060" }}
                        >
                          Delete
                        </LoadingButton>

                        <LoadingButton
                          className="deleteButton"
                          style={{ color: " #468def" }}
                          onClick={toggleDelete}
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </div>
                  ) : (
                    <>
                    
                      <IconButton
                        onClick={() => toggleDelete(item.id)}
                        style={{ margin: 10 }}
                      >
                        <img src={deleteicon} alt="delete" />
                      </IconButton>
                    </>
                  )}
                </div>
                )}
              </div>
            );
          })
        ) : (
          <p style={{ fontSize: 12 }}> You have not added any Cname records</p>
        )}
      </div>

      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="subtitle2"
          style={{ marginBottom: "10px", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          Ns ({nsRecord.length})
        </Typography>

        {nsRecord.length > 0 ? (
          nsRecord.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ width: "100%", display: "flex", margin: "10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    borderLeft: "2px solid #28bd8b",
                    width: "80%",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={gridCellStyle}>
                      {isEditable && indexId === item.id ? (
                         <div className="cd-align">
                          <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                          <input className="cd-input" value={item.host} />
                        </div>
                      ) : (
                        <p style={{ fontSize: "14px", padding: "10px" }}>
                          {item.host}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} style={gridCellStyle} display={"flex"}>
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "small" }}>NS</p>
                      </div>
                      <span
                        style={{
                          width: 1,
                          height: 20,
                          backgroundColor: "#0000005c",
                        }}
                      ></span>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        {isEditable && indexId === item.id ? (
                           <div className="cd-align">
                            <label className="cd-title">IPV4-ADDRESS*</label>
                            <input
                              className="cd-input"
                              value={item.value}
                            />{" "}
                          </div>
                        ) : (
                          <p style={{ fontSize: "14px", padding: "10px" }}>
                            {item.value}
                          </p>
                        )}
                      </div>

                      <div onClick={() => toggleThirdGrid(item.id)}>
                        {isThirdGridOpen  && indexId === item.id? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </Grid>

                    {isThirdGridOpen && indexId === item.id && (
                      <Grid item xs={12} style={gridLastCellStyle}>
                           <div className="cd-align">
                          <label className="cd-title">TTL(Time To Live)</label>
                          <select
                            style={{ width: 200 }}
                            className="cd-input"
                            value={""}
                          >
                            <option>4 hours</option>
                            <option>6 hours</option>
                            <option>1 day</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isEditable && indexId === item.id ? (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ backgroundColor: " #468def" }}
                      // onClick={props.handleTheftProtection}
                    >
                      SAVE
                    </LoadingButton>

                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ color: " #468def" }}
                      onClick={toggleEditable}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                ) : (
                  <div
                  style={{
                    width: "20%",
                    display: "flex",
                    height: "auto",
                    alignItems: "flex-start",
                  }}
                >
                  {isDelete && deleteId === item.id ? (
                    <div>
                      <p style={{ fontSize: "10px", padding: " 0 10px" }}>
                        Are you Sure?
                      </p>
                      <div>
                        <LoadingButton
                          loading={isLoading}
                          className="deleteButton"
                          onClick={deleteRecord}
                          style={{ backgroundColor: "#e76060" }}
                        >
                          Delete
                        </LoadingButton>

                        <LoadingButton
                          className="deleteButton"
                          style={{ color: " #468def" }}
                          onClick={toggleDelete}
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <IconButton
                        onClick={() => toggleEditable(item.id)}
                        style={{ margin: 10 }}
                      >
                        <img src={edit} alt="edit" />
                      </IconButton> */}
                      <IconButton
                        onClick={() => toggleDelete(item.id)}
                        style={{ margin: 10 }}
                      >
                        <img src={deleteicon} alt="delete" />
                      </IconButton>
                    </>
                  )}
                </div>
                )}
              </div>
            );
          })
        ) : (
          <p style={{ fontSize: 12 }}> You have not added any Ns records</p>
        )}
      </div>

      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="subtitle2"
          style={{ marginBottom: "10px", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          TXT ({txtRecord.length})
        </Typography>

        {txtRecord.length > 0 ? (
          txtRecord.map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ width: "100%", display: "flex", margin: "10px 0" }}
              >
                <div
                  style={{
                    backgroundColor: "#fcfcfc",
                    borderLeft: "2px solid #28bd8b",
                    width: "80%",
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={6} style={gridCellStyle}>
                      {isEditable && indexId === item.id ? (
                          <div className="cd-align">
                          <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                          <input className="cd-input" value={item.host} />
                        </div>
                      ) : (
                        <p style={{ fontSize: "14px", padding: "10px" }}>
                          {item.host}
                        </p>
                      )}
                    </Grid>
                    <Grid item xs={6} style={gridCellStyle} display={"flex"}>
                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontSize: "small" }}>TXT</p>
                      </div>
                      <span
                        style={{
                          width: 1,
                          height: 20,
                          backgroundColor: "#0000005c",
                        }}
                      ></span>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        {isEditable && indexId === item.id ? (
                           <div className="cd-align">
                            <label className="cd-title">IPV4-ADDRESS*</label>
                            <input
                              className="cd-input"
                              value={item.value}
                            />{" "}
                          </div>
                        ) : (
                          <p className = "lineText" style={{ padding: "10px" }}>
                            {item.value}
                          </p>
                        )}
                      </div>

                      <div onClick={() => toggleThirdGrid(item.id)}>
                        {isThirdGridOpen  && indexId === item.id? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </div>
                    </Grid>

                    {isThirdGridOpen && indexId === item.id && (
                      <Grid item xs={12} style={gridLastCellStyle}>
                       <div className="cd-align">
                          <label className="cd-title">TTL(Time To Live)</label>
                          <select
                            style={{ width: 200 }}
                            className="cd-input"
                            value={""}
                          >
                            <option>4 hours</option>
                            <option>6 hours</option>
                            <option>1 day</option>
                          </select>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>

                {isEditable && indexId === item.id ? (
                  <div
                    style={{
                      width: "20%",
                      display: "flex",
                      height: "auto",
                      alignItems: "flex-start",
                    }}
                  >
                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ backgroundColor: " #468def" }}
                      // onClick={props.handleTheftProtection}
                    >
                      SAVE
                    </LoadingButton>

                    <LoadingButton
                      loading={isLoading}
                      className="loadingButton1"
                      style={{ color: " #468def" }}
                      onClick={toggleEditable}
                    >
                      Cancel
                    </LoadingButton>
                  </div>
                ) : (
                  <div
                  style={{
                    width: "20%",
                    display: "flex",
                    height: "auto",
                    alignItems: "flex-start",
                  }}
                >
                  {isDelete && deleteId === item.id ? (
                    <div>
                      <p style={{ fontSize: "10px", padding: " 0 10px" }}>
                        Are you Sure?
                      </p>
                      <div>
                        <LoadingButton
                          loading={isLoading}
                          className="deleteButton"
                          onClick={deleteRecord}
                          style={{ backgroundColor: "#e76060" }}
                        >
                          Delete
                        </LoadingButton>

                        <LoadingButton
                          className="deleteButton"
                          style={{ color: " #468def" }}
                          onClick={toggleDelete}
                        >
                          Cancel
                        </LoadingButton>
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <IconButton
                        onClick={() => toggleEditable(item.id)}
                        style={{ margin: 10 }}
                      >
                        <img src={edit} alt="edit" />
                      </IconButton> */}
                      <IconButton
                        onClick={() => toggleDelete(item.id)}
                        style={{ margin: 10 }}
                      >
                        <img src={deleteicon} alt="delete" />
                      </IconButton>
                    </>
                  )}
                </div>
                )}
              </div>
            );
          })
        ) : (
          <p style={{ fontSize: 12 }}> You have not added any Txt records</p>
        )}
      </div>

      {/* <p style={{ fontSize: 12 }}> You have not added any A records</p> */}
      <style>
        {`.loadingButton{
                                      color: rgb(255, 255, 255);
                                      background-color: #468def;
                                      margin-top: 20px;
                                      cursor: pointer;
                                      padding:15px 25px;
                                      font-size:12px;
                                      width:max-content;
                                      border-radius:0px
                                  }
  
                                  .loadingButton1{
                                     color:#fff;
                                     font-size:12px;
                                     width:max-content;
                                     border-radius:0px;
                                     margin:10px
                                  }
                                   .deleteButton{
                                  color:#fff;
                                  font-size:10px;
                                  width:max-content;
                                  border-radius:0px;
                                  margin:5px
                                }
                                .lineText{    
                                  text-align:left;
                                  font-size: 12px;
                                      line-height: 20px;
                                      max-height: 34px;
                                      margin-bottom: 3px;
                                      display: -webkit-box;
                                      -webkit-line-clamp: 1;
                                      -webkit-box-orient: vertical;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      cursor: pointer;}
                                  `}
      </style>
    </div>
  );
};

export default DnsAllRecords;
