import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import variables from "../../../../assets/styles/_colors.scss";
import { useState } from "react";
import CreateUser from "./CreateUser";
import UserTable from "./UserTable";
import DeactivateUser from "./DeactivateUser";
import { useEffect } from "react";
import ActivateUser from "./ActivateUser";
import ViewUserDetails from "./ViewUserDetails";
import { useSearchParams } from "react-router-dom";
import { activateUsers, deactivateUsers, getUsersCount, inviteUsers } from "../../../../webservices/UserControlService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import CloseIcon from "@material-ui/icons/Close";
import RefreshIcon from '@mui/icons-material/Refresh';

export default function Users(props) {

    const queryClient = useQueryClient();

    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedTab, setSelectedTab] = useState("");
    const [createUser, showCreateUser] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showActivateUsers, setShowActivateUsers] = useState(false);
    const [showDeactivateUsers, setShowDeactivateUsers] = useState(false);
    const [viewUser, showViewUser] = useState(false);
    const [user, setUser] = useState(null);
    const [activeUsersCount, setActiveUsersCount] = useState(0);
    const [inactiveUsersCount, setInactiveUsersCount] = useState(0);
    const [invitedUsersCount, setInvitedUsersCount] = useState(0);
    const [deletedUsersCount, setDeletedUsersCount] = useState(0);
    const [resetSelection, setResetSelection] = useState(false);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    useEffect(() => {
        const currentTab = searchParams.get('type');
        setSelectedTab(currentTab ? currentTab : "active");
        searchParams.set('type', currentTab ? currentTab : "active");
        setSearchParams(searchParams);
    }, []);

    useEffect(() => {
    }, [selectedRows]);

    useEffect(() => {
        if (resetSelection) {
            setResetSelection(false);
        }
    }, [resetSelection]);

    useEffect(() => {
        showViewUser(searchParams.has("id") ? true : false);
        props.showTabs(searchParams.has("id") ? false : true);
    }, [searchParams]);

    const {
        data
    } = useQuery({
        queryKey: ['getUsersCount'],
        queryFn: () => getUsersCount(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (data) {
            const activeCount = data.find(a => a._id == "ACTIVE");
            const inactiveCount = data.find(a => a._id == "INACTIVE");
            const invitedCount = data.find(a => a._id == "INVITED");
            const deletedCount = data.find(a => a._id == "DELETED");
            setActiveUsersCount(activeCount ? activeCount.count : 0);
            setInactiveUsersCount(inactiveCount ? inactiveCount.count : 0);
            setInvitedUsersCount(invitedCount ? invitedCount.count : 0);
            setDeletedUsersCount(deletedCount ? deletedCount.count : 0);
        }
    }, [data]);

    const handleSuccess = (response) => {
        setResetSelection(true);
        showSnackbar(response);
        queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
        queryClient.invalidateQueries({ queryKey: ['getUsersCount'] });
    }

    const { mutateAsync: inviteUsersMutate } = useMutation({
        mutationKey: "inviteUsers",
        mutationFn: (ids) => inviteUsers({ ids: ids }),
        onSuccess: (response) => {
            handleSuccess(response);
        },
        onError: (errorResponse) => {
            setResetSelection(true);
            showSnackbar(errorResponse.response.data.error);
        }
    });

    const handleInviteUsers = () => {
        inviteUsersMutate(selectedRows.map(row => row.original._id));
    }

    const { mutateAsync: activateUsersMutate } = useMutation({
        mutationKey: "activateUsers",
        mutationFn: (ids) => activateUsers({ ids: ids }),
        onSuccess: (response) => {
            handleSuccess(response);
        },
        onError: (errorResponse) => {
            setResetSelection(true);
            showSnackbar(errorResponse.response.data.error);
        }
    });

    const handleActivateUsers = () => {
        activateUsersMutate(selectedRows.map(row => row.original._id));
    }

    const { mutateAsync: deactivateUsersMutate } = useMutation({
        mutationKey: "deactivateUsers",
        mutationFn: (ids) => deactivateUsers({ ids: ids }),
        onSuccess: (response) => {
            handleSuccess(response);
        },
        onError: (errorResponse) => {
            setResetSelection(true);
            showSnackbar(errorResponse.response.data.error);
        }
    });

    const handleDeactivateUsers = () => {
        deactivateUsersMutate(selectedRows.map(row => row.original._id));
    }

    const handleTabSelection = (type) => {
        searchParams.set('type', type);
        setSearchParams(searchParams);
        setSelectedTab(type);
    }

    const handleUserViewClick = (user) => {
        searchParams.set('id', user?._id);
        setSearchParams(searchParams);
        setUser(user);
        showViewUser(true);
        props.showTabs(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    const handleCreateUserClose = (data) => {
        showCreateUser(false)
        showSnackbar(data);
    }

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        })
    }

    const refreshData = () => {
        queryClient.invalidateQueries('getAllUsers');
    }

    return (
        <Box width="100%">
            {
                !viewUser && <Box>
                    <Stack direction="row" alignItems="center" width="100%" sx={{ p: 1, pl: 0, pr: 2, height: 58 }} spacing={2}>
                        {selectedRows.length === 0 &&
                            <>
                                <Stack direction="row" justifyContent="center" width="auto" sx={{ ml: "16px !important", borderRadius: 24, border: `1px solid ${variables.borderColor}`, cursor: "pointer" }}>
                                    <Box onClick={() => handleTabSelection("active")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "active" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Active</Typography>
                                        <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === "active" ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>
                                            {activeUsersCount}
                                        </Typography>
                                    </Box>
                                    <Box onClick={() => handleTabSelection("inactive")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "inactive" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Inactive</Typography>
                                        <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === "inactive" ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>
                                            {inactiveUsersCount}
                                        </Typography>
                                    </Box>
                                    <Box onClick={() => handleTabSelection("invited")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "invited" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Invited</Typography>
                                        <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === "invited" ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>
                                            {invitedUsersCount}
                                        </Typography>
                                    </Box>
                                    <Box onClick={() => handleTabSelection("deleted")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "deleted" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Deleted</Typography>
                                        <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === "deleted" ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>
                                            {deletedUsersCount}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack direction="row" justifyContent="left" width="40px" height="40px" sx={{ cursor: "pointer" }}>
                                    <Tooltip title="Refresh">
                                        <IconButton sx={{ border: `1px solid ${variables.borderColor}` }} onClick={refreshData}>
                                            <RefreshIcon sx={{ color: `${variables.pipelineBoardText}` }} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>

                                <Stack direction="row" justifyContent="end" height="36px" width="75%" spacing={2}>
                                    <Button variant="contained" sx={{ borderRadius: 10 }} onClick={() => showCreateUser(true)}><AddIcon /> New User </Button>
                                </Stack>
                            </>
                        }

                        {selectedRows.length > 0 &&
                            <>
                                <Box sx={{ p: 0.5, pl: 2 }}>
                                    {selectedTab === "active" &&
                                        <Button variant="contained" onClick={() => { handleDeactivateUsers() }}>
                                            Deactivate
                                        </Button>
                                    }
                                    {selectedTab === "inactive" &&
                                        <Button variant="contained" onClick={() => { handleActivateUsers() }}>
                                            Activate
                                        </Button>
                                    }
                                    {selectedTab === "invited" &&
                                        <Button variant="contained" onClick={() => { handleInviteUsers() }}>
                                            Reinvite
                                        </Button>
                                    }
                                </Box>
                                <Box sx={{
                                    background: `${variables.tabsBackgroundColor}`, borderRadius: 5, width: 'auto', pl: 2.5, pt: 0.5
                                }}>
                                    <Typography component="span" sx={{ color: `${variables.blackTextColor}` }}>
                                        {selectedRows.length} Selected
                                    </Typography>
                                    <IconButton sx={{ ml: 2, mt: '-1px' }} onClick={() => setResetSelection(true)}>
                                        <CloseIcon style={{ fontSize: "17px" }} />
                                    </IconButton>
                                </Box>
                            </>
                        }
                    </Stack>

                    <UserTable selectedTab={selectedTab}
                        rowSelection={(rows) => setSelectedRows(rows)}
                        showSnackbar={(data) => showSnackbar(data)}
                        handleUserViewClick={(user) => { handleUserViewClick(user); }}
                        resetSelection={resetSelection} />

                </Box>
            }
            {
                createUser && <CreateUser handleDrawerClose={(data) => handleCreateUserClose(data)} />
            }

            {
                showActivateUsers && <ActivateUser item={selectedRows} handleClose={() => setShowActivateUsers(false)} />
            }

            {
                showDeactivateUsers && <DeactivateUser isBulkAction={true} item={selectedRows} handleClose={() => setShowDeactivateUsers(false)} />
            }

            {
                viewUser && <ViewUserDetails item={user} />
            }

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

        </Box>
    )
}
