import * as React from 'react';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { Avatar, Divider, IconButton, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { makeStyles } from '@mui/styles';
import EditPipeline from '../../create/EditPipeline';
import { editStagesData } from '../../../../utils/createpipelineformdata';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import AddchartIcon from '@mui/icons-material/Addchart';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import AddIcon from '@mui/icons-material/Add';
import DeleteStage from '../../delete/DeleteStage';
import variables from "../../../../assets/styles/_colors.scss";

const useStyles = makeStyles((theme) => ({
    board: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

export default function PipelineBoardPopover(props) {
    const classes = useStyles();
    const [showDeleteStage, setShowDeleteStage] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [showEditPipeline, setShowEditPipeline] = useState(false);
    const [data, setData] = useState(null);

    const pipelineActions = [
        {
            name: "New Deal",
            icon: <AddIcon />,
            key: 'newDeal'
        },
        {
            name: "New Stage",
            icon: <AddchartIcon />,
            key: "newStage"
        },
        props.index !== props.stagesLength - 1 ? {
            name: "Move Right",
            icon: <LastPageIcon />,
            key: "moveRight"
        } : null,
        props.index !== 0 ? {
            name: "Move Left",
            icon: <FirstPageIcon />,
            key: "moveLeft"
        } : null,
        {
            name: "Collapse Stage",
            icon: <CloseFullscreenIcon />,
            key: "collapseStage"
        },
        {
            name: "Delete Stage",
            icon: <DeleteOutlinedIcon />,
            key: "deleteStage"
        }
    ]

    const handleClose = () => {
        props.resetHover();
        setAnchorEl(null);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "newDeal":
                    return true;
                case "newStage":
                    setShowEditPipeline(true);
                    setData({
                        header: "Manage Stages",
                        defaultValue: editStagesData,
                        moduleIndex: 1
                    });
                    return true;
                case "moveRight":
                    return true;
                case "moveLeft":
                    return true;
                case "collapseStage":
                    props.collapseStage();
                    return true;
                case "deleteStage":
                    setShowDeleteStage(true);
                    return true;
                default:
                    return <></>
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'pipeline-board-popover' : undefined;

    return (
        <>
            <IconButton aria-label="pipeline-board-popover" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.board }}
            >
                {pipelineActions.map((item, index) => (
                    <div key={`pipeline-board-popover-item-${index}`}>
                        {item !== null
                            ? <>
                                < ListItemButton
                                    sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
                                    onClick={() => handleItemClick(index, item)}
                                >
                                    <ListItemAvatar sx={{ minWidth: 46 }}>
                                        <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                                            {item?.icon}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item?.name}
                                    />
                                </ListItemButton >
                                <Divider sx={{ borderBottomWidth: 3 }} /> </>
                            : <></>
                        }
                    </div>
                ))}
            </Popover>
            {showEditPipeline && <EditPipeline data={data} handleDrawerClose={() => setShowEditPipeline(false)} />}
            {showDeleteStage && <DeleteStage stage={props.stage} handleClose={() => setShowDeleteStage(false)}></DeleteStage>}
        </>
    );
}