import React from "react";

import CommonSideBar from "../../components/SideBar";
const MyAccountSideBar = ({ ...rest }) => {

  const links = [
    { path: "/crm/pipeline", label: "Pipelines" },
    { path: "/crm/contacts", label: "Contacts" },
    { path: "/crm/companies", label: "Companies" },
    { path: "/crm/products", label: "Products" },
    { path: "/crm/activities?type=calendar", label: "Activities" },
    { path: "/crm/settings/usercontrols/users?type=active", label: "Settings" },
    { path: "/contacts/dashboard", label: "DashBoard" }
  ];

  return <CommonSideBar links={links} title={"CRM"} />;
};

export default MyAccountSideBar;
