import { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { width } from "@mui/system";
import Mappoper from "./Mappoper";
import { Tab } from "@mui/material";

class TrackeyMap extends Component {
  componentDidMount() {
    document.body.style = "overflow:hidden";
  }

  constructor(props) {
    super(props);
    this.onMarkerClick = this.onMarkerClick.bind(this);
  }

  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    selecteditem: {},
  };

  onMarkerClick(props, marker, e, item) {
    this.setState((prevstate) => {
      return {
        ...prevstate,
        activeMarker: marker,
        showingInfoWindow: true,
        selecteditem: item,
      };
    });
  }

  render() {
    return (
      <div
        id="trackeyMap"
        style={{ height: "calc(100vh - 300px)", width: "calc(100vw - 500px)" }}
      >
        <Map
          google={this.props.google}
          zoom={10}
          style={{
            // height: "calc(100vh - 220px)",
            // width: "calc(100vw - 400px)",
            width: "80%",
            height: "80%",
            position: "relative",
          }}
          initialCenter={{
            lat: 10.9601,
            lng: 78.0766,
          }}
        >
          {this.props.empList?.map((item) => {
            return (
              <Marker
                title={`${item.first_name} ${item.last_name}`}
                name={item.name}
                key={item.emp_id}
                text={"marker"}
                onClick={(props, marker, e) =>
                  this.onMarkerClick(props, marker, e, item)
                }
                position={{
                  lat: item.latitude,
                  lng: item.longitude,
                }}
              />
            );
          })}
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
          >
            <Mappoper selecteditem={this.state.selecteditem} />
          </InfoWindow>
        </Map>
      </div>
    );
  }
}

export { TrackeyMap };

export default GoogleApiWrapper({
  apiKey: "AIzaSyB3ZlA9dlt1bnsJyUPn-Ss3IPM8awi0F2g",
})(TrackeyMap);

// import React, { Component } from 'react';
// import GoogleMapReact from 'google-map-react';

// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// export default class TrackeyMap extends Component {
//   static defaultProps = {
//     center: {
//       lat: 59.95,
//       lng: 30.33
//     },
//     zoom: 11
//   };

//   render() {
//     return (
//       // Important! Always set the container height explicitly
//       <div style={{ height: '100vh', width: '100%' }}>
//         <GoogleMapReact
//           bootstrapURLKeys={{ key: 'AIzaSyAHNn96S1DtaseQZ9baOz0Z-zQN13pk0lY' }}
//           defaultCenter={this.props.center}
//           defaultZoom={this.props.zoom}
//         >
//           <AnyReactComponent
//             lat={59.955413}
//             lng={30.337844}
//             text="My Marker"
//           />
//         </GoogleMapReact>
//       </div>
//     );
//   }
// }
