import { Card, CardActions, CardContent, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";
import { useState } from "react";
import CustomizeFields from "../../pipelines/create/CustomizeFields";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CustomFields from "../../pipelines/create/CustomFields";
import MoveToUnusedFields from "./MoveToUnusedFields";
import { useEffect } from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import EditModuleName from "./EditModuleName";
import EditPipeline from "../../pipelines/create/EditPipeline";
import QuickCreatePreference from "./QuickCreatePreference";
import React from "react";

export default function FieldsConfiguration(props) {

    const [showFieldsForm, setShowFieldsForm] = useState(false);
    const [item, setItem] = useState(null);

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [editData, setEditData] = useState(null);
    const [editListName, setEditListName] = useState(null);
    const [showAddCustomField, setShowAddCustomField] = useState(false);

    const [showRemoveField, setShowRemoveField] = useState(false);
    const [showEditModuleName, setShowEditModuleName] = useState(false);
    const [showPipelineEdit, setShowPipelineEdit] = useState(false);
    const [showQuickCreatePreference, setShowQuickCreatePreference] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        setItem(props.module?.schema);
    }, [props.module]);

    const handleCustomizeFieldsClose = (formData) => {
        setShowFieldsForm(false);
    }

    const removeField = (key, index, item) => {
        setShowRemoveField(true);
    }

    const handleCreateCustomField = (val, data, addMode, listName, reopenModal) => {
        setShowAddCustomField(false);
    }

    const editField = (listName, data) => {
        setEditData(data);
        setEditListName(listName);
        setShowAddCustomField(true);
    }

    const handleEdit = () => {
        if (props.type === "pipeline") {
            setShowPipelineEdit(true);
        } else {
            setShowEditModuleName(true)
        }
    }

    return (
        <>
            <Card sx={{ width: 350, height: "82vh", display: "flex", flexDirection: "column", boxShadow: 'none', mr: 1, border: `1px solid ${variables.borderColor}` }} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                <CardContent sx={{ p: 0, overflowX: 'hidden', overflowY: 'auto', height: '76vh', pb: 1 }}>
                    <Stack direction="row" alignItems="center" sx={{ backgroundColor: `${variables.lightGreyBackgroundColor}`, height: 50, pt: 2, pb: 2, pl: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', width: '80%' }}>
                            {props.module?.label}
                        </Typography>
                        <Tooltip title={"Edit Module Name"} sx={{ visibility: isHovering ? 'inherit' : 'hidden' }}>
                            <Typography component="span" >
                                <IconButton aria-label="edit" size="medium"
                                    onClick={handleEdit}>
                                    <EditOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Typography>
                        </Tooltip>
                        <Tooltip title={"Quick Create Preference"} sx={{ visibility: isHovering ? 'inherit' : 'hidden' }}>
                            <Typography component="span" >
                                <IconButton aria-label="quick-preference" size="medium"
                                    onClick={() => setShowQuickCreatePreference(true)}>
                                    <SettingsIcon fontSize="inherit" />
                                </IconButton>
                            </Typography>
                        </Tooltip>
                    </Stack>
                    {item &&
                        <>
                            {Object.keys(item.fields).map((key, parentIndex) => {
                                return <React.Fragment key={parentIndex}>
                                    {key !== "unUsedFields"
                                        && item.fields[key].fields.length > 0
                                        && <Stack direction="column" spacing={1}>
                                            <Typography sx={{ fontWeight: 'bold', pt: 1, pl: 2, pb: 0.5 }}>
                                                {item.fields[key].name}
                                            </Typography>
                                            {item.fields[key].fields.map((field, index) => {
                                                return <Typography component="div" key={index}
                                                    sx={{ backgroundColor: hoveredIndex === `${key}-${index}` && `${variables.lightGreyBackgroundColor}`, pl: 2, pr: 2, pt: 0.75, pb: 0.75, m: '0 !important' }}
                                                    onMouseEnter={() => setHoveredIndex(`${key}-${index}`)} onMouseLeave={() => setHoveredIndex(null)}>
                                                    <Stack direction="row" alignItems="center"
                                                        sx={{
                                                            p: 1, border: `1px solid ${variables.borderColor}`, borderRadius: 1, borderLeft: field?.rules?.required?.value && '3px solid red', height: 36
                                                        }}>
                                                        {field.label}
                                                        {field.unique &&
                                                            <Typography sx={{ fontSize: "smaller", pl: 1, lineHeight: 1.9, color: `${variables.greyTextColor}` }}>(Unique)</Typography>}
                                                        {field.customField &&
                                                            <Typography component="span" sx={{
                                                                borderRadius: "50%",
                                                                height: "8px", width: "8px", display: "inline-block", backgroundColor: `${variables.addIconColor}`
                                                            }}></Typography>}
                                                        {hoveredIndex === `${key}-${index}` &&
                                                            <Typography component="span" style={{ marginLeft: "auto" }}>
                                                                {(field.editable || field.customField)
                                                                    && <Tooltip title={"Edit"}>
                                                                        <Typography component="span" >
                                                                            <IconButton aria-label="edit" color="primary" size="small"
                                                                                onClick={() => editField(key, field)}>
                                                                                <EditOutlinedIcon fontSize="inherit"></EditOutlinedIcon>
                                                                            </IconButton>
                                                                        </Typography>
                                                                    </Tooltip>}
                                                                {(field.deletable || field.customField)
                                                                    && <Tooltip title={"Remove"}>
                                                                        <Typography component="span" >
                                                                            <IconButton aria-label="remove" size="small"
                                                                                onClick={() => removeField(key, index, field)}>
                                                                                <RemoveCircleOutlineIcon fontSize="inherit" color="error"></RemoveCircleOutlineIcon>
                                                                            </IconButton>
                                                                        </Typography>
                                                                    </Tooltip>
                                                                }
                                                            </Typography>}
                                                    </Stack>
                                                </Typography>
                                            })}
                                        </Stack>
                                    }
                                </React.Fragment>
                            })}

                            {item.products.showProducts && item.products.data.length > 0 &&
                                <Stack direction="column" spacing={1}>
                                    <Typography sx={{ fontWeight: 'bold', pt: 1, pl: 2, pb: 0.5 }}>
                                        Associated Products
                                    </Typography>
                                    {item.products.data.map((product, index) => {
                                        return <Stack direction="row" alignItems="center" spacing={1} key={index}
                                            sx={{
                                                p: 1, border: `1px solid ${variables.borderColor}`, borderRadius: 1, height: 36, ml: "16px !important", mr: "16px !important"
                                            }}>
                                            {product.label}
                                        </Stack>
                                    })}
                                </Stack>}
                        </>
                    }
                </CardContent>

                <CardActions disableSpacing sx={{ p: "4px 8px", borderTop: `1px solid ${variables.lightBorderColor}` }}>
                    <Typography
                        onClick={() => setShowFieldsForm(true)} sx={{ pl: 1.5, pt: 0.5, pb: 0.5, color: "#3d5af1", cursor: "pointer", textDecoration: "underline" }}>
                        Customize Fields
                    </Typography>
                </CardActions>

            </Card>

            {showEditModuleName && <EditModuleName item={props.module} handleDialogClose={() => setShowEditModuleName(false)} />}

            {showQuickCreatePreference && <QuickCreatePreference close={() => setShowQuickCreatePreference(false)} module={props.module} />}

            {showPipelineEdit && <EditPipeline data={{
                header: "Edit Basic Info",
                defaultValue: props.module,
                moduleIndex: 0
            }} handleDrawerClose={() => setShowPipelineEdit(false)} />}

            {showAddCustomField && <CustomFields listName={editListName} addMode={false} data={editData}
                handleDrawerClose={(formData, addMode, listName, reopenModal) => handleCreateCustomField(false, formData, addMode, listName, reopenModal)}
            />}

            {showRemoveField && <MoveToUnusedFields handleClose={() => setShowRemoveField(false)} />}

            {showFieldsForm && <CustomizeFields
                open={true} handleDrawerClose={(formData) => handleCustomizeFieldsClose(formData)}
                // defaultValues={{ "fields": getValues("fields"), "products": getValues("products") }}
                title={`Edit ${props.module?.singular} Fields`} />}
        </>
    )
}