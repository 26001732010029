import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { Drawer, Button, TextField, FormControlLabel, Checkbox, Stack, Typography } from '@mui/material';
import { CloseButton } from '../../../components/CloseButton';
import variables from '../../../assets/styles/_colors.scss';

export default function PicklistManualOptions(props) {

    const [open,] = useState(true);
    const [data, setData] = useState("");
    const [replaceExistingOptions, setReplaceExistingOptions] = useState(false);

    function handleDrawerClose() {
        props.handleDrawerClose();
    }

    const getData = () => {
        return data.split("\n").filter(line => line.trim() !== "");
    }

    const saveOptions = () => {
        const optionsArray = getData().map(item => {
            return {
                label: item,
                value: item
            }
        })
        props.saveManualEntryOptions({
            options: optionsArray,
            replaceExistingOptions: replaceExistingOptions
        });
        handleDrawerClose();
    }

    return (
        <div>
            <React.Fragment>
                <Drawer anchor="right" open={open}
                    sx={{ minWidth: "35vw", maxWidth: "35vw" }}
                    PaperProps={{
                        sx: { minWidth: "35vw", maxWidth: "35vw", pt: 2, pb: 2, borderTop: `2px solid ${variables.borderColor}` },
                    }}>
                    <Typography variant="h5" sx={{ pl: 2 }}>Manual Entry
                        <CloseButton handleDialogClose={handleDrawerClose} />
                    </Typography>
                    <Box sx={{ width: '100%', pl: 2, pr: 2, pt: 2 }}>
                        <Typography sx={{ fontWeight: 600, pb: 2 }}>
                            Type in or paste your options (each in a new line)
                        </Typography>
                        <TextField
                            id="custom-field-add-bulk-manual-multiline"
                            placeholder="Please enter your values sequentially"
                            multiline
                            fullWidth
                            rows={10}
                            onChange={(event) => {
                                setData(event.target.value);
                            }}
                        />
                    </Box>
                    <Stack direction="row" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Stack direction="row" justifyContent="left" sx={{ width: "50%" }}>
                            <FormControlLabel control={<Checkbox checked={replaceExistingOptions} onChange={() => setReplaceExistingOptions(!replaceExistingOptions)} />}
                                label="Replace existing options" />
                        </Stack>
                        <Stack direction="row" spacing={2} justifyContent="right" sx={{ width: "50%" }}>
                            <Button variant="outlined" onClick={handleDrawerClose}> Cancel </Button>
                            <Button onClick={() => saveOptions()} disabled={getData().length === 0} variant="contained"> Add Options </Button>
                        </Stack>
                    </Stack>
                </Drawer>
            </React.Fragment>
        </div >
    );
}
