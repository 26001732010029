import {  Typography } from "@mui/material";
import React, { useState } from "react";
import { loadingAll } from "../../../../../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";


const style = {
  width: "100%",
  padding: 20,
  paddingTop: 0,
};

const gridLastCellStyle = {
  border: "1px solid #e9e9eb",
  textAlign: "center",
  display: "flex",
};
const SoaRecord = () => {
  const [isLoading] = useRecoilState(loadingAll);

  const [isThirdGridOpen, setThirdGridOpen] = useState(false);

  const toggleThirdGrid = () => {
    // Toggle the state when the toggle button is clicked
    setThirdGridOpen(!isThirdGridOpen);
  };

  const [isEditable, setEditable] = useState(false);

  const toggleEditable = () => {
    // Toggle the state when the toggle button is clicked
    setEditable(!isEditable);
    setThirdGridOpen(!isThirdGridOpen);
  };

  const gridCellStyle = {
    border: "1px solid #e9e9eb",
    textAlign: "center",
    borderBottom: `${isThirdGridOpen ? "none" : "1px solid #e9e9eb"}`,
    alignItems: "center",
    display: "flex",
  };
  return (
    <div style={style}>
      {/* <Typography
        variant="subtitle2"
        style={{ margin: "10px 0 10px 0", fontSize: 12, fontWeight: "bold" }}
      >
        {" "}
        CURRENT RECORDS (0)
      </Typography>

      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            backgroundColor: "#fcfcfc",
            borderLeft: "2px solid #28bd8b",
            width: "80%",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={6} style={gridCellStyle}>
              {isEditable ? (
                <div style={{ display: "flex" }}>
                  <label className="cd-title">DOMAIN/SUB-DOMAIN</label>
                  <input className="cd-input" value={""} />
                </div>
              ) : (
                <p style={{ fontSize: "14px", padding: "10px" }}>
                  nowdigitaleasy.com
                </p>
              )}
            </Grid>
            <Grid item xs={6} style={gridCellStyle} display={"flex"}>
              <div
                style={{
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ fontSize: "small" }}>A</p>
              </div>
              <span
                style={{ width: 1, height: 20, backgroundColor: "#0000005c" }}
              ></span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "53px",
                  alignItems: "center",
                }}
              >
                {isEditable ? (
                  <div style={{ display: "flex" }}>
                    <label className="cd-title">IPV4-ADDRESS*</label>
                    <input className="cd-input" value={""} />{" "}
                  </div>
                ) : (
                  <p style={{ fontSize: "14px", padding: "10px" }}>
                    nowdigitaleasy.com
                  </p>
                )}
              </div>

              <div onClick={toggleThirdGrid}>
                {isThirdGridOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
            </Grid>

            {isThirdGridOpen && (
              <Grid item xs={12} style={gridLastCellStyle}>
                <div style={{ display: "flex" }}>
                  <label className="cd-title">TTL(Time To Live)</label>
                  <select
                    style={{ width: 200 }}
                    className="cd-input"
                    value={""}
                  >
                    <option>4 hours</option>
                    <option>6 hours</option>
                    <option>1 day</option>
                  </select>
                </div>
              </Grid>
            )}
          </Grid>
        </div>

        {isEditable ? (
          <div
            style={{
              width: "20%",
              display: "flex",
              height: "auto",
              alignItems: "flex-start",
            }}
          >
            <LoadingButton
              loading={isLoading}
              className="loadingButton1"
              style={{ backgroundColor: " #468def" }}
              // onClick={props.handleTheftProtection}
            >
              SAVE
            </LoadingButton>

            <LoadingButton
              loading={isLoading}
              className="loadingButton1"
              style={{ color: " #468def" }}
              onClick={toggleEditable}
            >
              Cancel
            </LoadingButton>
          </div>
        ) : (
          <div
            style={{
              width: "20%",
              display: "flex",
              height: "auto",
              alignItems: "flex-start",
            }}
          >
            <IconButton onClick={toggleEditable} style={{ margin: 10 }}>
              <img src={edit} alt="edit" />
            </IconButton>
            <IconButton style={{ margin: 10 }}>
              <img src={deleteicon} alt="delete" />
            </IconButton>
          </div>
        )}
      </div> */}

      <Typography
          variant="subtitle2"
          style={{ margin: "30px 0 10px 0", fontSize: 12, fontWeight: "bold" }}
        >
          {" "}
          CURRENT RECORDS (0)
        </Typography>
        <p style={{ fontSize: 12 }}> You have not added any SRV records</p>

      {/* <p style={{ fontSize: 12 }}> You have not added any A records</p> */}
      <style>
        {`.loadingButton{
                                  color: rgb(255, 255, 255);
                                  background-color: #468def;
                                  margin-top: 20px;
                                  cursor: pointer;
                                  padding:15px 25px;
                                  font-size:12px;
                                  width:max-content;
                                  border-radius:0px
                              }
                              .loadingButton1{
                                color:#fff;
                                font-size:12px;
                                width:max-content;
                                border-radius:0px;
                                margin:10px
                             }
                              `}
      </style>
    </div>
  );
};

export default SoaRecord;
