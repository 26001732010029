import { Alert, Divider, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import userFollowing from "../../images/user-following.png";
import { profile_trigger, selected_contact } from "../../Atoms/LoginAtoms";
import { api_url } from "../../globalSettings";
import { useRecoilState } from "recoil";
import axios from "axios";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import trash from "../../images/trash.png";

const Box = styled.div`
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Typography = styled.div`
  color: #dd352c;
  font: 12px;
`;

const Typography1 = styled.div`
  color: #44b6ae;
  font-weight: 600;
`;

const TableContainer = styled.div`
  width: 100%;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TableLeft = styled.div`
  margin: 20px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f5f6fa;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableRight = styled.div`
  background-color: #f5f6fa;
  height: auto;
  padding: 20px;
  width: 80%;
  cursor: pointer;
`;

const TableRightTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const TableRow1 = styled.div`
  width: 60%;
  padding: 5px;
`;

const SidebarTab = styled.div`
  background-color: #f5f6fa;
  height: auto;
  padding: 20px;
  width: 80%;
  cursor: pointer;
  cursor: pointer;
  color: black;
  border-right: 2px solid white;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  align-items: center;
  transition: background-color 0.3s;
  ${(props) => props.active && "background-color: #f0f0f0;"}
`;

const SettingsIcon = styled(EditOutlinedIcon)`
  visibility: hidden;
  color: #00000066;
  font-size: 16px;
`;

const HoverableSidebarTab = styled(SidebarTab)`
  &:hover {
    ${SettingsIcon} {
      visibility: visible;
    }
  }
`;

const ContactNotes = () => {
  const [selectedContactDetails, setSelectedContactDetails] = useState([]);
  const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
  const [selectedContact] = useRecoilState(selected_contact);
  const [isHovered, setIsHovered] = useState(null);
  const [snackbaropen, setSnackbaropen] = React.useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    axios
      .get(
        `${api_url}/crm_customer/getCustomerByCustomerId/${selectedContact._id}`
      )
      .then((responce) => {
       
        setSelectedContactDetails(responce.data.data[0].add_note.reverse());
      })
      .catch((err) => {
      });
  }, [selectedContact, profileTrigger]);

   //   ==========================  REMOVE LABEL =============================

   const removeNotes = (event, Id) => {
    event.stopPropagation();
   
    const params = {
      customer_id: selectedContact._id,
      noteId: Id,
    };
    axios
      .patch(`${api_url}/crm_customer/deleteNoteById`, params)
      .then((result) => {
        setSnackbaropen({
          open: true,
          message: result.data.message,
          severity: "error",
          actionColor: "#fdeded",
        });
        setProfileTrigger(!profileTrigger);
      })
      .catch((error) => {
        if (error.response) {
         
        } else if (error.request) {
          
        } else {
          
        }
      });
  };


  return (
    <Box>
       <Snackbar
open={snackbaropen.open}
onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
autoHideDuration={6000}
>
<Alert
  severity={snackbaropen.severity}
  style={{ backgroundColor: `${snackbaropen.actionColor}` }}
>
  {snackbaropen.message}
</Alert>
</Snackbar>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginBottom: "5px",
        }}
      >
        <Typography>Last 5 Notes</Typography>
      </div>
      <Divider />


      {selectedContactDetails.length > 0
        ? selectedContactDetails.slice(0, 5).map((item) => {
            return (
              <TableContainer>
                <TableLeft>
                  <img
                    src={userFollowing}
                    alt="userFollowing"
                    width={40}
                    height={40}
                    style={{ position: "relative", top: "-5px" }}
                  />
                </TableLeft>
                <HoverableSidebarTab
                  onMouseEnter={() => setIsHovered(item._id)}
                  onMouseLeave={() => setIsHovered(null)}
                >
                  <div>
                    <TableRightTitle>
                      <Typography1>{selectedContact.company_name}</Typography1>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#00000066",
                          paddingLeft: "10px",
                        }}
                      >
                        {new Date(item.date)
                          .toLocaleDateString()
                          .split("/")
                          .join("-")}{" "}
                        {new Date(item.date).toLocaleTimeString()}
                      </span>
                    </TableRightTitle>
                    {item.description}
                  </div>
                  {isHovered === item._id && (
                    <div style={{ display: "flex" }}>
                     
                      <div
                        className="delete-icon"
                        style={{
                          position: "relative",
                          top: "-10px",
                          right: "0px",
                          borderRadius: 5,
                        }}
                        onClick={(e) => removeNotes(e, item._id)}
                      >
                        <img src={trash} width={15} height={15} alt="delete" />
                      </div>
                    </div>
                  )}
                </HoverableSidebarTab>
              </TableContainer>
            );
          })
        : ""}
    </Box>
  );
};

export default ContactNotes;
