import { useState } from "react";
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getCompanyColumns } from "../../webservices/PipelineService";
import { companies_atom } from "../../../../Atoms/CrmAtoms";
import CompanyActions from "./CompanyActions";
import CompaniesTable from "./view/table/CompaniesTable";

export default function CrmCompanies() {

    const [companiesAtom, setCompaniesAtom] = useRecoilState(companies_atom);

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [companyCols, setCompanyCols] = useState([]);

    useEffect(() => {

        if (companiesAtom.displayedColumns.companiesTable.length === 0) {
            getCompanyColumns().then((response) => {
                persistData(response.data);
            });
        } else {
            setCompanyCols(companiesAtom.displayedColumns.companiesTable);
        }

    }, []);

    const persistData = (cols) => {
        if (cols?.length > 0) {
            const obj = {
                ...companiesAtom,
                "displayedColumns": {
                    ...companiesAtom["displayedColumns"],
                    "companiesTable": cols
                }
            }
            setCompaniesAtom(obj);
            setCompanyCols(cols);
        }
    }

    return (
        <Box sx={{ ml: isFilterOpen ? '22vw' : 0 }}>
            <CompanyActions isFilterOpen={(val) => setIsFilterOpen(val)} />
            {companyCols.length > 0 && <CompaniesTable columns={companyCols} drawerOpen={isFilterOpen} />}
        </Box>
    );
}
