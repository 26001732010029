import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { Divider, ListItemButton, ListItemText } from '@mui/material';
import variables from "../../../assets/styles/_colors.scss";
import AddIcon from '@mui/icons-material/Add';
import { withStyles } from "@mui/styles";

const OptionsInBulkTypography = withStyles({
    root: {
        color: `${variables.linkColor}`,
        cursor: 'pointer',
        "&:hover": {
            textDecoration: 'underline'
        }
    }
})(Typography);

export default function PicklistBulkOptionsPopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const bulkActions = [
        {
            name: "Load Predefined Options",
            key: 'predefinedOptions'
        },
        {
            name: "Type your own options",
            key: "manualEntry"
        }
    ];

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "predefinedOptions":
                    props.openPredefinedOptions();
                    return true;
                case "manualEntry":
                    props.openManualEntry();
                    return true;
                default:
                    return <></>
            }
        }
    }

    const open = Boolean(anchorEl);
    const id = open ? 'associated-products' : undefined;

    return (
        <div style={{ 'display': 'inline-block' }}>
            <OptionsInBulkTypography onClick={handleClick}>
                <AddIcon sx={{ mr: 0.5 }} />Options in Bulk
            </OptionsInBulkTypography>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {bulkActions.map((item, index) => (
                    <>
                        {item !== null
                            ? <>
                                < ListItemButton
                                    key={item?.name}
                                    sx={{ p: 1 }}
                                    onClick={() => handleItemClick(index, item)}
                                >
                                    <ListItemText
                                        primary={item?.name}
                                    />
                                </ListItemButton >
                                <Divider sx={{ borderBottomWidth: 3 }} /> </>
                            : <></>
                        }
                    </>
                ))}
            </Popover>
        </div>
    );
}