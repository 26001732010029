import { createColumnHelper } from "@tanstack/react-table";
const columnHelper = createColumnHelper();
export const columnDef = [
  {
    accessorKey: "website_name",
    header: "Website Name",
  },
  {
    accessorKey: "website_url",
    header: "Website URL",
  },
  {
    accessorKey: "actions",
    header: "Actions",
  },
];

export const columnDefWithGrouping = [
  columnHelper.accessor("id", {
    header: "Id",
  }),
  {
    header: "Website Details",
    columns: [
      {
        accessorKey: "name",
        header: "Website Name",
      },
      {
        accessorKey: "url",
        header: "Website URL",
      },
      {
        accessorKey: "actions",
        header: "Actions",
      },
    ],
  },
];
