import axios from "axios";
import React, { useEffect, useState } from "react";
import TrackeyEmpListComp from "../common/TrackeyEmpListComp";
import * as icons from "@mui/icons-material";
import { Typography,  Stack, IconButton} from "@mui/material";

import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import WifiIcon from "@material-ui/icons/Wifi";
import NativeSelect from "@mui/material/NativeSelect";
import BatteryGauge from "react-battery-gauge";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";

import {

  login_token,
  login_companyid,
 
  empployeeSearch
} from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import { api_url } from "../../globalSettings";
import {getDynamicNamespaceSocket} from "../../config";


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export default function TrackeyEmployeeList(props) {

  const dynamicNamespaceSocket = getDynamicNamespaceSocket();

  const { totemplist, empListonduty, empListoffduty, emponlvelist , empnodatalist} = props;

   const [empList, getemployeelist] = useState([]);

  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);


   //================================  SEARCH BOX ====================================




  const [Getlist, setGetlist] = useState([]);
  const [branchid, setBranchid] = useState("");
  const [selectedBranchId, setSelectedBranchId] = useState("All");


  function getBranchList() {
   
    const params = {
      company_id: company_idd,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
        setBranchid(responce.data.data[0]._id);
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    getBranchList();
  }, [company_idd]);

  const handleChange = (event) => {
    setSelectedBranchId(event.target.value);
    
  };

   // ================================  total api call =====================================

   

   const gettotalemplist = () => {
    
    const params = {
      token: loginToken1,
      company_id: company_idd,
    };
    
    axios
    // https://server.nowdigitaleasy.com:3505/employee/totalEmployee?
      .get(`${api_url}/employee/getAllEmployeeByCompany`, {
        params: { ...params },
      })

      .then((response) => {
     
        getemployeelist(response.data.data);
        
      })
      .catch(function (error) {
        if (error.response) {
        
        } else if (error.request) {
     
        } else {
         
        }
        
      });
    
  }


  useEffect (() => {
    gettotalemplist();
  } ,[])

  // ======================================= FILTER SEARCH =================================================


  useEffect(() => {
    if(totemplist.all_employee_details){
      setActiveCompany(totemplist.all_employee_details[0]?.department_id._id);
    }
    if(empListonduty.on_duty_employee_details){
      setActiveCompany(empListonduty.on_duty_employee_details[0]?.department_id._id);
    }
    if(empListoffduty.off_duty_employee_details){
      setActiveCompany(empListoffduty.off_duty_employee_details[0]?.department_id._id);
    }
    if(emponlvelist.leave_employee_details){
      setActiveCompany(emponlvelist.leave_employee_details[0]?.department_id._id);
    }
    if(empnodatalist.noData_employee_details){
      setActiveCompany(empnodatalist.noData_employee_details[0]?.department_id._id);
    }
  },[])



  const [activeCompany, setActiveCompany] = useState(null);

  const handleCompanyClick = (departmentId) => {
  
    if (activeCompany === departmentId) {
      setActiveCompany(null);
      dynamicNamespaceSocket.emit('leaveRoom',departmentId);
    } else {
      dynamicNamespaceSocket.emit('joinRoom',departmentId)
      setActiveCompany(departmentId);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div id="trackeyEmployeeList">
     
     <FormControl
                sx={{ mb: 1 }}
                variant="standard"
                style={{ width: "100%" }}
                size="small"
              >
                
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                   <option value={null}>All</option>
                  {Getlist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>
                      </>
                    );
                  })}
                </NativeSelect>
              </FormControl>

     <div
      className="trackey-emp-list-comp1"
    >
      
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
      <Stack direction="row"  width={100} alignItems="center" spacing={1}>
         <Typography style={{ fontSize: 12 ,fontWeight : 600 }} variant="body2">

            Employees
          </Typography> 
        </Stack>
            <Stack
          direction={"row"}
          alignItems="center"
          width={120}
          justifyContent="space-between"
        >
          
            <Stack width={50}  style={{borderRight : "1px solid rgba(0, 0, 0, 0.321)" , borderLeft : "1px solid rgba(0, 0, 0, 0.321)"}} direction="row" justifyContent="center">
                <WifiIcon fontSize="small" />
             
            </Stack>
            <Stack width={50}  style={{borderRight : "1px solid rgba(0, 0, 0, 0.321)"}} direction="row" justifyContent="center">
                <SignalCellular4BarIcon fontSize="small" color="success" />
            </Stack>
            <Stack width={50}  style={{borderRight : "1px solid rgba(0, 0, 0, 0.321)"}} direction="row" justifyContent="center">
              <BatteryGauge
                size={16}
                orientation="vertical"
                // value={props.item.mobile_battery_strength}
              />
            </Stack>
          <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            style={{ padding: 0, marginRight: 10}}
          >
            <icons.MoreVert fontSize="small" />
          </IconButton>
   
        </Stack>

        </Stack>
        </div>
      {totemplist.all_employee_count ||
      empListonduty.on_duty_employee_count ||
      empListoffduty.off_duty_employee_count ||
      emponlvelist.leave_employee_count ||
      empnodatalist.noData_employee_count > 0 ? (
        (
          totemplist.all_employee_details ||
          empListonduty.on_duty_employee_details ||
          empListoffduty.off_duty_employee_details ||
          emponlvelist.leave_employee_details ||
          empnodatalist.noData_employee_details
        ).filter((branch) => selectedBranchId == "All" || branch.branch_id._id === selectedBranchId).map((item, index) => {
          return <>
        
         <div className="faq-dropdown">
    {/* {companies.map((company) => ( */}
      <div key={item.department_id._id}>
        <div
          className="company-container"
          onClick={() => handleCompanyClick(item.department_id._id)}
        >
          <div className="company-name1">{item.department_id.department_name}</div>
          <div className="expand-icon">
            {activeCompany === item.department_id._id ? "-" : "+"}
          </div>
        </div>
        {activeCompany === item.department_id._id && (
          <ul className="employees-list">{item.employes.map((item) => (
            <li key={item}>

              <TrackeyEmpListComp key={`empList${index}`} item={item} />
              </li>
            ))}
          </ul>
        )}
      </div>
    {/* ))} */}
  </div>
  
              </>
      
        })
      ) : (
"")}
{/* <TrackeyEmpListComp  /> */}
    </div>
  
  );
}
