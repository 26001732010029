import * as React from 'react';
import Box from '@mui/material/Box';
// import { DataGrid } from '@mui/x-data-grid';
import { getActivityColumns, getCallTableData, getEventTableData, getTaskTableData } from '../../../webservices/PipelineService';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Stack } from '@mui/material';
import DeleteDeal from '../delete/DeleteDeal';
import UpsertDeal from '../create/UpsertDeal';
import variables from '../../../assets/styles/_colors.scss';
import TableHeader from '../../../components/nativetable/TableHeader';
import { useSortableTable } from '../../../components/nativetable/useSortableTable';
import TableRow from '../../../components/nativetable/TableRow';

export default function ActivitiesTable(props) {

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [editDeal, setEditDeal] = useState(false);
    const [deleteDeal, setDeleteDeal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const transformColumns = (cols) => {
        let obj = [];
        cols.map((col, index) => {
            // col.selected &&
                obj.push({
                    // field: col.accessorKey,
                    // headerName: col.header,
                    // width: 150,
                    // cellClassName: index === 0 && ((params) => {
                    //     return 'firstColumn'
                    // })
                    label: col.header,
                    accessor: col.accessorKey,
                    sortable: true
                })
        });
        // obj.push({
        //     field: "actions",
        //     headerName: "",
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <Box
        //                 sx={{
        //                     backgroundColor: "whitesmoke",
        //                     width: "100%",
        //                     height: "100%",
        //                     display: "flex",
        //                     justifyContent: "center",
        //                     alignItems: "center"
        //                 }}
        //             >
        //                 <IconButton onClick={() => { setSelectedRow(params.row); setEditDeal(true) }}>
        //                     <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
        //                 </IconButton>
        //                 <IconButton onClick={() => { setSelectedRow(params.row); setDeleteDeal(true) }}>
        //                     <DeleteOutlineIcon fontSize='medium' color="error" />
        //                 </IconButton>
        //             </Box>
        //         );
        //     }
        // })
        setColumns(obj);
    }

    useEffect(() => {
        getActivityColumns(props.module).then((colResponse) => {
            transformColumns(colResponse.data);
            props.module === "tasks" && getTaskTableData(0, 10).then((response) => {
                setRows(response.rows);
            });
            props.module === "events" && getEventTableData(0, 10).then((response) => {
                setRows(response.rows);
            });
            props.module === "calls" && getCallTableData(0, 10).then((response) => {
                setRows(response.rows);
            });
        });
    }, [props.module]);

    useEffect(() => {

    }, [props.view]);

    useEffect(() => {

    }, [rows]);

    const [tableData, handleSorting] = useSortableTable(rows, columns);

    return (
        <Box sx={{ height: props.view === "pipeline" ? '58.5vh' : '68vh', width: '100%' }}>
            <table id="nativeTable">
                <TableHeader {...{ columns, handleSorting }} />
                <tbody>
                    {tableData.map((data) => {
                        return (
                            <TableRow data={data} columns={columns} />
                        );
                    })}
                </tbody>
            </table>
            {/* <DataGrid
                columnHeaderHeight={40}
                rowHeight={48}
                rows={rows}
                columns={columns}
                sx={{
                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                        fontSize: 14
                    },
                    "& .MuiDataGrid-cellContent": {
                        fontSize: 14
                    },
                    '& .firstColumn': {
                        fontWeight: 'bold'
                    }
                }}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 100,
                        },
                    },
                }}
                slots={{
                    noRowsOverlay: () => (
                        <Stack alignItems="center" justifyContent="center" sx={{ pt: 3, fontSize: 13.5 }}>
                            This record doesn't have any {props.module}
                        </Stack>
                    )
                }}
                pageSizeOptions={[100, 200]}
                disableRowSelectionOnClick
                disableColumnMenu
            /> */}
            {deleteDeal && <DeleteDeal item={{ name: selectedRow?.id }} handleClose={() => { setDeleteDeal(false); }} />}
            {editDeal && <UpsertDeal edit={true} handleDrawerClose={() => { setEditDeal(false); }} />}
        </Box>
    );
}
