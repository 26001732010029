import axios from 'axios';
import { crm_api_url } from '../../../globalSettings';

export const getAllProfiles = async () => {

    const url = `${crm_api_url}/profiles`;
    return axios.get(url).then(({ data }) => data);

};

export const createProfile = async (data) => {
    const url = `${crm_api_url}/profiles`;
    return axios.post(url, data).then(({ data }) => data);
}

export const editProfile = async (profileId, data) => {
    const url = `${crm_api_url}/profiles/${profileId}`;
    return axios.put(url, data).then(({ data }) => data);
}

export const getProfilesDropdown = async (profileId) => {
    const url = `${crm_api_url}/profiles/dropdown`;
    return axios.get(url).then(({ data }) => data);
}

export const getProfileUsers = async (profileId) => {
    const url = `${crm_api_url}/profiles/${profileId}/users`;
    return axios.get(url).then(({ data }) => data);
}

export const deleteProfile = async (profileId, profileName) => {
    const url = `${crm_api_url}/profiles/${profileId}`;
    return axios.delete(url, { data: { profileName: profileName } }).then(({ data }) => data);
}

export const getAllRoles = async () => {

    const url = `${crm_api_url}/roles`;
    return axios.get(url).then(({ data }) => data);

};

export const createRole = async (data) => {
    const url = `${crm_api_url}/roles`;
    return axios.post(url, data).then(({ data }) => data);
}

export const editRole = async (roleId, data) => {
    const url = `${crm_api_url}/roles/${roleId}`;
    return axios.put(url, data).then(({ data }) => data);
}

export const deleteRole = async (roleId, data) => {
    const url = `${crm_api_url}/roles/${roleId}`;
    return axios.delete(url, { data: data }).then(({ data }) => data);
}

export const getRoleUsers = async (roleId) => {
    const url = `${crm_api_url}/roles/${roleId}/users`;
    return axios.get(url).then(({ data }) => data);
}

export const createUser = async (data) => {
    const url = `${crm_api_url}/users`;
    return axios.post(url, data).then(({ data }) => data);
}

export const getUsersTableData = async (type, start, limit, sorting) => {
    // const url = `${crm_api_url}/users/table/index/${start}/size/${limit}`;
    const url = `${crm_api_url}/users`;
    return axios.get(url, { params: { status: type, limit: limit, offset: start } }).then(({ data }) => data);
};

export const getUsersCount = async () => {
    const url = `${crm_api_url}/users/count`;
    return axios.get(url).then(({ data }) => data);
};

export const editUser = async (userId, data) => {
    const url = `${crm_api_url}/users/${userId}`;
    return axios.put(url, data).then(({ data }) => data);
}

export const getUserById = async (userId) => {
    const url = `${crm_api_url}/users/${userId}`;
    return axios.get(url).then(({ data }) => data);
};

export const deleteUser = async (userId) => {
    const url = `${crm_api_url}/users/${userId}`;
    return axios.delete(url).then(({ data }) => data);
}

export const inviteUser = async (userId) => {
    // const url = `${crm_api_url}/users/${userId}/invite`;
    const url = `${crm_api_url}/users/${userId}/activate`;
    return axios.put(url).then(({ data }) => data);
}

export const inviteUsers = async (userIds) => {
    const url = `${crm_api_url}/users/activate`;
    return axios.put(url, userIds).then(({ data }) => data);
}

export const activateUser = async (userId) => {
    const url = `${crm_api_url}/users/${userId}/activate`;
    return axios.put(url).then(({ data }) => data);
}

export const activateUsers = async (userIds) => {
    const url = `${crm_api_url}/users/activate`;
    return axios.put(url, userIds).then(({ data }) => data);
}

export const deactivateUser = async (userId) => {
    const url = `${crm_api_url}/users/${userId}/deactivate`;
    return axios.put(url).then(({ data }) => data);
}

export const deactivateUsers = async (userIds) => {
    const url = `${crm_api_url}/users/deactivate`;
    return axios.put(url, userIds).then(({ data }) => data);
}

export const getUsersDropdown = async (type) => {
    const url = `${crm_api_url}/users/dropdown`;
    return axios.get(url, { params: { status: type } }).then(({ data }) => data);
}