import React, { useState } from "react";
import { Modal, Box, IconButton, Grid, Stack, Typography } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";

import netbankingImg from "../../../../../images/ProcessUi/netbanking.svg";
import creditCardImg from "../../../../../images/ProcessUi/creditcard.svg";
import walletImg from "../../../../../images/ProcessUi/wallet.svg";
import debitImg from "../../../../../images/ProcessUi/debitcard.svg";
import upiImg from "../../../../../images/ProcessUi/upi.svg";
import chequeImg from "../../../../../images/ProcessUi/cheque.svg";
import axios from "axios";
import { api_url1 } from "../../../../../globalSettings";
import { displayRazorpay } from "../../../CustomHook/RazorPay/RazorPay";
import { LoadingButton } from "@mui/lab";
import { PriceFormat } from "../../../CustomHook/DateFormat";
import Checkbox from "@mui/material/Checkbox";
import WalletDialog from "../../WalletDialog";
import IsWallet from "../../../CustomHook/IsWallet";
import PayViaWallet from "../../../CustomHook/PayViaWallet";

import RenewalStyle from "../Styles/RenewalPayment.module.css";
import RenewalButton from "./RenewalButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "8px",
};

const RenewalPayment = ({
  handleCloseModal,
  open,
  fullAmount,
  currencyCode,
  walletBalance,
  proceedFunc,
  loading
}) => {
  const [scroll, setScroll] = React.useState("paper");
  const [selectedTab, setSelectedTab] = useState(null);
  const handleTabClick = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const tabData = [
    {
      title: "Netbanking",
      des: "Upon selecting the 'Netbanking' option, you'll be sent to the Netbanking site to complete the payment and directed back when finished.",
      img: netbankingImg,
    },
    {
      title: "UPI",
      des: "Pay via the fastest growing and most trusted payment method in India. You can pay for your purchases instantly with zero downtime.",
      img: upiImg,
    },
    {
      title: "Debit Card",
      des: "We accept MasterCard Debit Cards, Maestro Debit Cards, RuPay, State Bank of India, and Visa Debit Cards for your debit card payment.",
      img: debitImg,
    },
    {
      title: "Credit Card",
      des: "We accept MasterCard, Visa, RuPay, American Express and Diner's Club Credit Cards.",
      img: creditCardImg,
    },
    {
      title: "Wallets",
      des: "Pay conveniently using any online wallet like Paytm, JioMoney, Mobikwik, OlaMoney, Freecharge, etc.",
      img: walletImg,
    },
    {
      title: "Cheque",
      des: "We'll provide you with our bank details where you can send your cheque. Your purchase will be settled once the funds are deposited into our account.",
      img: chequeImg,
    },
  ];
  const [openn, setOpen] = React.useState(false);
  const [isWallet, setIsWallet] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    setIsWallet(true);
  };

  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    setIsWallet(false);
  };
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    if (e.target.checked) handleClickOpen();
    setChecked(e.target.checked);
  };
  return (
    <>
      <Modal
        open={open}
        scroll={scroll}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="addcompanytitle">
            Renewal Payment
            <IconButton
              component="span"
              onClick={handleCloseModal}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <Stack
            spacing={1}
            sx={{ padding: "20px 16px 8px 16px" }}
            width="100%"
            alignItems="center"
            height="100%"
          >
            <div
              className={`${RenewalStyle.pricewrapper} ${RenewalStyle.borderStyle}`}
            >
              <div className={RenewalStyle.alignPrice}>
                <div>
                  <div>You Are Paying For One Order Request</div>
                </div>
                <div> {`${currencyCode} ${PriceFormat(fullAmount)}`}</div>
              </div>
              <div
                className={`${RenewalStyle.alignPrice} ${RenewalStyle.payablePrice}`}
              >
                <div>
                  <div className={RenewalStyle.amoutPaid}>
                    Total Amount To Be Paid
                  </div>
                </div>
                <div className={RenewalStyle.totalPrice}>
                  {" "}
                  {`${currencyCode} ${PriceFormat(fullAmount)}`}
                </div>
              </div>
            </div>
            <div
              className={`${RenewalStyle.walletWrapper} ${RenewalStyle.walletFlex}`}
            >
              <div>Pay Via My Balance</div>
              <div
                className={`${RenewalStyle.walletFlex} ${RenewalStyle.borderpadding} ${RenewalStyle.borderStyle}`}
              >
                <div>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    disabled={PayViaWallet(fullAmount, walletBalance)}
                    sx={{ padding: 0 }}
                  />
                </div>
                <div className={RenewalStyle.myBalanceMargin}>
                  <span>My Balance :</span>
                  <span>
                    {" "}
                    {`${currencyCode} ${PriceFormat(walletBalance)}`}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ width: "100%", marginTop: "3%" ,marginBottom: "1%", }}>Payment Method</div>
            <div
              style={{
                display: "flex",

                width: "100%",
                marginBottom: "3%",
              }}
            >
              {tabData.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  className={`tab-box ${
                    selectedTab === tabIndex ? "highlighted" : ""
                  }`}
                  onClick={() => handleTabClick(tabIndex)}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <img src={tab.img} alt="img" />
                    </div>
                    <div className="tab-title">{tab.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </Stack>
          <RenewalButton
            loading={loading}
            title={"Proceed"}
            disabled={false}
            clickFunc={proceedFunc}
          />
        </Box>
      </Modal>
    </>
  );
};

export default RenewalPayment;
