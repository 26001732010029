import React, { useState } from "react";
import { Box, MenuItem, Popover, Tab, Tabs, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { TbTargetArrow } from "react-icons/tb";
import { AiOutlineMenuFold } from "react-icons/ai";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { Link, NavLink } from "react-router-dom";

const boxStyle = {
  border: "1px solid var(--dark-filter-icon-color)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "25px",
  marginTop: "2px",
  width: "25px",
  color: "var( --light-filter-icon-color)",
  backgroundColor: "var(--onhover-filter-icon-bgc)",
  cursor: "pointer",
  marginRight: "10px",
  "&:hover": {
    backgroundColor: "var(--dark-filter-icon-color)",
    border: "1px solid #00000040"
  }
};

const VTabheader = () => {
    const [value, setValue] = React.useState('one');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
      return (
        <div style={{ padding: "10px 20px 8px 20px", borderBottom: "1px solid var(--light-border-color)" }}>
          <div style={{ display: "flex", color: "var(--light-text-color)", height: "40px", alignItems: "center" }}>
          <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example"
      >
        <Tab
          value="one"
          label="Hot Visitors"
        />
        <Tab value="two" label="Cold Visitors" />
      </Tabs>
           
            <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                <Typography sx={{fontSize:"14px", marginRight: "10px",paddingTop:"2px"}}>Visitors Prioritized By : Time Spent</Typography>
              <Box sx={{ borderRadius: "2px" }} style={boxStyle}>
                <FilterAltOutlinedIcon onClick={handleClickOpen}/>
              </Box>
              <span style={{ marginBottom: "3px", color: "var(--light-border-color)", marginRight: "10px" }}>|</span>
              <Box sx={boxStyle} style={{ borderRadius: "2px" }} component={Link} to="/chat/visitors/cricle-view">
                <TbTargetArrow /> 
              </Box>
              <Box sx={boxStyle} style={{ borderRadius: "2px" }} component={Link} to="/chat/visitors/tab-view">
                <ListOutlinedIcon />
              </Box>
              <Box sx={boxStyle} style={{ borderRadius: "50%" }} component={NavLink} to="/chat/visitors">
                <AiOutlineMenuFold />
              </Box>
            </Box>
          </div>
          <Popover
         open={open}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ width: "200px", height: "280px" }}>
          <MenuItem onClick={handleClose} disableRipple sx={{padding:"15px",borderBottom:"1px solid var(--light-border-color)"}}>
           All Contacts
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple sx={{padding:"15px",borderBottom:"1px solid var(--light-border-color)"}}>
            Returning Contacts
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple sx={{padding:"15px",borderBottom:"1px solid var(--light-border-color)"}}>
            Today's Contacts
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple sx={{padding:"15px",borderBottom:"1px solid var(--light-border-color)"}}>
            Cold Contacts
          </MenuItem>
          <MenuItem onClick={handleClose} disableRipple sx={{padding:"15px",borderBottom:"1px solid var(--light-border-color)"}}>
          Today New Contacts
          </MenuItem>
        </Box>
      </Popover>
        </div>
      );
    };
    


export default VTabheader
