import { Alert, Divider, IconButton, Snackbar, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { Box, Typography } from '@mui/material'
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { profile_trigger, selected_contact } from "../../Atoms/LoginAtoms";
import axios from "axios";
import { api_url } from "../../globalSettings";
import { Menu, MenuItem } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import tick from "../../images/tick.png";
import wrong from "../../images/wrong.png";
import trash from "../../images/trash.png";

const Box = styled.div`
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  font-size: 12px;
  width: 100%;
  flex-direction: column;
`;

const Typography = styled.div`
  color: #dd352c;
  font: 12px;
`;


const SidebarTab = styled.div`
  width: 100%;
  padding: 5px;
  cursor: pointer;
  color: black;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  ${(props) => props.active && "background-color: #f0f0f0;"}
`;

const SettingsIcon = styled(EditOutlinedIcon)`
  visibility: hidden;
  color: #00000066;
  font-size: 16px;
`;

const HoverableSidebarTab = styled(SidebarTab)`
  &:hover {
    ${SettingsIcon} {
      visibility: visible;
    }
  }
`;

const ContactFollowTable = () => {
  const [selectedContact] = useRecoilState(selected_contact);
  const [profileTrigger, setProfileTrigger] = useRecoilState(profile_trigger);
  const [selectedContactDetails, setSelectedContactDetails] = useState([]);
  const [getFollowUplist, setGetFollowUplist] = React.useState("");
  const [followupType, setFollowupType] = React.useState(null);
  const [followId, setFollowupId] = React.useState(null);
  const [snackbaropen, setSnackbaropen] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const [openTextBoxId, setOpenTextBoxId] = useState(null);
  const [openTextBoxId1, setOpenTextBoxId1] = useState(null);
  const [editDescription, setEditDescription] = useState("");
  const [editRemainder, setEditRemainder] = useState("");


  useEffect(() => {
    axios
      .get(
        `${api_url}/crm_customer/getCustomerByCustomerId/${selectedContact._id}`
      )
      .then((responce) => {
       
        setSelectedContactDetails(responce.data.data[0].followup || []);
      })
      .catch((err) => {
    
      });
  }, [selectedContact, profileTrigger]);

  // ============================= Status ============================

  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleOpen1 = (event, Id) => {
    setFollowupId(Id);
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleChange1 = (id) => {
    setFollowupType(id);
    handleClose1();
  };

  React.useEffect(() => {
    axios
      .get(
        `${api_url}/crm_setting/getGeneralSetting/${selectedContact.company_id}`
      )
      .then((responce) => {
       
        setGetFollowUplist(
          responce.data.data[0].follow_ups.add_new_followup_type.map(
            (item, key) => ({
              label: item.name,
              id: item._id,
              color: item.color,
            })
          )
        );
 ;
      })
      .catch(function (error) {
        if (error.response) {
      
        } else if (error.request) {
      
        } else {
         
        }
      });
  }, []);

  // ===================================== UPDATE  TASK ==========================
  const [update, setUpdate] = useState(false);
  useEffect(() => {
    if (followupType !== null || update == true) {
      setUpdate(false);
      // const followupparams =
      let followupparams = getFollowUplist.find(
        (obj) => obj.id === followupType
      );


      if (followupparams) {
        const params = {
          customer_id: selectedContact._id,
          followupId: followId,
          follow_up_type_id: followupparams.id,
          name: followupparams.name,
          color: followupparams.color,
          description: editDescription,
          remainders: editRemainder,
        };
        axios
          .patch(`${api_url}/crm_customer/updateFollowUpById`, params, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then(function (response) {
            setProfileTrigger(!profileTrigger);
            setOpenTextBoxId(null);
            setOpenTextBoxId1(null);
            // setSnackbaropen({
            //   open: true,
            //   message: "Shift Added successfully",
            //   severity: "success",
            //   actionColor: "#edf7ed",
            // });
          })

          .catch(function (error) {
            if (error.response) {
            
            } else if (error.request) {
           
            } else {
             
            }
           
          });
      } else {

        const params = {
          customer_id: selectedContact._id,
          followupId: followId,
          follow_up_type_id: "",
          name: "",
          color: "",
          description: editDescription,
          remainders: editRemainder,
        };
        axios
          .patch(`${api_url}/crm_customer/updateFollowUpById`, params, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then(function (response) {
            setProfileTrigger(!profileTrigger);
            setOpenTextBoxId(null);
            setOpenTextBoxId1(null);
            // setSnackbaropen({
            //   open: true,
            //   message: "Shift Added successfully",
            //   severity: "success",
            //   actionColor: "#edf7ed",
            // });
          })

          .catch(function (error) {
            if (error.response) {
             
            } else if (error.request) {
              
            } else {
            
            }
        
          });
      }
    }
  }, [followupType, update]);


  const deleteFollowup = (Id) => {

    const params = {
      customer_id: selectedContact._id,
      followupId: Id,
    };
    axios
      .patch(`${api_url}/crm_customer/deletefollowUpById`, params)
      .then((result) => {
        setSnackbaropen({
          open: true,
          message: result.data.message,
          severity: "error",
          actionColor: "#fdeded",
        });
        setProfileTrigger(!profileTrigger);
      })
      .catch((error) => {
        if (error.response) {
          
        } else if (error.request) {
        
        } else {
      
        };
      });
  }

  return (
    <Box>
      <Snackbar
open={snackbaropen.open}
onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
autoHideDuration={6000}
>
<Alert
  severity={snackbaropen.severity}
  style={{ backgroundColor: `${snackbaropen.actionColor}` }}
>
  {snackbaropen.message}
</Alert>
</Snackbar>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginBottom: "5px",
        }}
      >
        <Typography>Follow-Ups</Typography>
        <Divider />
      </div>
      <div className="task-table-container scroll">
        <table className="task-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
             
              <th>Description</th>
              <th>Remainder</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
             
              selectedContactDetails.length > 0
                ? selectedContactDetails.map((followup, index) => (
                    <React.Fragment key={followup._id}>
                      <tr
                    
                      >
                      
                        {followup.UpdatedDate !== null ? (
                          <td style={{ width: "20%" }}>
                            {new Date(followup.UpdatedDate).toLocaleString(
                              "default",
                              {
                                month: "short",
                                day: "numeric",
                                year: "2-digit",
                              }
                            )}
                          </td>
                        ) : (
                          <td style={{ width: "20%" }}>
                            {new Date(followup.CreatedDate).toLocaleString(
                              "default",
                              {
                                month: "short",
                                day: "numeric",
                                year: "2-digit",
                              }
                            )}
                          </td>
                        )}
                        <td style={{ width: "20%", fontSize: "12px" }}>
                          <span
                            style={{
                              padding: "1px 3px",
                              backgroundColor: followup.follow_up_type.color,
                              borderRadius: "5px",
                              color: "white",
                            }}
                            onClick={(e) => {
                              handleOpen1(e, followup._id);
                            }}
                          >
                            {followup.follow_up_type.name}
                          </span>
                        </td>
                     
                        <td style={{ width: "30%" }}>
                          {" "}
                          <HoverableSidebarTab>
                            {openTextBoxId === followup._id ? (
                              <>
                                <textarea
                                  value={editDescription}
                                  name="description"
                                  style={{ width: "80%" }}
                                  onChange={(e) => {
                                    setEditDescription(e.target.value);
                                  }}
                            
                                />
                                <div>
                                  {" "}
                                  <IconButton
                                    style={{ padding: 0 }}
                                    color="success"
                                    onClick={() => {
                                      setOpenTextBoxId(null);
                                    }}
                                    aria-label="add to shopping cart"
                                  >
                                    <img
                                      src={wrong}
                                      alt="Yes"
                                      width={18}
                                      height={18}
                                    />
                                  </IconButton>{" "}
                                  <IconButton
                                    style={{ padding: 0 }}
                                    color="success"
                                    onClick={() => {
                                      setUpdate(true);
                                    }}
                                    aria-label="add to shopping cart"
                                  >
                                    <img
                                      src={tick}
                                      alt="Yes"
                                      width={18}
                                      height={18}
                                    />
                                  </IconButton>{" "}
                                </div>
                              </>
                            ) : (
                              <>
                                {followup.description}{" "}
                                <SettingsIcon
                                  onClick={() => {
                                    setOpenTextBoxId(followup._id);
                                    setEditDescription(followup.description);
                                    setFollowupId(followup._id);
                                  }}
                                />{" "}
                              </>
                            )}
                          </HoverableSidebarTab>
                        </td>
                        <td style={{ width: "20%" }}>
                          <HoverableSidebarTab>
                            {openTextBoxId1 === followup._id ? (
                              <>
                                <input
                                  value={editRemainder}
                                  name="remainders"
                                  type="number"
                                  style={{ width: "80%" }}
                                  onChange={(e) => {
                                    setEditRemainder(e.target.value);
                                  }}
                                 
                                />

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  {" "}
                                  <IconButton
                                    style={{ padding: 0 }}
                                    color="success"
                                    onClick={() => {
                                      setOpenTextBoxId1(null);
                                    }}
                                    aria-label="add to shopping cart"
                                  >
                                    <img
                                      src={wrong}
                                      alt="Yes"
                                      width={18}
                                      height={18}
                                    />
                                  </IconButton>{" "}
                                  <IconButton
                                    style={{ padding: 0 }}
                                    color="success"
                                    onClick={() => {
                                      setUpdate(true);
                                    }}
                                    aria-label="add to shopping cart"
                                  >
                                    <img
                                      src={tick}
                                      alt="Yes"
                                      width={18}
                                      height={18}
                                    />
                                  </IconButton>{" "}
                                </div>
                              </>
                            ) : (
                              <>
                                {followup.remainders}
                                <SettingsIcon
                                  onClick={() => {
                                    setOpenTextBoxId1(followup._id); 
                                    setFollowupId(followup._id);
                                    setEditRemainder(followup.remainders);
                                  }}
                                />{" "}
                              </>
                            )}
                          </HoverableSidebarTab>
                        </td>
                        <td style={{ width: "10%" }}>
                        
                      <Tooltip title ="Delete">
                          <span onClick={() => {deleteFollowup(followup._id)}}>
                            {" "}
                            <img
                              src={trash}
                              width={15}
                              height={15}
                              alt="delete"
                            />
                          </span>
                          </Tooltip>
                          {/* </div> */}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                : ""
            }
          </tbody>
        </table>

        <style>
          {`
        .task-table-container {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction : column;
  font-family: "Poppins",sans-serif;
  overflow: auto;
}


.task-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}
.button{
  color: rgb(212, 211, 211) ;
  border: 1px solid #3367d6;
  background-color: #3367d6 ;
  padding: 5px 10px ;
  border-radius: 8px ;
  margin-bottom:10px;
}

.button:hover{
  color: rgb(212, 211, 211) ;
  border: 1px solid #3367d6;
  background-color: #3367d6 ;
  padding: 5px 10px ;
  border-radius: 8px ;
  margin-bottom:10px;
}

.task-table th,
.task-table td {
  padding: 12px;
  text-align: left;
}

.task-table th {
  background-color: #f2f2f2;
  font-weight: 500;
  font-size : 12px
}

.task-table tr {
  background: #ffffff;
    margin-left: 0;
    box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
    color: #1c1c22;
  transition: background-color 0.2s ease;
  cursor:pointer;
}

.task-table tr:hover {
  background-color: #f5f5f5;
}

.task-row.active {
  background-color: #e1f5fe;
  cursor: pointer;
}

.add-subtask-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s ease;
  margin:10px 0
}

.add-subtask-button:hover {
  background-color: #0056b3;
}

.sub-tasks-row td {
  padding: 0 12px;
  text-align: left;
  border: none;
}


.task-line {
  display: inline-block;
  width: 20px;
  position: relative;
  padding-left:10px;
}

.branch-line {
  position: absolute;
  top: 8px;
  left: -10px;
  height: calc(100% - 8px);
  width: 1px;
  background-color: #ccc;
}

.branch-line.expanded {
  background-color: #007bff;
}
.delete-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
  background-color: #00000024;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* Add this to your CSS */
.calendar-icon {
  position: relative;
  display: inline-block;
  font-size: 12px
}

.calendar-icon input {
  padding-right: 30px; /* Create space for the icon */
}

.calendar-icon .icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
/* Add this to your CSS */
.calendar-icon {
  position: relative;
  display: inline-block;
}

.calendar-icon .icon {
  cursor: pointer;
}
/* Add this to your CSS */
.calendar-dropdown {
  position: relative;
  display: inline-block;
}

.calendar-dropdown .icon {
  cursor: pointer;
}

.calendar-dropdown .dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

/* Add this to your CSS */
.calendar-dropdown {
  position: relative;
  display: inline-block;
}

.calendar-icon {
  cursor: pointer;
}

.calendar-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
`}
        </style>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl1}
          open={Boolean(anchorEl1)}
          onClose={handleClose1}
          style={{ width: "100px" }}
        >
          {getFollowUplist.length > 0
            ? getFollowUplist.map((item) => {
                return (
                  <MenuItem
                    key={item.id}
                    onClick={() => {
                      handleChange1(item.id);
                    }}
                    style={{
                      color: "white",
                      paddingLeft: "5px",
                      fontSize: 12,
                      width: "100px",
                      textAlign: "left",
                      marginBottom: 5,
                      justifyContent: "flex-start",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: item.color,
                        borderRadius: 5,
                        padding: 2,
                      }}
                    >
                      {item.label}
                    </span>
                  </MenuItem>
                );
              })
            : ""}
        </Menu>
      </div>
    </Box>
  );
};

export default ContactFollowTable;
