import { Box, Button, CircularProgress, Drawer, IconButton, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import variables from "../../../../assets/styles/_colors.scss";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseButton } from "../../../../components/CloseButton";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import { CustomSelect } from "../../../pipelines/components/CustomSelect";
import { CustomCheckbox } from "../../../pipelines/components/CustomCheckbox";
import { CustomAutoComplete } from "../../../pipelines/components/CustomAutocomplete";
import AddIcon from '@mui/icons-material/Add';
import { CustomInputField } from "../../../pipelines/components/CustomInputField";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { getAllStagesBySubPipelineId, getFormFields } from "../../../../webservices/PipelineService";
import { useEffect } from "react";
import { createStageRule, editStageRule } from "../../../../webservices/StageService";
import ReorderableStringList from "../../../pipelines/components/ReorderableStringList";

const schema = Yup.object().shape({
    fromStage: Yup.string().trim().required("From Stage cannot be empty"),
    toStage: Yup.string().trim().required("To Stage cannot be empty"),
    mandateFields: Yup.boolean(),
    mandatoryFields: Yup
        .array()
        .when("mandateFields", {
            is: true,
            then: () => Yup.array().min(1, "Fields cannot be empty")
        }),
    mandateNotes: Yup.boolean(),
    mandateFiles: Yup.boolean(),
    hasChecklist: Yup.boolean(),
    checklist: Yup.object().when("hasChecklist", {
        is: true,
        then: () =>
            Yup.object().shape({
                title: Yup.string().trim().required('Checklist Title cannot be empty'),
                options: Yup.array().of(
                    Yup.array().min(1).of(
                        Yup.string().trim().required('Checklist value cannot be empty')
                    )
                )
            })
    })
});

const UpsertTransitionRule = (props) => {

    const queryClient = useQueryClient();

    const [openDrawer, setOpenDrawer] = useState(true);
    const [showChecklist, setShowChecklist] = useState(false);
    const [fromStages, setFromStages] = useState([]);
    const [toStages, setToStages] = useState([]);
    const [filteredFromStages, setFilteredFromStages] = useState([]);
    const [filteredToStages, setFilteredToStages] = useState([]);
    const [fields, setFields] = useState([]);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data
    } = useQuery({
        queryKey: ['getAllStagesBySubPipelineId', props.subPipelineId],
        queryFn: () => getAllStagesBySubPipelineId(props.subPipelineId),
        refetchOnWindowFocus: false
    });

    const {
        data: formFields
    } = useQuery({
        queryKey: ['getAllFormFields'],
        queryFn: () => getFormFields(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (data) {
            const openClosedStages = data.stages.openStages.concat(data.stages.closedStages);
            setFromStages([{ label: 'Any Stage', value: 'any' }].concat(openClosedStages));
            setToStages(openClosedStages);
            setFilteredFromStages([{ label: 'Any Stage', value: 'any' }].concat(openClosedStages));
            setFilteredToStages(openClosedStages);
        }
    }, [data]);

    useEffect(() => {
        props.edit &&
            setValue("fromStage", props.item.fromStage ? props.item.fromStage._id : 'any');
    }, [filteredFromStages]);

    useEffect(() => {
        props.edit &&
            setValue("toStage", props.item.toStage._id);
    }, [filteredToStages]);

    useEffect(() => {
        formFields && setFields(formFields.data);
    }, [formFields]);

    const { mutateAsync, isPending } = useMutation({
        mutationKey: props.edit ? "editRule" : "createRule",
        mutationFn: (data) => props.edit ? editStageRule(data) : createStageRule(data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getTransitionRules'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    const methods = useForm(
        {
            defaultValues: {
                "fromStage": "",
                "toStage": "",
                "mandateFields": false,
                "mandatoryFields": [],
                "mandateNotes": false,
                "mandateFiles": false,
                "hasChecklist": false
            },
            shouldUnregister: false,
            resolver: yupResolver(schema),
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, setValue, trigger, getValues, resetField, watch, register, formState: { errors } } = methods;

    const selectedFromStage = watch("fromStage");
    const selectedToStage = watch("toStage");
    const isFieldSelected = watch("mandateFields");

    useEffect(() => {
        register("hasChecklist", false);
    }, [register]);

    useEffect(() => {
        selectedFromStage && setFilteredToStages(toStages.filter(stage => stage.value !== selectedFromStage));
    }, [selectedFromStage]);

    useEffect(() => {
        selectedToStage && setFilteredFromStages(fromStages.filter(stage => stage.value !== selectedToStage));
    }, [selectedToStage]);

    useEffect(() => {
        isFieldSelected && !props.edit && setValue("mandatoryFields", []);
        !isFieldSelected && resetField("mandatoryFields");
    }, [isFieldSelected]);

    useEffect(() => {
        if (props.item && props.edit) {
            setValue("mandateFields", props.item?.mandateFields);
            setValue("mandateNotes", props.item?.mandateNotes);
            setValue("mandateFiles", props.item?.mandateFiles);
            setValue("hasChecklist", props.item?.hasChecklist);
            if (props.item?.hasChecklist) {
                setValue("checklist.title", props.item?.checklist?.title);
                setValue("checklist.options", props.item?.checklist?.options);
            }
        }
    }, [props.item]);

    useEffect(() => {
        if (props.item && props.edit && formFields) {
            setValue("mandatoryFields", props.item?.mandatoryFields);
        }
    }, [props.item, formFields]);

    const onSubmit = async (data) => {
        const isFormValid = await trigger();
        if (isFormValid) {
            if (data.mandateFields || data.mandateNotes || data.mandateFiles || data.hasChecklist) {
                data.pipelineId = props.teamPipelineId;
                data.subPipelineId = props.subPipelineId;
                data.fromStage = data.fromStage === "any" ? null : data.fromStage;
                if (props.edit) {
                    data._id = props.item._id;
                    data.modifiedBy = "User 1";
                } else {
                    data.createdBy = "User 1";
                }
                mutateAsync(data);
            } else {
                showSnackbar({
                    open: true,
                    severity: 'error',
                    message: "Transition Rule should have atleast one mandatory item"
                });
            }
        }
    }

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        })
    }

    const handleAddChecklist = () => {
        setShowChecklist(true);
        setValue("hasChecklist", true);
    }

    const handleChecklistDelete = () => {
        setShowChecklist(false);
        setValue("hasChecklist", false);
        resetField("checklist");
    }

    function handleDrawerClose(data) {
        setOpenDrawer(false);
        props.handleDrawerClose(data);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <>
            <Drawer anchor="right" open={openDrawer}
                PaperProps={{
                    sx: {
                        minWidth: "35vw", maxWidth: "35vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                    },
                }}>

                <Box sx={{ height: "100vh" }}>

                    <Typography variant="h5" sx={{ pl: 2 }}>{props.edit ? 'Edit ' : 'Create '} Rule
                        <CloseButton handleDialogClose={() => handleDrawerClose()} />
                    </Typography>

                    <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 2.5, height: "86vh" }}>
                        <Typography>
                            When users try to move a record from 'From Stage' to 'To Stage', they'll be asked to fill in the fields and information you've mandated in this rule.
                        </Typography>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} id="ruleForm" style={{}}>
                                <Stack direction="column" spacing={2} sx={{ pt: 2, pb: 1 }}>

                                    <Stack direction="column" spacing={2}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: 14.5 }}> Specify Stages </Typography>
                                        <CustomSelect name={`fromStage`} inputLabel="From Stage*" options={filteredFromStages} />
                                        <CustomSelect name={`toStage`} inputLabel="To Stage*" options={filteredToStages} />
                                    </Stack>

                                    <Stack direction="column" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold', fontSize: 14.5 }}> Mandate Information to be filled </Typography>
                                        <CustomCheckbox name={"mandateFields"} width="20%" label={"Fields"} defaultChecked={false} />
                                        {isFieldSelected && <>
                                            <CustomAutoComplete name="mandatoryFields" label="Select Fields*" options={fields} />
                                        </>}
                                        <CustomCheckbox name={"mandateNotes"} width="20%" label={"Note"} defaultChecked={false} />
                                        <CustomCheckbox name={"mandateFiles"} width="20%" label={"File"} defaultChecked={false} />
                                    </Stack>

                                    {!getValues("hasChecklist") &&
                                        <Typography component="div" onClick={() => { handleAddChecklist() }}
                                            sx={{ color: "#3d5af1", cursor: "pointer", backgroundColor: `${variables.linkBackgroundColor}`, borderRadius: 1, p: "1rem 0" }} >
                                            <AddIcon sx={{ mr: 0.5 }} /> Add Checklist
                                        </Typography>}

                                    {getValues("hasChecklist") &&
                                        <Stack direction="column" spacing={2}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Typography sx={{ fontWeight: 'bold', fontSize: 14.5 }}> Checklist to be Completed </Typography>
                                                <IconButton onClick={() => { handleChecklistDelete() }}
                                                    sx={{ width: "7%" }}>
                                                    <DeleteOutlinedIcon />
                                                </IconButton>
                                            </Stack>

                                            <CustomInputField size="small" name="checklist.title" placeholder="Enter Checklist Title" />

                                            <ReorderableStringList id="checklist" hideDefaultValue={true} isChecklist={true} />
                                        </Stack>
                                    }
                                </Stack>
                            </form>
                        </FormProvider>
                    </Box>

                    <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button disabled={isPending} variant="outlined" onClick={() => handleDrawerClose()}> Cancel </Button>
                        <Button
                            disabled={isPending}
                            type="submit"
                            form={"ruleForm"}
                            variant="contained"
                            color="primary"
                        > Save
                            {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                        </Button>
                    </Stack>
                </Box>
            </Drawer >

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />
        </>
    );
};

export default UpsertTransitionRule;