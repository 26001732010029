import { useState } from "react";
import ActivitiesCalendar from "./ActivitiesCalendar";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import variables from "../../assets/styles/_colors.scss";
import ActivityTable from "./view/table/ActivityTable";
import ActivityActions from "./view/table/ActivityActions";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { getActivityColumns } from "../../webservices/PipelineService";
import { useSearchParams } from "react-router-dom";
import { activities_atom } from "../../../../Atoms/CrmAtoms";

export default function Activities() {

    const [activitiesAtom, setActivitesAtom] = useRecoilState(activities_atom);

    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type");

    const [selectedTab, setSelectedTab] = useState("calendar");
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [taskCols, setTaskCols] = useState(null);
    const [eventCols, setEventCols] = useState(null);
    const [callCols, setCallCols] = useState(null);

    useEffect(() => {

        setSearchParameters(type ? type : "calendar");

        if (activitiesAtom.displayedColumns.task.length === 0) {
            getActivityColumns("tasks").then((response) => {
                setTaskCols(response.data);
            });
        } else {
            setTaskCols(activitiesAtom.displayedColumns.task);
        }

        if (activitiesAtom.displayedColumns.event.length === 0) {
            getActivityColumns("events").then((response) => {
                setEventCols(response.data);
            });
        } else {
            setEventCols(activitiesAtom.displayedColumns.event);
        }

        if (activitiesAtom.displayedColumns.call.length === 0) {
            getActivityColumns("calls").then((response) => {
                setCallCols(response.data);
            });
        } else {
            setCallCols(activitiesAtom.displayedColumns.call);
        }

    }, []);

    const persistData = (cols, type) => {
        if (cols?.length > 0) {
            const obj = {
                ...activitiesAtom,
                "displayedColumns": {
                    ...activitiesAtom["displayedColumns"],
                    [type]: cols
                }
            }
            setActivitesAtom(obj);
        }
    }

    useEffect(() => {
        persistData(taskCols, "task");
    }, [taskCols]);

    useEffect(() => {
        persistData(eventCols, "event");
    }, [eventCols]);

    useEffect(() => {
        persistData(callCols, "call");
    }, [callCols]);

    const handleChange = (event, newValue) => {
        setIsFilterOpen(false);
        setSearchParameters(newValue);
    };

    const setSearchParameters = (type) => {
        setSelectedTab(type);
        searchParams.set('type', type);
        setSearchParams(searchParams);
    }

    return (
        <Box sx={{ ml: isFilterOpen ? '22vw' : 0 }}>
            {selectedTab &&
                <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', maxHeight: "50px", backgroundColor: `${variables.lightGreyBackgroundColor}` }}>
                        <TabList onChange={handleChange} >
                            <Tab label="Calendar" value="calendar" sx={{ width: '8vw', borderRight: `1px solid ${variables.lightBorderColor}` }} />
                            <Tab label="Tasks" value="tasks" sx={{ width: '8vw', borderRight: `1px solid ${variables.lightBorderColor}` }} />
                            <Tab label="Events" value="events" sx={{ width: '8vw', borderRight: `1px solid ${variables.lightBorderColor}` }} />
                            <Tab label="Calls" value="calls" sx={{ width: '8vw', borderRight: `1px solid ${variables.lightBorderColor}` }} />
                        </TabList>
                    </Box>
                    {selectedTab === "calendar" &&
                        <TabPanel sx={{ p: 0 }} value="calendar">
                            <ActivitiesCalendar />
                        </TabPanel>
                    }
                    {selectedTab === "tasks" &&
                        <TabPanel value="tasks" sx={{ p: 0 }}>
                            <ActivityActions module={"task"} isFilterOpen={(val) => setIsFilterOpen(val)} />
                            {taskCols?.length > 0 && <ActivityTable module={"task"} columns={taskCols} drawerOpen={isFilterOpen} />}
                        </TabPanel>
                    }
                    {selectedTab === "events" &&
                        <TabPanel value="events" sx={{ p: 0 }}>
                            <ActivityActions module={"event"} isFilterOpen={(val) => setIsFilterOpen(val)} />
                            {eventCols?.length > 0 && <ActivityTable module={"event"} columns={eventCols} drawerOpen={isFilterOpen} />}
                        </TabPanel>
                    }
                    {selectedTab === "calls" &&
                        <TabPanel value="calls" sx={{ p: 0 }}>
                            <ActivityActions module={"call"} isFilterOpen={(val) => setIsFilterOpen(val)} />
                            {callCols?.length > 0 && <ActivityTable module={"call"} columns={callCols} drawerOpen={isFilterOpen} />}
                        </TabPanel>
                    }
                </TabContext>
            }
        </Box>
    );
}
