import { Box, Divider } from "@mui/material";
import React, { useState } from "react";

import "../../../../../../node_modules/formiojs/dist/formio.full.css";
import "../../../../../../node_modules/formiojs/dist/formio.embed.css";
import "../../../../../../node_modules/formiojs/dist/formio.form.css";

import { styled } from "@mui/system";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NavLink } from "react-router-dom/dist";
import { json_formData } from "../../../../../Atoms/LoginAtoms";

const CssBox = styled(Box)({
  color: "darkslategray",
  backgroundColor: "#e5e8e9",
  display: "flex",
  gap: "30px",
  padding: 8,
  margin: 10,
  height: "100%",
  borderRadius: 4,
  width: "100%",
  overflow: "scroll",
  whiteSpace: "nowrap",
});

const CssCard = styled(Card)({
  color: "darkslategray",
  padding: 8,
  height: "-webkit-fill-available",
  borderRadius: "10px !important",
  overflow: "hidden",
  overflowY: "scroll",
  width: "320px",
  flexShrink: 0,
});

const style = {
  width: "100%",
  height: "auto",
  padding: 10,
  border: "1px solid #0000001c",
  borderRadius: 5,
  margin:"10px 0"
};

const Index = () => {
  const [jsonFormData, setJsonFormData] = useState(json_formData);

  const [jsonSchema, setSchema] = useState({components :[]});



  const onFormChange = (schema) => {
    setJsonFormData({ ...schema, components: [...schema.components] });
  };

  function convertToHTMLForm(formComponents) {
    let htmlForm = "<form>";

    formComponents.forEach((component) => {
      switch (component.type) {
        case "button":
          htmlForm += `<button type="submit" name="${component.key}" class="btn btn-${component.theme}">${component.label}</button>`;
          break;
        case "textarea":
          htmlForm += `<div>
                                <label for="${component.id}">${component.label}</label>
                                <textarea id="${component.id}" rows="${component.rows}"></textarea>
                             </div>`;
          break;

        case "textfield":
          htmlForm += `<div class="${component.customClass}">
                                <label for="${component.id}">${component.label}</label>
                                <input  type="text" id="${component.id}" />
                             </div>`;
          break;


      }
    });

    htmlForm += "</form>";
    return htmlForm;
  }

 

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
   
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };

  return (
    <>
      <CssBox>
        <CssCard sx={{ maxWidth: 345 }} className="scroll">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Contacts"
          />
          <Divider />
          {jsonFormData.components ? jsonFormData.components.map((item) => {
            return(
              <div
              style={style}
            >
              
              {item.label}({item.type})
            </div>)
          }) : ""}
        
          <Divider />
          <CardActions>
            <NavLink to="/contacts/customizeFields">Customize Fields</NavLink>
          </CardActions>
        </CssCard>
        <CssCard sx={{ maxWidth: 345 }} className="scroll">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Contacts"
          />
          <Divider />
          {jsonFormData.components ? jsonFormData.components.map((item) => {
            return(
              <div
              style={style}
            >
             
              {item.label}({item.type})
            </div>)
          }) : ""}
        
          <Divider />
          <CardActions>
            <NavLink to="/contacts/customizeFields">Customize Fields</NavLink>
          </CardActions>
        </CssCard>
        <CssCard sx={{ maxWidth: 345 }} className="scroll">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Contacts"
          />
          <Divider />
          {jsonFormData.components ? jsonFormData.components.map((item) => {
            return(
              <div
              style={style}
            >
            
              {item.label}({item.type})
            </div>)
          }) : ""}
        
          <Divider />
          <CardActions>
            <NavLink to="/contacts/customizeFields">Customize Fields</NavLink>
          </CardActions>
        </CssCard>
        <CssCard sx={{ maxWidth: 345 }} className="scroll">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Contacts"
          />
          <Divider />
          {jsonFormData.components ? jsonFormData.components.map((item) => {
            return(
              <div
              style={style}
            >
              
              {item.label}({item.type})
            </div>)
          }) : ""}
        
          <Divider />
          <CardActions>
            <NavLink to="/contacts/customizeFields">Customize Fields</NavLink>
          </CardActions>
        </CssCard>
        <CssCard sx={{ maxWidth: 345 }} className="scroll">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Contacts"
          />
          <Divider />
          {jsonFormData.components ? jsonFormData.components.map((item) => {
            return(
              <div
              style={style}
            >
              
              {item.label}({item.type})
            </div>)
          }) : ""}
        
          <Divider />
          <CardActions>
            <NavLink to="/contacts/customizeFields">Customize Fields</NavLink>
          </CardActions>
        </CssCard>
        <CssCard sx={{ maxWidth: 345 }} className="scroll">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Contacts"
          />
          <Divider />
          {jsonFormData.components ? jsonFormData.components.map((item) => {
            return(
              <div
              style={style}
            >
              
              {item.label}({item.type})
            </div>)
          }) : ""}
        
          <Divider />
          <CardActions>
            <NavLink to="/contacts/customizeFields">Customize Fields</NavLink>
          </CardActions>
        </CssCard>
        <CssCard sx={{ maxWidth: 345 }} className="scroll">
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Contacts"
          />
          <Divider />
          {jsonFormData.components ? jsonFormData.components.map((item) => {
            return(
              <div
              style={style}
            >
              
              {item.label}({item.type})
            </div>)
          }) : ""}
        
          <Divider />
          <CardActions>
            <NavLink to="/contacts/customizeFields">Customize Fields</NavLink>
          </CardActions>
        </CssCard>
      </CssBox>

      {/* <div>
<FormBuilder form={jsonFormData} onChange={onFormChange} />

    <ReactJson
      src={jsonFormData}
      name={null}
      collapsed={true}
    ></ReactJson>


    <Form form={jsonFormData} />

</div> */}
    </>
  );
};

export default Index;


