import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { editFieldsData } from "../../../utils/createpipelineformdata";
import { useEffect } from "react";
import FieldsForm from "./FieldsForm";
import validationSchema from "./validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import variables from "../../../assets/styles/_colors.scss";
import { CloseButton } from "../../../components/CloseButton";

const CustomizeFields = (props) => {

    const [data, setData] = useState(null);

    const methods = useForm(
        {
            defaultValues: props.defaultValues ? props.defaultValues : editFieldsData,
            shouldUnregister: false,
            resolver: yupResolver(validationSchema[2]),
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, getValues, watch } = methods;

    const currentFields = watch(`fields`);

    useEffect(() => {
        setData(getValues("fields"));
    }, [currentFields]);

    const onSubmit = (data) => {
        props.handleDrawerClose(data);
    }

    return (
        <>
            <Drawer anchor="right" open={props.open}
                PaperProps={{
                    sx: { minWidth: "75vw", maxWidth: "75vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}` },
                }}>
                <Typography variant="h5" sx={{ pl: 2 }}>{props.title}
                    <CloseButton handleDialogClose={() => props.handleDrawerClose(null)} />
                </Typography>
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box sx={{ width: '100%' }}>
                            <FieldsForm edit={true} />
                            <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 16px", background: `${variables.linkBackgroundColor}` }}>
                                <Button variant="outlined" onClick={() => props.handleDrawerClose(null)}> Cancel </Button>
                                <Button
                                    // disabled={isSubmitting}
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Box>
                    </form>
                </FormProvider>
            </Drawer>

        </>
    );
};

export default CustomizeFields;