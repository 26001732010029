import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    first_name: yup
      .string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please Enter First Name"),

    last_name: yup
      .string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required("Please enter Last Name"),

    email: yup
      .string()
      .trim()
      .lowercase()
      .email("Invalid Email")
      .required("Please Enter Email"),

    gender: yup.string().required("Please Select Gender"),
    date_of_birth: yup.string().required("Please Enter DOB"),

    address: yup.string().required("Please Enter Address"),

    work_type: yup.string().required("Please Select Worktype"),

    shift: yup.string().required("Please Enter Shift"),

    mobile: yup.string().required("Please Enter Mobile"),

    department: yup.string().required("Please Select Department"),

    designation: yup.string().required("Please Select Designation"),
    branch: yup.string().required("Please Select  Branch"),
    role: yup.string().required("Please Select Roles"),
  })
  .required();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }
  

const AddUser = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState(0);
  
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const selectedGender = watch("gender");

  const onSubmit = (data) => {
    console.log("Form data:", data);
  };
  const handleChange = (event,newValue) => {
    setValues(newValue);
  };
  const handleModalOpen = () => setIsModalOpen(true);
  // const handleModalClose = () => setIsModalOpen(false);
  return (
    <>
      <Button
        variant="contained"
        sx={{
          backgroundColor: "var(--button-color)",
          "&:hover": { backgroundColor: "var(--button-color)" },
        }}
        onClick={handleModalOpen}
      >
        Invite User
      </Button>

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            display: "flex",
            borderRadius: "8px",
            bgcolor: "background.paper",
            position: "absolute",
            top: "7%",
            left: "10%",
            width: "85vw",
            height: "85vh",
          }}
        >
          <Box sx={{ width: "250px" }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={values}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "left",
                alignItems: "flex-start",
                height: "85vh",
              }}
            >
              <Tab
                label="Basic Info"
                {...a11yProps(0)}
                sx={{
                  alignItems: "flex-start",
                  paddingRight: 0,
                  width: "250px",
                }}
              />
              <Tab
                label="Work Details"
                {...a11yProps(1)}
                sx={{ alignItems: "flex-start", paddingRight: 0 }}
              />
              <Tab
                label="Personal"
                {...a11yProps(2)}
                sx={{ alignItems: "flex-start", paddingRight: 0 }}
              />
              <Tab
                label="Passport & Visa Details"
                {...a11yProps(3)}
                sx={{ alignItems: "flex-start", paddingRight: 0 }}
              />
              <Tab
                label="Educational Qualification"
                {...a11yProps(4)}
                sx={{ alignItems: "flex-start", paddingRight: 0 }}
              />
              <Tab
                label="Work Experience"
                {...a11yProps(5)}
                sx={{ alignItems: "flex-start", paddingRight: 0 }}
              />
              <Tab
                label="Group Medical From"
                {...a11yProps(6)}
                sx={{ alignItems: "flex-start", paddingRight: 0 }}
              />
              <Tab
                label="Related Forms"
                {...a11yProps(7)}
                sx={{ alignItems: "flex-start", paddingRight: 0 }}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              width: "83%",
              height: "85vh",
              ".css-19kzrtu": { padding: 0 },
            }}
          >
            <TabPanel value={values} index={0}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px 20px 15px 15px",
                    borderBottom: "1px solid var(--light-border-color)",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    Add Employee
                  </Typography>
                  <CloseOutlinedIcon
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsModalOpen(false)}
                  />
                </Box>
                <Box
                  sx={{
                    padding: "5px 20px 5px 20px",
                    height: "77vh",
                    overflowY: "auto",
                  }}
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            {...register("first_name")}
                            error={!!errors?.first_name?.message}
                            helperText={errors?.first_name?.message}
                            variant="outlined"
                            label="First Name"
                            placeholder="First Name"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            {...register("last_name")}
                            error={!!errors?.last_name?.message}
                            helperText={errors?.last_name?.message}
                            variant="outlined"
                            label="Last Name"
                            placeholder="Last Name"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            {...register("email")}
                            error={!!errors?.email?.message}
                            helperText={errors?.email?.message}
                            variant="outlined"
                            label="Email"
                            placeholder="Email"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel >Gender</InputLabel>
                          <Select
                            {...register("gender")}
                            error={!!errors?.gender?.message}
                            helperText={errors?.gender?.message}
                            label="Gender"
                            value={selectedGender || ""}
                            onChange={(e) => setValue("gender", e.target.value)}
                          >
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="not specified">
                              Not specified
                            </MenuItem>
                            <MenuItem value="non-binary">Non-binary</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            {...register("date_of_birth")}
                            error={!!errors?.date_of_birth?.message}
                            helperText={errors?.date_of_birth?.message}
                            variant="outlined"
                            label="Date of Birth"
                            placeholder="DD/MM/YYYY"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            {...register("address")}
                            error={!!errors?.address?.message}
                            helperText={errors?.address?.message}
                            variant="outlined"
                            label="Address"
                            placeholder="Address"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            {...register("mobile")}
                            error={!!errors?.mobile?.message}
                            helperText={errors?.mobile?.message}
                            variant="outlined"
                            label="Mobile No"
                            placeholder="Mobile No"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel >Work Type</InputLabel>
                          <Select
                            {...register("work_type")}
                            error={!!errors?.work_type?.message}
                            helperText={errors?.work_type?.message}
                            label="Work Type"
                            value={selectedGender || ""}
                            onChange={(e) =>
                              setValue("work_type", e.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Shift</InputLabel>
                          <Select
                            {...register("shift")}
                            error={!!errors?.shift?.message}
                            helperText={errors?.shift?.message}
                            label="Shift"
                            onChange={(e) => setValue("Shift", e.target.value)}
                          >
                            {/* {Object.values(countries).map((country) => (
                  <MenuItem key={country.id} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))} */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Department</InputLabel>
                          <Select
                            {...register("department")}
                            error={!!errors?.department?.message}
                            helperText={errors?.department?.message}
                            label="Department"
                            onChange={(e) =>
                              setValue("department", e.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Designation</InputLabel>
                          <Select
                            {...register("designation")}
                            error={!!errors?.designation?.message}
                            helperText={errors?.designation?.message}
                            label="Designation"
                            onChange={(e) =>
                              setValue("designation", e.target.value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Branch</InputLabel>
                          <Select
                            {...register("branch")}
                            error={!!errors?.branch?.message}
                            helperText={errors?.branch?.message}
                            label="Branch"
                            onChange={(e) => setValue("branch", e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Roles</InputLabel>
                          <Select
                            {...register("role")}
                            error={!!errors?.role?.message}
                            helperText={errors?.role?.message}
                            label="Roles"
                            placeholder="Roles"
                            onChange={(e) => setValue("role", e.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            variant="outlined"
                            label="Employee No"
                            placeholder="Employee No"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "5px 20px 0 20px",
                        justifyContent: "end",
                        marginTop: "5px",
                      }}
                    >
                      {" "}
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          padding: "5px 10px 5px 10px",
                          marginRight: "20px",
                          backgroundColor: "var(--button-color)",
                          "&:hover": { backgroundColor: "var(--button-color)" },
                        }}
                        type="submit"
                      >
                        Save 
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{
                          padding: "5px 10px 5px 10px",
                          marginLeft: "20px",
                        }}
                        type="button"
                        onClick={() => setIsModalOpen(false)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={values} index={1}>
              Work Details
            </TabPanel>
            <TabPanel value={values} index={2}>
              Personal
            </TabPanel>
            <TabPanel value={values} index={3}>
              Passport & Visa Details
            </TabPanel>
            <TabPanel value={values} index={4}>
              Educational Qualification
            </TabPanel>
            <TabPanel value={values} index={5}>
              Work Experience
            </TabPanel>
            <TabPanel value={values} index={6}>
              Group Medical From
            </TabPanel>
            <TabPanel value={values} index={7}>
              Related Forms
            </TabPanel>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default AddUser;
