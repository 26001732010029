import AddIcon from '@mui/icons-material/Add';
import StageRow from "./StageRow";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { addStage, reorderStage } from "../../../../webservices/PipelineService";
import CustomSnackbar from '../../../../components/CustomSnackbar';
import variables from "../../../../assets/styles/_colors.scss";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Autocomplete, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import { settings_atom } from '../../../../../../Atoms/CrmAtoms';
import { useEffect } from 'react';
import { useState } from 'react';

export default function StagesConfiguration(props) {

    const queryClient = useQueryClient();

    const [settingsAtom, setSettingsAtom] = useRecoilState(settings_atom);

    const allOpenAndClosedStages = queryClient.getQueryData(["getAllStages"]);
    const [stages, setStages] = useState([]);
    const [selectedStage, setSelectedStage] = useState(null);
    const [availableStages, setAvailableStages] = useState([]);
    const [editStage, setEditStage] = useState(false);
    const [err, setErr] = useState(null);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    useEffect(() => {
        const type = props.isOpenStage ? "openStages" : "closedStages";
        const filteredStages = allOpenAndClosedStages?.stages[type].filter(ar => !props.stages.find(rm => (rm._id === ar.value)));
        setAvailableStages(filteredStages);
    }, [allOpenAndClosedStages, props.isOpenStage]);

    useEffect(() => {
        setStages(props.stages);
    }, [props.stages]);

    useEffect(() => {
        const obj = {
            ...settingsAtom,
            freezeActions: false
        }
        setSettingsAtom(obj);
    }, []);

    useEffect(() => {
        const obj = {
            ...settingsAtom,
            freezeActions: editStage
        }
        setSettingsAtom(obj);
    }, [editStage]);

    const { mutateAsync: handleAddStage, isError: addStageIsError, error: addStageError } = useMutation({
        mutationKey: "addStage",
        mutationFn: () => addStage({
            stageName: selectedStage,
            createdBy: "manual",
            isOpenStage: props.isOpenStage,
            subPipelineId: props.subPipelineId
        }),
        onSuccess: (response) => {
            setSelectedStage(null);
            setEditStage(false);
            props.highlightStage(false);
            queryClient.invalidateQueries({ queryKey: ['getAllStages'] });
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
            setSnackbarInfo({
                open: true,
                severity: 'success',
                message: response?.message
            });
        }
    });

    useEffect(() => {
        if (addStageIsError) {
            setErr(addStageError);
            setSnackbarInfo({
                open: true,
                severity: 'error',
                message: addStageError?.response?.data?.error?.message
            });
        }
    }, [addStageIsError]);

    const { mutateAsync: handleReorderStage, isError: reorderStageIsError, error: reorderStageError } = useMutation({
        mutationKey: "reorderStages",
        mutationFn: (data) => reorderStage({
            stages: data,
            modifiedBy: "manual",
            isOpenStage: props.isOpenStage,
            subPipelineId: props.subPipelineId
        }),
        onSuccess: (response) => {
            setSelectedStage(null);
            setEditStage(false);
            props.highlightStage(false);
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
            setSnackbarInfo({
                open: true,
                severity: 'success',
                message: response?.message
            });
        }
    });

    useEffect(() => {
        if (reorderStageIsError) {
            setErr(reorderStageError);
            setStages(props.stages);
            setSnackbarInfo({
                open: true,
                severity: 'error',
                message: reorderStageError?.response?.data?.error?.message
            });
        }
    }, [reorderStageIsError]);

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    const reorderItems = (result) => {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        let endIndex = result.destination.index;
        const itemArray = [...stages];
        const [removed] = itemArray.splice(startIndex, 1);
        itemArray.splice(endIndex, 0, removed);
        setStages(itemArray);
        handleReorderStage(itemArray);
    }

    return (
        <>
            <Typography sx={{ fontWeight: 'bold', pl: 2, pt: 2 }}>
                {props.label}
            </Typography>

            <DragDropContext onDragEnd={reorderItems} >
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {stages?.map((stage, stageIndex) => {
                                return (
                                    <Draggable key={stage._id} draggableId={stage._id} index={stageIndex} isDragDisabled={settingsAtom.freezeActions}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                variant={snapshot.isDragging ? "elevation" : "outlined"}
                                                elevation={4}
                                            >
                                                <Stack alignItems="center" direction="row" sx={{ width: "100%", pl: 1, cursor: settingsAtom.freezeActions ? "default" : "move", "&:hover": { backgroundColor: `${variables.lightGreyBackgroundColor}` } }} {...provided.dragHandleProps}>
                                                    <Typography component="div">
                                                        <DragIndicatorIcon sx={{ color: settingsAtom.freezeActions ? "grey" : "inherit" }} />
                                                    </Typography>
                                                    <StageRow stage={stage} stages={stages} stageIndex={stageIndex} teamPipelineId={props.teamPipelineId} subPipelineId={props.subPipelineId} />
                                                </Stack>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <Stack direction="column" sx={{ pr: 2 }}>
                {editStage &&
                    <Stack direction="row" sx={{ p: 1, pb: 0.6, pr: 0 }} spacing={0.5} width="100%" alignItems="center">
                        <Typography component="div">
                            <DragIndicatorIcon sx={{ color: settingsAtom.freezeActions ? "grey" : "inherit" }} />
                        </Typography>
                        <Autocomplete
                            freeSolo
                            sx={{ ml: 0 }}
                            size="small"
                            fullWidth
                            value={selectedStage}
                            onInputChange={(event, newValue) => {
                                setErr(null);
                                setSelectedStage(newValue);
                            }}
                            onChange={(event, newValue) => {
                                setErr(null);
                                setSelectedStage(newValue);
                            }}
                            options={availableStages}
                            renderInput={(params) => <TextField sx={{ border: err ? '1px solid red' : 'inherit', borderRadius: 1 }} {...params} />}
                        />
                    </Stack>
                }
                <Stack direction="row">
                    <Stack direction="row" alignItems="center" width="77%" sx={{ pl: 2 }}>
                        <Button disabled={settingsAtom.freezeActions} sx={{
                            textTransform: 'none'
                        }} onClick={() => { setEditStage(true); props.highlightStage(true); }}>
                            <AddIcon fontSize="small" />
                            <Typography component="div">
                                Stage
                            </Typography>
                        </Button>
                    </Stack>
                    {editStage &&
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="right" width="23%" sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", p: 1, mt: -0.6 }}>
                            <IconButton sx={{ height: 25, width: 25, bgcolor: `${variables.lightGreyBackgroundColor} !important`, color: `${variables.pipelineBoardText}`, border: `1px solid ${variables.borderColor}` }} size="small"
                                onClick={() => { setErr(null); setEditStage(false); props.highlightStage(false); setSelectedStage(null); }}>
                                <CloseOutlinedIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton sx={{ height: 25, width: 25, bgcolor: `${variables.linkColor} !important`, color: "#fff !important", opacity: selectedStage === null ? 0.5 : 1 }} size="small"
                                disabled={selectedStage === null}
                                onClick={() => { handleAddStage() }}>
                                <CheckOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Stack>
                    }
                </Stack>
            </Stack>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />
        </>
    )
}