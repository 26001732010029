// App.js

import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import NameFields from "./NameFields";
import Address from "./Address";
import Phone from "./Phone";
import Email from "./Email";
import Dates from "./Dates";
import Times from "./Times";
import names from "../../../images/username1.png";
import address from "../../../images/phone-book.png";
import phone from "../../../images/telephone.png";
import email from "../../../images/mail.png";
import date from "../../../images/calendar.png";
import time from "../../../images/clock.png";
import dropdown from "../../../images/dropdown-arrow.png";
import radio from "../../../images/radio.png";
import multi from "../../../images/test.png";
import checkbox from "../../../images/checkbox.png";
import website from "../../../images/globe.png";
import currency from "../../../images/money.png";
import fileuplode from "../../../images/files.png";
import multiline from "../../../images/menu.png";
import Multiline from "./Multiline";


const Typography = styled.div`
  width: 100%;
  font-size: 18px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 10px;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
`;




const Form = () => {
  const [formElements, setFormElements] = useState([]);
  const [isDragContextEmpty, setIsDragContextEmpty] = useState(true);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedFormElements = [...formElements];
    const [removed] = reorderedFormElements.splice(result.source.index, 1);
    reorderedFormElements.splice(result.destination.index, 0, removed);
    setFormElements(reorderedFormElements);
  };

  const handleDrop = (e, componentType) => {
    e.preventDefault();
    const newItem = {
      id: Date.now().toString(), // Generate a unique ID
      component:
        componentType === "input" ? (
          <NameFields />
        ) : componentType === "custom" ? (
          <Address />
        ) : componentType === "phone" ? (
          <Phone />
        ) : componentType === "email" ? (
          <Email />
        ) : componentType === "date" ? (
          <Dates />
        ) : componentType === "time" ? (
          <Times />
        ) : componentType === "multiline" ? (
          <Multiline />
        ) : (
          ""
        ),
    };
    setFormElements([...formElements, newItem]);
    setIsDragContextEmpty(false); // The context is no longer empty
  };



 

  useEffect(() => {
    if (formElements.length < 1) {
      setIsDragContextEmpty(true);
    }
  }, [formElements]);

  return (
    <div className="form-builder">
      <Grid container>
        <Grid
          item
          xs={5}
          sm={4}
          md={3}
          display={"flex"}
          justifyContent={"center"}
        >
          <div className="drag-container">
            <Typography> Basic Fields </Typography>
            <div
              className="drag-container-box"
              style={{ display: "flex", width: "100%" }}
            >
              <Grid container>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "input")
                    }
                    className="dragName"
                  >
                    <img
                      src={names}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Name
                  </div>
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "custom")
                    }
                    className="dragName"
                  >
                    <img
                      src={address}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Address
                  </div>
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "phone")
                    }
                    className="dragName"
                  >
                       <img
                      src={phone}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Phone
                  </div>
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "email")
                    }
                    className="dragName"
                  >
                    <img
                      src={email}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Email
                  </div>
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "date")
                    }
                    className="dragName"
                  >
                    <img
                      src={date}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Date
                  </div>
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "time")
                    }
                    className="dragName"
                  >
                    <img
                      src={time}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Time
                  </div>
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "dropdown")
                    }
                    className="dragName"
                  >
                    <img
                      src={dropdown}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    DropDown
                  </div>
                </Grid>
                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "custom")
                    }
                    className="dragName"
                  >
                    <img
                      src={radio}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Radio
                  </div>
                </Grid>

                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "multiplechoice")
                    }
                    className="dragName"
                  >
                    <img
                      src={multi}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Multiple Choice
                  </div>
                </Grid>

                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "checkbox")
                    }
                    className="dragName"
                  >
                    <img
                      src={checkbox}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Check Box
                  </div>
                </Grid>

                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "website")
                    }
                    className="dragName"
                  >
                    <img
                      src={website}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Website
                  </div>
                </Grid>

                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "currency")
                    }
                    className="dragName"
                  >
                    <img
                      src={currency}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Currency
                  </div>
                </Grid>

                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "fileuplode")
                    }
                    className="dragName"
                  >
                    <img
                      src={fileuplode}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    File Uplode
                  </div>
                </Grid>

                <Grid item xs={6} display={"flex"} justifyContent={"center"}>
                  <div
                    draggable
                    onDragStart={(e) =>
                      e.dataTransfer.setData("text/plain", "multiline")
                    }
                    className="dragName"
                  >
                    <img
                      src={multiline}
                      alt="Name"
                      width={10}
                      height={10}
                      style={{ marginRight: 5 }}
                    />
                    Multi Line
                  </div>
                </Grid>

         
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={7}
          sm={8}
          md={9}
          display={"flex"}
          justifyContent={"center"}
        >
          <div className="form-preview">
            <Typography>Form Preview</Typography>
            <DragDropContext
              onDragEnd={handleDragEnd}
              style={{ width: "100%" }}
            >
              <Droppable droppableId="form-elements" style={{ width: "100%" }}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    onDrop={(e) =>
                      handleDrop(e, e.dataTransfer.getData("text/plain"))
                    }
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {isDragContextEmpty && (
                      <div
                        className="drop-zone"
                        draggable
                        onDragStart={(e) =>
                          e.dataTransfer.setData("text/plain", "default")
                        }
                      >
                        Start Building!
                        <p
                          style={{
                            fontSize: 12,
                            marginTop: 10,
                            color: "#444444c4",
                          }}
                        >
                          Drag fields from the left panel and drop here to add
                          them to your form.
                        </p>
                      </div>
                    )}
                    {formElements.map((element, index) => (
                      <>
                        <Draggable
                          key={element.id}
                          draggableId={element.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div className="form-element">
                                {element.component}
                          
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
       
            <div>
       
      </div>
          
          </div>
        </Grid>
      </Grid>

      <style>
        {`   
        
        /* Style the form builder container */
        .form-builder {
          display: flex;
          flex-direction: row;
          width: 100%;
          height:100vh;
          font-family: "Poppins",sans-serif;
          background-color:#f7f7f7
        }
        
        /* Style the draggable items container */
        .drag-container {
          width: 100%;
          height:auto;
          border: 1px solid #ccc;
          background-color: #ecf0f0;
          font-size:12px;
        }
        .dragName{
            width: 100%;
            padding: 10px;
            border: 1px solid #00000026;
            margin: 10px;
            background-color:#fff;
            border-radius:5px;
            cursor:pointer
        }

        [data-rbd-droppable-id="form-elements"]{
            width:100%
        }

        /* Style individual draggable items */
        .draggable-item {
          padding: 8px;
          margin: 8px 0;
          background-color: #efefef;
          cursor: pointer;
          user-select: none;
        }
        
        /* Style the form elements container */
        .drop-container {
          flex: 1;
          padding: 20px;
          border: 1px solid #ccc;
          background-color: #ffffff;
        }
        
        /* Style individual form elements */
        .form-element {
          width:100%;
        }
        
        /* Style the form preview */
        .form-preview {
          width: 70%;
          border: 1px solid #ccc;
          background-color: #ffffff;
          display: flex;
          margin: 20px;
    flex-direction: column;
    align-items: center;
        }
        
        /* Style form element labels */
        .form-element label {
          display: block;
          margin-bottom: 5px;
          font-size:14px
        }
        
  
        .drop-zone {
            height: 100px;
            border: 1px dashed #ccc;
            padding: 20px;
            text-align: center;
            margin:20px;
            background: #fbfbfb;
          }
        
        /* Style text input fields */
        .form-element input {
          width: 80%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }`}
      </style>
    </div>
  );
};

export default Form;
