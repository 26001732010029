import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import DepartmentTableToolbar from "./DepartmentTableToolbar";
import DepartmentTableHeader from "./DepartmentTableHeader";
import {
  addDepartment,
  deleteDepartment,
  getDepartmentList,
  setDepartmentFormData,
  updateDepartment,
} from "../../../redux/actions/manage-company-action-creators";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/manageCompany.css";
import DepartmentDialog from "./DepartmentDialog";
import CommonDialog from "../../common/CommonDialog";

function createData(department_id, department_name, company_name, branch_name) {
  return { department_id, department_name, company_name, branch_name };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 10,
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
let currDepartmentName = "";
export default function DepartmentTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [order, setOrder] = useState("asc");
  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDrawer, setopenDrawer] = useState(false);
  const [drawerMode, setDrawerMode] = useState("ADD");

  const { userData } = useSelector((state) => state.login);
  const { companyInfo, departmentList, currentBranch, branchList } = useSelector((state) => state.manageCompany);
  useEffect(() => {
    const params = {
      action: "get_department_list",
      token: userData.data.token,
      company_id: companyInfo.company_id,
      branch_id: currentBranch.branch_id,
    };
    dispatch(getDepartmentList(params));
  }, [branchList]);

  useEffect(() => {
    let tempRow = [];
    departmentList.map((item) => tempRow.push(createData(item.department_id, item.department_name, item.company_name, item.branch_name)));
    setRows(tempRow);
  }, [departmentList]);

  const toggleDrawer = () => {
    // if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
    //   return;
    // }
    setopenDrawer(openDrawer ? false : true);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name, rowData) => {

    currDepartmentName = name;
    const formData = {
      department_id: rowData.department_id,
      branch_name: rowData.branch_name,
      company_name: rowData.company_name,
      department_name: rowData.department_name,
    };
    dispatch(setDepartmentFormData(formData));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const editIconClick = (e) => {
    setDrawerMode("EDIT");
    toggleDrawer();
  };

  const deleteIconOnClick = (e) => {
    setTimeout(() => {
      const currRowData = departmentList.filter((item) => item.department_id == currDepartmentName)[0];
      const params = {
        action: "delete_department",
        token: userData.data.token,
        department_id: currRowData.department_id,
      };
      dispatch(deleteDepartment(params));
    }, 200);
  };
  const saveOnClick = (formData) => {
    dispatch(setDepartmentFormData(Object.assign(formData, { action: drawerMode === "ADD" ? "add_department" : "update_department" })));
    if (drawerMode === "ADD") {
      dispatch(addDepartment());
    } else if (drawerMode === "EDIT") {
      setTimeout(() => {
        const currRowData = departmentList.filter((item) => item.department_id == currDepartmentName)[0];
        const params = {
          token: userData.data.token,
          company_id: companyInfo.company_id,
          department_id: currRowData.department_id,
          branch_id: currentBranch.branch_id,
        };
        dispatch(updateDepartment(params));
      }, 200);
    }
  };
  const addCompanyOnClick = (e) => {
    setDrawerMode("ADD");
    toggleDrawer();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const deleteIcon = (
    <IconButton component="span" id="deleteIcon" onClick={deleteIconOnClick}>
      <DeleteIcon color="secondary" />
    </IconButton>
  );

  const editIcon = (
    <IconButton component="span" id="editIcon" onClick={editIconClick}>
      <EditIcon color="primary" />
    </IconButton>
  );
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <DepartmentTableToolbar />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
            <DepartmentTableHeader
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              addCompanyOnClick={addCompanyOnClick}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.department_id, row)}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.department_id}
                    >
                      <TableCell align="left">{row.department_id}</TableCell>
                      <TableCell align="left">{row.department_name}</TableCell>
                      <TableCell align="left">{row.company_name}</TableCell>
                      <TableCell align="left">{row.branch_name}</TableCell>
                      <TableCell align="left" id="test">
                        {editIcon}
                      </TableCell>
                      <TableCell align="left" id="delete">
                        {deleteIcon}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <DepartmentDialog open={openDrawer}  saveOnClick={saveOnClick} mode={drawerMode} /> */}

      <CommonDialog
        open={openDrawer}
        title={drawerMode === "ADD" ? <div>Add Department</div> : <div>Edit Department</div>}
        toggleDrawer={toggleDrawer}
        content={<DepartmentDialog saveOnClick={saveOnClick} mode={drawerMode} toggleDrawer={toggleDrawer} />}
      />
    </div>
  );
}
