import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { CloseButton } from "../CloseButton";

export function DeleteNote(props) {

    const [open, setOpen] = useState(true);

    const handleClose = () => {
        setOpen(false);
        props.handleCancel();
    }

    const handleOk = () => {
        setOpen(false);
        props.handleOk();
    }

    return (
        <Modal
            open={open}
            aria-labelledby="delete-deal-title"
            aria-describedby="delete-deal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                p: "16px 24px"
            }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>Delete Deal
                    <CloseButton handleDialogClose={handleClose} />
                </Typography>

                <Typography component="div" variant="body" sx={{ mt: 3, mb: 1 }}>
                    Are you sure you want to delete this {props.module}?
                </Typography>

                <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleOk}>Yes, Delete</Button>
                </Stack>
            </Box>
        </Modal>
    )
}