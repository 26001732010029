import { Box, Stack, Typography } from "@mui/material";
import { CustomInputField } from "../components/CustomInputField"
import { CustomSelect } from "../components/CustomSelect";
import { get, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import variables from "../../../assets/styles/_colors.scss";

const options = [
    {
        label: "Is",
        value: "="
    },
    {
        label: "Isn't",
        value: "!="
    },
    {
        label: "Contains",
        value: "contains"
    },
    {
        label: "Doesn't contain",
        value: "not_contains"
    },
    {
        label: "Starts With",
        value: "starts_with"
    },
    {
        label: "Ends With",
        value: "ends_with"
    },
    {
        label: "Is Empty",
        value: "is_empty"
    },
    {
        label: "Is Not Empty",
        value: "not_empty"
    }
];

const TextType = (props) => {

    const { watch, formState: { errors }, resetField, setValue } = useFormContext();
    const [showField, setShowField] = useState(true);
    const fieldErrors = get(errors, props.name);

    const selectedOption = watch(`${props.name}.comparator`);

    const hideField = ["is_empty", "not_empty"];

    useEffect(() => {
        if (hideField.includes(selectedOption)) {
            setShowField(false);
            resetField(`${props.name}.value`);
        } else {
            setShowField(true);
        }
    }, [selectedOption]);

    useEffect(() => {
        setValue(`${props.name}.comparator`, options[0].value);
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>

            <Stack direction="row" sx={{ width: '100%', pt: 1 }} spacing={1}>
                <Stack direction="row" sx={{ width: '50%' }}>
                    <CustomSelect size="small" options={options} name={`${props.name}.comparator`} defaultValue={options[0].value} />
                </Stack>
                <Stack direction="row" sx={{ width: '50%' }}>
                    {showField &&
                        <CustomInputField size="small" rules={{ required: { value: true } }} name={`${props.name}.value`} />}
                </Stack>
            </Stack>

            {fieldErrors &&
                <Box sx={{ color: variables.removeIconColor, pt: 1 }}>
                    {fieldErrors["value"] && <Typography sx={{ fontSize: "0.85rem" }}> Please provide a valid {props.field?.label} </Typography>}
                </Box>
            }
        </>
    )
};

export default TextType;