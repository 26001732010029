import { Avatar, Divider, IconButton, ListItemAvatar, ListItemButton, ListItemText, Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import variables from "../../../../assets/styles/_colors.scss";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from "react";
import DeactivateUser from "./DeactivateUser";
import DeleteUser from "./DeleteUser";
import ActivateUser from "./ActivateUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { inviteUser } from "../../../../webservices/UserControlService";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

export default function UserTablePopover(props) {

    const queryClient = useQueryClient();

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [activateUser, showActivateUser] = useState(false);
    const [deactivateUser, showDeactivateUser] = useState(false);
    const [deleteUser, showDeleteUser] = useState(false);

    const rowActions = [
        props.selectedTab === "active" && {
            name: "Deactivate",
            icon: <BlockOutlinedIcon />,
            key: 'deactivate'
        },
        props.selectedTab === "invited" && {
            name: "Reinvite",
            icon: <PersonAddAltOutlinedIcon />,
            key: 'reinvite'
        },
        props.selectedTab === "inactive" && {
            name: "Activate",
            icon: <CheckCircleOutlinedIcon />,
            key: 'activate'
        },
        props.selectedTab !== "deleted" && {
            name: "Delete",
            icon: <DeleteOutlineIcon />,
            key: "delete"
        }
    ];

    const { mutateAsync } = useMutation({
        mutationKey: "inviteUser",
        mutationFn: (id) => inviteUser(id),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
            queryClient.invalidateQueries({ queryKey: ['getUsersCount'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = (data) => {
        setAnchorEl(null);
        props.handleClose(data);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "reinvite":
                    mutateAsync(props.item?.[0].original._id);
                    return true;
                case "deactivate":
                    showDeactivateUser(true);
                    return true;
                case "activate":
                    showActivateUser(true);
                    return true;
                case "delete":
                    showDeleteUser(true);
                    return true;
                default:
                    return <></>
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'sub-pipeline-popover' : undefined;

    return (
        <span style={{ 'display': 'inline-block' }}>
            <IconButton aria-label="pipeline-popover" onClick={handleClick} sx={{ height: "35px" }} >
                <MoreVertIcon />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.pipeline }}
            >
                {rowActions.map((item, index) => (
                    item &&
                    <span key={`pipeline-popover-item-${index}`}>
                        <ListItemButton
                            key={`${item.name}-${index}`}
                            sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemAvatar sx={{ minWidth: 46 }}>
                                <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                                    {item.icon}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </span>
                ))}
            </Popover>

            {
                deactivateUser && <DeactivateUser item={props.item?.[0]?.original} handleClose={(data) => { showDeactivateUser(false); handleClose(data); }} />
            }

            {
                deleteUser && <DeleteUser item={props.item?.[0]?.original} handleClose={(data) => { showDeleteUser(false); handleClose(data); }} />
            }

            {
                activateUser && <ActivateUser item={props.item} handleClose={(data) => { showActivateUser(false); handleClose(data); }} />
            }

        </span>
    );
}