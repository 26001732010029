import { Box, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";

const rows = [
        {
        stage: "Negotiation Review",
        amount: "$ 5000",
        duration: "3",
        date: "Feb 10, 2024 10.30 AM",
    }
]
const StageHistory = () => {

    return (
        <>
            <TableContainer component={Paper} sx={{boxShadow: "none", borderBottom: `1px solid ${variables.borderColor}` }}>
                <Table sx={{fontSize: "14px"}}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontSize: "1rem" }} width="20%"> Stage </TableCell>
                            <TableCell sx={{fontSize: "1rem" }}  width="20%"> Amount </TableCell>
                            <TableCell sx={{fontSize: "1rem" }}  width="20%"> Stage Duration (In Days) </TableCell>
                            <TableCell sx={{fontSize: "1rem" }}  width="20%"> Modified By </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{fontSize: "1rem", fontWeight: "bold" }}  width="20%">{row.stage}</TableCell>
                                <TableCell sx={{fontSize: "1rem" }}  width="20%">{row.amount}</TableCell>
                                <TableCell sx={{fontSize: "1rem" }}  width="20%">{row.duration}</TableCell>
                                <TableCell sx={{fontSize: "1rem" }}  width="20%">{row.date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
export default StageHistory;