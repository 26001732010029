import { Alert, Button, Snackbar, TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { api_url } from "../../globalSettings";
import { profile_trigger, selected_contact } from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";

const ContactTabNote = () => {
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
  const [description , setDescription] = useState('');
  const [selectedContact] = useRecoilState(selected_contact);


  const addNotes = () => {
    const params = JSON.stringify({
      
        customer_id:selectedContact._id,
        addNote:[{
            description:description,
            date: new Date()
        }]
    
    });
    axios
      .post(`${api_url}/crm_customer/addNote`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        setSnackbaropen({
          open: true,
          message: response.data.status,
          severity: "success",
          actionColor: "#edf7ed",
        });
        setProfileTrigger(!profileTrigger)
      })

      .catch(function (error) {
        if (error.response) {
          
        } else if (error.request) {
       
        } else {
       
        }
      });
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >   <Snackbar
    open={snackbaropen.open}
    onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
    autoHideDuration={6000}
  >
    <Alert
      severity={snackbaropen.severity}
      style={{ backgroundColor: `${snackbaropen.actionColor}` }}
    >
      {snackbaropen.message}
    </Alert>
  </Snackbar>
      <TextField
        variant="outlined"
        type="text"
        rows={3}
        InputProps={{
          style: {
            borderRadius: "0px",
          },
        }}
        value={description}
        onChange={(e)=>{setDescription(e.target.value)}}
        multiline
        fullWidth
        placeholder="Type in the text here to create a note"
      />
      <Button
        style={{
          width: "100px",
          marginTop: "20px",
        }}
        variant="contained" 
        disabled={description.length > 0 ? false : true}

        onClick={addNotes}
      >
        AddNote{" "}
      </Button>{" "}
    </div>
  );
};

export default ContactTabNote;
