import React , {useState} from 'react'
import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { styled } from "@mui/material/styles";
import { attendanceReports , performanceReports } from '../../Atoms/LoginAtoms';
import { useRecoilState } from "recoil";
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import ImageSliderDialog from './ImageSliderDialog ';
import AudioSliderDialog from './AudioSliderDialog';
import NotesSliderDialog from './NotesSliderDialog';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0000003d",
      color: theme.palette.common.white,
      padding: 10
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  


export default function EmpPerformanceReport() {
  const [getattendanceReport , setAttendanceReport] = useRecoilState(attendanceReports)
  const [getPerformanceReport , setPerformanceReport] = useRecoilState(performanceReports)
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const [isDialogOpen1, setIsDialogOpen1] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState([]);

  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);


  
  const openDialog = (image_name) => {
    setSelectedImages(image_name)
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog1 = (audio_name) => {
    setSelectedAudio(audio_name)
    setIsDialogOpen1(true);
  };

  const closeDialog1 = () => {
    setIsDialogOpen1(false);
  };

  const openDialog2 = (file_name) => {

    setSelectedFile(file_name)
    setIsDialogOpen2(true);
  };

  const closeDialog2 = () => {
    setIsDialogOpen2(false);
  };

  const [fileContents, setFileContents] = useState([]);
 

    const showText = async (Files) => {
 
 
      const fetchData = async () => {
        try {
          const fetchedData = await Promise.all(
            Files.map(async filePath => {
              const response = await fetch(`https://server.nowdigitaleasy.com:3505/uploads/task/${filePath}`);
              const data = await response.text();
              return { filePath, content: data };
            })
          );
          setFileContents(fetchedData);
          openDialog2();
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    };

  return (
  <div><div className="reporttop">
  <Typography>Employee Performance Details</Typography>
  <Typography 

  style={{cursor : "pointer"}}><FilterAltOutlinedIcon /></Typography>       
</div>
<div className="reporttable">
  <TableContainer sx={{ maxHeight : 500 }} component={Paper}>
    <Table sx={{ minWidth: 700 , maxHeight : 300 }} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell align="center">Day</StyledTableCell>
          <StyledTableCell align="center">Date</StyledTableCell>
          <StyledTableCell align="center">Task Category</StyledTableCell>
          <StyledTableCell align="center">Task Name</StyledTableCell>
          <StyledTableCell align="center" style={{color : "black"}}>Task Description</StyledTableCell>
          <StyledTableCell align="center">Task Status</StyledTableCell>
          <StyledTableCell align="center"style={{color : "black"}}>Estimated Task Time</StyledTableCell>
          <StyledTableCell align="center"style={{color : "black"}}>Actual Start Time</StyledTableCell>
          <StyledTableCell align="center">Actual End Time</StyledTableCell>
          <StyledTableCell align="center">Task Duration</StyledTableCell>
          <StyledTableCell align="center">Pause Duration</StyledTableCell>
          <StyledTableCell align="center">Delay Duration</StyledTableCell>
          <StyledTableCell align="center" style={{color : "black"}}>Image</StyledTableCell>
          <StyledTableCell align="center" style={{color : "black"}}>Audio</StyledTableCell>
          <StyledTableCell align="center" style={{color : "black"}}>Notes</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {getPerformanceReport ? getPerformanceReport.map((row) => (
          
          <StyledTableRow 
     
          style={{cursor : "pointer"}}>
             
            <StyledTableCell component="th" scope="row">{row.task_date ? row.task_date : "--"}</StyledTableCell>
            <StyledTableCell width={"120px"}  align="center">{row.task_date ? row.task_date : "--"}</StyledTableCell>
            <StyledTableCell align="center">{row.task_category ? row.task_category  : "--" }</StyledTableCell>
            <StyledTableCell align="center">{row.task_name ? row.task_name  : "--" }</StyledTableCell>
            <StyledTableCell align="center">{row.task_desc ? row.task_desc : "--"}</StyledTableCell>
            <StyledTableCell align="center">{row.task_status ? row.task_status : "--"}</StyledTableCell>
            <StyledTableCell align="center">{row.task_type === "quick" ? row.start_time : row.task_time }</StyledTableCell>
            <StyledTableCell align="center">{row.start_time ? row.start_time : "--" }</StyledTableCell>
            <StyledTableCell align="center">{row.end_time ? row.end_time : "--" }</StyledTableCell>
            <StyledTableCell align="center">{row.task_duration ? row.task_duration : "--"}</StyledTableCell>
            <StyledTableCell align="center">--</StyledTableCell>
            <StyledTableCell align="center">{row.task_status} </StyledTableCell>

            <StyledTableCell align="center" onClick={() =>openDialog(row.image_name)}>{row.image_name.length > 0 ? <WallpaperOutlinedIcon /> : "--"}</StyledTableCell>
            <ImageSliderDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        images={selectedImages} 
      />
            <StyledTableCell align="center" onClick={() =>openDialog1(row.audio_name)}>{row.audio_name.length > 0  ? <VolumeUpOutlinedIcon />
: "--"}</StyledTableCell>
  <AudioSliderDialog
        isOpen={isDialogOpen1}
        onClose={closeDialog1}
        Audio={selectedAudio} 
      />
          <StyledTableCell align="center" onClick={() =>showText(row.textfile_name)}>{row.textfile_name.length > 0 ? 
          <NoteAltOutlinedIcon />
           : "--"  }</StyledTableCell>
           <NotesSliderDialog 
isOpen={isDialogOpen2}
onClose={closeDialog2}
File={fileContents} //`https://server.nowdigitaleasy.com:3505/uploads/task/${row.audio_name[0]}`
/>
          </StyledTableRow>
        )) : "NO REPORT FOUND"} 
      </TableBody>
    </Table>
  </TableContainer>

  </div></div>
)
}
