import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import { useEffect } from 'react';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import { Stack } from '@mui/material';
import variables from "../../../assets/styles/_colors.scss";

const stepss = [
    {
        stage: "Needs Analysis",
        id: "1",
        openStage: true
    },
    {
        stage: "Qualification",
        id: "2",
        openStage: true
    },
    {
        stage: "Proposal",
        id: "3",
        openStage: true
    },
    {
        stage: "Closed Won",
        id: "4",
        closeStage: true,
        success: true
    },
    {
        stage: "Closed Lost",
        id: "5",
        closeStage: true,
        success: false
    }
];

export default function PipelineStepper() {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [error, setError] = React.useState({ 0: false, 1: false, 2: false, 3: true, 4: false });
    const [success, setSuccess] = React.useState(null);

    const handleStep = (index) => () => {
        let obj = {};
        obj = [...Array(index)].map((_, i) => {
            return obj[i] = stepss[i].openStage ? true : false;
        });
        setSuccess(stepss[index].closeStage !== undefined ? (stepss[index].success ? true : false) : null);
        setCompleted(obj);
        setActiveStep(index);
    };

    useEffect(() => {
    }, [success]);

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                {stepss.map((step, index) => (
                    <Step key={step.id} completed={completed[index]}
                        sx={{
                            "& .Mui-completed .MuiStepConnector-line, & .Mui-active .MuiStepConnector-line": {
                                borderColor: success === null ? `${variables.linkColor}` : (success ? `${variables.closeStageSuccessColor}` : `${variables.removeIconColor}`)
                            },
                            ...(success === false && {
                                ".MuiStepConnector-line": {
                                    borderColor: `${variables.removeIconColor}`
                                }
                            })
                        }}>
                        <StepButton color="inherit" onClick={handleStep(index)} disableRipple
                            sx={{
                                ".MuiStepLabel-label": {
                                    marginTop: "4px !important"
                                },
                                "& .MuiStepLabel-iconContainer .Mui-completed": {
                                    color: success === null ? `${variables.linkColor}` : (success ? `${variables.closeStageSuccessColor}` : `${variables.removeIconColor}`)
                                },
                                "& .MuiStepLabel-labelContainer .Mui-completed": {
                                    color: success === null ? 'rgba(0, 0, 0, 0.87)' : (success ? `${variables.closeStageSuccessColor}` : `${variables.removeIconColor}`)
                                }
                            }}>
                            <Stack direction="row" alignItems="center">
                                {step.stage}
                                {(step.closeStage === true && step.success) && <ThumbUpOutlinedIcon fontSize="small" sx={{ ml: 1, color: `${variables.closeStageSuccessColor}` }} />}
                                {(step.closeStage === true && !step.success) && <ThumbDownOutlinedIcon fontSize="small" sx={{ ml: 1, color: `${variables.removeIconColor}` }} />}
                            </Stack>

                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box >
    );
}