import axios from 'axios';
import { crm_api_url } from '../../../globalSettings';

export const getAllPipelines = async () => {

  return axios.get(`${crm_api_url}/pipelines/1`).then((data) => data);

}

export const getGivenStageData = async (param) => {

  const url = `${crm_api_url}/pipelines/${param.title}/limit/${param.limit}/page/${param.pageParam}/`;
  return axios.get(url).then(({ data }) => data);

};

export const getPipelineBoardColumns = async () => {

  const url = `${crm_api_url}/pipelines/board/columns`;
  return axios.get(url).then(({ data }) => data);

}

export const getTableColumns = async () => {

  const url = `${crm_api_url}/pipelines/table/columns/`;
  return axios.get(url).then(({ data }) => data);

}

export const getGiveStageDataListView = async (start, fetchsize, sorting) => {

  const url = `${crm_api_url}/pipelines/table/index/${start}/size/${fetchsize}`;
  return axios.get(url).then(({ data }) => data);

};

export const getPipelineTemplates = async () => {

  const url = `${crm_api_url}/pipelines/templates`;
  return axios.get(url).then(({ data }) => data);

}

export const getTemplateFields = async () => {

  const url = `${crm_api_url}/pipelines/templates/1/fields`;
  return axios.get(url).then(({ data }) => data);

}

export const getProducts = async () => {

  const url = `${crm_api_url}/products`;
  return axios.get(url).then(({ data }) => data);

}

export const getViews = async (module) => {

  const url = (module === "pipeline" && `${crm_api_url}/pipelines/views`)
    || (module === "task" && `${crm_api_url}/task/views`)
    || (module === "event" && `${crm_api_url}/event/views`)
    || (module === "call" && `${crm_api_url}/call/views`)
    || (module === "contacts" && `${crm_api_url}/contact/views`)
    || (module === "companies" && `${crm_api_url}/company/views`)
    || (module === "products" && `${crm_api_url}/product/views`);
  return axios.get(url).then(({ data }) => data);

}

export const getTaskTableData = async (start, fetchsize, sorting) => {

  const url = `${crm_api_url}/activity/task/table/index/${start}/size/${fetchsize}`;
  return axios.get(url).then(({ data }) => data);

};

export const getEventTableData = async (start, fetchsize, sorting) => {

  const url = `${crm_api_url}/activity/event/table/index/${start}/size/${fetchsize}`;
  return axios.get(url).then(({ data }) => data);

};

export const getCallTableData = async (start, fetchsize, sorting) => {

  const url = `${crm_api_url}/activity/call/table/index/${start}/size/${fetchsize}`;
  return axios.get(url).then(({ data }) => data);

};

export const getActivityColumns = async (type) => {

  let url = (type === "tasks" && `${crm_api_url}/activity/task/columns`)
    || (type === "events" && `${crm_api_url}/activity/event/columns`)
    || (type === "calls" && `${crm_api_url}/activity/call/columns`)
  return axios.get(url).then(({ data }) => data);

}

export const getContactColumns = async () => {

  const url = `${crm_api_url}/contact/columns/`;
  return axios.get(url).then(({ data }) => data);

}

export const getContactTableData = async (start, fetchsize, sorting) => {

  const url = `${crm_api_url}/contact/table/index/${start}/size/${fetchsize}`;
  return axios.get(url).then(({ data }) => data);

};

export const getCompanyColumns = async () => {

  const url = `${crm_api_url}/company/columns/`;
  return axios.get(url).then(({ data }) => data);

}

export const getCompanyTableData = async (start, fetchsize, sorting) => {

  const url = `${crm_api_url}/company/table/index/${start}/size/${fetchsize}`;
  return axios.get(url).then(({ data }) => data);

};

export const getProductColumns = async () => {

  const url = `${crm_api_url}/product/columns/`;
  return axios.get(url).then(({ data }) => data);

}

export const getProductTableData = async (start, fetchsize, sorting) => {

  const url = `${crm_api_url}/product/table/index/${start}/size/${fetchsize}`;
  return axios.get(url).then(({ data }) => data);

};

export const createPipeline = async (data) => {

  const url = `${crm_api_url}/pipelines`;
  return axios.post(url, data).then(({ data }) => data);
}

export const createSubPipeline = async (pipelineId, data) => {

  const url = `${crm_api_url}/pipelines/${pipelineId}/subpipeline`;
  return axios.post(url, data).then(({ data }) => data);
}

export const updateSubPipelineName = async (subPipelineId, subPipelineName) => {

  const url = `${crm_api_url}/pipelines/${subPipelineId}/name`;
  return axios.put(url, { subPipelineName: subPipelineName }).then(({ data }) => data);
}

export const setSubPipelineAsDefault = async (pipelineId, subPipelineId) => {

  const url = `${crm_api_url}/pipelines/${pipelineId}/${subPipelineId}/default`;
  return axios.put(url, {}).then(({ data }) => data);
}

export const deleteSubPipeline = async (pipelineId, subPipelineId) => {

  const url = `${crm_api_url}/pipelines/${pipelineId}/${subPipelineId}`;
  return axios.delete(url, {}).then(({ data }) => data);
}

export const getAllStages = async (data) => {

  const url = `${crm_api_url}/stages/all`;
  return axios.get(url).then(({ data }) => data);
}

export const getAllStagesBySubPipelineId = async (subpipelineId) => {

  const url = `${crm_api_url}/stages/subpipeline/${subpipelineId}`;
  return axios.get(url).then(({ data }) => data);
}

export const addStage = async (data) => {

  const url = `${crm_api_url}/stages/`;
  return axios.post(url, data).then(({ data }) => data);
}

export const reorderStage = async (data) => {

  const url = `${crm_api_url}/stages/reorder`;
  return axios.put(url, data).then(({ data }) => data);
}

export const renameStage = async (stageId, data) => {

  const url = `${crm_api_url}/stages/${stageId}/rename`;
  return axios.put(url, data).then(({ data }) => data);
}

export const replaceStage = async (stageId, data) => {

  const url = `${crm_api_url}/stages/${stageId}/replace`;
  return axios.put(url, data).then(({ data }) => data);
}

export const deleteStage = async (stageId, data) => {

  const url = `${crm_api_url}/stages/${stageId}`;
  return axios.delete(url, { data }).then(({ data }) => data);
}

export const getFormFields = async () => {

  const url = `${crm_api_url}/stages/formfields`;
  return axios.get(url).then(({ data }) => data);
}