import { Box, Grid, Popover, Typography } from "@mui/material"
import { useState } from "react";
import { makeStyles } from '@mui/styles';
import variables from "../../../assets/styles/_colors.scss";
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import moment from "moment/moment";
import EventMoreActionPopover from "./EventMoreActionPopover";
import EventIcon from '@mui/icons-material/Event';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

const useStyles = makeStyles((theme) => ({
    event: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

const EventInfo = (props) => {

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(props.anchor ? props.anchor : null);
    const open = Boolean(props.anchor);
    const id = open ? 'event-popover' : undefined;

    const handleClose = () => {
        props.reset();
        setAnchorEl(null);
    };

    return (
        <Popover
            elevation={1}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            classes={{ paper: classes.event }}
        >
            <Box sx={{ cursor: 'pointer', padding: '8px' }}>
                <Grid container alignItems="center" spacing={0}>
                    <Grid item xs={2} sx={{ p: 0 }}>
                        {props.event.event._def.extendedProps.type === "task" &&
                            <AssignmentTurnedInOutlinedIcon fontSize="large" sx={{ padding: '6px', border: `1px solid ${variables.borderColor}`, borderRadius: '50%' }} />}
                        {props.event.event._def.extendedProps.type === "event" &&
                            <EventIcon fontSize="large" sx={{ padding: '6px', border: `1px solid ${variables.borderColor}`, borderRadius: '50%' }} />}
                        {props.event.event._def.extendedProps.type === "call" &&
                            <PhoneOutlinedIcon fontSize="large" sx={{ padding: '6px', border: `1px solid ${variables.borderColor}`, borderRadius: '50%' }} />}
                    </Grid>
                    <Grid item xs={8} sx={{ pl: 1 }}>
                        <Grid container direction="row">
                            <Grid item xs={12}>
                                <Typography component="a" sx={{ color: "#212129", fontSize: "13.5px", "&:hover": { color: `${variables.linkColor}` } }}>
                                    {props.event.event._def.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography component="body1" sx={{ fontSize: "12px" }}>
                                    {moment(props.event?.event?._instance?.range?.start).format("MMM DD, YYYY")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <EventMoreActionPopover />
                    </Grid>
                </Grid>
            </Box>
        </Popover >
    )
}
export default EventInfo;