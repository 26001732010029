import * as React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Button, ButtonGroup, Grid, IconButton, List, ListItemButton, MenuItem, Select, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import variables from '../../../assets/styles/_colors.scss';
import { capitalizeFirstLetter, stringAvatar } from '../../../utils/PipelineConstants';
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { withStyles } from "@mui/styles";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import DeleteDeal from '../delete/DeleteDeal';
import UpsertDeal from '../create/UpsertDeal';
import { styled } from '@mui/system';
import PipelineStepper from './PipelineStepper';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PublicIcon from '@mui/icons-material/Public';
import CallIcon from '@mui/icons-material/Call';
import Tags from '../../../components/Tags';
import PipelineTimeline from './PipelineTimeline';
import { Notes } from '../../../components/notes/Notes';
import FileUpload from '../../../components/files/FilesUpload';
import Emails from './Emails';
import StageHistory from './StageHistory';
import ActivitesTab from './ActivitiesPipelineDetailedView';
import AddProductsToDeal from '../create/AddProductsToDeal';
import { renderComponents } from '../../../components/RenderComponents';
import { FormProvider, useForm } from 'react-hook-form';
import { editFieldsData } from '../../../utils/createpipelineformdata';

const StyledIcon = withStyles({
    root: {
        backgroundColor: `${variables.listBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.listBackgroundColor} !important`
        }
    }
})(IconButton);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 0,
    flexGrow: 1,
    minHeight: '100vh',
    minWidth: '100vw',
    height: '100vh',
    width: '100vw'
};

const useStyles = makeStyles((theme) => ({
    listItemSelected: {
        backgroundColor: `${variables.listBackgroundColor} !important`,
    },
}));

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important',
        fontWeight: 'bold !important',
    }
})(Tab);

const StyledTableSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        marginLeft: "12px !important",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

const StyledLink = styled(Link)`
     color: #212129;
`;

const otherInfo = [{
    label: "Contact Number",
    value: "123456789"
},
{
    label: "Custom Field 1",
    value: "Holaaaaa"
}];

export default function DealDetailedView(props) {

    const locationData = useLocation();
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editDeal, setEditDeal] = useState(false);
    const [deleteDeal, setDeleteDeal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedTab, setSelectedTab] = useState("0");

    const notesFilter = [
        {
            label: "All Notes",
            value: "all"
        },
        {
            label: "This Pipeline's Notes",
            value: "pipeline"
        }
    ];

    const timelineOptions = [
        {
            label: "All",
            value: "all"
        },
        {
            label: "Calls",
            value: "calls"
        },
        {
            label: "Notes",
            value: "notes"
        },
        {
            label: "Tasks",
            value: "tasks"
        },
        {
            label: "Events",
            value: "events"
        },
        {
            label: "Files",
            value: "files"
        }
    ]
    useEffect(() => {
        if (locationData?.state) {
            const item = locationData?.state?.item;

            setSelectedItem(item);
            setSelectedItemId(item.id);
        }
    }, [locationData?.state])

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
    }

    const renderFieldss = (field, prefix, isTableData) => {
        return renderComponents(field, prefix, isTableData);
    }

    const methods = useForm(
        {
            defaultValues: editFieldsData,
            shouldUnregister: false,
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, trigger } = methods;

    const onSubmit = async (data) => {
        const isStepValid = await trigger("products");
        if (isStepValid) {
            // alert(JSON.stringify(data.dealProducts));
            // handleDrawerClose();
        }
    }

    return (
        <div>
            <Box sx={style}>
                <Grid container direction="row" sx={{ mt: 6, ml: 7.25, width: 'auto' }}>
                    <Grid item xs={12} sx={{ backgroundColor: `${variables.lightGreyBackgroundColor}` }}>
                        <Grid container direction="row" sx={{ p: 2, pb: 0 }}>
                            <Grid item xs={0.25}>
                                <IconButton>
                                    <StyledLink to={-1}><KeyboardBackspaceIcon /></StyledLink>
                                </IconButton>
                            </Grid>
                            <Grid item xs={5} sx={{ pl: 2 }}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="h6" sx={{ color: `${variables.linkColor}`, fontWeight: 600, fontSize: "15px" }}>
                                            Test deal 1
                                        </Typography>
                                        <Typography sx={{ pt: 0.5 }}>
                                            <PersonIcon size="small" sx={{ width: 16, height: 16 }} />
                                            <Typography component="span" sx={{ fontSize: "13px", pl: 1 }}>
                                                User 1
                                            </Typography>
                                        </Typography>
                                    </Grid>
                                    <Grid item sx={{ pt: 1, pb: 1 }}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography sx={{ color: `${variables.labelTextColor}` }}>Team Pipeline: </Typography>
                                            <Typography component="span" className={classes.boldText}> Pipeline 1 </Typography>
                                            <StyledTableSeparator />
                                            <Typography sx={{ color: `${variables.labelTextColor}` }}>Closing Date: </Typography>
                                            <Typography component="span" className={classes.boldText}> -- </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6.75} sx={{}}>
                                <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                                    <Button variant="contained"> Send Mail</Button>
                                    <StyledIcon onClick={() => setEditDeal(true)}>
                                        <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                                    </StyledIcon>
                                    <StyledIcon onClick={() => setDeleteDeal(true)}>
                                        <DeleteOutlineIcon fontSize='medium' color="error" />
                                    </StyledIcon>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ p: 2, pl: 4, borderBottom: `1px solid ${variables.borderColor}` }}>
                            <PipelineStepper />
                        </Grid>
                    </Grid>
                    <Grid container direction="row" sx={{ p: 2, pt: 0, pr: 0 }}>
                        <Grid item xs={3}>
                            <Box sx={{ p: 2, bgcolor: "#fff", height: '65vh', overflowX: "hidden", overflowY: "auto" }}>
                                <Typography sx={{ fontWeight: 'bold', pb: 1.5 }}>Related Contact</Typography>
                                <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
                                    <Stack direction="column">
                                        <Avatar {...stringAvatar(`Ted Watson`)} />
                                    </Stack>
                                    <Stack direction="column">
                                        <Stack direction="row" sx={{ fontWeight: 'bold', "&:hover": { color: `${variables.linkColor}` } }}>
                                            {"Ted Watson"}
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" sx={{ pt: 2, pl: 1 }}>
                                    <MailOutlineIcon />
                                    <Typography sx={{ pl: 2 }}>support@iaaxin.com</Typography>
                                </Stack>
                                <Stack direction="row" sx={{ pt: 1, pl: 1 }}>
                                    <PhoneAndroidIcon />
                                    <Typography sx={{ pl: 2 }}>609-884-0686</Typography>
                                </Stack>
                                <Typography sx={{ fontWeight: 'bold', pb: 1.5, pt: 2 }}>Related Company</Typography>
                                <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
                                    <Stack direction="column">
                                        <Avatar {...stringAvatar(`Zylker Corp`)} />
                                    </Stack>
                                    <Stack direction="column">
                                        <Stack direction="row" sx={{ fontWeight: 'bold', "&:hover": { color: `${variables.linkColor}` } }}>
                                            {"Zylker Corp"}
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack direction="row" sx={{ pt: 2, pl: 1 }}>
                                    <PublicIcon />
                                    <Typography sx={{ pl: 2 }}>https://appv1.nowdigitaleasy.com/</Typography>
                                </Stack>
                                <Stack direction="row" sx={{ pt: 1, pl: 1 }}>
                                    <CallIcon />
                                    <Typography sx={{ pl: 2 }}>609-884-0686</Typography>
                                </Stack>
                                <Tags />
                                <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 2 }}>Other Info</Typography>
                                {otherInfo.map((field, index) => {
                                    return <Stack direction="row" sx={{ pb: 1 }} key={index}>
                                        <Typography width="200px">{field.label}</Typography>
                                        <Typography width="60%">{field.value ? field.value : '--'}</Typography>
                                    </Stack>
                                })}
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Tabs value={selectedTab} onChange={handleTabChange}>
                                <StyledTab label="Timeline" value="0" />
                                <StyledTab label="Notes" value="1" />
                                <StyledTab label="Activities" value="2" />
                                <StyledTab label="Emails" value="3" />
                                <StyledTab label="Stage History" value="4" />
                                <StyledTab label="Files" value="5" />
                                <StyledTab label="Products" value="6" />
                            </Tabs>

                            {/* <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "64vh", pb: 2 }}> */}
                            <Box sx={{ overflow: 'hidden', p: 1, bgcolor: `${variables.tabsBackgroundColor}` }}>
                                {
                                    selectedTab === "0" &&
                                    <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "64vh", pb: 2, bgcolor: "#fff" }}>
                                        <Stack direction="row" width="100%" alignItems="center" sx={{ pl: 2, pr: 1, pt: 1 }}>
                                            <Stack direction="row" justifyContent="left" width="50%">
                                                <Typography sx={{ fontWeight: "bold" }}>History</Typography>
                                            </Stack>
                                            <Stack direction="row" justifyContent="right" width="50%" alignItems="center">
                                                <Typography sx={{ pr: 2 }}>Filter: </Typography>
                                                <Select
                                                    defaultValue={timelineOptions[0]?.value}
                                                    size="small"
                                                >
                                                    {timelineOptions?.map(option => {
                                                        return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                    })}
                                                </Select>
                                            </Stack>
                                        </Stack>
                                        <PipelineTimeline />
                                    </Box>
                                }
                                {
                                    selectedTab === "1" &&
                                    <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "64vh", pb: 2, bgcolor: "#fff" }}>
                                        <Select
                                            defaultValue={notesFilter[0]?.value}
                                            sx={
                                                [{ mt: 1, fontWeight: 'bold' }, {
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        border: 'none'
                                                    }
                                                }]
                                            }
                                            size="small"
                                        >
                                            {notesFilter?.map(option => {
                                                return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            })}
                                        </Select>
                                        <Notes />
                                    </Box>
                                }

                                {selectedTab === "2" &&
                                    <Box sx={{ overflow: "hidden", height: "64vh", pb: 2, bgcolor: "#fff" }}>
                                        <ActivitesTab view="pipeline" />
                                    </Box>
                                }

                                {selectedTab === "3" &&
                                    <Box sx={{ overflow: "hidden", height: "64vh", pb: 2, bgcolor: "#fff" }}>
                                        <Emails />
                                    </Box>
                                }

                                {selectedTab === "4" &&
                                    <Box sx={{ overflow: "hidden", height: "64vh", pb: 2, bgcolor: "#fff" }}>
                                        <StageHistory />
                                    </Box>
                                }

                                {selectedTab === "5" &&
                                    <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "64vh", pb: 2, bgcolor: "#fff" }}>
                                        <FileUpload />
                                    </Box>
                                }

                                {
                                    selectedTab === "6" &&
                                    <Box sx={{ overflowX: "auto", height: "64vh", pl: 2, pt: 2, bgcolor: "#fff" }}>
                                        <FormProvider {...methods}>
                                            <form onSubmit={handleSubmit(onSubmit)} id="productsForm" style={{ height: "100%" }}>
                                                <AddProductsToDeal hideTitle={true} renderFields={(field, prefix) => renderFieldss(field, prefix, true)} />
                                            </form>
                                        </FormProvider>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {deleteDeal &&
                <DeleteDeal item={props.item} module={props.module} handleClose={() => { setDeleteDeal(false) }} />}

            {editDeal &&
                <UpsertDeal edit={true} module={props.module} handleDrawerClose={() => { setEditDeal(false) }} />}
        </div >
    );
}
