import * as React from 'react';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { Divider, IconButton, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import variables from "../../assets/styles/_colors.scss";
import { AddCircleOutline } from "@material-ui/icons";
import UpsertTask from './create/UpsertTask';

const useStyles = makeStyles((theme) => ({
    activities: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

const activities = [
    {
        name: "Create a Task",
        key: 'task'
    },
    {
        name: "Create an Event",
        key: "event"
    },
    {
        name: "Log a call",
        key: "call"
    }
]

export default function ActivitiesPopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [createTask, setCreateTask] = useState(false);
    const [createEvent, setCreateEvent] = useState(false);
    const [createCallLog, setCreateCallLog] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "task":
                    setCreateTask(true);
                    return true;
                case "event":
                    setCreateEvent(true);
                    return true;
                case "call":
                    setCreateCallLog(true);
                    return true;
                default:
                    return null;
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'activities-popover' : undefined;

    return (
        <React.Fragment>
            <IconButton aria-label="activities-popover" variant="outlined" onClick={handleClick} sx={{ p: 0, ml: 1 }}>
                <AddCircleOutline fontSize={props.size ? props.size : 'small'} color={"primary"} />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{ paper: classes.pipelineBulkActions }}
            >
                {activities.map((item, index) => (
                    <div key={`activities-item-${index}`}>
                        <ListItemButton
                            key={`${item.name}-${index}`}
                            sx={{ p: "8px 12px" }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </div>
                ))}
            </Popover>
            {createTask && <UpsertTask module={"task"} handleDrawerClose={() => { setCreateTask(false); props.resetHover(); }} />}
            {createEvent && <UpsertTask module={"event"} handleDrawerClose={() => { setCreateEvent(false); props.resetHover(); }} />}
            {createCallLog && <UpsertTask module={"call"} handleDrawerClose={() => { setCreateCallLog(false); props.resetHover(); }} />}
        </React.Fragment>
    );
}