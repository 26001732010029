// LOGOUT
export const logout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("clientid");
  localStorage.removeItem("userid");
  localStorage.removeItem('recoil-persist');
  window.location.reload('login');
};

// LOGIN STATUS
export const isLogin = () => {
  let login;
  const queryParams = new URLSearchParams(window.location.search);
  var login1 = queryParams.get("login");
 

  if (localStorage.getItem("auth")) {
    login = true;
  } else if (login1 === "true") {
    login = true;
  } else {
    login = false;
  }

  return login;
};
