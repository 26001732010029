import React  from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";


const summary = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "auto",
};

const topPart = {
  display: "flex",
  flexDirection: "column",
  borderBottom: "1px solid #00000036",
  padding: "10px 0px",
  margin: "10px",
};

const Typo = {
  fontSize: "12px",
  padding: "2px",
};

const Typo1 = {
  fontSize: "12px",
  padding: "0 0 10px 20px",
  width : "90px"
 // marginLeft: "10px",
  //borderLeft: "3px solid blue",
};


const span = {
  display: "inline-block",
  width: "90px",
};

const span1={
    width:"2px",
    height:"40px",
    backgroundColor:"blue",
    marginLeft:"10px",
    borderRadius : "5px"
}

const span2={
    width:"3px",
    height:"40px",
    backgroundColor:"pink",
    marginLeft:"10px",
    borderRadius : "5px"
}

const span3={
    width:"2px",
    height:"40px",
    backgroundColor:"red",
    marginLeft:"10px",
    borderRadius : "5px"
}

const span4={
    width:"3px",
    height:"40px",
    backgroundColor:"grey",
    marginLeft:"10px",
    borderRadius : "5px"
}


export default function Summary(props) {

  return (
    <div className="summary" style={summary}>
      <div className="topPart" style={topPart}>
        <Typography style={Typo}>
          <span style={span}>Duty Start</span> :  {props.summaryDetails.summary.duty_start}
        </Typography>
        <Typography style={Typo}>
          <span style={span}>Duty End </span> : <span style={span}>{props.summaryDetails.summary.duty_end}</span>
        </Typography>
        <Typography style={Typo}>
          <span style={span}>Working Time </span> :  <span style={span}>{props.summaryDetails.summary.workingTime}</span>
        </Typography>
        <Typography style={Typo}>
          <span style={span}>Alert </span> :  --
        </Typography>
        <Typography style={Typo}>
          <span style={span}>Expense </span> :  <span style={span}>{props.summaryDetails.summary.expence_amount}</span>
        </Typography>
      </div>
      <div className="bottomPart">
        <Grid container spacing={1}>
          <Grid item xs={6} display={"flex"} flexDirection={"row"} justifyContent={"start"} alignItems={"start"}>
            <span style={span1}></span>
            <Typography style={Typo1}>
              Travel
              <br />
              Distance
            </Typography>
            <span style={{color : "blue" , fontSize : "12px" , fontWeight : "700"}}>{props.summaryDetails.summary.travel_distance}km</span>
          </Grid>
          <Grid item xs={6} display={"flex"} flexDirection={"row"} justifyContent={"start"} alignItems={"start"}>
          <span style={span2}></span>
            <Typography style={Typo1}>
              Travel
              <br />
              Duration
            </Typography>
            <span style={{color : "pink" , fontSize : "12px" , fontWeight : "700" }}>{props.summaryDetails.summary.travel_duration} Hrs </span>
          </Grid>
    
        </Grid>
      </div>
      <br />
    </div>
  );
}
