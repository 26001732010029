import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { api_url1 } from "../../../globalSettings.js";
import { useRecoilState } from "recoil";
import BounceLoader from "../Component/BounceLoader.js";
import { selecteddomainn } from "../../../webservicesatoms/webserviceatom.js";
import CloseIcon from "../../../images/DomainDetails/closeIcon.png";
import DomainIcon from "../../../images/DomainDetails/Website.png";

import "../Style/domaindetails.css";
import "../../../styles/weservices.css";

import CustomSnackbar from "../Component/CustomSnackbar.js";
import NoData from "../Component/NoData.js";

import HostingIcon from "../../../images/DomainDetails/Upload to Cloud.png";
import GsuiteIcon from "../../../images/DomainDetails/GSuite.png";

import { DomainFilter } from "../CustomHook/DomainFilter.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import ManageHosting from "../Hosting/index.js";
import ManageGsuit from "../Gsuit/index.js";
import ManageDomain from "../Domain/index.js";
function DomainDetails() {
  const [Selecteddomain, setSelecteddomain] = useRecoilState(selecteddomainn);
  const navigate = useNavigate();

  // ============================= ChildNameServer =====================================

  //=====================================================================================================================================//

  /*================================= GET DOMAIN DETAILS AND KEY ==================================================*/
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleSnackbarClose = (event, reason) => {
    setSnackbarInfo((prevInfo) => ({
      ...prevInfo,
      open: false,
    }));
  };

  // ================================= GET DOMAIN DETAILS ===================================

  const fetchFilterData = () => {
    return axios
      .get(`${api_url1}/resellers/webservices/${Selecteddomain?.domainName}`)
      .then((result) => {
        return result.data;
      });
  };

  const { data: filterdDomainData } = useQuery({
    queryKey: ["domainFilter", Selecteddomain?.domainName],
    queryFn: fetchFilterData,
    select: (data) => data,
  });

  const [filterDomain = {}] = DomainFilter(
    filterdDomainData ?? [],
    "domain",
    "product"
  );
  const filterGsuite = ([] = DomainFilter(
    filterdDomainData ?? [],
    "gsuite",
    "product"
  ));
  const [filterPlesk = {}] = DomainFilter(
    filterdDomainData ?? [],
    "plesk",
    "product"
  );
  function handleGsuiteClick() {
    window.open("https://www.nowdigitaleasy.com/google-workspace", "_blank");
  }
  function handleHostingClick() {
    window.open("https://www.nowdigitaleasy.com/hosting", "_blank");
  }
  const { uniqueId } = useParams();

  return (
    <>
      {false ? (
        <BounceLoader />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#edeff685",
              flex: "2 2 70%",
              height: "calc(100vh - 48px)",
              overflow: "auto",
            }}
          >
            <div className="domain-details" style={{ width: "98%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <div style={{ marginBottom: "5px" }}>
                  <img
                    src={CloseIcon}
                    alt="closeIcon"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/webservices");
                    }}
                  />
                </div>
              </div>
              {Selecteddomain?.domainEnabled ? (
                <ManageDomain filterDomain={filterDomain} />
              ) : (
                <NoData
                  imgSrc={DomainIcon}
                  handleClick={() => {
                    alert("tranfer");
                  }}
                  tilte={"Domain Registration"}
                  line1={
                    "Want to transfer your domain to Nowdigitaleasy. We ll help you through the "
                  }
                  line2={
                    "process and automatically configure your domain - just click Transfer to begin!"
                  }
                />
              )}
              {Selecteddomain?.pleskEnable ? (
                <ManageHosting
                  productId={filterPlesk.productId}
                  domainName={filterPlesk.domainName}
                  setSnackbarInfo={setSnackbarInfo}
                  id={filterPlesk._id}
                  Selecteddomain={Selecteddomain}
                />
              ) : (
                <NoData
                  imgSrc={HostingIcon}
                  handleClick={handleHostingClick}
                  line1={"Speed, Scalability and Redundancy - get the "}
                  line2={"power of the cloud  for your website"}
                  tilte={"Hosting"}
                />
              )}
              {Selecteddomain?.guiteEnabled ? (
                filterGsuite.map((data) => (
                  <ManageGsuit
                    key={data}
                    setSnackbarInfo={setSnackbarInfo}
                    filterGsuite={data}
                    Selecteddomain={Selecteddomain}
                  />
                ))
              ) : (
                <NoData
                  imgSrc={GsuiteIcon}
                  handleClick={handleGsuiteClick}
                  line1={
                    "Professional email at your domain name, online storage,"
                  }
                  line2={"shared calendars, video meetings and more."}
                  title={"Google Workspace"}
                />
              )}
            </div>

            <CustomSnackbar
              snackbarInfo={snackbarInfo}
              handleSnackbarClose={handleSnackbarClose}
            />
          </div>
        </>
      )}
    </>
  );
}

export default DomainDetails;
