import React from "react";
import "../../styles/myAccount.css";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@mui/styles";
import { Avatar, Divider, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { lengthCheck } from "../../utils";
import ManageBranch from "./ManageBranch/ManageBranch";
import { setIsToShowSelectedCompany } from "../../redux/actions/manage-company-action-creators";
import DepartmentTable from "./Department/DepartmentTable";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
export default function SelectedCompany() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { companyInfo } = useSelector((state) => state.manageCompany);

  const closeBtnOnClick = () => {
    dispatch(setIsToShowSelectedCompany(false));
  };

  return (
    <div id="myAccount">
      <div className="my-account-left">
        <Paper elevation="3" style={{ height: "calc(100vh - 85px)" }}>
          <div className="my-account-left-photo-wrapper">
            <div className="my-account-close-btn" onClick={closeBtnOnClick}>
              <CloseIcon />
            </div>
            <div className="my-account-user-photo">
              <div>
                <Avatar className={classes.large} alt={companyInfo.company_name} />
              </div>
            </div>
            <div className="my-account-user-name">
              <h6 style={{ marginBottom: 0 }}>
                {companyInfo.company_name} &nbsp; {companyInfo.company_id}
              </h6>
            </div>
            <div className="my-account-user-email">
              <span>{companyInfo.email}</span>
            </div>
          </div>
          <Divider />
          <div className="my-account-left-info-wrapper" style={{ height: "calc(100vh - 240px)", overflow: "auto" }}>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">COMPANY NAME</div>
              <div className="my-account-left-info-value">{companyInfo.company_name}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">CONTACT PERSON</div>
              <div className="my-account-left-info-value">{lengthCheck(companyInfo.contact_person)}</div>
            </div>

            <div className="my-account-left-info">
              <div className="my-account-left-info-key">PHONE NUMBER</div>
              <div className="my-account-left-info-value">{lengthCheck(companyInfo.phone_number)}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">EMAIL</div>
              <div className="my-account-left-info-value">{companyInfo.email}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">GST NO</div>
              <div className="my-account-left-info-value">{lengthCheck(companyInfo.gst_no)}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">CITY</div>
              <div className="my-account-left-info-value">{lengthCheck(companyInfo.city)}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">STATE</div>
              <div className="my-account-left-info-value">{lengthCheck(companyInfo.state)}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">COUNTRY</div>
              <div className="my-account-left-info-value">{lengthCheck(companyInfo.country)}</div>
            </div>
            <div className="my-account-left-info">
              <div className="my-account-left-info-key">ZIPCODE</div>
              <div className="my-account-left-info-value">{lengthCheck(companyInfo.zip_code)}</div>
            </div>
          </div>
        </Paper>
      </div>
      <div className="my-account-right">
        <div style={{ flexBasis: 300, margin: "5px 10px" }}>
          <ManageBranch />
        </div>
        <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          <DepartmentTable />
        </div>
        {/* <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          <Designation />
        </div>
        <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          <TaskCategory />
        </div>
        <div style={{ flexBasis: 200, margin: "5px 10px" }}>
          <ExpenseCategory />
        </div> */}
      </div>
    </div>
  );
}
