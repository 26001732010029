import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { snackbarr } from "../trackey/trackeyatoms";
import { styled } from "@mui/system";
import {
  login_atoms,
  login_token,
  login_companyid,
  selectedEmp,
} from "../../Atoms/LoginAtoms";
import { api_url } from "../../globalSettings";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TrackeyMap from "../trackey/TrackeyMap";
import Autocomplete from "@mui/material/Autocomplete";

const useStyles = makeStyles({
  root: {
    padding: "5px",
    borderRadius: "5px",
    border: "1px solid #c4c4c4",
    color: "#666666",
  },
});

const CssTextField = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#009DFE",
  },

  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#009DFE",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#009DFE",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009DFE",
    },
  },
});

function TrackeyEmployeeAddtask(props) {

  const { trackeyEmployeeList } = props;


  const task = {
    category: "",
    name: "",
    place: "",
    discription: "",
    taskstatus: "",
    date: "",
    time: "",
  };

  const [Taskdetails, setTaskdetails] = useState(task);

  const [Taskdetailserr, setTaskdetailserr] = useState({});

  const [snackbar, setSnackbar] = useRecoilState(snackbarr);

  const classes = useStyles();

  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [SelectedEmp, setSelectedEmp] = useRecoilState(selectedEmp);
  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);


 

  // =============================== priority ==================

  const [priority, setPriority] = useState([]);
  const handleChange1 = (event) => {
    setPriority(event.target.value);
   
  };

 
  const [companyform, setCompanyForm] = useState([]);



  // ========================================= get address list  ============================
  const [getaddressList, setGetaddresslist] = useState([]);

  function getaddresslist() {
  
   
    const params = {
   
        token: loginToken1,
        branch_id: SelectedEmp.employee_id.branch_id,
    };


   
    axios
      .get(`${api_url}/get_address_list_by_branch`, { params: { ...params } })
      .then((responce) => {
       
      
        setGetaddresslist(
          responce.data.data.map((item, key) => ({
            label: item.address,
            id: item._id,
            lat: item.latitude,
            lng: item.longitude,
            locname: item.location_name,
          }))
        );
      })
      
      .catch(function (error) {
        if (error.response) {
       
 
        } else if (error.request) {
      
          
        } else {
  
         
        } 
       
      });
  }

  

  // ========================================= get address list  ============================
  const [getTaskCategory, setGetTaskCategory] = useState([]);
  function getTaskCategorylist() {
  
    const params = {
     
      token: loginToken1,
      branch_id: SelectedEmp.employee_id.branch_id,
    };

    axios
      .get(`${api_url}/getTaskList`, { params: { ...params } })
      .then((responce) => {
        
        setGetTaskCategory(
          responce.data.data.map((item, key) => ({
            label: item.task_category_name,
            id: item._id,
          }))
        );
      })
      .catch(function (error) {
        if (error.response) {
         
    
        } else if (error.request) {
        
        } else {
         
      
        }
     
      });
  }

  useEffect(() => {
    if (Object.keys(SelectedEmp).length >= 1) {
      getaddresslist();
      getTaskCategorylist();
    }
  }, [SelectedEmp]);

 
  const [taskCategory, setTaskCategory] = useState();

  const taskCategoryList = (event, newValue) => {
    if (newValue != null) {
      setTaskCategory(newValue.label);

   
    }
   
  };

  //============================================== Changing Form Values =============================================

  const formonchange = (e) => {
    const { value, name } = e.target;
    setCompanyForm({ ...companyform, [name]: value });
  };

  // ===================================  addtask  api ================================

  function addTask() {

    const params = JSON.stringify({
      token: loginToken1,
      user_id: loginAtom1,
      company_id: SelectedEmp.company_id._id,
      branch_id: SelectedEmp.employee_id.branch_id,
      employee_id: SelectedEmp.employee_id._id,
      department_id: SelectedEmp.employee_id.department_id,
      task_date: validFrom.split("-").reverse().join("-"),
      task_time: earlyStartTime,
      task_category: taskCategory,
      task_name: companyform.task_name,
      task_description: companyform.task_description,
      task_priority: priority,
      address: address,
      task_latitude: addressLat,
      task_longitude: addressLng,
      task_location_name: locationName,
   
    });


    axios
      .post(`${api_url}/task_schedule_by_branch`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
   
        props.handleClose();
       
       
      })

      .catch(function (error) {
        if (error.response) {
          
       
        } else if (error.request) {
         
    ;
        } else {
        
        
        }
     
      });

  }

  //  =============== set address and location ===============
  const [addressLat, setAddressLat] = useState("");
  const [addressLng, setAddressLng] = useState("");
  const [address, setAddress] = useState("");
  const [locationName, setLocationName] = useState("");
 
  const departmentlist = (event, newValue) => {
    if (newValue != null) {
      setAddressLng(newValue.lng);
      setAddress(newValue.label);
      setLocationName(newValue.locname);
      setAddressLat(newValue.lat);
    
    }

  };

  // ==============  TIME AND DATE ===========

  const [validFrom, setValidFrom] = React.useState(null);


  function valid_from(event) {

    setValidFrom(event.target.value);
  }


  const [earlyStartTime, setEarlyStartTime] = React.useState(
    dayjs("2018-01-01T00:00:00.000Z")
  );

  function earlyStart(event) {
  
    setEarlyStartTime(event.target.value);
  }



  const [branches, setBranches] = useState([]);

  const initialBranches = [
    { id: 1, name: 'Branch 1' },
    { id: 2, name: 'Branch 2' },
    { id: 3, name: 'Branch 3' },
 
  ];

  useState(() => {
    setBranches(initialBranches);
  }, []);

  return (
    <div>
      <Dialog open={props.opendialog} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ backgroundColor: "blue", color: "white" }}>
          Add Task
        </DialogTitle>
        <DialogContent>
          <Stack padding={2} spacing={2}>
            <Grid container spacing={4}>
              <Grid item xs={4} alignItems="center" justifyContent=" center">
                <TextField
                  size="small"
                  label="Task Name"
                  variant="standard"
                  name="task_name"
                  error={Taskdetailserr.name}
                  value={companyform.task_name}
                  onChange={formonchange}
                  required
                />
              </Grid>
              <Grid item xs={4} alignItems="center" justifyContent=" center">
                <Autocomplete
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "'Montserrat', sans-serif",
                    width: "100%",
                    height: "50px",
                  }}
                  id="outlined-multiline-static"
                  size="small"
                  fullWidth
                  options={getTaskCategory}
                  onChange={taskCategoryList}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      // helperText="select your branch"
                      label="Task Category**"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} alignItems="center" justifyContent=" center">
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={priority}
                  onChange={handleChange1}
                  label="Age"
                  variant="standard"
                  fullWidth
                  style={{
                    marginTop: "12px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <MenuItem style={{ padding: 5, width: "100%" }} value="">
                    select priority
                  </MenuItem>
                  <MenuItem
                    style={{ padding: 5, width: "100%" }}
                    value="high"
                    // name={}
                  >
                    high
                  </MenuItem>
                  <MenuItem
                    style={{ padding: 5, width: "100%" }}
                    value="medium"
                  >
                    medium
                  </MenuItem>
                  <MenuItem style={{ padding: 5, width: "100%" }} value="low">
                    low
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  style={{
                    textTransform: "capitalize",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "'Montserrat', sans-serif",
                    width: "100%",
                    height: "50px",
                  }}
                  id="outlined-multiline-static"
                  size="small"
                  fullWidth
                  options={getaddressList}
                  onChange={departmentlist}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      // helperText="select your branch"
                      label="Select Location Name*"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <CssTextField
                  style={{
                    height: "40px",
                    textTransform: "capitalize",
                    backgroundColor: "#ecf4f8",
                  }}
                  id="date"
                  type="date"
                  value={validFrom}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={valid_from}
                  size="small"
                  fullWidth
                  label="Date*"
                  variant="standard"
                />
              </Grid>

              <Grid item xs={4}>
                <CssTextField
                  style={{
                    height: "40px",
                    textTransform: "capitalize",
                  }}
                  id="time"
                  type="time"
                  value={earlyStartTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={earlyStart}
                  size="small"
                  fullWidth
                  variant="standard"
                  label="Early Start time*"
                />
              </Grid>

              <Grid item xs={12} alignItems="center" justifyContent=" center">
                <TextField
                  size="small"
                  label="Task Description"
                  name="task_description"
                  value={companyform.task_description}
                  variant="standard"
                  error={Taskdetailserr.place}
                  onChange={formonchange}
                  required
                  fullWidth
                />
              </Grid>
              
            </Grid>
            {/* <Stack direction="row" spacing={2}>
              <input
                type="date"
                className={classes.root}
                onChange={(e) => changeempdetails("date", e)}
              />
              <input
                type="time"
                className={classes.root}
                onChange={(e) => changeempdetails("time", e)}
              />
            </Stack> */}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={props.handleClose}
            className="tablebtncancel"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button onClick={addTask} className="tablebutton" variant="contained">
            Add Task
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  
  );
}

export default TrackeyEmployeeAddtask;
