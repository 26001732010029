import { Box, Divider, InputAdornment, ListItemButton, ListItemIcon, ListItemText, Popover, Tab, Tabs, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react"
import SearchIcon from "@material-ui/icons/Search";
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import variables from "../assets/styles/_colors.scss";
import { useEffect } from "react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getViews } from "../webservices/PipelineService";
// import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles(() => ({
    pipelineView: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important",
        width: "300px"
    }
}));

export const CrmViews = (props) => {

    const [unfilteredViews, setUnfilteredViews] = useState(null);
    const [selectedView, setSelectedView] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const classes = useStyles();
    // const [isFavoritesTab, setIsFavoritesTab] = useState(false);
    const [views, setViews] = useState(null);

    let inputHandler = (e) => {
        var input = e.target.value.toLowerCase();
        let filteredData = {};
        Object.keys(views)?.forEach((viewCategory) => {
            const filtered = views[viewCategory].views.filter(
                (view) => {
                    view.hide = view?.label?.toLowerCase().includes(input) ? false : true;
                    return view;
                }
            );
            filteredData[viewCategory] = {
                label: views[viewCategory].label,
                views: filtered
            }
        })
        setViews(filteredData);
    };

    useEffect(() => {
        getViews(props.module).then((response) => {
            setUnfilteredViews(response);
            setViews(response);
            setSelectedView(response["public"].views[0]);
        });

    }, [props.module]);

    const open = Boolean(anchorEl);
    const id = open ? 'pipeline-view-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleTabChange = (e, tabIndex) => {
        setCurrentTabIndex(tabIndex);
        if (tabIndex === 1) {
            var obj = {};
            for (const [key, value] of Object.entries(unfilteredViews)) {
                obj[key] = {
                    label: unfilteredViews[key].label,
                    views: value.views.filter(x => x.favorite === true)
                }
            }
            setViews(obj);
        } else {
            setViews(unfilteredViews);
        }
        // setIsFavoritesTab(tabIndex === 1 ? true : false);
    };

    useEffect(() => {

    }, [views]);

    const handleClose = () => {
        setCurrentTabIndex(0);
        // setIsFavoritesTab(false);
        setViews(unfilteredViews);
        setAnchorEl(null);
    };

    const handleItemSelection = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedView(item);
        handleClose();
    }

    const toggleFavorite = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
    }

    return (
        <>
            <Typography onClick={handleClick} sx={{
                p: 1, pl: 1.75, ml: "20px !important", border: open ? `2px solid ${variables.linkColor}` : `1px solid ${variables.borderColor}`, borderRadius: 3, cursor: 'pointer'
            }}>
                {selectedView?.label} <ArrowDropDownIcon />
            </Typography>

            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{ paper: classes.pipelineView }}
            >
                <Tabs variant="fullWidth" value={currentTabIndex} onChange={handleTabChange}>
                    <Tab label='All Views' />
                    <Tab label='Favorites' />
                </Tabs>
                <Box sx={{ height: '25vw', overflowY: 'auto', overflowX: 'hidden' }}>
                    <TextField size="small"
                        placeholder="Search"
                        sx={{ m: 1.5, mb: 0, mr: 0, pr: 3 }}
                        fullWidth
                        onChange={inputHandler}
                        InputProps={
                            {
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon size="medium" />
                                    </InputAdornment>
                                )
                            }} />

                    {views && Object.keys(views).map((key, index) => {
                        return views[key]?.views?.length > 0 &&
                            <React.Fragment key={index}>
                                <Typography component="div" sx={{ fontWeight: 'bold', pl: 2, mt: 1.5 }}>{views[key].label}</Typography>
                                {views[key]?.views?.map((view, index) => {

                                    return !view.hide && <ViewListItem view={view} key={index} index={index} selectedView={selectedView}
                                        handleItemSelection={handleItemSelection} toggleFavorite={toggleFavorite} />

                                })}
                            </React.Fragment>
                    })}
                </Box>
                {/* <Stack direction="row" alignItems="center" sx={{
                    position: 'absolute', bottom: 0, color: `${variables.linkColor}`, pl: 2,
                    cursor: "pointer", background: `${variables.whiteColor}`, height: '40px', width: '100%',
                    borderTop: `1px solid ${variables.lightBorderColor}`,
                    "&:hover": {
                        bgcolor: `${variables.linkBackgroundColor}`,
                        textDecoration: 'underline'
                    }
                }}>
                    <Typography component="div" ><AddIcon fontSize="small" sx={{ mr: 0.5, mb: 0.25 }} />Create View</Typography>
                </Stack> */}
            </Popover>
        </>
    )
}

const ViewListItem = (props) => {

    const [isHovering, setIsHovering] = useState(false);

    return (
        <div key={`pipeline-view-item-${props.index}`} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            <ListItemButton sx={{ pt: 1, pb: 1, pl: 1.5, pr: 2 }} onClick={(e) => props.handleItemSelection(e, props.view)} selected={props.view?.id === props.selectedView?.id}>
                <ListItemIcon sx={{ minWidth: 32 }} onClick={(e) => props.toggleFavorite(e, props.view)}>
                    <StarRoundedIcon sx={{
                        color: props.view?.favorite ? `${variables.favoritesColor}` : `${variables.borderColor}`,
                        visibility: props.view?.favorite ? 'inherit' : isHovering ? 'inherit' : 'hidden'
                    }} />
                </ListItemIcon>
                <ListItemText sx={{ ml: -0.75 }}
                    primary={props.view.label}
                />
            </ListItemButton>
            <Divider sx={{ borderBottomWidth: 3 }} />
        </div>
    )
}