import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import Note from "./Note";
import variables from "../../assets/styles/_colors.scss";
import { useEffect } from "react";
import AttachmentIcon from '@mui/icons-material/Attachment';

export const Notes = (props) => {

    const [notes, setNotes] = useState([]);
    const [focusOnNotes, setFocusOnNotes] = useState(false);
    const [note, setNote] = useState("");
    const [editData, setEditData] = useState(null);

    useEffect(() => {

    }, [notes]);

    const handleNoteCancel = () => {
        setNote("");
        setFocusOnNotes(false);
        setEditData(null);
    }

    const handleNoteSave = () => {
        if (editData) {
            const updatedObj = {
                ...editData,
                "note": note,
                "modifiedBy": "Test user",
                "editedTime": moment().calendar()
            }
            const editIndex = notes.findIndex(function (item, i) {
                return item.id === editData.id
            });
            const existingNotes = [...notes];
            existingNotes[editIndex] = updatedObj;
            setNotes(existingNotes);
            setEditData(null);
        } else {
            setNotes(current => [{
                "id": Math.random().toString(),
                "note": note,
                "createdBy": "Test user",
                "createdTime": moment().calendar()
            }, ...current]);
        }
        handleNoteCancel();
    }

    const handleNoteDelete = (index) => {
        const oldNotes = [...notes];
        const removedNote = oldNotes.splice(index, 1);
        setNotes(oldNotes);
    }

    const handleNoteEdit = (item) => {
        setEditData(item);
        setFocusOnNotes(true);
        setNote(item.note);
    }

    return (
        <>
            <Box sx={{ pl: 1, pt: 2, pr: 2, pb: 2}} >
                <TextField
                    fullWidth
                    value={note}
                    placeholder="What's this note about?"
                    onFocus={() => setFocusOnNotes(true)}
                    multiline
                    rows={focusOnNotes ? 4 : 1}
                    variant="outlined"
                    onChange={(event) => {
                        setNote(event.target.value);
                    }}
                />
                {focusOnNotes && <Stack direction="row" spacing={2} sx={{ pt: 2 }} alignItems="center">
                    <Button variant="contained" disabled={note.trim() === ""} onClick={handleNoteSave}>Save</Button>
                    <Button variant="outlined" onClick={handleNoteCancel}>Cancel</Button>
                    <Typography sx={{ cursor: 'pointer' }}>
                        <AttachmentIcon />
                        <Typography component="span" sx={{ "&:hover": { color: `${variables.linkColor}` } }}> Attach File</Typography>
                    </Typography>
                </Stack>}
            </Box>
            <Box sx={{ pb: 2, borderTop: `1px solid ${variables.lightBorderColor}` }}>
                {notes.length > 0 ?
                    <Typography component="div" >
                        {notes.map((n, index) => {
                            return <Note note={n} index={index} handleDelete={(index) => handleNoteDelete(index)} handleEdit={(item) => handleNoteEdit(item, index)} editData={editData} />
                        })}
                    </Typography>
                    :
                    <Stack direction="row" justifyContent="center">
                        <Typography sx={{ pt: 7 }}>This record doesn't have any notes.</Typography>
                    </Stack>
                }
            </Box>
        </>
    )
}
