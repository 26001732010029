import { atom } from "recoil";

const snackbarr = atom({
  key: "snackbarr",
  default: { open: false, seveority: "", message: "" },
});

// TO show on or off duty
const onOffDuty = atom({
  key: "onOffDuty",
  default: "ALL",
});

const empHistory = atom({
  key: "empHistory",
  default: "",
});

export { snackbarr, onOffDuty, empHistory };
