import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack, TextField } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { useState } from 'react';
import RoleTree from './RoleTree';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteRole } from '../../../../webservices/UserControlService';

export default function DeleteRole(props) {

    const [open, setOpen] = React.useState(true);
    const [selectedRole, setSelectedRole] = React.useState("");
    const [roleTree, showRoleTree] = useState(false);

    const queryClient = useQueryClient();

    useEffect(() => {
        setSelectedRole(props.roles.find(a => a.reportsTo === null)?.roleName);
    }, [props.roles]);

    const { mutateAsync } = useMutation({
        mutationKey: "deleteRole",
        mutationFn: (id) => deleteRole(id, { roleId: props.item._id, transferTo: selectedRole }),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllRoles'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = (data) => {
        setOpen(false);
        props.handleClose(data);
    }

    const handleRoleSelect = (item) => {
        item && setSelectedRole(item?.roleName);
        showRoleTree(false);
    }

    const handleDelete = () => {
        mutateAsync(props.item?._id);
    }

    return (
        <Modal open={open}>
            <>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Delete Role
                        <CloseButton handleDialogClose={() => handleClose()} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ pt: 2, pb: 2 }}>
                        Before you delete this role, you need to transfer the associated users & sub-roles to another role
                    </Typography>

                    <Typography component="div" onClick={(e) => { e.preventDefault(); e.stopPropagation(); showRoleTree(true) }} sx={{ cursor: 'pointer' }}>
                        <TextField
                            fullWidth
                            sx={{ input: { cursor: 'pointer' } }}
                            label="Transfer To*"
                            value={selectedRole}
                            InputProps={{
                                readOnly: true,
                            }} />
                    </Typography>

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                        <Button variant="contained" color="error" onClick={() => handleDelete()}>Delete Role</Button>
                    </Stack>
                </Box>

                {roleTree && <RoleTree nodeToDisable={props.item} handleClose={(item) => handleRoleSelect(item)} />}
            </>
        </Modal>
    );
}