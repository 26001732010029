import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ColumnContent from "./PipelineBoardColumnList";
import { ColumnContainer, ColumnHeader, ColumnTitle } from "./PipelineBoardStyles";
import { Box, Button, Checkbox, Collapse, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useState } from "react";
import PipelineBoardPopover from "./PipelineBoardPopover";
import { useEffect } from "react";
import UpsertDeal from "../../create/UpsertDeal";
import { useReducer } from "react";
import variables from "../../../../assets/styles/_colors.scss";
import { withStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import { pipeline_atoms } from "../../../../../../Atoms/CrmAtoms";
import { useRecoilState } from "recoil";

const StyledSeparator = withStyles({
  root: {
    borderRadius: "50%",
    display: "inline-block",
    height: "6px",
    width: "6px",
    backgroundColor: `${variables.borderColor}`
  }
})(Typography);

const Column = (props) => {

  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [checked, setChecked] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [showDeal, setShowDeal] = useState(false);
  const [tasksLength, setTasksLength] = useState(null);
  const [pipelineAtom, setPipelineAtom] = useRecoilState(pipeline_atoms);

  const [selectedItems, setSelectedItems] = useState([]);

  const title = props.title;
  const index = props.index;

  useEffect(() => {
    if (props.resetSelection) {
      handleHeaderCheckboxSelection(false);
      let a = Object.assign({}, pipelineAtom);
      a.resetBulkSelection = false;
      setPipelineAtom(a);
    }
  }, [props.resetSelection]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const getTasksLength = (count) => {
    setTasksLength(count);
  }

  const handleCollapse = (val) => {
    setChecked(val);
  }

  const handleHeaderCheckboxSelection = (val) => {
    const arr = [...Array(tasksLength).keys()];
    if (val) {
      setSelectedItems(arr);
      props.selected(arr);
    } else {
      setSelectedItems([]);
      props.selected([]);
    }
  }

  const handleCheckboxSelection = (index, val) => {
    const arr = selectedItems;
    const i = arr.indexOf(index);
    i > -1 ? arr.splice(i, 1) : arr.push(index);
    setSelectedItems(arr);
    props.selected(arr);
    forceUpdate();
  }

  useEffect(() => {

  }, [props.stage])

  useEffect(() => {

  }, [selectedItems])

  useEffect(() => {

  }, [props.handleCheckboxSelection]);

  return (
    <>
      {!checked &&
        <Box onClick={() => handleCollapse(true)}
          sx={{
            "&:hover": {
              backgroundColor: `${variables.whiteColor}`,
            },
            writingMode: 'vertical-rl', cursor: 'pointer',
            color: `${variables.blackTextColor}`, padding: 1, backgroundColor: "#F9FBFC", ml: 1.25,
            height: "calc(100vh - 185px)"
          }}>
          <Grid container direction="row">
            <Grid item xs={5} sx={{ fontWeight: 600, p: 1 }}>{title}</Grid>
            <Grid item xs={7} sx={{ p: 1 }}>
              <Typography variant="span" sx={{ pb: 1 }}>$0.00</Typography>
              <StyledSeparator />
              <Typography variant="span" sx={{ color: `${variables.greyTextColor}`, pt: 1 }}>3 Tickets</Typography>
            </Grid>
          </Grid>
        </Box>}
      <Collapse orientation="horizontal" in={checked} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
        <Draggable draggableId={title} index={index} isDragDisabled={(props.disableBoard || selectedItems.length > 0) ? true : false}>
          {(provided, snapshot) => (
            <>
              <ColumnContainer ref={provided.innerRef} {...provided.draggableProps} $isDragging={snapshot.isDragging}>
                <ColumnHeader $isDragging={snapshot.isDragging} $isOpen={props.stage?.openStage}>
                  <ColumnTitle
                    $isDragging={snapshot.isDragging}
                    {...provided.dragHandleProps}
                    aria-label={`${title} task list`}
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Box sx={{ width: '80%' }}>
                        {props.stage?.id ?
                          <>
                            <Typography component="div" sx={{ fontSize: '1rem', fontWeight: 600 }}>{title}</Typography>
                            <Typography variant="span" sx={{ paddingRight: 1, fontSize: '0.9rem', fontWeight: 400 }}>$0.00</Typography>
                            <StyledSeparator />
                            <Typography variant="span" sx={{ color: `${variables.greyTextColor}`, paddingLeft: 1, fontSize: '0.9rem', fontWeight: 400 }}>3 Tickets</Typography>
                          </>
                          : <>
                          </>
                        }
                      </Box>
                      <Stack direction="row" >
                        <Typography component="div" sx={{ visibility: isHovering ? 'inherit' : 'hidden' }} >
                          <PipelineBoardPopover stage={props.stage}
                            stagesLength={props.stagesLength} index={index} collapseStage={() => handleCollapse(false)} resetHover={() => setIsHovering(false)} />
                        </Typography>
                        {tasksLength > 0 &&
                          <Checkbox
                            size="medium"
                            sx={{
                              p: 0, '& .MuiSvgIcon-root': { fontSize: 24 },
                              visibility: selectedItems.length > 0 ? 'inherit' : (isHovering ? 'inherit' : 'hidden')
                            }}
                            color="primary"
                            checked={selectedItems.length > 0 && selectedItems.length === tasksLength}
                            indeterminate={selectedItems.length > 0 && selectedItems.length !== tasksLength}
                            onChange={(e) => handleHeaderCheckboxSelection(e.target.checked)}
                          />
                        }
                      </Stack>
                    </Stack>
                  </ColumnTitle>
                </ColumnHeader>
                <ColumnContent
                  listId={title}
                  title={title}
                  listType="TASK"
                  stage={props.stage}
                  getTasksLength={getTasksLength}
                  selectedItems={selectedItems}
                  disableBoard={props.disableBoard}
                  handleCheckboxSelection={handleCheckboxSelection}
                />
              </ColumnContainer>
              <Stack direction="row" sx={{ mt: "-47px", ml: 1.25, visibility: isHovering ? 'inherit' : 'hidden' }} >
                <Button sx={{
                  width: 'calc(100% - 48px)', backgroundColor: `${variables.whiteColor}`,
                  boxShadow: `${variables.boxShadow}`,
                  "&:hover": {
                    backgroundColor: `${variables.whiteColor}`,
                  }
                }}
                  onClick={() => setShowDeal(true)}
                > <AddIcon sx={{ mr: 1 }} /> Deal
                </Button>
                <IconButton sx={{
                  width: '48px', borderRadius: 0, backgroundColor: `${variables.whiteColor}`,
                  boxShadow: `${variables.boxShadow}`,
                  "&:hover": {
                    backgroundColor: `${variables.whiteColor}`,
                  }
                }} aria-label="delete" onClick={() => handleCollapse(false)}>
                  <CloseFullscreenIcon />
                </IconButton>
              </Stack>
            </>
          )}
        </Draggable>
      </Collapse>
      {showDeal && <UpsertDeal handleDrawerClose={() => setShowDeal(false)} />}
    </>
  );
};

export default Column;
