import { Button, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'

function Mappoper(props) {

  const selecteditem= props.selecteditem;
  const [tabs,setTabs] = useState(1);

  
  const handleChange = (event, newValue) => {
  
    setTabs(newValue);
  };

  return (
    <div>
      <Tabs value={tabs} onClick={handleChange} onChange={handleChange} aria-label="disabled tabs example">
      <Tab label="Active" />
      <Tab label="Disabled" disabled />
      <Tab label="Active" />
    </Tabs>

        <Typography>Battery {selecteditem.battery}%</Typography>
        <Typography>{selecteditem.first_name} {selecteditem.last_name}</Typography>
        <Typography>{selecteditem.on_off_duty_status == 'N' ? 'ON_DUTY' : "OFF_DUTY"}</Typography>
        <Typography>last Updated on {selecteditem.ins_date} : {selecteditem.ins_time}</Typography>
    </div>
  )
}

export default Mappoper