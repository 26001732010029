import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { api_url } from "../../../globalSettings";
import {
 
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  IconButton,

  Stack,
  TextField,
  Avatar,

} from "@mui/material";
import Grid from "@mui/material/Grid";

import { Box } from "@mui/system";
import { selectedcompany } from "../managecompanyatom";

import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useRecoilState } from "recoil";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

import {
  login_atoms,
  login_token,
  login_companyid,
  triger_branch,
} from "../../../Atoms/LoginAtoms";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(5),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    // transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function DepartmentTableNew() {
  const [Getlist, setGetlist] = useState([]);
  const [GetDesignationtlist, setGetDesignationlist] = useState([]);
  const [Companydrawer, setCompanyDrawer] = useState(false);
  const [companyform, setCompanyForm] = useState([]);
  const uploadref = useRef(null);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "calc(100vh - 85px)",
    bgcolor: "white",
    boxShadow: 24,
    overflow : "hidden",
    overflowY:"scroll"
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [trigerBranch, setTrigerBranch] = useRecoilState(triger_branch);

 
  const [SelectedCompany, setSelectedCompany] = useRecoilState(selectedcompany);

  const [branchid, setBranchid] = useState("");

  const handleChange2 = (event) => {
    setBranchid(event.target.value);

  };

  // ======================================= Api company list =================================================

  function getBranchList() {
    const params = {
      company_id: SelectedCompany.licence_details.company_id,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
   
        setBranchid(responce.data.data[0]._id);
      })
      .catch((err) => {
   
      });
  }

  useEffect(() => {
    getBranchList();
  }, [trigerBranch]);

  useEffect(() => {
    getDesignationList();
  }, [branchid]);

  // ======================================= Api Department list =================================================


  const [branchid1, setBranchid1] = React.useState("");

  const handleChange1 = (event, newValue) => {
    if (newValue != null) {
      setBranchid1(newValue.id);
     
    }
  };

  React.useEffect(() => {
    getDesignationList();
  }, [branchid]);

  function getDesignationList() {
    const params = {
      token: loginToken1,
      branch_id: branchid,
    };



    axios
      .get(`${api_url}/getDesignationList`, { params: { ...params } })
      .then((responce) => {
        setGetDesignationlist(responce.data.data);
      })
      .catch((err) => {
      
      });
  }

  //  ========================    GET UPDATE DETAILS  ===========================

const [open1, setOpen1] = React.useState(false);
const handleOpen1 = () => setOpen1(true);
const handleClose1 = () => setOpen1(false);
const [upDesignationId , setUpDesignationId] =useState('');
const [getDesignationdetail , setGetDesignationdetails] = useState([])



const getDesignationdetails = (designationId)  => (e)  => {


  e.preventDefault()
  setUpDesignationId(designationId);
  const params = {
    token: loginToken1,
    designation_id: designationId,
  };
 

  axios
    .get(`${api_url}/getDesignationDetails`, { params: { ...params } })
    .then((responce) => {

      setGetDesignationdetails(responce.data.data)
      handleOpen1()
    })
    .catch((err) => {
    });
}


const updateDesignation= () => {

getDesignationdetail.token = loginToken1;
getDesignationdetail.designation_id = upDesignationId;


axios
  .patch(`${api_url}/updateDesignation`, getDesignationdetail, {
    headers: {
      "content-type": "application/json",
    },
  })
  .then(function (response) {
   
    getDesignationList();
    setOpen1(false);
  })

  .catch(function (error) {
    if (error.response) {

    } else if (error.request) {

    } else {

    }

  });
}

function handlechange(e) {

const { value, name } = e.target;

setGetDesignationdetails({ ...getDesignationdetail, [name]: value });
}



  function adddesgination() {
    const url = "http://server.nowdigitaleasy.com:3505/addDesignation";
    const params = JSON.stringify({
      token: loginToken1,
      user_id: loginAtom1,
      company_id: SelectedCompany.licence_details.company_id,
      branch_id: branchid1,
      designation: companyform.Designation,

    });



    axios
      .post(`${api_url}/addDesignation`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        getBranchList();
        getDesignationList();
        setOpen(false);
      })

      .catch(function (error) {

      });
  }

  // ======================================= Api DELETE =================================================
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (id) => {setUpDesignationId(id); setOpen2(true)};
  const handleClose2 = () => setOpen2(false);

  const deleteDesignation = (designationid) => () => {

    const params = {
      designation_id: upDesignationId,
      token: loginToken1,
    };

    axios
      .delete(`https://server.nowdigitaleasy.com:3505/deleteDesignation`, {
        params,
      })
      .then((result) => {

        getDesignationList();
        setOpen2(false);
      })
      .catch((error) => {

        if (error.response) {

        } else if (error.request) {
  
        } else {

        }

      });
  };

  //============================================== Changing Form Values =============================================
  const formonchange = (e) => {
    const {name,value} = e.target
    setCompanyForm({ ...companyform, [name]: value });
  };

  // ------------------------------------------------- Drawer ------------------------------------------------------



  return (
    <div className="companylist">
      {/* ======================================================== Company Table ======================================================= */}

      <div className="company_table">
        <Box>
          <div className="company_table_title">DESIGNATION</div>
          <div className="company_table-filter">
            <div className="filter_btn">
     

              <FormControl
                sx={{ m: 1 }}
                variant="standard"
                style={{ minWidth: "150px" }}
                size="small"
              >
                <InputLabel htmlFor="demo-customized-select-native">
                  Select Your Branch
                </InputLabel>
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
                  onChange={handleChange2}
                  input={<BootstrapInput />}
                >

                  {Getlist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>

                      </>

                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>

            <div className="btn">
              <Button
       
                className="tablebutton"
              
                onClick={handleOpen}
              >
                Add Designation
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    ADD DESIGNATION
                    <IconButton
                      component="span"
                      onClick={handleClose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={4}
                    padding={5}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={4}>
                      <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={Getlist}
                          onChange={handleChange1}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                           
                              label="Select Branch*"
                            />
                          )}
                        />
                        
                      </Grid>

                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label="Designation"
                          name="Designation"
                          value={companyform.Designation}
                          onChange={formonchange}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={adddesgination}
                        >
                          Add Designation
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
            </div>
          </div>
          <TableContainer component={Paper} className="tblcontainer">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table_head ">Designation</TableCell>
          
                  <TableCell className="table_head">Status</TableCell>
             
                </TableRow>
              </TableHead>

              {GetDesignationtlist.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.branchid1}
                 
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                  >
                    <TableCell
                      className="company_name"
                      title={item.designation}
                    >
                      <div>
                        <Avatar style={{ marginRight: 15 }} />
                      </div>
                      {item.designation}
                    </TableCell>
                   

                    <TableCell title="Active" className="companypersonalinfo">
                      Active
                    </TableCell>

                    <div class="overlay" style={{ width: "40%" }}>
                      <div class="text">
                      <Button style={{ fontWeight: 600 }} onClick={getDesignationdetails(item._id)}>Update Desigination</Button>
                        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    UPDATE DESIGNATION
                    <IconButton
                      component="span"
                      onClick={handleClose1}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={2}
                    padding={2}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>

                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Department Name"}
                          //onChange={(e) => formonchange("department_name", e)}
                          onChange={handlechange}
                          name="designation"
                          value={getDesignationdetail.designation}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose1}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={updateDesignation}
                        >
                          Update Designation
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
                        <Button
                          onClick={() => {handleOpen2(item._id)}}
                          style={{ fontWeight: 600 }}
                        >
                          Delete
                        </Button>
                        <Modal
                          open={open2}
                          onClose={handleClose2}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            backgroundColor: "#ffffff9e",
                          }}
                          // TransitionComponent={Transition}
                        >
                          <Box sx={style} className="scroll">
                            <div className="deleteemployee">
                              Are You Sure You Want To Delete Your Desigination?
                            </div>
                            <Stack
                              spacing={2}
                              padding={2}
                              width="100%"
                              alignItems="center"
                              height="100%"
                            >
                              <div className="addcompanybtn">
                                <Stack direction={"row"} spacing={2}>
                                  <Button
                                    variant="outlined"
                                    className="tablebtncancel"
                                    style={{ color: "#000" }}
                                    onClick={handleClose2}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="tablebutton"
                                    onClick={
                                      deleteDesignation(item._id)
                                     
                                    }
                                  >
                                    Delete
                                  </Button>
                                </Stack>
                              </div>
                            </Stack>
                          </Box>
                        </Modal>
                        <Button style={{ fontWeight: 600 }}>More Option</Button>
                      </div>
                    </div>

                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
}

export default DepartmentTableNew;
