import React, { useState } from 'react';
import styled from "styled-components";
import ContactCompanyDetails from './ContactCompanyDetails';
import { Divider } from '@mui/material';
import ContactTabNote from './ContactTabNote';
import ContactDetailsFollowupTab from './ContactDetailsFollowupTab';
import ContactTabEmail from './ContactTabEmail';

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column
`;

const Typography = styled.div`
color:#dd352c;
font: 12px;
`;

const TableContainer = styled.div`
width:100%;
border: 1px solid #00000021;
border-bottom: none;

`;

const TableColumn = styled.div`
border-bottom: 1px solid #00000021;
display:flex;
flex-direction:row;
font-size:12px;
`;

const TableRow = styled.div`
width:40%;
padding:5px;
text-align:right;
border-right:1px solid #00000021;
`;

const TableRow1 = styled.div`
width:60%;
padding:5px
`;

const ContactTabs = () => {
    const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabData = [
    { label: 'Timeline', content: <ContactTabNote /> },
    { label: 'Notes', content: <ContactTabEmail /> },  
    { label: 'Activities', content: <ContactDetailsFollowupTab /> },
    { label: 'Social', content: <ContactDetailsFollowupTab /> },
    { label: 'Pipelines', content: <ContactDetailsFollowupTab /> },
    { label: 'Files', content: <ContactDetailsFollowupTab /> },
  ];

  return (
    <Box>
      <ul className="tabs">
        {tabData.map((tab, index) => (
          <li
            key={index}
            className={index === activeTab ? 'active' : ''}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <Divider />
      <div className="tab-content">
        {tabData[activeTab].content}
      </div>
      <style>
        {
        `.tabs {
            list-style: none;
            padding: 0;
            display: flex;
          }
          
          .tabs li {
            cursor: pointer;
            padding: 10px 20px;
            margin-right: 10px;
            font-size:12px
          }
          
          .tabs li.active {
            background-color: #ffffff;
            font-weight: bold;
            border-bottom: 3px solid #5a7cc3;
          }
          
          .tab-content {
            margin-top: 10px;
            display : flex;
            justify-content : center
          }`
        }
      </style>
    </Box>
  );
}

export default ContactTabs