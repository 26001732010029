import { Grid } from '@mui/material'
import React from 'react'
import ContactMainDetails from './ContactMainDetails'

import ContactTabs from './ContactTabs'
import ContactFollowTable from './ContactFollowTable'
import ContactNotes from './ContactNotes'

const ContactDetailsBody = (selectedContactDetails) => {

  return (
    <div style={{width : "100%"}}> <Grid container spacing={2}>
    <Grid  item xs={12} sm={6} md={4}>
       <ContactMainDetails />
   
    </Grid>
    <Grid  item xs={12} sm={6} md={8}>
       <ContactTabs />
       <ContactFollowTable />
       <ContactNotes />
    </Grid>
</Grid></div>
  )
}

export default ContactDetailsBody