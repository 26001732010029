import { Divider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { useRecoilState } from 'recoil';
import { profile_trigger, selected_contact } from '../../Atoms/LoginAtoms';
import axios from 'axios';
import { api_url } from '../../globalSettings';
import noTask from "../../images/CRM/plan_7404361.png";


const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  font-size:12px;
  width: 100%;
  flex-direction : column
`;

const Typography = styled.div`
color:#dd352c;
font: 12px;
`;





const ContactLogs = () => {
    const [selectedContact] = useRecoilState(selected_contact);
    const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
    const [selectedContactDetails , setSelectedContactDetails] = useState(selectedContact);

    useEffect(() => {
       axios
        .get(`${api_url}/crm_customer/getCustomerByCustomerId/${selectedContact._id}`)
        .then((responce) => {
        
          setSelectedContactDetails(responce.data.data[0].logs.reverse());
        })
        .catch((err) => {
        });
    },[selectedContact,profileTrigger])

  return (
    <Box ><div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
    <Typography >Logs</Typography>
    <Divider />
    </div>

   {selectedContactDetails.length > 0 ?  <div className="task-table-container scroll">
      <table className="task-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Author</th>
            <th>Event</th>
            <th>Message</th>
          </tr>
        </thead>
        <tbody>
          {
            
            selectedContactDetails.length > 0 ?    selectedContactDetails.map((logs) => (
              <React.Fragment 
               key={logs._id}
              >
                <tr
               
                >
                  
                  <td style={{ width: "20%" }}>
                  {new Date(logs.date)
                          .toLocaleDateString()
                          .split("/")
                          .join("-")}{" "}
                        {new Date(logs.date).toLocaleTimeString()}
                  </td>
                  <td style={{ width: "20%", fontSize: "12px" }}>
                  {selectedContact.company_name}
                  </td>
                  <td style={{ width: "20%" }}>
                  {logs.event}
                  </td>
                  <td style={{ width: "40%" }}>
                  {logs.message}
                  </td>
                </tr>
              </React.Fragment>
            )) : ""
          } 
         
        </tbody>
      </table>
      <style>
        {`
    .task-table-container {
display: flex;
justify-content: flex-start;
align-items:center;
flex-direction : column;
font-family: "Poppins",sans-serif;
overflow: auto;
}


.task-table {
border-collapse: collapse;
width: 100%;
margin: 0 auto;
}
.button{
color: rgb(212, 211, 211) ;
border: 1px solid #3367d6;
background-color: #3367d6 ;
padding: 5px 10px ;
border-radius: 8px ;
margin-bottom:10px;
}

.button:hover{
color: rgb(212, 211, 211) ;
border: 1px solid #3367d6;
background-color: #3367d6 ;
padding: 5px 10px ;
border-radius: 8px ;
margin-bottom:10px;
}

.task-table th,
.task-table td {
padding: 12px;
text-align: left;
}

.task-table th {
background-color: #f2f2f2;
font-weight: 500;
font-size : 12px
}

.task-table tr {
background: #ffffff;
margin-left: 0;
box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
color: #1c1c22;
transition: background-color 0.2s ease;
cursor:pointer;
}

.task-table tr:hover {
background-color: #f5f5f5;
}

.task-row.active {
background-color: #e1f5fe;
cursor: pointer;
}

.add-subtask-button {
background-color: #007bff;
color: white;
border: none;
padding: 4px 8px;
cursor: pointer;
border-radius: 4px;
font-size: 14px;
transition: background-color 0.2s ease;
margin:10px 0
}

.add-subtask-button:hover {
background-color: #0056b3;
}

.sub-tasks-row td {
padding: 0 12px;
text-align: left;
border: none;
}


.task-line {
display: inline-block;
width: 20px;
position: relative;
padding-left:10px;
}

.branch-line {
position: absolute;
top: 8px;
left: -10px;
height: calc(100% - 8px);
width: 1px;
background-color: #ccc;
}

.branch-line.expanded {
background-color: #007bff;
}
.delete-icon {
position: absolute;
top: -8px;
right: -8px;
cursor: pointer;
background-color: #00000024;
color: white;
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}

/* Add this to your CSS */
.calendar-icon {
position: relative;
display: inline-block;
font-size: 12px
}

.calendar-icon input {
padding-right: 30px; /* Create space for the icon */
}

.calendar-icon .icon {
position: absolute;
top: 50%;
right: 10px;
transform: translateY(-50%);
cursor: pointer;
}
/* Add this to your CSS */
.calendar-icon {
position: relative;
display: inline-block;
}

.calendar-icon .icon {
cursor: pointer;
}
/* Add this to your CSS */
.calendar-dropdown {
position: relative;
display: inline-block;
}

.calendar-dropdown .icon {
cursor: pointer;
}

.calendar-dropdown .dropdown-content {
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}

/* Add this to your CSS */
.calendar-dropdown {
position: relative;
display: inline-block;
}

.calendar-icon {
cursor: pointer;
}

.calendar-dropdown-content {
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}
`}
      </style>
    </div>
:
    <div className="task-table-container1">
          <img src={noTask} alt="loading" />
          <p style={{ margin: "20px", fontSize: "14px" }}>There is no Logs</p>
          <style>
            {" "}
            {`
        .task-table-container1 {
  display: flex;
  justify-content: center;
  align-items:center;
  height:auto;
  flex-direction : column;
  padding: 20px;
  font-family: "Poppins",sans-serif;
}
`}
          </style>
        </div> }
    </Box>
  )
}

export default ContactLogs