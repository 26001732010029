import React from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Header from "../Header";
import ContactInfo from "./ContactInfo";
import PanelContent from "./PanelContent";

const ContactDetail = () => {

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={32} minSize={32}>
          <Header />
          <ContactInfo />
        </Panel>
        <PanelResizeHandle
              style={{
                backgroundColor: "#ddd",
                width: "1px",
                cursor: "col-resize",
              }}/>
        <Panel defaultSize={68} minSize={68}>
        <PanelContent/>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default ContactDetail;
