import { Typography, Button, Chip, IconButton, Stack, Box } from "@mui/material";
import { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import { CustomAutocompleteFreesolo } from "../pages/pipelines/components/CustomAutocompleteFreesolo";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import variables from "../assets/styles/_colors.scss";

const Tags = (props) => {

    const [showEditTag, setShowEditTag] = useState(false);
    const [tags, setTags] = useState([]);

    const handleTagChange = (e, val) => {
        setTags(val);
    }

    const handleTagDelete = (tag) => {
        const oldTags = [...tags];
        const index = oldTags.indexOf(tag);
        const removedTag = oldTags.splice(index, 1);
        setTags(oldTags);
    }

    return (
        <>
            <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 1 }}>Tags</Typography>
            {!showEditTag &&
                <Box>
                    {tags.map((tag, index) => {
                        return <Typography component="span" sx={{ pr: 1 }} key={index}>
                            <Chip label={tag} variant="outlined" onDelete={() => handleTagDelete(tag)} sx={{ mt: 1 }} />
                        </Typography>
                    })}
                    <Button variant="outlined" size="small" sx={{ height: 32, mt: 1, borderRadius: 20, width: 60, textTransform: "none" }} onClick={() => setShowEditTag(true)}>
                        <AddIcon /> {tags.length === 0 && "Tag"}
                    </Button>
                </Box>
            }
            {
                showEditTag &&
                <Stack direction="row" width="100%" alignItems="center" spacing={1}>
                    <Stack direction="column" width="80%" justifyContent="left">
                        <CustomAutocompleteFreesolo defaultValue={tags} handleAutocompleteChange={handleTagChange} />
                    </Stack>
                    <Stack direction="row" width="20%" spacing={1}>
                        <IconButton size="small" sx={{ color: "#fff", backgroundColor: `${variables.linkColor} !important` }} onClick={() => setShowEditTag(false)}>
                            <DoneIcon />
                        </IconButton>
                        <IconButton size="small" sx={{ backgroundColor: `${variables.iconButtonBackgroundColor} !important` }} onClick={() => setShowEditTag(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </Stack>
            }
        </>
    )
};

export default Tags;