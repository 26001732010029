import * as React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Grid, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import variables from '../../../assets/styles/_colors.scss';
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { withStyles } from "@material-ui/core/styles";
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useLocation } from 'react-router-dom';
import { styled } from '@mui/system';
import { stringAvatar } from '../../../utils/PipelineConstants';
import FileUpload from '../../../components/files/FilesUpload';
import { ProductInfo } from './ProductInfo';
import PipelinesTab from './PipelineTableContactDetailedView';
import UpsertProduct from '../create/UpsertProduct';
import DeleteProduct from '../DeleteProduct';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import NativeTable from '../../../components/nativetable/Table';

const StyledIcon = withStyles({
    root: {
        backgroundColor: `${variables.listBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.listBackgroundColor} !important`
        }
    }
})(IconButton);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 0,
    flexGrow: 1,
    minHeight: '100vh',
    minWidth: '100vw',
    height: '100vh',
    width: '100vw'
};

const useStyles = makeStyles((theme) => ({
    listItemSelected: {
        backgroundColor: `${variables.listBackgroundColor} !important`,
    },
}));

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important',
        fontWeight: 'bold !important',
    }
})(Tab);

const StyledDotSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

const StyledLink = styled(Link)`
     color: #212129;
`;

export default function ProductDetailedView(props) {

    const locationData = useLocation();
    const [selectedItem, setSelectedItem] = useState(null);
    const [editProduct, setEditProduct] = useState(false);
    const [deleteProduct, setDeleteProduct] = useState(false);
    const [selectedTab, setSelectedTab] = useState("0");
    const [cloneProduct, setCloneProduct] = useState(false);

    useEffect(() => {
        if (locationData?.state) {
            const item = locationData?.state?.item;

            setSelectedItem(item);
        }
    }, [locationData?.state])

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
    }

    return (
        <>
            <Box sx={style}>
                <Grid container direction="row" sx={{ mt: 6, ml: 7.25, width: 'auto' }}>
                    <Grid item xs={12} sx={{ backgroundColor: `${variables.lightGreyBackgroundColor}`, borderBottom: `1px solid ${variables.borderColor}` }}>
                        <Grid container direction="row" sx={{ p: 2, pb: 0, pl: 1 }}>
                            <Grid item xs={0.25}>
                                <IconButton>
                                    <StyledLink to={-1}><KeyboardBackspaceIcon /></StyledLink>
                                </IconButton>
                            </Grid>
                            <Grid item xs={5} sx={{ pl: 2, pb: 1.5 }}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Stack direction="column">
                                        {selectedItem && selectedItem.productName && <Avatar {...stringAvatar(`${selectedItem?.productName}`)} />}
                                    </Stack>
                                    <Stack direction="column">
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>{selectedItem?.productName}</Typography>
                                            <StyledDotSeparator />
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>${selectedItem?.unitPrice}/Unit</Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography sx={{ color: `${variables.greyTextColor}` }}>
                                                <PersonIcon size="small" sx={{ width: 16, height: 16, mt: -0.25 }} />
                                                <Typography component="span" sx={{ pl: 0.5, fontSize: "12px" }}>{selectedItem?.productOwner}</Typography>
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6.75} sx={{}}>
                                <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                                    <Tooltip title="Clone" placement="bottom">
                                        <StyledIcon onClick={() => setCloneProduct(true)}>
                                            <FileCopyOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                                        </StyledIcon>
                                    </Tooltip>
                                    <Tooltip title="Edit" placement="bottom">
                                        <StyledIcon onClick={() => setEditProduct(true)}>
                                            <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                                        </StyledIcon>
                                    </Tooltip>
                                    <Tooltip title="Delete" placement="bottom">
                                        <StyledIcon onClick={() => setDeleteProduct(true)}>
                                            <DeleteOutlineIcon fontSize='medium' color="error" />
                                        </StyledIcon>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" sx={{ p: 0, pl: 1 }}>
                        <Grid item xs={3}>
                            <Box sx={{ bgcolor: "#fff", height: '83vh', overflowX: "hidden", overflowY: "auto", borderRight: `1px solid ${variables.borderColor}` }}>
                                {selectedItem && <ProductInfo item={selectedItem} />}
                            </Box>
                        </Grid>
                        <Grid item xs={9}>
                            <Tabs value={selectedTab} onChange={handleTabChange}>
                                <StyledTab label="Pipelines" value="0" />
                                <StyledTab label="Files" value="1" />
                                <StyledTab label="Native Table" value="2" />
                            </Tabs>

                            <Box sx={{ overflow: 'hidden', p: 1, bgcolor: `${variables.tabsBackgroundColor}` }}>

                                {selectedTab === "0" &&
                                    <Box sx={{ overflow: "hidden", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <PipelinesTab />
                                    </Box>
                                }

                                {selectedTab === "1" &&
                                    <Box sx={{ overflowX: "hidden", overflowY: "auto", height: "74vh", pb: 2, bgcolor: "#fff" }}>
                                        <FileUpload />
                                    </Box>
                                }

                                {selectedTab === "2" &&
                                    <Box sx={{ overflow: "auto", height: "73vh", pb: 2, bgcolor: "#fff" }}>
                                        <NativeTable />
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {deleteProduct &&
                <DeleteProduct item={selectedItem} module={props.module} handleClose={() => { setDeleteProduct(false) }} />}

            {cloneProduct &&
                <UpsertProduct edit={true} editData={selectedItem} isClone={true} module={props.module} handleDrawerClose={() => { setCloneProduct(false) }} />}

            {editProduct &&
                <UpsertProduct edit={true} editData={selectedItem} module={props.module} handleDrawerClose={() => { setEditProduct(false) }} />}

        </ >
    );
}
