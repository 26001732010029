import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AvatarStatus from "../Components/Avatar";
import Styles from "../Contact.module.scss";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useQuery } from "@tanstack/react-query";

const fetcher = () => {
  return axios
    .get( "https://660d45d36ddfa2943b33ecdd.mockapi.io/employee")
    .then(({ data }) => data);
};
const ContactInfo = () => {
  const [selected, setSelected] = useState({});
  const [showDeleteIcon, setShowDeleteIcon] = useState(false); 
  const location = useLocation();
  const navigate = useNavigate();

  const { data: contactdata, isLoading } = useQuery({
    queryKey: ["contactdata"],
    queryFn: fetcher,
  });
  
  useEffect(() => {
    const anySelected = Object.values(selected).some((value) => value);
    setShowDeleteIcon(anySelected); 
  }, [selected]);

  const handleRoute = (id) => {
    navigate(`/chat/contacts/${id}`);
  };
  const handleCheckboxChange = (id) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }));
  };
  if (isLoading) return <p>Loading...</p>;
  return (
    <TableContainer sx={{ maxHeight: "92%" }}>
      <Table
        sx={{ minWidth: 300, overflowY: "auto" }}
        stickyHeader
        aria-label="sticky table"
      >
        <TableHead sx={{ borderBottom: "1px solid #E2E3E6" }}>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: "var( --light-bg-img-color)",
                padding: "14px 10px 15px 10px",
                width:"30px",
              }}
            >
             
            </TableCell>
            <TableCell
              sx={{
                backgroundColor:"var( --light-bg-img-color)",
                padding: "14px 10px 15px 10px",
                display:"flex",
                justifyContent:"space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "600", paddingRight: "50px" }}
                >
                  {" "}
                  Contact List
                </Typography>
              </Box>
              <Box>{showDeleteIcon && <DeleteOutlineIcon sx={{cursor:"pointer","&:hover":{color:"red"}}}/>} </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ cursor: "pointer" }}>
          {contactdata.map((value) => (
            <TableRow
              key={value.id}
              sx={{ "&:hover": { backgroundColor: "var( --light-bg-img-color)" } }}
              className={`${Styles.ChoverCheckbox} ${
                location.pathname ===  `/chat/contacts/${value.id}`
                  ? Styles.CActiveselect
                  : ""
              }`}
            >
              <TableCell sx={{padding: "10px",width:"40px", }}>
                <Checkbox
                  className={
                    selected[value.id]
                      ? `${Styles.ChoverCheckbox}`
                      : Styles.CCheckbox
                  }
                  checked={selected[value.id] || false}
                  onChange={() => handleCheckboxChange(value.id)}
                />
              </TableCell>
              <TableCell sx={{ padding: "10px", display: "flex" }} onClick={() => handleRoute(value.id)}>
                
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <AvatarStatus
                      src={value.avatar}
                      alt="User Avatar"
                      status={value.status}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ marginLeft: "10px" }}>{value.name}</div>
                      <span style={{ marginLeft: "10px" }}>{value.email}</span>
                    </div>
                  </div>
             
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContactInfo;
