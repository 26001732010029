import { action_contants } from "../actions/action-types.js";

const initialState = {
  currentPage: "DASHBOARD",
  currentSetting: "",
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case action_contants.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case action_contants.SET_CURRENT_SETTING:
      return {
        ...state,
        currentSetting: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default homeReducer;
