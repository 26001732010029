import { Box, Stack, Typography } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import TransitionRules from "./transitionrules/TransitionRules";
import { useQuery } from "@tanstack/react-query";
import { getAllPipelines } from "../../../webservices/StageService";
import RestrictClosure from "./restrictclosure/RestrictClosure";

export default function StageTransitionRules() {

    const [selectedTab, setSelectedTab] = useState("transitionrules");

    const [searchParams, setSearchParams] = useSearchParams();

    const {
        data
    } = useQuery({
        queryKey: ['getAllPipelines'],
        queryFn: () => getAllPipelines(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        const currentTab = searchParams.get('type');
        setSelectedTab(currentTab ? currentTab : "transitionrules");
        searchParams.set('type', currentTab ? currentTab : "transitionrules");
        setSearchParams(searchParams);
    }, []);

    const handleTabSelection = (type) => {
        searchParams.set('type', type);
        setSearchParams(searchParams);
        setSelectedTab(type);
    }

    return (
        <Box width="100%">
            <Stack direction="row" alignItems="center" width="100%" sx={{ p: 1, pl: 0, pr: 2, height: 58 }} spacing={2}>
                <Stack direction="row" justifyContent="center" width="auto" sx={{ borderRadius: 24, border: `1px solid ${variables.borderColor}`, cursor: "pointer", backgroundColor: "#fff" }}>
                    <Box onClick={() => handleTabSelection("transitionrules")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "transitionrules" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Transition Rules</Typography>
                    </Box>
                    <Box onClick={() => handleTabSelection("restrictclosure")} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === "restrictclosure" ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                        <Typography component="span" sx={{ lineHeight: 1.2 }}>Closure Restriction</Typography>
                    </Box>
                </Stack>
            </Stack>
            {
                selectedTab === "transitionrules" &&
                <TransitionRules data={data} />
            }

            {
                selectedTab === "restrictclosure" &&
                <RestrictClosure data={data} />
            }
        </Box>
    )
}