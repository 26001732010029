
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { Checkbox, Typography, Box, Button, Stack, FormControlLabel } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import variables from "../../../assets/styles/_colors.scss";

export default function ReorderableList(props) {

    const [data, setData] = useState(props.items);
    const [showError, setShowError] = useState(false);

    const handleCancel = () => {
        props.handleCancel(null);
    };

    const handleOk = () => {
        props.handleOk(data);
    };

    function reorderItems(result) {
        if (!result.destination) {
            return;
        }
        const startIndex = result.source.index;
        let endIndex = result.destination.index;
        // Handle drag if user tries to position the item at first
        if (endIndex === 0) endIndex = endIndex + 1;
        // Handle drag if user tries to position the item at last
        if (endIndex == data.length - 1) endIndex = endIndex - 1;
        const itemArray = [...data];
        const [removed] = itemArray.splice(startIndex, 1);
        itemArray.splice(endIndex, 0, removed);
        setData(itemArray);
    }

    const handleCheckboxChange = (item) => {
        const selectedData = data.filter(d => d.selected === true);
        if (props.module === "pipelineBoard" && !item.selected && selectedData.length === 6) {
            setShowError(true);
            return;
        }
        setShowError(false);
        const updatedData = data.map((d) => {
            if (d._id === item._id) {
                return { ...d, selected: !item.selected };
            }
            return d;
        });
        setData(updatedData);
    }

    return (
        <Box sx={{ pl: 0.5, pr: 2, pt: 2 }}>
            <DragDropContext onDragEnd={reorderItems}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {data?.map((item, i) => {
                                return (
                                    <Draggable key={item._id} draggableId={item._id} index={i} isDragDisabled={item.disabled === true}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                variant={snapshot.isDragging ? "elevation" : "outlined"}
                                                elevation={4}
                                            >
                                                <Stack alignItems="center" direction="row" sx={{ width: "100%", pb: 1.5 }}>
                                                    <Typography component="div" sx={{ cursor: "move", visibility: item.disabled ? 'hidden' : 'inherit' }} {...provided.dragHandleProps} >
                                                        <DragIndicatorIcon />
                                                    </Typography>
                                                    <FormControlLabel sx={{ width: "100%", border: `1px solid ${variables.mediumBorderColor}`, borderRadius: 0, margin: 0 }} control={
                                                        <Checkbox checked={item.selected} disabled={item.disabled}
                                                            sx={{ borderRight: `1px solid ${variables.mediumBorderColor}`, borderLeft: `1px solid ${variables.mediumBorderColor}`, borderRadius: 0, mr: 1 }} onChange={() => handleCheckboxChange(item)}
                                                        />} label={item.header || item.label} />
                                                </Stack>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {showError && <Typography component="div" sx={{ color: `${variables.removeIconColor}`, pt: 1, pl: 1 }}>You cannot select more than 6 fields</Typography>}
            <Stack direction="row" justifyContent="right" spacing={2}
                sx={props.module !== "modifyProducts" ? { bottom: 16, right: 16, position: "fixed" } : {}}
            >
                <Button variant="outlined" onClick={handleCancel}> Cancel </Button>
                <Button variant="contained" onClick={handleOk}>Save</Button>
            </Stack>
        </Box>
    )
}