import { create } from "zustand";
import { persist } from "zustand/middleware";

const useWorkSpaceStore = create(
  persist(
    (set, get) => ({
      workSpace: [],

      setWorkSpace: (newWorkSpacesArray) => {
        set({ workSpace: newWorkSpacesArray });
      },
      getWorkSpace: () => {
        return get().workSpace;
      },
    }),
    {
      name: "workSpace-persit",
    }
  )
);

export default useWorkSpaceStore;


