import { Typography } from '@mui/material';
import React from 'react'
import Styles from "../../ContactInfo.module.scss";
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import { useNavigate, useParams } from 'react-router-dom';

const Benefit = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const handleClose = () => {
        navigate(`/chat/contacts/${id}`);
      };
  return (
    <div>
   
      <div className={Styles.CEditHeader}>
      <WestOutlinedIcon onClick={handleClose} sx={{cursor:"pointer"}}/>
      <Typography sx={{paddingLeft:"16px"}}>Benefit</Typography>
      </div>
    </div>
  )
}

export default Benefit
