import React from 'react'

const WhatsappHome = () => {
  return (
    <div>
      Whatsapp
    </div>
  )
}

export default WhatsappHome
