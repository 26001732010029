import { action_contants } from "../actions/action-types.js";

const initialState = {
  signUpPage: "INITIAL", //INITIAL, PERSONAL, VERIFY
  loginForm: {
    action: "signin",
    email: "",
    password: "",
  },
  userData: {},
  newUserData: {},
  signUpForm: {
    action: "signup",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    company_name: "",
    address: "",
    city: "",
    pincode: "",
    state: "",
    password: "",
    country: "",
  },
  userInfo: {},
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case action_contants.SET_SIGNUP_PAGE:
      return {
        ...state,
        signUpPage: action.payload,
      };
    case action_contants.SET_SIGN_IN_FORM_DATA:
      return {
        ...state,
        loginForm: action.payload,
      };

    case action_contants.GET_USER_DATA:
    return {
        ...state,
        userData: action.payload,
      };
    case action_contants.SET_SIGN_UP_FORM_DATA:
      return {
        ...state,
        signUpForm: action.payload,
            };
    case action_contants.GET_NEW_USER_DATA:
      return {
        ...state,
        newUserData: action.payload,
      };
    case action_contants.GET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };

    default:
      break;
  }
  return state;
};

export default loginReducer;
