import './../pipelines.css';

import React, { useEffect, useState } from "react";

import { getAllPipelines } from '../../../webservices/PipelineService';
import PipelineLeftPanel from "./PipelineLeftPanel";

export default function Pipeline() {

    const [pipelines, setPipelines] = useState([]);

    useEffect(() => {
        getAllPipelines().then((response) => {
            if (response?.data?.pipelines?.length > 0) {
                setPipelines(response.data.pipelines);
            }
        })
    }, []);

    return (
        <>
            {pipelines.length > 0 && <PipelineLeftPanel pipelineList={pipelines}></PipelineLeftPanel>}
        </>
    )
}
