import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import { api_url } from "../../../globalSettings";
import {
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  IconButton,
  Stack,
  TextField,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { selectedcompany } from "../managecompanyatom";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useRecoilState } from "recoil";
import { Alert, Snackbar } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../styles/trackey.css";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
//import recoil state
import {
  login_atoms,
  login_token,

  triger_branch,
} from "../../../Atoms/LoginAtoms";
import "../ManageBranch/ManageBranchNew.css";
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(5),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));


function ManageCompanyNew() {
  const [branchid, setBranchid] = useState("");


  const handleChange2 = (event) => {
    setBranchid(event.target.value);

  };
  // ============================ = WORKING DAYS ============================================================



  const [Getlist, setGetlist] = useState([]);
 
  const [companyform, setCompanyForm] = useState({
    Leave_type: "",
    no_of_days: "",
  });


 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "auto",
    bgcolor: "white",
    boxShadow: 24,
  };

  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
 
  const [SelectedCompany, setSelectedCompany] = useRecoilState(selectedcompany);
  const [trigerBranch, setTrigerBranch] = useRecoilState(triger_branch);



  const [branchid1, setBranchid1] = useState("");
 
  const [GetLeaveTypeList, setGetleaveTypelist] = useState([]);

  const branchlist = (event, newValue) => {
    if (newValue != null) {
      setBranchid1(newValue.id);
    
    }
  };

  function getLeaveTypeList() {

    const params = {
      token: loginToken1,
      branch_id: branchid,
    };

    axios
      .get(`${api_url}/getLeaveTypeList`, { params: { ...params } })
      .then((responce) => {
        setGetleaveTypelist(responce.data.data);
      })
      .catch(function (error) {
        if (error.response) {

        } else if (error.request) {

        } else {

        }

      });
  }

  useEffect(() => {
    getLeaveTypeList();
  }, [branchid]);

  useEffect(() => {
    getLeaveTypeList();
  }, []);

  function getBranchList() {

    const params = {
      company_id: SelectedCompany.licence_details.company_id,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
        setBranchid(responce.data.data[0]._id);
  
      })
      .catch((err) => {

      });
  }

  useEffect(() => {
    getBranchList();
    getLeaveTypeList();
  }, [trigerBranch]);

  const [leaveType, setLeaveType] = React.useState("");

  const handleChange1 = (event) => {
    setLeaveType(event.target.value);

  };

  // ======================================= Api DELETE =================================================
  const [open2, setOpen2] = React.useState(false);
  const [leveId,setLeaveId] = React.useState([])

  const handleOpen2 = (id) => (e) => {
    setOpen2(true);
    setLeaveId(id)
  }

  const handleClose2 = () => setOpen2(false);

  const deleteLeaveType = (e) => {
   
    axios
      .put(`${api_url}/deleteLeaveType`, {
        leave_type_id: leveId,
      token: loginToken1,
      })
      .then((result) => {
      
        getLeaveTypeList();
        setOpen2(false);
      })
      .catch((error) => {
   
        if (error.response) {
      
        } else if (error.request) {
   
        } else {

        }

      });
  };


  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setCompanyForm((state) => ({ ...state, [name]: value }));

    const newErrors = { ...errors };

    switch (name) {
      case "no_of_days":
        newErrors.shift_name = validator.isEmpty(value)
          ? "Please enter your First Name."
          : "";
        break;

      
      default:
        break;
    }

    setErrors(newErrors);
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {

      const params = JSON.stringify({
        token: loginToken1,
        user_id: loginAtom1,
        company_id: SelectedCompany.licence_details.company_id,
        branch_id: branchid1,
        leave_type: leaveType,
        no_of_days: companyform.no_of_days,
      });

      axios
        .post(`${api_url}/addLeaveType`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
    
          setSnackbaropen({
            open: true,
            message: "Leave Type Added successfully",
            severity: "success",
            actionColor: "#edf7ed",
          });
          getLeaveTypeList();
          setOpen(false);
        })
  
        .catch(function (error) {
          if (error.response) {
       
          } else if (error.request) {
        
          } else {
        
          }
 
        });

    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    // const isValid1 = validatePhone();
    // setIsValidPhone(isValid1);

 

    // if (validator.isEmpty(companyform.shift_name)) {
    //   isValid = false;
    //   newErrors.shift_name = "Please enter your first name.";
    // }

    if (validator.isEmpty(companyform.no_of_days)) {
      isValid = false;
      newErrors.no_of_days = "Please enter no of days.";
    }

    if (validator.isEmpty(branchid1)){
      isValid = false;
      newErrors.branchid1 = "Please Select Branch"
    }
    if (validator.isEmpty(leaveType)){
      isValid = false;
      newErrors.leaveType = "Please Select Leave Type"
    }
   
    // if (validator.isEmpty(personName)){
    //   isValid = false;
    //   newErrors.personName = "Please Select Joining Date.";
    // }
    // if (validator.isEmpty(earlyStartTime)){
    //   isValid = false;
    //   newErrors.earlyStartTime = "Please Select Joining Date.";
    // }
    // if (validator.isEmpty(lateStartTime)){
    //   isValid = false;
    //   newErrors.lateStartTime = "Please Select Joining Date.";
    // }
    // if (validator.isEmpty(earlyEndTime)){
    //   isValid = false;
    //   newErrors.earlyEndTime = "Please Select Joining Date.";
    // }
    // if (validator.isEmpty(lateEndTime)){
    //   isValid = false;
    //   newErrors.joiningDate = "Please Select Joining Date.";
    // }

    // if (validator.isEmpty(input.phone_number)) {
    //   isValid = false;
    //   newErrors.phone_number = "Please enter your phone number.";
    // } else if (
    //   !validator.isNumeric(input.phone_number) ||
    //   input.phone_number.length !== 10
    // ) {
    //   isValid = false;
    //   newErrors.phone_number = "Please enter a valid 10-digit phone number.";
    // }

    setErrors(newErrors);
    return isValid;
  };

  const [updateopen,setupdateopen] = React.useState(false)
  const [updateData,setupdateData] = React.useState({branch:{label:"",id:""}})
  const [leaveType33,setleaveType33] = React.useState([])
  const [days,setdays] = React.useState([])
  const [leaveTypeId,setleaveTypeId] = React.useState([])

  const updateLeave = (value) => (e) =>{


    setupdateopen(true)
 
    let branch = Getlist.find((obj)=>obj.id === value.branch_id)

    setupdateData({ ...updateData, ['branch']: {label:branch.label,id:branch.id} })
    setleaveType33({ ...leaveType33, ['leave']: value.leave_type })
    setdays(value.no_of_days)
    setleaveTypeId(value._id)

  }

  const updateClose = (e) =>{
    setupdateopen(false)
  }


  const [leaveTypename,setleaveTypename] = React.useState([])


  const daysChange = (e) =>{
    setdays(e.target.value)
  }

  const submit = (e) =>{

    axios.put(`${api_url}/updateLeaveType`,{token:loginToken1,branch_id:updateData.id,leave_type_id:leaveTypeId,leave_type:leaveTypename.leave,no_of_days:days}).then((result)=>{

      if(result.data.type === "success"){
     
        getLeaveTypeList();
      }

    }).catch((err)=>{

    })

  }


  return (
    <div className="companylist">
      <Snackbar
        open={snackbaropen.open}
        onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
        autoHideDuration={1000}
      >
        <Alert
          severity={snackbaropen.severity}
          style={{ backgroundColor: `${snackbaropen.actionColor}` }}
        >
          {snackbaropen.message}
        </Alert>
      </Snackbar>
      {/* ======================================================== Company Table ======================================================= */}

      <div className="company_table">
        <div className="company_table_title">LEAVE</div>
        <Box style={{ maxHeight: 400 }}>
          <div className="company_table-filter">
            <div className="filter_btn">
              <FormControl
                sx={{ m: 1 }}
                variant="standard"
                style={{ minWidth: "150px" }}
                size="small"
              >
                <InputLabel htmlFor="demo-customized-select-native">
                  Select Your Branch
                </InputLabel>
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
                  onChange={handleChange2}
                  input={<BootstrapInput />}
                >

                  {Getlist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>

                      </>

                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>

            <div className="btn">
            

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{ width: "50%" }}>
                  <div className="addcompanytitle">
                    ADD LEAVE TYPE
                    <IconButton
                      component="span"
                      onClick={handleClose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={4}
                    padding={5}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={Getlist}
                          onChange={branchlist}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              // {...register("Getlist", {
                              //   required: "required",
                              // })}
                              // error={errors.sub_category}
                              // helperText={errors.sub_category?.message}
                              label="Select Branch*"
                            />
                          )}
                        />
                         {errors.branchid1  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.branchid1} 
            </div> : ""}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
          
                        <FormControl sx={{ minWidth: "100%" }} size="small">
                          <InputLabel id="demo-select-small-label">
                            Select Leave Category
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            label="Select Leave Category"
                            value={leaveType}
                            onChange={handleChange1}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem
                              value="Sick Leave(SL)"
                            >
                              Sick Leave(SL)
                            </MenuItem>
                            <MenuItem value="Casual Leave(CL)">
                              Casual Leave(CL)
                            </MenuItem>
                            <MenuItem value="Paid Leave(PL)">
                              Paid Leave(PL)
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {errors.leaveType  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.leaveType} 
            </div> : ""}
                      
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"No Of Days"}
                          name="no_of_days"
                          onChange={handleChange}
                          // onChange={(e) => formonchange("no_of_days", e)}
                          fullWidth
                          required
                        />
                         {errors.no_of_days ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.no_of_days}  </div>
          ) : (
            ""
          )}
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={handleSubmit}
                        >
                          Add
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
            </div>
          </div>
          <TableContainer component={Paper} className="tblcontainer">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table_head ">Branch Name</TableCell>
                  {/* <TableCell className="table_head">Contact Person</TableCell>
                  <TableCell className="table_head">Email</TableCell>
                  <TableCell className="table_head">Phone</TableCell> */}
                  <TableCell className="table_head">Status</TableCell>
                  {/* <TableCell className="table_head">Delete</TableCell> */}
                </TableRow>
              </TableHead>

              {GetLeaveTypeList.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.companyId1}
                    // onClick={() => {
                    //   setSelectedCompany(item);
                    // }}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                  >
                    <TableCell className="company_name" title={item.leave_type}>
                      <div>
                        <Avatar style={{ marginRight: 15 }} />
                      </div>
                      {item.leave_type}
                    </TableCell>
                    {/* <TableCell
                      title={item.contact_person}
                      className="companypersonalinfo"
                    >
                      {item.contact_person}
                    </TableCell> */}
                    {/* <TableCell
                      title={item.email}
                      className="companypersonalinfo"
                    >
                      {item.email}
                    </TableCell> */}
                    {/* <TableCell
                      title={item.phone_number}
                      className="companypersonalinfo"
                    >
                      {item.phone_number}
                    </TableCell> */}
                    <TableCell title="Active" className="companypersonalinfo">
                      Active
                    </TableCell>
                    <div class="overlay" style={{ width: "40%" }}>
                      <div class="text">
                        <Button style={{ fontWeight: 600 }} onClick={updateLeave(item)}>Update Leave</Button>
                        <Button
                          onClick={handleOpen2(item._id)}
                          style={{ fontWeight: 600 }}
                        >
                          Delete
                        </Button>
                      
                        <Button style={{ fontWeight: 600 }}>More Option</Button>
                      </div>
                    </div>
                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      </div>
      <Modal
                          open={open2}
                          onClose={handleClose2}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            backgroundColor: "#ffffff9e",
                          }}
                          // TransitionComponent={Transition}
                        >
                          <Box sx={style} className="scroll">
                            <div className="deleteemployee">
                              Are You Sure You Want To Delete Your Leave Type?
                            </div>
                            <Stack
                              spacing={4}
                              padding={2}
                              width="100%"
                              alignItems="center"
                              height="100%"
                            >
                              <div className="addcompanybtn">
                                <Stack direction={"row"} spacing={2}>
                                  <Button
                                    variant="outlined"
                                    className="tablebtncancel"
                                    style={{ color: "#000" }}
                                    onClick={handleClose2}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="tablebutton"
                                    onClick={deleteLeaveType}
                                  >
                                    Delete
                                  </Button>
                                </Stack>
                              </div>
                            </Stack>
                          </Box>
                        </Modal>

                        <Modal
                open={updateopen}
                onClose={updateClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{ width: "50%" }}>
                  <div className="addcompanytitle">
                    UPDATE LEAVE TYPE
                    <IconButton
                      component="span"
                      onClick={updateClose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={4}
                    padding={5}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      {/* <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          value={updateData.branch.label}
                          options={Getlist}
                          onChange={branchChange}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                             
                              label="Select Branch*"
                            />
                          )}
                        />
                         
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>

                      <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          value={leaveType33.leave}
                          options={leaveTypes}
                          onChange={updateLeave11}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                             
                              label="Select Leave Category*"
                            />
                          )}
                        />
          
                      </Grid>
                      {errors.leaveType  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.leaveType} 
            </div> : ""} */}
                      
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"No Of Days"}
                          name="no_of_days"
                          onChange={daysChange}
                          value={days}
                          fullWidth
                          required
                        />
                         {/* {errors.no_of_days ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.no_of_days}  </div>
          ) : (
            ""
          )} */}
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={submit}
                        >
                          Add
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
    </div>
  );
}

export default ManageCompanyNew;
