import { Accordion, AccordionDetails, AccordionSummary, ListItem, ListItemIcon, TextField, Typography } from "@mui/material";
import { IoIosArrowBack } from "react-icons/io";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import Button from "../../../../components/Button";
import Img from '../../../../Images/MessageShrtcuts/Img.png'
import Upload from '../../../../Images/Upload.png'


function WebSettings({website,handleBackToWebsiteSettings}) {
  const [accordionExpanded, setAccordionExpanded] = useState({
    websiteInfo: false,
    setupInstructions: false,
    operators: false,
  });
  
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionExpanded((prevExpanded) => ({
      ...Object.fromEntries(
        Object.entries(prevExpanded).map(([key]) => [key, false])
      ),
      [panel]: isExpanded,
    }));
  };
  

  function GreySquareIcon() {
    return (
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" fill="#CCCCCC" />
      </svg>
    );
  }
  return (
    <div style={{
        height: "100%"
    }}>
      <div style={{
      padding: "3% 2%"
    }}>
        <div style={{
            // border: "1px solid blue",
            height: "70vh",
            
        }}>
          <div style={{
            // border: "1px solid green",
            height: "5vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start"
          }}>
            <IoIosArrowBack style={{
              cursor: "pointer",
              marginRight: "2%"
            }} onClick={handleBackToWebsiteSettings} />
            <span style={{
              fontWeight: '600'
            }}>
              Web Settings
            </span>
           <span style={{
            height: "5vh",
            borderLeft: "3px solid #efefef",
            marginLeft: "2%",
            marginRight: "3%"
           }}></span>
            <span>{website.name}</span> 


          </div>
          <div style={{
            // border: "1px solid pink",
            height: '10vh',
            marginTop: "5%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",backgroundColor: 'rgba(245,245,248,1)'
          }}>
            The website is not linked to any payment method. Link one to keep your subscription 

          </div>
          <div style={{
            // border: "1px solid grey",
            marginTop: "3%",
            
            // height: "8vh"
          }}>
            <Accordion
            expanded={accordionExpanded.websiteInfo}
            onChange={handleAccordionChange("websiteInfo")}
            style={{
              height: "100%",
              // marginBottom: "-10%"
              marginBottom: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <ListItem alignItems="center">
                <ListItemIcon>
                  <GreySquareIcon />
                </ListItemIcon>
                <Typography variant="subtitle1" style={{
                  // width: "15px",
                  display: "flex",
                  flexDirection: "column"
                }}>Website Information</Typography>
                <Typography
                  variant="body2"
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }}
                >
                  Configure your website information. This defines how your
                  website appears to your users.
                </Typography>
                
              </ListItem>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div style={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "2%"
                }}>
                  <TextField
                    id="websiteDomain"
                    label="Website Domain*"
                    variant="outlined"
                    
                  />
                  <TextField
                    id="websiteName"
                    label="Website Name*"
                    variant="outlined"
                    
                  />
                </div>
                <span
                  style={{
                    borderBottom: "1px solid #333",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",

                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body5">+Website Icon</Typography>

                    <img
                    src={Img}
                    alt="Image"
                    style={{
                      width: "2vw",
                    }}
                  />
                  </div>
                  

                  <img
                    src={Upload}
                    alt="upload"
                    style={{
                      width: "2vw",
                    }}
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={accordionExpanded.setupInstructions}
            onChange={handleAccordionChange("setupInstructions")}
            style={{
              marginBottom: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <ListItem alignItems="center">
                <ListItemIcon>
                  {" "}
                  <GreySquareIcon />
                </ListItemIcon>
                <Typography variant="subtitle1" style={{
                   display: "flex",
                   flexDirection: "column",
                   whiteSpace: "wrap"
                }}>Setup Instructions</Typography>
                <Typography
                  variant="body2"
                  style={{
                    display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  Configure your website information. This defines how your
                  website appears to your users.
                </Typography>
               
              </ListItem>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  Website ID
                </Typography>
                <div style={{

                }}>
                <TextField
                  id="websiteId"
                  variant="outlined"
                  style={{
                    marginLeft: "15px",
                    width: "75%"
                  }}
                  
                />
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "15px",
                    width: "15%"
                  }}
                >
                  Copy
                </Button>
                </div>
                  <span style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start"
                  }}>
                <Typography style={{ display: "flex", alignItems: "center" }}>
                  ChatBox
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      marginLeft: "15px",
                    }}
                  >
                    <img
                      src={`/Public/${"Advertisement.png"}`}
                      alt="advertisement"
                      style={{
                        width: "2vw",
                      }}
                    />
                    ChatBot Setup instructions
                  </Button>
                </Typography>
                </span>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                ></div>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={accordionExpanded.setupInstructions}
            onChange={handleAccordionChange("operatorInstructions")}
            style={{
              marginBottom: "10px",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <ListItem alignItems="center">
                <ListItemIcon>
                  
                  <GreySquareIcon />
                </ListItemIcon>
                <Typography variant="subtitle1">
                  Operators in the Team
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Add people that will be able to handle support for the
                  website.
                </Typography>
                
              </ListItem>
            </AccordionSummary>
            <AccordionDetails></AccordionDetails>
          </Accordion>

          </div>

        </div>
    </div>


    </div>
  )
}

export default WebSettings


