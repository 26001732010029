import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, IconButton, MenuItem, Select, Stack } from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import { CloseButton } from '../../../components/CloseButton';
import { deleteStage } from '../../../webservices/PipelineService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useState } from 'react';

export default function DeleteStage(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(true);
    const [selectedStage, setSelectedStage] = React.useState("");
    const [filteredStages, setFilteredStages] = useState([]);

    const data = queryClient.getQueryData(["getAllPipelines"]);

    useEffect(() => {

        const currentSubPipeline = data.pipelines.find(pipeline => pipeline._id === props.teamPipelineId)?.subPipelines.find(subPipeline => subPipeline._id === props.subPipelineId);
        setFilteredStages(currentSubPipeline.openStages.concat(currentSubPipeline.closedStages).filter(stage => stage._id !== props.stage._id));

    }, [data]);

    const { mutateAsync } = useMutation({
        mutationKey: "deleteStage",
        mutationFn: () => deleteStage(props.stage?._id, {
            pipelineId: props.teamPipelineId,
            subPipelineId: props.subPipelineId,
            transferToStage: selectedStage,
            isOpenStage: props.stage?.isOpenStage,
            createdBy: "User 1"
        }),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    }

    const handleDelete = () => {
        mutateAsync();
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Remove Stage from Sub-Pipeline
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ pt: 2 }}>
                        Are you sure you want to remove the stage <b>{props.stage?.name}</b> from this sub-pipeline?
                    </Typography>
                    <Typography component="div" variant="body" sx={{ pt: 2, pb: 2 }}>
                        Before deleting, we'll check if there are any Deals associated with this stage. In that case we'll transfer those Deals to another stage you choose from the below drop-down.
                    </Typography>

                    <FormControl size="small" fullWidth>
                        <Select
                            value={selectedStage}
                            onChange={(event) => setSelectedStage(event.target.value)}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>-None-</MenuItem>
                            {
                                filteredStages.map(stage => {
                                    return <MenuItem value={stage._id}>{stage.stageName}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="error" disabled={selectedStage === ""}
                            onClick={handleDelete}>Delete Stage</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}