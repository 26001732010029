import { Button, Divider, Paper, Typography } from "@mui/material";
import { TextField, Stack, IconButton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { api_url1 } from "../../../globalSettings.js";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useRecoilState } from "recoil";
import { selecteddomainn } from "../../../webservicesatoms/webserviceatom.js";
import Globe from "../../../images/DomainDetails/Globe.png";
import TransferIcon from "../../../images/DomainDetails/Transfer.png";
import { fetchDomainRenewalPrice } from "./Api/DomainApi.js";
import "../Style/domaindetails.css";
import "../../../styles/weservices.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DnsRecords from "./Components/DnsTable/index.js";
import NameServer from "./Components/NameServer.js";
import ChildNameServer from "./Components/ChildNameServer.js";
import DomainDetailsDialog from "./Components/DomainDetailsDialog.js";
import RenewModal from "./Components/Renewal/index.js";
import CustomSnackbar from "../Component/CustomSnackbar.js";
import TheftProtection from "./Components/TheftProtection.js";
import TopBar from "../Component/TopBar.js";
import ErrorInfo from "../Component/ErrorInfo.js";
import ContactIcon from "../../../images/DomainDetails/Contact.png";
import CircularProgress from "@mui/material/CircularProgress";

import {
  customContactData,
  customNameSereverTextStyle,
  customNameServer,
  customOutlineButtonStyle,
  contactDetailsNumber,
  contactTitle,
} from "../Style/DomainStyles.js";
import "./Style/domaindetails.css";
import TransferToken from "./Components/TransferToken.js";

import domainIcon from "../../../images/DomainDetails/DNS.png";
import RenewalError from "../Component/RenewalError.js";

const ManageDomain = ({ filterDomain,uniqueId }) => {
  const [Selecteddomain, setSelecteddomain] = useRecoilState(selecteddomainn);
  const [loadButton, setLoadButton] = useState(false);

  const [nameserversdialog, setNameserversdialog] = useState(false);
  function nameserverdialogonclose() {
    setNameserversdialog(false);
  }
  const [childserverupdatedialog, setChildserverupdatedialog] = useState(false);
  const [theftprotectionupdatedialog, settheftprotectionupdatedialog] =
    useState(false);
  const [transferTokendialog, settransferTokendialog] = useState(false);

  const [apipreloader, setapipreloader] = useState(false);
  const [TheftStatus, setTheftStatus] = useState(false);

  const [whoisinfoopen, setWhoisinfoopen] = useState(false);
  function handleWhoisClose() {
    setWhoisinfoopen(false);
  }
  const [DnsOpen, setDnsOpen] = useState(false);
  const openDnsRecords = () => {
    setDnsOpen(true);
  };

  const closeDnsRecords = () => {
    setDnsOpen(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    fetchDomainRenewalPrice(
      filterDomain,
      setLoadButton,
      setRenewalPrice,
      setOpen
    );
  };
  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "calc(100vh - 85px)",
    bgcolor: "white",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
  };

  const [myNameServer, setNameServer] = useState([" "]);
  const [childServer, setChildServer] = useState([
    { name: "", ip: "", slno: "" },
  ]);

  // ============================= ChildNameServer =====================================

  function childserverupdatedialogonclose() {
    setChildserverupdatedialog(false);
  }

  function theftprotectiondialogonclose() {
    settheftprotectionupdatedialog(false);
  }

  function transferTokendialogonclose() {
    settransferTokendialog(false);
  }

  //=====================================================================================================================================//
  const [domainData, setDomainData] = useState();
  const [renewalDomainPrice, setRenewalPrice] = useState({});

  const registrationPeriod = [
    { id: 1, label: "1 year" },
    { id: 2, label: "2 years" },
    { id: 3, label: "3 years" },
    { id: 4, label: "4 years" },
    { id: 5, label: "5 years" },
    { id: 6, label: "6 years" },
    { id: 7, label: "7 years" },
    { id: 8, label: "8 years" },
    { id: 9, label: "9 years" },
    { id: 10, label: "10 years" },
  ];

  /*================================= GET DOMAIN DETAILS AND KEY ==================================================*/
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleSnackbarClose = (event, reason) => {
    setSnackbarInfo((prevInfo) => ({
      ...prevInfo,
      open: false,
    }));
  };
  const [showData, setShowData] = useState(false);

  const handleToggleVisibility = () => {
    setShowData(!showData);
  };

  // ================================= GET DOMAIN DETAILS ===================================
  const fetchDomainDetails = () => {
    return axios
      .post(`${api_url1}/resellers/getDetailsByDomain`, {
        domain_name: Selecteddomain?.domainName,
      })
      .then((result) => {
        setTheftStatus(result.data.theftprotection);
        fetchactions(result.data.orderid);
        setDomainData(result.data);
        if (result.data.nameservers.length) {
          setNameServer(result.data.nameservers);
        } else {
          setNameServer([" ", " "]);
        }
        if (result.data.childnameservers.length) {
          setChildServer(result.data.childnameservers);
        } else {
          setChildServer([{ name: "", ip: "", slno: "" }]);
        }
        return result.data;
      });
  };

  const { data: domainDetails, isLoading: isDomainLoading } = useQuery({
    queryKey: ["domainDetails", Selecteddomain?.domainName],
    queryFn: fetchDomainDetails,
    enabled: Selecteddomain?.domainEnabled,
    select: (data) => data,
  });

  const dataToDisplay = showData ? domainData?.domsecret : "••••••••••••";

  const [actionstatus1, setactionstatus1] = useState([]);

  function handleTheftProtection() {
    if (TheftStatus) {
      axios
        .post(`${api_url1}/resellers/disableTheftProtection`, {
          order_id: domainDetails?.orderid,
        })
        .then((result) => {
          settheftprotectionupdatedialog(false);
          setapipreloader(!apipreloader);
          setTheftStatus(false);
          fetchactions(domainDetails?.orderid);
        })
        .catch((err) => {
          setapipreloader(!apipreloader);
          setSnackbarInfo({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        });
    } else {
      axios
        .post(`${api_url1}/resellers/enableTheftProtection`, {
          order_id: domainDetails?.orderid,
        })
        .then((result) => {
          setTheftStatus(true);
          fetchactions(domainDetails?.orderid);
          settheftprotectionupdatedialog(false);
          setapipreloader(!apipreloader);
        })
        .catch((err) => {
          setSnackbarInfo({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        });
    }
  }

  function fetchactions(orderid) {
    axios
      .get(
        `${api_url1}/resellers/getactions?orderid=${orderid}&no_of_records=10&page_no=1&action_status=&action_type=`
      )
      .then((result) => {
        if (result) {
          const filteredData = result.data.filter(
            (item) =>
              item.actionstatus !== "Success" && item.actionstatus !== "Failed"
          );

          setactionstatus1(filteredData);
        }
      })
      .catch((err) => {});
  }

  const isSuspended =
    domainDetails?.currentstatus.toLowerCase() === "suspend" ? true : false;
 
  return (
    <>
      <TopBar
        imgSrc={Globe}
        domainname={domainDetails?.domainname}
        orderid={domainDetails?.orderid}
        purchasedDate={domainDetails?.purchasedDate}
        expiryDate={domainDetails?.expiryDate}
        domainEnabled={Selecteddomain?.domainEnabled}
        handleClick={handleClick}
        loadButton={loadButton}
        isDisable={false}
        isLoading={isDomainLoading}
      />
      {isDomainLoading ? "" : <ErrorInfo actionstatus1={actionstatus1} />}

      {whoisinfoopen && (
        <DomainDetailsDialog
          whoisinfoopen={whoisinfoopen}
          setWhoisinfoopen={setWhoisinfoopen}
          domainDetails={domainData}
          setDomainData={setDomainData}
          handleWhoisClose={handleWhoisClose}
          setSnackbarInfo={setSnackbarInfo}
        />
      )}
      <div style={{ display: "grid" }}>
        {isSuspended && (
          <RenewalError
            height={"476px"}
            overlap={"-476px"}
            handleClick={handleClick}
            loadButton={loadButton}
            type={"Domain"}
          />
        )}
        {isDomainLoading ? (
          <div className="progress_domain">
            <CircularProgress />
          </div>
        ) : (
          <Paper>
            <Stack
              direction={"row"}
              spacing={4}
              padding={"20px 25px"}
              justifyContent="space-between"
              flexWrap={"wrap"}
            >
              <Stack direction="row" paddingBottom={5} spacing={1}>
                <div>
                  <img
                    alt="DomainIcon"
                    src={domainIcon}
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>

                <div>
                  <Typography
                    variant="body2"
                    style={{
                      paddingBottom: 10,
                      ...customNameSereverTextStyle,
                    }}
                  >
                    <b>NAME SERVER & DNS</b>
                  </Typography>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setNameserversdialog(true)}
                    style={customOutlineButtonStyle}
                  >
                    name server
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      setDnsOpen(true);
                    }}
                    style={customOutlineButtonStyle}
                  >
                    dns records
                  </Button>
                  {DnsOpen && (
                    <DnsRecords
                      drawerOpen={DnsOpen}
                      openDnsRecords={openDnsRecords}
                      closeDnsRecords={closeDnsRecords}
                      Selecteddomain={Selecteddomain}
                    />
                  )}
                </div>
              </Stack>

              <div>
                <Typography
                  variant="body2"
                  style={{
                    paddingBottom: 10,
                    ...customNameSereverTextStyle,
                  }}
                >
                  <b>NAME SERVERS</b>
                </Typography>
                {domainDetails?.nameservers?.map((nameServers) => (
                  <Typography style={customNameServer}>
                    {nameServers}
                  </Typography>
                ))}
              </div>

              <div>
                <Typography
                  variant="body2"
                  style={{
                    paddingBottom: 10,
                    ...customNameSereverTextStyle,
                  }}
                >
                  <b>CHILD NAME SERVER</b>
                </Typography>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setChildserverupdatedialog(true)}
                  style={customOutlineButtonStyle}
                >
                  Update
                </Button>
              </div>
            </Stack>

            <Divider />

            <div className="contact-details-web" style={{ paddingBottom: 60 }}>
              <Typography variant="body1" style={{ paddingBottom: 10 }}>
                <img src={ContactIcon} alt="contactIcon" />
                <b style={customNameSereverTextStyle}> Contact details</b>
              </Typography>
              <Stack
                direction={"row"}
                spacing={2}
                paddingLeft={1}
                justifyContent="space-between"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <div>
                  <Typography variant="body2" style={contactTitle}>
                    <b>Admin</b>
                  </Typography>
                  <Typography variant="body1" style={customContactData}>
                    {domainDetails ? domainDetails?.admincontact?.name : ""}
                  </Typography>
                  <Typography variant="body2" style={customContactData}>
                    {domainDetails ? domainDetails.admincontact?.company : ""}
                  </Typography>
                  <div style={contactDetailsNumber}>
                    <Typography variant="body1">
                      {domainDetails ? domainDetails?.admincontact?.telno : ""}
                    </Typography>
                    <Typography variant="body2">
                      {domainDetails
                        ? domainDetails.admincontact?.emailaddr
                        : ""}
                    </Typography>
                  </div>
                </div>

                <div>
                  <Typography variant="body2" style={contactTitle}>
                    <b>Billing</b>
                  </Typography>
                  <Typography variant="body1" style={customContactData}>
                    {domainDetails ? domainDetails.billingcontact?.name : ""}
                  </Typography>
                  <Typography variant="body2" style={customContactData}>
                    {domainDetails ? domainDetails.billingcontact?.company : ""}
                  </Typography>
                  <div style={contactDetailsNumber}>
                    <Typography variant="body1">
                      {domainDetails ? domainDetails?.admincontact?.telno : ""}
                    </Typography>
                    <Typography variant="body2">
                      {domainDetails
                        ? domainDetails.admincontact?.emailaddr
                        : ""}
                    </Typography>
                  </div>
                  <div></div>
                </div>

                <div>
                  <Typography variant="body2" style={contactTitle}>
                    <b>Registrant</b>
                  </Typography>
                  <Typography variant="body1" style={customContactData}>
                    {domainDetails
                      ? domainDetails?.registrantcontact?.name
                      : ""}
                  </Typography>
                  <Typography variant="body2" style={customContactData}>
                    {domainDetails
                      ? domainDetails.registrantcontact?.company
                      : ""}
                  </Typography>
                  <div style={contactDetailsNumber}>
                    <Typography variant="body1">
                      {domainDetails ? domainDetails?.admincontact?.telno : ""}
                    </Typography>
                    <Typography variant="body2">
                      {domainDetails
                        ? domainDetails.admincontact?.emailaddr
                        : ""}
                    </Typography>
                  </div>
                </div>

                <div>
                  <Button
                    color="primary"
                    onClick={() => setWhoisinfoopen(true)}
                    style={customOutlineButtonStyle}
                  >
                    View details
                  </Button>
                </div>
              </Stack>
            </div>

            <Divider />

            <div className="contact-details-web">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={TransferIcon} alt="transfer" />{" "}
                    <Typography
                      style={{
                        paddingLeft: "5px",
                        textTransform: "uppercase",
                        fontSize: "15px",
                        fontFamily: "Inter",
                        color: "000000",
                        fontWeight: "500",
                      }}
                    >
                      Transfer code
                    </Typography>
                  </div>

                  <TextField
                    style={{ paddingTop: "6px" }}
                    size="small"
                    value={dataToDisplay}
                    inputProps={{ readOnly: true }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleToggleVisibility}
                          edge="start"
                        >
                          {showData ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </div>

                <div>
                  <Typography
                    style={{
                      textTransform: "uppercase",
                      fontSize: "15px",
                      fontFamily: "Inter",
                      color: "000000",
                      fontWeight: "500",
                    }}
                  >
                    Theft protection
                  </Typography>

                  <FormControlLabel
                    labelPlacement="top"
                    style={{ cursor: "pointer" }}
                    onClick={() => settheftprotectionupdatedialog(true)}
                    control={
                      <div
                        class="form-check form-switch form-switch-sm hover-pointer"
                        style={{
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          style={{ cursor: "pointer" }}
                          checked={
                            TheftStatus //domainDetails?.theftprotection || false //TheftStatus
                          }
                          onChange={() => {
                            settheftprotectionupdatedialog(true);
                          }}
                        />
                        <label
                          class="form-check-label"
                          for="flexSwitchCheckDefault"
                        ></label>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </Paper>
        )}
      </div>

      <RenewModal
        open={open}
        handleClose={handleClose}
        style={style}
        registrationPeriod={registrationPeriod}
        renewalDomainPrice={renewalDomainPrice}
        Selecteddomain={filterDomain.domainName}
        id={filterDomain._id}
        setSnackbarInfo={setSnackbarInfo}
      />

      {nameserversdialog && (
        <NameServer
          nameserversdialog={nameserversdialog}
          nameserverdialogonclose={nameserverdialogonclose}
          myNameServer={myNameServer}
          order_id={domainDetails?.orderid}
          Selecteddomain={Selecteddomain.domainName}
          setSnackbarInfo={setSnackbarInfo}
          apipreloader={apipreloader}
          setapipreloader={setapipreloader}
        />
      )}
      {childserverupdatedialog ? (
        <ChildNameServer
          childserverupdatedialog={childserverupdatedialog}
          childserverupdatedialogonclose={childserverupdatedialogonclose}
          childServer={childServer}
          apipreloader={apipreloader}
          domainDetails={domainDetails}
          Selecteddomain={Selecteddomain}
          setSnackbarInfo={setSnackbarInfo}
          fetchDomainDetails={fetchDomainDetails}
        />
      ) : (
        ""
      )}

      {theftprotectionupdatedialog ? (
        <TheftProtection
          theftprotectionupdatedialog={theftprotectionupdatedialog}
          theftprotectiondialogonclose={theftprotectiondialogonclose}
          handleTheftProtection={handleTheftProtection}
          domainDetails={domainDetails}
          status={TheftStatus}
        />
      ) : (
        ""
      )}

      {transferTokendialog ? (
        <TransferToken
          transferTokendialog={transferTokendialog}
          transferTokendialogonclose={transferTokendialogonclose}
        />
      ) : (
        ""
      )}

      <CustomSnackbar
        snackbarInfo={snackbarInfo}
        handleSnackbarClose={handleSnackbarClose}
      />
    </>
  );
};

export default ManageDomain;
