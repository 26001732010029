import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import variables from "../../../assets/styles/_colors.scss";
import { useState } from "react";
import UpsertDeal from "../../pipelines/create/UpsertDeal";
import { DataGrid } from "@mui/x-data-grid";
import DeleteDeal from "../../pipelines/delete/DeleteDeal";
import { getGiveStageDataListView, getTableColumns } from "../../../webservices/PipelineService";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect } from "react";

const PipelinesTab = (props) => {

    const userPipelines = [
        {
            id: "1",
            name: "Test Pipeline",
            count: 5
        },
        {
            id: "2",
            name: "Sales Pipeline",
            count: 0
        },
        {
            id: "3",
            name: "Sales Test Pipeline",
            count: 1
        }
    ]
    const [selectedTab, setSelectedTab] = useState(userPipelines[0].id);
    const [selectedModule, setSelectedModule] = useState(null);
    const [createDeal, setCreateDeal] = useState(false);


    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [editDeal, setEditDeal] = useState(false);
    const [deleteDeal, setDeleteDeal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const transformColumns = (cols) => {
        let obj = [];
        cols.map((col, index) => {
            col.selected &&
                obj.push({
                    field: col.accessorKey,
                    headerName: col.header,
                    width: 150,
                    cellClassName: index === 0 && ((params) => {
                        return 'firstColumn'
                    })
                })
        });
        obj.push({
            field: "actions",
            headerName: "",
            sortable: false,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            backgroundColor: "whitesmoke",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <IconButton onClick={() => { setSelectedRow(params.row); setEditDeal(true) }}>
                            <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                        </IconButton>
                        <IconButton onClick={() => { setSelectedRow(params.row); setDeleteDeal(true) }}>
                            <DeleteOutlineIcon fontSize='medium' color="error" />
                        </IconButton>
                    </Box>
                );
            }
        })
        setColumns(obj);
    }

    useEffect(() => {
        getTableColumns().then((colResponse) => {
            transformColumns(colResponse.columns);
            if (selectedTab === "1") {
                getGiveStageDataListView(0, 10).then((response) => {
                    setRows(response.rows);
                });
            } else {
                setRows([]);
            }
        });
    }, [selectedTab]);

    return (
        <>
            <Stack direction="row" alignItems="center" width="100%" sx={{ p: 1, pl: 0, pr: 2 }}>
                <Stack direction="row" width="90%" sx={{ ml: 2 }}>
                    <Stack direction="row" justifyContent="left" width='auto' sx={{ cursor: "pointer", borderRadius: 24, border: `1px solid ${variables.borderColor}` }}>
                        {
                            userPipelines.map((pipeline, index) => {
                                return <Box onClick={() => setSelectedTab(pipeline.id)}
                                    sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === pipeline.id ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                                    <Typography component="span" sx={{ lineHeight: 1.2 }}>
                                        {pipeline.name}
                                    </Typography>
                                    <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === pipeline.id ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>
                                        {pipeline.count}
                                    </Typography>
                                </Box>
                            })
                        }
                    </Stack>
                </Stack >
                <Stack direction="row" justifyContent="right" width="10%" spacing={2}>
                    <Button variant="outlined" onClick={() => setCreateDeal(true)}><AddIcon /> Deal </Button>
                </Stack>
            </Stack >
            <Box sx={{ height: '73.5vh', width: '100%' }}>
                <DataGrid
                    columnHeaderHeight={40}
                    rowHeight={48}
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row?._id}
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            fontSize: 14
                        },
                        "& .MuiDataGrid-cellContent": {
                            fontSize: 14
                        },
                        '& .firstColumn': {
                            fontWeight: 'bold'
                        }
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                        },
                    }}
                    slots={{
                        noRowsOverlay: () => (
                            <Stack alignItems="center" justifyContent="center" sx={{ pt: 3, fontSize: 13.5 }}>
                                This record doesn't have any deals.
                            </Stack>
                        )
                    }}
                    pageSizeOptions={[100, 200]}
                    disableRowSelectionOnClick
                    disableColumnMenu
                />
            </Box>
            {deleteDeal && <DeleteDeal item={{ name: selectedRow?.id }} handleClose={() => { setDeleteDeal(false); }} />}
            {editDeal && <UpsertDeal edit={true} handleDrawerClose={() => { setEditDeal(false); }} />}
            {createDeal && <UpsertDeal module={selectedModule} handleDrawerClose={() => setSelectedModule(null)} />}
        </>
    )
}

export default PipelinesTab;