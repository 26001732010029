import * as React from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { api_url } from '../../globalSettings';
import { atom, useRecoilState } from 'recoil';


const rowss = atom({
  key : 'rowss',
  default : []
})


function Row(props) {
  const { row , taskdata} = props;
  const [open, setOpen] = React.useState(false);

  

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
        {row.first_name} {row.last_name}
        </TableCell>
        <TableCell >{row.email_id}</TableCell>
        <TableCell >{row.employee_company_id}</TableCell>
       </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Task
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Task Status</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  {taskdata?.map((task) => (
                    <TableRow>
                      <TableCell>{task.name}</TableCell>
                      <TableCell>{task.description}</TableCell>
                      <TableCell>{task.task_status}</TableCell>
                      <TableCell>{task.loc_name}</TableCell>
                    </TableRow>
                   ))} 
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}



function ManageHistory() {
  
  const { companyInfo } = useSelector((state) => state.manageCompany);
  const { userInfo } = useSelector((state) => state.login);
  const [data,setData] = React.useState([]);
  const [rows, setRows] = useRecoilState(rowss);
  const [taskdata,setTaskdata] = React.useState([]);

  React.useEffect(() => {
    getemployee();
   
    
  },[])

  


   //========================================== API CALL ================================================
   function getemployee(){
    let formdata = new FormData();
    var auth = localStorage.getItem('auth');

    formdata.append('token', JSON.parse(auth).data.token)
    formdata.append('action', "all_employee_list");
    formdata.append('company_id', companyInfo.company_id);
    formdata.append('user_id', userInfo.user_id);
    formdata.append('cmd', 1);

    axios.post(`${api_url}/web_tracking_employee.php`,formdata).then((data) => {
    
        setRows(data.data.data);
    }).catch((err) =>{
     
    })
    }

    React.useEffect(() => {
    rows.map((item) => {
    
    let formdata = new FormData();
    
    formdata.append('action','web_date_wise_list_task')
    formdata.append('cmd','1')
    formdata.append('company_id',companyInfo.company_id)
    formdata.append('emp_id',item.emp_id);
    formdata.append('from_date','15-09-2021');
    formdata.append('to_date','01-05-2022');

    axios.post(`${api_url}/web_add_task.php`,formdata).then((data) => {
    
      setTaskdata(state => [...state,data.data.data ? data.data.data : []])
    }).catch((err) => {
   
    })
    })
    },[rows])


  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell>Email&nbsp;(g)</TableCell>
            <TableCell>Employee ID&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,ind) => (
            <Row key={row.name} row={row} taskdata={taskdata[ind]} setData={setData}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default ManageHistory