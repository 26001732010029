import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { CircularProgress, Drawer, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import StagesForm from './StagesForm';
import validationSchema from './validationSchema';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { CloseButton } from '../../../components/CloseButton';
import variables from '../../../assets/styles/_colors.scss';
import CustomSnackbar from '../../../components/CustomSnackbar';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createSubPipeline } from '../../../webservices/PipelineService';
import { useEffect } from 'react';

const formId = "createSubPipelineForm";

export default function CreateSubPipeline(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = useState(true);
    const [data, setData] = useState(null);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const { mutateAsync, isPending } = useMutation({
        mutationKey: "createSubPipeline",
        mutationFn: (data) => createSubPipeline(props.pipelineId, data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    function handleDrawerClose(response) {
        props.handleDrawerClose(response);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    useEffect(() => {
        let dat = Object.assign({}, props.cloneData);
        if (props.cloneData) {
            dat.showSubPipeline = true;
            dat.openStages = dat?.openStages.map(obj => { return { label: obj.stageName, value: obj._id } });
            dat.closedStages = dat?.closedStages.map(obj => { return { label: obj.stageName, value: obj._id } });
        }
        setData(dat);
    }, [props.cloneData]);

    const currentValidationSchema = validationSchema[1];

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: data,
        resolver: yupResolver(currentValidationSchema),
        reValidateMode: "onChange",
        mode: "onChange"
    });

    const { handleSubmit, trigger } = methods;

    const handleNext = async (data) => {
        const isStepValid = await trigger();
        if (isStepValid) {
            // alert(JSON.stringify(data));
            data.createdBy = "User 1";
            mutateAsync(data);
        };
    };

    return (
        <React.Fragment>
            <Drawer anchor="right" open={open}
                PaperProps={{
                    sx: { minWidth: "35vw", maxWidth: "35vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}` },
                }}>
                <Typography variant="h5" sx={{ pl: 1.5 }}>{props.cloneData ? "Clone Sub-Pipeline" : "Create Sub-Pipeline"}
                    <CloseButton handleDialogClose={handleDrawerClose} />
                </Typography>
                <Box sx={{ width: '100%', pl: 0.5 }}>
                    <FormProvider {...methods}>
                        <form id={formId} onSubmit={handleSubmit(handleNext)}>
                            <StagesForm showSubPipeline={true} cloneData={data} />
                        </form>
                    </FormProvider>
                </Box>
                <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                    <Button variant="outlined" disabled={isPending} onClick={() => handleDrawerClose()}> Cancel </Button>
                    <Button
                        disabled={isPending}
                        type="submit"
                        form={formId}
                        variant="contained"
                        color="primary"
                    > Save
                        {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                    </Button>
                </Stack>
            </Drawer>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

        </React.Fragment>
    );
}
