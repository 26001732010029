import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { name: "Jan 2019", uv: 4 },
  { name: "Feb 2019", uv: 2 },
  { name: "Mar 2019", uv: 5 },
  { name: "Apr 2019" },
  { name: "May 2019", uv: 0 },
  { name: "Jun 2019", uv: 3 },
  { name: "July 2019", uv: 5 },
  { name: "Aug 2019" },
  { name: "Sep 2019", uv: 4 },
  { name: "Oct 2019", uv: 5 },
  { name: "Nov 2019", uv: 5 },
  { name: "Dec 2019", uv: 5 },
];
const GeneralRatingGraph = () => {
  return (
    <ResponsiveContainer height={"100%"} width={"100%"}>
      <LineChart
        width={1200}
        height={500}
        data={data}
        margin={{
          top: 100,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" stroke="0" padding={{ left: 20, right: 20 }} />
        <YAxis type="number" name="rating" unit="/5" stroke="0" padding={{ top: 20, bottom: 30 }} />
        <Tooltip />
        <Line
          connectNulls
          dataKey="uv"
          stroke="#31D631"
          strokeWidth={2}
          fill="#31D631"
          dot={{ stroke: "#31D631", strokeWidth: 4, fill: "#31D631" }}
        />{" "}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GeneralRatingGraph;
