import { Box, Divider, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import {CKEditor}  from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";
import { api_url } from "../../globalSettings";
import axios from "axios";
import downloadpng from "../../images/download.png"
import noTask from "../../images/CRM/plan_7404361.png";
import { profile_trigger, selected_contact } from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";

const CssBox = styled(Box)({
  backgroundColor: "white",
  marginBottom: "10",
  width: "100%",
  padding: " 10px",
  display: "flex",
  width: "100%",
  fontSize: "12px",
  flexDirection: "column",
  marginBottom:"10px"
});

const time = [
  { id: 1, day: "TODAY" },
  { id: 2, day: "YESTERDAY" },
  { id: 3, day: "THIS WEEK" },
  { id: 4, day: "LAST WEEK" },
  { id: 5, day: "THIS MONTH" },
  { id: 6, day: "LAST MONTH" },
  // { id: 7, day: "Sunday" },
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));



const ContactEmail = () => {
  const [selectedContact] = useRecoilState(selected_contact);
  const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
  const [selectedContactDetails, setSelectedContactDetails] =
  useState(selectedContact);

  // =========================  TIME API =============================
  const [dateId, setDateId] = React.useState("");

  const handleChange2 = (event) => {
    setDateId(event.target.value);
  };

  React.useEffect(() => {
    ClassicEditor
      .create(document.querySelector('#editor'))
      .then(editor => {
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  // ================= CUSTOMER LIST BY CUSTOMER ID =========================

  useEffect(() => {
    axios
      .get(
        `${api_url}/crm_customer/getCustomerByCustomerId/${selectedContact._id}`
      )
      .then((responce) => {
      
        setSelectedContactDetails(responce.data.data[0]);
      })
      .catch((err) => {
      });
  }, [selectedContact, profileTrigger]);

  return (
    <div style={{display:"flex" , flexDirection:"column" , width:"100%"}}>
    <CssBox>
      {" "}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginBottom: "5px",
        }}
      >
      </div>
      <Divider style={{ margin: "10px" }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              To
            </Grid>
            <Grid item xs={12} sm={8} md={10}>
              <FormControl
                sx={{ m: 1 }}
                variant="standard"
                style={{ width: "100%", marginLeft: "0" }}
                size="small"
              >
             
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={time[0].id}
                  onChange={handleChange2}
                  input={<BootstrapInput />}
                >
                  {time.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.day}</option>
                      </>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              Subject
            </Grid>
            <Grid item xs={12} sm={8} md={10}>
              <TextField
                fullWidth
                type="text"
                variant="outlined"
                size="small"
                placeholder="Enter Subject"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={2}
              display={"flex"}
              justifyContent={"flex-end"}
            >
            Main Content
            </Grid>
            <Grid item xs={12} sm={8} md={10}>
            <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: [
                        "heading",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "fontColor",
                        "fontBackgroundColor",
                        "code",
                        "uploadImage",
                        "link",
                        "sourceEditing"
                      ],

                  
                    }}
                    
                    data=""
                    onReady={(editor) => {
                      
                 
                    }}
                    onChange={(event, editor) => {

                

                    }}
                  />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4}></Grid>
      </Grid>
    </CssBox>
    <CssBox>
    <div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
        
        <Typography >MAILS</Typography>
        </div>
        <Divider style={{margin :"10px"}}/>
        {selectedContactDetails.send_mail.length > 0 ? 
        
        <div className="task-table-container scroll">
          <table className="task-table">
            <thead>
              <tr>
              {/* <th>ID</th> */}
                <th>Date</th>
                <th>From</th>
                <th>To</th>
                <th>File Name</th>
                <th>Subject</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
            
                selectedContactDetails.send_mail.length > 0 ?    selectedContactDetails.send_mail.map((mails) => (
                  <React.Fragment 
                   key={mails._id}
                  >
                    <tr
                  
                    >
                     
                     
                      <td style={{ width: "10%", fontSize: "12px" }}>
                      {new Date(mails.date)
                              .toLocaleDateString()
                              .split("/")
                              .join("-")}{" "}
                            {new Date(mails.date).toLocaleTimeString()}
                      </td>
                      <td style={{ width: "20%" }}>
                      {mails.from} KB
                      </td>
                      <td style={{ width: "20%" }}>
                      {mails.to}
                      </td>
                      <td style={{ width: "20%" }}>
                      {mails.image_name}
                      </td>
                      <td style={{ width: "20%" }}>
                      {mails.subject}
                      </td>
                      <td style={{ width: "10%" , display:"flex" , flexDirection:"row"}}>
                        <Tooltip title="Download File">
                      <div
                            style={{
                              borderRadius: 5,
                            }}
                         //   onClick={() => saveManual()}
                          >
      <a
        href={`${api_url}${mails.image_path}/${mails.image_name}`}
        onClick={() => axios.get(`${api_url}${mails.image_path}${mails.image_name}`)}
        target="_blank"
      >                        <img src={downloadpng} width={20} height={20} alt="delete" />
                            </a>
                          </div></Tooltip>
                     
                      </td>
                    </tr>
                  </React.Fragment>
                )) : ""
              } 
            </tbody>
          </table>
    
          <style>
            {`
        .task-table-container {
    display: flex;
    justify-content: flex-start;
    align-items:center;
    flex-direction : column;
    font-family: "Poppins",sans-serif;
    overflow: auto;
    }
    
    
    .task-table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    }
    .button{
    color: rgb(212, 211, 211) ;
    border: 1px solid #3367d6;
    background-color: #3367d6 ;
    padding: 5px 10px ;
    border-radius: 8px ;
    margin-bottom:10px;
    }
    
    .button:hover{
    color: rgb(212, 211, 211) ;
    border: 1px solid #3367d6;
    background-color: #3367d6 ;
    padding: 5px 10px ;
    border-radius: 8px ;
    margin-bottom:10px;
    }
    
    .task-table th,
    .task-table td {
    padding: 12px;
    text-align: left;
    }
    
    .task-table th {
    background-color: #f2f2f2;
    font-weight: 500;
    font-size : 12px
    }
    
    .task-table tr {
    background: #ffffff;
    margin-left: 0;
    box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
    color: #1c1c22;
    transition: background-color 0.2s ease;
    cursor:pointer;
    }
    
    .task-table tr:hover {
    background-color: #f5f5f5;
    }
    
    .task-row.active {
    background-color: #e1f5fe;
    cursor: pointer;
    }
    
    .add-subtask-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    transition: background-color 0.2s ease;
    margin:10px 0
    }
    
    .add-subtask-button:hover {
    background-color: #0056b3;
    }
    
    .sub-tasks-row td {
    padding: 0 12px;
    text-align: left;
    border: none;
    }
    
    
    .task-line {
    display: inline-block;
    width: 20px;
    position: relative;
    padding-left:10px;
    }
    
    .branch-line {
    position: absolute;
    top: 8px;
    left: -10px;
    height: calc(100% - 8px);
    width: 1px;
    background-color: #ccc;
    }
    
    .branch-line.expanded {
    background-color: #007bff;
    }
    .delete-icon {
    position: absolute;
    top: -8px;
    right: -8px;
    cursor: pointer;
    background-color: #00000024;
    color: white;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    }
    
    /* Add this to your CSS */
    .calendar-icon {
    position: relative;
    display: inline-block;
    font-size: 12px
    }
    
    .calendar-icon input {
    padding-right: 30px; /* Create space for the icon */
    }
    
    .calendar-icon .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    }
    /* Add this to your CSS */
    .calendar-icon {
    position: relative;
    display: inline-block;
    }
    
    .calendar-icon .icon {
    cursor: pointer;
    }
    /* Add this to your CSS */
    .calendar-dropdown {
    position: relative;
    display: inline-block;
    }
    
    .calendar-dropdown .icon {
    cursor: pointer;
    }
    
    .calendar-dropdown .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    }
    
    /* Add this to your CSS */
    .calendar-dropdown {
    position: relative;
    display: inline-block;
    }
    
    .calendar-icon {
    cursor: pointer;
    }
    
    .calendar-dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    }
    `}
          </style>
        </div>  : 
        <div className="task-table-container1">
              <img src={noTask} alt="loading" />
              <p style={{ margin: "20px", fontSize: "14px" }}>There is no Mails</p>
              <style>
                {" "}
                {`
            .task-table-container1 {
      display: flex;
      justify-content: center;
      align-items:center;
      height:auto;
      flex-direction : column;
      padding: 20px;
      font-family: "Poppins",sans-serif;
    }
    `}
              </style>
            </div> }
    </CssBox>
    </div>
  );
};

export default ContactEmail;
