export const action_contants = {
  //login
  SET_SIGNUP_PAGE: "SET_SIGNUP_PAGE",
  SET_SIGN_IN_FORM_DATA: "SET_SIGN_IN_FORM_DATA",
  SET_SIGN_UP_FORM_DATA: "SET_SIGN_UP_FORM_DATA",
  GET_USER_DATA: "GET_USER_DATA",
  GET_NEW_USER_DATA: "GET_NEW_USER_DATA",
  VERIFY_NEW_USER_OTP: "VERIFY_NEW_USER_OTP",
  GET_USER_INFO: "GET_USER_INFO",

  //common
  SHOW_TOAST_MESSAGE: "SHOW_TOAST_MESSAGE",
  SHOW_LOADER: "SHOW_LOADER",
  LOG_ERROR: "LOG_ERROR",

  //home
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_CURRENT_SETTING: "SET_CURRENT_SETTING",

  //manage company
  SET_COMPANY_INFO: "SET_COMPANY_INFO",
  SET_COMPANY_LIST: "SET_COMPANY_LIST",
  SET_SHOW_SELECTED_COMPANY: "SET_SHOW_SELECTED_COMPANY",
  SET_COMPANY_FORM_DATA: "SET_COMPANY_FORM_DATA",
  ADD_OR_EDIT_COMPANY: "ADD_OR_EDIT_COMPANY",

  SET_CURRENT_BRANCH: "SET_CURRENT_BRANCH",
  SET_BRANCH_LIST: "SET_BRANCH_LIST",
  ADD_OR_EDIT_BRANCH: "ADD_OR_EDIT_BRANCH",
  SET_BRANCH_FORM_DATA: "SET_BRANCH_FORM_DATA",

  SET_DEPARTMENT_LIST: "SET_DEPARTMENT_LIST",
  ADD_OR_EDIT_DEPARTMENT: "ADD_OR_EDIT_DEPARTMENT",
  SET_DEPARTMENT_FORM_DATA: "SET_DEPARTMENT_FORM_DATA",

  SET_DESIGNATION_LIST: "SET_DESIGNATION_LIST",
  ADD_OR_EDIT_DESIGNATION: "ADD_OR_EDIT_DESIGNATION",
  SET_DESIGNATION_FORM_DATA: "SET_DESIGNATION_FORM_DATA",

  SET_TASK_LIST: "SET_TASK_LIST",
  ADD_OR_EDIT_TASK: "ADD_OR_EDIT_TASK",
  SET_TASK_FORM_DATA: "SET_TASK_FORM_DATA",

  SET_EXPENSE_LIST: "SET_EXPENSE_LIST",
  ADD_OR_EDIT_EXPENSE: "ADD_OR_EDIT_EXPENSE",
  SET_EXPENSE_FORM_DATA: "SET_EXPENSE_FORM_DATA",

  // manage  employee
  SET_EMPLOYEE_LIST: "SET_EMPLOYEE_LIST",
};
