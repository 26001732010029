import { Avatar, Box, Button, Drawer, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import variables from "../../../assets/styles/_colors.scss";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from '@mui/icons-material/Person';
import DeleteContact from "../DeleteContact";
import UpsertContact from "../create/UpsertContact";
import { Notes } from "../../../components/notes/Notes";
import { ContactInfo } from "./ContactInfo";
import { stringAvatar } from "../../../utils/PipelineConstants";

const StyledIcon = withStyles({
    root: {
        height: "32px",
        width: "32px",
        backgroundColor: `${variables.iconButtonBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.iconButtonBackgroundHoverColor} !important`
        }
    }
})(IconButton);

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important'
    }
})(Tab);

const ContactQuickPreview = (props) => {

    const [open, setOpen] = useState(true);
    const [editContact, setEditContact] = useState(false);
    const [deleteContact, setDeleteContact] = useState(false);
    const [selectedTab, setSelectedTab] = useState("0");

    function handleDrawerClose() {
        setOpen(false);
        props.handleDrawerClose();
    }

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
    }

    return (
        <><Drawer anchor="right" open={open}
            PaperProps={{
                sx: {
                    minWidth: "35vw", maxWidth: "35vw", borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                },
            }}>
            <Box sx={{ height: "100vh" }}>
                <Box sx={{ bgcolor: `${variables.lightGreyBackgroundColor}`, pt: "20px", pb: 2 }}>
                    <Stack direction="row" width="100%" alignItems="top" sx={{ pl: 2, pr: 1.25 }}>
                        <Stack direction="column" width="60%" justifyContent="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Stack direction="column">
                                    {props.item?.contactName && <Avatar {...stringAvatar(`${props.item?.contactName}`)} />}
                                </Stack>
                                <Stack direction="column">
                                    <Stack direction="row" sx={{ cursor: 'pointer', color: `${variables.linkColor}`, fontWeight: 'bold' }}>
                                        {props.item?.contactName}
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {props.item?.companyName && <Typography sx={{ cursor: 'pointer', color: `${variables.linkColor}`, fontWeight: 'bold' }}>{props.item?.companyName}</Typography>}
                                        <Typography sx={{ color: `${variables.greyTextColor}` }}>
                                            <PersonIcon size="small" sx={{ width: 16, height: 16, mt: -0.25 }} />
                                            <Typography component="span" sx={{ pl: 0.5, fontSize: "12px" }}>{props.item?.contactOwner}</Typography>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction="row" width="40%" justifyContent="right" spacing={1}>
                            <StyledIcon onClick={() => setEditContact(true)}>
                                <EditOutlinedIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                            <StyledIcon onClick={() => setDeleteContact(true)}>
                                <DeleteOutlineIcon fontSize='medium' color="error" />
                            </StyledIcon>
                            <StyledIcon onClick={() => handleDrawerClose()}>
                                <CloseIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 2, pt: 2 }}>
                        <Button variant="contained" disabled={!props.item?.email || props.item?.email == ""}> Send Mail </Button>
                    </Stack>
                </Box>

                <Box sx={{ overflowY: "auto", overflowX: "hidden", pl: 1, height: "80vh" }}>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        <StyledTab label="Information" value="0" />
                        <StyledTab label="Notes" value="1" />
                    </Tabs>
                    {
                        <>
                            {selectedTab === "0" &&
                                <ContactInfo item={props.item} />
                            }

                            {selectedTab === "1" &&
                                <Notes />
                            }
                        </>
                    }
                </Box>

                {deleteContact &&
                    <DeleteContact item={props.item} module={props.module} handleClose={() => { setDeleteContact(false) }} />}

                {editContact &&
                    <UpsertContact edit={true} editData={props.item} module={props.module} handleDrawerClose={() => { setEditContact(false) }} />}
            </Box>
        </Drawer >
        </>
    );
};

export default ContactQuickPreview;