import moment from "moment";
import { differenceInMinutes } from "date-fns";

export const gstCalculation = (totalAmount, user) => {
  if (user.country.toUpperCase() === "INDIA") {
    if (user.state.toUpperCase() === "TAMIL NADU") {
      const percent = 9;
      const cgstAmt = (percent / 100) * totalAmount;
      const sgstAmt = (percent / 100) * totalAmount;

      const cgst = {
        Amt: Math.ceil(cgstAmt),
        percentage: percent,
      };

      const sgst = {
        Amt: Math.round(sgstAmt),
        percentage: percent,
      };

      const fullAmount = Math.round(cgstAmt + sgstAmt + totalAmount);

      return { gst: cgst.Amt + sgst.Amt, fullAmount };
    } else {
      const percent = 18;
      const igstAmt = (percent / 100) * totalAmount;

      const igst = {
        Amt: igstAmt,
        percentage: percent,
      };

      const fullAmount = totalAmount + igstAmt;

      return { gst: igst, fullAmount };
    }
  } else {
    const fullAmount = totalAmount;
    return {
      fullAmount,
    };
  }
};

export const formatDateWithOrdinal = (date) => {
  const dayOfMonth = moment(date).format("Do");
  const formattedDate = moment(date).format(`[${dayOfMonth}] MMM YYYY`);
  return formattedDate;
};


export const getOfferPrices = (
  gsuites,
  quantity,
  gsuite_userData,
  serviceType
) => {
  const seats =
    gsuite_userData.planName === "Annually" ||
    gsuite_userData.planName === "Biannually" ||
    gsuite_userData.planName === "Annual-Monthly" ||
    gsuite_userData.planName === "Triannually"
      ? gsuite_userData.numberOfSeats
      : gsuite_userData.maximumNumberOfSeats;

  let initialMilliseconds = 365 * 24 * 60 * 60 * 1000;

  const yearCalculate = gsuites.noOfYears * initialMilliseconds;


  if (serviceType === "new-liscence") {
   
    if (
      new Date().getTime() - new Date(gsuite_userData.purchaseDate).getTime() <=
      yearCalculate
    ) {
      console.log("new-liscence");

      if (quantity + seats <= gsuites.offerForUsers) {
        console.log("entered to first quantity condition");
        return {
          price: quantity * gsuites.offerPrice,
          singleOfferprice: gsuites.offerPrice,
          offerUser: quantity,
          defaultPrice: 0,
          defaultUser: 0,
        };
      }
      // } else if (quantity + seats >= gsuites.offerForUsers) {
      //   console.log("entered 2");
      //   const calculateUser = quantity + seats - gsuites.offerForUsers;
      //   console.log("calculateUser",calculateUser)
      //   const defaultPrice = calculateUser * gsuites.defaultPrice;
      //   console.log("defaultUser",defaultPrice)
      //   const offerPrice = gsuites.offerForUsers * gsuites.offerPrice;
      //   console.log("offerPrice",offerPrice)
      //   const overallPrice = defaultPrice + offerPrice;
      //   console.log("overallPrice",overallPrice)
      //   return {
      //     price: overallPrice,
      //     price:quantity * gsuites.offerPrice,
      //     singleOfferprice: gsuites.offerPrice,
      //     offerUser: gsuites.offerForUsers,
      //     defaultPrice: gsuites.defaultPrice,
      //     defaultUser: calculateUser,
      //   };
      // }
      else {
        return {
          price: gsuites.defaultPrice * quantity,
          singleOfferprice: 0,
          offerUser: 0,
          defaultPrice: gsuites.defaultPrice,
          defaultUser: quantity,
        };
      }
    } else {
      console.log("entered 3");
      console.log("entered else");
      return {
        price: gsuites.defaultPrice * quantity,
        singleOfferprice: 0,
        offerUser: 0,
        defaultPrice: gsuites.defaultPrice,
        defaultUser: quantity,
      };
    }
  } else if (serviceType === "renew") {
    if (
      new Date(gsuite_userData.expiryDate) -
        new Date(gsuite_userData.purchaseDate) <=
      yearCalculate
    ) {
      console.log("renew");
      if (quantity <= gsuites.offerForUsers) {
        console.log("entered to maximum condition");
        return {
          price: quantity * gsuites.offerPrice,
          singleOfferprice: gsuites.offerPrice,
          offerUser: quantity,
          defaultPrice: 0,
          defaultUser: 0,
        };
      } else if (quantity >= gsuites.offerForUsers) {
        console.log("entered 2");
        const calculateUser = quantity - gsuites.offerForUsers;
        const defaultPrice = calculateUser * gsuites.defaultPrice;
        const offerPrice = gsuites.offerForUsers * gsuites.offerPrice;
        const overallPrice = defaultPrice + offerPrice;
        return {
          price: overallPrice,
          singleOfferprice: gsuites.offerPrice,
          offerUser: gsuites.offerForUsers,
          defaultPrice: gsuites.defaultPrice,
          defaultUser: calculateUser,
        };
      }
    } else {
      console.log("entered else");
      console.log("z");
      return {
        price: gsuites.defaultPrice * quantity,
        singleOfferprice: 0,
        offerUser: 0,
        defaultPrice: gsuites.defaultPrice,
        defaultUser: quantity,
      };
    }
  }
  // }
};

export const calculateAmountNew = (
  gsuites,
  period,
  serviceType,
  quantity,
  gsuiteDatas,
  skuId
) => {
  if (period === "Flexible-Monthly") {
    const gsuitePrice = getOfferPrices(
      gsuites,
      quantity,
      gsuiteDatas,
      serviceType,
      skuId
    );
    let defaultPrice = gsuitePrice.price / 30;

    const currentDate = new Date();

    const providedDate = new Date(gsuiteDatas.expiryDate);

    if (serviceType === "renew") {
      return gsuitePrice.price;
    } else if (serviceType === "new-liscence") {
      const timeDiff = differenceInMinutes(providedDate, currentDate);
      const dateDiff = Math.ceil(timeDiff / (24 * 60));
      console.log('====================================');
      console.log("consoleeee",timeDiff,dateDiff,Math.round(defaultPrice * dateDiff));
      console.log('====================================');
      return Math.round(defaultPrice * dateDiff);
    }
  } else if (period === "Flexible-Quaterly") {
    const gsuitePrice = getOfferPrices(
      gsuites,
      quantity,
      gsuiteDatas,
      serviceType,
      skuId
    );

    let defaultPrice = gsuitePrice.price / 120;

    const currentDate = new Date();

    const providedDate = new Date(gsuiteDatas.expiryDate);

    if (serviceType === "renew") {
      return gsuitePrice.price;
    } else if (serviceType === "new-liscence") {
      const timeDiff = differenceInMinutes(providedDate, currentDate);
      const dateDiff = Math.ceil(timeDiff / (24 * 60));
      return Math.round(defaultPrice * dateDiff);
    }
  } else if (period === "Flexible-HalfYearly") {
    const gsuitePrice = getOfferPrices(
      gsuites,
      quantity,
      gsuiteDatas,
      serviceType,
      skuId
    );

    let defaultPrice = gsuitePrice.price / 180;

    const currentDate = new Date();

    const providedDate = new Date(gsuiteDatas.expiryDate);

    if (serviceType === "renew") {
      return gsuitePrice.price;
    } else if (serviceType === "new-liscence") {
      const timeDiff = differenceInMinutes(providedDate, currentDate);
      const dateDiff = Math.ceil(timeDiff / (24 * 60));
      return Math.round(defaultPrice * dateDiff);
    }
  } else if (period === "Annually") {
    const gsuitePrice = getOfferPrices(
      gsuites,
      quantity,
      gsuiteDatas,
      serviceType,
      skuId
    );

    let defaultPrice = gsuitePrice.price / 365;

    const currentDate = new Date();

    const providedDate = new Date(gsuiteDatas.expiryDate);

    if (serviceType === "renew") {
      return gsuitePrice.price;
    } else if (serviceType === "new-liscence") {
      const timeDiff = differenceInMinutes(providedDate, currentDate);
      const dateDiff = Math.ceil(timeDiff / (24 * 60));
      return Math.round(defaultPrice * dateDiff);
    }
  } else if (period === "Annual-Monthly") {
    const gsuitePrice = getOfferPrices(
      gsuites,
      quantity,
      gsuiteDatas,
      serviceType
    );

    let defaultPrice = gsuitePrice.price / 30;

    const currentDate = new Date();

    const providedDate = new Date(gsuiteDatas.expiryDate);

    if (serviceType === "renew") {
      return gsuitePrice.price;
    } else if (serviceType === "new-liscence") {
      const timeDiff = differenceInMinutes(providedDate, currentDate);
      const dateDiff = Math.ceil(timeDiff / (24 * 60));
      return Math.round(defaultPrice * dateDiff);
    }
  } else if (period === "Biannually") {
    const gsuitePrice = getOfferPrices(
      gsuites,
      quantity,
      gsuiteDatas,
      serviceType,
      skuId
    );
    let defaultPrice = gsuitePrice.price / 730;

    const currentDate = new Date();

    const providedDate = new Date(gsuiteDatas.expiryDate);

    if (serviceType === "renew") {
      return gsuitePrice.price;
    } else if (serviceType === "new-liscence") {
      const timeDiff = differenceInMinutes(providedDate, currentDate);
      const dateDiff = Math.ceil(timeDiff / (24 * 60));
      return Math.round(defaultPrice * dateDiff);
    }
  } else if (period === "Triannually") {
    const gsuitePrice = getOfferPrices(
      gsuites,
      quantity,
      gsuiteDatas,
      serviceType,
      skuId
    );

    let defaultPrice = gsuitePrice.price / 1095;

    const currentDate = new Date();

    const providedDate = new Date(gsuiteDatas.expiryDate);

    if (serviceType === "renew") {
      return gsuitePrice.price;
    } else if (serviceType === "new-liscence") {
      const timeDiff = differenceInMinutes(providedDate, currentDate);
      const dateDiff = Math.ceil(timeDiff / (24 * 60));
      return Math.round(defaultPrice * dateDiff);
    }
  }
};
