import { action_contants } from "./action-types";

export const setShowHideToastMsg = (isToShow, iconName, toastMessage) => {
  return (dispatch) => {
    dispatch({
      type: action_contants.SHOW_TOAST_MESSAGE,
      payload: { isToShow, iconName, toastMessage },
    });
  };
};

export const setShowHideLoader = (isToShow) => {
  return (dispatch) => {
    dispatch({
      type: action_contants.SHOW_LOADER,
      payload: isToShow,
    });
  };
};

export const logError = (params) => {
  return (dispatch) => {
    dispatch(setShowHideLoader(false));

    dispatch({
      type: action_contants.LOG_ERROR,
      payload: params,
    });
  };
};
