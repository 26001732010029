import axios from "axios";

import { useRecoilState } from "recoil";
import App from "../../App";
import { JWT } from "../../Atoms/LoginAtoms";
import { logout } from "../../auth";
import UseSelectedWorkSpace from "../../GlobalState/selectedWorkSpace";

const Interceptor = () => {
  const [JToken, setJToken] = useRecoilState(JWT);
  const selectedWorkSpace = UseSelectedWorkSpace(
    (state) => state.getSelectedWorkSpace
  );
  console.log("====================================");
  console.log("workspacrid", selectedWorkSpace().id);
  console.log("====================================");
  axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = JToken;
      config.headers["X-Workspace"] = selectedWorkSpace().id;
      return config;
    },
    function (error) {
      logout(() => {
        return (window.location.href = "/");
      });
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let res = error.response;
      if (res?.status === 401 && res.config && !res.config.__isRetryRequest) {
        logout(() => {
          return (window.location.href = "/");
        });
      }

      if (error.message !== undefined && error.message === "Network Error") {
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <App />
    </>
  );
};

export default Interceptor;
