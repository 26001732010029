import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import "../Style/DomainDetailsDialog.css";
import CloseIcon from "@material-ui/icons/Close";
import { api_url1 } from "../../../../globalSettings";
import errorImg from "../../../../images/DomainDetails/conatcterror.svg";
import axios from "axios";
import codes from "country-calling-code";
import { useRecoilState } from "recoil";
import { actionstatus } from "../../../../Atoms/warningpopup";
const DomainDetailsDialog = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loadButton, setLoadButton] = useState(false);

  const contactDetailsMap = {
    0: props.domainDetails?.registrantcontact,
    1: props.domainDetails?.admincontact,
    2: props.domainDetails?.billingcontact,
    3: props.domainDetails?.techcontact,
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const gridCellStyle = {
    border: "1px solid #e9e9eb",
    textAlign: "center",
    borderBottom: "none",
  };
  const gridLastCellStyle = {
    border: "1px solid #e9e9eb",
    textAlign: "center",
  };
  const contactbuttonStyles = {
    background: "#468def",
    border: "none",
    borderRadius: "2px",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "OpenSans",
    fontSize: "12px",
    fontWeight: 600,
    outline: "none",
    padding: "13px 20px",
    textTransform: "uppercase",
    transition: "all 0.2s ease-in",
  };
  const contactDetails = contactDetailsMap[selectedTab];
  const optionArray = ["registrant", "administrative", "billing", "technical"];
  const contactIdParam = [
    "registrantcontact",
    "admincontact",
    "billingcontact",
    "techcontact",
  ];
  const handleInputChange = (field, value) => {
    const updatedDomainDetails = { ...props.domainDetails };
    switch (selectedTab) {
      case 0:
        updatedDomainDetails.registrantcontact[field] = value;
        break;
      case 1:
        updatedDomainDetails.admincontact[field] = value;
        break;
      case 2:
        updatedDomainDetails.billingcontact[field] = value;
        break;
      case 3:
        updatedDomainDetails.techcontact[field] = value;
        break;
      default:
        break;
    }

    props.setDomainData(updatedDomainDetails);
  };
  const [errorInfo, setErrorInfo] = useState({ hasError: false, message: "" });
  const [actionstatus1, setactionstatus1] = useRecoilState(actionstatus);
  function updateContactDetails() {
    const requiredFields = [
      "name",
      "company",
      "emailaddr",
      "address1",
      "city",
      "country",
      "zip",
      "telnocc",
      "telno",
      "state",
    ];
    const missingField = requiredFields.find((field) => !contactDetails[field]);
    let missingTel = true;
    codes.forEach((country, index) => {
      if (country.countryCodes[0].includes(contactDetails.telnocc)) {
        missingTel = false;
      }
    });
    if (missingTel) {
      setErrorInfo({
        hasError: true,
        message: `{telnocc=TelephoneNo Country Code is invalid}`,
      });
      return;
    }
    if (missingField) {
      setErrorInfo({
        hasError: true,
        message: `Required parameter missing: ${missingField}`,
      });
      return;
    }

    setErrorInfo({ hasError: false, message: "" });
    let obj = {
      name: contactDetails.name,
      organization: contactDetails.company,
      email: contactDetails.emailaddr,
      address: contactDetails.address1,
      city: contactDetails.city,
      country: contactDetails.country,
      zipcode: contactDetails.zip,
      countrycode: contactDetails.telnocc,
      phone: contactDetails.telno,
      state: contactDetails.state,
      customer_id: props.domainDetails.customerid,
      order_id: props.domainDetails.orderid,
      updatecontactid:
        props.domainDetails[contactIdParam[selectedTab]].contactid,
      reg_contact_id: props.domainDetails.registrantcontact.contactid,
      admin_contact_id: props.domainDetails.admincontact.contactid,
      billing_contact_id: props.domainDetails.billingcontact.contactid,
      tech_contact_id: props.domainDetails.techcontact.contactid,
      type: optionArray[selectedTab],
    };
    setLoadButton(true);

    axios
      .post(`${api_url1}/resellers/modifycontactDetails`, obj)
      .then((result) => {
        if (result.data) {
          axios
            .get(
              `${api_url1}/resellers/getactions?orderid=${props.domainDetails.orderid}&no_of_records=10&page_no=1&action_status=&action_type=`
            )
            .then((result) => {
              if (result) {
                const filteredData = result.data.filter(
                  (item) =>
                    item.actionstatus !== "Success" &&
                    item.actionstatus !== "Failed"
                );
                setactionstatus1(filteredData);
              }
            })
            .catch((err) => {});
        }

        props.setSnackbarInfo({
          open: true,
          severity: "success",
          message: `${result.data}`,
        });
      })
      .catch((err) => {
        props.setSnackbarInfo({
          open: true,
          severity: "error",
          message: err.response.data.message,
        });
      })
      .finally(() => {
        setLoadButton(false);
      });
  }

  return (
    <Dialog
      open={props.whoisinfoopen}
      onClose={() => props.handleWhoisClose}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle>
        <div className="alignHeader">
          <div>
            <Typography className="contact-title">CONTACT DETAILS</Typography>
            <Typography className="contact-title-domainName">
              For:{props.domainDetails?.domainname}
            </Typography>
            {errorInfo.hasError && (
              <div className="contact-module-error">
                <div>
                  <img src={errorImg} alt="contactdetails-error" />
                </div>
                <div style={{ marginLeft: "2%" }}>{errorInfo.message}</div>
              </div>
            )}
          </div>

          <div>
            <IconButton
              component="span"
              onClick={() => {
                props.handleWhoisClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          className="cd-tabHead"
        >
          <Tab sx={{ paddingLeft: 0 }} label="REGISTRANT" />
          <Tab sx={{ paddingLeft: 0 }} label="ADMINISTRATIVE" />
          <Tab sx={{ paddingLeft: 0 }} label="BILLING" />
          <Tab sx={{ paddingLeft: 0 }} label="TECHNICAL" />
        </Tabs>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Name*</label>
              <input
                className="cd-input"
                value={contactDetails.name}
                onChange={(e) => handleInputChange("name", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Organization*</label>
              <input
                className="cd-input"
                value={contactDetails.company}
                onChange={(e) => handleInputChange("company", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Email*</label>
              <input
                className="cd-input"
                value={contactDetails.emailaddr}
                onChange={(e) => handleInputChange("emailaddr", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Country Code*</label>

              <input
                className="cd-input"
                value={contactDetails.telnocc}
                onChange={(e) => handleInputChange("telnocc", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Phone*</label>
              <input
                className="cd-input"
                value={contactDetails.telno}
                onChange={(e) => handleInputChange("telno", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align cd-disable">
              <label className="cd-title">FAX*</label>
              <input
                className="cd-input"
                disabled
                style={{
                  background: "transparent",
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Address*</label>
              <input
                className="cd-input"
                value={contactDetails.address1}
                onChange={(e) => handleInputChange("address1", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Zipcode*</label>
              <input
                className="cd-input"
                value={contactDetails.zip}
                onChange={(e) => handleInputChange("zip", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
            <div className="cd-align">
              <label className="cd-title">City*</label>
              <input
                className="cd-input"
                value={contactDetails.city}
                onChange={(e) => handleInputChange("city", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridLastCellStyle}>
            <div className="cd-align">
              <label className="cd-title">State*</label>
              <input
                className="cd-input"
                value={contactDetails.state}
                onChange={(e) => handleInputChange("state", e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={gridLastCellStyle}>
            <div className="cd-align">
              <label className="cd-title">Country*</label>
              <select
                id="country"
                className="cd-input"
                value={contactDetails.country}
                onChange={(e) => handleInputChange("country", e.target.value)}
              >
                {codes.map((country) => (
                  <option key={country.isoCode2} value={country.isoCode2}>
                    {country.country}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div style={{ paddingTop: "10px" }}>
              <LoadingButton
                loading={loadButton}
                style={contactbuttonStyles}
                onClick={() => {
                  updateContactDetails();
                }}
              >
                Update Contact Details
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DomainDetailsDialog;
