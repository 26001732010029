import React, { useEffect } from "react";
import { Box, List, ListItem, Typography, Drawer } from "@mui/material";
import { useState } from "react";
import { getTemplateFields } from "../../../webservices/PipelineService";
import { CloseButton } from "../../../components/CloseButton";
import variables from '../../../assets/styles/_colors.scss';

export default function ViewCloneTemplateFields(props) {

    const [, setOpen] = useState(true);
    const [fields, setFields] = useState(null);

    const handleClose = () => {
        setOpen(false);
        props.close();
    }

    useEffect(() => {
        getTemplateFields().then((response) => {
            setFields(response.data);
        });
    });

    useEffect(() => {

    }, [fields]);

    return (
        <div>
            <React.Fragment>
                <Drawer anchor="right"
                    PaperProps={{
                        sx: { minWidth: "35vw", maxWidth: "35vw" }
                    }}>
                    <Typography variant="h5" sx={{ pl: 2, pt: 2, borderTop: `2px solid ${variables.borderColor}` }}>Deal Fields
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>
                    <Typography sx={{ pt: 2, pl: 2, fontWeight: 600 }}>Deal Information</Typography>
                    <Box sx={{ width: '25vw', overflowX: "hidden", overflowY: "auto" }}>
                        <List sx={{
                            pt: 0, pb: 0,
                            '& .MuiListItem-root:hover': {
                                bgcolor: `${variables.linkBackgroundColor}`
                            }
                        }}>
                            {fields?.map((item, index) => (
                                <>
                                    <ListItem sx={{
                                        border: `1px solid ${variables.borderColor}`,
                                        borderRadius: 1, p: 1, m: 2,
                                        width: "auto"
                                    }}>
                                        <Typography variant="div">{item.name}</Typography>
                                    </ListItem>
                                </>
                            ))}
                        </List>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div >
    );
}
