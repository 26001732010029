import { Box, Button, Drawer, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { editFieldsData } from "../../../utils/createpipelineformdata";
import { useEffect } from "react";
import CustomizeFields from "./CustomizeFields";
import AddProductsToDeal from "./AddProductsToDeal";
import { CloseButton } from "../../../components/CloseButton";
import variables from "../../../assets/styles/_colors.scss";
import AddIcon from '@mui/icons-material/Add';
import { renderComponents } from "../../../components/RenderComponents";

const UpsertDeal = (props) => {

    const [data, setData] = useState(null);
    const [showFieldsForm, setShowFieldsForm] = useState(false);
    const [showProducts, setShowProducts] = useState(false);

    const [open, setOpen] = useState(true);

    function handleDrawerClose() {
        setOpen(false);
        props.handleDrawerClose();
    }

    const methods = useForm(
        {
            defaultValues: editFieldsData,
            shouldUnregister: false,
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, getValues, setValue, watch, trigger } = methods;

    const currentFields = watch(`fields`);

    useEffect(() => {
        props.edit === true && setValue("deal", {
            name: "Sample Name",
            stage: "2",
            subPipeline: "1",
            companyName: "xyz"
        })
    }, [props.edit]);

    useEffect(() => {
        setData(getValues("fields"));
    }, [currentFields]);

    const renderFields = (field, prefix, isTableData) => {
        return renderComponents(field, prefix, isTableData);
    }

    const handleCustomizeFieldsClose = (formData) => {
        setShowFieldsForm(false);
        if (formData !== null) {
            setValue("fields", formData.fields);
            setValue("products", formData.products);
        }
    }

    const onSubmit = async (data) => {
        const isStepValid = await trigger("fields");
        if (isStepValid) {
            // alert(JSON.stringify(data.deal));
            // alert(JSON.stringify(data.dealProducts));
            // handleDrawerClose();
        }
    }

    return (
        <><Drawer anchor="right" open={open}
            PaperProps={{
                sx: {
                    minWidth: showProducts ? "70vw" : "35vw", maxWidth: showProducts ? "70vw" : "35vw", pt: "20px", pb: 2,
                    borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                },
            }}>
            <Box sx={{ height: "100vh" }}>
                <Typography variant="h5" sx={{ pl: 2 }}>{props.edit ? "Edit Deal" : "Create Deal"}
                    <CloseButton handleDialogClose={handleDrawerClose} />
                </Typography>

                <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 2.5, height: "86vh" }}>
                    <FormProvider {...methods}>
                        <form onSubmit={handleSubmit(onSubmit)} id="dynamicForm" style={{ height: "100%" }}>
                            {data && Object.keys(data).filter(l => l !== "unUsedFields").map((l, i) => {
                                return <div key={`fields-wrapper-${i}`}>
                                    {data[l]?.fields?.length > 0 && <Typography component="div" sx={{ pb: 2, fontWeight: 600 }}> {data[l].name} </Typography>}
                                    <Stack direction="column" spacing={1}>
                                        {data[l].fields.map((field, index) => {
                                            return <Box key={`field-${index}`} sx={{ pb: 1.5 }}>{renderFields(field, "deal", false)}</Box>
                                        })}
                                    </Stack>
                                </div>
                            })}

                            {!showProducts && <Typography component="div" onClick={() => setShowProducts(true)}
                                sx={{ color: "#3d5af1", cursor: "pointer", backgroundColor: `${variables.linkBackgroundColor}`, borderRadius: 1, p: "1rem 0" }} >
                                <AddIcon sx={{ mr: 0.5 }} /> Products
                            </Typography>}

                            {showProducts && <Box sx={{ visibility: showProducts ? 'inherit' : 'hidden', height: showProducts ? 'inherit' : 0 }} >
                                <AddProductsToDeal renderFields={(field, prefix) => renderFields(field, prefix, true)} />
                            </Box>}
                        </form>
                    </FormProvider>
                </Box>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 16px 12px 20px", background: `${variables.linkBackgroundColor}` }}>
                    <Stack direction="row" justifyContent="left" sx={{ width: "50%" }}>
                        <Typography
                            onClick={() => setShowFieldsForm(true)} sx={{ color: "#3d5af1", cursor: "pointer", textDecoration: "underline" }}>
                            Customize Fields
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="right" sx={{ width: "50%" }}>
                        <Button variant="outlined" onClick={handleDrawerClose}> Cancel </Button>
                        <Button
                            // disabled={isSubmitting}
                            form="dynamicForm"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >Save</Button>
                        {/* {isSubmitting && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )} */}
                    </Stack>
                </Stack>

                {showFieldsForm && <CustomizeFields open={true} handleDrawerClose={(formData) => handleCustomizeFieldsClose(formData)}
                    defaultValues={{ "fields": getValues("fields"), "products": getValues("products") }} title="Edit Deal Fields" />}
            </Box>
        </Drawer >
        </>
    );
};

export default UpsertDeal;