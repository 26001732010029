import { Box, Grid, Tab, Tabs } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";
import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import Roles from "./roles/Roles";
import Profiles from "./profiles/Profiles";
import Users from "./users/Users";
import CrmSettings from "../CrmSettings";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important'
    }
})(Tab);

export default function UserControls(props) {

    const [selectedTab, setSelectedTab] = useState(null);
    const [showTabs, setShowTabs] = useState(true);

    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedTab(params.module);
    }, []);

    const handleTabClick = (e, val) => {
        setSelectedTab(val);
        navigate(`/crm/settings/usercontrols/${val}`);
    }

    return (
        <>
            <Grid container direction="row">
                <CrmSettings selectedMenu="usercontrols" selectedTab={selectedTab} />
                <Grid item direction="row" xs={10}>

                    {showTabs &&
                        <Grid container direction="column" sx={{ justifyContent: "center", height: "46px" }}>
                            <Grid item width="100%">
                                <Box sx={{ borderBottom: 1, borderColor: 'divider', maxHeight: "50px", backgroundColor: `${variables.lightGreyBackgroundColor}` }}>
                                    <Tabs value={selectedTab} onChange={(e, val) => handleTabClick(e, val)}>
                                        <StyledTab label="Users" value="users" />
                                        <StyledTab label="Profiles" value="profiles" />
                                        <StyledTab label="Roles" value="roles" />
                                    </Tabs>
                                </Box>
                            </Grid>
                        </Grid >
                    }
                    <Grid container direction="column" height="calc(100vh - 48px)" sx={{ overflow: 'hidden' }}>
                        {
                            <>
                                {selectedTab === "users" &&

                                    <Users showTabs={(val) => setShowTabs(val)} />
                                }

                                {selectedTab === "profiles" &&

                                    <Profiles />
                                }

                                {selectedTab === "roles" &&

                                    <Roles />
                                }
                            </>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}