import * as React from "react";
import {
    Controller,
    get,
    useFormContext
} from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import variables from "../../../assets/styles/_colors.scss";
import { Typography } from "@mui/material";

export const CustomDatePicker = ({ ...props }) => {
    const { control, formState: { errors } } = useFormContext();
    const error = get(errors, props.name);

    return (
        <Controller
            control={control}
            name={props.name}
            rules={props.rules}
            render={({ field }) => (
                <>
                    <div>{props.label}</div>
                    <DatePicker
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        style={{
                            width: props.width, height: "38px", borderRadius: "4px", paddingLeft: "14px",
                            borderColor: props.rules?.required?.value ?
                                error ? `${variables.removeIconColor}` : `${variables.borderColor} ${variables.borderColor} ${variables.borderColor} ${variables.removeIconColor}`
                                : error ? `${variables.removeIconColor}` : `${variables.borderColor}`,
                            borderStyle: 'solid',
                            borderWidth: props.rules?.required?.value ? `1px 1px 1px 3px` : `1px`
                        }}
                        {...field}
                        fixMainPosition={true}
                        fixRelativePosition={true}
                        calendarPosition="bottom-left"
                    />
                    {error?.message &&
                        <Typography component="p" sx={{ ml: 1.75, mt: 0.5, color: "#d32f2f", fontSize: "0.75rem" }}>
                            {error?.message}
                        </Typography>
                    }
                </>
            )}
        />
    );
};
