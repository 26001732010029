import React, { useState } from "react";
import ReactSwitch from "react-switch";
import Divider from "@mui/material/Divider";
import { Button,Box,Typography } from "@mui/material";
import { FaAngleRight } from "react-icons/fa6";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";

const ChatNotifications = () => {
  const [checked, setChecked] = useState(false);
  const [isHelpDialogOpen, setHelpDialogOpen] = useState(false);
  const [isDropdownIcon, setIsDropdownIcon] = useState(false);

  const handleChange = (val) => {
    setChecked(val);
  };

  const [pushNotifications, setPushNotifications] = useState({
    all: false,
    onlineMessages: false,
    offlineMessages: false,
    visitorBrowsing: false,
    notificationSounds: false,
  });

  const [emailNotifications, setEmailNotifications] = useState({
    unreadMessages: false,
    transcripts: false,
    userRatings: false,
    paidInvoices: false,
  });

  const handlePushChange = (name) => (val) => {
    setPushNotifications((prev) => ({ ...prev, [name]: val }));
  };

  const handleEmailChange = (name) => (val) => {
    setEmailNotifications((prev) => ({ ...prev, [name]: val }));
  };

  const handleHelpButtonClick = () => {
    setHelpDialogOpen((prev) => !prev);
    setIsDropdownIcon((prev) => !prev);
  };

  return (
    <div>
      <Typography
        sx={{
          flex: 1,
          marginBottom: 0,
          paddingRight: "12px",
          color: "#000",
          fontWeight: 700,
          fontSize: "24px",
          fontFamily: "Roboto",
        }}
      >
        Notification Settings
      </Typography>
      <Box
        sx={{
          border: "1px solid #e3e8ef",
          backgroundColor: "#fff",
          padding: "16px",
          marginTop: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            color: "#75869c",
            fontFamily: "Roboto",
          }}
        >
          Choose how you want to manage your notifications.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 800,
            color: "#75869c",
            fontFamily: "Roboto",
            marginTop: "8px",
          }}
        >
          It is not recommended to disable them, as you won't get notified when
          you receive new messages.
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "591px",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              lineHeight: "22px",
              fontWeight: 400,
              color: checked ? "black" : "#75869c",
              fontFamily: "Roboto",
            }}
          >
            Disable all Notifications
          </Typography>
          <ReactSwitch
            checked={checked}
            onChange={handleChange}
            handleDiameter={0}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        </div>
        <Box>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "18px",
              lineHeight: "28px",
              fontWeight: 700,
              color: "#1c293b",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            Push Notifications
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "491px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: pushNotifications.onlineMessages ? "black" : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Notify me of messages when I am online
            </Typography>
            <ReactSwitch
              checked={pushNotifications.onlineMessages}
              onChange={handlePushChange("onlineMessages")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "491px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: pushNotifications.offlineMessages ? "black" : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Notify me of messages when I am offline
            </Typography>
            <ReactSwitch
              checked={pushNotifications.offlineMessages}
              onChange={handlePushChange("offlineMessages")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "444px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: pushNotifications.visitorBrowsing ? "black" : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Notify me when a visitor is browsing my website
            </Typography>
            <ReactSwitch
              checked={pushNotifications.visitorBrowsing}
              onChange={handlePushChange("visitorBrowsing")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "591px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: pushNotifications.notificationSounds
                  ? "black"
                  : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Play notification sounds
            </Typography>
            <ReactSwitch
              checked={pushNotifications.notificationSounds}
              onChange={handlePushChange("notificationSounds")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Typography
            sx={{
              fontFamily: "Roboto",
              fontSize: "18px",
              lineHeight: "28px",
              fontWeight: 700,
              color: "#1c293b",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            Email Notifications
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "557px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: emailNotifications.unreadMessages ? "black" : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Email me of unread messages
            </Typography>
            <ReactSwitch
              checked={emailNotifications.unreadMessages}
              onChange={handleEmailChange("unreadMessages")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "513px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: emailNotifications.transcripts ? "black" : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Email me transcripts of conversations
            </Typography>
            <ReactSwitch
              checked={emailNotifications.transcripts}
              onChange={handleEmailChange("transcripts")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "604px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: emailNotifications.userRatings ? "black" : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Email me user ratings
            </Typography>
            <ReactSwitch
              checked={emailNotifications.userRatings}
              onChange={handleEmailChange("userRatings")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
          <Divider sx={{ borderColor: "black" }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "430px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "22px",
                fontWeight: 400,
                color: emailNotifications.paidInvoices ? "black" : "#75869c",
                fontFamily: "Roboto",
                marginBottom: "8px",
              }}
            >
              Email me paid invoices (only if you use a paid plan)
            </Typography>
            <ReactSwitch
              checked={emailNotifications.paidInvoices}
              onChange={handleEmailChange("paidInvoices")}
              handleDiameter={0}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          textTransform: "capitalize",
          alignItems: "center",
          justifyContent: "flex-end",
          borderTop: "1px solid #e3e8ef",
          backgroundColor: "#fafbfc ",
          color: "#75869c",
          lineHeight: "18px",
          cursor: "pointer",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          onClick={handleHelpButtonClick}
          sx={{
            textTransform: "capitalize",
            paddingTop: "15px",
            marginLeft: "729px",
          }}
        >
          Help{" "}
          {isDropdownIcon ? (
            <KeyboardArrowDownTwoToneIcon />
          ) : (
            <FaAngleRight
              sx={{
                cursor: "inherit",
                fontSize: "16px",
                height: "16px",
                width: "16px",
                position: "absolute",
                right: "22px",
                fill: "#0093ee",
                transform: "rotate(0)",
              }}
            />
          )}
        </Button>

        <Divider />
      </Box>

      {isHelpDialogOpen && (
        <Box
          sx={{
            padding: "6px 30px 26px",
            borderTop: "1px solid #e3e8ef",
            backgroundColor: "#fff",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              margin: "0.85em 0 0.45em",
              color: " #1c293b",
              fontFamily: "Roboto",
              fontWeight: "800!important",
            }}
          >
            What are transcripts?
          </Typography>
          <Typography
            sx={{
              color: "#2c405a",
              position: "relative",
              overflow: "hidden",
              fontFamily: "Roboto",
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "17px",
            }}
          >
            Transcripts of conversations help you archive full records of chats
            in your mailbox. This can be useful if you need to forward the chat
            history to someone who is not allowed to use your Iaaxin dashboard.
          </Typography>

          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "28px",
              margin: "0.85em 0 0.45em",
              color: " #1c293b",
              fontFamily: "Roboto",
              fontWeight: "800!important",
            }}
          >
            To which email address notifications are sent?
          </Typography>
          <Typography
            sx={{
              color: "#2c405a",
              position: "relative",
              overflow: "hidden",
              fontFamily: "Roboto",
              textAlign: "left",
              fontSize: "14px",
              lineHeight: "17px",
            }}
          >
            Iaaxin sends email to the email address that is configured for your
            account.
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default ChatNotifications;
