import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { setShowHideLoader } from "../../redux/actions/common-action-creators";
import { useTheme } from "@mui/material/styles";

export default function SimpleBackdropWithLoader() {
  const theme = useTheme();
  console.log("customthemee", theme);
  const useStyles = makeStyles(() => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isToShowLoader } = useSelector((state) => state.common);

  const handleClose = () => {
    dispatch(setShowHideLoader(false));
  };

  return (
    <Backdrop
      className={classes.backdrop}
      open={isToShowLoader}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
