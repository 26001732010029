const events = [
    {
        title: "Call 1",
        start: getDate("YEAR-MONTH-05T04:30:00+00:00"),
        end: getDate("YEAR-MONTH-05T05:30:00+00:00"),
        display: 'block',
        backgroundColor: "#9C27B0",
        borderColor: "#9C27B0",
        type: "call"
    },
    {
        title: "Task 1",
        start: getDate("YEAR-MONTH-29"),
        backgroundColor: "#FFA534",
        borderColor: "#FFA534",
        type: "task"
    },
    {
        groupId: "999",
        title: "Repeating Event",
        start: getDate("YEAR-MONTH-09T16:00:00+00:00"),
        end: getDate("YEAR-MONTH-09T16:30:00+00:00"),
        type: "event"
    },
    {
        groupId: "999",
        title: "Repeating Event",
        start: getDate("YEAR-MONTH-16T16:00:00+00:00"),
        end: getDate("YEAR-MONTH-09T16:30:00+00:00"),
        type: "event"
    },
    {
        title: "Event 1",
        start: getDate("YEAR-MONTH-18T10:30:00+00:00"),
        end: getDate("YEAR-MONTH-18T12:30:00+00:00"),
        type: "event"
    },
    {
        title: "Visit",
        start: getDate("YEAR-MONTH-18T12:00:00+00:00"),
        type: "event"
    },
    {
        title: "Test Event",
        start: getDate("YEAR-MONTH-19T07:00:00+00:00"),
        type: "event"
    },
    {
        title: "Meeting",
        start: getDate("YEAR-MONTH-18T14:30:00+00:00"),
        type: "event"
    },
    {
        title: "Counselling",
        start: getDate("YEAR-MONTH-18T17:30:00+00:00"),
        type: "event"
    },
    {
        title: "Knowledge Transfer",
        start: getDate("YEAR-MONTH-18T20:00:00+00:00"),
        type: "event"
    },
];

function getDate(dayString) {
    const today = new Date();
    const year = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();

    if (month.length === 1) {
        month = "0" + month;
    }

    return dayString.replace("YEAR", year).replace("MONTH", month);
}

export default events;