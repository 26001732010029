import React, { useState, useEffect } from "react";
import Column from "./PipelineBoardColumn";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Box } from "@mui/material";
import ModifyPipelineColumns from "../ModifyPipelineColumns";
import { useReducer } from "react";
import { BoardContainer } from "./PipelineBoardStyles";
import { getPipelineBoardColumns } from "../../../../webservices/PipelineService";
import { pipeline_atoms } from "../../../../../../Atoms/CrmAtoms";
import { useRecoilState } from "recoil";

export default function PipelineBoard(props) {

  const [stages, setStages] = useState(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [disableBoard, setDisableBoard] = useState(false);
  const [selectedItemsByColumn, setSelectedItemsByColumn] = useState({});
  const [pipelineAtom, setPipelineAtom] = useRecoilState(pipeline_atoms);
  const [resetSelection, setResetSelection] = useState(false);

  useEffect(() => {
    let a = Object.assign({}, pipelineAtom);
    if (a.displayedColumns.pipelineBoard?.length === 0) {
      getPipelineBoardColumns().then((data) => {
        const obj = {
          ...pipelineAtom,
          "displayedColumns": {
            ...pipelineAtom["displayedColumns"],
            "pipelineBoard": data.columns
          }
        }
        setPipelineAtom(obj);
      });
    }
  }, []);

  useEffect(() => {
    if (props.initial) {
      setStages(props.initial?.stages);
    }
  }, [props.initial]);

  useEffect(() => {
    forceUpdate();
  }, [props.drawerOpen]);

  useEffect(() => {
    if (pipelineAtom.resetBulkSelection) {
      setResetSelection(true);
    } else {
      setResetSelection(false);
    }
  }, [pipelineAtom.resetBulkSelection]);

  const reorderColumn = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // reordering column
    if (result.type === "COLUMN") {

      const reorderedStages = reorderColumn(stages, source.index, destination.index);
      setStages(reorderedStages);

      return;
    }

    // did not move anywhere
    if (
      source.droppableId === destination.droppableId
    ) {
      return;
    }
  };

  const handleSelectionByColumn = (list, index) => {
    let selectedItemsByCol = { ...selectedItemsByColumn };
    selectedItemsByCol[`${index}`] = list;
    setSelectedItemsByColumn(selectedItemsByCol);
    updateBoardBasedOnSelection(selectedItemsByCol);
  }

  const updateBoardBasedOnSelection = (selectedItemsByCol) => {
    const count = Object.keys(selectedItemsByCol).map(index => {
      return selectedItemsByCol[index].length;
    }).reduce((accumulator, count) => {
      return accumulator += count;
    }, 0);
    let a = Object.assign({}, pipelineAtom);
    a.selectedBoardData = count;
    setPipelineAtom(a);
    setDisableBoard(count > 0 ? true : false);
  }

  return (
    <Box sx={{ overflowX: 'auto', overflowY: 'hidden', height: "inherit", pr: props.drawerOpen ? "18vw" : "10vw" }}>
      <ModifyPipelineColumns module="pipelineBoard" />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
        >
          {(provided) => (
            <BoardContainer ref={provided.innerRef} {...provided.droppableProps}>
              {stages && stages.map((stage, index) => (
                < Column
                  key={stage.id}
                  index={index}
                  title={stage.name}
                  stage={stage}
                  stagesLength={stages?.length}
                  disableBoard={disableBoard}
                  selected={(items) => handleSelectionByColumn(items, index)}
                  resetSelection={resetSelection}
                />
              ))}
              {provided.placeholder}
            </BoardContainer>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};