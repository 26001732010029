import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { Button, Typography, styled, Divider } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledButton = styled(Button)({
    "&.MuiButtonBase-root": {
      width: "300px",
      display: "flex",
      justifyContent: "flex-start",
    },
  });

const ProfileEvents = () => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
      setOpen(!open);
    };

  return (
    <List sx={{ p: 0 }}>
    <StyledButton
      onClick={handleClick}
      sx={{
       padding:"11px 40px 10px 12px ",
        backgroundColor: "#fafbfc",
        borderBottom: "1px solid #e3e8ef",
      }}
    >
      <Typography
        sx={{
          fontSize: 12,
          fontFamily: "Inter",
          color: "#75869c",
          textAlign: "left",
        }}
      >
        LAST PROFILE EVENTS
      </Typography>
      {open ? (<ArrowDropDownIcon sx={{color: "#75869c",marginLeft:"auto"}}/>) : (<ArrowLeftIcon sx={{color: "#75869c",marginLeft:"auto"}}/>)}
    </StyledButton>

    {open && (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ListItem  sx={{padding:"11px 40px 10px 12px ",display:"flex",justifyContent:"center"}} >
         
          <Typography sx={{ fontSize: "13px", fontFamily: "Inter",color: "#75869c",}}>
            No events added.
          </Typography>
        </ListItem>
      </Collapse>
    )}
    <Divider sx={{ borderBottom: "1px solid grey" }} />
  </List>
  )
}

export default ProfileEvents





  
   







    


