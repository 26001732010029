
import { Stack, Typography } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { useEffect } from "react";
import CommonDateFields from "./CommonDateFields";

const usersDropdown = [
    {
        label: "User & System",
        value: "user_and_system"
    },
    {
        label: "User Or System",
        value: "user_or_system"
    },
    {
        label: "User",
        value: "user"
    },
    {
        label: "System",
        value: "system"
    },
    {
        label: "User Only",
        value: "user_only"
    },
    {
        label: "System Only",
        value: "system_only"
    }
];

const calenderOptions = [
    {
        label: "In the Last",
        value: "last"
    },
    {
        label: "Today",
        value: "today"
    },
    {
        label: "This Week",
        value: "week"
    },
    {
        label: "This Month",
        value: "month"
    },
    {
        label: "This Year",
        value: "year"
    },
    {
        label: "In the Last 30 Days",
        value: "last_30"
    },
    {
        label: "In the Last 60 Days",
        value: "last_60"
    },
    {
        label: "In the Last 90 Days",
        value: "last_90"
    },
    {
        label: "Until Now",
        value: "until_now"
    }
];

const TouchedRecords = (props) => {

    // const { setValue } = useFormContext();

    const singleField = ["today", "week", "month", "year", "last_30", "last_60", "last_90", "until_now"];
    const customFields = ["last"];

    useEffect(() => {
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>
            <Stack direction="row" sx={{ pt: 1, flexWrap: 'wrap' }} >
                <Stack direction="row" alignItems="center" spacing={1} sx={{ width: '100%' }}>
                    <Typography>By</Typography>
                    <Stack direction="row" >
                        <CustomSelect size="small" options={usersDropdown} name={`${props.name}.user`} defaultValue={usersDropdown[0]?.value} />
                    </Stack>
                </Stack>
                <Stack direction="row" sx={{ width: '100%' }}>
                    <CommonDateFields
                        name={props.name}
                        field={props.field}
                        options={calenderOptions}
                        singleField={singleField}
                        customFields={customFields} />

                </Stack>
            </Stack>
        </>
    );
};

export default TouchedRecords;