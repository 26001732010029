import * as React from 'react';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { makeStyles } from '@mui/styles';
import { Avatar, Divider, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import variables from "../../../../assets/styles/_colors.scss";
import DeleteSubPipeline from '../../../pipelines/delete/DeleteSubPipeline';
import CreateSubPipeline from '../../../pipelines/create/CreateSubpipeline';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    subPipeline: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

export default function SubPipelinePopover(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openCloneSubPipeline, setOpenCloneSubPipeline] = useState(false);
    const [openDeleteSubPipeline, setOpenDeleteSubPipeline] = useState(false);
    const [subPipelineActions, setSubPipelineActions] = useState([]);

    useEffect(() => {
        setSubPipelineActions(props.subPipelinesCount === 1 ? [{
            name: "Clone Sub-Pipeline",
            icon: <FileCopyOutlinedIcon />,
            key: 'cloneSubPipeline'
        }] : [{
            name: "Clone Sub-Pipeline",
            icon: <FileCopyOutlinedIcon />,
            key: 'cloneSubPipeline'
        }, {
            name: "Delete",
            icon: <DeleteOutlinedIcon />,
            key: "delete"
        }]);
    }, [props.subPipelinesCount]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "cloneSubPipeline":
                    setOpenCloneSubPipeline(true);
                    return true;
                case "delete":
                    setOpenDeleteSubPipeline(true);
                    return true;
                default:
                    return <></>
            }
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
        props.handlePopoverClose();
    };

    const classes = useStyles();
    const open = Boolean(anchorEl);
    const id = open ? 'sub-pipeline-popover' : undefined;

    return (
        <>
            <IconButton aria-label="sub-pipeline-popover" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.subPipeline }}
            >
                {subPipelineActions.map((item, index) => (
                    <div key={`sub-pipeline-popover-item-${index}`}>
                        <ListItemButton
                            sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemAvatar sx={{ minWidth: 46 }}>
                                <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                                    {item.icon}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </div>
                ))}
            </Popover>

            {openDeleteSubPipeline && <DeleteSubPipeline subPipeline={props.subPipeline} teamPipelineId={props.teamPipelineId} handleClose={() => setOpenDeleteSubPipeline(false)} />}

            {openCloneSubPipeline &&
                <CreateSubPipeline pipelineId={props.teamPipelineId}
                    cloneData={{
                        teamPipelineId: props.teamPipelineId,
                        subPipelineId: props.subPipeline._id,
                        showSubPipeline: true,
                        subPipelineName: props.subPipeline?.subPipelineName,
                        openStages: props.subPipeline?.openStages,
                        closedStages: props.subPipeline?.closedStages
                    }}
                    handleDrawerClose={() => setOpenCloneSubPipeline(false)} />
            }
        </>
    );
}