import { Divider} from "@mui/material";
import React  from "react";

import styled from "styled-components";
import envelop from "../../images/envelope.png";
import mobile from "../../images/mobile-button.png";
import phone from "../../images/phone-flip.png";
import tele from "../../images/phone-rotary.png";
import marker from "../../images/marker.png";





const Box = styled.div`
  background-color: white;
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Typography = styled.div`
  color: #5a7cc3;
  font: 12px;
  font-weight: 600;
`;




const ContactMainDetails = () => {


  return (
    <Box>
      <div
        style={{
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography>Stats</Typography>{" "}
        <p style={{ fontSize: "14px", paddingLeft: "5px" }}>
          Information assigned by the system
        </p>
        <Divider />
      </div>
      <div
        style={{
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography >Basic Info</Typography>{" "}
        <p style={{ fontSize: "14px", paddingLeft: "5px", margin: "10px 0" }}>
          <img src={envelop} alt="mail" style={{ marginRight: 10 }} />
          kavinkumar@iaaxin.com
        </p>
        <p style={{ fontSize: "14px", paddingLeft: "5px", margin: "10px 0" }}>
          <img src={mobile} alt="mail" style={{ marginRight: 10 }} />
          +91 8124372441
        </p>
        <p style={{ fontSize: "14px", paddingLeft: "5px", margin: "10px 0" }}>
          <img src={phone} alt="mail" style={{ marginRight: 10 }} />
          --
        </p>
        <p style={{ fontSize: "14px", paddingLeft: "5px", margin: "10px 0" }}>
          <img src={tele} alt="mail" style={{ marginRight: 10 }} />
          --
        </p>
        <p style={{ fontSize: "14px", paddingLeft: "5px", margin: "10px 0" }}>
          <img src={marker} alt="mail" style={{ marginRight: 10 }} />
          Karur
        </p>
        <Divider />
      </div>

      <div
        style={{
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography>Tags</Typography>{" "}
        <p style={{ fontSize: "14px", paddingLeft: "5px" }}>
          Information assigned by the system
        </p>
        <Divider />
      </div>

      <div
        style={{
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography>Description</Typography>{" "}
        <p style={{ fontSize: "14px", paddingLeft: "5px" }}>
          This is a sample Contact.
          <br />
          ✷ Quickly get started by creating a note ✍️or an activity ⏰ for this
          Contact.
          <br />✷ For any help write to us at support@bigin.com
        </p>
        <Divider />
      </div>

      <div
        style={{
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography>Other Info</Typography>{" "}
        <p style={{ fontSize: "14px", paddingLeft: "5px" }}>Email Opt Out </p>
        <Divider />
      </div>
   
      <style>
        {`
        .delete-icon {
            z-index:999;
            position: absolute;
            top: 50px;
            right: 50px;
            cursor: pointer;
            background-color: #00000024;
            color: white;
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          `}
      </style>
    </Box>
  );
};

export default ContactMainDetails;
