import React, { useState } from "react";
import { Button, Paper } from "@mui/material";
import { Grid, Stack } from "@mui/material";
import gmail from "../../../images/DomainDetails/Google.png";

import "./Style/Gsuite.css";
import gsuiteStyle from "./Style/Gsuite.module.css";
import AddAccount from "./Components/AddAccount/AddAccount";
import {
  customOutlineButtonStyle,
  customDisabledButtonStyle,
} from "../Style/DomainStyles";
import EmailAccounts from "./Components/EmailAccounts";
import AddAdminUser from "./Components/AddAdminUser";
import GsuiteRenewal from "./Components/Renewal/GsuiteRenewal";
import axios from "axios";
import { api_url1 } from "../../../globalSettings";
import {
  gstCalculation,
  getOfferPrices,
  calculateAmountNew,
} from "./HelperFunctions/helperFunction";
import { useQuery } from "@tanstack/react-query";
import TopBar from "../Component/TopBar";
import CircularProgress from "@mui/material/CircularProgress";

import ErrorInfoo from "../Component/ErrorInfoo";
import RenewalError from "../Component/RenewalError";
import "./Style/Gsuite.css";
import DeleteAccounts from "./Components/DeleteAccount/DeleteAccount";
import { RenewalEnable } from "../CustomHook/RenewalEnable";
const ManageGsuit = ({ setSnackbarInfo, filterGsuite, Selecteddomain }) => {
  const [loadButton, setLoadButton] = useState(false);
  const [gsuitePrice, setGsuitePrice] = useState({});
  const [renewalData, setRenewalData] = useState([]);
  const [priceData, setPriceData] = useState(0);
  const [taxData, settaxData] = useState(0);
  const [planValue, setplanValue] = useState("");
  const [isToolTipOpen, setTooltip] = useState(false);
  const [isAddToolTip, setAddToolTip] = useState(false);
  const [toolTipPrice, setToolPrice] = useState(0);

  function isToolTip(planName, details) {
    let initialMilliseconds = 365 * 24 * 60 * 60 * 1000;
    console.log("details?.gsuite_userData", details?.gsuite_userData);
    const createDate = details?.gsuite_userData?.purchaseDate;
    const expiryDate = details?.gsuite_userData?.expiryDate;
    const plan =
      details.plans === undefined
        ? details.gsuites
        : details?.plans?.find((data) => data.planName === planName);
    const yearCalculate =
      details.plans === undefined
        ? plan?.noOfYears * initialMilliseconds
        : plan?.gsuites?.noOfYears * initialMilliseconds;
    console.log(
      "csdcdcacax",
      new Date().getTime() -
        new Date(details?.gsuite_userData.purchaseDate).getTime() <=
        yearCalculate
    );
    const addAccTool =
      new Date().getTime() -
        new Date(details?.gsuite_userData.purchaseDate).getTime() <=
      yearCalculate;
    console.log("csdcdcacax", addAccTool);
    setAddToolTip(addAccTool);
    const isOpen =
      new Date(expiryDate).getTime() - new Date(createDate).getTime() <=
      yearCalculate;
    setTooltip(isOpen);
  }
  console.log("csdcdcacax", isAddToolTip);
  function findPrice(gsuites, data, quantity, period) {
    setToolPrice(
      getOfferPrices(gsuites, quantity, data.gsuite_userData, "renew")
    );
    const caluclatedPrice = calculateAmountNew(
      gsuites,
      period,
      "renew",
      quantity,
      data.gsuite_userData
    );

    setPriceData(caluclatedPrice);
    settaxData(gstCalculation(caluclatedPrice, data?.userData));
  }
  const fetchGSuiteData = async (api_url1) => {
    let obj = {
      customerId: Selecteddomain.gsuiteid,
      skuId: filterGsuite.skuId,
    };
    const queryParams = new URLSearchParams(obj).toString();

    const response = await axios.get(
      `${api_url1}/gsuite/service/servicedetailsclient?${queryParams}`
    );
    return response.data;
  };

  const { data, isLoading: isGsuiteLoading } = useQuery({
    queryKey: ["gsuiteDetails", Selecteddomain?._id, filterGsuite.skuId],
    queryFn: () => fetchGSuiteData(api_url1),
    enabled: Selecteddomain?.guiteEnabled,
    onSuccess: (data) => {},
    onError: (error) => {
      setSnackbarInfo({
        open: true,
        severity: "error",
        message: error.response?.data?.error?.message || "Unknown error",
      });
    },
  });

  function fetchGSuitePrice() {
    setLoadButton(true);
    let obj = {
      id: filterGsuite._id,
    };
    const queryParams = new URLSearchParams(obj).toString();
    axios
      .get(`${api_url1}/product/gsuite/payment?${queryParams}`)
      .then((result) => {
        isToolTip(result.data.defaultPlanName, result.data);
        setRenewalData(result.data);
        setplanValue(result.data.defaultPlanName);
        const filterPrice = result.data.plans.filter((data, index) => {
          return (
            result.data.defaultPlanName.toLowerCase() ===
            data.planName.toLowerCase()
          );
        });
        let initialGsuite = result.data.plans.find(
          (data) => data.planName === result.data.defaultPlanName
        );

        const quantity =
          result.data?.gsuite_userData?.numberOfSeats ||
          result.data?.gsuite_userData?.maximumNumberOfSeats;

        findPrice(
          initialGsuite.gsuites,
          result.data,
          quantity,
          result.data.defaultPlanName
        );

        setGsuitePrice(filterPrice[0]);
        setOpenRenewal(true);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadButton(false);
      });
  }

  const [open2, setOpen2] = React.useState(false);

  const hanldeClickOpen2 = () => {
    setOpen2(true);
  };
  const handleClickClose1 = () => {
    setOpen2(false);
  };
  const [openEmail, setOpenEmail] = useState(false);
  const handleEmailClose = () => {
    setOpenEmail(false);
  };

  const [openAdminAdd, setAdminAdd] = useState(false);
  const handleCloseAdminAdd = () => {
    setAdminAdd(false);
  };

  const [openRenewal, setOpenRenewal] = useState(false);
  const handleCloseRenwewal = () => {
    setOpenRenewal(false);
  };
  const isTOS =
    data?.suspensionReasons?.length > 0 &&
    data.suspensionReasons[0] === "PENDING_TOS_ACCEPTANCE";
  const isOnlySuspend = data?.status?.toLowerCase() === "suspended";
  const isSuspended = isOnlySuspend && !isTOS ? true : false;
  const isFlexible = data?.plan?.planName.toLowerCase() === "flexible";
  const [openDeleteAcc, setOpen] = useState(false);
  const handleClickDeleteOpen = () => {
    setOpen(true);
  };
  const handleClickDeleteClose = () => {
    setOpen(false);
  };
  const EnableRenewal = RenewalEnable(data?.expirydate);
  return (
    <div style={{ marginTop: "14px" }}>
      <TopBar
        imgSrc={gmail}
        domainname={"Google WorkSpace"}
        purchasedDate={data?.orderdate}
        expiryDate={data?.expirydate}
        planName={data?.skuName?.replace("Google Workspace", "").trim()}
        handleClick={fetchGSuitePrice}
        loading={loadButton}
        isDisable={EnableRenewal}
        isLoading={isGsuiteLoading}
      />

      {data?.status === "SUSPENDED" ? (
        <ErrorInfoo
          error={data?.suspensionReasons[0]}
          reason={
            "Please accept the terms and condition to activate the account"
          }
          status={true}
        />
      ) : (
        ""
      )}

      <div style={{ display: "grid" }}>
        {isSuspended && (
          <RenewalError
            height={"219px"}
            overlap={"-300px"}
            handleClick={fetchGSuitePrice}
            type={"Google WorkSpace"}
            loadButton={loadButton}
          />
        )}
        {isGsuiteLoading ? (
          <div className="progress_gsuite">
            <CircularProgress />
          </div>
        ) : (
          <Paper style={{ padding: "20px", marginBottom: "15px" }}>
            <div>
              <Stack
                alignItems="center"
                sx={{
                  background: "white",
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ display: "flex", padding: "40px" }}
                  >
                    <div className={gsuiteStyle.alingButton_title}>
                      <p className={gsuiteStyle.manageGsuite_Title}>
                        Manage Email Accounts
                      </p>
                      <Button
                        color="primary"
                        variant="outlined"
                        style={
                          data?.status === "SUSPENDED"
                            ? customDisabledButtonStyle
                            : customOutlineButtonStyle
                        }
                        onClick={() => {
                          const adminPanelUrl = data?.customerresourceUiUrl;
                          if (adminPanelUrl) {
                            window.open(adminPanelUrl, "_blank");
                          } else {
                            console.error("Invalid or missing admin panel URL");
                            alert("error");
                          }
                        }}
                        disabled={isSuspended}
                      >
                        Go To Admin Panel
                      </Button>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ display: "flex", padding: "40px" }}
                  >
                    <div className={gsuiteStyle.alingButton_title}>
                      <p className={gsuiteStyle.manageGsuite_Title}>
                        Total Email Accounts
                      </p>
                      <input
                        value={`${
                          isFlexible
                            ? data?.seats?.maximumNumberOfSeats
                            : data?.seats?.numberOfSeats
                        } Account`}
                        className={gsuiteStyle.accounts_button}
                        onClick={() => {
                          setOpenEmail(true);
                        }}
                        disabled={isOnlySuspend || isTOS}
                      />
                      <div>
                        {isOnlySuspend || isTOS ? (
                          <p className={gsuiteStyle.deleteAccount_tag}>
                            buy more
                          </p>
                        ) : (
                          <p
                            className={gsuiteStyle.buy_more_tag}
                            onClick={() => {
                              setOpen2(true);
                            }}
                          >
                            buy more
                          </p>
                        )}

                        <div className={gsuiteStyle.divider}></div>
                        <p
                          className={
                            !data?.plan?.isCommitmentPlan
                              ? gsuiteStyle.deleteAccount_tag_active
                              : gsuiteStyle.deleteAccount_tag
                          }
                          onClick={() => {
                            if (!data?.plan?.isCommitmentPlan) {
                              handleClickDeleteOpen();
                            }
                          }}
                        >
                          Delete Accounts
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Stack>
            </div>
          </Paper>
        )}
      </div>

      {
        <AddAccount
          open={open2}
          handleClose={handleClickClose1}
          setSnackbarInfo={setSnackbarInfo}
          filterGsuite={filterGsuite}
          isToolTip={isToolTip}
          isToolTipOpen={isAddToolTip}
          uniqueId={filterGsuite._id}
        />
      }

      <EmailAccounts
        open={openEmail}
        handleClickClose={handleEmailClose}
        handleClickOpen={hanldeClickOpen2}
        domainName={filterGsuite.domainName}
        setSnackbarInfo={setSnackbarInfo}
        gsuieData={data}
        isFlexible={isFlexible}
      />

      <GsuiteRenewal
        open={openRenewal}
        handleClose={handleCloseRenwewal}
        setSnackbarInfo={setSnackbarInfo}
        setGsuitePrice={setGsuitePrice}
        renewalData={renewalData}
        taxData={taxData}
        priceData={priceData}
        filterGsuite={filterGsuite}
        planValue={planValue}
        setplanValue={setplanValue}
        isToolTipOpen={isToolTipOpen}
        isToolTip={isToolTip}
        findPrice={findPrice}
        toolTipPrice={toolTipPrice}
        gsuitePrice={gsuitePrice}
        isFlexiblle={isFlexible}
        uniqueId={filterGsuite._id}
      />
      <AddAdminUser open={openAdminAdd} handleClose={handleCloseAdminAdd} />
      <DeleteAccounts
        open={openDeleteAcc}
        handleClickClose={handleClickDeleteClose}
        licensedNumberOfSeats={data?.seats?.licensedNumberOfSeats}
        numberOfSeats={
          isFlexible
            ? data?.seats?.maximumNumberOfSeats
            : data?.seats?.numberOfSeats
        }
        domainName={filterGsuite.domainName}
        customerId={data?.customerId}
        subscriptionId={data?.subscriptionId}
        setSnackbarInfo={setSnackbarInfo}
      />
    </div>
  );
};

export default ManageGsuit;
