import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import CustomDropDown from "./CustomDropDown";
import Paper from "@mui/material/Paper";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteArray } from "./RouteContent";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

const SearchBar = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [route, setRoute] = useState("");
  const [subRoute, setSubRoute] = useState({
    subRouteId: "",
    subRouteLabel: "",
    subroute: "",
  });
  const [subRouteArray, setSubRouteArray] = useState([]);
  const handleFocusOpen = () => setIsFocused(true);
  const handleFocusClose = () => setIsFocused(false); // Handle onBlur event
  const navigate = useNavigate();
  const handleChange = (event) => {
    setRoute(event.target.value);
    handleRouteArray(event.target.value);
    setSubRoute({
      subRouteId: "",
      subRouteLabel: "",
      subroute: "",
    });
    setIsFocused(true);
  };

  const handleDropDownOpen = () => {
    setIsDropDownOpen(true);
    setIsFocused(true);
  };

  const handleDropDownClose = () => {
    if (route !== null) {
      setIsDropDownOpen(false);
    }
  };
  const location = useLocation();
  const currentUrl = location.pathname;
  const parts = currentUrl.split("/");
  function handleRoute() {
    if (parts[1] !== route) {
      setSubRoute({
        subRouteId: "",
        subRouteLabel: "",
        subroute: "",
      });
    }
    setRoute(parts[1]);
    handleRouteArray(parts[1]);
  }
  function handleRouteArray(parts) {
    const subArray = RouteArray.find((item) => {
      return item.route === parts;
    });

    setSubRouteArray(subArray?.subRoutes || []);
  }

  useEffect(() => {
    handleRoute();
  }, [parts[1]]);
 
  function handleSubRouteChange(newValue) {
    setSubRoute(newValue);
    if (route === newValue.subRoute) navigate(`/${route}`);
    else navigate(`/${route}/${newValue.subRoute}`);
    setIsFocused(false);
  }

  return (
    <div
      onBlur={handleFocusClose}
      style={{
        display: "flex",
        width: isFocused ? "40vw" : "38vw",
        background: isFocused ? "#fff" : "#3D3F43",
        borderRadius: "5px",
      }}
    >
      <CustomDropDown
        value={route}
        handleChange={handleChange}
        items={RouteArray}
        handleFocusOpen={handleFocusOpen}
        isFocused={isFocused}
      />

      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable={true}
        onFocus={handleFocusOpen}
        onBlur={handleFocusClose}
        getOptionLabel={(option) => option.subRouteLabel}
        value={subRoute}
        onChange={(e, newValue) => handleSubRouteChange(newValue)}
        sx={{
          "& .MuiAutocomplete-clearIndicator": {
            color: "red",
          },
        }}
        PaperComponent={({ children }) => (
          <Paper
            style={{
              background: "white",
              marginTop: "10px",
              width: isFocused ? "calc(48vw - 120px)" : "calc(33vw - 120px)",
              position: "relative",
              right: "8vw",
            }}
          >
            {children}
          </Paper>
        )}
        renderInput={(params) => (
          <CustomTextField
            placeholder="Search...."
            {...params}
            onFocus={handleFocusOpen}
            InputProps={{
              ...params.InputProps,
              type: "search",
              style: {
                width: "30vw",
                border: "none",
                color: isFocused ? "black" : "#ddd",
                padding: "0px",
              },
              endAdornment: null,
            }}
          />
        )}
        open={isDropDownOpen}
        onOpen={handleDropDownOpen}
        onClose={handleDropDownClose}
        options={subRouteArray}
      />
    </div>
  );
};

export default SearchBar;
