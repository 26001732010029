import { Grid } from "@mui/material";
import React , {useEffect , useState} from "react";
import DashPieChart from "./DashPieChart";
import WorkTime from "./WorkTime";
import CallLogs from "./CallLogs";
import styled from "styled-components";
import DashBarChart from "./DashBarChart";
import axios from "axios";
import { login_companyid } from "../../../Atoms/LoginAtoms";
import { api_url } from "../../../globalSettings";
import { useRecoilState } from "recoil";
import TodayTask from "./TodayTask";
import UpcomingTask from "./UpcomingTask";


const Typography = styled.div`
  font-size: 18px;
  padding : 10px;
  color: #4fb385;
  padding-bottom : 0
`;





const DashBoard = () => {
  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);
  const [crmDashDetails , setCrmDashDetails] = useState("");


  useEffect(() => {
    axios
      .get(
        `${api_url}/todo/taskStatusDashboard/${company_idd}`
      )
      .then((responce) => {
     
        setCrmDashDetails(responce.data.data)
      })
      .catch(function (error) {
        if (error.response) {
          
        } else if (error.request) {
        
        } else {
        
        }
      });
  }, []);
  
  return (
    <div style={{backgroundColor : "#fcfafa"}}>
      {" "}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6} display={"flex"} justifyContent={"center"}>
          {Object.keys(crmDashDetails).length > 0 ? <DashPieChart crmDashDetails={crmDashDetails} /> : ""}
           
        </Grid>
        <Grid item xs={12} sm={6} md={6} display={"flex"} justifyContent={"center"}>
        {Object.keys(crmDashDetails).length > 0 ? <WorkTime crmDashDetails={crmDashDetails} /> : ""}
        </Grid>
        <Grid item xs={12} sm={6} md={6} display={"flex"} justifyContent={"center"}>
           {Object.keys(crmDashDetails).length > 0 ? <TodayTask crmDashDetails={crmDashDetails} /> : ""}
        </Grid>
        <Grid item xs={12} sm={6} md={6} display={"flex"} justifyContent={"center"}>
           {Object.keys(crmDashDetails).length > 0 ? <UpcomingTask crmDashDetails={crmDashDetails} /> : ""}

        </Grid>
        </Grid>
        <Typography>CALL LOGS</Typography>
        <hr/>
        <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12}>
          <CallLogs />
        </Grid>
        </Grid>

        <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6} display={"flex"} justifyContent={"center"}>
          <DashBarChart />
        </Grid>
        </Grid>
    </div>
  );
};

export default DashBoard;
