import { action_contants } from "../actions/action-types.js";

const initialState = {
  companyList: [],
  branchModifiedStatus: 0,
  branchList: [],
  departmentModifiedStatus: 0,
  departmentList: [],
  designationModifiedStatus: 0,
  designationList: [],
  taskModifiedStatus: 0,
  taskList: [],
  expenseModifiedStatus: 0,
  expenseList: [],
  branchForm: {
    action: "add_branch",
    token: "",
    company_id: "",
    branch_name: "",
    country: "",
    city: "",
    street1: "",
    contact_person: "",
    mobile_number: "",
  },
  departmentForm: {
    action: "add_department",
    token: "",
    company_id: "",
    branch_id: "",
    department_name: "",
  },
  designationForm: {
    action: "add_designation",
    token: "",
    company_id: "",
    designation: "",
  },
  taskForm: {
    action: "add_task_category",
    token: "",
    task_category_name: "",
    task_description: "",
    task_category_id: "",
    company_id: "",
  },
  expenseForm: {
    action: "add_expense_category",
    token: "",
    expense_category: "",
    company_id: "",
  },
  companyForm: {
    action: "add_company",
    token: "",
    company_name: "",
    email: "",
    phone_number: "",
    gst_no: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    street1: "",
    street2: "",
    contact_person: "",
    company_logo: {},
  },
  companyInfo: {},
  currentBranch: {},
  isToShowSelectedCompany: false,
  isToShowSelectedCompany: "",
};

const manageCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case action_contants.SET_COMPANY_INFO:
      return {
        ...state,
        companyInfo: action.payload,
      };

    case action_contants.SET_COMPANY_LIST:
      return {
        ...state,
        companyList: action.payload,
      };

    case action_contants.SET_COMPANY_FORM_DATA:
      return {
        ...state,
        companyForm: action.payload,
      };
    case action_contants.SET_SHOW_SELECTED_COMPANY:
      return {
        ...state,
        isToShowSelectedCompany: action.payload,
      };

    case action_contants.ADD_OR_EDIT_COMPANY:
      return {
        ...state,
        companyModifiedStatus: action.payload,
      };
    case action_contants.ADD_OR_EDIT_BRANCH:
      return {
        ...state,
        branchModifiedStatus: action.payload,
      };
    case action_contants.SET_CURRENT_BRANCH:
      return {
        ...state,
        currentBranch: action.payload,
      };
    case action_contants.SET_BRANCH_LIST:
      return {
        ...state,
        branchList: action.payload,
      };
    case action_contants.SET_BRANCH_FORM_DATA:
      return {
        ...state,
        branchForm: action.payload,
      };

    case action_contants.ADD_OR_EDIT_DEPARTMENT:
      return {
        ...state,
        departmentModifiedStatus: action.payload,
      };
    case action_contants.SET_DEPARTMENT_LIST:
      return {
        ...state,
        departmentList: action.payload,
      };
    case action_contants.SET_DEPARTMENT_FORM_DATA:
      return {
        ...state,
        departmentForm: action.payload,
      };

    case action_contants.ADD_OR_EDIT_DESIGNATION:
      return {
        ...state,
        designationModifiedStatus: action.payload,
      };
    case action_contants.SET_DESIGNATION_LIST:
      return {
        ...state,
        designationList: action.payload,
      };
    case action_contants.SET_DESIGNATION_FORM_DATA:
      return {
        ...state,
        designationForm: action.payload,
      };

    case action_contants.ADD_OR_EDIT_TASK:
      return {
        ...state,
        taskModifiedStatus: action.payload,
      };
    case action_contants.SET_TASK_LIST:
      return {
        ...state,
        taskList: action.payload,
      };
    case action_contants.SET_TASK_FORM_DATA:
      return {
        ...state,
        taskForm: action.payload,
      };

    case action_contants.ADD_OR_EDIT_EXPENSE:
      return {
        ...state,
        expenseModifiedStatus: action.payload,
      };
    case action_contants.SET_EXPENSE_LIST:
      return {
        ...state,
        expenseList: action.payload,
      };
    case action_contants.SET_EXPENSE_FORM_DATA:
      return {
        ...state,
        expenseForm: action.payload,
      };

    default:
      break;
  }
  return state;
};

export default manageCompanyReducer;
