import * as yup from "yup";

const schema = yup.object({
  branch_name: yup.string().required("Branch name is required feild"),
  country: yup.string(),
  city: yup.string(),
  street1: yup.string(),
  contact_person: yup.string(),
  mobile_number: yup.number(),
});

export default schema;
