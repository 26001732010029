import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PublicIcon from "@mui/icons-material/Public";
import { Button, Typography, styled, Divider } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    width: "300px",
    display: "flex",
    justifyContent: "flex-start",
  },
});

const MainInfo = () => {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <List sx={{ p: 0 }}>
      <StyledButton
        onClick={handleClick}
        sx={{
         padding:"11px 40px 10px 12px ",
          backgroundColor: "#fafbfc",
          borderBottom: "1px solid #e3e8ef",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "Inter",
            color: "#75869c",
            textAlign: "left",
          }}
        >
          MAIN INFORMATION
        </Typography>
        {open ? (<ArrowDropDownIcon sx={{color: "#75869c",marginLeft:"auto"}}/>) : (<ArrowLeftIcon sx={{color: "#75869c",marginLeft:"auto"}}/>)}
      </StyledButton>

      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListItem   >
            <ListItemIcon>
              <LocationOnIcon
                sx={{
              
                  cursor: "inherit",
                  color: "#3f536e",
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              />
            </ListItemIcon>
            <Typography sx={{ fontSize: "13px", fontFamily: "Inter" }}>
              Karur,India
            </Typography>
          </ListItem>

          <ListItem >
            <ListItemIcon>
              <AccessTimeIcon
                sx={{
               
                  cursor: "inherit",
                  color: "#3f536e",
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              />
            </ListItemIcon>
            <Typography sx={{ fontSize: "13px", fontFamily: "Inter" }}>
              Undefined
            </Typography>
          </ListItem>
          <ListItem >
            <ListItemIcon>
              <PublicIcon
                sx={{
                  
                  cursor: "inherit",
                  color: "#3f536e",
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              />
            </ListItemIcon>
            <Typography sx={{ fontSize: "13px", fontFamily: "Inter" }}>
              India
            </Typography>
          </ListItem>
        </Collapse>
      )}
      <Divider sx={{ borderBottom: "1px solid grey" }} />
    </List>
  );
};

export default MainInfo;
