import { Avatar, Box, Button, Drawer, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import variables from "../../../assets/styles/_colors.scss";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import PersonIcon from '@mui/icons-material/Person';
import { ProductInfo } from "./ProductInfo";
import { stringAvatar } from "../../../utils/PipelineConstants";
import DeleteProduct from "../DeleteProduct";
import UpsertProduct from "../create/UpsertProduct";

const StyledIcon = withStyles({
    root: {
        height: "32px",
        width: "32px",
        backgroundColor: `${variables.iconButtonBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.iconButtonBackgroundHoverColor} !important`
        }
    }
})(IconButton);

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important'
    }
})(Tab);

const StyledDotSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

const ProductQuickPreview = (props) => {

    const [open, setOpen] = useState(true);
    const [editProduct, setEditProduct] = useState(false);
    const [deleteProduct, setDeleteProduct] = useState(false);

    function handleDrawerClose() {
        setOpen(false);
        props.handleDrawerClose();
    }

    return (
        <><Drawer anchor="right" open={open}
            PaperProps={{
                sx: {
                    minWidth: "35vw", maxWidth: "35vw", borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                },
            }}>
            <Box sx={{ height: "100vh" }}>
                <Box sx={{ bgcolor: `${variables.lightGreyBackgroundColor}`, pt: "20px", pb: 2 }}>
                    <Stack direction="row" width="100%" alignItems="top" sx={{ pl: 2, pr: 1.25 }}>
                        <Stack direction="column" width="60%" justifyContent="left">
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Stack direction="column">
                                    {props.item?.productName && <Avatar {...stringAvatar(`${props.item?.productName}`)} />}
                                </Stack>
                                <Stack direction="column">
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography sx={{ cursor: 'pointer', fontWeight: 'bold', color: `${variables.linkColor}` }}>{props.item?.productName}</Typography>
                                        <StyledDotSeparator />
                                        <Typography sx={{ fontWeight: 'bold' }}>${props.item?.unitPrice}/Unit</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography sx={{ color: `${variables.greyTextColor}` }}>
                                            <PersonIcon size="small" sx={{ width: 16, height: 16, mt: -0.25 }} />
                                            <Typography component="span" sx={{ pl: 0.5, fontSize: "12px" }}>{props.item?.productOwner}</Typography>
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack direction="row" width="40%" justifyContent="right" spacing={1}>
                            <StyledIcon onClick={() => setEditProduct(true)}>
                                <EditOutlinedIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                            <StyledIcon onClick={() => setDeleteProduct(true)}>
                                <DeleteOutlineIcon fontSize='medium' color="error" />
                            </StyledIcon>
                            <StyledIcon onClick={() => handleDrawerClose()}>
                                <CloseIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                        </Stack>
                    </Stack>
                </Box>

                <Box sx={{ overflowY: "auto", overflowX: "hidden", pl: 1, height: "80vh" }}>
                    <ProductInfo item={props.item} />
                </Box>

                {deleteProduct &&
                    <DeleteProduct item={props.item} module={props.module} handleClose={() => { setDeleteProduct(false) }} />}

                {editProduct &&
                    <UpsertProduct edit={true} editData={props.item} module={props.module} handleDrawerClose={() => { setEditProduct(false) }} />}
            </Box>
        </Drawer >
        </>
    );
};

export default ProductQuickPreview;