import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { login_companyid } from "../../Atoms/LoginAtoms";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FaMessage } from "react-icons/fa6";
import { FaGlobeAsia } from "react-icons/fa";
import { IoPerson } from "react-icons/io5";
import { MdAnalytics } from "react-icons/md";
import {
  Box,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
//import Style from "./style.module.scss"
import Styles from "./ChatLayout.module.scss";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AssistantIcon from "@mui/icons-material/Assistant";
// import AssessmentIcon from "@mui/icons-material/Assessment";
// import EmailIcon from '@mui/icons-material/Email';
// import TwitterIcon from '@mui/icons-material/Twitter';
import ChatIcon from "@mui/icons-material/Chat";
import CallIcon from "@mui/icons-material/Call";
import SmsIcon from "@mui/icons-material/Sms";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaFacebookMessenger } from "react-icons/fa";
import InboxIcon from '@mui/icons-material/Inbox';

const fetcher = ({ queryKey }) => {
  const { companyId } = queryKey[1];
  console.log("companyId", companyId);
  const url = `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/website/defaultWebsite/${companyId}`;
  return axios.get(url).then(({ data }) => data);
};

const ChatSidebar = () => {
  const [companyId] = useRecoilState(login_companyid);
  const [inboxOpen, setInboxOpen] = useState(true);
  const location = useLocation();

  const { data, isLoading, error } = useQuery({
    queryKey: ["website_id", { companyId }],
    queryFn: fetcher,
  });
  console.log("RESPONSES", data);

  if (isLoading) return <Box sx={{width:"250px",borderRight:"1px solid #DDDDDD"}}><Typography sx={{paddingTop:"20px"}}>Loading ...</Typography></Box>;
  if (error) return <Box sx={{width:"250px",borderRight:"1px solid #DDDDDD"}}><Typography sx={{paddingTop:"20px"}}>Could not get website Id</Typography></Box>;

  const handleInboxToggle = () => {
    setInboxOpen(!inboxOpen);
  };
  const links = [
    {
      id: 1,
      label: "Inbox",
      to: `/chat/inbox/${data._id}`,
      icon: <FaMessage fontSize="18px"/>,
    },
    {
      id: 2,
      label: "Visitors",
      to: "/chat/visitors",
      icon: <FaGlobeAsia fontSize="18px"/>,
    },
    {
      id: 3,
      label: "Contacts",
      to: "/chat/contacts",
      icon: <IoPerson fontSize="18px"/>,
    },
    {
      id: 4,
      label: "Analytics",
      to: "/chat/analytics",
      icon: <MdAnalytics fontSize="18px"/>,
    },
  ];

  return (
    <div>
      <div className={Styles.Chat}>Chat Now</div>
      <div className={Styles.Chatwrapper}>
        {links.map((item) => (
          <div key={item.id}>
            {item.label === "Inbox" ? (
              <>
                <ListItem
                sx={{padding:"0"}}
                  onClick={handleInboxToggle}
                  className={
                    location.pathname.startsWith(`${item.to}`)
                      ? Styles.selectedChatMenu
                      : ""
                  }
                ><ListItemButton  disableRipple={true}  sx={{ padding: "13px 20px 12px 20px" }}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ color: "var(--dark-text-color)" }}>
                    {item.label}
                  </ListItemText>
                  <ListItemIcon sx={{display:"flex",justifyContent:"flex-end"}}>
                    {inboxOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemIcon>
                  </ListItemButton>
                </ListItem>
                <Collapse in={inboxOpen} timeout="auto" unmountOnExit>
                  {[
                     { text: "All", to: ":websiteId", icon: <InboxIcon /> },
                    {
                      text: "Unassigned",
                      to: "unassigned",
                      icon: <AssistantIcon />,
                    },
                    // { text: "Email", to: "email", icon: <EmailIcon/> },
                    { text: "Chat", to: "chat", icon: <ChatIcon /> },
                    { text: "Voice", to: "voice", icon: <CallIcon /> },
                    { text: "SMS", to: "sms", icon: <SmsIcon /> },
                    {
                      text: "Messenger",
                      to: "messenger",
                      icon: (
                        <FaFacebookMessenger style={{ fontSize: "20px" }} />
                      ),
                    },
                    {
                      text: "Instagram",
                      to: "instagram",
                      icon: <InstagramIcon />,
                    },
                    // { text: "Twitter", to: "twitter", icon: <TwitterIcon /> },
                    {
                      text: "WhatsApp",
                      to: "whatsapp",
                      icon: <WhatsAppIcon />,
                    },
                  ].map((data, index) => (
                    <Link
                      to={`/chat/inbox/${data.to}`}
                      key={index}
                      component={ListItem}
                      style={{ margin: 0, padding: 0, textDecoration: "none" }}
                    >
                      <ListItem
                        dense
                        className={
                          location.pathname.startsWith(`/chat/inbox/${data.to}`)
                            ? Styles.Chatselect
                            : ""
                        }
                        style={{ margin: 0, padding: 0 }}
                      >
                        <ListItemButton
                          disableRipple={true}
                          sx={{ padding: "10px 20px 10px 45px" }}
                          className={
                            location.pathname.startsWith(
                              `/chat/inbox/${data.to}`
                            )
                              ? Styles.selectedChatMenu
                              : ""
                          }
                        >
                          <ListItemIcon>{data.icon}</ListItemIcon>
                          <ListItemText
                            primary={data.text}
                            sx={{
                              margin: "-20px",
                              color: "var(--light-text-color)",
                            }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  ))}
                </Collapse>
              </>
            ) : (
              <NavLink
                key={item.id}
                to={item.to}
                style={{ textDecoration: "none" }}
              >
                <ListItem
                 sx={{padding:"0"}}
                  className={
                    location.pathname.startsWith(`${item.to}`)
                      ? Styles.selectedChatMenu
                      : ""
                  }
                >
                  <ListItemButton disableRipple={true} sx={{ padding: "13px 20px 12px 20px" }}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText sx={{ color: "var(--dark-text-color)" }}>
                      {item.label}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            )}
          </div>
        ))}
      </div>
    </div>

    
    // <ul className={Style.menu}>
    //   {links.map(item => (
    //     <li key={item.id}>
    //     <NavLink to={item.to} className={Style.menuItem}>
    //       {item.icon}
    //       {item.label}
    //     </NavLink>
    //     </li>
    //   ))}
    // </ul>
    
    // </div>
    // );
    // }; 
  
    
  );
};
export default ChatSidebar;

