import { Button, IconButton, TextField } from "@mui/material";

import Typography from "@mui/material/Typography";
import React, { useState } from "react";

import Table from "../../components/common/Table";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRecoilState } from "recoil";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { PassInvoiceId, loadingAll, login_atoms } from "../../Atoms/LoginAtoms";
import { api_url1 } from "../../globalSettings";
import FilterInv from "./FilterInv";
import CustomChip from "./StatusUi";
import PayNowButton from "./PayNowButton";
import { clientidd } from "../login/loginatom";
const typoTitle = {
  color: "#3367d6",
  fontSize: 16,
  fontWeight: 700,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BillingDetails() {
  const [openn, setOpenn] = React.useState(false);

  const handleClosee = (event, reason) => {
    setOpenn(false);
  };

  const [invoiceData, setInvoiceData] = useState([]);
  const [List, getlist] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [clientId] = useRecoilState(clientidd);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [deleteId, setDeleteId] = useState("");

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelte = (id) => {
    setDeleteId(id);

    setOpen(true);
  };

  //======================== filter Menu =======================/

  const [filter, setFilter] = useState("All");

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const initialDateRange = [
    {
      startDate: "",
      endDate: "",
      key: "selection",
    },
  ];
  const [open11, setOpen11] = React.useState(false);
  const [custom, setCustom] = React.useState("All");
  const [selectionRange, setSelectionRange] = useState(initialDateRange);
  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);

  /*============================================================GET ALL INVOICE DATA ===========================================================*/
  const [tableData, setTableData] = useState({
    page: 1,
    limit: 10,
  });
  const [pendingCount, setPendingCount] = useState("");
  const [paidCount, setPaidCount] = useState("");
  const [overDueCount, setOverDueCount] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [isLoading, setIsLoading] = useRecoilState(loadingAll);

  const handleClose11 = () => {
    setOpen11(false);
  };
  function getInvoiceData() {
    handleClose11();
    setIsLoading(true);
    const params = new URLSearchParams({
      page: tableData.page,
      limit: tableData.limit,
      filter: filter === "All" ? "all" : filter,
      date_filter: custom === "All" ? "all" : custom,
      customStartDate: selectionRange[0].startDate,
      customEndDate: selectionRange[0].endDate,
      clientId: loginAtom1,
    });

    return axios
      .get(`${api_url1}/invoice_by_client?${params}`)
      .then((result) => {
        let data = {
          hasNextPage: result.data.hasNextPage,
          hasPrevPage: result.data.hasPrevPage,
          limit: result.data.limit,
          page: result.data.page,
          totalPages: result.data.totalPages,
          nextPage: result.data.nextPage,
          totalDocs: result.data.totalDocs,
        };

        setTableData(data);

        if (filter === "All") {
          setTotalCount(result.data.totalDocs);
          setPendingCount(result.data.pendingInvoices.pendingCount);
          setPaidCount(result.data.paidInvoices.paidCount);
          setOverDueCount(result.data.overDueInvoices.overdueCount);
        }

        const formattedData = result.data.data.map((item) => {
          return {
            ...item,
            invoiceDate: moment(item.invoiceDate).format("DD/MM/YY"),
            DueDate: moment(item.DueDate).format("DD/MM/YY"),
            Total: new Intl.NumberFormat(item.locale, {
              style: "currency",
              currency: item.currency,
              minimumFractionDigits: 0,
            }).format(item.Total),
          };
        });

        setTableData(data);
        setInvoiceData(formattedData);
        setIsLoading(false);
        handleClose11();
        handleClose1();
        return formattedData;
      })
      .catch((err) => {
        setIsLoading(false);
        return err;
      });
  }
  function callFetchInvoice({ queryKey }) {
    if (
      custom === "custom" &&
      selectionRange[0].startDate &&
      open11 === false
    ) {
      return getInvoiceData();
    } else if (
      custom === "last_week" ||
      custom === "last_month" ||
      custom === "All"
    ) {
      return getInvoiceData();
    }
  }
  useQuery({
    queryKey: [
      "invoice",
      { custom, selectionRange, open11 },
      List,
      searchParam,
      filter,
      tableData.limit,
      tableData.page,
    ],
    queryFn: callFetchInvoice,
  });

  const dateFilter = [
    { label: "All", value: "All" },
    { label: "custom", value: "custom" },
    // { label: "This Month", value: "This Month" },
    { label: "Last Month", value: "last_month" },
    // { label: "This Week", value: "This Week" },
    { label: "Last Week", value: "last_week" },
  ];
  function dateSelector(e) {
    if (e.target.value === "custom") {
      setCustom(e.target.value);
      setOpen11(true);
    } else {
      setSelectionRange([
        {
          startDate: "",
          endDate: "",
          key: "selection",
        },
      ]);
      setCustom(e.target.value);
    }
  }

  const Columns = [
    {
      header: "Invoice Id",
      accessorKey: "invoiceId",
    },

    {
      header: "InvoiceDate",
      accessorKey: "invoiceDate",
    },
    {
      header: "DueDate",
      accessorKey: "DueDate",
    },
    {
      header: "paymentmethod",
      accessorKey: "paymentmethod",
    },
    {
      header: "status",
      accessorKey: "status",
      cell: (info) => {
        const status = info.row.original.status;
        return <CustomChip status={status} />;
      },
    },
    {
      header: "Total",
      accessorKey: "Total",
    },
    {
      header: "Actions",
      accessorKey: "_id",
      accessorFn: (row) => row._id,
      cell: (info) => {
        const status = info.row.original;
        return <PayNowButton status={status} />;
      },
    },
  ];

  /*============================================================================================================================================*/
  /*===========================================DELETE INVOICE ==================================================================================*/
  const DeleteInvoice = () => {
    axios
      .delete(`${api_url1}/deleteInvoice/${deleteId}`)
      .then((result) => {
        setOpenn(true);
        getlist(!List);
        setOpen(false);
      })
      .catch((error) => {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const tabData = [
    { label: "All", value: "All", count: totalCount },
    { label: "Pending", value: "pending", count: pendingCount },
    { label: "Paid", value: "paid", count: paidCount },
    { label: "Overdue", value: "overdue", count: overDueCount },
  ];
  function fetchBalance() {
    return axios
      .get(`${api_url1}/wallet/balance/${clientId}`)
      .then((result) => {
        return result.data;
      });
  }
  const { data } = useQuery({
    queryKey: ["walletBalance"],
    queryFn: fetchBalance,
  });

  /*============================================================================================================================================*/
  return (
    <>
      <div style={{ width: "100%", padding: 20 }}>
        <div
          className="titlebtn"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography style={typoTitle} variant="h5">
            Manage Invoice
          </Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: 30 }}>
              <FilterInv walletData={data} />
              <TextField
                id="standard-select-currency"
                size="small"
                select
                value={custom}
                onChange={(e) => dateSelector(e)}
                sx={{
                  width: "10rem",
                }}
                SelectProps={{
                  style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                  },
                }}
              >
                {dateFilter.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "5px",
                justifyContent: "end",
              }}
            >
              {selectionRange[0].startDate ? (
                <>
                  <h4
                    style={{
                      margin: "0",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    Filter By:{" "}
                  </h4>
                  {moment(selectionRange[0].startDate).format("DD/MM/YY") !==
                  "Invalid date" ? (
                    <Typography sx={{ fontSize: "12px" }}>
                      {moment(selectionRange[0].startDate).format("DD/MM/YY")} -{" "}
                      {moment(selectionRange[0].endDate).format("DD/MM/YY")}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ul className="tabs">
            {tabData.map((tab, index) => (
              <li
                key={index}
                className={index === activeTab ? "active" : ""}
                onClick={() => {
                  handleTabClick(index);
                  setFilter(tab.value);
                }}
              >
                {`${tab.label}(${tab.count})`}
              </li>
            ))}
          </ul>
        </div>

        <Table
          columns={Columns}
          data={invoiceData}
          setTableData={setTableData}
          tableData={tableData}
          getlist={getlist}
          List={List}
        />
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Delete Invoice ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You Like To Delete The Invoice The It Will Delete The Invoice Data
              Permanently
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Disagree
            </Button>
            <Button onClick={DeleteInvoice} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={openn} autoHideDuration={1000} onClose={handleClosee}>
          <Alert onClose={handleClosee} severity="error" sx={{ width: "100%" }}>
            Invoice Deleted uccessfully!
          </Alert>
        </Snackbar>
      </div>
      <Dialog
        open={open11}
        onClose={handleClose11}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DateRangePicker
          onChange={(item) => setSelectionRange([item.selection])}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={selectionRange}
          direction="horizontal"
        />
        <div></div>
        <DialogActions>
          <Button onClick={handleClose11}>Cancel</Button>
          <Button autoFocus onClick={getInvoiceData}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <style>
        {`.tabs {
            list-style: none;
            padding: 0;
            display: flex;
          }
          
          .tabs li {
            cursor: pointer;
            padding: 10px 20px;
            margin-right: 10px;
            font-size:12px;
            text-transform: uppercase;
          }
          
          .tabs li.active {
            background-color: #ffffff;
            font-weight: bold;
            border-bottom: 3px solid ${
              activeTab === 0
                ? "#5a7cc3"
                : activeTab === 1
                ? "#ede884"
                : activeTab === 2
                ? "#0dca0a"
                : activeTab === 3
                ? "red"
                : ""
            } ;
            text-transform: uppercase;
          }
          
          .tab-content {
            margin-top: 10px;
            display : flex;
            justify-content : center
          }`}
      </style>
    </>
  );
}
