import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Controller, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { useState } from 'react';

const ITEM_HEIGHT = 48;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200,
        },
    },
};

export default function CustomMultiSelect(props) {

    const { control, watch, setValue, getValues } = useFormContext();
    const [refresh, setRefresh] = useState(false);

    const toggle = watch(`${props.attributeId}.switchOn`);
    const select = watch(`${props.name}`);

    useEffect(() => {
        setRefresh(false);
        const optionsViewObj = props.options?.find(element => element.value == "view");
        if ((
            optionsViewObj &&
            getValues(`${props.name}`)?.indexOf('view') < 0) ||
            getValues(`${props.name}`)?.length === 0) {
            setValue(`${props.attributeId}.switchOn`, false);
        }
        setRefresh(true);
    }, [select]);

    useEffect(() => {

    }, [props.defaultValue]);

    return (
        refresh &&
        <Controller
            name={props.name}
            control={control}
            defaultValue={props.defaultValue ? props.defaultValue : []}
            render={({ value, onChange, field }) => (
                <FormControl>
                    <Select
                        autoWidth
                        {...field}
                        multiple
                        size={props.size ? props.size : "medium"}
                        // onChange={(val) => { onChange(); handleOnChange(val); }}
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                border: 'none'
                            }
                        }}
                        // renderValue={(selected) => {
                        //     return (
                        //         selected?.map((option) => option.label).join(", ")
                        //     );
                        // }}
                        MenuProps={MenuProps}
                    >
                        {props.options?.map((option) => (
                            <MenuItem value={option.value} key={option.value} disabled={option.disabled}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        />
    );
}