import TableRow from "./TableRow";
import TableHeader from "./TableHeader";
import { useSortableTable } from "./useSortableTable";
import "./table.css";
import { useEffect, useState } from "react";

const NativeTable = (props) => {
  const [tableData, handleSorting] = useSortableTable(props.data, props.columns);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  return (
    <>
      <table id="nativeTable">
        <TableHeader {...{ columns, handleSorting }} />
        <tbody>
          {tableData.map((data) => {
            return (
              <TableRow data={data} columns={columns} />
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default NativeTable;
