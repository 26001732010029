import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { CloseButton } from "../../../../components/CloseButton";
import { useState } from "react";

const Delete = (props) => {

    const [open, setOpen] = useState(true);

    function handleDialogClose(type) {
        setOpen(false);
        props.handleDialogClose(type);
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="delete-dialog"
                PaperProps={{
                    sx: { minWidth: '40%', maxWidth: '40%', overflow: 'hidden' }
                }}>

                <DialogTitle id="delete-dialog-title">
                    Delete
                    <CloseButton handleDialogClose={handleDialogClose} />
                </DialogTitle>
                <DialogContent>
                    The selected records and all related activities will be deleted.
                    Are you sure to proceed ?
                </DialogContent>

                <DialogActions>
                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1.5, pr: 1.5 }}>
                        <Button variant="outlined" onClick={handleDialogClose}>Cancel</Button>
                        <Button variant="contained" color="error" onClick={handleDialogClose}>Delete</Button>
                    </Stack>
                </DialogActions>

            </Dialog>
        </>
    )

}

export default Delete;