import { Alert, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import MapComponentNew from "./MapComponentNew";

import { onOffDuty, snackbarr } from "./trackeyatoms";
import TrackeyBox from "./TrackeyBoxList";
import TrackeyEmployeeList from "./TrackeyEmployeeList";

import "../../styles/trackey.css";
import { api_url } from "../../globalSettings";
import { useLoadScript } from "@react-google-maps/api";
import { empHistory } from "./trackeyatoms";
import { Typography } from "@mui/material";
import Attendance from "./Attendance";
import WorkHours from "./WorkHours";
import LeaveAproval from "./LeaveAproval";
//import recoil state
import {
  login_atoms,
  login_token,
  login_companyid,
  totalEmployee,
  empployeeSearch
} from "../../Atoms/LoginAtoms";
import History from "./History/History";
import Attendancereport from "./Attendancereport";
import WorkHoursReport from "./WorkHoursReport";
import Emptask from "./Emptask";
import EmpPerformance from "./EmpPerformance";
import EmpExpense from "./EmpExpense";
import EmptaskReports from "./EmptaskReports";
import EmpPerformanceReport from "./EmpPerformanceReport";
import EmpexpenseReport from "./EmpexpenseReport";
import TrackeyeDashBoard from "./TrackeyeDashBoard";


export default function TrackeyBody() {

  const [totemplist, setTotalEmpList] = useRecoilState(totalEmployee);
  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);

  const { pathname } = useLocation();
  const [emponlvelist, setEmponlvelist] = useState([]);
  const [empListonduty, setEmployeelistonduty] = useState([]);
  const [empListoffduty, setEmployeelistoffduty] = useState([]);
  const [empListNoData, setEmployeelistNOdata] = useState([]);
  const [snackbar, setSnackbar] = useRecoilState(snackbarr);
  const [ONFFDuty, setONFFDuty] = useRecoilState(onOffDuty);

  const [empHistory1, setEmpHistory1] = useRecoilState(empHistory);

  // ========================= get onduty employe =======================

  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [latiLOngi, setlatiLOngi] = useState([]);

  const [ondutyCount, setOndutyCount] = useState('');
  const [offdutyCount, setOffdutyCount] = useState('');
  const [onlveCount, setonlveCount] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [noDataCount, setNoDataCount] = useState('');


  // ================================  getempondutylist api call =====================================

  function getempondutylist() {
    
    
    const params = {
      token: loginToken1,
      // device: "mobile",
      company_id: company_idd,
    };
   
    axios
      .get(`${api_url}/employee/get_company_employee_onduty_details`, {
        params: { ...params },
      })

      .then((response) => {

        setEmployeelistonduty(response.data.data);
        setOndutyCount(response.data.data.on_duty_employee_count);
        
      })
      .catch(function (error) {
        if (error.response) {
 
        } else if (error.request) {

        } else {

        }

      });
   
  }

  // ================================  getempoffdutylist api call =====================================

  function getempoffdutylist() {
    
    const params = {
      token: loginToken1,
      company_id: company_idd,
    };
    
    axios
      .get(`${api_url}/employee/get_company_employee_offduty_details`, {
        params: { ...params },
      })
      .then((response) => {
       
        setEmployeelistoffduty(response.data.data);
        setOffdutyCount(response.data.data.off_duty_employee_count);
       
      })
      .catch(function (error) {
        if (error.response) {

        } else if (error.request) {

        } else {

        }       
      });
    
  }

  // ================================  Leave api call =====================================

  function getemponleavelist() {
   
    const params = {
      token: loginToken1,
     
      company_id: company_idd,
    };
    
    axios
      .get(`${api_url}/employee/get_company_leave_employee_details`, {
        params: { ...params },
      })

      .then((response) => {
      
        setlatiLOngi(response.data.data.on_duty_employee_details);
        
        setEmponlvelist(response.data.data);
        setonlveCount(response.data.data.leave_employee_count);
       
      })
      .catch(function (error) {
        if (error.response) {

        } else if (error.request) {

        } else {

        }

      });
    
  }

  // ================================  total api call =====================================

  function gettotalemplist() {
  
    const params = {
      token: loginToken1,
      company_id: company_idd,
    };
   
    axios
    
      .get(`${api_url}/employee/totalEmployee`, {
        params: { ...params },
      })

      .then((response) => {
       
        setTotalEmpList(response.data.data);
        setTotalCount(response.data.data.all_employee_count);
        
      })
      .catch(function (error) {
        if (error.response) {

        } else if (error.request) {
         
        
        } else {
        }
      
      });
    
  }

  // ================================  total api call =====================================

  function getempnodatalist() {
   
    const params = {
      // token: loginToken1,
      company_id: company_idd,
    };
    
    axios
      .get(`${api_url}/employee/noData?`, {
        params: { ...params },
      })

      .then((response) => {
       
        setEmployeelistNOdata(response.data.data)
        setNoDataCount(response.data.data.noData_employee_count)
       
      })
      .catch(function (error) {
        if (error.response) {

        } else if (error.request) {

        } else {

        }

      });
    
  }

  useEffect(() => {

    if (ONFFDuty === "ON_DUTY") {
     
      setEmponlvelist("");
      setEmployeelistNOdata("");
      setTotalEmpList("");
      setEmployeelistoffduty("");
      getempondutylist();
      const interval = setInterval(() => {
        getempondutylist();
      }, 10000);
      return () => clearInterval(interval);
    } else if (ONFFDuty == "OFF_DUTY") {

      setEmponlvelist("");
      setEmployeelistNOdata("");
      setTotalEmpList("");
      setEmployeelistonduty("");
      getempoffdutylist();
      const interval = setInterval(() => {
        getempoffdutylist();
      }, 10000);
      return () => clearInterval(interval);
    } else if (ONFFDuty == "ON_LEAVE") {

      setEmployeelistNOdata("");
      setEmployeelistoffduty("");
      setEmployeelistonduty("");
      setTotalEmpList("");
      getemponleavelist();
     
    } else if (ONFFDuty == "NO_DATA") {

      setEmponlvelist("");
      setEmployeelistoffduty("");
      setEmployeelistonduty("");
      setTotalEmpList("");
      getempnodatalist();
      
    } else if (ONFFDuty == "ALL") {

      setEmponlvelist("");
      setEmployeelistNOdata("");
      setEmployeelistoffduty("");
      setEmployeelistonduty("");
      gettotalemplist();
      const interval = setInterval(() => {
        gettotalemplist();
      }, 10000);
      return () => clearInterval(interval);
    } else {
      
      gettotalemplist();
      const interval = setInterval(() => {
        gettotalemplist();
      }, 10000);
      return () => clearInterval(interval);
    }
   
  }, [ONFFDuty , company_idd]);


  function handleclosesnack() {
    setSnackbar((state) => ({ ...state, open: false }));
  }

  useEffect(() => {
    getempoffdutylist();
    getempondutylist();
    getemponleavelist();
    getempnodatalist()
    gettotalemplist();
  }, [company_idd]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB3ZlA9dlt1bnsJyUPn-Ss3IPM8awi0F2g",
    libraries:['places']
  });

  return (
    <div>
      <Snackbar open={snackbar.open} onClose={handleclosesnack}>
        <Alert severity={snackbar.seveority}>{snackbar.message}</Alert>
      </Snackbar>

      <>
        {pathname === "/trackeye" ? (
          <>
            {" "}
            <Typography
              variant="subtitle2"
              align="left"
              style={{
                width: "100%",
                color: "White",
                backgroundColor: "Black",
                paddingLeft: "15px",
                fontSize: "20px",
                fontWeight: "300",
              }}
            >
              Tracking{" "}
            </Typography>
            {empHistory1 === "last_hour" ? (
              <>
                <div id="trackeye">
                  <History />
                </div>
              </>
            ) : empHistory1 === "Today" ? (
              <div id="trackeye">
                <History />
              </div>
            ) : empHistory1 === "Yesterday" ? (
              <div id="trackeye">
                <History />
              </div>
            ) : empHistory1 === "This_Week" ? (
              <div id="trackeye">
                <History />
              </div>
            ) : empHistory1 === "Last_Week" ? (
              <div id="trackeye">
                <History />
              </div>
            ) : empHistory1 === "This_Month" ? (
              <div id="trackeye">
                <History />
              </div>
            ) : empHistory1 === "Last_Month" ? (
              <div id="trackeye">
                <History />
              </div>
            ) : (
              <div id="trackeye">
                <div id="trakey_EmployeeListMap_left">
                  <TrackeyBox //trackeyboxlist
                    totemplist={totalCount}
                    empListonduty={ondutyCount}
                    empListoffduty={offdutyCount}
                    emponlvelist={onlveCount}
                    empnodatalist={noDataCount}
                  />

               

                <TrackeyEmployeeList
                    // getemployeelist={getemployeelist}
                    totemplist={totemplist}
                    empListonduty={empListonduty}
                    empListoffduty={empListoffduty}
                    emponlvelist={emponlvelist}
                     empnodatalist={empListNoData}
                  />
                </div>

                <div className="trakey_EmployeeListMap_right">



                  {isLoaded ? (
                    Object.keys(totemplist).length ||
                    Object.keys(empListonduty).length ||
                    Object.keys(empListoffduty).length ||
                    Object.keys(emponlvelist).length >= 1 ? (
                      <MapComponentNew
                        totemplist={totemplist}
                        empListonduty={empListonduty}
                        empListoffduty={empListoffduty}
                        emponlvelist={emponlvelist}
                        empnodatalist={empListNoData}
                      />
                    ) : null
                  ) : (
                    ""
                  )}
  
                </div>
              </div>
            )}
          </>
        ) : pathname === "/trackeye/attendence" ? (
          <Attendance />
        ) : pathname === "/trackeye/workhours" ? (
          <WorkHours />
        ) : pathname === "/trackeye/emptask" ? (
          <Emptask />
        ): pathname === "/trackeye/empPerformance" ? (
          <EmpPerformance />
        ): pathname === "/trackeye/empExpense" ? (
          <EmpExpense />
        )
        : pathname === "/trackeye/leaveapproval" ? (
          <LeaveAproval />
        ) : pathname === "/trackeye/attendence/report" ? (
          <Attendancereport />
        ) : pathname === "/trackeye/workhours/report" ? (
          <WorkHoursReport />
        ): pathname === "/trackeye/emptask/report" ? (
          <EmptaskReports />
        ): pathname === "/trackeye/empPerformance/report" ? (
          <EmpPerformanceReport />
        ): pathname === "/trackeye/empExpense/report" ? (
          <EmpexpenseReport />
        ) : pathname === "/trackeye/TrackeyeDashboard" ? (
          <TrackeyeDashBoard/>
        ): (
          ""
        )}
      </>
    </div>
  );
}
