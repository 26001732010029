export const api_url1 = "https://liveserver.nowdigitaleasy.com:5000"
export const api_url = "https://liveserver.nowdigitaleasy.com:5000"
export const crm_api_url = "https://crmlive.nowdigitaleasy.com:5050"
// export const crm_api_url = "http://localhost:5050"

//   process.env.NODE_ENV === "development"
//     ? "https://devserver.nowdigitaleasy.com:3505"
//     : "https://liveserver.nowdigitaleasy.com:5000";

// export const api_url1 =
//   process.env.NODE_ENV === "development"
//     ? "https://devserver.nowdigitaleasy.com:3505"
//     : "https://liveserver.nowdigitaleasy.com:5000";

// export const api_url1 =
//   process.env.NODE_ENV === "development"
//     ? "https://liveserver.nowdigitaleasy.com:5000"
//     : "https://devserver.nowdigitaleasy.com:3505";

export let axiosOptions = {
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
};

// export const api_url =
//   process.env.NODE_ENV === "development"
//     ? "https://devserver.nowdigitaleasy.com:3505"
//     : "https://liveserver.nowdigitaleasy.com:5000"; //https://server.nowdigitaleasy.com:3505  //http://localhost:3505
