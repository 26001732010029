import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";

const AddAdminUser = ({ open, handleClose }) => {
  const gridCellStyle = {
    border: "1px solid #e9e9eb",
    textAlign: "center",
    borderBottom: "none",
  };
  const gridLastCellStyle = {
    border: "1px solid #e9e9eb",
    textAlign: "center",
  };
  const customOutlineButtonStyle = {
    background: "#468def",
    border: "1px solid #468def",
    borderRadius: "2px",
    color: "#0000",
    cursor: "pointer",
    display: "inline-block",
    fontFamily: "OpenSans",
    fontSize: "12px",
    lineHeight: 1,
    outline: "none",
    padding: "7px 14px",
    transition: "0.2s",
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth={"md"} fullWidth>
      <DialogTitle>
        <div className="alignHeader">
          <div>
            <Typography className="contact-title">
              Create admin user for google workspace account
            </Typography>
            <Typography className="contact-title-domainName">For:</Typography>
          </div>
          <div>
            <IconButton component="span" onClick={() => {}}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item xs={12} style={gridCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Email*</label>
              <input className="cd-input" />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Password*</label>
              <input className="cd-input" />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Confirm Password*</label>
              <input className="cd-input" />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
          <div className="cd-align">
              <label className="cd-title">First Name*</label>
              <input className="cd-input" />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Last Name*</label>
              <input className="cd-input" />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Name*</label>
              <input className="cd-input" />
            </div>
          </Grid>
          <Grid item xs={6} style={gridCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Organization*</label>
              <input className="cd-input" disabled />
            </div>
          </Grid>

          <Grid item xs={6} style={gridLastCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Alternative Mail*</label>
              <input className="cd-input" />
            </div>
          </Grid>
          <Grid item xs={6} style={gridLastCellStyle}>
          <div className="cd-align">
              <label className="cd-title">Zip Code*</label>
              <input className="cd-input" />
            </div>
          </Grid>
        </Grid>
        <Button
          color="primary"
          variant="outlined"
          style={customOutlineButtonStyle}
        >
         Create Admin Account
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AddAdminUser;
