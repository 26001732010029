import React from 'react'
import '../../../../pages/pipelines/pipelines.css';
import { getCoreRowModel, getSortedRowModel, useReactTable, } from '@tanstack/react-table'
import { useInfiniteQuery, } from '@tanstack/react-query'
import { getContactColumns, getContactTableData } from '../../../../webservices/PipelineService'
import IndeterminateCheckbox from '../../../../components/IndeterminateCheckbox';
import { useRecoilState } from 'recoil';
import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useReducer } from 'react';
import variables from "../../../../assets/styles/_colors.scss";
import ModifyPipelineColumns from '../../../pipelines/view/ModifyPipelineColumns'
import { contacts_atom } from '../../../../../../Atoms/CrmAtoms'
import ContactTableRow from './ContactTableRow'
import { CrmTableHeader } from '../../../../components/table/CrmTableHeader';
import { CrmTableFooter } from '../../../../components/table/CrmTableFooter';

const fetchSize = 10;

const ContactsTable = (props) => {

    const tableContainerRef = useRef(null);
    const [sorting, setSorting] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [columnVisibility, setColumnVisibility] = useState({});
    const [columnOrder, setColumnOrder] = useState([]);
    const [cols, setCols] = useState([]);
    const rerender = useReducer(() => ({}), {})[1];
    const [contactsAtom, setContactsAtom] = useRecoilState(contacts_atom);

    const defaultColumns = [{
        accessorKey: "select",
        header: ({ table }) => (
            <IndeterminateCheckbox
                {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler()
                }}
            />
        ),
        cell: ({ row }) => (
            <IndeterminateCheckbox
                {...{
                    checked: row.getIsSelected(),
                    disabled: !row.getCanSelect(),
                    onChange: row.getToggleSelectedHandler(),
                }}
            />
        ),
        enableSorting: false,
        enableHiding: false,
        hideOptions: true
    },
    {
        accessorKey: 'activity',
        header: '',
        enableHiding: false,
        enableSorting: false,
        hideOptions: true
    }];

    const getSelectedCols = (() => {
        let visibleCols = [];
        let orderedCols = ["select", "activity"];
        contactsAtom?.displayedColumns?.contactsTable?.map((c) => {
            orderedCols.push(c.accessorKey);
            visibleCols[c.accessorKey] = c.selected ? true : false;
        });
        setCols(visibleCols);
        setColumnOrder(orderedCols);
    });

    const columns = useMemo(
        () => defaultColumns.concat(props.columns),
        []
    )

    const { data, fetchNextPage, isFetching, isLoading } =
        useInfiniteQuery({
            queryKey: [`contacts-table-data`, sorting],
            queryFn: async ({ pageParam = 0 }) => {
                const start = pageParam * fetchSize
                const fetchedData = getContactTableData(start, fetchSize, sorting)
                return fetchedData
            },
            getNextPageParam: (_lastGroup, groups) => groups.length,
            placeholderData: {},
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages?.flatMap(page => page.rows) ?? [],
        [data]
    )
    const meta = data?.pages?.[0]?.meta ?? 0
    const totalFetched = flatData.length;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement
                if (
                    scrollHeight - scrollTop - clientHeight < 100 &&
                    !isFetching &&
                    totalFetched < meta.totalRowCount
                ) {
                    fetchNextPage()
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, meta.totalRowCount]
    )

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current)
    }, [fetchMoreOnBottomReached])

    const table = useReactTable({
        data: flatData,
        columns: columns,
        state: {
            sorting,
            columnVisibility,
            columnOrder,
            rowSelection
        },
        onColumnVisibilityChange: setColumnVisibility,
        onColumnOrderChange: setColumnOrder,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        onRowSelectionChange: setRowSelection,
        getSortedRowModel: getSortedRowModel(),
        // debugTable: true,
    })

    useEffect(() => {
        let a = Object.assign({}, contactsAtom);
        if (a.displayedColumns?.contactsTable?.length === 0) {
            getContactColumns().then((response) => {
                const obj = {
                    ...contactsAtom,
                    "displayedColumns": {
                        ...contactsAtom["displayedColumns"],
                        "contactsTable": response.columns
                    }
                }
                setContactsAtom(obj);
            });
        }
        getSelectedCols();
    }, []);

    useEffect(() => {
        table.setColumnVisibility(cols);
        rerender();
    }, [cols]);

    useEffect(() => {
        table.setColumnOrder(columnOrder);
        rerender();
    }, [columnOrder]);

    useEffect(() => {
        getSelectedCols();
    }, [contactsAtom.displayedColumns?.contactsTable]);

    useEffect(() => {
        const selectedRows = table?.getSelectedRowModel()?.flatRows?.length;
        let a = Object.assign({}, contactsAtom);
        a.selectedTableData = selectedRows;
        setContactsAtom(a);
    }, [rowSelection]);

    useEffect(() => {
        if (contactsAtom.resetBulkSelection) {
            table.resetRowSelection();
            let a = Object.assign({}, contactsAtom);
            a.resetBulkSelection = false;
            setContactsAtom(a);
        }
    }, [contactsAtom.resetBulkSelection]);

    if (isLoading) {
        return <>Loading...</>
    }

    return (
        <Box sx={{
            overflow: 'hidden', width: "100%", color: "#000",
            bgcolor: `${variables.tabsBackgroundColor}`
        }}>
            <ModifyPipelineColumns module="contactsTable" />
            <Box
                sx={{ overflow: 'auto', height: "79vh", width: "100%", color: "#000" }}
                onScroll={e => fetchMoreOnBottomReached(e.target)}
                ref={tableContainerRef}
            >
                <table id="pipelineTable">
                    <thead>
                        <CrmTableHeader table={table} />
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((rowEl) => {
                            return (
                                <ContactTableRow key={rowEl.original.id} rowEl={rowEl} />
                            );
                        })}
                    </tbody>
                </table>
            </Box>
            <Stack direction="row" justifyContent="center" sx={{
                width: props.drawerOpen ? "calc(100vw - 22vw - 60px)" : "calc(100vw - 60px)", maxHeight: 70, position: "absolute", bottom: 2,
                padding: "12px 20px", background: `${variables.lightGreyBackgroundColor}`
            }}>
                <CrmTableFooter table={table} meta={meta} data={flatData} />
            </Stack>
        </Box>
    )
}

export default ContactsTable;