import React from 'react';
import { Grid, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { CustomInputField } from '../components/CustomInputField';
import { CustomAutoComplete } from '../components/CustomAutocomplete';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getProfilesDropdown } from '../../../webservices/UserControlService';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const StyledTypography = withStyles({
    root: {
        paddingBottom: 16,
        fontWeight: 600
    }
})(Typography);

export default function BasicInfoForm() {

    const queryClient = useQueryClient();
    const [permissions, setPermissions] = useState([]);

    const {
        register,
        control,
        formState: { errors }
    } = useFormContext();

    const {
        data,
        isFetched
    } = useQuery({
        queryKey: ['getAllProfilesDropdown'],
        queryFn: () => getProfilesDropdown(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        setPermissions(data);
    }, [isFetched]);

    useEffect(() => {
        console.log(errors)
    }, [errors]);

    return (
        <React.Fragment>
            <Grid container direction="row" spacing={3} sx={{ m: 0, width: "100%", pr: 2, pl: 0.5 }}>
                <Grid item xs={12} sm={12} sx={{ pl: "16px !important" }}>
                    <StyledTypography component="div">
                        Pipeline Info
                    </StyledTypography>
                    <CustomInputField label="Pipeline Name*" name="pipelineName" />
                </Grid>
                <Grid item xs={12} sm={12} sx={{ pl: "16px !important" }}>
                    <StyledTypography component="div">
                        How would you like to name the records in this Pipeline?
                    </StyledTypography>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <CustomInputField label="Plural*" name="plural" />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <CustomInputField label="Singular*" name="singular" />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} sx={{ pl: "16px !important" }}>

                    <StyledTypography component="div">
                        Pipeline Permissions
                    </StyledTypography>

                    <CustomAutoComplete name="permissions" label="Permissions*" options={permissions ? permissions : []} defaultValue={[]} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
