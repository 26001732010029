import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ContactDrawer from "../ContactDrawer/ContactDrawer";
import {
  login_companyid,profile_trigger
} from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import {
  Button, Snackbar, Alert
} from "@mui/material";
import { api_url } from "../../globalSettings";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddContactDrawer from "./AddContactDrawer";
import ContactDetails from "./ContactDetails";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NavLink } from "react-router-dom/dist";



const ITEM_HEIGHT = 48;






export default function Allcontactlist() {

  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 onNext={handleNext} />;
      case 2:
        return <Step2 onNext={handleNext} onPrevious={handlePrevious} />;
      case 3:
        return <Step3 onPrevious={handlePrevious} />;
      default:
        return null;
    }
  };

  return <div>{renderStep()}</div>;
};

function Step1({ onNext, onPrevious }) {
  const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
  const [contactId, setContactId] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackbaropen, setSnackbaropen] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const open = Boolean(anchorEl);
  
  const handleClose = () => {
    setAnchorEl(null);
  };
 


    //table state
   
    // ========================================= Get Customer Api Call ============================================

  const [customerList , setCustomerList] = useState([]);

  useEffect(() => {
    axios
      .get(`${api_url}/crm_customer/getCustomerByCompany/${company_idd}`)
      .then((responce) => {
      
        setCustomerList(responce.data.data);
      })
      .catch((err) => {
      });
  },[])


    // ============== Delete Contact ====================

    const deleteContact = () => {
 
      axios
        .delete(`${api_url}/crm_customer/deleteCustomerById/${contactId}` )
        .then((result) => {
          setSnackbaropen({
            open: true,
            message: result.data.message,
            severity: "error",
            actionColor: "#fdeded",
          });
          setProfileTrigger(!profileTrigger);
          handleClose()
          // setOpen2(false);
        })
        .catch((error) => {
          if (error.response) {
           
          } else if (error.request) {
           
          } else {
         
          }
        });
    };


  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);



  

  //   ================================ DRAWER =====================================

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
    
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };


  
 //   ================================ DRAWER =====================================

 const [openDrawer1, setopenDrawer1] = useState(false);

 const toggleDrawer1 = (event) => {
 
   if (
     event.type === "keydown" &&
     (event.key === "Tab" || event.key === "Shift")
   ) {
     return;
   }
   setopenDrawer1(openDrawer1 ? false : true);
 };


  


  return (
  <>
  {/* <ContactDetails /> */}

  <Snackbar
  open={snackbaropen.open}
  onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
  autoHideDuration={6000}
  >
  <Alert
    severity={snackbaropen.severity}
    style={{ backgroundColor: `${snackbaropen.actionColor}` }}
  >
    {snackbaropen.message}
  </Alert>
  </Snackbar>
  <div className="kavin" 
 style={{display:"flex" , justifyContent :"end" , marginBottom : "20px"}}
   > 
   
              <Button
                style={{
                  backgroundColor: "green",
                  color: "white",
                  padding: "5px 20px",
                  borderRadius: 10,
                  // position: "absolute",
                  // right: "20px",
                  // top: "5px",0
                  display: "flex",
                  alignItems: "center",
                }}
                className="dashbutton"
                onClick={toggleDrawer1}
              >
                <AddCircleOutlineIcon
                  fontSize="small"
                  style={{ paddingRight: 3 }}
                />
                Add Contacts
              </Button>
              <AddContactDrawer
                open={openDrawer1}
                toggleDrawer={toggleDrawer1}
              />
   </div>
    {/* {customerList.length > 0 ?  */}
    <div style={{ padding: 20 }}>
      {" "}
     
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className="table_head">First Name</TableCell>
              <TableCell className="table_head">Last Name</TableCell>
              <TableCell className="table_head">Status</TableCell>
              <TableCell className="table_head">Emails</TableCell>
              <TableCell className="table_head">Mobile Number</TableCell>
              <TableCell className="table_head">Priority</TableCell>
              <TableCell className="table_head">Country</TableCell>
              <TableCell className="table_head">Label</TableCell>
              <TableCell className="table_head">Action</TableCell>
            </TableRow>
          </TableHead>

          {/* {customerList.map((item) => {
                return ( */}
          <TableRow
            hover
            //key={item}
            style={{ cursor: "pointer" }}
            className="companylisttbl"
          >
            <TableCell
              //  title={item.contact_person}
              //onClick={toggleDrawer}
              // title={item.company_name}
                onClick={() => {
                  //setSelectedContact(item);
                //  onNext();
                }}
      
              className="companypersonalinfo"
            >
              {/* <NavLink to="/contacts/contactDetails">{item.first_name}</NavLink> */}
              <NavLink to="/contacts/contactDetails">Kavin</NavLink>
            </TableCell>
            <TableCell
           // onClick={toggleDrawer}
              //  title={item.contact_person}
              className="companypersonalinfo"
               onClick={() => {
                 // setSelectedContact(item);
                 // onNext();
                }}
            >
              {/* <NavLink to="/contacts/contact/details">{item.last_name}</NavLink> */}
            </TableCell>
            <TableCell
              //  title={item.contact_person}
              className="companypersonalinfo"
            >
              {/* {item.Status.status_name} */}
            </TableCell>
           
            <TableCell
              //  title={item.contact_person}
              className="companypersonalinfo"
            >
              {/* {item.email} */}
            </TableCell>
            
            <TableCell
              // title={item.email}
              className="companypersonalinfo"
            >
              {/* {item.phone} */}
            </TableCell>
            <TableCell
              // title={item.email}
              className="companypersonalinfo"
            >
              {/* {item.priority} */}
            </TableCell>
            <TableCell
              // title={item.email}
              className="companypersonalinfo"
            >
              {/* {item.country} */}
            </TableCell>
            <TableCell
              // title={item.email}
              className="companypersonalinfo"
            >
              
            </TableCell>
            
            <TableCell
              // title={item.phone_number}
              className="companypersonalinfo"
            >
             <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
      >
        <MoreVertIcon />
      </IconButton>
    </div>
            </TableCell>
          </TableRow>
         {/* );
         })}  */}

        </Table>
        <ContactDrawer
              open={openDrawer}
              toggleDrawer={toggleDrawer}
              //selectedEmp={SelectedEmpDrawer}
            />
        <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '12ch',
              },
            }}
          ><div style={{display : "flex" , flexDirection:"column" , alignItems:"flex-start" , justifyContent:"flex-start"}}>
              <MenuItem   style={{width : "100%" , display : "flex" , justifyContent : "flex-start",padding:5}} onClick={handleClose}>
              <EditOutlinedIcon style={{marginRight : "5px"}} fontSize="small"/> Edit
              </MenuItem>
               <MenuItem  style={{width : "100%" , display : "flex" , justifyContent : "flex-start",padding:5}} onClick={deleteContact}>
              <DeleteOutlineIcon style={{marginRight : "5px" , color :"red"}} fontSize="small" /> Delete
               </MenuItem>
            </div>
          </Menu>
      </TableContainer>
        {/* <div className="table-container">
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </button>
      </div>
    </div> */}
          
 
    </div> 
    
    {/* : <div className="task-table-container1">
          <img src={noTask} alt="loading" />
          <p style={{ margin: "20px", fontSize: "14px" }}>There is no Contacts</p>
          <style>
            {" "}
            {`
        .task-table-container1 {
  display: flex;
  justify-content: center;
  align-items:center;
  height:100vh;
  flex-direction : column;
  padding: 20px;
  font-family: "Poppins",sans-serif;
}
`}
          </style>
        </div>} */}
      <style>
      {`
      .clienttable {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        /* Table container styles */
.table-container {
  margin: 20px;
  font-family: Arial, sans-serif;
  width : 100%
}

/* Table styles */
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 8px;
  
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

/* Pagination styles */
.pagination {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.pagination button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Page number display */
.pagination span {
  font-weight: bold;
}
        `}
    </style>
    </>
    
    // <ContactDetails />
  );
}

function Step2({ onNext, onPrevious }) {
  return (
    <> 
      <IconButton color="primary" onClick={onPrevious} aria-label="add to shopping cart">
        <ArrowBackIcon />
      </IconButton>
      <ContactDetails /> 
    </>
  );
}

function Step3({ onPrevious }) {
  return (
    <div>
      <h2>Step 3</h2>
      <button onClick={onPrevious}>Previous</button>
    </div>
  );
}
