import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";

const rows = [
    {
        subject: "Test subject 1",
        from: "User 1",
        to: "User 2",
        date: "Feb 10, 2024",
        source: "Test source"
    },
    {
        subject: "Test subject 2",
        from: "User 2",
        to: "User 3",
        date: "March 10, 2024",
        source: "Test source 1"
    }
]
const Emails = () => {

    return (
        <>
            <TableContainer component={Paper} sx={{ boxShadow: "none", borderBottom: `1px solid ${variables.borderColor}` }}>
                <Table sx={{
                    fontSize: "14px",
                    "& .MuiTableRow-root:hover": {
                        backgroundColor: `whitesmoke`
                    }
                }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontSize: "1rem" }} width="25%"> Subject </TableCell>
                            <TableCell sx={{ fontSize: "1rem" }} width="20%"> From </TableCell>
                            <TableCell sx={{ fontSize: "1rem" }} width="20%"> To </TableCell>
                            <TableCell sx={{ fontSize: "1rem" }} width="20%"> Date </TableCell>
                            <TableCell sx={{ fontSize: "1rem" }} width="15%"> Source </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ fontSize: "1rem", fontWeight: "bold" }} width="25%">{row.subject}</TableCell>
                                <TableCell sx={{ fontSize: "1rem" }} width="20%">{row.from}</TableCell>
                                <TableCell sx={{ fontSize: "1rem" }} width="20%">{row.to}</TableCell>
                                <TableCell sx={{ fontSize: "1rem" }} width="20%">{row.date}</TableCell>
                                <TableCell sx={{ fontSize: "1rem" }} width="15%">{row.source}</TableCell>
                            </TableRow>
                        ))}
                        {rows.length === 0 &&
                            <TableCell colSpan={5} align="center" sx={{ fontSize: "1rem" }} >Looks like there aren't any emails to show.</TableCell>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
export default Emails;