import { Alert, Divider, Grid, Snackbar, TextField, Tooltip } from '@mui/material';
import React , { useState, useCallback, useEffect } from 'react'
// import { Box, Typography } from '@mui/material'
import styled from "styled-components";
import { profile_trigger, selected_contact } from '../../Atoms/LoginAtoms';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { api_url } from '../../globalSettings';
import { LoadingButton } from '@mui/lab';
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import trash from "../../images/trash.png";
import downloadpng from "../../images/download.png"
import noTask from "../../images/CRM/plan_7404361.png";
import { saveAs } from 'file-saver';

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column;
  font-size:12px
`;

const Typography = styled.div`
color:#4fb385;
font: 12px;
`;

const TableContainer = styled.div`
width:100%;
`;

const TableColumn = styled.div`
display:flex;
flex-direction:row;
font-size:12px;
`;

const TableRow = styled.div`
width:20%;
padding:5px;
text-align:right;
`;

const TableRow1 = styled.div`
width:80%;
padding:5px
`;
const ContactAddFile = () => {
    const [selectedContact] = useRecoilState(selected_contact);
    const [description , setDescription] = React.useState('');
    const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
    const [loading , setLoading ] = useState(false);
    const [selectedContactDetails, setSelectedContactDetails] =
    useState(selectedContact);
    const [snackbaropen, setSnackbaropen] = React.useState({
        open: false,
        message: "",
        severity: "",
      });
//   ================================   FILE SELECT  =====================================
const [selectedFile, setSelectedFile] = React.useState(null);


const onChangeHandler = useCallback((event) => {
 setSelectedFile(event.target.files[0]);
}, []);

// ================= CUSTOMER LIST BY CUSTOMER ID =========================

useEffect(() => {
  axios
    .get(
      `${api_url}/crm_customer/getCustomerByCustomerId/${selectedContact._id}`
    )
    .then((responce) => {
    
      setSelectedContactDetails(responce.data.data[0]);
    })
    .catch((err) => {
    });
}, [selectedContact, profileTrigger]);

    const addFile = () => {

        const size = selectedFile.size/1000; 

        setLoading(true);
        const formdata = new FormData();
        formdata.append("customer_id",selectedContact._id );
    formdata.append("size", size);
    formdata.append("image", selectedFile);
    formdata.append("description", description);

    
    
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };
        axios({
          method: "post",
          url: `${api_url}/crm_customer/addFile`,
          data: formdata,
          config,
        })
          .then(function (response) {
          
            setSnackbaropen({
                open: true,
                message: response.data.message,
                severity: "success",
                actionColor: "#edf7ed",
              });
              setDescription('');
            //   setSelectedFile('');
            setLoading(false);
            setProfileTrigger(!profileTrigger);
            //handleClose14();
          })
          .catch(function (error) {
            if (error.response) {
          
            } else if (error.request) {
             
            } else {
             
            }
          });
      
      }

     
  return (
    <div style={{display:"flex" , flexDirection:"column" , width:"100%"}}>
    <Box > 
        <div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
        <Snackbar
open={snackbaropen.open}
onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
autoHideDuration={6000}
>
<Alert
  severity={snackbaropen.severity}
  style={{ backgroundColor: `${snackbaropen.actionColor}` }}
>
  {snackbaropen.message}
</Alert>
</Snackbar>
    <Typography >ADD NEW FILE</Typography>
    </div>
    <Divider style={{margin :"10px"}}/>
    <div style={{ width: "100%", fontSize: "12px" , display:"flex" , alignItems : "center" , flexDirection : "column" }}>    <Grid container spacing={1} >
    <Grid item xs={12} sm={12} md={6}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          File Description{" "}
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <TextField
    variant="outlined"
    type="text"
    rows={3}
    InputProps={{
      style: {
        borderRadius: "0px",
      },
    }}
    value={description}
    onChange={(e)=>{setDescription(e.target.value)}}
    multiline
    fullWidth
    placeholder="File Description"
  />
        </Grid>
      </Grid>
    </Grid>{" "}
    <Grid item xs={12} sm={12} md={6} >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          Select File To Upload{" "}
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <div>
      <input type="file" onChange={onChangeHandler} />
    </div> 
        </Grid>
      </Grid>
    </Grid>{" "}
    </Grid>
    <LoadingButton 
    style={{
      width: "100px",
      marginTop: "20px",
    }}
    variant="contained" 
    disabled={description.length > 0 && selectedFile != null? false : true}
    loading={loading == true ? true : false} 
 onClick={addFile}
  >
    Add File{" "}
  </LoadingButton >{" "}
  </div>
    </Box>
    <Box > 
    <div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
        
    <Typography >FILES</Typography>
    </div>
    <Divider style={{margin :"10px"}}/>
    {selectedContactDetails.files.length > 0 ? 
    
    <div className="task-table-container scroll">
      <table className="task-table">
        <thead>
          <tr>
          {/* <th>ID</th> */}
            <th>File Name</th>
            <th>Upload On</th>
            <th>Size</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            //   (
            //   taskList
            // ).filter((task) => company_idd == "All" || task.company_id === company_idd)
            selectedContactDetails.files.length > 0 ?    selectedContactDetails.files.map((files) => (
              <React.Fragment 
               key={files._id}
              >
                <tr
                //   className={`task-row ${
                //     expandedTaskId === task._id ? "active" : ""
                //   }`}
                >
                 
                  <td style={{ width: "20%" }}>
                  {files.image_name}
                  </td>
                  <td style={{ width: "20%", fontSize: "12px" }}>
                  {new Date(files.date)
                          .toLocaleDateString()
                          .split("/")
                          .join("-")}{" "}
                        {new Date(files.date).toLocaleTimeString()}
                  </td>
                  <td style={{ width: "20%" }}>
                  {files.size} KB
                  </td>
                  <td style={{ width: "30%" }}>
                  {files.description}
                  </td>
                  <td style={{ width: "10%" , display:"flex" , flexDirection:"row"}}>
                    <Tooltip title="Download File">
                  <div
                        style={{
                          borderRadius: 5,
                        }}
                     //   onClick={() => saveManual()}
                      >
<a
    href={`${api_url}${files.image_path}/${files.image_name}`}
    onClick={() => axios.get(`${api_url}${files.image_path}${files.image_name}`)}
    target="_blank"
  >                        <img src={downloadpng} width={20} height={20} alt="delete" />
                        </a>
                      </div></Tooltip>
                      <Tooltip title="Delete File">
                    <div
                        style={{
                          borderRadius: 5,
                          marginLeft:5
                        }}
                        //onClick={(e) => removeNotes(e, item._id)}
                      >
                        <img src={trash} width={20} height={20} alt="delete" />
                      </div> </Tooltip>
                  </td>
                </tr>
              </React.Fragment>
            )) : ""
          } 
        </tbody>
      </table>
      {/* <p>Selected Options: {selectedOptions.join(', ')}</p> */}

      <style>
        {`
    .task-table-container {
display: flex;
justify-content: flex-start;
align-items:center;
flex-direction : column;
font-family: "Poppins",sans-serif;
overflow: auto;
}


.task-table {
border-collapse: collapse;
width: 100%;
margin: 0 auto;
}
.button{
color: rgb(212, 211, 211) ;
border: 1px solid #3367d6;
background-color: #3367d6 ;
padding: 5px 10px ;
border-radius: 8px ;
margin-bottom:10px;
}

.button:hover{
color: rgb(212, 211, 211) ;
border: 1px solid #3367d6;
background-color: #3367d6 ;
padding: 5px 10px ;
border-radius: 8px ;
margin-bottom:10px;
}

.task-table th,
.task-table td {
padding: 12px;
text-align: left;
}

.task-table th {
background-color: #f2f2f2;
font-weight: 500;
font-size : 12px
}

.task-table tr {
background: #ffffff;
margin-left: 0;
box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
color: #1c1c22;
transition: background-color 0.2s ease;
cursor:pointer;
}

.task-table tr:hover {
background-color: #f5f5f5;
}

.task-row.active {
background-color: #e1f5fe;
cursor: pointer;
}

.add-subtask-button {
background-color: #007bff;
color: white;
border: none;
padding: 4px 8px;
cursor: pointer;
border-radius: 4px;
font-size: 14px;
transition: background-color 0.2s ease;
margin:10px 0
}

.add-subtask-button:hover {
background-color: #0056b3;
}

.sub-tasks-row td {
padding: 0 12px;
text-align: left;
border: none;
}


.task-line {
display: inline-block;
width: 20px;
position: relative;
padding-left:10px;
}

.branch-line {
position: absolute;
top: 8px;
left: -10px;
height: calc(100% - 8px);
width: 1px;
background-color: #ccc;
}

.branch-line.expanded {
background-color: #007bff;
}
.delete-icon {
position: absolute;
top: -8px;
right: -8px;
cursor: pointer;
background-color: #00000024;
color: white;
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
}

/* Add this to your CSS */
.calendar-icon {
position: relative;
display: inline-block;
font-size: 12px
}

.calendar-icon input {
padding-right: 30px; /* Create space for the icon */
}

.calendar-icon .icon {
position: absolute;
top: 50%;
right: 10px;
transform: translateY(-50%);
cursor: pointer;
}
/* Add this to your CSS */
.calendar-icon {
position: relative;
display: inline-block;
}

.calendar-icon .icon {
cursor: pointer;
}
/* Add this to your CSS */
.calendar-dropdown {
position: relative;
display: inline-block;
}

.calendar-dropdown .icon {
cursor: pointer;
}

.calendar-dropdown .dropdown-content {
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}

/* Add this to your CSS */
.calendar-dropdown {
position: relative;
display: inline-block;
}

.calendar-icon {
cursor: pointer;
}

.calendar-dropdown-content {
position: absolute;
top: 100%;
left: 0;
z-index: 1;
}
`}
      </style>
    </div>  : 
    <div className="task-table-container1">
          <img src={noTask} alt="loading" />
          <p style={{ margin: "20px", fontSize: "14px" }}>There is no Files</p>
          <style>
            {" "}
            {`
        .task-table-container1 {
  display: flex;
  justify-content: center;
  align-items:center;
  height:auto;
  flex-direction : column;
  padding: 20px;
  font-family: "Poppins",sans-serif;
}
`}
          </style>
        </div> }
    
    </Box>
    </div>
  )
}

export default ContactAddFile