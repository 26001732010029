import React, { useEffect,  useState } from "react";
import { Button } from "@mui/material";

import axios from "axios";
import { api_url } from "../../globalSettings";

import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendarIcon from "../../images/CRM/calendar.png";
import addComment from "../../images/CRM/add1.png";
import addUser from "../../images/CRM/add-user.png";
import noTask from "../../images/CRM/plan_7404361.png";
import {
  login_companyid,
  login_token,
  profile_trigger,
} from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import AddSubTasks from "./AddSubTasks";
import { Menu, MenuItem, List, ListItem, Checkbox } from "@mui/material";
import { TextField } from "@mui/material";
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";



const span1 = {
  width: "3px",
  display: "flex",
  height: "50px",
  position: "relative",
  left: "-8px",
  backgroundColor: "#0000002e",
  borderRadius: "5px",
};

const span2 = {
  width: "50px",
  display: "flex",
  height: "2px",
  position: "relative",
  left: "-8px",
  backgroundColor: "#0000002e",
  borderRadius: "5px",
};

const CrmTaskList = (activeTab) => {
  const [expandedTaskId, setExpandedTaskId] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [subtaskList, setsubTaskList] = useState([]);
  const [company_idd] = useRecoilState(login_companyid);
  const [profileTrigger, setProfileTrigger] = useRecoilState(profile_trigger);
  const [taskId, setTaskId] = useState("");
  const [taskId1, setTaskId1] = useState("");
  const [subTaskId, setSubTaskId] = useState("");
  const [prioritylist, setPrioritylist] = useState([]);
  const [priorityId, setPriorityId] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [statuslist, setStatuslist] = useState([]);
  const [statusId, setStatusId] = useState(null);
  const [subpriorityId, setsubPriorityId] = useState(null);
  const [subdueDate, setsubDueDate] = useState(null);
  const [substatusId, setsubStatusId] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [commentList1, setCommentList1] = useState([]);
  const [assignEmployeeList, setAssignEmployeeList] = useState([]);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions1, setSelectedOptions1] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectAll1, setSelectAll1] = useState(false);



  const handleOptionToggle = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions(selectedOptions.filter((optionId) => optionId !== id));
    } else {
      setSelectedOptions([...selectedOptions, id]);
    }
  };

  const handleSelectAllToggle = () => {
    if (!selectAll) {
      setSelectedOptions(employeeList.map((option) => option.id));
    } else {
      setSelectedOptions([]);
    }
    setSelectAll(!selectAll);
  };

  const handleOptionToggle1 = (id) => {
    if (selectedOptions1.includes(id)) {
      setSelectedOptions1(selectedOptions1.filter((optionId) => optionId !== id));
    } else {
      setSelectedOptions1([...selectedOptions1, id]);
    }
  };

  const handleSelectAllToggle1 = () => {
    if (!selectAll1) {
      setSelectedOptions1(employeeList.map((option) => option.id));
    } else {
      setSelectedOptions1([]);
    }
    setSelectAll1(!selectAll1);
  };

 

  //   ================================ DRAWER =====================================

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (taskId) => {
  
    setTaskId(taskId);
   
    setopenDrawer(openDrawer ? false : true);
  };

  const handleTaskClick = (taskId) => (e) => {
    setTaskId1(taskId);
    if (taskId === expandedTaskId) {
      setExpandedTaskId(null);
    } else {
      setExpandedTaskId(taskId);
      //subtaskapiCalling(taskId);
    }
  };
  

   //============================ BOX SHOW MORE =======================================
   const [expanded, setExpanded] = useState([])


  const handleExpandClick = (e,taskId) => {
     e.stopPropagation();
    if (taskId === expanded) {
      setExpanded(null);
    } else {
      setExpanded(taskId);
    }
  };

  //function subtaskapiCalling(taskid) {
    useEffect(() => {
      axios
      .get(`${api_url}/todo/getSubTask/${taskId1}`) //${activeTab}
      .then((responce) => {
        setsubTaskList(responce.data.data);
      })
      .catch((err) => {
      });
    },[taskId1,profileTrigger])
    
  //}

  useEffect(() => {
    axios
      .get(`${api_url}/todo/getpriorityByid/${activeTab.activeTab}`) //${activeTab}
      .then((responce) => {
       
        setPrioritylist(
          responce.data.data.map((item, key) => ({
            label: item.name,
            id: item._id,
          }))
        );
      
      })
      .catch((err) => {
      });
  }, [activeTab]);

  useEffect(() => {
    axios
      .get(`${api_url}/todo/getStatusByid/${activeTab.activeTab}`) //${activeTab}
      .then((responce) => {
      
        setStatuslist(
          responce.data.data.map((item, key) => ({
            label: item.name,
            id: item._id,
          }))
        );
      
      })
      .catch((err) => {
      });
  }, [activeTab]);

  //======================== Employee List =============================
  const [employeeList, setEmployeelist] = useState([]);


  useEffect(() => {
    const params = {
      company_id: company_idd,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/get_company_employeeList`, { params: { ...params } }) //${activeTab}
      .then((responce) => {
     
        setEmployeelist(
          responce.data.data.map((item, key) => ({
            label1: item.first_name,
            label2: item.last_name,
            id: item._id,
          }))
        );
      
      })
      .catch((err) => {
      
      });
  }, [activeTab]);

  useEffect(() => {
    axios
      .get(`${api_url}/todo/getTask/${activeTab.activeTab}`) //${activeTab}
      .then((response) => {
        const initializedTaskList = response.data.data.map((task) => ({
          ...task,
          selectedDate: null,
          calendarOpen: false,
        }));
        setTaskList(initializedTaskList);
      })
      .catch((err) => {
      });
  }, [activeTab, profileTrigger]); //activeTab

  // ============================= Priorty ============================

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event, taskId) => {
    setTaskId(taskId);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (id) => {
    setPriorityId(id);
    handleClose();
  };

  // ============================= Status ============================

  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleOpen1 = (event, taskId) => {
   
    setTaskId(taskId);
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleChange1 = (id) => {
    setStatusId(id);
    handleClose1();
  };

  // ============================= Comment ============================

  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleOpen2 = (event, taskId, comment) => {
 
    setCommentList(comment);
    setTaskId(taskId);
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

 

  // ============================= Assign Employee ============================

  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleOpen3 = (event, taskId, comment) => {
    
    setAssignEmployeeList(comment);
    setTaskId(taskId);
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  // ============================= SUB TASK PRIORITY ============================

  const [anchorEl11, setAnchorEl11] = useState(null);

  const handleOpen11 = (event, taskId) => {
    setSubTaskId(taskId);
    setAnchorEl11(event.currentTarget);
  };

  const handleClose11 = () => {
    setAnchorEl11(null);
  };

  const handleChange11 = (id) => {
    setsubPriorityId(id);
    handleClose11();
  };

  // ============================= SUB Status ============================

  const [anchorEl12, setAnchorEl12] = useState(null);

  const handleOpen12 = (event, taskId) => {
    setSubTaskId(taskId);
    setAnchorEl12(event.currentTarget);
  };

  const handleClose12 = () => {
    setAnchorEl12(null);
  };

  const handleChange12 = (id) => {
    setsubStatusId(id);
    handleClose12();
  };

  // ============================= Sub Assign Employee ============================

  const [anchorEl13, setAnchorEl13] = useState(null);

  const handleOpen13 = (event, taskId, comment) => {
   
    setAssignEmployeeList(comment);
    setSubTaskId(taskId);
    setAnchorEl13(event.currentTarget);
  };

  const handleClose13 = () => {
    setAnchorEl13(null);
  };


   // ============================= Sub Task Comment ============================

   const [anchorEl14, setAnchorEl14] = useState(null);

   const handleOpen14 = (event, taskId, comment) => {
 
     setCommentList1(comment);
     setSubTaskId(taskId);
     setAnchorEl14(event.currentTarget);
   };
 
   const handleClose14 = () => {
     setAnchorEl14(null);
   };
 
  

    // ===================================== UPDATE  TASK ==========================

  useEffect(() => {
    if (priorityId !== null || dueDate !== null || statusId !== null) {
      const params = {
      
        taskId: taskId,
        title: "",
        description: "",
        start_date: "",
        due_date: dueDate,
        priority: priorityId,
        status: statusId,
      };
      axios
        .patch(`${api_url}/todo/updateTask`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
       
          setProfileTrigger(!profileTrigger);
          // setSnackbaropen({
          //   open: true,
          //   message: "Shift Added successfully",
          //   severity: "success",
          //   actionColor: "#edf7ed",
          // });
        })

        .catch(function (error) {
          if (error.response) {
      
          } else if (error.request) {
           
          } else {
         
          }
      
        });
    }
  }, [priorityId, dueDate, statusId]);


  // ===================================== UPDATE SUB TASK ==========================

  useEffect(() => {
    if (subpriorityId !== null || subdueDate !== null || substatusId !== null) {
      const params = {
        // projectId: selectedProject._id,
        // name: updateName,
        // start_date: validFrom,
        // end_date: validTo,
        // estimation_hours: updateNo,
        // notes: updateNotes,
        subtaskId: subTaskId,
        title: "",
        description: "",
        start_date: "",
        due_date: subdueDate,
        priority: subpriorityId,
        status: substatusId,
      };
      axios
        .patch(`${api_url}/todo/updateSubTask`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
          setProfileTrigger(!profileTrigger)
          //subtaskapiCalling();          // setSnackbaropen({
          //   open: true,
          //   message: "Shift Added successfully",
          //   severity: "success~",
          //   actionColor: "#edf7ed",
          // });
        })

        .catch(function (error) {
          if (error.response) {
          
          } else if (error.request) {
          
          } else {
            
          }
       
        });
    }
  }, [subpriorityId, subdueDate, substatusId]);

  useEffect(() => {
     if (selectedOptions != []) {
      const params = {
        taskId: taskId,
        employee: selectedOptions,
      };
     
      axios
        .patch(`${api_url}/todo/assignEmployee`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
          setProfileTrigger(!profileTrigger);
          // setSnackbaropen({
          //   open: true,
          //   message: "Shift Added successfully",
          //   severity: "success",
          //   actionColor: "#edf7ed",
          // });
        })

        .catch(function (error) {
          if (error.response) {
         
          } else if (error.request) {
          
          } else {
          
          }
        
        });
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (selectedOptions1 != []) {
      const params = {
        subtaskId: subTaskId,
        employee: selectedOptions1,
      };
      axios
        .patch(`${api_url}/todo/assignSubtaskEmployee`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
          setProfileTrigger(!profileTrigger);
          // setSnackbaropen({
          //   open: true,
          //   message: "Shift Added successfully",
          //   severity: "success",
          //   actionColor: "#edf7ed",
          // });
        })

        .catch(function (error) {
          if (error.response) {
        
          } else if (error.request) {
      
          } else {
           
          }
        
        });
    }
  }, [selectedOptions1]);

  const [companyform, setCompanyForm] = useState({
    comment: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange123 = (e) => {
    const { name, value } = e.target;
    setCompanyForm((state) => ({ ...state, [name]: value }));

    // Real-time validation
    const newErrors = { ...errors };

    switch (name) {
      case "comment":
        newErrors.comment = validator.isEmpty(value)
          ? "Please enter your Comment."
          : "";
        break;
        default:
        break;
    }

    setErrors(newErrors);
  };

  const handleSubmit = () => {

    if (validate()) {
      const formdata = new FormData();
      formdata.append("taskid", taskId);
      formdata.append("comment", companyform.comment);
      formdata.append("image", "");

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      axios({
        method: "patch",
        url: `${api_url}/todo/addTaskComment`,
        data: formdata,
        config,
      })
        .then(function (response) {
          
          setProfileTrigger(!profileTrigger);
          handleClose2();
        })
        .catch(function (error) {
        
          if (error.response) {
            
          } else if (error.request) {
           
          } else {
           
          }
        
        });
    }
  };

  const handleSubmit14 = () => {

    if (validate()) {
      const formdata = new FormData();
      formdata.append("subTaskId", subTaskId);
      formdata.append("comment", companyform.comment);
      formdata.append("image", "");

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      axios({
        method: "patch",
        url: `${api_url}/todo/addSubTaskComment`,
        data: formdata,
        config,
      })
        .then(function (response) {
        
          setProfileTrigger(!profileTrigger);
          handleClose14();
        })
        .catch(function (error) {
          if (error.response) {
            
          } else if (error.request) {
            
          } else {
           
          }
        });
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    if (validator.isEmpty(companyform.comment)) {
      isValid = false;
      newErrors.comment = "Please enter your Comment.";
    }

    setErrors(newErrors);
    return isValid;
  };

  // ================= Search Input =====================

  const [searchTerm, setSearchTerm] = useState("");
  const [searchText, setSearchText] = useState("");

  const [searchText1, setSearchText1] = useState("");


  const handleSearchTextChange = (event) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  const handleSearchTextChange1 = (event) => {
    const newText = event.target.value;
    setSearchText1(newText);
  };


  // ======================== Remove Assign Employee ==============================
  const [isHovered, setIsHovered] = useState(null);



  const removeAssign = (event, taskId, employeeid) => {
    event.stopPropagation();
    
    const params = {
      TaskId: taskId,
      employeeId: employeeid,
    };
    axios
      .patch(`${api_url}/todo/removeTaskEmployee`, params)
      .then((result) => {
        // setSnackbaropen({
        //   open: true,
        //   message: "Employee Delete successfull",
        //   severity: "error",
        //   actionColor: "#fdeded",
        // });
        setProfileTrigger(!profileTrigger);
        // setOpen2(false);
      })
      .catch((error) => {
        if (error.response) {
         
        } else if (error.request) {
          
        } else {
         
        }
      });
  };

  const SubTaskRemoveAssign = (event, taskId, employeeid) => {
    event.stopPropagation();
   
    const params = {
      subTaskId: taskId,
      employeeId: employeeid,
    };
    axios
      .patch(`${api_url}/todo/removeSubtaskEmployee`, params)
      .then((result) => {
        // setSnackbaropen({
        //   open: true,
        //   message: "Employee Delete successfull",
        //   severity: "error",
        //   actionColor: "#fdeded",
        // });
        setProfileTrigger(!profileTrigger);
        // setOpen2(false);
      })
      .catch((error) => {
        if (error.response) {
      
        } else if (error.request) {
       
        } else {
          
        }
      });
  };

  //const dataForDisplay = expanded ? data : data.slice(0, 10);

  return (
    <>
      {taskList.length > 0 ? (
        <div className="task-table-container">
          <table className="task-table">
            <thead>
              <tr>
                <th>Task Name</th>
                <th>Due Date</th>
                <th>Priority</th>
                <th>Comments</th>
                <th>Status</th>
                <th>Assignee</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {
                //   (
                //   taskList
                // ).filter((task) => company_idd == "All" || task.company_id === company_idd)
                taskList.map((task) => (
                  <React.Fragment key={task.id}>
                    <tr
                      className={`task-row ${
                        expandedTaskId === task._id ? "active" : ""
                      }`}
                    >
                      <td
                        style={{ width: "40%", height: "70px" }}
                        onClick={handleTaskClick(task._id)}
                      >
                        {task.title}
                        <span
                          style={{
                            marginLeft: "10px",
                            color: "rgba(0, 0, 0, 0.18)",
                          }}
                        >
                       
                     
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <div className="calendar-dropdown">
                          <div
                            className="calendar-icon"
                            onClick={() => {
                              setTaskId(task._id);
                              const updatedTaskList = taskList.map((t) => ({
                                ...t,
                                calendarOpen:
                                  t._id === task._id && !t.calendarOpen,
                              }));
                              setTaskList(updatedTaskList);
                            }}
                          >
                            {task.due_date ? (
                              <>
                                {new Date(task.due_date).toLocaleDateString() <
                                new Date().toLocaleDateString() ? (
                                  <span style={{ color: "#ed5156" }}>
                                    {new Date(task.due_date).toLocaleString(
                                      "default",
                                      { month: "short", day: "numeric" }
                                    )}
                                  </span>
                                ) : new Date(
                                    task.due_date
                                  ).toLocaleDateString() ==
                                  new Date().toLocaleDateString() ? (
                                  <span style={{ color: "#fec007" }}>
                                    {/* {new Date(task.due_date).toLocaleString(
                              "default",
                              { month: "short", day: "numeric" }
                            )} */}
                                    Today
                                  </span>
                                ) : (
                                  new Date(task.due_date).toLocaleString(
                                    "default",
                                    { month: "short", day: "numeric" }
                                  )
                                )}{" "}
                              </>
                            ) : (
                              <img src={calendarIcon} alt="Calendar" />
                            )}
                          </div>
                          {task.calendarOpen && (
                            <div className="calendar-dropdown-content">
                              <DatePicker
                                selected={task.selectedDate}
                                onChange={(date) => {
                                  setDueDate(date);
                                  const updatedTaskList = taskList.map((t) => ({
                                    ...t,
                                    selectedDate:
                                      t._id === task._id
                                        ? date
                                        : t.selectedDate,
                                  }));
                                  setTaskList(updatedTaskList);
                                }}
                                dateFormat="MMMM d, yyyy"
                                minDate={new Date()} // Disable dates before today
                                inline
                              />
                            </div>
                          )}
                        </div>
                      </td>
                      <td style={{ width: "10%", fontSize: "12px" }}>
                        {/* <img src={triangle} alt="Priority"/> */}
                        <span
                          onClick={(e) => {
                            handleOpen(e, task._id);
                          }}
                        >
                          {task.priority.name == "normal" ? (
                            <span style={{ color: "#bdc7f4" }}>
                              {task.priority.name}
                            </span>
                          ) : task.priority.name == "urgent" ? (
                            <span style={{ color: "#ed5156" }}>
                              {task.priority.name}
                            </span>
                          ) : task.priority.name == "high" ? (
                            <span style={{ color: "#fec007" }}>
                              {task.priority.name}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <span
                          onClick={(e) => {
                            handleOpen2(e, task._id, task.comment);
                          }}
                        >
                          <img src={addComment} alt="add Comment" />
                        </span>
                      </td>
                      <td style={{ width: "10%" }}>
                        <div
                          style={{
                            backgroundColor: task.status.color,
                            fontSize: "12px",
                            color: "white",
                            textAlign: "center",
                            padding: "3px",
                            borderRadius: "5px",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              handleOpen1(e, task._id);
                            }}
                          >
                            {" "}
                            {task.status.name}
                          </span>
                        </div>
                      </td>
                      <td style={{ width: "10%" }}>
                        <span
                          style={{ display: "flex", flexDirection: "row" }}
                          onClick={(e) => {
                            handleOpen3(e, task._id, task.employee);
                          }}
                        >
                          {task.employee.length > 0 ? (
                            <>
                              {expanded === task._id ?  task.employee.map((item) => (
                                <div
                                  className="box"
                                  style={{
                                    backgroundColor: "#2ecd6f",
                                    color: "white",
                                    padding: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "30px",
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                    position: "relative", // Add position relative to the box
                                  }}
                                  onMouseEnter={() => setIsHovered(item._id)}
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  {item.first_name.charAt(0)}
                                  {item.last_name.charAt(0)}
                                  {isHovered === item._id  && (
                                    <div
                                      className="delete-icon"
                                      onClick={(e) =>
                                        removeAssign(e, task._id, item._id)
                                      }
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                              )) : task.employee.slice(0, 2).map((item) => (
                                <div
                                  className="box"
                                  style={{
                                    backgroundColor: "#2ecd6f",
                                    color: "white",
                                    padding: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "30px",
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                    position: "relative", // Add position relative to the box
                                  }}
                                  onMouseEnter={() => setIsHovered(item._id)}
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  {item.first_name.charAt(0)}
                                  {item.last_name.charAt(0)}
                                  {isHovered === item._id && (
                                    <div
                                      className="delete-icon"
                                      onClick={(e) =>
                                        removeAssign(e, task._id, item._id)
                                      }
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                              ))}
                             <a
                                type="button"
                                //onClick={() => setExpanded(!expanded)}
                                style={{fontSize:"12px"}}
                                 //onClick={(e) => handleExpandClick(task._id , e)}
                                 onClick={ (e) => handleExpandClick(e,task._id )}
                              >
                                {task.employee.length > 2 ? expanded === task._id ? "ShowLess" : "ShowMore" : ""}
                              </a>
                              {/* {displayedBoxes.map((box, index) => (
                                <div key={index} className="box">
                                  
                                </div>
                              ))}{" "}
                              {!showAll && (
                                <button onClick={() => setShowAll(true)}>
                                  Show More
                                </button>
                              )} */}
                            </>
                          ) : (
                            <img src={addUser} alt="add Comment" />
                          )}
                        </span>
                        
                      </td>
                      <td style={{ width: "10%" }}>
                        <MoreVertOutlinedIcon />
                      </td>
                    </tr>
                    {expandedTaskId === task._id && (
                      // <tr className="sub-tasks-row" >
                      //   <td colSpan="7" style={{padding : 0}}>
                      <>
                        {/* {subtaskList.length > 0 ? ( */}
                        <>
                          {subtaskList.map((subTask) => (
                            <tr className="sub-tasks-row">
                              <td
                                style={{
                                  width: "40%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span style={span1}></span>
                                <span style={span2}></span>
                                {subTask.title}
                                <span className="task-line"></span>
                              </td>
                              <td style={{ width: "10%", fontSize: "12px" }}>
                              
                              <div className="calendar-dropdown">
                          <div
                            className="calendar-icon"
                            onClick={() => {
                              setSubTaskId(subTask._id);
                              const updatedTaskList = subtaskList.map((t) => ({
                                ...t,
                                calendarOpen:
                                  t._id === subTask._id && !t.calendarOpen,
                              }));
                              setsubTaskList(updatedTaskList);
                            }}
                          >
                            {subTask.due_date ? (
                              <>
                                {new Date(subTask.due_date).toLocaleDateString() <
                                new Date().toLocaleDateString() ? (
                                  <span style={{ color: "#ed5156" }}>
                                    {new Date(subTask.due_date).toLocaleString(
                                      "default",
                                      { month: "short", day: "numeric" }
                                    )}
                                  </span>
                                ) : new Date(
                                  subTask.due_date
                                  ).toLocaleDateString() ==
                                  new Date().toLocaleDateString() ? (
                                  <span style={{ color: "#fec007" }}>
                                    
                                    Today
                                  </span>
                                ) : (
                                  new Date(subTask.due_date).toLocaleString(
                                    "default",
                                    { month: "short", day: "numeric" }
                                  )
                                )}{" "}
                              </>
                            ) : (
                              <img src={calendarIcon} alt="Calendar" />
                            )}
                          </div>
                          {subTask.calendarOpen && (
                            <div className="calendar-dropdown-content">
                              <DatePicker
                                selected={subTask.selectedDate}
                                onChange={(date) => {
                                  setsubDueDate(date);
                                  const updatedTaskList = subtaskList.map((t) => ({
                                    ...t,
                                    selectedDate:
                                      t._id === subTask._id
                                        ? date
                                        : t.selectedDate,
                                  }));
                                  setsubTaskList(updatedTaskList);
                                }}
                                dateFormat="MMMM d, yyyy"
                                minDate={new Date()} // Disable dates before today
                                inline
                              />
                            </div>
                          )}
                        </div>
                              </td>
                              <td style={{ width: "10%", fontSize: "12px" }}>
                                {/* <img src={triangle} alt="Priority" /> */}
                                <span
                          onClick={(e) => {
                            handleOpen11(e, subTask._id);
                          }}
                        >
                          {subTask.priority.name == "normal" ? (
                            <span style={{ color: "#bdc7f4" }}>
                              {subTask.priority.name}
                            </span>
                          ) : subTask.priority.name == "urgent" ? (
                            <span style={{ color: "#ed5156" }}>
                              {subTask.priority.name}
                            </span>
                          ) : subTask.priority.name == "high" ? (
                            <span style={{ color: "#fec007" }}>
                              {subTask.priority.name}
                            </span>
                          ) : (
                            ""
                          )}
                        </span>
                                {/* {subTask.priority.name == "normal" ? (
                                  <span style={{ color: "#bdc7f4" }}>
                                    {subTask.priority.name}
                                  </span>
                                ) : subTask.priority.name == "urgent" ? (
                                  <span style={{ color: "#ed5156" }}>
                                    {subTask.priority.name}
                                  </span>
                                ) : subTask.priority.name == "high" ? (
                                  <span style={{ color: "#fec007" }}>
                                    {subTask.priority.name}
                                  </span>
                                ) : (
                                  ""
                                )} */}
                              </td>
                              <td style={{ width: "10%" }}>
                              <span
                          onClick={(e) => {
                            handleOpen14(e, subTask._id, subTask.comment);
                          }}
                        >
                          <img src={addComment} alt="add Comment" />
                        </span>
                              </td>
                              <td style={{ width: "10%" }}>
                              <div
                          style={{
                            backgroundColor: subTask.status.color,
                            fontSize: "12px",
                            color: "white",
                            textAlign: "center",
                            padding: "3px",
                            borderRadius: "5px",
                          }}
                        >
                          <span
                            onClick={(e) => {
                              handleOpen12(e, subTask._id);
                            }}
                          >
                            {" "}
                            {subTask.status.name}
                          </span>
                        </div>
                              </td>
                              <td style={{ width: "10%"}}>
                        <span
                          style={{ display: "flex", flexDirection: "row" }}
                          onClick={(e) => {
                            handleOpen13(e, subTask._id, subTask.employee);
                          }}
                        >
                        
                          {subTask.employee.length > 0 ? (
                            <>
                              {expanded === subTask._id ?  subTask.employee.map((item) => (
                                <div
                                  className="box"
                                  style={{
                                    backgroundColor: "#2ecd6f",
                                    color: "white",
                                    padding: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "30px",
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                    position: "relative", // Add position relative to the box
                                  }}
                                  onMouseEnter={() => setIsHovered(item._id)}
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  {item.first_name.charAt(0)}
                                  {item.last_name.charAt(0)}
                                  {isHovered === item._id  && (
                                    <div
                                      className="delete-icon"
                                      onClick={(e) =>
                                        removeAssign(e, subTask._id, item._id)
                                      }
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                              )) : subTask.employee.slice(0, 2).map((item) => (
                                <div
                                  className="box"
                                  style={{
                                    backgroundColor: "#2ecd6f",
                                    color: "white",
                                    padding: "3px",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "30px",
                                    marginRight: "10px",
                                    borderRadius: "5px",
                                    position: "relative", 
                                  }}
                                  onMouseEnter={() => setIsHovered(item._id)}
                                  onMouseLeave={() => setIsHovered(null)}
                                >
                                  {item.first_name.charAt(0)}
                                  {item.last_name.charAt(0)}
                                  {isHovered === item._id && (
                                    <div
                                      className="delete-icon"
                                      onClick={(e) =>
                                        SubTaskRemoveAssign(e, subTask._id, item._id)
                                      }
                                    >
                                      X
                                    </div>
                                  )}
                                </div>
                              ))}
                             <a
                                type="button"
                                //onClick={() => setExpanded(!expanded)}
                                style={{fontSize:"12px"}}
                                 //onClick={(e) => handleExpandClick(task._id , e)}
                                 onClick={ (e) => handleExpandClick(e,subTask._id )}
                              >
                                {subTask.employee.length > 2 ? expanded === subTask._id ? "ShowLess" : "ShowMore" : ""}
                              </a>
                             
                            </>
                          ) : (
                            <img src={addUser} alt="add Comment" />
                          )}
                        </span>
                        
                      </td>
                              <td style={{ width: "10%" }}>
                                <MoreVertOutlinedIcon />
                              </td>
                            </tr>
                            
                          ))}
                        </>
                    
                        <button
                          className="add-subtask-button"
                          onClick={(e) => {
                            toggleDrawer(task._id);
                          }}
                        >
                          Add Sub-Task
                        </button>

                      
                      </>
                   
                    )}
                  </React.Fragment>
                ))
              }
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onChange={handleChange}
                onClose={handleClose}
              >
                {prioritylist.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      onClick={() => {
                        handleChange(item.id);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl1}
                open={Boolean(anchorEl1)}
                onClose={handleClose1}
              >
                {statuslist.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      onClick={() => {
                        handleChange1(item.id);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={handleClose2}
              >
                {commentList.map((item) => {
                  return (
                    <option
                      key={item._id}
                      style={{ padding: "10px" }}
                     
                    >
                      {item.comment}
                    </option>
                  );
                })}
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    style={{ width: "300px" }}
                    rows={4}
                    name="comment"
                    onChange={handleChange123}
                    placeholder="Text Comment"
                  />
                  {errors.comment ? (
                    <div
                      className="text-danger"
                      style={{
                        marginTop: "10px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      <ErrorOutlineIcon
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      {errors.comment}
                    </div>
                  ) : (
                    ""
                  )}
                  <Button
                    className="button"
                    onClick={handleSubmit}
                    style={{ marginTop: "10px", width: "150px" }}
                  >
                    Add Comment
                  </Button>
                </div>
              </Menu>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl3}
                open={Boolean(anchorEl3)}
                onClose={handleClose3}
              >
                <List>
                  <ListItem>
                    <TextField
                      size="small"
                      placeholder="Search Assign Employee"
                      value={searchText}
                      onChange={handleSearchTextChange}
                    />
                  </ListItem>
                  <ListItem>
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllToggle}
                    />
                    Assign All
                  </ListItem>
                  {employeeList
                    .filter((list) =>
                      list.label1
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          onClick={() => handleOptionToggle(item.id)}
                        >
                          <Checkbox
                            checked={selectedOptions.includes(item.id)}
                            onClick={(event) => event.stopPropagation()}
                          />
                          <span style={{ fontSize: "12px" }}>
                            {item.label1} {item.label2}
                          </span>
                        </MenuItem>
                      );
                    })}
                </List>
              </Menu>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl11}
                open={Boolean(anchorEl11)}
                onChange={handleChange11}
                onClose={handleClose11}
              >
                {prioritylist.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      onClick={() => {
                        handleChange11(item.id);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl12}
                open={Boolean(anchorEl12)}
                onClose={handleClose12}
              >
                {statuslist.map((item) => {
                  return (
                    <MenuItem
                      key={item.id}
                      onClick={() => {
                        handleChange12(item.id);
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Menu>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl13}
                open={Boolean(anchorEl13)}
                onClose={handleClose13}
              >
                <List>
                  <ListItem>
                    <TextField
                      size="small"
                      placeholder="Search Assign Employee"
                      value={searchText1}
                      onChange={handleSearchTextChange1}
                    />
                  </ListItem>
                  <ListItem>
                    <Checkbox
                      checked={selectAll1}
                      onChange={handleSelectAllToggle1}
                    />
                    Assign All
                  </ListItem>
                  {employeeList
                    .filter((list) =>
                      list.label1
                        .toLowerCase()
                        .includes(searchText1.toLowerCase())
                    )
                    .map((item) => {
                      return (
                        <MenuItem
                          key={item.id}
                          onClick={() => handleOptionToggle1(item.id)}
                        >
                          <Checkbox
                            checked={selectedOptions1.includes(item.id)}
                            onClick={(event) => event.stopPropagation()}
                          />
                          <span style={{ fontSize: "12px" }}>
                            {item.label1} {item.label2}
                          </span>
                        </MenuItem>
                      );
                    })}
                </List>
              </Menu>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorEl14}
                open={Boolean(anchorEl14)}
                onClose={handleClose14}
              >
                {commentList1.map((item) => {
                  return (
                    <option
                      key={item._id}
                      style={{ padding: "10px" }}
                     
                    >
                      {item.comment}
                    </option>
                  );
                })}
                <hr />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    style={{ width: "300px" }}
                    rows={4}
                    name="comment"
                    onChange={handleChange123}
                    placeholder="Text Comment"
                  />
                  {errors.comment ? (
                    <div
                      className="text-danger"
                      style={{
                        marginTop: "10px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      <ErrorOutlineIcon
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      {errors.comment}
                    </div>
                  ) : (
                    ""
                  )}
                  <Button
                    className="button"
                    onClick={handleSubmit14}
                    style={{ marginTop: "10px", width: "150px" }}
                  >
                    Add Comment
                  </Button>
                </div>
              </Menu>
            </tbody>
          </table>

          <style>
            {`
        

        .task-table-container {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction : column;
  padding: 20px;
  font-family: "Poppins",sans-serif;
}


.task-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}
.button{
  color: rgb(212, 211, 211) ;
  border: 1px solid #3367d6;
  background-color: #3367d6 ;
  padding: 5px 10px ;
  border-radius: 8px ;
  margin-bottom:10px;
}

.button:hover{
  color: rgb(212, 211, 211) ;
  border: 1px solid #3367d6;
  background-color: #3367d6 ;
  padding: 5px 10px ;
  border-radius: 8px ;
  margin-bottom:10px;
}

.task-table th,
.task-table td {
  padding: 12px;
  text-align: left;
}

.task-table th {
  background-color: #f2f2f2;
  font-weight: 500;
  font-size : 12px
}

.task-table tr {
  background: #ffffff;
    margin-left: 0;
    box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
    color: #1c1c22;
  transition: background-color 0.2s ease;
  cursor:pointer;
}

.task-table tr:hover {
  background-color: #f5f5f5;
}

.task-row.active {
  background-color: #e1f5fe;
  cursor: pointer;
}

.add-subtask-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s ease;
  margin:10px 0
}

.add-subtask-button:hover {
  background-color: #0056b3;
}

.sub-tasks-row td {
  padding: 0 12px;
  text-align: left;
  border: none;
}


.task-line {
  display: inline-block;
  width: 20px;
  position: relative;
  padding-left:10px;
}

.branch-line {
  position: absolute;
  top: 8px;
  left: -10px;
  height: calc(100% - 8px);
  width: 1px;
  background-color: #ccc;
}

.branch-line.expanded {
  background-color: #007bff;
}
.delete-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
  background-color: #00000024;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* Add this to your CSS */
.calendar-icon {
  position: relative;
  display: inline-block;
  font-size: 12px
}

.calendar-icon input {
  padding-right: 30px; /* Create space for the icon */
}

.calendar-icon .icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
/* Add this to your CSS */
.calendar-icon {
  position: relative;
  display: inline-block;
}

.calendar-icon .icon {
  cursor: pointer;
}
/* Add this to your CSS */
.calendar-dropdown {
  position: relative;
  display: inline-block;
}

.calendar-dropdown .icon {
  cursor: pointer;
}

.calendar-dropdown .dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

/* Add this to your CSS */
.calendar-dropdown {
  position: relative;
  display: inline-block;
}

.calendar-icon {
  cursor: pointer;
}

.calendar-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
`}
          </style>
        </div>
      ) : (
        <div className="task-table-container1">
          <img src={noTask} alt="loading" />
          <p style={{ margin: "20px", fontSize: "14px" }}>There is no Task</p>

          <style>
            {" "}
            {`
        .task-table-container1 {
  display: flex;
  justify-content: center;
  align-items:center;
  height:100%;
  flex-direction : column;
  padding: 20px;
  font-family: "Poppins",sans-serif;
}
`}
          </style>
        </div>
      )}
      {/* <AddSubTa
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        activeTab={activeTab}
      /> */}
      <AddSubTasks
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        activeTab={activeTab}
        taskId={taskId}
      />
    </>
  );
};

export default CrmTaskList;
