import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import { api_url } from "../../../globalSettings";
import {

  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  IconButton,
 
  Stack,
  TextField,
  Avatar,

} from "@mui/material";
import Grid from "@mui/material/Grid";

import { Box } from "@mui/system";
import { selectedcompany } from "../managecompanyatom";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useRecoilState } from "recoil";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import {
  login_atoms,
  login_token,

  triger_branch
} from "../../../Atoms/LoginAtoms";
import Autocomplete from "@mui/material/Autocomplete";
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(5),
  },
  '& .MuiNativeSelect-icon': {
    color : "#000",
    paddingRight : "5px",
    position:"realative",
    right : "5px"
  },
  '& .MuiInputBase-input': {
    borderRadius: 20,
    position: 'relative',
    color:"#000",
    fontWeight:"500",
    display : "flex",
    alignitem:"center",
    backgroundColor: "none",
    border: '2px solid #ced4da',
    fontSize: 14,
    padding: '5px 26px 5px 12px', 
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 20,
      color:"#000",
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

const CssTextField = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#009DFE",
  },

  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#009DFE",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#009DFE",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009DFE",
    },
  },
});




function Holidays() {
  const [trigerBranch, setTrigerBranch] = useRecoilState(triger_branch);

  const [Getlist, setGetlist] = useState([]);
  const [GetDepartmentlist, setGetDepartmentlist] = useState([]);
  const [companyform, setCompanyForm] = useState({
    holiday_name: "",
    holiday_type: "",
    description: "",
  });
 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "calc(100vh - 85px)",
    bgcolor: "white",
    boxShadow: 24,
    overflow : "hidden",
    overflowY:"scroll"
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);

  const [SelectedCompany, setSelectedCompany] = useRecoilState(selectedcompany);
  
  const [branchid, setBranchid] = useState("");
    

  const handleChange2 = (event) => {
    setBranchid(event.target.value);
 
  };
 
  // ======================================= Api company list =================================================

  function getBranchList() {
 
    const params = {
      company_id: SelectedCompany.licence_details.company_id,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
   
        setBranchid(responce.data.data[0]._id);
      })
      .catch((err) => {
   
      });
  }

  useEffect(() => {
   
    getBranchList();
  }, [trigerBranch]);

  useEffect(() => {
    getHolidayList();
  }, [branchid]);

  // ======================================= Api Department list =================================================

  const [branchid1, setBranchid1] = React.useState("");

  const handleChange1 = (event, newValue) => {
    if (newValue != null) {
      setBranchid1(newValue.id);
   
    }
  };

  React.useEffect(() => {
    getHolidayList();
  }, [branchid]);


  React.useEffect(() => {
    getHolidayList();
  }, [branchid]);

  function getHolidayList() {

    const params = {
      token: loginToken1,
      branch_id: branchid,
    };



    axios
      .get(`${api_url}/getHolidaytList`, { params: { ...params } })
      .then((responce) => {
        setGetDepartmentlist(responce.data.data);
 
      })
      .catch((err) => {
    
      });
  }


  //  ========================    GET UPDATE DETAILS  ===========================

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [upHolidayId , setUpHolidayId] =useState('');
  const [getHolidaydetail , setGetHolidaydetails] = useState([])


  const getHolidaydetails = (holidayId)  => (e)  => {
 
    e.preventDefault()
    setUpHolidayId(holidayId);

    const params = {
      token: loginToken1,
      holiday_id: holidayId,
    };
   

    axios
      .get(`${api_url}/getHolidayDetails`, { params: { ...params } })
      .then((responce) => {
    
        setGetHolidaydetails(responce.data.data)
        handleOpen1()
  
      })
      .catch((err) => {
    
      });
  }

const [holidayName,setholidayName] = React.useState('')



  
const updateHoliday= () => {



  getHolidaydetail.token = loginToken1;
  getHolidaydetail.holiday_id = upHolidayId;



  axios
    .patch(`${api_url}/updateHoliday`, {getHolidaydetail:getHolidaydetail,holidayName:holidayName.holiday_name}, {
      headers: {
        "content-type": "application/json",
      },
    })
    .then(function (response) {
  

      if(response.data.type === "success"){
        alert("updated")
      }
      
 
      getHolidayList();
      setOpen1(false);
    })

    .catch(function (error) {
      if (error.response) {
      
      } else if (error.request) {
     
      } else {
  
      }

    });
}

function handlechange(e) {

  const { value, name } = e.target;

  setGetHolidaydetails({ ...getHolidaydetail, [name]: value });
  setholidayName({...holidayName,[name]:value})

}

function handlechange1(e) {

  const {value,  name } = e.target;


  setGetHolidaydetails({ ...getHolidaydetail, [name]: value.split("-").reverse().join("-") });
}



   // ======================================= Api DELETE =================================================
   const [open2, setOpen2] = React.useState(false);
   const handleOpen2 = (id) => {setUpHolidayId(id); setOpen2(true);};
   const handleClose2 = () => setOpen2(false);
 
  
  const deleteHoliday = (holidayid) => () => {

    const params ={
      holiday_id:upHolidayId,
      token:loginToken1
  }

    axios
      .delete(`https://server.nowdigitaleasy.com:3505/deleteHoliday`,{ params } )
      .then((result) => {
      
        alert("Deleted")
     
        getHolidayList();
        setOpen2(false);
      })
      .catch((error) => {
  
        if (error.response) {
        
       
        } else if (error.request) {
      
        } else {
    
        }
      });
  };


  //============================================== Changing Form Values =============================================

  // ------------------------------------------------- Drawer ------------------------------------------------------
  const [validFrom, setValidFrom] = React.useState('');


  function valid_from(event) {

    setValidFrom(event.target.value);
  }


   // ================================= VALIDATION ===================================


  const [errors, setErrors] = useState({});


  const handlechange23 = (e) => {
    const { name, value } = e.target;
    setCompanyForm((state) => ({ ...state, [name]: value }));
    const newErrors = { ...errors };

    switch (name) {
      case "holiday_name":
        newErrors.holiday_name = validator.isEmpty(value)
          ? "Please enter your holiday name."
          : "";
        break;
      case "holiday_type":
        newErrors.holiday_type = validator.isEmpty(value)
          ? "Please enter your holiday type."
          : "";
        break;
        case "description":
          newErrors.description = validator.isEmpty(value)
            ? "Please enter your description."
            : "";
          break;
         
      
      default:
        break;
    }

    setErrors(newErrors);
  };



  const handleSubmit = (event) => {
    event.preventDefault();


   

    if (validate()) {

      const params = JSON.stringify({
        token: loginToken1,
        user_id: loginAtom1,
        company_id: SelectedCompany.licence_details.company_id,
        branch_id: branchid1,
        holiday_name: companyform.holiday_name,
        holiday_date: validFrom.split("-").reverse().join("-"),
        holidayDate_filter:validFrom,
        holiday_type: companyform.holiday_type,
        description: companyform.description,
      });
  
  
      axios
        .post(`${api_url}/addHoliday`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
          getBranchList();
          getHolidayList();
          setOpen(false);
        })
  
        .catch(function (error) {
          if (error.response) {

          } else if (error.request) {

          } else {

          }

        });

    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    // const isValid1 = validatePhone();
    // setIsValidPhone(isValid1);

 

    if (validator.isEmpty(companyform.holiday_name)) {
      isValid = false;
      newErrors.holiday_name = "Please enter your holiday name.";
    }

    if (validator.isEmpty(companyform.holiday_type)) {
      isValid = false;
      newErrors.holiday_type = "Please enter holiday type.";
    }

    if (validator.isEmpty(companyform.description)) {
      isValid = false;
      newErrors.description = "Please enter description.";
    }

    if (validator.isEmpty(branchid1)){
      isValid = false;
      newErrors.branchid1 = "Please Select Branch"
    }
    if (validator.isEmpty(validFrom)){
      isValid = false;
      newErrors.validFrom = "Please Select Leave Type"
    }
 
    setErrors(newErrors);
    return isValid;
  };

  return (
    <div className="companylist">
      {/* ======================================================== Company Table ======================================================= */}

      <div className="company_table">
        <Box>
          <div className="company_table_title">HOLIDAYS</div>
          <div className="company_table-filter">
            <div className="filter_btn">
         
              <FormControl sx={{ m: 1 }} variant="standard" style={{minWidth : "150px",}} size="small">
        <InputLabel htmlFor="demo-customized-select-native">Select Your Branch</InputLabel>
        <NativeSelect
          id="demo-customized-select-native"
          defaultValue={Getlist.length >= 1 ?  Getlist[0].id : "" }
          onChange={handleChange2}
          input={<BootstrapInput />}
        >
  
         {Getlist.map((item) => {
                return (<>
          <option value={item.id}>{item.label}</option>

          </>

          );
              })}
        </NativeSelect>
      </FormControl>
            </div>

            <div className="btn">
              <Button
         
                className="tablebutton"
             
                onClick={handleOpen}
              >
                Add Holidays
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    ADD HOLIDAYS
                    <IconButton
                      component="span"
                      onClick={handleClose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={4}
                    padding={5}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={4}>
                   
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={Getlist}
                          onChange={handleChange1}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                          
                              label="Select Branch*"
                            />
                          )}
                        />
                        {errors.branchid1  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.branchid1} 
            </div> : ""}
                      </Grid>
                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Holiday Name"}
                          //onChange={(e) => formonchange("holiday_name", e)}
                          name="holiday_name"
                          onChange={handlechange23}
                          fullWidth
                          required
                        />
                         {errors.holiday_name ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.holiday_name}  </div>
          ) : (
            ""
          )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                            backgroundColor: "#ecf4f8",
                          }}
                          id="date"
                          type="date"
                          value={validFrom}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={valid_from}
                          size="small"
                          fullWidth
                          label="Date*"
                          variant="outlined"
                        />
                         {errors.validFrom  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.validFrom} 
            </div> : ""}
                      </Grid>

                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Holiday Type "}
                          name="holiday_type"
                          onChange={handlechange23}
                          //onChange={(e) => formonchange("holiday_type", e)}
                          fullWidth
                          required
                        />
                         {errors.holiday_type ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.holiday_type}  </div>
          ) : (
            ""
          )}
                      </Grid>
                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Description"}
                          name="description"
                          onChange={handlechange23}
                          //onChange={(e) => formonchange("description", e)}
                          fullWidth
                          required
                        />
                         {errors.description ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.description}  </div>
          ) : (
            ""
          )}
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={handleSubmit}
                        >
                          Add Holidays
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
            </div>
          </div>
          <TableContainer component={Paper} className="tblcontainer">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table_head ">Holiday Name</TableCell>
                  <TableCell className="table_head">Holiday Date</TableCell>
                  <TableCell className="table_head">Holiday Type</TableCell>
                  <TableCell className="table_head">Description</TableCell>
                  <TableCell className="table_head">Status</TableCell>
                  {/* <TableCell className="table_head">Delete</TableCell> */}
                </TableRow>
              </TableHead>

              {GetDepartmentlist.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.branchid1}
                    // onClick={() => {
                    //   setSelectedCompany(item);
                    // }}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                  >
                    <TableCell
                      className="company_name"
                      title={item.holiday_name}
                    >
                      <div>
                        <Avatar style={{ marginRight: 15 }} />
                      </div>
                      {item.holiday_name}
                    </TableCell>
                    <TableCell
                      title={item.holiday_date}
                      className="companypersonalinfo"
                    >
                      {item.holiday_date}
                    </TableCell>
                    <TableCell
                      title={item.holiday_type}
                      className="companypersonalinfo"
                    >
                      {item.holiday_type}
                    </TableCell>
                    <TableCell
                      title={item.description}
                      className="companypersonalinfo"
                    >
                      {item.description}
                    </TableCell>

                    <TableCell title="Active" className="companypersonalinfo">
                      Active
                    </TableCell>

                    <div class="overlay" style={{ width: "40%" }}>
                      <div class="text">
                        <Button style={{ fontWeight: 600 }} onClick={getHolidaydetails(item._id)}>Update Holiday</Button>
                        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    UPDATE HOLIDAYS
                    <IconButton
                      component="span"
                      onClick={handleClose1}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={2}
                    padding={2}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                  
                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Holiday Name"}
                          //onChange={(e) => formonchange("holiday_name", e)}
                          onChange={handlechange}
                          name="holiday_name"
                          value={getHolidaydetail.holiday_name}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                            backgroundColor: "#ecf4f8",
                          }}
                          id="date"
                          type="date"
                            
                          onChange={handlechange1}
                          name="holiday_date"
                          value={getHolidaydetail.holiday_date ? getHolidaydetail.holiday_date.split("-").reverse().join("-") : ""}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        
                          size="small"
                          fullWidth
                          label="Date*"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Holiday Type "}
                         
                          onChange={handlechange}
                          name="holiday_type"
                         value={getHolidaydetail.holiday_type}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Description"}
                      
                          onChange={handlechange}
                          name="description"
                          value={getHolidaydetail.description}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose1}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={updateHoliday}
                        >
                          Update Holidays
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
                        <Button  onClick={() => {handleOpen2(item._id)}}  style={{ fontWeight: 600 }}>Delete</Button>
                        <Modal
                              open={open2}
                              onClose={handleClose2}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              style={{
                                backgroundColor: "#ffffff9e",
                              }}
                            
                            >
                               <Box sx={style} className="scroll">
                                <div className="deleteemployee">
                                Are You Sure You Want To Delete Your Holiday?
                                </div>  
                                <Stack
                                  spacing={4}
                                  padding={2}
                                  width="100%"
                                  alignItems="center"
                                  height="100%"
                                >
                                 

                                  <div className="addcompanybtn">
                                    <Stack direction={"row"} spacing={2}>
                                      <Button
                                        variant="outlined"
                                        className="tablebtncancel"
                                        style={{ color: "#000" }}
                                        onClick={handleClose2}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="contained"
                                        className="tablebutton"
                                        onClick={
                                          deleteHoliday()
                                       
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </Stack>
                                  </div>
                                </Stack>
                              </Box>
                            </Modal>
                        <Button style={{ fontWeight: 600 }}>More Option</Button>
                      </div>
                    </div>

              
                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
}

export default Holidays;
