import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import variables from "../../../../assets/styles/_colors.scss";
import React, { useState } from "react";
import { useEffect } from "react";
import { getAllPipelines } from "../../../../webservices/StageService";
import CreatePipelineWrapper from "../../../pipelines/create/CreatePipelineWrapper";
import CreatePipeline from "../../../pipelines/create/CreatePipeline";
import CreateSubPipeline from "../../../pipelines/create/CreateSubpipeline";
import ClonePipelineModal from "../../../pipelines/create/ClonePipelineModal";
import EditPipeline from "../../../pipelines/create/EditPipeline";
import { editStagesData } from "../../../../utils/createpipelineformdata";
import SubPipeline from "./SubPipeline";
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import CustomSnackbar from "../../../../components/CustomSnackbar";

export default function SubPipelines(props) {

    const [searchParams, setSearchParams] = useSearchParams();

    const [selectedTab, setSelectedTab] = useState(null);
    const [teamPipelines, setTeamPipelines] = useState([]);
    const [openCreatePipelineWrapper, setOpenCreatePipelineWrapper] = useState(false);
    const [openCreatePipelineStepper, setOpenCreatePipelineStepper] = useState(false);
    const [openCreateSubPipelineModal, setOpenCreateSubPipelineModal] = useState(false);
    const [openClonePipelineModal, setOpenClonePipelineModal] = useState(false);
    const [showPipelineEdit, setShowPipelineEdit] = useState(false);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data
    } = useQuery({
        queryKey: ['getAllPipelines'],
        queryFn: () => getAllPipelines(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        if (data && data.pipelines.length > 0) {
            setTeamPipelines(data.pipelines);
            setSelectedTab(data.pipelines[0]?._id);

            const currentTab = searchParams.get('id');
            setSelectedTab(currentTab ? currentTab : data?.pipelines[0]?._id);
            searchParams.set('id', currentTab ? currentTab : data?.pipelines[0]?._id);
            setSearchParams(searchParams);
        }
    }, [data]);

    const handleTabSelection = (id) => {
        searchParams.set('id', id);
        setSearchParams(searchParams);
        setSelectedTab(id);
    }

    const handleCreatePipelineWrapperClose = (type) => {
        if (type) {
            type === "newpipeline" && setOpenCreatePipelineStepper(true);
            type === "newsubpipeline" && setOpenCreateSubPipelineModal(true);
            type === "clonepipeline" && setOpenClonePipelineModal(true);
        }
        setOpenCreatePipelineWrapper(false);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        })
    }

    return (
        <>
            <Stack direction="row" alignItems="center" width="100%" sx={{ pb: 1 }}>
                <Stack direction="row" width="80%" >
                    <Typography component="div" sx={{ bgcolor: '#fff', borderRadius: 24, border: `1px solid ${variables.borderColor}`, cursor: "pointer" }}>
                        {
                            teamPipelines.map((teamPipeline, index) => {
                                return <Box key={index} onClick={() => handleTabSelection(teamPipeline._id)} sx={{ display: "inline-flex", p: "8px 16px", height: "32px", borderRadius: 16, m: "4px !important", bgcolor: selectedTab === teamPipeline._id ? `${variables.listBackgroundColor}` : "#fff", "&:hover": { color: `${variables.linkColor}` } }}>
                                    <Typography component="span" sx={{ lineHeight: 1.2 }}>{teamPipeline.pipelineName}</Typography>
                                    <Typography component="span" sx={{ lineHeight: 1.2, borderRadius: 2, bgcolor: selectedTab === teamPipeline._id ? "#FFF" : `${variables.listBackgroundColor}`, pl: 0.5, pr: 0.5, ml: 1 }}>{teamPipeline.subPipelines?.length}</Typography>
                                </Box>
                            })
                        }
                    </Typography>
                </Stack>
                <Stack direction="row" justifyContent="right" width="20%">
                    <Button variant="outlined" onClick={() => setOpenCreatePipelineWrapper(true)}><AddIcon /> New Team Pipeline </Button>
                </Stack>
            </Stack>

            {teamPipelines.length > 0 &&
                <Grid container direction="column" width="auto" height="78vh" sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
                    {
                        teamPipelines.filter(x => x._id === selectedTab).map((teamPipeline, tIndex) => {
                            return <React.Fragment key={tIndex}>
                                {teamPipeline.subPipelines.map((subPipeline, subPipelineIndex) => {
                                    return <React.Fragment key={subPipelineIndex}>
                                        <SubPipeline teamPipelineId={teamPipeline._id} subPipelinesCount={teamPipeline?.subPipelines?.length} subPipeline={subPipeline} subPipelineIndex={subPipelineIndex} />
                                    </React.Fragment>
                                })}
                            </React.Fragment>
                        })
                    }
                    <Card sx={{
                        width: 350, height: "75vh", boxShadow: 'none', mr: 1, border: `1px solid ${variables.borderColor}`, borderRadius: "4px !important",
                        "&:hover": {
                            border: `1px solid ${variables.linkColor}`
                        }
                    }}>
                        <CardContent sx={{ p: '0px !important', overflowX: 'hidden', overflowY: 'auto', height: '75vh' }}>
                            <Grid container direction="row" alignItems="center" justifyContent="center" height='inherit' sx={{ cursor: 'pointer' }} onClick={() => setOpenCreateSubPipelineModal(true)}>
                                <Stack direction="column" alignItems="center" spacing={2}>
                                    <CreateNewFolderOutlinedIcon fontSize="large" sx={{ color: `${variables.greyTextColor}` }} />
                                    <Typography sx={{ color: `${variables.linkColor}` }}>
                                        <AddIcon /> New Sub-Pipeline
                                    </Typography>
                                </Stack>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            }

            {openCreatePipelineWrapper &&
                <CreatePipelineWrapper handleDialogClose={(type) => handleCreatePipelineWrapperClose(type)} />}
            {openCreatePipelineStepper &&
                <CreatePipeline isNewPipeline={true} handleDrawerClose={(response) => { showSnackbar(response); setOpenCreatePipelineStepper(false) }} />}
            {openCreateSubPipelineModal &&
                <CreateSubPipeline pipelineId={selectedTab} handleDrawerClose={(response) => { showSnackbar(response); setOpenCreateSubPipelineModal(false) }} />}
            {openClonePipelineModal &&
                <ClonePipelineModal handleClose={((clone) => setOpenClonePipelineModal(false))} />}

            {showPipelineEdit &&
                <EditPipeline data={{
                    header: "Manage Stages",
                    defaultValue: editStagesData,
                    moduleIndex: 1
                }} handleDrawerClose={() => setShowPipelineEdit(false)} />}

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

        </>
    )
}