import { width } from '@material-ui/system';
import { Divider, Grid, TextField } from '@mui/material';
import React from 'react'
// import { Box, Typography } from '@mui/material'
import styled from "styled-components";
import ContactAddFollowupRemainder from './ContactAddFollowupRemainder';
import ContactListFollowupRemainder from './ContactListFollowupRemainder';

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column
`;

const Typography = styled.div`
color:#dd352c;
font: 12px;
`;

const TableContainer = styled.div`
width:100%;
`;

const TableColumn = styled.div`
display:flex;
flex-direction:row;
font-size:12px;
`;

const TableRow = styled.div`
width:20%;
padding:5px;
text-align:right;
`;

const TableRow1 = styled.div`
width:80%;
padding:5px
`;


const ContactFollowUpsTab = () => {
  return (<div style={{display:"flex",  width : "100%", flexDirection : "column" , alignItems :"flex-start",marginBottom:"5px"}}>
    <Box ><div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
        <Typography >Follow-Ups</Typography>
        <Divider />
        </div>
       <Grid container>
            <Grid item xs={6} sm={6} md={6}>
            <TableContainer>
    <TableColumn>
    <TableRow>
       Discription
    </TableRow>
    <TableRow1>
    <TextField variant='outlined' type='text' rows={3} multiline fullWidth placeholder='Type in the text here to create a note'/> 
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Date
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Update Remainders
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
   
        </TableContainer>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
            <TableContainer>
    <TableColumn>
    <TableRow>
       Type
    </TableRow>
    <TableRow1>
    <TextField variant='outlined' type='text' rows={3} multiline fullWidth placeholder='Type in the text here to create a note'/> 
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Admin
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Created
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Updated
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
   
        </TableContainer>
            </Grid>
       </Grid>
        </Box>

        <ContactAddFollowupRemainder />
        <ContactListFollowupRemainder />
         </div>
  )
}

export default ContactFollowUpsTab