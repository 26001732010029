import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import VisitorDetails from './Panel/VisitorDetails';
import VisitorBody from './VisitorBody';
import VisitorTab from './tabview/VisitorTab';
import VCircleview from './VCircleview';

const VisitorRoute = () => {
  return (
    <Routes>
       <Route path="*" element={<VisitorBody />} />
    <Route path="/:id/*" element={<VisitorDetails />}/>
    <Route path="/cricle-view" element={<VCircleview />}/>
    <Route path="/tab-view" element={<VisitorTab />}/>
  
    </Routes>
  )
}

export default VisitorRoute
