import * as React from "react";
import { useFormContext } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { CustomSelect } from "../../pipelines/components/CustomSelect";
import { CustomDatePicker } from "../../pipelines/components/CustomDatePicker";
import { CustomCheckbox } from "../../pipelines/components/CustomCheckbox";
import { CustomTimePicker } from "../../pipelines/components/CustomTimePicker";
import { useEffect } from "react";

export const CustomTaskRepeatField = (props) => {
    const { control, getValues, setValue, watch } = useFormContext();
    const [tooltipText, setTooltipText] = React.useState(props.tooltipText);
    const [disableReminder, setDisableReminder] = React.useState(false);

    const repeatChecked = watch(props.name);
    const reminderChecked = watch(`${props.prefix}.reminder`);
    const dueDateField = `${props.prefix}.${props.compareWith}`;
    const compareWith = watch(dueDateField);

    useEffect(() => {
        setDisableReminder(new Date(compareWith).getDate() < new Date().getDate());
    }, [compareWith]);

    useEffect(() => {
    }, [disableReminder]);

    const rules = {
        required: {
            value: true,
        }
    };

    const repeatOptions = [
        {
            label: "Every Day",
            value: "day"
        },
        {
            label: "Every Week",
            value: "week"
        },
        {
            label: "Every Month",
            value: "month"
        },
        {
            label: "Every Year",
            value: "year"
        }
    ];

    const reminderTaskOptions = [
        {
            label: "On due date",
            value: "onDate"
        },
        {
            label: "A day before due date",
            value: "aDayBefore"
        },
        {
            label: "2 days before due date",
            value: "twoDaysBefore"
        }
    ];

    const reminderEventOptions = [
        {
            label: "5 mins",
            value: "5"
        },
        {
            label: "10 mins",
            value: "10"
        },
        {
            label: "15 mins",
            value: "15"
        },
        {
            label: "30 mins",
            value: "30"
        },
        {
            label: "1 hr",
            value: "onehour"
        },
        {
            label: "2 hrs",
            value: "twohours"
        },
        {
            label: "1 day",
            value: "oneday"
        },
        {
            label: "2 days",
            value: "twodays"
        }
    ];

    return (
        <Stack direction="column">
            <CustomCheckbox width="25%" name={props.name} label={props?.label} defaultChecked={props.field?.checkbox?.defaultChecked} />
            {
                repeatChecked &&
                <Stack direction="row" spacing={2} alignItems="center">
                    <CustomSelect size="small" name={`${props.name}.mode`} defaultValue={repeatOptions[0]?.value} options={repeatOptions} />
                    <Typography>Until</Typography>
                    <CustomDatePicker name={`${props.name}.date`} rules={rules} />
                </Stack>
            }
            <CustomCheckbox width="25%" disableCheckbox={disableReminder} name={`${props.prefix}.reminder`} label={`Reminder`} defaultChecked={false} />
            {
                reminderChecked && (
                    props.module === "task"
                        ?
                        <Stack direction="row" spacing={2} alignItems="center">
                            <CustomSelect size="small" name={`${props.prefix}.reminder.mode`} defaultValue={reminderTaskOptions[0]?.value} options={reminderTaskOptions} />
                            <CustomTimePicker name={`${props.prefix}.reminder.time`} rules={rules} />
                        </Stack>
                        :
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Stack direction="row" width="50%">
                                <CustomSelect size="small" width="50%" name={`${props.prefix}.reminder.mode`} defaultValue={reminderEventOptions[0]?.value} options={reminderEventOptions} />
                            </Stack>
                            <Typography>before start time</Typography>
                        </Stack>
                )
            }
        </Stack>
    );
};
