import * as Yup from 'yup';

export default [
    //validation for step 1
    Yup.object().shape({
        pipelineName: Yup.string().label("Pipeline Name").trim().required('Pipeline Name cannot be empty'),
        singular: Yup.string().label("Singular").trim().required('Singular cannot be empty'),
        plural: Yup.string().label("Plural").trim().required('Plural cannot be empty'),
        permissions: Yup.array()
            .min(1, "Permissions cannot be empty"),
    }),
    //validation for step 2
    Yup.object().shape({
        showSubPipeline: Yup.boolean(),
        subPipelineName: Yup
            .string()
            .when("showSubPipeline", {
                is: true,
                then: () => Yup.string().required("Sub-Pipeline Name cannot be empty")
            }),
        openStages: Yup.array()
            .of(Yup.object().shape({ label: Yup.string().required('Open Stage cannot be empty') })).min(1),
        closedStages: Yup.array()
            .of(Yup.object().shape({ label: Yup.string().required('Close Stage cannot be empty') })).min(1)
    }),
    //validation for step 3
    Yup.object().shape({
        fields: Yup.object().shape({
            basic: Yup.object().shape({
                name: Yup.string(),
                fields: Yup.array()
            }),
            additional: Yup.object().shape({
                name: Yup.string(),
                fields: Yup.array()
            }),
            unUsedFields: Yup.object().shape({
                name: Yup.string(),
                fields: Yup.array()
            })
        }),
        products: Yup.object().shape({
            showProducts: Yup.boolean(),
            data: Yup
                .array()
                .when("showProducts", {
                    is: true,
                    then: () => Yup.array().min(2)
                }),
        }),
        creatingCustomField: Yup.boolean().default(false),
        customField:
            Yup.object().when("creatingCustomField", {
                is: true,
                then: () =>
                    Yup.object().shape({
                        label: Yup.string().trim().required('Field Label cannot be empty'),
                        type: Yup.string().required('Field Type cannot be empty'),
                        picklist: Yup.object().when("type", {
                            is: "picklist",
                            then: () => Yup.object().shape({
                                options: Yup.array()
                                    .of(Yup.object().shape({ label: Yup.string().required('Option cannot be empty') })).min(2),
                                defaultValue: Yup.string(),
                                sortAscending: Yup.boolean()
                            })
                        }),
                        lookup: Yup.object().when("type", {
                            is: "lookup",
                            then: () => Yup.object().shape({
                                defaultValue: Yup.string(),
                                selectedModule: Yup.string(),
                                relatedListName: Yup.string().trim().required('Related List Name cannot be empty')
                            })
                        })
                    })
            })
    })
];
