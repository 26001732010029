import React from 'react';

const MyTableComponent = () => {
  return (
    <table style={{ marginTop: '10px' }} className="table line-item-table">
      <thead>
        <tr className="header">
          <td>Item</td>
          <td className="text-right">Amount</td>
        </tr>
      </thead>
      <tbody>
        <tr id="ember31" className="item ember-view">
          <td>
            <div>Google Workspace Business Starter (Quarterly License)</div>
            <div className="text-muted mt-2 f13">
              <div>108 * ₹348.00</div>
              <div className="wrap">
                rainier-capital.com - 19 rainiercapital-email.co - 19
                rainiercapitalemail.co - 19 rainiercapital-email.com - 19
                rainieremail.org - 16 rainieremails.com - 16 Offer price
                Rs.116/user/month One year commitment 07-02-2024 to 07-05-2024
              </div>
            </div>
          </td>
          <td className="text-right">₹37,584.00</td>
        </tr>
        <tr className="item">
          <td className="border-top-0 text-right pt-1">CGST9 (9%)</td>
          <td className="border-top-0 text-right pt-1">₹3,382.56</td>
        </tr>
        <tr className="item">
          <td className="border-top-0 text-right pt-1">SGST9 (9%)</td>
          <td className="border-top-0 text-right pt-1">₹3,382.56</td>
        </tr>
        <tr className="item">
          <td className="text-right font-weight-sbold">Total</td>
          <td className="text-right font-weight-sbold">₹44,349.00</td>
        </tr>
      </tbody>
    </table>
  );
};

export default MyTableComponent;
