import { width } from '@material-ui/system';
import { Divider, Grid, TextField } from '@mui/material';
import React from 'react'
// import { Box, Typography } from '@mui/material'
import styled from "styled-components";
import Radio from '@mui/material/Radio';
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";

 

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column;
`;

const Typography = styled.div`
color:#dd352c;
font: 12px;
`;

const TableContainer = styled.div`
width:100%;
`;

const TableColumn = styled.div`
display:flex;
flex-direction:row;
font-size:12px;
`;

const TableRow = styled.div`
width:20%;
padding:5px;
text-align:right;
`;

const TableRow1 = styled.div`
width:80%;
padding:5px
`;

const ContactListFollowupRemainder = () => {
  return (
    <Box ><div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
    <Typography >List Of Remainder</Typography>
    </div>
    <Divider style={{margin :"10px"}}/>

    <div className="task-table-container">
          <table className="task-table">
            <thead>
              <tr>
              <th>ID</th>
                <th>Date</th>
                <th>Method</th>
                <th>Status</th>
                <th>Template</th>
                <th>Remind</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {/* {
                //   (
                //   taskList
                // ).filter((task) => company_idd == "All" || task.company_id === company_idd)
                taskList.map((task) => ( */}
                  <React.Fragment 
                  //key={task.id}
                  >
                    <tr
                    //   className={`task-row ${
                    //     expandedTaskId === task._id ? "active" : ""
                    //   }`}
                    >
                      <td
                        style={{ width: "10%", height: "40px" }}
                        //onClick={handleTaskClick(task._id)}
                      >
                    
                      </td>
                      <td style={{ width: "20%" }}>
                       
                      </td>
                      <td style={{ width: "10%", fontSize: "12px" }}>

                       
                      </td>
                      <td style={{ width: "10%" }}>
                     
                      </td>
                      <td style={{ width: "30%" }}>
                   
                      </td>
                      <td style={{ width: "10%" }}>
                 
                        
                      </td>
                      <td style={{ width: "10%" }}>
                        <MoreVertOutlinedIcon />
                      </td>
                    </tr>
           
                  </React.Fragment>
                {/* ))
              } */}
             
            </tbody>
          </table>
          {/* <p>Selected Options: {selectedOptions.join(', ')}</p> */}

          <style>
            {`
        

        .task-table-container {
  display: flex;
  justify-content: flex-start;
  align-items:center;
  flex-direction : column;
  padding: 20px;
  font-family: "Poppins",sans-serif;
}


.task-table {
  border-collapse: collapse;
  width: 100%;
  margin: 0 auto;
}
.button{
  color: rgb(212, 211, 211) ;
  border: 1px solid #3367d6;
  background-color: #3367d6 ;
  padding: 5px 10px ;
  border-radius: 8px ;
  margin-bottom:10px;
}

.button:hover{
  color: rgb(212, 211, 211) ;
  border: 1px solid #3367d6;
  background-color: #3367d6 ;
  padding: 5px 10px ;
  border-radius: 8px ;
  margin-bottom:10px;
}

.task-table th,
.task-table td {
  padding: 12px;
  text-align: left;
}

.task-table th {
  background-color: #f2f2f2;
  font-weight: 500;
  font-size : 12px
}

.task-table tr {
  background: #ffffff;
    margin-left: 0;
    box-shadow: -2px 5px 9px 7px rgba(0, 0, 0, 0.03);
    color: #1c1c22;
  transition: background-color 0.2s ease;
  cursor:pointer;
}

.task-table tr:hover {
  background-color: #f5f5f5;
}

.task-row.active {
  background-color: #e1f5fe;
  cursor: pointer;
}

.add-subtask-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.2s ease;
  margin:10px 0
}

.add-subtask-button:hover {
  background-color: #0056b3;
}

.sub-tasks-row td {
  padding: 0 12px;
  text-align: left;
  border: none;
}


.task-line {
  display: inline-block;
  width: 20px;
  position: relative;
  padding-left:10px;
}

.branch-line {
  position: absolute;
  top: 8px;
  left: -10px;
  height: calc(100% - 8px);
  width: 1px;
  background-color: #ccc;
}

.branch-line.expanded {
  background-color: #007bff;
}
.delete-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
  background-color: #00000024;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* Add this to your CSS */
.calendar-icon {
  position: relative;
  display: inline-block;
  font-size: 12px
}

.calendar-icon input {
  padding-right: 30px; /* Create space for the icon */
}

.calendar-icon .icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
/* Add this to your CSS */
.calendar-icon {
  position: relative;
  display: inline-block;
}

.calendar-icon .icon {
  cursor: pointer;
}
/* Add this to your CSS */
.calendar-dropdown {
  position: relative;
  display: inline-block;
}

.calendar-dropdown .icon {
  cursor: pointer;
}

.calendar-dropdown .dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}

/* Add this to your CSS */
.calendar-dropdown {
  position: relative;
  display: inline-block;
}

.calendar-icon {
  cursor: pointer;
}

.calendar-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
`}
          </style>
        </div>
    </Box>  )
}

export default ContactListFollowupRemainder