import { Box, Button, CircularProgress, Drawer, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import variables from "../../../../assets/styles/_colors.scss";
import { CloseButton } from "../../../../components/CloseButton";
import { CustomInputField } from "../../../pipelines/components/CustomInputField";
import { CustomSelect } from "../../../pipelines/components/CustomSelect";
import RoleTree from "../roles/RoleTree";
import { useEffect } from "react";
import { createUser, getProfilesDropdown } from "../../../../webservices/UserControlService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import timezones from 'timezones-list';
import countryLocaleMap from 'country-locale-map';

import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
    firstName: Yup.string().label("First Name").trim().max(50, "First Name cannot be more than 50 chars long"),
    lastName: Yup.string().label("Last Name *").trim().required("Last Name cannot be empty").max(50, "Last Name cannot be more than 50 chars long"),
    email: Yup.string().label("Email *").trim().email("Email is not valid").required("Email cannot be empty").max(250, "Email cannot be more than 250 chars long"),
    roleName: Yup.string().label("Role *").trim().required("Role cannot be empty"),
    profile: Yup.string().label("Profile *").trim().required("Profile cannot be empty")
});

const CreateUser = (props) => {

    const queryClient = useQueryClient();
    const countryLocale = countryLocaleMap.getAllCountries();

    const [openDrawer, setOpenDrawer] = useState(true);
    const [roleTree, showRoleTree] = useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data: profiles,
        isSuccess,
        isError,
        error
    } = useQuery({
        queryKey: ['getAllProfilesDropdown'],
        queryFn: () => getProfilesDropdown(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        isError && setSnackbarInfo({
            open: true,
            severity: 'error',
            message: error?.response?.data?.error?.message
        });
    }, [isError]);

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    function handleDrawerClose(data) {
        setOpenDrawer(false);
        props.handleDrawerClose(data);
    }

    const methods = useForm(
        {
            defaultValues: {
                "firstName": "",
                "lastName": "",
                "email": "",
                "role": "",
                "profile": ""
            },
            resolver: yupResolver(schema),
            shouldUnregister: false,
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, setValue, trigger } = methods;

    const handleRoleSelect = (item) => {
        if (item) {
            setValue("roleName", item.roleName);
            setValue("role", item?._id);
        }
        showRoleTree(false);
    }

    const { mutateAsync, isPending } = useMutation({
        mutationKey: "createUser",
        mutationFn: (data) => createUser(data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
            queryClient.invalidateQueries({ queryKey: ['getUsersCount'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    const onSubmit = async (data) => {
        const isFormValid = await trigger();
        if (isFormValid) {
            // alert(JSON.stringify(data));
            data.countryLocale = countryLocale[236];
            data.timezone = timezones[326];
            data.timeFormat = "12";
            data.createdBy = "User 1";
            mutateAsync(data);
        }
    }

    return (
        <>
            <Drawer anchor="right" open={openDrawer}
                PaperProps={{
                    sx: {
                        minWidth: "35vw", maxWidth: "35vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                    },
                }}>
                <Box sx={{ height: "100vh" }}>

                    <Typography variant="h5" sx={{ pl: 2 }}>Create User
                        <CloseButton disabled={isPending} handleDialogClose={handleDrawerClose} />
                    </Typography>

                    <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 2.5, height: "86vh" }}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} id="userForm" style={{ height: "100%" }}>
                                <Stack direction="column" spacing={2}>
                                    <CustomInputField name={`firstName`} size="medium" label="First Name" />
                                    <CustomInputField name={`lastName`} size="medium" label="Last Name*" />
                                    <CustomInputField name={`email`} size="medium" label="Email*" />
                                    <Typography component="div" onClick={() => showRoleTree(true)} sx={{ cursor: 'pointer' }}>
                                        <CustomInputField name={`roleName`} size="medium" label="Role*" placeholder="Select" readOnly={true} showCursor={true} />
                                    </Typography>
                                    <CustomSelect name={`profile`}
                                        inputLabel="Profile*"
                                        defaultValue={profiles?.[0]?.value}
                                        options={profiles}>
                                    </CustomSelect>
                                </Stack>
                            </form>
                        </FormProvider>
                    </Box>

                    <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button disabled={isPending} variant="outlined" onClick={() => handleDrawerClose()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={isPending}
                            type="submit"
                            form={"userForm"}
                            variant="contained"
                            color="primary"
                        > Save
                            {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                        </Button>
                    </Stack>
                </Box>

                {roleTree && <RoleTree nodeToDisable={props.item} handleClose={(item) => handleRoleSelect(item)} />}

                <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

            </Drawer >
        </>
    );
};

export default CreateUser;