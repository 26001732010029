import React from 'react'
import ChatRouter from './components/ChatRouter';
import MainMenu from './MainMenu';

const ChatApp = () => {
  return (
    <div style={{display:"flex", height:"calc(100vh - 50px)"}}>
        <MainMenu />
        <ChatRouter />
    </div>
  )
}

export default ChatApp