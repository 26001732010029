import React from "react";
import RenewalStyle from "../Styles/RenewalConfirm.module.css";
import { PriceFormatSymbol } from "../../../CustomHook/DateFormat";
const PriceTable = ({ currencyCode, priceData, gst, tax }) => {
  return (
    <table className={RenewalStyle.table_width}>
      <tbody>
        <tr className={RenewalStyle.title_color}>
          <td colspan="2">Subtotal</td>
          <td colspan="2">{PriceFormatSymbol(priceData, currencyCode)}</td>
        </tr>
        <tr className={RenewalStyle.title_color}>
          <td colspan="2">Tax</td>
          <td colspan="2">{PriceFormatSymbol(gst, currencyCode) }</td>
        </tr>
        <tr className={RenewalStyle.total_color}>
          <td colspan="2">Total</td>
          <td colspan="2">{PriceFormatSymbol(tax, currencyCode)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PriceTable;
