import React, { useState, useEffect } from "react";
import {
  Marker,
  GoogleMap,
} from "@react-google-maps/api";
import {  Stack,  TextField, Button } from "@mui/material";
import { useRecoilState } from "recoil";
import axios from "axios";
import { api_url } from "../../globalSettings";
import { login_atoms, login_token , ZoomLevel} from "../../Atoms/LoginAtoms";
import { Alert, Snackbar } from "@mui/material";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import './Addpoi.css'

export default function Addpoi(props) {

  const center = {
    lat: parseFloat(props.SelectedEmp.current_location_latitude),
    lng: parseFloat(props.SelectedEmp.current_location_longitude),
  };
  const [location, setLocation] = useState(center);
  const [LocationLat, setLocationLat] = useState();
  const [LocationLng, setLocationLng] = useState();
  const [trigger, settrigger] = useState(false);
  const [zoomLevel] =useRecoilState(ZoomLevel);


  const [companyform, setCompanyForm] = useState({
    location_name: "",
    address: "",
  
  });

  const formonchange = (e) => {

    const { value, name } = e.target;
    setCompanyForm({ ...companyform, [name]: value });
  };



  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  //  ================================ GET LOCATION API CALL  ========================
  const [LocationName, setLocationName] = useState();

  function getlocationname() {
  
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${LocationLat},${LocationLng}&key=AIzaSyB3ZlA9dlt1bnsJyUPn-Ss3IPM8awi0F2g`;


    axios
      .get(url)
      .then((responce) => {
      
        setLocationName(responce.data.results[1].formatted_address);
      })
      .catch(function (error) {
        if (error.response) {
        
        } else if (error.request) {
       
        } else {
        
        }
   
      });
  }

  useEffect(() => {
    getlocationname();
  }, [trigger]);

  //  ================================ API CALL  ========================
  function addPoi() {

    const params = JSON.stringify({
      token: loginToken1,
      user_id: loginAtom1,
      company_id: props.SelectedEmp.company_id._id,
      branch_id: props.SelectedEmp.employee_id.branch_id,
      department_id:props.SelectedEmp.employee_id.department_id,
      latitude: LocationLat,
      longitude: LocationLng,
      location_name: LocationName,
      address: companyform.address,
    });

    axios
      .post(`${api_url}/add_address_By_Branch`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        setSnackbaropen({
          open: true,
          message: response.data.message,
          severity: "success",
          actionColor: "#edf7ed",
        });
      })

      .catch(function (error) {
        if (error.response) {
          setSnackbaropen({
            open: true,
            message: error.response.data.message,
            severity: "error",
            actionColor: "#fdeded",
          });
        
        } else if (error.request) {
        
        } else {

        }
  
      });
  }






  const handleMarkerDrag = (event) => {

    setLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
   
    setLocationLat(event.latLng.lat());
    setLocationLng(event.latLng.lng());
    
    settrigger(!trigger);
  };

  

  return (
    <>
      <Snackbar
        open={snackbaropen.open}
        onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
        autoHideDuration={1000}
      >
        <Alert
          severity={snackbaropen.severity}
          style={{ backgroundColor: `${snackbaropen.actionColor}` }}
        >
          {snackbaropen.message}
        </Alert>
      </Snackbar>
      <SearchBar setMarkerPosition={setLocation} setLocationLat={setLocationLat} setLocationLng={setLocationLng} setLocationName={setLocationName}/>
      {/* <GoogleMap
      // onLoad={handleMapLoad}
      onLoad={handleOnLoad}
        // id="searchbox-example"
        mapContainerStyle={{
          height: "200px",
          width: "500px",
        }}
        zoom={zoomLevel} 
        center={location}
        // onClick={onClick}
      > */}
          <GoogleMap
        mapContainerStyle={{ height: '200px', width: '500px' }}
        center={location}
        zoom={zoomLevel} 
        //onLoad={handleOnLoad}
      >
       
        <Marker
          // position={location}
          // draggable={true}
          // onDragEnd={onDragEnd}
          position={location}
          draggable={true}
          onDragEnd={handleMarkerDrag}
          // onLoad={onMarkerLoad}
        />
      </GoogleMap>
      {/* </LoadScript> */}
      <Stack
        spacing={4}
        padding={5}
        width="100%"
        alignItems="center"
        height="100%"
      >
      
          {/* <Grid item xs={6} alignItems="center" justifyContent=" center">
            <TextField
              size="small"
              id="standard-basic"
              variant="standard"
              label={"Location Name"}
              onChange={(e) => formonchange("location_name", e)}
              fullWidth
              required
            />
          </Grid> */}

          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            label={"Location Name"}
            name="address"
            value={companyform.address}
            onChange={formonchange}
            fullWidth
            required
          />
       
        <div className="addcompanybtn">
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="outlined"
              className="tablebtncancel"
              style={{ color: "#000" }}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="tablebutton"
              onClick={addPoi}
              style={{marginLeft : "10px"}}
            >
              Add POI
            </Button>
          </Stack>
        </div>
      </Stack>
    </>
  );


}


  const SearchBar = ({ setMarkerPosition , setLocationLat , setLocationLng , setLocationName }) => {
    const [address, setAddress] = useState('');
  
    const handleSelect = async (value) => {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setMarkerPosition(latLng);
      setLocationLat(latLng.lat);
      setLocationLng(latLng.lng);
      setLocationName(value);
    };
  
    return (
      <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="search-bar-container">
            <input {...getInputProps({ placeholder: 'Search for a location' })} />
            <div className="suggestions-container">
              {loading ? <div>Loading...</div> : null}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                };
                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion, { style })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  };
  