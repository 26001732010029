import axios from "axios";
import { api_url1 } from "../../../globalSettings";

export async function  FetchWorkSpaceApi() {
  return axios.get(`${api_url1}/workspace/workspace_list`).then((result) => {
    return result.data.workspaces;
  }).catch(error => {
    console.error('Error fetching workspace data:', error);
    return []; // return empty array or handle error gracefully
  });
}
