import React from "react";
import { IconButton, Grid, Modal, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api_url1 } from "../../../../globalSettings";
import { useFormik, FormHelperText } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";

import {plesknameserverpopup} from "../../../../Atoms/warningpopup"
import { useRecoilState } from "recoil";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};
const Nameserver = (props) => {

    const [nameserverdialog, setnameserverdialog] = useRecoilState(plesknameserverpopup);

  return (
    <>
      <div>
        <div>
          <Modal
            open={nameserverdialog}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="scroll">
              
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  maxWidth: "100%",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <div style={{ padding: 20 }}>
                      <h6 style={{ fontWeight: "600", margin: 0 }}>
                        Name Servers
                      </h6>
                      
                      <div style={{paddingTop:"5px"}}>
                      {props.nameserver.map((item,index)=>(
                        <Typography>
                           {`${index + 1}. ${item}`}
                        </Typography>
                      ))}
                      </div>
                     
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      backgroundColor: "#e0e0e0",
                      padding: 20,
                      zIndex: "0",
                    }}
                  >
                    {/* <IconButton
                          component="span"
                          onClick={props.theftprotectiondialogonclose}
                        >
                          <CloseIcon />
                        </IconButton> */}
                        <div style={{display:'flex',justifyContent:"flex-end"}}>
                            <IconButton onClick={()=>setnameserverdialog(false)}>
                                <CloseIcon/>
                            </IconButton>
                        </div>
                    <div style={{ padding: 20, fontSize: 11, fontWeight: 500, paddingTop:"0px" }}>
                    Name servers, also known as Domain Name System (DNS) servers, are essential components of the internet infrastructure. They play a crucial role in translating human-readable domain names into numeric IP addresses that computers use to identify each other on the network.
                      <br />
                     
                      <br />
                      <br />
                      <a href="nowdigitaleasy.com">
                        Learn more about the name server process
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </div>

            </Box>
          </Modal>
        </div>
        <style>
          {`
                    .button{
                        color: rgb(255, 255, 255);
                        background-color: rgb(231, 96, 96);
                        margin-top: 20px;
                        padding: 10px;
                        cursor: pointer;
                        width:max-content
                    }
                    `}
        </style>
      </div>
    </>
  );
};

export default Nameserver;
