import { Divider } from '@mui/material';
import React from 'react'
// import { Box, Typography } from '@mui/material'
import styled from "styled-components";

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column
`;

const Typography = styled.div`
color:#dd352c;
font: 12px;
`;

const TableContainer = styled.div`
width:100%;
`;

const TableColumn = styled.div`
display:flex;
flex-direction:row;
font-size:12px;
`;

const TableRow = styled.div`
width:20%;
padding:5px;
text-align:right;
`;

const TableRow1 = styled.div`
width:80%;
padding:5px
`;
const TestCampaign = () => {
  return (
    <div style={{display:"flex",  width : "100%", flexDirection : "column" , alignItems :"flex-start",marginBottom:"5px"}}>
    <Box ><div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
        <Typography >Follow-Ups</Typography>
        <Divider />
        </div>
       
        </Box>
         </div>
  )
}

export default TestCampaign