import { Avatar, Box, Stack, Typography } from "@mui/material"
import Tags from "../../../components/Tags";
import PublicIcon from '@mui/icons-material/Public';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { stringAvatar } from "../../../utils/PipelineConstants";
import variables from "../../../assets/styles/_colors.scss";
import moment from "moment/moment";
import { withStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { useState } from "react";

const metaData = {
    "stats": [{
        label: "Open Activity",
        value: "1"
    },
    {
        label: "Open Pipeline Records",
        value: "2"
    },
    {
        label: "Won Pipeline Record",
        value: "1"
    }],
    "otherInfo": [{
        label: "Additional Field",
        value: "Holaaaaa"
    }]
}

const StyledDotSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

export const CompanyInfo = (props) => {

    const [address, setAddress] = useState({});

    const getAddress = () => {
        const data = props.item;
        let obj = "";
        if (data) {
            obj += data.billingStreet ? data.billingStreet : "";
            obj += data.billingCity ? obj === "" ? `${data.billingCity}` : `, ${data.billingCity}` : "";
            obj += data.billingState ? obj === "" ? `${data.billingState}` : `, ${data.billingState}` : "";
            obj += data.billingCountry ? obj === "" ? `${data.billingCountry}` : `, ${data.billingCountry}` : "";
            obj += data.billingZip ? obj === "" ? `${data.billingZip}` : `, ${data.billingZip}` : "";
        }

        return {
            val: obj,
            isEmpty: obj === "" && true
        };
    }

    useEffect(() => {
        setAddress(getAddress());
    }, [props.item]);

    return (
        <Box sx={{ pl: 2, pt: 2 }}>
            {props.showStats &&
                <Box sx={{ pb: 1 }}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1 }}>Stats</Typography>
                    {metaData["stats"]?.map((field, index) => {
                        return <Stack direction="row" sx={{ pb: 1 }} key={index} alignItems="center" spacing={1}>
                            <Typography >{field.label}</Typography>
                            <StyledDotSeparator />
                            <Typography>{field.value}</Typography>
                        </Stack>
                    })}
                </Box>
            }
            {
                <Box sx={{}}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1 }}>Basic Info</Typography>
                    <Stack direction="column">
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <PublicIcon />
                            <Typography>
                                {props.item?.website ?
                                    <a
                                        target="_blank" rel="noreferrer" href={props.item?.website}>
                                        {props.item?.website}
                                    </a>
                                    : "--"
                                }
                            </Typography>
                        </Stack>
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <CallOutlinedIcon />
                            <Typography>{props.item?.phone ? props.item?.phone : '--'}</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ pb: 1 }} spacing={2}>
                            <LocationOnOutlinedIcon />
                            {
                                address?.isEmpty
                                    ? <Typography> -- </Typography>
                                    : <a
                                        target="_blank" rel="noreferrer" href={`https://maps.google.com?q=${address?.val}`}>
                                        {address?.val}
                                    </a>
                            }

                        </Stack>
                    </Stack>
                </Box>
            }

            <Tags />

            {
                <Box sx={{ pb: 1 }}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 2 }}>Description</Typography>
                    <Typography width="100%">{props.item?.description ? props.item.description : '--'}</Typography>
                </Box>
            }
            {
                <Box sx={{ pb: 1 }}>
                    <Typography component="div" sx={{ fontWeight: "bold", pb: 1, pt: 1 }}>Other Info</Typography>
                    {metaData["otherInfo"]?.map((field, index) => {
                        return <Stack direction="row" sx={{ pb: 1 }} key={index}>
                            <Typography width="200px">{field.label}</Typography>
                            <Typography width="60%">{field.value ? field.value : '--'}</Typography>
                        </Stack>
                    })}
                </Box>
            }
            {
                <Stack direction="row" alignItems="center" sx={{ pb: 1 }}>
                    <Avatar sx={{ width: 25, height: 25, fontSize: 10 }} {...stringAvatar("Harvey Specter")} />
                    <Typography sx={{ color: `${variables.greyTextColor}`, fontSize: 14, pl: 1.5 }}> Last Modified on </Typography>
                    <Typography sx={{ fontSize: 14, pl: 0.5 }}> {moment().calendar()} </Typography>
                </Stack>
            }
        </Box >
    )
}