import React from 'react'

const Phone = () => {
  return (
    <div
    style={{ width: "100%", display: "flex", cursor: "pointer" }}
    // onClick={toggleDrawer1}
  >
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
      }}
    >
      <label>Phone</label>
      <input
        type="number"
        style={{
          width: "100%",
          padding: "8px",
          border: "1px solid #ccc",
          borderRadius: 4,
        }}
      />
    </div>
  </div> 
   )
}

export default Phone