import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setShowHideToastMsg } from "../../redux/actions/common-action-creators";
import "../../styles/toastMsg.css";

export default function ToastMsg() {
  const dispatch = useDispatch();
  const { toastMessage } = useSelector((state) => state.common, shallowEqual);

  const toastOnClose = () => {
    dispatch(setShowHideToastMsg(false, "", ""));
  };
  useEffect(() => {
    setTimeout(() => {
      toastOnClose();
    }, 10000);
  }, []);
  return (
    <div className="toast-msg">
      <div className="toast-msg-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="47" height="45" viewBox="0 0 47 45">
          <rect id="Rectangle_14" data-name="Rectangle 14" width="47" height="45" rx="2" fill="#f52535" />
        </svg>
      </div>
      <div className="toast-msg-content">
        <svg xmlns="http://www.w3.org/2000/svg" width="259" height="15" viewBox="0 0 259 15">
          <text
            id="Login_Failed_please_check_your_credentials."
            data-name="Login Failed, please check your credentials."
            transform="translate(0 12)"
            fill="#2d2d2d"
            font-size="12"
            font-family="Montserrat-Regular, Montserrat"
          >
            <tspan x="0" y="0">
              {toastMessage}
            </tspan>
          </text>
        </svg>
      </div>
      <div className="toast-msg-close" onClick={toastOnClose}>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.489" height="13.489" viewBox="0 0 13.489 13.489">
          <g id="Group_80" data-name="Group 80" transform="translate(-1329.798 -70.646)">
            <g id="Group_17" data-name="Group 17" transform="translate(1330.152 71)">
              <line id="Line_1" data-name="Line 1" x2="12.782" y2="12.782" transform="translate(0 0)" fill="none" stroke="#ff5454" stroke-width="1" />
              <line id="Line_2" data-name="Line 2" x1="12.782" y2="12.782" transform="translate(0 0)" fill="none" stroke="#ff5454" stroke-width="1" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}
