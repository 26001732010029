import { IconButton } from "@mui/material"
import CloseIcon from "@material-ui/icons/Close";
import variables from "../assets/styles/_colors.scss";

export const CloseButton = (props) => {

    return (
        <IconButton
            sx={{
                position: props.position ? props.position : "absolute",
                right: 8,
                top: 8,
                color: variables.closeButtonColor,
                float: "right"
            }}
            size={props.size ? props.size : "medium"}
            onClick={() => props.handleDialogClose()}
            disabled={props.disabled}
        >
            <CloseIcon />
        </IconButton>
    )
}