import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Modal,
  Stack,
  Snackbar,
  Grid,
  CircularProgress,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const addwebstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  boxShadow: 24,
};

const AttachmentMessage = ({ companyId, websiteId, website_icon }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const queryClient = useQueryClient();
  const fileInputRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);

  useEffect(() => {
    if (alertOpen) {
      setAlertVisible(true);
      const timer = setTimeout(() => {
        setAlertOpen(false);
        setAlertVisible(false);
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [alertOpen]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertVisible(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    formik.resetForm();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("website_icon", file);
  };

  const validationSchema = Yup.object().shape({
    website_icon: Yup.mixed().required("Please upload website icon"),
  });

  const formik = useFormik({
    initialValues: {
      website_icon: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting, setFieldError }) => {
      try {
        setLoading(true);
        const file = fileInputRef.current.files[0];
        const formDataToUpdate = new FormData();
        formDataToUpdate.append("website_id", websiteId);
        formDataToUpdate.append("website_icon", file);

        const response = await axios.put(
          `${process.env.REACT_APP_CHATAPP_BASEURL}/admin/website/changeProfile/${websiteId}`,
          formDataToUpdate
        );
        console.log(response.data);
        formik.resetForm();
        setAlertMessage("Image added successfully");
        setAlertSeverity("success");
        setAlertOpen(true);
        setIsModalOpen(false);
        queryClient.invalidateQueries("websitelist");
      } catch (error) {
        console.error("Add website error:", error);
        setAlertMessage("Error adding website");
        setAlertSeverity("error");
        setAlertOpen(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box position="relative">
      <img
        src={`${process.env.REACT_APP_CHATAPP_BASEURL}/admin/website/image/${website_icon}`}
        alt="images"
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          color: "#fff",
          opacity: 0,
          transition: "opacity 0.3s ease",
        }}
        onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
        onMouseLeave={(e) => (e.currentTarget.style.opacity = 0)}
        onClick={handleModalOpen}
      >
        <Typography variant="h6">
          <PhotoCameraIcon />
        </Typography>
      </div>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box sx={addwebstyle}>
          <div>
            <Typography
              variant="h6"
              sx={{
                paddingTop: "10px",
                paddingLeft: "20px",
                paddingRight: "20px",
                fontSize: 16,
                fontWeight: 600,
                color: "#fff",
                fontFamily: "Inter",
                textTransform: "capitalize",
                backgroundColor: "royalblue",
                height: "50px",
              }}
            >
              UPDATE PROFILE
            </Typography>
          </div>
          <Stack
            spacing={2}
            direction="row"
            width="100%"
            alignItems="center"
            height="100%"
            justifyContent="center"
          >
            <Grid container direction="column">
              <Grid item xs={12} sm={6} md={4}>
                <Stack
                  spacing={2}
                  padding={2}
                  direction="row"
                  width="100%"
                  height="100%"
                >
                  <Typography
                    variant="body1"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "115px",
                      fontSize: "15px",
                      fontFamily: "Inter",
                    }}
                  >
                    Website Icon<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {formik.values.website_icon && (
                      <img
                        src={URL.createObjectURL(formik.values.website_icon)}
                        alt="Website Icon"
                        style={{ maxWidth: "80px", maxHeight: "100px" }}
                      />
                    )}

                    <label
                      htmlFor="website_icon"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: "#75869c",
                      }}
                    >
                      <input
                        id="website_icon"
                        name="website_icon"
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        style={{ display: "none" }}
                      />
                      <CloudUploadIcon sx={{ width: "25px", height: "25px" }} />
                    </label>
                  </div>

                  {formik.touched.website_icon &&
                    formik.errors.website_icon && (
                      <Typography variant="body2" color="error">
                        {formik.errors.website_icon}
                      </Typography>
                    )}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="flex-end"
                  marginRight="25px"
                  marginBottom="18px"
                >
                  <Button
                    variant="outlined"
                    className="tablebtncancel"
                    style={{ color: "#000" }}
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: "#ffffff !important",
                      color: "#3367d6 !important",
                      border: "1px solid #3367d6 !important",
                    }}
                    onClick={formik.handleSubmit}
                  >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default AttachmentMessage;
