import React, { useState} from "react";
import Style from "./Consolidate.module.css";
import NdeLogo from "./images/NDE_Logo.png";
import { width } from "@material-ui/system";
import { displayRazorpay } from "../../../webservices/CustomHook/RazorPay/RazorPay";
import axios from "axios";
import { api_url1 } from "../../../../globalSettings";
import CustomSnackbar from "../../../webservices/Component/CustomSnackbar";
import { useQuery } from "@tanstack/react-query";
import { pdfAtom } from "../../../../Atoms/WebServices/webservices";
import { useRecoilState } from "recoil";
import { getPdfUrl } from "../../../webservices/CustomHook/PdfUrl";
import CircularProgress from "@mui/material/CircularProgress"
import LoadingButton from '@mui/lab/LoadingButton';

import PdfRender from "./PdfRender";


const ConsolidatePayment = () => {
  const [loading, setLoading] = useState(false);
  const [invoiceData, setInvoiceState] = useRecoilState(pdfAtom);

  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleSnackbarClose = (event, reason) => {
    setSnackbarInfo((prevInfo) => ({
      ...prevInfo,
      open: false,
    }));
  };

  function PaymentApiCall() {
    setLoading(true);
    let obj = {
      invoiceId: invoiceData._id,
    };

    axios
      .post(`${api_url1}/payment/consolidate/invoice`, obj)
      .then((result) => {
        if (!result.data.isRazorpay) {
          setSnackbarInfo({
            open: true,
            severity: "success",
            message:
              "Payment Successfully Made Via Wallet,It Will Take Few Minutes To Update",
          });
        } else {
          const handlerFunction = function (response) {
            setSnackbarInfo({
              open: true,
              severity: "success",
              message: "Payment Successful,It Will Take Few Minutes To Update!",
            });
          };

          let options = {
            ...result.data.options,
            handler: handlerFunction,
          };

          displayRazorpay(options, setSnackbarInfo);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }
  const [pdfUrl, setPdfUrl] = useState("");

  function fetchPdfUrl() {
    return axios
      .get(`${api_url1}/consolidate/pdf/${invoiceData._id}`)
      .then((result) => {
        console.log("pdfurl", result);
        getPdfUrl(result.data.pdfkey, setPdfUrl);

        return result.data;
      });
  }
  console.log("acadccsdss", pdfUrl);

  useQuery({
    queryKey: ["fetchPdfUrl"],
    queryFn: fetchPdfUrl,
  });
  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf);
  }

  const pagesArray = Array.from(
    { length: numPages?._pdfInfo?.numPages || numPages },
    (_, index) => index + 1
  );



  return (
    <>
      <div className={Style.consolidarewrapper}>
        <div className={`${Style.logoContainer} ${Style.alignContainer}`}>
          <div className={Style.Wrapper}>
            <img src={NdeLogo} className={Style.imgContainer} />
          </div>
        </div>
        <div className={`${Style.priceContainer} ${Style.alignContainer}`}>
          <div className={`${Style.spaceBetween} ${Style.Wrapper}`}>
            <div>
              <div>
                <h4>{invoiceData?.Total}</h4>
                <span className={Style.overdueColor}>Balance Due</span>
              </div>
              <div
                className={Style.alignItems}
                style={{ marginTop: "10px", fontSize: "12px" }}
              >
                <div className={`${Style.coulmnFlex} ${Style.tinyWrapper}`}>
                  <span className={Style.titleShade}>Invoice # :</span>
                  <span>{invoiceData.invoiceId}</span>
                </div>
                <div className={`${Style.coulmnFlex} ${Style.tinyWrapper}`}>
                  <span className={Style.titleShade}>Due Date :</span>
                  <span>{invoiceData.invoiceDate}</span>
                </div>
              </div>
            </div>
            <div>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={PaymentApiCall}
              >
                Pay Now
              </LoadingButton>
            </div>
          </div>
        </div>
        <div className={Style.pdfViewer}>
          {pdfUrl ? (
            <PdfRender
              memoizedPdfUrl={pdfUrl}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              pagesArray={pagesArray}
            />
          ) : (
            <CircularProgress size={24} />
          )}
        </div>
      </div>
      <CustomSnackbar
        snackbarInfo={snackbarInfo}
        handleSnackbarClose={handleSnackbarClose}
      />
    </>
  );
};

export default ConsolidatePayment;
