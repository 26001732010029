import React from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
const SourceChart = ({ data }) => {

  return (
    <PieChart width={120} height={120}>
      <Pie
        data={data}
        cx={55}
        cy={55}
        innerRadius={30}
        outerRadius={55} 
        fill="#8884d8"
        paddingAngle={1}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.fill} />
        ))}
      </Pie>
      {/* <Tooltip /> */}
    </PieChart>
  );
};

export default SourceChart;
