import { Grid, Typography } from "@mui/material";
import ReorderableAutocompleteList from "../components/ReorderableAutocompleteList";
import React, { useState } from "react";
import { CustomInputField } from "../components/CustomInputField";
import { CustomSelect } from "../components/CustomSelect";
import { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { getAllStages } from "../../../webservices/PipelineService";
import CustomSnackbar from "../../../components/CustomSnackbar";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

const options = [
    {
        "label": "Sub Pipeline 1",
        "value": "1"
    },
    {
        "label": "Sub Pipeline 2",
        "value": "2"
    }
]

const StyledTypography = withStyles({
    root: {
        fontWeight: "600 !important",
        paddingBottom: "16px"
    }
})(Typography);

export default function StagesForm(props) {

    const queryClient = useQueryClient();

    const {
        control,
        getValues,
        formState: { errors }
    } = useFormContext();

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data,
        isError,
        error
    } = useQuery({
        queryKey: ['getAllStages'],
        queryFn: () => getAllStages(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        isError && setSnackbarInfo({
            open: true,
            severity: 'error',
            message: error?.response?.data?.error?.message
        });
    }, [isError]);

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{ m: 0, pr: 2, pl: 0.5, overflowX: "hidden", overflowY: "auto", maxHeight: props.showSubPipeline ? "80vh" : "70vh", width: "100%" }} >
                {props.edit ?
                    <Typography variant="div" sx={{ pt: 3, pl: 2, width: "100%" }}>
                        <CustomSelect size="small" label="Sub-Pipeline Name" name={"subPipelineName"} inputLabel="Sub-Pipeline Name"
                            options={options} />
                    </Typography>
                    :
                    props.showSubPipeline &&
                    <Typography variant="div" sx={{ pt: 2, width: "100%", pr: 1, pl: 1 }}>
                        <CustomInputField size="small" name="subPipelineName" label="Sub-Pipeline Name*" defaultValue={props.cloneData?.subPipelineName} />
                    </Typography>}
                <Grid item width="100%">
                    <StyledTypography component="div">
                        Open Stages
                    </StyledTypography>
                    <Grid item xs={12} sm={12}>
                        <ReorderableAutocompleteList id="openStages" items={data?.stages?.openStages} defaultValues={props.cloneData?.openStages} />
                    </Grid>
                </Grid>
                <Grid item width="100%">
                    <StyledTypography component="div">
                        Closed Stages
                    </StyledTypography>
                    <Grid item xs={12} sm={12}>
                        <ReorderableAutocompleteList id="closedStages" items={data?.stages?.closedStages} defaultValues={props.cloneData?.closedStages} ></ReorderableAutocompleteList>
                    </Grid>
                </Grid>
            </Grid>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

        </React.Fragment>
    )
}