import React from 'react';
import Modal from 'react-modal';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import './ImageSliderDialog.css'
// Import Swiper styles
import 'swiper/swiper.min.css';
// import "swiper/css/pagination";
// import 'swiper/modules/pagination/pagination.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import CSS styles for the slider (assuming you have imported the necessary CSS file for react-slick)

// Modal styles
const modalStyles = {
  overlay: { 
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999
  },
  content: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '400px',
    maxHeight: '80%',
    overflow: 'hidden'
  }
};

const ImageSliderDialog = ({ isOpen, onClose, images }) => {

  return (
    <Modal
    
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="Image Slider Dialog"
    >
   
      <Swiper
        pagination={{
          type: "fraction",
        }}
        navigation={true}
        modules={[Navigation, Pagination, Scrollbar]}
        className="mySwiper"
      >  
      {images.map((image, index) => (
        <div key={index}>
        <SwiperSlide>
      
        <img src={`https://server.nowdigitaleasy.com:3505/uploads/task/${image}`} alt={`Image ${index}`} />
       
        </SwiperSlide>
        </div>
        ))}
        {/* <SwiperSlide>Slide 1</SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide> */}
      </Swiper>
    </Modal>
  );
};

export default ImageSliderDialog;