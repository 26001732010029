import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import variables from "../../../../assets/styles/_colors.scss";
import { useState } from "react";
import { flexRender } from "@tanstack/react-table";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteDeal from "../../delete/DeleteDeal";
import UpsertDeal from "../../create/UpsertDeal";
import styled from "styled-components";
import ActivitiesPopover from "../../../activities/ActivitiesPopover";
import DealQuickPreview from "../DealQuickPreview";
import { useParams } from "react-router";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
    display: block;
    color: #212129;
    padding-top: 8px;
    padding-bottom: 8px;
`;

export default function PipelineTableRow(props) {

    const paramsData = useParams();
    const rowEl = props.rowEl;
    const [isHovering, setIsHovering] = useState(false);
    const [editDeal, setEditDeal] = useState(false);
    const [deleteDeal, setDeleteDeal] = useState(false);
    const [showQuickView, setShowQuickView] = useState(false);

    useEffect(() => {
    }, [paramsData]);

    return (
        <tr key={rowEl?.original?.id} id={rowEl?.original?.id} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
            style={{ position: "relative", backgroundColor: isHovering ? `${variables.tableBackgroundColor}` : '#fff' }}
        >
            {rowEl.getVisibleCells().map((cellEl) => {
                return (
                    <td key={cellEl.id}>
                        {cellEl.column.columnDef.accessorKey === "dealName" ?
                            <>

                                <StyledLink to={`/crm/pipeline/table/1/1/1`}
                                    state={{
                                        module: props.module,
                                        item: rowEl?.original
                                    }}
                                    style={{ color: isHovering ? `${variables.linkColor}` : "#000" }} >
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                    <Typography component="span" sx={{ visibility: isHovering ? 'inherit' : 'hidden', pl: 1.5 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowQuickView(true) }}>
                                        <Tooltip title="Quick Preview" placement="right">
                                            <IconButton sx={{ p: 0 }}>
                                                <VisibilityOutlinedIcon fontSize="medium" color={variables.greyTextColor} />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </StyledLink>
                            </>
                            :
                            cellEl.column.columnDef.accessorKey === "activity"
                                ? <>
                                    <Typography component="span" sx={{
                                        visibility: isHovering ? 'inherit' : 'hidden'
                                    }}>
                                        <ActivitiesPopover size='medium' color="primary" resetHover={() => setIsHovering(false)} />
                                    </Typography>
                                </>
                                :
                                <>
                                    {flexRender(
                                        cellEl.column.columnDef.cell,
                                        cellEl.getContext()
                                    )}
                                </>
                        }
                    </td>
                );
            })}
            <Stack direction="row" spacing={1} sx={{
                visibility: isHovering ? 'inherit' : 'hidden',
                height: "47px",
                zIndex: 5,
                width: "85px",
                bgcolor: `${variables.lightGreyBackgroundColor}`,
                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                position: 'sticky', right: 0.5,
                pl: 0.5
            }}>
                <IconButton onClick={() => setEditDeal(true)}>
                    <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                </IconButton>
                <IconButton onClick={() => setDeleteDeal(true)}>
                    <DeleteOutlineIcon fontSize='medium' color="error" />
                </IconButton>
            </Stack>

            {showQuickView && <DealQuickPreview item={rowEl.original} handleDrawerClose={() => { setShowQuickView(false); setIsHovering(false); }} />}

            {deleteDeal && <DeleteDeal item={{ name: rowEl?.original?.id }} handleClose={() => { setDeleteDeal(false); setIsHovering(false); }} />}

            {editDeal && <UpsertDeal edit={true} handleDrawerClose={() => { setEditDeal(false); setIsHovering(false); }} />}
        </tr>
    )
}