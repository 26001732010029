import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { CloseButton } from '../../../components/CloseButton';

export default function DeletePipeline(props) {

    const [open, setOpen] = React.useState(true);
    const [deletePipeline, setDeletePipeline] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px"
                }}>

                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Delete Team Pipeline
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>

                    <Typography id="modal-modal-description" component="div" variant="body" sx={{ pt: 2 }}>
                        Deleting this team pipeline will permanently delete the below entities
                    </Typography>
                    <Typography component="div" variant="body" sx={{ pb: 2 }}>
                        associated with this pipeline.
                    </Typography>
                    <Typography component='li' variant='body' sx={{ pb: 1 }}>
                        Records in this team pipeline
                    </Typography>
                    <Typography component='li' variant='body' sx={{ pb: 1 }}>
                        Workflows
                    </Typography>
                    <Typography component='li' variant='body' sx={{ pb: 1 }}>
                        Forms
                    </Typography>
                    <Typography component='li' variant='body' sx={{ pb: 1 }}>
                        Email Templates
                    </Typography>
                    <Typography component='li' variant='body' sx={{ pb: 2 }}>
                        Tags
                    </Typography>

                    <FormControlLabel control={<Checkbox checked={deletePipeline}
                        onChange={() => setDeletePipeline(!deletePipeline)} />} label="I understand the consequences of deleting this team pipeline." />

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 1 }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="error" disabled={!deletePipeline}
                            onClick={handleClose}>Delete Team Pipeline</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}