
import React from "react";



const Address = () => {
  return (
<>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <label>Street Address</label>
        <input
          type="text"
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: 4,
          }}
        />{" "}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        {" "}
        <label>Address Line 2</label>
        <input
          type="text"
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: 4,
          }}
        />{" "}
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          float:"left",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <label>City</label>
        <input
          type="text"
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: 4,
          }}
        />{" "}
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          float:"left",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <label>State</label>
        <input
          type="text"
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: 4,
          }}
        />{" "}
      </div>
      <div
        style={{
          width: "50%",
          display: "flex",
          float:"left",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <label>Zip Code</label>
        <input
          type="text"
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: 4,
          }}
        />{" "}
      </div>
      </>
  
  );
};

export default Address;
