import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useState } from 'react';
import { CircularProgress, Drawer, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import BasicInfoForm from './BasicInfoForm';
import StagesForm from './StagesForm';
import FieldsForm from './FieldsForm';
import validationSchema from './validationSchema';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from 'react';
import { convertToTeamPipelineFormData, createPipelineFormData } from '../../../utils/createpipelineformdata';
import { CloseButton } from '../../../components/CloseButton';
import variables from '../../../assets/styles/_colors.scss';
import { createPipeline } from '../../../webservices/PipelineService';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CustomSnackbar from '../../../components/CustomSnackbar';

const newPipelineSteps = [
    'Basic Info',
    'Stages',
    'Fields',
];

const convertToPipelineSteps = [
    'Basic Info',
    'Fields'
]

export default function CreatePipeline(props) {

    const queryClient = useQueryClient();

    const [isNewPipeline,] = useState(props.isNewPipeline);
    const [steps, setSteps] = useState([]);
    const [formId, setFormId] = useState([]);

    const [open,] = useState(true);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    useEffect(() => {
        isNewPipeline ? setSteps(newPipelineSteps) : setSteps(convertToPipelineSteps);
        isNewPipeline ? setFormId("createNewPipelineForm") : setFormId("convertToTeamPipelineForm");
    }, [isNewPipeline]);

    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === steps.length - 1;

    const currentValidationSchema = isNewPipeline ? validationSchema[activeStep] : validationSchema[activeStep === 0 ? 0 : 2];

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: isNewPipeline ? (props.cloneData ? props.cloneData.defaultValue : createPipelineFormData) : convertToTeamPipelineFormData,
        resolver: yupResolver(currentValidationSchema),
        reValidateMode: "onChange",
        mode: "onChange"
    });

    const { handleSubmit, trigger, formState: { errors } } = methods;

    function renderStepContent(step) {
        if (isNewPipeline) {
            switch (step) {
                case 0:
                    return <BasicInfoForm />;
                case 1:
                    return <StagesForm />;
                case 2:
                    return <FieldsForm />;
                default:
                    return <div>Not Found</div>;
            }
        } else {
            switch (step) {
                case 0:
                    return <BasicInfoForm />;
                case 1:
                    return <FieldsForm />;
                default:
                    return <div>Not Found</div>;
            }
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = async (data) => {
        const isStepValid = await trigger();
        if (isStepValid) {
            if (isLastStep) {
                // alert(JSON.stringify(data));
                data.createdBy = "User 1";
                mutateAsync(data);
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const { mutateAsync, isPending } = useMutation({
        mutationKey: "createPipeline",
        mutationFn: (data) => createPipeline(data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    function handleDrawerClose(response) {
        props.handleDrawerClose(response);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <React.Fragment>
            <Drawer anchor="right" open={open}
                PaperProps={{
                    sx: {
                        minWidth: isLastStep ? "75vw" : "35vw", maxWidth: isLastStep ? "75vw" : "35vw", pt: "20px", pb: 2,
                        borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                    },
                }}>
                <Typography variant="h5" sx={{ pl: 2 }}>{isNewPipeline ? (props.cloneData ? props.cloneData.header : "Create Team Pipeline") : "Convert to Team Pipeline"}
                    <CloseButton disabled={isPending} handleDialogClose={handleDrawerClose} />
                </Typography>
                <Box>
                    <Stepper activeStep={activeStep} sx={{ pb: 0, pt: 2, maxWidth: isLastStep ? "51vw" : "100vw" }} alternativeLabel>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel sx={{
                                        "& .MuiStepLabel-labelContainer .MuiStepLabel-label": {
                                            fontSize: "medium",
                                        }
                                    }}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <FormProvider {...methods}>
                        <form id={formId} onSubmit={handleSubmit(handleNext)}>
                            {renderStepContent(activeStep)}
                        </form>
                    </FormProvider>
                </Box>
                <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                    <Button variant="outlined" disabled={isPending} onClick={() => handleDrawerClose()}> Cancel </Button>
                    {activeStep !== 0 && (<Button variant="outlined" disabled={isPending} onClick={handleBack}> Previous </Button>)}
                    <Button
                        disabled={isPending}
                        type="submit"
                        form={formId}
                        variant="contained"
                        color="primary"
                    > {isLastStep ? 'Save' : 'Next'}
                        {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                    </Button>
                </Stack>
            </Drawer>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

        </React.Fragment>
    );
}
