import DatePicker from "react-datepicker";
import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Radio,
  Select,
  MenuItem,
  Box,
  Checkbox,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css"; 
// import "./organization.css";
import CustomSwitch from "../../../../components/Switch";
import Button from "../../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import RedAsterisk from "../../../../components/RedAsterisk";
import MyCheckbox from "../../../../components/CheckBox";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import enIN from 'date-fns/locale/en-IN';
import Calender from '../../../../Images/Calender.png'

registerLocale('en-IN', enIN);

function Leave() {
    const [age, setAge] = React.useState("");
  const [day, setDay] = React.useState("1");
  const [month, setMonth] = React.useState("1");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [unit, setUnit] = useState(""); // state for radio buttons
  const [balance, setBalance] = useState(""); // state for radio buttons
  const [startDate, setStartDate] = useState(new Date()); // State for the start date
  const [endDate, setEndDate] = useState(null); // State for the end date

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const minDate = new Date(); // Current date
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleAddLeaveClick = () => {
    setIsDialogOpen(true);
  };
  const handleAddLeave = () => {
    console.log("adding add leave click");
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChange1 = (event) => {
    setDay(event.target.value);
  };
  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };
  const handleBalanceChange = (event) => {
    setBalance(event.target.value);
  };

  const handleDialogClose = () => {
    setStartDate(null);
    setEndDate(null);
    setIsDialogOpen(false);
  };
  const numbers = Array.from({ length: 30 }, (_, i) => i + 1);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <div>
      <div
        style={{
          overflowY: "auto",
          // border: "1px solid red",
          height: "61.9vh",
        }}
      >
        <div
          style={{
            // height: "55vh",
            marginTop: "1%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              style={{
                background: "rgba(0, 10, 255, 1)",
                color: "white",
                borderRadius: "5px",
                marginTop: "2%",
                marginRight: "2%",
              }}
              onClick={handleAddLeaveClick}
            >
              Add Leave
            </Button>
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow
                  style={{
                    width: "907px",
                    height: "40px",
                    top: "130px",
                    left: "203px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  <TableCell>Leave Name</TableCell>
                  <TableCell>Days</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  style={{
                    width: "907px",
                    height: "40px",
                    top: "130px",
                    left: "203px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                     style={{marginRight:"5px"}}
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#DA342E" />
                    </svg>
                    Absent
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#DA342E" />
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fill="#FFFFFF"
                        fontSize="10"
                      >
                        2
                      </text>
                    </svg>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <CustomSwitch />
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    width: "907px",
                    height: "40px",
                    top: "130px",
                    left: "203px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                     style={{marginRight:"5px"}}
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#90C02E" />
                    </svg>
                    Compensatory off
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#90C02E" />
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fill="#FFFFFF"
                        fontSize="10"
                      >
                        2
                      </text>
                    </svg>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <CustomSwitch />
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    height: "40px",
                    top: "130px",
                    left: "203px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  <TableCell
                    style={{
                      padding: "0px 16px",
                      height: "40px",
                    }}
                  >
                    <svg
                     style={{marginRight:"5px"}}
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#C12F7F" />
                    </svg>
                    Family and Medical Leave
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#C12F7F" />
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fill="#FFFFFF"
                        fontSize="10"
                      >
                        50
                      </text>
                    </svg>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <CustomSwitch />
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    height: "40px",
                    top: "130px",
                    left: "203px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                    style={{marginRight:"5px"}}
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#9D33F7" />
                    </svg>
                    Funeral Leave
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#9D33F7" />
                      <path
                        d="M8.74707 3.85156V11H7.84375V4.97949L6.02246 5.64355V4.82812L8.60547 3.85156H8.74707Z"
                        fill="white"
                      />
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fill="#FFFFFF"
                        fontSize="10"
                      >
                        1
                      </text>
                    </svg>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <CustomSwitch />
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{
                    height: "40px",
                    top: "130px",
                    left: "203px",
                    gap: "0px",
                    opacity: "0px",
                  }}
                >
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                     style={{marginRight:"5px"}}
                      width="8"
                      height="8"
                      viewBox="0 0 8 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="4" cy="4" r="4" fill="#EFD80B" />
                    </svg>
                 
                      Holiday
                    
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8" r="8" fill="#EFD80B" />
                      <text
                        x="50%"
                        y="50%"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fill="#FFFFFF"
                        fontSize="10"
                      >
                        50
                      </text>
                    </svg>
                  </TableCell>
                  <TableCell
                    style={{
                      padding: "0px 16px",
                    }}
                  >
                    <CustomSwitch />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <div>
              <Dialog
                open={isDialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
              >
                 <Box
                style={{
                  background: "rgba(0, 10, 255, 1)",
                  padding: "10px 10px 10px 25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                  }}
                >
                  Leave
                </Typography>
                <CloseIcon
                  onClick={handleDialogClose}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Box>
                <DialogContent
                  style={{
                    // overflow: "hidden",
                    padding: "15px 30px",
                  }}
                >
                  <div
                    style={{
                      height: "62vh",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "20px",
                        gap:"103px",
                      }}
                    >
                      <Typography>
                        Name
                        <RedAsterisk />
                      </Typography>
                      <TextField
                      fullWidth
                        size="small"
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "110px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography>
                        Type
                        <RedAsterisk />
                      </Typography>

                      <Select
                        // value={age}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        fullWidth
                        size="small"
                      >
                        <MenuItem value="">
                          <em></em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "103px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography>
                        Unit
                        <RedAsterisk />
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                       
                          justifyContent: "space-between",
                          gap: "50px",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            
                          }}
                        >
                          <Radio
                            checked={unit === "Days"}
                            onChange={handleUnitChange}
                            value="Days"
                            name="unit"
                          />
                          <Typography>Hours</Typography>
                        </span>
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Radio
                            checked={unit === "Hours"}
                            onChange={handleUnitChange}
                            value="Hours"
                            name="unit"
                          />
                          <Typography>Days</Typography>
                        </span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <MyCheckbox />
                        <Typography>Accural</Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                          width: "73vw",
                          height: "auto",
                        }}
                      >
                        <TextField
                          select
                          value={age}
                          onChange={handleChange}
                          size="small"
                          variant="outlined"
                          style={{}}
                        >
                          <MenuItem value={10}>Yearly</MenuItem>
                          <MenuItem value={20}>Monthly</MenuItem>
                          <MenuItem value={30}>Weekly</MenuItem>
                        </TextField>
                        <Typography>On</Typography>
                        <TextField
                          select
                          value={day}
                          onChange={handleChange1}
                          size="small"
                          SelectProps={{
                            MenuProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          }}
                          variant="outlined"
                          style={{ margin: "0 10px" }}
                        >
                          {numbers.map((num) => (
                            <MenuItem key={num} value={num}>
                              {num}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          id="outlined-select-month"
                          select
                          label="Month"
                          value={month}
                          onChange={handleChangeMonth}
                          variant="outlined"
                          size="small"
                          SelectProps={{
                            MenuProps: {
                              style: {
                                maxHeight: 300,
                              },
                            },
                          }}
                        >
                          {months.map((month, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {month}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Typography>No.of Days</Typography>
                        <TextField
                          size="small"
                          style={{
                            width: "5vw",
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap:"7px",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography>
                        Balanced based on
                        <RedAsterisk />
                      </Typography>
                      <Radio
                        checked={balance === "Fixed entitlement"}
                        onChange={handleBalanceChange}
                        value="Fixed entitlement"
                        name="unit"
                      />
                      <Typography>Fixed entitlement</Typography>
                      <Radio
                        checked={balance === "Leave grant"}
                        onChange={handleBalanceChange}
                        value="Leave grant"
                        name="unit"
                      />
                      <Typography>Leave Grant</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "74px",
                      }}
                    >
                      <Typography>Description</Typography>
                      <TextField fullWidth />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "100px",
                        marginTop: "20px",
                      }}
                    >
                      <Typography>Validity</Typography>
        <Box sx={{display:"flex"}}>
                        <Box>
                      <DatePicker
                        selected={startDate}
                        onChange={handleStartDateChange}
                        dateFormat="dd/MM/yyyy"
                        // locale="en-IN"
                        // minDate={minDate}
                        placeholderText="Select a date"
                        customInput={
                          <button className="custom-date-button" style={{width:"180px",height:"35px",border: '1px solid #E6E6E6',borderRadius:"5px"}}>
                            {startDate
                              ? startDate.toLocaleDateString()
                              : "From"}
                            <img
                              src={Calender}
                              alt="Calendar"
                              style={{
                                width: "15px",
                                height: "15px",
                                marginLeft: "45px",
                              }}
                            />
                          </button>
                        }
                      />
                      </Box>
                      <Box sx={{marginLeft:"35px"}}>
                      <DatePicker
                        selected={endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select a date"
                        customInput={
                          <button className="custom-date-button" style={{width:"180px",height:"35px",border: '1px solid #E6E6E6',borderRadius:"5px"}}>
                            {endDate ? endDate.toLocaleDateString() : "To"}
                            <img
                              src={Calender}
                              alt="Calendar"
                              style={{
                                width: "15px",
                                height: "15px",
                                marginLeft: "45px",
                              }}
                            />
                          </button>
                        }
                      />
                      </Box>
                      </Box>
                    </div>
                  </div>
                  
                </DialogContent>
                <DialogActions
                  style={{
                    padding:"0 25px 15px 0"
                  }}
                >
                  <Button
                    style={{
                      background: "rgba(0,0,0,0)",
                      color: "black",
                      marginRight:"10px",
                      boxShadow: " 0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      background: "rgba(0, 10, 255, 1)",
                      color: "white",
                      borderRadius: "5px",
                    }}
                    onClick={handleAddLeave}
                  >
                    Add Leave
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default Leave




