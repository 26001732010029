import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { Card, CardActions, CardContent, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Stack, Typography } from '@mui/material';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { EMPTY_STR } from "../../../utils/PipelineConstants";
import { useEffect } from 'react';
import { CloseButton } from '../../../components/CloseButton';
import variables from "../../../assets/styles/_colors.scss";
import { makeStyles } from '@mui/styles';


export default function CreatePipelineWrapper(props) {

  const NEW_PIPELINE = "newpipeline";
  const NEW_SUB_PIPELINE = "newsubpipeline";

  const [open, setOpen] = useState(true);
  const [selectedValue, setSelectedValue] = useState(NEW_PIPELINE);
  const [selectedPipeline, setSelectedPipeline] = useState(EMPTY_STR);

  const useStyles = makeStyles({
    card: {
      minWidth: 275,
      border: `1px solid ${variables.borderColor}`,
      borderRadius: '4px !important',
      boxShadow: "none !important",
      '&:hover': {
        border: `1px solid ${variables.borderHoverColor}`
      }
    },
    cardContent: {
      paddingTop: 0
    },
    cardActions: {
      justifyContent: 'center',
      paddingBottom: '16px !important'
    },
    cardIcons: {
      height: '40px',
      fontSize: '50px',
      textAlign: 'center',
      paddingBottom: 8
    }
  });

  const classes = useStyles();

  function handleDialogClose(type) {
    setOpen(false);
    props.handleDialogClose(type);
  }

  useEffect(() => {
  }, [selectedPipeline]);

  function handleRadioOnChange(event, value) {
    setSelectedValue(value);
    setSelectedPipeline(EMPTY_STR);
  }

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: { minWidth: '40%', maxWidth: '40%', minHeight: '57%', maxHeight: '57%' }
      }}>

      <DialogTitle id="create-pipeline-wrapper-dialog-title">
        What would you like to create?
        <CloseButton handleDialogClose={handleDialogClose} />
      </DialogTitle>

      <DialogContent>
        <FormControl fullWidth sx={{ alignItems: 'center' }}>
          <RadioGroup
            row
            defaultValue={NEW_PIPELINE}
            onChange={handleRadioOnChange}
          >
            <FormControlLabel value={NEW_PIPELINE} control={<Radio color="primary" />}
              label={<Typography fontWeight="bold">New Team Pipeline</Typography>} />
            <FormControlLabel value={NEW_SUB_PIPELINE} control={<Radio color="primary" />}
              label={<Typography fontWeight="bold">New Sub-Pipeline</Typography>} />
          </RadioGroup>
        </FormControl>
        {selectedValue === NEW_PIPELINE &&
          <>
            <Typography pb={2}>Ideal if you need different set of fields and stages to store a new type of record.</Typography>
            <Stack direction="row" spacing={2}>
              <Card className={classes?.card}>
                <CardContent className={classes?.cardContent} >
                  <Typography className={classes.cardIcons}>
                    <EmojiObjectsIcon fontSize="large" color='primary' />
                  </Typography>
                  <Typography align="center" color="default" fontWeight="bold">
                    Create from Scratch
                  </Typography>
                  <Typography align="center">
                    Create a blank team pipeline from
                  </Typography>
                  <Typography align="center">
                    scratch by defining your
                  </Typography>
                  <Typography align="center">
                    own fields and stages
                  </Typography>
                </CardContent>
                <CardActions className={classes?.cardActions}>
                  <Button variant="contained" size="medium" color="primary" onClick={() => handleDialogClose(NEW_PIPELINE)}>Start Creating</Button>
                </CardActions>
              </Card>

              <Card className={classes?.card}>
                <CardContent className={classes?.cardContent}>
                  <Typography className={classes.cardIcons} sx={{ pt: 0.5 }}>
                    <FileCopyOutlinedIcon fontSize="medium" color='primary' />
                  </Typography>
                  <Typography align="center" color="default" fontWeight="bold">
                    Clone from a Template
                  </Typography>
                  <Typography align="center">
                    Browse form our library of predefined
                  </Typography>
                  <Typography align="center">
                    team pipeline template and choose
                  </Typography>
                  <Typography align="center">
                    the one that suits you.
                  </Typography>
                </CardContent>
                <CardActions className={classes?.cardActions}>
                  <Button variant="contained" size="medium" color="primary" onClick={() => handleDialogClose("clonepipeline")}>Pick a Template</Button>
                </CardActions>
              </Card>
            </Stack>
          </>
        }
        {selectedValue === NEW_SUB_PIPELINE &&
          <>
            <Typography paddingBottom="16px">Perfect for creating a new series of stages in any of your existing team pipeline.</Typography>
            <Card className={classes.card} >
              <CardContent className={classes.cardContent}>
                <Typography className={classes.cardIcons}>
                  <CreateNewFolderOutlinedIcon fontSize="large" color='primary'></CreateNewFolderOutlinedIcon>
                </Typography>
                <Typography align="center" color="default" fontWeight="bold">
                  Create Sub-Pipeline
                </Typography>
                <Typography align="center">
                  Choose a team pipeline under which you wish
                </Typography>
                <Typography align="center">
                  to create this new sub-pipeline.
                </Typography>
                <Stack alignItems="center">
                  <FormControl sx={{ minWidth: 200, pt: 1 }} size="small">
                    <Select
                      labelId="create-sub-pipeline-wrapper-label"
                      id="create-sub-pipeline-wrapper-select"
                      value={selectedPipeline}
                      onChange={(event) => setSelectedPipeline(event.target.value)}
                      displayEmpty
                    >
                      <MenuItem value={EMPTY_STR} disabled>Choose Team Pipeline</MenuItem>
                      <MenuItem value={'1'}>Sales Pipeline</MenuItem>
                      <MenuItem value={'2'}>Product Development</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>

              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button variant="contained" size="medium" disabled={selectedPipeline === EMPTY_STR} color="primary" onClick={() => handleDialogClose(NEW_SUB_PIPELINE)}>
                  Start Creating
                </Button>
              </CardActions>
            </Card>
          </>
        }
      </DialogContent>
    </Dialog >
  );
}