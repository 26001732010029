import {  Button, Grid, MenuItem, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  loadingAll,
  login_atoms,
  login_companyid,
  login_token,
} from "../../Atoms/LoginAtoms";
import axios from "axios";
import { api_url } from "../../globalSettings";
import PhoneInput from "react-phone-input-2";
import { Country } from "country-state-city";


const countryList = Country.getAllCountries();




const EditCompany = () => {
  
  const [isLoading, setIsLoading] = useRecoilState(loadingAll);
  const [getCompanydetails, setGetCompanydetails] = useState([]);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [updateMoibile, setUpdateMobile] = useState(
    getCompanydetails.phone_number
  );


 

  const [companyId1, setCompanyId1] = useRecoilState(login_companyid);
  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
 

  const [country1 , setCountry1] =  useState('');
  const [currency , setCurrency] =  useState('')

  useEffect(() => {
    setIsLoading(true);
    const params = {
      user_id: loginAtom1,
      token: loginToken1,
    };
    axios
      .get(`${api_url}/getCompanyList`, { params: { ...params } })
      .then((responce) => {
    
        let data = responce.data.data.find((obj) => obj._id === companyId1);
        setGetCompanydetails(data);
      
        setCountry1(data.country);
        setCurrency(data.currency);
        setIsLoading(false)
      })
      .catch((err) => {
  ;
        setIsLoading(false);
      });
  }, [companyId1]);

  // =============================   UPDATE COMPANY ==============================

  const updateCompany = () => {
    getCompanydetails.token = loginToken1;
    getCompanydetails.company_id = companyId1;
    getCompanydetails.phone_number = updateMoibile;
    getCompanydetails.currency = currency;

   

    axios
      .patch(`${api_url}/updateCompany`, getCompanydetails, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
 
        // setSnackbaropen({
        //   open: true,
        //   message: "Shift Added successfully",
        //   severity: "success",
        //   actionColor: "#edf7ed",
        // });
      })

      .catch(function (error) {
        if (error.response) {
        
        } else if (error.request) {
         
        } else {
         
        }
      
      });
  };

  const handlechange = (e) => {
    const { value, name } = e.target;
    setGetCompanydetails({ ...getCompanydetails, [name]: value });
  };

  function handlechange1(e) {

    setUpdateMobile(e);
  }


  const handleCountryChange = (e) => {
      
       const { value, name } = e.target;

       setCountry1(value);
       setGetCompanydetails({ ...getCompanydetails, [name]: value });
  }


  const handleCurrencyChange = (e) => {

    const { value, name } = e.target;
    setCurrency(value);
    setGetCompanydetails({ ...getCompanydetails, [name]: value });
}

  


  return (
    <Stack
      spacing={4}
      padding={5}
      width="100%"
      alignItems="center"
      height="100%"
    >
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          alignItems="center"
          justifyContent=" center"
        >
          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            value={getCompanydetails.company_name}
            name="company_name"
            label="Company Name"
            InputLabelProps={{ shrink: true }}
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            label={"Email"}
            name="email"
            InputLabelProps={{ shrink: true }}
            value={getCompanydetails.email}
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PhoneInput
            style={{ width: "100%" }}
            enableAreaCodes={true}
            placeholder="Enter phone number"
            value={getCompanydetails.phone_number}
            country={"in"}
            name="phone_number"
            onChange={handlechange1}
            //onChange={setValue}
            //onBlur={handleBlur}
            // inputStyle={isValidPhone ? {} : { border: '2px solid red' }}
          />{" "}
          {/* <PhoneInput
            placeholder="Enter phone number"
            name="phone_number"
            value={getCompanydetails.mobile_number}
            defaultCountry="IN"
            onChange={handlechange1}
          /> */}
          {/* <TextField
  size="small"
  id="standard-basic"
  variant="standard"
  label={"Phone Number"}
  onChange={(e) => formonchange("phone_number", e)}
  fullWidth
  required
/> */}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            name="contact_person"
            InputLabelProps={{ shrink: true }}
            label={"Contact Person"}
            value={getCompanydetails.contact_person}
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            label={"GST NO"}
            name="gst_no"
            InputLabelProps={{ shrink: true }}
            value={getCompanydetails.gst_no}
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {/* <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            name="currency"
            label={"Currency"}
            InputLabelProps={{ shrink: true }}
            value={currency}
            onChange={handlechange}
            fullWidth
            required
          /> */}
          <TextField
                      size="small"
                      name="currency"
                      label="Currency"
                      select
                      //value={country.country}
                      onChange={(e) => handleCurrencyChange(e)}
                      fullWidth
                      // required
                      InputProps={{
                        style: { lineHeight: "1" },
                        endAdornment: null,
                      }}
                      value={currency}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              maxWidth: 200,
                              marginTop: 10,
                            },
                          },
                        },
                      }}
                    >
                      {countryList.map((option, index) => (
                        <MenuItem key={index} value={option.currency}>
                          {option.currency}
                        </MenuItem>
                      ))}
                    </TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            label={"City"}
            name="city"
            value={getCompanydetails.city}
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            name="state"
            label={"State"}
            value={getCompanydetails.state}
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
             <TextField
                      size="small"
                      name="country"
                      label="Country"
                      select
                      //value={country.country}
                      onChange={(e) => handleCountryChange(e)}
                      fullWidth
                      // required
                      InputProps={{
                        style: { lineHeight: "1" },
                        endAdornment: null,
                      }}
                      value={country1}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              maxWidth: 200,
                              marginTop: 10,
                            },
                          },
                        },
                      }}
                    >
                      {countryList.map((option, index) => (
                        <MenuItem key={index} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          alignItems="center"
          justifyContent=" center"
        >
          <TextField
            size="small"
            id="standard-basic"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={getCompanydetails.zip_code}
            name="zip_code"
            label="PinCode"
            onChange={handlechange}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <div className="addcompanybtn">
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="contained"
            className="tablebutton"
            onClick={updateCompany}
          >
            Update Company
          </Button>
        </Stack>
      </div>
    </Stack>
  );
};

export default EditCompany;
