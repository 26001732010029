import React, { useEffect, useState } from "react";
import { Drawer, Button, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./AddContactDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@mui/material/Grid";

import { api_url } from "../../globalSettings";

import axios from "axios";
import {profile_trigger} from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import "react-country-dropdown/dist/index.css";


import styled from "styled-components";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import moment from "moment";


const CssTextField = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#000",
  },

  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#00000033",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#00000033",
      padding: "9.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009DFE",
    },
  },
});



const AdddProjectdrawer = styled.div`
  width: 30vw;

  height: 100%;
`;

const UpdateProject = (props) => {

  const [errors, setErrors] = useState({});
  const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
 


  //===================================== set valid time  ===========================


  const [selectedProject, setSelectedProject] = useState({
    name: "",
    start_date: "",
    end_date: "",
    estimation_hours: "",
    notes: "",
  });


  const [updateName, setUpdateName] = useState("");
  const [updateNo, setUpdateNo] = useState("");
  const [updateNotes, setUpdateNotes] = useState("");


  const [validFrom, setValidFrom] = React.useState("");
  const [validTo, setValidTo] = React.useState("");


  function handleChange3(e) {
    setValidFrom(e.target.value);
    const { value, name } = e.target;
    setSelectedProject((prev) => ({
      ...prev,
      [name]: value.split("-").join("/"),
    }));
 
  }

  function handleChange4(e) {
    setValidTo(e.target.value);
    const { value, name } = e.target;
    setSelectedProject((prev) => ({
      ...prev,
      [name]: value.split("-").join("/"),
    }));

  }

  const handleChange = (event) => {
    setUpdateName(event.target.value);
    const { name, value } = event.target;
    setSelectedProject((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange1 = (event) => {
    setUpdateNo(event.target.value);
    const { name, value } = event.target;
    setSelectedProject((prev) => ({ ...prev, [name]: value }));
  };

  const handleChange2 = (event) => {
    setUpdateNotes(event.target.value);
    const { name, value } = event.target;
    setSelectedProject((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setSelectedProject(props.selectedProject);
  }, [props.selectedProject]);

  const updateProfile = () => {
    const params = {
      projectId: selectedProject._id,
      name: updateName,
      start_date: validFrom,
      end_date: validTo,
      estimation_hours: updateNo,
      notes: updateNotes,
    };
    axios
      .patch(
        `${api_url}/todo/update_project`,
         params ,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(function (response) {
        setProfileTrigger(!profileTrigger);
        // setSnackbaropen({
        //   open: true,
        //   message: "Shift Added successfully",
        //   severity: "success",
        //   actionColor: "#edf7ed",
        // });
      })

      .catch(function (error) {
        if (error.response) {
     
        } else if (error.request) {
        
        } else {
      
        }
      
      });
  };

  return (
    <div>
      {" "}
      <React.Fragment>
        <Drawer anchor="right" open={props.open} onClose={props.toggleDrawer}>
          <AdddProjectdrawer>
            <div className="addcompanytitle">
              UPDATE PROJECT
              <IconButton
                component="span"
                onClick={props.toggleDrawer}
                style={{ padding: 0, marginRight: 20 }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div className="information">
              <Grid container spacing={4} padding={5}>
                <Grid item xs={12} alignItems="center" justifyContent=" center">
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    value={selectedProject.name}
                    label={"Project Name"}
                    name="name"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  {errors.name ? (
                    <div
                      className="text-danger"
                      style={{
                        marginTop: "10px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      <ErrorOutlineIcon
                        style={{ marginRight: "5px", fontSize: "15px" }}
                      />
                      {errors.name}
                    </div>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <CssTextField
                    style={{
                      height: "50px",
                      textTransform: "capitalize",
                    }}
                    id="date"
                    type="date"
                    name="start_date"
                    value={
                      selectedProject.start_date
                        ? moment(
                            new Date(
                              selectedProject.start_date
                            ).toLocaleDateString(),
                            "MM/DD/YYYY"
                          )
                            .format("DD/MM/YYYY")
                            .split("/")
                            .reverse()
                            .join("-")
                        : ""
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange3}
                    size="small"
                    fullWidth
                    label="Valid From*"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <CssTextField
                    style={{
                      height: "50px",
                      textTransform: "capitalize",
                    }}
                    id="date"
                    type="date"
                    name="end_date"
                    value={
                      selectedProject.end_date
                        ? moment(
                            new Date(
                              selectedProject.end_date
                            ).toLocaleDateString(),
                            "MM/DD/YYYY"
                          )
                            .format("DD/MM/YYYY")
                            .split("/")
                            .reverse()
                            .join("-")
                        : ""
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange4}
                    size="small"
                    fullWidth
                    label="Valid To*"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    variant="outlined"
                    type="number"
                    label={"Estimation (hours)"}
                    value={selectedProject.estimation_hours}
                    name="estimation_hours"
                    onChange={handleChange1}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} alignItems="center" justifyContent=" center">
                  <TextField
                    size="small"
                    id="outlined-multiline-static"
                    variant="outlined"
                    multiline
                    rows={3}
                    label={"Project Notes"}
                    value={selectedProject.notes}
                    name="notes"
                    onChange={handleChange2}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "30px",
              }}
            >
              <Button className="tablebutton" onClick={updateProfile}>
                UPDATE Project
              </Button>
            </div>
            
          </AdddProjectdrawer>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default UpdateProject;
