import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../../styles/navBar.css";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import vector from "./img/Vector.png";

import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

import { TextField } from "@mui/material";

import { useRecoilState } from "recoil";
import { login_EmpDetails } from "../../Atoms/LoginAtoms";
import { isSideBarActive } from "../../Atoms/SideBarAtom";
import { preloadStatus } from "../../Atoms/sequence";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import notifiImg from "./img/Notifications.png";
import settings from "./img/Group.png";
import profile from "./img/Group 68835.png";
import NotificationDrawer from "./NotificationDrawer";
import UserDrawer from "./Component/UserDrawer";
import SearchBar from "./NavBar/SearchBar";
import CustomCompanyDropDown from "./CompanyDropDown/CustomCompanyDropDown";
const CssTextField = styled(TextField)(({ theme }) => ({
  borderRadius: 20,
  width: 120,
  cursor: "pointer",
  color: "antiquewhite",

  "& label.Mui-focused": {
    color: "antiquewhite",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "antiquewhite",
      color: "antiquewhite",
    },
    "&:hover fieldset": {
      borderColor: "antiquewhite",
      color: "antiquewhite",
    },
    "&.Mui-focused fieldset": {
      borderColor: "antiquewhite",
      color: "antiquewhite",
    },
    "& p": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "68px",
      height: "17px",
      position: "relative",
      top: "16%",
    },
  },
}));

<CssTextField label="Your Label" variant="outlined" />;

export default function NavBar() {
  let history = useNavigate();

  const [loginEmpDetails] = useRecoilState(login_EmpDetails);
  const { userInfo } = useSelector((state) => state.login);
  const [isBarOpen, setIsBarOpen] = useRecoilState(isSideBarActive);

  const [preloadStatus1, setpreloadStatus1] = useRecoilState(preloadStatus);

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };

  const [openNotificationDrawer, setNotificationDrawer] = useState(false);

  function handleNotificationOpen() {
    setNotificationDrawer(true);
  }

  function handleNotificationClose() {
    setNotificationDrawer(false);
  }

  const [showNavbar, setShowNavbar] = useState(false);

  return (
    <div className="navBar">
      {preloadStatus1 ? <div class="lineerPreloader"></div> : ""}
      <div className="outside-container">
        <div style={{ marginLeft: "6px" }}>
          <IconButton
            onClick={() => {
              setIsBarOpen(!isBarOpen);
            }}
          >
            <img src={vector} alt="web services" style={{ paddingLeft: 5 }} />
          </IconButton>
        </div>
        <div className="container1">
          <div className={`nav-elements  ${showNavbar && "active"}`}>
            <div style={{ marginRight: "24px" }}>
              <SearchBar />
            </div>
          </div>
          <div className="nav-bar-icons-wrapper">
            {loginEmpDetails.role === "employee" ? (
              ""
            ) : (
              <>
                <div className="nav-bar-notification">
                  {true ? (
                    <CustomCompanyDropDown />
                  ) : (
                    <Tooltip title="Add Company" placement="top-end">
                      <Link
                        style={{
                          color: "#000",
                          textDecoration: "none",
                        }}
                        to="/settings/managecompany"
                      >
                        <AddCircleOutlineIcon />
                      </Link>{" "}
                    </Tooltip>
                  )}
                </div>
                <div className="nav-bar-settings">
                  <img
                    style={{ color: "#000", cursor: "pointer", padding: 0 }}
                    src={notifiImg}
                    alt="notification"
                    onClick={handleNotificationOpen}
                  />
                </div>
                <div className="nav-bar-settings nav-bar-settings-icon">
                  <img
                    src={settings}
                    style={{ color: "#000", cursor: "pointer" }}
                    onClick={() => history("mySettings")}
                    alt="settings"
                  />
                </div>{" "}
              </>
            )}

            <div className="nav-bar-user-icon" onClick={toggleDrawer}>
              <img
                style={{ height: "36px", width: "36px" }}
                alt={userInfo.first_name}
                src={profile}
              />
            </div>
            <UserDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
            <NotificationDrawer
              open={openNotificationDrawer}
              handleClose={handleNotificationClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
