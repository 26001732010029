export const RouteArray = [
  {
    routeId: "#myaccount",
    routeLabel: "My Account",
    route: "myaccount",
    subRoutes: [
      {
        subRouteId: "#profile",
        subRouteLabel: "Profile",
        subRoute: "profile",
      },
      {
        subRouteId: "#billing",
        subRouteLabel: "Biling",
        subRoute: "billing",
      },
    ],
  },
  {
    routeId: "#webservices",
    routeLabel: "Web Services",
    route:"webservices",
    subRoutes: [
      {
        subRouteId: "#services",
        subRouteLabel: "Services",
        subRoute: "webservices",
      },
    ],
  },

  {
    routeId: "#ndeMail",
    routeLabel: "Mail",
    route:"mail",
    subRoutes: [
        {
            subRouteId: "#inboxx",
            subRouteLabel: "Inbox",
            subRoute:"inbox"
          },
      {
        subRouteId: "#draftss",
        subRouteLabel: "Drafts",
        subRoute:"drafts"
      },
      {
        subRouteId: "#templates",
        subRouteLabel: "Templates",
        subRoute:"templates"
      },
      {
        subRouteId: "#sent",
        subRouteLabel: "Sent",
        subRoute:"sent"
      },
      {
        subRouteId: "#spam",
        subRouteLabel: "Spam",
        subRoute:"spam"
      },
      {
        subRouteId: "#trash",
        subRouteLabel: "Trash",
        subRoute:"trash"
      },
      {
        subRouteId: "#outbox",
        subRouteLabel: "Out Box",
        subRoute:"outbox"
      },
    ],
  },

  {
    routeId: "#Chat",
    routeLabel: "Chat Now",
    route:"chat",
    subRoutes: [
    //   {
    //     subRouteId: "#services",
    //     subRouteLabel: "Services",
    //     subRoute: "webservices",
    //   },
    ],
  },
  {
    routeId: "#vissonNow",
    routeLabel: "Visson Now",
    route:"contacts",
    subRoutes: [
      {
        subRouteId: "#dashboard",
        subRouteLabel: "DashBoard",
        subRoute: "dashboard",
      },
      {
        subRouteId: "#contact",
        subRouteLabel: "Contacts",
        subRoute: "contact",
      },
      {
        subRouteId: "#dashboard",
        subRouteLabel: "DashBoard",
        subRoute: "dashboard",
      },
      {
        subRouteId: "#fields",
        subRouteLabel: "Fields",
        subRoute: "Fields",
      },
    ],
  },
  {
    routeId: "#peoplesnow",
    routeLabel: "Peoples Now",
    route:"settings",
    subRoutes: [
    //   {
    //     subRouteId: "#services",
    //     subRouteLabel: "Services",
    //     subRoute: "webservices",
    //   },
    ],
  },
];
