import { FormControl, MenuItem, Select, Stack, Typography, Button } from '@mui/material';
import variables from "../../../../assets/styles/_colors.scss";
import CustomSnackbar from '../../../../components/CustomSnackbar';
import UpsertTransitionRule from './UpsertTransitionRule';
import AddIcon from '@mui/icons-material/Add';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TransitionRulesTable from "../transitionrules/TransitionRulesTable";
import { useEffect } from 'react';
import { useState } from 'react';

export default function TransitionRules(props) {

    const [selectedTeamPipeline, setSelectedTeamPipeline] = useState("");
    const [selectedSubPipeline, setSelectedSubPipeline] = useState("");
    const [showCreateRule, setShowCreateRule] = useState(false);
    const [sortByAsc, setSortByAsc] = useState(true);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    useEffect(() => {
        if (props.data) {
            setSelectedTeamPipeline(props.data?.pipelines[0]?._id);
            setSelectedSubPipeline(props.data?.pipelines[0]?.subPipelines[0]?._id);
        }
    }, [props.data]);

    const handleTeamPipelineChange = (event) => {
        const selectedPipeline = event.target.value;
        setSelectedTeamPipeline(selectedPipeline);
        setSelectedSubPipeline(props.data?.pipelines.find(pipeline => pipeline._id === selectedPipeline)?.subPipelines[0]?._id);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        });
    }

    return (
        <>
            <Typography sx={{ fontWeight: 'bold', fontSize: 16, pt: 0.5 }}>
                Transition Rules
            </Typography>
            <Typography sx={{ pb: 2, pt: 0.5 }}>
                Transition Rules let you specify details that need to be filled by users when they move a pipeline record from one stage to another.
            </Typography>

            <Stack direction="row" width="100%" sx={{ pb: 2 }}>
                <Stack direction="row" spacing={1} width="50%" justifyContent="flex-start" alignItems="center">
                    <Typography>Team Pipeline</Typography>
                    <FormControl size="small" sx={{ background: "#fff" }}>
                        <Select
                            value={selectedTeamPipeline}
                            onChange={handleTeamPipelineChange}
                        >
                            {
                                props.data?.pipelines.map((pipeline, i) => {
                                    return <MenuItem key={i} value={pipeline._id}>{pipeline.pipelineName}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <Typography sx={{ ml: "20px !important" }}>Sub-Pipeline</Typography>
                    <FormControl size="small" sx={{ background: "#fff" }}>
                        <Select
                            value={selectedSubPipeline}
                            onChange={(event) => setSelectedSubPipeline(event.target.value)}
                        >
                            {
                                props.data?.pipelines.find(pipeline => pipeline._id === selectedTeamPipeline)?.subPipelines.map((subPipeline, i) => {
                                    return <MenuItem key={i} value={subPipeline._id}>{subPipeline.subPipelineName}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} width="50%" alignItems="center" justifyContent="flex-end">
                    <Typography>Sort By</Typography>
                    <Stack direction="row" onClick={() => { setSortByAsc(!sortByAsc) }} sx={{ ml: 1, cursor: "pointer", background: "#fff", border: `1px solid ${variables.borderColor}`, p: 1, borderRadius: 4 }}>
                        {sortByAsc ?
                            <ArrowUpwardIcon />
                            :
                            <ArrowDownwardIcon />
                        }
                        <Typography> Created Time </Typography>
                    </Stack>
                    <Button variant="contained" onClick={() => { setShowCreateRule(true); }}><AddIcon /> New Rule </Button>
                </Stack>
            </Stack>

            <TransitionRulesTable
                teamPipelineId={selectedTeamPipeline}
                subPipelineId={selectedSubPipeline}
                sortByAsc={sortByAsc}
                showSnackbar={(data) => showSnackbar(data)} />

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

            {showCreateRule && <UpsertTransitionRule handleDrawerClose={(data) => { setShowCreateRule(false); showSnackbar(data); }} teamPipelineId={selectedTeamPipeline} subPipelineId={selectedSubPipeline} />}
        </>
    )
}