import { Box, Button, Drawer, IconButton, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import variables from "../../../../assets/styles/_colors.scss";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteActivity from "../../DeleteActivity";
import UpsertTask from "../../create/UpsertTask";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@mui/styles";
import PersonIcon from '@mui/icons-material/Person';
import { ActivityInfo } from "./ActivityInfo";
import { Notes } from "../../../../components/notes/Notes";
import SplitButton from "../../../../components/SplitButton";

const StyledIcon = withStyles({
    root: {
        height: "32px",
        width: "32px",
        backgroundColor: `${variables.iconButtonBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.iconButtonBackgroundHoverColor} !important`
        }
    }
})(IconButton);

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important'
    }
})(Tab);

const ActivityQuickPreview = (props) => {

    const [open, setOpen] = useState(true);
    const [editActivity, setEditActivity] = useState(false);
    const [deleteActivity, setDeleteActivity] = useState(false);
    const [selectedTab, setSelectedTab] = useState("0");

    function handleDrawerClose() {
        setOpen(false);
        props.handleDrawerClose();
    }

    const handleTabChange = (e, val) => {
        setSelectedTab(val);
    }

    const handleSplitButtonSelection = (actionLabel) => {

        if (actionLabel === "Mark as Completed") {
        }
        if (actionLabel === "Reschedule Call") {
            setEditActivity(true);
        }

        if (actionLabel === "Cancel Call") {
        }
    }

    return (
        <><Drawer anchor="right" open={open}
            PaperProps={{
                sx: {
                    minWidth: "35vw", maxWidth: "35vw", borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                },
            }}>
            <Box sx={{ height: "100vh" }}>
                <Box sx={{ bgcolor: `${variables.lightGreyBackgroundColor}`, pt: "20px", pb: 2 }}>
                    <Stack direction="row" width="100%" alignItems="top" sx={{ pl: 2, pr: 1.25 }}>
                        <Stack direction="column" width="60%" justifyContent="left">
                            <Typography variant="h6" sx={{ color: `${variables.linkColor}`, fontWeight: 600, fontSize: "15px" }}>
                                {props.module === "task" && props.item.taskName}
                                {props.module === "event" && props.item.title}
                                {props.module === "call" && props.item.toFrom}
                            </Typography>
                            <Typography sx={{ pt: 0.5 }}>
                                <PersonIcon size="small" sx={{ width: 16, height: 16 }} />
                                <Typography component="span" sx={{ fontSize: "13px", pl: 1 }}>
                                    {props.module === "task" && props.item.taskOwner}
                                    {props.module === "event" && props.item.host}
                                    {props.module === "call" && props.item.callOwner}
                                </Typography>
                            </Typography>
                        </Stack>
                        <Stack direction="row" width="40%" justifyContent="right" spacing={1}>
                            <StyledIcon onClick={() => setEditActivity(true)}>
                                <EditOutlinedIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                            <StyledIcon onClick={() => setDeleteActivity(true)}>
                                <DeleteOutlineIcon fontSize='medium' color="error" />
                            </StyledIcon>
                            <StyledIcon onClick={() => handleDrawerClose()}>
                                <CloseIcon fontSize='medium' sx={{ color: `${variables.greyTextColor}` }} />
                            </StyledIcon>
                        </Stack>
                    </Stack>
                    <Stack direction="row" sx={{ pl: 2, pt: 2 }}>
                        {props.module === "task" &&
                            <Button variant="contained"> Mark as completed </Button>}
                        {props.module === "call" &&
                            <SplitButton options={["Reschedule Call", "Cancel Call"]} label="Mark as Completed" width={200} handleSplitButtonSelection={(actionLabel) => handleSplitButtonSelection(actionLabel)} />}
                    </Stack>
                </Box>

                <Box sx={{ overflowY: "auto", overflowX: "hidden", pl: 1, height: "80vh" }}>
                    <Tabs value={selectedTab} onChange={handleTabChange}>
                        <StyledTab label="Information" value="0" />
                        <StyledTab label="Notes" value="1" />
                    </Tabs>
                    {
                        <>
                            {selectedTab === "0" &&
                                <ActivityInfo module={props.module} />
                            }

                            {selectedTab === "1" &&
                                <Notes />
                            }
                        </>
                    }
                </Box>

                {deleteActivity &&
                    <DeleteActivity item={props.item} module={props.module} handleClose={() => { setDeleteActivity(false) }} />}

                {editActivity &&
                    <UpsertTask edit={true} module={props.module} handleDrawerClose={() => { setEditActivity(false) }} />}
            </Box>
        </Drawer >
        </>
    );
};

export default ActivityQuickPreview;