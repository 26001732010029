import * as React from 'react';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { CircularProgress, Drawer, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import StagesForm from './StagesForm';
import validationSchema from './validationSchema';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import BasicInfoForm from './BasicInfoForm';
import { useEffect } from 'react';
import FieldsForm from './FieldsForm';
import { CloseButton } from '../../../components/CloseButton';
import variables from '../../../assets/styles/_colors.scss';

const formId = "editPipelineForm";

export default function EditPipeline(props) {

    const [open, setOpen] = useState(true);

    useEffect(() => {
    }, [props.data]);

    useEffect(() => {
    }, [props.data.moduleIndex]);

    function handleDrawerClose() {
        props.handleDrawerClose();
    }

    const renderEditForm = () => {
        switch (props.data.moduleIndex) {
            case 0:
                return <BasicInfoForm />
            case 1:
                return <StagesForm edit={true} />
            case 2:
                return <FieldsForm edit={true} />
            default:
                return <p> Not found </p>
        }
    }

    const currentValidationSchema = validationSchema[props.data.moduleIndex];

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: props.data.defaultValue,
        resolver: yupResolver(currentValidationSchema),
        reValidateMode: "onChange",
        mode: "onChange"
    });

    const { handleSubmit, trigger } = methods;

    const handleNext = async (data) => {
        const isStepValid = await trigger();
        if (isStepValid) {
            // alert(JSON.stringify(data));
            handleDrawerClose();
        };
    };

    return (
        <div>
            <React.Fragment>
                <Drawer anchor="right" open={open}
                    PaperProps={{
                        sx: {
                            minWidth: props.data.moduleIndex === 2 ? "75vw" : "35vw", maxWidth: props.data.moduleIndex === 2 ? "75vw" : "35vw", pt: "20px", pb: 2,
                            borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                        },
                    }}>
                    <Typography variant="h5" sx={{ pl: 2 }}>{props.data.header}
                        <CloseButton handleDialogClose={handleDrawerClose} />
                    </Typography>
                    <Box sx={{ width: '100%' }}>
                        <FormProvider {...methods}>
                            <form id={formId} onSubmit={handleSubmit(handleNext)}>
                                {renderEditForm()}
                            </form>
                        </FormProvider>
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="right" sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button variant="outlined" onClick={handleDrawerClose}> Cancel  </Button>
                        <Button
                            // disabled={isSubmitting}
                            form={formId}
                            type="submit"
                            variant="contained"
                            color="primary"
                        > Save</Button>
                        {/* {isSubmitting && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )} */}
                    </Stack>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
