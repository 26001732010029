import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CloseButton } from '../../components/CloseButton';

export default function DeleteProduct(props) {

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="delete-product-title"
                aria-describedby="delete-product-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px"
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Delete Product
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ mt: 3, mb: 1 }}>
                        Are you sure you want to delete the product <em>{props?.item?.productName}?</em>
                    </Typography>

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" color="error" onClick={handleClose}>Delete Product</Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}