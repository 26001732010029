import { Modal } from '@mui/material';
import DashBoard from "./DashBoard/Dashboard"
function SettingsModule({open,handleClose}) {

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          
        }}
      >
        <div style={{
          width: "79vw",
          height: "85vh",
          backgroundColor: "white",
          // border: "2px solid #000",
          // padding: "20px",
          // textAlign: "center",
          borderRadius: "8px",
          overflow: "hidden"
          
        }}>

          <DashBoard handleClose={handleClose} />
          
        </div>
      </Modal>
    </div>
  );
}

export default SettingsModule;
