import React from 'react'

const ChatHome = () => {
  return (
    <div>
      Chat
    </div>
  )
}

export default ChatHome
