import { action_contants } from "../actions/action-types.js";

const initialState = {
  employeeList: [],
  branchForm: {
    action: "add_branch",
    token: "",
    company_id: "",
    branch_name: "",
    country: "",
    city: "",
    street1: "",
    contact_person: "",
    mobile_number: "",
  },
  companyInfo: {},
};

const manageEmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case action_contants.SET_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.payload,
      };

    default:
      break;
  }
  return state;
};

export default manageEmployeeReducer;
