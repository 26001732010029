import React, { useState } from "react";

import { api_url1 } from "../../globalSettings";
import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";
import LogInHeader from "./LogIn/Component/LogInHeader";
import CustomButtonContainer from "./Components/CustomButtonContainer";
import CustomButton from "./Components/CustomButton";
import CustomSnackBar from "../SnackBar/CustomSnackBar";
const ResetPassword = (props) => {
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please Enter your password")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Character"
      ),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassowrd: false,
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const params = {
        token: props.token,
        newPassword: values.password,
        confirmPassword: values.confirmPassword,
        id: props.id,
      };

      axios
        .patch(`${api_url1}/client/changepassword`, params)
        .then(function (response) {
          setSnackbaropen({
            open: true,
            severity: "success",
            message: response.data,
          });
          window.location.href = "/login";
        })

        .catch(function (error) {
          setSnackbaropen({
            open: true,
            severity: "error",
            actionColor: "#edf7ed",
            message: error.response.data.error.message,
          });
          setSubmitting(false);
        });
    },
  });
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePassowrdVisiblility = (event) => {
    formik.handleChange({
      target: {
        name: "showPassword",
        value: !formik.values.showPassword,
      },
    });
  };
  const handleConfirmPassowrdVisiblility = (event) => {
    formik.handleChange({
      target: {
        name: "showConfirmPassowrd",
        value: !formik.values.showConfirmPassowrd,
      },
    });
  };
  return (
    <div>
      <LogInHeader />

      <form onSubmit={formik.handleSubmit}>
        <div style={{ padding: "0px 5px" }}>
          <CustomButtonContainer
            name={"password"}
            value={formik.values.password}
            error={formik.touched.password && !!formik.errors.password}
            helperText={formik.touched.password && formik.errors.password}
            onChangeFuncion={formik.handleChange}
            title={"Password"}
            isPassword={true}
            handleMouseDownPassword={handleMouseDownPassword}
            handleVisiblility={handlePassowrdVisiblility}
            showPassword={formik.values.showPassword}
            isMargin={true}
          />
          <CustomButtonContainer
            name={"confirmPassword"}
            value={formik.values.confirmPassword}
            error={
              formik.touched.confirmPassword && !!formik.errors.confirmPassword
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            onChangeFuncion={formik.handleChange}
            title={"Confirm Password"}
            isPassword={true}
            handleMouseDownPassword={handleMouseDownPassword}
            handleVisiblility={handleConfirmPassowrdVisiblility}
            showPassword={formik.values.showConfirmPassowrd}
            isMargin={true}
          />
          <CustomButton
            title={"Reset Password"}
            onSubmit={formik.handleSubmit}
            loading={formik.isSubmitting}
          />
        </div>
      </form>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="left"
      />
    </div>
  );
};

export default ResetPassword;
