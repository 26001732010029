import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { CloseButton } from "../../../components/CloseButton";

export default function EditModuleName(props) {

    const [open, setOpen] = useState(true);
    const [plural, setPlural] = useState("");
    const [singular, setSingular] = useState("");

    function handleDialogClose(type) {
        setOpen(false);
        props.handleDialogClose(type);
    }

    useEffect(() => {
        setSingular(props.item?.singular);
        setPlural(props.item?.plural);
    }, [props.item]);

    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: { minWidth: '42%', maxWidth: '42%', overflow: 'hidden' }
            }}>

            <DialogTitle>
                Edit Module Name
                <CloseButton handleDialogClose={handleDialogClose} />
            </DialogTitle>
            <DialogContent>
                <form id="editModuleForm" onSubmit={handleDialogClose}>
                    <TextField
                        fullWidth
                        autoFocus
                        label={"Plural*"}
                        variant="outlined"
                        value={plural}
                        onChange={(e) => setPlural(e.target.value)}
                        size={"medium"}
                        sx={{
                            mt: 1, mb: 2
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        fullWidth
                        label={"Singular*"}
                        variant="outlined"
                        size={"medium"}
                        value={singular}
                        onChange={(e) => setSingular(e.target.value)}
                        sx={{
                            mt: 1
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction="row" justifyContent="right" sx={{ pb: 2, pr: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        form={"editModuleForm"}
                        disabled={plural?.trim() === "" || singular?.trim() === ""}>
                        Save
                    </Button>
                </Stack>
            </DialogActions>

        </Dialog>
    );

};