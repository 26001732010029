import React, { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { api_url1 } from "../../globalSettings";
import { useRecoilState } from "recoil";
import UseSelectedWorkSpace from "../../GlobalState/selectedWorkSpace";
import {
  JWT,
  login_EmpDetails,
  login_atoms,
  login_token,
} from "../../Atoms/LoginAtoms";
import { clientidd } from "../login/loginatom";
import { useParams, useNavigate } from "react-router-dom";
import useWorkSpaceStore from "../../GlobalState/WorkSpaceState";
import CustomSnackBar from "../SnackBar/CustomSnackBar";
import { useQuery } from "@tanstack/react-query";

const TokenAuth = () => {
  const navigate = useNavigate();
  const setWorkSpace = useWorkSpaceStore((state) => state.setWorkSpace);
  const setSelectedWorkSpace = UseSelectedWorkSpace(
    (state) => state.setSelectedWorkSpace
  );
  const [clientid, setClientid] = useRecoilState(clientidd);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [loginEmpDetails, setLoginEmpDetails] =
    useRecoilState(login_EmpDetails);
  const { jwt } = useParams();
  const splitted = jwt.split(" ");
  console.log("myjwttoken", splitted[1]);
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [JToken, setJToken] = useRecoilState(JWT);

  function checkAuth() {
    let obj = {
      token: splitted[1],
    };

    axios.post(`${api_url1}/client/signin`, obj).then((data) => {
      localStorage.setItem("auth", JSON.stringify(data.data.data));

      const selectedWorkSpace = data.data.workspaces.find(
        (workspace) => workspace.id === data.data.defaultWorkspace
      );
      setSnackbaropen({
        open: true,
        message: "Redirecting......",
        severity: "success",
      });
      setSelectedWorkSpace(selectedWorkSpace);
      setWorkSpace(data.data.workspaces);
      setloginAtom1(data.data.data.user_id);
      setloginToken1(data.data.data.token);
      setClientid(data.data.data.user_id);
      setJToken(`Bearer ${data.data.data.jwtToken}`);
      setLoginEmpDetails(data.data.data);

      window.location.href = "/";
      return data.data.data;
    });
  }
  useQuery({
    queryKey: ["LogInViaTokenRidrect"],
    queryFn: checkAuth,
  });
  return (
    <>
      <Box
        height={"calc(100vh - 48px)"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="right"
      />
    </>
  );
};

export default TokenAuth;
