import React from 'react'

const VoiceHome = () => {
  return (
    <div>
      Voice
    </div>
  )
}

export default VoiceHome
