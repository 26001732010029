
import SwapVertIcon from '@mui/icons-material/SwapVert';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import variables from "../../../../assets/styles/_colors.scss";
import DeleteStage from "../../../pipelines/delete/DeleteStage";
import RenameStage from "./RenameStage";
import ReplaceStage from "./ReplaceStage";
import { useState } from 'react';
import { settings_atom } from '../../../../../../Atoms/CrmAtoms';
import { useRecoilState } from 'recoil';

export default function StageRow(props) {

    const [settingsAtom, ] = useRecoilState(settings_atom);

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [removeStage, setRemoveStage] = useState(false);
    const [replaceStage, setReplaceStage] = useState(false);
    const [renameStage, setRenameStage] = useState(false);

    return (
        <>
            <Typography component="div"
                sx={{ width: '100%', backgroundColor: hoveredIndex === props.stageIndex && `${variables.lightGreyBackgroundColor}`, pr: 2, pt: 0.75, pb: 0.75, m: '0 !important' }}
                onMouseEnter={() => { setHoveredIndex(props.stageIndex); }} onMouseLeave={() => { setHoveredIndex(null); }}>
                <Stack direction="row" alignItems="center"
                    sx={{
                        p: 1, border: `1px solid ${variables.borderColor}`, borderRadius: 1, height: 36
                    }}>
                    {props.stage?.stageName}
                    {hoveredIndex === props.stageIndex && !settingsAtom.freezeActions &&
                        <Typography component="span" style={{ marginLeft: "auto" }}>
                            <Tooltip title={"Rename"}>
                                <Typography component="span" >
                                    <IconButton aria-label="rename" color="primary" size="small" onClick={() => setRenameStage(true)}>
                                        <EditOutlinedIcon fontSize="inherit"></EditOutlinedIcon>
                                    </IconButton>
                                </Typography>
                            </Tooltip>
                            <Tooltip title={"Replace"}>
                                <Typography component="span" >
                                    <IconButton aria-label="replace" color="primary" size="small" onClick={() => setReplaceStage(true)}>
                                        <SwapVertIcon fontSize="inherit"></SwapVertIcon>
                                    </IconButton>
                                </Typography>
                            </Tooltip>
                            {props.stages.length > 1 &&
                                <Tooltip title={"Remove"}>
                                    <Typography component="span" >
                                        <IconButton aria-label="remove" size="small" onClick={() => setRemoveStage(true)}>
                                            <RemoveCircleOutlineIcon fontSize="inherit" color="error"></RemoveCircleOutlineIcon>
                                        </IconButton>
                                    </Typography>
                                </Tooltip>
                            }
                        </Typography>}
                </Stack>
            </Typography>
            {
                renameStage && <RenameStage stage={props.stage} handleClose={() => setRenameStage(false)} teamPipelineId={props.teamPipelineId} subPipelineId={props.subPipelineId} />
            }

            {
                replaceStage && <ReplaceStage stage={props.stage} stages={props.stages} handleClose={() => setReplaceStage(false)} teamPipelineId={props.teamPipelineId} subPipelineId={props.subPipelineId} />
            }

            {
                removeStage && <DeleteStage stage={props.stage} stages={props.stages} handleClose={() => setRemoveStage(false)} teamPipelineId={props.teamPipelineId} subPipelineId={props.subPipelineId} />
            }

        </>
    );
}