import { Grid, Stack, Tab, Tabs } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";
import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import FieldsConfiguration from "./FieldsConfiguration";
import { FormProvider, useForm } from "react-hook-form";
import { createCallLog, createCompany, createContact, createEvent, createProduct, createTask, editFieldsData } from "../../../utils/createpipelineformdata";
import validationSchema from "../../pipelines/create/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import CrmSettings from "../CrmSettings";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important'
    }
})(Tab);

const moduleFields = [
    {
        label: "Contacts",
        plural: "Contacts",
        singular: "Contact",
        key: "contacts",
        schema: createContact
    },
    {
        label: "Companies",
        plural: "Companies",
        singular: "Company",
        key: "companies",
        schema: createCompany
    },
    {
        label: "Products",
        plural: "Products",
        singular: "Product",
        key: "products",
        schema: createProduct
    },
    {
        label: "Tasks",
        plural: "Tasks",
        singular: "Task",
        key: "tasks",
        schema: createTask
    },
    {
        label: "Events",
        plural: "Events",
        singular: "Event",
        key: "events",
        schema: createEvent
    },
    {
        label: "Calls",
        plural: "Calls",
        singular: "Call",
        key: "calls",
        schema: createCallLog
    }
];

const pipelineFields = [
    {
        label: "Sales Pipeline",
        key: "salesPipeline",
        pipelineName: "Sales Pipeline",
        singular: "Deal",
        plural: "Deals",
        permissions: [
            { value: "administration", label: "Administration" },
            { value: "standard", label: "Standard" }
        ],
        schema: editFieldsData
    },
    {
        label: "Test Pipeline",
        key: "testPipeline",
        pipelineName: "Test Pipeline",
        singular: "Ticket",
        plural: "Tickets",
        permissions: [
            { value: "administration", label: "Administration" },
            { value: "standard", label: "Standard" }
        ],
        schema: editFieldsData
    }
];

export default function FieldsSetting(props) {

    const [selectedTab, setSelectedTab] = useState("modulefields");
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedTab(params.module);
    }, []);

    const methods = useForm(
        {
            defaultValues: props.defaultValues ? props.defaultValues : editFieldsData,
            shouldUnregister: false,
            resolver: yupResolver(validationSchema[2]),
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, getValues, watch } = methods;

    return (
        <>
            <Grid container direction="row">
                <CrmSettings selectedMenu="fields" selectedTab={selectedTab} />
                <Grid item xs={10}>
                    <Grid container direction="column" sx={{ justifyContent: "center", height: "46px" }}>
                        <Grid item width="40%">
                            <Tabs value={selectedTab} onChange={(e, val) => { setSelectedTab(val); navigate(`/crm/settings/fields/${val}`) }}>
                                <StyledTab label="Module Fields" value="modulefields" />
                                <StyledTab label="Pipeline Fields" value="pipelinefields" />
                            </Tabs>
                        </Grid>
                        <Grid item width="60%" sx={{ pr: 1 }}>
                            {/* header right section */}
                        </Grid>
                    </Grid >
                    <Grid container direction="column" height="calc(100vh - 92px)"
                        sx={{ backgroundColor: `${variables.tabsBackgroundColor}`, p: 2, pb: 0, pr: 2, overflowX: 'auto', overflowY: 'hidden' }}>
                        <Grid container direction="row" height="inherit" sx={{ flexFlow: 'row' }}>
                            {
                                <>
                                    {selectedTab === "modulefields" &&

                                        moduleFields.map((module, i) => {
                                            return <Grid item key={i}>
                                                <Stack direction="column" sx={{ border: `1px solid ${variables.lightBorderColor}`, backgroundColor: `${variables.tabsBackgroundColor}` }}>
                                                    <FormProvider {...methods}>
                                                        <form>
                                                            <FieldsConfiguration module={module} />
                                                        </form>
                                                    </FormProvider>
                                                </Stack>
                                            </Grid>
                                        })

                                    }

                                    {selectedTab === "pipelinefields" &&
                                        pipelineFields.map(module => {
                                            return <Grid item>
                                                <Stack direction="column" sx={{ border: `1px solid ${variables.lightBorderColor}`, backgroundColor: `${variables.tabsBackgroundColor}` }}>
                                                    <FormProvider {...methods}>
                                                        <form>
                                                            <FieldsConfiguration module={module} type="pipeline" />
                                                        </form>
                                                    </FormProvider>
                                                </Stack>
                                            </Grid>
                                        })
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}