import { useState } from "react";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Avatar, Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import variables from "../../../../assets/styles/_colors.scss";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { stringAvatar } from "../../../../utils/PipelineConstants";
import { withStyles } from "@material-ui/core/styles";
import EditUser from "./EditUser";
import ActivateUser from "./ActivateUser";
import DeactivateUser from "./DeactivateUser";
import DeleteUser from "./DeleteUser";
import { getUserById } from "../../../../webservices/UserControlService";
import CustomSnackbar from "../../../../components/CustomSnackbar";

const StyledIcon = withStyles({
    root: {
        backgroundColor: `${variables.listBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.listBackgroundColor} !important`
        }
    }
})(IconButton);

const ViewUserDetails = (props) => {

    const [userId, setUserId] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const [address, setAddress] = useState({});

    const [selectedUser, setSelectedUser] = useState(null);
    const [editUser, showEditUser] = useState(false);
    const [activateUser, showActivateUser] = useState(false);
    const [deactivateUser, showDeactivateUser] = useState(false);
    const [deleteUser, showDeleteUser] = useState(false);
    const [params, setParams] = useState({
        module: null,
        type: null
    });
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    useEffect(() => {
        setParams({
            module: searchParams.get('module'),
            type: searchParams.get('type')
        })
        setUserId(searchParams.get('id'));
    }, []);

    useEffect(() => {
        if (props.item) {
            setSelectedUser(props.item);
        } else {
            userId && getUserInfo();
        }

    }, [userId]);

    const getUserInfo = () => {
        getUserById(userId).then(res => {
            setSelectedUser(res);
        });
    }

    const getAddress = () => {
        const data = selectedUser;
        let obj = "";
        if (data) {
            obj += data.street ? data.street : "";
            obj += data.city ? obj === "" ? `${data.city}` : `, ${data.city}` : "";
            obj += data.state ? obj === "" ? `${data.state}` : `, ${data.state}` : "";
            obj += data.country ? obj === "" ? `${data.country}` : `, ${data.country}` : "";
            obj += data.zip ? obj === "" ? `${data.zip}` : `, ${data.zip}` : "";
        }

        return {
            val: obj,
            isEmpty: obj === "" && true
        };
    }

    useEffect(() => {
        setAddress(getAddress());
    }, [selectedUser]);

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        });
        getUserInfo();
    }

    return (
        <>
            <Box>
                <Grid container direction="row" sx={{ width: 'auto' }}>
                    <Grid item xs={12} sx={{ backgroundColor: `${variables.lightGreyBackgroundColor}`, borderBottom: `1px solid ${variables.borderColor}` }}>
                        <Grid container direction="row" sx={{ p: 2, pb: 0, pl: 1 }} alignItems="start">
                            <Grid item xs={0.25}>
                                <Link to={`/crm/settings/usercontrols/users?type=${params.type}`}>
                                    <IconButton>
                                        <KeyboardBackspaceIcon />
                                    </IconButton>
                                </Link>
                            </Grid>
                            <Grid item xs={5} sx={{ pl: 2, pb: 1.5 }}>
                                <Stack direction="row" spacing={2} alignItems="start">
                                    <Stack direction="column">
                                        {selectedUser && <Avatar {...stringAvatar(`${selectedUser.firstName} ${selectedUser.lastName}`)} />}
                                    </Stack>
                                    <Stack direction="column">
                                        <Stack direction="row" sx={{ fontWeight: 'bold' }}>
                                            {`${selectedUser?.firstName} ${selectedUser?.lastName}`}
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <Typography sx={{ color: `${variables.greyTextColor}` }}>{selectedUser?.role?.roleName} at {selectedUser?.companyName} </Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <EmailOutlinedIcon />
                                            <Typography component="a" sx={{ color: `${variables.linkColor}` }} target="_blank" rel="noreferrer" href={`mailto:${selectedUser?.email}`}>
                                                {selectedUser?.email}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6.75} sx={{}}>
                                <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                                    {selectedUser?.status === "INVITED" &&
                                        <Button variant="outlined" color="primary" onClick={() => showActivateUser(true)}>
                                            Reinvite
                                        </Button>}
                                    {selectedUser?.status === "INACTIVE" &&
                                        <Button variant="contained" color="primary" onClick={() => showActivateUser(true)}>
                                            Activate
                                        </Button>
                                    }
                                    {selectedUser?.status === "ACTIVE" &&
                                        <Button variant="outlined" color="error" onClick={() => showDeactivateUser(true)}>
                                            Deactivate
                                        </Button>
                                    }
                                    {selectedUser?.status !== "DELETED" &&
                                        <>
                                            <StyledIcon onClick={() => showEditUser(true)}>
                                                <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                                            </StyledIcon>
                                            <StyledIcon onClick={() => showDeleteUser(true)}>
                                                <DeleteOutlineIcon fontSize='medium' color="error" />
                                            </StyledIcon>
                                        </>
                                    }
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" sx={{ p: 0, pl: 1.5, pt: 1.5, overflowY: 'auto', overflowX: 'hidden' }}>
                        <Box width="100%" sx={{ p: 1 }}>
                            <Typography sx={{ fontWeight: 'bold', pl: 1, pb: 1 }}>User Information</Typography>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Profile </Typography>
                                <Typography>{selectedUser?.profile?.profileName}</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Mobile </Typography>
                                <Typography>{selectedUser?.mobile ? selectedUser?.mobile : "--"}</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Phone </Typography>
                                <Typography>{selectedUser?.phone ? selectedUser?.phone : "--"}</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Website </Typography>
                                <Typography>{selectedUser?.website ? selectedUser?.website : "--"}</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Fax </Typography>
                                <Typography>{selectedUser?.fax ? selectedUser?.fax : "--"}</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Date Of Birth </Typography>
                                <Typography>{selectedUser?.dateOfBirth ? selectedUser?.dateOfBirth : "--"}</Typography>
                            </Stack>
                        </Box>

                        <Box width="100%" sx={{ p: 1, pl: 1, pb: 2 }}>
                            <Typography sx={{ fontWeight: 'bold', pl: 1, pb: 0.5 }}>Address</Typography>
                            <Typography sx={{ pl: 1, pt: 1 }}> {address?.isEmpty ? "--" : address?.val} </Typography>
                        </Box>

                        <Box width="100%" sx={{ p: 1 }}>
                            <Typography sx={{ fontWeight: 'bold', pl: 1, pb: 1 }}>Locale Information</Typography>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Country Locale </Typography>
                                <Typography>{selectedUser?.countryLocale?.name}</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Time Format </Typography>
                                <Typography>{selectedUser?.timeFormat} Hours</Typography>
                            </Stack>
                            <Stack direction="row" width="100%" sx={{ p: 1 }}>
                                <Typography width="15%"> Time Zone </Typography>
                                <Typography>{selectedUser?.timezone?.label}</Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

            {
                editUser && <EditUser item={selectedUser} handleDrawerClose={(data) => { showEditUser(false); showSnackbar(data); }} />
            }

            {
                activateUser && <ActivateUser item={[{ original: selectedUser }]} handleClose={(data) => { showActivateUser(false); showSnackbar(data); }} />
            }

            {
                deactivateUser && <DeactivateUser item={selectedUser} handleClose={(data) => { showDeactivateUser(false); showSnackbar(data); }} />
            }

            {
                deleteUser && <DeleteUser item={selectedUser} handleClose={(data) => { showDeleteUser(false); showSnackbar(data); }} />
            }
        </>
    );
}

export default ViewUserDetails;