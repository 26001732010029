import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deactivateUser } from '../../../../webservices/UserControlService';

export default function DeactivateUser(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(true);

    const { mutateAsync } = useMutation({
        mutationKey: "deactivateUser",
        mutationFn: (id) => deactivateUser(id),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
            queryClient.invalidateQueries({ queryKey: ['getUsersCount'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = (data) => {
        setOpen(false);
        props.handleClose(data);
    }
    const handleDeactivation = () => {
        mutateAsync(props.item?._id);
    }

    return (
        <Modal open={open} >
            <>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>{props.count > 1 ? "Deactivate Users" : "Deactivate User"}
                        <CloseButton handleDialogClose={() => handleClose()} />
                    </Typography>

                    {props.isBulkAction ?
                        <Typography component="div" variant="body" sx={{ pt: 2, pb: 2 }}>
                            {`If you deactivate these users, they'll no longer be able to access this organization. However, the deactivated user's records will still remain in this organization.`}
                        </Typography> :
                        <Typography component="div" variant="body" sx={{ pt: 2, pb: 2 }}>
                            {`If you deactivate the user, ${props.item?.email} they'll no longer be able to access this organization. However, the deactivated user's records will still remain in this organization.`}
                        </Typography>
                    }

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                        <Button variant="contained" color="error" onClick={handleDeactivation}>Deactivate User</Button>
                    </Stack>
                </Box>
            </>
        </Modal>
    );
}