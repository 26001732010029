import FullCalendar from "@fullcalendar/react";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from "@mui/material";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import moment from "moment";

export default function ActivitiesRightPane(props) {

    const sideCalendarComponentRef = useRef();

    useEffect(() => {
        if (props.selectedDateFromMain) {
            queueMicrotask(() => {
                const calendarApi = sideCalendarComponentRef?.current?.getApi();
                calendarApi?.changeView("dayGridMonth");
                calendarApi?.gotoDate(props.selectedDateFromMain);
            });
        }
    }, [props.selectedDateFromMain]);

    const dayCellContent = (arg) => {
        const isSelectedDate = moment(arg.date).isSame(moment(props.selectedDateFromMain));
        const style = {
            background: 'green',
            width: '22px',
            height: '21px',
            borderRadius: '50%',
            textAlign: 'center',
            color: '#fff'
        }
        return (
            <>
                <Typography sx={isSelectedDate ? style : { color: '#212129' }}>{arg.dayNumberText}</Typography>
            </>
        );
    };

    const infoText = "At least 1 activity type should be selected";
    const [activityTypes, setActivityTypes] = useState({
        tasks: true,
        events: true,
        calls: true,
    });

    const handleChange = (event) => {
        setActivityTypes({
            ...activityTypes,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSelect = (param) => {
        props.handleDateSelection(param.startStr);
    }

    const { tasks, events, calls } = activityTypes;
    const error = [tasks, events, calls].filter((v) => v).length === 1;

    return (
        <>
            <FullCalendar
                ref={sideCalendarComponentRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: '',
                    center: 'title',
                    right: 'prev,next',
                }}
                initialView='dayGridMonth'
                editable={true}
                selectable={true}
                selectMirror={true}
                select={handleSelect}
                dayMaxEvents={true}
                dayCellContent={dayCellContent}
            />
            <div>
                <Stack sx={{ pt: 2 }}>
                    <Typography style={{ fontWeight: 600 }}>Activity Types</Typography>{" "}
                    <FormControl
                        required
                        error={error}
                        sx={{ pl: 1.25 }}
                        component="fieldset"
                        variant="standard"
                    >
                        <Tooltip title={tasks && error ? infoText : ""}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={tasks} disabled={tasks && error} onChange={handleChange} name="tasks"
                                        sx={{ '&.Mui-checked': { color: "#FFA534" }, '&.Mui-disabled': { opacity: 0.4 } }} />
                                }
                                label="Tasks"
                            />
                        </Tooltip>
                        <Tooltip title={events && error ? infoText : ""}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={events}
                                        sx={{ '&.Mui-checked': { color: "#1976d2" }, '&.Mui-disabled': { opacity: 0.4 } }}
                                        disabled={events && error} onChange={handleChange} name="events" />
                                }
                                label="Events"
                            />
                        </Tooltip>
                        <Tooltip title={calls && error ? infoText : ""}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={calls} sx={{ '&.Mui-checked': { color: "#9c27b0" }, '&.Mui-disabled': { opacity: 0.4 } }} disabled={calls && error} onChange={handleChange} name="calls" />
                                }
                                label="Calls"
                            />
                        </Tooltip>
                    </FormControl>
                </Stack>
                <Stack sx={{ pt: 1 }}>
                    <Typography style={{ fontWeight: 600 }}>
                        Ownership
                    </Typography>{" "}
                    <FormControl sx={{ pl: 1.25 }}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="AllActivities"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                value="MyActivities"
                                control={<Radio />}
                                label="My Activities"
                            />
                            <FormControlLabel
                                value="AllActivities"
                                control={<Radio />}
                                label="All Activities"
                            />
                        </RadioGroup>
                    </FormControl>
                </Stack>
                <Stack sx={{ pt: 1, pb: 2 }}>
                    <Typography style={{ fontWeight: 600 }}>
                        Status
                    </Typography>{" "}
                    <FormControl sx={{ pl: 1.25 }}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="All"
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                value="OpenActivities"
                                control={<Radio />}
                                label="Open Activities"
                            />
                            <FormControlLabel
                                value="ClosedActivities"
                                control={<Radio />}
                                label="Closed Activities"
                            />
                            <FormControlLabel
                                value="All"
                                control={<Radio />}
                                label="All"
                            />
                        </RadioGroup>
                    </FormControl>
                </Stack>
            </div>
        </>
    )
}