import { Drawer } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";

const Fields = styled.div`
  width: 100%;
  display: flex;
`;
const Field = styled.div`
  width: 50%;
`;

const NameFields = () => {
  //   ================================ DRAWER =====================================

  const [openDrawer1, setopenDrawer1] = useState(false);

  const toggleDrawer1 = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer1(openDrawer1 ? false : true);
  };

  return (
    <>
    <div
      style={{ width: "100%", display: "flex", cursor: "pointer" }}
      onClick={toggleDrawer1}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <label>First Name</label>
        <input
          type="text"
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: 4,
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
      >
        <label>Last Name</label>
        <input
          type="text"
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: 4,
          }}
        />
      </div>
    </div>
    <React.Fragment>
        <Drawer anchor="right" open={openDrawer1} onClose={toggleDrawer1}>
          {" "}
          <div className="adddcontactdrawer" style={{width : '50vw'}}>
            <div className="addcompanytitle" style={{height : "50px"}}>
                Properties 
            </div>

          </div>
        </Drawer>
      </React.Fragment>
    </>
  );
};

export default NameFields;
