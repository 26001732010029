import React , {useState} from 'react'
import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { styled } from "@mui/material/styles";
import { attendanceReports , taskReports } from '../../Atoms/LoginAtoms';
import { useRecoilState } from "recoil";
import WallpaperOutlinedIcon from '@mui/icons-material/WallpaperOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import ImageSliderDialog from './ImageSliderDialog ';
import ReactAudioPlayer from 'react-audio-player';
import AudioSliderDialog from './AudioSliderDialog';
import NotesSliderDialog from './NotesSliderDialog';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0000003d",
      color: theme.palette.common.white,
      padding: 10
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  

 

  

export default function EmptaskReports() {
    const [getattendanceReport , setAttendanceReport] = useRecoilState(attendanceReports)
    const [getTaskReport , setTaskReport] = useRecoilState(taskReports)


    const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const [isDialogOpen1, setIsDialogOpen1] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState([]);

  const [isDialogOpen2, setIsDialogOpen2] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);


  const openDialog = (image_name) => {
    setSelectedImages(image_name)
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const openDialog1 = (audio_name) => {
    setSelectedAudio(audio_name)
    setIsDialogOpen1(true);
  };

  const closeDialog1 = () => {
    setIsDialogOpen1(false);
  };

  const showTxtFile = (file) => {
    fetch(`https://server.nowdigitaleasy.com:3505/uploads/task/${file}`)
    .then(response => response.text())
    .then(data => setSelectedFile(data))
    .catch(error => console.error(error));
    
  }

    return (
    <div><div className="reporttop">
    <Typography>Employee Task Details</Typography>
    <Typography 
   // onClick={()=> setopenDrawer(true)} 
    style={{cursor : "pointer"}}><FilterAltOutlinedIcon /></Typography>       
</div>
<div className="reporttable">
    <TableContainer sx={{ maxHeight : 500 }} component={Paper}>
      <Table sx={{ minWidth: 700 , maxHeight : 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Task Category</StyledTableCell>
            <StyledTableCell align="center">Task Name</StyledTableCell>
            <StyledTableCell align="center" style={{color : "black"}}>Description</StyledTableCell>
            <StyledTableCell align="center">Estimate Task time</StyledTableCell>
            <StyledTableCell align="center">Actual Start time</StyledTableCell>
            <StyledTableCell align="center">Actual End time</StyledTableCell>
            <StyledTableCell align="center">Place To Visit</StyledTableCell>
            <StyledTableCell align="center" style={{color : "black"}}>Location</StyledTableCell>
            <StyledTableCell align="center">Travel Distance</StyledTableCell>
            <StyledTableCell align="center">Travel Duration</StyledTableCell>
            <StyledTableCell align="center">Pause Duration</StyledTableCell>
            <StyledTableCell align="center">Task Status</StyledTableCell>
            <StyledTableCell align="center" style={{color : "black"}}>Image</StyledTableCell>
            <StyledTableCell align="center" style={{color : "black"}}>Audio</StyledTableCell>
            <StyledTableCell align="center" style={{color : "black"}}>Notes</StyledTableCell>
            <StyledTableCell align="center">Task Form</StyledTableCell>
            <StyledTableCell align="center">Verified Datetime</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getTaskReport.map((row) => (
            
            <StyledTableRow 
            //key={row.name} 
            //onClick={() => attendanceReport()} 
            style={{cursor : "pointer"}}>
               
              <StyledTableCell component="th" scope="row">{row.task_category}</StyledTableCell>
              <StyledTableCell component="th" scope="row">{row.task_name}</StyledTableCell>
              <StyledTableCell align="center">{row.task_desc}</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">{row.start_time}</StyledTableCell>
              <StyledTableCell align="center">{row.end_time}</StyledTableCell>
              <StyledTableCell align="center">{row.start_location_name}</StyledTableCell>
              <StyledTableCell align="center">{row.start_location_name}</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">{row.task_duration}</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">{row.task_status}</StyledTableCell>
              <StyledTableCell align="center" onClick={() =>openDialog(row.image_name)} >{row.image_name.length > 0 ? <WallpaperOutlinedIcon /> : "--"}</StyledTableCell>
              <ImageSliderDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        images={selectedImages} //`https://server.nowdigitaleasy.com:3505/uploads/task/${row.audio_name[0]}`
      />
              <StyledTableCell align="center" onClick={() =>openDialog1(row.audio_name)}>{row.audio_name.length > 0  ? <VolumeUpOutlinedIcon />
: "--"}</StyledTableCell>
              <AudioSliderDialog
        isOpen={isDialogOpen1}
        onClose={closeDialog1}
        Audio={selectedAudio} //`https://server.nowdigitaleasy.com:3505/uploads/task/${row.audio_name[0]}`
      />
              <StyledTableCell align="center" onClick={() =>showTxtFile(row.textfile_name)}>{row.textfile_name.length > 0 ? 
          <NoteAltOutlinedIcon />
           : "--"  }</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">{row.end_time}</StyledTableCell>
            </StyledTableRow>
          ))} 
          {/* <StyledTableRow 
          //key={row.name} 
            //onClick={() => attendanceReport()} 
            style={{cursor : "pointer"}}>
               
              
              
              <StyledTableCell component="th" scope="row">--</StyledTableCell>
              <StyledTableCell component="th" scope="row">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
            </StyledTableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
    </div></div>
  )
}
