import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import MapVisitors from "../ChatSidebar/MapVisitors";
// import VisitorContact from '../ChatSidebar/VisitorContact'
// import ChatAnalysis from '../ChatSidebar/ChatAnalysis'
// import MainChat from "../MainChat";
import VisitorRoute from "../chatnow/visitors/VisitorRoute";
import ContactRoute from "../chatnow/contacts/ContactRoute";
import Analytics from "../chatnow/analytics/Analytics";
import InboxHome from "../chatnow/inbox";
const ChatRouter = () => {
  return (
    <Routes>
      <Route path="inbox/*" element={<InboxHome />} />
      <Route path="visitors/*" element={<VisitorRoute />} />
      <Route path="contacts/*" element={<ContactRoute />} />
      <Route path="analytics/*" element={<Analytics />} />
      {/*  <Route path='inbox/:websiteId/*' element={<MainChat />} />
      <Route path='mapviewers' element={<MapVisitors />} />
      <Route path="contacts" element={<VisitorContact />} />
      <Route path="analytics" element={<ChatAnalysis />} />  */}
      <Route path="*" element={<Navigate to="inbox/:websiteId" />} />
    </Routes>
  );
};

export default ChatRouter;
