import React , {useState , useEffect} from "react";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../redux/actions/home-action-creator";
import "./Report.css"
import { Typography } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import {

  Drawer,

} from "@mui/material";
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { api_url } from "../../globalSettings";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import { login_atoms , login_token , attendanceReports , empExpDetails} from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import SearchInput, { createFilter } from 'react-search-input';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#0000003d",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));





const time = [
  { id: 1, day: "TODAY" },
  { id: 2, day: "YESTERDAY" },
  { id: 3, day: "THIS WEEK" },
  { id: 4, day: "LAST WEEK" },
  { id: 5, day: "THIS MONTH" },
  { id: 6, day: "LAST MONTH" },
  // { id: 7, day: "Sunday" },
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    // borderRadius: 20,
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));


const KEYS_TO_FILTERS = ['first_name', 'last_name'];


export default function EmpExpense() {
  // const dispatch = useDispatch();
  const closeBtnOnClick = () => {
    dispatch(setCurrentPage("TRACK_EYE"));
  };


 //=========================== Drawer1 ========================


  const handleChange = (event) => {
    setCompany_idd(event.target.value);
 
  };


  const handleChange1 = (event) => {
    setBranchId(event.target.value);

  };

  const handleChange3 = (event) => {
    setDepartmentId(event.target.value);
  };
   
   

  // =========================  TIME API =============================
const [dateId , setDateId] = useState('')

  const handleChange2 = (event) => {
    setDateId(event.target.value);
  };
  

  //=========================== Drawer1 ========================

  const [openDrawer, setopenDrawer] = useState(true);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };


  // ================================== company list =========================
  const [Getlist, setGetlist] = useState([]);
  const [branchList , setBranchlist] = useState([]);
  const [departmentList , setDepartmentlist] = useState([]);
  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [company_idd, setCompany_idd] = useState('');
  const [branchId, setBranchId] = useState('');
  const [ department_id ,setDepartmentId] = useState()


  function getCompanyList() {
    const params = {
      user_id: loginAtom1,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getCompanyList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.company_name,
            id: item._id,
          }))
        );

        setCompany_idd(responce.data.data[0]._id);
      })
      .catch((err) => {

      });
  }

   // ===================  GET BRANCH LIST ==================

  function getDepartmentList() {
    const params = {
      token: loginToken1,
      branch_id: branchId,
    };

    axios
      .get(`${api_url}/getDepartmentList`, { params: { ...params } })
      .then((responce) => {
        setDepartmentlist(
          responce.data.data.map((item, key) => ({
            label: item.department_name,
            id: item._id,
          }))
          );
        setDepartmentId(responce.data.data[0]._id);
      })
      .catch((err) => {

      });
  }


  // ===================  GET BRANCH LIST ==================

  function getBranchList() {
    const params = {
      company_id: company_idd,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setBranchlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
        setBranchId(responce.data.data[0]._id);
      })
      .catch((err) => {

      });
  }

 //================================  EMPLOYEE LIST =======================
  
 const [employeList , setGetEmployeelist ] = useState([])

 function getEmployeeList() {
  const params = {
    branch_id: branchId,
    token: loginToken1,
  };

  axios
    .get(`${api_url}/getEmployeeList`, { params: { ...params } })
    .then((responce) => {
      setGetEmployeelist(responce.data.data);
    })
    .catch((err) => {
    });
}


 useEffect(() => {
  getEmployeeList();
}, [branchId]);


  useEffect(() => {
    getCompanyList();
    setDateId(time[0].id)
    setAttendanceReport("");
  }, []);

  useEffect(() => {
    getBranchList();
  }, [company_idd]);

  useEffect(() => {
    getDepartmentList();
  }, [branchId]);


  


  const [start , setStart] = useState('');
  const [end , setEnd] = useState('');

  useEffect (() => {
    if(dateId == 1){
     var start = new Date();
     start.setHours(0,0,0,0);
     setStart(start.toISOString())
     var end = new Date();
     end.setHours(23,59,59,999);
     
     setEnd(end.toISOString())

    }
 
    else if(dateId == 2){
   
     const start = new Date();

     start.setHours(0,0,0,0);
 
     setStart(start.toISOString());
 
     const end = new Date();
     end.setDate(end.getDate() - 1);
     end.setHours(23,59,59,999);
     setEnd(end.toISOString());

    }
    else if(dateId == 3){
  
   var curr = new Date; 
   var first = curr.getDate() - curr.getDay(); 
   var last = first + 6; 
   
   var firstDay = new Date(curr.setDate(first)).toISOString();
   var lastDay = new Date(curr.setDate(last)).toISOString();

 setStart(firstDay);
 setEnd(lastDay);
    }
    else if(dateId == 4){

    var beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
 var beforeOneWeek2 = new Date(beforeOneWeek);
 var  day0 = beforeOneWeek.getDay()
 var  diffToMonday = beforeOneWeek.getDate() - day0 + (day0 === 0 ? -6 : 1)
 var  lastMonday = new Date(beforeOneWeek.setDate(diffToMonday)).toISOString();
 var  lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6)).toISOString();
 
 setStart(lastMonday);
 setEnd(lastSunday);

    }
    else if(dateId == 5){

    var date = new Date();

     setStart(firstDay);
     setEnd(lastDay);
 
 
    }
    else if(dateId == 6){

   setStart(firstDay);
   setEnd(lastDay);
 
    }
   },[dateId])


  const dispatch = useDispatch();
  const history = useNavigate()

  const attendanceReport = () => {

    history('/trackeye/empExpense/report')

  };

const [searchTerm, setSearchTerm] = useState('');

const searchUpdated = (term) => {
setSearchTerm(term);
};

  const filteredItems = employeList.filter(createFilter(searchTerm, KEYS_TO_FILTERS));


  // =================================== ATTENDENCE API ====================================

const [getattendanceReport , setAttendanceReport] = useRecoilState(attendanceReports)



const [expDetails , setExpDetails] = useRecoilState(empExpDetails)

const [expense , setExpense ] = useState([]);

  //  ============================ SELECT EMPLOYEE =============================

  const [selectAll, setSelectAll] = useState(false);

  const [selectOptions, setSelectOptions] = useState([]);

  
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (value === "selectAll") {
      setSelectAll(checked);
      if (checked) {
        const result = filteredItems.map((word) => word._id);

        setSelectOptions(result);
      } else {
        setSelectOptions([]);
      }
    } else {
      if (checked) {
        setSelectOptions((prevOptions) => [...prevOptions, value]);
      } else {
        setSelectOptions((prevOptions) =>
          prevOptions.filter((option) => option !== value)
        );
        setSelectAll(false);
      }
    }
  };
  


  function attendance(){
    const params = JSON.stringify({
      employee_id:selectOptions,
      company_id:company_idd,
      branch_id:branchId,
      user_id:loginAtom1,
      department_id:department_id,
      startDate:start,
      endDate:end,

    });
    axios
      .post(`${api_url}/employee/expenseDashboard`,params, 
      {
        headers: {
          "content-type": "application/json",
        },
      }
      )
      .then(function (response) {
        setExpense(response.data.data);
        setopenDrawer(false)
      })

      .catch(function (error) {
        if (error.response) {
        
          alert("ERROR")
        } else if (error.request) {
         

        } else {
   
        }

      });
  }


  const  expenseDetails = (id) => {
    const params = JSON.stringify({
      employee_id:id,
      company_id:company_idd,
      branch_id:branchId,
      user_id:loginAtom1,
      department_id:department_id,
      startDate:start,
      endDate:end,
    });
    axios
      .post(`${api_url}/employee/expense`,params, 
      {
        headers: {
          "content-type": "application/json",
        },
      }
      )
      .then(function (response) {
  
        setExpDetails(response.data.data);
        attendanceReport();

      })

      .catch(function (error) {
        if (error.response) {

          alert("ERROR")
        } else if (error.request) {

        } else {

        }

      });
  }





  return (
    <>
      {/* <div className="my-account-close-btn" onClick={closeBtnOnClick}>
        <CloseIcon />
      </div> */}
    <div className="reporttop">
          <Typography>EXPENSE</Typography>
          <Typography onClick={()=> setopenDrawer(true)} style={{cursor : "pointer"}}><FilterAltOutlinedIcon /></Typography>       
    </div>
    <div className="reporttable">
    <TableContainer sx={{ maxHeight : 500 }} component={Paper}>
      <Table sx={{ minWidth: 700 , maxHeight : 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Company</StyledTableCell>
            <StyledTableCell>Branch</StyledTableCell>
            <StyledTableCell align="center">Department</StyledTableCell>
            <StyledTableCell align="center">Employee</StyledTableCell>
            <StyledTableCell align="center">Fix Expense</StyledTableCell>
            <StyledTableCell align="center">Variable Expense</StyledTableCell>
            <StyledTableCell align="center">Total Expense</StyledTableCell>
          </TableRow>
        </TableHead>
        {expense.length > 0 ? expense.map((row) => (
        <TableBody>
            {/* <StyledTableRow  onClick={() => attendanceReport()} style={{cursor : "pointer"}}>
              <StyledTableCell component="th" scope="row">{attendancedashboardReport.departmentName}</StyledTableCell>
              <StyledTableCell component="th" scope="row">{attendancedashboardReport.employeeName}</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">{attendancedashboardReport.totalDays}</StyledTableCell>
              <StyledTableCell align="center">{attendancedashboardReport.present}</StyledTableCell>
              <StyledTableCell align="center">{attendancedashboardReport.absent}</StyledTableCell>
              <StyledTableCell align="center">{attendancedashboardReport.leave}</StyledTableCell>
              <StyledTableCell align="center">{attendancedashboardReport.holiday}</StyledTableCell>
              <StyledTableCell align="center">{attendancedashboardReport.weeklyoff}</StyledTableCell>
            </StyledTableRow> */}
            <StyledTableRow  onClick={() => expenseDetails(row.employeeDetails._id)} style={{cursor : "pointer"}}>
              <StyledTableCell component="th" scope="row">{row.companyDetails.company_name}</StyledTableCell>
              <StyledTableCell component="th" scope="row">{row.branchDetails.branch_name}</StyledTableCell>
              <StyledTableCell align="center">{row.departmentDetails.department_name}</StyledTableCell>
              <StyledTableCell align="center">{row.employeeDetails.first_name}</StyledTableCell>
              <StyledTableCell align="center">{row.fixedExpense ? row.fixedExpense : "--"}</StyledTableCell>
              <StyledTableCell align="center">{row.variableExpense ? row.variableExpense : "--"}</StyledTableCell>
              <StyledTableCell align="center">{row.totalExpense}</StyledTableCell>
            </StyledTableRow>
          </TableBody> 
            )) :  <StyledTableCell>NO DATA FOUND</StyledTableCell>} 
      </Table>
    </TableContainer>
    </div>
    <Drawer  anchor="right" open={openDrawer} onClose={toggleDrawer}
    // toggleDrawer={toggleDrawer}
    >
      <div id="drawer" style={{width :"500px "}}> 
      <Grid container spacing={2}>
        <Grid item xs={6} display = {"flex"} justifyContent ={"flex-start"} alignItems={"flex-start"} flexDirection ={"column"}>
        <InputLabel htmlFor="demo-customized-select-native" style={{fontSize : "12px" , margin  :"8px 0 0 8px"}}>
                  Company :
                </InputLabel>
        <FormControl 
             sx={{ m: 1 }}
            variant="standard"
            style={{ width: "95%" }}
            size="small"
          >
            
            <NativeSelect
              id="demo-customized-select-native"
              defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
              onChange={handleChange}
              input={<BootstrapInput />}
            >

              {Getlist.map((item) => {
                return (
                  <>
                    <option value={item.id}>{item.label}</option>
 
                  </>
                );
              })}
            </NativeSelect>
          </FormControl>
          <InputLabel htmlFor="demo-customized-select-native" style={{fontSize : "12px" , margin :"8px 0 0 8px"}}>
                  Branch :
                </InputLabel>
          <FormControl
             sx={{ m: 1 }}
            variant="standard"
            style={{ width: "95%" }}
            size="small"
          >
            
            <NativeSelect
              id="demo-customized-select-native"
              defaultValue={branchList.length >= 1 ? branchList[0].id : ""}
              onChange={handleChange1}
              input={<BootstrapInput />}
            >
              {branchList.map((item) => {
                return (
                  <>
                   <option value={item.id}>{item.label}</option>
                  </>
                );
              })}
            </NativeSelect>
          </FormControl>
          <InputLabel htmlFor="demo-customized-select-native" style={{fontSize : "12px" , margin :"8px 0 0 8px"}}>
                  Department :
                </InputLabel>
          <FormControl
             sx={{ m: 1 }}
            variant="standard"
            style={{ width: "95%" }}
            size="small"
          >
           
            <NativeSelect
              id="demo-customized-select-native"
              defaultValue={departmentList.length >= 1 ? departmentList[0].id : ""}
              onChange={handleChange3}
              input={<BootstrapInput />}
            >

              {departmentList.map((item) => {
                return (
                  <>
                   <option value={item.id}>{item.label}</option>

                  </>
                );
              })}
            </NativeSelect>
          </FormControl>
 
        </Grid>
        <Grid item xs={6} display = {"flex"} justifyContent ={"flex-start"} alignItems={"flex-start"} flexDirection ={"column"}>
        <FormControl
            sx={{ m: 1 }}
            variant="standard"
            style={{ width: "95%" , marginLeft : "0"}}
            size="small"
          >
            <InputLabel htmlFor="demo-customized-select-native">
                  Date Selection :
                </InputLabel>
            <NativeSelect
              id="demo-customized-select-native"
              defaultValue={time[0].id}
              onChange={handleChange2}
              input={<BootstrapInput />}
            >

              {time.map((item) => {
                return (
                  <>
                    <option value={item.id}>{item.day}</option>

                  </>
                );
              })}
            </NativeSelect>
          </FormControl>
          
          <InputLabel htmlFor="demo-customized-select-native" style={{fontSize : "12px" , marginTop : "8px" , marginBottom : "8px"}}>
                  Employe List :
          </InputLabel>
          <SearchInput className="search-input" onChange={searchUpdated} />
          <div className="empList">
          <div className="eachemp">
              <label>
        <input
          type="checkbox"
          value="selectAll"
          checked={selectAll}
          onChange={handleCheckboxChange}
        /> {" "}
        Select All
      </label>
      </div>
          {filteredItems.map((item) => {
                  return (
                    <div className="eachemp">
                      {/* <Typography variant="body2" style={{fontSize : "12px" , cursor : "pointer"}} onClick ={(e) => {setEmployeId(item._id);setEnableQuotations(e.target.checked) }}>  
                <Checkbox
                // /type="checkbox"
                //onChange={(e) => setEnableQuotations(e.target.checked)}
                style={{marginRight : "10px"}} 
              />{item.first_name}</Typography> */}
                      {/* <input style={{cursor : "pointer"}} type="checkbox" disabled={false} onClick={(e) => {setEmployeId(item._id)}}   name="ListOfDepartments" value={item._id}/>
                <span style={{marginLeft : 10, fontSize : 13}}>{item.first_name} {item.last_name}</span> */}

                      <label style={{ marginLeft: "20px" }}>
                        <input
                          type="checkbox"
                          value={item._id}
                          checked={selectOptions.includes(item._id)}
                          onChange={handleCheckboxChange}
                        />{" "}
                        {item.first_name} {item.last_name}
                      </label>
                    </div>
                  );
                })}
          </div>
          <div className="buttons"> 
                <Button className="button1"
                onClick={() => {attendance();}}
                >
                  apply</Button>
          </div>
        </Grid>
        </Grid>
      </div>
     
    </Drawer>
    </>
  );
}
