import React, { useEffect, useState } from "react";
import { Drawer,  Button, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import "./AddContactDrawer.css";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@mui/material/Grid";
import NativeSelect from "@mui/material/NativeSelect";

import FormControl from "@mui/material/FormControl";
import { api_url } from "../../globalSettings";
import axios from "axios";
import { profile_trigger, selectedEmp } from "../../Atoms/LoginAtoms";
import InputBase from "@mui/material/InputBase";
import { useRecoilState } from "recoil";
import InputLabel from "@mui/material/InputLabel";
import "react-country-dropdown/dist/index.css";

import styled from "styled-components";
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


const CssTextField = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#000",
  },

  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#00000033",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#00000033",
      padding: "9.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009DFE",
    },
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "1px solid #ced4da",
    borderRadius:"5px",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
 
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const AdddProjectdrawer = styled.div`
  width: 30vw;

  height: 100%;
`;

const AddSubTasks = (props) => {
    const [employee_id] = useRecoilState(selectedEmp);
    const [errors, setErrors] = useState({});
    const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
    const [priorityId , setPriorityId] = useState('');
    const [prioritylist , setPrioritylist] = useState([]);
    const [companyform, setCompanyForm] = useState({
        title: "",
        description: "",
        comment: "",
    });
    // const uploadref = useRef(null);

    const handleChange1 = (event) => {
        setPriorityId(event.target.value);
      };
  


    useEffect(() => {
        axios
           .get(`${api_url}/todo/getpriorityByid/${props.activeTab.activeTab}`)   //${activeTab}
          .then((responce) => {
            setPrioritylist(
                responce.data.data.map((item, key) => ({
                  label: item.name,
                  id: item._id,
                })))
            setPriorityId(responce.data.data[0]._id);
          })
          .catch((err) => {
          });
    },[props])
  
     //===================================== set valid time  ===========================
  
     const [validFrom, setValidFrom] = React.useState('');
     const [validTo, setValidTo] = React.useState('');
   
     function valid_from(event) {
       setValidFrom(event.target.value);
     }
   
     function valid_to(event) {
       setValidTo(event.target.value);
     }
  
  
    const handleChange = (e) => {
      const { name, value } = e.target;
    
      setCompanyForm((state) => ({ ...state, [name]: value }));
  

      const newErrors = { ...errors };
  
      switch (name) {
        case "title":
          newErrors.title = validator.isEmpty(value)
            ? "Please enter your Task Name."
            : "";
          break;
        default:
          break;
      }
  
      setErrors(newErrors);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
  
     
  
      if (validate()) {

     

        const formdata = new FormData();
        formdata.append("projectId", props.activeTab.activeTab);
        formdata.append("taskId", props.taskId);
        formdata.append("employee", "649ebbd22a57ea9dd87fe5a2");
        formdata.append("title", companyform.title);
        formdata.append("description", companyform.description);
        formdata.append("start_date", validFrom);
        formdata.append("due_date", validTo);
       formdata.append("priority", priorityId);
        formdata.append("comment", companyform.comment);
  
       
        const config = {
            headers: { "content-type": "multipart/form-data" },
          };
          axios({
            method: "post",
            url: `${api_url}/todo/addSubtask`,
            data: formdata,
            config,
          })
            .then(function (response) {
            
              setProfileTrigger(!profileTrigger);
            //setCompanyId1(response.data.data);
            //   setSnackbaropen1({
            //     open: true,
            //     message: "Company Added successfully",
            //     severity: "success",
            //     actionColor: "#edf7ed",
            //   });
            //   //getcompanydetails();
            //   setTrigerCompany(!trigerCompany);
            //   getCompanyList();
            //   setTrigerCompany(trigerCompany);
            })
            .catch(function (error) {
              if (error.response) {
               
              } else if (error.request) {
            
              } else {
                // Something happened in setting up the request that triggered an Error
              }
            });
      }
    };
  
    const validate = () => {
      let isValid = true;
      const newErrors = {};
  
      if (validator.isEmpty(companyform.title)) {
        isValid = false;
        newErrors.title = "Please enter your Task name.";
      }
  
    setErrors(newErrors);
      return isValid;
    };
  

  
  
  
    
    return (
      <div>
        {" "}
        <React.Fragment>
          <Drawer anchor="right" open={props.open} onClose={props.toggleDrawer}>
            <AdddProjectdrawer>
              <div className="addcompanytitle">
                ADD SUB TASK
                <IconButton
                  component="span"
                  onClick={props.toggleDrawer}
                  style={{ padding: 0, marginRight: 20 }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="information">
                <Grid container spacing={4} padding={5}>
                  <Grid item xs={12} alignItems="center" justifyContent=" center">
                    <TextField
                      size="small"
                      id="standard-basic"
                      variant="outlined"
                      label={"Task Name"}
                      name="title"
                      onChange={handleChange}
                      fullWidth
                      required
                    />
            {errors.title ? (
              <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
                <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
                {errors.title} 
              </div>
            ) : (
              ""
            )}
                  </Grid>
                  {/* <div className="informationtitle">Additional information</div> */}
                  <Grid item xs={12} sm={6} md={6}>
                    <CssTextField
                      style={{
                        height: "50px",
                        textTransform: "capitalize",
                      }}
                      id="date"
                      type="date"
                      value={validFrom}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={valid_from}
                      size="small"
                      fullWidth
                      label="Start Date*"
                      variant="outlined"
                    />
                  </Grid>
  
                  <Grid item xs={12} sm={6} md={6}>
                    <CssTextField
                      style={{
                        height: "50px",
                        textTransform: "capitalize",
                      }}
                      id="date"
                      type="date"
                      value={validTo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={valid_to}
                      size="small"
                      fullWidth
                      label="Due Date*"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <TextField
                      size="small"
                      id="outlined-multiline-static"
                      variant="outlined"
                      multiline
                      rows={3}
                      label={"Description"}
                      name="description"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} alignItems="center" justifyContent=" center">
                    <TextField
                      size="small"
                      id="outlined-multiline-static"
                      variant="outlined"
                      multiline
                      rows={3}
                      label={"Comment"}
                      name="comment"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} alignItems="center" justifyContent=" center">
                  <InputLabel
                htmlFor="demo-customized-select-native"
                style={{ fontSize: "12px", margin: "8px 0 0 8px" }}
              >
               Priority
              </InputLabel>
              <FormControl
                //sx={{ m: 1 }}
                variant="standard"
                style={{ width: "100%" }}
                size="small"
              >
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={prioritylist.length >= 1 ? prioritylist[0].id : ""}
                  onChange={handleChange1}
                  input={<BootstrapInput />}
                >
                  {prioritylist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>
                      </>
                    );
                  })}
                </NativeSelect>
              </FormControl>
                  </Grid>
                  <Grid item xs={12} alignItems="center" justifyContent=" center">

                  </Grid>
                </Grid>
              </div>
  
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "30px",
                }}
              >
                <Button
                  className="tablebutton"
                  onClick={handleSubmit}
                >
                  Add Task
                </Button>
              </div>
            </AdddProjectdrawer>
          </Drawer>
        </React.Fragment>
      </div>
    );
  };

export default AddSubTasks