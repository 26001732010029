
import React  from "react";
import { Route, Routes } from "react-router-dom";


import DomainDetails from "./Home";
import DomainList from "./Component/DomainList";
function Webservices() {

  document.body.style = "background-color : #f7f7f7;";


  return (
    <>
      <div
        className="webservices"
        style={{ display: "flex", height: "calc(100vh - 48px)" }}
      >
        {/* Left Section - Domain List */}
        <div
          style={{
            flex: "2 2 30%",
            position: "sticky",
            top: 0,
            height: "calc(100vh - 48px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <DomainList />
        </div>

        {/* Right Section - Domain Details */}
        <Routes>
          <Route path="/:DomainName/:uniqueId" element={<DomainDetails />} />
        </Routes>
      </div>
    </>
  );
}

export default Webservices;
