// importing atom from recoil
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

  const pdfAtom = atom({
    key: "pdfAtom",
    default: "",
    effects_UNSTABLE: [persistAtom],
  });

export {
    pdfAtom
}
  