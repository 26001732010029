import React, {  useState } from 'react';
import styled from 'styled-components';
import { FaCog } from 'react-icons/fa';

import UpdateProject from './UpdateProject';

const SidebarContainer = styled.div`
  width: 20%;
  background-color: white;
  color: black;
  font-family: "Poppins", sans-serif
`;

const SidebarTab = styled.div`
  cursor: pointer;
  padding: 10px 20px;
  color: black;
  border-right:2px solid white;
  display: flex;
  font-size:14px;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
  ${props => props.active && 'background-color: #f0f0f0;'}
 
`;

const SettingsIcon = styled(FaCog)`
  visibility: hidden;
  color: #0098e5;
`;

const HoverableSidebarTab = styled(SidebarTab)`
  &:hover {
    ${SettingsIcon} {
      visibility: visible;
    }
  }
`;





const Sidebar = ({ activeTab, setActiveTab , filteredItems}) => {

  const [selectedProject , setSelectedProject] = useState('');
     
  //   ================================ DRAWER =====================================

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {

    setopenDrawer(openDrawer ? false : true);
  };

    const handleIconClick = (project) => {
      
        setSelectedProject(project);
        toggleDrawer();
      };

  return (
    <SidebarContainer>
      {filteredItems.map((item) => (
      <HoverableSidebarTab key={item._id} onClick={() => setActiveTab(item._id)} active={activeTab === item._id}>
        {item.name}   <SettingsIcon onClick={()=>{handleIconClick(item)}} />
        </HoverableSidebarTab>
        ))}
         <UpdateProject
                open={openDrawer} 
                toggleDrawer={toggleDrawer}
                selectedProject={selectedProject}
         />
    </SidebarContainer>
  );
};

export default Sidebar;
