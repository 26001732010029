import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { CircularProgress, Typography } from "@mui/material";
import Styles from "../Panel/ChatPanel.module.scss";
import Logo from "../../../images/Groups.png";
import { GoLink } from "react-icons/go";
import Iaaxin from '../../../images/Iaaxin.mp4';

const VpanelContent = () => {
  const navigate = useNavigate();
  const [visitor, setVisitor] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchVisitorDetails = async () => {
      try {
        const response = await axios.get(
          `https://662630ce052332d55321f8bc.mockapi.io/chatnow/${id}`
        );
        setVisitor(response.data);
      } catch (error) {
        console.error("Error fetching visitor details:", error);
      }
    };

    fetchVisitorDetails();
  }, [id]);

  const handleClose = () => {
    navigate("/chat/visitors");
  };

  return (
    <>
      <div className={Styles.CNcontentmain}>
        <div className={Styles.CNcontentwrapper}>
          {visitor ? (
            <>
              <div className={Styles.CNimage}>
                <img src={Logo} alt="Logo" style={{ width: "50px" }} />
              </div>
              <div className={Styles.CNDetailwrapper}>
                <Typography className={Styles.CNName}>
                  {visitor.Visitors}
                </Typography>
                <div className={Styles.CNSpanwrapper}>
                  <span>05:14pm</span>
                  <span style={{ paddingLeft: "5px" }}>{visitor.location}</span>
                  <span style={{ paddingLeft: "5px" }}>.</span>
                  <span style={{ paddingLeft: "5px" }}>
                    Chrome , Windows , Desktop
                  </span>
                  <span style={{ paddingLeft: "5px" }}>.</span>
                  <a href="/#" className={Styles.CNPanelLink}>
                    {" "}
                    More
                  </a>
                </div>
              </div>
              <div style={{ marginLeft: "auto",display:"flex",alignItems:"center"}}>
                <CloseIcon onClick={handleClose} />
              </div>
            </>
          ) : (
            <div
              style={{
                position: "absolute",
                top: "12%",
                left: "70%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
      </div>
      <div className={Styles.CNLinktag}>
        <span>
          <GoLink />
          <a href="https://iaaxin.com/guna" className={Styles.CNPanelLink}>
            https://iaaxin.com/guna
          </a>
        </span>
      </div>
      <div className={Styles.CNVideotag}>
        <video width="100%" height="50%" controls>
          <source src={Iaaxin} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default VpanelContent;
