import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

const Webservicesstepp = atom({
  key: "Webservicesstepp",
  default: 0,
});
const selecteddomainn = atom({
  key: "selecteddomainn",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export { Webservicesstepp, selecteddomainn };
