import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { renameStage } from '../../../../webservices/PipelineService';

export default function RenameStage(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(true);
    const [stageName, setStageName] = React.useState("");
    const [radioValue, setRadioValue] = React.useState("all");

    const { mutateAsync } = useMutation({
        mutationKey: "renameStage",
        mutationFn: () => renameStage(props.stage?._id, {
            pipelineId: props.teamPipelineId,
            subPipelineId: props.subPipelineId,
            stageName: stageName,
            renameAll: radioValue === "all" ? true : false,
            isOpenStage: props.stage?.isOpenStage,
            createdBy: "User 1"
        }),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    }

    useEffect(() => {
        setStageName(props.stage?.stageName);
    }, [props.stage]);

    const onInputChange = (e) => {
        setStageName(e.target.value);
    }

    const handleChange = (event) => {
        setRadioValue((event.target).value);
    };

    const handleRename = () => {
        mutateAsync();
    }

    return (
        <Modal open={open}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                p: "16px 24px",
                width: '33%'
            }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>Rename Stage
                    <CloseButton handleDialogClose={handleClose} />
                </Typography>

                <Box sx={{ pt: 2 }}>
                    <TextField
                        label="Stage Name"
                        autoFocus
                        fullWidth
                        value={stageName}
                        onChange={(e) => onInputChange(e)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />

                    <FormControl sx={{ pt: 2 }} disabled={stageName == props.stage?.stageName}>
                        <RadioGroup
                            defaultValue="other"
                            value={radioValue}
                            onChange={handleChange}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel
                                value="all"
                                control={<Radio />}
                                label="Rename this stage to in other pipelines too."
                            />
                            <FormControlLabel
                                value="only"
                                control={<Radio />}
                                label="Only in this sub-pipeline."
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="primary" disabled={stageName === props.stage?.stageName}
                        onClick={handleRename}>Save</Button>
                </Stack>
            </Box>
        </Modal>
    );
}