import React from "react";
import RenewalStyle from "../Styles/RenewalConfirm.module.css";

const OfferMarkUp = () => {
  return (
    <div className={RenewalStyle.markUpWrapper}>
      <div>
        <span className={RenewalStyle.offerPercentage}>40%</span>{" "}
        <span className={RenewalStyle.offerText}>Offer</span>{" "}
        <span className={RenewalStyle.offerAcc}>
          {" "}
          for the first 20 Accounts
        </span>
      </div>
    </div>
  );
};

export default OfferMarkUp;
