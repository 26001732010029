import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import { api_url } from "../../globalSettings";
import {

  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  IconButton,
 
  Stack,
  TextField,
  Avatar,
 
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import "./Employeelistnew.css";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { useRecoilState } from "recoil";
import Autocomplete from "@mui/material/Autocomplete";
//=============delete ========================

import Slide from "@mui/material/Slide";
import PhoneInput from "react-phone-number-input";
// import 'react-phone-input-2/lib/material.css'
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";

//import recoil state
import {
  login_atoms,
  login_token,
  login_companyid,

} from "../../Atoms/LoginAtoms";
import EmployeDrawer from "./EmployeDrawer";

const CssTextField = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#009DFE",
  },

  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#009DFE",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#009DFE",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009DFE",
    },
  },
});


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    borderRadius:"5px",
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "9px 26px 9px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));


function CompanyListTable() {
  const [Getlist, setGetlist] = useState([]);
  const [GetBranchlist, setGetBranchlist] = useState([]);


  const [GetDepartmentlist, setGetDepartmentlist] = useState([]);
  const [Companydrawer, setCompanyDrawer] = useState(false);
  const [companyform, setCompanyForm] = useState({
    employee_company_id: "",
    first_name: "",
    last_name: "",
    email_id: "",
    nick_name: "",
   // mobile_no: "",
    address: "",
    joining_date: "",
  });


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "calc(100vh - 85px)",
    bgcolor: "white",
    boxShadow: 24,
    overflow : "hidden",
    overflowY:"scroll"
  };

  const [joiningDate, setJoiningdate] = React.useState('');
 

  function joining_date(event) {

    setJoiningdate(event.target.value);
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);

  const [Getemployeelist, setGetEmployeelist] = useState([]);
  const [companyid, setCompanyid] = useState("");

  const [departmentid, setDepartmentid] = useState("");
  


  const [GetShiftlist, setGetShiftlist] = useState([]);



  const [shiftid, setShiftid] = useState("");

  function shiftList(event, newValue) {
    if (newValue != null) {
      setShiftid(newValue.id);
    }
  }

  React.useEffect(() => {
    getBranchList();
    getEmployeeList();
  }, [companyid]);

  const [branchid, setBranchid] = React.useState("");



  const branchlist = (event, newValue) => {
    if (newValue != null) {
      setBranchid(newValue.id);
    }
  };

  React.useEffect(() => {
    getDepartmentList();
  }, [branchid]);

  const departmentlist = (event, newValue) => {
    if (newValue != null) {
      setDepartmentid(newValue.id);
    }
  };

  React.useEffect(() => {
    getDepartmentList();
  }, [branchid]);


  // ======================================= FILTER SEARCH =================================================
  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchInput !== "") {
      const filteredData = Getemployeelist.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(Getemployeelist);
    }
  };
 

  // ======================================= Api company list =================================================

  function getEmployeeList() {
    const params = {
      company_id: company_idd,
      token: loginToken1,
    };



    axios
      .get(`${api_url}/get_company_employeeList`, { params: { ...params } })
      .then((responce) => {
        setGetEmployeelist(responce.data.data);
 
      })
      .catch((err) => {
      
      });
  }

  useEffect(() => {
    getEmployeeList();
  }, [company_idd]);

  // ======================================= Api company list =================================================

  function getCompanyList() {
    const params = {
      user_id: loginAtom1,
      token: loginToken1,
    };



    axios
      .get(`${api_url}/getCompanyList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(responce.data.data);
  
      })
      .catch((err) => {
     
      });
  }

  useEffect(() => {
    getCompanyList();
  }, []);

  // ==================================== Shiftlist api call =======================================
 
  const [SelectedEmpDrawer, setSelectedEmpDrawer] = useState("");



  function getShiftList() {
    const params = {
      token: loginToken1,
      branch_id: SelectedEmpDrawer.branch_id._id,
    };



    axios
      .get(`${api_url}/getShiftList`, { params: { ...params } })
      .then((responce) => {
        setGetShiftlist(
          responce.data.data.map((item, key) => ({
            label: item.shift_name,
            id: item._id,
          }))
        );
      
        handleOpen1();
      })
      .catch((err) => {
      
      });
  }

  useEffect(() => {
    getBranchList();
  }, [company_idd]);



  // ==================================== branchlist api call =======================================



  function getBranchList() {
   
    const params = {
      company_id: company_idd,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetBranchlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
        // setBranchlist(
        //   responce.data.data.map((item, key) => ({
        //     label: item.branch_name,
        //     id: item._id,
        //   }))
        // );
  
      })
      .catch((err) => {
  
      });
  }

  //   ========================  deparment list api =============================

  function getDepartmentList() {
  
    const params = {
      token: loginToken1,
      branch_id: branchid,
    };

   

    axios
      .get(`${api_url}/getDepartmentList`, { params: { ...params } })
      .then((responce) => {
        setGetDepartmentlist(
          responce.data.data.map((item, key) => ({
            label: item.department_name,
            id: item._id,
          }))
        );
   
      })
      .catch((err) => {
    
      });
  }

  

  // =============================   ADD COMPANY ==============================
  // =============================   ADD COMPANY ==============================
  const [value, setValue] = useState('');
  



  // =============================  ASSIGN SHIFT ==============================
  const [SelectedEmpId, setSelectedEmpId ] = useState("");

  React.useEffect(()=>{
    if(SelectedEmpId){
      getShiftList();
    }
  },[SelectedEmpId])

  function assignShift(braid, depid) {
    const params = JSON.stringify({
      token: loginToken1,
      user_id: loginAtom1,
      company_id: company_idd,
      branch_id: SelectedEmpId.branch_id._id,
      department_id: SelectedEmpId.department_id._id,
      employee_id: SelectedEmpId._id,
      shift_id: shiftid,
    });



    axios
      .post(`${api_url}/assign_shift`, params, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
      
        getEmployeeList();
        setOpen1(false);
      })

      .catch(function (error) {
        if (error.response) {
         
        } else if (error.request) {
          
        } else {
      
        }
      
      });
  }

   //  ========================    GET COMPANY DETAILS  ===========================

const [trigger , setTrigger] = useState(false)



   const [getEmployeedetails , setGetEmployeedetails] = useState([])
   

   const [empupid , setempupid ] = useState('')

  const getEmployeeDetails = (employeeid) => (e) => {
    e.preventDefault()
   
    setempupid(employeeid)

    const params = {
      token: loginToken1,
      employee_id: employeeid,
    };

    axios
      .get(`${api_url}/getEmployeeDetails`, { params: { ...params } })
      .then((responce) => {
      
        setGetEmployeedetails(responce.data.data);
        setTrigger(true)
        setOpen3(true);
      })
      .catch((err) => {
       
      });
  }


   // ======================================= API UPDATE =================================================
   const [open3, setOpen3] = React.useState(false);

  const handleClose3 = () => setOpen3(false);

  function updateEmployee(employeeid){


    getEmployeedetails.token = loginToken1;
    getEmployeedetails.employee_id = empupid;
    getEmployeedetails.branch_id = branchId;



    axios
      .patch(`${api_url}/updateEmployee`, getEmployeedetails, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(function (response) {
        // setSnackbaropen({
        //   open: true,
        //   message: "Shift Added successfully",
        //   severity: "success",
        //   actionColor: "#edf7ed",
        // });
        getEmployeeList();
        setOpen3(false);
      })

      .catch(function (error) {
        if (error.response) {
       
        } else if (error.request) {
        
        } else {
         
        }
       
      });
  }

  




   // ======================================= Api DELETE =================================================
   const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [deleteEmpId , setDeleteEmpId] = useState("")
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const [deleteId , setDeleteId] = useState('');





  const deleteEmployee = (companyid) => () => {


    const params ={
      employee_id:deleteId,
      token:loginToken1
  }
 
    axios
      .delete(`https://server.nowdigitaleasy.com:3505/deleteEmployee`,{ params } )
      .then((result) => {
      
        alert("Deleted")  
        // setSnackbaropen({
        //   open: true,
        //   message: "Employee Delete successfull",
        //   severity: "error",
        //   actionColor: "#fdeded",
        // });
        getEmployeeList();
        setOpen2(false);
      })
      .catch((error) => {
      
        if (error.response) {
         
        } else if (error.request) {
         
        } else {
          
        }
     
      });
  };




  //============================================== Changing Form Values =============================================



  function handlechange(e) {
    const { value, name } = e.target;
    setGetEmployeedetails({ ...getEmployeedetails, [name]: value });
  }

  

  // ------------------------------------------------- Drawer ------------------------------------------------------

 

 
  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
   
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };


  const [branchId, setBranchId] = useState("");
  

  // =================  UPADTE EMPLOYEES =========================
  const handleChange1 = (event) => {
    setBranchId(event.target.value);
   
  };




  // ================================= VALIDATION ===================================

  
  const [isValidPhone, setIsValidPhone] = useState(true);


  const validatePhone = () => {

    const phonePattern = /^(\+\d{1,3})?\d{12}$/;
    return phonePattern.test(value);
  };

  const [errors, setErrors] = useState({});
 

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setCompanyForm((state) => ({ ...state, [name]: value }));

 
    const newErrors = { ...errors };

    switch (name) {
      case "first_name":
        newErrors.first_name = validator.isEmpty(value)
          ? "Please enter your First Name."
          : "";
        break;
      case "last_name":
        newErrors.last_name = validator.isEmpty(value)
          ? "Please enter your Last Name."
          : "";
        break;
        case "employee_company_id":
        newErrors.employee_company_id = validator.isEmpty(value)
          ? "Please enter your Employee Id."
          : "";
        break;
      case "email_id":
        newErrors.email_id = validator.isEmpty(value)
          ? "Please enter your email Address."
          : !validator.isEmail(value)
          ? "Please enter a valid email address."
          : "";
        break;
   
      default:
        break;
    }

    setErrors(newErrors);
  };



  const handleSubmit = (event) => {
    event.preventDefault();

  
   

    if (validate()) {

        const params = JSON.stringify({
          token: loginToken1,
          user_id: loginAtom1,
          company_id: company_idd,
          branch_id: branchid,
          department_id: departmentid,
          employee_company_id: companyform.employee_company_id,
          first_name: companyform.first_name,
          last_name: companyform.last_name,
          email_id: companyform.email_id,
          nick_name: companyform.nick_name,
          moile_no: value,
          address: companyform.address,
          joining_date: joiningDate,
        });
        axios
          .post(`${api_url}/addEmployee`, params, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then(function (response) {
            getEmployeeList();
            setOpen(false);
            alert("Employee Added Successfully")
          })
    
          .catch(function (error) {
            if (error.response) {
             
            } else if (error.request) {
         
            } else {
           
            }
        
          });
  

    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    const isValid1 = validatePhone();
    setIsValidPhone(isValid1);

    if (isValid1) {
   
    } else {
      
    }

    if (validator.isEmpty(companyform.first_name)) {
      isValid = false;
      newErrors.first_name = "Please enter your first name.";
    }

    if (validator.isEmpty(companyform.last_name)) {
      isValid = false;
      newErrors.last_name = "Please enter your last name.";
    }

    if (validator.isEmpty(companyform.employee_company_id)) {
      isValid = false;
      newErrors.employee_company_id = "Please enter your Employee Id.";
    }

    if (validator.isEmpty(companyform.email_id)) {
      isValid = false;
      newErrors.email_id = "Please enter your email Address.";
    } else if (!validator.isEmail(companyform.email_id)) {
      isValid = false;
      newErrors.email_id = "Please enter a valid email address.";
    }

    if (validator.isEmpty(branchid)){
      isValid = false;
      newErrors.branchid = "Please Select Branch"
    }
    if (validator.isEmpty(departmentid)){
      isValid = false;
      newErrors.departmentid = "Please Select Department"
    }
    if (validator.isEmpty(joiningDate)){
      isValid = false;
      newErrors.joiningDate = "Please Select Joining Date.";
    }

    // if (validator.isEmpty(input.phone_number)) {
    //   isValid = false;
    //   newErrors.phone_number = "Please enter your phone number.";
    // } else if (
    //   !validator.isNumeric(input.phone_number) ||
    //   input.phone_number.length !== 10
    // ) {
    //   isValid = false;
    //   newErrors.phone_number = "Please enter a valid 10-digit phone number.";
    // }

    setErrors(newErrors);
    return isValid;
  };

  return (
    <div className="companylist">
      {/* ======================================================== Company Table ======================================================= */}

      <div className="company_table">
        <Box>
          <div className="company_table-filter" style={{justifyContent:"flex-end"}}>
            {/* <div className="filter_btn">
              <TextField
                variant="standard"
                style={{ width: "30%", marginLeft: 20 }}
                icon="search"
                placeholder="Search..."
                onChange={(e) => searchItems(e.target.value)}
                InputProps={{
                  endAdornment: <SearchOutlinedIcon />,
                }}
              />
            </div> */}

            {/* ============================================ ADD EMPLOYEE MODAL BOX ========================================= */}

            <div className="btn">
              <Button
                // variant="contained"
                className="tablebutton"
                // onClick={opendrawer}
                // startIcon={<icons.Add />}
                // disableElevation
                onClick={handleOpen}
              >
                Add EMPLOYEE
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    ADD EMPLOYEE
                    <IconButton
                      component="span"
                      onClick={handleClose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={2}
                    padding={2}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          name="first_name"
                          label={"First Name"}
                          //onChange={(e) => formonchange("first_name", e)}
                          onChange={handleChange}
                          fullWidth
                          required
                        />
                        {errors.first_name ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.first_name}
            </div>
          ) : (
            ""
          )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          name="last_name"
                          label={"Last Name"}
                          //onChange={(e) => formonchange("last_name", e)}
                          onChange={handleChange}
                          fullWidth
                          required
                        />
                         {errors.last_name ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.last_name}
            </div>
          ) : (
            ""
          )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Nick Name"}
                          //onChange={(e) => formonchange("nick_name", e)}
                          name="nick_name"
                          onChange={handleChange}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                      <PhoneInput
                                placeholder="Enter phone no*"
                                value={value}
                                defaultCountry="IN"
                                onChange={setValue}
                          />
                            {/* <PhoneInput
          style={{width : "100%"}}
             enableAreaCodes={true}
            placeholder="Enter phone number"
            value={value}
            country={"in"}
            name="moile_no"
             onChange={handleChange2}
            //onChange={setValue}
             onBlur={handleBlur}
            // inputStyle={isValidPhone ? {} : { border: '2px solid red' }}
          /> */}
                   {!isValidPhone && <div className="text-danger"style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />Please enter a valid phone number.</div>}     
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"E-mail"}
                          //onChange={(e) => formonchange("email_id", e)}
                          onChange={handleChange}
                          name="email_id"
                          fullWidth
                          required
                        />
                          {errors.email_id ? (
            <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.email_id}
            </div>
          ) : (
            ""
          )}{" "}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Address"}
                          //onChange={(e) => formonchange("address", e)}
                          onChange={handleChange}
                          name="address"
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                            width: "100%",
                            height: "50px",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={GetBranchlist}
                          onChange={branchlist}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              // helperText="select your branch"
                              label="Select branch*"
                            />
                          )}
                        />
                        {errors.branchid  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.branchid} 
            </div> : ""}
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                            width: "100%",
                            height: "50px",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={GetDepartmentlist}
                          onChange={departmentlist}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              // helperText="select your branch"
                              label="Select Department*"
                            />
                          )}
                        />
                         {errors.departmentid  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.departmentid}
            </div> : ""}
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture
                            label="Joining date"
                            // joining_date
                            openTo="year"
                            views={["year", "month", "day"]}
                            value={value}
                            onChange={(newValue) => {
                              setValue(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider> */}
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                            backgroundColor: "#ecf4f8",
                          }}
                          id="date"
                          type="date"
                          value={joiningDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={joining_date}
                          size="small"
                          fullWidth
                          label="Date*"
                          variant="outlined"
                        />
                          {errors.joiningDate  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.joiningDate}
            </div> : ""}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Employee Id"}
                          name="employee_company_id"
                          // onChange={(e) =>
                          //   formonchange("employee_company_id", e)
                          // }
                          onChange={handleChange}
                          fullWidth
                          required
                        />
                         {errors.employee_company_id ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.employee_company_id}
            </div>
          ) : (
            ""
          )}
                      </Grid>
                     
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={handleSubmit}
                        >
                          Add EMPLOYEE
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
            </div>
          </div>

          {/* ================================ LIST OF EMPLOYEE TABLE ================================= */}

          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table_head">Name</TableCell>
                  <TableCell className="table_head">Company Name</TableCell>
                  <TableCell className="table_head">Email</TableCell>
                  <TableCell className="table_head">Phone</TableCell>
                  <TableCell className="table_head">Branch</TableCell>
                  <TableCell className="table_head">Depratment</TableCell>
                  <TableCell className="table_head">Action</TableCell>
                  {/* <TableCell className="table_head">Delete</TableCell> */}
                </TableRow>
              </TableHead>
              {searchInput.length > 1
                ? filteredResults.map((item) => {
                    return (
     
              <></>
                    );
                  })
                : Getemployeelist.map((item) => {
                    return (
                      <TableRow
                        hover
                        key={item}
                        onClick={() => {
                          setSelectedEmpDrawer(item);
                        }}
                        style={{ cursor: "pointer" }}
                        className="companylisttbl"
                      >
                      
                        <TableCell
                          className="company_name"
                          title={item.first_name}
                          onClick={toggleDrawer}
                        >
                          <div>
                            <Avatar style={{ marginRight: 15 }} />
                          </div>
                          {item.first_name}
                        </TableCell>

                      
                       
                        <TableCell
                          title={item.employee_company_id}
                          className="companypersonalinfo"
                        >
                          {item.employee_company_id}
                        </TableCell>
                        <TableCell
                          title={item.email_id}
                          className="companypersonalinfo"
                        >
                          {item.email_id}
                        </TableCell>
                        <TableCell
                          title={item.moile_no}
                          className="companypersonalinfo"
                        >
                          {item.moile_no}
                        </TableCell>

                        <TableCell
                          title={item.branch_id.branch_name}
                          className="companypersonalinfo"
                        >
                          {item.branch_id.branch_name}
                        </TableCell>
                        <TableCell
                          title={item.department_id.department_name}
                          className="companypersonalinfo"
                        >
                          {item.department_id.department_name}
                        </TableCell>
                        <TableCell
                          title={item.department_id.department_name}
                          className="companypersonalinfo"
                        >
            </TableCell>

                        <div class="overlay">
                          <div class="text">
                            <Button  onClick={getEmployeeDetails(item._id)} style={{ fontWeight: 600 }}>
                              Update User
                            </Button>
                            <Modal
                              BackdropProps={{
                                style: { backgroundColor: "#75757542" },
                              }}
                open={open3}
                onClose={handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    UPDATE EMPLOYEE
                    <IconButton
                      component="span"
                      onClick={handleClose3}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={4}
                    padding={5}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          value={getEmployeedetails.first_name}
                          label={"First Name"}
                          name="first_name"
                          onChange={handlechange}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          value={getEmployeedetails.last_name}
                          name="last_name"
                          label={"Last Name"}
                          onChange={handlechange}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          value={getEmployeedetails.nick_name}
                          id="standard-basic"
                          name="nick_name"
                          variant="outlined"
                          label={"Nick Name"}
                          onChange={handlechange}
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          value={getEmployeedetails.moile_no}
                          name="moile_no"
                          variant="outlined"
                          label={"Phone Number"}
                          onChange={handlechange}
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          value={getEmployeedetails.email_id}
                          id="standard-basic"
                          name="email_id"
                          variant="outlined"
                          label={"E-mail"}
                          onChange={handlechange}
                          fullWidth
                          required
                        />
                      </Grid>

                      <Grid
              item
              xs={4}
              // display={"flex"}
              // justifyContent={"flex-start"}
              // alignItems={"flex-start"}
              // flexDirection={"column"}
            >
              {/* <InputLabel
                htmlFor="demo-customized-select-native"
                style={{ fontSize: "12px", margin: "8px 0 0 8px" }}
              >
                Company :
              </InputLabel> */}
              <FormControl
                // sx={{ m: 1 }}
                variant="standard"
                style={{ width: "100%" }}
                size="small"
              >
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={getEmployeedetails.branch_id}
                  onChange={handleChange1}
                  input={<BootstrapInput />}
                >
               
                  {GetBranchlist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>
                      
                      </>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </Grid>
                     
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          value={getEmployeedetails.address}
                          name="address"
                          id="standard-basic"
                          variant="outlined"
                          label={"Address"}
                          onChange={handlechange}
                          fullWidth
                          required
                        />
                      </Grid>



                      <Grid item xs={12} sm={6} md={4}>
                        <TextField 
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          name="employee_company_id"
                          label={"Employee Id"}
                          value={getEmployeedetails.employee_company_id}
                          onChange={handlechange}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose3}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick= {() => {updateEmployee(item._id) }}
                        >
                          UPDATE EMPLOYEE
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
                            </Modal>
                        
                            <Button
                              
                             
                              onClick= {() => {handleOpen2(); setDeleteId(item._id)}}
                              style={{ fontWeight: 600 }}
                            >
                              Delete
                            </Button>
                           

                            <Modal
                              open={open2}
                              onClose={handleClose2}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              style={{
                                backgroundColor: "#ffffff9e",
                              }}
                              TransitionComponent={Transition}
                            >
                               <Box sx={style} className="scroll">
                                <div className="deleteemployee">
                                Are You Sure You Want To Delete Your Employee ?
                                </div>  
                                <Stack
                                  spacing={4}
                                  padding={2}
                                  width="100%"
                                  alignItems="center"
                                  height="100%"
                                >
                                 

                                  <div className="addcompanybtn">
                                    <Stack direction={"row"} spacing={2}>
                                      <Button
                                        variant="outlined"
                                        className="tablebtncancel"
                                        style={{ color: "#000" }}
                                        onClick={handleClose2}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="contained"
                                        className="tablebutton"
                                        onClick={
                                        deleteEmployee()
                                        }
                                      >
                                        Delete
                                      </Button>
                                    </Stack>
                                  </div>
                                </Stack>
                              </Box>
                            </Modal>
                            <Button
                              onClick={() => {
                             
                                setSelectedEmpId(item);
                              }}
                              style={{ fontWeight: 600 }}
                            >
                              Assign Shift
                            </Button>
                            <Modal
                              open={open1}
                              onClose={handleClose1}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              style={{
                                backgroundColor: "#ffffff9e",
                              }}
                            >
                              <Box sx={style} className="scroll">
                                <div className="addcompanytitle">
                                  ASSIGN SHIFT
                                  <IconButton
                                    component="span"
                                    onClick={handleClose1}
                                    style={{ padding: 0, marginRight: 20 }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </div>
                                <Stack
                                  spacing={4}
                                  padding={5}
                                  width="100%"
                                  alignItems="center"
                                  height="100%"
                                >
                                  <Autocomplete
                                    style={{
                                      textTransform: "capitalize",
                                      backgroundColor: "#FFFFFF",
                                      fontFamily: "'Montserrat', sans-serif",
                                      width: "100%",
                                      height: "50px",
                                    }}
                                    id="outlined-multiline-static"
                                    size="small"
                                    fullWidth
                                    options={GetShiftlist}
                                    onChange={shiftList}
                                    renderInput={(params) => (
                                      <TextField
                                        variant="standard"
                                        {...params}
                                        // helperText="select your branch"
                                        label="Select Department*"
                                      />
                                    )}
                                  />

                                  <div className="addcompanybtn">
                                    <Stack direction={"row"} spacing={2}>
                                      <Button
                                        variant="outlined"
                                        className="tablebtncancel"
                                        style={{ color: "#000" }}
                                        onClick={handleClose1}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="contained"
                                        className="tablebutton"
                                        onClick={() => {
                                          assignShift();
                                     
                                         
                                        }}
                                      >
                                        ASSIGN SHIFT
                                      </Button>
                                    </Stack>
                                  </div>
                                </Stack>
                              </Box>
                            </Modal>
                          </div>
                        </div>
                       
                      </TableRow>
                    );
                  })}
              <EmployeDrawer
                open={openDrawer}
                toggleDrawer={toggleDrawer}
                selectedEmp={SelectedEmpDrawer}
              />
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
}

export default CompanyListTable;
