import React from 'react';

const Typography = ({ variant = 'body1', children, fontWeight, color, fontSize, ...rest }) => {
  let style = {
    margin: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  };

  switch (variant) {
    case 'h1':
      style = {
        ...style,
        fontSize: '72px',
        lineHeight: '90px',
      };
      break;
    case 'h2':
      style = {
        ...style,
        fontSize: '60px',
        lineHeight: '72px',
      };
      break;
    case 'h3':
      style = {
        ...style,
        fontSize: '48px',
        lineHeight: '60px',
      };
      break;
    case 'h4':
      style = {
        ...style,
        fontSize: '36px',
        lineHeight: '44px',
      };
      break;
    case 'h5':
      style = {
        ...style,
        fontSize: '30px',
        lineHeight: '38px',
      };
      break;
    case 'h6':
      style = {
        ...style,
        fontSize: '24px',
        lineHeight: '32px',
      };
      break;
    case 'subtitle1':
      style = {
        ...style,
        fontSize: '20px',
        lineHeight: '30px',
      };
      break;
    case 'subtitle2':
      style = {
        ...style,
        fontSize: '18px',
        lineHeight: '28px',
      };
      break;
    case 'body1':
      style = {
        ...style,
        fontSize: '16px',
        lineHeight: '24px',
      };
      break;
    case 'body2':
      style = {
        ...style,
        fontSize: '12px',
        lineHeight: '18px',
      };
      break;
    case 'caption':
      style = {
        ...style,
        fontSize: '10px',
        lineHeight: '16px',
      };
      break;
    default:
      break;
  }

  if (fontWeight) {
    style = {
      ...style,
      fontWeight,
    };
  }

  if (color) {
    style = {
      ...style,
      color,
    };
  }

  if (fontSize) {
    style = {
      ...style,
      fontSize,
    };
  }

  return (
    <p style={style} {...rest}>
      {children}
    </p>
  );
};

export default Typography;
