
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import { DeleteNote } from './DeleteNote';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import variables from "../../assets/styles/_colors.scss";
import { withStyles } from "@mui/styles";
import { useEffect } from 'react';

const StyledTableSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

export default function Note(props) {

    const [deleteNote, setDeleteNote] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    useEffect(() => {
        setIsEditing(props.editData && props.note?.id === props.editData?.id ? true : false);
    }, [props.editData]);

    return (
        <>
            <Card onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
                sx={{ boxShadow: 'none', padding: 1, borderBottom: `1px solid ${variables.lightBorderColor}`, "&:hover": { backgroundColor: `${variables.lightGreyBackgroundColor}` } }}>
                <Stack width="100%" direction="row" alignItems="center">
                    <Stack direction="row" width="30px">
                        <PersonIcon size="small" sx={{ width: '16px !important', height: '16px !important' }} />
                    </Stack>
                    <Stack width="82%" direction="row" spacing={1} alignItems="center">
                        <Typography sx={{ fontWeight: 'bold' }}>{props.note?.createdBy}</Typography>
                        <StyledTableSeparator />
                        {props.note?.editedTime
                            ? <>
                                <Typography sx={{ fontSize: 13, color: `${variables.greyTextColor}` }}>(Edited)</Typography>
                                <Typography sx={{ fontSize: 13, color: `${variables.greyTextColor}` }}>{props.note?.editedTime}</Typography>
                            </>
                            :
                            <Typography sx={{ fontSize: 13, color: `${variables.greyTextColor}` }}>{props.note?.createdTime}</Typography>
                        }
                    </Stack>
                    <Stack width="10%" direction="row" justifyContent="right" spacing={1} sx={{ visibility: isHovering ? (isEditing ? "hidden" : "inherit") : "hidden" }}>
                        <IconButton onClick={() => { props.handleEdit(props.note); }} sx={{ height: "30px", width: "30px", bgcolor: "#fff !important" }}>
                            <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                        </IconButton>
                        <IconButton onClick={() => { setDeleteNote(true) }} sx={{ height: "30px", width: "30px", bgcolor: "#fff !important" }}>
                            <DeleteOutlineIcon fontSize='medium' color="error" />
                        </IconButton>
                    </Stack>
                </Stack>
                <Stack direction="row">
                    <Typography component="div" sx={{ whiteSpace: 'pre-wrap', width: "100%", pl: 4 }}>{props.note?.note}</Typography>
                </Stack>
            </Card>

            {
                deleteNote && <DeleteNote module="note" handleOk={() => { setDeleteNote(false); props.handleDelete(props.index) }} handleCancel={() => setDeleteNote(false)} />
            }
        </>
    )
}