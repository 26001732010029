import { Checkbox } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import Box from "@mui/material/Box";

import { useFormik } from "formik";
import * as Yup from "yup";

import { clientidd } from "../../loginatom";
import { useRecoilState } from "recoil";
import {
  JWT,
  login_atoms,
  login_token,
  login_EmpDetails,
} from "../../../../Atoms/LoginAtoms";
import { api_url1 } from "../../../../globalSettings";
import { NavLink } from "react-router-dom";
import Style from "../../Style/LogInPage.module.css";
import CustomButtonContainer from "../../Components/CustomButtonContainer";
import CustomButton from "../../Components/CustomButton";
import CustomSnackBar from "../../../SnackBar/CustomSnackBar";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useWorkSpaceStore from "../../../../GlobalState/WorkSpaceState";
import UseSelectedWorkSpace from "../../../../GlobalState/selectedWorkSpace";
function ButtonContainer() {
  const setWorkSpace = useWorkSpaceStore((state) => state.setWorkSpace);
  const setSelectedWorkSpace = UseSelectedWorkSpace(
    (state) => state.setSelectedWorkSpace
  );
  const [clientid, setClientid] = useRecoilState(clientidd);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [loginEmpDetails, setLoginEmpDetails] =
    useRecoilState(login_EmpDetails);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const isToken = token === null ? false : true;
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [JToken, setJToken] = useRecoilState(JWT);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter Email"),
    password: Yup.string().required("Please enter Password"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      showPassword: false,
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      let obj = {
        email: values.email,
        password: values.password,
      };
      setSubmitting(true);

      axios
        .post(`${api_url1}/client/signin`, obj)
        .then((data) => {
          localStorage.setItem("auth", JSON.stringify(data.data.data));
          setSnackbaropen({
            open: true,
            message: "login successfull",
            severity: "success",
          });

          const selectedWorkSpace = data.data.workspaces.find(
            (workspace) => workspace.id === data.data.defaultWorkspace
          );

          setSelectedWorkSpace(selectedWorkSpace);
          setWorkSpace(data.data.workspaces);
          setloginAtom1(data.data.data.user_id);
          setloginToken1(data.data.data.token);
          setClientid(data.data.data.user_id);
          setJToken(`Bearer ${data.data.data.jwtToken}`);
          setLoginEmpDetails(data.data.data);
          window.location.href = "/";
        })
        .catch((error) => {
          setSnackbaropen({
            open: true,
            message: error.response.data.error.message,
            severity: "error",
            actionColor: "#edf7ed",
          });
          setSubmitting(false);
        });
    },
  });
  const handleVisiblility = () => {
    formik.handleChange({
      target: {
        name: "showPassword",
        value: !formik.values.showPassword,
      },
    });
  };
  function LogInViaToken() {
    let obj = {
      token: token,
    };
    axios.post(`${api_url1}/client/signin`, obj).then((data) => {
      localStorage.setItem("auth", JSON.stringify(data.data.data));

      setSnackbaropen({
        open: true,
        message: "login successfull",
        severity: "success",
      });

      setloginAtom1(data.data.data.user_id);
      setloginToken1(data.data.data.token);
      setClientid(data.data.data.user_id);
      setJToken(`Bearer ${data.data.data.jwtToken}`);
      setLoginEmpDetails(data.data.data);
      window.location.href = "/";
    });
  }
  useQuery({
    queryKey: ["LogInViaToken"],
    queryFn: LogInViaToken,
    enabled: isToken,
  });
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <form onSubmit={formik.handleSubmit}>
          <div style={{ margin: "0px 3%" }}>
            <CustomButtonContainer
              name={"email"}
              fieldType={"email"}
              value={formik.values.email}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              onChangeFuncion={formik.handleChange}
              title={"Email"}
              isPassword={false}
              isMargin={true}
            />
            <br />
            <CustomButtonContainer
              name={"password"}
              value={formik.values.password}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              onChangeFuncion={formik.handleChange}
              title={"Password"}
              isPassword={true}
              handleMouseDownPassword={handleMouseDownPassword}
              handleVisiblility={handleVisiblility}
              showPassword={formik.values.showPassword}
              isMargin={false}
            />

            <div className={Style.align_container}>
              <div className={Style.align_button}>
                <Checkbox sx={{ padding: "5px 5px 5px 0px" }} />
                <p className={Style.black_text}>Remember for 30 days</p>
              </div>
              <NavLink to="/login/verifymail" className={Style.blue_text}>
                Forgot Password ?
              </NavLink>
            </div>
            <CustomButton
              loading={formik.isSubmitting}
              onSubmit={formik.handleSubmit}
              title={"Sign In"}
            />

            <div className={Style.black_text_} style={{ marginTop: "4%" }}>
              Don't Have An Account?
              <NavLink to="/signUp" style={{ marginLeft: "4px" }}>
                <a href="/#" className={Style.blue_text}>
                  Sign Up
                </a>
              </NavLink>
            </div>
          </div>
        </form>
      </Box>
      <CustomSnackBar
        snackbaropen={snackbaropen}
        setSnackbaropen={setSnackbaropen}
        position="left"
      />
    </>
  );
}

export default ButtonContainer;
