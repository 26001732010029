import { useState } from "react";
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconButton } from "@mui/material";
import variables from "../../assets/styles/_colors.scss";

const TableHeader = ({ columns, handleSorting }) => {
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("asc");

    const handleSortingChange = (accessor) => {
        const sortOrder =
            accessor === sortField && order === "asc" ? "desc" : "asc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    return (
        <thead>
            <tr style={{ verticalAlign: 'middle', top: 0, position: 'sticky', zIndex: 2, background: "#FFF", filter: 'drop-shadow(0 0 2px lightgrey)' }}>
                {columns.map(({ label, accessor, sortable, width }) => {
                    return (
                        <th
                            key={accessor}
                            onClick={sortable ? () => handleSortingChange(accessor) : null}
                            style={{ cursor: sortable ? 'pointer' : 'default', overflow: 'hidden', width: width, height: 40, fontWeight: 500, padding: 8 }}
                        >
                            {label}
                            {/* {sortField === accessor && order === "asc" && <IconButton sx={{ height: 25, width: 25 }}><ArrowUpwardIcon fontSize="small" /></IconButton>}
                            {sortField === accessor && order === "desc" && <IconButton sx={{ height: 25, width: 25 }}><ArrowDownwardIcon fontSize="small" /></IconButton>} */}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TableHeader;