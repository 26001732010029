import { Divider, Grid, TextField } from '@mui/material';
import React from 'react'
// import { Box, Typography } from '@mui/material'
import styled from "styled-components";
import Radio from '@mui/material/Radio';
 

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column;
`;

const Typography = styled.div`
color:#4fb385;
font: 12px;
`;

const TableContainer = styled.div`
width:100%;
`;

const TableColumn = styled.div`
display:flex;
flex-direction:row;
font-size:12px;
`;

const TableRow = styled.div`
width:20%;
padding:5px;
text-align:right;
`;

const TableRow1 = styled.div`
width:80%;
padding:5px
`;


const ContactAddFollowupRemainder = () => {
  const [selectedValue, setSelectedValue] = React.useState('');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <Box ><div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
    <Typography >Add Remainder Of Follow-up</Typography>
    </div>
    <Divider style={{margin :"10px"}}/>

   <Grid container spacing={2} style={{fontSize : '12px'}}>
        <Grid item xs={6} sm={4} md={2} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
   Remainder Date
        </Grid>
        <Grid item xs={6} sm={8} md={10}>
<TextField size='small' fullWidth type='date'/>     
   </Grid>
        <Grid item xs={6} sm={4} md={2} display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        Remainder For
        </Grid>
        <Grid item xs={6} sm={8} md={10}>
        <div>
      <Radio
        checked={selectedValue === 'a'}
        onChange={handleChange}
        value="a"
        name="radio-buttons"
        inputProps={{ 'aria-label': 'A' }}
      />Admin
      <Radio
        checked={selectedValue === 'b'}
        onChange={handleChange}
        value="b"
        name="radio-buttons"
        inputProps={{ 'aria-label': 'B' }}
      />Client
    </div>

        </Grid>
   </Grid>
    </Box>
  )
}

export default ContactAddFollowupRemainder