import React from "react";
import { IconButton, Grid, Modal, Box, Button } from "@mui/material";

import axios from "axios";
import { api_url1 } from "../../../../globalSettings";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import { useRecoilState } from "recoil";
import { actionstatus } from "../../../../Atoms/warningpopup";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};
const buttonStyles = {
  marginRight: "10px",
  background: "#0000",
  border: "1px solid #468def",
  borderRadius: "2px",
  color: "#468def",
  cursor: "pointer",
  display: "inline-block",
  fontFamily: "OpenSans",
  fontSize: "12px",
  lineHeight: "1",
  outline: "none",
  padding: "7px 14px",
  transition: "0.2s",
  height: "30px",
};
const ChildNameServer = (props) => {

  const [actionstatus1, setactionstatus1] = useRecoilState(actionstatus);

  const validationSchema = Yup.object().shape({
    childServer: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("Child Name Server is required")
          .test("isValidDomain", "Invalid Child Name Server", function (value) {
            const domainRegex = new RegExp(
              `^[a-zA-Z0-9.-]+\\.${props.Selecteddomain.domainName}$`
            );
            return domainRegex.test(value);
          }),
        ip: Yup.string()
          .required("IP Address is required")
          .matches(
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            "Invalid IP Address"
          ),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      childServer: props.childServer || [{}],
    },
    validationSchema,
  });


  const addChildNameServerApi = async (ns, index) => {
    try {
      await formik.validateField(`childServer[${index}].name`);
      await formik.validateField(`childServer[${index}].ip`);
      const nameFieldValue = formik.values.childServer[index].name;
      const ipFieldValue = formik.values.childServer[index].ip;
      if (!nameFieldValue || !ipFieldValue) {
        return;
      }

      let obj = {
        domain_name: props.Selecteddomain.domainName,
        slno: ns.slno,
        order_id: props.domainDetails.orderid,
        childname: ns.name,
        childnameip: ns.ip,
      };

      await axios.post(`${api_url1}/resellers/updatecns`, obj);
      props.setSnackbarInfo({
        open: true,
        severity: "success",
        message: "updated successfully",
      });
      fetchactions(props.domainDetails.orderid);
      props.fetchDomainDetails();
      props.childserverupdatedialogonclose();
    } catch (error) {
      props.setSnackbarInfo({
        open: true,
        severity: "error",
        message: error.response.data.message,
      });
    }
  };

  const deleteChildNameServerApi = async (ns, index) => {

    try {

      await formik.validateField(`childServer[${index}].name`);
      await formik.validateField(`childServer[${index}].ip`);
      const nameFieldValue = formik.values.childServer[index].name;
      const ipFieldValue = formik.values.childServer[index].ip;

      if (!nameFieldValue || !ipFieldValue) {
        return;
      }

      let obj = {
        order_id: props.domainDetails.orderid,
        cns: ns.name,
        ip: ns.ip,
      };

     const res1 = await axios.post(`${api_url1}/resellers/deleteChildNameServer`, obj);

      fetchactions(props.domainDetails.orderid);
      props.fetchDomainDetails();
      props.childserverupdatedialogonclose();

      props.setSnackbarInfo({
        open: true,
        severity: "success",
        message: "deleted successfully",
      });

    } catch (error) {

      props.setSnackbarInfo({
        open: true,
        severity: "error",
        message: error.response.data.message,
      });

    }

  };

  const addChildServer = () => {
    formik.setFieldValue("childServer", [...formik.values.childServer, {}]);
  };

  function fetchactions(orderid) {
    axios
      .get(
        `${api_url1}/resellers/getactions?orderid=${orderid}&no_of_records=10&page_no=1&action_status=&action_type=`
      )
      .then((result) => {
        if (result) {
          const filteredData = result.data.filter(
            (item) =>
              item.actionstatus !== "Success" && item.actionstatus !== "Failed"
          );

          setactionstatus1(filteredData);
        }
      })
      .catch((err) => {});
  }

  return (
    <>
      <div>
        <div>
          <Modal
            open={props.childserverupdatedialog}
            onClose={props.childserverupdatedialogonclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="scroll">
              <div className="addcompanytitle" style={{ height: 60 }}>
                Child Name Server
                <IconButton
                  component="span"
                  onClick={props.childserverupdatedialogonclose}
                  style={{ padding: 0, marginRight: 20 }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    maxWidth: "100%",

                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Grid container spacing={1} style={{ padding: 10 }}>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={6}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <strong>Host Name</strong>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          sm={6}
                          xs={6}
                          alignItems="center"
                          justifyContent="center"
                        >
                          <strong>Ip Address</strong>
                        </Grid>
                        <>
                          {formik.values.childServer.map((ns, index) => (
                            <>
                              <Grid
                                item
                                md={6}
                                sm={6}
                                xs={6}
                                justifyContent="start"
                                key={index}
                              >
                                <div style={{ position: "relative" }}>
                                  <div>
                                    <input
                                      className="nameServe-inputField"
                                      name={`childServer[${index}].name`}
                                      value={ns.name}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldTouched(
                                          `childServer[${index}].name`,
                                          true,
                                          false
                                        );
                                      }}
                                    />
                                  </div>
                                  <div
                                    className="error-wrapper"
                                    style={{
                                      height: "20px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {formik.errors.childServer?.[index]
                                      ?.name && (
                                      <div className="childeError-wrapper">
                                        {formik.errors.childServer[index].name}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                item
                                md={6}
                                sm={6}
                                xs={6}
                                alignItems="center"
                                justifyContent="flex-start"
                                key={index}
                                className="alignChildServer"
                              >
                                <div style={{ position: "relative" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <input
                                      className="ChildServerIp-inputField "
                                      name={`childServer[${index}].ip`}
                                      value={ns.ip}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                        formik.setFieldTouched(
                                          `childServer[${index}].ip`,
                                          true,
                                          false
                                        );
                                      }}
                                    />
                                    <div className="childServer-Button-Change">
                                      <Button
                                        style={buttonStyles}
                                        type="button"
                                        onClick={() => {
                                          addChildNameServerApi(ns, index);
                                        }}
                                      >
                                        Add
                                      </Button>
                                      <Button
                                        style={buttonStyles}
                                        type="button"
                                        onClick={() => {
                                          deleteChildNameServerApi(ns, index);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </div>
                                  <div
                                    className="error-wrapper"
                                    style={{
                                      height: "20px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {formik.errors.childServer?.[index]?.ip && (
                                      <div className="childeError-wrapper">
                                        {formik.errors.childServer[index].ip}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </Grid>
                            </>
                          ))}
                        </>
                        <button
                          className="nameServer-AddButton"
                          type="button"
                          onClick={() => {
                            addChildServer();
                          }}
                        >
                          + Add ChildNameServer
                        </button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        backgroundColor: "#e0e0e0",
                        padding: "20px",
                        zIndex: "-1",
                      }}
                    >
                      Use the DNS service to point this domain to a hosting or
                      email package.
                    </Grid>
                  </Grid>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ChildNameServer;
