import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import PublicRoute from "./router/PublicRoute";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PrivateRoute from "./router/PrivateRoute";
import NoMatch from "./components/common/NoMatch";
import Login from "./components/login/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.css";
import SignUp from "./components/signUp/SignUp";
import Home from "./containers/Home";
import NavBar from "./components/dashboard/NavBar";
import Dashboard2 from "./containers/Dashboard2";
//importing recoilroot from recoil
import { RecoilRoot, useRecoilState } from "recoil";

import { loadingAll } from "./Atoms/LoginAtoms";
import { LinearProgress } from "@mui/material";
// import tanstack query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ConsolidatePayment from "./components/User/Components/ConsolidatePayment/ConsolidatePayment";
import Invoiceprint from "./components/User/invoice/Invoiceprint";




function App() {
  const [isLoading] = useRecoilState(loadingAll);
  const queryClient = new QueryClient();

  return (
    <>
      {isLoading ? <LinearProgress /> : ""}
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path="myaccount/billing/payment/:invoiceId"
              element={<ConsolidatePayment />}
            />
            <Route path="/Invoiceprint" element={<Invoiceprint />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/login/verifymail" element={<Login />} />
            <Route path="/login/resetpassword" element={<Login />} />
            {/* <Route path="/*" element={<PrivateRoute component={<Home />} />} /> */}
            {/* <Route path="/home" element={
          <PrivateRoute >
        <Dashboard2 />
        </PrivateRoute>
        }  /> */}
            <Route path="/*" element={<PrivateRoute />} />

            {/* <Route path="/dashboard" element={<PrivateRoute component={<Dashboard2 />} />} />
        <Route path="/webservices" element={<PrivateRoute component={<Webservices />} />} />
        <Route path="/webservices/DomainDetails" element={<PrivateRoute component={<DomainDetails />} />} /> */}
            {/* Add more routes as needed */}
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </>
  );
}
//This comment by Kavin
export default App;
