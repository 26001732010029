import { createStore, compose, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
//Redux DevTools for debugging application's state changes.

const appStore = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk, logger) //used to handle asynchronous actions in Redux.
  )
);
export default appStore;
