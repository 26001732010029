import React from "react";
import { Drawer, Avatar, Button } from "@mui/material";
import "./ContactDrawer.css";
import { Divider, Typography } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ReactStars from "react-rating-stars-component";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

const firstExample = {
  size: 20,
  value: 3,
  edit: false,
};

export default function ContactDrawer(props) {
  return (
    <div>
      <React.Fragment>
        <Drawer anchor="right" open={props.open} onClose={props.toggleDrawer}>
          <div id="contactdrawer">
            <div className="Contactprofile">
              <div className="entity-data">
                <Avatar style={{ marginRight: 15, width: 75, height: 75 }} />
                <div className="Contactinfo">
                  <div className="Contactname">
                    <Typography variant="h6" style={{ marginRight: 20 }}>
                      Kavin
                    </Typography>
                    <FacebookOutlinedIcon
                      sx={{ color: "#e2dfdf", marginRight: 1 }}
                    />
                    <TwitterIcon sx={{ color: "#e2dfdf", marginRight: 1 }} />
                    <InstagramIcon sx={{ color: "#e2dfdf", marginRight: 1 }} />
                  </div>

                  <Divider />
                  <div className="ContactLocation">
                    <LocationOnOutlinedIcon />
                    <a href="#"> Coimbatore </a>
                  </div>
                </div>
              </div>

              <Divider />
              <div className="contactscore">
                <div className="score">
                  <Typography variant="body2">Score</Typography>
                  <Typography variant="body1">77 </Typography>
                </div>
                <div className="fit">
                  <Typography variant="body2">Customer Fit</Typography>
                  <ReactStars {...firstExample} />
                </div>
              </div>
            </div>
            <div className="contactbuttons">
              <Button className="cbutton">
                <EmailOutlinedIcon fontSize="small" />
                Email
              </Button>
              <Button className="cbutton">
                <LocalPhoneOutlinedIcon fontSize="small" /> Call
              </Button>
              <Button className="cbutton">
                <SendToMobileOutlinedIcon fontSize="small" /> Sms
              </Button>
              <Button className="cbutton">
                <DescriptionOutlinedIcon fontSize="small" />
                Notes
              </Button>
              <Button className="cbutton">
                <TaskOutlinedIcon fontSize="small" /> Task
              </Button>
              <Button className="cbutton">
                <CalendarTodayIcon fontSize="small" /> Meeting
              </Button>
              <Button className="cbutton">
                <PaidOutlinedIcon fontSize="small" /> Add Deals
              </Button>
              {/* <Button className="cbutton">k</Button> */}
            </div>
            <Divider />
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
