import { CustomCheckbox } from "../pages/pipelines/components/CustomCheckbox";
import { CustomSelect } from "../pages/pipelines/components/CustomSelect";
import { CustomCurrencyField } from "../pages/pipelines/components/CustomCurrencyField";
import { CustomDatePicker } from "../pages/pipelines/components/CustomDatePicker";
import { CustomDateTimePicker } from "../pages/pipelines/components/CustomDateTimePicker";
import { CustomNumberField } from "../pages/pipelines/components/CustomNumberField";
import { CustomInputField } from "../pages/pipelines/components/CustomInputField";

// TODO optimize this method

export function renderComponents(field, prefix, isTableData) {
    switch (field.type) {
        case "text":
        case "url":
        case "phone":
        case "email":
            return isTableData ? (<CustomInputField size="small" name={`${prefix}.${field.key}`} rows={field.rows} value={field.value} rules={field.rules} subType={field.subType} />)
                : (<CustomInputField name={`${prefix}.${field.key}`} rows={field.rows} value={field.value} label={field.label} rules={field.rules} subType={field.subType} />)
        case "currency":
            return isTableData ? (<CustomCurrencyField size="small" name={`${prefix}.${field.key}`} rules={field.rules} />)
                : (<CustomCurrencyField name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} />)
        case "checkbox":
            return isTableData ? (<CustomCheckbox size="small" name={`${prefix}.${field.key}`} defaultChecked={field.checkbox.defaultChecked} />)
                : (<CustomCheckbox name={`${prefix}.${field.key}`} label={field.label} defaultChecked={field.checkbox.defaultChecked} />)
        case "date": {
            return field.subType === "datePicker" ?
                <CustomDatePicker name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} /> :
                <CustomDateTimePicker name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} />
        }
        case "subPipeline":
            return (<CustomSelect name={`${prefix}.${field.key}`} inputLabel={field.label} value={null} rules={field.rules}
                options={[{ label: 'Sub Pipeline 1', value: "1" }, { label: 'Sub Pipeline 2', value: "2" }]}>
            </CustomSelect>)
        case "stage":
            return (<CustomSelect name={`${prefix}.${field.key}`} inputLabel={field.label} value={null} rules={field.rules}
                options={[{ label: 'Stage 1', value: "1" }, { label: 'Stage 2', value: "2" }]}>
            </CustomSelect>)
        case "user":
            return (<CustomSelect name={`${prefix}.${field.key}`} inputLabel={field.label} value={null} rules={field.rules}
                options={[{ label: 'Mike Ross', value: "1" }, { label: 'Harvey Specter', value: "2" }]}>
            </CustomSelect>)
        case "picklist":
            return isTableData ? (<CustomSelect size="small" name={`${prefix}.${field.key}`} defaultValue={field.picklist.defaultValue?.value} rules={field.rules}
                options={field.picklist.options} />)
                : (<CustomSelect name={`${prefix}.${field.key}`} inputLabel={field.label} defaultValue={field.picklist.defaultValue?.value} rules={field.rules}
                    options={field.picklist.options} />)
        case "lookup":
            return isTableData ? (<CustomSelect size="small" name={`${prefix}.${field.key}`} rules={field.rules}
                options={field.options ? field.options : [{ label: "Zylker Corp", value: "abc" }, { label: "XYZ Corp", value: "xyz" }]}>
            </CustomSelect>) :
                (<CustomSelect name={`${prefix}.${field.key}`} rules={field.rules} inputLabel={field.label}
                    options={field.options ? field.options : [{ label: "ABC Corp", value: "abc" }, { label: "XYZ Corp", value: "xyz" }]}>
                </CustomSelect>)
        case "numerical":
            return isTableData ? (<CustomNumberField size="small" name={`${prefix}.${field.key}`} rules={field.rules} subType={field.subType} />)
                : (<CustomNumberField name={`${prefix}.${field.key}`} label={field.label} rules={field.rules} subType={field.subType} />)
        default:
            return null;
    }
}