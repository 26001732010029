import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import WebsiteTable from "../websitesettings/websites/WebsiteTable";
import MessageShortcuts from "../websitesettings/MessageShortcuts";
import ChatAvailability from "../websitesettings/ChatAvailability";
import ChatNotifications from "../websitesettings/ChatNotifications";

const MainWebsiteSettings = () => {
  const [value, setValue] = useState("websites");

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            style={{
              marginTop: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              color: "rgba(0,0,0,0.6)",
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: 1.25,
              letterSpacing: "0.02857em",
            }}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab
              style={{ textTransform: "none" }}
              label="Websites"
              value="websites"
              onClick={() => handleChange("websites")}
            />
            <Tab
              style={{ textTransform: "none" }}
              label="Message Shortcuts"
              value="messageShortcuts"
              onClick={() => handleChange("messageShortcuts")}
            />
            <Tab
              style={{ textTransform: "none" }}
              label="Availability"
              value="availability"
              onClick={() => handleChange("availability")}
            />
            <Tab
              style={{ textTransform: "none" }}
              label="Notifications"
              value="notifications"
              onClick={() => handleChange("notifications")}
            />
          </TabList>
        </Box>
        <TabPanel value="websites">
          <WebsiteTable />
        </TabPanel>
        <TabPanel value="messageShortcuts">
          <MessageShortcuts />
        </TabPanel>
        <TabPanel value="availability">
          <ChatAvailability />
        </TabPanel>
        <TabPanel value="notifications">
          <ChatNotifications />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default MainWebsiteSettings;
