import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AvatarStatus from "../../../components/Avatar";
import Styles from "../../../MainMenu/ChatLayout.module.scss";
import { useQuery } from "@tanstack/react-query";

const fetcher = () => {
  return axios
    .get("https://662630ce052332d55321f8bc.mockapi.io/chatnow")
    .then(({ data }) => data);
};
const VisitorInfo = () => {
  const [selected, setSelected] = useState({});
  const [searchQuery, setSearchQuery] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const { data: visitordata } = useQuery({
    queryKey: ["visitordata"],
    queryFn: fetcher,
  });

  const handleRoute = (id) => {
    navigate(`/chat/visitors/${id}`);
  };

  const handleCheckboxChange = (id) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [id]: !prevSelected[id],
    }));
  };
  const filterData = (visitordata) => {
    if (!searchQuery) return visitordata;

    return visitordata.filter((visitor) => {
      for (const key in visitor) {
        if (Object.hasOwnProperty.call(visitor, key)) {
          const value = visitor[key];
          if (
            typeof value === "string" &&
            value.toLowerCase().includes(searchQuery.toLowerCase())
          ) {
            return true;
          }
        }
      }
      return false;
    });
  };
  const filteredData = filterData(visitordata);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <>
      <div
        style={{
          padding: "10px 20px 8px 20px",
          borderBottom: "1px solid var(--light-border-color)",
        }}
      >
        <div
          style={{
            display: "flex",
            color: "var( --light-text-color)",
            height: "40px",
            alignItems: "center",
          }}
        >
          <SearchIcon sx={{ marginRight: "5px" }} />
          <input
            style={{
              width: "100%",
              paddingLeft: "10px",
              border: "none",
              height: "25px",
              outline: "none",
            }}
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search (minimum of 3 characters)"
          ></input>
        </div>
      </div>
      <TableContainer sx={{ maxHeight: "92%" }}>
        <Table
          size="small"
          sx={{ minWidth: 300, overflowY: "auto" }}
          stickyHeader
          aria-label="sticky table"
        >
          <TableHead sx={{ borderBottom: "1px solid #E2E3E6", height: "54px" }}>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  backgroundColor: "var(--light-bg-img-color)",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  backgroundColor: "var(--light-bg-img-color)",
                }}
              >
                Visitors
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ cursor: "pointer" }}>
            {filteredData &&
              filteredData.map((value) => (
                <TableRow
                  className={`${Styles.ChatCheckbox} ${
                    location.pathname === `/chat/visitors/${value.id}`
                      ? Styles.ChatActiveselect
                      : ""
                  }`}
                  key={value.id}
                  sx={{
                    "&:hover": { backgroundColor: "var(--light-bg-img-color)" },
                  }}
                >
                  <TableCell sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Checkbox
                      className={
                        selected[value.id]
                          ? `${Styles.ChatCheckbox}`
                          : Styles.CNCheckbox
                      }
                      checked={selected[value.id] || false}
                      onChange={() => handleCheckboxChange(value.id)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      paddingLeft: "0",
                      display: "flex",
                      paddingTop: "10px",
                    }}
                    onClick={() => handleRoute(value.id)}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <AvatarStatus
                        src={value.avatar}
                        alt="User Avatar"
                        status={value.status}
                      />
                      <div style={{ flexDirection: "column", display: "flex" }}>
                        <span style={{ marginLeft: "10px" }}>
                          {value.Visitors}
                        </span>
                        <span style={{ marginLeft: "10px" }}>
                          {value.email}
                        </span>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default VisitorInfo;
