import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Button,
  Modal,
  Stack,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import EmailStyle from "../Style/EmailAcc.module.css";
import CloseIcon from "@material-ui/icons/Close";
import DeleteAccounts from "./DeleteAccount/DeleteAccount";
import RenewalConfirm from "../../Component/Renewal/Componets/RenewalConfirm";
import RenewalStyle from "../Style/Renewal.module.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "12px",
};

const EmailAccounts = ({
  open,
  handleClickOpen,
  handleClickClose,
  domainName,
  isFlexible,
  setSnackbarInfo,
  gsuieData,
}) => {
  let buyMore = {
    background: "#28bd8b",
    border: "none",
    borderRadius: "2px",
    color: "#fff",
    cursor: "pointer",
    fontFamily: "OpenSans",
    fontSize: "12px",
    fontWeight: "600",
    outline: "none",
    padding: "14px 50px",
    textTransform: "uppercase",
  };
  const [openDeleteAcc, setOpen] = useState(false);
  const handleClickDeleteOpen = () => {
    setOpen(true);
  };
  const handleClickDeleteClose = () => {
    setOpen(false);
  };
  const [scroll, setScroll] = React.useState("paper");

  return (
    <>
      <Modal
        open={open}
        scroll={scroll}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="_addcompanytitle_">
            Email Accounts
            <IconButton
              component="span"
              onClick={handleClickClose}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <Stack
            spacing={1}
            sx={{ padding: "20px 16px 8px 16px" }}
            width="100%"
            alignItems="center"
            height="100%"
          >
            <RenewalConfirm
              confirmationText={
                " Log into Admin Panel to manage these accounts for For"
              }
              domainName={domainName}
              isDelete={false}
            />
            <div className={RenewalStyle.middleWrapper}>
              <div className={EmailStyle.email_cell_align}>
                <div className={EmailStyle.email_purchased_cell}>
                  <div className={EmailStyle.email_table_title}>
                    PURCHASED ACCOUNTS
                  </div>
                  <div className={EmailStyle.email_table_count}>
                    {isFlexible
                      ? gsuieData?.seats?.maximumNumberOfSeats
                      : gsuieData?.seats?.numberOfSeats}
                  </div>
                </div>
                <div className={EmailStyle.email_cell_divider}></div>
                <div className={EmailStyle.email_delete_cell}>
                  <div className={EmailStyle.email_table_title}>
                    CURRENTLY IN USE
                  </div>
                  <div className={EmailStyle.email_table_count}>
                    {gsuieData?.seats?.licensedNumberOfSeats}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={RenewalStyle.price_wrapper}
              style={{ alignItems: "center" }}
            >
              <Button
                style={buyMore}
                onClick={() => {
                  handleClickClose();
                  handleClickOpen();
                }}
              >
                Buy More
              </Button>
              <div>
                <p
                  className={EmailStyle.email_delete_acc}
                  style={{
                    color: !gsuieData?.plan?.isCommitmentPlan
                      ? "rgba(197, 1, 1, 1);"
                      : "#c4ccd8",
                  }}
                  onClick={() => {
                    if (!gsuieData?.plan?.isCommitmentPlan) {
                      handleClickClose();
                      handleClickDeleteOpen();
                    }
                  }}
                >
                  Delete Account
                </p>
              </div>
            </div>
          </Stack>
        </Box>
      </Modal>
      <DeleteAccounts
        open={openDeleteAcc}
        handleClickClose={handleClickDeleteClose}
        licensedNumberOfSeats={gsuieData?.seats?.licensedNumberOfSeats}
        numberOfSeats={
          isFlexible
            ? gsuieData?.seats?.maximumNumberOfSeats
            : gsuieData?.seats?.numberOfSeats
        }
        domainName={domainName}
        customerId={gsuieData?.customerId}
        subscriptionId={gsuieData?.subscriptionId}
        setSnackbarInfo={setSnackbarInfo}
      />
    </>
  );
};

export default EmailAccounts;
