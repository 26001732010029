import {
  Modal,
  DialogContent,
  Typography,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import EmailStyle from "../../Style/EmailAcc.module.css";
import DeleteStyle from "../../Style/DeleteAcc.module.css";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { api_url1 } from "../../../../../globalSettings";
import { LoadingButton } from "@mui/lab";
import RenewalConfirm from "../../../Component/Renewal/Componets/RenewalConfirm";
import Style from "../../Style/Renewal.module.css";
import { NumberFormat } from "../../../CustomHook/DateFormat";

import { styled } from "@mui/material/styles";
import Note from "../../../Component/Renewal/Componets/Note";
import RenewalButton from "../../../Component/Renewal/Componets/RenewalButton";

const MuiTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    height: "32px",
    borderRadius: "5px",
    padding: "3px",
    "&:focus": {
      border: "1px solid rgba(217, 217, 217, 1)",
    },
    "&:hover": {
      "& + fieldset": {
        border: "1px solid rgba(217, 217, 217, 1)",
      },
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid rgba(217, 217, 217, 1)",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "12px",
};

const DeleteAccounts = ({
  open,
  handleClickClose,
  licensedNumberOfSeats,
  numberOfSeats,
  domainName,
  customerId,
  subscriptionId,
  setSnackbarInfo,
}) => {
  const [deleteCount, setDeleteCount] = useState(0);
  const [scroll, setScroll] = React.useState("paper");

  let deleteValid =
    Math.abs(numberOfSeats - licensedNumberOfSeats) > 0 ? true : false;
  let unused = Math.abs(numberOfSeats - licensedNumberOfSeats);
  let exceedDelete = unused - deleteCount < 0 ? false : true;
  const deleteButtonStyles = {
    background: deleteValid && exceedDelete ? "#0209fe" : "#e0e0e2",
    border: "none",
    borderRadius: "2px",
    color: deleteValid && exceedDelete ? "#fff" : "#a2a2a6",
    cursor: deleteValid && exceedDelete ? "not-allowed" : "pointer",
    fontFamily: "OpenSans",
    fontSize: "12px",
    fontWeight: 600,
    outline: "none",

    textTransform: "uppercase",
  };
  const handleDeleteCount = (event) => {
    let value = event ? parseInt(event) : "";
    setDeleteCount(value);
  };
  const [loadButton, setLoadButton] = useState(false);
  function deleteGsuiteAccount() {
    let obj = {
      customer_id: customerId,
      subscription_id: subscriptionId,
      numberofseats: deleteCount,
    };
    setLoadButton(true);

    axios
      .post(`${api_url1}/gsuite/deleteseats`, obj)
      .then((result) => {
        setSnackbarInfo({
          open: true,
          severity: "success",
          message:
            "Seats Deleted Successfully,It Will Take Few Minutes To Update",
        });
        handleClickClose();
      })
      .catch((err) => {})
      .finally(() => {
        setLoadButton(false);
      });
  }

  return (
    <Modal
      open={open}
      scroll={scroll}
      onClose={handleClickClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="scroll">
        <div className="_addcompanytitle_">
          Delete GSuite Account
          <IconButton
            component="span"
            onClick={handleClickClose}
            style={{ padding: 0, marginRight: 20 }}
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <Stack
          spacing={1}
          sx={{ padding: "20px 16px 8px 16px" }}
          width="100%"
          alignItems="center"
          height="100%"
        >
          <RenewalConfirm isDelete={true} domainName={domainName} />
          <div
            className={Style.middleWrapper}
            style={{ flexDirection: "column" }}
          >
            <div className={Style.deleteAlignement}>
              <div>
                <div className={Style.deleteTitle}>Purchased Accounts</div>
                <div className={Style.deleteCount}>
                  {NumberFormat(numberOfSeats)}
                </div>
              </div>
              <div className={EmailStyle.email_cell_divider}></div>
              <div className={EmailStyle.email_delete_cell}>
                <div className={Style.deleteTitle}>Currently In Use</div>{" "}
                <div className={Style.deleteCount}>
                  {NumberFormat(licensedNumberOfSeats)}
                </div>
              </div>
              <div className={EmailStyle.email_cell_divider}></div>
              <div className={EmailStyle.email_delete_cell}>
                <div className={Style.deleteTitle}>Unused Accounts</div>
                <div className={Style.deleteCount}>{NumberFormat(unused)}</div>
              </div>
            </div>
            <div className={DeleteStyle.delete_unused_wrapper}>
              <div className={Style.deleteTitle}>
                Unused accounts to be deleted
              </div>
              <div className={DeleteStyle.delete_unused_inputWrapper}>
                <MuiTextField
                  value={deleteCount}
                  onChange={(e) => {
                    handleDeleteCount(e.target.value);
                  }}
                  variant="outlined"
                  disabled={!deleteValid}
                />
              </div>
              {deleteValid ? (
                " "
              ) : (
                <div className={DeleteStyle.delete_unused_acc}>
                  You have no unused accounts to delete
                </div>
              )}
              {exceedDelete ? (
                " "
              ) : (
                <div className={DeleteStyle.delete_unused_acc}>
                  Invalid Count of unused accounts
                </div>
              )}
            </div>
          </div>
          <Note
            Text={
              "Login to Google Workspace Admin Panel to manage these accounts"
            }
          />
        </Stack>
        <RenewalButton
          disabled={!deleteValid}
          loading={loadButton}
          title={"DELETE"}
          clickFunc={deleteGsuiteAccount}
          deleteStyle={deleteButtonStyles}
        />
      </Box>
    </Modal>
  );
};

export default DeleteAccounts;
