import { Box, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchCloneTemplate from './SearchCloneTemplate';
import SearchIcon from "@material-ui/icons/Search";
import CloneTemplateBoard from './CloneTemplateBoard';
import { useState } from 'react';
import { useEffect } from 'react';
import CloseIcon from "@material-ui/icons/Close";

export default function ClonePipeline(props) {

    const [searchText, setSearchText] = useState("");
    const [selectedPipeline, setSelectedPipeline] = useState(null);
    const [showSearchField, setShowSearchField] = useState(false);
    const [isHovering, setIsHovering] = useState(false);

    let handleInputChange = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setSearchText(lowerCase);
    };

    const handlePipelineSelection = (pipeline) => {
        setSelectedPipeline(pipeline);
        if (!props.showTemplate) {
            props.handleClonePipelineSelection(pipeline);
        }
    }

    useEffect(() => {

    }, [props.showTemplate]);

    return (
        <>
            {props.showTemplate ?
                <Grid container spacing={2} sx={{ height: '100%', mt: 0, pl: 1 }} direction="row">
                    <Grid item xs={2} sx={{ height: "100vh", overflowY: "scroll", overflowX: "hidden", p: 0 }}>
                        <Typography component="div" variant="h7" sx={{ pb: 2, pl: 2, fontWeight: "bold" }}>Clone from a Template</Typography>
                        <div style={{ textAlign: 'center' }}>
                            <TextField
                                id="outlined-basic"
                                onChange={handleInputChange}
                                variant="outlined"
                                placeholder="Search"
                                type="search"
                                sx={{ width: "88%" }}
                                size="small"
                                InputProps={
                                    searchText === "" && {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon size="large" />
                                            </InputAdornment>
                                        )
                                    }}
                            />
                        </div>
                        <SearchCloneTemplate showTemplate={props.showTemplate} input={searchText} handleSelection={(pipeline) => setSelectedPipeline(pipeline)} />
                    </Grid>
                    <Grid item xs={10} sx={{ p: "0px !important" }}>
                        <CloneTemplateBoard selectedPipeline={selectedPipeline} />
                    </Grid>
                </Grid>
                : <Box onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                    <Stack direction="row" alignItems="center" sx={{ minHeight: "50px", height: "50px", width: "100%" }} spacing={1}>
                        <Stack direction="row" sx={{ width: "85%" }}>
                            <Typography component="div" sx={{ padding: 1, fontSize: '1rem', fontWeight: 600, lineHeight: 2.5 }}>
                                Clone from a Template
                            </Typography>
                        </Stack>
                        {!showSearchField &&
                            <Stack direction="row" alignItems="left" sx={{ visibility: isHovering ? 'inherit' : 'hidden', width: "15%" }}>
                                <IconButton sx={{ width: "30px", height: "30px" }} onClick={() => setShowSearchField(!showSearchField)}>
                                    <SearchIcon />
                                </IconButton>
                            </Stack>
                        }
                    </Stack>
                    {showSearchField && <div>
                        <Stack direction="row" sx={{ pr: 1, pl: 1 }}>
                            <TextField
                                id="outlined-basic"
                                onChange={handleInputChange}
                                variant="outlined"
                                placeholder="Search"
                                type="search"
                                size="small"
                                InputProps={
                                    searchText === "" && {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon size="medium" />
                                            </InputAdornment>
                                        )
                                    }}
                            />
                            <IconButton size="small" sx={{ float: 'right', width: "30px", height: "30px", ml: 0.5, mt: 0.5 }} onClick={() => { setShowSearchField(!showSearchField); setSearchText(""); }}>
                                <CloseIcon />
                            </IconButton>
                        </Stack>
                    </div>}
                    <SearchCloneTemplate showTemplate={props.showTemplate} input={searchText} removeSelection={props.removeSelection}
                        handleSelection={(pipeline) => handlePipelineSelection(pipeline)} />
                </Box>
            }
        </>
    );
}