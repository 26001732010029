import NavBar from "../components/dashboard/NavBar";
import GlobalDashBoard from "../GlobalDashBoard";
import { isLogin } from "../auth";
import { Navigate, Route, Routes } from "react-router-dom";
import Trackey from "../containers/Trackey";
// import AppStudio from "../containers/AppStudio";
// import ManageCompany from "../components/manageCompany/ManageCompany";
// import ManageEmployee from "../components/manageEmployee/ManageEmployee";
// import Crmsettings from "../components/crm/Crmsettings";
// import EditEmailAccount from "../components/crm/Tabs/EditEmailAccount";
// import ManageAttendence from "../components/manageattendence/ManageAttendence";
// import ManageHistory from "../components/manageattendence/ManageHistory";
//import MarketingStudio from "../containers/MarketingStudio";
// import Email from "../Email/Email";
// import EmailWarmupReport from "../Email/Dashboard/Sequence/EmailWarmupReport";
import Contacts from "../containers/Contacts";
import MyAccount from "../components/User/MyAccount";
import MainSideBar from "../components/dashboard/MainSideBar";
import MySettings from "../components/ManageSettings/MySettings";
import Pipeline from "../apps/crm/pages/pipelines/view/Pipeline";
import Activities from "../apps/crm/pages/activities/Activities";
import ActivityDetailedView from "../apps/crm/pages/activities/view/table/ActivityDetailedView";
import DealDetailedView from "../apps/crm/pages/pipelines/view/DealDetailedView";
import Loadable from "./Loadable";
import { lazy } from "react";
import CrmContacts from "../apps/crm/pages/contacts/CrmContacts";
import ContactDetailedView from "../apps/crm/pages/contacts/view/ContactDetailedView";
import CrmCompanies from "../apps/crm/pages/companies/CrmCompanies";
import CompaniesDetailedView from "../apps/crm/pages/companies/view/CompaniesDetailedView";
import CrmProducts from "../apps/crm/pages/products/CrmProducts";
import ProductDetailedView from "../apps/crm/pages/products/view/ProductDetailedView";
import UserControls from "../apps/crm/pages/settings/usercontrols/UserControls";
import FieldsSetting from "../apps/crm/pages/settings/fields/Fields";
import StagesSetting from "../apps/crm/pages/settings/stages/Stages";

import ChatIndex from "../chats";
import TokenAuth from "../components/Redirect/TokenAuth";
//import Invoiceprint from "../components/User/invoice/Invoiceprint";
// import MainLayout from "../components/NDEMail/components/Layout";
const PrivateRoute = () => {
  const Webservices = Loadable(lazy(() => import("../components/webservices")));
  const Peoplesnow = Loadable(lazy(() => import("../PeoplesNow")));
  const Ndemail = Loadable(lazy(() => import("../components/NDEMail12/Mail")));
  const MarketingStudio = Loadable(
    lazy(() => import("../MarketingStudio/index"))
  );
  const userToken = isLogin();
  // const [websiteId, setWebsiteId] = useState("");

  if (!userToken) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div id="home">
        <div className="home-nav-bar">
          <NavBar />
        </div>
        <div>
          <div>
            <MainSideBar />
          </div>
          <div className="home-body">
            <Routes>
              <Route path="/dashboard/*" element={<GlobalDashBoard />} />
              <Route path="*" element={<Navigate to={"/dashboard"} />} />
              <Route path="/webservices/*" element={<Webservices />} />
              <Route path="settings/*" element={null} />
              <Route path="settings/*" element={null} />
              {/* <Route path="appstudio" element={<AppStudio />} /> */}

              <Route path="trackeye" element={<Trackey />} />
              <Route path="trackeye/attendence" element={<Trackey />} />
              <Route path="trackeye/attendence/report" element={<Trackey />} />
              <Route path="trackeye/workhours" element={<Trackey />} />
              <Route path="trackeye/workhours/report" element={<Trackey />} />
              <Route path="trackeye/emptask" element={<Trackey />} />
              <Route path="trackeye/emptask/report" element={<Trackey />} />
              <Route path="trackeye/empPerformance" element={<Trackey />} />
              <Route
                path="trackeye/empPerformance/report"
                element={<Trackey />}
              />
              <Route path="trackeye/empExpense" element={<Trackey />} />
              <Route path="trackeye/empExpense/report" element={<Trackey />} />
              <Route path="/trackeye/TrackeyeDashboard" element={<Trackey />} />
              <Route path="trackeye/leaveapproval" element={<Trackey />} />

              <Route path="peoplesnow/*" element={<Peoplesnow />} />

              <Route path="contacts" element={<Contacts />} />
              <Route path="/token-auth/:jwt" element={<TokenAuth />} />

              <Route path="contacts/dashboard" element={<Contacts />} />
              <Route path="contacts/contact" element={<Contacts />} />
              <Route path="contacts/contactDetails" element={<Contacts />} />
              <Route path="contacts/crmtask" element={<Contacts />} />
              <Route path="contacts/report" element={<Contacts />} />
              <Route path="contacts/forms" element={<Contacts />} />
              <Route path="contacts/Fields" element={<Contacts />} />
              <Route path="contacts/customizeFields" element={<Contacts />} />

              <Route path="mySettings/chat" element={<MySettings />} />
              <Route path="/chat/*" element={<ChatIndex />} />

              <Route path="/myaccount/profile" element={<MyAccount />} />
              <Route path="/myaccount/billing" element={<MyAccount />} />

              <Route path="crm/pipeline" element={"<Pipeline />"} />
              <Route
                path="crm/pipeline/:view/:pipelineId/:subpipelineId/:id"
                element={<DealDetailedView />}
              />
              <Route path="crm/activities" element={<Activities />} />
              <Route
                path="crm/activities/:module/:id"
                element={<ActivityDetailedView />}
              />
              <Route path="crm/contacts" element={<CrmContacts />} />
              <Route
                path="crm/contacts/:id"
                element={<ContactDetailedView />}
              />
              <Route path="crm/companies" element={<CrmCompanies />} />
              <Route
                path="crm/companies/:id"
                element={<CompaniesDetailedView />}
              />
              <Route path="crm/products" element={<CrmProducts />} />
              <Route
                path="crm/products/:id"
                element={<ProductDetailedView />}
              />
              <Route path="crm/settings" element={<UserControls />} />
              <Route
                path="crm/settings/usercontrols/:module"
                element={<UserControls />}
              />
              <Route
                path="crm/settings/fields/:module"
                element={<FieldsSetting />}
              />
              <Route
                path="crm/settings/stages/:module"
                element={<StagesSetting />}
              />

              <Route path="markettingstudio/*" element={<MarketingStudio />} />
              <Route path="/mail/*" element={<Ndemail />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivateRoute;
