import React, { useState, useRef, useEffect } from "react";
import {
  autoUpdate,
  useFloating,
  useClick,
  useDismiss,
  offset,
  flip,
  size,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import { ClickAwayListener, MenuList, Paper, Popper } from "@mui/material";
import { MenuItem, Grow } from "@mui/material";
import "./DashBoard.css";
import CachedIcon from "@mui/icons-material/Cached";

import "./DashBoard.css";

import IconButton from "@mui/material/IconButton";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PublicIcon from "@mui/icons-material/Public";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LockIcon from "@mui/icons-material/Lock";
import LanguageIcon from "@mui/icons-material/Language";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ClearIcon from "@material-ui/icons/Clear";
import { useTheme } from "@mui/material/styles";
const OFFSET = 10;


const options = [
  {
    value: "Susan",
    label: "Susan",
  },
  {
    value: "Rahul",
    label: "Rahul",
  },
  {
    value: "Kamla",
    label: "Kamla",
  },
  {
    value: "ArunKumar",
    label: "ArunKumar",
  },
  {
    value: "Prakash",
    label: "Prakash",
  },
  {
    value: "Kandakumar",
    label: "Knadakumar",
  },
  {
    value: "Raja",
    label: "Raja",
  },

  {
    value: "Rolex",
    label: "Rolex",
  },
];

const CustomButtons = styled(Button)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
  font-size: 0.675rem;
 
  background-color:#1F7A1F;

 
  border-radius: 18px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;


  &.focusVisible {
   
    outline: none;
  }
  &:hover {
    background-color: #1F7A1F;
  }

  &.disabled {
   
    transform: scale(1);
  }
`
);

function Dashboard2() {
  const theme = useTheme();
const useStyles = makeStyles(() => ({
  DropDownButton: {
    "&.MuiButtonBase-root": {
      "&.MuiButton-root": {
        fontWeight: 800,
        fontSize: "14px",
        textTransform: "none",
      },
    },

    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    cursor: "pointer",
    bottom: "5px",
    left: "50px",
    color: "#424242",
  },

  dashboardSelectMenu: {
    backGroundColor: "#fff",
    border: "2px solid",
    borderColor: "#67193",
    "&MuiPaper-root": {
      "&-MuiMenu-paper": {
        boxShadow: 0,
        backgroundColor: "none",
      },
    },

    "& .MuiPopover-paper": {
      minWidth: "220px",
      maxWidth: "fit-content",
      height: "150px",
    },
    "&MuiMenu-paper": {
      overflowy: "hidden",
      overflowx: "hidden",
      boxShadow: "none",
    },
  },
  searchBarContainer: {
    position: "absolute",
    minWidth: "inherit",
    display: "flex",
    justifyContent: "space-evenly",
    borderRadius: "20px",
    padding: 0,
    top: "15px",
    height: "10px",
    cursor: "default",
    "&.MuiListItem-button": {
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "& MuiButtonBase-root": {
      height: "40px",
    },
  },
  search: {
    borderRadius: "4px",

    marginLeft: 0,
    width: "220px",
    border: "1px solid grey",
    backgroundColor: "none",
    position: "relative",

    height: "32px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "50%",
    left: "170px",
    top: "8px",
    fontSize: "10px",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  clearIcon: {
    cursor: "pointer",
    // marginRight: theme.spacing(1),
    marginRight: "10px",
    color: "#555",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    left: "10px",
    "&MuiInputBase-root": {
      left: "20px",
    },
  },
  menuDivider: {
    margin: "0 20px",
  },

  inputInput: {
    // padding: theme.spacing(1, 1, 1, 0),
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    height: "40px",
    left: "10px",
    width: "100%",
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  fontSize: {
    height: "0.8rem",
    borderRadius: 20,
    border: "2px solid #fff",
    width: "150px",
    textAlign: "center",
    fontSize: "10px",
    color: "#424242",

    outline: "none",

    cursor: "pointer",
    "& label.Mui-focused": {
      color: "antiquewhite",
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "antiquewhite",
        borderRadius: "20px",
      },
      "& input": {
        fontWeight: 600,
        textAlign: "center",
        fontSize: "15px",
      },
    },

    // '&MuiInputBase-root': {
    //   '.MuiOutlinedInput-root': {
    //     '& fieldset': {
    //       borderColor: 'antiquewhite',
    //       color: 'antiquewhite',
    //     },
    //     input: {
    //       fontSize: '50px',
    //       fontWeight: 600,
    //     },
    //   },
    // },
    menu: {},
  },

  textfield: {
    "& .MuiPaper-root": {
      "& .MuiPopover-paper": {
        boxShadow: "none",
        backgroundColor: "none",
      },
    },
  },

  // inputRoot: {
  //   color: 'inherit',
  //   width: '100%',
  // },
  // inputInput: {
  //   padding: theme.spacing(1, 1, 1, 0),
  //   // vertical padding + font size from searchIcon
  //   paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  //   transition: theme.transitions.create('width'),
  //   width: '100%',
  //   [theme.breakpoints.up('md')]: {
  //     width: '20ch',
  //   },
  // },
  menu: {
    // '&:hover': {
    //   backgroundColor: '#fff',
    // },
    // '&.Mui-focusVisible': {
    //   backgroundColor: '#fff',
    // },
  },
  totalFilter: {
    fontSize: "2rem",
    color: theme.palette.common.orange,
  },
  dollarSign: {
    fontSize: "1.5rem",
    color: theme.palette.common.orange,
  },
}));
  const [value, setValue] = useState("today");
  const [showDetails, setShowDetails] = useState(false);
  const [data1, setData1] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [openUser, setOpenUser] = React.useState(false);
  const anchorRef = React.useRef(null);


  const [editOptionIndex, setEditOptionIndex] = useState(null);
  const [editedOption, setEditedOption] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [show, setShow] = useState(false);
  const [hideDashboard, setHideDashboard] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState("Alluser");
  const [searchText, setSearchText] = useState("");

  const inputRef = useRef(null);
  const classes = useStyles();

  const [selection, setSelection] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context, strategy } = useFloating({
    placement: "bottom-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementMounted: autoUpdate,
    middleware: [
      offset(OFFSET),
      flip(),
      size({
        apply: ({ availableHeight, elements }) => {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.max(50, availableHeight - OFFSET)}px`,
          });
        },
      }),
    ],
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const handleOptionClick = (optionLabel) => {
    setSelection(optionLabel);
    setIsOpen(false);
    handleClose();
  };

  useEffect(() => {
    setSelection(options[0].label);
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.current.Target);
    setOpen(!open);
  };
  const handleClose = () => {
    // if (e.target.innerText !== selection && e.target.innerText !== '') {
    //   setSelection(e.target.innerText);
    // }
    // setSearchText('');
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    const inputText = event.target.value;
    setSearchText(inputText);
    setIsInputEmpty(inputText === "");
  };
  const handleSearch = (event) => {
    setIsInputEmpty(false);
  };

  const handleInputChange = (event) => {
    setEditedOption(event.target.value);
  };

  const handleEditStart = () => {
    setIsEditing(true);
  };

  const handleEditEnd = () => {
    setIsEditing(false);
  };

  // const handleClose = () => {
  //   setAnchorEl(null);

  //   setOpen(false);
  // };

  const clearSearch = () => {
    setSearchText("");
    setIsInputEmpty(true);
  };
  const handleEditOption = (index) => {
    setEditOptionIndex(index);
    setEditedOption(options[index]);
    setEditMode(true);
    handleClose();
    setIsEditing(false);
  };

  const handleToggleUserMenu = () => {
    setOpenUser((prevOpen) => !prevOpen);
  };

  const handleCloseUser = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenUser(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenUser(false);
    } else if (event.key === "Escape") {
      setOpenUser(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openUser);
  React.useEffect(() => {
    if (prevOpen.current === true && openUser === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = openUser;
  }, [openUser]);

  const MemoizedIconButton = React.memo(({ onClick }) => (
    <IconButton
      onClick={onClick}
      style={{ color: "#9e9e9e", padding: 0, marginLeft: "50px" }}
    >
      <CachedIcon style={{ fontSize: "20px" }} />
    </IconButton>
  ));
  const handleOptionChange = (event) => {
    setSelection(event.target.value);
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  const showDetailsHandle = (dayStr) => {
    setData1(dayStr);
    setShowDetails(true);
  };
  const handleToggleDashboard = () => {
    setHideDashboard(!hideDashboard);
    setIsEditing(true);
  };
  const handleCancel = () => {
    setEditMode(false);
    handleClose();
    handleToggleDashboard();
  };
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    // const updatedOptions = [...options];
    // updatedOptions[editOptionIndex] = editedOption;

    setSelection(selection);
    setEditMode(false);
    handleToggleDashboard();
    setIsEditing(false);
    handleClose();
  };
  const handleShow = () => {
    setShow(!show);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSearchValueChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const saveOptions = () => {
    // Here you can implement saving options to your backend or local storage
    handleClose(); // Close the menu after saving
  };

  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item);
    setOpenUser(false);
  };
  const displayOptions = options
    .map((item) => {
      if (item.label.toLowerCase().includes(searchText.toLowerCase())) {
        return item;
      }
      return undefined;
    })
    .filter((item) => item !== undefined);

  function renderOption(value) {
    if (selection === value) {
      return <div className={classes.checkedItem}>{value}</div>;
    }
    return value;
  }

  // const handleSearchChange = (e) => {
  //   setSearchText(e.target.value);
  // };
  //===================================================== API CALL ========================================================

  return (
    <div className="dashBoard20">
      <div
        className={`navbar-dashboard${hideDashboard ? "hide-dashboard" : ""}`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <div className="navbarleft">
          <Button
            style={{
              border: "1px solid",
              borderRadius: "15px",
              borderColor: "#333333",
              padding: "6px 15px",
              alignItems: "center",
              justifyContent: "center",
              height: "30px",
              position: "absolute",
              left: "20px",
              top: "13px",
            }}
            type="button"
            disableRipple
            disableTouchRipple
            onFocusVisible={false}
            className={classes.DropDownButton}
            {...getReferenceProps({ ref: refs.setReference })}
          >
            {selection}
            <KeyboardArrowDownIcon />
          </Button>

          <LanguageIcon
            style={{
              position: "absolute",
              left: "210px",
              top: "17px",
              fontSize: "25px",
              color: "GrayText",
            }}
          />
        </div>
        {isOpen && (  
          <FloatingPortal>
            <div
              className="dropdown-container"
              {...getFloatingProps({
                ref: refs.setFloating,
                style: { ...floatingStyles, position: strategy },
              })}
            >
              <p className="header">
                {" "}
                <div className={classes.search}>
                  {isInputEmpty && (
                    <div className={classes.searchIcon}>
                      <SearchIcon style={{ fontSize: "20px" }} />
                    </div>
                  )}
                  <InputBase
                    placeholder="Search..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleSearchChange}
                    value={searchText}
                    endAdornment={
                      searchText && (
                        <ClearIcon
                          className={classes.clearIcon}
                          onClick={clearSearch}
                        />
                      )
                    }
                  />
                </div>
              </p>
              <div className="bodycontainer">
                <ul
                  style={{ marginBottom: "0px", paddingLeft: "0px" }}
                  onClick={handleClose}
                >
                  {displayOptions.map((item, index) => (
                    <div
                      className="dropdownitemshover"
                      key={index}
                      onClick={handleClose}
                      style={{
                        height: "40px",
                        width: "236px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <div
                        style={{ position: "relative", left: "10px" }}
                        onMouseLeave={handleClose}
                        onClick={() => handleOptionClick(item.label)}
                      >
                        {renderOption(item.label)}{" "}
                      </div>
                    </div>
                  ))}
                </ul>
              </div>
              <div className="footer">
                {" "}
                <AddIcon style={{ top: "-2px", position: "relative" }} />{" "}
                <span> New DashBoard </span>{" "}
              </div>
            </div>
          </FloatingPortal>
        )}
        {/* <div key={index} style={{ height: '40px' }}>
                    <Link
                      to={`/option/${item.label}`}
                      onClick={() => handleOptionClick(item.label)}
                    >
                      <MenuItem onClick={handleClose}>
                        {renderOption(item.label)}
                      </MenuItem>
                    </Link>
                    <Divider className={classes.menuDivider} />
                  </div> */}
        {/* <Popover
          open={editMode}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{
            color: '#212121',
            // border: '1px solid #9e9e9e',
            borderRadius: '15px',
            left: '35px',
            position: 'absolute',
            fontWeight: 700,
            fontSize: '0.8rem',
            textTransform: 'none',

            top: '35px',
            height: '120px',
            // padding: 0,
            // paddingLeft: '7px',
            // paddingRight: '7px',
            // height: '80px',
            // width: '40rem',
            // justifyContent: 'space-between',
          }}
          className={classes.textfield}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginRight: '5px',
              marginTop: '5px',
              boxShadow: 'none',
              borderRadius: '10px',
              border: 'none',
            }}
          >
            <TextField
              variant="outlined"
              value={editedOption}
              onChange={handleOptionChange}
              fullWidth
              style={{
                marginRight: '7px',
                height: '50px',
                background: 'none',
                borderRadius: '25px',
                boxShadow: 'none',
                width: '150px',
                marginBottom: '10px',
              }}
            />
            <div
              style={{
                marginTop: '16px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Button
                onClick={handleSave}
                variant="contained"
                style={{
                  marginRight: '8px',
                  backgroundColor: '#357a38',
                  borderRadius: '20px',
                  fontSize: '15px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                }}
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                variant="outlined"
                style={{
                  fontSize: '13px',
                  fontWeight: 'lighter',
                  color: 'black',
                  borderRadius: '20px',
                  border: '1px solid #9e9e9e',
                  textTransform: 'none',
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Popover> */}
        <div
          className="toggle"
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          {!hideDashboard && (
            <>
              <input
                className="dashboardinputedit"
                value={selection}
                onClick={handleEditStart}
                onChange={handleOptionChange}
                onBlur={handleEditEnd}
                autoFocus
              />

              <div className="usermenu">
                <Button
                  style={{
                    verticalAlign: "center",
                    width: "100%",
                    height: "33px",
                    left: "310px",
                    borderRadius: "16px",
                  }}
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={openUser ? "composition-menu" : undefined}
                  aria-expanded={openUser ? "true" : undefined}
                  aria-haspopup="true"
                  variant="outlined"
                  onClick={handleToggleUserMenu}
                >
                  {" "}
                  <span
                    style={{
                      fontSize: "11px",
                      fontWeight: "800",
                      textAlign: "center",
                      alignItems: "end",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {selectedMenuItem === "Alluser" ? (
                      <PublicIcon />
                    ) : selectedMenuItem === "Only Me" ? (
                      <LockIcon />
                    ) : (
                      <EditNoteIcon />
                    )}

                    {selectedMenuItem}
                  </span>
                  <span
                    style={{
                      alignItems: "end",
                      justifyContent: "center",
                      display: "flex",
                      textAlign: "center",
                      paddingTop: "5px",
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </span>
                </Button>
                <Popper
                  open={openUser}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseUser}>
                          <MenuList
                            style={{ padding: "10px" }}
                            autoFocusItem={openUser}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={() => handleMenuItemClick("Alluser")}
                            >
                              <PublicIcon /> Alluser
                            </MenuItem>

                            <MenuItem
                              onClick={() => handleMenuItemClick("Only Me")}
                            >
                              <LockIcon /> Only Me
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleMenuItemClick("Custom")}
                            >
                              <EditNoteIcon /> Custom
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  onClick={handleSave}
                  variant="contained"
                  style={{
                    position: "absolute",

                    marginRight: "8px",
                    backgroundColor: "#357a38",
                    borderRadius: "20px",
                    fontSize: "0.8rem",
                    fontWeight: "300",
                    textTransform: "none",
                    top: "12px",
                    left: "85%",
                    height: "32px",
                  }}
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancel}
                  variant="outlined"
                  style={{
                    position: "absolute",
                    fontSize: "0.8rem",
                    fontWeight: "lighter",
                    color: "black",
                    borderRadius: "20px",
                    border: "1px solid #9e9e9e",
                    textTransform: "none",
                    top: "12px",
                    left: "91%",
                    height: "32px",
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}

          {isHovering && hideDashboard && (
            <IconButton
              onClick={handleToggleDashboard}
              style={{
                color: "#9e9e9e",
                padding: 0,
                fontSize: "17px",
                position: "fixed",
                left: "310px",
                top: "65px",
              }}
            >
              <span>Edit</span>
            </IconButton>
          )}
        </div>

        <div className="refresh-icon">
          <Button
            onClick={handleRefresh}
            style={{ color: "#9e9e9e", padding: 0 }}
          >
            {" "}
            <CachedIcon style={{ fontSize: "20px" }} />{" "}
          </Button>
          <CustomButtons
            onClick={() => handleShow()}
            variant="contained"
            style={{ height: "35px" }}
          >
            {" "}
            <AddIcon style={{ fontSize: "0.8rem" }} /> Componet
          </CustomButtons>
          <MoreHorizIcon />
        </div>
      </div>

      {/* <div className="dashBoardBox">
        <div className="dashBoardBoxTitle">
          <Typography style={{fontWeight:"600"}}>Key Indicator</Typography>
        </div>
      <Grid container spacing={4}>
      {DashBoardData.map((item) => {return(
         <Grid item xs={6} sm={4} md={2}>
         <Box
      sx={{
        borderRadius:"5px",
        width: "100%",
        height: 100,
        padding:2,
        backgroundColor: item.backGroundColor,
        color:item.color
       
      }}
    >
         <Typography style={{ fontWeight:"600" , fontSize:"12px"}}>{item.title}</Typography>
         <Typography style={{marginTop : "10px" , fontWeight:"900" , fontSize:"25px"}}>{item.data}</Typography>
         </Box>
        </Grid>)}
      )}      
      </Grid>
      </div>
      <div className="dashBoardBox">
      <Grid container spacing={4}>
      <Grid item xs={12} md={7} lg={8}>
                <MainCard  > 
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h6">Sales Report</Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            id="standard-select-currency"
                            size="small"
                            select
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            sx={{ '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' } }}
                        >
                            {status.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <hr></hr>
                    <Stack spacing={1.5} sx={{ mb: -12 }}>
                        <Typography variant="h6" color="secondary">
                            Net Profit
                        </Typography>
                        <Typography variant="h6">$1560</Typography>
                    </Stack>
                    <SalesColumnCharts />
                </MainCard>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
        <Box
        sx={{
        borderRadius:"5px",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
       
      }}
    > 
        <Stack
             direction="row"
             padding="16px"
             paddingBottom={0}
             alignItems="center"
             width="100%"
           >
           <Stack direction="row"  width="10%" alignItems="center" justifyContent={"center"} spacing={1}>
<CalendarMonthIcon style={{color:"#54c0eb"}}/>
            </Stack>
             
             
             <Stack direction="row"  width="80%" alignItems="flex-start" flexDirection={"column"} spacing={1}>
              <Typography style={{ fontSize: 15 ,fontWeight : 600 }} variant="body2">
                
                 Calendar
               </Typography> 
               </Stack>
               </Stack>
        
     
       <hr/>
      <DashBoardCalender showDetailsHandle={showDetailsHandle} />
      <br />
      {showDetails && <Details data={data1} />}
         </Box>
        </Grid>  
        </Grid>
      </div>

      <div className="dashBoardBox">
      
      <Grid container spacing={4}>
    
       <Grid item xs={12} sm={6} md={8}>
        <Box
        sx={{
        borderRadius:"5px",
        width: "100%",
        height: "100%",
        padding:"10px",
        backgroundColor: "white",
       
      }}
    > 
   
   
      <Calendar />
    
         </Box>
        

         
         
        </Grid>  
       
        <Grid item xs={12} sm={6} md={4}>
        <Box
        sx={{
        borderRadius:"5px",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
      
      }}
    > 
        <Stack
             direction="row"
             padding="16px"
             paddingBottom={0}
             alignItems="center"
             height="auto"
             width="100%"
             
           >
           <Stack direction="row"  width="10%" alignItems="center" justifyContent={"center"} spacing={1}>
           <img src={email} alt="email" width={25} height={25} style={{marginRight : "10px"}}/>
            </Stack>
             
             
             <Stack direction="row"  width="80%" alignItems="flex-start" flexDirection={"column"} spacing={1}>
              <Typography style={{ fontSize: 15 ,fontWeight : 600 }} variant="body2">
              
                 SuccessFul Case
               </Typography> 
               </Stack>
               </Stack>
        
         <hr/>
       
       
         {DashTableData.map((item) => {return(
          <>
             <Stack
             direction="row"
             alignItems="center"
             height="auto"
             width="100%"
             paddingLeft="16px"
           >
           <Stack direction="row"  width="10%" alignItems="flex-start" justifyContent={"center"} spacing={1}>
           <img src={email1} alt="email" width={20} height={20} style={{marginRight : "10px"}}/>
             </Stack>
             <Stack direction="row"  width="60%" alignItems="flex-start" flexDirection={"column"} spacing={1}>
              <Typography style={{ fontSize: 12 ,fontWeight : 600 }} variant="body2">
                 {item.title}
               </Typography> 
               <Typography style={{ fontSize: 12 ,fontWeight : 600 , margin :0 }} variant="body2">
                 {item.subTitle}
               </Typography> 
             </Stack>
             <Stack direction="row"  width="20%" alignItems="flex-start" justifyContent={"flex-end"} spacing={1}>
              <Typography style={{ fontSize: 12 ,fontWeight : 600 ,color :"#54c0eb" }} variant="body2">
                 {item.date}
               </Typography> 
             </Stack>
             
             
             </Stack>
             <hr/>  
             </>
        )}
      )} 
       
         </Box>
        </Grid>   
      </Grid>
      </div> */}
    </div>
  );
}

export default Dashboard2;
