import { Box, Paper, Grid } from "@mui/material";
import React, { useState } from "react";
import Settings from "../img/settings.png";
import Ip from "../img/ip-address.png";
import NameServer from "../img/domain.png";
import Button from "@mui/material/Button";
import styled from "styled-components";
import ServerImg from "../../../images/DomainDetails/servers.png";
import RenewalError from "../Component/RenewalError";
import axios from "axios";
import { api_url1 } from "../../../globalSettings";
import { useQuery } from "@tanstack/react-query";
import HostingRenewal from "./Components/Renewal";
import { gstCalculation } from "../Gsuit/HelperFunctions/helperFunction";
import ChangeDomain from "./Components/ChangeDomain";
import {
  customOutlineButtonStyle,
  customDisabledButtonStyle,
} from "../Style/DomainStyles";
import hostingStyle from "../Gsuit/Style/Gsuite.module.css";
import TopBar from "../Component/TopBar";
import Nameserver from "./Components/Nameserver";
import { CircularProgress, IconButton } from "@mui/material";

import { plesknameserverpopup } from "../../../Atoms/warningpopup";
import { useRecoilState } from "recoil";
import ErrorInfoo from "../Component/ErrorInfoo";
import "./Style/hostingStyle.css";
const Typography1 = styled.div`
  font-size: 12px;
  color: #0d6efd;
  cursor: pointer;
`;

function ManageHosting({
  domainName,
  productId,
  setSnackbarInfo,
  id,
  Selecteddomain,
}) {
  const imgStyle = {
    width: "30px",
    height: "30px",
  };

  const inputStyle = {
    width: "130px",
    marginBottom: "10px",
    paddingLeft: "4px",
  };
  const [hostingRenewalPrice, setHostingRenewalPrice] = useState({});
  const [nameserverdialog, setnameserverdialog] =
    useRecoilState(plesknameserverpopup);
  const [nameserver, setnameserver] = useState([]);
  const [upgradePlann, setUpgradePlan] = useState("");
  const [upgradeDuration, setUpgradeDuration] = useState("");
  const [upgradeGst, setUpgradeGst] = useState();
  const [changePlan, setChangePlan] = useState();
  const [changeDuration, setChangeDuration] = useState();
  const [loading, setLoading] = useState(false);
  const handleButtonClick = () => {
    window.open(pleskUrl.stdout, "_blank");
  };
  const [open, setOpen] = useState(false);
  const [openChange, setOpenChange] = useState(false);
  const [PriceData, setPriceData] = useState();
  const [gst, setGst] = useState();
  const [planValue, setplanValue] = useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseChange = () => {
    setOpenChange(false);
  };

  const { data: pleskData, isLoading: isPleskLoading } = useQuery({
    queryKey: ["pleskDetails", Selecteddomain.pleskid],
    queryFn: async () => {
      const result = await axios.get(
        `${api_url1}/plesk/domain-details/${Selecteddomain.pleskid}`
      );
      return result.data;
    },
    enabled: Selecteddomain.pleskEnable,
  });

  const { data: pleskUrl } = useQuery({
    queryKey: [
      "pleskDetails",
      Selecteddomain.pleskid,
      Selecteddomain?.domainName,
    ],
    queryFn: async () => {
      const result = await axios.get(
        `${api_url1}/plesk/cpannel/${Selecteddomain.pleskid}`
      );
      return result.data;
    },
    enabled: Selecteddomain.pleskEnable,
  });

  function fetchHostingPrice() {
    setLoading(true);
    let obj = {
      id: id,
    };
    const queryParams = new URLSearchParams(obj).toString();
    axios
      .get(`${api_url1}/product/plesk/renewal?${queryParams}`)
      .then((result) => {
        setplanValue(result.data.defaultPlanName);
        setHostingRenewalPrice(result.data);
        setPriceData(result.data.defaultPrice);
        setGst(gstCalculation(result.data.defaultPrice, result.data.userData));
        handleOpen();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  function upgradePlan() {
    let obj = {
      productId: productId,
      _id: id,
    };

    const queryParams = new URLSearchParams(obj).toString();
    axios
      .get(`${api_url1}/product/hosting/plans/upgrade?${queryParams}`)
      .then((result) => {
        setUpgradePlan(result.data.default.details._id);
        setChangePlan(result.data.default.details);
        setUpgradeDuration(result.data.default.details.period);

        setUpgradeGst(
          gstCalculation(
            result.data.default.details.price,
            result.data.userData
          )
        );
        setChangeDuration(result.data);
        setOpenChange(true);
      });
  }

  const onclickFun = (nameservers) => (e) => {
    setnameserverdialog(true);
    let arrayns = nameservers.split(",");
    setnameserver(arrayns);
  };
  const isSuspended =
    pleskData?.status.toLowerCase() === "suspend" ? true : false;
  return (
    <div style={{ marginTop: "14px" }}>
      <TopBar
        imgSrc={ServerImg}
        domainname={"Web Hosting"}
        planName={pleskData?.planName}
        purchasedDate={pleskData?.purchaseDate}
        expiryDate={pleskData?.renewalDate}
        loading={loading}
        handleClick={fetchHostingPrice}
        isDisable={false}
        isLoading={isPleskLoading}
      />
      {isSuspended && (
        <ErrorInfoo
          error={"Account Has Been Suspended"}
          reason={"Renew Your Account To Continue!"}
        />
      )}
      <div style={{ display: "grid" }}>
        {isSuspended && (
          <RenewalError
            height={"237.67px"}
            width={"100px"}
            overlap={"-318px"}
            handleClick={fetchHostingPrice}
            type={"Hosting"}
          />
        )}
        {isPleskLoading ? (
          <div className="progress_hosting">
            <CircularProgress />
          </div>
        ) : (
          <Paper style={{ padding: "20px" }}>
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              ></div>
              <Box sx={{ height: "auto" }}>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ display: "flex", padding: "20px" }}
                    >
                      <div className="manageHosting">
                        <img
                          src={Settings}
                          alt="SettingsIcon"
                          style={{ ...imgStyle, marginRight: "10px" }}
                        />
                      </div>
                      <div>
                        <div>
                          <p className={hostingStyle.manageGsuite_Title}>
                            MANAGE WEBHOSTING
                          </p>
                        </div>

                        <Button
                          variant="outlined"
                          style={{ marginBottom: "10px" }}
                          sx={
                            isSuspended
                              ? customDisabledButtonStyle
                              : customOutlineButtonStyle
                          }
                          onClick={() => {
                            handleButtonClick();
                          }}
                          disabled={isSuspended}
                        >
                          Go To Panel
                        </Button>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ display: "flex", padding: "20px" }}
                    >
                      <div>
                        <img
                          src={Ip}
                          alt="Ipicon"
                          style={{ ...imgStyle, marginRight: "10px" }}
                        />
                      </div>
                      <div>
                        <div>
                          <p className={hostingStyle.manageGsuite_Title}>
                            DEDICATED IPs
                          </p>
                        </div>
                        <input
                          disabled
                          value={pleskData?.ipaddress}
                          style={inputStyle}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ display: "flex", padding: "20px" }}
                    >
                      <div>
                        <img
                          src={NameServer}
                          alt="SettingsIcon"
                          style={{ ...imgStyle, marginRight: "10px" }}
                        />
                      </div>
                      <div>
                        <div>
                          <p className={hostingStyle.manageGsuite_Title}>
                            NAME SERVERS
                          </p>
                        </div>
                        <IconButton
                          disableRipple
                          onClick={onclickFun(pleskData?.nameServers)}
                        >
                          <Typography1>View Details</Typography1>
                        </IconButton>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ display: "flex", padding: "20px" }}
                    >
                      <div>
                        <img
                          src={Settings}
                          alt="SettingsIcon"
                          style={{ ...imgStyle, marginRight: "10px" }}
                        />
                      </div>
                      <div>
                        <div>
                          <p className={hostingStyle.manageGsuite_Title}>
                            CHANGE DOMAIN
                          </p>
                        </div>

                        <Button
                          variant="outlined"
                          sx={
                            isSuspended
                              ? customDisabledButtonStyle
                              : customOutlineButtonStyle
                          }
                          onClick={() => {
                            upgradePlan();
                          }}
                          disabled={isSuspended}
                        >
                          Change Domain
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          </Paper>
        )}
      </div>
      <HostingRenewal
        setSnackbarInfo={setSnackbarInfo}
        open={open}
        handleClose={handleClose}
        renewalData={hostingRenewalPrice}
        PriceData={PriceData}
        setPriceData={setPriceData}
        gst={gst}
        setGst={setGst}
        planValue={planValue}
        setplanValue={setplanValue}
        id={id}
        productId={productId}
      />
      <ChangeDomain
        open={openChange}
        setSnackbarInfo={setSnackbarInfo}
        handleClose={handleCloseChange}
        setUpgradeGst={setUpgradeGst}
        upgradeGst={upgradeGst}
        changePlan={changePlan}
        setChangePlan={setChangePlan}
        setChangeDuration={setChangeDuration}
        changeDuration={changeDuration}
        upgradePlann={upgradePlann}
        setUpgradePlan={setUpgradePlan}
        upgradeDuration={upgradeDuration}
        setUpgradeDuration={setUpgradeDuration}
        id={id}
        domainName={domainName}
      />
      <Nameserver nameserver={nameserver} />
    </div>
  );
}

export default ManageHosting;
