import React, { useState } from "react";
import { Modal, Box, IconButton, Grid, Stack, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import Gmail from "../../../../../images/DomainDetails/googleRenewal.png";
import "../../Style/AddAccount.css";
import RenewalStyle from "../../Style/Renewal.module.css";
import { styled } from "@mui/material/styles";

import RenewalConfirm from "../../../Component/Renewal/Componets/RenewalConfirm.js";
import RenewalDropDown from "../../../Component/Renewal/Componets/RenewalDropDown.js";
import PriceTable from "../../../Component/Renewal/Componets/PriceTable.js";
import RenewalButton from "../../../Component/Renewal/Componets/RenewalButton.js";
import RenewalPayment from "../../../Component/Renewal/Componets/ProcessRenewal.js";
import IsWallet from "../../../CustomHook/IsWallet.js";
import { displayRazorpay } from "../../../CustomHook/RazorPay/RazorPay.js";
import { api_url1 } from "../../../../../globalSettings.js";
import axios from "axios";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "8px",
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#404040",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 0,
    background: "#404040",
  },
}));
const GsuiteRenewal = ({
  open,
  handleClose,
  setSnackbarInfo,
  setGsuitePrice,
  filterGsuite,
  renewalData,
  priceData,
  taxData,
  planValue,
  setplanValue,
  isToolTipOpen,
  isToolTip,
  findPrice,
  toolTipPrice,
  gsuitePrice,
  isFlexiblle,
  uniqueId,
}) => {
  const [scroll, setScroll] = React.useState("paper");

  const handleInputPlanValue = (event) => {
    const value = event.target.value;
    setplanValue(value);
    handlePrice(event.target.value, quantity);
    isToolTip(value, renewalData);
  };
  function handlePrice(planName, quantity) {
    const [filterPrice] = renewalData.plans.filter((data, index) => {
      return planName === data.planName;
    });
    setGsuitePrice(filterPrice);
    findPrice(filterPrice.gsuites, renewalData, quantity, planName);
  }

  const [processModal, setProcessModal] = useState(false);
  function handleCloseModal() {
    setProcessModal(false);
  }
  const quantity = isFlexiblle
    ? renewalData?.gsuite_userData?.maximumNumberOfSeats
    : renewalData?.gsuite_userData?.numberOfSeats;
  const isExceed = quantity < gsuitePrice?.gsuites?.offerForUsers;
  const offerUser = Math.abs(gsuitePrice?.gsuites?.offerForUsers - quantity);

  const [loading, setLoading] = useState(false);
  function GsuiteRenewal() {
    setLoading(true);
    let obj = {
      id: uniqueId,
      planName: planValue,
      isWallet: IsWallet(renewalData?.walletBalance),
    };

    axios
      .post(`${api_url1}/payment/gsuite/renewal`, obj)
      .then((result) => {
        if (!result.data.isRazorpay) {
          setSnackbarInfo({
            open: true,
            severity: "success",
            message:
              "Payment Successfully Made Via Wallet,It Will Take Few Minutes To Update",
          });
        } else {
          const handlerFunction = function (response) {
            setSnackbarInfo({
              open: true,
              severity: "success",
              message: "Payment Successful,It Will Take Few Minutes To Update!",
            });
          };

          let options = {
            ...result.data.options,
            handler: handlerFunction,
          };

          displayRazorpay(options, setSnackbarInfo);
        }
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="_addcompanytitle_">
            GSuite Renewal
            <IconButton
              component="span"
              onClick={handleClose}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <Stack
            spacing={1}
            sx={{ padding: "20px 16px 8px 16px" }}
            width="100%"
            alignItems="center"
            height="100%"
          >
            <RenewalConfirm
              confirmationText={
                "Are You Sure You Want To Confirm The Renewal For"
              }
              domainName={filterGsuite.domainName}
              isDelete={false}
            />
            <div
              className={RenewalStyle.middleWrapper}
              style={{ justifyContent: "space-between" }}
            >
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.img_container}>
                  <img src={Gmail} />
                </div>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>
                    Google Work Space
                  </div>
                  <div className={RenewalStyle.accFontSize}>
                    {quantity} account
                  </div>
                  {isToolTipOpen && (
                    <>
                      {isExceed ? (
                        <BootstrapTooltip
                          title={
                            <div style={{ borderRadius: 0 }}>
                              <p style={{ marginBottom: "0.3rem" }}>
                                {`${gsuitePrice?.gsuites?.name} ${gsuitePrice?.gsuites?.offerPrice} acc/${gsuitePrice?.priceparam}`}
                              </p>
                              <p style={{ marginBottom: "0.3rem" }}>
                                <s>{`${gsuitePrice?.gsuites?.name} ${gsuitePrice?.gsuites?.defaultPrice} acc/${gsuitePrice?.priceparam}`}</s>{" "}
                              </p>
                            </div>
                          }
                          arrow
                        >
                          <div className={RenewalStyle.link}>
                            View cost per account
                          </div>
                        </BootstrapTooltip>
                      ) : (
                        <BootstrapTooltip
                          title={
                            <div>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "10px",
                                  }}
                                >
                                  <div>
                                    {`${gsuitePrice?.gsuites?.offerForUsers}`}{" "}
                                    Accounts
                                  </div>
                                  <div>40% </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "10px",
                                  }}
                                >
                                  <div>
                                    {`${gsuitePrice?.gsuites?.name} ${gsuitePrice?.gsuites?.offerPrice} acc/${gsuitePrice?.priceparam}`}
                                  </div>
                                  <div>
                                    <s>{`${gsuitePrice?.gsuites?.name} ${gsuitePrice?.gsuites?.defaultPrice} acc/${gsuitePrice?.priceparam}`}</s>
                                  </div>
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div style={{ padding: "10px" }}>
                                  {offerUser} accounts
                                </div>
                                <div style={{ padding: "10px" }}>
                                  {`${gsuitePrice?.gsuites?.name} ${gsuitePrice?.gsuites?.defaultPrice} acc/${gsuitePrice?.priceparam}`}
                                </div>
                              </div>
                            </div>
                          }
                          arrow
                        >
                          <Typography
                            style={{
                              color: "rgb(109, 118, 135)",
                              cursor: "pointer",
                              font: "400 12px OpenSans, sans-serif",
                              textDecoration: "underline",
                              display: "inline",
                            }}
                          >
                            View cost per account
                          </Typography>
                        </BootstrapTooltip>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div>
                <div className={RenewalStyle.googleFont}>Duration</div>

                <RenewalDropDown
                  value={planValue}
                  handleChange={handleInputPlanValue}
                  valueParam={"planName"}
                  keyParam={"priceparam"}
                  items={renewalData?.plans}
                  displayParam={"planName"}
                />
              </div>
            </div>
            <div className={RenewalStyle.price_wrapper}>
              <PriceTable
                currencyCode={renewalData?.userData?.currencyCode}
                priceData={priceData}
                gst={taxData?.gst?.Amt || taxData?.gst}
                tax={taxData?.fullAmount}
              />
            </div>
          </Stack>
          <RenewalButton
            loading={false}
            title={"Proceed"}
            disabled={false}
            clickFunc={() => {
              handleClose();
              setProcessModal(true);
            }}
          />
        </Box>
      </Modal>

      <RenewalPayment
        handleCloseModal={handleCloseModal}
        open={processModal}
        fullAmount={taxData?.fullAmount}
        currencyCode={renewalData?.userData?.currencyCode}
        walletBalance={renewalData?.walletBalance}
        loading={loading}
        proceedFunc={GsuiteRenewal}
      />
    </>
  );
};

export default GsuiteRenewal;
