import React from "react";
import TrackeyBody from "../components/trackey/TrackeyBody";
import "../styles/trackey.css";
import Sidebar from "../components/trackey/Sidebar";
import { isSideBarActive } from "../Atoms/SideBarAtom";
import { useRecoilState } from "recoil";
export default function Trackey() {
  const[isOpen]=useRecoilState(isSideBarActive)
  return (
    <div style={{display :"flex"}}>
      {
        isOpen &&   <Sidebar />
      }
   
     <div id="trackey">
     <TrackeyBody />
   </div>
    </div>
   
  );
}
