import { React } from 'react'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPipelineTemplates } from '../../../webservices/PipelineService';
import { withStyles } from '@mui/styles';

const StyledAccordionSummary = withStyles({
    root: {
        minHeight: "50px !important",
        maxHeight: "50px !important",
        paddingRight: "8px !important",
        paddingLeft: "8px !important",
        "& .MuiAccordionSummary-content": {
            alignItems: "center"
        },
        '&.Mui-expanded': {
            minHeight: "50px !important",
            maxHeight: "50px !important",
        }
    },
    expandIcon: {
        order: -1
    }
})(AccordionSummary);

function SearchCloneTemplate(props) {

    const [selectedPipeline, setSelectedPipeline] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [expandAll, setExpandAll] = useState(false);

    const handleSelection = (pipeline, ignoreRemoveSelection) => {
        setSelectedPipeline(pipeline);
        // setSelectedPipeline(ignoreRemoveSelection ? pipeline : props.removeSelection ? null : pipeline);
        props.handleSelection(pipeline)
    }

    useEffect(() => {
        getPipelineTemplates().then(response => {
            setTemplates(response.data);
            if (props.showTemplate) {
                handleSelection(response.data?.[0].pipelines?.[0]);
            }
        });
    }, []);

    // useEffect(() => {
    //     setSelectedPipeline(null);
    // }, [props.removeSelection]);

    let filteredData = [];
    templates?.forEach((category) => {
        const filtered = category.pipelines.filter(
            (pipeline) => {
                return pipeline?.name?.toLowerCase().includes(props.input)
            }
        );
        filteredData.push({
            "category": category.category,
            "pipelines": filtered
        });
    })

    return (
        <Box>
            {
                filteredData.map((item, index) => {
                    return <Accordion key={index} defaultExpanded={props.showTemplate ? index === 0 : false} sx={{ m: "0 !important" }} 
                    elevation={0}>
                        <StyledAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`accordion-summary-${index}`}
                            id="panel1a-header"
                            sx={{ minHeight: '40px', flexDirection: 'row-reverse' }}
                        >
                            <Typography sx={{ pl: 1, fontWeight: 600 }}>{item.category}</Typography>
                        </StyledAccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                            <List sx={{ pt: 0, pb: 0 }}>
                                {item.pipelines.map((pipeline, i) => (
                                    <ListItemButton
                                        sx={{ pl: 1 }}
                                        key={`${pipeline.id}.${i}`}
                                        onClick={() => handleSelection(pipeline, true)}
                                        selected={selectedPipeline?.id === pipeline.id}>
                                        <ListItemIcon sx={{ minWidth: 28, pl: 1, pr: 1 }}>
                                            <StackedBarChartIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={pipeline.name} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </AccordionDetails>
                    </Accordion>
                })
            }
        </Box>
    )
}

export default SearchCloneTemplate