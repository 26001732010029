import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
} from "recharts";

const data = [
  {
    name: "Page A",
    achieved: 590,
    target: 700,
  },
];

const SingleBar = () => {
  return (
    <div style={{ height: 180 }}>
      <BarChart
        layout="vertical"
        width={500}
        height={180}
        data={data}
        margin={{
          top: 10,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" stroke="0" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Legend />
        <Bar dataKey="achieved" stackId="a" barSize={30} fill="#B4D056" />
        <Bar dataKey="target" stackId="a" barSize={30} fill="#94828270" />
      </BarChart>
    </div>
  );
};

export default SingleBar;
