import React, { useState } from 'react'
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Button, Typography, styled, Divider, Box, ListItem, ListItemButton, ListItemText, TextField, Modal } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const StyledButton = styled(Button)({
    "&.MuiButtonBase-root": {
      width: "300px",
      display: "flex",
      justifyContent: "flex-start",
    },
  });

const CustomAction = () => {
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
      setOpen(!open);
    };
    const [chat, setChat] = React.useState(true);
    const handleSharedImageFilesClick = () => {
        setChat(!chat);
    };
  
    const [modalOpen, setModalOpen] = useState(false);
    const [altEmail, setAltEmail] = useState("");

    const handleOpenModal = () => {
      setModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setModalOpen(false);
      setAltEmail('');
    };

  return (
    <List sx={{ p: 0 }}>
    <StyledButton
      onClick={handleClick}
      sx={{
       padding:"11px 40px 10px 12px ",
        backgroundColor: "#fafbfc",
        borderBottom: "1px solid #e3e8ef",
      }}
    >
      <Typography
        sx={{
          fontSize: 12,
          fontFamily: "Inter",
          color: "#75869c",
          textAlign: "left",
        }}
      >
       CUSTOM ACTIONS
      </Typography>
      {open ? (<ArrowDropDownIcon sx={{color: "#75869c",marginLeft:"auto"}}/>) : (<ArrowLeftIcon sx={{color: "#75869c",marginLeft:"auto"}}/>)}
    </StyledButton>

    {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>
            <StyledButton
              onClick={handleSharedImageFilesClick}
              sx={{
                padding: "11px 40px 10px 12px ",
                backgroundColor: "#fafbfc",
                borderBottom: "1px solid #e3e8ef",
              }}
            >
             {chat ? (
                <KeyboardArrowUpIcon sx={{color: "#75869c",}}/>
              ) : (
                <KeyboardArrowDownIcon sx={{color: "#75869c",}}/>
              )}
              <Typography
                sx={{
                  fontSize: 12,
                  fontFamily: "Inter",
                  color: "#75869c",
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
              >
                Send chat transcript
              </Typography>
            </StyledButton>

            {chat && (
              <>
                <Box
                  onClick={handleSharedImageFilesClick}
                  sx={{
                    backgroundColor: "#F8F8F8",
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    color: "#75869c",
                    cursor: "pointer",
                    borderBottom: "1px solid #e3e8ef",
                  }}
                >
                  <ArrowBackIosIcon sx={{ fontSize: "14px" }} />
                  <Typography>Back</Typography>
                </Box>
                <List sx={{p:0}}>
                    <ListItemButton>
                        <ListItemText>Email me a chat transcript</ListItemText>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText>Email guna a chat transcript</ListItemText>
                    </ListItemButton>
                    <ListItemButton onClick={handleOpenModal}>
                        <ListItemText>Email someone else chat transcript</ListItemText>
                    </ListItemButton>
                </List>
              </>
            )}
          </List>

        </Collapse>
      )}
    <Divider sx={{ borderBottom: "1px solid grey" }} />
    <Modal open={modalOpen} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              border: "2px solid grey",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography sx={{fontWeight:"600"}}>External operator email address</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              fullWidth
              placeholder="Enter external email address..."
              type="text"
              value={altEmail}
              onChange={(e) => setAltEmail(e.target.value)}
            />
            <Typography sx={{paddingTop:'10px',color:"#878787"}}>Note that this external operator will be able to send messages in the conversation.</Typography>
          
              <Box sx={{display:"flex",justifyContent:"end",paddingTop:"10px"}}>
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    color: "black",
                    border: "1px solid grey",
                    backgroundColor: "white",
                    textTransform: "capitalize",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    marginLeft: "10px",
                    color: "white",
                    border: "1px solid grey",
                    backgroundColor: "#0093ee",
                    textTransform: "capitalize",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: "#0093ee",
                    },
                  }}
                >
                 Sent Transcript
                </Button>
                </Box>
           
          </Box>
        </Modal>
  </List>
  )
}

export default CustomAction



   
    

  






 







    


