import { TabList, TabContext, TabPanel } from "@mui/lab";
import { useState, useEffect } from "react";
import { IconButton, Tab } from "@mui/material";

import PipelineBoard from "../view/board/PipelineBoard";
import PipelineTable from "../view/table/PipelineTable";
import { useSearchParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import CreateSubPipeline from "../create/CreateSubpipeline";
import SubPipelinePopover from "./SubPipelinePopover";
import variables from "../../../assets/styles/_colors.scss";

const SubPipelineTabs = (props) => {

    const [selectedTab, setSelectedTab] = useState(null);
    const [tabs, setTabs] = useState([]);
    const [panels, setPanels] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openCreateSubPipelineModal, setOpenCreateSubPipelineModal] = useState(false);

    const handleChange = (event, index) => {
        setSearchParameters(index);
    };

    const createSubPipelineModal = (val) => {
        setOpenCreateSubPipelineModal(val);
    }

    const getSubPipelineByIndex = (index) => {
        return props.currentPipeline?.subPipelines[index];
    }

    const getIndexBySubPipelineId = (id) => {
        return props.currentPipeline.subPipelines.findIndex(function (p, index) {
            return p.id === id;
        });
    }

    const setSearchParameters = (index) => {
        setSelectedTab(index.toString());
        searchParams.set('subpipeline', getSubPipelineByIndex(index)?.id);
        setSearchParams(searchParams);
    }

    useEffect(() => {
        if (props?.currentPipeline?.subPipelines?.length > 0) {
            const pipelinesArr = props.currentPipeline.subPipelines;
            if (tabs.length !== pipelinesArr.length) {
                setTabs(tabs => []);
                setPanels(panels => []);
                pipelinesArr.map(((p, index) => {
                    const a = {
                        label: p.name,
                        value: index.toString(),
                        key: p.id
                    }
                    setTabs(tabs => [...tabs, a]);
                    setPanels(panels => [...panels, {
                        value: index.toString()
                    }])
                    return;
                }))
            }
            const id = searchParams.get('subpipeline');
            if (!id) {
                setSearchParameters("0");
            }
            if (id) {
                setSelectedTab(getIndexBySubPipelineId(id).toString());
            }
        }
    }, [tabs, props.currentPipeline, props.pipelineView, selectedTab, searchParams]);

    return (
        props?.currentPipeline?.subPipelines?.length > 0
            ?
            <>
                {selectedTab &&
                    <TabContext value={selectedTab}>
                        {panels.map(panel => (
                            <TabPanel key={panel.value} label={panel.label} value={panel.value}
                                sx={{ p: 0, width: '100%', bgcolor: `${variables.tabsBackgroundColor}`, height: "calc(100vh - 153px)" }}>
                                {props.pipelineView
                                    ? <PipelineBoard drawerOpen={props.drawerOpen} initial={props.currentPipeline.subPipelines[panel.value]} />
                                    : <PipelineTable drawerOpen={props.drawerOpen} initial={props.currentPipeline.subPipelines[panel.value]}></PipelineTable>
                                }
                            </TabPanel>
                        ))}
                        < TabList onChange={handleChange} variant="scrollable" scrollButtons="auto"
                            sx={{
                                color: 'black',
                                position: 'absolute',
                                minHeight: 40,
                                bottom: "1px",
                                borderTop: `1px solid ${variables.borderColor}`,
                                ".Mui-disabled": {
                                    opacity: '0.3 !important'
                                }
                            }}>
                            {tabs && tabs.map(tab => (
                                <Tab sx={{ maxHeight: 45, minHeight: 45 }} key={tab.value} label={tab.label} value={tab.value} icon={<SubPipelinePopover selectedItem={tab} />} iconPosition="end">
                                </Tab>
                            ))}
                            <IconButton aria-label="add" size="large" onClick={() => createSubPipelineModal(true)}>
                                <AddIcon />
                            </IconButton>
                        </TabList >
                    </TabContext>
                }
                {openCreateSubPipelineModal && <CreateSubPipeline handleDrawerClose={() => createSubPipelineModal(false)} ></CreateSubPipeline>}
            </>
            :
            <div> No sub pipelines found in {props?.currentPipeline?.name}</div>
    );
}
export default SubPipelineTabs;