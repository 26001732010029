import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import TabContent from "./TabContent";
import SearchInput, { createFilter } from "react-search-input";
import AddProject from "./AddProject";
import { api_url } from "../../globalSettings";
import axios from "axios";
import { useRecoilState } from "recoil";
import { login_companyid, profile_trigger } from "../../Atoms/LoginAtoms";
import { Button } from "@mui/material";
import AddTask from "./AddTask";
import noTask from "../../images/CRM/plan_7404361.png";
import { isSideBarActive } from "../../Atoms/SideBarAtom";
const TopBarContainer = styled.div`
  background-color: white;
  padding: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const KEYS_TO_FILTERS = ["name"];

export default function CrmTask() {
  const [searchTerm, setSearchTerm] = useState("");
  const [company_idd] = useRecoilState(login_companyid);
  const [getProjectList, setProjectList] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [profileTrigger, setProfileTrigger] = useRecoilState(profile_trigger);
  const [isOpen] = useRecoilState(isSideBarActive);

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  //   ================================ DRAWER =====================================

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
    setopenDrawer(openDrawer ? false : true);
  };

  useEffect(() => {
    axios
      .get(`${api_url}/todo/list_all_projects/${company_idd}`)
      .then((responce) => {
        setProjectList(responce.data.data);
        setActiveTab(responce.data.data[0]._id);
      })
      .catch((err) => {});
  }, [profileTrigger, company_idd]);

  const filteredItems = getProjectList.filter(
    createFilter(searchTerm, KEYS_TO_FILTERS)
  );

  return (
    <AppContainer>
      <TopBarContainer>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <SearchInput className="search-input" onChange={handleSearchChange} />
          <AddProject />
        </div>
        <Button
          className="button"
          style={{ padding: "5px 10px", fontSize: "12px" }}
          onClick={toggleDrawer}
        >
          ADDTask
        </Button>
      </TopBarContainer>

      <ContentContainer>
        {filteredItems.length > 0 ? (
          <>
            {isOpen && (
              <Sidebar
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filteredItems={filteredItems}
              />
            )}
            <TabContent activeTab={activeTab} />
          </>
        ) : (
          <div className="task-table-container1">
            <img src={noTask} alt="loading" />{" "}
            <p style={{ margin: "20px", fontSize: "14px" }}>
              There is no Project
            </p>
            <style>
              {" "}
              {`
        .task-table-container1 {
  display: flex;
  justify-content: center;
  align-items:center;
  width:100%;
  height:100%;
  flex-direction : column;
  padding: 20px;
  font-family: "Poppins",sans-serif;
}
`}
            </style>
          </div>
        )}
      </ContentContainer>

      <style>
        {`.search-input input {
            background-color:#00e7ff00;
        width: 100%;
        padding-left: 3px;
        height: 32px;}
        
        .search-input {
            margin-right: 5px;
            margin: 0;
            width: 16%;}

            .button{
              color: rgb(212, 211, 211) ;
              border: 1px solid #3367d6;
              background-color: #3367d6 ;
              padding: 5px 10px ;
              border-radius: 8px ;
              margin-bottom:10px;
            }
            `}
      </style>
      <AddTask
        open={openDrawer}
        toggleDrawer={toggleDrawer}
        activeTab={activeTab}
      />
    </AppContainer>
  );
}
