import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Avatar, Checkbox, FormControl, FormControlLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { useEffect } from 'react';
import { useState } from 'react';
import variables from "../../../../assets/styles/_colors.scss";
import { stringAvatar } from '../../../../utils/PipelineConstants';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { deleteUser, getUsersDropdown } from '../../../../webservices/UserControlService';

const criteriaOptions = [
    {
        "header": "Record Ownership",
        "content": "Records associated with the deleted user along with their pipeline records in open stages will be transferred to the new user.",
        "key": "recordOwnership"
    },
    {
        "header": "Owner Assignments",
        "content": "Assignment of records via Forms, Workflows and Email-in associated with the deleted user will be transferred to the new user.",
        "key": "ownerAssignments"
    },
    {
        "header": "Criteria",
        "content": "All criteria associated with the deleted user will be transferred to the new user.",
        "key": "criteria"
    },
    {
        "header": "Email Notification",
        "content": "All email notifications associated with the deleted user will be transferred to the new user.",
        "key": "emailNotification"
    }
];

export default function DeleteUser(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(true);
    const [selectedRadioOption, setSelectedRadioOption] = useState("yes");
    const [selectedUser, setSelectedUser] = useState("");
    const [criteria, setCriteria] = useState({
        "recordOwnership": false,
        "ownerAssignments": false,
        "criteria": false,
        "emailNotification": false
    })

    useEffect(() => {
    }, [props.item]);

    const {
        data: users
    } = useQuery({
        queryKey: ['getUsersDropdown'],
        queryFn: () => getUsersDropdown("ACTIVE"),
        refetchOnWindowFocus: false
    });

    const { mutateAsync } = useMutation({
        mutationKey: "deleteRole",
        mutationFn: (id) => deleteUser(id),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getUsersCount'] });
            queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = (data) => {
        setOpen(false);
        props.handleClose(data);
    }

    const handleRadioChange = (event, val) => {
        setSelectedRadioOption(val);
        setSelectedUser("");
    }

    const handleCheckboxChange = (key, val) => {
        setCriteria({
            ...criteria,
            [key]: val
        });
    }

    const handleDelete = () => {
        mutateAsync(props.item?._id);
    }

    return (
        <Modal open={open} >
            <>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 16px 16px 24px",
                    width: '50%'
                }}>
                    <Stack direction="row" alignItems="center" spacing={2} >
                        <Typography variant="h6" sx={{ fontWeight: 600 }}> Delete User </Typography>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ bgcolor: `${variables.listBackgroundColor}`, pl: 1, pr: 1, pt: 0.5, pb: 0.5, borderRadius: 2 }} >
                            <Avatar {...stringAvatar(`${props.item?.firstName} ${props.item?.lastName}`, 20, 20, "0.7rem")} />
                            <Typography component="span">
                                {props.item?.firstName} {props.item?.lastName}
                            </Typography>
                        </Stack>
                        <CloseButton handleDialogClose={() => handleClose()} />
                    </Stack>

                    <Box sx={{ height: selectedRadioOption === "yes" ? "70vh" : "32vh", overflowY: "auto" }}>
                        <Typography component="div" variant="body" sx={{ pt: 2, pb: 1, fontWeight: "bold" }}>
                            {`Would you like to transfer ${props.item?.firstName} ${props.item?.lastName}'s ownership before deleting?`}
                        </Typography>

                        <FormControl fullWidth sx={{}}>
                            <RadioGroup
                                row
                                defaultValue={"yes"}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value={"yes"} control={<Radio color="primary" />}
                                    label={<Typography>Yes, transfer before deleting</Typography>} />
                                <FormControlLabel value={"no"} control={<Radio color="primary" />}
                                    label={<Typography>Delete without transfer</Typography>} />
                            </RadioGroup>
                        </FormControl>

                        {
                            selectedRadioOption === "yes" &&
                            <Box sx={{ p: 1, pl: 0 }}>
                                <Stack direction="row" alignItems="center" width="100%" spacing={2} sx={{ pt: 1 }}>
                                    <Stack direction="row" width="20%" justifyContent="end">
                                        Transfer To*
                                    </Stack>
                                    <Stack direction="row" width="70%" justifyContent="start">
                                        <Select
                                            size="small"
                                            fullWidth
                                            placeholder="Select"
                                            displayEmpty
                                            value={selectedUser}
                                            onChange={(event) => setSelectedUser(event.target.value)}
                                        >
                                            {
                                                users?.map((user, index) => {
                                                    return <MenuItem key={index} value={user._id}>
                                                        {user?.firstName} {user.lastName}
                                                    </MenuItem>
                                                })
                                            }
                                        </Select>
                                    </Stack>
                                    <Stack direction="row" width="20%" >
                                    </Stack>
                                </Stack>
                                <Box sx={{ pt: 2 }}>
                                    {criteriaOptions.map(option => {
                                        return <Stack direction="row" alignItems="start" justifyContent="start" spacing={1} sx={{ pt: 1, pb: 1 }}>
                                            <Checkbox
                                                sx={{ mt: "-6px !important", ml: "-6px !important" }}
                                                color="primary"
                                                checked={criteria[option.key]}
                                                onChange={(e) => handleCheckboxChange(option.key, e.target.checked)}
                                            />
                                            <Stack direction="column">
                                                <Typography sx={{ fontWeight: "bold" }}>{option.header}</Typography>
                                                <Typography>{option.content}</Typography>
                                            </Stack>
                                        </Stack>
                                    })
                                    }
                                </Box>
                            </Box>
                        }
                        <Box sx={{ bgcolor: `${variables.failureBackgroundColor}`, p: 1 }}>
                            <Typography>Note</Typography>
                            <Typography>
                                <ul>
                                    <li>
                                        If the deleted user has integrated any other applications with this account, those integrations will be disabled.
                                    </li>
                                    <li>
                                        Deleting this user will not reduce the number of user licenses for which your company is billed. If you need to reduce user licenses, you can do it from the Subscriptions page.
                                    </li>
                                </ul>
                            </Typography>
                        </Box>
                    </Box>

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1, width: "96%", bgcolor: `${variables.linkBackgroundColor}` }}>
                        <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                        {selectedRadioOption === "yes" ?
                            <Button variant="contained" color="error" onClick={handleClose} disabled={selectedUser === ""}>
                                Transfer and Delete
                            </Button>
                            : <Button variant="contained" color="error" onClick={handleDelete}>
                                Delete
                            </Button>
                        }
                    </Stack>
                </Box>
            </>
        </Modal>
    );
}