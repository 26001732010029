import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CloseButton } from '../../components/CloseButton';
import { capitalizeFirstLetter } from '../../utils/PipelineConstants';

export default function DeleteActivity(props) {

    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        props.handleClose();
    }

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        Delete {capitalizeFirstLetter(props.module)}
                        <CloseButton handleDialogClose={handleClose} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ pt: 2 }}>
                        Are you sure you want to delete the {props.module}
                        <em>
                            {props.module === "task" && ` ${props.item.taskName}`}
                            {props.module === "event" && ` ${props.item.title}`}
                            {props.module === "call" && ` to ${props.item.toFrom}`}
                        </em>?
                    </Typography>

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="error" onClick={handleClose}>
                            Delete
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </div>
    );
}