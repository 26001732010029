import styled from '@xstyled/styled-components';
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

export const BoardContainer = styled.div`
    display: inline-flex;
    padding-top: 12px;
    height: 100%;
`;

export const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-bottom: 10px;
    box-shadow: ${({ $isDragging }) => ($isDragging ? '5px 5px 10px #A5ADBA' : 'none')};
`;

export const ColumnHeader = styled.div`
  padding: 8px 8px 8px 10px;
  background-color: #fff;
  color: #000;
  overflow: hidden;
  border-radius: 4px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  outline: none !important;
  transition: background-color 0.2s ease;
  border-top: ${({ $isOpen }) => ($isOpen ? '3px solid #1565c0' : '3px solid #2BB97C')};
  min-height: 50px;
  max-height: 50px;
`;

export const ColumnTitle = styled.div`
  padding: 4px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  outline: none !important;
  position: relative;
  &:focus {
    outline: 2px solid #998dd9;
    outline-offset: 2px;
  }
`;

export const ListWrapper = styled.div`
  background-color: ${(props) => getBackgroundColor(props.$isDraggingOver, props.$isDraggingFrom)};
  display: flex;
  flex-direction: column;
  border: 4px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 290px;
  height: calc(100vh - 240px);
`;

export const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 540px;
`;

export const DropZone = styled.div`
  padding-bottom: 40px;
  margin-right: 1.5px;
`;

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return '#FFEBE6';
  }
  if (isDraggingFrom) {
    return '#E6FCFF';
  }
  return '#F9FBFC';
};

export const ItemContainer = styled.a`
  position: relative;
  width: 100%;
  padding: 4px;
  border: 2px solid transparent;
  border-color: transparent;
  background-color: transparent;
  box-shadow: ${({ $isDragging }) => ($isDragging ? `2px 2px 1px #A5ADBA` : 'none')};
  box-sizing: border-box;
  min-height: 70px;
  margin-bottom: 4px;
  user-select: none;

  border-radius: 4px;
  margin: 0 0 8px 0;
  background: #fff;
  cursor: pointer;
  border: 1px solid #E5EBEF;
  color: #212129;

  /* anchor overrides */
  color: #091e42;
  padding-left: 12px;

  &:hover {
    color: #091e42;
    border: 1px solid #1976d2;
    #dealName {
      color: #1976d2;
    }
  }
  
  display: flex;
`;

export const ItemContent = styled.div`
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
`;
