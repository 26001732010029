import { Checkbox } from "@mui/material";
import React from "react";

function IndeterminateCheckbox({ indeterminate, ...rest }) {

    const ref = React.useRef(null);
    return <Checkbox ref={ref} indeterminate={!rest.checked && indeterminate} {...rest} />
}

export default IndeterminateCheckbox;
