import React from 'react'
import None from '../../../images/tabview.png';
import { Typography } from '@mui/material';
const VTabbody = () => {
  return (
    <div style={{display:"flex",justifyContent:'center'}}>
      <div>
        <img src={None} alt='None'/>
        <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
        <Typography sx={{paddingBottom:"10px"}}>No Visitors Match The Priority Settings</Typography>
        <a href='/#'>Learn More</a>
        </div>
      </div>
    </div>
  )
}

export default VTabbody
