import React from 'react'
import RenewalStyle from "../Styles/RenewalConfirm.module.css";

const Note = ({Text}) => {
  return (
   <div style={{marginTop:"12px",width:"100%"}}>
    <span className={RenewalStyle.noteStyle}>Note</span><span className={RenewalStyle.noteText}>{Text}</span>
   </div>
  )
}

export default Note