import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

const Typography = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom:20px
`;

const DashPieChart = (crmDashDetails) => {


  const [chartData, setChartData] = useState({
    series: [crmDashDetails.crmDashDetails.done , crmDashDetails.crmDashDetails.open
      ,crmDashDetails.crmDashDetails.inprogress ,crmDashDetails.crmDashDetails.closed],
    options: {
      chart: {
        width: 380,
        type: "pie",
      },
      labels: ["Done", "Open","Inprogress", "Closed"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <>
    <div
      id="chart"
      style={{
        width: "95%",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        borderRadius: "10px",
        marginTop: "20px",
        flexDirection:"column",
        alignItems:"center",
        padding:"20px",
        minHeight:320
      }}
    >
    <Typography>Task Status</Typography>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width={380}
      />
    </div>
    </>
  );
};

export default DashPieChart;
