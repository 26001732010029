import { Autocomplete, Chip, TextField } from "@mui/material";
import * as React from "react";
import variables from "../../../assets/styles/_colors.scss";

export const CustomAutocompleteFreesolo = ({ ...props }) => {

    return (
        <Autocomplete
            multiple
            id={props.id}
            options={props.options ? props.options : []}
            freeSolo
            defaultValue={props.defaultValue ? props.defaultValue : []}
            onChange={props.handleAutocompleteChange}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    fullWidth
                    sx={{ border: `1px solid ${variables.borderColor}`, borderRadius: 0.5, paddingLeft: 1 }}
                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                />
            )}
        />
    );
};
