import React, { useEffect, useState } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import { api_url } from "../../../globalSettings";
import {

  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  IconButton,
 
  Stack,
  TextField,
  Avatar,

} from "@mui/material";
import Grid from "@mui/material/Grid";

import { Box } from "@mui/system";
import { selectedcompany } from "../managecompanyatom";

import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";

import { useRecoilState } from "recoil";
import { Alert, Snackbar } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import "../../../styles/trackey.css";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";

import {
  login_atoms,
  login_token,
  login_companyid,
  triger_branch,
} from "../../../Atoms/LoginAtoms";
import "../ManageBranch/ManageBranchNew.css";
import { makeStyles } from "@mui/styles";
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  customOutlinedInput: {
    /* Your custom styles here */
    padding: "4px 14px",
    height: "40px",
    width: "100%",
  },
}));

const CssTextField = styled(TextField)({
  borderRadius: "4px",

  "& label.Mui-focused": {
    color: "#009DFE",
  },

  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#009DFE",
  },

  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#009DFE",
      padding: "9.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#009DFE",
    },
  },
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(5),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    //transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 0,
    },
  },
};

const names = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

function ManageCompanyNew() {
  // ============================ = WORKING DAYS ============================================================

  const [personName, setPersonName] = React.useState([]);


  const handleChange0 = (event) => {

    const {
      target: { value },
    } = event;

    setPersonName(
      typeof value === "string" ? value.split(",") : value
    );
  };

  // ============================= Update WORKING DAYS ============================================================

  const [personName1, setPersonName1] = React.useState("");


  const handleChange5 = (event) => {

    const {
      target: { value },
    } = event;

    setPersonName1(
      typeof value === "string" ? value.join(",") : value
    );
  };

  const [Getlist, setGetlist] = useState([]);

  const [companyform, setCompanyForm] = useState({
    shift_name: "",
    daily_working_hour: "",
 
  });


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "calc(100vh - 85px)",
    bgcolor: "white",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
  };

  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  //==================== MODEL BOX FOR ADD AND UPDATE SHIFT =================

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  
  const [SelectedCompany, setSelectedCompany] = useRecoilState(selectedcompany);
  const [trigerBranch, setTrigerBranch] = useRecoilState(triger_branch);

  const [branchid, setBranchid] = useState("");

  const handleChange2 = (event) => {
    setBranchid(event.target.value);
  };

  //  ========================    GET UPDATE DETAILS  ===========================

  const [upShiftId, setUpShiftId] = useState("");
  const [getShiftdetail, setGetShiftdetails] = useState([]);


  const getShiftdetails = (shiftId) => (e) => {

    e.preventDefault();
    setUpShiftId(shiftId);

    const params = {
      token: loginToken1,
      shift_id: shiftId,
    };

    axios
      .get(`${api_url}/getShiftDetails`, { params: { ...params } })
      .then((responce) => {

        setGetShiftdetails(responce.data.data);

        setPersonName1(responce.data.data.working_day.split(","));
   
        handleOpen1();
   
      })
      .catch((err) => {
     
      });
  };

  const updateShift = () => {


    getShiftdetail.token = loginToken1;
    getShiftdetail.shift_id = upShiftId;
    getShiftdetail.working_day = personName1.toString();


    axios
      .patch(
        `${api_url}/updateShift`,
        {
          getShiftdetail: getShiftdetail,
          shiftname: updateShiftName.shift_name,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(function (response) {
     
        setupdateShiftName("");
   
        getShiftList();
        setOpen1(false);
      })

      .catch(function (error) {
        if (error.response) {
    
        } else if (error.request) {
       
     
        } else {
     ;
        }
      
      });
  };

  const [updateShiftName, setupdateShiftName] = React.useState([]);

  function handlechange(e) {
  
    const { value, name } = e.target;

    setupdateShiftName({ ...updateShiftName, [name]: value });

    setGetShiftdetails({ ...getShiftdetail, [name]: value });
  }

  React.useEffect(() => {

  }, [updateShiftName]);

  function handlechange1(e) {

    const { value, name } = e.target;
  

    setGetShiftdetails({
      ...getShiftdetail,
      [name]: value.split("-").reverse().join("-"),
    });
  }

  //  ========================    GET COMPANY DETAILS  ===========================

 
  // ======================================= Api DELETE =================================================

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (id) => {
    setUpShiftId(id);
    setOpen2(true);
  };
  const handleClose2 = () => setOpen2(false);

  const deleteShift = (shiftid) => () => {

    const params = {
      shift_id: upShiftId,
      token: loginToken1,
    };
 
    axios
      .delete(`https://server.nowdigitaleasy.com:3505/deleteShift`, { params })
      .then((result) => {
      
        getShiftList();
        setOpen2(false);
      })
      .catch((error) => {
     
        if (error.response) {
    
        } else if (error.request) {
         
        } else {
        
        }
     
      });
  };

  //============================================== Changing Form Values =============================================

  const [GetShiftList, setGetShiftlist] = useState([]);


  const [branchid1, setBranchid1] = React.useState("");


  const handleChange1 = (event, newValue) => {
    if (newValue != null) {
      setBranchid1(newValue.id);

    }
  };

  function getShiftList() {

    const params = {
 
      token: loginToken1,
      branch_id: branchid,
    };

    axios
      .get(`${api_url}/getShiftList`, { params: { ...params } })
      .then((responce) => {
        setGetShiftlist(responce.data.data);

      })
      .catch(function (error) {
        if (error.response) {
       
        } else if (error.request) {
      
        } else {
     
        }

      });
  }

  useEffect(() => {
    getBranchList();
    getShiftList();
  }, [trigerBranch]);

  function getBranchList() {
 
    const params = {
      company_id: SelectedCompany.licence_details.company_id,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
        setBranchid(responce.data.data[0]._id);
      })
      .catch((err) => {
 
      });
  }

  useEffect(() => {
    getShiftList();
  }, [branchid]);

  //===================================== set valid time  ===========================

  const [validFrom, setValidFrom] = React.useState("");

  const [validTo, setValidTo] = React.useState("");


  function valid_from(event) {
 
    setValidFrom(event.target.value);
  }

  function valid_to(event) {
  
    setValidTo(event.target.value);
  }

  //===================================== Start and end time  ============================
  const [earlyStartTime, setEarlyStartTime] = React.useState(
    dayjs("2018-01-01T00:00:00.000Z")
  );

  const [lateStartTime, setLateStartTime] = React.useState(
    dayjs("2018-01-01T00:00:00.000Z")
  );
  const [earlyEndTime, setEarlyEndTime] = React.useState(
    dayjs("2018-01-01T00:00:00.000Z")
  );

  const [lateEndTime, setLateEndTime] = React.useState(
    dayjs("2018-01-01T00:00:00.000Z")
  );

  function earlyStart(event) {
  
    setEarlyStartTime(event.target.value);
  }

  function lateStart(event) {
  
    setLateStartTime(event.target.value);
  }
  function earlyEnd(event) {

    setEarlyEndTime(event.target.value);
  }
  function lateEnd(event) {

    setLateEndTime(event.target.value);
  }
  const classes = useStyles();


  const [errors, setErrors] = useState({});


  const handleChange = (e) => {
    const { name, value } = e.target;
 
    setCompanyForm((state) => ({ ...state, [name]: value }));

    const newErrors = { ...errors };

    switch (name) {
      case "first_name":
        newErrors.shift_name = validator.isEmpty(value)
          ? "Please enter your First Name."
          : "";
        break;
      case "last_name":
        newErrors.daily_working_hour = validator.isEmpty(value)
          ? "Please enter your Last Name."
          : "";
        break;

      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if phone number is valid before submitting the form

    if (validate()) {
      const params = JSON.stringify({
        token: loginToken1,
        user_id: loginAtom1,
        company_id: SelectedCompany.licence_details.company_id,
        branch_id: branchid1,
        shift_name: companyform.shift_name,
        valid_from: validFrom.split("-").reverse().join("-"),
        valid_to: validTo.split("-").reverse().join("-"),
        working_day: personName.toString(),
        early_start_time: earlyStartTime,
        late_start_time: lateStartTime,
        early_end_time: earlyEndTime,
        late_end_time: lateEndTime,
        daily_working_hour: companyform.daily_working_hour,
      });


      axios
        .post(`${api_url}/addShift`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
    
          setSnackbaropen({
            open: true,
            message: "Shift Added successfully",
            severity: "success",
            actionColor: "#edf7ed",
          });
          getBranchList();
          getShiftList();
          setOpen(false);
        })

        .catch(function (error) {
          if (error.response) {
         
          } else if (error.request) {
        
          } else {
         
          }
        
        });
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    // const isValid1 = validatePhone();
    // setIsValidPhone(isValid1);

    if (validator.isEmpty(companyform.shift_name)) {
      isValid = false;
      newErrors.shift_name = "Please enter your first name.";
    }

    if (validator.isEmpty(companyform.daily_working_hour)) {
      isValid = false;
      newErrors.daily_working_hour = "Please enter your last name.";
    }

    if (validator.isEmpty(branchid1)) {
      isValid = false;
      newErrors.branchid1 = "Please Select Branch";
    }
    if (validator.isEmpty(validFrom)) {
      isValid = false;
      newErrors.validFrom = "Please Select Date";
    }
    if (validator.isEmpty(validTo)) {
      isValid = false;
      newErrors.validTo = "Please Select Date.";
    }
 

    setErrors(newErrors);
    return isValid;
  };

  return (
    <div className="companylist">
      <Snackbar
        open={snackbaropen.open}
        onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
        autoHideDuration={1000}
      >
        <Alert
          severity={snackbaropen.severity}
          style={{ backgroundColor: `${snackbaropen.actionColor}` }}
        >
          {snackbaropen.message}
        </Alert>
      </Snackbar>
      {/* ======================================================== Company Table ======================================================= */}

      <div className="company_table">
        <div className="company_table_title">SHIFT</div>
        <Box style={{ maxHeight: 400 }}>
          <div className="company_table-filter">
            <div className="filter_btn">
          
              <FormControl
                sx={{ m: 1 }}
                variant="standard"
                style={{ minWidth: "150px" }}
                size="small"
              >
                <InputLabel htmlFor="demo-customized-select-native">
                  Select Your Branch
                </InputLabel>
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
                  onChange={handleChange2}
                  input={<BootstrapInput />}
                >
       
                  {Getlist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>
                      </>
                     
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>

            <div className="btn">
              <Button
                // variant="contained"
                className="tablebutton"
                // onClick={opendrawer}
                // startIcon={<icons.Add />}
                // disableElevation
                onClick={handleOpen}
              >
                Add SHIFT
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className style={{ width: "50%" }}>
                  <div className="addcompanytitle">
                    ADD SHIFT
                    <IconButton
                      component="span"
                      onClick={handleClose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={4}
                    padding={5}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Shift Name"}
                          name="shift_name"
                          onChange={handleChange}
                          //onChange={(e) => formonchange("shift_name", e)}
                          fullWidth
                          required
                        />
                        {errors.shift_name ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.shift_name}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={Getlist}
                          onChange={handleChange1}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              // {...register("Getlist", {
                              //   required: "required",
                              // })}
                              // error={errors.sub_category}
                              // helperText={errors.sub_category?.message}
                              label="Select Branch*"
                            />
                          )}
                        />
                        {errors.branchid1 ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.branchid1}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Daily Working Hours"}
                          name="daily_working_hour"
                          onChange={handleChange}
                          // onChange={(e) =>
                          //   formonchange("daily_working_hour", e)
                          // }
                          fullWidth
                          required
                        />
                        {errors.daily_working_hour ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.daily_working_hour}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "50px",
                            textTransform: "capitalize",
                          }}
                          id="date"
                          type="date"
                          value={validFrom}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={valid_from}
                          size="small"
                          fullWidth
                          label="Valid From*"
                          variant="outlined"
                        />
                        {errors.validFrom ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.validFrom}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                            backgroundColor: "#ecf4f8",
                          }}
                          id="date"
                          type="date"
                          value={validTo}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={valid_to}
                          size="small"
                          fullWidth
                          label="Valid To*"
                          variant="outlined"
                        />
                        {errors.validTo ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.validTo}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl
                          sx={{
                            m: 0,
                            height: "30px",
                            maxWidth: "100%",
                            minWidth: "100%",
                          }}
                          size="small"
                        >
                          <InputLabel id="demo-multiple-checkbox-label">
                            Working Days
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            className={classes.customOutlinedInput}
                            value={personName}
                            onChange={handleChange0}
                            input={<OutlinedInput label="Working Days" />}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={{ display: "flex" }}
                              >
                                <Checkbox
                                  checked={personName.indexOf(name) > -1}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {errors.personName ? (
                          <div
                            className="text-danger"
                            style={{
                              marginTop: "10px",
                              fontSize: "12px",
                              fontWeight: "bold",
                            }}
                          >
                            <ErrorOutlineIcon
                              style={{ marginRight: "5px", fontSize: "15px" }}
                            />
                            {errors.personName}
                          </div>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                          }}
                          id="time"
                          type="time"
                          value={earlyStartTime}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={earlyStart}
                          size="small"
                          fullWidth
                          variant="outlined"
                          label="Early Start time*"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                          }}
                          id="time"
                          type="time"
                          value={lateStartTime}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={lateStart}
                          size="small"
                          fullWidth
                          variant="outlined"
                          label="late Start time*"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                          }}
                          id="time"
                          type="time"
                          value={earlyEndTime}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={earlyEnd}
                          size="small"
                          fullWidth
                          variant="outlined"
                          label="Early End time*"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CssTextField
                          style={{
                            height: "40px",
                            textTransform: "capitalize",
                          }}
                          id="time"
                          type="time"
                          value={lateEndTime}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={lateEnd}
                          size="small"
                          fullWidth
                          variant="outlined"
                          label="Late End time*"
                        />
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={handleSubmit}
                        >
                          Add Shift
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
            </div>
          </div>
          <TableContainer component={Paper} className="tblcontainer">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table_head ">Shift Name</TableCell>
                  <TableCell className="table_head">Work Duration</TableCell>
                  <TableCell className="table_head">
                    Valid From to Valid To
                  </TableCell>
                  <TableCell className="table_head">Early Start Time</TableCell>
                  <TableCell className="table_head">Late Start Time</TableCell>
                  <TableCell className="table_head">Early End Time</TableCell>
                  <TableCell className="table_head">Late End Time</TableCell>
                  {/* <TableCell className="table_head">Delete</TableCell> */}
                </TableRow>
              </TableHead>

              {GetShiftList.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.companyId1}
                    // onClick={() => {
                    //   setSelectedCompany(item);
                    // }}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                  >
                    <TableCell className="company_name" title={item.shift_name}>
                      <div>
                        <Avatar style={{ marginRight: 15 }} />
                      </div>
                      {item.shift_name}
                    </TableCell>
                    <TableCell
                      title={item.daily_working_hour}
                      className="companypersonalinfo"
                    >
                      {item.daily_working_hour}
                    </TableCell>
                    <TableCell
                      title={item.working_day}
                      className="companypersonalinfo"
                    >
                      {item.valid_from} to {item.valid_to}
                    </TableCell>
                    <TableCell
                      title={item.early_start_time}
                      className="companypersonalinfo"
                    >
                      {item.early_start_time}
                    </TableCell>
                    <TableCell
                      title={item.late_start_time}
                      className="companypersonalinfo"
                    >
                      {item.late_start_time}
                    </TableCell>

                    <TableCell
                      title={item.early_end_time}
                      className="companypersonalinfo"
                    >
                      {item.early_end_time}
                    </TableCell>

                    <TableCell
                      title={item.late_end_time}
                      className="companypersonalinfo"
                    >
                      {item.late_end_time}
                    </TableCell>

                    <div class="overlay" style={{ width: "40%" }}>
                      <div class="text">
                        <Button
                          style={{ fontWeight: 600 }}
                          onClick={getShiftdetails(item._id)}
                        >
                          Update Shift
                        </Button>
                        <Modal
                          open={open1}
                          onClose={handleClose1}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} className style={{ width: "50%" }}>
                            <div className="addcompanytitle">
                              UPDATE SHIFT
                              <IconButton
                                component="span"
                                onClick={handleClose1}
                                style={{ padding: 0, marginRight: 20 }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </div>
                            <Stack
                              spacing={4}
                              padding={5}
                              width="100%"
                              alignItems="center"
                              height="100%"
                            >
                              <Grid container spacing={4}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  alignItems="center"
                                  justifyContent=" center"
                                >
                                  <TextField
                                    size="small"
                                    id="standard-basic"
                                    variant="outlined"
                                    label={"Shift Name"}
                                    //onChange={(e) => formonchange("shift_name", e)}
                                    name="shift_name"
                                    onChange={handlechange}
                                    value={getShiftdetail.shift_name}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                        

                                <Grid item xs={12} sm={6} md={4}>
                                  <TextField
                                    size="small"
                                    id="standard-basic"
                                    variant="outlined"
                                    label={"Daily Working Hours"}
                                  
                                    name="daily_working_hour"
                                    onChange={handlechange}
                                    value={getShiftdetail.daily_working_hour}
                                    fullWidth
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CssTextField
                                    style={{
                                      height: "50px",
                                      textTransform: "capitalize",
                                    }}
                                    id="date"
                                    type="date"
                                    name="valid_from"
                                    value={
                                      getShiftdetail.valid_from
                                        ? getShiftdetail.valid_from
                                            .split("-")
                                            .reverse()
                                            .join("-")
                                        : ""
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handlechange1}
                                    size="small"
                                    fullWidth
                                    label="Valid From*"
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CssTextField
                                    style={{
                                      height: "40px",
                                      textTransform: "capitalize",
                                      backgroundColor: "#ecf4f8",
                                    }}
                                    id="date"
                                    type="date"
                               
                                    name="valid_to"
                                    value={
                                      getShiftdetail.valid_to
                                        ? getShiftdetail.valid_to
                                            .split("-")
                                            .reverse()
                                            .join("-")
                                        : ""
                                    }
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handlechange1}
                                    size="small"
                                    fullWidth
                                    label="Valid To*"
                                    variant="outlined"
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl
                                    sx={{
                                      m: 0,
                                      height: 30,
                                      maxWidth: "100%",
                                      minWidth: "100%",
                                    }}
                                  >
                                    <InputLabel id="demo-multiple-checkbox-label">
                                      Working Days
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-checkbox-label"
                                      id="demo-multiple-checkbox"
                                      multiple
                                      name="valid_to"
                                      //value={getShiftdetail.valid_to}
                                      className={classes.customOutlinedInput}
                                      value={personName1}
                                      onChange={handleChange5}
                                      input={
                                        <OutlinedInput label="Working Days" />
                                      }
                                      renderValue={(selected) =>
                                        selected.join(", ")
                                      }
                                      MenuProps={MenuProps}
                                    >
                                      {names.map((name) => (
                                        <MenuItem
                                          key={name}
                                          value={name}
                                          style={{ display: "flex" }}
                                        >
                                          <Checkbox
                                            checked={
                                              personName1.indexOf(name) > -1
                                            }
                                          />
                                          <ListItemText primary={name} />
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CssTextField
                                    style={{
                                      height: "40px",
                                      textTransform: "capitalize",
                                    }}
                                    id="time"
                                    type="time"
                                    value={getShiftdetail.early_start_time}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    name="early_start_time"
                                    onChange={handlechange}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    label="Early Start time*"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CssTextField
                                    style={{
                                      height: "40px",
                                      textTransform: "capitalize",
                                    }}
                                    id="time"
                                    type="time"
                                    name="late_start_time"
                                    value={getShiftdetail.late_start_time}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handlechange}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    label="late Start time*"
                                  />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                  <CssTextField
                                    style={{
                                      height: "40px",
                                      textTransform: "capitalize",
                                    }}
                                    id="time"
                                    type="time"
                                    name="early_end_time"
                                    value={getShiftdetail.early_end_time}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handlechange}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    label="Early End time*"
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <CssTextField
                                    style={{
                                      height: "40px",
                                      textTransform: "capitalize",
                                    }}
                                    id="time"
                                    type="time"
                                    name="late_end_time"
                                    value={getShiftdetail.late_end_time}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={handlechange}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    label="Late End time*"
                                  />
                                </Grid>
                              </Grid>
                              <div className="addcompanybtn">
                                <Stack direction={"row"} spacing={2}>
                                  <Button
                                    variant="outlined"
                                    className="tablebtncancel"
                                    style={{ color: "#000" }}
                                    onClick={handleClose1}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="tablebutton"
                                    onClick={updateShift}
                                  >
                                    Update Shift
                                  </Button>
                                </Stack>
                              </div>
                            </Stack>
                          </Box>
                        </Modal>
                        <Button
                          onClick={() => {
                            handleOpen2(item._id);
                          }}
                          style={{ fontWeight: 600 }}
                        >
                          Delete
                        </Button>
                        <Modal
                          open={open2}
                          onClose={handleClose2}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            backgroundColor: "#ffffff9e",
                          }}
                          // TransitionComponent={Transition}
                        >
                          <Box sx={style} className="scroll">
                            <div className="deleteemployee">
                              Are You Sure You Want To Delete Your Shift?
                            </div>
                            <Stack
                              spacing={4}
                              padding={2}
                              width="100%"
                              alignItems="center"
                              height="100%"
                            >
                              <div className="addcompanybtn">
                                <Stack direction={"row"} spacing={2}>
                                  <Button
                                    variant="outlined"
                                    className="tablebtncancel"
                                    style={{ color: "#000" }}
                                    onClick={handleClose2}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="tablebutton"
                                    onClick={
                                      deleteShift()
                                   
                                    }
                                  >
                                    Delete
                                  </Button>
                                </Stack>
                              </div>
                            </Stack>
                          </Box>
                        </Modal>
                        <Button style={{ fontWeight: 600 }}>More Option</Button>
                      </div>
                    </div>

                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
}

export default ManageCompanyNew;
