import { Grid } from "@mui/material";
import React from "react";
import Truncate from "react-truncate";
import styled from "styled-components";

const Box = styled.div`
  background-color: #ffffff;
  box-shadow:0 0 5px 2px #0000002b;
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 20px;
  margin-left: 10px;
  display: flex;
  width: 96%;
  min-height: 150px;
  flex-direction: column;
`;

const Typography = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const CallLogs = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            <Typography>
              <Truncate lines={1} ellipsis={<span>... </span>}>
                Calls Completed - This Month
              </Truncate>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box>
          <Typography>
              <Truncate lines={1} ellipsis={<span>... </span>}>
                Calls Completed - This Month
              </Truncate>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box>
          <Typography>
              <Truncate lines={1} ellipsis={<span>... </span>}>
                Calls Completed - This Month
              </Truncate>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box>
            {" "}
            <Typography>
              <Truncate lines={1} ellipsis={<span>... </span>}>
                Calls Completed - This Month
              </Truncate>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default CallLogs;
