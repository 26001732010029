import * as React from "react";
import Grid from "@mui/material/Grid";
import Wrapper from "../../Components/Wrapper";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const CallAnalytics = () => {

  const initialChartItems = [
    [
      {
        id: "callsCompleted",
        component: (
          <Wrapper title={"Calls Completed - This Month"} count={1} percentage={"100%"} rate={"0"}  noData={true}/>
        ),
      },
      {
        id: "inboundSeconds",
        component: (
          <Wrapper
            title={"Inbound Seconds - This Month"}
            count={1}
            percentage={"100%"}
            rate={"0"}
            noData={true}
          />
        ),
      },
    ],
    [
      {
        id: "upcomingCalls",
        component: (
          <Wrapper title={"Upcoming Calls - This Month"} count={1} percentage={"100%"} rate={"0"} noData={true} />
        ),
      },
      {
        id: "outboundSeconds",
        component: (
          <Wrapper title={"Outbound Seconds - This Month"} count={1} percentage={"100%"} rate={"0"} noData={true} />
        ),
      },
    ],
    [
      {
        id: "inbondCalls",
        component: (
          <Wrapper
            title={"Inbound Calls - This Month"}
            count={1}
            rate={"0"}
            percentage={"100%"}
            noData={true}
          />
        ),
      },
      {
        id: "missedCalls",
        component: (
          <Wrapper title={"Missed Calls - This Month"} count={1} percentage={"100%"} rate={"0"} noData={true}/>
        ),
      },
    ],
    [
      {
        id: "outboundCalls",
        component: (
          <Wrapper title={"Outbound Calls - This Month"} count={1} percentage={"100%"} rate={"0"} noData={true}/>
        ),
      },
      {
        id: "averageCall",
        component: (
          <Wrapper title={"Average Call Duration (seconds)"} count={1} percentage={"100%"} rate={"0"} noData={true}/>
        ),
      },
    ],
  ];

  const [chartItems, setChartItems] = React.useState(initialChartItems);
  function splitIndex(columnString) {
    const Index = columnString.split("-");
    return Index;
  }

  const handleOnDragEnd = (result) => {
    console.log("sssXA", result);
    if (!result.destination) return;
    const sourceIndex = splitIndex(result.source.droppableId);
    const destinationIndex = splitIndex(result.destination.droppableId);
    const Source = chartItems[sourceIndex[1]][sourceIndex[2]];
    const Destination = chartItems[destinationIndex[1]][destinationIndex[2]];
    const tempArray = [...chartItems];
    if (
      chartItems[sourceIndex[1]].length === 1 ||
      chartItems[destinationIndex[1]].length === 1
    ) {
      tempArray[sourceIndex[1]] = chartItems[destinationIndex[1]];
      tempArray[destinationIndex[1]] = chartItems[sourceIndex[1]];
    } else {
      tempArray[sourceIndex[1]][sourceIndex[2]] = Destination;
      tempArray[destinationIndex[1]][destinationIndex[2]] = Source;
      console.log("tempaArray", chartItems[sourceIndex[1]].length);
    }

    setChartItems(tempArray);
  };
  console.log("chartItems", chartItems);

    return (
      <DragDropContext onDragEnd={(result, index) => handleOnDragEnd(result, 0)}>
        <Grid container spacing={1} >
          {chartItems.map((column, columnIndex) => (
            <Grid container item spacing={1} xs={12} sm={6} md={3}>
              {column.map(({ id, component }, index) => (
                <Grid  item xs={12}  key={id}>
                  <Droppable
                    droppableId={`column-${columnIndex}-${index}`}
                    direction="horizontal"
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Draggable key={id} draggableId={id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {component}
                            </div>
                          )}
                        </Draggable>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </DragDropContext>
    );
  }
  
export default CallAnalytics
