import { Box, Button, CircularProgress, Drawer, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import variables from "../../../../assets/styles/_colors.scss";
import { CloseButton } from "../../../../components/CloseButton";
import { CustomInputField } from "../../../pipelines/components/CustomInputField";
import { CustomSelect } from "../../../pipelines/components/CustomSelect";
import { createProfile } from "../../../../webservices/UserControlService";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import CustomSnackbar from "../../../../components/CustomSnackbar";

const schema = Yup.object().shape({
    profileName: Yup.string().label("Profile Name").trim()
        .required("Profile Name cannot be empty")
        .min(2, "Profile Name must be atleast 2 chars long")
        .max(200, "Profile Name cannot be more that 200 chars long"),
    cloneFrom: Yup.string().label("Clone From").trim().required("Clone From cannot be empty"),
    profileDescription: Yup.string().label("Description").trim(),
});

export default function CreateProfile(props) {

    const queryClient = useQueryClient();

    const [profiles, setProfiles] = useState([]);

    const [openDrawer, setOpenDrawer] = useState(true);

    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    useEffect(() => {
        if (props.profiles) {
            const profilesDropdown = props.profiles.map(profile => ({ label: profile.profileName, value: profile._id }));
            setProfiles(profilesDropdown);
        }
    }, [props.profiles]);

    useEffect(() => {
        if (profiles && profiles.length > 0) {
            setValue("cloneFrom", props.cloneData ? props.cloneData?._id : profiles[0]?.value);
        }
    }, [profiles]);

    useEffect(() => {
        if (props.cloneData) {
            setValue("cloneFrom", props.cloneData?._id);
        }
    }, [props.cloneData]);

    const methods = useForm(
        {
            defaultValues: {
                "profileName": "",
                "cloneFrom": "",
                "profileDescription": "",
            },
            resolver: yupResolver(schema),
            shouldUnregister: false,
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, setValue, trigger } = methods;

    const { mutateAsync, isPending } = useMutation({
        mutationKey: "createProfile",
        mutationFn: (data) => createProfile(data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllProfiles'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    const onSubmit = async (data) => {
        const isFormValid = await trigger();
        if (isFormValid) {
            // alert(JSON.stringify(data));
            data.createdBy = "User 1";
            mutateAsync(data);
        }
    }

    function handleDrawerClose(data) {
        setOpenDrawer(false);
        props.handleDrawerClose(data);
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <>
            <Drawer anchor="right" open={openDrawer}
                PaperProps={{
                    sx: {
                        minWidth: "35vw", maxWidth: "35vw", pt: "20px", pb: 2, borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                    },
                }}>
                <Box sx={{ height: "100vh" }}>

                    <Typography variant="h5" sx={{ pl: 2 }}>Create Profile
                        <CloseButton disabled={isPending} handleDialogClose={() => handleDrawerClose()} />
                    </Typography>

                    <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 2.5, height: "86vh" }}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} id="profileForm" style={{ height: "100%" }}>
                                <Stack direction="column" spacing={2.5}>
                                    <CustomInputField name={`profileName`} size="medium" label="Profile Name*" />
                                    <CustomSelect name={`cloneFrom`}
                                        inputLabel="Clone From*"
                                        defaultValue={props.cloneData?.profileName}
                                        disabled={props.cloneData ? true : false}
                                        options={profiles} />
                                    <CustomInputField name={`profileDescription`} size="medium" label="Description" rows={5} subType="multi" placeholder="A few words about this profile" />
                                </Stack>
                            </form>
                        </FormProvider>
                    </Box>

                    <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button disabled={isPending} variant="outlined" onClick={() => handleDrawerClose()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={isPending}
                            type="submit"
                            form={"profileForm"}
                            variant="contained"
                            color="primary"
                        > Save
                            {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                        </Button>
                    </Stack>
                </Box>
            </Drawer >

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />
        </>
    );
};
