import React, { useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const RenewalDropDown = ({
  value,
  handleChange,
  items,
  valueParam,
  keyParam,
  isHosting,
  displayParam,
  isArray,
}) => {
  const useStyles = makeStyles(() => ({
    formControl: {
      "& .MuiInputBase-root": {
        border: "none",
        minWidth: "120px",
        justifyContent: "center",
        border: "1px solid rgba(77, 77, 77, 0.3)",
        width: "150px",
        borderRadius: 0,
        fontSize: "13px",
        fontWeight: 600,
      },
      "& .MuiSelect-select": {
        padding: "8px !important",
        border: "none",
      },
      "& .MuiInput-underline:before, & .MuiInput-underline:after": {
        border: "none !important",
      },
      "& .MuiOutlinedInput-root": {
        "&  .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
    },
    select: {
      width: "auto",
      fontSize: "13px",
      border: "none",
      fontWeight: 600,
      "&:focus": {
        backgroundColor: "transparent",
        border: "none",
      },
    },
    selectIcon: {
      position: "relative",

      fontWeight: 600,
    },
    paper: {
      borderRadius: 12,
      marginTop: 8,
      maxHeight: "300px !important",
      overflowY: "auto",
      marginTop: "5px",
    },
    list: {
      paddingTop: 0,
      paddingBottom: 0,

      "& li": {
        fontSize: "13px",
        fontWeight: 500,
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
  }));
  const classes = useStyles();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    getContentAnchorEl: null,
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        onChange={handleChange}
        disableUnderline
        IconComponent={ExpandMoreRoundedIcon}
        MenuProps={menuProps}
        classes={{
          select: classes.select,
          icon: classes.selectIcon,
        }}
      >
        {isArray
          ? items.map((value, index) => (
              <MenuItem key={`${value}-${index}`} value={value}>
                {value}
              </MenuItem>
            ))
          : isHosting
          ? items.map(([interval, price]) => (
              <MenuItem key={`${interval}-${price}`} value={interval}>
                {interval}
              </MenuItem>
            ))
          : items.map((item) => (
              <MenuItem key={item[keyParam]} value={item[valueParam]}>
                {valueParam.toLowerCase() === "year"
                  ? `${item[valueParam]} year${
                      item[valueParam] !== 1 ? "s" : ""
                    }`
                  : item[displayParam]}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

export default RenewalDropDown;
