import * as React from 'react';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { Avatar, Divider, IconButton, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ImageAspectRatioOutlinedIcon from '@mui/icons-material/ImageAspectRatioOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { makeStyles } from '@mui/styles';
import EditPipeline from '../create/EditPipeline';
import DeletePipeline from '../delete/DeletePipeline';
import { editBasicInfo, editFieldsData, editStagesData } from '../../../utils/createpipelineformdata';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CreatePipeline from '../create/CreatePipeline';
import variables from "../../../assets/styles/_colors.scss";

const useStyles = makeStyles((theme) => ({
    pipeline: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

const pipelineActions = [
    {
        name: "Edit Basic Info",
        icon: <EditNoteOutlinedIcon />,
        key: 'editBasicInfo'
    },
    {
        name: "Manage Stages",
        icon: <BarChartOutlinedIcon />,
        key: "editStages"
    },
    {
        name: "Customize Fields",
        icon: <ImageAspectRatioOutlinedIcon />,
        key: "editFields"
    },
    {
        name: "Clone Pipeline",
        icon: <FileCopyOutlinedIcon />,
        key: "clonePipeline"
    },
    {
        name: "Delete Pipeline",
        icon: <DeleteOutlinedIcon />,
        key: "delete"
    }
]

export default function PipelinePopover(props) {
    const classes = useStyles();
    const [showEditPipeline, setShowEditPipeline] = useState(false);
    const [data, setData] = useState(null);
    const [showAddPipeline, setShowAddPipeline] = useState(false);
    const [showDeletePipeline, setShowDeletePipeline] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "editBasicInfo":
                    setShowEditPipeline(true);
                    setData({
                        header: "Edit Basic Info",
                        defaultValue: editBasicInfo,
                        moduleIndex: index
                    });
                    return true;
                case "editStages":
                    setShowEditPipeline(true);
                    setData({
                        header: "Manage Stages",
                        defaultValue: editStagesData,
                        moduleIndex: index
                    });
                    return true;
                case "editFields":
                    setShowEditPipeline(true);
                    setData({
                        header: "Customize Fields",
                        defaultValue: editFieldsData,
                        moduleIndex: index
                    });
                    return true;
                case "clonePipeline":
                    setShowAddPipeline(true);
                    setData({
                        header: "Clone Team Pipeline",
                        defaultValue: Object.assign({}, editBasicInfo, editStagesData, editFieldsData)
                    });
                    return true;
                case "delete":
                    setShowDeletePipeline(true);
                    return true;
                default:
                    return <></>
            }
        }

    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'sub-pipeline-popover' : undefined;

    return (
        <div style={{ 'display': 'inline-block' }}>
            <IconButton aria-label="pipeline-popover" onClick={handleClick} sx={{ mr: 0.5, p: 0.75 }}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.pipeline }}
            >
                {pipelineActions.map((item, index) => (
                    <div key={`pipeline-popover-item-${index}`}>
                        <ListItemButton
                            key={`${item.name}-${index}`}
                            sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemAvatar sx={{ minWidth: 46 }}>
                                <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                                    {item.icon}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </div>
                ))}
            </Popover>
            {showEditPipeline && <EditPipeline data={data} handleDrawerClose={() => setShowEditPipeline(false)} />}
            {showAddPipeline && <CreatePipeline isNewPipeline={true} cloneData={data} handleDrawerClose={() => setShowAddPipeline(false)} />}
            {showDeletePipeline && <DeletePipeline handleClose={() => setShowDeletePipeline(false)}></DeletePipeline>}
        </div>
    );
}