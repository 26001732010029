import Info from "./info/Info";
import Shift from "./Shift/Shift";
import React, { useState, useRef, useEffect } from 'react';

import { Tab, Tabs, Box, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Department from "./Department/Department";
import Expense from "./Expense/Expense";
import Leave from "./Leave/Leave";
import Holidays from "./Holidays/Holidays";
import Designation from './Designation/Designation'
import Branch from "./Branch/Branch";
import TaskCategory from "./TaskCategory/TaskCategory";

const theme = createTheme();

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        height: "90%",
        overflowY: "hidden"
      }}
      {...other}
    >
      {value === index && (
        <Box style={{ height: "90%",borderBottom: "1px solid rgba(0,0,0,0.1)",overflowY: "auto",}}
        >
         {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  tabsContainer: {
    overflowX: 'auto',
    fontFamily: 'Roboto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
  },
  tabLabel: {
    minHeight: 20,
    padding: "16px 5px"
  },
  smallTabs: {
    minHeight: 25,
  },
}));

function Organization({ handleClose }) {
  const classes = useStyles();
  const tabsRef = useRef(null);
  const [value, setValue] = useState(1);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
      const delta = e.deltaY || e.detail || (-e.wheelDelta);
      const tabList = document.querySelector('.MuiTabs-root');
      if (tabList) {
        const targetScrollLeft = tabList.scrollLeft + delta;
        // Ensure smooth scrolling within bounds
        const maxScrollLeft = tabList.scrollWidth - tabList.clientWidth;
        const targetScrollLeftClamped = Math.min(maxScrollLeft, Math.max(0, targetScrollLeft));
        smoothScroll(tabList, tabList.scrollLeft, targetScrollLeftClamped, 50);
      }
    };
  
    if (tabsRef.current) {
      tabsRef.current.addEventListener('wheel', handleWheel);
    }
  
    return () => {
      if (tabsRef.current) {
        tabsRef.current.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  function smoothScroll(element, start, end, duration) {
    let startTime = null;

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const scrollAmount = easeInOutQuad(timeElapsed, start, end - start, duration);
      element.scrollLeft = scrollAmount;
      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          height: "100%",
          width: "100%"
        }}
      >
        <div
          style={{
            width: "100%",
            height: "10%",
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.1)"
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
            className={`${classes.tabsContainer} ${classes.smallTabs}`}
            style={{
              marginLeft: "-1%",
            }}
            sx={{
              ".MuiTab-root .MuiButtonBase-root": {},
              ".MuiTabs-indicator": {
                height: "5px",
                backgroundColor: "rgba(0, 10, 255, 1)",
                borderRadius: "10px",
              },
             
            }}
          >
            <Tab label="Info" className={classes.tabLabel} />
            <Tab label="Shift" className={classes.tabLabel} />
            <Tab label="Department" className={classes.tabLabel} />
            <Tab label="Expense" className={classes.tabLabel} />
            <Tab label="Leave" className={classes.tabLabel} />
            <Tab label="Holidays" className={classes.tabLabel} />
            <Tab label="Designation" className={classes.tabLabel} />
            <Tab label="Branch" className={classes.tabLabel} />
            <Tab label="Task Category" className={classes.tabLabel} />
          </Tabs>
          <CloseIcon
            onClick={handleClose}
            style={{
              width: "16px",
              height: "16px",
              marginLeft: "16px",
              marginRight: "10px",
              cursor: "pointer",
              position: "relative",
              top: "1",
              right: "5",
            }}
          />
        </div>
        <CustomTabPanel value={value} index={0}>
          <Info />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Shift />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Department />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Expense />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Leave />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <Holidays />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <Designation />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <Branch />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          <TaskCategory />
        </CustomTabPanel>
      </div>
    </ThemeProvider>
  );
}

export default Organization;
