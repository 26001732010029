import * as React from "react";
import {
    Controller,
    get,
    useFormContext
} from "react-hook-form";
import variables from "../../../assets/styles/_colors.scss";
import { TextField } from "@mui/material";

export const CustomCurrencyField = ({ ...props }) => {
    const { control, formState } = useFormContext();
    const error = get(formState.errors, props.name);

    return (
        <Controller
            control={control}
            name={props.name}
            rules={props.rules}
            defaultValue={props.defaultValue ? props.defaultValue : ""}
            render={({ field }) => (
                <TextField
                    fullWidth
                    id={props.name}
                    type="number"
                    label={props.label}
                    variant="outlined"
                    placeholder={props.placeholder}
                    size={props.size ? props.size : "medium"}
                    sx={{
                        ".MuiOutlinedInput-root": {
                            borderLeft: props?.rules?.required?.value ? '3px solid red !important' : `1px solid ${variables.borderColor}`
                        }
                    }}
                    {...field}
                    {...(props.subType === "multi" ? { rows: (props.rows ? props.rows : 4), "multiline": true } : {})}
                    error={Boolean(error)}
                    helperText={error?.message}
                    InputLabelProps={{ shrink: true }}
                />
            )}
        />
    );
};
