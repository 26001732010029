import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Style from "./Style/SideBar.module.css";
import Collapse from "@mui/material/Collapse";
import { useRecoilState } from "recoil";
import { isSideBarActive } from "../../Atoms/SideBarAtom";
const CommonSideBar = ({ links, title }) => {
  const location = useLocation();
  const history = useNavigate();

  const isActive = (path) => location.pathname === path;

  const handleClick = (path) => {
    if (!isActive(path)) {
      history(path);
    }
  };
  const [isOpen, setIsOpen] = useRecoilState(isSideBarActive);

  return (
    <Collapse orientation="horizontal" in={isOpen} timeout={500}>
    <div className={Style.sideBar_wrapper}>
      <div
        style={{
          fontSize: "15px",
          fontWeight: 500,
          textAlign: "center",
          padding: "10px 0px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        {title}
      </div>

      <div className="webServicesTab MyAccountSidebarHover">
        {links.map((link) => (
          <div
            key={link.path}
            className={`link ${
              isActive(link.path) || isActive(link.path)
                ? "MyAccountSidebar-active"
                : ""
            } ${Style.Sidebar_options}`}
            onClick={() => handleClick(link.path)}
          >
            <span className="MyAccountSidebar-font">{link.label}</span>
          </div>
        ))}
      </div>
    </div>
    </Collapse>
  );
};

export default CommonSideBar;
