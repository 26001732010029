import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlLabel, Switch } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";

export const CustomSwitch = (props) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={props.name}
            control={control}
            defaultValue={props.defaultChecked}
            render={({ field: { onChange, value } }) => (
                <FormControlLabel
                    sx={
                        props.disabled && {
                            "& .MuiSwitch-root": {
                                opacity: 0.4
                            }
                        }}
                    control={
                        <Switch
                            sx={{
                                ".Mui-checked+.MuiSwitch-track": {
                                    color: `${variables.linkColor}`
                                }
                            }}
                            checked={value}
                            onChange={onChange}
                            disabled={props.disabled}
                        />
                    }
                    label={props.label}
                />
            )}
        />
    )
};
