import { Card, CardContent, Stack, TextField, Tooltip, Typography, IconButton, Autocomplete, Button, } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useState } from "react";
import variables from "../../../../assets/styles/_colors.scss";
import { useEffect } from "react";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { setSubPipelineAsDefault, updateSubPipelineName } from "../../../../webservices/PipelineService";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { settings_atom } from "../../../../../../Atoms/CrmAtoms";
import { useRecoilState } from "recoil";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import SubPipelinePopover from "./SubPipelinePopover";
import StagesConfiguration from "./StagesConfiguration";

export default function SubPipeline(props) {

    const queryClient = useQueryClient();
    const [settingsAtom,] = useRecoilState(settings_atom);
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const [subPipelineHoverIndex, setSubPipelineHoverIndex] = useState(null);

    const [editSubPipelineName, setEditSubPipelineName] = useState(false);
    const [subPipelineName, setSubPipelineName] = useState(null);
    const [highlightStage, setHighlightStage] = useState(false);

    useEffect(() => {
        setSubPipelineName(props.subPipeline?.subPipelineName);
    }, [props.subPipeline]);

    const markAsDefault = () => {
        if (!props.subPipeline.isDefault) {
            mutateAsync({
                pipelineId: props.teamPipelineId,
                subPipelineId: props.subPipeline._id
            });
        }
    }

    const { mutateAsync } = useMutation({
        mutationKey: "setSubPipelineAsDefault",
        mutationFn: (data) => setSubPipelineAsDefault(data.pipelineId, data.subPipelineId),
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
            setSnackbarInfo({
                open: true,
                severity: 'success',
                message: response?.message
            });
        }
    });

    const { mutateAsync: handleNameUpdate } = useMutation({
        mutationKey: "updateSubPipelineName",
        mutationFn: (data) => updateSubPipelineName(props.subPipeline._id, subPipelineName),
        onSuccess: (response) => {
            queryClient.invalidateQueries({ queryKey: ['getAllPipelines'] });
            setSnackbarInfo({
                open: true,
                severity: 'success',
                message: response?.message
            });
        }
    });

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <>
            <Card sx={{ width: 350, height: "75vh", borderRadius: "4px !important", boxShadow: 'none', mr: 1, border: highlightStage ? `1px solid ${variables.linkColor}` : `1px solid ${variables.borderColor}` }}
                onMouseEnter={() => { setSubPipelineHoverIndex(props.subPipelineIndex); }}
                onMouseLeave={() => { setSubPipelineHoverIndex(null); }}>
                <CardContent sx={{ p: 0, overflowX: 'hidden', overflowY: 'auto', height: '75vh', pb: 1 }}>
                    {!editSubPipelineName ?
                        <Stack direction="row" alignItems="center" sx={{ backgroundColor: `${variables.lightGreyBackgroundColor}`, pt: 1, pb: 1, pl: 2, pr: 1 }}>
                            <Stack direction="column" width='80%'>
                                <Stack direction="row" alignItems="center">
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        {props.subPipeline.subPipelineName}
                                        <Tooltip title={props.subPipeline.isDefault ? "Default Sub-pipeline" : "Set as default"} >
                                            <Typography component="span">
                                                <IconButton onClick={() => markAsDefault()} disabled={props.subPipeline.isDefault || settingsAtom.freezeActions} sx={{
                                                    p: 0.25, ml: 1, mt: -0.5, visibility: props.subPipeline.isDefault ? 'inherit' : subPipelineHoverIndex === props.subPipelineIndex ? 'inherit' : 'hidden'
                                                }}>
                                                    <StarRoundedIcon sx={{
                                                        color: props.subPipeline.isDefault ? `${variables.addIconColor}` : `${variables.borderColor}`
                                                    }} />
                                                </IconButton>
                                            </Typography>
                                        </Tooltip>
                                    </Typography>
                                </Stack>
                                <Typography sx={{ width: '80%', fontSize: 12, color: `${variables.greyTextColor}` }}>
                                    {props.subPipeline.openStages.length + props.subPipeline.closedStages.length} Stages
                                </Typography>
                            </Stack>

                            <Stack direction="row" sx={{ visibility: subPipelineHoverIndex === props.subPipelineIndex ? 'inherit' : 'hidden' }}>
                                <Tooltip title={"Edit Sub-Pipeline Name"} >
                                    <Typography component="span" >
                                        <IconButton aria-label="edit" size="medium" onClick={() => setEditSubPipelineName(true)} disabled={settingsAtom.freezeActions}>
                                            <EditOutlinedIcon fontSize="inherit" />
                                        </IconButton>
                                    </Typography>
                                </Tooltip>

                                <SubPipelinePopover subPipeline={props.subPipeline} teamPipelineId={props.teamPipelineId} subPipelinesCount={props.subPipelinesCount} handlePopoverClose={() => setSubPipelineHoverIndex(null)} />
                            </Stack>
                        </Stack>

                        : <Stack direction="row" spacing={1} sx={{ p: 2, pt: 1, pb: 0.6 }} width="100%" alignItems="center">
                            <TextField
                                size="small"
                                fullWidth
                                autoFocus
                                variant="outlined"
                                value={subPipelineName}
                                onChange={event => setSubPipelineName(event.target.value)}
                            />
                            <IconButton sx={{ height: 28, width: 28, bgcolor: `${variables.lightGreyBackgroundColor} !important`, color: `${variables.pipelineBoardText}`, border: `1px solid ${variables.borderColor}` }} size="small" onClick={() => setEditSubPipelineName(false)}>
                                <CloseOutlinedIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton sx={{ height: 28, width: 28, bgcolor: `${variables.linkColor} !important`, color: "#fff" }} size="small" onClick={() => setEditSubPipelineName(false)}>
                                <CheckOutlinedIcon onClick={handleNameUpdate} fontSize="inherit" />
                            </IconButton>

                        </Stack>
                    }

                    <StagesConfiguration label="Open Stages" isOpenStage={true} stages={props.subPipeline.openStages} teamPipelineId={props.teamPipelineId} subPipelineId={props.subPipeline._id} highlightStage={(val) => setHighlightStage(val)} />

                    <StagesConfiguration label="Closed Stages" isOpenStage={false} stages={props.subPipeline.closedStages} teamPipelineId={props.teamPipelineId} subPipelineId={props.subPipeline._id} highlightStage={(val) => setHighlightStage(val)} />

                </CardContent>
            </Card >

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

        </>
    )
}
