import React , {useState} from "react";
import ContactSideBar from "./ContactSideBar";
import { Box, Typography, Grid, Paper , Button} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";

import Allcontactlist from "./Allcontactlist";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Contacts() {

  return (
    <div className="contacts" style={{ width: "100%" , display:"flex" , flexDirection : "column"}}>
      {/* <ContactSideBar /> */}
   
   <Allcontactlist />
    </div>
  );
}
