
import axios from "axios";
import React, { useEffect, useState } from "react";

import { useRecoilState } from "recoil";
import MapComponentNew from "./MapComponentNew";

import { onOffDuty, snackbarr } from "./trackeyatoms";
import TrackeyBox from "./TrackeyBoxList";

import "../../styles/trackey.css";
import { api_url } from "../../globalSettings";
import { useLoadScript } from "@react-google-maps/api";
import { Typography } from "@mui/material";

import {
    Button,
  
    TableCell,
    Paper,
    Table,
   
    TableRow,
    TableContainer,
     } from "@mui/material";
//import recoil state
import {
  login_token,
  login_companyid,
} from "../../Atoms/LoginAtoms";

import Grid from '@mui/material/Grid';
import DatePicker from "react-multi-date-picker"
import 'react-datepicker/dist/react-datepicker.css';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './TrackeyeDashBoard.css'


export default function TrackeyeDashBoard() {
    const [emponlvelist, setEmponlvelist] = useState([]);
  const [empListonduty, setEmployeelistonduty] = useState([]);
  const [empListoffduty, setEmployeelistoffduty] = useState([]);

  const [ONFFDuty, setONFFDuty] = useRecoilState(onOffDuty);

  // ========================= get onduty employe =======================

  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [company_idd, setCompany_idd] = useRecoilState(login_companyid);
  const [latiLOngi, setlatiLOngi] = useState([]);

  const [totemplist, setTotalEmpList] = useState([]);


  const [ondutyCount, setOndutyCount] = useState([]);
  const [offdutyCount, setOffdutyCount] = useState([]);
  const [onlveCount, setonlveCount] = useState([]);
  const [totalCount, setTotalCount] = useState([]);

  // ================================  getempondutylist api call =====================================

  function getempondutylist() {
 
    const params = {
      token: loginToken1,
      // device: "mobile",
      company_id: company_idd,
    };

    axios
      .get(`${api_url}/employee/get_company_employee_onduty_details`, {
        params: { ...params },
      })

      .then((response) => {
       
        setEmployeelistonduty(response.data.data);
 
      })
      .catch(function (error) {
        if (error.response) {
       
        } else if (error.request) {
       
        } else {
       
        }
      
      });
    
  }

  // ================================  getempoffdutylist api call =====================================

  function getempoffdutylist() {
 
    const params = {
      token: loginToken1,
      // device: "mobile",
      company_id: company_idd,
    };
 
    axios
      .get(`${api_url}/employee/get_company_employee_offduty_details`, {
        params: { ...params },
      })

      .then((response) => {
       
        setEmployeelistoffduty(response.data.data);
        setOffdutyCount(response.data.data.off_duty_employee_count);
        // off_duty();
      })
      .catch(function (error) {
        if (error.response) {
       
        } else if (error.request) {
        
        } else {
       
        }
       
      });
 
  }

  // ================================  Leave api call =====================================

  function getemponleavelist() {
   
    const params = {
      token: loginToken1,
      company_id: company_idd,
    };
    axios
      .get(`${api_url}/employee/get_company_leave_employee_details`, {
        params: { ...params },
      })

      .then((response) => {
        setlatiLOngi(response.data.data.on_duty_employee_details);
        setEmponlvelist(response.data.data);
        setonlveCount(response.data.data.leave_employee_count);
      })
      .catch(function (error) {
        if (error.response) {
        
        } else if (error.request) {
        
        } else {
         
        }
      
      });

  }

  // ================================  total api call =====================================

  function gettotalemplist() {
   
    const params = {
      token: loginToken1,
      company_id: company_idd,
    };
    axios
      .get(`${api_url}/employee/totalEmployee`, {
        params: { ...params },
      })

      .then((response) => {
       
        setTotalEmpList(response.data.data);
        setTotalCount(response.data.data.all_employee_count);
     
      })
      .catch(function (error) {
        if (error.response) {
      
        } else if (error.request) {
       
        } else {
         
        }
     
      });
    
  }

  // ================================  total api call =====================================

  function getempnodatalist() {
    const params = {
      token: loginToken1,
      company_id: company_idd,
    };
    axios
      .get(`${api_url}/employee/get_company_no_data_employee_details`, {
        params: { ...params },
      })

      .then((response) => {
      
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
   
        } else if (error.request) {
        
        } else {
         
        }
   
      });
  }

  

  useEffect(() => {
    if (ONFFDuty === "ON_DUTY") {

      setTotalEmpList("");
      setEmployeelistoffduty("");
      getempondutylist();
        getempondutylist();
    } else if (ONFFDuty == "OFF_DUTY") {
      setTotalEmpList("");
      setEmployeelistonduty("");
      getempoffdutylist();
        getempoffdutylist();

    } else if (ONFFDuty == "ON_LEAVE") {
 
      getemponleavelist();
      setEmployeelistoffduty("");
      setEmployeelistonduty("");
      setTotalEmpList("");
    } else if (ONFFDuty == "NO_DATA") {
      getempnodatalist();
      setEmployeelistoffduty("");
      setEmployeelistonduty("");
      setTotalEmpList("");
    } else if (ONFFDuty == "ALL") {
    
      setEmployeelistoffduty("");
      setEmployeelistonduty("");
      gettotalemplist();
        gettotalemplist();

    } else {
      gettotalemplist();
        gettotalemplist();

    }
 }, [ONFFDuty]);



  const [topTenExpenses , setTopTenExpense] = useState([])
  const [topTenDistanceTravelleds , setTopTenDistanceTravelled] = useState([])
  const [topTenLeaves , setTopTenLeave] = useState([])
  const [topTenPerformance , setTopTenPerformance] = useState([])
  const [start , setStart] = useState('');
  const [end , setEnd] = useState('');


  const [from , setFrom] = useState('');
  const [to , setTo] = useState('');


  function CustomRangeInput({ onFocus, value, separator }) {
    let values = value.split(separator);  
    let from = values[0] || "";
    setFrom(from)
    let to = values[1] || "";
    setTo(to)
    value = from && to ? "from " + from + ", to " + to : from
    
    return (
        <>
        <input
        onFocus={onFocus}
        value={value}
        style={{ display: "none",}}
        readOnly
        />
        <Button onFocus={onFocus}  value={value}>
         <CalendarMonthIcon />
        </Button>
       
        </>
      
  
    )
  }


  const thisMonth = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString()
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString()


    setStart(firstDay);
    setEnd(lastDay);
  }

  const topTenExpense = () => {
    const params = {
        company_id: company_idd,
        startDate:start,
        endDate:end
      };
      axios
        .get(`${api_url}/employee/topTenExpense`, {
          params: { ...params },
        })
  
        .then((response) => {
          
          setTopTenExpense(response.data.data.topTenExpenses);
          // off_duty();
        })
        .catch(function (error) {
          if (error.response) {
          
          } else if (error.request) {
      
          } else {
          
          }
       
        });
  }

  const topTenDistanceTravelled = () => {
    const params = {
        company_id: company_idd,
        startDate:start,
        endDate:end
      };
      axios
        .get(`${api_url}/employee/topTenDistanceTravelled`, {
          params: { ...params },
        })
  
        .then((response) => {
          setTopTenDistanceTravelled(response.data.data.topTenDistanceTravelled);
                 })
        .catch(function (error) {
          if (error.response) {
           
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
          } else {
            // Something happened in setting up the request that triggered an Error
          }
        });
  }

  const topTenLeave = () => {
    const params = {
        company_id: company_idd,
        startDate:start,
        endDate:end
      };
      axios
        .get(`${api_url}/employee/topTenLeave`, {
          params: { ...params },
        })
  
        .then((response) => {
      
          setTopTenLeave(response.data.data.topTenLeaveTakers);
         
          // off_duty();
        })
        .catch(function (error) {
          if (error.response) {
           
          } else if (error.request) {
          
          } else {
        
          }
        
        });
  }


  const topTenPerfomances = () => {
    const params = {
        company_id: company_idd
      };
      axios
      // https://server.nowdigitaleasy.com:3505/employee/totalEmployee?
        .get(`${api_url}/employee/TopTenPerformers`, {
          params: { ...params },
        })
  
        .then((response) => {
          // setlatiLOngi(response.data.data.all_employee_details);
          //setEmployeelistoffduty(response.data.data);
          setTopTenPerformance(response.data.data);
         
          // off_duty();
        })
        .catch(function (error) {
          if (error.response) {
          
          } else if (error.request) {
       
          } else {
          
          }
      
        });
  }



  useEffect(() => {
    getempoffdutylist();
    getempondutylist();
    getemponleavelist();
    gettotalemplist();
    thisMonth();
  }, []);

  useEffect(() => {
    topTenExpense();
    topTenDistanceTravelled();
    topTenLeave();
    topTenPerfomances()
  }, [end])
  
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyB3ZlA9dlt1bnsJyUPn-Ss3IPM8awi0F2g", // Add your API key
  });


 
  return (
    <div>
         <div id="trackeye">
                <div id="trakey_EmployeeListMap_left" style={{height : "100vh" , width : "600px"}}>
                  <TrackeyBox //trackeyboxlist
                    totemplist={totalCount}
                    empListonduty={ondutyCount}
                    empListoffduty={offdutyCount}
                    emponlvelist={onlveCount}
                  />

{isLoaded ? (
                    Object.keys(totemplist).length ||
                    Object.keys(empListonduty).length ||
                    Object.keys(empListoffduty).length ||
                    Object.keys(emponlvelist).length >= 1 ? (
                      <MapComponentNew
                        totemplist={totemplist}
                        empListonduty={empListonduty}
                        empListoffduty={empListoffduty}
                        emponlvelist={emponlvelist}
                      />
                    ) : null
                  ) : (
                    ""
                  )}

                </div>

                <div className="trakey_EmployeeListMap_right">
                <Grid container spacing={2}>
        <Grid item xs={6}>
            <div className="topTitles">
                <Typography style={{fontWeight : 700}}>Top 10 Spender</Typography>
                <div style={{display : "flex" , flex:"row" , alignItems : "center"}}>
                <Typography style={{fontWeight : 700 , fontSize : 12}}>{Object.keys(topTenExpenses).length >= 1 ? `[${new Date(start).toDateString()} to ${new Date(end).toDateString()}]`   : ""}</Typography>
            <DatePicker     
      range
      eachDaysInRange
      render={<CustomRangeInput />}
    >
    {}
     <Button  className="button1" style={{marginBottom : "10px"}}>Apply</Button>
        </DatePicker>
        </div>
            </div>
        
        <TableContainer component={Paper} style={{minHeight : "400px" , display : "flex" , alignItems : "flex-start" , justifyContent  : "center"}}>
        {topTenExpenses.length >= 1 ?
                <Table stickyHeader>
              {/* <TableHead>
                <TableRow>
                  <TableCell  className="table_head ">Top Expenser</TableCell>
                  <TableCell align="center" className="table_head"> </TableCell>
                </TableRow>
              </TableHead> */}
  {topTenExpenses.map((item) => {
           return ( 
                  <TableRow
                    hover
                    key={item}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                    //onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}
                  >
                    <TableCell
                      title="Employee Name"
                      className="companypersonalinfo"
                    >
                      {item.employee.first_name}
                    </TableCell>

                    <TableCell title="Expense Amount"  className="companypersonalinfo">
                    {item.amount}
                    </TableCell>
              
                  </TableRow>);
          })}
                
            </Table>
             : <Typography style={{fontWeight : 700, marginTop :20}}>No Data Found</Typography> }  
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
        <div className="topTitles">
                <Typography style={{fontWeight : 700}}>Top 10 Traveler</Typography>
                <div style={{display : "flex" , flex:"row" , alignItems : "center",}}>
                <Typography style={{fontWeight : 700 , fontSize : 12}}>{Object.keys(topTenDistanceTravelleds).length >= 1 ? `[${new Date(start).toDateString()} to ${new Date(end).toDateString()}]`   : ""}</Typography>
            <DatePicker     
      range
      eachDaysInRange
      render={<CustomRangeInput />}
    >
    {}
     <Button  className="button1" style={{marginBottom : "10px"}}>Apply</Button>
        </DatePicker>
        </div>
            </div>

        <TableContainer component={Paper} style={{minHeight : "400px" , display : "flex" , alignItems : "flex-start" , justifyContent  : "center"}}>
        {Object.keys(topTenDistanceTravelleds).length >= 1 ?
            <Table stickyHeader>
              {/* <TableHead>
                <TableRow>
                  <TableCell  className="table_head ">Top Distance Travelled</TableCell>
                  <TableCell align="center" className="table_head"></TableCell>
                </TableRow>
              </TableHead> */}

             { topTenDistanceTravelleds.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                    //onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}
                  >
               
                    <TableCell
                      title="Employee Name"
                      className="companypersonalinfo"
                    >
                      {item.employee.first_name}
                    </TableCell>

                    <TableCell  title="Distance Traveled" className="companypersonalinfo">
                    {item.total}
                    </TableCell>
              
                  </TableRow>
             )})}
           
            </Table> : <Typography style={{fontWeight : 700 , marginTop :20}}>No Data Found</Typography> }
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
        <div className="topTitles">
                <Typography style={{fontWeight : 700}}>Top 10 Leave Taker</Typography>
                <div style={{display : "flex" , flex:"row" , alignItems : "center"}}>
                <Typography style={{fontWeight : 700 , fontSize : 12}}>{Object.keys(topTenLeaves).length >= 1 ? `[${new Date(start).toDateString()} to ${new Date(end).toDateString()}]`   : ""}</Typography>
            <DatePicker     
      range
      eachDaysInRange
      render={<CustomRangeInput />}
    >
    {}
     <Button  className="button1" style={{marginBottom : "10px"}}>Apply</Button>
        </DatePicker>
        </div>
            </div>
        <TableContainer component={Paper} style={{minHeight : "400px" , display : "flex" , alignItems : "flex-start" , justifyContent  : "center"}}>
        {Object.keys(topTenLeaves).length >= 1 ?  
            <Table stickyHeader>
              {/* <TableHead>
                <TableRow>
                  <TableCell   className="table_head ">Top Leave Taker</TableCell>
                  <TableCell align="center"  className="table_head"></TableCell>
                </TableRow>
              </TableHead> */}
{topTenLeaves.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                    //onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}
                  >
               
                    <TableCell
                    
                    title="Employee Name"
                      className="companypersonalinfo"
                    >
                      {item.employee.first_name}
                    </TableCell>

                    <TableCell title="Total Days"  className="companypersonalinfo">
                    {item.total}
                    </TableCell>
              
                  </TableRow>
                );
              })}
            </Table>
             :  <Typography style={{fontWeight : 700 , marginTop :20}}>No Data Found</Typography> }  
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
        <div className="topTitles">
                <Typography style={{fontWeight : 700 }}>Top 10 Performance</Typography>
                <div style={{display : "flex" , flex:"row" , alignItems : "center"}}>
                {/* <Typography style={{fontWeight : 700 , fontSize : 12}}>{Object.keys(topTenDistanceTravelleds).length >= 1 ? `[${new Date(start).toDateString()} to ${new Date(end).toDateString()}]`   : ""}</Typography> */}
            <Typography style={{fontWeight : 700 , fontSize : 12}}> Total</Typography>
      <DatePicker     
      range
      eachDaysInRange
      render={<CustomRangeInput />}
    >
    {}
     {/* <Button  className="button1" style={{marginBottom : "10px"}}>Apply</Button> */}
        </DatePicker>
        </div>
            </div>
        <TableContainer component={Paper} style={{minHeight : "400px" , display : "flex" , alignItems : "flex-start" , justifyContent  : "center"}}>
        {Object.keys(topTenPerformance).length >= 1 ? <Table stickyHeader>
              {/* <TableHead>
                <TableRow>
                  <TableCell align="center"  className="table_head ">Employe Name</TableCell>
                  <TableCell align="center" className="table_head">Expenses Amount</TableCell>
                </TableRow>
              </TableHead> */}

           {topTenPerformance.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item}
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                    //onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}
                  >
               
                    <TableCell
                    align="center"
                    title="Employee Name"
                      className="companypersonalinfo"
                    >
                      {item.employee.first_name}
                    </TableCell>

                    <TableCell title="Employee Name" align="center" className="companypersonalinfo">
                    {item.percentage}%
                    </TableCell>
                  </TableRow>
                );
              })} 
            </Table> : <Typography style={{fontWeight : 700 , marginTop :20}}>No Data Found</Typography>}
          </TableContainer>
        </Grid>
      </Grid>
                </div>
              </div>
    </div>
  )
}



  