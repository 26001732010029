import { Stack, Typography } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { useEffect } from "react";
import CommonDateFields from "./CommonDateFields";

const options = [
    {
        label: "In the Last",
        value: "last"
    },
    {
        label: "On",
        value: "on"
    },
    {
        label: "Before",
        value: "before"
    },
    {
        label: "After",
        value: "after"
    },
    {
        label: "Between",
        value: "between"
    },
    {
        label: "Today",
        value: "today"
    },
    {
        label: "Yesterday",
        value: "yesterday"
    },
    {
        label: "This Week",
        value: "week"
    },
    {
        label: "This Month",
        value: "month"
    },
    {
        label: "This Year",
        value: "year"
    },
    {
        label: "Last Week",
        value: "last_week"
    },
    {
        label: "Last Month",
        value: "last_month"
    },
    {
        label: "Till today",
        value: "till_today"
    }
];


const notesDropdown = [
    {
        label: "Without Any Notes",
        value: "no_notes"
    },
    {
        label: "With Notes",
        value: "with_notes"
    }
];

const Notes = (props) => {

    const singleField = ["today", "yesterday", "week", "month", "year", "last_week", "last_month", "till_today"]
    const showDateField = ["on", "before", "after"];
    const showTwoDateFields = ["between"];
    const customFields = ["last"];

    useEffect(() => {
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>

            <Stack direction="row" sx={{ pt: 1, flexWrap: 'wrap' }} >

                <Stack direction="row" sx={{ width: '55%' }}>
                    <CustomSelect size="small" options={notesDropdown} name={`${props.name}.notes`} defaultValue={notesDropdown[0].value} />
                </Stack>

                <CommonDateFields
                    name={props.name}
                    field={props.field}
                    options={options}
                    singleField={singleField}
                    showDateField={showDateField}
                    showTwoDateFields={showTwoDateFields}
                    customFields={customFields} />

            </Stack >
        </>

    )
};

export default Notes;