export const editBasicInfo = {
    pipelineName: "Development Pipeline",
    singular: "Ticket",
    plural: "Tickets",
    permissions: [
        { value: "administration", label: "Administration" },
        { value: "standard", label: "Standard" }
    ]
};

export const editStagesData = {
    showSubPipeline: false,
    subPipelineName: "",
    openStages: [{
        label: "Open Stage 3",
        value: "3"
    }],
    closedStages: [{
        label: "Close Stage 2",
        value: "2"
    }]
};

export const editFieldsData = {
    fields: {
        basic: {
            name: 'Deal Information',
            singular: 'Deal',
            plural: 'Deals',
            permissions: [
                { value: "administration", label: "Administration" },
                { value: "standard", label: "Standard" }
            ],
            fields: [
                {
                    key: "name",
                    id: Math.random().toString(),
                    label: 'Name',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Name cannot be empty"
                        }
                    }
                },
                {
                    key: "subPipeline",
                    id: Math.random().toString(),
                    label: 'Sub-Pipeline',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'subPipeline',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Sub-Pipeline cannot be empty"
                        }
                    }
                },
                {
                    key: "stage",
                    id: Math.random().toString(),
                    label: 'Stage',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'stage',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Stage cannot be empty"
                        }
                    }
                },
                {
                    key: 'closingDate',
                    id: Math.random().toString(),
                    label: 'Closing Date',
                    type: 'date',
                    subType: 'datePicker',
                    editable: true,
                    deletable: true,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            label: "Closing Date cannot be empty"
                        }
                    }
                },
                {
                    key: 'companyName',
                    id: Math.random().toString(),
                    label: 'Company Name',
                    editable: true,
                    deletable: true,
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Company Name cannot be empty"
                        }
                    }
                }
            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
                {
                    key: "currentSalary",
                    id: Math.random().toString(),
                    label: 'Current Salary',
                    editable: true,
                    deletable: true,
                    type: 'numerical',
                    subType: 'decimal',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Current Salary cannot be empty"
                        }
                    }
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'multi',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Description cannot be empty"
                        }
                    }
                }
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: []
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: true,
        data: [
            {
                key: "product",
                _id: Math.random().toString(),
                label: 'Product',
                editable: false,
                type: 'lookup',
                lookup: {
                    relatedListName: 'Pipelines',
                    selectedModule: 'products'
                },
                rules: {
                    required: {
                        value: true,
                        message: "Product cannot be empty"
                    }
                },
                selected: true,
                disabled: true
            },
            {
                key: "productCode",
                _id: Math.random().toString(),
                label: 'Product Code',
                editable: true,
                alwaysMandatory: false,
                type: 'text',
                subType: 'single',
                unique: false,
                systemDefined: true,
                selected: true,
                disabled: false
            },
            {
                key: "productCategory",
                _id: Math.random().toString(),
                label: "Product Category",
                type: "picklist",
                editable: true,
                rules: {},
                subType: "single",
                picklist: {
                    "defaultValue": {
                        "label": "None",
                        "value": "none"
                    },
                    "sortAscending": false,
                    "options": [
                        {
                            "label": "None",
                            "value": "none"
                        },
                        {
                            "label": "Hardware",
                            "value": "hardware"
                        },
                        {
                            "label": "Software",
                            "value": "software"
                        },
                        {
                            "label": "CRM Apps",
                            "value": "crmApps"
                        }
                    ]
                },
                selected: true,
                disabled: false
            },
            {
                key: "unitPrice",
                _id: Math.random().toString(),
                label: 'Unit Price',
                type: 'currency',
                editable: true,
                systemDefined: true,
                rules: {
                    required: {
                        value: true,
                        message: "Price cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "description",
                _id: Math.random().toString(),
                label: 'Description',
                editable: true,
                alwaysMandatory: false,
                type: 'text',
                subType: 'multi',
                rows: 1,
                unique: false,
                systemDefined: true,
                selected: false,
                disabled: false
            },
            // {
            //     key: "productActive",
            //     label: "Product Active",
            //     id: Math.random().toString(),
            //     type: "checkbox",
            //     checkbox: {
            //         defaultChecked: true
            //     }
            // },
            {
                key: "quantity",
                _id: Math.random().toString(),
                label: "Quantity",
                type: "numerical",
                editable: true,
                subType: "number",
                rules: {
                    required: {
                        value: true,
                        message: "Quantity cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "discount",
                _id: Math.random().toString(),
                label: "Discount",
                type: "numerical",
                editable: true,
                subType: "number",
                rules: {
                    required: {
                        value: true,
                        message: "Discount cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "total",
                _id: Math.random().toString(),
                label: 'Total',
                type: 'currency',
                editable: false,
                systemDefined: true,
                selected: true,
                disabled: true
            }
        ]
    },
    dealProducts: [
    ]
}

export const createPipelineFormData = {
    pipelineName: "",
    singular: "",
    plural: "",
    permissions: [ ],
    showStages: true,
    openStages: [
        { label: null, value: null },
        { label: null, value: null }
    ],
    closedStages: [
        { label: null, value: null },
        { label: null, value: null }
    ],
    fields: {
        basic: {
            name: 'Basic Info',
            fields: [
                {
                    key: "name",
                    id: Math.random().toString(),
                    label: 'Name',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Name cannot be empty"
                        }
                    }
                },
                {
                    key: "subPipeline",
                    id: Math.random().toString(),
                    label: 'Sub-Pipeline',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'subPipeline',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Sub-Pipeline cannot be empty"
                        }
                    }
                },
                {
                    key: "stage",
                    id: Math.random().toString(),
                    label: 'Stage',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'stage',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Stage cannot be empty"
                        }
                    }
                },
                {
                    key: 'closingDate',
                    id: Math.random().toString(),
                    label: 'Closing Date',
                    type: 'date',
                    subType: 'datePicker',
                    editable: true,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            label: "Closing Date cannot be empty"
                        }
                    }
                },
                {
                    key: 'companyName',
                    id: Math.random().toString(),
                    label: 'Company Name',
                    editable: true,
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Company Name cannot be empty"
                        }
                    }
                }

            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
                {
                    key: "currentSalary",
                    id: Math.random().toString(),
                    label: 'Current Salary',
                    editable: true,
                    type: 'numerical',
                    subType: 'decimal',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Current Salary cannot be empty"
                        }
                    }
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    editable: true,
                    type: 'text',
                    subType: 'multi',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Description cannot be empty"
                        }
                    }
                }
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: true,
        data: [
            {
                key: "product",
                _id: Math.random().toString(),
                label: 'Product',
                editable: false,
                type: 'lookup',
                lookup: {
                    relatedListName: 'Pipelines',
                    selectedModule: 'products'
                },
                rules: {
                    required: {
                        value: true,
                        message: "Product cannot be empty"
                    }
                },
                selected: true,
                disabled: true
            },
            {
                key: "productCode",
                _id: Math.random().toString(),
                label: 'Product Code',
                editable: true,
                alwaysMandatory: false,
                type: 'text',
                subType: 'single',
                unique: false,
                systemDefined: true,
                selected: true,
                disabled: false
            },
            {
                key: "productCategory",
                _id: Math.random().toString(),
                label: "Product Category",
                type: "picklist",
                editable: true,
                rules: {},
                subType: "single",
                picklist: {
                    "defaultValue": {
                        "label": "None",
                        "value": "none"
                    },
                    "sortAscending": false,
                    "options": [
                        {
                            "label": "None",
                            "value": "none"
                        },
                        {
                            "label": "Hardware",
                            "value": "hardware"
                        },
                        {
                            "label": "Software",
                            "value": "software"
                        },
                        {
                            "label": "CRM Apps",
                            "value": "crmApps"
                        }
                    ]
                },
                selected: true,
                disabled: false
            },
            {
                key: "unitPrice",
                _id: Math.random().toString(),
                label: 'Unit Price',
                type: 'currency',
                editable: true,
                systemDefined: true,
                rules: {
                    required: {
                        value: true,
                        message: "Price cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "description",
                _id: Math.random().toString(),
                label: 'Description',
                editable: true,
                alwaysMandatory: false,
                type: 'text',
                subType: 'multi',
                rows: 1,
                unique: false,
                systemDefined: true,
                selected: false,
                disabled: false
            },
            {
                key: "quantity",
                _id: Math.random().toString(),
                label: "Quantity",
                type: "numerical",
                editable: true,
                subType: "number",
                rules: {
                    required: {
                        value: true,
                        message: "Quantity cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "discount",
                _id: Math.random().toString(),
                label: "Discount",
                type: "decimal",
                editable: true,
                subType: "number",
                rules: {
                    required: {
                        value: true,
                        message: "Discount cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "total",
                _id: Math.random().toString(),
                label: 'Total',
                type: 'currency',
                editable: false,
                systemDefined: true,
                selected: true,
                disabled: true
            }
        ]
    }
}

export const createSubpipelineFormData = {
    showSubPipeline: true,
    subPipelineName: "",
    openStages: [
        { label: "", value: "" },
        { label: "", value: "" }
    ],
    closedStages: [
        { label: "", value: "" },
        { label: "", value: "" }
    ]
}

export const convertToTeamPipelineFormData = {
    pipelineName: "Test data",
    singular: "aklf",
    plural: "sddga",
    permissions: [
        { value: "administration", label: "Administration" },
        { value: "standard", label: "Standard" }
    ],
    fields: {
        basic: {
            name: 'Basic Info',
            fields: [
                {
                    key: "name",
                    id: Math.random().toString(),
                    label: 'Name',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Name cannot be empty"
                        }
                    }
                },
                {
                    key: "subPipeline",
                    id: Math.random().toString(),
                    label: 'Sub-Pipeline',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'subPipeline',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Sub-Pipeline cannot be empty"
                        }
                    }
                },
                {
                    key: "stage",
                    id: Math.random().toString(),
                    label: 'Stage',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'stage',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Stage cannot be empty"
                        }
                    }
                },
                {
                    key: 'closingDate',
                    id: Math.random().toString(),
                    label: 'Closing Date',
                    type: 'date',
                    subType: 'datePicker',
                    editable: true,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            label: "Closing Date cannot be empty"
                        }
                    }
                },
                {
                    key: 'companyName',
                    id: Math.random().toString(),
                    label: 'Company Name',
                    editable: true,
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Company Name cannot be empty"
                        }
                    }
                }

            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
                {
                    key: "currentSalary",
                    id: Math.random().toString(),
                    label: 'Current Salary',
                    editable: true,
                    type: 'numerical',
                    subType: 'decimal',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Current Salary cannot be empty"
                        }
                    }
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    editable: true,
                    type: 'text',
                    subType: 'multi',
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Description cannot be empty"
                        }
                    }
                }
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: true,
        data: [
            {
                key: "product",
                _id: Math.random().toString(),
                label: 'Product',
                editable: false,
                type: 'lookup',
                lookup: {
                    relatedListName: 'Pipelines',
                    selectedModule: 'products'
                },
                rules: {
                    required: {
                        value: true,
                        message: "Product cannot be empty"
                    }
                },
                selected: true,
                disabled: true
            },
            {
                key: "productCode",
                _id: Math.random().toString(),
                label: 'Product Code',
                editable: true,
                alwaysMandatory: false,
                type: 'text',
                subType: 'single',
                unique: false,
                systemDefined: true,
                selected: true,
                disabled: false
            },
            {
                key: "productCategory",
                _id: Math.random().toString(),
                label: "Product Category",
                type: "picklist",
                editable: true,
                rules: {},
                subType: "single",
                picklist: {
                    "defaultValue": {
                        "label": "None",
                        "value": "none"
                    },
                    "sortAscending": false,
                    "options": [
                        {
                            "label": "None",
                            "value": "none"
                        },
                        {
                            "label": "Hardware",
                            "value": "hardware"
                        },
                        {
                            "label": "Software",
                            "value": "software"
                        },
                        {
                            "label": "CRM Apps",
                            "value": "crmApps"
                        }
                    ]
                },
                selected: true,
                disabled: false
            },
            {
                key: "unitPrice",
                _id: Math.random().toString(),
                label: 'Unit Price',
                type: 'currency',
                editable: true,
                systemDefined: true,
                rules: {
                    required: {
                        value: true,
                        message: "Price cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "description",
                _id: Math.random().toString(),
                label: 'Description',
                editable: true,
                alwaysMandatory: false,
                type: 'text',
                subType: 'multi',
                rows: 1,
                unique: false,
                systemDefined: true,
                selected: false,
                disabled: false
            },
            {
                key: "quantity",
                _id: Math.random().toString(),
                label: "Quantity",
                type: "numerical",
                editable: true,
                subType: "number",
                rules: {
                    required: {
                        value: true,
                        message: "Quantity cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "discount",
                _id: Math.random().toString(),
                label: "Discount",
                type: "decimal",
                editable: true,
                subType: "number",
                rules: {
                    required: {
                        value: true,
                        message: "Discount cannot be empty"
                    },
                    min: {
                        value: 0,
                        message: "Invalid value"
                    }
                },
                selected: true,
                disabled: false
            },
            {
                key: "total",
                _id: Math.random().toString(),
                label: 'Total',
                type: 'currency',
                editable: false,
                systemDefined: true,
                selected: true,
                disabled: true
            }
        ]
    }
}

export const cloneSubpipelineFormData = {
    showSubPipeline: true,
    subPipelineName: "Heyyyy",
    openStages: [
        { label: "Open Stage 3", value: "" },
    ],
    closedStages: [
        { label: "Close Stage 1", value: "" },
    ]
}

export const createTask = {
    fields: {
        basic: {
            name: 'Task information',
            fields: [
                {
                    key: "name",
                    id: Math.random().toString(),
                    label: 'Task Name',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true,
                    freeze: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Task Name cannot be empty"
                        }
                    }
                },
                {
                    key: 'dueDate',
                    id: Math.random().toString(),
                    label: 'Due Date',
                    type: 'date',
                    subType: 'datePicker',
                    editable: true,
                    systemDefined: true,
                    freeze: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Due Date cannot be empty"
                        }
                    }
                },
                {
                    key: "repeat",
                    label: "Repeat",
                    editable: false,
                    id: Math.random().toString(),
                    type: "repeat",
                    checkbox: {
                        defaultChecked: false
                    },
                    compareWith: "dueDate"
                },
                {
                    key: 'relatedTo',
                    id: Math.random().toString(),
                    label: 'Related To',
                    placeholder: "Search Contacts/Companies/Pipelines",
                    editable: true,
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Related To cannot be empty"
                        }
                    }
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'multi',
                    unique: false,
                    systemDefined: true,
                    placeholder: "A few words about this task",
                    rules: {
                        required: {
                            value: false,
                            message: "Description cannot be empty"
                        }
                    }
                },
                {
                    key: "markAsHighPriority",
                    label: "Mark as High Priority",
                    id: Math.random().toString(),
                    type: "checkbox",
                    editable: false,
                    checkbox: {
                        defaultChecked: false
                    }
                },
                {
                    key: "markAsCompleted",
                    label: "Mark as completed",
                    id: Math.random().toString(),
                    type: "checkbox",
                    editable: false,
                    checkbox: {
                        defaultChecked: false
                    }
                }
            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: false
    }
}

export const createEvent = {
    fields: {
        basic: {
            name: 'Event Information',
            fields: [
                {
                    key: "title",
                    id: Math.random().toString(),
                    label: 'Title',
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true,
                    freeze: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Title cannot be empty"
                        }
                    }
                },
                {
                    key: 'from',
                    id: Math.random().toString(),
                    label: 'From',
                    type: 'date',
                    subType: 'dateTimePicker',
                    systemDefined: true,
                    freeze: true,
                    rules: {
                        required: {
                            value: true,
                            message: "From Date cannot be empty"
                        }
                    }
                },
                {
                    key: 'to',
                    id: Math.random().toString(),
                    label: 'To',
                    type: 'date',
                    subType: 'dateTimePicker',
                    systemDefined: true,
                    freeze: true,
                    rules: {
                        required: {
                            value: true,
                            message: "To Date cannot be empty"
                        }
                    }
                },
                {
                    key: "repeat",
                    label: "Repeat",
                    editable: false,
                    id: Math.random().toString(),
                    type: "repeat",
                    checkbox: {
                        defaultChecked: false
                    },
                    compareWith: "from"
                },
                {
                    key: "location",
                    id: Math.random().toString(),
                    label: 'Location',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true,
                    required: {
                        value: false,
                        message: "Location cannot be empty"
                    }
                },
                {
                    key: 'relatedTo',
                    id: Math.random().toString(),
                    label: 'Related To',
                    placeholder: "Search Contacts/Companies/Pipelines",
                    editable: true,
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Related To cannot be empty"
                        }
                    }
                },
                {
                    key: 'participants',
                    id: Math.random().toString(),
                    label: 'Participants',
                    placeholder: "Search Contacts, Users or invite people by email",
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Participants cannot be empty"
                        }
                    }
                }
            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: false
    }
}

export const createCallLog = {
    fields: {
        basic: {
            name: 'Call Information',
            fields: [
                {
                    key: "toFrom",
                    id: Math.random().toString(),
                    label: 'To/From',
                    type: 'lookup',
                    lookup: {
                        relatedListName: 'Pipelines',
                        selectedModule: 'contacts'
                    },
                    rules: {
                        required: {
                            value: true,
                            message: "To/From cannot be empty"
                        }
                    }
                },
                {
                    key: 'startTime',
                    id: Math.random().toString(),
                    label: 'Call Start Time',
                    type: 'date',
                    subType: 'dateTimePicker',
                    systemDefined: true,
                    freeze: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Call Start Time cannot be empty"
                        }
                    }
                },
                {
                    key: "callType",
                    label: "Call Type",
                    type: "picklist",
                    subType: "single",
                    picklist: {
                        "defaultValue": {
                            "label": "Outbound",
                            "value": "outbound"
                        },
                        "sortAscending": false,
                        "options": [
                            {
                                "label": "Outbound",
                                "value": "outbound"
                            },
                            {
                                "label": "Inbound",
                                "value": "inbound"
                            },
                            {
                                "label": "Missed",
                                "value": "missed"
                            }
                        ]
                    },
                    rules: {
                        required: {
                            value: false,
                            message: "Call Type cannot be empty"
                        }
                    }
                },
                {
                    key: 'relatedTo',
                    id: Math.random().toString(),
                    label: 'Related To',
                    placeholder: "Search Contacts/Companies/Pipelines",
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'multi',
                    unique: false,
                    systemDefined: true,
                    placeholder: "A few words about this call",
                    rules: {
                        required: {
                            value: false,
                            message: "Description cannot be empty"
                        }
                    }
                },
                {
                    key: "callAgenda",
                    id: Math.random().toString(),
                    label: 'Call Agenda',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: false,
                            message: "Call Agenda cannot be empty"
                        }
                    }
                }
            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: false
    }
}

export const createContact = {
    fields: {
        basic: {
            name: 'Contact Information',
            fields: [
                {
                    key: "firstName",
                    id: Math.random().toString(),
                    label: 'First Name',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "lastName",
                    id: Math.random().toString(),
                    label: 'Last Name',
                    editable: false,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: true,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Last Name cannot be empty"
                        }
                    }
                },
                {
                    key: "title",
                    id: Math.random().toString(),
                    label: 'Title',
                    editable: false,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "email",
                    id: Math.random().toString(),
                    label: 'Email',
                    editable: true,
                    deletable: false,
                    type: 'email',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "phone",
                    _id: Math.random().toString(),
                    label: "Phone",
                    type: "numerical",
                    editable: true,
                    deletable: true,
                    subType: "number",
                    systemDefined: true
                },
                {
                    key: 'companyName',
                    id: Math.random().toString(),
                    label: 'Company Name',
                    editable: true,
                    deletable: true,
                    type: 'lookup',
                    lookup: {
                        defaultValue: 'contacts',
                        relatedListName: 'Pipelines',
                        selectedModule: 'companies'
                    },
                    systemDefined: true
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'multi',
                    unique: false,
                    systemDefined: true
                }

            ]
        },
        address: {
            name: "Address Information",
            fields: [
                {
                    key: "mailingStreet",
                    id: Math.random().toString(),
                    label: 'Mailing Street',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "mailingCity",
                    id: Math.random().toString(),
                    label: 'Mailing City',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "mailingState",
                    id: Math.random().toString(),
                    label: 'Mailing State',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "mailingCountry",
                    id: Math.random().toString(),
                    label: 'Mailing Country',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "mailingZip",
                    id: Math.random().toString(),
                    label: 'Mailing Zip',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                }
            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
                {
                    key: "dateOfBirth",
                    id: Math.random().toString(),
                    label: 'Date of Birth',
                    editable: true,
                    type: 'date',
                    subType: 'datePicker',
                    systemDefined: true
                },
                {
                    key: "assistant",
                    id: Math.random().toString(),
                    label: 'Assistant',
                    editable: true,
                    type: 'text',
                    subType: 'single',
                    systemDefined: true
                }
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: false
    }
}

export const createCompany = {
    fields: {
        basic: {
            name: 'Company Information',
            fields: [
                {
                    key: "companyName",
                    id: Math.random().toString(),
                    label: 'Company Name',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: true,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Company Name cannot be empty"
                        }
                    }
                },
                {
                    key: "phone",
                    _id: Math.random().toString(),
                    label: "Phone",
                    type: "numerical",
                    editable: true,
                    deletable: true,
                    subType: "number",
                    systemDefined: true
                },
                {
                    key: "website",
                    id: Math.random().toString(),
                    label: 'Website',
                    editable: true,
                    deletable: true,
                    type: 'url',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    placeholder: 'A few words about this company',
                    subType: 'multi',
                    unique: false,
                    systemDefined: true
                }

            ]
        },
        address: {
            name: "Address Information",
            fields: [
                {
                    key: "billingStreet",
                    id: Math.random().toString(),
                    label: 'Billing Street',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "billingCity",
                    id: Math.random().toString(),
                    label: 'Billing City',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "billingState",
                    id: Math.random().toString(),
                    label: 'Billing State',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "billingCountry",
                    id: Math.random().toString(),
                    label: 'Billing Country',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "billingCode",
                    id: Math.random().toString(),
                    label: 'Billing Code',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                }
            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
                {
                    key: "companyType",
                    _id: Math.random().toString(),
                    label: "Company Type",
                    type: "picklist",
                    editable: true,
                    rules: {},
                    subType: "single",
                    picklist: {
                        "defaultValue": null,
                        "sortAscending": false,
                        "options": [
                            {
                                "label": "Supplier",
                                "value": "supplier"
                            },
                            {
                                "label": "Reseller",
                                "value": "reseller"
                            },
                            {
                                "label": "Vendor",
                                "value": "vendor"
                            }
                        ]
                    },
                    selected: true,
                    disabled: false
                },
                {
                    key: "annualRevenue",
                    id: Math.random().toString(),
                    label: 'Annual Revenue',
                    editable: true,
                    type: 'currency',
                    systemDefined: true
                }
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: false
    }
}

export const createProduct = {
    fields: {
        basic: {
            name: 'Product Information',
            fields: [
                {
                    key: "productName",
                    id: Math.random().toString(),
                    label: 'Product Name',
                    editable: true,
                    alwaysMandatory: true,
                    type: 'text',
                    subType: 'single',
                    unique: true,
                    systemDefined: true,
                    rules: {
                        required: {
                            value: true,
                            message: "Product Name cannot be empty"
                        }
                    }
                },
                {
                    key: "productCode",
                    id: Math.random().toString(),
                    label: 'Product Code',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    subType: 'single',
                    unique: false,
                    systemDefined: true
                },
                {
                    key: "productCategory",
                    _id: Math.random().toString(),
                    label: "Product Category",
                    type: "picklist",
                    editable: true,
                    deletable: true,
                    rules: {},
                    subType: "single",
                    picklist: {
                        "defaultValue": {
                            "label": "None",
                            "value": "none"
                        },
                        "sortAscending": false,
                        "options": [
                            {
                                "label": "None",
                                "value": "none"
                            },
                            {
                                "label": "Hardware",
                                "value": "hardware"
                            },
                            {
                                "label": "Software",
                                "value": "software"
                            }
                        ]
                    },
                    selected: true,
                    disabled: false
                },
                {
                    key: "unitPrice",
                    id: Math.random().toString(),
                    label: 'Unit Price',
                    editable: true,
                    type: 'numerical',
                    subType: 'decimal',
                    systemDefined: true
                },
                {
                    key: "description",
                    id: Math.random().toString(),
                    label: 'Description',
                    editable: true,
                    deletable: true,
                    type: 'text',
                    placeholder: 'A few words about this product',
                    subType: 'multi',
                    unique: false,
                    systemDefined: true
                }

            ]
        },
        additional: {
            name: 'Additional Info',
            fields: [
            ]
        },
        unUsedFields: {
            name: 'Unused Fields',
            fields: [
                {
                    key: "manufacturer",
                    _id: Math.random().toString(),
                    label: "Manufacturer",
                    type: "picklist",
                    editable: true,
                    rules: {},
                    subType: "single",
                    picklist: {
                        "defaultValue": null,
                        "sortAscending": false,
                        "options": [
                            {
                                "label": "A",
                                "value": "a"
                            },
                            {
                                "label": "B",
                                "value": "b"
                            },
                            {
                                "label": "C",
                                "value": "c"
                            }
                        ]
                    }
                },
                {
                    key: "qtyOrdered",
                    id: Math.random().toString(),
                    label: 'Qty Ordered',
                    editable: true,
                    type: 'numerical',
                    subType: 'decimal',
                    systemDefined: true
                },
            ]
        }
    },
    products: {
        name: 'Associated Products',
        showProducts: false
    }
}

export const roles = {
    id: 'root',
    roleName: 'Super Admin',
    reportsTo: null,
    peerDataVisibility: true,
    description: 'Users with this role have access to the data owned by all other users.',
    children: [
        {
            id: '1',
            roleName: 'Admin 1',
            reportsTo: 'root',
            peerDataVisibility: true,
            description: 'Users belonging to this role cannot see data for admin users.'
        },
        {
            id: '2',
            roleName: 'Admin 2',
            reportsTo: 'root',
            peerDataVisibility: true,
            description: 'Users belonging to this role cannot see data for admin users.',
            children: [
                {
                    id: '3',
                    roleName: 'Manager 1',
                    reportsTo: '2',
                    peerDataVisibility: false,
                    description: 'Users belonging to this role cannot see data for manager users.',
                    children: [
                        {
                            id: '4',
                            roleName: 'Sales Person 1',
                            reportsTo: '3',
                            peerDataVisibility: false,
                            description: 'Users belonging to this role cannot see data of other sales person users.'
                        }
                    ]
                },
            ],
        },
    ],
};