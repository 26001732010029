import { createTheme } from "@mui/material/styles";
// import './theme.css';

let theme = createTheme({
  // palette: {
  //   primary: {
  //     main: "#3861FB",
  //   },
  //   secondary: {
  //     main: "#005595",
  //   },
  //   error: {
  //     main: "#3861fb",
  //   },
  // },
  // typography: {
  //   fontFamily: ["Roboto"],
  //   h1: {
  //     fontSize: "40px",
  //     fontWeight: "700",
  //   },
  //   h2: {
  //     fontSize: "26px",
  //     fontWeight: "600",
  //   },
  //   subtitle1: {
  //     fontSize: "24px",
  //   },
  //   subtitle2: {
  //     fontSize: "20px",
  //   },
  //   body1: {
  //     fontSize: "16px",
  //   },
  //   p: {
  //     fontSize: "14px",
  //   },
  // },
});

theme = createTheme(theme, {
  // palette: {
  //   info: {
  //     main: theme.palette.secondary.main,
  //   },
  // },
});

export default theme;
