import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  Paper,
  Typography,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import RedAsterisk from "../../../../components/RedAsterisk";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../../../../components/Button";
import CloseIcon from "@mui/icons-material/Close";
import { getAllCountries } from "countries-and-timezones";
import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const fetchBranch = async () => {
  const response = await axios.get(
    "https://liveserver.nowdigitaleasy.com:5002/api/v1/branch/get-branch"
  );
  return response.data;
};

const schema = yup
  .object({
    branch_name: yup
      .string()
      .min(3, "Please Enter Minimum 3 characters")
      .max(50, "Please Enter Maximum 50 characters")
      .required("Please Enter First Name"),
      phone_number: yup
      .string()
      .matches(/^[0-9]{10,}$/, "Mobile number must be at least 10 digits")
      .required("Please Enter Mobile Number"),
    country: yup.string().required("Please Select Country "),
    state: yup.string().required("Please Enter State"),
    city: yup.string().required("Please Enter City"),
    street1: yup.string().required("Please Enter Street"),
  })
  .required();

function Branch() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const countries = getAllCountries();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const {
    data: branchData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["branchData"],
    queryFn: fetchBranch,
  });

  const onSubmit = (data) => {
      console.log("Organization:", data);
    axios
    .post("https://liveserver.nowdigitaleasy.com:5002/api/v1/branch/create-branch", data)
    .then((response) => {
      queryClient.invalidateQueries({ queryKey: ["branchData"] });
      // setAlertSuccess(true);
      reset();
      setTimeout(() => {
        setIsDialogOpen(false);
      }, 3000);
    })
    .catch((error) => {
      console.error("Error adding user:", error);
      const errorMessage = error.response?.data?.error?.message || "Something went wrong";
      // setAlertError(errorMessage);
    });
  };
  
  const handleAddBranchClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  if (isLoading)
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "55%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError) return <div>Error loading data</div>;
  return (
    <div>
      <div
        style={{
          height: "65vh",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                background: "rgba(0, 10, 255, 1)",
                color: "white",
                marginTop: "2%",
                marginRight: "2%",
              }}
              onClick={handleAddBranchClick}
            >
              Add Branch
            </Button>
          </div>
          <div>
            <Paper
              style={{
                width: "100%",
                marginTop: "2%",
              }}
            >
              <Table
                style={{
                  height: "50%",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      Branch Name
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bolder",
                      }}
                    >
                      
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branchData.map((branch) => (
                    <TableRow key={branch.branch_id}>
                      <TableCell>{branch.branch_name}</TableCell>
                      {/* <TableCell>{branch.phone_number}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <Dialog
              open={isDialogOpen}
              onClose={handleDialogClose}
              maxWidth="md"
              fullWidth
            >
              <Box
                style={{
                  background: "rgba(0, 10, 255, 1)",
                  padding: "10px 10px 10px 25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "white",
                  }}
                >
                  Branch
                </Typography>
                <CloseIcon
                  onClick={handleDialogClose}
                  style={{
                    cursor: "pointer",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Box>
              <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("branch_name")}
                          error={!!errors.branch_name}
                          helperText={errors.branch_name?.message}
                          variant="outlined"
                          label="Branch Name *"
                          placeholder="Branch Name"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("phone_number")}
                          error={!!errors.phone_number}
                          helperText={errors.phone_number?.message}
                          variant="outlined"
                          label="Mobile No *"
                          placeholder="Mobile No"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Country Code * </InputLabel>
                        <Select
                          {...register("country")}
                          error={!!errors.country}
                          value={watch("country") || ""}
                          onChange={(e) =>
                            setValue("country", e.target.value)
                          }
                          label="Country Code * "
                          MenuProps={{
                            PaperProps: {
                              sx: { maxHeight: "30%", width: "200px" },
                            },
                          }}
                        >
                          {Object.values(countries).map((country) => (
                            <MenuItem key={country.id} value={country.name}>
                              {country.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("state")}
                          error={!!errors.state}
                          helperText={errors.state?.message}
                          variant="outlined"
                          label="State *"
                          placeholder="State"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("city")}
                          error={!!errors.city}
                          helperText={errors.city?.message}
                          variant="outlined"
                          label="City *"
                          placeholder="City"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth margin="normal">
                        <TextField
                          {...register("street1")}
                          error={!!errors.street1}
                          helperText={errors.street1?.message}
                          variant="outlined"
                          label="Street *"
                          placeholder="Street"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      style={{
                        background: "rgba(0, 0, 0, 0)",
                        borderRadius: "5px",
                        color: "Black",
                        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                        marginRight: "20px",
                      }}
                      onClick={handleDialogClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        background: "rgba(0, 10, 255, 1)",
                        borderRadius: "5px",
                        color: "white",
                      }}
                      type="submit"
                    >
                      Add Branch
                    </Button>
                  </Box>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Branch;

// sx={{".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper" : { maxWidth: "310px",maxHeight: "35%",}}}
