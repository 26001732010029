import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useState } from "react";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import UpsertRole from "./UpsertRole";
import DeleteRole from "./DeleteRole";
import variables from "../../../../assets/styles/_colors.scss";
import { withStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { getAllRoles } from "../../../../webservices/UserControlService";
import CustomSnackbar from "../../../../components/CustomSnackbar";
import { useQuery } from "@tanstack/react-query";
import ViewRoleUsers from "./ViewRoleUsers";

const StyledDotSeparator = withStyles({
    root: {
        borderRadius: "50%",
        display: "inline-block",
        height: "5px",
        width: "5px",
        backgroundColor: `${variables.borderColor}`
    }
})(Typography);

export default function Roles(props) {

    const [showCreateRole, setShowCreateRole] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data: roles,
        isFetched,
        isError,
        error,
        isFetching
    } = useQuery({
        queryKey: ['getAllRoles'],
        queryFn: () => getAllRoles(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        setExpanded(roles?.ids.map(a => a._id));
    }, [isFetched]);

    useEffect(() => {
        isError && setSnackbarInfo({
            open: true,
            severity: 'error',
            message: error?.response?.data?.error?.message
        });
    }, [isError]);

    const renderTree = (node) => (
        <TreeItem sx={{ ".MuiTreeItem-content": { height: 40, borderBottom: `1px solid ${variables.lightBorderColor}` } }}
            key={node?._id}
            nodeId={node?._id}
            label={<CustomTreeItem item={node} showSnackbar={(data) => showSnackbar(data)} roles={roles?.ids} />}>
            {Array.isArray(node?.children)
                ? node?.children.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    const handleExpandAll = () => {
        setExpanded(roles?.ids.map(a => a._id));
    }

    const handleCollapseAll = () => {
        setExpanded([]);
    }

    const handleToggle = (e, nodeIds) => {
        setExpanded(nodeIds);
    }

    const handleCreateRoleClose = (data) => {
        setShowCreateRole(false);
        showSnackbar(data);
    }

    const showSnackbar = (data) => {
        data && setSnackbarInfo({
            open: true,
            severity: data.success ? 'success' : 'error',
            message: data.message
        })
    }

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <>
            <Stack direction="row" width="100%" sx={{ p: 2 }}>
                <Stack direction="column" width="85%">
                    <Typography sx={{ fontWeight: 'bold', fontSize: 16, pb: 1.5 }}>
                        Roles
                    </Typography>
                    <Typography>
                        Roles help you define visibility levels for records in your organization. A user on a lower role can't view the records of users above them in the role hierarchy.
                    </Typography>
                </Stack>
                <Stack direction="row" width="15%" height="36px" justifyContent="right">
                    <Button variant="contained" sx={{ borderRadius: 10 }} onClick={() => setShowCreateRole(true)}>
                        <AddIcon /> New Role
                    </Button>
                </Stack>
            </Stack>

            <Box sx={{ maxWidth: '90vw', pt: 1, pl: 2 }}>
                <Stack spacing={1} direction="row" sx={{ pb: 1, borderBottom: `1px solid ${variables.lightBorderColor}` }} alignItems="center">
                    <Typography sx={{ fontWeight: "bold" }}>ABC Cloud Tech</Typography>
                    <StyledDotSeparator />
                    <Typography variant="a" onClick={handleExpandAll} href="#" sx={{ cursor: 'pointer', color: `${variables.linkColor}`, "&:hover": { textDecoration: 'underline' } }}>
                        Expand All
                    </Typography>
                    <StyledDotSeparator />
                    <Typography variant="a" onClick={handleCollapseAll} href="#" sx={{ cursor: 'pointer', color: `${variables.linkColor}`, "&:hover": { textDecoration: 'underline' } }}>
                        Collapse All
                    </Typography>
                </Stack>
                <Box sx={{ minHeight: '50vh', maxHeight: '68vh', overflowY: 'scroll' }}>
                    {
                        isFetching ?
                            <Stack direction="row" alignItems="center" justifyContent="center" width="100%" height="50px">
                                <Typography>Loading ...</Typography>
                            </Stack>
                            : <TreeView
                                defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
                                defaultExpandIcon={<AddBoxOutlinedIcon />}
                                expanded={expanded ?? []}
                                selected={[]}
                                onNodeToggle={handleToggle}
                            >
                                {roles && renderTree(roles.data[0])}
                            </TreeView>
                    }
                </Box>
            </Box>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

            {showCreateRole && <UpsertRole handleDrawerClose={(data) => handleCreateRoleClose(data)} />}
        </>
    )
}

const CustomTreeItem = (props) => {

    const [isHovering, setIsHovering] = useState(false);
    const [createRole, setCreateRole] = useState(false);
    const [editRole, setEditRole] = useState(false);
    const [deleteRole, setDeleteRole] = useState(false);
    const [viewUsers, setViewUsers] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);

    const handleCreateClose = (data) => {
        setCreateRole(false);
        props.showSnackbar(data);
    }

    const handleEditClose = (data) => {
        setEditRole(false);
        props.showSnackbar(data);
    }

    const handleDeleteClose = (data) => {
        setDeleteRole(false);
        props.showSnackbar(data);
    }

    const stopNavigation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const handleView = (e, item) => {
        stopNavigation(e);
        setSelectedRole(item);
        setViewUsers(true);
    }

    const handleViewClose = (action) => {
        action === "edit" && setEditRole(true);
        action === "delete" && setDeleteRole(true);
        setViewUsers(false);
    }

    return (
        <>
            <Box onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
                <Stack direction="row" alignItems="center" width="auto" onClick={(e) => handleView(e, props.item)}>
                    <Typography sx={{ flexGrow: 1 }}>
                        {props.item?.roleName}
                        <Typography component="span" sx={{ pl: 2, visibility: isHovering ? 'inherit' : 'hidden' }}>
                            <IconButton aria-label="add" size="medium" onClick={(e) => { stopNavigation(e); setCreateRole(true) }}>
                                <AddIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton aria-label="edit" size="medium" onClick={(e) => { stopNavigation(e); setEditRole(true) }}>
                                <EditOutlinedIcon fontSize="inherit" />
                            </IconButton>
                            {props.item?.reportsTo !== null &&
                                <IconButton aria-label="delete" size="medium" onClick={(e) => { stopNavigation(e); setDeleteRole(true) }}>
                                    <DeleteOutlineOutlinedIcon fontSize="inherit" />
                                </IconButton>}
                        </Typography>
                    </Typography>
                </Stack>
            </Box>

            {createRole && <UpsertRole item={props.item} disableReportsTo={true} handleDrawerClose={(data) => handleCreateClose(data)} />}

            {editRole && <UpsertRole edit={true} roles={props.roles} item={props.item} handleDrawerClose={(data) => handleEditClose(data)} />}

            {deleteRole && <DeleteRole roles={props.roles} item={props.item} handleClose={(data) => { handleDeleteClose(data) }} />}

            {viewUsers && <ViewRoleUsers item={props.item} handleClose={(action) => { handleViewClose(action) }} />}
        </>
    )
}