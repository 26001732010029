
import { useEffect, useState } from 'react';
import { getProducts } from '../../../webservices/PipelineService';
import { Autocomplete, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import variables from "../../../assets/styles/_colors.scss";

export default function AddProductsToDeal(props) {

    const {
        control,
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext();

    const [productsSchema,] = useState(getValues("products"));
    const [products, setProducts] = useState(null);

    const getDefaultValue = () => {
        let val = {};
        productsSchema.data.filter(d => d.selected === true).map((item, index) => {
            switch (item.key) {
                case "quantity": return val[item.key] = 1;
                case "discount": return val[item.key] = 0;
                default:
                    return val[item.key] = null;
            }
        });
        return val;
    }
    const defaultValue = getDefaultValue();


    const { fields, append, remove } = useFieldArray({
        name: `dealProducts`,
        control,
    });

    const dealProducts = useWatch({ control, name: "dealProducts" });

    useEffect(() => {
        getProducts().then((response) => {
            setProducts(response.products);
        })
    }, []);

    const addNewRow = (event) => {
        event.stopPropagation();
        append(defaultValue);
    }

    const removeSelectedRow = (index) => {
        remove(index);
    }

    const handleChange = (index, data) => {
        setValue(`dealProducts.${index}.product`, data.label);
        setValue(`dealProducts.${index}.unitPrice`, data.price);
    }

    return (
        <>
            {!props.hideTitle && <Typography component="div" sx={{ pb: 2, fontWeight: 600 }}>Associated Products</Typography>}
            <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '8px 8px' }}>
                <thead>
                    <tr>
                        {productsSchema.data.filter(d => d.selected === true).map((item, index) => {
                            return <th>{item.label || item.hiddenLabel}</th>
                        })}
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {fields.map((item, i) => {
                        return (
                            <tr style={{ height: "30px" }} key={item.id}>
                                {productsSchema.data.filter(d => d.selected === true).map((schema, index) => {
                                    if (schema.key === "total") {
                                        return <td>
                                            <Typography>${((dealProducts[i]?.unitPrice - ((dealProducts[i]?.unitPrice) * (dealProducts[i]?.discount / 100))) * dealProducts[i]?.quantity).toFixed(2)}
                                            </Typography>
                                        </td>
                                    }
                                    if (dealProducts[i]?.product === null) {
                                        if (schema.key === "product") {
                                            schema.options = products;
                                            return <td>
                                                <Controller
                                                    name={`dealProducts.${i}.product`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "Product cannot be empty"
                                                        }
                                                    }
                                                    }
                                                    render={({ field: { ref, ...field } }) => (
                                                        <Autocomplete
                                                            disablePortal
                                                            id={`select_${item.value}`}
                                                            options={products}
                                                            onChange={(e, data) => handleChange(i, data)}
                                                            size="small"
                                                            defaultValue={item.label}
                                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                                            renderInput={(params, ref, value) => (
                                                                <TextField
                                                                    error={Boolean(errors["dealProducts"]?.[i]?.product)}
                                                                    helperText={errors["dealProducts"]?.[i]?.product?.message}
                                                                    id={`products_${i}`}
                                                                    inputRef={ref}
                                                                    {...params}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </td>
                                        } else {
                                            return <td></td>
                                        }
                                    }
                                    else {
                                        if (schema.key === "product") {
                                            return <td><Typography>{dealProducts[i]?.product}</Typography></td>
                                        } else {
                                            return <td style={{ width: (schema.type === "numerical" && "100px") || (schema.type === "currency" && "130px")  || (schema.type === "lookup" && "140px") }}>
                                                {props.renderFields(schema, `dealProducts.${i}`)}
                                            </td>
                                        }
                                    }
                                })}
                                <td>
                                    <IconButton onClick={() => removeSelectedRow(i)} sx={{ color: `${variables.removeIconColor}` }}>
                                        <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
                                    </IconButton>
                                </td>
                            </tr>
                        );
                    })
                    }
                </tbody>
            </table>
            <Stack direction="row" sx={{ pt: 2 }}>
                <Typography component="div" onClick={(e) => addNewRow(e)} sx={{ color: "#3d5af1", cursor: "pointer", backgroundColor: "#F9FBFC", borderRadius: 1, p: "1rem 0" }} >
                    <AddIcon sx={{ mr: 0.5 }} /> Product
                </Typography>
            </Stack>
        </>
    );
}
