import React from "react";
import { useRecoilState } from "recoil";
import { onOffDuty } from "../trackey/trackeyatoms";

export default function TrackeyBox(props) {
  
  const [ONFFDuty, setONFFDuty] = useRecoilState(onOffDuty);

  

  return (
    <div
      id={props.id}
      style={{ backgroundColor: props.backgroundColor }}
      className="trackey-box"
      onClick={() => {
        props.onclick ? setONFFDuty(props.onclick) : setONFFDuty("ALL");
      }}
    >
      <div>{props.displayCount ? props.displayCount : "0"}</div>
      <div> {props.displayName}</div>
    </div>
  );
}
