import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AssociatedProductsActionPopover from './AssociatedProductsActionPopover';
import { useState } from 'react';
import ModifyProductsTable from './ModifyProductsTable';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';

export default function AssociatedProducts() {

    const {
        getValues,
        setValue,
        formState: { errors }
    } = useFormContext();

    const formData = getValues('products');

    const [showProducts, setShowProducts] = useState(formData?.showProducts);
    const [showModifyProducts, setShowModifyProducts] = useState(false);
    const [tableData, setTableData] = useState(formData?.data);

    const handleModifyProductsClose = (data) => {
        if (data) {
            setTableData(data);
            setValue('products', {
                ...formData,
                data: data
            });
        }
        setShowModifyProducts(false);
    }

    const removeProductsSection = () => {
        setValue('products', {
            data: [],
            showProducts: false
        });
        setShowProducts(false);
    }

    useEffect(() => {

    }, [tableData, showProducts]);

    return (
        showProducts && <>
            <Box sx={{ pt: 1, pb: 1, fontWeight: "bold" }}>
                <Typography variant="span" > Associated Products </Typography>
                <AssociatedProductsActionPopover removeProductsSection={removeProductsSection} modifyTableFields={() => setShowModifyProducts(true)}></AssociatedProductsActionPopover>
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" sx={{ minWidth: "51vw", maxWidth: "51vw" }}>
                    <TableHead>
                        <TableRow>
                            {tableData.map((d, i) => {
                                return d.selected && <TableCell id={i} key={i}> {d.label} </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1}>
                            {tableData.map((d, i) => {
                                return d.selected && <TableCell id={i} key={i}> {(d.subType || d.type)?.toUpperCase()} </TableCell>
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {showModifyProducts && <ModifyProductsTable items={tableData} close={handleModifyProductsClose}></ModifyProductsTable>}
        </>
    );
}