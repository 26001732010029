import { Stack, Typography } from "@mui/material";
import { CustomSelect } from "../components/CustomSelect";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect } from "react";
import CommonDateFields from "./CommonDateFields";

const activitiesDropdown = [
    {
        label: "Without Open Activities",
        value: "no_open_activities"
    },
    {
        label: "Overdue",
        value: "overdue"
    },
    {
        label: "Activities Due",
        value: "activities_due"
    },

    {
        label: "Without Any Activities",
        value: "no_activities"
    },
    {
        label: "Activities Done",
        value: "activities_done"
    }
];

const overdueDropdown = [
    {
        label: "Activities",
        value: "activities"
    },
    {
        label: "Tasks",
        value: "tasks"
    },
    {
        label: "Calls",
        value: "calls"
    }
];

const activitiesDueDropdown = [
    {
        label: "Today",
        value: "today"
    },
    {
        label: "Tomorrow",
        value: "tomorrow"
    },
    {
        label: "Next 7 days",
        value: "next7days"
    },
    {
        label: "Today and Overdue",
        value: "today_and_overdue"
    }
];

const calenderOptions = [
    {
        label: "In the Last",
        value: "last"
    },
    {
        label: "On",
        value: "on"
    },
    {
        label: "Before",
        value: "before"
    },
    {
        label: "After",
        value: "after"
    },
    {
        label: "Between",
        value: "between"
    },
    {
        label: "Today",
        value: "today"
    },
    {
        label: "Yesterday",
        value: "yesterday"
    },
    {
        label: "This Week",
        value: "week"
    },
    {
        label: "This Month",
        value: "month"
    },
    {
        label: "This Year",
        value: "year"
    },
    {
        label: "Last Week",
        value: "last_week"
    },
    {
        label: "Last Month",
        value: "last_month"
    },
    {
        label: "Till today",
        value: "till_today"
    }
];

const Activities = (props) => {

    const { control, resetField, setValue } = useFormContext();

    const singleField = ["today", "yesterday", "week", "month", "year", "last_week", "last_month", "till_today"]
    const showDateField = ["on", "before", "after"];
    const showTwoDateFields = ["between"];
    const customFields = ["last"];

    const selectedOption = useWatch({ control, name: `${props.name}.activities` });

    useEffect(() => {
        if (selectedOption !== "overdue") {
            resetField(`${props.name}.overdue`);
        }
        if (selectedOption !== "activities_due") {
            resetField(`${props.name}.activities_due`);
        }
    }, [selectedOption]);

    useEffect(() => {
        setValue(`${props.name}.activities`, activitiesDropdown[0].value);
    }, []);

    return (
        <>
            <Typography sx={{ pt: 1 }}>
                {props.field?.label}
            </Typography>
            <Stack direction="row" sx={{ pt: 1, flexWrap: 'wrap' }} >
                <Stack direction="row" spacing={1} sx={{ width: '100%' }}>

                    <Stack direction="row" >
                        <CustomSelect size="small" options={activitiesDropdown} name={`${props.name}.activities`} defaultValue={activitiesDropdown[0].value} />
                    </Stack>

                    <Stack direction="row" sx={{ width: '50%' }}>
                        {selectedOption === "overdue" &&
                            <CustomSelect size="small" options={overdueDropdown} name={`${props.name}.overdue`} defaultValue={overdueDropdown[0].value} />}

                        {selectedOption === "activities_due" &&
                            <CustomSelect size="small" options={activitiesDueDropdown} name={`${props.name}.activities_due`} defaultValue={activitiesDueDropdown[0].value} />}
                    </Stack>
                </Stack>
                <Stack direction="row" sx={{ width: '100%' }}>
                    {(selectedOption === "no_activities" || selectedOption === "activities_done") &&
                        <CommonDateFields
                            name={props.name}
                            field={props.field}
                            options={calenderOptions}
                            singleField={singleField}
                            showDateField={showDateField}
                            showTwoDateFields={showTwoDateFields}
                            customFields={customFields} />
                    }

                </Stack>
            </Stack>
        </>
    );
};

export default Activities;