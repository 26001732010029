import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import PicklistPredefinedOptions from './PicklistPredefinedOptions';
import PicklistBulkOptionsPopover from './PicklistBulkOptionsPopover';
import PicklistManualOptions from './PicklistManualOptions';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomCheckbox } from '../components/CustomCheckbox';
import { CustomSelect } from '../components/CustomSelect';
import ReorderableObjectList from '../components/ReorderableObjectList';

const generateNumberDropdown = (maxLimit) => {
    let array = [];
    for (let i = 1; i <= maxLimit; i++) {
        array.push({ label: i, value: i })
    }
    return array;
}

export default function CustomFieldOptions(props) {

    const options = {
        "url": {
            "required": true,
            "unique": true
        },
        "text": {
            "required": true,
            "unique": true,
            "encrypt": true,
            "subType":
            {
                label: "Sub Type",
                defaultValue: "single",
                options: [
                    {
                        "label": "Single Line",
                        "value": "single"
                    },
                    {
                        "label": "Multi Line",
                        "value": "multi"
                    }
                ]
            }
        },
        "numerical": {
            "required": true,
            "unique": true,
            "encrypt": true,
            "subType": {
                label: "Sub Type",
                defaultValue: "number",
                options: [
                    {
                        "label": "Number",
                        "value": "number"
                    },
                    {
                        "label": "Long Number",
                        "value": "longNumber"
                    },
                    {
                        "label": "Decimal",
                        "value": "decimal"
                    },
                    {
                        "label": "Percent",
                        "value": "percent"
                    },
                    {
                        "label": "Auto Number",
                        "value": "autoNumber"
                    }
                ]
            }
        },
        "user": {
            "required": true,
            "allowRecordAccessibility": true
        },
        "date": {
            "required": true,
            "subType":
            {
                label: "Sub Type",
                defaultValue: "datePicker",
                options: [
                    {
                        "label": "Date Picker",
                        "value": "datePicker"
                    },
                    {
                        "label": "Date & Time",
                        "value": "dateTime"
                    }
                ]
            }
        },
        "currency": {
            "required": true
        },
        "email": {
            "required": true,
            "unique": true,
            "encrypt": true,
        },
        "phone": {
            "required": true,
            "unique": true,
            "encrypt": true,
            "subType": {
                label: "Field Length",
                defaultValue: 20,
                options: generateNumberDropdown(30)
            }
        },
        "checkbox": {
            "showRadioGroup": true
        },
        "lookup": {
            "required": true,
            "lookup": {
                "label": "Lookup Module",
                "defaultValue": "contacts",
                "relatedListName": "Related List 1",
                "options": [
                    {
                        "label": "Pipelines",
                        "value": "pipelines"
                    }, {
                        "label": "Contacts",
                        "value": "contacts"
                    }, {
                        "label": "Companies",
                        "value": "companies"
                    }, {
                        "label": "Products",
                        "value": "products"
                    },
                ]
            }
        },
        "picklist": {
            "required": true,
            "subType":
            {
                label: "Sub Type",
                defaultValue: "single",
                options: [
                    {
                        "label": "Single Select",
                        "value": "single"
                    },
                    {
                        "label": "Multi Select",
                        "value": "multi"
                    }
                ]
            }
        }
    }

    const {
        control,
        register,
        formState: { errors }
    } = useFormContext();

    const [selectedFieldSchema, setSelectedFieldSchema] = useState(null);
    const [selectedField, setSelectedField] = useState(null);
    const [showPredefinedBulkOptions, setShowPredefinedBulkOptions] = useState(false);
    const [showManualBulkOptions, setShowManualBulkOptions] = useState(false);
    const [bulkOptions, setBulkOptions] = useState(null);

    useEffect(() => {
        const data = options[props.selectedFieldType];
        setSelectedFieldSchema(data);
        setSelectedField(props.selectedField);
    }, [props.selectedFieldType]);

    useEffect(() => {
    }, [selectedField]);

    useEffect(() => {
    }, [props.bulkOptions]);

    return (
        <React.Fragment>
            <FormControl variant="standard" sx={{ pt: 2 }} fullWidth>
                {selectedFieldSchema?.subType &&
                    <CustomSelect disabled={!props.addMode} inputLabel={selectedFieldSchema?.subType?.label} name={"customField.subType"} defaultValue={selectedFieldSchema?.subType?.defaultValue}
                        label={selectedFieldSchema?.subType?.label} options={selectedFieldSchema?.subType?.options}>
                    </CustomSelect>
                }
                {selectedFieldSchema?.required &&
                    <CustomCheckbox disableCheckbox={selectedField?.alwaysMandatory} name={"customField.rules.required.value"} label={"Mandatory Field"}></CustomCheckbox>}

                {selectedFieldSchema?.unique &&
                    <CustomCheckbox name={"customField.unique"} label={"Do not allow duplicate values"} tooltipText="Select this to mark as unique" />}

                {selectedFieldSchema?.encrypt &&
                    <CustomCheckbox name={"customField.encrypt"} label={"Encrypt fields"}></CustomCheckbox>}

                {selectedFieldSchema?.allowRecordAccessibility &&
                    <CustomCheckbox name={"customField.allowRecordAccessibility"} label={"Allow Record Accessibility"}></CustomCheckbox>}

                {selectedFieldSchema?.showRadioGroup && <>
                    <Typography sx={{ fontWeight: 600 }} id="custom-fields-radio-buttons-group-label">Default State</Typography>
                    <FormControl component="fieldset">
                        <Controller
                            control={control}
                            name="customField.checkbox.defaultChecked"
                            render={({ field }) => (
                                <RadioGroup row defaultValue="false" {...field}>
                                    <FormControlLabel
                                        value="true"
                                        control={<Radio />}
                                        label="Checked"
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<Radio />}
                                        label="Unchecked"
                                    />
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                </>}
            </FormControl>

            {selectedFieldSchema?.lookup && <>
                <Typography sx={{ fontWeight: 600, pb: 2, pt: 2 }}>Lookup Details</Typography>
                <CustomSelect disabled={selectedField?.systemDefined} inputLabel={selectedFieldSchema?.lookup?.label} name={"customField.lookup.selectedModule"} defaultValue={selectedFieldSchema?.lookup?.defaultValue}
                    label={selectedFieldSchema?.lookup?.label} options={selectedFieldSchema?.lookup?.options}>
                </CustomSelect>
                < FormControl fullWidth>
                    <TextField
                        id="list-name"
                        label="Related List Name*"
                        disabled={selectedField?.systemDefined}
                        {...register("customField.lookup.relatedListName")}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        error={Boolean(errors?.customField?.lookup?.relatedListName)}
                        helperText={errors?.customField?.lookup?.relatedListName?.message} />
                </FormControl>
            </>
            }
            {props.selectedFieldType == 'picklist' && <>
                <Stack direction="row" spacing={2} sx={{pb: 2, pt: 1}} alignItems="center">
                    <Stack direction="row" justifyContent="left" sx={{ width: "50%" }}>
                        <Typography sx={{ fontWeight: 600 }}>Picklist Options</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent="right" sx={{ width: "50%" }}>
                        <PicklistBulkOptionsPopover openPredefinedOptions={() => setShowPredefinedBulkOptions(true)} openManualEntry={() => setShowManualBulkOptions(true)}></PicklistBulkOptionsPopover>
                    </Stack>
                </Stack>
                {showPredefinedBulkOptions && <PicklistPredefinedOptions savePredefinedOptions={(data) => setBulkOptions(data)} handleDrawerClose={() => setShowPredefinedBulkOptions(false)} />}
                {showManualBulkOptions && <PicklistManualOptions saveManualEntryOptions={(data) => setBulkOptions(data)} handleDrawerClose={() => setShowManualBulkOptions(false)} />}
                <ReorderableObjectList id="customField.picklist" label="Picklist Options" bulkOptions={bulkOptions} />
                <CustomCheckbox name={"customField.picklist.sortAscending"} label={"Show picklist values in Ascending order"}></CustomCheckbox>
            </>
            }
        </React.Fragment >
    );
}