import React from "react";
import { IconButton, Grid, Modal, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { api_url1 } from "../../../../globalSettings";
import { useFormik, FormHelperText } from "formik";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};
const TransferToken = (props) => {
  return (
    <>
      <div>
        <div>
          <Modal
            open={props.transferTokendialog}
            onClose={props.transferTokendialogonclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="scroll">
              {/* <div className="addcompanytitle" style={{ height: 60 }}>
                        Child Name Server
                        <IconButton
                          component="span"
                          onClick={props.theftprotectiondialogonclose}
                          style={{ padding: 0, marginRight: 20 }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  maxWidth: "100%",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <div style={{ padding: 20 }}>
                      <h6 style={{ fontWeight: "600", margin: 0 }}>
                        TRANSFER CODE
                      </h6>
                      <p style={{ fontWeight: "400" }}>For : iaaxin.com </p>
                      <div
                        style={{
                          margin: 10,
                          padding: 15,
                          border: "1px solid #00000024",
                        }}
                      >
                        <h6
                          style={{
                            fontWeight: "600",
                            fontSize: "13px",
                          }}
                        >
                          YOUR TRANSFER CODE IS
                        </h6>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      backgroundColor: "#e0e0e0",
                      padding: 20,
                      zIndex: "0",
                    }}
                  >
                    {/* <IconButton
                          component="span"
                          onClick={props.theftprotectiondialogonclose}
                        >
                          <CloseIcon />
                        </IconButton> */}
                    <div style={{ padding: 20, fontSize: 11, fontWeight: 500 }}>
                      The Transfer Code acts as an additional layer of security
                      to protect from accidental or unauthorized transfers of
                      your Domain Name.
                      <br />
                      <br />
                      This is also called as Domain Secret, Auth code or EPP
                      code.
                      <br />
                      <br />
                      You will need provide this code when you're transferring
                      your Domain to another provider.
                      <br />
                      <br />
                      <a href="nowdigitaleasy.com">
                        Learn more about the transfer-out process
                      </a>
                    </div>
                  </Grid>
                </Grid>
              </div>

            </Box>
          </Modal>
        </div>
        <style>
          {`
                    .button{
                        color: rgb(255, 255, 255);
                        background-color: rgb(231, 96, 96);
                        margin-top: 20px;
                        padding: 10px;
                        cursor: pointer;
                        width:max-content
                    }
                    `}
        </style>
      </div>
    </>
  );
};

export default TransferToken;
