import { Box, Button, Fade, IconButton, Paper, Popper, Stack, Tooltip, Typography } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import variables from "../../../assets/styles/_colors.scss";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { getContactColumns, getContactTableData } from "../../../webservices/PipelineService";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect } from "react";
import UpsertContact from "../../contacts/create/UpsertContact";
import DeleteContact from "../../contacts/DeleteContact";
import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ContactQuickPreview from "../../contacts/view/ContactQuickPreview";

const StyledLink = styled(Link)`
    color: #212129;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
`;

const ContactsTab = (props) => {

    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

    const [createContact, setCreateContact] = useState(false);
    const [editContact, setEditContact] = useState(false);
    const [deleteContact, setDeleteContact] = useState(false);
    const [showQuickView, setShowQuickView] = useState(false);

    const transformColumns = (cols) => {
        let obj = [];
        cols?.map((col, index) => {
            if (col.selected) {
                if (col.accessorKey === "contactName" && index === 0) {
                    obj.push({
                        field: col.accessorKey,
                        headerName: col.header,
                        width: 150,
                        cellClassName: index === 0 && ((params) => {
                            return 'firstColumn'
                        }),
                        renderCell: (params) => {
                            <>
                                <StyledLink to={`/crm/contacts/1`} style={{ color: selectedRowId == params.row.id ? `${variables.linkColor}` : `${variables.pipelineBoardText}` }} >
                                    {params.row.contactName}
                                </StyledLink>
                                <Typography component="span" sx={{ pl: 1.5 }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setShowQuickView(true) }}>
                                    <Tooltip title="Quick Preview" placement="right">
                                        <IconButton sx={{ p: 0 }}>
                                            <VisibilityOutlinedIcon fontSize="small" color={variables.greyTextColor} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </>
                        }

                    })
                } else {
                    obj.push({
                        field: col.accessorKey,
                        headerName: col.header,
                        width: 150
                    });
                }
            }
        });
        setColumns(obj);
    }

    useEffect(() => {
        getContactColumns().then((colResponse) => {
            transformColumns(colResponse.data);
            getContactTableData(0, 10).then((response) => {
                setRows(response.rows);
            });
        });
    }, []);

    useEffect(() => {
        console.log('selected rowwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', selectedRow);
    }, [selectedRow]);

    const popperRef = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handlePopperOpen = (event) => {
        const hoveredDataset = event.currentTarget.dataset;
        console.log(hoveredDataset);
        const row = rows.find((r) => r.id == hoveredDataset.id);
        setSelectedRowId(hoveredDataset.id);
        setSelectedRow(row);
        setAnchorEl(event.currentTarget);
    };

    const handlePopperClose = (event) => {
        if (
            anchorEl == null ||
            popperRef.current.contains(event.nativeEvent?.relatedTarget)
        ) {
            return;
        }
        setAnchorEl(null);
    };

    return (
        <>
            <Stack direction="row" alignItems="center" width="100%" sx={{ p: 1, pl: 1.5, pr: 2 }}>
                <Stack direction="row" justifyContent="left" width='90%' sx={{ fontWeight: 'bold' }}>
                    Contacts
                </Stack >
                <Stack direction="row" justifyContent="right" width="10%" spacing={2}>
                    <Button variant="outlined" onClick={() => setCreateContact(true)}><AddIcon /> Contact </Button>
                </Stack>
            </Stack >
            <Box sx={{ height: '73.5vh', width: '100%' }}>
                <DataGrid
                    columnHeaderHeight={40}
                    rowHeight={48}
                    rows={rows}
                    columns={columns}
                    sx={{
                        "& .MuiDataGrid-columnHeaderTitleContainer": {
                            fontSize: 14
                        },
                        "& .MuiDataGrid-cellContent": {
                            fontSize: 14
                        },
                        '& .firstColumn': {
                            fontWeight: 'bold'
                        }
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 100,
                            },
                        },
                    }}
                    slots={{
                        noRowsOverlay: () => (
                            <Stack alignItems="center" justifyContent="center" sx={{ pt: 3, fontSize: 13.5 }}>
                                This record doesn't have any contacts.
                            </Stack>
                        )
                    }}
                    slotProps={{
                        row: {
                            onMouseEnter: handlePopperOpen,
                            onMouseLeave: handlePopperClose
                        }
                    }}
                    pageSizeOptions={[100, 200]}
                    disableRowSelectionOnClick
                    disableColumnMenu
                />
                <Popper
                    ref={popperRef}
                    open={open}
                    anchorEl={anchorEl}
                    placement={"right"}
                    transition
                    onMouseLeave={() => setAnchorEl(null)}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper sx={{ transform: "translateX(69.5vw)" }}>
                                <Stack direction="row" spacing={1} alignItems="center" sx={{
                                    height: "47px",
                                    zIndex: 5,
                                    width: "85px",
                                    alignItems: "center",
                                    bgcolor: `${variables.lightGreyBackgroundColor}`,
                                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                                    pl: 0.5
                                }}>
                                    <IconButton>
                                        <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} onClick={() => setEditContact(true)} />
                                    </IconButton>
                                    <IconButton>
                                        <DeleteOutlineIcon fontSize='medium' color="error" onClick={() => setDeleteContact(true)} />
                                    </IconButton>
                                </Stack>
                            </Paper>
                        </Fade>
                    )}
                </Popper>
            </Box>

            {showQuickView && <ContactQuickPreview module={"contacts"} handleDrawerClose={() => { setShowQuickView(false); }} item={selectedRow} />}
            {deleteContact && <DeleteContact item={selectedRow} handleClose={() => { setDeleteContact(false); }} />}
            {editContact && <UpsertContact edit={true} editData={selectedRow} handleDrawerClose={() => { setEditContact(false); }} />}
            {createContact && <UpsertContact module={"contacts"} handleDrawerClose={() => { }} />}
        </>
    )
}

export default ContactsTab;