import * as React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Button, ButtonGroup, Grid, IconButton, List, ListItemButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import variables from '../../../../assets/styles/_colors.scss';
import { capitalizeFirstLetter, stringAvatar } from '../../../../utils/PipelineConstants';
import PersonIcon from '@mui/icons-material/Person';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { withStyles } from "@mui/styles";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import moment from 'moment/moment';
import { ActivityInfo } from './ActivityInfo';
import { Notes } from '../../../../components/notes/Notes';
import { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from "@mui/system";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import SplitButton from '../../../../components/SplitButton';
import DeleteActivity from '../../DeleteActivity';
import UpsertTask from '../../create/UpsertTask';
import FileUpload from '../../../../components/files/FilesUpload';

const StyledIcon = withStyles({
    root: {
        backgroundColor: `${variables.listBackgroundColor} !important`,

        "&:hover": {
            backgroundColor: `${variables.listBackgroundColor} !important`
        }
    }
})(IconButton);

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    p: 0,
    flexGrow: 1,
    minHeight: '100vh',
    minWidth: '100vw',
    height: '100vh',
    width: '100vw'
};

const tasks = [{
    "id": 1,
    "taskName": "Task 1",
    "dueDate": "Feb 09, 2024",
    "status": "In Progress",
    "priority": "Normal",
    "relatedTo": null,
    "taskOwner": "Admin 1"
},
{
    "id": 2,
    "taskName": "Task 2",
    "dueDate": "Feb 11, 2024",
    "status": "In Progress",
    "priority": "Normal",
    "relatedTo": "Mike Ross",
    "taskOwner": "Admin 2"
},
{
    "id": 3,
    "taskName": "Task 3",
    "dueDate": "Feb 15, 2024",
    "status": "Completed",
    "priority": "High",
    "taskOwner": "Admin 3"
},
{
    "id": 4,
    "taskName": "Task 4",
    "dueDate": "Feb 09, 2024",
    "status": "In Progress",
    "priority": "Normal",
    "relatedTo": "Harvey Specter",
    "taskOwner": "User 3"
},
{
    "id": 5,
    "taskName": "Task 5",
    "dueDate": "Feb 19, 2024",
    "status": "In Progress",
    "priority": "Normal",
    "relatedTo": "Mike Rolland",
    "taskOwner": "User 3"
},
{
    "id": 6,
    "taskName": "Task 6",
    "dueDate": "Feb 02, 2024",
    "status": "In Progress",
    "priority": "Normal",
    "taskOwner": "User 3"
},
{
    "id": 7,
    "taskName": "Task 7",
    "dueDate": "Feb 05, 2024",
    "status": "In Progress",
    "priority": "Normal",
    "taskOwner": "User 3"
},
{
    "id": 8,
    "taskName": "Task 8",
    "dueDate": "Feb 09, 2024",
    "status": "In Progress",
    "priority": "test user",
    "taskOwner": "User 3"
},
{
    "id": 9,
    "taskName": "Task 9",
    "dueDate": "Feb 29, 2024",
    "status": "In Progress",
    "priority": "High",
    "relatedTo": "John Doe",
    "taskOwner": "User 1"
},
{
    "id": 10,
    "taskName": "Task 10",
    "dueDate": "Feb 09, 2024",
    "status": "In Progress",
    "priority": "Normal",
    "taskOwner": "User 3"
}]

const events = [
    {
        "id": 1,
        "title": "Event 1",
        "from": "Feb 03, 01:00 PM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 1",
        "host": "Admin 1"
    },
    {
        "id": 2,
        "title": "Event 2",
        "from": "Feb 11, 03:00 AM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 4",
        "host": "Admin 2"
    },
    {
        "id": 3,
        "title": "Event 3",
        "from": "Feb 15, 01:00 PM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 1",
        "host": "Admin 3"
    },
    {
        "id": 4,
        "title": "Event 4",
        "from": "Feb 09, 04:00 AM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 21",
        "host": "User 3"
    },
    {
        "id": 5,
        "title": "Event 5",
        "from": "Feb 19, 01:00 AM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 21",
        "host": "User 3"
    },
    {
        "id": 6,
        "title": "Event 6",
        "from": "Feb 02, 08:00 AM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 21",
        "host": "User 3"
    },
    {
        "id": 7,
        "title": "Event 7",
        "from": "Feb 05, 01:00 PM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 21",
        "host": "User 3"
    },
    {
        "id": 8,
        "title": "Event 8",
        "from": "Feb 09, 07:00 AM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 21",
        "host": "User 3"
    },
    {
        "id": 9,
        "title": "Event 9",
        "from": "Feb 29, 09:00 AM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 3",
        "host": "User 1"
    },
    {
        "id": 10,
        "title": "Event 10",
        "from": "Feb 09, 01:00 AM",
        "to": "Feb 29, 03.00 PM",
        "relatedTo": "User 21",
        "host": "User 3"
    }];

const calls = [
    {
        "id": 1,
        "toFrom": "User 1",
        "callType": "Inbound",
        "callStartTime": "Feb 03, 01:00 PM",
        "callDuration": "30 secs",
        "relatedTo": "User 1",
        "callOwner": "Admin 1"
    },
    {
        "id": 2,
        "toFrom": "User 2",
        "callType": "Outbound",
        "callStartTime": "Feb 11, 03:00 AM",
        "callDuration": "10 mins",
        "relatedTo": "User 4",
        "callOwner": "Admin 2"
    },
    {
        "id": 3,
        "toFrom": "User 3",
        "callType": "Outbound",
        "callStartTime": "Feb 15, 01:00 PM",
        "callDuration": "20 mins",
        "relatedTo": "User 1",
        "callOwner": "Admin 3"
    },
    {
        "id": 4,
        "toFrom": "User 4",
        "callType": "Inbound",
        "callStartTime": "Feb 09, 04:00 AM",
        "callDuration": "15 mins",
        "relatedTo": "User 28",
        "callOwner": "User 3"
    },
    {
        "id": 5,
        "toFrom": "User 5",
        "callType": "Inbound",
        "callStartTime": "Feb 19, 01:00 AM",
        "callDuration": "10 mins",
        "relatedTo": "User 21",
        "callOwner": "User 3"
    },
    {
        "id": 6,
        "toFrom": "User 6",
        "callType": "Inbound",
        "callStartTime": "Feb 02, 08:00 AM",
        "callDuration": "40 mins",
        "relatedTo": "User 21",
        "callOwner": "User 3"
    },
    {
        "id": 7,
        "toFrom": "User 7",
        "callType": "Outbound",
        "callStartTime": "Feb 05, 01:00 PM",
        "callDuration": "25 mins",
        "relatedTo": "User 21",
        "callOwner": "User 3"
    },
    {
        "id": 8,
        "toFrom": "User 8",
        "callType": "Inbound",
        "callStartTime": "Feb 09, 07:00 AM",
        "callDuration": "40 secs",
        "relatedTo": "User 21",
        "callOwner": "User 3"
    },
    {
        "id": 9,
        "toFrom": "User 9",
        "callType": "Inbound",
        "callStartTime": "Feb 29, 09:00 AM",
        "callDuration": "10 mins",
        "relatedTo": "User 3",
        "callOwner": "User 1"
    },
    {
        "id": 10,
        "toFrom": "User 10",
        "callType": "Outbound",
        "callStartTime": "Feb 09, 01:00 AM",
        "callDuration": "10 mins",
        "relatedTo": "User 21",
        "callOwner": "User 3"
    }
];

const useStyles = makeStyles((theme) => ({
    listItemSelected: {
        backgroundColor: `${variables.listBackgroundColor} !important`,
    },
}));

const StyledLink = styled(Link)`
     color: #212129;
`;

export default function ActivityDetailedView(props) {

    const navigate = useNavigate();
    const paramsData = useParams();
    const locationData = useLocation();
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);
    const [editActivity, setEditActivity] = useState(false);
    const [deleteActivity, setDeleteActivity] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [items, setItems] = useState(null);

    useEffect(() => {
        if (locationData?.state) {
            const item = locationData?.state?.item;
            setSelectedItem(item);
        }
    }, [locationData?.state])

    const getCurrentItemIndex = (id) => {
        return items?.findIndex(item => item.id === (id ? id : selectedItemId));
    };

    useEffect(() => {
        setItems(paramsData.module === "task" && tasks
            || paramsData.module === "event" && events
            || paramsData.module === "call" && calls)
    }, [paramsData]);

    useEffect(() => {
        if (selectedItem) {
            setSelectedItemId(selectedItem.id);
            setSelectedItemIndex(getCurrentItemIndex(selectedItem.id));
        }
    }, [selectedItem]);

    const handleItemSelection = (item, index) => {
        navigate(`/crm/activities/${paramsData.module}/${item.id}`, {
            state: {
                module: paramsData.module,
                item: item,
            }
        })
    }

    const handleClose = (val) => {
        setOpen(false);
        // paramsData.handleClose(val);
    }

    const handleSplitButtonSelection = (actionLabel) => {

        if (actionLabel === "Mark as Completed") {
        }
        if (actionLabel === "Reschedule Call") {
        }
        if (actionLabel === "Cancel Call") {
        }
    }

    const handlePreviousClick = () => {

        const item = items[selectedItemIndex - 1];
        navigate(`/crm/activities/${paramsData.module}/${item.id}`, {
            state: {
                module: paramsData.module,
                item: item,
            }
        })
    }

    const handleNextClick = () => {

        const item = items[selectedItemIndex + 1];
        navigate(`/crm/activities/${paramsData.module}/${item.id}`, {
            state: {
                module: paramsData.module,
                item: item,
            }
        })
    }

    return (
        <>
            <Box sx={style}>
                <Grid container direction="row" sx={{ mt: 6, ml: 7.25, width: 'auto' }}>
                    <Grid container direction="row" xs={2.5}>
                        <Grid container direction="column" sx={{ borderRight: `1px solid ${variables.borderColor}` }}>
                            <Grid item sx={{ height: '65px', pt: 1, alignItems: "center" }}>
                                <Stack direction="row" alignItems="center">
                                    <IconButton onClick={() => handleClose(null)}>
                                        <StyledLink to={-1}><KeyboardBackspaceIcon /></StyledLink>
                                    </IconButton>
                                    <Typography component="div" variant="h6" sx={{ pl: 1 }}>All {capitalizeFirstLetter(paramsData?.module)}s </Typography>
                                </Stack>
                            </Grid>
                            <Grid item sx={{ overflowX: 'hidden', overflowY: 'auto', height: "calc(100vh - 110px)", borderTop: `1px solid ${variables.borderColor}` }}>
                                <Box>
                                    <List sx={{ pt: 0 }}>
                                        {items?.map((item, index) => {
                                            return <ListItemButton key={index} sx={{ p: 0, "&:hover #name": { color: `${variables.linkColor}` } }} selected={selectedItemId == item.id}
                                                classes={{ selected: classes.listItemSelected }} onClick={() => handleItemSelection(item, index)}
                                            >
                                                <Stack direction="row" alignItems="center" sx={{ width: '100%', p: 2, pr: 0, borderBottom: `1px solid ${variables.borderColor}` }}>
                                                    <Stack direction="column" justifyContent="left"
                                                        sx={{ width: '80%' }}
                                                    >
                                                        <Typography id="name" sx={{ fontWeight: 'bold' }}>
                                                            {paramsData.module === "task" && item.taskName}
                                                            {paramsData.module === "event" && item.title}
                                                            {paramsData.module === "call" && item.toFrom}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '13px', color: `${variables.greyTextColor}` }}>
                                                            {paramsData.module === "event" ? `${item.from} - ${item.to}` : item.relatedTo}
                                                        </Typography>
                                                    </Stack>
                                                    {paramsData.module === "task" &&
                                                        <Stack direction="column" sx={{ width: '20%' }} justifyContent="right">
                                                            <Typography sx={{ fontSize: '13px', color: `${variables.greyTextColor}` }}> {moment().format("MMM DD")} </Typography>
                                                        </Stack>}
                                                </Stack>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" xs={9.5}>
                        <Grid container direction="column" sx={{ pt: 1, pb: 1, pr: 2, pl: 2, justifyContent: "center", height: "65px" }}>
                            <Grid item width="40%">
                                <Typography variant="h6" sx={{ color: `${variables.linkColor}`, fontWeight: 600, fontSize: "15px" }}>
                                    {paramsData.module === "task" && selectedItem?.taskName}
                                    {paramsData.module === "event" && selectedItem?.title}
                                    {paramsData.module === "call" && selectedItem?.toFrom}
                                </Typography>
                                <Typography sx={{ pt: 0.5 }}>
                                    <PersonIcon size="small" sx={{ width: 16, height: 16 }} />
                                    <Typography component="span" sx={{ fontSize: "13px", pl: 1 }}>
                                        {paramsData.module === "task" && selectedItem?.taskOwner}
                                        {paramsData.module === "event" && selectedItem?.host}
                                        {paramsData.module === "call" && selectedItem?.callOwner}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item width="60%" sx={{ pr: 1 }}>
                                <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                                    {paramsData.module === "task" &&
                                        <Button variant="contained"> Mark as completed </Button>}
                                    {paramsData.module === "call" &&
                                        <SplitButton options={["Reschedule Call", "Cancel Call"]} label="Mark as Completed" width={200} handleSplitButtonSelection={(actionLabel) => handleSplitButtonSelection(actionLabel)} />}
                                    {/* <Typography component="div" sx={{ p: "6px 16px", borderRadius: 2, backgroundColor: `${variables.listBackgroundColor}` }}>
                                        <CheckCircleOutlineIcon color="primary" /> Completed
                                    </Typography>
                                    {paramsData.module === "call" && <Typography component="div" sx={{ p: "6px 16px", borderRadius: 2, backgroundColor: `${variables.listBackgroundColor}`, color: `${variables.greyTextColor}` }}>
                                        <PhoneDisabledIcon /> Cancelled
                                    </Typography>} */}
                                    <StyledIcon onClick={() => setEditActivity(true)}>
                                        <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
                                    </StyledIcon>
                                    <StyledIcon onClick={() => setDeleteActivity(true)}>
                                        <DeleteOutlineIcon fontSize='medium' color="error" />
                                    </StyledIcon>
                                    <ButtonGroup variant="outlined" sx={{ height: 35, borderRadius: 30, border: `1px solid ${variables.borderColor}` }}>
                                        <IconButton sx={{ borderRadius: 0, borderRight: `1px solid ${variables.borderColor}` }} disabled={selectedItemIndex === 0} onClick={handlePreviousClick}>
                                            <NavigateBeforeIcon fontSize='medium' />
                                        </IconButton>
                                        <IconButton sx={{ borderRadius: 0 }} disabled={selectedItemIndex === (items?.length - 1)} onClick={handleNextClick}>
                                            <NavigateNextIcon fontSize='medium' />
                                        </IconButton>
                                    </ButtonGroup>

                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container direction="column" height="calc(100vh - 65px)" sx={{ backgroundColor: `${variables.tabsBackgroundColor}`, p: 2, pb: 0, pr: 2 }}>
                            <Grid container="row">
                                <Grid item xs={selectedItem?.relatedTo ? 8 : 12} sx={{ backgroundColor: `${variables.whiteColor}`, overflowX: 'hidden', overflowY: 'auto', height: '82vh', pb: 6 }}>
                                    <ActivityInfo module={paramsData.module} />
                                    <Typography component="div" sx={{ fontWeight: "bold", pt: 2, pl: 2 }}>Notes</Typography>

                                    <Box sx={{ pl: 1, mt: -1 }}>
                                        <Notes />
                                    </Box>
                                    {paramsData.module !== "call" &&
                                        <FileUpload />
                                    }
                                </Grid>
                                {selectedItem?.relatedTo &&
                                    <Grid item xs={4}>
                                        <Box sx={{ p: 2, pt: 5, bgcolor: "#fff", height: '82vh' }}>
                                            <Stack direction="row" alignItems="center" spacing={2} sx={{ cursor: 'pointer' }}>
                                                <Stack direction="column">
                                                    <Avatar {...stringAvatar(`${selectedItem.relatedTo}`)} />
                                                </Stack>
                                                <Stack direction="column">
                                                    <Stack direction="row" sx={{ fontWeight: 'bold', "&:hover": { color: `${variables.linkColor}` } }}>
                                                        {selectedItem.relatedTo}
                                                    </Stack>
                                                    <Stack direction="row" sx={{ fontWeight: 'bold', "&:hover": { color: `${variables.linkColor}` } }}>
                                                        Zylker Corp
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <Stack direction="row" sx={{ pt: 2, pl: 1 }}>
                                                <MailOutlineIcon />
                                                <Typography sx={{ pl: 2 }}>support@iaaxin.com</Typography>
                                            </Stack>
                                            <Stack direction="row" sx={{ pt: 1, pl: 1 }}>
                                                <PhoneAndroidIcon />
                                                <Typography sx={{ pl: 2 }}>609-884-0686</Typography>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {deleteActivity &&
                <DeleteActivity item={props.item} module={props.module} handleClose={() => { setDeleteActivity(false) }} />}

            {editActivity &&
                <UpsertTask edit={true} module={props.module} handleDrawerClose={() => { setEditActivity(false) }} />}
        </>
    );
}
