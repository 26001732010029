import React, { useState } from "react";
import { Modal, Box, IconButton, Grid, Stack } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { useRecoilState } from "recoil";
import { selecteddomainn } from "../../../../../webservicesatoms/webserviceatom.js";
import { gstCalculation } from "../../../Gsuit/HelperFunctions/helperFunction.js";
import RenewalStyle from "../../../Gsuit/Style/Renewal.module.css";
import { PriceFormat } from "../../../CustomHook/DateFormat.js";
import RenewalButton from "../../../Component/Renewal/Componets/RenewalButton.js";
import RenewalConfirm from "../../../Component/Renewal/Componets/RenewalConfirm.js";
import RenewalDropDown from "../../../Component/Renewal/Componets/RenewalDropDown.js";
import PriceTable from "../../../Component/Renewal/Componets/PriceTable.js";
import RenewalPayment from "../../../Component/Renewal/Componets/ProcessRenewal.js";
import { api_url1 } from "../../../../../globalSettings.js";
import axios from "axios";
import IsWallet from "../../../CustomHook/IsWallet.js";
import { displayRazorpay } from "../../../CustomHook/RazorPay/RazorPay.js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "10px",
};

const ChangeDomain = ({
  open,
  handleClose,
  setSnackbarInfo,
  setUpgradeGst,
  upgradeGst,
  changePlan,
  setChangePlan,

  changeDuration,
  upgradePlann,
  setUpgradePlan,
  upgradeDuration,
  setUpgradeDuration,
  id,

}) => {
  const [Selecteddomain, setSelecteddomain] = useRecoilState(selecteddomainn);
  const [scroll, setScroll] = React.useState("paper");

  const [processModal, setProcessModal] = useState(false);
  function handleCloseModal() {
    setProcessModal(false);
  }
  function handleDuration(event) {
    setUpgradeDuration(event.target.value);
    const upgradePrice = changeDuration?.price.find((data) => {
      return data.period === event.target.value && data._id === upgradePlann;
    });
    setUpgradeGst(
      gstCalculation(upgradePrice?.price, changeDuration?.userData)
    );
    setChangePlan(upgradePrice);
  }
  function handlePlan(event) {
    setUpgradePlan(event.target.value);
    const upgradePrice = changeDuration?.price.find((data) => {
      return data.period === upgradeDuration && data._id === event.target.value;
    });
    setUpgradeGst(
      gstCalculation(upgradePrice?.price, changeDuration?.userData)
    );
    setChangePlan(upgradePrice);
  }
  const [loading, setLoading] = useState(false);
  function pleskChangePlan() {
    setLoading(true);
    let obj = {
      // domainName: domainName,
      productId: upgradePlann,
      id: id,
      period: upgradeDuration,
      isWallet: IsWallet(changeDuration?.walletBalance),
    };
    axios
      .post(`${api_url1}/payment/plesk/changeplan`, obj)
      .then((result) => {
        if (!result.data.isRazorpay) {
          setSnackbarInfo({
            open: true,
            severity: "success",
            message:
              "Payment Successfully Made Via Wallet,It Will Take Few Minutes To Update",
          });
        } else {
          const handlerFunction = function (response) {
            setSnackbarInfo({
              open: true,
              severity: "success",
              message: "Payment Successful,It Will Take Few Minutes To Update!",
            });
          };
          let options = {
            ...result.data.options,
            handler: handlerFunction,
          };
          displayRazorpay(options, setSnackbarInfo);
        }

        handleCloseModal();
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <Modal
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="_addcompanytitle_">
            Change Plan
            <IconButton
              component="span"
              onClick={handleClose}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <Stack
            spacing={2}
            padding={2}
            width="100%"
            alignItems="center"
            height="100%"
          >
            <RenewalConfirm
              confirmationText={"Are You Sure You Want To Change The Plan For"}
              domainName={Selecteddomain.domainName}
              isDelete={false}
            />
            <div className={RenewalStyle.middleWrapper} style={{justifyContent:"space-between"}}>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>Plan</div>
                  <RenewalDropDown
                    value={upgradePlann}
                    handleChange={handlePlan}
                    valueParam={"_id"}
                    keyParam={"_id"}
                    displayParam={"name"}
                    items={changeDuration?.upgradePlans}
                  />
                </div>
              </div>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>Renewal Cost</div>
                  <div className={RenewalStyle.accFontSize}>
                    {changeDuration?.userData?.currencyCode}{" "}
                    {PriceFormat(changePlan?.price)}/{changePlan?.param}
                  </div>
                </div>
              </div>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>Duration</div>
                  <RenewalDropDown
                    value={upgradeDuration}
                    handleChange={handleDuration}
                    items={changeDuration?.filteredFields}
                    isArray={true}
                  />
                </div>
              </div>
            </div>
            <div className={RenewalStyle.price_wrapper}>
              <PriceTable
                currencyCode={changeDuration?.userData?.currencyCode}
                priceData={changePlan?.price}
                gst={upgradeGst?.gst}
                tax={upgradeGst?.fullAmount}
              />
            </div>
          </Stack>
          <RenewalButton
            loading={false}
            title={"Proceed"}
            disabled={false}
            clickFunc={() => {
              handleClose();
              setProcessModal(true);
            }}
          />
        </Box>
      </Modal>
      <RenewalPayment
        handleCloseModal={handleCloseModal}
        open={processModal}
        fullAmount={upgradeGst?.fullAmount}
        currencyCode={changeDuration?.userData?.currencyCode}
        walletBalance={changeDuration?.walletBalance}
        loading={loading}
        proceedFunc={pleskChangePlan}
      />
    </>
  );
};

export default ChangeDomain;
