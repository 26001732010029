import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Button from "../../../../components/Button";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import RedAsterisk from "../../../../components/RedAsterisk";
import MyCheckbox from "../../../../components/CheckBox";
import WebSettings from "./WebSettings";
import People from '../../../../Images/People.png'
import Settings from '../../../../Images/Settings.png'
import threedots from '../../../../Images/threedotsmenu.png'
import integration from '../../../../Images/Integration.png'
import Edit from '../../../../Images/Edit.png'
import trash from '../../../../Images/Trashcan.png'

function WebsiteSettings() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [websites, setWebsites] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDialogOpen2, setDialogOpen2] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState(null); 
  

  const handleEmployeeList = () => {
    setDialogOpen2(true);
  };
  const handleTableMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleCloseDialog2 = () => {
    setDialogOpen2(false);
  };
  const handleBackToWebsiteSettings =()=>{
    setSelectedWebsite(null)
  }

  const handleWebsiteSettings = (website) => {
    setSelectedWebsite(website); // Set the selected website
    
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleDialogClose = (formikProps, isSubmitting = false) => {
    if (!isSubmitting) {
      formikProps.resetForm(); 
    }
    setDialogOpen(false); 
  };
  const handleAddWebsite = (values) => {
    const newWebsite = { name: values.name, domain: values.domain };
    setWebsites((prevWebsites) => [...prevWebsites, newWebsite]);
    setDialogOpen(false);
  };

  const iconStyle = {
    width: "5vw",
    height: "5vw",
    maxWidth: "25px",
    maxHeight: "25px",
    marginRight: "10px",
    cursor: "pointer",
  };
  const menuStyle = {
    width: "1vw",
    marginRight: "10%",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Website Name is required"),
    domain: Yup.string().required("Website Domain is required"),
  });
  const containerValue = true; // or any dynamic value
  return (
    <div
      style={{
        // border: "1px solid red",
        height: "75.8vh",
      }}
    >{selectedWebsite ?(
      <WebSettings website={selectedWebsite} handleBackToWebsiteSettings={handleBackToWebsiteSettings} />
    ) :(

   

    
      <div
        style={{
          padding: "4% 3%",
          // border: " 1px solid red",
        }}
      >
        <div
          style={{
            // border: "1px solid red ",
            // height: "55vh",
            overflowY: "auto",

            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              // border: "1px solid black",
              width: "100%",
              height: "9vh",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontWeight: "bolder",
              }}
            >
              Website Settings
            </Typography>
            <span>
              <Button onClick={handleDialogOpen}>Add Website</Button>
            </span>
          </div>
          <div
            style={{
              // overflowY: "auto",
              // border: "1px solid blue",
              flex: "1",
            }}
          >
            <Table
              style={{
                border: "1px solid #efefef",
              }}
            >
              <TableHead style={{ background: "rgba(245, 245, 248, 1)" }}>
                <TableRow>
                  <TableCell colSpan={5}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        height: "35px",
                        padding: "0px 10px",
                        gap: "10px",
                      }}
                    >
                      <span>Website Memberships</span>
                      <span>
                        You have memberships on those websites. Choose a website
                        to view its configuration.
                      </span>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {websites.map((website, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        height: "10px",
                        width: "20px",

                        padding: "5px 12px",
                      }}
                    >
                      <svg width="50" height="50">
                        <circle
                          cx="25"
                          cy="25"
                          r="20"
                          stroke="rgba(245,245,248,1)"
                          fill="rgba(245, 245, 248, 1)"
                        />
                        <text
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          fill="black"
                          fontSize="20px"
                          // fontWeight="bold"
                        >
                          {website.name.charAt(0)}
                        </text>
                      </svg>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "3px 16px",
                      }}
                    >
                      <Typography>{website.name}</Typography>
                      <p
                        style={{
                          margin: "0",
                        }}
                      >
                        {website.domain}
                      </p>
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "2px 16px",
                      }}
                    >
                      <img
                        src={People}
                        alt="Organization"
                        style={iconStyle}
                        onClick={handleEmployeeList}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "2px 16px",
                      }}
                    >
                      <img
                        src={Settings}
                        alt="Organization"
                        style={iconStyle}
                        onClick={() => handleWebsiteSettings(website)}
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        padding: "2px 16px",
                      }}
                    >
                      <img
                        src={threedots}
                        alt="Organization"
                        style={iconStyle}
                        onClick={handleTableMenu}
                      />
                    </TableCell>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      sx={{
                        ".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper":
                          {
                            boxShadow: "0px 3px 3px 0px rgba(0,0,0,0.1)",
                          },
                      }}
                    >
                      <MenuItem onClick={handleCloseMenu}>
                        <img
                          src={integration}
                          alt="Organization"
                          style={menuStyle}
                        />
                        Integrations
                      </MenuItem>
                      <MenuItem onClick={handleCloseMenu}>
                        <img
                          src={Edit}
                          alt="Organization"
                          style={menuStyle}
                        />
                        Edit
                      </MenuItem>
                      <MenuItem onClick={handleCloseMenu}>
                        <img
                          src={trash}
                          alt="Organization"
                          style={menuStyle}
                        />
                        Delete
                      </MenuItem>
                    </Menu>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
           
            <Dialog
              open={isDialogOpen}
              onClose={handleDialogClose}
              maxWidth="xs"
              fullWidth
            >
              <DialogTitle style={{ fontSize: "calc( 0.4vw + 10px )" }}>
                Add Website
              </DialogTitle>
              <DialogContent>
                <Formik
                  initialValues={{ name: "", domain: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handleAddWebsite(values);
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting, ...formikProps }) => (
                    <Form>
                      <div>
                        <div
                          style={
                            {
                              // border: "1px solid red"
                            }
                          }
                        >
                          <div
                            style={{
                              padding: "2% 0%",
                            }}
                          >
                            <Typography
                              style={{
                                padding: "1% 0%",
                              }}
                            >
                              WebSite Name
                              <RedAsterisk />
                            </Typography>
                            <Field
                              type="text"
                              name="name"
                              placeholder="Enter Website Name"
                              as={TextField}
                              fullWidth
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              style={{ color: "red" }}
                            />
                          </div>

                          <Typography
                            style={{
                              padding: "1% 0%",
                            }}
                          >
                            WebSite Domain
                            <RedAsterisk />
                          </Typography>
                          <Field
                            type="text"
                            name="domain"
                            placeholder="Enter Website Domain"
                            as={TextField}
                            fullWidth
                          />
                          <ErrorMessage
                            name="domain"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </div>
                      </div>
                      <DialogActions>
                        <Button
                          onClick={() => handleDialogClose(formikProps, false)} // Pass false as second parameter
                          style={{
                            background: "rgba(0,0,0,0)",
                            color: "black",
                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                        </Button>
                      </DialogActions>
                    </Form>
                  )}
                </Formik>
              </DialogContent>
            </Dialog>
            <Dialog
              open={isDialogOpen2}
              onClose={handleCloseDialog2}
              maxWidth="sm"
              fullWidth
            >
              <div
                style={{
                  padding: "10px",

                  background: "rgba(0, 10, 255, 1)",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                }}
              >
                <CloseIcon
                  onClick={handleCloseDialog2}
                  sx={{
                    marginLeft: "95%",
                    color: "white",
                  }}
                />
              </div>
              <DialogTitle>Employee List</DialogTitle>
              <DialogContent>
                <Paper elevation={2}>
                  <Table container={containerValue.toString()}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>Employee Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from({ length: 10 }, (_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <MyCheckbox />
                          </TableCell>
                          <TableCell>Employee {index + 1}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  style={{ boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)" }}
                >
                  Submit
                </Button>
                <Button
                  onClick={handleCloseDialog2}
                  variant="contained"
                  style={{
                    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.1)",
                    background: "rgba(0,10,255,1)",
                  }}
                >
                  Close
                </Button>
              </DialogActions>
              <div
                style={{
                  border: "1px solid transparent",
                  padding: "15px",
                  borderRadius: "5px",
                  background: "rgba(245, 245, 248, 1)",
                }}
              ></div>
            </Dialog>
          </div>
        </div>
      </div>
      )}
    </div>
  );
}

export default WebsiteSettings;
