import { Box, Stack } from "@mui/material";
import ActivitiesMainCalendar from "./ActivitiesMainCalendar";
import ActivitiesRightPane from "./ActivitiesRightPane";
import { useState } from "react";

export default function ActivitiesCalendar() {

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateFromMain, setSelectedDateFromMain] = useState(null);

    return (
        <Stack direction="row" spacing={2} sx={{ pt: 1.5, pl: 1 }}>
            <Box sx={{ width: '78vw', height: 'calc(100vh - 110px)', overflow: 'hidden' }}>
                <ActivitiesMainCalendar selectedDate={selectedDate} handleDateSelection={(date) => setSelectedDateFromMain(date)} />
            </Box>
            <Box sx={{ width: '20vw', height: 'calc(100vh - 110px)', overflow: 'auto', pr: 0.5 }}>
                <ActivitiesRightPane selectedDateFromMain={selectedDateFromMain} handleDateSelection={(date) => setSelectedDate(date)} />
            </Box>
        </Stack>
    )
}