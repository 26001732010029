import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ListItemIcon, Stack, ToggleButton, ToggleButtonGroup, Button, IconButton, ListItemText, Box, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AddIcon from '@mui/icons-material/Add';
import UpsertDeal from '../create/UpsertDeal';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import variables from "../../../assets/styles/_colors.scss";
import FilterListIcon from '@mui/icons-material/FilterList';
import PipelineFilterWrapper from '../filter/PipelineFilterWrapper';
import TableBulkActionsPopover from './bulkactions/TableBulkActionsPopover';
import CloseIcon from "@material-ui/icons/Close";
import { pipeline_atoms } from '../../../../../Atoms/CrmAtoms';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import ChangeOwner from './bulkactions/ChangeOwner';
import { CrmViews } from '../../../components/CrmViews';

const PipelineActions = (props) => {
    const [sortBy, setSortBy] = useState('closingDate');
    const [sortOrder, setSortOrder] = useState('asc');
    const [showDeal, setShowDeal] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [bulkActionsCount, setBulkActionsCount] = useState(0);
    const [pipelineAtom, setPipelineAtom] = useRecoilState(pipeline_atoms);
    const [showChangeOwner, setShowChangeOwner] = useState(false);

    useEffect(() => {
        resetBulkSelection();
    }, []);

    const updateSelection = () => {
        const count = pipelineAtom.isBoardView ? pipelineAtom.selectedBoardData : pipelineAtom.selectedTableData;
        if (count > 0) {
            setBulkActionsCount(count);
            setShowBulkActions(true);
        } else {
            setBulkActionsCount(0);
            setShowBulkActions(false);
        }
    };

    useEffect(() => {
        updateSelection();
    }, [pipelineAtom.selectedBoardData]);

    useEffect(() => {
        updateSelection();
    }, [pipelineAtom.selectedTableData]);

    const handleSortByToggleChange = (event, sortOrder) => {
        setSortOrder(sortOrder);
    };

    const handleSortByChange = (event) => {
        setSortBy(event.target.value);
    };

    const handleFilterClick = () => {
        setShowFilter(!showFilter);
        props.isFilterOpen(!showFilter);
    }

    const handleViewTypeChange = (event) => {
        event.target.value === 'pipeline' ? props.sendViewType(true) : props.sendViewType(false);
    }

    const toggleControl = {
        value: sortOrder,
        onChange: handleSortByToggleChange,
        exclusive: true,
    };

    const handleChangeOwner = () => {
        setShowChangeOwner(true);
    }

    const resetBulkSelection = () => {
        setShowBulkActions(false);
        let a = Object.assign({}, pipelineAtom);
        a.resetBulkSelection = true;
        a.selectedBoardData = 0;
        a.selectedTableData = 0;
        setPipelineAtom(a);
    };

    return (
        <>
            {!showBulkActions ?
                <Box sx={{ width: '100%' }}>
                    {showFilter &&
                        <PipelineFilterWrapper module="pipeline" open={showFilter} handleFilterClose={() => handleFilterClick()} />
                    }
                    <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                        <Stack direction="row" spacing={2} justifyContent="left" sx={{ width: '50%', color: "#000", mt: -0.5, pl: 1.75 }}>
                            {!showFilter &&
                                <IconButton color="primary" sx={{ background: `${variables.listBackgroundColor}` }}
                                    onClick={() => { handleFilterClick() }}>
                                    <FilterListIcon />
                                </IconButton>
                            }
                            <CrmViews module="pipeline" />
                        </Stack>
                        <Stack direction="row" justifyContent="right" spacing={2}
                            sx={{ right: 14, position: "absolute", width: '50%' }}
                        >
                            {props.pipelineView && <>
                                <FormControl sx={{ minWidth: 120 }} size="small">
                                    <InputLabel>Sort By</InputLabel>
                                    <Select
                                        labelId="pipeline-sort-by-label"
                                        id="pipeline-sort-by"
                                        value={sortBy}
                                        label="Sort By"
                                        onChange={handleSortByChange}
                                        inputProps={{ IconComponent: () => null }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={'closingDate'}>Closing Date</MenuItem>
                                        <MenuItem value={'createdTime'}>Created Time</MenuItem>
                                        <MenuItem value={'lastActivityTime'}>Last Activity Time</MenuItem>
                                    </Select>
                                </FormControl>
                                <Stack spacing={2}>
                                    <ToggleButtonGroup size="small" {...toggleControl}>
                                        <ToggleButton value="asc" key="asc">
                                            <ArrowUpwardIcon />
                                        </ToggleButton>
                                        <ToggleButton value="desc" key="desc">
                                            <ArrowDownwardIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                            </>
                            }
                            <FormControl sx={{
                                minWidth: 25,
                                maxWidth: 80,
                                ".MuiInputBase-root.MuiOutlinedInput-root": {
                                    maxHeight: 37
                                }
                            }} size="small">
                                <InputLabel>View Type</InputLabel>
                                <Select
                                    labelId="pipeline-view-type-label"
                                    id="pipeline-view-type"
                                    value={props.pipelineView ? 'pipeline' : 'list'}
                                    label="View Type"
                                    onChange={handleViewTypeChange}
                                    renderValue={(value) => {
                                        return (
                                            <Box sx={{ display: "flex" }}>
                                                {value === "pipeline" ?
                                                    <ListItemIcon>
                                                        <ViewKanbanOutlinedIcon />
                                                    </ListItemIcon>
                                                    :
                                                    <ListItemIcon>
                                                        <ListAltOutlinedIcon />
                                                    </ListItemIcon>}
                                            </Box>
                                        );
                                    }}
                                >
                                    <MenuItem value="pipeline">
                                        <ListItemIcon>
                                            <ViewKanbanOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText>Pipeline View</ListItemText>
                                    </MenuItem>
                                    <MenuItem value="list">
                                        <ListItemIcon>
                                            <ListAltOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText>List View</ListItemText>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <Button variant="contained" sx={{ height: 36, borderRadius: 20, width: 80 }} onClick={() => setShowDeal(true)}>
                                <AddIcon /> Deal
                            </Button>
                            <IconButton color="primary" sx={{ background: `${variables.listBackgroundColor}` }}>
                                <MoreVertIcon />
                            </IconButton>
                        </Stack>
                        {showDeal && <UpsertDeal handleDrawerClose={() => setShowDeal(false)} />}
                    </Stack>
                </Box>
                : <Box sx={{ width: '100%', pl: 1.5, mt: -0.75 }}>
                    <Stack justifyContent="left" direction="row" spacing={2}>
                        <Button variant="contained" onClick={handleChangeOwner} sx={{ textTransform: 'none', height: 40, borderRadius: 20, width: 135 }}>
                            <Typography>Change Owner</Typography>
                        </Button>
                        <TableBulkActionsPopover module="pipeline" />
                        <Box sx={{
                            background: `${variables.tabsBackgroundColor}`, borderRadius: 5, width: 'auto', pl: 2.5, pt: 0.5
                        }}>
                            <Typography component="span" sx={{ color: `${variables.blackTextColor}` }}>
                                {bulkActionsCount} Selected
                            </Typography>
                            <IconButton sx={{ ml: 2, mt: '-1px' }} onClick={resetBulkSelection}>
                                <CloseIcon style={{ fontSize: "17px" }} />
                            </IconButton>
                        </Box>
                    </Stack>
                    {showChangeOwner &&
                        <ChangeOwner handleDialogClose={() => setShowChangeOwner(false)} />
                    }
                </Box>
            }
        </>
    );
}
export default PipelineActions;