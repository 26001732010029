import React from 'react';
import Modal from 'react-modal';
import Slider from 'react-slick';
import { Pagination, Navigation , Scrollbar} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import './ImageSliderDialog.css'
// Import Swiper styles
import 'swiper/swiper.min.css';
// import "swiper/css/pagination";
// import 'swiper/modules/pagination/pagination.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/scrollbar/scrollbar.min.css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import ReactAudioPlayer from 'react-audio-player';


// Import CSS styles for the slider (assuming you have imported the necessary CSS file for react-slick)

// Modal styles
const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999
  },
  content: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '500px',
    maxHeight: '80%',
    overflow: 'hidden'
  }
};

export default function AudioSliderDialog({ isOpen, onClose, Audio }) {
    const settings = {
        // Customize the settings for the image slider
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      return (
        <Modal
        
          isOpen={isOpen}
          onRequestClose={onClose}
          style={modalStyles}
          contentLabel="Image Slider Dialog"
        >
          {/* <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={`https://server.nowdigitaleasy.com:3505/uploads/task/${image}`} alt={`Image ${index}`} />
              </div>
            ))}
          </Slider> */}
          <Swiper
            pagination={{
              type: "fraction",
            }}
            navigation={true}
            modules={[Navigation, Pagination, Scrollbar]}
            className="mySwiper"
          >  
          {Audio.map((audio, index) => (
            <div key={index}>
            <SwiperSlide>
            {/* <img src={`https://server.nowdigitaleasy.com:3505/uploads/task/${audio}`} alt={`Image ${index}`} /> */}
                     <ReactAudioPlayer
  src={`https://server.nowdigitaleasy.com:3505/uploads/task/converted/${audio}`}
//   autoPlay
  controls
/> 
            </SwiperSlide>
            </div>
            ))}
          </Swiper>
        </Modal>
      );
    }
