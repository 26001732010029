import React from 'react'

const UnassignHome = () => {
  return (
    <div>
      Unassigned
    </div>
  )
}

export default UnassignHome
