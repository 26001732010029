// import React, { useState, useEffect } from "react";
// import Avatar from "@mui/material/Avatar";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Modal from "@mui/material/Modal";
// import useGetVisitorInfo from "../VisitorInformation/components/useGetVisitorInfo";
// import usePutVisitorInfo from "../VisitorInformation/components/usePutInformation";
// import { useParams } froy65  ,,,m "react-router-dom";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import PublicIcon from "@mui/icons-material/Public";
// import { IoLogoChrome } from "react-icons/io";
// import CloudIcon from "@mui/icons-material/Cloud";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import { MdOutlineModeEdit } from "react-icons/md";
// import PersonIcon from "@mui/icons-material/Person";
// import Divider from "@mui/material/Divider";
// import Tooltip from "@mui/material/Tooltip";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import AddIcon from "@mui/icons-material/Add";
// import IconButton from "@mui/material/IconButton";
// import ClosedCaptionOffIcon from "@mui/icons-material/ClosedCaptionOff";
// import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
// import Chip from "@mui/material/Chip";
// import Stack from "@mui/material/Stack";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import { Card, CardContent, CardHeader } from "@mui/material";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import AddCircleIcon from "@mui/icons-material/AddCircle";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";
// import useVisitorsInformation from "../VisitorInformation/components/useVisitorsInformation";

// const Info = ({  VisitorId }) => {
//   console.log("santhiya conversationId",VisitorId)
//   const { websiteId } = useParams();

//   // const { data: visitorInfo } = useGetVisitorInfo(VisitorId, websiteId);

//   // const { data } = useVisitorsInformation(VisitorId);

//   const [editedName, setEditedName] = useState("");
//   const [editedEmail, setEditedEmail] = useState("");
//   const [isNameModalOpen, setIsNameModalOpen] = useState(false);
//   const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
//   const { mutateVisitorInfo: putVisitorInfo } = usePutVisitorInfo();
//   const [age, setAge] = React.useState("");
//   // const [segmentData, setSegmentData] = useState([]);
//   const [dataKey, setDataKey] = useState("");
//   const [dataValue, setDataValue] = useState("");
//   const [keyValue, setKeyValue] = useState("");
//   const [tableData, setTableData] = useState([]);
//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   // useEffect(() => {
//   //   if (visitorInfo) {
//   //     setEditedName(visitorInfo.name || "");
//   //     setEditedEmail(visitorInfo.email || "");
//   //   }
//   // }, [visitorInfo]);

//   const handleNameChange = (event) => {
//     setEditedName(event.target.value);
//   };

//   const handleEmailChange = (event) => {
//     setEditedEmail(event.target.value);
//   };

//   const handleSaveName = async () => {
//     try {
//       await putVisitorInfo({
//         VisitorId,
//         updatedData: { name: editedName },
//       });

//       setIsNameModalOpen(false);
//     } catch (error) {
//       console.error("Error updating name:", error.message);
//       console.error("Error response:", error.response);
//     }
//   };

//   const handleSaveEmail = async () => {
//     try {
//       await putVisitorInfo({
//         VisitorId,
//         updatedData: { email: editedEmail },
//       });

//       setIsEmailModalOpen(false);
//     } catch (error) {
//       console.error("Error updating email:", error.message);
//       console.error("Error response:", error.response);
//     }
//   };

//   // MAIN INFO
//   const [showMainInformation, setShowMainInformation] = useState(false);
//   const handleMainInformationClick = () => {
//     setShowMainInformation(!showMainInformation);
//   };

//   // VISITOR DEVICE
//   const [showVisitorDevice, setShowVisitorDevice] = useState(false);
//   const handleVisitorDeviceClick = () => {
//     setShowVisitorDevice(!showVisitorDevice);
//   };

//   //  CONVERSATION PARTICIPATE
//   const [showConversation, setShowConversation] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [participants, setParticipants] = useState([]);
//   const [participantEmail, setParticipantEmail] = useState("");

//   const handleConversationClick = () => {
//     setShowConversation(!showConversation);
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false);
//     setParticipantEmail("");
//   };

//   const handleAddClick = () => {
//     setIsModalOpen(true);
//   };

//   const handleRemoveParticipant = (index) => {
//     const updatedParticipants = [...participants];
//     updatedParticipants.splice(index, 1);
//     setParticipants(updatedParticipants);
//   };

//   const handleSaveParticipant = () => {
//     if (participantEmail.trim() !== "") {
//       setParticipants([...participants, participantEmail]);
//       setParticipantEmail("");
//     }
//     setIsModalOpen(false);
//   };

//   const [emails, setEmails] = useState([
//     "shiva@iaaxin.com",
//     "other@example.com",
//   ]);

//   //  QUICK JUMP
//   const [showquickJump, setQuickJump] = useState(false);
//   const handleQuickClick = () => {
//     setQuickJump(!showquickJump);
//   };

//   const [showquick_1Jump, setQuickJump_1] = useState(false);
//   const handleQuickClick_1 = () => {
//     setQuickJump_1(!showquick_1Jump);
//   };

//   const [showquickJump_2, setQuickJump_2] = useState(false);
//   const handleQuickClick_2 = () => {
//     setQuickJump_2(!showquickJump_2);
//   };

//   // SEGMENT
//   const [segmentText, setSegmentText] = React.useState("");
//   const [chips, setChips] = React.useState([]);

//   const handleInputChange = (e) => {
//     setSegmentText(e.target.value);
//   };

//   const handleInputKeyDown = (e) => {
//     if (e.key === "Enter" && segmentText.trim() !== "") {
//       addSegment(segmentText.trim());
//     }
//   };

//   const addSegment = (text) => {
//     setChips([...chips, text]);
//     setSegmentText("");
//   };

//   const handleDelete = (index) => {
//     const updatedChips = [...chips];
//     updatedChips.splice(index, 1);
//     setChips(updatedChips);
//   };

//   const [showSegmentConv, setShowSegmentConv] = useState(false);
//   const handleSegmentConvClick = () => {
//     setShowSegmentConv(!showSegmentConv);
//   };

//   // VISITOR DATA
//   const [showVisitorData, setShowVisitorData] = useState(false);
//   const handleVisitorDataClick = () => {
//     setShowVisitorData(!showVisitorData);
//   };

//   const handleDataKeyChange = (event) => {
//     setDataKey(event.target.value);
//   };
//   const handleDataValueChange = (event) => {
//     setDataValue(event.target.value);
//   };
//   const handleAddData = () => {
//     if (dataKey && keyValue) {
//       setTableData([...tableData, { dataKey, keyValue }]);
//       setDataKey("");
//       setKeyValue("");
//     }
//   };

//   const [inputValue, setInputValue] = useState("");
//   const handleInputClick = (e) => {
//     e.stopPropagation();
//   };

//   // LAST PROFILE EVENTS
//   const [showLastConv, setShowLastConv] = useState(false);
//   const handleLastConvClick = () => {
//     setShowLastConv(!showLastConv);
//   };

//   const [shownotePad, setShowNotepad] = useState(false);
//   const handleNotepadClick = () => {
//     setShowNotepad(!shownotePad);
//   };

//   // CUSTOM ACTIONS
//   const [showButtons, setShowButtons] = useState(false);

//   const handleShowButtonClick = () => {
//     setShowButtons(!showButtons);
//   };

//   const [showCustomActions, setshowCustomActions] = useState(false);
//   const handleCustomActionsClick = () => {
//     setshowCustomActions(!showCustomActions);
//   };

//   const location = useLocation();
//   const history = useNavigate();

//   const isActive = (path) => location.pathname === path;
//   const [showDropdown, setShowDropdown] = useState(false);
//   const handleToggleDropdown = () => {
//     setShowDropdown((prevState) => !prevState);
//   };
//   const handleClick = (path) => {
//     if (!isActive(path)) {
//       history(path);
//     }
//   };

//   return (
//     <Box sx={{ marginTop: "-56px", backgroundColor: "white",marginLeft:"400px" }}>
//       {/* {visitorInfo && data && ( */}
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             Width: "290px",
//             height: "90vh",
//           }}
//         >
//           {/* profile */}
//           <Avatar
//             sx={{
//               width: "60px",
//               height: "60px",
//               borderRadius: "100%",
//               backgroundColor: "#899499",
//               backgroundPosition: "center center",
//               backgroundSize: "120%",
//               backgroundRepeat: "no-repeat",
//               marginTop: "25px",
//               marginLeft: "-520px",
//             }}
//           />

//           {/* Name */}
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               marginTop: "-59px",
//               marginBottom: "-1px",
//             }}
//           >
//             <Box
//               sx={{
//                 fontFamily: "Poppins, sans-serif",
//                 overflow: "hidden",
//                 WebkitBoxFlex: 1,
//                 msFlex: 1,
//                 flex: 1,
//                 width: "155px",
//                 fontSize: "18px",
//                 lineHeight: "1.5",
//                 marginBottom: "-1px",
//                 fontWeight: 500,
//                 whiteSpace: "nowrap",
//                 wordWrap: "break-word",
//                 wordBreak: "break-all",
//                 marginLeft: "-270px",
//                 marginTop: "-2px",
//                 position: "relative",
//               }}
//             >
//               {/* <span>{data.data.name}</span> */}
//               <Button
//                 onClick={() => setIsNameModalOpen(true)}
//                 sx={{
//                   position: "absolute",
//                   top: 0,
//                   right: 0,
//                   cursor: "pointer",
//                   minWidth: "0px !important",
//                 }}
//               >
//                 <MdOutlineModeEdit sx={{ width: "22px" }} />
//               </Button>
//             </Box>
//           </Box>

//           {/* Email */}
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               fontFamily: "Poppins, sans-serif",
//             }}
//           >
//             <Box
//               sx={{
//                 color: "#2C405A",
//                 textDecoration: "none",
//                 display: "flex",
//                 alignItems: "center",
//                 overflow: "hidden",
//                 WebkitBoxFlex: 1,
//                 msFlex: 1,
//                 flex: 1,
//                 width: "155px",
//                 fontSize: "13px",
//                 lineHeight: "1.5",
//                 whiteSpace: "nowrap",
//                 wordWrap: "break-word",
//                 wordBreak: "break-all",
//                 marginLeft: "-260px",
//                 position: "relative",
//               }}
//             >
//               <>
//                 {/* <span>{data.data.set_email}</span> */}
//                 <Button
//                   onClick={() => setIsEmailModalOpen(true)}
//                   sx={{
//                     position: "absolute",
//                     top: 0,
//                     right: 0,
//                     cursor: "pointer",
//                   }}
//                 >
//                   <MdOutlineModeEdit sx={{ width: "22px" }} />
//                 </Button>
//               </>
//             </Box>
//           </Box>

//           {/* City */}
//           <Box
//             sx={{
//               fontFamily: "Poppins, sans-serif",
//               marginTop: "-3px",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "flex-end",
//               marginLeft: "-375px",
//             }}
//           >
//             <Box
//               sx={{
//                 height: "13px",
//                 fontSize: "13px",

//                 color: "#2C405A",
//                 marginBottom: "8px",
//                 userSelect: "all",
//                 wordBreak: "break-all",
//                 hyphens: "auto",
//               }}
//             >
//               {/* <span>{data.data.city}</span> */}
//             </Box>
//           </Box>

//           {/* new profile */}
//           <Button
//             variant="contained"
//             size="large"
//             sx={{
//               marginTop: "20px",
//               marginLeft: "-311px",
//               fontSize: "12px",
//               fontFamily: "Inter",
//               width: "260px",
//               display: "flex",
//               alignItems: "center",
//               lineHeight: "18px",
//               textTransform: "capitalize",
//             }}
//           >
//             <PersonIcon
//               sx={{
//                 marginRight: "8px",
//                 marginLeft: "-26px",
//               }}
//             />
//             {/* View {data.data.name}'s Profile */}
//           </Button>

// <Divider
//   sx={{
//     backgroundColor: "#75869c",
//     height: "1px",
//     width: "306px",
//     marginLeft: "-300px",
//     marginTop: "10px",
//   }}
// />

//           {/*============================================ Main Information ================================================================*/}
//           <Button
//             size="large"
//             onClick={handleMainInformationClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               MAIN INFORMATION
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />
//             {showMainInformation && (
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   backgroundColor: "whitesmoke",
//                   padding: "12px",
//                   width: "308px",
//                   zIndex: "10",
//                 }}
//               >
//                 <Tooltip title="Visitor city and country" placement="top" arrow>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginTop: "5px",
//                     }}
//                   >
//                     <LocationOnIcon
//                       sx={{
//                         fontSize: 19,
//                         color: "rgb(44, 64, 90)",
//                         marginTop: "5px",
//                         marginLeft: "8px",
//                       }}
//                     />
//                     <Typography
//                       style={{
//                         fontFamily: "Poppins",
//                         marginLeft: "13px",
//                         fontSize: 12,
//                         color: "#75869c",
//                       }}
//                     >
//                       {/* {data.data.city},{data.data.country} */}
//                     </Typography>
//                   </Box>
//                 </Tooltip>
//                 <Tooltip title="Visitor local time" placement="top" arrow>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginTop: "5px",
//                       marginLeft: "8px",
//                     }}
//                   >
//                     <AccessTimeIcon
//                       sx={{ fontSize: 19, color: "rgb(44, 64, 90)" }}
//                     />
//                     <Typography
//                       style={{
//                         fontFamily: "Poppins, sans-serif",
//                         marginLeft: "13px",
//                         fontSize: 13,
//                         color: "#75869c",
//                       }}
//                     >
//                       Undefined
//                     </Typography>
//                   </Box>
//                 </Tooltip>
//                 <Tooltip
//                   // title={visitorInfo?.country || ""}
//                   placement="top"
//                   arrow
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginTop: "5px",
//                       marginLeft: "8px",
//                     }}
//                   >
//                     <PublicIcon
//                       sx={{ fontSize: 19, color: "rgb(44, 64, 90)" }}
//                     />
//                     <Typography
//                       style={{
//                         fontFamily: "Poppins, sans-serif",
//                         marginLeft: "13px",
//                         fontSize: 13,
//                         color: "#75869c",
//                       }}
//                     >
//                       {" "}
//                       {/* {data.data.country} */}
//                     </Typography>
//                   </Box>
//                 </Tooltip>
//               </Box>
//             )}
//           </Button>
//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />

//           {/* ====================================================== visitor device ========================================================*/}
//           <Button
//             size="large"
//             onClick={handleVisitorDeviceClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               VISITOR DEVICE
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />
//             {showVisitorDevice && (
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   backgroundColor: "whitesmoke",
//                   padding: "12px",
//                   width: "308px",
//                   zIndex: "10",
//                 }}
//               >
//                 <Tooltip
//                   title="Visitor browser and system"
//                   placement="top"
//                   arrow
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginTop: "8px",
//                     }}
//                   >
//                     <IoLogoChrome
//                       style={{
//                         fontSize: 19,
//                         color: "rgb(44, 64, 90)",
//                         marginTop: "5px",
//                         marginLeft: "10px",
//                       }}
//                     />
//                     <Typography
//                       style={{
//                         fontFamily: "Poppins",
//                         marginLeft: "13px",
//                         fontSize: 13,
//                         color: "#75869c",
//                       }}
//                     >
//                       {/* {data.data.browserName} */}
//                     </Typography>
//                   </Box>
//                 </Tooltip>
//                 <Tooltip title="Visitor local time" placement="top" arrow>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginTop: "8px",
//                     }}
//                   >
//                     <CloudIcon
//                       sx={{
//                         fontSize: 19,
//                         color: "rgb(44, 64, 90)",
//                         marginTop: "5px",
//                         marginLeft: "10px",
//                       }}
//                     />
//                     <Typography
//                       style={{
//                         fontFamily: "Poppins",
//                         marginLeft: "13px",
//                         fontSize: 13,
//                         color: "#75869c",
//                       }}
//                     >
//                       {/* {data.data.ip} */}
//                     </Typography>
//                   </Box>
//                 </Tooltip>
//               </Box>
//             )}
//           </Button>
//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />
//           {/* ================================================== conversation participation =============================================== */}
//           <Button
//             size="large"
//             onClick={handleConversationClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               CONVERSATION PARTICIPATION
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />
//             {showConversation && (
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   backgroundColor: "whitesmoke",
//                   padding: "12px",
//                   width: "308px",
//                   zIndex: "10",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     marginTop: "8px",
//                   }}
//                 >
//                   <Tooltip title="Conversation Owner" placement="top" arrow>
//                     <MailOutlineIcon
//                       sx={{
//                         fontSize: 16,
//                         color: "rgb(44, 64, 90)",
//                         marginTop: "5px",
//                         marginLeft: "10px",
//                       }}
//                     />{" "}
//                   </Tooltip>
//                   <Typography
//                     style={{
//                       fontFamily: "Poppins",
//                       marginLeft: "15px",
//                       fontSize: 12,
//                       color: "#75869c",
//                     }}
//                   >
//                     {/* {data.data.conversation_participant} */}
//                   </Typography>
//                   <IconButton
//                     onClick={handleAddClick}
//                     style={{ marginLeft: "85px", padding: 0 }}
//                   >
//                     <AddIcon style={{ fontSize: 16, color: "#1976d2" }} />
//                   </IconButton>
//                 </Box>
//                 {participants.map((participant, index) => (
//                   <div
//                     key={index}
//                     style={{
//                       display: "flex",
//                       alignItems: "center",
//                       marginTop: "8px",
//                     }}
//                   >
//                     <ClosedCaptionOffIcon
//                       sx={{
//                         fontSize: 16,
//                         color: "rgb(44, 64, 90)",
//                         marginTop: "5px",
//                         marginLeft: "10px",
//                       }}
//                     />
//                     <Typography
//                       style={{
//                         fontFamily: "Poppins",
//                         marginLeft: "15px",
//                         fontSize: 12,
//                         color: "#75869c",
//                       }}
//                     >
//                       {participant}
//                     </Typography>
//                     <IconButton
//                       size="small"
//                       onClick={() => handleRemoveParticipant(index)}
//                       style={{ marginLeft: "85px", padding: 0 }}
//                     >
//                       <HighlightOffOutlinedIcon
//                         style={{ fontSize: 16, color: "#e0102b" }}
//                       />
//                     </IconButton>
//                   </div>
//                 ))}{" "}
//               </Box>
//             )}
//           </Button>
//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />

//           {/* model */}
//           {isModalOpen && (
//             <Card
//               open={isModalOpen}
//               onClose={handleCloseModal}
//               sx={{
//                 width: 400,
//                 backgroundColor: "whitesmoke",
//                 p: 2,
//                 position: "absolute",
//                 top: "50%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//               }}
//             >
//               <CardHeader>Add Participant</CardHeader>
//               <CardContent>
//                 <Typography
//                   variant="subtitle1"
//                   style={{ fontFamily: "Poppins", marginTop: "-34px" }}
//                 >
//                   Participant email address:
//                 </Typography>
//                 <TextField
//                   style={{ fontFamily: "Poppins", fontSize: "13px" }}
//                   label="Email"
//                   fullWidth
//                   margin="normal"
//                   value={participantEmail}
//                   onChange={(e) => setParticipantEmail(e.target.value)}
//                 />
//               </CardContent>
//               <CardContent>
//                 <Button
//                   onClick={handleCloseModal}
//                   color="primary"
//                   sx={{
//                     marginLeft: "55px",
//                     marginTop: "-28px",
//                     backgroundColor: "#1976d2",
//                     color: "#fff",
//                   }}
//                 >
//                   Cancel
//                 </Button>

//                 <Button
//                   onClick={handleSaveParticipant}
//                   color="primary"
//                   style={{
//                     marginLeft: "30px",
//                     marginTop: "-28px",
//                     backgroundColor: "#1976d2",
//                     color: "#fff",
//                   }}
//                 >
//                   <AddCircleIcon
//                     style={{ marginRight: "5px", fontSize: "15px" }}
//                   />
//                   Add Participant
//                 </Button>
//               </CardContent>
//             </Card>
//           )}

//           {/*===================================================== quickjump ==============================================================*/}
//           <Button
//             size="large"
//             onClick={handleQuickClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               QUICK JUMP
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />
//             {showquickJump && (
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   backgroundColor: "whitesmoke",
//                   padding: "12px",
//                   width: "308px",
//                   zIndex: "10",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     marginTop: "8px",
//                   }}
//                 >
//                   {/* i dropdown */}
//                 </Box>
//                 <Button
//                   size="large"
//                   onClick={handleQuickClick_1}
//                   sx={{
//                     marginTop: "-19px",
//                     marginLeft: "-12px",
//                     fontSize: "12px",
//                     fontFamily: "Poppins",
//                     width: "307px",
//                     display: "flex",
//                     lineHeight: "18px",
//                     borderRadius: "0px",
//                     backgroundColor: "white",
//                     height: "40px",
//                   }}
//                 >
//                   <Typography
//                     style={{
//                       marginLeft: "10px",
//                       color: "#75869c",
//                       fontSize: "12px",
//                       fontFamily: "Poppins",
//                       fontWeight: "bold",
//                     }}
//                   >
//                     Shared Image files
//                   </Typography>

//                   <ArrowDropDownIcon
//                     style={{
//                       color: "#75869c",
//                       marginLeft: "auto",
//                       marginRight: "10px",
//                     }}
//                   />
//                   {showquick_1Jump && (
//                     <Box
//                       sx={{
//                         position: "absolute",
//                         top: "40px",
//                         left: "0px",
//                         backgroundColor: "whitesmoke",
//                         padding: "12px",
//                         width: "308px",
//                         zIndex: "10",
//                       }}
//                     >
//                       <Box
//                         sx={{
//                           display: "flex",
//                           alignItems: "center",
//                           marginTop: "8px",
//                         }}
//                       >
//                         <Typography
//                           style={{
//                             fontFamily: "Poppins",
//                             marginLeft: "13px",
//                             fontSize: 13,
//                             color: "#75869c",
//                           }}
//                         >
//                           {/* {data.data.sharedImages_file} */}
//                         </Typography>
//                       </Box>
//                     </Box>
//                   )}
//                 </Button>
//               </Box>
//             )}
//           </Button>
//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />

//           {/* ============================================ segment for conversation ======================================================*/}
//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />

//           <Button
//             size="large"
//             onClick={handleSegmentConvClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               SEGMENTS FOR CONVERSATION
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />
//             {showSegmentConv && (
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   backgroundColor: "whitesmoke",
//                   padding: "12px",
//                   width: "308px",
//                   zIndex: "10",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     marginTop: "8px",
//                   }}
//                 >
//                   <Box
//                     component="form"
//                     sx={{
//                       "& .MuiTextField-root": { m: 1, width: "267px" },
//                     }}
//                     noValidate
//                     autoComplete="off"
//                   >
//                     <div onClick={(e) => e.stopPropagation()}>
//                       <TextField
//                         id="outlined-textarea"
//                         multiline
//                         InputProps={{
//                           style: {
//                             width: "250px",
//                             height: "30px",
//                             textAlign: "center",
//                             fontFamily: "Poppins",
//                             fontSize: "12px",
//                           },
//                           placeholder: "Segment conversation...",
//                         }}
//                         value={segmentText}
//                         onChange={handleInputChange}
//                         onKeyDown={handleInputKeyDown}
//                       >
//                         {/* {data.data.segment_conversation} */}
//                       </TextField>
//                     </div>
//                   </Box>
//                 </Box>
//                 <Stack direction="row" spacing={1}>
//                   {chips.map((chip, index) => (
//                     <Chip
//                       key={index}
//                       label={chip}
//                       onDelete={() => handleDelete(index)}
//                       size="small"
//                       deleteIcon={<CloseRoundedIcon />}
//                       style={{ fontFamily: "poppins", fontSize: "12px" }}
//                     />
//                   ))}
//                 </Stack>
//               </Box>
//             )}
//           </Button>
//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />
//           {/*================================================== visitor data ===============================================================*/}

//           <Button
//             size="large"
//             onClick={handleVisitorDataClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               VISITOR DATA
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />

//             {/* {data.showVisitorData && ( */}
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   backgroundColor: "whitesmoke",
//                   padding: "12px",
//                   width: "307px",
//                   zIndex: "10",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     marginTop: "8px",
//                   }}
//                 >
//                   <Box
//                     component="form"
//                     sx={{
//                       "& .MuiTextField-root": { m: 1, width: "267px" },
//                     }}
//                     noValidate
//                     autoComplete="off"
//                   >
//                     <div>
//                       <div
//                         style={{ display: "flex", marginBottom: "10px" }}
//                         onClick={(e) => e.stopPropagation()}
//                       >
//                         <input
//                           type="text"
//                           value={dataKey}
//                           onChange={(e) => setDataKey(e.target.value)}
//                           placeholder="Data Key"
//                           style={{
//                             width: "100px",
//                             height: "auto",
//                             marginRight: "10px",
//                             padding: "0",
//                             border: "none",
//                             borderBottom: "1px solid #000",
//                             outline: "none",
//                           }}
//                         />
//                         <input
//                           type="text"
//                           value={keyValue}
//                           onChange={(e) => setKeyValue(e.target.value)}
//                           placeholder="Key Value"
//                           style={{
//                             width: "100px",
//                             height: "auto",
//                             marginRight: "10px",
//                             padding: "0",
//                             border: "none",
//                             borderBottom: "1px solid #000",
//                             outline: "none",
//                           }}
//                         />
//                         <AddCircleOutlineIcon
//                           onClick={handleAddData}
//                           style={{
//                             height: "auto",
//                             marginLeft: "30px",
//                             fontSize: "16px",
//                           }}
//                         >
//                           {" "}
//                         </AddCircleOutlineIcon>
//                       </div>
//                     </div>
//                     <TableContainer
//                       component={Paper}
//                       style={{ maxHeight: "250px", overflow: "auto" }}
//                     >
//                       <Table>
//                         <TableHead>
//                           <TableRow>
//                             <TableCell
//                               style={{
//                                 fontFamily: "poppins",
//                                 fontSize: "12px",
//                                 padding: "15px",
//                               }}
//                             >
//                               Data Key
//                             </TableCell>
//                             <TableCell
//                               style={{
//                                 fontFamily: "poppins",
//                                 fontSize: "12px",
//                               }}
//                             >
//                               Key Value
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {tableData.map((row, index) => (
//                             <TableRow key={index}>
//                               <TableCell
//                                 style={{
//                                   fontFamily: "poppins",
//                                   fontSize: "12px",
//                                 }}
//                               >
//                                 {row.dataKey}
//                               </TableCell>
//                               <TableCell
//                                 style={{
//                                   fontFamily: "poppins",
//                                   fontSize: "12px",
//                                 }}
//                               >
//                                 {row.keyValue}
//                               </TableCell>
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   </Box>
//                 </Box>
//               </Box>
//             {/* )} */}
//           </Button>

//           {/* ==================================================== private notepad ========================================================= */}
//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />
//           <Button
//             size="large"
//             onClick={handleNotepadClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//               border: "none",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               PRIVATE NOTEPAD
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />
//             {shownotePad && (
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   width: "307px",
//                   backgroundColor: "whitesmoke",
//                 }}
//               >
//                 <input
//                   type="text"
//                   placeholder="Type a message here"
//                   value={inputValue}
//                   onChange={(e) => setInputValue(e.target.value)}
//                   style={{
//                     width: "100%",
//                     padding: "0px",
//                     border: "none",
//                     borderRadius: "none",
//                     backgroundColor: "white",
//                     height: "200px",
//                     marginTop: "10px",
//                   }}
//                 />
//               </Box>
//             )}
//           </Button>

//           {/*===================================================== CUSTOM ACTIONS =========================================================*/}

//           <Divider
//             sx={{
//               backgroundColor: "#75869c",
//               height: "1px",
//               width: "306px",
//               marginLeft: "-300px",
//             }}
//           />
//           <Button
//             size="large"
//             onClick={handleCustomActionsClick}
//             sx={{
//               marginTop: "0px",
//               marginLeft: "-298px",
//               fontSize: "12px",
//               fontFamily: "Poppins",
//               width: "307px",
//               display: "flex",
//               lineHeight: "18px",
//               borderRadius: "0px",
//               backgroundColor: "white",
//               height: "40px",
//             }}
//           >
//             <Typography
//               style={{
//                 marginLeft: "10px",
//                 color: "#75869c",
//                 fontSize: "12px",
//                 fontFamily: "Poppins",
//                 fontWeight: "bold",
//               }}
//             >
//               CUSTOM ACTIONS
//             </Typography>
//             <ArrowDropDownIcon
//               style={{
//                 color: "#75869c",
//                 marginLeft: "auto",
//                 marginRight: "10px",
//               }}
//             />
//             {showCustomActions && (
//               <Box
//                 sx={{
//                   position: "absolute",
//                   top: "40px",
//                   left: "0px",
//                   backgroundColor: "whitesmoke",
//                   padding: "12px",
//                   width: "307px",
//                   zIndex: "10",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     marginTop: "8px",
//                   }}
//                 >
//                   <Button
//                     size="large"
//                     onClick={handleShowButtonClick}
//                     sx={{
//                       marginTop: "-19px",
//                       marginLeft: "-12px",
//                       marginRight: "-48px",
//                       fontSize: "12px",
//                       fontFamily: "Poppins",
//                       width: "308px",
//                       display: "flex",
//                       lineHeight: "18px",
//                       borderRadius: "-16px",
//                       backgroundColor: "white",
//                       height: "40px",
//                     }}
//                   >
//                     <Typography
//                       style={{
//                         marginLeft: "10px",
//                         color: "#75869c",
//                         fontSize: "12px",
//                         fontFamily: "Poppins",
//                         fontWeight: "bold",
//                       }}
//                     >
//                       Send chat transcript
//                     </Typography>
//                     <ArrowDropDownIcon
//                       style={{
//                         color: "#75869c",
//                         marginLeft: "auto",
//                         marginRight: "10px",
//                       }}
//                     />
//                     {showButtons && (
//                       <Box
//                         sx={{
//                           position: "absolute",
//                           top: "40px",
//                           left: "0px",
//                           backgroundColor: "whitesmoke",
//                           padding: "12px",
//                           width: "307px",
//                           zIndex: "10",
//                         }}
//                       >
//                         <Button
//                           size="large"
//                           sx={{
//                             marginTop: "8px",
//                             fontSize: "12px",
//                             fontFamily: "Poppins",
//                             width: "100%",
//                             display: "flex",
//                             lineHeight: "18px",
//                             backgroundColor: "blue",
//                             height: "40px",
//                           }}
//                         >
//                           Email me a chat transcript
//                         </Button>
//                         <Button
//                           size="large"
//                           sx={{
//                             marginTop: "8px",
//                             fontSize: "12px",
//                             fontFamily: "Poppins",
//                             width: "100%",
//                             display: "flex",
//                             lineHeight: "18px",
//                             backgroundColor: "blue",
//                             height: "40px",
//                           }}
//                         >
//                           Email someone else a chat transcript
//                         </Button>
//                       </Box>
//                     )}
//                   </Button>
//                 </Box>
//               </Box>
//             )}
//           </Button>
//           {/* Modals */}
//           <Modal
//             open={isNameModalOpen}
//             onClose={() => setIsNameModalOpen(false)}
//           >
//             <Box
//               sx={{
//                 width: 400,
//                 backgroundColor: "whitesmoke",
//                 p: 4,
//                 position: "absolute",
//                 top: "15%",
//                 left: "35%",
//               }}
//             >
//               <Typography sx={{ marginBottom: 3, fontSize: "24px" }}>
//                 Edit Name
//               </Typography>
//               <TextField
//                 label="Name"
//                 value={editedName}
//                 onChange={handleNameChange}
//                 fullWidth
//                 sx={{ marginBottom: 2 }}
//               />

//               <Button
//                 variant="contained"
//                 sx={{ marginRight: 3, marginLeft: 17 }}
//                 onClick={handleSaveName}
//               >
//                 Save
//               </Button>
//               <Button
//                 variant="contained"
//                 onClick={() => setIsNameModalOpen(false)}
//               >
//                 Cancel
//               </Button>
//             </Box>
//           </Modal>

//           <Modal
//             open={isEmailModalOpen}
//             onClose={() => setIsEmailModalOpen(false)}
//           >
//             <Box
//               sx={{
//                 width: 400,
//                 backgroundColor: "whitesmoke",
//                 p: 4,
//                 position: "absolute",
//                 top: "15%",
//                 left: "35%",
//               }}
//             >
//               <Typography sx={{ marginBottom: 3, fontSize: "24px" }}>
//                 Edit Email
//               </Typography>
//               <TextField
//                 label="Email"
//                 value={editedEmail}
//                 onChange={handleEmailChange}
//                 fullWidth
//                 sx={{ marginBottom: 2 }}
//               />
//               <Button
//                 variant="contained"
//                 sx={{ marginRight: 3, marginLeft: 17 }}
//                 onClick={handleSaveEmail}
//               >
//                 Save
//               </Button>
//               <Button
//                 variant="contained"
//                 onClick={() => setIsEmailModalOpen(false)}
//               >
//                 Cancel
//               </Button>
//             </Box>
//           </Modal>
//         </Box>
//       {/* )} */}
//     </Box>
//   );
// };
// export default Info;

import React from "react";
import { Box, Typography } from "@mui/material";
import HeaderComponent from "./infocomponents/HeaderComponent";
import MainInfo from "./infocomponents/MainInfo";
import VisitorInfo from "./infocomponents/VisitorInfo";
import ConversationInfo from "./infocomponents/ConversationInfo";
import QuickJumpInfo from "./infocomponents/QuickJumpInfo";
import SegmentConvo from "./infocomponents/SegmentConvo";
import BlockIcon from '@mui/icons-material/Block';
import VisitorData from "./infocomponents/VisitorData";
import ProfileEvents from "./infocomponents/ProfileEvents";
import PrivateNote from "./infocomponents/PrivateNote";
import CustomAction from "./infocomponents/CustomAction";

const Info = ({ VisitorId }) => {
  console.log("santhiya conversationId", VisitorId);
  
  return (
    <>
      <Box sx={{ width: "285px", right: 0,height: "calc(100vh - 50px)", }}>
        <HeaderComponent />
        <Box sx={{overflowY:"auto",height:"73%",overflowX:"hidden"}}>
        <MainInfo />
        <VisitorInfo />
        <ConversationInfo />
        <QuickJumpInfo />
        {/* <SegmentConvo visitorId={VisitorId}/> */}
        <VisitorData />
        <ProfileEvents/>
        <PrivateNote />
        <CustomAction />
        </Box>
        <Box sx={{height:"65px",padding:"10px 0 0 75px"}}>
          <Box sx={{display:"flex",padding:"10px 15px 10px 15px",backgroundColor:"red",borderRadius:"3px",width:"130px",alignItems:"center",color:"#FFF",cursor:"pointer"}}>
            <BlockIcon/>
            <Typography sx={{paddingLeft:"5px"}}>Block User</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Info;
