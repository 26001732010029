import { Divider} from '@mui/material';
import React  from 'react'
import styled from "styled-components";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build/";

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column;
`;

const Typography = styled.div`
color:#4fb385;
font: 12px;
`;


const ContactNotesTab = () => {



React.useEffect(() => {
    ClassicEditor
      .create(document.querySelector('#editor'))
      .then(editor => {
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <Box > <div style={{display:"flex" , flexDirection : "row" , alignItems :"flex-start",marginBottom:"5px"}}>
    <Typography >New Notes</Typography>
    </div>
    <Divider style={{margin :"10px"}}/>
    <CKEditor
                    editor={ClassicEditor}
                    config={{
                      toolbar: [
                        "heading",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "fontColor",
                        "fontBackgroundColor",
                        "code",
                        "uploadImage",
                        "link",
                        "sourceEditing"
                      ],

                  
                    }}
                    
                    data=""
                    onReady={(editor) => {
                     
                    }}
                    onChange={(event, editor) => {


                    }}

                  />
    </Box>
    )
}

export default ContactNotesTab