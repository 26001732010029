import React, { useEffect,  useState } from "react";
import axios from "axios";
import { api_url, api_url1 } from "../../globalSettings";
import {
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import timezones from "timezones-list";
import Grid from "@mui/material/Grid";

import { Box } from "@mui/system";
import { selectedcompany } from "./managecompanyatom";
import "./CompanyListTable.css";
import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";

//=============delete ========================

import { Alert, Snackbar } from "@mui/material";
import "react-phone-number-input/style.css";
import { useRecoilState } from "recoil";
import userimg from "../../images/user.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NativeSelect from "@mui/material/NativeSelect";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import {
  login_atoms,
  login_token,
  login_companyid,
  triger_company,
} from "../../Atoms/LoginAtoms";


const currency = [
  { id: 1, day: "INR" },
  { id: 2, day: "USD" },

];

const day = [
  { id: 1, day: "MONDAY" },
  { id: 2, day: "TUESDAY" },
  { id: 3, day: "WEDNESDAY" },
  { id: 4, day: "THURESDAY" },
  { id: 5, day: "FRIDAY" },
  { id: 6, day: "SATURDAY" },
  { id: 7, day: "SUNDAY" },
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {

    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "1px solid #ced4da",
    borderRadius: "5px",
    fontSize: 14,
    padding: "9px 26px 9px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      // borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

//=============delete ========================



const CompanyListTable = ({ open123, handleClose123 }) => {

  const [avatar, setAvatar] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setAvatar(selectedFile);
  };



 
 
  const [value1, setValue1] = React.useState("");
  const [Getlist, setGetlist] = useState([]);
  const [Companydrawer, setCompanyDrawer] = useState(false);
  const [companyform, setCompanyForm] = useState({
    company: "",
    email: "",
    phone_number: "",
    contact_person: "",
    logo: "",
    gst_no: "",
    country: "",
    state: "",
    city: "",
    zip_code: "",
    currency: "",
    prefix: "",
    suffix: "",
    street1: "",
    street2: "",
  });


  const timezoneChange = (event) => {
    setValue1(event.target.value);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "calc(100vh - 85px)",
    bgcolor: "white",
    boxShadow: 24,
    overflow: "hidden",
    overflowY: "scroll",
  };

  //-----------------------  Delete  ------------------------------------

  const [open1, setOpen1] = React.useState(false);

 
  const [snackbaropen, setSnackbaropen] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const [snackbaropen1, setSnackbaropen1] = useState({
    open: false,
    message: "",
    severity: "",
  });

 

 

  //---------------------------------------------------------------
  const [trigerCompany, setTrigerCompany] = useRecoilState(triger_company);
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState("paper");
  const [open2, setOpen2] = React.useState(false);
  const [currencyId, setCurrencyId] = React.useState("");
  const [dayId, setDayId] = React.useState("");

  const handleOpen1 = (scrollType) => {
    setOpen2(true);
    setScroll(scrollType);
  };


  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);



  // ======================================= Api company list =================================================

  function getCompanyList() {

    const params = {
      user_id: loginAtom1,
      token: loginToken1,
    };

  

    axios
      .get(`${api_url1}/getCompanyList`, { params: { ...params } })
      .then((responce) => {
       
        setGetlist(responce.data.data);
      })
      .catch((err) => {
     
      });
  }

  useEffect(() => {
    getCompanyList();
    setCurrencyId(currency[0].day);
    setDayId(day[0].day);
  }, []);


  // =============================   ADD COMPANY ==============================
  const [value, setValue] = useState("");
 

  // =============================   UPDATE COMPANY ==============================

 
  //============================================== Changing Form Values =============================================
  

  // ------------------------------------------------- Drawer ------------------------------------------------------


  const closedrawer = () => {
    setCompanyDrawer(false);
  };

 


  const [errors, setErrors] = useState({});

  const [isValidPhone, setIsValidPhone] = useState(true);

  const handleChange2 = (value, country, event) => {
    setValue(value);
  };

  const validatePhone = () => {

    const phonePattern = /^(\+\d{1,3})?\d{12}$/;
    return phonePattern.test(value);
  };

  const handleBlur = () => {
    const isValid = validatePhone();
    setIsValidPhone(isValid);
  };

  const handleChange = (e) => {
    
    const { name, value } = e.target;
   
    setCompanyForm((state) => ({ ...state, [name]: value }));

  
    const newErrors = { ...errors };

    switch (name) {
      case "company":
        newErrors.company = validator.isEmpty(value)
          ? "Please enter your Company Name."
          : "";
        break;
      case "email":
        newErrors.email = validator.isEmpty(value)
          ? "Please enter your email Address."
          : !validator.isEmail(value)
          ? "Please enter a valid email address."
          : "";
        break;

      // case "password":
      //   newErrors.password = validator.isEmpty(value)
      //     ? "Please enter your password."
      //     : "";
      //   break;
      // case "address":
      //   newErrors.address = validator.isEmpty(value)
      //     ? "Please enter your address."
      //     : "";
      //   break;
      // case "city":
      //   newErrors.city = validator.isEmpty(value)
      //     ? "Please enter your city."
      //     : "";
      //   break;
      // case "pincode":
      //   newErrors.pincode = validator.isEmpty(value)
      //     ? "Please enter your pincode."
      //     : "";
      //   break;
      // case "state":
      //   newErrors.state = validator.isEmpty(value)
      //     ? "Please enter your state."
      //     : "";
      //   break;
      // case "country":
      //   newErrors.country = validator.isEmpty(value)
      //     ? "Please enter your country."
      //     : "";
      //   break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if phone number is valid before submitting the form

    if (validate()) {
      const formdata = new FormData();
      formdata.append("token", loginToken1);
   
      formdata.append("user_id", loginAtom1);
    
      formdata.append("company_name", companyform.company);
      formdata.append("email", companyform.email);
      formdata.append("phone_number", value);
      formdata.append("contact_person", companyform.contact_person);
      formdata.append("gst_no", companyform.gst_no);
      formdata.append("country", companyform.country);
      formdata.append("state", companyform.state);
      formdata.append("city", companyform.city);
      formdata.append("zip_code", companyform.zip_code);
      formdata.append("currency", currencyId);
      formdata.append("weekstart", dayId);
      formdata.append("timezone", value1);
     
      formdata.append("street1", companyform.street1);
      formdata.append("street2", companyform.street2);
      formdata.append("company_logo", avatar);



      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      axios({
        method: "post",
        url: `${api_url}/addCompany`,
        data: formdata,
        config,
      })
        .then(function (response) {
      
          closedrawer();
          setOpen(false);

        
          setSnackbaropen1({
            open: true,
            message: "Company Added successfully",
            severity: "success",
            actionColor: "#edf7ed",
          });
         
          setTrigerCompany(!trigerCompany);
          getCompanyList();
          setTrigerCompany(trigerCompany);
        })
        .catch(function (error) {
    
          if (error.response) {
           
          } else if (error.request) {
            
          } else {
          
          }
       
        });
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    const isValid1 = validatePhone();
    setIsValidPhone(isValid1);

    if (isValid1) {
   
    } else {

    }

    if (validator.isEmpty(companyform.company)) {
      isValid = false;
      newErrors.company = "Please enter your Company name.";
    }

    if (validator.isEmpty(companyform.email)) {
      isValid = false;
      newErrors.email = "Please enter your email Address.";
    } else if (!validator.isEmail(companyform.email)) {
      isValid = false;
      newErrors.email = "Please enter a valid email address.";
    }
    // else if(){
    // }

    // if (validator.isEmpty(input.phone_number)) {
    //   isValid = false;
    //   newErrors.phone_number = "Please enter your phone number.";
    // } else if (
    //   !validator.isNumeric(input.phone_number) ||
    //   input.phone_number.length !== 10
    // ) {
    //   isValid = false;
    //   newErrors.phone_number = "Please enter a valid 10-digit phone number.";
    // }

    setErrors(newErrors);
    return isValid;
  };

  const handleCurrencyChange = (event) => {
    setCurrencyId(event.target.value);
 
  };

  const handleDayChange = (event) => {
    setDayId(event.target.value);
  
  };

  return (
    <div className="companylist">
      <Snackbar
        open={snackbaropen.open}
        onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
        autoHideDuration={6000}
      >
        <Alert
          severity={snackbaropen.severity}
          style={{ backgroundColor: `${snackbaropen.actionColor}` }}
        >
          {snackbaropen.message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={snackbaropen1.open}
        onClose={() => setSnackbaropen1((state) => ({ ...state, open: false }))}
        autoHideDuration={6000}
      >
        <Alert
          severity={snackbaropen1.severity}
          style={{ backgroundColor: `${snackbaropen1.actionColor}` }}
        >
          {snackbaropen1.message}
        </Alert>
      </Snackbar>
      {/* ==================================================Add Company Drawer=================================================== */}

      {/* ======================================================== Company Table ======================================================= */}

      <Modal
        open={open123}
        scroll={scroll}
        onClose={handleClose123}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="addcompanytitle">
            ADD COMPANY
            <IconButton
              component="span"
              onClick={handleClose123}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Stack
            spacing={2}
            padding={2}
            width="100%"
            alignItems="center"
            height="100%"
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <label htmlFor="avatar-upload" className="circle-image-input">
                    {avatar ? (
                      <img
                        src={URL.createObjectURL(avatar)}
                        alt="Avatar"
                        className="avatar-image"
                      />
                    ) : (
                      //<span className="upload-text"><AccountCircleOutlinedIcon style={{fontSize : 100}}/></span>
                      <img
                        src={userimg}
                        width={120}
                        height={120}
                        alt="profile pic"
                      />
                    )}
                    <input
                      type="file"
                      id="avatar-upload"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="file-input"
                    />
                  </label>
                </div>
                {avatar ? (
                  <Typography
                    style={{
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "green",
                    }}
                  >
                    Logo Selected
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    Select Your Company Logo
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                alignItems="center"
                justifyContent=" center"
              >
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  name="company"
                  label={"Company"}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.company ? (
                  <div
                    className="text-danger"
                    style={{
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <ErrorOutlineIcon
                      style={{ marginRight: "5px", fontSize: "15px" }}
                    />
                    {errors.company}
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"Contact Person"}
                  //onChange={(e) => formonchange("contact_person", e)}
                  name="contact_person"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"Email"}
                  name="email"
                  onChange={handleChange}
                  fullWidth
                  required
                />
                {errors.email ? (
                  <div
                    className="text-danger"
                    style={{
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <ErrorOutlineIcon
                      style={{ marginRight: "5px", fontSize: "15px" }}
                    />
                    {errors.email}
                  </div>
                ) : (
                  ""
                )}{" "}
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <PhoneInput
                  style={{ width: "100%" }}
                  enableAreaCodes={true}
                  placeholder="Enter phone number"
                  value={value}
                  country={"in"}
                  name="phone_number"
                  required
                  onChange={handleChange2}
                  //onChange={setValue}
                  onBlur={handleBlur}
                  // inputStyle={isValidPhone ? {} : { border: '2px solid red' }}
                />{" "}
                {!isValidPhone && (
                  <div
                    className="text-danger"
                    style={{
                      marginTop: "10px",
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    <ErrorOutlineIcon
                      style={{ marginRight: "5px", fontSize: "15px" }}
                    />
                    Please enter a valid phone number.
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"GST NO"}
                  //onChange={(e) => formonchange("gst_no", e)}
                  name="gst_no"
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  // sx={{ m: 1 }}
                  variant="standard"
                  style={{ width: "100%", marginLeft: "0" }}
                  size="small"
                >
                  <NativeSelect
                    id="demo-customized-select-native"
                    defaultValue={currency[0].day}
                    onChange={handleCurrencyChange}
                    input={<BootstrapInput />}
                  >
                    {currency.map((item) => {
                      return (
                        <>
                          <option value={item.day}>{item.day}(Currency)</option>
                        </>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl sx={{ width: "100%" }}>
                  <select
                    defaultValue={"IN"}
                    onChange={timezoneChange}
                    className="pop-dropdown"
                  >
                    {timezones.map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  // sx={{ m: 1 }}
                  variant="standard"
                  style={{ width: "100%", marginLeft: "0" }}
                  size="small"
                >
                  {/* <InputLabel id="demo-simple-select-disabled-label">Age</InputLabel> */}
                  <NativeSelect
                    id="demo-customized-select-native"
                    defaultValue={day[0].day}
                    onChange={handleDayChange}
                    input={<BootstrapInput />}
                  >
                    {day.map((item) => {
                      return (
                        <>
                          <option value={item.day}>{item.day}</option>
                        </>
                      );
                    })}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"Street1"}
                  name="street1"
                  onChange={handleChange}
                  //onChange={(e) => formonchange("street1", e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"Street2"}
                  name="street2"
                  onChange={handleChange}
                  //onChange={(e) => formonchange("street2", e)}
                  fullWidth
                  //required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"City"}
                  name="city"
                  onChange={handleChange}
                  //onChange={(e) => formonchange("city", e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"State"}
                  name="state"
                  onChange={handleChange}
                  //onChange={(e) => formonchange("state", e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"Country"}
                  name="country"
                  onChange={handleChange}
                  //onChange={(e) => formonchange("country", e)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  id="standard-basic"
                  variant="outlined"
                  label={"Pin Code"}
                  name="zip_code"
                  onChange={handleChange}
                  //onChange={(e) => formonchange("zip_code", e)}
                  fullWidth
                />
              </Grid>
            </Grid>
            <div className="addcompanybtn">
              <Stack direction={"row"} spacing={2}>
                <Button
                  variant="outlined"
                  className="tablebtncancel"
                  style={{ color: "#000" }}
                  onClick={handleClose123}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="tablebutton"
                  onClick={handleSubmit}
                >
                  Add Company
                </Button>
              </Stack>
            </div>
          </Stack>
        </Box>
      </Modal>

      {/* <Table /> */}
    </div>
  );
};

export default CompanyListTable;
