import React from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import VpanelContent from "./VpanelContent";
import VisitorInfo from "./VisitorInfo";

const VisitorDetails = () => {
 
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <PanelGroup direction="horizontal">
        <Panel defaultSize={32} minSize={32}>
          <VisitorInfo />
        </Panel>
        <PanelResizeHandle
          style={{
            backgroundColor: "#ddd",
            width: "1px",
            cursor: "col-resize",
          }}
        />
        <Panel defaultSize={68} minSize={68}>
          <VpanelContent />
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default VisitorDetails;
