import React, { useEffect, useState } from "react";
import { Drawer, Avatar, Button } from "@mui/material";
import "../ContactDrawer/ContactDrawer.css";
// import styled from "styled-components";
import { Box, Divider, Grid, Typography } from "@mui/material";

import ReactStars from "react-rating-stars-component";

import {
  crmDashCollapse,

  profile_trigger,
  selected_contact,
} from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import { styled, alpha } from "@mui/material/styles";
import ContactSideBar from "./ContactSideBar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ContactMainDetails from "./ContactMainDetails";
import ContactSocialDetails from "./ContactSocialDetails";
import ContactCompanyDetails from "./ContactCompanyDetails";
import ContactNotes from "./ContactNotes";
import ContactTabs from "./ContactTabs";
import ContactFollowTable from "./ContactFollowTable";
import ContactDetailsBody from "./ContactDetailsBody";
import ContactFollowUpsTab from "./ContactFollowUpsTab";
import ContactNotesTab from "./ContactNotesTab";
import TestCampaign from "./TestCampaign";
import ContactAddFile from "./ContactAddFile";
import ContactEmail from "./ContactEmail";
import axios from "axios";
import { api_url } from "../../globalSettings";
import ContactLogs from "./ContactLogs";
import { NavLink } from "react-router-dom/dist";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const firstExample = {
  size: 20,
  value: 3,
  edit: false,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    display: "flex",
    flexDirection: "column",
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 12,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

// const Box = styled.div`
//   background-color: white;
//   padding: 10px;
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
// `;

// const Box1 = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items : center;
//   font-size : 12px
// `;

const ContactDetails = () => {
  const [selectedContact] = useRecoilState(selected_contact);
  const [collapsed, setCollapsed] = useRecoilState(crmDashCollapse);
  const [profileTrigger, setProfileTrigger] = useRecoilState(profile_trigger);
  const [contactTypeId, setContactTypeId] = useState(null);
  const [selectedContactDetails, setSelectedContactDetails] =
    useState(selectedContact);
  // ============================= CONVERT ============================

  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleChange1 = (id) => {
    setContactTypeId(id);
    handleClose1();
  };


 
  

  const ratingChanged = (newRating) => {
  };

  // ============================ GET CONTACT TYPE =========================

  const [contactList, setContactList] = React.useState("");

  function getcontactlist() {
    axios
      .get(
        `${api_url}/crm_setting/getContactTypes/${selectedContact.company_id}`
      ) 
      .then((responce) => {
       
        setContactList(responce.data.data[0].contact_type);
      })
      .catch(function (error) {
        if (error.response) {
         
        } else if (error.request) {
       
        } else {
      
        }
      });
  }

  useEffect(() => {
    setCollapsed(true);
    getcontactlist();
  }, []);

  // =====================   UPDATE CUSTOMER DETAILS ====================

  useEffect(() => {
    if (contactTypeId !== null) {
      let c = contactList.find((obj) => obj._id === contactTypeId);

      const params = {
        // projectId: selectedProject._id,
        // name: updateName,
        // start_date: validFrom,
        // end_date: validTo,
        // estimation_hours: updateNo,
        // notes: updateNotes,
        customer_id: selectedContact._id,
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        contact_type: {
          name: c.name,
          color: c.color,
          id: c._id,
        },
        country: "",
        priority: "",
      };
      axios
        .patch(`${api_url}/crm_customer/updateCustomerDetails`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
          getcontactlist();
          setProfileTrigger(!profileTrigger);
        })
        .catch(function (error) {
          if (error.response) {
           
          } else if (error.request) {
           
          } else {
          
          }
          
        });
    }
  }, [contactTypeId]);

  useEffect(() => {
    axios
      .get(
        `${api_url}/crm_customer/getCustomerByCustomerId/${selectedContact._id}`
      )
      .then((responce) => {
       
        //setCustomerList(responce.data.data);
        setSelectedContactDetails(responce.data.data[0]);
      })
      .catch((err) => {
     
      });
  }, [selectedContact, profileTrigger]);

  return (
    <div id="contactdrawer">
  =
      <Box style={{ backgroundColor: "white" }}>
        <Grid container borderBottom={"1px solid #0000002b"}>
          <Grid item xs={6} sm={6} md={6} padding={1} display={"flex"} alignItems={"center"} gap={3}>
            <NavLink to="/contacts/contact">
              <ArrowBackIcon />
            </NavLink>
            <div>
            <Typography variant="h5" style={{ marginRight: 20 }}>
              #
              Kavin Kumar
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <ReactStars
                count={4}
                onChange={ratingChanged}
                size={24}
                activeColor="#ffd700"
              />
              <span
                style={{
                  padding: "1px 3px",
                  backgroundColor:
                    selectedContactDetails.priority == "low"
                      ? "rgb(188, 59, 50)"
                      : "",
                  borderRadius: "5px",
                  color: "white",
                }}
              >
                {selectedContactDetails.priority}
              </span>
              <p style={{ margin: 0 }}>
                Assigned Admin:
                Kavin Kumar
              </p>
            </div>
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            padding={1}
            display={"flex"}
            alignItems={"flex-end"}
            justifyContent={"flex-start"}
            flexDirection={"column"}
          >
            <div>
              <Button
                id="demo-customized-button"
                aria-haspopup="true"
                
                style={{
                  margin: 5,
                  fontSize: 10,
                  backgroundColor: "#199891",
                  color: "white",
                  fontWeight: "400",
                }}
                variant="contained"
                disableElevation
                onClick={(e) => {
                  handleOpen1(e);
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Convert
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl1}
                open={Boolean(anchorEl1)}
                onClose={handleClose1}
              >
                {contactList && Object.keys(selectedContactDetails).length >= 1
                  ? contactList.map((item) => {
                      return selectedContactDetails.ContactType._id ==
                        item._id ? (
                        ""
                      ) : (
                        <MenuItem
                          key={item._id}
                          onClick={() => {
                            handleChange1(item._id);
                          }}
                          style={{
                            color: item.color,
                            paddingLeft: "5px",
                            fontSize: 12,
                            width: "100%",
                            textAlign: "left",
                            justifyContent: "flex-start",
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      );
                      //</div>
                    })
                  : ""}
              </StyledMenu>
           
              <Button
                id="demo-customized-button"
                aria-haspopup="true"
                style={{
                  margin: 5,
                  fontSize: 10,
                  backgroundColor: "#bc3b32",
                  color: "white",
                  fontWeight: "400",
                }}
                variant="contained"
                disableElevation
                endIcon={<DeleteOutlineIcon />}
              >
                Delete
              </Button>
            </div>
            <div>Last Update : </div>
          </Grid>
        </Grid>

        <ContactDetailsBody selectedContactDetails={selectedContactDetails} />
      </Box>


      <style>
        {`.tabs {
            list-style: none;
            padding: 0;
            display: flex;
          }
          
          .tabs li {
            cursor: pointer;
            padding: 10px 20px;
            margin-right: 10px;
            font-size:12px
          }
          
          .tabs li.active {
            background-color: #ffffff;
            font-weight: bold;
            border-bottom: 3px solid #5a7cc3;
          }
          
          .tab-content {
            margin-top: 10px;
            display : flex;
            justify-content : center
          }`}
      </style>
      <Divider />
    </div>
  );
};

export default ContactDetails;
