import { fontSize, style } from '@material-ui/system';
import { Divider } from '@mui/material';
import React from 'react'
// import { Box, Typography } from '@mui/material'
import styled from "styled-components";

const Box = styled.div`
  background-color: white;
  margin-bottom:10px;
  padding: 10px;
  display: flex;
  width: 100%;
  flex-direction : column
`;

const Typography = styled.div`
color:#5a7cc3;
font: 12px;
`;

const TableContainer = styled.div`
width:100%;
border: 1px solid #00000021;
border-bottom: none;

`;

const TableColumn = styled.div`
border-bottom: 1px solid #00000021;
display:flex;
flex-direction:row;
font-size:12px;
`;

const TableRow = styled.div`
width:40%;
padding:5px;
text-align:right;
border-right:1px solid #00000021;
`;

const TableRow1 = styled.div`
width:60%;
padding:5px
`;

const ContactCompanyDetails = () => {
    return (
        <Box ><div style={{display:"flex" , flexDirection : "row" , alignItems :"center",marginBottom:"5px"}}>
        <Typography >COMPANY</Typography>
        <Divider />
        </div>
        <TableContainer>
    <TableColumn>
    <TableRow>
       Company Name
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Webpage
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Campaign
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Industry 
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
    <TableColumn>
    <TableRow>
        Phone
    </TableRow>
    <TableRow1>
    kavin
    </TableRow1>
    </TableColumn>
        </TableContainer>
       
        </Box>
      )
    }

export default ContactCompanyDetails