import * as React from 'react';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import CreatePipeline from '../create/CreatePipeline';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import DeleteSubPipeline from '../delete/DeleteSubPipeline';
import { makeStyles } from '@mui/styles';
import { Avatar, Divider, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import CreateSubPipeline from '../create/CreateSubpipeline';
import { cloneSubpipelineFormData, editStagesData } from '../../../utils/createpipelineformdata';
import EditPipeline from '../create/EditPipeline';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import variables from "../../../assets/styles/_colors.scss";

const useStyles = makeStyles((theme) => ({
    subPipeline: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

const subPipelineActions = [
    {
        name: "Manage Stages",
        icon: <BarChartOutlinedIcon />,
        key: 'editStages'
    },
    {
        name: "Convert To Team Pipeline",
        icon: <ChangeCircleOutlinedIcon />,
        key: 'convertToPipeline'
    },
    {
        name: "Clone Sub-Pipeline",
        icon: <FileCopyOutlinedIcon />,
        key: 'cloneSubPipeline'
    },
    {
        name: "Delete Sub-Pipeline",
        icon: <DeleteOutlinedIcon />,
        key: "delete"
    }
]
export default function SubPipelinePopover(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openCreatePipelineStepper, setOpenCreatePipelineStepper] = useState(false);
    const [openCreateSubPipeline, setOpenCreateSubPipeline] = useState(false);
    const [openDeleteSubPipeline, setOpenDeleteSubPipeline] = useState(false);
    const [openManageStages, setOpenManageStages] = useState(false);
    const [editData, setEditData] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "convertToPipeline":
                    setOpenCreatePipelineStepper(true);
                    return true;
                case "cloneSubPipeline":
                    setOpenCreateSubPipeline(true);
                    return true;
                case "editStages":
                    setOpenManageStages(true);
                    setEditData({
                        header: "Manage Stages",
                        defaultValue: editStagesData,
                        moduleIndex: 1
                    });
                    return true;
                case "delete":
                    setOpenDeleteSubPipeline(true);
                    return true;
                default:
                    return <></>
            }
        }

    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const classes = useStyles();
    const open = Boolean(anchorEl);
    const id = open ? 'sub-pipeline-popover' : undefined;

    return (
        <>
            <IconButton aria-label="sub-pipeline-popover" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.subPipeline }}
            >
                {subPipelineActions.map((item, index) => (
                    <div key={`sub-pipeline-popover-item-${index}`}>
                        <ListItemButton
                            sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemAvatar sx={{ minWidth: 46 }}>
                                <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                                    {item.icon}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </div>
                ))}
            </Popover>
            {openCreatePipelineStepper && <CreatePipeline isNewPipeline={false} handleDrawerClose={() => setOpenCreatePipelineStepper(false)} />}
            {openCreateSubPipeline && <CreateSubPipeline cloneData={cloneSubpipelineFormData} handleDrawerClose={() => setOpenCreateSubPipeline(false)} />}
            {openDeleteSubPipeline && <DeleteSubPipeline item={props.selectedItem} handleClose={() => setOpenDeleteSubPipeline(false)} />}
            {openManageStages && <EditPipeline data={editData} handleDrawerClose={() => setOpenManageStages(false)} />}
        </>
    );
}