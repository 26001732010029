import React, { useEffect } from "react";

import dashboard from "./img/Dashboard Layout.png";
import service from "./img/Service.png";
import sellsy from "./img/Sellsy.png";
import chat from "./img/Chat.png";
import cloudMail from "./img/Cloud Mail.png";
import Tracking from "./img/Tracking.png";
import Nametag from "./img/Name Tag.png";
import NDEMail from "./img/NDEMail.png";
import { NavLink } from "react-router-dom";
import "./Style/MainSideBar.css";

const MainSideBar = () => {
  useEffect(() => {
    document.body.classList.add("shrink");
  }, []);

  return (
    <div >
      <nav className="nav1">
        <div className="sidebar-links">
          <ul>
            <li className="tooltip-element liItems-space" data-tooltip="0">
              <NavLink to="/dashboard" activeClassName="active-link">
                <div className="icon">
                  <img src={dashboard} alt="dashboard" />
                </div>
                <span className="truncate-text">Dashboard</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space" data-tooltip="1">
              <NavLink
                to="/webservices"
                className="tooltip-element"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img
                    src={service}
                    alt="web services"
                    style={{ paddingLeft: 5 }}
                  />
                </div>
                {/* <span className="link hide">Web Services</span> */}
                <span className="truncate-text">Web Services</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space" data-tooltip="2">
              <NavLink
                className="tooltip-element"
                to="/markettingstudio"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={sellsy} alt="web services" />
                </div>
                <span>Marketing</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space" data-tooltip="2">
              <NavLink
                className="tooltip-element"
                to="/mail"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={NDEMail} alt="web services" />
                </div>
                <span>NDE Mail</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/chat"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={chat} alt="web services" />
                </div>
                <span>Chat Now</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/contacts"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={cloudMail} alt="web services" />
                </div>
                <span className="truncate-text">Vission Now</span>
              </NavLink>
            </li>
            {/* <li className="tooltip-element" >
              <NavLink className="tooltip-element" to="/123" activeClassName="active-link" >
                <div className="icon">
                <img src={influence} alt='web services' />
                </div>
                <span className="shrink-btn">Influencer</span>
              </NavLink>
            </li> */}
            <li className="tooltip-element liItems-space" data-tooltip="3">
              <NavLink
                className="tooltip-element"
                to="/trackeye"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img
                    src={Tracking}
                    alt="web services"
                    style={{ paddingLeft: 7 }}
                  />
                </div>
                <span>Spot Now</span>
              </NavLink>
            </li>
            <li className="tooltip-element liItems-space">
              <NavLink
                className="tooltip-element"
                to="/peoplesnow"
                // to="/settings/manageemployee"
                activeClassName="active-link"
              >
                <div className="icon">
                  <img src={Nametag} alt="web services" />
                </div>
                <span>Peoples Now</span>
              </NavLink>
            </li>

            {/* More list items go here */}
          </ul>

          <h4 className="hide">Shortcuts</h4>
          {/* <span className="shrink-btn" onClick={handleShrinkClick}>
          kavi
          </span> */}
          <ul>{/* Your existing shortcut links go here */}</ul>
        </div>

        {/* <div className="tooltip">
        {tooltips.map((tooltip, index) => (
          <span key={index} className={index === activeIndex ? 'show' : ''}>
            {tooltip}
          </span>
        ))}
        </div> */}
      </nav>

      {/* <main>
        <h1>My Dashboard</h1>
        <p className="text">
        </p>
        <p className="copyright">
          &copy; 2021 - <span>Aqumex</span> All Rights Reserved.
        </p>
      </main> */}
    </div>
  );
};

export default MainSideBar;
