import React, { useState } from "react";
import { Modal, Box, IconButton, Grid, Stack } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import { useRecoilState } from "recoil";
import { selecteddomainn } from "../../../../../webservicesatoms/webserviceatom.js";
import domainImg from "../../../../../images/DomainDetails/googleRenewal.png";
import { gstCalculation } from "../../../Gsuit/HelperFunctions/helperFunction.js";
import { PriceFormat } from "../../../CustomHook/DateFormat.js";
import RenewalConfirm from "../../../Component/Renewal/Componets/RenewalConfirm.js";
import RenewalStyle from "../../../Gsuit/Style/Renewal.module.css";
import RenewalDropDown from "../../../Component/Renewal/Componets/RenewalDropDown.js";
import PriceTable from "../../../Component/Renewal/Componets/PriceTable.js";
import RenewalButton from "../../../Component/Renewal/Componets/RenewalButton.js";
import RenewalPayment from "../../../Component/Renewal/Componets/ProcessRenewal.js";
import { api_url1 } from "../../../../../globalSettings.js";
import axios from "axios";
import IsWallet from "../../../CustomHook/IsWallet.js";
import { displayRazorpay } from "../../../CustomHook/RazorPay/RazorPay.js";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
  borderRadius: "12px",
};

const HostingRenewal = ({
  open,
  handleClose,
  setSnackbarInfo,
  renewalData,
  PriceData,
  setPriceData,
  gst,
  setGst,
  planValue,
  setplanValue,
  id,
  productId,
}) => {
  const [Selecteddomain, setSelecteddomain] = useRecoilState(selecteddomainn);
  const [scroll, setScroll] = React.useState("paper");
  const handleInputPlanValue = (event) => {
   
    setplanValue(event.target.value);
    let key = event.target.value;
    let price = renewalData.priceDetails.allprices[key];
    setPriceData(price);
    setGst(gstCalculation(price, renewalData.userData));
  };
  const proceedStyle = {
    background: "#28bd8b",
    border: "none",
    borderRadius: "2px",
    color: "#fff",
    cursor: "pointer",
    display: "inline-block",
    fontFamily: "OpenSans",
    fontSize: "12px",
    fontWeight: 600,
    outline: "none",
    position: "relative",
    textTransform: "uppercase",
  };

  const [processModal, setProcessModal] = useState(false);
  function handleCloseModal() {
    setProcessModal(false);
  }

  const[loading,setLoading]=useState(false)
  function HostingRenewal() {
    setLoading(true);
    let obj = {
      id: id,
      period: planValue,
      isWallet: IsWallet(renewalData?.walletBalance),
    };
    axios
      .post(`${api_url1}/payment/plesk/renewal`, obj)
      .then((result) => {
        if (!result.data.isRazorpay) {
          setSnackbarInfo({
            open: true,
            severity: "success",
            message:
              "Payment Successfully Made Via Wallet,It Will Take Few Minutes To Update",
          });
        } else {
          const handlerFunction = function (response) {
            setSnackbarInfo({
              open: true,
              severity: "success",
              message: "Payment Successful,It Will Take Few Minutes To Update!",
            });
          };
          let options = {
            ...result.data.options,
            handler: handlerFunction,
          };
          displayRazorpay(options, setSnackbarInfo);
        }
        handleCloseModal();
        handleClose();
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
      <Modal
        open={open}
        scroll={scroll}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll">
          <div className="_addcompanytitle_">
            Hosting Renewal
            <IconButton
              component="span"
              onClick={handleClose}
              style={{ padding: 0, marginRight: 20 }}
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </div>
          <Stack
            spacing={2}
            padding={2}
            width="100%"
            alignItems="center"
            height="100%"
          >
            <RenewalConfirm
              confirmationText={
                "Are You Sure You Want To Confirm The Renewal For"
              }
              domainName={Selecteddomain.domainName}
              isDelete={false}
            />
            <div className={RenewalStyle.middleWrapper} style={{justifyContent:"space-between"}}>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.img_container}>
                  <img src={domainImg} alt="img of domain" />
                </div>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>
                    {Selecteddomain?.domainName}
                  </div>
                  <div className={RenewalStyle.link}>Hosting</div>
                </div>
              </div>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>Duration</div>
                  <RenewalDropDown
                    value={planValue}
                    handleChange={handleInputPlanValue}
                    valueParam={"year"}
                    keyParam={"year"}
                    items={Object.entries(
                      renewalData?.priceDetails?.allprices || {}
                    )}
                    isHosting={true}
                    displayParam={"year"}
                  />
                </div>
              </div>
              <div className={RenewalStyle.flex_}>
                <div className={RenewalStyle.flex_container}>
                  <div className={RenewalStyle.googleFont}>Renewal Cost</div>
                  <div className={RenewalStyle.accFontSize}>
                    {renewalData.userData?.currencyCode} {"  "}
                    {`${PriceFormat(PriceData)}`}/
                    {planValue === "oneTimeMonthly" || planValue === "quarterly"
                      ? "mo"
                      : "Yr"}
                  </div>
                </div>
              </div>
            </div>
            <div className={RenewalStyle.price_wrapper}>
              <PriceTable
                currencyCode={renewalData?.userData?.currencyCode}
                priceData={PriceData}
                gst={gst?.gst}
                tax={gst?.fullAmount}
              />
            </div>
          </Stack>
          <RenewalButton
            loading={false}
            title={"Proceed"}
            disabled={false}
            clickFunc={() => {
              handleClose();
              setProcessModal(true);
            }}
          />
        </Box>
      </Modal>
      <RenewalPayment
        handleCloseModal={handleCloseModal}
        open={processModal}
        fullAmount={gst?.fullAmount}
        currencyCode={renewalData?.userData?.currencyCode}
        walletBalance={renewalData?.walletBalance}
        loading={loading}
        proceedFunc={HostingRenewal}
      />
     
    </>
  );
};

export default HostingRenewal;
