import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import Organization from "./Organization/Organization";
import Roles from "./Roles/Roles";
import WebForms from "./WebForms/WebForms";
import Schedules from "./Schedules/Schedules";
import LiveChat from "./LiveChat/LiveChat";
import orgSettingsItems from "./OrgSettingsItems";
import mailSettingsItems from "./MailSettingsItems";
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

function Dashboard({ handleClose }) {
  const [selectedOption, setSelectedOption] = React.useState("option1");
  const [selectedOptions, setSelectedOptions] = React.useState("1");

  const [selectedOrganization, setSelectedOrganization] = useState(
    orgSettingsItems[0]
  );
  const handleItemClick = (item) => {
    setSelectedOrganization(item);
    console.log(selectedOrganization);
  };
  useEffect(() => {
    console.log(selectedOrganization);
  }, [selectedOrganization]);

  const renderComponent = () => {
    const handleCompanyChange = (event) => {
      setSelectedOptions(event.target.value);
    };
    switch (selectedOption) {
      case "option1":
        return (
          <div
            style={{
              height: "89.5%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,0.1)",
                height: "90%",
              }}
            >
              {orgSettingsItems.map((item, index) => (
                <div
                  key={index}
                  className="dashboard-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 0 10px 10px",
                    gap: "5%",
                    fontSize: "14px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedOrganization &&
                      selectedOrganization.labelText === item.labelText
                        ? "rgba(0,0,0,0.1)"
                        : "transparent",
                  }}
                  onClick={() => handleItemClick(item)}
                >
                  <Box>{item.icon}</Box>
            
                  <Typography style={{ fontSize: "14px" }}>
                    {item.labelText}
                  </Typography>
                </div>
              ))}
            </div>
           
            <div
              style={{
                flex: "1",
              }}
            >
              <TextField
                select
                value={selectedOptions}
                onChange={handleCompanyChange}
                variant="outlined"
                fullWidth
                sx={{
                  width: "100%",
                  fontSize: "calc(0.4vw + 10px)",

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover": {
                    borderColor: "transparent",
                  },
                  ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input ":
                    {
                      padding: "8px",
                    },
                }}
              >
                <MenuItem value="1">
                  <div
                    style={{
                      display: "flex",
                     }}
                  >
                   <BusinessOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 1, marginRight: "10px",marginLeft: "10px", }}/>
                    <Typography >NDE</Typography>
                  </div>
                </MenuItem>
                <MenuItem value="2">
                  <div style={{display:"flex",}}>
                  <BusinessOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 1 , marginRight: "10px",marginLeft: "10px",}}/>
                  <Typography >Digital Easy</Typography>
                  </div>
                </MenuItem>
                <MenuItem value="3">
                  <div style={{display:"flex",}}>
                  <BusinessOutlinedIcon sx={{ stroke: "#ffffff", strokeWidth: 1 , marginRight: "10px",marginLeft: "10px",}}/>
                  <Typography >Iaaxin</Typography>
                  </div>
                </MenuItem>
              </TextField>
            </div>
          </div>
        );
      case "option2":
        return (
          <div style={{ height: "89.5%" }}>
            {mailSettingsItems.map((item, index) => (
              <div
                key={index}
                className="dashboard-item"
                style={itemStyle}
                onClick={() => handleItemClick(item)}
              >
               {item.icon}
                <Typography style={{ fontSize: "14px" }}>
                  {item.labelText}
                </Typography>
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };
  const renderOrganizationComponent = () => {
    switch (selectedOrganization?.labelText) {
      case "Organization":
        return <Organization handleClose={handleClose} />;
      case "Roles and Permissions":
        return <Roles handleClose={handleClose} />;
      case "Web Forms":
        return <WebForms />;
      case "Schedules":
        return <Schedules />;
      case "Live Chat":
        return <LiveChat handleClose={handleClose} />;
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === "option1") {
      setSelectedOrganization(orgSettingsItems[0]);
    } else {
      setSelectedOrganization(null);
    }
  };

  const itemStyle = {
    display: "flex",
    alignItems: "center",
    padding: "6px 0 6px 10px",
    gap: "5%",
    fontSize: "14px",
  };

  return (
    <div style={{ height: "100%" }}>
      <Grid
        container
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flex: "0",
        }}
      >
        <Grid
          item
          style={{
            borderRight: "1px solid rgba(0,0,0,0.1)",
            height: "100%",
            width: "200px",
          }}
        >
          <div
            style={{ borderBottom: "1px solid rgba(0,0,0,0.1)", height: "10%" }}
          >
            <TextField
              select
              value={selectedOption}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              sx={{
                width: "100%",

                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                  {
                    padding: "10px",
                  },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover": {
                  borderColor: "transparent",
                },
                padding: "5px",
              }}
            >
              <MenuItem value="option1">Organization Settings</MenuItem>
              <MenuItem value="option2">Mail Settings</MenuItem>
            </TextField>
          </div>

          {renderComponent()}
        </Grid>
        <Grid item xs={12} style={{ flex: "1", overflowY: "auto" }}>
          <div style={{ height: "99.5%" }}>
            {selectedOrganization && renderOrganizationComponent()}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Dashboard;
