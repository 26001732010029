import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/SecondarySiderBar.css";
import tacometer from "../../components/dashboard/img/tachometer.png";
import customer from "../../components/dashboard/img/customer.png";
import { IconButton } from "@mui/material";
import rightarrow from '../../components/dashboard/img/rightarrow.png'
import leftarrow from '../../components/dashboard/img/leftarrow.png'



function Sidebar() {
  const location = useLocation();
  const history = useNavigate();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const isActive = (path) => location.pathname === path;

  const handleClick = (path) => {
    if (!isActive(path)) {
      history(path);
    }
  };

  const myAccOnClick = () => {
    //dispatch(setCurrentPage("ATTENDANCE"));
    history("/trackeye/attendence");
    // props.toggleDrawer(true);
  };

  const myWorkHours = () => {
    // dispatch(setCurrentPage("ATTENDANCE"));
    history("/trackeye/workhours");
    // props.toggleDrawer(true);
  };

  const empTask = () => {
    //dispatch(setCurrentPage("ATTENDANCE"));
    history("/trackeye/emptask");
    // props.toggleDrawer(true);
  };

  const empPerformance = () => {
    //dispatch(setCurrentPage("ATTENDANCE"));
    history("/trackeye/empPerformance");
    // props.toggleDrawer(true);
  };

  const empExpense = () => {
    //dispatch(setCurrentPage("ATTENDANCE"));
    history("/trackeye/empExpense");
    // props.toggleDrawer(true);
  };

  const leaveAproval = () => {
    // dispatch(setCurrentPage("LEAVE"));
    history("/trackeye/leaveapproval");
    // props.toggleDrawer(true);
  };

  //=========================== Drawer1 ========================

  const [openDrawer, setopenDrawer] = useState(false);

  const toggleDrawer = (event) => {
   
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setopenDrawer(openDrawer ? false : true);
  };

  // ==============================  end  ============================

  return (
 
      <div className={`secondarySidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
       <IconButton className='secondaryToggleButton' onClick={toggleSidebar}>
  {!isSidebarOpen ? <img src={rightarrow} width={8} height={8}/> : <img src={leftarrow} width={8} height={8}/> }
  </IconButton>
      <div className="webServicesTab">
        <ul>
          <li data-tooltip="0">
            <div
               className={`link ${isActive('/trackeye/TrackeyeDashboard') ? 'active' : ''}`}
               onClick={() => handleClick('/trackeye/TrackeyeDashboard')}
            
            >
              <div className="icon">
                <img
                  src={tacometer}
                  alt="dashboard"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>DashBoard</span>
            </div>
          </li>
          <li data-tooltip="1">
            <div
            className={`link ${isActive('/trackeye') ? 'active' : ''}`}
            onClick={() => handleClick('/trackeye')}
            
            >
              <div className="icon">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Tracking</span>
            </div>
          </li>
          <li data-tooltip="2">
            <div
            className={`link  ${isActive('/trackeye/leaveapproval') ? 'active' : ''}`}
            onClick={() => handleClick('/trackeye/leaveapproval')}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Leave</span>
            </div>
          </li>
          <li data-tooltip="2">
            <div
              onClick={handleToggleDropdown} 
              className={`link  report-dropdown-container ${showDropdown == true || isActive('/trackeye/attendence') ? 'active' : ''}`}
              activeClassName="active-link"
            >
              <div className="icon">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Reports</span>
            </div>
            {showDropdown && (
        <div className="report-dropdown">
          <ul>
            <li>
            <div
            className={`link  ${isActive('/trackeye/attendence') ? 'active' : ''}`}
            onClick={() => handleClick('/trackeye/attendence')}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Attendance</span>
            </div>
            </li>
            <li>
            <div
            className={`link  ${isActive('/trackeye/workhours') ? 'active' : ''}`}
            onClick={() => handleClick('/trackeye/workhours')}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Work Hours</span>
            </div>
            </li>
            <li>
            <div
            className={`link  ${isActive('/trackeye/emptask') ? 'active' : ''}`}
            onClick={() => handleClick('/trackeye/emptask')}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Task History</span>
            </div>
            </li>
            <li>
            <div
            className={`link  ${isActive('/trackeye/empPerformance') ? 'active' : ''}`}
            onClick={() => handleClick('/trackeye/empPerformance')}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Performance</span>
            </div>
            </li>
            <li>
            <div
            className={`link  ${isActive('/trackeye/empExpense') ? 'active' : ''}`}
            onClick={() => handleClick('/trackeye/empExpense')}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Expense</span>
            </div>
            </li>
          </ul>
        </div>
      )}
          </li>
        </ul>

        <h4 className="hide">Shortcuts</h4>
       
        <ul>{/* Your existing shortcut links go here */}</ul>
      </div>
      </div>

  );
}

export default Sidebar;

{
  /* <Stack spacing={2}>

      <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px",
            height: "auto",
            flexDirection :"column",
            alignItems: "center",
            cursor:"pointer"
          }}
          onClick={()=>history('/trackeye/TrackeyeDashboard')}
        >
<img width="50" height="50" src={dash1} alt="performance-macbook"/>   
<Typography style={{fontSize:"12px" , fontWeight:"700"}}>DashBoard</Typography>
     </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px",
            height: "auto",
            flexDirection :"column",
            alignItems: "center",
            cursor:"pointer"
          }}
          onClick={()=>history('/trackeye')}
        >
<img width="50" height="50" src={dash2} alt="track-order"/>
<Typography style={{fontSize:"12px" , fontWeight:"700"}}>Tracking</Typography>

   </div>
   <div style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px",
            height: "auto",
            flexDirection :"column",
            alignItems: "center",
            cursor:"pointer"
          }} onClick={leaveAproval}>
          <img width="50" height="50" src={dash4} alt="track-order"/>
          <Typography style={{fontSize:"12px" , fontWeight:"700"}}>Leave <br/> Aproval</Typography>
          </div>
        <div
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "10px 0px",
            height: "auto",
            flexDirection :"column",
            alignItems: "center",
            cursor:"pointer"

          }}
        >
<img width="50" height="50" src={dash3} alt="track-order"/>
<Typography style={{fontSize:"12px" , fontWeight:"700" ,textAlign:"center"}}>Reports</Typography>
         <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Button
              className="slidebar_btn"
              hover
              sx={{ p: 1 }}
              onClick={myAccOnClick}
            >
              Attendance
            </Button>
            <UserDrawer open={openDrawer} toggleDrawer={toggleDrawer} />
            <br />
            <Button
              className="slidebar_btn"
              sx={{ p: 1 }}
              onClick={myWorkHours}
            >
              Work Hours
            </Button>{" "}
            <br />
            <br />
            <Button
              className="slidebar_btn"
              sx={{ p: 1 }}
              onClick={empTask}
            >
              Employe Task History
            </Button>{" "}
            <br />
            <br />
            <Button
              className="slidebar_btn"
              sx={{ p: 1 }}
              onClick={empPerformance}
            >
              Employe Performance
            </Button>
            <br />
            <br />
            <Button
              className="slidebar_btn"
              sx={{ p: 1 }}
              onClick={empExpense}
            >
              Employee Expense
            </Button>
            <br />
            <br />
         
          </Popover>
        </div>
      </Stack> */
}
