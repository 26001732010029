import { Drawer, IconButton } from "@mui/material";
import React, { useState, useRef,  } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../redux/actions/home-action-creator";
import { logout } from "../../../auth";
import { useRecoilState } from "recoil";
import Style from "../Style/Profile.module.css";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Checkin from "../../../images/DomainDetails/CheckIn.png";
import Badge from "@mui/material/Badge";
import CustomSnackbar from "../../webservices/Component/CustomSnackbar";
import "../Style/UserDrawer.css";
import axios from "axios";
import {
  apiresponse,
  login_EmpDetails,
  login_atoms,
} from "../../../Atoms/LoginAtoms";
import { styled } from "@mui/material/styles";
import { api_url, api_url1 } from "../../../globalSettings";
import ProfileUpload from "./ProfileUpload";

const CssDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    top: 48,
    height: "100vh",
    width: "23rem",
  },
}));

const customButtonStyle = {
  width: "80px",
  height: "4vh",
  border: "1px solid #ddbdbd",
  background: "#F0483F",
  fontSize: "11px",
  fontFamily: "Inter",
  color: "#ffff",
  fontWeight: 600,
  borderRadius: "2px",
  padding: 0,
  marginLeft: "6px",
};

const customAccButtonStyle = {
  width: "90px",
  height: "4vh",
  border: "1px solid black",
  background: "#ffff",
  fontSize: "11px",
  fontFamily: "Inter",
  fontWeight: 500,
  color: "black",
  borderRadius: "2px",
  padding: 0,
};
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",

    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));
const CircleMenuItem = ({ color, selected, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        borderRadius: "50%",
        width: "15px",
        height: "15px",
        backgroundColor: color,
        marginTop: "3px",
      }}
    ></div>
  );
};
export default function UserDrawer(props) {
  let history = useNavigate();
  const uploadref = useRef(null);

  const dispatch = useDispatch();

  const [companyform, setCompanyForm] = useState({ logo: "" });
  const [loginEmpDetails] = useRecoilState(login_EmpDetails);
  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [apiresponse1, setApiresponse1] = useRecoilState(apiresponse);

  const onlogochange = (e) => {
    setCompanyForm((state) => ({ ...state, logo: e.target.files[0] }));
  };
  const myAccOnClick = () => {
    dispatch(setCurrentPage("MY_ACCOUNT"));
    props.toggleDrawer(true);
    history("/myaccount/profile");
  };
  const signOutOnClick = () => {
    logout();
  };
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: "",
    severity: "",
    message: "",
  });

  const handleSnackbarClose = (event, reason) => {
    setSnackbarInfo((prevInfo) => ({
      ...prevInfo,
      open: false,
    }));
  };
  function getuserdetails() {
    //const url = `http://server.nowdigitaleasy.com:3505/getUserDetails`;
    const params = {
      user_id: loginAtom1,
    };
    axios
      .get(`${api_url1}/getUserDetails`, { params: { ...params } })
      .then((response) => {
        setApiresponse1(response.data.data);
      });
  }

  function updateProfile() {
    let formdata = new FormData();

    formdata.append("user_id", loginAtom1);
    formdata.append("profile_picture", companyform.logo);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .patch(
        `https://server.nowdigitaleasy.com:3505/update_user_profile`,
        formdata,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        setSnackbarInfo({
          open: true,
          severity: "success",
          message: result.data.message,
        });

        getuserdetails();
      })
      .catch((err) => {});

    axios({
      method: "patch",
      url: `${api_url}/update_user_profile`,
      data: formdata,
      config,
    })
      .then(function (response) {
        getuserdetails();
      })
      .catch(function (error) {
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  }

  const updateProfileFun = () => {
    uploadref.current.click();
  };

  React.useEffect(() => {
    if (companyform.logo) {
      updateProfile();
    }
  }, [companyform.logo]);

  const [age, setAge] = React.useState("Available");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const [selectedCircle, setSelectedCircle] = React.useState("green");

  const handleChangee = (event) => {
    setSelectedCircle(event.target.value);
  };

  const handleAdminConsoleClick = () => {
    window.open("https://mailadmin.nowdigitaleasy.com/", "_blank");
  };
  return (
    <div>
      <>
        <CssDrawer
          anchor="right"
          open={props.open}
          onClose={props.toggleDrawer}
        >
          <div className={Style.profile_drawer_wrapper}>
            <div className={Style.profile_top}>
              <div className="drawer-close-btn" onClick={props.toggleDrawer}>
                <IconButton component="span" style={{ padding: 0 }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className={Style.profile_middle_wrapper}>
              <div className={Style.profile_middle}>
                <div className={Style.profile_icon_wrapper}>
                  <div className={Style.userdraweravatar}>
                    <>
                      <ProfileUpload
                        onlogochange={onlogochange}
                        updateProfileFun={updateProfileFun}
                        uploadref={uploadref}
                        path={apiresponse1.profile_pic_path}
                        srcUrl={`https://server.nowdigitaleasy.com${apiresponse1.profile_pic_path}/${apiresponse1.profile_pic_name}`}
                      />
                    </>
                  </div>
                </div>
                <div className={Style.profile_middle_text}>
                  <p className={Style.text_margin}>
                    {loginEmpDetails?.fullName}
                  </p>
                  <p className={Style.text_margin}>{loginEmpDetails?.email}</p>
                  <p
                    className={Style.text_margin}
                    style={{ cursor: "pointer" }}
                    onClick={handleAdminConsoleClick}
                  >
                    Admin Console
                  </p>{" "}
                  <div style={{ marginTop: "0.8rem" }}>
                    <Button style={customAccButtonStyle} onClick={myAccOnClick}>
                      My Account
                    </Button>
                    <Button style={customButtonStyle} onClick={signOutOnClick}>
                      Sign Out
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={Style.profile_bottom_wrapper}>
              <div className={Style.profile_bottom}>
                <div className={Style.profile_bottom_text}>
                  <div>Remote Work</div>
                  <div className={Style.profile_checkin}>
                    <img src={Checkin} alt="checkin" />
                    <div style={{ marginLeft: "20px" }}>00.00.00 Hours </div>
                  </div>
                  <div className={Style.profile_dropdown}>
                    <Box sx={{ marginRight: "20px" }}>
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedCircle}
                          onChange={handleChangee}
                          sx={{
                            width: "56px",
                            borderRadius: "2px",
                            height: "26px",
                            backgroundColor: "#f0f0f4",
                          }}
                          key={"#####f0f0f4"}
                        >
                          <MenuItem value="green">
                            <CircleMenuItem
                              color="green"
                              selected={selectedCircle === "green"}
                            />
                          </MenuItem>
                          <MenuItem value="red">
                            <CircleMenuItem
                              color="red"
                              selected={selectedCircle === "red"}
                            />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          onChange={handleChange}
                          sx={{
                            width: "162px",
                            borderRadius: "2px",
                            height: "26px",
                            backgroundColor: "#f0f0f4",
                          }}
                        >
                          <MenuItem value={"Available"} key={"Available"}>
                            Available
                          </MenuItem>
                          <MenuItem value={"Unavailable"} key={"Unavailable"}>
                            Unavailable
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CssDrawer>
        <CustomSnackbar
          snackbarInfo={snackbarInfo}
          handleSnackbarClose={handleSnackbarClose}
        />
      </>
    </div>
  );
}
