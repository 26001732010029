import React from 'react'
import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { styled } from "@mui/material/styles";
import { attendanceReports  , workHoursReports} from '../../Atoms/LoginAtoms';
import { useRecoilState } from "recoil";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#0000003d",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  
  

export default function WorkHoursReport() {
    const [getWorkHoursReports , setWorkHoursReports] = useRecoilState(workHoursReports)

    return (
    <div><div className="reporttop">
    <Typography>Employee Work Hours Details</Typography>
    <Typography 
    style={{cursor : "pointer"}}><FilterAltOutlinedIcon /></Typography>       
</div>
<div className="reporttable">
    <TableContainer sx={{ maxHeight : 500 }} component={Paper}>
      <Table sx={{ minWidth: 700 , maxHeight : 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Date</StyledTableCell>
            <StyledTableCell align="center">Day</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
            <StyledTableCell align="center">Onduty Time</StyledTableCell>
            <StyledTableCell align="center">Off Duty Time</StyledTableCell>
            <StyledTableCell align="center">Estimated Work Duration</StyledTableCell>
            <StyledTableCell align="center">Actual Work Duration</StyledTableCell>
            <StyledTableCell align="center">Different Duration</StyledTableCell>
            <StyledTableCell align="center">OnDuty Location</StyledTableCell>
            <StyledTableCell align="center">Off Duty Location</StyledTableCell>
            <StyledTableCell align="center">Travell Distance</StyledTableCell>
            <StyledTableCell align="center">Total Break Distance</StyledTableCell>
            <StyledTableCell align="center">Break Duration</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {getWorkHoursReports ? getWorkHoursReports.map((row) => (
            
            <StyledTableRow key={row.name} 
            style={{cursor : "pointer"}}>

              <StyledTableCell component="th" scope="row">{new Date(row.date).toLocaleDateString()}</StyledTableCell>
              <StyledTableCell component="th" scope="row">{row.day}</StyledTableCell>
              <StyledTableCell align="center">{row.status}</StyledTableCell>
              <StyledTableCell align="center">{row.onDutyTime}</StyledTableCell>
              <StyledTableCell align="center">{row.offDutyTime ? row.offDutyTime : "--" }</StyledTableCell>
              <StyledTableCell align="center">{row.estimatedWorkDuration}</StyledTableCell>
              <StyledTableCell align="center">{row.actualWorkDuration}</StyledTableCell>
              <StyledTableCell align="center">--</StyledTableCell>
              <StyledTableCell align="center">{row.onDutyLocation.split(',').map(part => part.trim()).reverse()[2]} {} </StyledTableCell>
              <StyledTableCell align="center">{row.offDutyLocation ? row.offDutyLocation.split(',').map(part => part.trim()).reverse()[2] : "--"}</StyledTableCell>
              <StyledTableCell align="center">{row.travelDistance}</StyledTableCell>
              <StyledTableCell align="center">{row.totalBreakDistance}</StyledTableCell>
              <StyledTableCell align="center">{row.breakDuration}</StyledTableCell>
            </StyledTableRow>
          )) : "No Record Found"}
        </TableBody>
      </Table>
    </TableContainer>
    </div></div>
  )
}
