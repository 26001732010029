import { Avatar, Divider, IconButton, ListItemAvatar, ListItemButton, ListItemText, Popover, Stack } from "@mui/material";
import { useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import variables from "../../assets/styles/_colors.scss";
import CreateProfile from "../../pages/settings/usercontrols/profiles/CreateProfile";
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from "@mui/styles";
import DeleteProfile from "../../pages/settings/usercontrols/profiles/DeleteProfile";
import EditProfile from "../../pages/settings/usercontrols/profiles/EditProfile";
import ViewProfileUsers from "../../pages/settings/usercontrols/profiles/ViewProfileUsers";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    borderRadius: "4px !important",
    border: `1px solid ${variables.popoverBorderColor}`,
    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
  }
}));

const TableRow = ({ data, columns, profiles, showSnackbar }) => {

  const [isHovering, setIsHovering] = useState(false);
  const [editProfile, setEditProfile] = useState(false);

  const resetHover = (data) => {
    setIsHovering(false)
    data && showSnackbar(data);
  }

  return (
    <>
      <tr key={data._id} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}
        style={{ backgroundColor: isHovering ? `whitesmoke` : "#FFF", height: 48, verticalAlign: 'middle' }}>

        {columns.map(({ accessor, width }, index) => {

          return <td key={accessor} id={data._id} onClick={() => { setEditProfile(true); }} handleDrawerClose={() => { setEditProfile(false) }}
            style={{ width: width, backgroundColor: 'inherit', padding: 8, cursor: 'pointer', color: (isHovering && index === 0) ? `${variables.linkColor}` : 'inherit', fontWeight: index === 0 ? 600 : 'normal' }}
          >

            {data[accessor]}

          </td>
        })}

        <Stack direction="row" spacing={1} alignItems="center"
          sx={{
            visibility: isHovering ? 'inherit' : 'hidden',
            height: "47px",
            zIndex: 5,
            width: "85px",
            bgcolor: `${variables.lightGreyBackgroundColor}`,
            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            position: 'sticky', right: 0.5,
            pl: 0.5
          }}>

          <IconButton sx={{ height: "35px", width: "35px" }} onClick={() => setEditProfile(true)}>
            <EditOutlinedIcon fontSize='medium' color={variables.greyTextColor} />
          </IconButton>

          <ProfilesPopover item={data} profiles={profiles} handleClose={(data) => resetHover(data)} />

        </Stack>
      </tr>

      {editProfile && <EditProfile item={data} handleDrawerClose={(data) => { setEditProfile(false); setIsHovering(false); resetHover(data); }} />}

    </>
  );
};

export default TableRow;


function ProfilesPopover(props) {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [cloneProfile, showCloneProfile] = useState(false);
  const [viewUsers, setViewUsers] = useState(false);
  const [deleteProfile, showDeleteProfile] = useState(false);
  const [editProfile, showEditProfile] = useState(false);

  const handleViewProfileModalClose = (action) => {
    action === "edit" && showEditProfile(true);
    action === "delete" && showDeleteProfile(true);
    setViewUsers(false);
    props.handleClose();
  }

  const rowActions = [
    {
      name: "Clone",
      icon: <FileCopyOutlinedIcon />,
      key: 'cloneProfile'
    },
    {
      name: "View Users",
      icon: <VisibilityOutlinedIcon />,
      key: 'viewUsers'
    },
    (!props.item.systemDefined) && {
      name: "Delete",
      icon: <DeleteOutlineIcon />,
      key: "delete"
    }
  ];

  const handleClose = (data) => {
    setAnchorEl(null);
    props.handleClose(data);
  };

  const handleItemClick = (index, item) => {
    handleClose();
    if (index !== null || index !== undefined) {
      switch (item.key) {
        case "cloneProfile":
          showCloneProfile(true);
          return true;
        case "viewUsers":
          setViewUsers(true);
          return true;
        case "delete":
          showDeleteProfile(true);
          return true;
        default:
          return <></>
      }
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileDelete = (data) => {
    showDeleteProfile(false);
    handleClose(data);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'profile-table-popover' : undefined;

  return (
    <span style={{ 'display': 'inline-block' }}>
      <IconButton onClick={handleClick} sx={{ height: "35px" }} >
        <MoreVertIcon />
      </IconButton>
      <Popover
        elevation={1}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.pipeline }}
      >
        {rowActions.map((item, index) => (
          item &&
          <span key={`profile-popover-item-${index}`}>
            <ListItemButton
              key={`${item.name}-${index}`}
              sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
              onClick={() => handleItemClick(index, item)}
            >
              <ListItemAvatar sx={{ minWidth: 46 }}>
                <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                  {item.icon}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={item.name}
              />
            </ListItemButton>
            <Divider sx={{ borderBottomWidth: 3 }} />
          </span>
        ))}
      </Popover>

      {cloneProfile &&
        <CreateProfile profiles={props.profiles} cloneData={props.item} handleDrawerClose={(data) => { showCloneProfile(false); handleClose(data); }} />}

      {deleteProfile &&
        <DeleteProfile item={props.item} handleClose={(data) => { handleProfileDelete(data) }} />}

      {viewUsers &&
        <ViewProfileUsers item={props.item} handleClose={(action) => { handleViewProfileModalClose(action) }} />}

      {editProfile &&
        <EditProfile item={props.item} handleDrawerClose={(data) => { showEditProfile(false); handleClose(data) }} />}

    </span>
  );
}