import React, { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Button, Typography, styled, Divider,Modal,TextField,Box, ListItemText } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const StyledButton = styled(Button)({
  "&.MuiButtonBase-root": {
    width: "300px",
    display: "flex",
    justifyContent: "flex-start",
  },
});

const ConversationInfo = () => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const [newEmail, setNewEmail] = useState("");
  const [emailModalOpen, setEmailModalOpen] = useState(false);

  const handleEditEmail = () => {
    setEmailModalOpen(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModalOpen(false);
    setNewEmail("");
  };

  return (
    <List sx={{ p: 0 }}>
      <StyledButton
        onClick={handleClick}
        sx={{
          padding:"11px 40px 10px 12px ",
          backgroundColor: "#fafbfc",
          borderBottom: "1px solid #e3e8ef",
        }}
      >
        <Typography
          sx={{
            fontSize: 12,
            fontFamily: "Inter",
            color: "#75869c",
            textAlign: "left",
          }}
        >
          CONVERSATION PARTICIPANTS
        </Typography>
        {open ? (<ArrowDropDownIcon sx={{color: "#75869c",marginLeft:"auto"}}/>) : (<ArrowLeftIcon sx={{color: "#75869c",marginLeft:"auto"}}/>)}
      </StyledButton>

      {open && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListItem >
            <ListItemIcon sx={{minWidth:"40px",}}>
              <EmailOutlinedIcon
                sx={{
                  
                  cursor: "inherit",
                  color: "#3f536e",
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              />
            </ListItemIcon>
            <ListItemText>
            <Typography sx={{ fontSize: "13px", fontFamily: "Inter" }}>
              santhiya@iaaxin.com
            </Typography>
            </ListItemText>
            <Button sx={{marginLeft:"15px",textTransform:"capitalize",color:"#0093ee",fontSize:"12.5px",lineHeight:"15px",cursor:"pointer"}} onClick={handleEditEmail}>
                Add
            </Button>
          </ListItem>
        </Collapse>
      )}
      <Divider sx={{ borderBottom: "1px solid grey" }} />

      <Modal open={emailModalOpen} onClose={handleCloseEmailModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid grey",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography>Participant email address</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              fullWidth
              placeholder="Enter participant email address..."
              type="text"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <Box>
              <>
                <Button
                  onClick={handleCloseEmailModal}
                  sx={{
                    marginLeft: "140px",
                    color: "black",
                    border: "1px solid grey",
                    backgroundColor: "white",
                    textTransform: "capitalize",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </Button>
              </>
              <>
                <Button
                  onClick={handleCloseEmailModal}
                  sx={{
                    marginLeft: "10px",
                    color: "white",
                    border: "1px solid grey",
                    backgroundColor: "#0093ee",
                    textTransform: "capitalize",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: "#0093ee",
                    },
                  }}
                >
                 Add Participant
                </Button>
              </>
            </Box>
          </Box>
        </Modal>
    </List>
  );
};

export default ConversationInfo;
