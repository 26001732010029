import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import Styles from "../../ContactInfo.module.scss";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Overview = ({ contact }) => {
  const [asset, setAsset] = React.useState(false);
  const [benefit, setBenefit] = React.useState(false);
  const [exit, setExit] = React.useState(false);
  const [expense, setExpense] = React.useState(false);
  const [request, setRequest] = React.useState(false);

  const handleClick = (listName) => {
    switch (listName) {
      case "asset":
        setAsset(!asset);
        break;
      case "benefit":
        setBenefit(!benefit);
        break;
      case "exit":
        setExit(!exit);
        break;
      case "expense":
        setExpense(!expense);
        break;
      case "request":
        setRequest(!request);
        break;
      default:
        break;
    }
  };

  return (
    <div style={{overflowY:"auto",height:"70%"}}>
      <div className={Styles.CTabbody}>
        <List
          sx={{ width: "100%", maxWidth: 360,  }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box
            onClick={() => handleClick("asset")}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
                 {asset ? (
              <ExpandLess sx={{ color:  'var(--light-text-color)' }} />
            ) : (
              <ExpandMore sx={{ color:'var(--light-text-color)' }} />
            )}

            <Typography sx={{ paddingLeft: "10px", fontSize: "14px" }}>
              ASSET
            </Typography>
          </Box>
          <Collapse in={asset} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>Asset</ListItemText>
            </List>
          </Collapse>
        </List>

        <Link style={{ marginLeft: "auto" }} to="asset">
          <AddIcon sx={{ fontSize: "23px", color: 'var(--light-text-color)' }} />
        </Link>
      </div>
      <div className={Styles.CTabbody}>
        <List
          sx={{ width: "100%", maxWidth: 360, }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box
            onClick={() => handleClick("benefit")}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            {benefit ? (
              <ExpandLess sx={{ color: 'var(--light-text-color)' }} />
            ) : (
              <ExpandMore sx={{ color: 'var(--light-text-color)' }} />
            )}

            <Typography sx={{ paddingLeft: "10px", fontSize: "14px" }}>
              BENEFIT
            </Typography>
          </Box>
          <Collapse in={benefit} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>Benefit</ListItemText>
            </List>
          </Collapse>
        </List>

        <Link style={{ marginLeft: "auto" }} to="benefit">
          <AddIcon sx={{ fontSize: "23px", color: 'var(--light-text-color)' }} />
        </Link>
      </div>
      <div className={Styles.CTabbody}>
        <List
          sx={{ width: "100%", maxWidth: 360,  }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box
            onClick={() => handleClick("exit")}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            {exit ? (
              <ExpandLess sx={{ color: 'var(--light-text-color)' }} />
            ) : (
              <ExpandMore sx={{ color: 'var(--light-text-color)' }} />
            )}

            <Typography sx={{ paddingLeft: "10px", fontSize: "14px" }}>
              EXIT DETAILS
            </Typography>
          </Box>
          <Collapse in={exit} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>Exit Details</ListItemText>
            </List>
          </Collapse>
        </List>

        <Link style={{ marginLeft: "auto" }} to="exit-details">
          <AddIcon sx={{ fontSize: "23px", color: 'var(--light-text-color)' }} />
        </Link>
      </div>
      <div className={Styles.CTabbody}>
        <List
          sx={{ width: "100%", maxWidth: 360,  }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box
            onClick={() => handleClick("expense")}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            {expense ? (
              <ExpandLess sx={{ color: 'var(--light-text-color)' }} />
            ) : (
              <ExpandMore sx={{ color: 'var(--light-text-color)' }} />
            )}

            <Typography sx={{ paddingLeft: "10px", fontSize: "14px" }}>
              TRAVEL EXPENSE
            </Typography>
          </Box>
          <Collapse in={expense} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>Travel Expense</ListItemText>
            </List>
          </Collapse>
        </List>

        <Link style={{ marginLeft: "auto" }} to="travel-expense">
          <AddIcon sx={{ fontSize: "23px", color: 'var(--light-text-color)' }} />
        </Link>
      </div>
      <div className={Styles.CTabbody}>
        <List
          sx={{ width: "100%", maxWidth: 360,  }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <Box
            onClick={() => handleClick("request")}
            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            {request ? (
              <ExpandLess sx={{ color: 'var(--light-text-color)' }} />
            ) : (
              <ExpandMore sx={{ color: 'var(--light-text-color)' }} />
            )}

            <Typography sx={{ paddingLeft: "10px", fontSize: "14px" }}>
              TRAVEL REQUEST
            </Typography>
          </Box>
          <Collapse in={request} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemText>Travel Request</ListItemText>
            </List>
          </Collapse>
        </List>

        <Link style={{ marginLeft: "auto" }} to="travel-request">
          <AddIcon sx={{ fontSize: "23px", color: 'var(--light-text-color)' }} />
        </Link>
      </div>
    </div>
  );
};

export default Overview;
