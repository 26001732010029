import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@mui/material/IconButton";
import Style from "../../../Style/TopBar/CustomDrawer.module.css";
const DrawerHeader = ({ onClose,title }) => {
  return (
    <div className={Style.drawerHeaderWrapper}>
      <h3 className={Style.drawertitle}>{title}</h3>

      <IconButton component="span" onClick={onClose} style={{ padding: 0 }}>
        <CloseIcon style={{ color: "black" }} />
      </IconButton>
    </div>
  );
};

export default DrawerHeader;
