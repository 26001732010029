import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import SimpleBackdropWithLoader from "../components/common/Loader";
import ToastMsg from "../components/common/ToastMsg";

export default function Common() {

  const { isToShowToastMsg } = useSelector((state) => state.common, shallowEqual);

  return (
    <div id="common">
      {isToShowToastMsg && <ToastMsg />}
      <SimpleBackdropWithLoader />
    </div>
  );
}
