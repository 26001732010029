import React from "react";
import refresh from "../../../images/DomainDetails/Refresh.png";
import Style from "../Style/ErrorInfo.module.css";


const ErrorInfo = ({actionstatus1}) => {

 


  return (
    <>
    {actionstatus1.length >=1 ? 
    <>
    {
      actionstatus1.map((item)=>(
        <div className={Style.container}>
      <div className={Style.inner_container}>
        <div className={Style.img_margin}>
          <img src={refresh} alt="action pending" />
        </div>
        <div>
          <div>
            <p className={Style.text_style}>
              {item.actiontypedesc}
            </p>
          </div>
          <div>
            <p className={Style.text_style}>{item.actionstatusdesc}</p>
          </div>
        </div>
      </div>
    </div>
      ))
    }
    </> : ""}
    
    </>
  );
};

export default ErrorInfo;