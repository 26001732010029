import * as React from 'react';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { Divider, IconButton, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import variables from "../../../assets/styles/_colors.scss";
import UpsertTask from './UpsertTask';
import MoreVertIcon from "@mui/icons-material/MoreVert";

const useStyles = makeStyles((theme) => ({
    activities: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

const activities = [
    {
        name: "Edit",
        key: 'edit'
    },
    {
        name: "Mark as completed",
        key: "markAsCompleted"
    },
    {
        name: "Delete",
        key: "delete"
    }
]

export default function EventMoreActionPopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [editActitivy, setEditActivity] = useState(false);
    const [markAsCompleted, setMarkAsCompleted] = useState(false);
    const [deleteActivity, setDeleteActivity] = useState(false);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "edit":
                    setEditActivity(true);
                    return true;
                case "markAsCompleted":
                    setMarkAsCompleted(true);
                    return true;
                case "delete":
                    setDeleteActivity(true);
                    return true;
                default:
                    return null;
            }
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'activities-popover' : undefined;

    return (
        <div style={{ 'display': 'inline-block' }}>
            <IconButton aria-label="activities-popover" variant="outlined" onClick={handleClick} sx={{ p: 0.5, ml: 1 }}>
                <MoreVertIcon fontSize='small' />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                classes={{ paper: classes.pipelineBulkActions }}
            >
                {activities.map((item, index) => (
                    <div key={`activities-item-${index}`}>
                        <ListItemButton
                            key={`${item.name}-${index}`}
                            sx={{ p: "8px 12px" }}
                            onClick={() => handleItemClick(index, item)}
                        >
                            <ListItemText
                                primary={item.name}
                            />
                        </ListItemButton>
                        <Divider sx={{ borderBottomWidth: 3 }} />
                    </div>
                ))}
            </Popover>
            {editActitivy && <UpsertTask module={"task"} edit={true} handleDrawerClose={() => { setEditActivity(false); props.resetHover(); }} />}
            {markAsCompleted && <UpsertTask module={"event"} handleDrawerClose={() => { setMarkAsCompleted(false); props.resetHover(); }} />}
            {deleteActivity && <UpsertTask module={"call"} handleDrawerClose={() => { setDeleteActivity(false); props.resetHover(); }} />}
        </div>
    );
}