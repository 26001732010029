// importing atom from recoil
import { atom } from "recoil";
//for persisting recoil state
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

  const isSideBarActive = atom({
    key: "isSideBarActive",
    default: true,
    effects_UNSTABLE: [persistAtom],
  });

export {
    isSideBarActive
}
  