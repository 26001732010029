import React from "react";
import { IconButton, Grid, Modal, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import { loadingAll } from "../../../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};

const TheftProtection = (props) => {

    const [isLoading]  = useRecoilState(loadingAll);


  return (
    <>
      <div>
        <div>
          <Modal
            open={props.theftprotectionupdatedialog}
            onClose={props.theftprotectiondialogonclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="scroll">
              {/* <div className="addcompanytitle" style={{ height: 60 }}>
                    Child Name Server
                    <IconButton
                      component="span"
                      onClick={props.theftprotectiondialogonclose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  maxWidth: "100%",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    {props.status ? (
                      <div style={{ padding: 20 }}>
                        <h6 style={{ fontWeight: "600", margin: 0 }}>
                          DISABLE THEFT PROTECTION
                        </h6>
                        <p style={{ fontWeight: "400" }}>For : {props.domainDetails.domainname} </p>
                        <div style={{ padding: 10 }}>
                          <h6
                            style={{
                              fontWeight: "600",
                              fontSize: "13px",
                              color: "#e76060",
                            }}
                          >
                            Disabling Theft protection may cause unauthorized
                            transfer outs.
                          </h6>
                          <h6 style={{ fontWeight: "bold", fontSize: "13px" }}>
                            Are you sure you want to continue?
                          </h6>
                          <LoadingButton
                            loading={isLoading}
                            className="loadingButton"
                            style={{backgroundColor:"#e76060"}}
                            onClick={props.handleTheftProtection}
                          >
                            YES, DISABLE THEFT PROTECTION
                          </LoadingButton>
                        </div>
                      </div>
                    ) : (
                      <div style={{ padding: 20 }}>
                        <h6 style={{ fontWeight: "500", margin: 0 }}>
                          ENABLE THEFT PROTECTION
                        </h6>
                        <p style={{ fontWeight: "400" }}>For : {props.domainDetails.domainname} </p>
                        <div style={{ padding: 10 }}>
                          <h6
                            style={{
                              fontWeight: "600",
                              fontSize: "13px",
                            }}
                          >
                            By Enabling Theft Protection, your Domain Name will
                            be safeguarded against unauthorized Transfer-outs.
                          </h6>

                          <LoadingButton
                           loading={isLoading}
                            className="loadingButton"
                            style={{backgroundColor:" #468def"}}
                            onClick={props.handleTheftProtection}
                          >
                            ENABLE THEFT PROTECTION
                          </LoadingButton>
                        </div>
                      </div>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      backgroundColor: "#e0e0e0",
                      padding: 20,
                      zIndex: "0",
                    }}
                  >
                    {/* <IconButton
                      component="span"
                      onClick={props.theftprotectiondialogonclose}
                    >
                      <CloseIcon />
                    </IconButton> */}
                    <div style={{ padding: 20, fontSize: 11, fontWeight: 500 }}>
                      When Theft Protection is enabled, your Domain Name cannot
                      be transferred. This protects from unauthorized
                      transfer-outs.
                      <br />
                      <br />
                      You will need to disable Theft Protection when you are
                      transferring your Domain Name to another provider.
                      <br />
                      <br />
                      <a href="nowdigitaleasy.com">How it Works</a>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Modal>
        </div>
        <style>
          {`
                .loadingButton{
                    color: rgb(255, 255, 255);
                    background-color: #468def;
                    margin-top: 20px;
                    cursor: pointer;
                    width:max-content;
                    border-radius:0px
                }
                `}
        </style>
      </div>
    </>
  );
};

export default TheftProtection;
