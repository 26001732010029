// importing atom from recoil
import { atom } from "recoil";
//for persisting recoil state
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

var warningpopup = atom({
  key: "warningpopup",
  default: false,
});

var addsteppopup = atom({
    key: "addsteppopup",
    default: false,
  });

  var addprospectsopup = atom({
    key: "addprospectsopup",
    default: false,
  });

  var snackbarstatus = atom({
    key: "snackbarstatus",
    default: {severity:"",message:"",type:"",state:false},
  });

  var prospectstrigger = atom({
    key: "prospectstrigger",
    default: false,
  });

  var stepstrigger = atom({
    key: "stepstrigger",
    default: false,
  });

  var sendmail = atom({
    key: "sendmail",
    default: false,
  });

  const actionstatus = atom({
    key: "actionstatus",
    default: [],
    effects_UNSTABLE: [persistAtom],
  });

  const plesknameserverpopup = atom({
    key: "plesknameserverpopup",
    default: false,
  });
  


export {
    warningpopup,
    addsteppopup,
    addprospectsopup,
    snackbarstatus,
    prospectstrigger,
    stepstrigger,
    sendmail,
    actionstatus,
    plesknameserverpopup
};
