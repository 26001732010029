import { Autocomplete, Box, Button, CircularProgress, Drawer, Grid, Stack, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import variables from "../../../../assets/styles/_colors.scss";
import { CloseButton } from "../../../../components/CloseButton";
import { CustomInputField } from "../../../pipelines/components/CustomInputField";
import { CustomSelect } from "../../../pipelines/components/CustomSelect";
import RoleTree from "../roles/RoleTree";
import { CustomDatePicker } from "../../../pipelines/components/CustomDatePicker";
import timezones from 'timezones-list';
import countryLocaleMap from 'country-locale-map';
import { editUser, getProfilesDropdown } from "../../../../webservices/UserControlService";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import CustomSnackbar from "../../../../components/CustomSnackbar";

import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

const schema = Yup.object().shape({
    firstName: Yup.string().label("First Name").trim().max(50, "First Name cannot be more than 50 chars long"),
    lastName: Yup.string().label("Last Name").trim().required("Last Name cannot be empty").max(50, "Last Name cannot be more than 50 chars long"),
    email: Yup.string().label("Email").trim().email("Email is not valid").required("Email cannot be empty").max(250, "Email cannot be more than 250 chars long"),
    roleName: Yup.string().label("Role").trim().required("Role cannot be empty"),
    profile: Yup.string().label("Profile").trim().required("Profile cannot be empty"),
    phone: Yup.string().trim().max(30, "Phone cannot be more than 30 chars long"),
    mobile: Yup.string().trim().max(30, "Mobile cannot be more than 30 chars long"),
    website: Yup.string().trim().max(250, "Website cannot be more than 250 chars long"),
    fax: Yup.string().trim().max(30, "Fax cannot be more than 30 chars long"),
    dateOfBirth: Yup.string().trim(),
    countryLocale: Yup.object(),
    timezone: Yup.object(),
    timeFormat: Yup.string().required("Time Format cannot be empty"),
    street: Yup.string().max(250, "Street cannot be more than 250 chars long").optional(),
    city: Yup.string().max(100, "City cannot be more than 100 chars long").optional(),
    state: Yup.string().max(100, "State cannot be more than 100 chars long").optional(),
    country: Yup.string().max(100, "Country cannot be more than 100 chars long").optional(),
    zipcode: Yup.string().max(30, "Zip Code cannot be more than 30 chars long").optional(),
});

const EditUser = (props) => {

    const queryClient = useQueryClient();

    const [open, setOpen] = useState(true);
    const [roleTree, showRoleTree] = useState(false);
    const countryLocale = countryLocaleMap.getAllCountries();
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data: profiles,
        isError,
        error
    } = useQuery({
        queryKey: ['getAllProfilesDropdown'],
        queryFn: () => getProfilesDropdown(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        isError && setSnackbarInfo({
            open: true,
            severity: 'error',
            message: error?.response?.data?.error?.message
        });
    }, [isError]);

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    function handleDrawerClose(data) {
        setOpen(false);
        props.handleDrawerClose(data);
    }

    const handleRoleSelect = (item) => {
        if (item) {
            setValue("roleName", item.roleName);
            setValue("role", item?._id);
        }
        showRoleTree(false);
    }

    const { mutateAsync, isPending } = useMutation({
        mutationKey: "editUser",
        mutationFn: (data) => editUser(props.item?._id, data),
        onSuccess: (response) => {
            handleDrawerClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
        },
        onError: (errorResponse) => {
            setSnackbarInfo({
                open: true,
                severity: 'error',
                disableAutoHide: true,
                message: errorResponse.response.data.error?.message
            });
        }
    });

    const methods = useForm(
        {
            defaultValues: props.item ? {
                "firstName": props.item?.firstName,
                "lastName": props.item?.lastName,
                "profile": props.item?.profile?._id,
                "role": props.item?.role?._id,
                "roleName": props.item?.role?.roleName,
                "email": props.item?.email,
                "phone": props.item?.phone,
                "mobile": props.item?.mobile,
                "website": props.item?.website,
                "fax": props.item?.fax,
                "dateOfBirth": props.item?.dateOfBirth,
                "countryLocale": props.item?.countryLocale,
                "timezone": props.item?.timezone,
                "timeFormat": props.item?.timeFormat,
                "street": props.item?.street,
                "city": props.item?.city,
                "state": props.item?.state,
                "country": props.item?.country,
                "zipcode": props.item?.zipcode
            } : {},
            shouldUnregister: false,
            resolver: yupResolver(schema),
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const { handleSubmit, setValue, trigger, control, getValues } = methods;

    const onSubmit = async (data) => {
        const isStepValid = await trigger();
        if (isStepValid) {
            data._id = props.item._id;
            data.createdBy = props.item.createdBy;
            data.modifiedBy = "User 1";
            data.status = props.item.status;
            // alert(JSON.stringify(data));
            mutateAsync(data);
        }
    }

    return (
        <>
            <Drawer anchor="right" open={open}
                PaperProps={{
                    sx: {
                        minWidth: "70vw", maxWidth: "70vw", borderTop: `2px solid ${variables.borderColor}`, overflow: "hidden"
                    },
                }}>
                <Box sx={{ height: "100vh" }}>

                    <Typography variant="h5" sx={{ pl: 3, pb: 2, pt: 2, background: `${variables.linkBackgroundColor}` }}>Edit User
                        <CloseButton handleDialogClose={handleDrawerClose} />
                    </Typography>

                    <Box sx={{ overflowY: "auto", overflowX: "hidden", p: 2, pl: 3, height: "83vh" }}>
                        <FormProvider {...methods}>
                            <form onSubmit={handleSubmit(onSubmit)} id="userForm" style={{ height: "100%" }}>

                                <Box>
                                    <Typography sx={{ fontWeight: "bold", fontSize: 17 }}>User Information</Typography>
                                    <Grid container direction="row" spacing={4} sx={{ pl: 4, pr: 4, pt: 4 }} >
                                        <Grid item xs={6}>
                                            <Grid container direction="column" rowSpacing={3}>
                                                <Grid item>
                                                    <CustomInputField name={`firstName`} size="medium" label="First Name" />
                                                </Grid>
                                                <Grid item>
                                                    {profiles &&
                                                        <CustomSelect name={`profile`}
                                                            inputLabel="Profile*"
                                                            options={profiles}>
                                                        </CustomSelect>}
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`email`} size="medium" label="Email" disabled={true} />
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`mobile`} size="medium" label="Mobile" />
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`fax`} size="medium" label="Fax" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" rowSpacing={3}>
                                                <Grid item>
                                                    <CustomInputField name={`lastName`} size="medium" label="Last Name*" />
                                                </Grid>
                                                <Grid item>
                                                    <Typography component="div" onClick={() => showRoleTree(true)} sx={{ cursor: 'pointer' }}>
                                                        <CustomInputField name={`roleName`} size="medium" label="Role*" placeholder="Select" readOnly={true} />
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`phone`} size="medium" label="Phone" />
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`website`} size="medium" label="Website" subType="url" />
                                                </Grid>
                                                <Grid item>
                                                    <CustomDatePicker name={`dateOfBirth`} width="100%" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ pt: 4, pb: 2 }}>
                                    <Typography sx={{ fontWeight: "bold", fontSize: 17 }}>Locale Information</Typography>
                                    <Grid container direction="row" spacing={4} sx={{ pl: 4, pr: 4, pt: 4 }} >
                                        <Grid item xs={6}>
                                            <Grid container direction="column" rowSpacing={3}>
                                                <Grid item>
                                                    <Controller
                                                        name="countryLocale"
                                                        control={control}
                                                        defaultValue={countryLocale[236]}
                                                        render={({ field: { ref, ...field } }) => (
                                                            <Autocomplete
                                                                disableClearable
                                                                getOptionLabel={(option) => option.name}
                                                                onChange={(event, value) => { field.onChange(value) }}
                                                                options={countryLocale}
                                                                defaultValue={countryLocale[236]}
                                                                value={getValues("countryLocale")}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Country Locale" inputRef={ref} />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <CustomSelect name={`timeFormat`} inputLabel="Time Format" defaultValue={"12"} options={[{ label: "12 Hours", value: "12" }, { label: "24 Hours", value: "24" }]} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" rowSpacing={3}>
                                                <Grid item>
                                                    <Controller
                                                        name="timezone"
                                                        control={control}
                                                        defaultValue={timezones[326]}
                                                        render={({ field: { ref, ...field } }) => (
                                                            <Autocomplete
                                                                disableClearable
                                                                getOptionLabel={(option) => option.label}
                                                                onChange={(event, value) => { field.onChange(value) }}
                                                                options={timezones}
                                                                defaultValue={timezones[326]}
                                                                value={getValues("timezone")}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Time Zone" inputRef={ref} />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ pt: 4, pb: 2 }}>
                                    <Typography sx={{ fontWeight: "bold", fontSize: 17 }}>Address Information</Typography>
                                    <Grid container direction="row" spacing={4} sx={{ pl: 4, pr: 4, pt: 4 }} >
                                        <Grid item xs={6}>
                                            <Grid container direction="column" rowSpacing={3}>
                                                <Grid item>
                                                    <CustomInputField name={`street`} size="medium" label="Street" />
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`state`} size="medium" label="State" />
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`zipcode`} size="medium" label="Zip Code" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container direction="column" rowSpacing={3}>
                                                <Grid item>
                                                    <CustomInputField name={`city`} size="medium" label="City" />
                                                </Grid>
                                                <Grid item>
                                                    <CustomInputField name={`country`} size="medium" label="Country" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </FormProvider>
                    </Box>

                    <Stack direction="row" justifyContent="right" spacing={2} sx={{ maxHeight: 70, position: "absolute", bottom: 0, width: "100%", padding: "12px 20px", background: `${variables.linkBackgroundColor}` }}>
                        <Button disabled={isPending} variant="outlined" onClick={() => handleDrawerClose()}>
                            Cancel
                        </Button>
                        <Button
                            disabled={isPending}
                            type="submit"
                            form={"userForm"}
                            variant="contained"
                            color="primary"
                        > Save
                            {isPending && <CircularProgress size={18} sx={{ ml: 1 }} />}
                        </Button>
                    </Stack>
                </Box>

                <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />

                {roleTree && <RoleTree handleClose={(item) => handleRoleSelect(item)} />}

            </Drawer >
        </>
    );
};

export default EditUser;