import React from "react";
import "../styles/contacts.css";
import Dashboard from "./ContactPage/Dashboard3";
export default function Contacts() {

  return (
    <div className="contacts" style={{ width: "100%" }}>
      <Dashboard />
    </div>
  );
}
