import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { CloseButton } from '../../../../components/CloseButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { activateUser } from '../../../../webservices/UserControlService';

export default function ActivateUser(props) {

    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(true);

    const { mutateAsync } = useMutation({
        mutationKey: "deleteRole",
        mutationFn: (id) => activateUser(id),
        onSuccess: (response) => {
            handleClose(response);
            queryClient.invalidateQueries({ queryKey: ['getAllUsers'] });
            queryClient.invalidateQueries({ queryKey: ['getUsersCount'] });
        },
        onError: (errorResponse) => {
            handleClose(errorResponse.response.data.error);
        }
    });

    const handleClose = (data) => {
        setOpen(false);
        props.handleClose(data);
    }

    const handleActivation = () => {
        mutateAsync(props.item[0]?.original?._id);
    }

    return (
        <Modal open={open} >
            <>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>{props.item.length > 1 ? "Activate Users" : "Activate User"}
                        <CloseButton handleDialogClose={() => handleClose()} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ pt: 2, pb: 2 }}>
                        {`Upon activation ${props.item.length > 1 ? "these users" : `the user ${props.item[0]?.original?.email}`} will regain access to this organization.`}
                    </Typography>

                    <Stack spacing={2} direction="row" justifyContent="right" sx={{ pt: 2, pb: 1 }}>
                        <Button variant="outlined" onClick={() => handleClose()}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleActivation}>Activate User</Button>
                    </Stack>
                </Box>
            </>
        </Modal>
    );
}