import { Alert,  Grid, Snackbar, TextField } from "@mui/material";
import React , {useCallback, useState} from "react";
import { login_EmpDetails,  profile_trigger, selected_contact } from "../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import axios from "axios";
import { api_url } from "../../globalSettings";
import { LoadingButton } from "@mui/lab";




const ContactTabEmail = () => {
    const [loginEmpDetails] = useRecoilState(login_EmpDetails)
    const [selectedContact] = useRecoilState(selected_contact);
  
    const [description , setDescription] = React.useState('');
    const [subject , setSubject] = React.useState('');
    const [profileTrigger , setProfileTrigger] = useRecoilState(profile_trigger);
    const [snackbaropen, setSnackbaropen] = React.useState({
        open: false,
        message: "",
        severity: "",
      });




//   ================================   FILE SELECT  =====================================
   const [selectedFile, setSelectedFile] = React.useState(null);


  const onChangeHandler = useCallback((event) => {
    setSelectedFile(event.target.files[0]);
  }, []);


const [loading , setLoading ] = useState(false)


  const sendMail = () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("customer_id",selectedContact._id );
    formdata.append("from", loginEmpDetails.employeeDetails.email_id);
    formdata.append("to", selectedContact.email);
    formdata.append("subject", subject);
    formdata.append("follow_up", "none");
    formdata.append("remainder", "none");
    formdata.append("attachment_link", "");
    formdata.append("image", selectedFile);
    formdata.append("content", description);


    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios({
      method: "post",
      url: `${api_url}/crm_customer/addMailData`,
      data: formdata,
      config,
    })
      .then(function (response) {
      
        setSnackbaropen({
            open: true,
            message: response.data.message,
            severity: "success",
            actionColor: "#edf7ed",
          });
          setSubject('');
          setDescription('');
        setLoading(false);
        setProfileTrigger(!profileTrigger);
      })
      .catch(function (error) {
        if (error.response) {
          
        } else if (error.request) {
         
        } else {
        
        }
      });
  
  }



  return (
    <div style={{ width: "100%", fontSize: "12px" , display:"flex" , alignItems : "center" , flexDirection : "column" }}>
    <Snackbar
open={snackbaropen.open}
onClose={() => setSnackbaropen((state) => ({ ...state, open: false }))}
autoHideDuration={6000}
>
<Alert
  severity={snackbaropen.severity}
  style={{ backgroundColor: `${snackbaropen.actionColor}` }}
>
  {snackbaropen.message}
</Alert>
</Snackbar>
  <Grid container spacing={1}>
  <Grid item xs={12} sm={12} md={6}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          From{" "}
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <TextField
    variant="outlined"
    type="text"
    InputProps={{
      style: {
        borderRadius: "0px",
      },
    }}
    value={loginEmpDetails.employeeDetails.email_id}
    size="small"
    fullWidth
    placeholder="In Hours"
  />
        </Grid>
      </Grid>
    </Grid>{" "}
    <Grid item xs={12} sm={12} md={6} >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          To{" "}
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <TextField
    variant="outlined"
    type="text"
    InputProps={{
      style: {
        borderRadius: "0px",
      },
    }}
    value={selectedContact.email}
    size="small"
    fullWidth
    placeholder="In Hours"
  />
        </Grid>
      </Grid>
    </Grid>{" "}
    <Grid item xs={10} sm={10} md={10} marginTop={2}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          Subject{" "}
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <TextField
    variant="outlined"
    type="text"
    InputProps={{
      style: {
        borderRadius: "0px",
      },
    }}
    value={subject}
    onChange={(e)=>{setSubject(e.target.value)}}
    size="small"
    fullWidth
    placeholder="Subject"
  />
        </Grid>
      </Grid>
    </Grid>{" "}
    <Grid item xs={10} sm={10} md={10} marginTop={2}>
      <Grid container spacing={1}>
        <Grid
          xs={12}
          sm={4}
          md={3}
          display={"flex"}
          justifyContent={"flex-end"}
          paddingTop={2}
        >
          Main Content
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <TextField
    variant="outlined"
    type="text"
    rows={3}
    InputProps={{
      style: {
        borderRadius: "0px",
      },
    }}
    value={description}
    onChange={(e)=>{setDescription(e.target.value)}}
    multiline
    fullWidth
    placeholder="Content"
  />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={10} sm={10} md={10} marginTop={2}>
      <Grid container spacing={1}>
        <Grid
          xs={12}
          sm={4}
          md={3}
          display={"flex"}
          justifyContent={"flex-end"}
          paddingTop={1}
        >
          Files
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
        <div>
      <input type="file" onChange={onChangeHandler} />
     
    </div> 
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  <LoadingButton 
    style={{
      width: "100px",
      marginTop: "20px",
     
    }}
    variant="contained" 
    disabled={description.length > 0 && subject.length > 0 ? false : true}
    loading={loading == true ? true : false} 
 onClick={sendMail}
  >
    Send{" "}
  </LoadingButton >{" "}
</div>
  )
}

export default ContactTabEmail