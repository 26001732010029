// importing atom from recoil
import { atom } from "recoil";
//for persisting recoil state
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const PassInvoiceId = atom({
  key: "PassInvoiceId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

const domain_data = atom({
  key: "domain_data",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

var json_formData = atom({
  key: "json_formData",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

var login_atoms = atom({
  key: "login_atoms",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var groupFieldId = atom({
  key: "groupFieldId",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

var noti_list = atom({
  key: "noti_list",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var login_token = atom({
  key: "login_token",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var login_EmpDetails = atom({
  key: "login_EmpDetails",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var selected_contact = atom({
  key: "selected_contact",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var crmDashCollapse = atom({
  key: "crmDashCollapse",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

var login_companyid = atom({
  key: "company_id",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var selectedEmp = atom({
  key: "selectedEmp",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
var profile_trigger = atom({
  key: "profile_trigger",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var selectedEmpPosition = atom({
  key: "selectedEmpPosition",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var ZoomLevel = atom({
  key: "ZoomLevel",
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

var attendanceReports = atom({
  key: "attendanceReportss",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var workHoursReports = atom({
  key: "attendanceReports",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var empExpDetails = atom({
  key: "empExpDetails",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var taskReports = atom({
  key: "taskReports",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var performanceReports = atom({
  key: "performanceReports",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var apiresponse = atom({
  key: "apiresponse",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

var triger_branch = atom({
  key: "triger_branch",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

var triger_company = atom({
  key: "triger_company",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

var triger_records = atom({
  key: "triger_records",
  default: false,
  effects_UNSTABLE: [persistAtom],
});

var openSnackbar = atom({
  key: "openSnackbar",
  default: false,
});
var currentTabss = atom({
  key: "currentTabs",
  default: "",
});

var testsockect = atom({
  key: "testsockect",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

var latlong = atom({
  key: "latlong",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

var totalEmployee = atom({
  key: "totalEmployee",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

var empployeeSearch = atom({
  key: "empployeeSearch",
  default: [],
});

var intervelTrigger = atom({
  key: "intervelTrigger",
  default: "",
});

var loadingAll = atom({
  key: "isLoadingg",
  default: false,
});

var isLoading = atom({
  key: "isLoading",
  default: false,
});

var livedata = atom({
  key: "livedata",
  default: [],
});

var SelectEmployeeId = atom({
  key: "SelectEmployeeId",
  default: [],
  effects_UNSTABLE: [persistAtom],
});
var UserType = atom({
  key: "UserType",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
var JWT = atom({
  key: "JWT",
  default: "",
  effects_UNSTABLE: [persistAtom],
});
var OpenRazorPay = atom({
  key: "OpenRazorPay",
  default: false,
  effects_UNSTABLE: [persistAtom],
});
export {
  domain_data,
  login_atoms,
  json_formData,
  selected_contact,
  login_EmpDetails,
  apiresponse,
  login_token,
  login_companyid,
  selectedEmp,
  ZoomLevel,
  selectedEmpPosition,
  triger_branch,
  openSnackbar,
  currentTabss,
  attendanceReports,
  testsockect,
  latlong,
  taskReports,
  performanceReports,
  workHoursReports,
  empExpDetails,
  totalEmployee,
  triger_company,
  triger_records,
  noti_list,
  groupFieldId,
  empployeeSearch,
  intervelTrigger,
  isLoading,
  loadingAll,
  livedata,
  SelectEmployeeId,
  profile_trigger,
  crmDashCollapse,
  UserType,
  JWT,
  OpenRazorPay,
  PassInvoiceId,
};
