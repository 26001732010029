import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import UnassignHome from './Unassigned'
import ChatHome from './Chat'
import VoiceHome from './Voice'
import SmsHome from './SMS'
import MessengerHome from './Messenger'
import InstagramHome from './Instagram'
import WhatsappHome from './Whatsapp'
import MainChat from '../../MainChat'
const InboxRoute = () => {
  return (
    <Routes>
      <Route path=':websiteId/*' element={<MainChat />} />
    <Route path="unassigned/*" element={<UnassignHome/>}/>
    <Route path="chat/*" element={<ChatHome/>}/>
    <Route path="voice/*" element={<VoiceHome/>}/>
    <Route path="sms/*" element={<SmsHome/>}/>
    <Route path="messenger/*" element={<MessengerHome/>}/>
    <Route path="instagram/*" element={<InstagramHome/>}/>
    <Route path="whatsapp/*" element={<WhatsappHome/>}/>
    <Route path='*' element={<Navigate to= ":websiteId" />}/>
 </Routes>
  )
}

export default InboxRoute
