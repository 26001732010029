import React,{useState}  from "react";
import WifiIcon from "@material-ui/icons/Wifi";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import * as icons from "@mui/icons-material";
import {
  Menu,
  Typography,
  MenuItem,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,

} from "@mui/material";
import axios from "axios";

import TrackeyEmployeeAddtask from "./TrackeyEmployeeAddtask";
import red from "../../images/trackeyemap/red.png";
import green from "../../images/trackeyemap/green.png";
import BatteryGauge from "react-battery-gauge";
import SignalCellular0BarIcon from "@mui/icons-material/SignalCellular0Bar";
import SignalCellular1BarIcon from "@mui/icons-material/SignalCellular1Bar";
import SignalCellular2BarIcon from "@mui/icons-material/SignalCellular2Bar";
import SignalCellular3BarIcon from "@mui/icons-material/SignalCellular3Bar";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import Tooltip from "@mui/material/Tooltip";

import {
 
  login_token,

  selectedEmp,
 
  latlong,
  intervelTrigger,
  isLoading,
  livedata,
  SelectEmployeeId,
  selectedEmpPosition,
  ZoomLevel
} from "../../Atoms/LoginAtoms";
import { api_url } from "../../globalSettings";
import { useRecoilState } from "recoil";
import "../../styles/trackey.css";
import HistoryIcon from "@mui/icons-material/History";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Button from "@mui/material/Button";

import DialogContentText from "@mui/material/DialogContentText";
import Follow from "./Follow";
import CloseIcon from "@mui/icons-material/Close";
import Popover from "@mui/material/Popover";
import { makeStyles } from "@mui/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { empHistory } from "../trackey/trackeyatoms";


const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    pointerEvents: "auto",
    padding: theme.spacing(1),
  },
}));


function TrackeyEmpListComp(props) {

  


  const [anchore1, setAnchore1] = useState(null);
  const [trackeyEmployeeList, setTrackemplist] = useState({});
  const [opendialog, setOpendialog] = useState(false);
  const open = Boolean(anchore1);
  const [SelectedEmp, setSelectedEmp] = useRecoilState(selectedEmp);
  const [SelectedEmpPosition ,setSelectedEmpPosition ] =  useRecoilState(selectedEmpPosition);
  const [zoomLevel, setZoomLevel] =useRecoilState(ZoomLevel);
  const [empHistory1, setEmpHistory1] = useRecoilState(empHistory);

  const [intervelTrigger1, setintervelTrigger1] = useRecoilState(intervelTrigger);
  const [isLoading1, setisLoading1] = useRecoilState(isLoading);
 

  const handleChange123 = (data) => {
    
    setSelectedEmp(data.data)
    setSelectedEmpPosition({
      lat: parseFloat(data.data.current_location_latitude),
      lng: parseFloat(data.data.current_location_longitude),
      // lat: 10.7905,
      // lng: 78.7047,
    })
    setZoomLevel(20)
  }

  const handleClick = (e) => {
    setAnchore1(e.currentTarget);
  };

  const handleClose = () => {
    setAnchore1(null);
  };

  const handledialogclose = () => {
    setOpendialog(false);
  };


  // ==================== FOLLOW POPUP =======================

  const [open1, setOpen1] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClose1 = () => {
    setOpen1(false);
  
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open2 = Boolean(anchorEl);




  const [error,setError] = React.useState([])
  const [livedata1,setlivedata1] = useRecoilState(livedata)
  const [dbdata,setdbdata] = useState([])
  const [employeeId,setemployeeId] = useRecoilState(SelectEmployeeId)


 

  const followFun = (value) => (e) =>{ 

   
    setemployeeId(value._id)

    setOpen1(true);
    setisLoading1(true);

   


    axios.post(`${api_url}/getlastData`,{empoloyee_id:value._id,attentance_id:SelectedEmp._id}).then((result)=>{

      if(result.data.data.length >=1){

         let lastduty = result.data.data.map((obj)=>({socket_id:'',employee_id:obj.employee_id,emp_attendance_id:obj._id,on_duty_id:obj.latest_onduty_id,latitude:obj.current_location_latitude,longitude:obj.current_location_longitude,time:obj.current_location_time,date:obj.updated,location_name:obj.current_location_name,mobile_battery_strength:obj.mobile_battery_strength,signal_strength:obj.signal_strength,data_network_type:obj.data_network_type}))

         

         setdbdata({data:lastduty[0]})
         setlivedata1(lastduty[0])
        
         setError(false)
         setisLoading1(false)

      }
    }).catch((err)=>{
    
      if(err.response.status === 404){
        setError(true)
        setisLoading1(false)
      }

    })

  }


  return (

       <>
           <div
      className="trackey-emp-list-comp"
      onClick={() => {
        handleChange123({data : props.item});
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
      <Stack direction="row" width={100} alignItems="center" spacing={1}>
          <img
            src={props.item.duty_status == "on_duty" ? green : red}
            height={10}
            width={10}
          />
         <Typography style={{ fontSize: 10 }} variant="body2">
            {`${props.item.employee_id.first_name} ${props.item.employee_id.last_name}`} 
           
          </Typography> 
        </Stack>
            <Stack
          direction={"row"}
          alignItems="center"
          width={120}
          justifyContent="space-between"
        >
          <Tooltip title={props.item.data_network_type}>
            <Stack width={50} direction="row" justifyContent="center">
              {props.item.data_network_type == "wifi" ? (
                <WifiIcon fontSize="small" />
              ) : (
                <WifiOffIcon fontSize="small" />
              )}
            </Stack>
          </Tooltip>
          <Tooltip title={props.item.signal_strength}>
            <Stack width={50} direction="row" justifyContent="center">
              {props.item.signal_strength >= "80" ? (
                <SignalCellular4BarIcon fontSize="small" color="success" />
              ) : props.item.signal_strength >= "60" ? (
                <SignalCellular3BarIcon fontSize="small" color="success" />
              ) : props.item.signal_strength >= "40" ? (
                <SignalCellular2BarIcon color="success" fontSize="small" />
              ) : props.item.signal_strength >= "20" ? (
                <SignalCellular1BarIcon color="success" fontSize="small" />
              ) : (
                <SignalCellular0BarIcon />
              )}
            
            </Stack>
          </Tooltip>
          <Tooltip title={`${props.item.mobile_battery_strength}%`}>
            <Stack width={50} direction="row" justifyContent="center">
              <BatteryGauge
                size={16}
                orientation="vertical"
                value={props.item.mobile_battery_strength}
              />
            </Stack>
          </Tooltip>
          <IconButton
            //aria-controls={open ? emp_id : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            // id={emp_id + company_id}
            style={{ padding: 0, margin: 10, marginLeft: 0 }}
          >
            <icons.MoreVert fontSize="small" />
          </IconButton>
              <TrackeyEmployeeAddtask
            trackeyEmployeeList={trackeyEmployeeList}
            handleClose={handledialogclose}
            opendialog={opendialog}
          />

          <Menu
            open={open}
            anchorEl={anchore1}
            //id={emp_id}
            onClose={handleClose}
            // MenuListProps={{
            //   "aria-labelledby": emp_id + company_id,
            // }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <div
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <MenuItem className="tmenu">
                  <HistoryIcon fontSize="small" style={{ paddingRight: 5 }} />
                  Show History
                  <ArrowForwardIosIcon
                    fontSize="small"
                    style={{ fontSize: 10, marginLeft: 5 }}
                  />
                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={open2}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    style={{
                      marginLeft: "5px",
                      marginTop: "-7px",
                      Width: "150px",
                    }}
                  >
                    <MenuItem
                      className="tmenu"
                      onClick={() => setEmpHistory1("last_hour")}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ fontSize: 10, marginRight: 5 }}
                      />
                      Last Hours
                    </MenuItem>
                    <MenuItem
                      className="tmenu"
                      onClick={() => setEmpHistory1("Today")}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ fontSize: 10, marginRight: 5 }}
                      />
                      Today
                    </MenuItem>
                    <MenuItem
                      className="tmenu"
                      onClick={() => setEmpHistory1("Yesterday")}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ fontSize: 10, marginRight: 5 }}
                      />
                      Yesterday
                    </MenuItem>
                    <MenuItem
                      className="tmenu"
                      onClick={() => setEmpHistory1("This_Week")}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ fontSize: 10, marginRight: 5 }}
                      />
                      This Week
                    </MenuItem>
                    <MenuItem
                      className="tmenu"
                      onClick={() => setEmpHistory1("Last_Week")}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ fontSize: 10, marginRight: 5 }}
                      />
                      Last Week
                    </MenuItem>
                    <MenuItem
                      className="tmenu"
                      onClick={() => setEmpHistory1("This_Month")}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ fontSize: 10, marginRight: 5 }}
                      />
                      This Month
                    </MenuItem>
                    <MenuItem
                      className="tmenu"
                      onClick={() => setEmpHistory1("Last_Month")}
                    >
                      <ArrowForwardIosIcon
                        fontSize="small"
                        style={{ fontSize: 10, marginRight: 5 }}
                      />
                      Last Month
                    </MenuItem>
                  </Popover>
                </MenuItem>
              </div>
           
              <MenuItem
                className="tmenu"
                onClick={followFun(props.item.employee_id)}
                
              >
                <RemoveRedEyeIcon
                  fontSize="small"
                  style={{ paddingRight: 5 }}
                />
                Follow
              </MenuItem>
              <Dialog
                open={open1}
                onClose={handleClose1}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle
                  id="scroll-dialog-title"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  Follow
                  <Button onClick={handleClose1}>
                    <CloseIcon />
                  </Button>
                </DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                 <Follow livedata={livedata1} error={error} employeeid={employeeId} dbdata={dbdata}/>
                  </DialogContentText>
                </DialogContent>
              </Dialog>
              {/* <MenuItem
                className="tmenu"
                onClick={()=>{setOpen3(true)}}
              >
                <AddTaskIcon fontSize="small" style={{ paddingRight: 5 }} />
                Add POI
              </MenuItem>
              <Dialog
            open={open3}
            onClose={handleClose3}
            scroll={scroll3}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle
              id="scroll-dialog-title"
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              ADD POI{" "}
              <Button onClick={handleClose3}>
                <CloseIcon />
              </Button>
            </DialogTitle>
            <DialogContent dividers={scroll === "paper"}>
              <DialogContentText
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                <Addpoi handleClose={handleClose3} 
                //SelectedEmp={k} 
                />
              </DialogContentText>
            </DialogContent>
          </Dialog> */}
              <MenuItem
                className="tmenu"
                onClick={() => {
                  setTrackemplist(props.item);
                  setOpendialog(true);
                }}
              >
                <AddTaskIcon fontSize="small" style={{ paddingRight: 5 }} />
                Add task
              </MenuItem>
            </div>
          </Menu>
        </Stack>

        </Stack>
        </div>
           
    </>
  );
}
export default TrackeyEmpListComp;
