import * as React from 'react';
import Popover from '@mui/material/Popover';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import { Avatar, Divider, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import variables from "../../../assets/styles/_colors.scss";

const useStyles = makeStyles((theme) => ({
    productActions: {
        borderRadius: "4px !important",
        border: `1px solid ${variables.popoverBorderColor}`,
        boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.2) !important"
    }
}));

export default function AssociatedProductsActionPopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const productActions = [
        {
            name: "Modify Fields",
            icon: <EditIcon />,
            key: 'modifyFields'
        },
        {
            name: "Remove Section",
            icon: <RemoveCircleOutlineIcon />,
            key: "removeSection"
        }
    ];

    const handleItemClick = (index, item) => {
        handleClose();
        if (index !== null || index !== undefined) {
            switch (item.key) {
                case "modifyFields":
                    props.modifyTableFields();
                    return true;
                case "removeSection":
                    props.removeProductsSection();
                    return true;
                default:
                    return <></>
            }
        }
    }

    const classes = useStyles();
    const open = Boolean(anchorEl);
    const id = open ? 'associated-products' : undefined;

    return (
        <React.Fragment>
            <IconButton aria-label="associated-products" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Popover
                elevation={1}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.productActions }}
            >

                {productActions.map((item, index) => (
                    <>
                        {item !== null
                            ? <React.Fragment key={index}>
                                < ListItemButton
                                    key={item?.name}
                                    sx={{ pt: 0.5, pb: 0.5, pl: 0.5, pr: 1.5 }}
                                    onClick={() => handleItemClick(index, item)}
                                >
                                    <ListItemAvatar sx={{ minWidth: 46 }}>
                                        <Avatar sx={{ bgcolor: "inherit", color: "#000000de" }}>
                                            {item?.icon}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item?.name}
                                    />
                                </ListItemButton >
                                <Divider sx={{ borderBottomWidth: 3 }} />
                            </React.Fragment>
                            : <></>
                        }
                    </>
                ))}
            </Popover>
        </React.Fragment>
    );
}