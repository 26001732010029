import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { empHistory } from "../trackeyatoms";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import axios from "axios";
import { api_url } from "../../../globalSettings";
import { login_token, selectedEmp } from "../../../Atoms/LoginAtoms";
import { useRecoilState } from "recoil";
import HistoryMap from "./HistoryMap";

import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";


function History() {
  const [task, settask] = React.useState([]);
  const [empHistory1, setEmpHistory1] = useRecoilState(empHistory);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);
  const [SelectedEmp, setSelectedEmp] = useRecoilState(selectedEmp);


  async function callAPI(ondutyId) {
    
    return await axios
      .get(
        "https://server.nowdigitaleasy.com:3505/employee/get_task_by_onduty",
        {
          params: {
            token: loginToken1,
            on_duty_id: ondutyId,
          },
        }
      )
      .then((response) => response.data.data);
  }

  async function main(data) {
    let responseArray = await Promise.all(
      data.map(async (i) => {
        return { i, activities: await callAPI(i._id) };
      })
    );

    settask(responseArray);
  }

  // =================================== Last Hour api ======================

    function lastHour() {


    var today = new Date();
    var hourago = new Date(today.getTime() - 1000 * 60 * 60);

 

    var hours = Number(hourago.toLocaleTimeString().match(/^(\d+)/)[1]);
    var endhours = Number(today.toLocaleTimeString().match(/^(\d+)/)[1]);
    var minutes = Number(hourago.toLocaleTimeString().match(/:(\d+)/)[1]);
    var endminutes = Number(today.toLocaleTimeString().match(/:(\d+)/)[1]);
    var AMPM = hourago.toLocaleTimeString().match(/\s(.*)$/)[1];

    if (AMPM == "PM" && hours < 12) hours = hours + 12;
    if (AMPM == "PM" && endhours < 12) endhours = endhours + 12;
    if (AMPM == "AM" && hours == 12) hours = hours - 12;
    if (AMPM == "AM" && endhours == 12) endhours = endhours - 12;
    var sHours = hours.toString();
    var eHours = endhours.toString();
    var sMinutes = minutes.toString();
    var eMinutes = endminutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (endhours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    if (endminutes < 10) eMinutes = "0" + eMinutes;
    const lastHourStart = `${sHours}:${sMinutes}`;

    const lastHourend = `${eHours}:${eMinutes}`;
    const params = {
      token: loginToken1,
      employee_id: SelectedEmp.employee_id._id,
      from_date: lastHourStart,
      to_date: lastHourend,
    };
    axios
      .get(`${api_url}/employee/filter_employee_history_by_last_hr`, {
        params: { ...params },
      })

      .then((response) => {
        main(response.data.data);

      })
      .catch(function (error) {
        if (error.response) {
        
        } else if (error.request) {
         
        } else {
      
        }
      
      });
  }



 // ===================================== TODAY api =========================
  
  function today() {

    var today = new Date().toLocaleDateString();
    const [month, day, year] = today.split("/");
    const daynew = `${day}`.padStart(2, "0");
    const monthnew = `${month}`.padStart(2, "0");
    var to_day = `${daynew}-${monthnew}-${year}`;
   
    const params = {
      token: loginToken1,
      employee_id: SelectedEmp.employee_id._id,
      from_date: to_day,
      to_date: to_day,
    };
    axios
      .get(`${api_url}/employee/filter_employee_history_by_date`, {
        params: { ...params },
      })

      .then((response) => {
      
        main(response.data.data);
      
      })
      .catch(function (error) {
        if (error.response) {
        
        } else if (error.request) {
      
        } else {
    
        }
    
      });
  }

// ===================================== YESTERDAY API =========================
  
function yesterday() {
  var today = new Date();
  var yesterday = new Date(today.getTime() - 1000 * 60 * 60 * 24).toLocaleDateString();
  const [month, day, year] = yesterday.split("/");
  const daynew = `${day}`.padStart(2, "0");
  const monthnew = `${month}`.padStart(2, "0");
  var yester_day = `${daynew}-${monthnew}-${year}`;


  const params = {
    token: loginToken1,
    employee_id: SelectedEmp.employee_id._id,
    from_date: yester_day,
    to_date: yester_day,
  };
  axios
    .get(`${api_url}/employee/filter_employee_history_by_date`, {
      params: { ...params },
    })

    .then((response) => {
      main(response.data.data);
     
    })
    .catch(function (error) {
      if (error.response) {
   
      } else if (error.request) {
      
      } else {
      
      }
    
    });
}


// ===================================== THIS WEEK API =========================
  
function thisWeek() {


  //this week

  var curr = new Date; // get current date
  var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6
  
  var firstDay = new Date(curr.setDate(first)).toLocaleDateString();
  var lastDay = new Date(curr.setDate(last)).toLocaleDateString();
  const [month, day, year] = firstDay.split("/");
  const daynew = `${day}`.padStart(2, "0");
  const monthnew = `${month}`.padStart(2, "0");
  var first_day = `${daynew}-${monthnew}-${year}`;
  const [month1, day1, year1] = lastDay.split("/");
  const daynew1 = `${day1}`.padStart(2, "0");
  const monthnew1 = `${month1}`.padStart(2, "0");
  var last_day = `${daynew1}-${monthnew1}-${year1}`;


  const params = {
    token: loginToken1,
    employee_id: SelectedEmp.employee_id._id,
    from_date: first_day,
    to_date: last_day,
  };
  axios
    .get(`${api_url}/employee/filter_employee_history_by_date`, {
      params: { ...params },
    })

    .then((response) => {
      main(response.data.data);
 
    })
    .catch(function (error) {
      if (error.response) {
      
      } else if (error.request) {
     
      } else {
   
      }
  
    });
}


// ===================================== LAST WEEK API =========================
  
function lastWeek() {


  //last week
  var beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
var beforeOneWeek2 = new Date(beforeOneWeek);
var  day0 = beforeOneWeek.getDay()
var  diffToMonday = beforeOneWeek.getDate() - day0 + (day0 === 0 ? -6 : 1)
var  lastMonday = new Date(beforeOneWeek.setDate(diffToMonday)).toLocaleDateString();
var  lastSunday = new Date(beforeOneWeek2.setDate(diffToMonday + 6)).toLocaleDateString();

  const [month, day, year] = lastMonday.split("/");
  const daynew = `${day}`.padStart(2, "0");
  const monthnew = `${month}`.padStart(2, "0");
  var first_day = `${daynew}-${monthnew}-${year}`;
  const [month1, day1, year1] = lastSunday.split("/");
  const daynew1 = `${day1}`.padStart(2, "0");
  const monthnew1 = `${month1}`.padStart(2, "0");
  var last_day = `${daynew1}-${monthnew1}-${year1}`;

  const params = {
    token: loginToken1,
    employee_id: SelectedEmp.employee_id._id,
    from_date: first_day,
    to_date: last_day,
  };
  axios
    .get(`${api_url}/employee/filter_employee_history_by_date`, {
      params: { ...params },
    })

    .then((response) => {
   
      main(response.data.data);
 
    })
    .catch(function (error) {
      if (error.response) {
       
      } else if (error.request) {
    
      } else {
        
      }
    
    });
}


  // ===================================== this month api =========================
  
  function thisMonth() {


    //this month

     var date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString()
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString()
    

    const [month, day, year] = firstDay.split("/");
    const daynew = `${day}`.padStart(2, "0");
    const monthnew = `${month}`.padStart(2, "0");
    var first_day = `${daynew}-${monthnew}-${year}`;
    const [month1, day1, year1] = lastDay.split("/");
    const daynew1 = `${day1}`.padStart(2, "0");
    const monthnew1 = `${month1}`.padStart(2, "0");
    var last_day = `${daynew1}-${monthnew1}-${year1}`;


    const params = {
      token: loginToken1,
      employee_id: SelectedEmp.employee_id._id,
      from_date: first_day,
      to_date: last_day,
    };
    axios
      .get(`${api_url}/employee/filter_employee_history_by_date`, {
        params: { ...params },
      })

      .then((response) => {
        main(response.data.data);
     
      })
      .catch(function (error) {
        if (error.response) {
       
        } else if (error.request) {
        
        } else {
      
        }
      
      });
  }

   // ===================================== last month api =========================

  function lastMonth(){
    var date = new Date();
    var firstDay = new Date(
      date.getFullYear(),
      date.getMonth() - 1,
      1
    ).toLocaleDateString();
    var lastDay = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).toLocaleDateString();
    const [month, day, year] = firstDay.split("/");
    const daynew = `${day}`.padStart(2, "0");
    const monthnew = `${month}`.padStart(2, "0");
    var first_day = `${daynew}-${monthnew}-${year}`;
    const [month1, day1, year1] = lastDay.split("/");
    const daynew1 = `${day1}`.padStart(2, "0");
    const monthnew1 = `${month1}`.padStart(2, "0");
    var last_day = `${daynew1}-${monthnew1}-${year1}`;

    axios
      .get(
        "https://server.nowdigitaleasy.com:3505/employee/filter_employee_history_by_date",
        {
          params: {
            token: loginToken1,
            employee_id: SelectedEmp.employee_id._id,
            from_date: first_day,
            to_date: last_day,
          },
        }
      )
      .then((result) => {
        main(result.data.data);
      })
      .catch((err) => {
      });

  }


  React.useEffect(() => {
    if (empHistory1 === "last_hour") {
      lastHour();
    } else if (empHistory1 === "Today") {
     today();
    } else if (empHistory1 === "Yesterday") {
     yesterday();
    } else if (empHistory1 === "This_Week") {
      thisWeek();
    } else if (empHistory1 === "Last_Week") {
      lastWeek();
    } else if (empHistory1 === "This_Month") {
      thisMonth();
    } else if (empHistory1 === "Last_Month") {
      lastMonth();
    }
  }, []);


  //=============================== Onduty Sockect ============================
  const [ondutySocket, setOndutySocket] = useState([]);
  const [selectedOndtyEmp, setSelectedOndtyEmp] = useState([]);

  function ondutySockect() {
    setOndutySocket("");
    const params = {
      token: loginToken1,
      on_duty_id: selectedOndtyEmp._id,
    };

    axios
      .get(`${api_url}/employee/getlatitude_longitude/${params.on_duty_id}`)

      .then((response) => {
        setOndutySocket(response.data.data);
        
      })
      .catch(function (error) {
        if (error.response) {
         
        } else if (error.request) {
        
        } else {
         
        }
       
      });
  }

  React.useEffect(() => {
    ondutySockect();
  }, [selectedOndtyEmp._id]);

  return (
    <div id="history">
      <div className="history_left_Container">
        <div className="history_emp_name">
          <Typography variant="body2">
            {SelectedEmp.employee_id.first_name}{" "}
            {SelectedEmp.employee_id.last_name}
          </Typography>
          <Button onClick={() => setEmpHistory1("")}>
            <DisabledByDefaultIcon />
          </Button>
        </div>
        <Box
          sx={{
            // paddingLeft: "100px",
            paddingRight: "50px",
            paddingTop: "20px",
            bgcolor: "#F5F5F5",
            height: "100vh",
          }}
        >
         

          <Box
            sx={{
              width: "500px",
              height: "500px",
              borderRadius: "4px",
              bgcolor: "white",
              boxShadow: "1px 2px 4px rgba(0,0,0, 0.3)",
              overflowY: "scroll",
            }}
          >
            {task.length >= 1 ? (
              <Grid container spacing={2}>
                {task.map((item) => (
                  <Grid item xs={12}>
                    <Box sx={{ bgcolor: "#e3e1e1" }}>
                      <Box
                        sx={{
                          padding: "12px",
                          paddingBottom: "0px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSelectedOndtyEmp(item.i);
                          ondutySockect();
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <LocationOnIcon sx={{ color: "green" }} />
                            <Typography className="historytypo">
                              {item.i.on_duty_date} {new Date(`2000-01-01T${item.i.on_duty_time}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                              {/* {new Date(item.i.on_duty_time).toLocaleTimeString()} */}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{ color: "green" }}
                            className="historytypo"
                          >
                            "On Duty"
                          </Typography>
                        </Box>
                        <div
                          style={{
                            marginLeft: "10.6px",
                            borderLeft: "1px solid #00000040",
                            height: "13px",
                            marginTop: "-3px",
                          }}
                        ></div>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          bgcolor: item.activities.map((k) =>
                            Object.keys(k).length >= 1 ? "white" : "#e3e1e1"
                          ),
                        }}
                      >
                        <Box
                          sx={{
                            borderTop: item.activities.map((k) =>
                              Object.keys(k).length >= 1
                                ? "1px solid #00000040"
                                : ""
                            ),
                            height: "auto",
                            marginLeft: item.activities.map((k) =>
                              Object.keys(k).length >= 1 ? "10.6px" : ""
                            ),
                            width: item.activities.map((k) =>
                              Object.keys(k).length >= 1 ? "30px" : "24px"
                            ),
                            borderBottom: item.activities.map((k) =>
                              Object.keys(k).length >= 1
                                ? "1px solid #00000040"
                                : ""
                            ),
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        ></Box>

                        {item.activities.length >= 1 ? (
                          <Box
                            sx={{
                              bgcolor: "#e3e1e1",
                              width: "-webkit-fill-available",
                            }}
                          >
                            {item.activities.map((k) => (
                              <Box>
                                {Object.keys(k).length >= 1 ? (
                                  <Box
                                    sx={{
                                      borderLeft: "1px solid #00000040",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <RadioButtonCheckedIcon
                                      sx={{
                                        width: "16px",
                                        marginLeft: "-13px",
                                        zIndex: "2",
                                        color: k.task_status == "completed" ? "green" : "red",
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        height: "40px",
                                        marginLeft: "10.6px",
                                        width: "-webkit-fill-available",
                                        borderBottom: "1px solid #00000040",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        bgcolor: "#e3e1e1",
                                        paddingRight: "10px",
                                      }}
                                    >
                                      <Typography className="historytypo">
                                        {new Date(
                                          k.created
                                        ).toLocaleDateString()}{" "}
                                        {new Date(
                                          k.created
                                        ).toLocaleTimeString()}

                                      </Typography>{" "}
                                      <Typography className="historytypo">
                                        {k.task_name}
                                      </Typography>
                                    </Box>
                                  </Box>
                                ) : (
                                  ""
                                )}
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              bgcolor: "#e3e1e1",
                              width: "-webkit-fill-available",
                            }}
                          >
                            <Box>
                              <Box
                                sx={{
                                  borderLeft: "1px solid #00000040",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  marginLeft: "10.6px",
                                }}
                              >
                                <Box
                                  sx={{
                                    borderTop: "1px solid #00000040",
                                    width: "20px",
                                  }}
                                ></Box>
                                <Box
                                  sx={{
                                    height: "40px",
                                    marginLeft: "10.6px",
                                    width: "-webkit-fill-available",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "#e3e1e1",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      borderTop: "1px solid #00000040",
                                      width: "-webkit-fill-available",
                                    }}
                                  ></Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>

                      <Box sx={{ paddingBottom: "12px" }}>
                        <Box
                          sx={{
                            borderLeft: "1px solid #00000040",
                            height: "13px",
                            marginLeft: "10.6px",
                          }}
                        ></Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingLeft: "12px",
                            paddingRight: "12px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <LocationOnIcon
                              sx={{ marginTop: "-3px", color: "red" }}
                            />
                            <Typography className="historytypo">
                              {item.i.off_duty_date}  {new Date(`2000-01-01T${item.i.off_duty_time}`).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
                               {/* {item.i.off_duty_time} */}
                            </Typography>
                          </Box>
                          <Typography sx={{ color: "red" }}>
                            "Off Duty"
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </div>
      <div className="history_right_Container">
        {" "}
        <HistoryMap
          ondutySocket={ondutySocket}
          selectedOndtyEmp={selectedOndtyEmp}
        />
      </div>
    </div>
  );
}

export default History;
