import React, { useState } from "react";
import { useFormik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import { useRecoilState } from "recoil";

import { IconButton, Button, Modal, Grid, Stack, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { api_url1 } from "../../../../globalSettings";
import { actionstatus } from "../../../../Atoms/warningpopup";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "calc(100vh - 85px)",
  bgcolor: "white",
  boxShadow: 24,
  overflow: "hidden",
  overflowY: "scroll",
};
const NameServer = (props) => {
  const [load, setLoad] = useState(false);
  const [actionstatus1, setactionstatus1] = useRecoilState(actionstatus);

  const formik = useFormik({
    initialValues: {
      nameServer: [...props.myNameServer],
    },
    // validationSchema: Yup.object().shape({
    //   nameServer: Yup.array().of(
    //     Yup.string()
    //       .required("Name Server is required")
    //       .test(
    //         "is-required",
    //         "Name Server is required",
    //         (value) => value.trim() !== ""
    //       )
    //   ),
    // }),
    onSubmit: (values) => {
      const cleanedNameServers = values.nameServer.filter(
        (ns) => ns.trim() !== ""
      );
      let obj = {
        ns: cleanedNameServers,
        order_id: props.order_id,
      };
      setLoad(true);

      axios
        .post(`${api_url1}/resellers/modifyNameServers`, obj)
        .then((result) => {
          fetchactions(props.order_id);
          props.setSnackbarInfo({
            open: true,
            message: `${result.data.actionstatusdesc}`,
            severity: "success",
          });
          props.setapipreloader(!props.apipreloader);
          props.nameserverdialogonclose();
        })
        .catch((err) => {
          props.setSnackbarInfo({
            open: true,
            message: err.response.data.message,
            severity: "error",
          });
        })
        .finally(() => {
          setLoad(false);
        });
    },
  });

  const addNameServer = () => {
    formik.setFieldValue("nameServer", [...formik.values.nameServer, " "]);
  };

  const deletenameserver = (ns1) => (e) => {
    const updatedNameServers = formik.values.nameServer.filter(
      (ns) => ns !== ns1
    );

    formik.setValues({
      ...formik.values,
      nameServer: updatedNameServers,
    });
  };

  function fetchactions(orderid) {
    axios
      .get(
        `${api_url1}/resellers/getactions?orderid=${orderid}&no_of_records=10&page_no=1&action_status=&action_type=`
      )
      .then((result) => {
        if (result) {
          const filteredData = result.data.filter(
            (item) =>
              item.actionstatus !== "Success" && item.actionstatus !== "Failed"
          );

          setactionstatus1(filteredData);
        }
      })
      .catch((err) => {});
  }

  return (
    <>
      <div>
        <div>
          <Modal
            open={props.nameserversdialog}
            onClose={props.nameserverdialogonclose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className="scroll">
              <div className="addcompanytitle" style={{ height: 60 }}>
                Name Server
                <IconButton
                  component="span"
                  onClick={props.nameserverdialogonclose}
                  style={{ padding: 0, marginRight: 20 }}
                >
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    maxWidth: "100%",

                    fontSize: "12px",
                    fontWeight: 600,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Grid container spacing={1} style={{ padding: 10 }}>
                        <>
                          {formik.values.nameServer.map((ns, index) => (
                            <Grid
                              item
                              md={12}
                              sm={12}
                              xs={12}
                              alignItems="center"
                              justifyContent=" center"
                            >
                              <input
                                className="nameServe-inputField"
                                name={`nameServer[${index}]`}
                                onChange={(e) => {
                                  const updatedNameServers = [
                                    ...formik.values.nameServer,
                                  ];
                                  updatedNameServers[index] = e.target.value;
                                  formik.setFieldValue(
                                    "nameServer",
                                    updatedNameServers
                                  );
                                }}
                                value={ns || ""}
                                error={
                                  formik.touched.nameServer &&
                                  formik.touched.nameServer[index] &&
                                  formik.errors.nameServer &&
                                  formik.errors.nameServer[index]
                                }
                                helperText={
                                  formik.touched.nameServer &&
                                  formik.touched.nameServer[index] &&
                                  formik.errors.nameServer &&
                                  formik.errors.nameServer[index]
                                }
                              />
                              <IconButton onClick={deletenameserver(ns)}>
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          ))}
                        </>
                        <button
                          className="nameServer-AddButton"
                          type="button"
                          onClick={() => {
                            addNameServer();
                          }}
                        >
                          + Add NameServer
                        </button>
                        <div
                          className="addcompanybtn"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Stack direction={"row"} spacing={2}>
                            <LoadingButton
                              loading={load}
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={formik.handleSubmit}
                            >
                              Update
                            </LoadingButton>
                            <Button
                              onClick={props.nameserverdialogonclose}
                              variant="contained"
                              color="primary"
                            >
                              Cancel
                            </Button>
                          </Stack>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        backgroundColor: "#e0e0e0",
                        padding: "20px",
                        zIndex: "-1",
                      }}
                    >
                      Use the DNS service to point this domain to a hosting or
                      email package.
                    </Grid>
                  </Grid>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default NameServer;
