import { Grid, Tab, Tabs } from "@mui/material";
import variables from "../../../assets/styles/_colors.scss";
import { withStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { editFieldsData } from "../../../utils/createpipelineformdata";
import validationSchema from "../../pipelines/create/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import SubPipelines from "./subpipelines/SubPipelines";
import CrmSettings from "../CrmSettings";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getAllStages } from "../../../webservices/PipelineService";
import { useQuery } from "@tanstack/react-query";
import StageTransitionRules from "./StageTransitionRules";

const StyledTab = withStyles({
    root: {
        textTransform: 'none !important',
        fontSize: '14px !important'
    }
})(Tab);

export default function StagesSetting(props) {

    const [selectedTab, setSelectedTab] = useState("subpipelines");
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        setSelectedTab(params.module);
    }, []);

    const methods = useForm(
        {
            defaultValues: props.defaultValues ? props.defaultValues : editFieldsData,
            shouldUnregister: false,
            resolver: yupResolver(validationSchema[1]),
            reValidateMode: "onChange",
            mode: "onChange"
        }
    );

    const {
        data: allStages
    } = useQuery({
        queryKey: ['getAllStages'],
        queryFn: () => getAllStages(),
        refetchOnWindowFocus: false
    });

    return (
        <>
            <Grid container direction="row">
                <CrmSettings selectedMenu="stages" selectedTab={selectedTab} />
                <Grid item xs={10}>
                    <Grid container direction="column" sx={{ justifyContent: "center", height: "46px" }}>
                        <Grid item width="40%">
                            <Tabs value={selectedTab} onChange={(e, val) => { setSelectedTab(val); navigate(`/crm/settings/stages/${val}`) }}>
                                <StyledTab label="Sub-Pipelines" value="subpipelines" />
                                <StyledTab label="Stage Transition Rules" value="stagetransition" />
                            </Tabs>
                        </Grid>
                        <Grid item width="60%" sx={{ pr: 1 }}>
                            {/* header right section */}
                        </Grid>
                    </Grid >
                    <Grid container direction="column" sx={{ backgroundColor: `${variables.tabsBackgroundColor}`, pl: 2, pb: 0, pr: 2, pt: 1.25 }}>
                        <Grid container direction="row" height="inherit">
                            {
                                <>
                                    {selectedTab === "subpipelines" &&

                                        <SubPipelines module={module} />

                                    }

                                    {selectedTab === "stagetransition" &&

                                        <StageTransitionRules />

                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}