import atoms, { atom } from 'recoil'

export const company_idd = atom({
    key: 'company_idd',
    default : ''
});

export const snackbarr = atom({
    key : 'snackbarr',
    default: {open : false, message : '',seveority : ''}
})