import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { CloseButton } from '../../../../components/CloseButton';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import { Stack } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllRoles } from '../../../../webservices/UserControlService';
import { useQuery } from '@tanstack/react-query';
import CustomSnackbar from '../../../../components/CustomSnackbar';

export default function RoleTree(props) {

    const [open, setOpen] = React.useState(false);
    const [snackbarInfo, setSnackbarInfo] = useState({
        open: false,
        severity: '',
        message: ""
    });

    const {
        data,
        isSuccess,
        isFetched,
        isError,
        error
    } = useQuery({
        queryKey: ['getAllRolesTree'],
        queryFn: () => getAllRoles(),
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        setOpen(true);
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            setOpen(false);
            setSnackbarInfo({
                open: true,
                severity: 'error',
                message: error?.response?.data?.error?.message
            });
        }
    }, [isError]);

    const handleClose = (obj) => {
        setOpen(false);
        props.handleClose(obj);
    }

    const renderTree = (node) => (
        node && <TreeItem sx={{ ".MuiTreeItem-content": { height: 36 } }} key={node._id} nodeId={node._id}
            label={<CustomTreeItem item={node} selectedNode={(item) => handleClose(item)}
            />} disabled={props.nodeToDisable?._id === node._id}>
            {Array.isArray(node.children)
                ? node.children?.map((node) => renderTree(node))
                : null}
        </TreeItem>
    );

    const handleSnackbarClose = () => {
        setSnackbarInfo((prevInfo) => ({
            ...prevInfo,
            open: false,
        }));
    };

    return (
        <>
            <Modal open={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    p: "16px 24px",
                    width: '33%',
                    outline: 'none'
                }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>Choose a Role
                        <CloseButton handleDialogClose={() => handleClose()} />
                    </Typography>

                    <Typography component="div" variant="body" sx={{ pt: 2, pb: 2 }}>
                        To select a role, please click on it's name
                    </Typography>

                    <Box sx={{ minHeight: '30vh', maxHeight: '60vh', flexGrow: 1, maxWidth: '60vw' }}>
                        {data &&
                            <TreeView
                                defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
                                defaultExpanded={data?.ids?.map(a => a._id)}
                                defaultExpandIcon={<AddBoxOutlinedIcon />}
                            >
                                {renderTree(data?.data?.[0])}
                            </TreeView>
                        }
                    </Box>
                </Box>
            </Modal>

            <CustomSnackbar snackbarInfo={snackbarInfo} handleSnackbarClose={handleSnackbarClose} />
        </>
    );
}

const CustomTreeItem = (props) => {

    const handleView = (e, item) => {
        e.stopPropagation();
        e.preventDefault();
        props.selectedNode(item);
    }

    return (
        <>
            <Box>
                <Stack direction="row" alignItems="center" width="auto" onClick={(e) => handleView(e, props.item)}>
                    <Typography sx={{ flexGrow: 1 }}>
                        {props.item?.roleName}
                    </Typography>
                </Stack>
            </Box>
        </>
    )
}