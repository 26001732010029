import React, { useEffect, useState } from "react";
import tacometer from "../../components/dashboard/img/tachometer.png";
import customer from "../../components/dashboard/img/customer.png";
import { useLocation, useNavigate } from "react-router-dom/dist";

const SettingsSideBar = () => {
  const location = useLocation();
  const history = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const isActive = (path) => location.pathname === path;

  const handleClick = (path) => {
    if (!isActive(path)) {
      history(path);
    }
  };
  return (
    <div className="secondarySidebar">
      <h6>Settings</h6>
      <div className="webServicesTab">
        <ul>
          <li>
            <div
              className={`link ${
                isActive("/mySettings") || isActive("/contacts") ? "active" : ""
              }`}
              onClick={() => handleClick("/mySettings")}
              //onClick={() => history("/trackeye/TrackeyeDashboard")}
              // activeClassName="active-link"
            >
              <div className="icon">
                <img
                  src={tacometer}
                  alt="dashboard"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Organization</span>
            </div>
          </li>
          <li>
            <div
              className={`link ${isActive("/123") ? "active" : ""}`}
              onClick={() => handleClick("/123")}
              // onClick={() => history("/trackeye")}
              // className="link"
              // activeClassName="active-link"
            >
              <div className="icon">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Employee</span>
            </div>
          </li>
          <li>
            <div
              className={`link  ${
                isActive("/contacts/contact") ? "active" : ""
              }`}
              onClick={() => handleClick("/contacts/contact")}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>CRM Settings</span>
            </div>
          </li>
          <li>
            <div
              className={`link  ${
                isActive("/contacts/crmtask") ? "active" : ""
              }`}
              onClick={() => handleClick("/contacts/crmtask")}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Web Forms</span>
            </div>
          </li>
          <li>
            <div
              className={`link  ${
                isActive("/contacts/crmtask") ? "active" : ""
              }`}
              onClick={() => handleClick("/contacts/crmtask")}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Email Accounts</span>
            </div>
          </li>

          <li>
            <div
              className={`link  ${
                isActive("/mySettings/chat") ? "active" : ""
              }`}
              onClick={() => handleClick("/mySettings/chat")}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Live Chat</span>
            </div>
          </li>

          <li>
            <div
              className={`link  ${
                isActive("/contacts/crmtask") ? "active" : ""
              }`}
              onClick={() => handleClick("/contacts/crmtask")}
            >
              <div className="icon ">
                <img
                  src={customer}
                  alt="web services"
                  style={{ paddingLeft: 10 }}
                />
              </div>
              <span>Schedules</span>
            </div>
          </li>
          {/* <li >
          <div
            onClick={handleToggleDropdown} 
            className={`link  report-dropdown-container ${showDropdown == true || isActive('/123') ? 'active' : ''}`}
            activeClassName="active-link"
          >
            <div className="icon">
              <img
                src={customer}
                alt="web services"
                style={{ paddingLeft: 10 }}
              />
            </div>
            <span>Pipelines</span>
          </div>
          {showDropdown && (
      <div className="report-dropdown">
        <ul>
          <li>
          <div
          className={`link  ${isActive('/1234') ? 'active' : ''}`}
          onClick={() => handleClick('/1234')}
          >
            <div className="icon ">
              <img
                src={customer}
                alt="web services"
                style={{ paddingLeft: 10 }}
              />
            </div>
            <span>Sales Pipeline</span>
          </div>
          </li>
          <li>
          <div
          className={`link  ${isActive('/12345') ? 'active' : ''}`}
          onClick={() => handleClick('/12345')}
          >
            <div className="icon ">
              <img
                src={customer}
                alt="web services"
                style={{ paddingLeft: 10 }}
              />
            </div>
            <span>Support Pipeline</span>
          </div>
          </li>
        </ul>
      </div>
    )}
        </li> */}
        </ul>
      </div>
    </div>
  );
};

export default SettingsSideBar;
