import React, { useEffect, useState } from "react";
import axios from "axios";
import { api_url } from "../../../globalSettings";
import {
 
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Button,
  IconButton,
 
  Stack,
  TextField,
  Avatar,
  
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { selectedcompany } from "../managecompanyatom";

import Modal from "@mui/material/Modal";
import CloseIcon from "@material-ui/icons/Close";

import { useRecoilState } from "recoil";

import {
  login_atoms,
  login_token,

  triger_branch,
} from "../../../Atoms/LoginAtoms";
import Autocomplete from "@mui/material/Autocomplete";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import validator from "validator";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(5),
  },
  "& .MuiNativeSelect-icon": {
    color: "#000",
    paddingRight: "5px",
    position: "realative",
    right: "5px",
  },
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    color: "#000",
    fontWeight: "500",
    display: "flex",
    alignitem: "center",
    backgroundColor: "none",
    border: "2px solid #ced4da",
    fontSize: 14,
    padding: "5px 26px 5px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 20,
      color: "#000",
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

function DepartmentTableNew() {
  const [Getlist, setGetlist] = useState([]);
  const [GetDepartmentlist, setGetDepartmentlist] = useState([]);
  const [Companydrawer, setCompanyDrawer] = useState(false);
  const [companyform, setCompanyForm] = useState({
    branch_name: "",
    department_name: "",
  });


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxHeight: "calc(100vh - 85px)",
    bgcolor: "white",
    boxShadow: 24,
    overflow : "hidden",
    overflowY:"scroll"
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [loginAtom1, setloginAtom1] = useRecoilState(login_atoms);
  const [loginToken1, setloginToken1] = useRecoilState(login_token);

  const [trigerBranch, setTrigerBranch] = useRecoilState(triger_branch);


  const [SelectedCompany, setSelectedCompany] = useRecoilState(selectedcompany);


  const [branchid, setBranchid] = useState("");

  const handleChange2 = (event) => {
    setBranchid(event.target.value);
  
  };

  // ======================================= Api company list =================================================

  function getBranchList() {
  
    const params = {
      company_id: SelectedCompany.licence_details.company_id,
      token: loginToken1,
    };

    axios
      .get(`${api_url}/getBranchList`, { params: { ...params } })
      .then((responce) => {
        setGetlist(
          responce.data.data.map((item, key) => ({
            label: item.branch_name,
            id: item._id,
          }))
        );
   
        setBranchid(responce.data.data[0]._id);
      })
      .catch((err) => {
    
      });
  }

  useEffect(() => {
    getBranchList();
  }, [trigerBranch]);

  useEffect(() => {
    getDepartmentList();
  }, [branchid]);
  // ======================================= Api Department list =================================================
 
  const [branchid1, setBranchid1] = React.useState("");

  const handleChange1 = (event, newValue) => {
    if (newValue != null) {
      setBranchid1(newValue.id);
  
    }
  };

  React.useEffect(() => {
    getDepartmentList();
  }, [branchid]);

  
  React.useEffect(() => {
    getDepartmentList();
  }, [branchid]);

  function getDepartmentList() {
 
    const params = {
      token: loginToken1,
      branch_id: branchid,
    };

   
    axios
      .get(`${api_url}/getDepartmentList`, { params: { ...params } })
      .then((responce) => {
        setGetDepartmentlist(responce.data.data);
      
      })
      .catch((err) => {
     
      });
  }
//  ========================    GET UPDATE DETAILS  ===========================

const [open1, setOpen1] = React.useState(false);
const handleOpen1 = () => setOpen1(true);
const handleClose1 = () => setOpen1(false);

//const [branchDetails , setBranchDetails] = useState([]);
const [upDepartmentId , setUpDepartmentId] =useState('');
const [getDepartmentdetail , setGetDepartmentdetails] = useState([])


const getDepartmentdetails = (departmentId)  => (e)  => {

  e.preventDefault()
  setUpDepartmentId(departmentId);

  const params = {
    token: loginToken1,
    department_id: departmentId,
  };
 

  axios
    .get(`${api_url}/getDepartmentDetails`, { params: { ...params } })
    .then((responce) => {
   
      setGetDepartmentdetails(responce.data.data)
      handleOpen1()
  
    })
    .catch((err) => {

    });
}


const updateDepartment= () => {


getDepartmentdetail.token = loginToken1;
getDepartmentdetail.department_id = upDepartmentId;

axios
  .patch(`${api_url}/updateDepartment`, getDepartmentdetail, {
    headers: {
      "content-type": "application/json",
    },
  })
  .then(function (response) {

    getDepartmentList();
    setOpen1(false);
  })

  .catch(function (error) {
    if (error.response) {
    
    } else if (error.request) {

    } else {
  
    }
 

  });
}

function handlechange(e) {

const { value, name } = e.target;

setGetDepartmentdetails({ ...getDepartmentdetail, [name]: value });
}


  // ======================================= Api DELETE =================================================
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (id) =>  {setUpDepartmentId(id); setOpen2(true); }
  const handleClose2 = () => setOpen2(false);


  const deleteDepartment = (departmentid) => () => {
   
   
    const params = {
      department_id: upDepartmentId,
      token: loginToken1,
    };
   
    axios
      .delete(`https://server.nowdigitaleasy.com:3505/deleteDepartment`, {
        params,
      })
      .then((result) => {
      
        alert("Deleted");
      
        getDepartmentList();
        setOpen2(false);
      })
      .catch((error) => {
       
        if (error.response) {
     
    
          alert(error.response.data.message);
      
        } else if (error.request) {
        
     
        } else {
  
        }
     
      });
  };

  //============================================== Changing Form Values =============================================
 

  // ------------------------------------------------- Drawer ------------------------------------------------------


   // ================================= VALIDATION ===================================

  const [errors, setErrors] = useState({});



  const handleChange = (e) => {
    const { name, value } = e.target;
   
    setCompanyForm((state) => ({ ...state, [name]: value }));


    const newErrors = { ...errors };

    switch (name) {
      case "department_name":
        newErrors.department_name = validator.isEmpty(value)
          ? "Please enter your Department Name."
          : "";
        break;
      // case "last_name":
      //   newErrors.daily_working_hour = validator.isEmpty(value)
      //     ? "Please enter your Last Name."
      //     : "";
      //   break;
      
      default:
        break;
    }

    setErrors(newErrors);
  };



  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if phone number is valid before submitting the form
   

    if (validate()) {

      const params = JSON.stringify({
        token: loginToken1,
        user_id: loginAtom1,
        company_id: SelectedCompany.licence_details.company_id,
        branch_id: branchid1,
        department_name: companyform.department_name,
    
      });
  
      
  
      axios
        .post(`${api_url}/addDepartment`, params, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then(function (response) {
      
          getBranchList();
          getDepartmentList();
          setOpen(false);
        })
  
        .catch(function (error) {
          if (error.response) {
     
          } else if (error.request) {
         

          } else {
         
          }
        
        });
    }
  };

  const validate = () => {
    let isValid = true;
    const newErrors = {};

    // const isValid1 = validatePhone();
    // setIsValidPhone(isValid1);

 

    // if (validator.isEmpty(companyform.shift_name)) {
    //   isValid = false;
    //   newErrors.shift_name = "Please enter your first name.";
    // }

    if (validator.isEmpty(companyform.department_name)) {
      isValid = false;
      newErrors.department_name = "Please enter Department Name.";
    }

    if (validator.isEmpty(branchid1)){
      isValid = false;
      newErrors.branchid1 = "Please Select Branch"
    }
  
    setErrors(newErrors);
    return isValid;
  };


  return (
    <div className="companylist">
      {/* ======================================================== Company Table ======================================================= */}

      <div className="company_table">
        <Box>
          <div className="company_table_title">DEPARTMENT</div>
          <div className="company_table-filter">
            <div className="filter_btn">
          
              <FormControl
                sx={{ m: 1 }}
                variant="standard"
                style={{ minWidth: "150px" }}
                size="small"
              >
                <InputLabel htmlFor="demo-customized-select-native">
                  Select Your Branch
                </InputLabel>
                <NativeSelect
                  id="demo-customized-select-native"
                  defaultValue={Getlist.length >= 1 ? Getlist[0].id : ""}
                  onChange={handleChange2}
                  input={<BootstrapInput />}
                >

                  {Getlist.map((item) => {
                    return (
                      <>
                        <option value={item.id}>{item.label}</option>
                    
                      </>
                   
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>

            <div className="btn">
              <Button
          
                className="tablebutton"
               
                onClick={handleOpen}
              >
                Add Department
              </Button>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    ADD DEPARTMENT
                    <IconButton
                      component="span"
                      onClick={handleClose}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={2}
                    padding={2}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={4}>
                      
                          <Autocomplete
                          style={{
                            textTransform: "capitalize",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "'Montserrat', sans-serif",
                          }}
                          id="outlined-multiline-static"
                          size="small"
                          fullWidth
                          options={Getlist}
                          onChange={handleChange1}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                            
                              label="Select Branch*"
                            />
                          )}
                        />
                      
                         {errors.branchid1  ?  <div className="text-danger" style={{ marginTop: "10px", fontSize : "12px" , fontWeight:"bold" }}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.branchid1} 
            </div> : ""}
                      </Grid>

                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Department Name"}
                   
                          name="department_name"
                          onChange={handleChange}
                          fullWidth
                          required
                        />
                         {errors.department_name ? (
            <div className="text-danger" style={{ marginTop: "10px" , fontSize : "12px" , fontWeight:"bold"}}>
              <ErrorOutlineIcon style={{ marginRight: "5px", fontSize : "15px" }} />
              {errors.department_name}  </div>
          ) : (
            ""
          )}
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={handleSubmit}
                        >
                          Add Department
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
            </div>
          </div>
          <TableContainer component={Paper} className="tblcontainer">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table_head ">Department</TableCell>
                
                  <TableCell className="table_head">Status</TableCell>
              
                </TableRow>
              </TableHead>

              {GetDepartmentlist.map((item) => {
                return (
                  <TableRow
                    hover
                    key={item.branchid1}
                    
                    style={{ cursor: "pointer" }}
                    className="companylisttbl"
                  >
                    <TableCell
                      className="company_name"
                      title={item.department_name}
                    >
                      <div>
                        <Avatar style={{ marginRight: 15 }} />
                      </div>
                      {item.department_name}
                    </TableCell>
                    <TableCell title="Active" className="companypersonalinfo">
                      Active
                    </TableCell>

                    <div class="overlay" style={{ width: "40%" }}>
                      <div class="text">
                        <Button style={{ fontWeight: 600 }} onClick={getDepartmentdetails(item._id)}>Update Department</Button>
                        <Modal
                open={open1}
                onClose={handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className="scroll">
                  <div className="addcompanytitle">
                    UPDATE DEPARTMENT
                    <IconButton
                      component="span"
                      onClick={handleClose1}
                      style={{ padding: 0, marginRight: 20 }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                  <Stack
                    spacing={2}
                    padding={2}
                    width="100%"
                    alignItems="center"
                    height="100%"
                  >
                    <Grid container spacing={4}>

                      <Grid
                        item
                        xs={12} sm={6} md={4}
                        alignItems="center"
                        justifyContent=" center"
                      >
                        <TextField
                          size="small"
                          id="standard-basic"
                          variant="outlined"
                          label={"Department Name"}
                          //onChange={(e) => formonchange("department_name", e)}
                          onChange={handlechange}
                          name="department_name"
                          value={getDepartmentdetail.department_name}
                          fullWidth
                          required
                        />
                      </Grid>
                    </Grid>
                    <div className="addcompanybtn">
                      <Stack direction={"row"} spacing={2}>
                        <Button
                          variant="outlined"
                          className="tablebtncancel"
                          style={{ color: "#000" }}
                          onClick={handleClose1}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          className="tablebutton"
                          onClick={updateDepartment}
                        >
                          Update Department
                        </Button>
                      </Stack>
                    </div>
                  </Stack>
                </Box>
              </Modal>
                        <Button
                          onClick={() => handleOpen2(item._id)}
                          style={{ fontWeight: 600 }}
                        >
                          Delete
                        </Button>
                        <Modal
                          open={open2}
                          onClose={handleClose2}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          style={{
                            backgroundColor: "#ffffff9e",
                          }}
                       
                        >
                          <Box sx={style} className="scroll">
                            <div className="deleteemployee">
                              Are You Sure You Want To Delete Your Department?
                            </div>
                            <Stack
                              spacing={4}
                              padding={2}
                              width="100%"
                              alignItems="center"
                              height="100%"
                            >
                              <div className="addcompanybtn">
                                <Stack direction={"row"} spacing={2}>
                                  <Button
                                    variant="outlined"
                                    className="tablebtncancel"
                                    style={{ color: "#000" }}
                                    onClick={handleClose2}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className="tablebutton"
                                    onClick={
                                      deleteDepartment()
                                    
                                    }
                                  >
                                    Delete
                                  </Button>
                                </Stack>
                              </div>
                            </Stack>
                          </Box>
                        </Modal>
                        <Button style={{ fontWeight: 600 }}>More Option</Button>
                      </div>
                    </div>

               
                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        </Box>
      </div>
    </div>
  );
}

export default DepartmentTableNew;
