import * as React from "react";
import { Controller, get, useFormContext } from "react-hook-form";
import { Autocomplete, FormControl, InputLabel, TextField } from "@mui/material";

export const CustomAutoComplete = ({ ...props }) => {
    const { control, formState } = useFormContext();
    const error = get(formState.errors, props.name);

    return (
        <FormControl fullWidth error={error ? true : false}>
            <InputLabel shrink id="custom-autocomplete-label">{props.inputLabel}</InputLabel>
            <Controller
                name={props.name}
                control={control}
                rules={props.rules}
                defaultValue={props.defaultValue ? props.defaultValue : props.single ? "" : []}
                render={({ field: { ref, ...field } }) => (
                    <Autocomplete
                        size={props.size ? props.size : "medium"}
                        multiple={props.single ? false : true}
                        getOptionLabel={(option) => option.label}
                        id="custom-autocomplete"
                        onChange={(event, value) => { field.onChange(value) }}
                        options={props.options}
                        value={field.value}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        // defaultValue={props.defaultValue ? props.defaultValue : []}
                        // {...field}
                        renderInput={(params) => (
                            <TextField
                                error={Boolean(error)}
                                helperText={error?.message}
                                id="custom-autocomplete"
                                label={props.label}
                                inputRef={ref}
                                placeholder={props.placeholder ? props.placeholder : ""}
                                sx={{ borderLeft: props.rules?.minLength?.value > 0 ? "3px solid red !important" : 'inherit' }}
                                {...params}
                            />
                        )}
                    />
                )}
            />
        </FormControl>
    );
};
