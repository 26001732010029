import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Dialog,
  Paper,
  Typography,
  TextField,
  Box,
  DialogContent,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";

import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RedAsterisk from "../../../../components/RedAsterisk";
import Button from "../../../../components/Button";

const schema = yup
  .object({
    task_name: yup
      .string()
      .min(3, "Please Enter Minimum 3 characters")
      .max(50, "Please Enter Maximum 50 characters")
      .required("Please Enter Task Name"),
  })
  .required();

function TaskCategory() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  //const [tasks, setTask] = useState([]); // State to manage expense data

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAddTaskClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const onSubmit = (data) => {
    console.log("Task:", data);
    reset();
  };

  return (
    <div
      style={{
        height: "65vh",
      }}
    >
      <div
        style={
          {
            // overflowY: "auto",
          }
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <Button
            style={{
              background: "rgba(0, 10, 255, 1)",
              color: "white",
              marginTop: "2%",
              marginRight: "2%",
            }}
            onClick={handleAddTaskClick}
          >
            Add Task
          </Button>
        </div>
        <div>
          <Paper
            style={{
              width: "100%",
              marginTop: "2%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Task Name</TableCell>
                  <TableCell>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {tasks.map((task, index) => (
                    <TableRow key={index}>
                      <TableCell>{task.name}</TableCell>
                      <TableCell>{task.description}</TableCell>
                    </TableRow>
                  ))} */}
              </TableBody>
            </Table>
          </Paper>
          <Dialog
            open={isDialogOpen}
            onClose={handleDialogClose}
            maxWidth="xs"
            fullWidth
          >
            <Box
              style={{
                background: "rgba(0, 10, 255, 1)",
                padding: "10px 10px 10px 25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                }}
              >
                Task
              </Typography>
              <CloseIcon
                onClick={handleDialogClose}
                style={{
                  cursor: "pointer",
                  color: "white",
                  fontSize: "20px",
                }}
              />
            </Box>
            <DialogContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputLabel sx={{color:"black"}}>
                      Task Name <RedAsterisk />
                    </InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        {...register("task_name")}
                        error={!!errors.task_name}
                        helperText={errors.task_name?.message}
                        variant="outlined"
                        placeholder="Task Name"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{color:"black"}}>Description</InputLabel>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        {...register("description")}
                        placeholder="Description"
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    style={{
                      background: "rgba(0, 0, 0, 0)",
                      borderRadius: "5px",
                      color: "Black",
                      boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.25)",
                      marginRight: "20px",
                    }}
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      background: "rgba(0, 10, 255, 1)",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    type="submit"
                  >
                    Add
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
}

export default TaskCategory;
