import React from "react";
import Style from "../../Style/LogInPage.module.css";
import NDELogo from "../../Images/NDE_Logo_Blue.png";
const LogInHeader = () => {
  return (
    <>
      <div></div>
      <div className={Style.Image_Align}>
     
  
          <img src={NDELogo} alt="NDE Logo" />

      </div>
    </>
  );
};

export default LogInHeader;
